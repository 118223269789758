import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spin } from "antd";
import { motion } from "framer-motion";
import ResetPassword from "../../Components/Auth/ResetPassword";
import { useUser } from "../../contexts/UserContext";
import { BsEnvelope } from "react-icons/bs";
import { GoLock } from "react-icons/go";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ReviewSlider from "./ReviewSlider";

const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean | null>(null);
  const [vis, setVis] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const navigate = useNavigate();
  const { checkLoginMethodAndMember, loginWithEmailAndPass } = useUser();

  useEffect(() => {
    setError(null);
  }, [email, password, confirmPassword]);

  const Icon = vis ? AiOutlineEye : AiOutlineEyeInvisible;

  const handleLoginCheck = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const { signInMethods, member } = await checkLoginMethodAndMember(email);
    if (!member) {
      setError(
        "No account found for your organization with this email, please contact us or the appropriate team lead in your organization or Sign Up for your organization as a lead."
      );
    } else if (signInMethods.length === 0) {
      setIsNew(true);
      setError(null);
    } else {
      setIsNew(false);
      setError(null);
    }
    setLoading(false);
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await loginWithEmailAndPass(email, password, "old");
      navigate("/");
    } catch (error: any) {
      console.error(error.message);
      setError(error.message);
    }
    setLoading(false);
  };

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await loginWithEmailAndPass(email, password, "new");
      navigate("/");
    } catch (error: any) {
      console.error(error.message);
      setError(error.message);
    }
    setLoading(false);
  };

  const isValidEmail = (email: string) => {
    // taken from personna.ai src code
    if (typeof email !== "string" || email.trim() === "") return false;
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email matches the pattern
    return pattern.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    setIsEmailValid(isValidEmail(emailValue)); // Validate email and update state
  };

  // const GoogleLogin = async () => {
  //   setLoading(true);
  //   try {
  //     // const provider = new GoogleAuthProvider();
  //     // await signInWithPopup(auth, provider);
  //     const clientId = encodeURIComponent(GOOGLE_AUTH_CLIENT_ID);
  //     const redirect_uri = encodeURIComponent(GOOGLE_AUTH_REDIRECT_URI);
  //     const nonce = Math.random().toString(36).substring(2, 15);
  //     const responseType = encodeURIComponent("id_token");
  //     const scope = encodeURIComponent(GOOGLE_AUTH_SCOPE);
  //     const prompt = encodeURIComponent("consent");
  //     const state = encodeURIComponent("djwdjd");

  //     const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirect_uri}&response_type=${responseType}&scope=${scope}&nonce=${nonce}&prompt=${prompt}&state=${state}`;
  //     window.location.href = authUrl;
  //     setLoading(false);
  //     // navigate("/");
  //   } catch (error: any) {
  //     console.error(error.message);
  //     setError(error.message);
  //   }
  //   setLoading(false);
  // };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center !cursor-default relative bg-gradient-radial from-white via-purple-100 to-indigo-100 font-TWK-Lausanne-500">
      {reset && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center">
          <ResetPassword setReset={setReset} email={email} />
        </div>
      )}

      {/* Logo at top left */}
      <div className="absolute top-4 left-4 z-10">
        <a href="https://www.floqer.com" target="_blank" rel="noreferrer">
          <img
            src="https://storage.googleapis.com/public_image_assets/internal-web-app-logos/floqer_logo.png"
            alt="Company Logo"
            className="h-16 cursor-pointer"
          />
        </a>
      </div>

      <div className="absolute inset-0 z-0 bg-gradient-radial from-white via-white/70 to-indigo-500/25" />

      <motion.div
        className="w-full max-w-md bg-white p-8 rounded-3xl shadow-lg border border-gray-200 z-10"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <div className="text-center mb-6">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-3xl text-primary tracking-normal mb-2 font-TWK-Lausanne-500"
          >
            Welcome <span className="text-[#222222]">back!</span>
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-gray-500 text-sm font-TWK-Lausanne-400"
          >
            Use Floqer to bring your GTM motions to life in minutes.
          </motion.p>
        </div>

        {error && (
          <motion.div
            className="text-sm bg-red-100 border border-red-400 text-red-700 p-3 rounded-lg mb-4 shadow-md text-center font-TWK-Lausanne-400"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <i className="fas fa-exclamation-circle mr-2"></i>
            {error.includes("Firebase: Error (auth/wrong-password).") ? "Please enter correct password." : error}
          </motion.div>
        )}

        <form
          className="flex flex-col gap-4"
          onSubmit={isNew === null ? handleLoginCheck : isNew === true ? handleSignUp : handleLogin}
        >
          <div className="relative">
            <input
              placeholder="yourname@company.com"
              type="email"
              value={email}
              autoComplete="off"
              readOnly={isNew !== null}
              onChange={handleEmailChange}
              className={`rounded-full border border-gray-300 text-gray-700 w-full pl-10 py-3 ${
                isNew !== null ? "bg-gray-200" : ""
              } focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:border-indigo-500`}
              style={{ height: "48px", fontFamily: "TWK_Lausanne_300" }}
            />
            <BsEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
          </div>

          {isNew !== null && (
            <div>
              <div className="relative">
                <input
                  placeholder="Enter your password"
                  type={vis ? "text" : "password"}
                  value={password}
                  required
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                  ref={(input) => input && !isNew && input.focus()}
                  className={`rounded-full border border-gray-300 text-gray-700 w-full pl-10 py-3 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:border-indigo-500`}
                  style={{ height: "48px", fontFamily: "TWK_Lausanne_300" }}
                />
                <GoLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                <button
                  type="button"
                  onClick={() => setVis(!vis)}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5"
                >
                  <Icon size={"20px"} />
                </button>
              </div>
              {isNew === true && (
                <div className="relative mt-2">
                  <input
                    placeholder="Confirm your password"
                    type="password"
                    value={confirmPassword}
                    required
                    autoComplete="off"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className={`rounded-full border border-gray-300 text-gray-700 w-full pl-10 py-3 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:border-indigo-500`}
                    style={{ height: "48px", fontFamily: "TWK_Lausanne_300" }}
                  />
                  <GoLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5" />
                </div>
              )}
            </div>
          )}

          {loading ? (
            <Button
              type="primary"
              className="rounded-full border-0 text-lg flex items-center justify-center"
              style={{ height: "48px", fontFamily: "TWK_Lausanne_500" }}
              disabled
            >
              <Spin size="small" />
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              className={`rounded-full border-0 text-lg ${!isEmailValid ? "bg-gray-500" : ""}`}
              style={{ height: "48px", fontFamily: "TWK_Lausanne_500" }}
              disabled={!isEmailValid}
            >
              {isNew === null ? "Continue" : isNew === true ? "Sign Up" : "Login"}
            </Button>
          )}

          {isNew === false && (
            <Button
              type="link"
              onClick={() => setReset(true)}
              className="text-primary font-medium !hover:text-indigo-500 font-TWK-Lausanne-300"
            >
              Reset password
            </Button>
          )}
          {/* {!loading && isNew === null && (
            <div className="w-full flex flex-col gap-4">
              <div className="divider">
                <hr />
                <span>OR</span>
                <hr />
              </div>
              <button
                className="flex gap-2 justify-center items-center border border-[#dee4ed] rounded w-full px-4 py-2 hover:border-primary"
                onClick={GoogleLogin}
              >
                <FcGoogle />{" "}
                <span style={{ marginLeft: "8px" }}>Log in with Google</span>
              </button>
            </div>
          )} */}
          {/* <p className="w-full text-center flex flex-col">
            Not a part of any organization?{" "}
            <span>
              Sign up with your organization&nbsp;
              <Link to="/org" className="text-[#5452f6] hover:underline">
                <span>here</span>
              </Link>
            </span>
          </p> */}
        </form>

        <p className="text-center text-xs mt-4 font-TWK-Lausanne-300">
          By {isNew === true ? "signing up" : "signing in"}, you agree that you have read and understood, and agree to
          Floqer's{" "}
          <a href="https://www.floqer.com/terms-of-service" className="text-indigo-500">
            Terms
          </a>{" "}
          and{" "}
          <a href="https://www.floqer.com/privacy-policy" className="text-indigo-500">
            Privacy Policy
          </a>
          .
        </p>
      </motion.div>

      <ReviewSlider />
    </div>
  );
};

export default Login;
