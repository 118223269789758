import { useState, useRef } from "react";
import * as XLSX from "xlsx";
import { ResponseConfiguration } from "../../utils/interfaces";
import CSVModal from "./Modals/CSVModal";
import Papa from "papaparse";

type Props = {
  template: ResponseConfiguration[];
  workflowId: string;
  children?: React.ReactNode;
  refresh: () => void;
};

const FileInput = ({ template, workflowId, children, refresh }: Props) => {
  const [resArr, setResArr] = useState<any[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setFileName(file.name);

    // Handle based on file extension
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (fileExtension === "csv") {
      // Handle CSV
      Papa.parse(file, {
        complete: (results) => {
          setResArr(results.data);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    } else if (["xlsx", "xls"].includes(fileExtension || "")) {
      // Handle Excel
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setResArr(jsonData);
        } catch (error) {
          console.error("Error parsing Excel file:", error);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    fileInputRef.current?.click();
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        id="file-upload"
      />
      <div onClick={handleButtonClick}>
        {resArr.length === 0 ? (
          children
        ) : (
          <CSVModal
            workflowId={workflowId}
            name={fileName}
            getRemoveFileProps={() => ({
              onClick: () => {
                setResArr([]);
                setFileName("");
              },
            })}
            resArr={resArr}
            template={template}
            refresh={refresh}
          />
        )}
      </div>
    </div>
  );
};

export default FileInput;
