import { setStateType } from "@/utils/constants";
import FloqerLogo from "@Assets/SVGs/floqerLogo.svg";

type Props = {
  availableSignals: any[];
  setCreate: setStateType<string>;
};

const types = [
  {
    name: "Watchers (Signals)",
    id: "watcher",
  },
  {
    name: "Lists (Static)",
    id: "list",
  },
];

const Signals = ({ availableSignals, setCreate }: Props) => {
  // Sorting the signals to ensure consistent order in the ui
  const sortedSignals = [...availableSignals].sort((a, b) => a.id.localeCompare(b.id));

  return (
    <div className="w-full h-full flex flex-col gap-16 px-5">
      {types.map((type) => (
        <div key={type.id} className="flex flex-col gap-4">
          <h2 className="text-lg font-medium text-[#1E0068]">{type.name}</h2>
          <div className="grid grid-cols-4 gap-4">
            {sortedSignals
              .filter((signal) => signal.type === type.id)
              .map((signal) => (
                <button
                  key={signal.id}
                  className="flex items-center text-start gap-3 border-2 border-gray-300 rounded-md p-3 hover:border-primary hover:shadow-md transition"
                  onClick={() => setCreate(signal.id)}
                >
                  <img src={signal.logo || FloqerLogo} alt={signal.name} className="w-8 h-8" />
                  <h3 className="text-md font-normal">{signal.name}</h3>
                </button>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Signals;
