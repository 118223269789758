import LoaderButton from "@/Components/LoaderButton";
import { Input, message, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { getTotalItems } from "./SalesNavList";
import { useNavigate } from "react-router-dom";
import { createSignal } from "../../apis";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";

interface Props {
  name: string;
  signalId: string;
  preview?: boolean;
  generatePreview: (payload: any) => Promise<void>;
  data: any;
}

const CrustDataListViaURL: React.FC<Props> = ({ name, generatePreview, signalId, preview, data }) => {
  const navigate = useNavigate();
  const [url, setUrl] = useState<string>("");
  const [pageCount, setPageCount] = useState(1);
  const [totalItems, setTotalItems] = useState<number>();

  useEffect(() => {
    const total = data?.metadata?.total_results || 0;
    setTotalItems(total);
  }, [totalItems]);

  const handleInputChange = (e: any) => {
    const value = parseInt(e.target.value, 10);

    if (!isNaN(value)) {
      setPageCount(Math.max(1, Math.min(99, value))); // Clamping the value between 1 and 99
    }
  };

  const handleStepChange = (step: number) => {
    setPageCount((prev) => {
      const newValue = prev + step;
      return Math.max(1, Math.min(99, newValue)); // Ensure value stays in range
    });
  };

  return (
    <div className="py-5 px-10 overflow-hidden flex flex-col h-screen">
      <div className="flex flex-col space-y-4">
        <div className="flex-1">
          <label htmlFor="searchURL" className="block mb-2 text-lg font-semibold text-black">
            Sales Navigator Search URL
          </label>
          <Input
            placeholder="https://www.linkedin.com/sales/people/293202-johndoe"
            size="large"
            className="h-14"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <div className="flex-1">
          <label htmlFor="pageCount" className="block mb-2 text-lg font-semibold text-black">
            Page Count <span className="text-gray-400 text-sm">(Optional)</span>
          </label>
          <div className="flex gap-x-3 items-center">
            <button
              onClick={() => handleStepChange(-1)}
              className="px-3 p-1 h-8 w-8 bg-gray-200 hover:bg-gray-300 rounded-full"
              disabled={pageCount <= 1}
            >
              -
            </button>
            <Tooltip
              trigger={["focus"]}
              title={"Number of pages to be extracted from salesnav URL (each page has upto 25 items)"}
              placement="topLeft"
              overlayClassName="numeric-input"
            >
              <Input
                type="number"
                value={pageCount}
                onChange={handleInputChange}
                placeholder="Input a number"
                max={99}
                min={1}
                className="h-14"
              />
            </Tooltip>
            <button
              onClick={() => handleStepChange(1)}
              className="px-3 py-1 h-fit bg-gray-200 hover:bg-gray-300 rounded-full"
              disabled={pageCount >= 99}
            >
              +
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-4">
        {preview && (
          <LoaderButton
            text="Generate Preview"
            loaderText="Generating Preview..."
            btnClasses="mt-auto"
            onClickCallback={async () => {
              if (!url) {
                message.error("Please provide a SalesNav URL");
                return;
              }
              await generatePreview({ salesNavUrl: url, type: "salesNavUrl" });
              return;
            }}
            error=""
            disabled={false}
          />
        )}
        <LoaderButton
          text="Create Signal"
          loaderText="Creating Signal..."
          btnClasses="mt-auto"
          onClickCallback={async () => {
            if (!url) {
              message.error("Please provide a SalesNav URL");
              return;
            }

            const newId = await createSignal(
              SIGNAL_ID_TO_URL[signalId as keyof typeof SIGNAL_ID_TO_URL],
              {
                salesNavUrl: url,
                page: getTotalItems(totalItems || 0, pageCount),
                type: "salesNavUrl",
              },
              name
            );
            navigate(`/list/${newId}`);
          }}
          error=""
          disabled={false}
        />
      </div>
    </div>
  );
};

export default CrustDataListViaURL;
