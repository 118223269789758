import { memo } from "react";
import WorkflowFolderIcon from "../../../assets/SVGs/WorkflowFolderIcon.svg";
import { MdMoreHoriz } from "react-icons/md";
import moment from "moment";
import { DISPLAY_DATE_TIME_FORMAT } from "@/utils/constants";
import { Dropdown } from "antd";

interface FolderProps {
  folder: any;
  getFolderActionMenu: any;
  RenameWorkflowFolder: any;
  toRenameFolder: any;
  setExpandedKeys: React.Dispatch<React.SetStateAction<string[]>>;
}

const Folder = memo(
  ({ folder, getFolderActionMenu, RenameWorkflowFolder, toRenameFolder, setExpandedKeys }: FolderProps) => {
    if (!folder) return;

    return (
      <div
        className="px-2 py-1 grid grid-cols-8 items-center hover:bg-primary/10 rounded-md"
        onClick={() =>
          setExpandedKeys((preExpandedKeys) => {
            if (preExpandedKeys.includes(`folder=${folder.id}`)) {
              return preExpandedKeys.filter((i) => i != `folder=${folder.id}`);
            } else {
              return [...preExpandedKeys, `folder=${folder.id}`];
            }
          })
        }
      >
        <div className="flex gap-x-3 items-center font-normal col-span-6">
          <img src={WorkflowFolderIcon} alt="workflow-folder-icon" width={18} />
          {!toRenameFolder || toRenameFolder.id !== folder.id ? (
            <p className="!text-gray-700 text-md">
              {folder.name.length > 70 ? `${folder.name.slice(0, 67)}...` : folder.name}
            </p>
          ) : (
            <RenameWorkflowFolder />
          )}
        </div>
        <p className="text-sm w-full text-gray-500 font-light">
          {moment.utc(folder.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT)}
        </p>
        <div className="hoverClass flex items-center justify-center">
          <Dropdown
            overlay={getFolderActionMenu(folder)}
            trigger={["click"]}
            overlayStyle={{
              width: "11rem",
            }}
          >
            <button
              className="flex justify-center h-fit items-center p-1 rounded-md transition-opacity duration-600 ease-in-out hover:opacity-100 hover:bg-gray-200"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
              }}
            >
              <MdMoreHoriz size={20} />
            </button>
          </Dropdown>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Skip comparison for props that never change
    return (
      prevProps.folder === nextProps.folder &&
      prevProps.toRenameFolder === nextProps.toRenameFolder &&
      prevProps.setExpandedKeys === nextProps.setExpandedKeys &&
      prevProps.getFolderActionMenu === nextProps.getFolderActionMenu
    );
  }
);

export default Folder;
