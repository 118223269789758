import { message, Table } from "antd";
import { useEffect, useState } from "react";
import { HiLink } from "react-icons/hi2";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { fetchListConnections } from "../apis";
import { getUserWorkflows } from "@/utils/apis";
import moment from "moment";
import { capitalize } from "lodash";
import Connection from "../Modals/Connection";
import { ResponseStructure } from "../models";
import { CollapsibleIcon } from "@/Components/NavbarNew/CollapsibleIcon";

type Props = {
  close: () => void;
  responseStructure: ResponseStructure[];
};

const STATUS_COLORS = {
  active: "bg-green-500",
  terminated: "bg-red-500",
  paused: "bg-yellow-500",
};

const TABLE_COLUMNS: any[] = [
  { title: "Workflow Name", dataIndex: "name", key: "name", width: "40%" },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    widht: "15%",
    align: "center",
    render: (params: any) => (
      <div className="flex items-center justify-center gap-2">
        <div className={`w-2 h-2 rounded-full ${STATUS_COLORS[params as keyof typeof STATUS_COLORS]}`}></div>
        <p className="text-sm font-normal">{capitalize(params)}</p>
      </div>
    ),
  },
  {
    title: "Connected On",
    dataIndex: "created_at",
    key: "created_at",
    width: "20%",
    align: "center",
    render: (params: any) => moment(params).utc().local().format("MMM DD, YYYY HH:mm:ss"),
  },
  { title: "Total Leads Pushed", dataIndex: "leads_pushed", key: "leads_pushed", width: "15%", align: "center" },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    width: "10%",
  },
];

const ListConnections = ({ close, responseStructure }: Props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState<string | undefined>(undefined);
  const [connections, setConnections] = useState<any[]>([]);
  const [workflows, setWorkflows] = useState<any[]>([]);

  useEffect(() => {
    if (!id) return;
    const getConnectionsAndWorkflows = async () => {
      setLoading(true);
      try {
        const [conns, flows] = await Promise.all([fetchListConnections(id), getUserWorkflows(true)]);
        setConnections(conns);
        const firebase = flows.workflows;
        const supabase = flows.workflowsV2;
        const allWorkflows: any[] = (firebase?.workflows || [])
          .concat(supabase?.workflows || [])
          .map((workflow: any) => {
            if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id)) workflow.supabase = false;
            else workflow.supabase = true;
            return workflow;
          });
        allWorkflows.sort((a: any, b: any) => {
          const aDate = moment.utc(a.createdAt);
          const bDate = moment.utc(b.createdAt);

          if (aDate.isAfter(bDate)) return -1;
          if (aDate.isBefore(bDate)) return 1;
          return 0;
        });
        setWorkflows(allWorkflows);
      } catch (error) {
        message.error("Failed to fetch connections");
      } finally {
        setLoading(false);
      }
    };
    getConnectionsAndWorkflows();
  }, [id]);

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-5">
      <div className="text-2xl font-semibold p-5 pb-0 bg-gradient-to-r from-[#5e35b1] via-[#d192e2] to-[#ab47bc] bg-clip-text text-black cursor-default">
        {/* CollapsibleIcon in top left */}
        <div className="flex gap-2 items-center text-2xl font-semibold bg-gradient-to-r from-[#5e35b1] via-[#d192e2] to-[#ab47bc] bg-clip-text text-black cursor-default">
          <CollapsibleIcon isHeader />
          <button onClick={close}>
            <MdOutlineKeyboardBackspace size={35} />
          </button>
          Connections
        </div>
      </div>
      <button
        type="button"
        onClick={() => setUpdate("")}
        className="flex items-center gap-2 ml-auto rounded-md border border-gray-200 disabled:cursor-not-allowed disabled:bg-gray-100
             mx-5 px-3 py-1.5 font-medium text-dark hover:bg-primary/10 transition-all duration-200 ease-in-out w-fit justify-center"
      >
        <HiLink color="rgb(30 0 104)" size={25} />
        Create a new Connection
      </button>
      <Table
        loading={loading}
        dataSource={connections.map((connection) => {
          const { workflow_id, created_at, leads_pushed, status } = connection;
          const { name, publishedWorkflowConfig } = workflows.find((workflow) => workflow.id === workflow_id) || {};
          return {
            key: connection.id,
            name: (
              <Link to={`/workflow/${workflow_id}`} target="_blank">
                {name || publishedWorkflowConfig.name}
              </Link>
            ),
            status,
            created_at,
            leads_pushed,
            actions: (
              <button className="text-primary underline" onClick={() => setUpdate(workflow_id)}>
                Update
              </button>
            ),
          };
        })}
        columns={TABLE_COLUMNS}
      />
      <Connection
        update={update}
        close={() => setUpdate(undefined)}
        existingConnections={connections}
        workflows={workflows}
        loading={loading}
        responseStructure={responseStructure}
        addConnection={(connection: any) => setConnections([connection, ...connections])}
        updateExistingConnection={(connection: any) => {
          const updatedConnections = connections.map((conn) =>
            conn.workflow_id === connection.workflow_id ? connection : conn
          );
          setConnections(updatedConnections);
        }}
      />
    </div>
  );
};

export default ListConnections;
