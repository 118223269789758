import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "./ui/Command";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/Popover";
import { FilterGroup, IFiltersProps } from "./filter.schema";
import { WithTooltip } from "./ui/Tooltip";
import { Separator } from "./ui/Separator";
import { useState } from "react";
import { GrDocumentMissing } from "react-icons/gr";
import { LuPlus } from "react-icons/lu";
import { filterGroupIcons } from "./filters";
import { FiHelpCircle } from "react-icons/fi";

const ButtonAddFilter = ({
  availableFilters,
  appliedFilters,
  addFilters,
}: {
  availableFilters: IFiltersProps[];
  appliedFilters: IFiltersProps[];
  addFilters: (_filters: IFiltersProps[]) => void;
}) => {
  const groups = availableFilters
    .map((filter) => filter.group)
    .filter((value, index, self) => self.indexOf(value) === index);
  const [search, setSearch] = useState<string>();
  const [groupFilter, setGroupFilter] = useState<FilterGroup>();

  const onFilterByGroup = (group: FilterGroup) => {
    if (groupFilter == group) {
      setGroupFilter(undefined);
      return;
    }
    setGroupFilter(group);
  };

  const addFilter = (filter: IFiltersProps) => {
    const newFilters = [];

    //checking if the filter has a dependency
    if (filter.dependsOnFilterIds && filter.dependsOnFilterIds?.length > 0) {
      const exists_any_dependency = filter.dependsOnFilterIds.some((dependencyId) =>
        appliedFilters.some((appliedFilter) => appliedFilter.id === dependencyId)
      );
      if (!exists_any_dependency) {
        const firstDependency = availableFilters.find(
          (availableFilter) => filter.dependsOnFilterIds && filter.dependsOnFilterIds.includes(availableFilter.id)
        );
        if (firstDependency) {
          const dependencyFilter = { ...firstDependency };
          dependencyFilter.operator = dependencyFilter.availableOperators && dependencyFilter.availableOperators[0];
          dependencyFilter.value = 15;
          dependencyFilter.hasChanged = true;
          newFilters.push(dependencyFilter);
        }
      }
    }

    // adding selected filter
    const newFilter = { ...filter };
    const appliedFiltersWithSameId = appliedFilters.filter((appliedFilter) => appliedFilter.id === filter.id);
    const operatorsUsed = appliedFiltersWithSameId.map((appliedFilter) => appliedFilter.operator);
    newFilter.operator = newFilter.availableOperators.filter((operator) => !operatorsUsed.includes(operator))[0];
    newFilters.push(newFilter);

    addFilters(newFilters);
  };

  function isApplied(filter: IFiltersProps) {
    const appliedFiltersWithSameId = appliedFilters.filter((appliedFilter) => appliedFilter.id === filter.id);

    return (
      appliedFiltersWithSameId.length == filter.availableOperators.length ||
      (appliedFiltersWithSameId.length == 1 && filter.areOperatorsAccumulative == false)
    );
  }

  const filterCountByGroup = groups.reduce((countByGroup: any, group) => {
    const filtersInGroup = availableFilters.filter((filter) => filter.group === group);
    countByGroup[group] = filtersInGroup.length;
    return countByGroup;
  }, {});

  const onPopOverOpenChange = (open: boolean) => {
    if (!open) {
      setGroupFilter(undefined);
    }
  };

  return (
    <Popover onOpenChange={onPopOverOpenChange}>
      <PopoverTrigger asChild>
        <button className="w-fit border border-gray-400 inline-flex items-center justify-center whitespace-nowrap text-sm font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-gray-200 hover:text-accent-foreground h-9 rounded-md px-3">
          <LuPlus className="mr-2 h-4 w-4" /> Add Filter
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0 " align="start">
        <Command>
          <CommandInput
            placeholder="Type filter..."
            onValueChange={setSearch}
            onClick={() => {
              setGroupFilter(undefined);
            }}
          />
          <div className="grid grid-cols-3 m-1 space-x-1">
            {groups.map((group) => (
              <div
                key={group}
                className={`flex items-center flex-col p-2 cursor-pointer space-y-2 ${
                  groupFilter == group
                    ? "text-primary rounded-md bg-violet-100"
                    : "hover:text-primary hover:bg-gray-100 hover:rounded-md text-muted-foreground"
                }`}
                onClick={() => onFilterByGroup(group)}
              >
                {filterGroupIcons?.[group] || <GrDocumentMissing />}
                {/* {group == "Company" && <GoOrganization className="h-5 w-5" />}
                {group == "Technology" && <CiLaptop className="h-5 w-5" />}
                {group == "Job Posting" && <GoBriefcase className="h-5 w-5" />} */}
                <p className="text-xs font-medium">
                  {group} ({filterCountByGroup[group]})
                </p>
              </div>
            ))}
          </div>
          <Separator />
          <CommandList>
            <CommandEmpty>No results found for "{search}"</CommandEmpty>
            {groups
              .filter((group) => groupFilter == undefined || groupFilter == group)
              .map((group) => (
                <CommandGroup heading={group} key={group}>
                  {availableFilters
                    .filter((filter) => filter.group == group)
                    .map((filter, index) => (
                      <CommandItem
                        className={isApplied(filter) ? "text-gray-400 group pointer-events-none opacity-50" : "group"}
                        key={index}
                        onSelect={() => addFilter(filter)}
                        disabled={isApplied(filter)}
                      >
                        <filter.icon className="mr-2 h-3 w-3" />
                        <span>{filter.title}</span>
                        {filter.description && (
                          <WithTooltip title={filter.description} side="right">
                            <FiHelpCircle className="ml-2 h-3 w-3 text-muted-foreground group-hover:opacity-100 opacity-0" />
                          </WithTooltip>
                        )}
                      </CommandItem>
                    ))}
                </CommandGroup>
              ))}
            <CommandSeparator />
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default ButtonAddFilter;
