export const regions = [
  "3842, Churchill, Victoria, Australia",
  "08002, Cherry Hill, New Jersey, United States",
  "Negrești-Oaș, Satu Mare, Romania",
  "13676, Potsdam, New York, United States",
  "Fremont, Ohio, United States",
  "Libertyville, Illinois, United States",
  "Alexandria, Ohio, United States",
  "Cork, County Cork, Ireland",
  "Castelo Branco, Portugal",
  "958 06, Partizánske, Trenciansky, Slovakia",
  "50135, Florence, Tuscany, Italy",
  "Krasnogorskiy, Chelyabinsk, Russia",
  "Differdange, Luxembourg, Luxembourg",
  "Charlottesville High, Virginia, United States",
  "7773, Hinds, Canterbury, New Zealand",
  "36780, Salamanca, Guanajuato, Mexico",
  "66300, Yozgat, Yozgat, Türkiye",
  "428028, Cheboksary, Chuvashia, Russia",
  "Nevsehir, Nevşehir, Türkiye",
  "Roberts Creek, British Columbia, Canada",
  "9914, Kirkenes, Troms og Finnmark, Norway",
  "Barron, Wisconsin, United States",
  "Lawrenceburg, Indiana, United States",
  "Urlingford, County Kilkenny, Ireland",
  "248000, Kaluga, Kaluga, Russia",
  "Ward County, Texas, United States",
  "C25D4Y2, Makinsk, Akmola Region, Kazakhstan",
  "694901, Uglegorsk, Sakhalin, Russia",
  "04734, Blaine, Maine, United States",
  "Tula, Tamaulipas, Mexico",
  "Fiery Flat, Victoria, Australia",
  "620017, Yekaterinburg, Sverdlovsk, Russia",
  "346919, Novoshakhtinsk, Rostov, Russia",
  "71300, Ciénega de Zimatlán, Oaxaca, Mexico",
  "LU1 1AA, Luton, England, United Kingdom",
  "Hill, Arkansas, United States",
  "Ternopil, Ternopil, Ukraine",
  "Kotovskoye, Orel, Russia",
  "606408, Balakhna, Nizhniy Novgorod, Russia",
  "Sete Lagoas, Minas Gerais, Brazil",
  "Yemanzhelinskoye, Chelyabinsk, Russia",
  "05-300, Mińsk Mazowiecki, Mazowieckie, Poland",
  "Aix-en-Provence, Provence-Alpes-Côte d'Azur, France",
  "Burlington, Colorado, United States",
  "Atbasar, Akmola Region, Kazakhstan",
  "28006, Madrid, Community of Madrid, Spain",
  "Bedford, Ohio, United States",
  "Norilsk, Krasnoyarsk Krai, Russia",
  "Coahuila, Mexico",
  "446029, Syzran, Samara, Russia",
  "61442, Keithsburg, Illinois, United States",
  "185 40, Piraeus, Attiki, Greece",
  "601 32, Katerini, Central Macedonia, Greece",
  "Savannah International Airport-Travis Field, Savannah, Georgia, United States",
  "South Patrick, Florida, United States",
  "Stanstead, England, United Kingdom",
  "Mato Grosso, Brazil",
  "184230, Polyarnyye Zori, Murmansk, Russia",
  "Oliver's Battery, Winchester, England, United Kingdom",
  "Luzerne, Iowa, United States",
  "586 43, Linköping, Östergötland County, Sweden",
  "10290, Zaprešić, Zagreb, Croatia",
  "49160-428, Nossa Senhora do Socorro, Sergipe, Brazil",
  "Berkeley, England, United Kingdom",
  "East Haskell, Broken Arrow, Oklahoma, United States",
  "Contagem, Minas Gerais, Brazil",
  "Lancaster County, Virginia, United States",
  "Unicoi, Tennessee, United States",
  "187503, Tikhvin, Leningrad, Russia",
  "Villa Carlos Paz, Córdoba, Argentina",
  "641886, Shadrinsk, Kurgan, Russia",
  "12141, Vilnius, Vilniaus, Lithuania",
  "678800, Chokurdakh, Sakha, Russia",
  "Araklı, Trabzon, Türkiye",
  "38025, Dyersburg, Tennessee, United States",
  "Adilcevaz Bucağı, Bitlis, Türkiye",
  "99601, Sodankylä, Lapland, Finland",
  "346989, Bol’shaya Kirsanovka, Rostov, Russia",
  "Moscow, Russia",
  "Aral, Xinjiang Uygur, China",
  "Jenaro Herrera, Loreto, Peru",
  "16882, Woodward, Pennsylvania, United States",
  "Erathu, Kerala, India",
  "95200-334, Vacaria, Rio Grande do Sul, Brazil",
  "Córdoba, Argentina",
  "Up Mahal Keyarla, Nerwa, Himachal Pradesh, India",
  "Greater Metz Area",
  "Queensbury, New York, United States",
  "Metz, Missouri, United States",
  "23326, Chesapeake, Virginia, United States",
  "820165, Tulcea, Tulcea, Romania",
  "39437, Ellisville, Mississippi, United States",
  "S25B5A1, Ertis, Pavlodar Region, Kazakhstan",
  "356420, Blagodarnyy, Stavropol, Russia",
  "83660, Parma, Idaho, United States",
  "Sequoyah County, Oklahoma, United States",
  "Stanton, California, United States",
  "385006, Maykop, Adygea, Russia",
  "185013, Petrozavodsk, Karelia, Russia",
  "28277, Bremen, Bremen, Germany",
  "Villers-lès-Nancy, Grand Est, France",
  "Tralee, Tralee, County Kerry, Ireland",
  "Chapayevskiy, Altai Krai, Russia",
  "38350, Kayseri, Kayseri, Türkiye",
  "44266, Ravenna, Ohio, United States",
  "West Mifflin, Pennsylvania, United States",
  "Linares de la Sierra, Andalusia, Spain",
  "13875, Ponderano, Piedmont, Italy",
  "46017, Valencia, Valencian Community, Spain",
  "Kimball, Saint Clair, Ontario, Canada",
  "Alfalfa County, Oklahoma, United States",
  "32147, Palatka, Florida, United States",
  "Kurganinsk, Krasnodar, Russia",
  "Wilsonville, Oregon, United States",
  "Newcastle, New Brunswick, Canada",
  "Greater Newcastle Area",
  "65721, Ozark, Missouri, United States",
  "Bennington, Kansas, United States",
  "Ogden–Clearfield metropolitan area, Utah, United States",
  "Cleveland, Alabama, United States",
  "Hopkins, Cleveland, Ohio, United States",
  "LU4 0AA, Luton, England, United Kingdom",
  "66958, Morrowville, Kansas, United States",
  "Maladzyechna District, Minsk Province, Belarus",
  "40026, Goshen, Kentucky, United States",
  "Bexar, Arkansas, United States",
  "Klinteby Frihed, Region Zealand, Denmark",
  "Cookstown, Northern Ireland, United Kingdom",
  "Adair, Oklahoma, United States",
  "920003, Slobozia, Ialomiţa, Romania",
  "Gorod Kineshma, Ivanovo, Russia",
  "Indiana, Pennsylvania, United States",
  "Umbai, Malacca, Malaysia",
  "723 48, Västerås, Västmanland County, Sweden",
  "Svobodnyy, Amur, Russia",
  "Boyd, Texas, United States",
  "456811, Verkhniy Ufaley, Chelyabinsk, Russia",
  "Dnipro, Dnipropetrovsk, Ukraine",
  "20600, Denizli, Denizli, Türkiye",
  "Wilkinson Oaks, South Gate Ridge, Florida, United States",
  "Viseu, Portugal",
  "21032-959, Rio de Janeiro, Rio de Janeiro, Brazil",
  "Buchanan Castle, Glasgow, Scotland, United Kingdom",
  "K4P 0A1, Kars, Ontario, Canada",
  "62100, Calais, Hauts-de-France, France",
  "82400, Stryy, Lviv, Ukraine",
  "2322, Hamar, Innlandet, Norway",
  "46307, Crown Point, Indiana, United States",
  "80020, Pärnu, Parnu, Estonia",
  "Kostiantynivka Raion, Donetsk, Ukraine",
  "Yablanitsa, Lovech, Bulgaria",
  "T23 HCF1, Cork, County Cork, Ireland",
  "Le Petit Malheur, La Chapelle-Bayvel, Normandy, France",
  "352922, Armavir, Krasnodar, Russia",
  "3141, Salgótarján, Nógrád, Hungary",
  "Myanmar",
  "Chornobaivska silska hromada, Kherson, Ukraine",
  "Gardendale, Alabama, United States",
  "North Brabant, Netherlands",
  "Obshtina Belene, Pleven, Bulgaria",
  "Tatenhill, England, United Kingdom",
  "Shelby County, Ohio, United States",
  "Barrón, Guanajuato, Mexico",
  "Montour, Savignac-de-Duras, Nouvelle-Aquitaine, France",
  "Bandon, County Cork, Ireland",
  "301240, Shchëkino, Tula, Russia",
  "River Forest, Gallatin, Missouri, United States",
  "Galliano, Eupilio, Lombardy, Italy",
  "Claiborne West, Loveland, Ohio, United States",
  "53219, Milwaukee, Wisconsin, United States",
  "644001, Omsk, Omsk, Russia",
  "Cherokee County, Alabama, United States",
  "730227, Vaslui, Vaslui, Romania",
  "Henderson County, Kentucky, United States",
  "Pemiscot County, Missouri, United States",
  "44287, Dortmund, North Rhine-Westphalia, Germany",
  "60-320, Poznan, Wielkopolskie, Poland",
  "Itasca, Texas, United States",
  "Olten, Solothurn, Switzerland",
  "Saint Vincent and the Grenadines",
  "43210, Kutahya, Kütahya, Türkiye",
  "25400, Erzurum, Erzurum, Türkiye",
  "Daniels Landing, Winter Garden, Florida, United States",
  "71073, Sibley, Louisiana, United States",
  "3306, Gramsh, Elbasan, Albania",
  "Siauliai, Siauliu, Lithuania",
  "Granville, Tennessee, United States",
  "Krasnoarmeyskoye, Kabardino-Balkaria, Russia",
  "P0M 2A0, Killarney, Ontario, Canada",
  "Pottersville, New York, United States",
  "Luzerne, Auvergne-Rhône-Alpes, France",
  "Handa, Aichi, Japan",
  "Tipton, Missouri, United States",
  "223 63, Malmo, Skåne County, Sweden",
  "Woodstown, New Jersey, United States",
  "Waynesboro City County, Virginia, United States",
  "Devara Hipparagi, Karnataka, India",
  "656058, Barnaul, Altai Krai, Russia",
  "RG2 0AA, Reading, England, United Kingdom",
  "24013, Roanoke, Virginia, United States",
  "24129, Radford, Virginia, United States",
  "33010, Mersin, İçel, Türkiye",
  "Alekseyevka, Samara, Russia",
  "630005, Novosibirsk, Novosibirsk, Russia",
  "620100, Yekaterinburg, Sverdlovsk, Russia",
  "N0J 1Y0, Straffordville, Ontario, Canada",
  "23200, Elâzığ, Elazığ, Türkiye",
  "Port Salerno, Florida, United States",
  "95758, Elk Grove, California, United States",
  "17013, Carlisle, Pennsylvania, United States",
  "North Korea",
  "Norton, Massachusetts, United States",
  "682644, Amursk, Khabarovsk, Russia",
  "Spinkhill, England, United Kingdom",
  "Lawrence County, South Dakota, United States",
  "610041, Piatra Neamţ, Neamţ, Romania",
  "Winston, Florida, United States",
  "Canadian Woods, South Carolina, United States",
  "Fairfield Bay, Arkansas, United States",
  "Boyd, Antigonish, Subdivision A, Nova Scotia, Canada",
  "Bedford, England, United Kingdom",
  "Danehill, England, United Kingdom",
  "Columbia County, Georgia, United States",
  "452009, Belebey, Bashkortostan, Russia",
  "Mykolayiv, Ukraine",
  "L1C 4T2, Bowmanville, Ontario, Canada",
  "Sanborn, Minnesota, United States",
  "47146, Floyds Knobs, Indiana, United States",
  "617480, Kungur, Perm, Russia",
  "Caswell, Towcester, England, United Kingdom",
  "450006, Ufa, Bashkortostan, Russia",
  "Osage County, Oklahoma, United States",
  "11575, Roosevelt, New York, United States",
  "Woodstock, Vermont, United States",
  "24159, Kiel, Schleswig-Holstein, Germany",
  "06131, Perugia, Umbria, Italy",
  "Gilesgate Moor, Durham, England, United Kingdom",
  "Morganville, New Jersey, United States",
  "7730, Steinkjer, Trøndelag, Norway",
  "Simpsonville, South Carolina, United States",
  "Ashburn, Virginia, United States",
  "169314, Ukhta, Komi, Russia",
  "Zrub-Komarivskyi, Chernivtsi, Ukraine",
  "20-089, Lublin, Lubelskie, Poland",
  "Brownsburg, Indiana, United States",
  "85053, Ingolstadt, Bavaria, Germany",
  "14500, Hämeenlinna, Kanta-Häme, Finland",
  "Alta Floresta, Mato Grosso, Brazil",
  "Marathon County, Wisconsin, United States",
  "399050, Gryazi, Lipetsk, Russia",
  "Pittsburgh, Pennsylvania, United States",
  "A92 TFF1, Drogheda, Ireland",
  "Manipur, India",
  "15047, Greenock, Pennsylvania, United States",
  "75931, Brookeland, Texas, United States",
  "618503, Solikamsk, Perm, Russia",
  "Şemdinli, Hakkâri, Türkiye",
  "Kuznetsk, Penza, Russia",
  "Morrison, Missouri, United States",
  "5517, Haugesund, Rogaland, Norway",
  "Boyds, Maryland, United States",
  "Queens County, New York, United States",
  "Shenandoah, Miami, Florida, United States",
  "Koszalin, Zachodniopomorskie, Poland",
  "Bari, Odisha, India",
  "347381, Volgodonsk, Rostov, Russia",
  "Terebovlianska miska hromada, Ternopil, Ukraine",
  "07026, Olbia, Sardinia, Italy",
  "75015, Paris, Île-de-France, France",
  "Hoffman Estates, Illinois, United States",
  "397102, Rusanovo, Voronezh, Russia",
  "10111, Tallinn, Harjumaa, Estonia",
  "452775, Staryye Tuymazy, Bashkortostan, Russia",
  "Greater Ponta Delgada Area",
  "20750, Turku, Southwest Finland, Finland",
  "09470, Aydın, Aydın, Türkiye",
  "Turner, Maine, United States",
  "Blairs Mills, Pennsylvania, United States",
  "674370, Nerchinskiy Zavod, Zabaykalsky Krai, Russia",
  "69000, Genting Highlands, Pahang, Malaysia",
  "425100, Beclean, Bistriţa-Năsăud, Romania",
  "Neftekamsk, Bashkortostan, Russia",
  "Lyman, South Carolina, United States",
  "5024, Porirua, Wellington, New Zealand",
  "20270, Vela Luka, Dubrovnik-Neretva, Croatia",
  "José Alfredo San Román Duval, Hidalgo, Mexico",
  "Klingenbach, Burgenland, Austria",
  "301841, Yefremov, Tula, Russia",
  "Adair County, Iowa, United States",
  "74076, Stillwater, Oklahoma, United States",
  "Istanbul, Istanbul, Türkiye",
  "Bandera, Texas, United States",
  "142215, Serpukhov, Moscow, Russia",
  "Baarle-Nassau, North Brabant, Netherlands",
  "57701, Rapid City, South Dakota, United States",
  "Onslow County, North Carolina, United States",
  "Douglas County, Nevada, United States",
  "Pickens County, Georgia, United States",
  "New Cambria, Missouri, United States",
  "Hopkins Bayview, Baltimore, Maryland, United States",
  "Mundybash, Kemerovo, Russia",
  "Blair, Wisconsin, United States",
  "75018, Paris, Île-de-France, France",
  "Solikamsk, Perm, Russia",
  "Hughesville, Pennsylvania, United States",
  "426008, Izhevsk, Udmurtia, Russia",
  "Harrison County, Indiana, United States",
  "9200, Mosonmagyaróvár, Győr-Moson-Sopron, Hungary",
  "Kamenka, Ivanovo, Russia",
  "Guyana",
  "Clark, New Jersey, United States",
  "Telciu, Bistriţa-Năsăud, Romania",
  "Radford, Darwen, England, United Kingdom",
  "Landers, California, United States",
  "646250, Cherlak, Omsk, Russia",
  "53404, Racine, Wisconsin, United States",
  "41014, Castelvetro di Modena, Emilia-Romagna, Italy",
  "641882, Shadrinsk, Kurgan, Russia",
  "694011, Korsakov, Sakhalin, Russia",
  "L10F8F1, Chapaev, West Kazakhstan Region, Kazakhstan",
  "6791, Szeged, Csongrád, Hungary",
  "Gibson, Louisiana, United States",
  "Yellow Creek, Ohio, United States",
  "HU3 1AA, Kingston Upon Hull, England, United Kingdom",
  "96280, Mostardas, Rio Grande do Sul, Brazil",
  "Sveta Nedelja, Zagreb, Croatia",
  "Gatesville, Texas, United States",
  "Hutchinson, Cowichan Valley C, British Columbia, Canada",
  "425, Nancy, Kentucky, United States",
  "Yegor'yevskiy, Novosibirsk, Russia",
  "658223, Rubtsovsk, Altai Krai, Russia",
  "NE8 1AD, Gateshead, England, United Kingdom",
  "142411, Noginsk, Moscow, Russia",
  "Philousa Khrysokhous, Paphos, Cyprus",
  "06650, Porvoo, Uusimaa, Finland",
  "Germany",
  "Sofia Metropolitan Area",
  "Clinton County, Indiana, United States",
  "614068, Perm, Perm, Russia",
  "Emiliano Zapata, Hidalgo, Mexico",
  "Greater Malmö Metropolitan Area",
  "7633, Pécs, Baranya, Hungary",
  "Daniels-Welchester, Lakewood, Colorado, United States",
  "São Domingos, Cacheu Region, Guinea-Bissau",
  "Dixon, Nebraska, United States",
  "Perry County, Indiana, United States",
  "Wallaceburg, Ontario, Canada",
  "L0S 1J0, Niagara-on-the-Lake, Ontario, Canada",
  "Columbiana County, Ohio, United States",
  "C19C4Y9, Zhaltyr, Akmola Region, Kazakhstan",
  "5627 CW, Eindhoven, North Brabant, Netherlands",
  "San Sebastian, Puerto Rico",
  "Valladolid, Yucatán, Mexico",
  "Vitimkanskoye, Buryatia, Russia",
  "Kurtamyshsky District, Kurgan, Russia",
  "53595, Dodgeville, Wisconsin, United States",
  "Midland, Ontario, Canada",
  "San Donato Milanese, Lombardy, Italy",
  "6771, Szeged, Csongrád, Hungary",
  "Wilkinson County, Georgia, United States",
  "60327, Frankfurt, Hesse, Germany",
  "Lebanon, Tennessee, United States",
  "Chippewa, Pennsylvania, United States",
  "Lehighton, Pennsylvania, United States",
  "BT26 6AA, Hillsborough, Northern Ireland, United Kingdom",
  "43961, Emiliano Zapata, Hidalgo, Mexico",
  "802 82, Gävle, Gävleborg County, Sweden",
  "Kupyansk, Kharkiv, Ukraine",
  "L7 0AB, Liverpool, England, United Kingdom",
  "68309, Mannheim, Baden-Württemberg, Germany",
  "Sabine Pass, Port Arthur, Texas, United States",
  "Mingāora, Khyber Pakhtunkhwa, Pakistan",
  "Kirstinebjerg Huse, Region of Southern Denmark, Denmark",
  "77160, Siauliai, Siauliu, Lithuania",
  "Foster, Rhode Island, United States",
  "Herkimer County, New York, United States",
  "50036, Boone, Iowa, United States",
  "Johnson County, Iowa, United States",
  "30027, San Donà di Piave, Veneto, Italy",
  "63439, Emden, Missouri, United States",
  "Sharon, Pennsylvania, United States",
  "V93 VX90, Killarney, County Kerry, Ireland",
  "Webb, Texas, United States",
  "Essex, Iowa, United States",
  "59808, Missoula, Montana, United States",
  "Salto, São Paulo, Brazil",
  "Tipton St John, England, United Kingdom",
  "307178, Zheleznogorsk, Kursk, Russia",
  "Caddo, Oklahoma, United States",
  "76017, Arlington, Texas, United States",
  "Roanne, Auvergne-Rhône-Alpes, France",
  "46050, Kahramanmaraş, Kahraman Maras, Türkiye",
  "Morrison County, Minnesota, United States",
  "2677, Pukekohe East, New Zealand",
  "Sargentes de la Lora, Castilla and Leon, Spain",
  "Russellville, Alabama, United States",
  "Hanover, Massachusetts, United States",
  "BT82 9TL, Sion Mills, Northern Ireland, United Kingdom",
  "309504, Stary Oskol, Belgorod, Russia",
  "Ouagadougou, Centre, Burkina Faso",
  "59936, West Glacier, Montana, United States",
  "Freeborn, Minnesota, United States",
  "Novotroitsk, Novosibirsk, Russia",
  "Burke, Wisconsin, United States",
  "54555, Phillips, Wisconsin, United States",
  "Puketotara, Waikato, New Zealand",
  "20165, Sterling, Virginia, United States",
  "350000, Krasnodar, Krasnodar, Russia",
  "Craigieburn, Victoria, Australia",
  "Union County, Oregon, United States",
  "230020, Slatina, Olt, Romania",
  "Codington County, South Dakota, United States",
  "Arkansas, United States",
  "Engomi, Nicosia, Cyprus",
  "02140, Albacete, Castile-La Mancha, Spain",
  "Nizhnekamsky District, Tatarstan, Russia",
  "Murray River, Prince Edward Island, Canada",
  "Sainte-Anne-de-la-Rochelle, Quebec, Canada",
  "IP4 1DS, Ipswich, England, United Kingdom",
  "La Traille, Corneilla-de-Conflent, Occitanie, France",
  "Rusk County, Wisconsin, United States",
  "Sherman County, Nebraska, United States",
  "Sandoval, Roxas, Mimaropa, Philippines",
  "33250, Mersin, İçel, Türkiye",
  "Carlisle, England, United Kingdom",
  "692421, Kavalerovo, Primorye, Russia",
  "664074, Irkutsk, Irkutsk, Russia",
  "80210, Joensuu, North Karelia, Finland",
  "Bates Cottages, Whitley Bay, England, United Kingdom",
  "Tate, Ica, Peru",
  "Niagara, North Dakota, United States",
  "58271, Pembina, North Dakota, United States",
  "AB55 5JW, Keith, Scotland, United Kingdom",
  "Sivaslı, Uşak, Türkiye",
  "Washington DC-Baltimore Area",
  "Sokołów Podlaski, Mazowieckie, Poland",
  "Decatur, Illinois, United States",
  "1011 AP, Amsterdam, North Holland, Netherlands",
  "665734, Bratsk, Irkutsk, Russia",
  "641441, Kurtamysh, Kurgan, Russia",
  "Seminole, Florida, United States",
  "48816, Howell, Michigan, United States",
  "El Bosque, Santiago Metropolitan Region, Chile",
  "Durham, Connecticut, United States",
  "Crook, Colorado, United States",
  "Utrecht Area",
  "Samarate, Lombardy, Italy",
  "Southampton, Pennsylvania, United States",
  "Tatarsk, Novosibirsk, Russia",
  "LV-3004, Jelgava, Jelgava Municipality, Latvia",
  "450112, Ufa, Bashkortostan, Russia",
  "Washington County, Utah, United States",
  "Mons-Boubert, Hauts-de-France, France",
  "673492, Bukachacha, Zabaykalsky Krai, Russia",
  "Orebic, Dubrovnik-Neretva, Croatia",
  "Plaza Pedro Lucas Urribarri, Santa Rita, Zulia State, Venezuela",
  "20400, Denizli, Denizli, Türkiye",
  "Genesee-Moselle, Buffalo, New York, United States",
  "601906, Kovrov, Vladimir, Russia",
  "Southampton County, Virginia, United States",
  "76228, Karlsruhe, Baden-Württemberg, Germany",
  "Coles, Virginia, United States",
  "24937, Flensburg, Schleswig-Holstein, Germany",
  "Denton, Nebraska, United States",
  "6402, Këlcyrë, Gjirokastër, Albania",
  "Achinsk, Krasnoyarsk Krai, Russia",
  "Sioux City Region",
  "Setúbal, Portugal",
  "Oradea, Bihor, Romania",
  "Gryazi, Lipetsk, Russia",
  "Ambarchik, Sakha, Russia",
  "430071, Baia Mare, Maramureş, Romania",
  "52550, Slavgorod, Dnipropetrovsk, Ukraine",
  "Wilsonville, Alabama, United States",
  "Balashovsky District, Saratov, Russia",
  "Cherepanovo, Tyumen’, Russia",
  "Manassas Park, Virginia, United States",
  "Salem, Central Java, Indonesia",
  "Bergen, Mecklenburg-West Pomerania, Germany",
  "19500, Çorum, Çorum, Türkiye",
  "169720, Usinsk, Komi, Russia",
  "29305, Spartanburg, South Carolina, United States",
  "Jena, Louisiana, United States",
  "13700, Bitlis, Bitlis, Türkiye",
  "Grundy County, Tennessee, United States",
  "Spring, Texas, United States",
  "452008, Belebey, Bashkortostan, Russia",
  "9010, Klagenfurt, Carinthia, Austria",
  "81-591, Gdynia, Pomorskie, Poland",
  "364028, Groznyy, Chechnya, Russia",
  "Redwood, Mississippi, United States",
  "Fort Steele, British Columbia, Canada",
  "186423, Segezha, Karelia, Russia",
  "636841, Asino, Tomsk, Russia",
  "140405, Kolomna, Moscow, Russia",
  "Sokyriany, Chernivtsi, Ukraine",
  "99217, Spokane, Washington, United States",
  "McHenry County, North Dakota, United States",
  "Greggs Einöde, Bad Wurzach, Baden-Württemberg, Germany",
  "Greater Nashik Area",
  "St. Louis County, Minnesota, United States",
  "Brooks County, Georgia, United States",
  "54-402, Wrocław, Dolnośląskie, Poland",
  "140091, Dzerzhinskiy, Moscow, Russia",
  "Schuylkill, Pennsylvania, United States",
  "78658, Gonzales, Texas, United States",
  "Ruscova, Maramureş, Romania",
  "54661, Trempealeau, Wisconsin, United States",
  "38008, Santa Cruz de Tenerife, Canary Islands, Spain",
  "Kay-Anlog, Calamba, Calabarzon, Philippines",
  "Le Ravallier, La Perrière, Normandy, France",
  "23832, Chesterfield, Virginia, United States",
  "LV-5404, Daugavpils, Daugavpils, Latvia",
  "Moscow, Tennessee, United States",
  "Highland, St Paul, Minnesota, United States",
  "367007, Makhachkala, Dagestan, Russia",
  "95128, Catania, Sicily, Italy",
  "50937, Cologne, North Rhine-Westphalia, Germany",
  "Maury, Miremont, Occitanie, France",
  "1033, Cheseaux-sur-Lausanne, Vaud, Switzerland",
  "Nyíregyháza District, Szabolcs-Szatmár-Bereg, Hungary",
  "Faroe Islands",
  "Clinton, New York, United States",
  "Lisboa, Lisbon, Portugal",
  "HG3 3EP, Burnt Yates, England, United Kingdom",
  "Cena, Jelgava Municipality, Latvia",
  "352 55, Växjö, Kronoberg County, Sweden",
  "14770, Hämeenlinna, Kanta-Häme, Finland",
  "220226, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "Drobeta-Turnu Severin, Mehedinţi, Romania",
  "Bertieu, Castres, Occitanie, France",
  "30189, Woodstock, Georgia, United States",
  "40420, Jyväskylä, Central Finland, Finland",
  "Göynücek, Amasya, Türkiye",
  "Weber City, Virginia, United States",
  "625013, Tyumen, Tyumen’, Russia",
  "Montague County, Texas, United States",
  "98126, Messina, Sicily, Italy",
  "Orange County, California, United States",
  "Emmons, North Carolina, United States",
  "Fort Mitchell, Kentucky, United States",
  "450035, Zalău, Sălaj, Romania",
  "E28D9F6, Makhambet, Atyrau Region, Kazakhstan",
  "211401, Polatsk, Vitsyebsk, Belarus",
  "St-Étienne, Auvergne-Rhône-Alpes, France",
  "17800, Çanakkale, Çanakkale, Türkiye",
  "Lillers, Hauts-de-France, France",
  "Kholmskiy, Krasnodar, Russia",
  "55343, Hopkins, Minnesota, United States",
  "362035, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "Prokop'yevskiy, Kemerovo, Russia",
  "Dzerzhinsk gorodskoy sovet, Minsk Province, Belarus",
  "47300, Ogulin, Karlovac, Croatia",
  "Timiryazev, Lipetsk, Russia",
  "99710, Sodankylä, Lapland, Finland",
  "Zavala, Sinaloa, Mexico",
  "72207, Zenica, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Northampton County, Pennsylvania, United States",
  "KW15 1RF, Kirkwall, Scotland, United Kingdom",
  "Billings, Oklahoma, United States",
  "Sawyerville, Alabama, United States",
  "143401, Krasnogorsk, Moscow, Russia",
  "67203, Wichita, Kansas, United States",
  "Karnes - Finnavuolli, Lyngseidet, Troms og Finnmark, Norway",
  "66131, Saarbrücken, Saarland, Germany",
  "Isle Of Islay, Scotland, United Kingdom",
  "36524, Coffeeville, Alabama, United States",
  "Dalgopol, Varna, Bulgaria",
  "63730, Valle de Banderas, Nayarit, Mexico",
  "620075, Yekaterinburg, Sverdlovsk, Russia",
  "Comuna 12 - Cabecera del Llano, Santander, Colombia",
  "Hotchkiss, Colorado, United States",
  "6728, Szeged, Csongrád, Hungary",
  "21218, Baltimore, Maryland, United States",
  "Doddridge County, West Virginia, United States",
  "Union, Kentucky, United States",
  "8010, Graz, Styria, Austria",
  "Hemlock Corners, Merrickville-Wolford, Ontario, Canada",
  "Moore County, Tennessee, United States",
  "Mayes County, Oklahoma, United States",
  "Bonaire, Georgia, United States",
  "Blaine Lake, Saskatchewan, Canada",
  "Le Petit Malheur, Thiberville, Normandy, France",
  "Marshall County, Kentucky, United States",
  "Blue Island, Illinois, United States",
  "Fergus County, Montana, United States",
  "02874, Saunderstown, Rhode Island, United States",
  "7330, Zavet, Razgrad, Bulgaria",
  "Norton, Stockton-On-Tees, England, United Kingdom",
  "DG1 1RP, Dumfries, Scotland, United Kingdom",
  "Ussuriysk, Primorye, Russia",
  "26230, Pickens, West Virginia, United States",
  "Thomas, Oklahoma, United States",
  "871 51, Härnösand, Västernorrland County, Sweden",
  "Sauk Creek, Madison, Wisconsin, United States",
  "Johnson County, Illinois, United States",
  "Terrell, North Carolina, United States",
  "Volkhov, Leningrad, Russia",
  "Okhotsky District, Khabarovsk, Russia",
  "Chișinău, Moldova",
  "East Waterford, Pennsylvania, United States",
  "452686, Neftekamsk, Bashkortostan, Russia",
  "Sibley's and James Store Historic District, Virginia, United States",
  "36581, Sunflower, Alabama, United States",
  "462275, Mednogorsk, Orenburg, Russia",
  "Independence, Missouri, United States",
  "LV-3600, Ventspils, Ventspils, Latvia",
  "610 25, Norrköping, Östergötland County, Sweden",
  "M28E2E7, Temirtau, Karaganda Region, Kazakhstan",
  "2802, Gjøvik, Innlandet, Norway",
  "3534, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Klyuchi, Amur, Russia",
  "Fiorentino, San Marino",
  "Bucksport, Maine, United States",
  "346503, Shakhty, Rostov, Russia",
  "62500, Tunceli, Tunceli, Türkiye",
  "Pittsburg, California, United States",
  "Progresskoye, Novgorod, Russia",
  "167014, Syktyvkar, Komi, Russia",
  "Morrison, Colorado, United States",
  "Lisbon, Lisbon, Portugal",
  "50676, Cologne, North Rhine-Westphalia, Germany",
  "33322, Fort Lauderdale, Florida, United States",
  "E1A 1M6, Dieppe, New Brunswick, Canada",
  "220207, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "Guadalupe, Puebla, Mexico",
  "540002, Cúcuta, Norte de Santander, Colombia",
  "Saint-Georges-sur-Erve, Pays de la Loire, France",
  "Carroll County, Virginia, United States",
  "Ísafjörður, Westfjords, Iceland",
  "99402, Asotin, Washington, United States",
  "Danielsville, Pennsylvania, United States",
  "433507, Dimitrovgrad, Ul’yanovsk, Russia",
  "Khabarovsk, Khabarovsk, Russia",
  "23000, Zrenjanin, Vojvodina, Serbia",
  "425, Ísafjörður, Westfjords, Iceland",
  "Oklahoma County, Oklahoma, United States",
  "Laurens, New York, United States",
  "500034, Hyderabad, Telangana, India",
  "Lebern, Solothurn, Switzerland",
  "Tateyama, Toyama, Japan",
  "Umatilla County, Oregon, United States",
  "621 46, Visby, Gotland County, Sweden",
  "Antalya, Antalya, Türkiye",
  "1504, Krujë, Durres, Albania",
  "625051, Tyumen, Tyumen’, Russia",
  "Castro Verde, Beja, Portugal",
  "Walworth, England, United Kingdom",
  "99097, Erfurt, Thuringia, Germany",
  "Liberty, Ohio, United States",
  "Huron, Ohio, United States",
  "Terry, Pennsylvania, United States",
  "New Castle County, Delaware, United States",
  "5652 EC, Eindhoven, North Brabant, Netherlands",
  "Kaltan, Kemerovo, Russia",
  "25025, Blount, West Virginia, United States",
  "Al Ruseifa, Zarqa, Jordan",
  "Sweetwater County, Wyoming, United States",
  "Mariupol, Donetsk, Ukraine",
  "32500, Isparta, Isparta, Türkiye",
  "PA15 4NF, Greenock, Scotland, United Kingdom",
  "29602, Marbella, Andalusia, Spain",
  "Woodnorth, Pipestone, Manitoba, Canada",
  "367013, Makhachkala, Dagestan, Russia",
  "656057, Barnaul, Altai Krai, Russia",
  "Presidente Castelo Branco, Paraná, Brazil",
  "Jefferson County, Texas, United States",
  "143203, Baba Bakala, Punjab, India",
  "Colleton County, South Carolina, United States",
  "VLT 1103, Valletta, Valletta, Malta",
  "MD-3300, Tiraspol, Transnistria autonomous territorial unit, Moldova",
  "Teteven, Lovech, Bulgaria",
  "59105, Billings, Montana, United States",
  "Kirovo-Chepetsky District, Kirov, Russia",
  "Adams, Wisconsin, United States",
  "358015, Elista, Kalmykia, Russia",
  "Pecos Ranch Estates, Chandler, Arizona, United States",
  "Nicholas County, West Virginia, United States",
  "West Choctaw, Oklahoma, United States",
  "Hancock County, Illinois, United States",
  "Georgetown, Tennessee, United States",
  "La Loma Plaza Historic District, Taos, New Mexico, United States",
  "Winslow, England, United Kingdom",
  "HP8 4SP, Chalfont St Giles, England, United Kingdom",
  "Nišava District, Centralna Srbija, Serbia",
  "Elâzığ, Elazığ, Türkiye",
  "164173, Mirny, Arkhangel’sk, Russia",
  "354000, Sochi, Krasnodar, Russia",
  "Lamar, Arkansas, United States",
  "Summit Argo, Illinois, United States",
  "143406, Krasnogorsk, Moscow, Russia",
  "Burlăceni, Cahul District, Moldova",
  "Hampton County, South Carolina, United States",
  "Hofheim am Taunus, Hesse, Germany",
  "6227 AA, Maastricht, Limburg, Netherlands",
  "Strovolos, Nicosia, Cyprus",
  "361347, Okhamandal, Gujarat, India",
  "Doornkop, Johannesburg, Gauteng, South Africa",
  "Kalush, Ivano-Frankivsk, Ukraine",
  "Freeborn, Missouri, United States",
  "24133, Patrick Springs, Virginia, United States",
  "Barguna District, Barisāl, Bangladesh",
  "3528, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "71221, Bastrop, Louisiana, United States",
  "80631, Greeley, Colorado, United States",
  "Oktyabr'skiy, Mahilyow, Belarus",
  "Eye, England, United Kingdom",
  "211657, Homyel’, Vitsyebsk, Belarus",
  "St James, Western Australia, Australia",
  "346421, Novocherkassk, Rostov, Russia",
  "461045, Buzuluk, Orenburg, Russia",
  "Gates, New York, United States",
  "Chase County, Kansas, United States",
  "791 73, Falun, Dalarna County, Sweden",
  "Wyoming, Pennsylvania, United States",
  "Miami County, Kansas, United States",
  "56377, Nassau, Rhineland-Palatinate, Germany",
  "575, East Haakon, South Dakota, United States",
  "27612, Raleigh, North Carolina, United States",
  "220061, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "66800, Yozgat, Yozgat, Türkiye",
  "Palopo, South Sulawesi, Indonesia",
  "84131, Salerno, Campania, Italy",
  "Adair County, Missouri, United States",
  "Attala, Tolna, Hungary",
  "426069, Izhevsk, Udmurtia, Russia",
  "Verona, Mississippi, United States",
  "Dayton, Nevada, United States",
  "35016, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Summerston, Glasgow, Scotland, United Kingdom",
  "666786, Ust-Kut, Irkutsk, Russia",
  "McHenry, Illinois, United States",
  "Santa Fe, Santa Fe, Argentina",
  "Mariinskiy Posad, Chuvashia, Russia",
  "Wrightwood, California, United States",
  "Salzburg-Umgebung, Salzburg, Austria",
  "Nash County, North Carolina, United States",
  "Iron County, Michigan, United States",
  "Stillwater, New York, United States",
  "Merrickville, Ontario, Canada",
  "Slavonski Brod, Brod-Posavina, Croatia",
  "El Eulma, Setif, Algeria",
  "8516, Narvik, Nordland, Norway",
  "90130, Oulu, North Ostrobothnia, Finland",
  "4063, Debrecen, Hajdú-Bihar, Hungary",
  "Rock, Wales, United Kingdom",
  "54155, Oneida, Wisconsin, United States",
  "Colorado, Paraná, Brazil",
  "07745, Jena, Thuringia, Germany",
  "Oak Lake 59 A, Pipestone, Manitoba, Canada",
  "Iron River, Wisconsin, United States",
  "5400, Hallein, Salzburg, Austria",
  "6221 ER, Maastricht, Limburg, Netherlands",
  "Amira Arrès, Mila, Algeria",
  "Cherry Valley, Arkansas, United States",
  "80333, Munich, Bavaria, Germany",
  "F56 AW60, Ballymote, County Sligo, Ireland",
  "Voznesenskiy, Krasnoyarsk Krai, Russia",
  "618425, Berezniki, Perm, Russia",
  "Innsbruck-Land, Tyrol, Austria",
  "Ansonville, North Carolina, United States",
  "Deweyville, Utah, United States",
  "St. Charles County, Missouri, United States",
  "5616 AA, Eindhoven, North Brabant, Netherlands",
  "367910, Makhachkala, Dagestan, Russia",
  "Green Bay, Wisconsin Metropolitan Area",
  "373 00, Vólos, Thessalia, Greece",
  "6200-014, Covilhã, Castelo Branco, Portugal",
  "39348, Paulding, Mississippi, United States",
  "2607, Lillehammer, Innlandet, Norway",
  "02360, Plymouth, Massachusetts, United States",
  "30906, Augusta, Georgia, United States",
  "352396, Kropotkin, Krasnodar, Russia",
  "Traverse Bay, Manitoba, Canada",
  "90144, Palermo, Sicily, Italy",
  "Butler County, Nebraska, United States",
  "Howard, Kansas, United States",
  "Goshen, Kentucky, United States",
  "67502, Hutchinson, Kansas, United States",
  "Wells, Vermont, United States",
  "412610, Svobodnyy, Saratov, Russia",
  "Refugio de Ríos, Guanajuato, Mexico",
  "11080, Belgrade, Serbia",
  "Sorong Regency, West Papua, Indonesia",
  "Yanceyville, North Carolina, United States",
  "Stokenchurch, England, United Kingdom",
  "184621, Severomorsk, Murmansk, Russia",
  "Hitrino Municipality, Shumen, Bulgaria",
  "Klinteby, Region Zealand, Denmark",
  "TR18 3QH, Penzance, England, United Kingdom",
  "423800, Naberezhnyye Chelny, Tatarstan, Russia",
  "Poltava, Ukraine",
  "08846, Middlesex, New Jersey, United States",
  "Gjøvik, Innlandet, Norway",
  "63833, Catron, Missouri, United States",
  "Morava, Centralna Srbija, Serbia",
  "190000, St Petersburg, St Petersburg City, Russia",
  "167018, Syktyvkar, Komi, Russia",
  "Coleman, Texas, United States",
  "641219, Yurgamysh, Kurgan, Russia",
  "Throckmorton County, Texas, United States",
  "843301, Aral, Xinjiang Uygur, China",
  "7735, Steinkjer, Trøndelag, Norway",
  "423816, Naberezhnyye Chelny, Tatarstan, Russia",
  "Bakaldinskiy, Bashkortostan, Russia",
  "Kirensk, Irkutsk, Russia",
  "M28E7T9, Temirtau, Karaganda Region, Kazakhstan",
  "Mineral Point, Wisconsin, United States",
  "Uppsala County, Sweden",
  "36766, Salamanca, Guanajuato, Mexico",
  "50-075, Wrocław, Dolnośląskie, Poland",
  "Springhead, Oldham, England, United Kingdom",
  "C22D5G2, Atbasar, Akmola Region, Kazakhstan",
  "Armstrong Creek, Wisconsin, United States",
  "30204, Cartagena, Región de Murcia, Spain",
  "Vilas Boas, Guanambi, Bahia, Brazil",
  "J0E 0C2, Sainte-Anne-de-la-Rochelle, Quebec, Canada",
  "Ulmi, Giurgiu, Romania",
  "Nyer, Occitanie, France",
  "48600, Muğla, Muğla, Türkiye",
  "7971, Rørvik, Trøndelag, Norway",
  "Kremenchuk, Poltava, Ukraine",
  "44215, Chippewa-on-the-Lake, Ohio, United States",
  "35394, Giessen, Hesse, Germany",
  "Webster City, Iowa, United States",
  "Karasuksky District, Novosibirsk, Russia",
  "Linn County, Iowa, United States",
  "Zhytomyr Raion, Zhytomyr, Ukraine",
  "30110-001, Belo Horizonte, Minas Gerais, Brazil",
  "Mecklenburg-West Pomerania, Germany",
  "36036, Grady, Alabama, United States",
  "B6720BAC, San Andrés de Giles, Buenos Aires Province, Argentina",
  "Kezmarok, Presov, Slovakia",
  "Verkhniy Ufaley, Chelyabinsk, Russia",
  "ST16 3QS, Stafford, England, United Kingdom",
  "67600, Zonguldak, Zonguldak, Türkiye",
  "Trempealeau, Wisconsin, United States",
  "N0K 0A1, Rostock, Ontario, Canada",
  "Steubenville Township, Ohio, United States",
  "Aglangia, Nicosia, Cyprus",
  "20774, Upper Marlboro, Maryland, United States",
  "Barron Park, York, South Carolina, United States",
  "Claymont, Delaware, United States",
  "Paulding County, Ohio, United States",
  "Bergen, Vestland, Norway",
  "2491 AP, The Hague, South Holland, Netherlands",
  "Outagamie County, Wisconsin, United States",
  "Eau Claire, Wisconsin, United States",
  "Caswell County, North Carolina, United States",
  "Cape Coral Metropolitan Area",
  "Saint Michael, Barbados",
  "Tipton, California, United States",
  "45127, Essen, North Rhine-Westphalia, Germany",
  "Kragujevac, Centralna Srbija, Serbia",
  "34323, Kragujevac, Centralna Srbija, Serbia",
  "21244, Windsor Mill, Maryland, United States",
  "Ayre's Quay, Sunderland, England, United Kingdom",
  "Hickory, North Carolina, United States",
  "39124, Magdeburg, Saxony-Anhalt, Germany",
  "693014, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Windsor Locks, Connecticut, United States",
  "Parkers Lake, Kentucky, United States",
  "Devadurga, Karnataka, India",
  "3371 EP, Hardinxveld-Giessendam, South Holland, Netherlands",
  "Moroni, Grande Comore, Comoros",
  "2034, Neuchâtel, Neuchâtel, Switzerland",
  "663285, Severo-Yeniseyskiy, Krasnoyarsk Krai, Russia",
  "Münster, Tyrol, Austria",
  "320070, Reşiţa, Caraş-Severin, Romania",
  "Kavakbaşı, Bitlis, Türkiye",
  "Çan, Çanakkale, Türkiye",
  "Anderson County, South Carolina, United States",
  "03833, Exeter, New Hampshire, United States",
  "Continental Divide, New Mexico, United States",
  "9404 KV, Assen, Drenthe, Netherlands",
  "Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "23436, Suffolk, Virginia, United States",
  "Traverse, Perrero, Piedmont, Italy",
  "44070, Malatya, Malatya, Türkiye",
  "147105, Patran, Punjab, India",
  "Richland, Missouri, United States",
  "35707, Zdolbuniv, Rivne, Ukraine",
  "Makarovo, Kaluga, Russia",
  "Clervaux, Diekirch, Luxembourg",
  "Lawrence County, Tennessee, United States",
  "393191, Kotovsk, Tambov, Russia",
  "Burleigh, Blenheim, Marlborough, New Zealand",
  "341 00, Chalkida, Central Greece, Greece",
  "Oktibbeha County, Mississippi, United States",
  "Stanley, New Mexico, United States",
  "LE3 2UB, Leicester, England, United Kingdom",
  "666513, Ust-Kut, Irkutsk, Russia",
  "Novo Sarajevo, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Burlington, Connecticut, United States",
  "Michurinsky, Bryansk, Russia",
  "57800, Sinop, Sinop, Türkiye",
  "79027, Dimmitt, Texas, United States",
  "Louisayne, Avignonet-Lauragais, Occitanie, France",
  "Rutland, Ohio, United States",
  "443110, Samara, Samara, Russia",
  "Krasnoyarskoye, Saratov, Russia",
  "446306, Otradnyy, Samara, Russia",
  "Perth And Kinross, Scotland, United Kingdom",
  "10952, Monsey, New York, United States",
  "Krasnokholmsky District, Tver’, Russia",
  "Ferguson, Kentucky, United States",
  "628006, Khanty-Mansiysk, Khanty-Mansi, Russia",
  "9042, Speicher, Appenzell Outer-Rhoden, Switzerland",
  "628306, Nefteyugansk, Khanty-Mansi, Russia",
  "Wisemans Ferry, New South Wales, Australia",
  "Saint-Brieuc, Brittany, France",
  "77563, Hitchcock, Texas, United States",
  "Broome County, New York, United States",
  "6215-131, Covilhã, Castelo Branco, Portugal",
  "500072, Hyderabad, Telangana, India",
  "629300, Novy Urengoy, Yamalo-Nenets, Russia",
  "General Electric Realty Plot, Schenectady, New York, United States",
  "Coventry, Rhode Island, United States",
  "07927, Cedar Knolls, New Jersey, United States",
  "4109, Robertson, Queensland, Australia",
  "24314, Bastian, Virginia, United States",
  "Hettinger County, North Dakota, United States",
  "Hardington Moor, England, United Kingdom",
  "Davison County, South Dakota, United States",
  "Morrison, Córdoba, Argentina",
  "Lexington, North Carolina, United States",
  "Johnson City-Kingsport-Bristol Area",
  "Auglaize, Missouri, United States",
  "6041, Charleroi, Walloon Region, Belgium",
  "655602, Sayanogorsk, Khakassia, Russia",
  "678403, Tiksi, Sakha, Russia",
  "Seneca Rocks, West Virginia, United States",
  "OX10 6RP, Benson, England, United Kingdom",
  "KA8 8HU, Ayr, Scotland, United Kingdom",
  "Wheatland, Missouri, United States",
  "01206, Vilnius, Vilniaus, Lithuania",
  "Inverness, Nova Scotia, Canada",
  "George Town, Cayman Islands",
  "76102, Fort Worth, Texas, United States",
  "6503, Bellinzona, Ticino, Switzerland",
  "Kazakhstan",
  "420024, Bistriţa, Bistriţa-Năsăud, Romania",
  "54270, Essey-lès-Nancy, Grand Est, France",
  "31007, Pamplona, Chartered Community of Navarre, Spain",
  "Miamisburg, Ohio, United States",
  "PE11 3TQ, Deeping St Nicholas, England, United Kingdom",
  "F39A3T1, Zaysan, East Kazakhstan Region, Kazakhstan",
  "06010, Bristol, Connecticut, United States",
  "9404, Assen, Drenthe, Netherlands",
  "B2740, Arrecifes, Buenos Aires Province, Argentina",
  "Galaxia, Santo Domingo, Dominican Republic",
  "Qiannan Buyei and Miao Autonomous Prefecture, Guizhou, China",
  "1055, Middelburg, Mpumalanga, South Africa",
  "Helsinki Metropolitan Area",
  "F63A8F1, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "Kingfisher County, Oklahoma, United States",
  "32040, Isparta, Isparta, Türkiye",
  "Preston Hollow, New York, United States",
  "Ocean County, New Jersey, United States",
  "Russell, Manitoba, Canada",
  "Bayfield Town, Wisconsin, United States",
  "LV-3007, Jelgava, Jelgava Municipality, Latvia",
  "24123, Bergamo, Lombardy, Italy",
  "Abbeville-Saint-Lucien, Hauts-de-France, France",
  "636844, Asino, Tomsk, Russia",
  "Geneva County, Alabama, United States",
  "Kempten, Bavaria, Germany",
  "Todd, North Carolina, United States",
  "North Dumfries, Ontario, Canada",
  "Lowndes, Missouri, United States",
  "T0H 2L0, McLennan, Alberta, Canada",
  "2703, Narvacan, Ilocos Region, Philippines",
  "2496 HT, The Hague, South Holland, Netherlands",
  "656056, Barnaul, Altai Krai, Russia",
  "R6W 1H2, Winkler, Manitoba, Canada",
  "McHenry Vista, Modesto, California, United States",
  "Sussex, New Brunswick, Canada",
  "456818, Verkhniy Ufaley, Chelyabinsk, Russia",
  "70200, Karaman, Karaman, Türkiye",
  "633191, Boyarka, Novosibirsk, Russia",
  "621 43, Visby, Gotland County, Sweden",
  "Amphoe Wiset Chai Chan, Ang Thong, Thailand",
  "Butte, Nebraska, United States",
  "Lehighs Corners, Elizabethtown-Kitley, Ontario, Canada",
  "356423, Blagodarnyy, Stavropol, Russia",
  "249031, Obninsk, Kaluga, Russia",
  "Sully, Vaud, Switzerland",
  "Burkina Faso",
  "Krasnotur’insk, Sverdlovsk, Russia",
  "Fundão, Castelo Branco, Portugal",
  "Wheatland, Wisconsin, United States",
  "Chapayevskoye, Rostov, Russia",
  "Ransomville, New York, United States",
  "Carndonagh, County Donegal, Ireland",
  "53083, Sheboygan, Wisconsin, United States",
  "MD-4575, Marcăuți, Dubăsari District, Moldova",
  "Beratzhausen, Bavaria, Germany",
  "Skadovsk Raion, Kherson, Ukraine",
  "1501, Krujë, Durres, Albania",
  "Mucur, Kırşehir, Türkiye",
  "08844, Hillsborough, New Jersey, United States",
  "Highlands, New Jersey, United States",
  "Blandon, Pennsylvania, United States",
  "77836, Caldwell, Texas, United States",
  "20155, Milan, Lombardy, Italy",
  "8200, Pomorie, Burgas, Bulgaria",
  "Friuli-Venezia Giulia, Italy",
  "Alexander, Arkansas, United States",
  "347366, Volgodonsk, Rostov, Russia",
  "53011, Cascade, Wisconsin, United States",
  "Chippewa Falls, Wisconsin, United States",
  "627330, Golyshmanovo, Tyumen’, Russia",
  "Parker County, Texas, United States",
  "08003, Cherry Hill, New Jersey, United States",
  "Sevierville, Tennessee, United States",
  "Urbana-Champaign Area",
  "76067, Mineral Wells, Texas, United States",
  "49735, Gaylord, Michigan, United States",
  "Mexico City Metropolitan Area",
  "San Sebastián Coatán, Huehuetenango, Guatemala",
  "Vatutinska miska hromada, Cherkasy, Ukraine",
  "Rowan County, Kentucky, United States",
  "Norfolk, New York, United States",
  "Krasnoyarsk, Krasnoyarsk Krai, Russia",
  "Price, North Carolina, United States",
  "30188, Woodstock, Georgia, United States",
  "Cherokee, Kansas, United States",
  "Lawrence County, Kentucky, United States",
  "Dickson City, Pennsylvania, United States",
  "Clayville, Gauteng, South Africa",
  "Kherson Metropolitan Area",
  "77550, Galveston, Texas, United States",
  "Tirana, Tirana, Albania",
  "Batalha, Leiria, Portugal",
  "142201, Serpukhov, Moscow, Russia",
  "Loudoun Valley Estates, Virginia, United States",
  "Hamlin, Michigan, United States",
  "Joint Base Elmendorf Richardson, Alaska, United States",
  "BT28 2YJ, Lisburn, Northern Ireland, United Kingdom",
  "Brussels Metropolitan Area",
  "141552, Solnechnogorsk, Moscow, Russia",
  "IP31 3PY, Thurston, England, United Kingdom",
  "Aiken County, South Carolina, United States",
  "Fairfax, Vermont, United States",
  "Orellana la Vieja, Extremadura, Spain",
  "Elk Grove Village, Illinois, United States",
  "EX1 1AE, Exeter, England, United Kingdom",
  "618556, Solikamsk, Perm, Russia",
  "20097, San Donato Milanese, Lombardy, Italy",
  "13142, Pulaski, New York, United States",
  "Stafford, Kansas, United States",
  "Moody Air Force Base, Georgia, United States",
  "41880, Jyväskylä, Central Finland, Finland",
  "73529, Comanche, Oklahoma, United States",
  "Lymanka, Odessa, Ukraine",
  "Iskitimsky District, Novosibirsk, Russia",
  "920057, Slobozia, Ialomiţa, Romania",
  "Holmes, Rotherham, England, United Kingdom",
  "346882, Bataysk, Rostov, Russia",
  "Nikolayevskoye, Chuvashia, Russia",
  "Stein am Rhein, Schaffhausen, Switzerland",
  "Vila Real, Portugal",
  "540007, Cúcuta, Norte de Santander, Colombia",
  "Hamilton, Waikato, New Zealand",
  "403533, Frolovo, Volgograd, Russia",
  "Kherson, Kherson, Ukraine",
  "Morton, Minnesota, United States",
  "Sidi Erraies, Béja, Tunisia",
  "188992, Svetogorsk, Leningrad, Russia",
  "Christian County, Missouri, United States",
  "Severnyy, Chelyabinsk, Russia",
  "Chesapeake, Ohio, United States",
  "Genesee County, Michigan, United States",
  "Graiguenamanagh, County Kilkenny, Ireland",
  "72632, Eureka Springs, Arkansas, United States",
  "Labinsk, Krasnodar, Russia",
  "Page Hill, Buckingham, England, United Kingdom",
  "Grady, New Mexico, United States",
  "Кизилорда державна адміністрація, Kyzylorda Region, Kazakhstan",
  "48200, Muğla, Muğla, Türkiye",
  "V0E 1B4, Armstrong, British Columbia, Canada",
  "Central Jakarta, Jakarta, Indonesia",
  "393960, Morshansk, Tambov, Russia",
  "Ufa, South West Ethiopia Peoples, Ethiopia",
  "450131, Zalău, Sălaj, Romania",
  "90145, Palermo, Sicily, Italy",
  "Sherman, Michigan, United States",
  "Western Area, Sierra Leone",
  "Russell, Kansas, United States",
  "Pointe-à-Pitre, Pointe-à-Pitre, Guadeloupe",
  "Gedebey District, Qazakh-Tovuz, Azerbaijan",
  "413728, Pugachëv, Saratov, Russia",
  "Mirnyy, Ul’yanovsk, Russia",
  "410170, Oradea, Bihor, Romania",
  "Marshall, Wisconsin, United States",
  "624262, Asbest, Sverdlovsk, Russia",
  "Iowa City, Iowa, United States",
  "Sandusky, Lynchburg, Virginia, United States",
  "Pontotoc, Mississippi, United States",
  "28199, Bremen, Bremen, Germany",
  "Santa Angelina, Pouso Alegre, Minas Gerais, Brazil",
  "9300, Finnsnes, Troms og Finnmark, Norway",
  "50300, Nevsehir, Nevşehir, Türkiye",
  "McIntosh Hill, New Brunswick, Canada",
  "Leamington, Ontario, Canada",
  "8053, Graz, Styria, Austria",
  "Ternivska silska rada, Sevastopol, Ukraine",
  "Eureka Springs, Arkansas, United States",
  "TS1 1AA, Middlesbrough, England, United Kingdom",
  "44123, Ferrara, Emilia-Romagna, Italy",
  "Churchill, Victoria, Australia",
  "618554, Solikamsk, Perm, Russia",
  "Los Guayos, Carabobo State, Venezuela",
  "99802, Juneau, Alaska, United States",
  "Oktyabrsky District, Khanty-Mansi, Russia",
  "Richardson, Arkansas, United States",
  "Régis, Laramière, Occitanie, France",
  "Dudelange, Luxembourg, Luxembourg",
  "Dublin 8, County Dublin, Ireland",
  "La Luzerne, Chaillé-les-Marais, Pays de la Loire, France",
  "412306, Balashov, Saratov, Russia",
  "Buzovna, Baku Ekonomic Zone, Azerbaijan",
  "167031, Syktyvkar, Komi, Russia",
  "Besançon, Bourgogne-Franche-Comté, France",
  "02049, Albacete, Castile-La Mancha, Spain",
  "Landeronde, Pays de la Loire, France",
  "KA6 7HG, Rankinston, Scotland, United Kingdom",
  "Fulton, Illinois, United States",
  "97100, Basse-Terre, Basse-Terre, Guadeloupe",
  "FY1 1AD, Blackpool, England, United Kingdom",
  "00200, Helsinki, Uusimaa, Finland",
  "Pittsfield, Massachusetts, United States",
  "9664, Hammerfest, Troms og Finnmark, Norway",
  "361335, Okhamandal, Gujarat, India",
  "9700-013, Angra do Heroísmo, Portugal",
  "Windsor Forrest, Essequibo Islands, Guyana",
  "Hebron, England, United Kingdom",
  "86370-000, Santa Amélia, Paraná, Brazil",
  "Forrestfield, Western Australia, Australia",
  "Caroline, New York, United States",
  "86990, Emiliano Zapata, Tabasco, Mexico",
  "Frontier County, Nebraska, United States",
  "9490, Vaduz, Vaduz, Liechtenstein",
  "Skwierzyna, Lubuskie, Poland",
  "Akhtubinskoye, Volgograd, Russia",
  "10115, Berlin, Berlin, Germany",
  "Kimball Mill, Naperville, Illinois, United States",
  "1630, Bulle, Fribourg, Switzerland",
  "623412, Kamensk Uralskiy, Sverdlovsk, Russia",
  "Santiago do Cacém, Setúbal, Portugal",
  "694010, Korsakov, Sakhalin, Russia",
  "36030, Teluk Intan, Perak, Malaysia",
  "02511, Albacete, Castile-La Mancha, Spain",
  "16129, Genoa, Liguria, Italy",
  "Dickey, Maine, United States",
  "Hamblen County, Tennessee, United States",
  "Loudonville, Ohio, United States",
  "462716, Dombarovskiy, Orenburg, Russia",
  "73047, Monteroni di Lecce, Apulia, Italy",
  "1041, Tirana, Tirana, Albania",
  "Nowata, Ahal Region, Turkmenistan",
  "Myaundzha, Magadan, Russia",
  "Oswego–Oneida Streets Historic District, Baldwinsville, New York, United States",
  "10914, Tallinn, Harjumaa, Estonia",
  "M52C7C1, Osakarovka, Karaganda Region, Kazakhstan",
  "Grenada County, Mississippi, United States",
  "Ashland, Ohio, United States",
  "14478, Potsdam, Brandenburg, Germany",
  "Clark County, Washington, United States",
  "Arapahoe County, Colorado, United States",
  "46000, Kahramanmaraş, Kahraman Maras, Türkiye",
  "7635, Pécs, Baranya, Hungary",
  "426075, Izhevsk, Udmurtia, Russia",
  "Yüregir, Adana, Türkiye",
  "75783, Quitman, Texas, United States",
  "Rome, New York, United States",
  "Tillmann, Gronau, North Rhine-Westphalia, Germany",
  "08087, Tuckerton, New Jersey, United States",
  "97420, Coos Bay, Oregon, United States",
  "McClain Park, Texas City, Texas, United States",
  "64088, Sibley, Missouri, United States",
  "142440, Noginsk, Moscow, Russia",
  "825200, Isaccea, Tulcea, Romania",
  "45000, Manisa, Manisa, Türkiye",
  "Waltershecke, Neu-Bamberg, Rhineland-Palatinate, Germany",
  "24141, Radford, Virginia, United States",
  "24515, Lynchburg, Virginia, United States",
  "100009, Ploieşti, Prahova, Romania",
  "Norton, Runcorn, England, United Kingdom",
  "Stockholm–DeKalb–Hart Historic District, New York, United States",
  "99205, Spokane, Washington, United States",
  "182108, Velikiye Luki, Pskov, Russia",
  "Sierra Madre, California, United States",
  "Chag'dari, Bukhara Region, Uzbekistan",
  "92125, Klaipėda, Klaipėdos, Lithuania",
  "Colfax, North Carolina, United States",
  "LV-3008, Svēte, Jelgava Municipality, Latvia",
  "North Clarendon, Vermont, United States",
  "Vetlanda kommun, Jönköping County, Sweden",
  "Chapel Hill, North Carolina, United States",
  "Santa Clara, California, United States",
  "Florence County, South Carolina, United States",
  "354057, Sochi, Krasnodar, Russia",
  "Barronsfield, Cumberland, Subdivision A, Nova Scotia, Canada",
  "1000, Brussels, Brussels Region, Belgium",
  "33007, Oviedo, Principality of Asturias, Spain",
  "427433, Votkinsk, Udmurtia, Russia",
  "Grundy, Virginia, United States",
  "WR10 2JT, Throckmorton, England, United Kingdom",
  "CV7 9BT, Coventry, England, United Kingdom",
  "RM17 5JX, Grays, England, United Kingdom",
  "CH5 4BR, Connah's Quay, Wales, United Kingdom",
  "V3H 0A1, Port Moody, British Columbia, Canada",
  "1217, Tetovo, Polog, North Macedonia",
  "Hamble-Le-Rice, England, United Kingdom",
  "Bastrop, Texas, United States",
  "427622, Glazov, Udmurtia, Russia",
  "23434, Suffolk, Virginia, United States",
  "White Lake, Michigan, United States",
  "Plymouth, North Carolina, United States",
  "16290, Bursa, Bursa, Türkiye",
  "9002, Lushnjë, Fier, Albania",
  "Eastland-Wilora Lake, Charlotte, North Carolina, United States",
  "35010, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Benson 35, Saskatchewan, Canada",
  "Craig-Y-Don, Llandudno, Wales, United Kingdom",
  "Várpalota, Veszprém, Hungary",
  "431449, Ruzayevka, Mordovia, Russia",
  "NR2 1AB, Norwich, England, United Kingdom",
  "623107, Pervoural'sk, Sverdlovsk, Russia",
  "Carrollton, Virginia, United States",
  "79778, Rankin, Texas, United States",
  "Aralık, Igdir, Türkiye",
  "67700, Kokkola, Central Ostrobothnia, Finland",
  "Venice, Ohio, United States",
  "618268, Gubakha, Perm, Russia",
  "Clearfield, Kentucky, United States",
  "86327, Dewey, Arizona, United States",
  "12062, East Nassau, New York, United States",
  "25100, Erzurum, Erzurum, Türkiye",
  "Batagay, Sakha, Russia",
  "Victoria, Australia",
  "44092, Wickliffe, Ohio, United States",
  "Kuznetskoye, Chelyabinsk, Russia",
  "02804, Ashaway, Rhode Island, United States",
  "Garden Grove, California, United States",
  "Phelps-University Heights, Springfield, Missouri, United States",
  "Londonderry, Northern Ireland, United Kingdom",
  "N0C 0A3, Dundalk, Ontario, Canada",
  "Darkes Forest, New South Wales, Australia",
  "İzmit, Kocaeli, Türkiye",
  "Gorod Shuya, Ivanovo, Russia",
  "Pohénégamook, Quebec, Canada",
  "662066, Bogotol selo, Krasnoyarsk Krai, Russia",
  "90680, Stanton, California, United States",
  "10001, New York, New York, United States",
  "Summerside, Prince Edward Island, Canada",
  "Ivanovo Metropolitan Area",
  "Caddo Heights, Shreveport, Louisiana, United States",
  "452613, Oktyabrsky, Bashkortostan, Russia",
  "Decatur, Michigan, United States",
  "64658, Fürth, Hesse, Germany",
  "R0C 0A4, Stonewall, Manitoba, Canada",
  "Rayleigh, England, United Kingdom",
  "Starr County, Texas, United States",
  "46778, Petroleum, Indiana, United States",
  "693006, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Bełchatowski, Łódzkie, Poland",
  "Stone County, Missouri, United States",
  "Aqsu District, Jetisu Region, Kazakhstan",
  "15892, Santiago de Compostela, Galicia, Spain",
  "L9 0EA, Liverpool, England, United Kingdom",
  "Chickasaw Ridge, Florida, United States",
  "Somerset County, Pennsylvania, United States",
  "Richardson Village, Sacramento, California, United States",
  "22406, Fredericksburg, Virginia, United States",
  "38122, Trento, Trentino-Alto Adige, Italy",
  "Manchester, Massachusetts, United States",
  "142253, Serpukhov, Moscow, Russia",
  "Bikin, Khabarovsk, Russia",
  "55303, Anoka, Minnesota, United States",
  "Stafford, Virginia, United States",
  "Carson City, Nevada, United States",
  "69366, Whitman, Nebraska, United States",
  "Pittsburg, Kansas, United States",
  "Morrow, Ohio, United States",
  "Greater Parma Metropolitan Area",
  "Derepazarı, Rize, Türkiye",
  "036 01, Martin, Zilina, Slovakia",
  "Holmes Mill, Kentucky, United States",
  "60400, Tokat, Tokat, Türkiye",
  "81929, Munich, Bavaria, Germany",
  "656 36, Karlstad, Värmland County, Sweden",
  "4670, Walkervale, Queensland, Australia",
  "M28C2K1, Aqtau, Karaganda Region, Kazakhstan",
  "142181, Podolsk, Moscow, Russia",
  "249663, Kirsanovo-Pyatnitsa, Kaluga, Russia",
  "06080, Perugia, Umbria, Italy",
  "Morgan Hill, California, United States",
  "Witry-lès-Reims, Grand Est, France",
  "Llano Bonito, Herrera, Panama",
  "Strassen, Luxembourg, Luxembourg",
  "36513, McIntosh, Alabama, United States",
  "Reynolds Station, Kentucky, United States",
  "CO13 0DZ, Kirkby-Le-Soken, England, United Kingdom",
  "Zimado, Far-North, Cameroon",
  "4611, Kristiansand, Agder, Norway",
  "33060, Mersin, İçel, Türkiye",
  "17878, Stillwater, Pennsylvania, United States",
  "6022, Innsbruck, Tyrol, Austria",
  "664050, Irkutsk, Irkutsk, Russia",
  "162616, Cherepovets, Vologda, Russia",
  "Soro, Odisha, India",
  "Kovel Raion, Volyn, Ukraine",
  "Novotroitskiy, Bashkortostan, Russia",
  "636783, Strezhevoy, Tomsk, Russia",
  "Balatonalmádi, Veszprém, Hungary",
  "Wellsford, Auckland, New Zealand",
  "Polovino-Cheremkhovo, Irkutsk, Russia",
  "M28C4G3, Aqtau, Karaganda Region, Kazakhstan",
  "Novoselitskoye, Stavropol, Russia",
  "Burton-By-Lincoln, England, United Kingdom",
  "Southwest Schuylkill, Philadelphia, Pennsylvania, United States",
  "Hopkinstown, Pontypridd, Wales, United Kingdom",
  "188808, Vyborg, Leningrad, Russia",
  "La Bertière, Saint-Jean-des-Échelles, Pays de la Loire, France",
  "Reynoldsburg, Ohio, United States",
  "Clark County, Ohio, United States",
  "4970, Weber, Manawatū-Whanganui, New Zealand",
  "Entre Ríos Province, Argentina",
  "Lavaca County, Texas, United States",
  "Wyoming, Iowa, United States",
  "Chelan, Washington, United States",
  "20460, Denizli, Denizli, Türkiye",
  "Rzhevsky District, Tver’, Russia",
  "Smithfield, Virginia, United States",
  "Lamoure, Agonac, Nouvelle-Aquitaine, France",
  "Mesudiye, Ordu, Türkiye",
  "07280, Porvoo, Uusimaa, Finland",
  "9000, Varna, Varna, Bulgaria",
  "Vagos, Aveiro, Portugal",
  "Calumet, Iowa, United States",
  "9900-038, Horta, Portugal",
  "45400, Manisa, Manisa, Türkiye",
  "412303, Balashov, Saratov, Russia",
  "Treasure Hills, Texas, United States",
  "1201, Tetovo, Polog, North Macedonia",
  "93320-001, Novo Hamburgo, Rio Grande do Sul, Brazil",
  "Toulon, Illinois, United States",
  "Leñgir, Turkistan Region, Kazakhstan",
  "Greenock, Pennsylvania, United States",
  "Rutland, Iowa, United States",
  "S3 7AA, Sheffield, England, United Kingdom",
  "Young, Río Negro, Uruguay",
  "LV-5418, Daugavpils, Daugavpils, Latvia",
  "Aral, Kyzylorda Region, Kazakhstan",
  "461633, Buguruslan, Orenburg, Russia",
  "53188, Waukesha, Wisconsin, United States",
  "Rockland St Peter, England, United Kingdom",
  "9620, Hammerfest, Troms og Finnmark, Norway",
  "Olmsted, Ohio, United States",
  "Parmer, Jhadol, Rajasthan, India",
  "Svobodnyy, Saratov, Russia",
  "60133, Hanover Park, Illinois, United States",
  "Troitsk, Chelyabinsk, Russia",
  "Holmeson, New Jersey, United States",
  "Worth, Illinois, United States",
  "396880, Glazovo, Voronezh, Russia",
  "10179, Berlin, Berlin, Germany",
  "45000-010, Vitória da Conquista, Bahia, Brazil",
  "Laramie, Wyoming, United States",
  "C40A7E0, Esil, Akmola Region, Kazakhstan",
  "Kearney Park, Mississippi, United States",
  "Leeds, Maine, United States",
  "Sullivan County, New Hampshire, United States",
  "S13E3A7, Ekibastuz, Pavlodar Region, Kazakhstan",
  "43230, Columbus, Ohio, United States",
  "3521, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Mittelland, Appenzell Outer-Rhoden, Switzerland",
  "PA15 1AE, Greenock, Scotland, United Kingdom",
  "44118, Cleveland, Ohio, United States",
  "Harrison County, Mississippi, United States",
  "Sarajevo-Romanija, Serb Republic, Bosnia and Herzegovina",
  "Konotop, Sumy, Ukraine",
  "364015, Groznyy, Chechnya, Russia",
  "Wellsburg, West Virginia, United States",
  "Bollinger Moor, Saterland, Lower Saxony, Germany",
  "24614, Grundy, Virginia, United States",
  "Kempton Park, Gauteng, South Africa",
  "Viborg Municipality, Central Denmark Region, Denmark",
  "Williams, Arizona, United States",
  "Benevento, Campania, Italy",
  "Aosta, Valle d'Aosta, Italy",
  "Matiçan, District of Pristina, Kosovo",
  "Bila, Ternopil, Ukraine",
  "Dnipropetrovsk, Ukraine",
  "Zhytomyr, Ukraine",
  "62360, Saint-Étienne-au-Mont, Hauts-de-France, France",
  "Comanche Heights, Chandler, Arizona, United States",
  "Obshtina Varbitsa, Shumen, Bulgaria",
  "Southwest Meade, South Dakota, United States",
  "La Noblesse, Thenay, Centre-Val de Loire, France",
  "80134, Parker, Colorado, United States",
  "Vladimirsky Municipal Okrug, St Petersburg City, Russia",
  "Brownwood, Texas, United States",
  "Harrisonville, Missouri, United States",
  "63050, Şanlıurfa, Şanliurfa, Türkiye",
  "07928, Chatham, New Jersey, United States",
  "Graz-Umgebung, Styria, Austria",
  "Greeley, Kansas, United States",
  "Campbell County, Virginia, United States",
  "Barron Park, Palo Alto, California, United States",
  "930, Regensburg, Bavaria, Germany",
  "Borlänge, Dalarna County, Sweden",
  "143400, Krasnogorsk, Moscow, Russia",
  "Craigsville, West Virginia, United States",
  "3112, Tønsberg, Vestfold og Telemark, Norway",
  "50-457, Wrocław, Dolnośląskie, Poland",
  "Cumberland, Kentucky, United States",
  "Kingsport, Tennessee, United States",
  "Brown County, Ohio, United States",
  "68849, Kearney, Nebraska, United States",
  "Langlade, Causse-et-Diège, Occitanie, France",
  "Bebrene, Daugavpils, Latvia",
  "Lake Clarke Shores, Florida, United States",
  "75213, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "9007, Lushnjë, Fier, Albania",
  "6080, Innsbruck, Tyrol, Austria",
  "Covington, Washington, United States",
  "4300, Karlovo, Plovdiv, Bulgaria",
  "Dixon, Wyoming, United States",
  "Toa Alta, Puerto Rico",
  "Langlade, Saint-Amand-de-Belvès, Nouvelle-Aquitaine, France",
  "Ottawa County, Michigan, United States",
  "Frederick County, Maryland, United States",
  "Chautauqua County, Kansas, United States",
  "Titus Ridge, Pine Swamp, North Carolina, United States",
  "55128, Mainz, Rhineland-Palatinate, Germany",
  "58464, McHenry, North Dakota, United States",
  "Handeloh, Lower Saxony, Germany",
  "Traverse City, Michigan, United States",
  "25-515, Kielce, Świętokrzyskie, Poland",
  "SY7 9PY, Craven Arms, England, United Kingdom",
  "10040, Zagreb, Zagreb, Croatia",
  "Stavropol, Stavropol, Russia",
  "Umeå kommun, Västerbotten County, Sweden",
  "Emery Place Condos, Nashville, Tennessee, United States",
  "Sheffield, England, United Kingdom",
  "Cascade, Wisconsin, United States",
  "361345, Okhamandal, Gujarat, India",
  "20104, Narva, Ida-Virumaa, Estonia",
  "29660, Marbella, Andalusia, Spain",
  "Leiria, Leiria, Portugal",
  "152915, Rybinsk, Yaroslavl’, Russia",
  "A92 Y628, Drogheda, Ireland",
  "Kharkiv Metropolitan Area",
  "La Guardia de Jaén, Andalusia, Spain",
  "Braxton, Mississippi, United States",
  "600300, Bacău, Bacău, Romania",
  "692036, Lesozavodsk, Primorye, Russia",
  "10-686, Olsztyn, Warmińsko-mazurskie, Poland",
  "Hot Springs County, Wyoming, United States",
  "78550-090, Sinop, Mato Grosso, Brazil",
  "423462, Almetyevsk, Tatarstan, Russia",
  "Vologda, Vologda, Russia",
  "27043, Peschanyy Brod, Kirovohrad, Ukraine",
  "Кандыагаш, Aqtöbe region, Kazakhstan",
  "Kouvola sub-region, Kymenlaakso, Finland",
  "Val-De-Marne, Île-de-France, France",
  "357744, Kislovodsk, Stavropol, Russia",
  "Greater Granada Metropolitan Area",
  "Tioga Estates, Oakdale, California, United States",
  "Lida, Hrodna, Belarus",
  "Zarechnyy, Penza, Russia",
  "Alcorn County, Mississippi, United States",
  "20000, Dubrovnik, Dubrovnik-Neretva, Croatia",
  "3531, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Wang Wiset district, Trang, Thailand",
  "Clay County, Kentucky, United States",
  "23206, Sukošan, Zadar, Croatia",
  "Bratislava-Ružinov, Bratislava, Slovakia",
  "Crawford County, Michigan, United States",
  "Stadt Passau, Bavaria, Germany",
  "75206, Dallas, Texas, United States",
  "428022, Cheboksary, Chuvashia, Russia",
  "Pavlikeni, Veliko Turnovo, Bulgaria",
  "Chambers, Nebraska, United States",
  "Alexandria City County, Virginia, United States",
  "Toolestown, County Kildare, Ireland",
  "Yeletskiy, Lipetsk, Russia",
  "Golyshmanovsky District, Tyumen’, Russia",
  "Potsdam, Brandenburg, Germany",
  "676011, Skovorodino, Amur, Russia",
  "Rockingham County, New Hampshire, United States",
  "164844, Onega, Arkhangel’sk, Russia",
  "Camden, Michigan, United States",
  "Mushahari, Bihar, India",
  "Nyékládháza, Borsod-Abaúj-Zemplén, Hungary",
  "Novy, Vladimir, Russia",
  "09004, Burgos, Castilla and Leon, Spain",
  "Palatka, Florida, United States",
  "Korsakovo, Buryatia, Russia",
  "30567, Pendergrass, Georgia, United States",
  "386104, Nazran, Ingushetia, Russia",
  "Prince George's County, Maryland, United States",
  "Håbo kommun, Uppsala County, Sweden",
  "88630, Sionne, Grand Est, France",
  "662951, Artëmovsk, Krasnoyarsk Krai, Russia",
  "12902-100, Bragança Paulista, São Paulo, Brazil",
  "Poti, Georgia",
  "35390, Giessen, Hesse, Germany",
  "238152, Chernyakhovskiy, Kaliningrad, Russia",
  "Nicollet Drive, Wisconsin, United States",
  "309972, Valuyki, Belgorod, Russia",
  "Martin County, Indiana, United States",
  "49107, Buchanan, Michigan, United States",
  "Czechia",
  "V92 HRX3, Tralee, County Kerry, Ireland",
  "188308, Gatchina, Leningrad, Russia",
  "450071, Ufa, Bashkortostan, Russia",
  "97200, Fort-de-France, Fort-de-France, Martinique",
  "Tribunj, Sibenik-Knin, Croatia",
  "Estrées-St-Denis, Hauts-de-France, France",
  "Üzümlü, Erzincan, Türkiye",
  "623955, Tavda, Sverdlovsk, Russia",
  "31016, Pamplona, Chartered Community of Navarre, Spain",
  "37138, Verona, Veneto, Italy",
  "Lee County, Alabama, United States",
  "67217, Wichita, Kansas, United States",
  "Ouche Millard, Saint-Éloy-de-Gy, Centre-Val de Loire, France",
  "Muş, Muş, Türkiye",
  "Greater Aarhus Area",
  "89077, Ulm, Baden-Württemberg, Germany",
  "20110, Manassas, Virginia, United States",
  "Newton, Massachusetts, United States",
  "Jacksonville, North Carolina, United States",
  "58366, Rolette, North Dakota, United States",
  "427793, Mozhga, Udmurtia, Russia",
  "K1K 0A3, Ottawa, Ontario, Canada",
  "Loulé, Faro, Portugal",
  "184605, Severomorsk, Murmansk, Russia",
  "San Sebastián, Cusco, Peru",
  "Starraghan, Newtownbutler, Northern Ireland, United Kingdom",
  "Marion, South Carolina, United States",
  "Mississippi County, Missouri, United States",
  "184506, Monchegorsk, Murmansk, Russia",
  "353495, Gelendzhik, Krasnodar, Russia",
  "Chesterfield County, South Carolina, United States",
  "McDonald, Kansas, United States",
  "Buffalo City Metropolitan Municipality, Eastern Cape, South Africa",
  "Ishëm, Durres, Albania",
  "702 85, Örebro, Örebro County, Sweden",
  "2522 AA, The Hague, South Holland, Netherlands",
  "Dublin, Georgia, United States",
  "Dobryanka, Perm, Russia",
  "80750, Snyder, Colorado, United States",
  "70605, Lake Charles, Louisiana, United States",
  "80-288, Gdańsk, Pomorskie, Poland",
  "LV-3620, Piltene, Ventspils, Latvia",
  "Amur, Russia",
  "6405, Përmet, Gjirokastër, Albania",
  "Partido de General San Martín, Buenos Aires Province, Argentina",
  "Curry County, New Mexico, United States",
  "0470034, Otaru, Hokkaido, Japan",
  "Ermenek Bucağı, Karaman, Türkiye",
  "Benson, Minnesota, United States",
  "Sandnessjøen, Nordland, Norway",
  "8504, Narvik, Nordland, Norway",
  "618243, Chusovoy, Perm, Russia",
  "09005, Burgos, Castilla and Leon, Spain",
  "14700, Hämeenlinna, Kanta-Häme, Finland",
  "52000, Ordu, Ordu, Türkiye",
  "50-020, Wrocław, Dolnośląskie, Poland",
  "Okfuskee County, Oklahoma, United States",
  "Mate Asher Regional Council, North District, Israel",
  "Nemaha, Florida, United States",
  "73072, Norman, Oklahoma, United States",
  "Wharton Green, Winsford, England, United Kingdom",
  "Orenburg Metropolitan Area",
  "45360, Kouvola, Kymenlaakso, Finland",
  "Lancaster, South Carolina, United States",
  "13056, Cortland, New York, United States",
  "Caenby Corner, England, United Kingdom",
  "Wallace, Michigan, United States",
  "670013, Ulan-Ude, Buryatia, Russia",
  "Coimbra Metropolitan Area",
  "Overton County, Tennessee, United States",
  "Red Deer County, Alberta, Canada",
  "Trenton, New Jersey, United States",
  "Carroll County, Missouri, United States",
  "ST6 1AA, Stoke-On-Trent, England, United Kingdom",
  "456316, Miass, Chelyabinsk, Russia",
  "Knox County, Tennessee, United States",
  "Central Department, Paraguay",
  "180016, Pskov, Pskov, Russia",
  "Sumter Street, Minneola, Florida, United States",
  "Stanley, North Carolina, United States",
  "60900, Tokat, Tokat, Türkiye",
  "357903, Zelenokumsk, Stavropol, Russia",
  "Laâyoune-Sakia El Hamra, Morocco",
  "City Of Peterborough, England, United Kingdom",
  "X08Y6E8, Shymkent, Shymkent, Kazakhstan",
  "Faulkbourne, England, United Kingdom",
  "Serbia",
  "29190, Málaga, Andalusia, Spain",
  "56121, Pisa, Tuscany, Italy",
  "Lebanon, Connecticut, United States",
  "6968, Lugano, Ticino, Switzerland",
  "214510, Bogoroditskoye, Smolensk, Russia",
  "M28C1P7, Aqtau, Karaganda Region, Kazakhstan",
  "49160-000, Nossa Senhora do Socorro, Sergipe, Brazil",
  "Danville, Iowa, United States",
  "97128, Goyave, Basse-Terre, Guadeloupe",
  "Mongolia",
  "211382, Orsha, Vitsyebsk, Belarus",
  "Seville East, Victoria, Australia",
  "Roosevelt, Dinalupihan, Central Luzon, Philippines",
  "653026, Prokopyevsk, Kemerovo, Russia",
  "73013, Galatina, Apulia, Italy",
  "Blanco, Oklahoma, United States",
  "89135, Reggio di Calabria, Calabria, Italy",
  "Aral, Talas Region, Kyrgyzstan",
  "352702, Timashevsk, Krasnodar, Russia",
  "Cavalier Hill, Leeds, England, United Kingdom",
  "2000-090, Santarém, Santarém, Portugal",
  "41020, Seville, Andalusia, Spain",
  "BS32 9DB, Bradley Stoke, England, United Kingdom",
  "673002, Petrovsk-Zabaykal’skiy, Zabaykalsky Krai, Russia",
  "618545, Solikamsk, Perm, Russia",
  "20250, Orebic, Dubrovnik-Neretva, Croatia",
  "606034, Dzerzhinsk, Nizhniy Novgorod, Russia",
  "Lincoln Park, Kenosha, Wisconsin, United States",
  "Camp Pendleton South, California, United States",
  "Orallo, Queensland, Australia",
  "8901, Zalaegerszeg, Zala, Hungary",
  "623950, Tavda, Sverdlovsk, Russia",
  "Overton Park, Cheltenham, England, United Kingdom",
  "Florence, Montana, United States",
  "416502, Akhtubinsk, Astrakhan’, Russia",
  "Aral Moreira, Mato Grosso do Sul, Brazil",
  "65231, Vaasa, Ostrobothnia, Finland",
  "North McCone, Montana, United States",
  "Rockland, Idaho, United States",
  "500170, Braşov, Braşov, Romania",
  "Carrollton, Texas, United States",
  "Sofia City, Bulgaria",
  "63737, Valle de Banderas, Nayarit, Mexico",
  "Limerick Metropolitan Area",
  "Uzhursky District, Krasnoyarsk Krai, Russia",
  "09400, Aydın, Aydın, Türkiye",
  "658095, Novoaltaysk, Altai Krai, Russia",
  "47647, Buckskin, Indiana, United States",
  "FY4 5ES, Blackpool, England, United Kingdom",
  "Garfield Heights, Ohio, United States",
  "Dunayivtsi, Khmelnytsky, Ukraine",
  "Dawsonville, Georgia, United States",
  "71486, Zwolle, Louisiana, United States",
  "Provo, Utah, United States",
  "69126, Heidelberg, Baden-Württemberg, Germany",
  "Becker, Algoma Unorganized North Part, Ontario, Canada",
  "69005, Zaporizhzhya, Zaporizhzhya, Ukraine",
  "71644, Grady, Arkansas, United States",
  "Madison, Mississippi, United States",
  "424032, Yoshkar-Ola, Mari El, Russia",
  "634021, Tomsk, Tomsk, Russia",
  "627303, Golyshmanovo, Tyumen’, Russia",
  "Thayer, Missouri, United States",
  "PH33 6FF, Fort William, Scotland, United Kingdom",
  "184670, Polyarnyy, Murmansk, Russia",
  "Herisau, Appenzell Outer-Rhoden, Switzerland",
  "Saint-Benoît-du-Lac, Quebec, Canada",
  "Pervoural'sk, Sverdlovsk, Russia",
  "82331, Saratoga, Wyoming, United States",
  "Delaware County, Iowa, United States",
  "Kaneohe Base, Hawaii, United States",
  "57100, Savonlinna, South Savo, Finland",
  "64800, Uşak, Uşak, Türkiye",
  "Barangay 10 (Kingfisher), Cavite City, Calabarzon, Philippines",
  "Grady, North Carolina, United States",
  "Albuquerque-Santa Fe Metropolitan Area",
  "353644, Yeyskoye Ukrepleniye, Krasnodar, Russia",
  "Beaver, Oregon, United States",
  "Emmons, Minnesota, United States",
  "Racine, Quebec, Canada",
  "Webster County, Mississippi, United States",
  "West Brazos, Texas, United States",
  "Keşan, Edirne, Türkiye",
  "KW15 1BH, Kirkwall, Scotland, United Kingdom",
  "20207, Župa dubrovačka, Dubrovnik-Neretva, Croatia",
  "Knezha, Pleven, Bulgaria",
  "97131, Petit-Canal, Pointe-à-Pitre, Guadeloupe",
  "60115, DeKalb, Illinois, United States",
  "BT79 7LG, Omagh, Northern Ireland, United Kingdom",
  "9440, Evenskjær, Troms og Finnmark, Norway",
  "Belebey, Bashkortostan, Russia",
  "McKenzie County, North Dakota, United States",
  "Canton of Troyes-1, Grand Est, France",
  "624486, Severoural’sk, Sverdlovsk, Russia",
  "692039, Lesozavodsk, Primorye, Russia",
  "871 53, Härnösand, Västernorrland County, Sweden",
  "Saint-Étienne-de-Crossey, Auvergne-Rhône-Alpes, France",
  "Cavalier, Laluque, Nouvelle-Aquitaine, France",
  "831 45, Östersund, Jämtland County, Sweden",
  "Thomasville, North Carolina, United States",
  "Fairfield County, Ohio, United States",
  "02905, Providence, Rhode Island, United States",
  "Miass, Chelyabinsk, Russia",
  "41230, Louisa, Kentucky, United States",
  "33311, Fort Lauderdale, Florida, United States",
  "169727, Usinsk, Komi, Russia",
  "Green Valley, Arizona, United States",
  "309259, Shebekino, Belgorod, Russia",
  "Sumter Gardens, North Port, Florida, United States",
  "172009, Torzhok, Tver’, Russia",
  "Danestone, Aberdeen, Scotland, United Kingdom",
  "53402, Racine, Wisconsin, United States",
  "66426, Westmoreland, Kansas, United States",
  "Cyprus",
  "Spišská Nová Ves, Kosice, Slovakia",
  "462363, Novotroitsk, Orenburg, Russia",
  "40769, Williamsburg, Kentucky, United States",
  "617067, Krasnokamsk, Perm, Russia",
  "Kempten (Allgäu), Bavaria, Germany",
  "354030, Sochi, Krasnodar, Russia",
  "03026, Chumaky, Dnipropetrovsk, Ukraine",
  "358006, Elista, Kalmykia, Russia",
  "R25H4H6, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "Antelope Acres, Arizona, United States",
  "723 55, Västerås, Västmanland County, Sweden",
  "Chernyshëvka, Tambov, Russia",
  "Sovetskiy, Nizhniy Novgorod, Russia",
  "37134, Verona, Veneto, Italy",
  "Muromtsevo, Kaluga, Russia",
  "Terry, Montana, United States",
  "Korzhovogolubovskoye, Bryansk, Russia",
  "Robin Hood, England, United Kingdom",
  "Payne, Saint Clair, Ontario, Canada",
  "82-308, Elbląg, Warmińsko-mazurskie, Poland",
  "Trinity, Jersey",
  "Lee, New Hampshire, United States",
  "62882, Sandoval, Illinois, United States",
  "Daggett, Michigan, United States",
  "13736, Berkshire, New York, United States",
  "62635, Emden, Illinois, United States",
  "346330, Donetsk, Rostov, Russia",
  "62558, Pawnee, Illinois, United States",
  "Carver, Virginia, United States",
  "66484, Walshausen, Rhineland-Palatinate, Germany",
  "59600-010, Mossoró, Rio Grande do Norte, Brazil",
  "8867, Niederurnen, Glarus, Switzerland",
  "Khanty-Mansi, Russia",
  "27200, Gaziantep, Gaziantep, Türkiye",
  "Le Malheurtias, Dième, Auvergne-Rhône-Alpes, France",
  "Refugio, Cienfuegos, Cuba",
  "Zwolle, Overijssel, Netherlands",
  "F39B8F8, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Hardin County, Illinois, United States",
  "Vinton Highlands, Lafayette, Indiana, United States",
  "Fortaleza, Ceará, Brazil",
  "2836, Gjøvik, Innlandet, Norway",
  "Hughesville, Maryland, United States",
  "Benton County, Washington, United States",
  "Cayuga, Texas, United States",
  "75476, Ravenna, Texas, United States",
  "B6L 0B1, Onslow Mountain, Nova Scotia, Canada",
  "Carbondale, Colorado, United States",
  "68331, Cortland, Nebraska, United States",
  "Deschutes River Woods, Oregon, United States",
  "665832, Angarsk, Irkutsk, Russia",
  "Greater Houston",
  "Warren, New Jersey, United States",
  "Howells, Nebraska, United States",
  "Golden, Missouri, United States",
  "Troitsky District, Chelyabinsk, Russia",
  "Greater Como Metropolitan Area",
  "Cambria, Wisconsin, United States",
  "Clarendon, Jamaica",
  "184511, Monchegorsk, Murmansk, Russia",
  "E9C 2N8, Storeytown, New Brunswick, Canada",
  "Princeton, Texas, United States",
  "Lamoille County, Vermont, United States",
  "Davis, North Carolina, United States",
  "Maqat, Atyrau Region, Kazakhstan",
  "1387, Drammen, Viken, Norway",
  "Calumet Heights, Chicago, Illinois, United States",
  "623141, Pervoural'sk, Sverdlovsk, Russia",
  "624574, Ivdel’, Sverdlovsk, Russia",
  "N0H 2E0, Ravenna, Ontario, Canada",
  "TR18 4BU, Penzance, England, United Kingdom",
  "88-115, Inowrocław, Kujawsko-pomorskie, Poland",
  "5523, Haugesund, Rogaland, Norway",
  "S21 3YE, Spinkhill, England, United Kingdom",
  "384 46, Vólos, Thessalia, Greece",
  "40476, Düsseldorf, North Rhine-Westphalia, Germany",
  "81-586, Gdynia, Pomorskie, Poland",
  "F39B2C9, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Van Buren County, Michigan, United States",
  "Plymouth, Indiana, United States",
  "Pacific Palisades, Los Angeles, California, United States",
  "Valuysky District, Belgorod, Russia",
  "88900, Crotone, Calabria, Italy",
  "6340, Baar, Zug, Switzerland",
  "Stoke-On-Trent, England, United Kingdom",
  "Dorchester, New Brunswick, Canada",
  "8832, Wollerau, Schwyz, Switzerland",
  "José Refugio Salcido, Durango, Mexico",
  "Botany Worsted Mills Historic District, Passaic, New Jersey, United States",
  "20792, Upper Marlboro, Maryland, United States",
  "15-101, Białystok, Podlaskie, Poland",
  "644070, Omsk, Omsk, Russia",
  "L9V 2C6, Dundalk, Ontario, Canada",
  "655014, Abakan, Khakassia, Russia",
  "Archer Farm, North Carolina, United States",
  "Rockland, Maine, United States",
  "22908, Charlottesville, Virginia, United States",
  "21010, Diyarbakır, Diyarbakır, Türkiye",
  "X38G4B6, Kentau, Turkistan Region, Kazakhstan",
  "690000, Vladivostok, Primorye, Russia",
  "Tillo, Siirt, Türkiye",
  "453430, Blagoveshchensk, Bashkortostan, Russia",
  "11007, Cadiz, Andalusia, Spain",
  "19966, Millsboro, Delaware, United States",
  "Antwerp, Flemish Region, Belgium",
  "Gagarinsky District, Sevastopol, Sevastopol, Ukraine",
  "VLT 1234, Valletta, Valletta, Malta",
  "Clarkston, Washington, United States",
  "Thurston County, Nebraska, United States",
  "La Judith, Quevedo, Los Ríos, Ecuador",
  "358009, Elista, Kalmykia, Russia",
  "Slavgorodskiy gorodskoy sovet, Mahilyow, Belarus",
  "Charleroi Metropolitan Area",
  "Wicklewood, England, United Kingdom",
  "Reynoldston, Wales, United Kingdom",
  "Yesilyurt, Malatya, Türkiye",
  "East Sussex, England, United Kingdom",
  "Murray Bridge, South Australia, Australia",
  "Ransomes Europark, Ipswich, England, United Kingdom",
  "Port Elizabeth, Eastern Cape, South Africa",
  "Popel, Tboung Khmum, Cambodia",
  "Deuel County, Nebraska, United States",
  "Eastland, Texas, United States",
  "20000, Ajaccio, Corsica, France",
  "Sarıveliler, Karaman, Türkiye",
  "67716, Linares, Nuevo León, Mexico",
  "Vila de Furnas, Ivaiporã, Paraná, Brazil",
  "3880, Pukeatua, Waikato, New Zealand",
  "155809, Kineshma, Ivanovo, Russia",
  "Rusesa, Kigoma Region, Tanzania",
  "Winona, Mississippi, United States",
  "Archer, South Dundas, Ontario, Canada",
  "14779, Salamanca, New York, United States",
  "Bol’shiye Berezniki, Mordovia, Russia",
  "Huntington, New York, United States",
  "La Sainte-Bertière, Noyal-sous-Bazouges, Brittany, France",
  "410290, Oradea, Bihor, Romania",
  "1050, Brussels, Brussels Region, Belgium",
  "9050-078, Funchal, Madeira Island, Portugal",
  "Garvin, Minnesota, United States",
  "Pacific City, Oregon, United States",
  "03442, Bennington, New Hampshire, United States",
  "Dadease, Ashanti Region, Ghana",
  "Terry, Taron-Sadirac-Viellenave, Nouvelle-Aquitaine, France",
  "Marshall County, Tennessee, United States",
  "G42 0AA, Glasgow, Scotland, United Kingdom",
  "Ashland, California, United States",
  "Tulungagung Regency, East Java, Indonesia",
  "Benkovac, Zadar, Croatia",
  "238313, Gur'yevsk, Kaliningrad, Russia",
  "New Hanover County, North Carolina, United States",
  "27814, Blounts Creek, North Carolina, United States",
  "188990, Svetogorsk, Leningrad, Russia",
  "Chornomorivka, Kherson, Ukraine",
  "B6L 5C6, Central Onslow, Nova Scotia, Canada",
  "100200, Ploieşti, Prahova, Romania",
  "655100, Ust’-Abakan, Khakassia, Russia",
  "440068, Satu Mare, Satu Mare, Romania",
  "34096, Istanbul, Istanbul, Türkiye",
  "Morgan County, Illinois, United States",
  "76135, Fort Worth, Texas, United States",
  "Kewaunee County, Wisconsin, United States",
  "Greater Windsor Metropolitan Area",
  "Tepelenë, Gjirokastër, Albania",
  "426054, Izhevsk, Udmurtia, Russia",
  "Oldham County, Kentucky, United States",
  "55120, Mainz, Rhineland-Palatinate, Germany",
  "Burnett, Arkansas, United States",
  "Cambridge, England, United Kingdom",
  "Cherlakskiy, Omsk, Russia",
  "Pierce County, Georgia, United States",
  "Gliwice, Śląskie, Poland",
  "23703, Portsmouth, Virginia, United States",
  "97310, Kourou, Cayenne, French Guiana",
  "Shadrinsk, Kurgan, Russia",
  "659306, Biysk, Altai Krai, Russia",
  "Pershing Park, College Station, Texas, United States",
  "Mount Edgecombe, Runaway Bay, Saint Ann, Jamaica",
  "13417, Tallinn, Harjumaa, Estonia",
  "Buffalo County, Wisconsin, United States",
  "169300, Ukhta, Komi, Russia",
  "GY2 4BG, St Sampson, Guernsey, Guernsey",
  "29670, Pendleton, South Carolina, United States",
  "678729, Okhotskiy Perevoz, Sakha, Russia",
  "Plymouth County, Iowa, United States",
  "Leeds, North Dakota, United States",
  "Webb, Alabama, United States",
  "County Kilkenny, Ireland",
  "141313, Sergiyev Posad, Moscow, Russia",
  "Michurinskoye, Volgograd, Russia",
  "Almaty, Almaty, Kazakhstan",
  "10420, Les Noës-près-Troyes, Grand Est, France",
  "Nasik, Maharashtra, India",
  "Hopewell Township, New Jersey, United States",
  "Dorchester, New Jersey, United States",
  "Skagit County, Washington, United States",
  "Fort McDowell, Arizona, United States",
  "Zilina, Slovakia",
  "Chelan Tisso, Diphu, Assam, India",
  "6010, Innsbruck, Tyrol, Austria",
  "607232, Arzamas, Nizhniy Novgorod, Russia",
  "450068, Ufa, Bashkortostan, Russia",
  "17976, Shenandoah, Pennsylvania, United States",
  "Jackson County, Missouri, United States",
  "Keith, Scotland, United Kingdom",
  "252 46, Helsingborg, Skåne County, Sweden",
  "Wayne, Pennsylvania, United States",
  "Reynolds, Manitoba, Canada",
  "KA6 7HB, Rankinston, Scotland, United Kingdom",
  "70125, New Orleans, Louisiana, United States",
  "Greater Venice Metropolitan Area",
  "Ploieşti, Prahova, Romania",
  "64649, Kidder, Missouri, United States",
  "Wyandotte County, Kansas, United States",
  "Scarborough, Tobago, Trinidad and Tobago",
  "Inverness, California, United States",
  "Crockett, Haut-Madawaska, New Brunswick, Canada",
  "Cheyenne County, Nebraska, United States",
  "367003, Makhachkala, Dagestan, Russia",
  "5140, Lyaskovets, Veliko Turnovo, Bulgaria",
  "Bernalillo, New Mexico, United States",
  "392543, Sovkhoza Komsomolets poselok, Tambov, Russia",
  "6528, Bellinzona, Ticino, Switzerland",
  "694420, Aleksandrovsk-Sakhalinskiy, Sakhalin, Russia",
  "Appleton, Wisconsin, United States",
  "57600, Sinop, Sinop, Türkiye",
  "Sandersville, Mississippi, United States",
  "Morrisonville, Illinois, United States",
  "Isla Clarión, Colima, Mexico",
  "19645-959, Nantes, São Paulo, Brazil",
  "Metkovi, Dubrovnik-Neretva, Croatia",
  "Cayuga County, New York, United States",
  "98158, Messina, Sicily, Italy",
  "Nyêmo County, Tibet, China",
  "Balıkesir, Türkiye",
  "2618, Lillehammer, Innlandet, Norway",
  "00141, Rome, Latium, Italy",
  "8510, Frauenfeld, Thurgau, Switzerland",
  "Australia and New Zealand",
  "665806, Angarsk, Irkutsk, Russia",
  "Terry, Labastide-Marnhac, Occitanie, France",
  "Southampton, New York, United States",
  "52221, Guernsey, Iowa, United States",
  "152170, Borisoglebskiy, Yaroslavl’, Russia",
  "Wheatland, Wyoming, United States",
  "Maury, Richmond, Virginia, United States",
  "Warren County, Kentucky, United States",
  "Wagoner County, Oklahoma, United States",
  "Salgótarján, Hungary",
  "Barry, Wales, United Kingdom",
  "County Durham, England, United Kingdom",
  "Midland, Missouri, United States",
  "400430, Cluj-Napoca, Cluj, Romania",
  "12760, Long Eddy, New York, United States",
  "65-409, Zielona Góra, Lubuskie, Poland",
  "Yegor'yevskiy, Orenburg, Russia",
  "35987, Steele, Alabama, United States",
  "4624, Kristiansand, Agder, Norway",
  "Fairfield, New South Wales, Australia",
  "7625, Pécs, Baranya, Hungary",
  "Moscow, Idaho, United States",
  "23001, Jaén, Andalusia, Spain",
  "Allen County, Kansas, United States",
  "Petersburg City County, Virginia, United States",
  "Rémire-Montjoly, Cayenne, French Guiana",
  "1094, Budapest, Budapest, Hungary",
  "Ottersheim, Rhineland-Palatinate, Germany",
  "Amsterdam, Mpumalanga, South Africa",
  "Gregory Hills, New South Wales, Australia",
  "BH6 3AA, Bournemouth, England, United Kingdom",
  "CF61 1AB, Llantwit Major, Wales, United Kingdom",
  "Rivière-du-Loup, Quebec, Canada",
  "353900, Novorossiysk, Krasnodar, Russia",
  "47058, Duisburg, North Rhine-Westphalia, Germany",
  "632867, Karasuk, Novosibirsk, Russia",
  "La Croix du Roseau, Boisseuil, Nouvelle-Aquitaine, France",
  "Río Blanco, Veracruz, Mexico",
  "Newcastle, Nebraska, United States",
  "70000, Laayoune, Laâyoune-Sakia El Hamra, Morocco",
  "24069, Cascade, Virginia, United States",
  "Buyukcekmece, Istanbul, Türkiye",
  "369281, Mednogorsky, Karachay-Cherkessia, Russia",
  "Panola, Mahoor, Maharashtra, India",
  "Nin, Zadar, Croatia",
  "Washburn, Missouri, United States",
  "Crocketts Bluff, Arkansas, United States",
  "Pennington, Texas, United States",
  "Tateposco, Jalisco, Mexico",
  "172512, Nelidovo, Tver’, Russia",
  "81623, Carbondale, Colorado, United States",
  "Real, Calabarzon, Philippines",
  "5400-397, Chaves, Vila Real, Portugal",
  "Roane, Saint-Antonin, Occitanie, France",
  "624474, Severoural’sk, Sverdlovsk, Russia",
  "658200, Rubtsovsk, Altai Krai, Russia",
  "430010, Saransk, Mordovia, Russia",
  "Jasper, Florida, United States",
  "857, Sundsvall, Västernorrland County, Sweden",
  "El Alfalfar, Álvaro Obregón, Mexico City, Mexico",
  "55021, Faribault, Minnesota, United States",
  "618262, Gubakha, Perm, Russia",
  "Taylor, Arizona, United States",
  "Rogerstone, Wales, United Kingdom",
  "Pitt County, North Carolina, United States",
  "98149, Messina, Sicily, Italy",
  "R7N 3L7, Dauphin, Manitoba, Canada",
  "Novovoronezh, Voronezh, Russia",
  "71010, Port Dickson, Negri Sembilan, Malaysia",
  "58050, Sivas, Sivas, Türkiye",
  "Gävle, Gävleborg County, Sweden",
  "Winona Lake, Indiana, United States",
  "641473, Kurtamysh, Kurgan, Russia",
  "628010, Khanty-Mansiysk, Khanty-Mansi, Russia",
  "1120, Vienna, Vienna, Austria",
  "93280, Wasco, California, United States",
  "Harrison, Maine, United States",
  "Martinsville, Missouri, United States",
  "Mentese, Muğla, Türkiye",
  "456872, Kyshtym, Chelyabinsk, Russia",
  "390044, Ryazan, Ryazan’, Russia",
  "B1 1UD, Birmingham, England, United Kingdom",
  "652150, Mariinskiy, Kemerovo, Russia",
  "10430, Rosières-près-Troyes, Grand Est, France",
  "Voznesenskoye, Ivanovo, Russia",
  "Oxford, Georgia, United States",
  "Clay County, Arkansas, United States",
  "Hawkins Place, Wright, Florida, United States",
  "44-109, Gliwice, Śląskie, Poland",
  "Brooke-Alvinston, Ontario, Canada",
  "KA18 2RR, Ochiltree, Scotland, United Kingdom",
  "3283, Killarney, Victoria, Australia",
  "Appomattox Historic District, Appomattox, Virginia, United States",
  "665415, Cheremkhovo, Irkutsk, Russia",
  "Huntington Station, New York, United States",
  "Kayapınar, Diyarbakır, Türkiye",
  "29406, Charleston, South Carolina, United States",
  "249038, Obninsk, Kaluga, Russia",
  "Almetyevsk, Tatarstan, Russia",
  "Sunflower, Rondonópolis, Mato Grosso, Brazil",
  "Ingolstadt, Bavaria, Germany",
  "Newport, North Carolina, United States",
  "NR24 2RJ, Edgefield, England, United Kingdom",
  "Cavtat, Dubrovnik-Neretva, Croatia",
  "53141, Kenosha, Wisconsin, United States",
  "3011 AA, Rotterdam, South Holland, Netherlands",
  "Mason County, Texas, United States",
  "37876, Sevierville, Tennessee, United States",
  "Johnson County, Indiana, United States",
  "Titusville, Birmingham, Alabama, United States",
  "27214, Browns Summit, North Carolina, United States",
  "Oneida, Wisconsin, United States",
  "346908, Novoshakhtinsk, Rostov, Russia",
  "R6W 1S7, Winkler, Manitoba, Canada",
  "Winona, Minnesota, United States",
  "671, Kiowa Rural, Kansas, United States",
  "52100, Mikkeli, South Savo, Finland",
  "R25H5G0, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "Michalovce, Kosice, Slovakia",
  "Belogorskiy, Arkhangel’sk, Russia",
  "Shilka, Zabaykalsky Krai, Russia",
  "Angersbach, Hesse, Germany",
  "B61C8F1, Sarqan, Jetisu Region, Kazakhstan",
  "249662, Kirsanovo-Pyatnitsa, Kaluga, Russia",
  "Pinellas County, Florida, United States",
  "Pennington, Minnesota, United States",
  "254 54, Helsingborg, Skåne County, Sweden",
  "Nahirianka, Ternopil, Ukraine",
  "16110, Bursa, Bursa, Türkiye",
  "South Antelope Valley, California, United States",
  "94703, Berkeley, California, United States",
  "Marshall County, Minnesota, United States",
  "Crested Butte, Colorado, United States",
  "320232, Reşiţa, Caraş-Severin, Romania",
  "Volgodonskoy, Volgograd, Russia",
  "75856, Pennington, Texas, United States",
  "Hooker, Oklahoma, United States",
  "791 46, Falun, Dalarna County, Sweden",
  "Les Abymes, Pointe-à-Pitre, Guadeloupe",
  "Newton Mearns, Scotland, United Kingdom",
  "Morris, Manitoba, Canada",
  "Nauru",
  "989, Yakima, Washington, United States",
  "Chester Springs, Pennsylvania, United States",
  "C19C2M0, Zhaltyr, Akmola Region, Kazakhstan",
  "Fallston, Maryland, United States",
  "2521 AA, The Hague, South Holland, Netherlands",
  "Shelby County, Iowa, United States",
  "03300, Afyonkarahisar, Afyon, Türkiye",
  "85602, Benson, Arizona, United States",
  "95129, Catania, Sicily, Italy",
  "Surry, Virginia, United States",
  "Vladimirescu, Arad, Romania",
  "690002, Vladivostok, Primorye, Russia",
  "Krasnoarmeyskoye, Chuvashia, Russia",
  "665831, Angarsk, Irkutsk, Russia",
  "Northampton, Massachusetts, United States",
  "B6720CNH, San Andrés de Giles, Buenos Aires Province, Argentina",
  "Vaduz, Liechtenstein",
  "Tarrant Keyneston, England, United Kingdom",
  "Medina-Sidonia, Andalusia, Spain",
  "22556, Stafford, Virginia, United States",
  "21224, Baltimore, Maryland, United States",
  "Goa, India",
  "73032, Kherson, Kherson, Ukraine",
  "611 33, Nyköping, Södermanland County, Sweden",
  "65770-000, Governador Archer, Maranhão, Brazil",
  "404105, Volzhskiy, Volgograd, Russia",
  "Kholmsk, Sakhalin, Russia",
  "90510, Torrance, California, United States",
  "Alcalá de Henares, Community of Madrid, Spain",
  "Loraine Gold Mine, Matjhabeng, Free State, South Africa",
  "Weston, Nebraska, United States",
  "Kristianstad, Skåne County, Sweden",
  "654059, Novokuznetsk, Kemerovo, Russia",
  "Peck, Michigan, United States",
  "59804, Missoula, Montana, United States",
  "ST18 0QZ, Little Haywood, England, United Kingdom",
  "EH12 0BD, Edinburgh, Scotland, United Kingdom",
  "Dumfries And Galloway, Scotland, United Kingdom",
  "76227, Karlsruhe, Baden-Württemberg, Germany",
  "166744, Amderma, Nenets, Russia",
  "Ferguson, Pennsylvania, United States",
  "NR25 6PH, West Beckham, England, United Kingdom",
  "371 41, Karlskrona, Blekinge County, Sweden",
  "Stark City, Missouri, United States",
  "5300, Karcag, Jász-Nagykun-Szolnok, Hungary",
  "Yaoundé, Centre, Cameroon",
  "47899, Serravalle, San Marino",
  "R25A4D3, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "KW15 1RL, Kirkwall, Scotland, United Kingdom",
  "Jeff Davis County, Georgia, United States",
  "8061 ZN, Hasselt, Overijssel, Netherlands",
  "238312, Gur'yevsk, Kaliningrad, Russia",
  "623301, Krasnoufimsk, Sverdlovsk, Russia",
  "J1T 0A8, Asbestos, Quebec, Canada",
  "Cayuga, New York, United States",
  "Quay, Portraine, Fingal, Ireland",
  "443001, Samara, Samara, Russia",
  "252 24, Helsingborg, Skåne County, Sweden",
  "6011, Kecskemét, Bács-Kiskun, Hungary",
  "Pennington, Arkansas, United States",
  "1670, Pepingen, Flemish Region, Belgium",
  "75-201, Koszalin, Zachodniopomorskie, Poland",
  "30000, Hakkâri, Hakkâri, Türkiye",
  "Sveti Nikole, Vardar, North Macedonia",
  "Fannin Terrace, Midland, Texas, United States",
  "Seri Kembangan, Malaysia",
  "C22A3K8, Atbasar, Akmola Region, Kazakhstan",
  "680 11, Alexandroúpolis, Eastern Macedonia and Thrace, Greece",
  "385003, Maykop, Adygea, Russia",
  "St Petersburg, Florida, United States",
  "70600, Kuopio, Northern Savonia, Finland",
  "Greater Braga Area",
  "Salamanca, Coquimbo Region, Chile",
  "Bodo Centre, Logone Oriental, Chad",
  "F63D2A9, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "West Slope, Oregon, United States",
  "35211, Birmingham, Alabama, United States",
  "8005, Burrel, Dibrë, Albania",
  "500081, Hyderabad, Telangana, India",
  "Cambridge, Wisconsin, United States",
  "301664, Novomoskovsk, Tula, Russia",
  "Chernyakhovsk, Kaliningrad, Russia",
  "Galliano, Sanfront, Piedmont, Italy",
  "Culpeper, Virginia, United States",
  "Tarragona, Catalonia, Spain",
  "Pender Island, British Columbia, Canada",
  "Union County, Pennsylvania, United States",
  "Cumberland County, Pennsylvania, United States",
  "Fundación, Magdalena, Colombia",
  "Chistopol'skoye, Kirov, Russia",
  "Chapayevskoye, Kostroma, Russia",
  "23060, Glen Allen, Virginia, United States",
  "37129, Verona, Veneto, Italy",
  "94596, Walnut Creek, California, United States",
  "Rice, Virginia, United States",
  "85700, Montournais, Pays de la Loire, France",
  "E25C4A6, Maqat, Atyrau Region, Kazakhstan",
  "BT27 5FJ, Lisburn, Northern Ireland, United Kingdom",
  "OX10 6NJ, Benson, England, United Kingdom",
  "404132, Volzhskiy, Volgograd, Russia",
  "Blagoveshchenka, Altai Krai, Russia",
  "620010, Yekaterinburg, Sverdlovsk, Russia",
  "93313, Bakersfield, California, United States",
  "Cherkessk, Karachay-Cherkessia, Russia",
  "67505, South Hutchinson, Kansas, United States",
  "E1A 8L8, Dieppe, New Brunswick, Canada",
  "142121, Podolsk, Moscow, Russia",
  "Collins Chabane, Limpopo, South Africa",
  "Montour, Baccon, Centre-Val de Loire, France",
  "Milan, Tennessee, United States",
  "43001, Tarragona, Catalonia, Spain",
  "78702, Austin, Texas, United States",
  "42211, Cadiz, Kentucky, United States",
  "Roanoke City County, Virginia, United States",
  "Belomorsky District, Karelia, Russia",
  "Clinton County, New York, United States",
  "F92 AX82, Letterkenny, County Donegal, Ireland",
  "Morris, Connecticut, United States",
  "16946, Tioga, Pennsylvania, United States",
  "Gaston, Cussac-Fort-Médoc, Nouvelle-Aquitaine, France",
  "12914-040, Bragança Paulista, São Paulo, Brazil",
  "Wood County, Texas, United States",
  "Viljandimaa, Estonia",
  "Vietnam",
  "Hallein, Salzburg, Austria",
  "Voznesensk, Mykolayiv, Ukraine",
  "Aiken, South Carolina, United States",
  "Pokrovska silska hromada, Dnipropetrovsk, Ukraine",
  "Marinette County, Wisconsin, United States",
  "6828 HG, Arnhem, Gelderland, Netherlands",
  "UB3 3PJ, Hayes, England, United Kingdom",
  "Devarapalli, Andhra Pradesh, India",
  "06008, Badajoz, Extremadura, Spain",
  "69091, Zaporizhzhya, Zaporizhzhya, Ukraine",
  "F63D0K4, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "610120, Piatra Neamţ, Neamţ, Romania",
  "Pope County, Arkansas, United States",
  "423578, Nizhnekamsk, Tatarstan, Russia",
  "Mérida Metropolitan Area",
  "Manchester, Ohio, United States",
  "4025, Debrecen, Hajdú-Bihar, Hungary",
  "00510, Helsinki, Uusimaa, Finland",
  "6003, Libohovë, Gjirokastër, Albania",
  "450098, Ufa, Bashkortostan, Russia",
  "Trnava Region, Slovakia",
  "Namur, Quebec, Canada",
  "Pisa, Tuscany, Italy",
  "641431, Kurtamysh, Kurgan, Russia",
  "8301, Peshkopi, Dibrë, Albania",
  "Himara, Vlorë, Albania",
  "Torrance, Scotland, United Kingdom",
  "Laayoune, Laâyoune-Sakia El Hamra, Morocco",
  "6200-570, Covilhã, Castelo Branco, Portugal",
  "LaFayette, Georgia, United States",
  "346800, Bataysk, Rostov, Russia",
  "Wheatland, Iowa, United States",
  "632338, Barabinsk, Novosibirsk, Russia",
  "Bedford, Nova Scotia, Canada",
  "Richmond Hill, New York, New York, United States",
  "Peesangan, Rajasthan, India",
  "2765, Nelson, New South Wales, Australia",
  "Brookings, Oregon, United States",
  "04009, Almería, Andalusia, Spain",
  "South Windham, Connecticut, United States",
  "Vela Luka, Dubrovnik-Neretva, Croatia",
  "Morgan County, Missouri, United States",
  "Şalqar District, Aqtöbe region, Kazakhstan",
  "214 67, Malmo, Skåne County, Sweden",
  "K9H 1H8, Peterborough, Ontario, Canada",
  "Stone County, Mississippi, United States",
  "Hennepin, Oklahoma, United States",
  "78003, Bandera, Texas, United States",
  "68132, Omaha, Nebraska, United States",
  "C19F5A0, Zhaltyr, Akmola Region, Kazakhstan",
  "Manchester, Connecticut, United States",
  "Eastland Estates, Providence, North Carolina, United States",
  "Saratoga, New York, United States",
  "185 00, Piraeus, Attiki, Greece",
  "Saintes-Maries-de-la-Mer, Provence-Alpes-Côte d'Azur, France",
  "09128, Cagliari, Sardinia, Italy",
  "CO16 0DY, Thorpe-Le-Soken, England, United Kingdom",
  "Los Angeles, California, United States",
  "MD-2001, Chişinău, Chișinău, Moldova",
  "Ridderstien, Region of Southern Denmark, Denmark",
  "628305, Nefteyugansk, Khanty-Mansi, Russia",
  "Sunshine Coast, Queensland, Australia",
  "34711, Clermont, Florida, United States",
  "98628, Klickitat, Washington, United States",
  "Richmond Hill, Georgia, United States",
  "Waterford, Western Australia, Australia",
  "48700, Muğla, Muğla, Türkiye",
  "430, Ísafjörður, Westfjords, Iceland",
  "75017, Paris, Île-de-France, France",
  "33052, Marathon, Florida, United States",
  "Montour Falls, New York, United States",
  "Örnsköldsvik, Västernorrland County, Sweden",
  "Kapan, Nepal",
  "Sennecey-lès-Dijon, Bourgogne-Franche-Comté, France",
  "LS7 4SP, Leeds, England, United Kingdom",
  "450142, Zalău, Sălaj, Romania",
  "Shirvan District, Shirvan - Salyan, Azerbaijan",
  "49100, Angers, Pays de la Loire, France",
  "Cass County, Illinois, United States",
  "Dorohoi, Botoşani, Romania",
  "Osipovichskiy gorodskoy sovet, Mahilyow, Belarus",
  "Aitkin County, Minnesota, United States",
  "38478, Pulaski, Tennessee, United States",
  "Lane County, Oregon, United States",
  "Hays, Montana, United States",
  "163046, Archangel, Arkhangel’sk, Russia",
  "Grimestad, Tjøme, Vestfold og Telemark, Norway",
  "McCall Street Historic District, Waukesha, Wisconsin, United States",
  "10136, Turin, Piedmont, Italy",
  "080644, Giurgiu, Giurgiu, Romania",
  "Timişoara, Timiş, Romania",
  "611 30, Nyköping, Södermanland County, Sweden",
  "Jamestown, Kentucky, United States",
  "40, Stavanger/Sandnes, Rogaland, Norway",
  "9016, Tromsø, Troms og Finnmark, Norway",
  "Chita, Tatarstan, Russia",
  "Reynolds, Illinois, United States",
  "Williamson, New York, United States",
  "R6W 1W7, Winkler, Manitoba, Canada",
  "236013, Kaliningrad, Kaliningrad, Russia",
  "6843 AA, Arnhem, Gelderland, Netherlands",
  "Scott County, Indiana, United States",
  "Phillips, Wisconsin, United States",
  "Orangeburg East, Modesto, California, United States",
  "60960, Rankin, Illinois, United States",
  "Hillvue, New South Wales, Australia",
  "Clearwater, Florida, United States",
  "Boone County, Iowa, United States",
  "5537, Haugesund, Rogaland, Norway",
  "Winter Park, Florida, United States",
  "Odessa, Washington, United States",
  "Duvall Street",
  "Oceano, California, United States",
  "071 01, Michalovce, Kosice, Slovakia",
  "2309, Pernik, Pernik, Bulgaria",
  "X12P6P2, Shymkent, Shymkent, Kazakhstan",
  "La Médina, Sfax, Tunisia",
  "Sutton Bonington, England, United Kingdom",
  "62908, Belknap, Illinois, United States",
  "Trondheim, Trøndelag, Norway",
  "HU3 1LY, Kingston Upon Hull, England, United Kingdom",
  "J0E 1Y0, Racine, Quebec, Canada",
  "81224, Crested Butte, Colorado, United States",
  "Greater Izmir",
  "300001, Timişoara, Timiş, Romania",
  "Greater Esmeraldas Area",
  "Greater Sierra Vista-Douglas Area",
  "NE10 0TE, Gateshead, England, United Kingdom",
  "3142, Tønsberg, Vestfold og Telemark, Norway",
  "618245, Chusovoy, Perm, Russia",
  "Robertson, Virginia, United States",
  "31001, Pamplona, Chartered Community of Navarre, Spain",
  "Mooncoin, County Kilkenny, Ireland",
  "Kenedy, Texas, United States",
  "87031, Los Lunas, New Mexico, United States",
  "G5 0AB, Glasgow, Scotland, United Kingdom",
  "15701, Santiago de Compostela, Galicia, Spain",
  "Partizanskiy, Krasnoyarsk Krai, Russia",
  "28909-900, Cabo Frio, Rio de Janeiro, Brazil",
  "LE12 6RS, East Leake, England, United Kingdom",
  "117628, Moscow, Moscow City, Russia",
  "Gregory, Michigan, United States",
  "Staunton, Gloucester, England, United Kingdom",
  "Mala Liubasha, Rivne, Ukraine",
  "Kanashskoye, Tatarstan, Russia",
  "Adana Subregion, Türkiye",
  "Lyon County, Iowa, United States",
  "632862, Karasuk, Novosibirsk, Russia",
  "Fairfield, California, United States",
  "Fairfield, Virginia, United States",
  "OX10 6AA, Benson, England, United Kingdom",
  "Warren County, Missouri, United States",
  "Upshur County, Texas, United States",
  "Bruley, Grand Est, France",
  "Vaciamadrid, Community of Madrid, Spain",
  "E4M 2A9, Bayfield, New Brunswick, Canada",
  "9034, Klagenfurt, Carinthia, Austria",
  "Progress, Amur, Russia",
  "Greater Aberdeen Area",
  "Cumberland, Subdivision C, Nova Scotia, Canada",
  "Baku Ekonomic Zone, Azerbaijan",
  "Phillips County, Kansas, United States",
  "Llano County, Texas, United States",
  "Arrast-Larrebieu, Nouvelle-Aquitaine, France",
  "Orange County, Virginia, United States",
  "IP13 0WE, Pettistree, England, United Kingdom",
  "1004, Lausanne, Vaud, Switzerland",
  "Webbers Falls, Oklahoma, United States",
  "67877, Sublette, Kansas, United States",
  "Tyler, Alabama, United States",
  "Jamestown, North Dakota, United States",
  "Sumner County, Kansas, United States",
  "8701, Ritchie, Northern Cape, South Africa",
  "Gibsonton, Florida, United States",
  "Konya, Türkiye",
  "Liberty Hill, Texas, United States",
  "Randall Mall, North Randall, Ohio, United States",
  "23105, Amelia Court House, Virginia, United States",
  "71913, Hot Springs, Arkansas, United States",
  "72000, Batman, Batman, Türkiye",
  "Țintești, Buzău, Romania",
  "Sauk Creek Condominum, Madison, Wisconsin, United States",
  "Comanche, San Antonio, Texas, United States",
  "Les Roques Bertières, Varages, Provence-Alpes-Côte d'Azur, France",
  "Serpukhov, Moscow, Russia",
  "Hettinger, North Dakota, United States",
  "86324, Comalcalco, Tabasco, Mexico",
  "59350, Saint-André-Lez-Lille, Hauts-de-France, France",
  "Watauga, Tennessee, United States",
  "Bielany, Mazowieckie, Poland",
  "35-310, Rzeszów, Podkarpackie, Poland",
  "Metz, West Virginia, United States",
  "403541, Frolovo, Volgograd, Russia",
  "94707, Berkeley, California, United States",
  "Sumter County, Georgia, United States",
  "347375, Volgodonsk, Rostov, Russia",
  "Grafton, West Virginia, United States",
  "Chita, Zabaykalsky Krai, Russia",
  "620090, Focşani, Vrancea, Romania",
  "68161, Mannheim, Baden-Württemberg, Germany",
  "Cass County, Texas, United States",
  "452006, Belebey, Bashkortostan, Russia",
  "Noble, Illinois, United States",
  "58480, Sanborn, North Dakota, United States",
  "Kostromskoy District, Kostroma, Russia",
  "7755, Visby, North Denmark Region, Denmark",
  "Chippewa, Michigan, United States",
  "Hamlin, Pennsylvania, United States",
  "Vladimir-Volynskiy, Volyn, Ukraine",
  "Rowan County, North Carolina, United States",
  "628681, Megion, Khanty-Mansi, Russia",
  "Glen Carbon, Illinois, United States",
  "Big Bear Lake, California, United States",
  "Veliko Tŭrnovo, Veliko Turnovo, Bulgaria",
  "Pierce, Nebraska, United States",
  "Dillon, Plaine-et-Vallées, Nouvelle-Aquitaine, France",
  "Severnyy, Novosibirsk, Russia",
  "Tillman, Mobile, Alabama, United States",
  "59863, Ravalli, Montana, United States",
  "Pikesville, Maryland, United States",
  "06123, Perugia, Umbria, Italy",
  "629004, Salekhard, Yamalo-Nenets, Russia",
  "110073, Piteşti, Argeş, Romania",
  "4020, Linz, Upper Austria, Austria",
  "Embilmeegama, Central Province, Sri Lanka",
  "Washington North, Kenosha, Wisconsin, United States",
  "35200, Rennes, Brittany, France",
  "Washburn, Maine, United States",
  "254 38, Helsingborg, Skåne County, Sweden",
  "43050, Kutahya, Kütahya, Türkiye",
  "Brighton, Ontario, Canada",
  "Overton, England, United Kingdom",
  "Presidio de Morales, Guanajuato, Mexico",
  "Niksar, Tokat, Türkiye",
  "Barnwell, Alberta, Canada",
  "14464, Hamlin, New York, United States",
  "981 42, Kiruna, Norrbotten County, Sweden",
  "Elk City, Oklahoma, United States",
  "Bolivar County, Mississippi, United States",
  "Emporia, Virginia, United States",
  "73470, Nances, Auvergne-Rhône-Alpes, France",
  "142119, Podolsk, Moscow, Russia",
  "E7K 2V9, Gregg Settlement, New Brunswick, Canada",
  "Sainte-Sabine, Saint-Antonin, Occitanie, France",
  "383 33, Vólos, Thessalia, Greece",
  "Asherton, Texas, United States",
  "07950, Morris Plains, New Jersey, United States",
  "Cameroon",
  "607224, Arzamas, Nizhniy Novgorod, Russia",
  "169311, Ukhta, Komi, Russia",
  "Noland, Arkansas, United States",
  "25523, Hamlin, West Virginia, United States",
  "4842, Arendal, Agder, Norway",
  "E7G 2M4, Plaster Rock, New Brunswick, Canada",
  "Fairview-North Olmsted East, Ohio, United States",
  "Osijek-Baranja, Croatia",
  "3373 AS, Hardinxveld-Giessendam, South Holland, Netherlands",
  "Nisa, Portalegre, Portugal",
  "Redhill, England, United Kingdom",
  "NR25 6PQ, West Beckham, England, United Kingdom",
  "Indiana County, Pennsylvania, United States",
  "Pechea, Galaţi, Romania",
  "8613, Mo i Rana, Nordland, Norway",
  "42300, Roanne, Auvergne-Rhône-Alpes, France",
  "Melun, Île-de-France, France",
  "Chesterfield, Virginia, United States",
  "Campbell, New York, United States",
  "142204, Serpukhov, Moscow, Russia",
  "07013, Palma, Balearic Islands, Spain",
  "Newcastle, California, United States",
  "Pugachevskiy, Bashkortostan, Russia",
  "47006, Valladolid, Castilla and Leon, Spain",
  "362002, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "Kaufman County, Texas, United States",
  "Lecce, Apulia, Italy",
  "632956, Verkh-Kargat, Novosibirsk, Russia",
  "Älvkarleby, Uppsala County, Sweden",
  "Mons, Auvergne-Rhône-Alpes, France",
  "Coshocton County, Ohio, United States",
  "97138, Mérida, Yucatán, Mexico",
  "724 81, Västerås, Västmanland County, Sweden",
  "Le Roseau, Prunières, Provence-Alpes-Côte d'Azur, France",
  "18212, Lehighton, Pennsylvania, United States",
  "Mingo, Iowa, United States",
  "Greater George Town",
  "Seward, Webb 138, Saskatchewan, Canada",
  "Grenchen, Solothurn, Switzerland",
  "Loup City, Nebraska, United States",
  "01867, Reading, Massachusetts, United States",
  "37110, McMinnville, Tennessee, United States",
  "Zheleznogorsk, Krasnoyarsk Krai, Russia",
  "1229, Tetovo, Polog, North Macedonia",
  "72028, Choctaw, Arkansas, United States",
  "25504, Barboursville, West Virginia, United States",
  "61367, Sublette, Illinois, United States",
  "Liberty Lake, Washington, United States",
  "8500-818, Portimão, Faro, Portugal",
  "S0A 0A5, Grayson, Saskatchewan, Canada",
  "62088, Staunton, Illinois, United States",
  "Saint-Jean, Quebec, Canada",
  "70124, Bari, Apulia, Italy",
  "Clarendon, Michigan, United States",
  "Patrick AFB, Florida, United States",
  "2629, Lillehammer, Innlandet, Norway",
  "Lunenburg, Lower Saxony, Germany",
  "73066, Choctaw, Oklahoma, United States",
  "Schleicher County, Texas, United States",
  "Newport County, Rhode Island, United States",
  "Albany, Texas, United States",
  "Norfolk Island",
  "B25B1B7, Esik, Almaty Region, Kazakhstan",
  "Niagara, Ontario, Canada",
  "Ménard, Pénestin, Brittany, France",
  "Arar, Northern Frontier, Saudi Arabia",
  "628012, Khanty-Mansiysk, Khanty-Mansi, Russia",
  "Ust'-Vel'skoye, Arkhangel’sk, Russia",
  "Okmulgee, Oklahoma, United States",
  "462271, Mednogorsk, Orenburg, Russia",
  "662546, Lesosibirsk, Krasnoyarsk Krai, Russia",
  "Teufen, Appenzell Outer-Rhoden, Switzerland",
  "3064, Craigieburn, Victoria, Australia",
  "Hanson Ranch, Calgary, Alberta, Canada",
  "Nicey, Bourgogne-Franche-Comté, France",
  "Obshtina Svilengrad, Khaskovo, Bulgaria",
  "Noble-Contrée, Valais, Switzerland",
  "Greenwood, Mississippi, United States",
  "618502, Solikamsk, Perm, Russia",
  "Travis Heights, Austin, Texas, United States",
  "Lunenburg, Ontario, Canada",
  "Blount County, Alabama, United States",
  "Clearwater, Minnesota, United States",
  "Dent County, Missouri, United States",
  "SA69 9DA, Saundersfoot, Wales, United Kingdom",
  "Cavalier County, North Dakota, United States",
  "6100-258, Sertã, Castelo Branco, Portugal",
  "Bashkia Librazhd, Elbasan, Albania",
  "820002, Tulcea, Tulcea, Romania",
  "32178, Palatka, Florida, United States",
  "06490, Ankara, Ankara, Türkiye",
  "Fairfield, Texas, United States",
  "Stadskanaal, Groningen, Netherlands",
  "662556, Lesosibirsk, Krasnoyarsk Krai, Russia",
  "Dover, Florida, United States",
  "Augusta, Kansas, United States",
  "Le Prébleau, Vornay, Centre-Val de Loire, France",
  "McPherson West, Laredo, Texas, United States",
  "692501, Ussuriysk, Primorye, Russia",
  "Colonial Park, Pennsylvania, United States",
  "23924, Chase City, Virginia, United States",
  "Rakitnoye, Belgorod, Russia",
  "99510, Sodankylä, Lapland, Finland",
  "Lehigh, Pennsylvania, United States",
  "CF63 1AB, Barry, Wales, United Kingdom",
  "Storozhynets Raion, Chernivtsi, Ukraine",
  "618383, Kizel, Perm, Russia",
  "35860-000, Conceição do Mato Dentro, Minas Gerais, Brazil",
  "Gager, Mecklenburg-West Pomerania, Germany",
  "666701, Kirensk, Irkutsk, Russia",
  "NR24 2AQ, Edgefield, England, United Kingdom",
  "686323, Susuman, Magadan, Russia",
  "Buncombe County, North Carolina, United States",
  "420006, Bistriţa, Bistriţa-Năsăud, Romania",
  "452450, Birsk, Bashkortostan, Russia",
  "100011, Ploieşti, Prahova, Romania",
  "34125, Trieste, Friuli-Venezia Giulia, Italy",
  "Pınarhisar, Kırklareli, Türkiye",
  "5700, Zell am See, Salzburg, Austria",
  "422986, Chistopol’, Tatarstan, Russia",
  "Ishim, Tyumen’, Russia",
  "Sierra County, New Mexico, United States",
  "Roanoke, Texas, United States",
  "27107, Winston-Salem, North Carolina, United States",
  "Isanti, Minnesota, United States",
  "Commerce, Georgia, United States",
  "Lac-Beauport, Quebec, Canada",
  "403117, Uryupinsk, Volgograd, Russia",
  "Salem, Massachusetts, United States",
  "99600, Sodankylä, Lapland, Finland",
  "Harrison County, Iowa, United States",
  "341 10, Chalkida, Central Greece, Greece",
  "60183, Wasco, Illinois, United States",
  "Peculiar, Missouri, United States",
  "Dublin, County Dublin, Ireland",
  "503 37, Borås, Västra Götaland County, Sweden",
  "Ty Person, Pont-Melvez, Brittany, France",
  "Cucoara, Cahul District, Moldova",
  "Noril'sk, Taymyr, Russia",
  "31024, Kohtla-Järve, Ida-Virumaa, Estonia",
  "13680, Rensselaer Falls, New York, United States",
  "10461, Bronx, New York, United States",
  "3034 AE, Rotterdam, South Holland, Netherlands",
  "41-910, Bytom, Śląskie, Poland",
  "730187, Vaslui, Vaslui, Romania",
  "Lieu Lorain, Couzon, Auvergne-Rhône-Alpes, France",
  "52100, Arezzo, Tuscany, Italy",
  "Hanover, Virginia, United States",
  "Beaver Dam, Kentucky, United States",
  "18029, Cherkasy, Cherkasy, Ukraine",
  "2790012, Urayasu, Chiba, Japan",
  "662544, Lesosibirsk, Krasnoyarsk Krai, Russia",
  "Negril, Westmoreland, Jamaica",
  "Lewis County, Washington, United States",
  "Astara District, Lankaran-Astara, Azerbaijan",
  "00181, Helsinki, Uusimaa, Finland",
  "Wake Forest, North Carolina, United States",
  "618506, Solikamsk, Perm, Russia",
  "42119, Wuppertal, North Rhine-Westphalia, Germany",
  "Klyuchikovskiy, Novosibirsk, Russia",
  "Somerset, California, United States",
  "Greater Kahramanmaras",
  "Refugio, Texas, United States",
  "346 00, Chalkida, Central Greece, Greece",
  "Palu, Elazığ, Türkiye",
  "628312, Nefteyugansk, Khanty-Mansi, Russia",
  "Saint-Étienne-du-Rouvray, Normandy, France",
  "Marion County, Florida, United States",
  "Gulf Village, Galveston, Texas, United States",
  "546 42, Thessaloniki, Central Macedonia, Greece",
  "420138, Kazan, Tatarstan, Russia",
  "Cherokee, Alabama, United States",
  "M1E 0A1, Scarborough, Ontario, Canada",
  "E4M 1M6, Coburg, New Brunswick, Canada",
  "08032, Barcelona, Catalonia, Spain",
  "PE11 3EJ, Deeping St Nicholas, England, United Kingdom",
  "23322, Chesapeake, Virginia, United States",
  "308000, Belgorod, Belgorod, Russia",
  "89231, Neu Ulm, Bavaria, Germany",
  "357906, Zelenokumsk, Stavropol, Russia",
  "Seville, Andalusia, Spain",
  "Waller County, Texas, United States",
  "2696, Pukekawa, Waikato, New Zealand",
  "Rrogozhinë, Tirana, Albania",
  "Cannonsburg, Kentucky, United States",
  "40390, Cumhuriyet, Kırşehir, Türkiye",
  "Sterling, Illinois, United States",
  "06420, Ankara, Ankara, Türkiye",
  "164507, Severodvinsk, Arkhangel’sk, Russia",
  "Wyandotte, Michigan, United States",
  "Kalmar, Kalmar County, Sweden",
  "Stark County, North Dakota, United States",
  "Vyskatka, Leningrad, Russia",
  "St Nicholas Cliff, Scarborough, England, United Kingdom",
  "18010-000, Sorocaba, São Paulo, Brazil",
  "37214, Nashville, Tennessee, United States",
  "24111, Subotica, Vojvodina, Serbia",
  "694550, Severo-Kurilsk, Sakhalin, Russia",
  "Chesterfield, New Jersey, United States",
  "Foster City, California, United States",
  "Addison, Michigan, United States",
  "Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Alexander, North Dakota, United States",
  "44124, Ferrara, Emilia-Romagna, Italy",
  "Porto, Portugal",
  "397221, Kirsanovka, Voronezh, Russia",
  "Goshen, Alabama, United States",
  "McLennan North, Richmond, British Columbia, Canada",
  "Seine-Maritime, Normandy, France",
  "Frontier, Saskatchewan, Canada",
  "Davidson Lake, Dumfries, New Brunswick, Canada",
  "86179, Augsburg, Bavaria, Germany",
  "6200, Covilhã, Castelo Branco, Portugal",
  "Zhigansk, Sakha, Russia",
  "Las Palmas de Gran Canaria, Canary Islands, Spain",
  "00176, Sargents, New Hampshire, United States",
  "Stoke Sub Hamdon, England, United Kingdom",
  "Addison County, Vermont, United States",
  "300245, Timişoara, Timiş, Romania",
  "02-972, Warsaw, Mazowieckie, Poland",
  "1011, Budapest, Budapest, Hungary",
  "610250, Piatra Neamţ, Neamţ, Romania",
  "Melbourne, Victoria, Australia",
  "97360, Mana, Saint-Laurent-du-Maroni, French Guiana",
  "E28E0B0, Makhambet, Atyrau Region, Kazakhstan",
  "70178, Stuttgart, Baden-Württemberg, Germany",
  "Clearwater, British Columbia, Canada",
  "9811, Vadsø, Troms og Finnmark, Norway",
  "Milam, Texas, United States",
  "Ruski Krstur, Vojvodina, Serbia",
  "Banner, Kentucky, United States",
  "Eksjö, Jönköping County, Sweden",
  "Queens Domain, Tasmania, Australia",
  "Franklin, Pennsylvania, United States",
  "Polski Trambesh, Veliko Turnovo, Bulgaria",
  "Gradys Creek, New South Wales, Australia",
  "Millard, Omaha, Nebraska, United States",
  "16941, Genesee, Pennsylvania, United States",
  "Palma del Río, Andalusia, Spain",
  "White Wolf, California, United States",
  "Sibleyras, Saint-Pierreville, Auvergne-Rhône-Alpes, France",
  "Cavalier, Viens, Provence-Alpes-Côte d'Azur, France",
  "McMinnville, Oregon, United States",
  "Ponta Delgada, Ilha de São Miguel, Portugal",
  "666671, Ust'-Ilimsk, Irkutsk, Russia",
  "Brookston, Indiana, United States",
  "301247, Shchëkino, Tula, Russia",
  "BD20 7JN, Sutton-In-Craven, England, United Kingdom",
  "Aralle, West Sulawesi, Indonesia",
  "357114, Nevinnomyssk, Stavropol, Russia",
  "20632, Faulkner, Maryland, United States",
  "Clearwater County, Alberta, Canada",
  "Upper Austria, Austria",
  "Bathurst, New Brunswick, Canada",
  "Greenwood County, Kansas, United States",
  "2571, Nisou, Nicosia, Cyprus",
  "Adams County, Pennsylvania, United States",
  "Greenville, Wisconsin, United States",
  "46360, Kahramanmaraş, Kahraman Maras, Türkiye",
  "29004, Málaga, Andalusia, Spain",
  "76610, Le Havre, Normandy, France",
  "Rankin Area, Gilmer, North Carolina, United States",
  "Tarazona de Aragón, Aragon, Spain",
  "49623, Chase, Michigan, United States",
  "Blagoveshchensk, Amur, Russia",
  "Greater Sievierodonetsk-Lysychansk Area",
  "Dewitt, New South Wales, Australia",
  "Marlow, England, United Kingdom",
  "70190, Stuttgart, Baden-Württemberg, Germany",
  "Scarborough, Maine, United States",
  "Juniata County, Pennsylvania, United States",
  "Vernon Parish County, Louisiana, United States",
  "Dawson, Ontario, Canada",
  "Makhambet, Atyrau Region, Kazakhstan",
  "79100, Freiburg, Baden-Württemberg, Germany",
  "88206, Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Catawba, South Carolina, United States",
  "Hyde Park, New York, United States",
  "680007, Khabarovsk, Khabarovsk, Russia",
  "West Sussex, England, United Kingdom",
  "02100, Adıyaman, Adıyaman, Türkiye",
  "6074, Sawyers Valley, Western Australia, Australia",
  "680014, Khabarovsk, Khabarovsk, Russia",
  "Clarke County, Virginia, United States",
  "Amizour District, Bejaia, Algeria",
  "Arada, Santa Bárbara, Honduras",
  "29150, Sumter, South Carolina, United States",
  "Linz-Land, Upper Austria, Austria",
  "Newberrys, Appleton, Wisconsin, United States",
  "Toledo, Norte de Santander, Colombia",
  "Novi Pazar, Shumen, Bulgaria",
  "33760, Clearwater, Florida, United States",
  "601907, Kovrov, Vladimir, Russia",
  "Capel Sound, Victoria, Australia",
  "78626, Georgetown, Texas, United States",
  "Orekhovo-Zuyevo, Moscow, Russia",
  "Oktyabr'skiy, Dagestan, Russia",
  "Devachuli, Gandakī, Nepal",
  "46743, Harlan, Indiana, United States",
  "Union, Missouri, United States",
  "Caddo Gap, Arkansas, United States",
  "R7N 2T7, Dauphin, Manitoba, Canada",
  "Swainby, England, United Kingdom",
  "Rogersville, Tennessee, United States",
  "S0N 2S0, Tompkins, Saskatchewan, Canada",
  "20-086, Lublin, Lubelskie, Poland",
  "Călăraşi, Călăraşi, Romania",
  "693008, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "89802, Elko, Nevada, United States",
  "TR20 8TR, Penzance, England, United Kingdom",
  "Prince Edward, Ontario, Canada",
  "Cartaxo, Santarém, Portugal",
  "68600, Izmayil, Odessa, Ukraine",
  "Randolph County, Illinois, United States",
  "37056, Dickson, Tennessee, United States",
  "352 61, Växjö, Kronoberg County, Sweden",
  "Wayne, West Virginia, United States",
  "450039, Ufa, Bashkortostan, Russia",
  "152615, Uglich, Yaroslavl’, Russia",
  "Cottonwood, Arizona, United States",
  "Ma Louisa, Curacao",
  "3925, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Barton Seagrave, Kettering, England, United Kingdom",
  "Gregg, Pennsylvania, United States",
  "55122, Mainz, Rhineland-Palatinate, Germany",
  "23293, Zadar, Zadar, Croatia",
  "8515, Narvik, Nordland, Norway",
  "Wilkesville, Ohio, United States",
  "Grudziądz, Kujawsko-pomorskie, Poland",
  "Renville County, Minnesota, United States",
  "Municipio Libertador, Merida State, Venezuela",
  "Korostenska miska hromada, Zhytomyr, Ukraine",
  "R25A2H3, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "Clark County, Kentucky, United States",
  "Montague, Michigan, United States",
  "West Lafayette, Indiana, United States",
  "Humenné, Presov, Slovakia",
  "College Station-Bryan Area",
  "Yancey, Texas, United States",
  "Delta, Pennsylvania, United States",
  "70-562, Szczecin, Zachodniopomorskie, Poland",
  "Randolph, New York, United States",
  "686433, Evensk, Magadan, Russia",
  "Sokolskoye, Nizhniy Novgorod, Russia",
  "Darke County, Ohio, United States",
  "4861, Pepinster, Walloon Region, Belgium",
  "NG1 2BH, Nottingham, England, United Kingdom",
  "694670, Kholmsk, Sakhalin, Russia",
  "724 76, Västerås, Västmanland County, Sweden",
  "Albany, Louisiana, United States",
  "Burke County, North Dakota, United States",
  "McLean, Ohio, United States",
  "Perpignan, Occitanie, France",
  "Krasnoarmeysky District, Chelyabinsk, Russia",
  "5521, Haugesund, Rogaland, Norway",
  "Glendale Hills, Gilmer, North Carolina, United States",
  "59520, Marquette-lez-Lille, Hauts-de-France, France",
  "Latium, Italy",
  "94702, Berkeley, California, United States",
  "Heiden, Appenzell Outer-Rhoden, Switzerland",
  "Walworth Gate, England, United Kingdom",
  "Kaneyama, Yamagata, Japan",
  "Gijón, Principality of Asturias, Spain",
  "Wheatland, Illinois, United States",
  "Aral District, Kyzylorda Region, Kazakhstan",
  "E7K 2V2, Gregg Settlement, New Brunswick, Canada",
  "Groningen, Netherlands",
  "Craven Wood, Liverpool, England, United Kingdom",
  "Minsk, Minsk, Belarus",
  "Worcester and Kidderminster Area",
  "22848, Harrisonburg, Virginia, United States",
  "Cottonwood Heights, Utah, United States",
  "45100, Orléans, Centre-Val de Loire, France",
  "5780, Lukovit, Lovech, Bulgaria",
  "433501, Dimitrovgrad, Ul’yanovsk, Russia",
  "19130, Philadelphia, Pennsylvania, United States",
  "97219, Mérida, Yucatán, Mexico",
  "Latsia, Nicosia, Cyprus",
  "79112, Freiburg, Baden-Württemberg, Germany",
  "386130, Nazran, Ingushetia, Russia",
  "1011 AC, Amsterdam, North Holland, Netherlands",
  "Beaufort County, South Carolina, United States",
  "Masallı, Lankaran-Astara, Azerbaijan",
  "Bennett Plaza, Plantation, Florida, United States",
  "Gubkin, Belgorod, Russia",
  "Rockland St Mary, England, United Kingdom",
  "Youngtown, Tasmania, Australia",
  "Platte Center, Nebraska, United States",
  "Oguzhan, Mary Region, Turkmenistan",
  "1020, Skopje, Skopje Statistical Region, North Macedonia",
  "Rainsville, Alabama, United States",
  "SO43 7EA, Emery Down, England, United Kingdom",
  "Seneca, South Dakota, United States",
  "M52C1A0, Osakarovka, Karaganda Region, Kazakhstan",
  "L02K9C3, Oral, West Kazakhstan Region, Kazakhstan",
  "Summers, Arkansas, United States",
  "9400, Sopron, Győr-Moson-Sopron, Hungary",
  "94830, Kemi, Lapland, Finland",
  "Pecos Vistas, Chandler, Arizona, United States",
  "20111, Manassas, Virginia, United States",
  "Krakowski, Małopolskie, Poland",
  "Judith Bhering, Coronel Fabriciano, Minas Gerais, Brazil",
  "22078, Turate, Lombardy, Italy",
  "Stanley, Virginia, United States",
  "Shchëkino, Kursk, Russia",
  "DD4 9SH, Dundee, Scotland, United Kingdom",
  "666920, Bodaybo, Irkutsk, Russia",
  "Middlesex County, Massachusetts, United States",
  "Kilkenny, New Hampshire, United States",
  "Ripley, West Virginia, United States",
  "23420, Benkovac, Zadar, Croatia",
  "Pisac, Cusco, Peru",
  "LE1 1AD, Leicester, England, United Kingdom",
  "52310, Ordu, Ordu, Türkiye",
  "Ghent Metropolitan Area",
  "X12X8T6, Shymkent, Shymkent, Kazakhstan",
  "BT79 0NS, Omagh, Northern Ireland, United Kingdom",
  "354065, Sochi, Krasnodar, Russia",
  "09117, Chemnitz, Saxony, Germany",
  "Boyd County, Kentucky, United States",
  "Yıldızeli, Sivas, Türkiye",
  "M28E1F8, Temirtau, Karaganda Region, Kazakhstan",
  "14754, Little Genesee, New York, United States",
  "Mitchell, Victoria, Australia",
  "OL1 1AD, Oldham, England, United Kingdom",
  "Latin America",
  "70100, Kuopio, Northern Savonia, Finland",
  "Krasnyye Klyuchi, Samara, Russia",
  "Grays Harbor County, Washington, United States",
  "654027, Novokuznetsk, Kemerovo, Russia",
  "Ağrı, Türkiye",
  "352392, Kropotkin, Krasnodar, Russia",
  "81000, Podgorica, Montenegro",
  "62404, Kharkiv, Kharkiv, Ukraine",
  "4000, Brisbane City, Queensland, Australia",
  "Makarovskiy, Kursk, Russia",
  "13006, Marseille, Provence-Alpes-Côte d'Azur, France",
  "Umanskiy, Rostov, Russia",
  "Białybór, Kujawsko-pomorskie, Poland",
  "28850, Giresun, Giresun, Türkiye",
  "75013, Paris, Île-de-France, France",
  "662152, Achinsk, Krasnoyarsk Krai, Russia",
  "Lisbon, Iowa, United States",
  "C01F2Y6, Kokshetau, Akmola Region, Kazakhstan",
  "628482, Kogalym, Khanty-Mansi, Russia",
  "23261, Zrenjanin, Vojvodina, Serbia",
  "Bayfield County, Wisconsin, United States",
  "Inverness, Scotland, United Kingdom",
  "Al Hamidiyah, Tripoli, Libya",
  "430033, Baia Mare, Maramureş, Romania",
  "Makiivka, Donetsk, Ukraine",
  "307624, Glazovo, Kursk, Russia",
  "Greater Kassel Area",
  "56688, Wirt, Minnesota, United States",
  "Mozhga, Udmurtia, Russia",
  "Xanəgah, Lankaran-Astara, Azerbaijan",
  "Amsterdam-Zuid, North Holland, Netherlands",
  "Windsor, Virginia, United States",
  "Mingo, Benaguacil, Valencian Community, Spain",
  "Greater Bristol Area, United Kingdom",
  "68664, Snyder, Nebraska, United States",
  "423825, Naberezhnyye Chelny, Tatarstan, Russia",
  "4628, Kristiansand, Agder, Norway",
  "Armstrong, Iowa, United States",
  "Novocherkassk, Rostov, Russia",
  "Churchill County, Nevada, United States",
  "Grainger County, Tennessee, United States",
  "Suiqui Milamilani, La Paz, Bolivia",
  "60131, Ancona, Marches, Italy",
  "00166, Rome, Latium, Italy",
  "Greene County, Iowa, United States",
  "West Sumatra, Indonesia",
  "Greater Sevilla Metropolitan Area",
  "Muskingum, Ohio, United States",
  "74077, Stillwater, Oklahoma, United States",
  "20009, San Sebastián, Basque Country, Spain",
  "Slobozia, Giurgiu, Romania",
  "NAMER",
  "652992, Tashtagol, Kemerovo, Russia",
  "Ottawa, Illinois, United States",
  "3105, Salgótarján, Nógrád, Hungary",
  "C1N 4J8, Summerside, Prince Edward Island, Canada",
  "Great Falls, Montana, United States",
  "446304, Otradnyy, Samara, Russia",
  "688000, Palana, Koryak, Russia",
  "38047, Dyersburg, Tennessee, United States",
  "641442, Kurtamysh, Kurgan, Russia",
  "610005, Piatra Neamţ, Neamţ, Romania",
  "Tatarsko-Suksinskoye, Tatarstan, Russia",
  "Otaru, Hokkaido, Japan",
  "40383, Versailles, Kentucky, United States",
  "Vila Nova de Foz Côa, Guarda, Portugal",
  "110006, Piteşti, Argeş, Romania",
  "Lamoure, Ponlat-Taillebourg, Occitanie, France",
  "68025, Fremont, Nebraska, United States",
  "13015, Marseille, Provence-Alpes-Côte d'Azur, France",
  "02500, Adıyaman, Adıyaman, Türkiye",
  "65300, Vaasa, Ostrobothnia, Finland",
  "30817, Lorca, Región de Murcia, Spain",
  "55040, Isanti, Minnesota, United States",
  "Cameron, Illinois, United States",
  "309980, Valuyki, Belgorod, Russia",
  "Parque Yellowstone, Guanajuato, Mexico",
  "Santiago Sochiapan, Veracruz, Mexico",
  "Franklin County, Florida, United States",
  "Northwest Grayson, Texas, United States",
  "21666, Stevensville, Maryland, United States",
  "89126, Reggio di Calabria, Calabria, Italy",
  "CF61 1TQ, Llantwit Major, Wales, United Kingdom",
  "58900, Sivas, Sivas, Türkiye",
  "Daggett County, Utah, United States",
  "Prijedor, Serb Republic, Bosnia and Herzegovina",
  "636445, Kolpashevo, Tomsk, Russia",
  "410577, Oradea, Bihor, Romania",
  "6300-225, Guarda, Guarda, Portugal",
  "Schenectady County, New York, United States",
  "655002, Abakan, Khakassia, Russia",
  "4629, Kristiansand, Agder, Norway",
  "442533, Kuznetsk, Penza, Russia",
  "68372, Hickman, Nebraska, United States",
  "Feimaņi Parish, Rēzekne Municipality, Latvia",
  "38150, Santa Cruz de Tenerife, Canary Islands, Spain",
  "Sherburne County, Minnesota, United States",
  "54050, Sakarya, Sakarya, Türkiye",
  "Norwich, Ontario, Canada",
  "6826 AB, Arnhem, Gelderland, Netherlands",
  "J0N 1G0, Pointe-Calumet, Quebec, Canada",
  "La Malheureuse, Tour-en-Sologne, Centre-Val de Loire, France",
  "AB55 4BL, Keith, Scotland, United Kingdom",
  "97300, Cayenne, Cayenne, French Guiana",
  "Kumertau, Bashkortostan, Russia",
  "Lorient, Brittany, France",
  "602 22, Norrköping, Östergötland County, Sweden",
  "40321, Malá Subotica, Međimurje, Croatia",
  "6280, Galabovo, Stara Zagora, Bulgaria",
  "7404, Kaposvár, Somogy, Hungary",
  "Davidson County, Tennessee, United States",
  "Orleans, Massachusetts, United States",
  "Adairsville, Georgia, United States",
  "30741, Rossville, Georgia, United States",
  "Tori, Parnu, Estonia",
  "Waseca, Saskatchewan, Canada",
  "La Coruña, Miranda State, Venezuela",
  "Greater Leeds Area",
  "Brankas, Jelgava Municipality, Latvia",
  "E4M 1A9, Bayfield, New Brunswick, Canada",
  "01536, North Grafton, Massachusetts, United States",
  "Socorro, New Mexico, United States",
  "66400, Yozgat, Yozgat, Türkiye",
  "Skalica, Trnava Region, Slovakia",
  "Racine, West Virginia, United States",
  "80230, Joensuu, North Karelia, Finland",
  "Medzhibozh, Khmelnytsky, Ukraine",
  "Oregon City, Oregon, United States",
  "00185, Kilkenny, New Hampshire, United States",
  "Upper Gagetown, New Brunswick, Canada",
  "18226, Aleksinac, Centralna Srbija, Serbia",
  "Urbanización Valle Verde, Puerto Cabello, Carabobo State, Venezuela",
  "Charleroi, Walloon Region, Belgium",
  "Allendale Town, England, United Kingdom",
  "76250, Déville-lès-Rouen, Normandy, France",
  "Greater Udine Metropolitan Area",
  "Kiselevskoye, Rostov, Russia",
  "400677, Cluj-Napoca, Cluj, Romania",
  "15-369, Białystok, Podlaskie, Poland",
  "Dimos Aradippou, Larnaca, Cyprus",
  "431446, Ruzayevka, Mordovia, Russia",
  "Carlton, Washington, United States",
  "31314, Fort Stewart, Georgia, United States",
  "Burton, Texas, United States",
  "Debrecen District, Hajdú-Bihar, Hungary",
  "Guernsey, Saskatchewan, Canada",
  "Buck's Cross, England, United Kingdom",
  "Callaway Manor, Austintown, Ohio, United States",
  "Claiborne, Arkansas, United States",
  "Grafton County, New Hampshire, United States",
  "692916, Nakhodka, Primorye, Russia",
  "Topki, Lipetsk, Russia",
  "3067 VR, Rotterdam, South Holland, Netherlands",
  "656031, Barnaul, Altai Krai, Russia",
  "Sullivan, Wisconsin, United States",
  "F63A8Y7, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "Guilford, Missouri, United States",
  "North Charleston, South Carolina, United States",
  "Sabines, Chiapas, Mexico",
  "15700, Burdur, Burdur, Türkiye",
  "Webb County, Texas, United States",
  "Hindsford, Manchester, England, United Kingdom",
  "Taneyville, Missouri, United States",
  "Fredericksburg, Virginia, United States",
  "624266, Asbest, Sverdlovsk, Russia",
  "Ashtabula Township, Ohio, United States",
  "Valencia Metropolitan Area",
  "Mikhaylovka, Amur, Russia",
  "450010, Mikhaylovka, Bashkortostan, Russia",
  "165312, Kotlas, Arkhangel’sk, Russia",
  "21921, Elkton, Maryland, United States",
  "28910-380, Cabo Frio, Rio de Janeiro, Brazil",
  "22046, Falls Church, Virginia, United States",
  "52257, Luzerne, Iowa, United States",
  "Highland Mills, New York, United States",
  "Oktyabrsky District, Chelyabinsk, Russia",
  "79511, Coahoma, Texas, United States",
  "LE3 0AA, Leicester, England, United Kingdom",
  "SG13 7AX, Hertford, England, United Kingdom",
  "37006, Salamanca, Castilla and Leon, Spain",
  "791 43, Falun, Dalarna County, Sweden",
  "621 53, Visby, Gotland County, Sweden",
  "110109, Piteşti, Argeş, Romania",
  "Johnston, Iowa, United States",
  "McDonald, Missouri, United States",
  "Humboldt, Arizona, United States",
  "Churhat, Madhya Pradesh, India",
  "Syzran, Samara, Russia",
  "Abbeville, South Carolina, United States",
  "Bergenfield, New Jersey, United States",
  "456388, Miass, Chelyabinsk, Russia",
  "211413, Polatsk, Vitsyebsk, Belarus",
  "12123, Nassau, New York, United States",
  "Sovetskiy, Homel, Belarus",
  "12307, Schenectady, New York, United States",
  "678580, Batagay-Alyta, Sakha, Russia",
  "Barbour, Wallace 243, Saskatchewan, Canada",
  "Newton County, Mississippi, United States",
  "Payne, Georgia, United States",
  "B25C7C9, Esik, Almaty Region, Kazakhstan",
  "Killarney, Manitoba, Canada",
  "A4400GCE, Salta, Salta, Argentina",
  "Galaxia, México, Mexico",
  "04680, Steuben, Maine, United States",
  "IP29 5NY, Bury St Edmunds, England, United Kingdom",
  "81-603, Gdynia, Pomorskie, Poland",
  "143432, Krasnogorsk, Moscow, Russia",
  "607273, Letea Veche, Bacău, Romania",
  "3012 AA, Rotterdam, South Holland, Netherlands",
  "Korçë, Korçë, Albania",
  "Augusta, Maine, United States",
  "E28D2H0, Makhambet, Atyrau Region, Kazakhstan",
  "Pleven, Bulgaria",
  "Old Landing at Smythe Park, Wando, South Carolina, United States",
  "43030, Kutahya, Kütahya, Türkiye",
  "20100, Denizli, Denizli, Türkiye",
  "Baikonyr, Kyzylorda Region, Kazakhstan",
  "05650, East Calais, Vermont, United States",
  "Melilla, Melilla, Spain",
  "413850, Balakovo, Saratov, Russia",
  "Seward, Illinois, United States",
  "Taylors Hill, Victoria, Australia",
  "51013, Tartu, Tartumaa, Estonia",
  "46700, Kahramanmaraş, Kahraman Maras, Türkiye",
  "9447, Evenskjær, Troms og Finnmark, Norway",
  "Adair, Wolseley 155, Saskatchewan, Canada",
  "Derbent, Konya, Türkiye",
  "23260, Zrenjanin, Vojvodina, Serbia",
  "34432, Dunnellon, Florida, United States",
  "Franklin, Massachusetts, United States",
  "Berlin Metropolitan Area",
  "6013, Ålesund, Møre og Romsdal, Norway",
  "East Glacier Park, Montana, United States",
  "23847, Emporia, Virginia, United States",
  "89079, Ulm, Baden-Württemberg, Germany",
  "Y35 X6Y6, Wexford, County Wexford, Ireland",
  "628422, Surgut, Khanty-Mansi, Russia",
  "Davison Street, Kings, Subdivision D, Nova Scotia, Canada",
  "Hardington Mandeville, England, United Kingdom",
  "Morang District, Nepal",
  "Chittenden, Vermont, United States",
  "00145, Rome, Latium, Italy",
  "95696, Vacaville, California, United States",
  "62340, Griggsville, Illinois, United States",
  "Novotroitskoye, Donetsk, Ukraine",
  "75087, Rockwall, Texas, United States",
  "6007, Ålesund, Møre og Romsdal, Norway",
  "16214, Clarion, Pennsylvania, United States",
  "20131, Milan, Lombardy, Italy",
  "Hawaii, United States",
  "Pettis, Missouri, United States",
  "Linares, Nariño, Colombia",
  "424004, Yoshkar-Ola, Mari El, Russia",
  "N00Y8F8, Kyzylorda, Kyzylorda Region, Kazakhstan",
  "Sumner, Portland, Oregon, United States",
  "623957, Tavda, Sverdlovsk, Russia",
  "Harper, Maryland County, Liberia",
  "79501, Anson, Texas, United States",
  "33101, Miami, Florida, United States",
  "Istočno Novo Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "H34F5C1, Qulan, Jambyl Region, Kazakhstan",
  "Castellote, Aragon, Spain",
  "Lafayette County, Wisconsin, United States",
  "Curry Mallet, England, United Kingdom",
  "Kahraman Maras, Türkiye",
  "Greater Montgomery Area",
  "Lawrence County, Pennsylvania, United States",
  "Boone County, Nebraska, United States",
  "Gallatin Commercial Historic District, Tennessee, United States",
  "Darlington, Missouri, United States",
  "19147, Philadelphia, Pennsylvania, United States",
  "430028, Saransk, Mordovia, Russia",
  "Foster City, Michigan, United States",
  "621 50, Visby, Gotland County, Sweden",
  "51003, Ceuta, Ceuta, Spain",
  "Page County, Iowa, United States",
  "Potenza, Basilicata, Italy",
  "59400, Tekirdağ, Tekirdağ, Türkiye",
  "CO13 0FQ, Kirkby-Le-Soken, England, United Kingdom",
  "9220, Aalborg, North Denmark Region, Denmark",
  "7010, Nelson, Nelson, New Zealand",
  "Anadyrsky District, Chukot, Russia",
  "Downtown Juneau, Juneau, Alaska, United States",
  "Valle d'Aosta, Italy",
  "Székesfehérvári Járás, Fejér, Hungary",
  "47008, Valladolid, Castilla and Leon, Spain",
  "Pulang Pisau Regency, Central Kalimantan, Indonesia",
  "456320, Miass, Chelyabinsk, Russia",
  "Caledonia, Ohio, United States",
  "53405, Racine, Wisconsin, United States",
  "45177, Guadalajara, Jalisco, Mexico",
  "71223, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "90768, Fürth, Bavaria, Germany",
  "Pitt Meadows, British Columbia, Canada",
  "Ungureni, Botoşani, Romania",
  "Frolovo, Volgograd, Russia",
  "Obion, Tennessee, United States",
  "Krasnoarmeysk, Moscow, Russia",
  "Schoolcraft Township, Michigan, United States",
  "09100, Aydın, Aydın, Türkiye",
  "Bowmanstead, England, United Kingdom",
  "X5800BGZ, Río Cuarto, Córdoba, Argentina",
  "28052, Gastonia, North Carolina, United States",
  "Mount Callahan Acres, Springdale, Arkansas, United States",
  "4153, Reinach, Basel-Country, Switzerland",
  "1951, Sion, Valais, Switzerland",
  "582 32, Linköping, Östergötland County, Sweden",
  "4203, Altenberg bei Linz, Upper Austria, Austria",
  "96900, Rovaniemi, Lapland, Finland",
  "Cherokee, Iowa, United States",
  "TR18 2AA, Penzance, England, United Kingdom",
  "Malatya, Malatya, Türkiye",
  "38100, Kayseri, Kayseri, Türkiye",
  "IP13 0HP, Pettistree, England, United Kingdom",
  "Greater Manisa",
  "30292, Williamson, Georgia, United States",
  "70700, Karaman, Karaman, Türkiye",
  "6523, Bellinzona, Ticino, Switzerland",
  "Taneytown, Maryland, United States",
  "683, Mannheim, Baden-Württemberg, Germany",
  "Albany, Indiana, United States",
  "13011, Marseille, Provence-Alpes-Côte d'Azur, France",
  "413855, Balakovo, Saratov, Russia",
  "Mathews County, Virginia, United States",
  "Langlade, Bézac, Occitanie, France",
  "Sportswood Country Club, Harrisonburg, Virginia, United States",
  "10924, Goshen, New York, United States",
  "23008, Jaén, Andalusia, Spain",
  "24401, Staunton, Virginia, United States",
  "La Crosse County, Wisconsin, United States",
  "Granada, Amazonas, Peru",
  "Churachandpur, Manipur, India",
  "90504, Torrance, California, United States",
  "658206, Rubtsovsk, Altai Krai, Russia",
  "Le Grand Champ, Laramière, Occitanie, France",
  "390035, Ryazan, Ryazan’, Russia",
  "Stanley, Liverpool, England, United Kingdom",
  "63091, Rosebud, Missouri, United States",
  "Fayette County, Tennessee, United States",
  "613044, Klyuchi, Kirov, Russia",
  "5313, Klingnau, Aargau, Switzerland",
  "53119, Bonn, North Rhine-Westphalia, Germany",
  "Milam, Munsyari, Uttarakhand, India",
  "Clarke, Milton, Ontario, Canada",
  "Oldambt, Groningen, Netherlands",
  "Sitiá, Ceará, Brazil",
  "Luhansk, Ukraine",
  "14026, Bowmansville, New York, United States",
  "Windsor, Ontario, Canada",
  "618384, Kizel, Perm, Russia",
  "43000, Kutahya, Kütahya, Türkiye",
  "Langlade, Bonnes, Nouvelle-Aquitaine, France",
  "1070, Brussels, Brussels Region, Belgium",
  "900 01, Umeå, Västerbotten County, Sweden",
  "HP17 0UE, Little Kimble, England, United Kingdom",
  "Walkerton, Ontario, Canada",
  "Carson, Mississippi, United States",
  "Engelstofte Huse, Region Zealand, Denmark",
  "Redwood Pass, Marlborough, New Zealand",
  "The Bahamas",
  "678902, Aldan, Sakha, Russia",
  "Nikolayevskoye, Rostov, Russia",
  "Horqin Left Rear Banner, Inner Mongolia, China",
  "72061, Greenbrier, Arkansas, United States",
  "L10F4M7, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Leninsk-Kuznetsky District, Kemerovo, Russia",
  "Menominee Township, Michigan, United States",
  "East Amherst, New York, United States",
  "56716, Crookston, Minnesota, United States",
  "168079, Kerchom”ya, Komi, Russia",
  "689480, Bilibino, Chukot, Russia",
  "Kershaw County, South Carolina, United States",
  "Greater Sanliurfa",
  "DY10 3BD, Kidderminster, England, United Kingdom",
  "Osmangazi, Bursa, Türkiye",
  "Bradford, Vermont, United States",
  "040 23, Košice, Kosice, Slovakia",
  "120073, Buzău, Buzău, Romania",
  "33710, Tampere, Pirkanmaa, Finland",
  "Vienna, Austria",
  "Kotel'nich, Kirov, Russia",
  "V0E 1B1, Armstrong, British Columbia, Canada",
  "Woodlawn, Schenectady, New York, United States",
  "97615, Dzaoudzi, Mayotte",
  "Broome, England, United Kingdom",
  "Traverse, Le Bastit, Occitanie, France",
  "385330, Ust’-Labinsk, Krasnodar, Russia",
  "23432, Suffolk, Virginia, United States",
  "682, Mannheim, Baden-Württemberg, Germany",
  "Boone County, Arkansas, United States",
  "Pomorie Municipality, Burgas, Bulgaria",
  "Semeyka, Voronezh, Russia",
  "28600, Giresun, Giresun, Türkiye",
  "410, Ísafjörður, Westfjords, Iceland",
  "Custer City, Oklahoma, United States",
  "Ejisu Municipal District, Ashanti Region, Ghana",
  "Foster, Kentucky, United States",
  "353681, Yeysk, Krasnodar, Russia",
  "48149, Münster, North Rhine-Westphalia, Germany",
  "74135, Tulsa, Oklahoma, United States",
  "3044, Drammen, Viken, Norway",
  "32233, Atlantic Beach, Florida, United States",
  "4622, Egerkingen, Solothurn, Switzerland",
  "V3H 4W6, Port Moody, British Columbia, Canada",
  "15200, Burdur, Burdur, Türkiye",
  "07, Sassari, Sardinia, Italy",
  "Downtown Vinton, Vinton, Virginia, United States",
  "14476, Kendall, New York, United States",
  "33520, Podravska Slatina, Virovitica-Podravina, Croatia",
  "Rockland, Ontario, Canada",
  "Treasure Oaks, Conway, Florida, United States",
  "Paraná, Brazil",
  "Florence, Texas, United States",
  "62040, Granite City, Illinois, United States",
  "Pepinster, Walloon Region, Belgium",
  "15704, Santiago de Compostela, Galicia, Spain",
  "Olenegorsk, Murmansk, Russia",
  "Rigas Feraios, Thessalia, Greece",
  "North Western Province, Sri Lanka",
  "80200, Joensuu, North Karelia, Finland",
  "Saint Mathews, Nqutu, KwaZulu-Natal, South Africa",
  "Holt, Michigan, United States",
  "44-110, Gliwice, Śląskie, Poland",
  "Sovetskaya, Krasnodar, Russia",
  "TR9 6AU, St Columb Major, England, United Kingdom",
  "602 19, Norrköping, Östergötland County, Sweden",
  "Yellow Spring, West Virginia, United States",
  "Kiowa, Nebraska, United States",
  "7050, Trondheim, Trøndelag, Norway",
  "Harmondsworth, West Drayton, England, United Kingdom",
  "143441, Krasnogorsk, Moscow, Russia",
  "78555-024, Sinop, Mato Grosso, Brazil",
  "42345, Zimapán, Hidalgo, Mexico",
  "Hopkins, Michigan, United States",
  "Harnett County, North Carolina, United States",
  "Gävleborg County, Sweden",
  "Vickie Lane-Bowie Street, Fort Worth, Texas, United States",
  "36318, Coffee Springs, Alabama, United States",
  "Bentong, Pahang, Malaysia",
  "48153, Münster, North Rhine-Westphalia, Germany",
  "Brookend, England, United Kingdom",
  "309186, Gubkin, Belgorod, Russia",
  "Kitsap County, Washington, United States",
  "40353, Mount Sterling, Kentucky, United States",
  "6303, Tepelenë, Gjirokastër, Albania",
  "504 61, Borås, Västra Götaland County, Sweden",
  "Krasnoarmeyskoye, Samara, Russia",
  "Greenbrier Farms, Winston-Salem, North Carolina, United States",
  "Florida, United States",
  "8061, Hasselt, Overijssel, Netherlands",
  "Okanogan County, Washington, United States",
  "24111, Kiel, Schleswig-Holstein, Germany",
  "Louisiana, United States",
  "8043 XT, Zwolle, Overijssel, Netherlands",
  "Tres Brazos, Guerrero, Mexico",
  "Middelburg, Mpumalanga, South Africa",
  "23560, Lübeck, Schleswig-Holstein, Germany",
  "Obshtina Lyaskovets, Veliko Turnovo, Bulgaria",
  "Suffolk, England, United Kingdom",
  "Cleveland County, Oklahoma, United States",
  "De Kalb County, Tennessee, United States",
  "74467, Wagoner, Oklahoma, United States",
  "F92 R5C6, Letterkenny, County Donegal, Ireland",
  "3942, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Jimo District, Shandong, China",
  "200020, Craiova, Dolj, Romania",
  "87068, Bosque Farms, New Mexico, United States",
  "Iquique, Tarapacá Region, Chile",
  "Jim Wells County, Texas, United States",
  "Wickham, England, United Kingdom",
  "241020, Sven’, Bryansk, Russia",
  "Crowton, England, United Kingdom",
  "427962, Sarapul, Udmurtia, Russia",
  "01120, Adana, Adana, Türkiye",
  "491 10, Kérkira, Ionian Islands, Greece",
  "06050, Badajoz, Extremadura, Spain",
  "Whitney Park, Wilkinsburg, Pennsylvania, United States",
  "A91 A2HD, Dundalk, County Louth, Ireland",
  "27150, Otero de Rey, Galicia, Spain",
  "L8E 6G3, Stoney Creek, Ontario, Canada",
  "Palandöken, Erzurum, Türkiye",
  "9442, Evenskjær, Troms og Finnmark, Norway",
  "Martin County, Minnesota, United States",
  "Hickory, Pennsylvania, United States",
  "54936, Fond du Lac, Wisconsin, United States",
  "820169, Tulcea, Tulcea, Romania",
  "30236, Jonesboro, Georgia, United States",
  "79057, McLean, Texas, United States",
  "Saint-Laurent-du-Maroni, French Guiana",
  "99099, Erfurt, Thuringia, Germany",
  "641876, Shadrinsk, Kurgan, Russia",
  "8100, Várpalota, Veszprém, Hungary",
  "Bignan, Brittany, France",
  "Galliano, Cantù, Lombardy, Italy",
  "11100, Bilecik, Bilecik, Türkiye",
  "Asotin, Washington, United States",
  "Snizhnianska miska hromada, Donetsk, Ukraine",
  "692038, Lesozavodsk, Primorye, Russia",
  "Grant County, Arkansas, United States",
  "44288, Windham, Ohio, United States",
  "6018, Ålesund, Møre og Romsdal, Norway",
  "700045, Iaşi, Iaşi, Romania",
  "678620, Ust’-Maya, Sakha, Russia",
  "Pacific, San Bernardino, California, United States",
  "121 26, Stockholm, Stockholm County, Sweden",
  "Lunavada, Gujarat, India",
  "981 93, Kiruna, Norrbotten County, Sweden",
  "461640, Buguruslan, Orenburg, Russia",
  "California, United States",
  "456323, Miass, Chelyabinsk, Russia",
  "Kirsanovsky District, Tambov, Russia",
  "33319, Fort Lauderdale, Florida, United States",
  "72529, Cherokee Village, Arkansas, United States",
  "456871, Kyshtym, Chelyabinsk, Russia",
  "M28D4C0, Temirtau, Karaganda Region, Kazakhstan",
  "110033, Piteşti, Argeş, Romania",
  "8035 PS, Zwolle, Overijssel, Netherlands",
  "Walker, Louisiana, United States",
  "21221, Essex, Maryland, United States",
  "10000, Troyes, Grand Est, France",
  "47042, Versailles, Indiana, United States",
  "391306, Kasimov, Ryazan’, Russia",
  "CB10 2LG, Sewards End, England, United Kingdom",
  "Pecos County, Texas, United States",
  "Russell County, Kentucky, United States",
  "606407, Balakhna, Nizhniy Novgorod, Russia",
  "Akhtubinsk, Astrakhan’, Russia",
  "Benton City, Washington, United States",
  "Jászberény, Jász-Nagykun-Szolnok, Hungary",
  "Hameau de Bas, Barneville-Carteret, Normandy, France",
  "64011, Bates City, Missouri, United States",
  "50-004, Wrocław, Dolnośląskie, Poland",
  "4704, Laç, Lezhë, Albania",
  "Tajikistan",
  "310010, Arad, Arad, Romania",
  "BS5 0AA, Bristol, England, United Kingdom",
  "Randall Corner, Sheffield, New Brunswick, Canada",
  "Greater Salzburg",
  "Obshtina Dimitrovgrad, Khaskovo, Bulgaria",
  "Davison Township, Michigan, United States",
  "Hubbards, Halifax, Nova Scotia, Canada",
  "Stanley, Skelmersdale, England, United Kingdom",
  "Carson, Iowa, United States",
  "2512 AA, The Hague, South Holland, Netherlands",
  "Shenandoah, Aurora, Colorado, United States",
  "Şirvan, Siirt, Türkiye",
  "960 03, Zvolen, Banska Bystrica, Slovakia",
  "4056, Basel, Basel, Switzerland",
  "86-300, Grudziądz, Kujawsko-pomorskie, Poland",
  "Coffee Creek, Montana, United States",
  "1000, Skopje, Skopje Statistical Region, North Macedonia",
  "24070, Catawba, Virginia, United States",
  "Burnett, Wisconsin, United States",
  "89803, Elko, Nevada, United States",
  "Beaufort West Local Municipality, Western Cape, South Africa",
  "Miguel Hidalgo, Mexico City, Mexico",
  "58660, Coulanges-lès-Nevers, Bourgogne-Franche-Comté, France",
  "NR35 2RW, Broome, England, United Kingdom",
  "Pershing Grove, Florida, United States",
  "162604, Cherepovets, Vologda, Russia",
  "352330, Ust’-Labinsk, Krasnodar, Russia",
  "78700, Karabuk, Karabuk, Türkiye",
  "Bubq, Durres, Albania",
  "Bistrica, Pelagonia, North Macedonia",
  "130003, Cartagena, Bolívar, Colombia",
  "R13B6D3, Beyneu, Mangystau Region, Kazakhstan",
  "Yaroslavl Metropolitan Area",
  "24030, Erzincan, Erzincan, Türkiye",
  "Pearl City, Hawaii, United States",
  "4401, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "628148, Saranpaul’, Khanty-Mansi, Russia",
  "45826, Chickasaw, Ohio, United States",
  "Gaysin, Vinnytsya, Ukraine",
  "Sweet, Idaho, United States",
  "Putnam, Ontario, Canada",
  "357117, Nevinnomyssk, Stavropol, Russia",
  "Lorain County, Ohio, United States",
  "Vólos, Thessalia, Greece",
  "Tooele County, Utah, United States",
  "Zaysan District, East Kazakhstan Region, Kazakhstan",
  "Sanderstead, South Croydon, England, United Kingdom",
  "Holton, Michigan, United States",
  "420140, Bistriţa, Bistriţa-Năsăud, Romania",
  "Reşiţa, Caraş-Severin, Romania",
  "Cheshire, Flagstaff, Arizona, United States",
  "88463-000, Angelina, Santa Catarina, Brazil",
  "15082, McDonald, Pennsylvania, United States",
  "Buffalo, Minnesota, United States",
  "90500, Oulu, North Ostrobothnia, Finland",
  "Ashland, Oregon, United States",
  "Canillo, Andorra",
  "Hannover-Braunschweig-Göttingen-Wolfsburg Region",
  "Amurskoye, Altay, Russia",
  "Craig, Alaska, United States",
  "652993, Tashtagol, Kemerovo, Russia",
  "446008, Syzran, Samara, Russia",
  "Carson, California, United States",
  "34440, Istanbul, Istanbul, Türkiye",
  "76810-552, Porto Velho, Rondônia, Brazil",
  "461, Trollhättan, Västra Götaland County, Sweden",
  "12832, Granville, New York, United States",
  "41-936, Bytom, Śląskie, Poland",
  "Bradley, Oklahoma, United States",
  "48002, Bilbao, Basque Country, Spain",
  "67501, Hutchinson, Kansas, United States",
  "700004, Iaşi, Iaşi, Romania",
  "4847, Arendal, Agder, Norway",
  "Haywood, Manitoba, Canada",
  "S21 3YN, Spinkhill, England, United Kingdom",
  "Keflavík, Southern Peninsula, Iceland",
  "29652, Greer, South Carolina, United States",
  "Hamilton, New York, United States",
  "37073, Greenbrier, Tennessee, United States",
  "Lafayette County, Missouri, United States",
  "Trumbull, Connecticut, United States",
  "Trois-Rivières, Basse-Terre, Guadeloupe",
  "Le Roseau, Boisseuil, Nouvelle-Aquitaine, France",
  "Mason County, Illinois, United States",
  "F42G7A0, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "Bacău, Romania",
  "Westchester, Illinois, United States",
  "51234, Boyden, Iowa, United States",
  "Bathinda, India",
  "Norton County, Kansas, United States",
  "IP33 3SP, Bury St Edmunds, England, United Kingdom",
  "Vladimir, Vladimir, Russia",
  "TS6 0AB, Middlesbrough, England, United Kingdom",
  "723 50, Västerås, Västmanland County, Sweden",
  "60047, Lake Zurich, Illinois, United States",
  "Nis Metropolitan Area",
  "Gaya Sadar, Bihar, India",
  "6456, Molde, Møre og Romsdal, Norway",
  "Hardy, Nebraska, United States",
  "Kamenka, Samara, Russia",
  "Polača, Zadar, Croatia",
  "623377, Polevskoy, Sverdlovsk, Russia",
  "Aldan-Maadyr, Tuva, Russia",
  "28600, Pori, Satakunta, Finland",
  "Alğa, Aqtöbe region, Kazakhstan",
  "Žiar nad Hronom, Banska Bystrica, Slovakia",
  "Fierville-les-Mines, Normandy, France",
  "Stansted, England, United Kingdom",
  "35115, Panevėžys, Panevėžio, Lithuania",
  "Sion Farm, St Croix, US Virgin Islands",
  "33463, Lake Worth, Florida, United States",
  "Cibola, Albuquerque, New Mexico, United States",
  "1700, Fribourg, Fribourg, Switzerland",
  "Saludares, Dingras, Ilocos Region, Philippines",
  "1002, Lausanne, Vaud, Switzerland",
  "Shar'ya, Kostroma, Russia",
  "Chistopol’ye, Novosibirsk, Russia",
  "Washburn-Bruce, North Las Vegas, Nevada, United States",
  "453305, Kumertau, Bashkortostan, Russia",
  "Kocherinovo, Kyustendil, Bulgaria",
  "35673, Trinity, Alabama, United States",
  "37066, Gallatin, Tennessee, United States",
  "Gay, Georgia, United States",
  "Tatarskiy Kalmayur, Ul’yanovsk, Russia",
  "S13E1X8, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Josephine, West Virginia, United States",
  "Chautauqua Park, Florida, United States",
  "30401, Swainsboro, Georgia, United States",
  "12100, Castellón de la Plana, Valencian Community, Spain",
  "Botetourt County, Virginia, United States",
  "57438, Faulkton, South Dakota, United States",
  "Handwara, Jammu & Kashmir, India",
  "34381, Istanbul, Istanbul, Türkiye",
  "Palo, Iowa, United States",
  "66130, Saarbrücken, Saarland, Germany",
  "Greater Algeciras Metropolitan Area",
  "Pearl, Illinois, United States",
  "Cassagnau, Chélan, Occitanie, France",
  "Timiryazevo, Penza, Russia",
  "64, Altdorf, Uri, Switzerland",
  "27704, Durham, North Carolina, United States",
  "16735, Kane, Pennsylvania, United States",
  "67100, Zonguldak, Zonguldak, Türkiye",
  "Windsor County, Vermont, United States",
  "130004, Târgovişte, Dîmboviţa, Romania",
  "Serdobsky District, Penza, Russia",
  "11050, Belgrade, Serbia",
  "McKinley, Guihulngan, Central Visayas, Philippines",
  "Zelenokumsk, Stavropol, Russia",
  "75331, Engelsbrand, Baden-Württemberg, Germany",
  "Jefferson Parish County, Louisiana, United States",
  "24522, Appomattox, Virginia, United States",
  "31061, Milledgeville, Georgia, United States",
  "70549, Lake Arthur, Louisiana, United States",
  "47137, Duisburg, North Rhine-Westphalia, Germany",
  "80-886, Gdańsk, Pomorskie, Poland",
  "Kiowa, Oklahoma, United States",
  "Worth, Crawley, England, United Kingdom",
  "694448, Aleksandrovsk-Sakhalinskiy, Sakhalin, Russia",
  "6412, Molde, Møre og Romsdal, Norway",
  "Phillipstown, Northern Cape, South Africa",
  "E6E 1M6, Hawkins Corner, New Brunswick, Canada",
  "Panevėžio, Lithuania",
  "Tunceli, Türkiye",
  "37920, Kastamonu, Kastamonu, Türkiye",
  "18110, Niš, Centralna Srbija, Serbia",
  "542 41, Mariestad, Västra Götaland County, Sweden",
  "Antelope Park 322, Saskatchewan, Canada",
  "Kakhovka Raion, Kherson, Ukraine",
  "33400, Tampere, Pirkanmaa, Finland",
  "Island County, Washington, United States",
  "L1C 3H2, Bowmanville, Ontario, Canada",
  "618542, Solikamsk, Perm, Russia",
  "221 31, Tripoli, Peloponnese, Greece",
  "Harmon County, Oklahoma, United States",
  "21035, Davidsonville, Maryland, United States",
  "43157, Thurston, Ohio, United States",
  "McPherson Ranch, Fort Worth, Texas, United States",
  "Basse-Terre, Guadeloupe",
  "San Diego County, California, United States",
  "39194, Yazoo City, Mississippi, United States",
  "Ayios Dhometios Municipality, Nicosia, Cyprus",
  "7018, Trondheim, Trøndelag, Norway",
  "Bashkia Pustec, Korçë, Albania",
  "Laconia, New Hampshire, United States",
  "11800, Bilecik, Bilecik, Türkiye",
  "Silver Springs, Nevada, United States",
  "Scott, Virginia, United States",
  "Laurens, Anglès, Occitanie, France",
  "38280, Kayseri, Kayseri, Türkiye",
  "320018, Reşiţa, Caraş-Severin, Romania",
  "Sangarcía, Castilla and Leon, Spain",
  "Blue Hill, Maine, United States",
  "Aqqwlı district, Pavlodar Region, Kazakhstan",
  "Gelibolu, Çanakkale, Türkiye",
  "Llanon, Wales, United Kingdom",
  "Pearl River County, Mississippi, United States",
  "97356, Cayenne, French Guiana",
  "57400, Sinop, Sinop, Türkiye",
  "Oktyabrsky District, Orenburg, Russia",
  "Mayeste, Narrosse, Nouvelle-Aquitaine, France",
  "Kandiyohi, Minnesota, United States",
  "Zurich, Switzerland",
  "Devrek, Zonguldak, Türkiye",
  "McPherson Loop at San Isidro, Laredo, Texas, United States",
  "Danville, New Hampshire, United States",
  "24128, Bergamo, Lombardy, Italy",
  "77177, Siauliai, Siauliu, Lithuania",
  "Dewittville, Quebec, Canada",
  "Georgetown, Demerara-Mahaica, Guyana",
  "Mills County, Texas, United States",
  "02886, Warwick, Rhode Island, United States",
  "57122, Livorno, Tuscany, Italy",
  "9624, Hammerfest, Troms og Finnmark, Norway",
  "Churchill 1, Manitoba, Canada",
  "18036, Stepanki, Cherkasy, Ukraine",
  "80125, Naples, Campania, Italy",
  "385009, Maykop, Adygea, Russia",
  "Hawkins Estates, Lenexa, Kansas, United States",
  "Comanche, San Jose, California, United States",
  "Brestski Rayon, Brest, Belarus",
  "Mount Ayr, Iowa, United States",
  "Starke, Florida, United States",
  "Covington, Ohio, United States",
  "Weidenmühle, Neu-Bamberg, Rhineland-Palatinate, Germany",
  "Rubik, Lezhë, Albania",
  "61001, Kharkiv, Kharkiv, Ukraine",
  "Randolph, Minnesota, United States",
  "Bahia, Brazil",
  "Wilson, Kenosha, Wisconsin, United States",
  "55942, Winona, Minnesota, United States",
  "Mikkeli sub-region, South Savo, Finland",
  "R25A0C3, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "354008, Sochi, Krasnodar, Russia",
  "24323, Crockett, Virginia, United States",
  "V0J 2G0, McLeod Lake, British Columbia, Canada",
  "607227, Arzamas, Nizhniy Novgorod, Russia",
  "Kiowa, Colorado, United States",
  "Ashe County, North Carolina, United States",
  "Vorderland, Appenzell Outer-Rhoden, Switzerland",
  "Pugachëvskiy, Orenburg, Russia",
  "64449, Fillmore, Missouri, United States",
  "Cottonwood, Alabama, United States",
  "Lowndes County, Georgia, United States",
  "Güneysu, Rize, Türkiye",
  "Seia, Guarda, Portugal",
  "Harrison County, Texas, United States",
  "629734, Nadym, Yamalo-Nenets, Russia",
  "665816, Angarsk, Irkutsk, Russia",
  "Poquoson, Virginia, United States",
  "Vaggeryd, Jönköping County, Sweden",
  "Copălău, Botoşani, Romania",
  "626156, Tobolsk, Tyumen’, Russia",
  "Nancefield, Musina, Limpopo, South Africa",
  "51115, Rijeka, Primorje-Gorski, Croatia",
  "Callahan, Orlando, Florida, United States",
  "Golden Valley County, North Dakota, United States",
  "2504, Biel, Berne, Switzerland",
  "Anzhero-Sudzhensk, Kemerovo, Russia",
  "McLeansboro, Illinois, United States",
  "Sanborn County, South Dakota, United States",
  "42285, Wuppertal, North Rhine-Westphalia, Germany",
  "Barneston, Nebraska, United States",
  "Pombal, Leiria, Portugal",
  "Örebro, Örebro County, Sweden",
  "Gregory Park, Saint Catherine, Jamaica",
  "99620, Sodankylä, Lapland, Finland",
  "Freetown, Western Area, Sierra Leone",
  "86100, Campobasso, Molise, Italy",
  "155310, Staraya Vichuga, Ivanovo, Russia",
  "28005, Madrid, Community of Madrid, Spain",
  "Gibson, Western Australia, Australia",
  "Lamoureux, Juillac, Nouvelle-Aquitaine, France",
  "Robertson, Western Cape, South Africa",
  "Porto Ferreira, São Paulo, Brazil",
  "Stroiești, Suceava, Romania",
  "Voznesenskaya, Ingushetia, Russia",
  "75644, Gilmer, Texas, United States",
  "141310, Sergiyev Posad, Moscow, Russia",
  "188807, Vyborg, Leningrad, Russia",
  "Reeves I, Bong County, Liberia",
  "06781, Terryville, Connecticut, United States",
  "Zelenodol'skiy, Altai Krai, Russia",
  "Overland Park, Kansas, United States",
  "Michurinskoye, Bryansk, Russia",
  "Hrodna, Belarus",
  "Northern Africa",
  "Whitman, Philadelphia, Pennsylvania, United States",
  "412305, Balashov, Saratov, Russia",
  "Rogersville, Alabama, United States",
  "61058, Kharkiv, Kharkiv, Ukraine",
  "Wise, Virginia, United States",
  "Urayasu, Chiba, Japan",
  "Minnehaha Oaks, Clermont, Florida, United States",
  "L10F5G6, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Kovrov, Vladimir, Russia",
  "Manchester, New Hampshire, United States",
  "62766, Emiliano Zapata, Morelos, Mexico",
  "22906, Charlottesville, Virginia, United States",
  "Alcantarilla, Región de Murcia, Spain",
  "Bear, Delaware, United States",
  "Castellón de la Plana, Valencian Community, Spain",
  "53202, Milwaukee, Wisconsin, United States",
  "McIntosh County, North Dakota, United States",
  "Waterford, Connecticut, United States",
  "29067, Kershaw, South Carolina, United States",
  "40133, Bologna, Emilia-Romagna, Italy",
  "Krasnoyarskoye, Omsk, Russia",
  "Entroncamento, Santarém, Portugal",
  "Netherlands",
  "Galax City County, Virginia, United States",
  "32669, Newberry, Florida, United States",
  "Desa Jenawi, Central Java, Indonesia",
  "694025, Korsakov, Sakhalin, Russia",
  "01109, Dresden, Saxony, Germany",
  "Broadwater, Stevenage, England, United Kingdom",
  "Michurinskiy, Penza, Russia",
  "Tulcea, Tulcea, Romania",
  "Beaufort, Grevenmacher, Luxembourg",
  "73460, Tishomingo, Oklahoma, United States",
  "Sarqan District, Jetisu Region, Kazakhstan",
  "Rowanfield, Cheltenham, England, United Kingdom",
  "Dunnsville, Virginia, United States",
  "Clarks Summit, Pennsylvania, United States",
  "Cascade, Montana, United States",
  "Liberty, Missouri, United States",
  "Mirnyy, Samara, Russia",
  "693003, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "San Sebastián De Mariquita, Tolima, Colombia",
  "3929, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "V1G 0A1, Dawson Creek, British Columbia, Canada",
  "Pinecrest, Aiken, South Carolina, United States",
  "Graysville, Tennessee, United States",
  "Draganić, Karlovac, Croatia",
  "95037, Morgan Hill, California, United States",
  "624787, Verkhnyaya Salda, Sverdlovsk, Russia",
  "Kingfisher Plaza, Saint Elizabeth, Jamaica",
  "McIntosh, South Dakota, United States",
  "Victoria, New Brunswick, Canada",
  "18013-565, Sorocaba, São Paulo, Brazil",
  "6033, Innsbruck, Tyrol, Austria",
  "9926, Kirkenes, Troms og Finnmark, Norway",
  "Pochëp, Bryansk, Russia",
  "452650, Bakaly, Bashkortostan, Russia",
  "K0A 2M0, Limoges, Ontario, Canada",
  "5611 KV, Eindhoven, North Brabant, Netherlands",
  "15-091, Białystok, Podlaskie, Poland",
  "Todd County, South Dakota, United States",
  "Nyköpings kommun, Södermanland County, Sweden",
  "Groznyy, Chechnya, Russia",
  "Tutayevsky District, Yaroslavl’, Russia",
  "666787, Ust-Kut, Irkutsk, Russia",
  "184664, Polyarnyy, Murmansk, Russia",
  "47906, West Lafayette, Indiana, United States",
  "Malakhovo-Slobodskoye, Orel, Russia",
  "33710, St Petersburg, Florida, United States",
  "42000, Konya, Konya, Türkiye",
  "İnebolu, Kastamonu, Türkiye",
  "Queens, New York, United States",
  "Guilford, Pennsylvania, United States",
  "Comalcalco, Tabasco, Mexico",
  "Tatebayashi, Gunma, Japan",
  "Cooper, Texas, United States",
  "8614, Mo i Rana, Nordland, Norway",
  "65230, Armstrong, Missouri, United States",
  "Howell, Missouri, United States",
  "Halifax Mainland, Halifax, Nova Scotia, Canada",
  "B31 4ED, Birmingham, England, United Kingdom",
  "North Douglas, Juneau, Alaska, United States",
  "Moorends, England, United Kingdom",
  "Çorlu, Tekirdağ, Türkiye",
  "3055, Drammen, Viken, Norway",
  "10450, Jastrebarsko, Zagreb, Croatia",
  "Choctaw County, Oklahoma, United States",
  "Manassas Park City County, Virginia, United States",
  "Simpson, Arkansas, United States",
  "452453, Birsk, Bashkortostan, Russia",
  "KW15 1SZ, Kirkwall, Scotland, United Kingdom",
  "82110, Joensuu, North Karelia, Finland",
  "618440, Berezniki, Perm, Russia",
  "Häädemeeste vald, Pärnu maakond, Estonia",
  "Deer Lodge County, Montana, United States",
  "Saint Lucia",
  "Montgomery County, Kansas, United States",
  "Barisal Sadar Upazila, Barisāl, Bangladesh",
  "Madhya Pradesh, India",
  "Kilis, Türkiye",
  "Patricksburg, Indiana, United States",
  "06300, Nice, Provence-Alpes-Côte d'Azur, France",
  "2460, Grafton, New South Wales, Australia",
  "V3H 5J5, Port Moody, British Columbia, Canada",
  "Ashland, Kentucky, United States",
  "Greater Córdoba, Spain Area",
  "99208, Spokane, Washington, United States",
  "Osmancık, Çorum, Türkiye",
  "Évora, Leiria, Portugal",
  "Trivignano Udinese, Friuli-Venezia Giulia, Italy",
  "390000, Ryazan, Ryazan’, Russia",
  "Canadian County, Oklahoma, United States",
  "Y25 VY26, Gorey, County Wexford, Ireland",
  "34216, Kragujevac, Centralna Srbija, Serbia",
  "Miller Place, New York, United States",
  "Lancashire, England, United Kingdom",
  "E7L 1K7, Divide, New Brunswick, Canada",
  "24112, Martinsville, Virginia, United States",
  "Malaya Bugul’ma, Tatarstan, Russia",
  "155909, Shuya, Ivanovo, Russia",
  "37743, Greeneville, Tennessee, United States",
  "Obshtina Karnobat, Burgas, Bulgaria",
  "TA3 6EX, Stoke St Gregory, England, United Kingdom",
  "Cherepovetsky District, Vologda, Russia",
  "Rockbridge Baths, Virginia, United States",
  "34136, Trieste, Friuli-Venezia Giulia, Italy",
  "Bath County, Kentucky, United States",
  "75300, Novotroitske, Kherson, Ukraine",
  "Herkimer, Kansas, United States",
  "725100, Câmpulung Moldovenesc, Suceava, Romania",
  "Winnebago County, Iowa, United States",
  "Griggsville, Illinois, United States",
  "Liptovský Mikuláš, Zilina, Slovakia",
  "Екібастуз державна адміністрація, Pavlodar Region, Kazakhstan",
  "Pépin, Tresques, Occitanie, France",
  "214031, Smolensk, Smolensk, Russia",
  "Knoxfield, Victoria, Australia",
  "Potter, Wisconsin, United States",
  "89, Reggio di Calabria, Calabria, Italy",
  "Churchill Park, Caerphilly, Wales, United Kingdom",
  "35530, İzmir, İzmir, Türkiye",
  "694640, Kholmsk, Sakhalin, Russia",
  "Warren, Rhode Island, United States",
  "Amherst, Ohio, United States",
  "Sunflower, Mississippi, United States",
  "2211, Pesnica pri Mariboru, Pesnica, Slovenia",
  "Jászberény District, Jász-Nagykun-Szolnok, Hungary",
  "960 53, Zvolen, Banska Bystrica, Slovakia",
  "Stewartsville, New Jersey, United States",
  "Sangarea Centre, Mamou Region, Guinea",
  "Hamilton County, Ohio, United States",
  "7715, Steinkjer, Trøndelag, Norway",
  "Powell Butte, Oregon, United States",
  "24114, Martinsville, Virginia, United States",
  "80147, Naples, Campania, Italy",
  "S13E9G1, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Amélia Rodrigues, Bahia, Brazil",
  "Cascade County, Montana, United States",
  "C25E0T5, Makinsk, Akmola Region, Kazakhstan",
  "169502, Sosnogorsk, Komi, Russia",
  "97122, Baie-Mahault, Basse-Terre, Guadeloupe",
  "692930, Nakhodka, Primorye, Russia",
  "Benton County, Indiana, United States",
  "Jackson County, Georgia, United States",
  "Vanceboro, North Carolina, United States",
  "K0G 1N0, Merrickville, Ontario, Canada",
  "San Nicolás de los Garza, Nuevo León, Mexico",
  "10123, Turin, Piedmont, Italy",
  "24415, Subotica, Vojvodina, Serbia",
  "Kingsbury Regis, Sherborne, England, United Kingdom",
  "676462, Svobodnyy, Amur, Russia",
  "346751, Bataysk, Rostov, Russia",
  "654000, Novokuznetsk, Kemerovo, Russia",
  "2400-773, Leiria, Leiria, Portugal",
  "363751, Mozdok, Severnaya Osetiya-Alaniya, Russia",
  "Nikel, Murmansk, Russia",
  "353919, Novorossiysk, Krasnodar, Russia",
  "Rio Grande, Rio Grande do Sul, Brazil",
  "Summit, New York, United States",
  "Comoé, Waterfalls, Burkina Faso",
  "Oxford County, Maine, United States",
  "56074, Nicollet, Minnesota, United States",
  "Charles County, Maryland, United States",
  "Volzhskiy, Volgograd, Russia",
  "33776, Seminole, Florida, United States",
  "Halifax County, North Carolina, United States",
  "Belknap, Louisville, Kentucky, United States",
  "Michurinsky District, Tambov, Russia",
  "E8K 3J1, Nicholas Denys, New Brunswick, Canada",
  "Round Prairie Estates, Gentry, Arkansas, United States",
  "Frodisia, Nicosia, Cyprus",
  "Adair Park, Atlanta, Georgia, United States",
  "Bas-Rhin, Grand Est, France",
  "Aikensville, Puslinch, Ontario, Canada",
  "Faulkner, New Smyrna Beach, Florida, United States",
  "Loire-Atlantique, Pays de la Loire, France",
  "43-300, Bielsko-Biała, Śląskie, Poland",
  "43942, Powhatan Point, Ohio, United States",
  "59630-670, Mossoró, Rio Grande do Norte, Brazil",
  "Kendall Square, Cambridge, Massachusetts, United States",
  "Cheremkhovsky District, Irkutsk, Russia",
  "Linz-Wels-Steyr Area",
  "Dubno Raion, Rivne, Ukraine",
  "399420, Bogoroditskoye, Lipetsk, Russia",
  "Buynaksky District, Dagestan, Russia",
  "89704, New Washoe City, Nevada, United States",
  "2460-248, Alcobaça, Leiria, Portugal",
  "Cambria, New York, United States",
  "99555, Sodankylä, Lapland, Finland",
  "Newcastle, New South Wales, Australia",
  "08690, Artvin, Artvin, Türkiye",
  "Butler County, Kentucky, United States",
  "Walt Whitman Houses, Brooklyn, New York, United States",
  "Sublette, Saint-Laurent-Nouan, Centre-Val de Loire, France",
  "18078-153, Sorocaba, São Paulo, Brazil",
  "Täby, Stockholm County, Sweden",
  "606422, Balakhna, Nizhniy Novgorod, Russia",
  "Old Leake, England, United Kingdom",
  "Obshtina Tsar Kaloyan, Razgrad, Bulgaria",
  "90520, Oulu, North Ostrobothnia, Finland",
  "5260, Odense, Region of Southern Denmark, Denmark",
  "Toboloto, Sinaloa, Mexico",
  "Personico, Ticino, Switzerland",
  "320109, Reşiţa, Caraş-Severin, Romania",
  "SO19 0AB, Southampton, England, United Kingdom",
  "Jeti-Oguz, Issyk-Kul Region, Kyrgyzstan",
  "Blaine, Minnesota, United States",
  "75214, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Astë, Kukës, Albania",
  "Blount, West Virginia, United States",
  "Tula, Russia",
  "Providence, Utah, United States",
  "34121, Trieste, Friuli-Venezia Giulia, Italy",
  "Dzerzhinskiy, Moscow, Russia",
  "6410, Mount Caroline, Western Australia, Australia",
  "Custer County, Montana, United States",
  "36752, Lowndesboro, Alabama, United States",
  "347923, Taganrog, Rostov, Russia",
  "Aleksin, Kaluga, Russia",
  "Nacogdoches County, Texas, United States",
  "Cheshire County, New Hampshire, United States",
  "Zyryanka, Kurgan, Russia",
  "47200, Mardin, Mardin, Türkiye",
  "100 12, Stockholm, Stockholm County, Sweden",
  "Andaman and Nicobar Islands, India",
  "Greater Decatur, IL Area",
  "39126, Magdeburg, Saxony-Anhalt, Germany",
  "Metz, Limpopo, South Africa",
  "Richmond, Missouri, United States",
  "KA18 2RL, Ochiltree, Scotland, United Kingdom",
  "Cedar Grove, New Jersey, United States",
  "Christian Malford, England, United Kingdom",
  "54024, Eureka Center, Wisconsin, United States",
  "Mason, Tennessee, United States",
  "Saint-Pierre, Reunion",
  "Westmoreland, Bath, England, United Kingdom",
  "28901, Andrews, North Carolina, United States",
  "Sligo, County Sligo, Ireland",
  "670033, Ulan-Ude, Buryatia, Russia",
  "03872, Sanbornville, New Hampshire, United States",
  "666677, Ust'-Ilimsk, Irkutsk, Russia",
  "Broome Street, England, United Kingdom",
  "Pope County, Minnesota, United States",
  "Vernon, Connecticut, United States",
  "679002, Birobidzhan, Birobijan, Russia",
  "35216, Birmingham, Alabama, United States",
  "Kalispell, Montana, United States",
  "43005, Tarragona, Catalonia, Spain",
  "663302, Noril'sk, Taymyr, Russia",
  "Carterton, England, United Kingdom",
  "Oldham Edge, Oldham, England, United Kingdom",
  "42010, Konya, Konya, Türkiye",
  "Huron Heights, London, Ontario, Canada",
  "Peniche, Leiria, Portugal",
  "Isle of Palms, South Carolina, United States",
  "Hopkins, Minnesota, United States",
  "Kenesary, Akmola Region, Kazakhstan",
  "391792, Gornyak, Ryazan’, Russia",
  "Shannon, County Clare, Ireland",
  "Valley, Alabama, United States",
  "Makarovskoye, Tatarstan, Russia",
  "43953, Steubenville, Ohio, United States",
  "Christiana, North-West, South Africa",
  "Oberkirch, Lucerne, Switzerland",
  "Bolvadin, Afyon, Türkiye",
  "Polk County, North Carolina, United States",
  "Dobrich, Dobrich, Bulgaria",
  "6403, Përmet, Gjirokastër, Albania",
  "60000, Khotin, Chernivtsi, Ukraine",
  "424040, Yoshkar-Ola, Mari El, Russia",
  "90650, Oulu, North Ostrobothnia, Finland",
  "Powhatan Point, Ohio, United States",
  "152307, Borisogleb, Yaroslavl’, Russia",
  "Uruguay",
  "Jasper County, Mississippi, United States",
  "16506, Erie, Pennsylvania, United States",
  "Coffee Farm, Durban, KwaZulu-Natal, South Africa",
  "Roanoke, Indiana, United States",
  "Valais, Switzerland",
  "412919, Vol'sk, Saratov, Russia",
  "Helsingborg, Skåne County, Sweden",
  "Greenbrier, Waterloo, Iowa, United States",
  "61022, Kharkiv, Kharkiv, Ukraine",
  "Washburn County, Wisconsin, United States",
  "59801, Missoula, Montana, United States",
  "Greater Uppsala Metropolitan Area",
  "04008, Almería, Andalusia, Spain",
  "La Traille de Roland, Nérigean, Nouvelle-Aquitaine, France",
  "Valley Park, Missouri, United States",
  "Portland Yamhill Historic District, Portland, Oregon, United States",
  "Bosques, Buenos Aires Province, Argentina",
  "Price Creek, North Carolina, United States",
  "393361, Kirsanov, Tambov, Russia",
  "368606, Derbent, Dagestan, Russia",
  "Rimavská Sobota, Banska Bystrica, Slovakia",
  "3371, Hardinxveld-Giessendam, South Holland, Netherlands",
  "Lackawanna County, Pennsylvania, United States",
  "Hamamözü, Amasya, Türkiye",
  "İzmir, Türkiye",
  "Belmont, New Hampshire, United States",
  "46040, Kahramanmaraş, Kahraman Maras, Türkiye",
  "67450, Lymanske, Odessa, Ukraine",
  "Cimarron, Laredo, Texas, United States",
  "Shirley Slope, New Providence, The Bahamas",
  "Stonewall, Manitoba, Canada",
  "Cameron, Texas, United States",
  "Provincia Tomas Barron, Oruro, Bolivia",
  "TR13 0AN, Nancegollan, England, United Kingdom",
  "Saki, Oyo State, Nigeria",
  "Shakhty, Novosibirsk, Russia",
  "Kokhma, Ivanovo, Russia",
  "676013, Skovorodino, Amur, Russia",
  "Selenicë, Vlorë, Albania",
  "Morshansk, Tambov, Russia",
  "652059, Yurga Vtoraya, Kemerovo, Russia",
  "Fremont County, Colorado, United States",
  "Stanstead Abbotts, England, United Kingdom",
  "Río Cuarto, Córdoba, Argentina",
  "Guernsey, Wyoming, United States",
  "Mörbylånga kommun, Kalmar County, Sweden",
  "Taylor, Michigan, United States",
  "43130, Tarragona, Catalonia, Spain",
  "Young County, Texas, United States",
  "22802, Harrisonburg, Virginia, United States",
  "Shawnee Slopes, Calgary, Alberta, Canada",
  "Dodge, Wisconsin, United States",
  "Aveiro, Aveiro, Portugal",
  "1340015, Urayasu, Chiba, Japan",
  "Marion County, West Virginia, United States",
  "06784, Sherman, Connecticut, United States",
  "56431, Aitkin, Minnesota, United States",
  "Goranboy District, Ganja-Dashkasan, Azerbaijan",
  "South Hutchinson, Kansas, United States",
  "S Rayavaram, Andhra Pradesh, India",
  "211415, Polatsk, Vitsyebsk, Belarus",
  "Howard County, Missouri, United States",
  "22300, Edirne, Edirne, Türkiye",
  "Greater Swansea Area",
  "40810, Bledsoe, Kentucky, United States",
  "50733, Cologne, North Rhine-Westphalia, Germany",
  "420077, Kazan, Tatarstan, Russia",
  "6004, East Perth, Western Australia, Australia",
  "663293, Severo-Yeniseyskiy, Krasnoyarsk Krai, Russia",
  "346408, Novocherkassk, Rostov, Russia",
  "356410, Blagodarnyy, Stavropol, Russia",
  "Põhja-Pärnumaa vald, Pärnu maakond, Estonia",
  "61-815, Poznan, Wielkopolskie, Poland",
  "79104, Freiburg, Baden-Württemberg, Germany",
  "Sequoyah, Oakland, California, United States",
  "130002, Târgovişte, Dîmboviţa, Romania",
  "Loganlea, Queensland, Australia",
  "52-129, Wrocław, Dolnośląskie, Poland",
  "607226, Arzamas, Nizhniy Novgorod, Russia",
  "8935 AA, Leeuwarden, Friesland, Netherlands",
  "Schleitheim, Schaffhausen, Switzerland",
  "Hidalgo del Parral, Chihuahua, Mexico",
  "Banská Stiavnica, Banska Bystrica, Slovakia",
  "Burlington, Iowa, United States",
  "Kitsman Raion, Chernivtsi, Ukraine",
  "Cleveland, North Carolina, United States",
  "3500, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Obshtina Dupnitsa, Kyustendil, Bulgaria",
  "Hocking County, Ohio, United States",
  "Khmilnytska miska hromada, Vinnytsya, Ukraine",
  "46910, Kouvola, Kymenlaakso, Finland",
  "Douglas, Arizona, United States",
  "Melung Rural Municipality, Nepal",
  "Charleston-Huntington Area",
  "Sudan",
  "47895, Domagnano, San Marino",
  "McDonald, Ohio, United States",
  "Lisbon, Ohio, United States",
  "97128, McMinnville, Oregon, United States",
  "Municipio Andŕes Eloy Blanco, Sucre State, Venezuela",
  "353484, Gelendzhik, Krasnodar, Russia",
  "981 34, Kiruna, Norrbotten County, Sweden",
  "671703, Severobaykal’sk, Buryatia, Russia",
  "Kingston Pike-Sequoyah Hills, Knoxville, Tennessee, United States",
  "R0A 9A1, Roseau River, Manitoba, Canada",
  "DG1 4TG, Dumfries, Scotland, United Kingdom",
  "166739, Shoyna, Nenets, Russia",
  "West Wickham, England, United Kingdom",
  "Dundee, Illinois, United States",
  "Rzhevskoye, Belgorod, Russia",
  "58318, Bottineau, North Dakota, United States",
  "Clay County, Mississippi, United States",
  "45150, Kouvola, Kymenlaakso, Finland",
  "Rocky Mount-Wilson Area",
  "Callaway Chase, Callaway, Florida, United States",
  "Falun, Alberta, Canada",
  "PA16 8HF, Greenock, Scotland, United Kingdom",
  "Derbentskiy, Krasnodar, Russia",
  "Bradley, Huddersfield, England, United Kingdom",
  "City Centre, Tabasco, Mexico",
  "LE12 6HX, East Leake, England, United Kingdom",
  "Denizli, Denizli, Türkiye",
  "45880, Payne, Ohio, United States",
  "Trinity Bay North, Newfoundland and Labrador, Canada",
  "32780, Titusville, Florida, United States",
  "Montours, Brittany, France",
  "Dzerzhinsk, Donetsk, Ukraine",
  "Angra do Heroísmo, Portugal",
  "C22A2B0, Atbasar, Akmola Region, Kazakhstan",
  "X38H6F6, Kentau, Turkistan Region, Kazakhstan",
  "Benavente, Santarém, Portugal",
  "186422, Segezha, Karelia, Russia",
  "05300, Amasya, Amasya, Türkiye",
  "McKean County, Pennsylvania, United States",
  "Douglas County, Minnesota, United States",
  "627709, Mezenka, Tyumen’, Russia",
  "Malheur County, Oregon, United States",
  "Rowanburn, Scotland, United Kingdom",
  "93274, Tulare, California, United States",
  "423802, Naberezhnyye Chelny, Tatarstan, Russia",
  "Volgograd, Russia",
  "85-001, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "440031, Penza, Penza, Russia",
  "32090, Isparta, Isparta, Türkiye",
  "Jones, Michigan, United States",
  "89075, Ulm, Baden-Württemberg, Germany",
  "Fairfield, New Jersey, United States",
  "Martinsville, Illinois, United States",
  "81-341, Gdynia, Pomorskie, Poland",
  "Tomball, Texas, United States",
  "07100, Antalya, Antalya, Türkiye",
  "Copiah County, Mississippi, United States",
  "L'gov, Kursk, Russia",
  "692904, Nakhodka, Primorye, Russia",
  "35220, Slavonski Šamac, Brod-Posavina, Croatia",
  "Jackson County, Illinois, United States",
  "Rockingham, Township 10, Arkansas, United States",
  "30032, Decatur, Georgia, United States",
  "Barton-Le-Clay, England, United Kingdom",
  "95548, Klamath, California, United States",
  "Bangkok City, Thailand",
  "East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "8064 AA, Hasselt, Overijssel, Netherlands",
  "48300, Muğla, Muğla, Türkiye",
  "Fondi, Latium, Italy",
  "Drniš, Sibenik-Knin, Croatia",
  "47053, Duisburg, North Rhine-Westphalia, Germany",
  "18055-300, Sorocaba, São Paulo, Brazil",
  "18200, Cankiri, Çankırı, Türkiye",
  "Haysville, Kansas, United States",
  "646768, Tikhvinka, Omsk, Russia",
  "89705, Carson City, Nevada, United States",
  "7785, Mitchells Plain, Western Cape, South Africa",
  "28913-100, Cabo Frio, Rio de Janeiro, Brazil",
  "03110, Afyonkarahisar, Afyon, Türkiye",
  "Renville County, North Dakota, United States",
  "Dixon Township, Illinois, United States",
  "Nizhny Tagil, Sverdlovsk, Russia",
  "7042, Trondheim, Trøndelag, Norway",
  "LU2 9TN, Luton, England, United Kingdom",
  "9420, Harstad, Troms og Finnmark, Norway",
  "BT28 2RB, Lisburn, Northern Ireland, United Kingdom",
  "Colonial, Ijuí, Rio Grande do Sul, Brazil",
  "Chatham, England, United Kingdom",
  "29386, Spartanburg, South Carolina, United States",
  "Highland Park, New Jersey, United States",
  "624273, Asbest, Sverdlovsk, Russia",
  "Merrick, Springfield, Massachusetts, United States",
  "58200, Savonlinna, South Savo, Finland",
  "Gera, Thuringia, Germany",
  "Davidson Ridge, South Carolina, United States",
  "06127, Perugia, Umbria, Italy",
  "433503, Dimitrovgrad, Ul’yanovsk, Russia",
  "North Tazewell, Virginia, United States",
  "Dupnitsa, Kyustendil, Bulgaria",
  "Ul'yanovskoye, Tatarstan, Russia",
  "87525, Taos Ski Valley, New Mexico, United States",
  "N0A 0A6, Cayuga, Ontario, Canada",
  "621 57, Visby, Gotland County, Sweden",
  "Volkovyskiy gorodskoy sovet, Hrodna, Belarus",
  "11700, Novohrad-Volyns’kyy, Zhytomyr, Ukraine",
  "Ayanka, Koryak, Russia",
  "Novy Urengoy, Yamalo-Nenets, Russia",
  "79096, Wheeler, Texas, United States",
  "Harney Heights, Vancouver, Washington, United States",
  "29151, Sumter, South Carolina, United States",
  "Greenbrier County, West Virginia, United States",
  "63600, Şanlıurfa, Şanliurfa, Türkiye",
  "Greater Pittsfield Area",
  "Tyler County, West Virginia, United States",
  "Kalachinsk, Omsk, Russia",
  "Morton County, Kansas, United States",
  "Siong, Kedah, Malaysia",
  "Kilkenny, Minnesota, United States",
  "Saratoga, California, United States",
  "Hancock, Maine, United States",
  "Malá Subotica, Međimurje, Croatia",
  "Manchester Area, United Kingdom",
  "Cortlandt, New York, United States",
  "625001, Tyumen, Tyumen’, Russia",
  "N13G1G8, Aral, Kyzylorda Region, Kazakhstan",
  "94538, Fremont, California, United States",
  "Belfast, Northern Ireland, United Kingdom",
  "Greater Cambridge Area",
  "29033-367, Vitória, Espírito Santo, Brazil",
  "Sikkim, India",
  "SS1 2WL, Southend-On-Sea, England, United Kingdom",
  "31000, Toulouse, Occitanie, France",
  "Yefremovo-Stepanovskoye, Rostov, Russia",
  "440011, Satu Mare, Satu Mare, Romania",
  "Roanoke, Alabama, United States",
  "51101, Rijeka, Primorje-Gorski, Croatia",
  "2984 AA, Ridderkerk, South Holland, Netherlands",
  "10457, Bronx, New York, United States",
  "Clayton, Ontario, Canada",
  "Wharton, New Jersey, United States",
  "14589, Williamson, New York, United States",
  "Chickasaw Place, Florida, United States",
  "694225, Poronaysk, Sakhalin, Russia",
  "Le Rucher, Gisy-les-Nobles, Bourgogne-Franche-Comté, France",
  "Voronezh, Voronezh, Russia",
  "07922, Berkeley Heights, New Jersey, United States",
  "Clarion, Pennsylvania, United States",
  "85049, Ingolstadt, Bavaria, Germany",
  "Furth im Wald, Bavaria, Germany",
  "Parker, Colorado, United States",
  "Vllahinë, Vlorë, Albania",
  "Raynham, Massachusetts, United States",
  "Rickenbach, Lucerne, Switzerland",
  "Hof, Bavaria, Germany",
  "Valparaíso, Valparaiso Region, Chile",
  "655009, Abakan, Khakassia, Russia",
  "Bryans Road, Maryland, United States",
  "28523, Vaciamadrid, Community of Madrid, Spain",
  "Küre, Kastamonu, Türkiye",
  "Elista, Kalmykia, Russia",
  "Otero, Pergamino, Buenos Aires Province, Argentina",
  "X06F0B7, Shymkent, Shymkent, Kazakhstan",
  "Dominican Republic",
  "10120, Tallinn, Harjumaa, Estonia",
  "59727, Divide, Montana, United States",
  "60514, Clarendon Hills, Illinois, United States",
  "Sokolnice, South Moravia, Czechia",
  "45279, Essen, North Rhine-Westphalia, Germany",
  "Volgograd, Volgograd, Russia",
  "Reynolds, Nebraska, United States",
  "Barton, Oxford, England, United Kingdom",
  "224027, Brest, Brest, Belarus",
  "Greater Jacksonville, NC Area",
  "Pepingen, Flemish Region, Belgium",
  "Quitman County, Mississippi, United States",
  "Runnels County, Texas, United States",
  "Ivanovo Municipality, Ruse, Bulgaria",
  "Scotland, United Kingdom",
  "43933, Tlanalapa, Hidalgo, Mexico",
  "03200, Afyonkarahisar, Afyon, Türkiye",
  "09125, Chemnitz, Saxony, Germany",
  "152918, Rybinsk, Yaroslavl’, Russia",
  "248010, Kaluga, Kaluga, Russia",
  "10300, Georgetown, Penang, Malaysia",
  "46061, Noblesville, Indiana, United States",
  "BT79 0EL, Omagh, Northern Ireland, United Kingdom",
  "633127, Sarapulka, Novosibirsk, Russia",
  "617452, Kungur, Perm, Russia",
  "Middlesex County, New Jersey, United States",
  "Daytona Beach, Florida, United States",
  "Greeley, Montana, United States",
  "Atchison, Kansas, United States",
  "Adaklı, Bingöl, Türkiye",
  "29240-000, Alfredo Chaves, Espírito Santo, Brazil",
  "Livingston, Louisiana, United States",
  "Barnesville, New Brunswick, Canada",
  "23289, Henrico, Virginia, United States",
  "Shchuch’ye, Kurgan, Russia",
  "2614, Lillehammer, Innlandet, Norway",
  "678730, Ust’-Nera, Sakha, Russia",
  "Corona, Queens, New York, United States",
  "Ulubey, Ordu, Türkiye",
  "Buturlinovka, Voronezh, Russia",
  "Kings, Nova Scotia, Canada",
  "Winchester, England, United Kingdom",
  "446101, Chapayevsk, Samara, Russia",
  "Karlsruhe, Baden-Württemberg, Germany",
  "Antioquia, Colombia",
  "Walshausen, Rhineland-Palatinate, Germany",
  "Koochiching County, Minnesota, United States",
  "San Marino, California, United States",
  "78214, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "Craigavon, Northern Ireland, United Kingdom",
  "29808, Aiken, South Carolina, United States",
  "23558, Lübeck, Schleswig-Holstein, Germany",
  "Greater Catania Metropolitan Area",
  "Patrick Estate, Queensland, Australia",
  "Braşov, Braşov, Romania",
  "Bujumbura, Bujumbura Mairie, Burundi",
  "Christiansted, St Croix, US Virgin Islands",
  "48006, Bilbao, Basque Country, Spain",
  "Anderson, California, United States",
  "Carlton, England, United Kingdom",
  "Barronsknock, County Kilkenny, Ireland",
  "Oman",
  "13815, Norwich, New York, United States",
  "8574, Kissonerga, Paphos, Cyprus",
  "675028, Blagoveshchensk, Amur, Russia",
  "12303, Schenectady, New York, United States",
  "Jamestown, North Carolina, United States",
  "Ust’-Nera, Sakha, Russia",
  "Turakurgan, Namangan Region, Uzbekistan",
  "618548, Solikamsk, Perm, Russia",
  "Berks County, Pennsylvania, United States",
  "Murray River, New South Wales, Australia",
  "66700, Yozgat, Yozgat, Türkiye",
  "Pyrénées-Atlantiques, Nouvelle-Aquitaine, France",
  "97203, Mérida, Yucatán, Mexico",
  "Chaseburg, Wisconsin, United States",
  "Juneau County, Wisconsin, United States",
  "74075, Stillwater, Oklahoma, United States",
  "L'govskoye, Ryazan’, Russia",
  "Lander, Roffiac, Auvergne-Rhône-Alpes, France",
  "647502, Dudinka, Krasnoyarsk Krai, Russia",
  "Upper Sandusky, Ohio, United States",
  "5024, Çorovodë, Berat, Albania",
  "9000-000, Funchal, Madeira Island, Portugal",
  "9705, Szombathely, Vas, Hungary",
  "Kryvyi Rih, Dnipropetrovsk, Ukraine",
  "694680, Kholmsk, Sakhalin, Russia",
  "DD3 0AA, Dundee, Scotland, United Kingdom",
  "Bremen, Alabama, United States",
  "Pleasent Street, DeKalb, Illinois, United States",
  "H91 KNY3, Galway, County Galway, Ireland",
  "McKenzie, Eugene, Oregon, United States",
  "Konyaalti, Antalya, Türkiye",
  "Grimesland, North Carolina, United States",
  "9414, Harstad, Troms og Finnmark, Norway",
  "Köse, Gümüşhane, Türkiye",
  "Wroclaw Metropolitan Area",
  "665101, Nizhneudinsk, Irkutsk, Russia",
  "4431, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "347380, Volgodonsk, Rostov, Russia",
  "04002, Almería, Andalusia, Spain",
  "Senegal",
  "Catawba County, North Carolina, United States",
  "Asnyezhytsy, Brest, Belarus",
  "Northumberland County, Pennsylvania, United States",
  "Touros, Rio Grande do Norte, Brazil",
  "Dooru, Jammu & Kashmir, India",
  "Codlea, Braşov, Romania",
  "88-103, Inowrocław, Kujawsko-pomorskie, Poland",
  "Salerno, Campania, Italy",
  "142203, Serpukhov, Moscow, Russia",
  "40-036, Katowice, Śląskie, Poland",
  "Holmestrand, Vestfold og Telemark, Norway",
  "4814, Mount Louisa, Queensland, Australia",
  "Nova Granada, São Paulo, Brazil",
  "Horta, Ilha do Faial, Portugal",
  "33195, Oviedo, Principality of Asturias, Spain",
  "60118, Dundee, Illinois, United States",
  "Togliatti, Samara, Russia",
  "Kassel, Hesse, Germany",
  "Dublin 9, County Dublin, Ireland",
  "Broomes Island, Maryland, United States",
  "Horad Polatsk, Vitsyebsk, Belarus",
  "55013, Chisago City, Minnesota, United States",
  "Eddy County, North Dakota, United States",
  "Russell, Kentucky, United States",
  "Kielce Metropolitan Area",
  "BT78 5EJ, Omagh, Northern Ireland, United Kingdom",
  "Cimarron, New Mexico, United States",
  "83100, Toulon, Provence-Alpes-Côte d'Azur, France",
  "Devaruppula, Telangana, India",
  "02919, Johnston, Rhode Island, United States",
  "L25 8RA, Liverpool, England, United Kingdom",
  "Middlesex Centre, Ontario, Canada",
  "Federal District, Venezuela",
  "Reynolds, Georgia, United States",
  "Salem, Alabama, United States",
  "162605, Cherepovets, Vologda, Russia",
  "Fillmore, Illinois, United States",
  "14865, Montour Falls, New York, United States",
  "Jefferson County, Arkansas, United States",
  "232 31, Malmo, Skåne County, Sweden",
  "Cannons Creek, Porirua, Wellington, New Zealand",
  "34135, Trieste, Friuli-Venezia Giulia, Italy",
  "Kimble Wick, England, United Kingdom",
  "Clay County, Indiana, United States",
  "Bowman, North Dakota, United States",
  "624594, Ivdel’, Sverdlovsk, Russia",
  "67601, Hays, Kansas, United States",
  "656065, Barnaul, Altai Krai, Russia",
  "28400, Giresun, Giresun, Türkiye",
  "Hillsborough, New Hampshire, United States",
  "88, Crotone, Calabria, Italy",
  "Yeroskipou, Paphos, Cyprus",
  "07758, Port Monmouth, New Jersey, United States",
  "Wyandot County, Ohio, United States",
  "63570, Şanlıurfa, Şanliurfa, Türkiye",
  "Moore, Oklahoma, United States",
  "Greater Groningen Area",
  "Chippewa County, Wisconsin, United States",
  "Linn, West Virginia, United States",
  "53221, Milwaukee, Wisconsin, United States",
  "Kapanewon Prambanan, Yogyakarta, Indonesia",
  "10010, Balıkesir, Balıkesir, Türkiye",
  "690001, Vladivostok, Primorye, Russia",
  "Marrakesh, Marrakesh-Safi, Morocco",
  "Kirstead Green, England, United Kingdom",
  "87280, Limoges, Nouvelle-Aquitaine, France",
  "81090, Walsh, Colorado, United States",
  "54028, Mykolaiv, Mykolayiv, Ukraine",
  "3501, Hasselt, Flemish Region, Belgium",
  "Killarney, Queensland, Australia",
  "9871, Kemijärvi, Lapland, Finland",
  "Liberty, Indiana, United States",
  "4508, Lezhë, Lezhë, Albania",
  "17972, Schuylkill Haven, Pennsylvania, United States",
  "67300, Zonguldak, Zonguldak, Türkiye",
  "Stavroúpolis, Eastern Macedonia and Thrace, Greece",
  "30122, Venice, Veneto, Italy",
  "Kirsebærhuse, Region of Southern Denmark, Denmark",
  "Phillipsburg, New Jersey, United States",
  "6016, Ålesund, Møre og Romsdal, Norway",
  "Egerup Huse, Region Zealand, Denmark",
  "652480, Anzhero-Sudzhensk, Kemerovo, Russia",
  "Bârlad, Vaslui, Romania",
  "84129, Salerno, Campania, Italy",
  "Powderly, Texas, United States",
  "Monroe, North Carolina, United States",
  "Portage, Michigan, United States",
  "İyidere, Rize, Türkiye",
  "EN6 1JN, Potters Bar, England, United Kingdom",
  "Parker, Kansas, United States",
  "8100-237, Loulé, Faro, Portugal",
  "Kalinino, Krasnodar, Russia",
  "Cooke Town, Bengaluru North, Karnataka, India",
  "Wilson County, Texas, United States",
  "20122, Milan, Lombardy, Italy",
  "E25C1G2, Maqat, Atyrau Region, Kazakhstan",
  "Chemung, Ontario, Canada",
  "96500, Rovaniemi, Lapland, Finland",
  "ST3 1AA, Stoke-On-Trent, England, United Kingdom",
  "346893, Bataysk, Rostov, Russia",
  "79108, Freiburg, Baden-Württemberg, Germany",
  "9050-061, Funchal, Madeira Island, Portugal",
  "Polk County, Minnesota, United States",
  "Finland",
  "Lauderdale County, Alabama, United States",
  "LV-3614, Pope, Ventspils, Latvia",
  "254 50, Helsingborg, Skåne County, Sweden",
  "453304, Kumertau, Bashkortostan, Russia",
  "E9C 2B4, New Bandon Northumberland County, New Brunswick, Canada",
  "Blue Springs, Missouri, United States",
  "Mississippi County, Arkansas, United States",
  "BH7 6HW, Bournemouth, England, United Kingdom",
  "Süleymanpaşa, Tekirdağ, Türkiye",
  "İliç, Erzincan, Türkiye",
  "5724, Teteven, Lovech, Bulgaria",
  "Călăraşi, Dolj, Romania",
  "2303, Hamar, Innlandet, Norway",
  "06182, Badajoz, Extremadura, Spain",
  "34132, Kassel, Hesse, Germany",
  "10511, Buchanan, New York, United States",
  "430001, Saransk, Mordovia, Russia",
  "14075, Hamburg, New York, United States",
  "58410, Savonlinna, South Savo, Finland",
  "655018, Abakan, Khakassia, Russia",
  "617475, Kungur, Perm, Russia",
  "Sérrai, Central Macedonia, Greece",
  "Bari, Rajasthan, India",
  "57200, Savonlinna, South Savo, Finland",
  "SA6 8AJ, Swansea, Wales, United Kingdom",
  "X38Y7H4, Kentau, Turkistan Region, Kazakhstan",
  "33974, Lehigh Acres, Florida, United States",
  "Ramsey Heights, England, United Kingdom",
  "47597, Wheatland, Indiana, United States",
  "MD-4572, Coșnița, Dubăsari District, Moldova",
  "658825, Slavgorod, Altai Krai, Russia",
  "Les Vignes, Bouillé-Ménard, Pays de la Loire, France",
  "Donegal, County Donegal, Ireland",
  "Salta, Argentina",
  "Grand Junction, Colorado, United States",
  "Middelburg, Eastern Cape, South Africa",
  "14518, Phelps, New York, United States",
  "Nashua, Minnesota, United States",
  "20732, Chesapeake Beach, Maryland, United States",
  "Verona, Wisconsin, United States",
  "Tompkinsville, Florida, United States",
  "66845, Cottonwood Falls, Kansas, United States",
  "Greenville, Alabama, United States",
  "Ashtabula, Ohio, United States",
  "347904, Taganrog, Rostov, Russia",
  "Pécs, Baranya, Hungary",
  "624766, Verkhnyaya Salda, Sverdlovsk, Russia",
  "Birmingham, Michigan, United States",
  "New Mark, Chouteau, Missouri, United States",
  "30121, Cartersville, Georgia, United States",
  "Gonzales, Texas, United States",
  "Orange County, Florida, United States",
  "98263, Lyman, Washington, United States",
  "53207, Milwaukee, Wisconsin, United States",
  "Rockingham, North Carolina, United States",
  "La Cotardière, Grimesnil, Normandy, France",
  "Szegedi járás, Csongrád, Hungary",
  "Buchanan, New York, United States",
  "725300, Gura Humorului, Suceava, Romania",
  "NN15 5NX, Burton Latimer, England, United Kingdom",
  "Callan, County Kilkenny, Ireland",
  "Itasca County, Minnesota, United States",
  "Ziebach County, South Dakota, United States",
  "4604, Rrëshen, Lezhë, Albania",
  "670000, Ulan-Ude, Buryatia, Russia",
  "75032, Rockwall, Texas, United States",
  "Battle Creek, Michigan, United States",
  "Ritchie, Bengough 40, Saskatchewan, Canada",
  "70300, Kuopio, Northern Savonia, Finland",
  "Paide linn, Jarva, Estonia",
  "Thurston Planche, England, United Kingdom",
  "98019, Duvall, Washington, United States",
  "14482, Potsdam, Brandenburg, Germany",
  "Cleveland, Texas, United States",
  "77103, Siauliai, Siauliu, Lithuania",
  "16137, Mercer, Pennsylvania, United States",
  "Kyrgyzstan",
  "Arizona, United States",
  "301830, Bogoroditsk, Tula, Russia",
  "Spokane County, Washington, United States",
  "Burnett, Bentonville, Arkansas, United States",
  "18709, Luzerne, Pennsylvania, United States",
  "611 50, Nyköping, Södermanland County, Sweden",
  "98548, Hoodsport, Washington, United States",
  "56537, Fergus Falls, Minnesota, United States",
  "Moskovskiy, Brest, Belarus",
  "Goshen, Ohio, United States",
  "180, Rostock, Mecklenburg-West Pomerania, Germany",
  "53300, Lappeenranta, South Karelia, Finland",
  "62234, Collinsville, Illinois, United States",
  "Omsky District, Omsk, Russia",
  "LS11 0AA, Leeds, England, United Kingdom",
  "Chile",
  "Emeryville, Ontario, Canada",
  "Morton, Texas, United States",
  "Ozark Acres, Arkansas, United States",
  "Bennington, Oklahoma, United States",
  "18000, Niš, Centralna Srbija, Serbia",
  "18847, Susquehanna, Pennsylvania, United States",
  "180007, Pskov, Pskov, Russia",
  "Wellsville, New York, United States",
  "Amizour, Bejaia, Algeria",
  "5611 AA, Eindhoven, North Brabant, Netherlands",
  "680000, Khabarovsk, Khabarovsk, Russia",
  "Rēzekne, Rezekne, Latvia",
  "Oregon, Illinois, United States",
  "3402, Librazhd, Elbasan, Albania",
  "Logan, Ohio, United States",
  "Grayson, Georgia, United States",
  "Vilyuysk, Sakha, Russia",
  "Archero, Dronero, Piedmont, Italy",
  "Bolzano, Trentino-Alto Adige, Italy",
  "Assens Municipality, Region of Southern Denmark, Denmark",
  "7036, Trondheim, Trøndelag, Norway",
  "621 58, Visby, Gotland County, Sweden",
  "Lafayette County, Florida, United States",
  "Banner, Wyoming, United States",
  "658821, Slavgorod, Altai Krai, Russia",
  "Pine Bluff, Arkansas, United States",
  "623300, Krasnoufimsk, Sverdlovsk, Russia",
  "Ripley, England, United Kingdom",
  "Huntingdon County, Pennsylvania, United States",
  "4029, Debrecen, Hajdú-Bihar, Hungary",
  "Greater Brighton and Hove Area",
  "90420, Oulu, North Ostrobothnia, Finland",
  "156014, Kostroma, Kostroma, Russia",
  "Greene, Iowa, United States",
  "Charleston, Tennessee, United States",
  "Covington, Pennsylvania, United States",
  "Fallbrook, California, United States",
  "Lewis and Clark, Missoula, Montana, United States",
  "Cairo, Egypt",
  "Karsun, Ul’yanovsk, Russia",
  "Dunklin County, Missouri, United States",
  "Ventspils Municipality, Latvia",
  "03801, Portsmouth, New Hampshire, United States",
  "R0M 0A3, Pipestone, Manitoba, Canada",
  "Roanoke Rapids, North Carolina, United States",
  "Guadeloupe",
  "North Slope Borough, Alaska, United States",
  "47004, Valladolid, Castilla and Leon, Spain",
  "CO16 0LH, Thorpe-Le-Soken, England, United Kingdom",
  "Halifax Peninsula, Halifax, Nova Scotia, Canada",
  "9403 AA, Assen, Drenthe, Netherlands",
  "Gaythorne, Queensland, Australia",
  "Nova Zagora, Sliven, Bulgaria",
  "28277, Charlotte, North Carolina, United States",
  "11100, Aosta, Valle d'Aosta, Italy",
  "Humboldt Park, Chicago, Illinois, United States",
  "North Randall, Ohio, United States",
  "Habo, Jönköping County, Sweden",
  "21077, Hamburg, Hamburg, Germany",
  "90124, Palermo, Sicily, Italy",
  "Bebrene Parish, Daugavpils, Latvia",
  "Rio Blanco County, Colorado, United States",
  "49055, Dnipro, Dnipropetrovsk, Ukraine",
  "34080, Montpellier, Occitanie, France",
  "78564, Zapata, Texas, United States",
  "Stone Mountain, Georgia, United States",
  "Texcoco de Mora, México, Mexico",
  "Addison, Vermont, United States",
  "Lane End, England, United Kingdom",
  "Fairfax, Minnesota, United States",
  "Atoka, Virginia, United States",
  "662541, Lesosibirsk, Krasnoyarsk Krai, Russia",
  "31550, Valpovo, Osijek-Baranja, Croatia",
  "2033 WD, Haarlem, North Holland, Netherlands",
  "Fort Concho, San Angelo, Texas, United States",
  "185 33, Piraeus, Attiki, Greece",
  "New Juaben North Municipal District, Eastern Region, Ghana",
  "49088, Osnabrück, Lower Saxony, Germany",
  "446002, Syzran, Samara, Russia",
  "Rouen, Normandy, France",
  "682462, Nikolayevsk-na-Amure, Khabarovsk, Russia",
  "Olovyannaya, Zabaykalsky Krai, Russia",
  "Kamensk-Uralsky, Sverdlovsk, Russia",
  "Sheridan, Wyoming, United States",
  "Jetisay, Turkistan Region, Kazakhstan",
  "33300, Bordeaux, Nouvelle-Aquitaine, France",
  "E9C 1T4, Storeytown, New Brunswick, Canada",
  "Belmont, Victoria, Australia",
  "Apatou, Saint-Laurent-du-Maroni, French Guiana",
  "346430, Novocherkassk, Rostov, Russia",
  "2341, Maribor, Maribor, Slovenia",
  "Preston City, Connecticut, United States",
  "341, Kassel, Hesse, Germany",
  "21229, Baltimore, Maryland, United States",
  "Hampton, Virginia, United States",
  "V0H 1T0, Oliver, British Columbia, Canada",
  "65051, Linn, Missouri, United States",
  "Rosso Sénégal, Saint-Louis Region, Senegal",
  "727529, Stroiești, Suceava, Romania",
  "356403, Blagodarnyy, Stavropol, Russia",
  "66033, Greeley, Kansas, United States",
  "Wisen, Solothurn, Switzerland",
  "413849, Balakovo, Saratov, Russia",
  "BT1 6ES, Belfast, Northern Ireland, United Kingdom",
  "3434, Kerrie, Victoria, Australia",
  "East London, Eastern Cape, South Africa",
  "Coles County, Illinois, United States",
  "Saint-Laurent-les-Tours, Occitanie, France",
  "Odessa, Ontario, Canada",
  "Oliver, Ohio, United States",
  "Robertson, Connecticut, United States",
  "320, Ourense, Galicia, Spain",
  "Dayton, Tennessee, United States",
  "Rotterdam, New York, United States",
  "Ralls County, Missouri, United States",
  "Snina, Presov, Slovakia",
  "20112, Manassas, Virginia, United States",
  "Nikolayevskoye, Chechnya, Russia",
  "358903, Elista, Kalmykia, Russia",
  "450011, Zalău, Sălaj, Romania",
  "Morgan County, Alabama, United States",
  "Abakanovo, Vologda, Russia",
  "Karstula, Central Finland, Finland",
  "23242, Henrico, Virginia, United States",
  "Yerevan, Yerevan, Armenia",
  "Växjö kommun, Kronoberg County, Sweden",
  "18000, Cankiri, Çankırı, Türkiye",
  "10000, Zagreb, Zagreb, Croatia",
  "640020, Kurgan, Kurgan, Russia",
  "Huron-Kinloss, Ontario, Canada",
  "Zwolle, Louisiana, United States",
  "55200, Samsun, Samsun, Türkiye",
  "Prescott and Russell, Ontario, Canada",
  "Zagreb, Zagreb, Croatia",
  "624480, Severoural’sk, Sverdlovsk, Russia",
  "624577, Ivdel’, Sverdlovsk, Russia",
  "Matagorda County, Texas, United States",
  "8020, Székesfehérvár, Fejér, Hungary",
  "G6B 1A1, Nantes, Quebec, Canada",
  "656067, Barnaul, Altai Krai, Russia",
  "182100, Velikiye Luki, Pskov, Russia",
  "Daneshill, Basingstoke, England, United Kingdom",
  "06133, Perugia, Umbria, Italy",
  "25430, Erzurum, Erzurum, Türkiye",
  "Valday, Tver’, Russia",
  "23502, Elâzığ, Elazığ, Türkiye",
  "Norton City County, Virginia, United States",
  "04259, Monmouth, Maine, United States",
  "30633, Danielsville, Georgia, United States",
  "672000, Chita, Zabaykalsky Krai, Russia",
  "Queensland, Australia",
  "Sibenik-Knin, Croatia",
  "Mitchell, Australian Capital Territory, Australia",
  "Mâcon, Bourgogne-Franche-Comté, France",
  "Fulton, Ohio, United States",
  "Lucas, Iowa, United States",
  "McLeod Gardens, Florida, United States",
  "4562, Biberist, Solothurn, Switzerland",
  "25442, Shenandoah Junction, West Virginia, United States",
  "Otsego County, Michigan, United States",
  "18913, Carversville, Pennsylvania, United States",
  "446009, Syzran, Samara, Russia",
  "56124, Pisa, Tuscany, Italy",
  "Berdyansk, Zaporizhzhya, Ukraine",
  "Cambodia",
  "Comanche Creek, Texas, United States",
  "4301, Koplik, Shkodër, Albania",
  "Lucas, Ohio, United States",
  "40509, Lexington, Kentucky, United States",
  "47012, Valladolid, Castilla and Leon, Spain",
  "Gösgen, Solothurn, Switzerland",
  "8917 AA, Leeuwarden, Friesland, Netherlands",
  "Dundalk, Ontario, Canada",
  "641884, Shadrinsk, Kurgan, Russia",
  "Washtenaw Heights, Michigan, United States",
  "Craig County, Virginia, United States",
  "Addison, Maine, United States",
  "309145, Gubkin, Belgorod, Russia",
  "Vigo di Cadore, Veneto, Italy",
  "Greater Macon",
  "894, Örnsköldsvik, Västernorrland County, Sweden",
  "220005, Minsk, Minsk, Belarus",
  "South Yellowstone, Montana, United States",
  "Greater Bremen Area",
  "Terrell Plantation, Rolesville, North Carolina, United States",
  "Porirua, Wellington, New Zealand",
  "Stansfield, England, United Kingdom",
  "03815, Strafford, New Hampshire, United States",
  "3122, Tønsberg, Vestfold og Telemark, Norway",
  "628417, Surgut, Khanty-Mansi, Russia",
  "Pokrovska selyshchna hromada, Dnipropetrovsk, Ukraine",
  "27010, Kilis, Kilis, Türkiye",
  "Rēzekne Municipality, Latvia",
  "94061, Redwood City, California, United States",
  "Pápa, Veszprém, Hungary",
  "623388, Polevskoy, Sverdlovsk, Russia",
  "Plaster Rock, New Brunswick, Canada",
  "Big Five Hlabisa, KwaZulu-Natal, South Africa",
  "Kemper Creek, Mississippi, United States",
  "Wright, Kansas, United States",
  "B0J 0B2, Lunenburg, Nova Scotia, Canada",
  "Batmany, Ivanovo, Russia",
  "Yates, Newport News, Virginia, United States",
  "Limestone County, Alabama, United States",
  "72212, Zenica, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Fredericktown, Ohio, United States",
  "Novocherkasskiy, Orenburg, Russia",
  "Saudi Arabia",
  "Bouillé-Ménard, Pays de la Loire, France",
  "Kilis, Kilis, Türkiye",
  "Turkistan Region, Kazakhstan",
  "B0N 1V0, Meaghers Grant, Nova Scotia, Canada",
  "169301, Ukhta, Komi, Russia",
  "Gmina Olsztyn, Śląskie, Poland",
  "Kanawha Falls, West Virginia, United States",
  "Gregory County, South Dakota, United States",
  "Kerridge, Macclesfield, England, United Kingdom",
  "Guadalupe County, Texas, United States",
  "39106, Magdeburg, Saxony-Anhalt, Germany",
  "Zavet Municipality, Razgrad, Bulgaria",
  "169305, Ukhta, Komi, Russia",
  "98120, Kemijärvi, Lapland, Finland",
  "3372 VL, Hardinxveld-Giessendam, South Holland, Netherlands",
  "Collingswood, New Jersey, United States",
  "32212, Čačak, Centralna Srbija, Serbia",
  "F39B6G4, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Bejaia District, Bejaia, Algeria",
  "NR24 2RS, Edgefield, England, United Kingdom",
  "Hardyville, Kentucky, United States",
  "Zdolbuniv, Rivne, Ukraine",
  "624231, Nizhnyaya Tura, Sverdlovsk, Russia",
  "Oconee County, South Carolina, United States",
  "Caldwell County, Texas, United States",
  "Paulding, Mississippi, United States",
  "Central McKenzie, North Dakota, United States",
  "Rossendale, England, United Kingdom",
  "Bedford, Leigh, England, United Kingdom",
  "29943, Tillman, South Carolina, United States",
  "Montes Claros, Minas Gerais, Brazil",
  "Stichtse Vecht, Utrecht, Netherlands",
  "Linares, Cantabria, Spain",
  "78753, Austin, Texas, United States",
  "Rostock, Ontario, Canada",
  "Shelby, Michigan, United States",
  "Batesville, Arkansas, United States",
  "35-326, Rzeszów, Podkarpackie, Poland",
  "Berat, Queensland, Australia",
  "Sorgun, Yozgat, Türkiye",
  "7103, Szekszárd, Tolna, Hungary",
  "Sweet Valley, Pennsylvania, United States",
  "Curry, County Galway, Ireland",
  "Port Lavaca, Texas, United States",
  "Yeletsko-Lozovskiy, Lipetsk, Russia",
  "63738, Valle de Banderas, Nayarit, Mexico",
  "141542, Solnechnogorsk, Moscow, Russia",
  "611 46, Nyköping, Södermanland County, Sweden",
  "Athens, Pennsylvania, United States",
  "555300, Cisnădie, Sibiu, Romania",
  "Cherry Willingham, England, United Kingdom",
  "Belfield–Emporia Historic District, Virginia, United States",
  "35244, Birmingham, Alabama, United States",
  "301369, Aleksin, Kaluga, Russia",
  "623417, Kamensk Uralskiy, Sverdlovsk, Russia",
  "26003, Logroño, Autonomía de La Rioja, Spain",
  "01130, Adana, Adana, Türkiye",
  "Burkesville, Kentucky, United States",
  "Luzerne, Canton of Rosans, Provence-Alpes-Côte d'Azur, France",
  "New Taipei City, New Taipei City, Taiwan",
  "Preble, New York, United States",
  "Lakeland, Florida, United States",
  "79091, Umbarger, Texas, United States",
  "Greater Sheffield Area",
  "23170, La Guardia de Jaén, Andalusia, Spain",
  "74836, Stonewall, Oklahoma, United States",
  "Washburn, Wisconsin, United States",
  "IP31 3SD, Thurston, England, United Kingdom",
  "See/Lac, Fribourg, Switzerland",
  "76351, Archer City, Texas, United States",
  "Brookend, Lydney, England, United Kingdom",
  "9444, Evenskjær, Troms og Finnmark, Norway",
  "89040, Overton, Nevada, United States",
  "33763, Clearwater, Florida, United States",
  "Carbon, Texas, United States",
  "2012 NR, Haarlem, North Holland, Netherlands",
  "Agency, Missouri, United States",
  "Agency, Iowa, United States",
  "Cherlaksky District, Omsk, Russia",
  "Twin Falls, Idaho, United States",
  "423408, Almetyevsk, Tatarstan, Russia",
  "Santiago Metropolitan Area",
  "Waynesville, North Carolina, United States",
  "McIntosh County, Georgia, United States",
  "95126, Catania, Sicily, Italy",
  "Maury, Saint-Just-Malmont, Auvergne-Rhône-Alpes, France",
  "GY1 1HL, Saint Peter Port, Guernsey, Guernsey",
  "OL9 7AA, Oldham, England, United Kingdom",
  "58060, Sivas, Sivas, Türkiye",
  "238171, Chernyakhovskiy, Kaliningrad, Russia",
  "40313, Clearfield, Kentucky, United States",
  "Schwyz, Schwyz, Switzerland",
  "37135, Verona, Veneto, Italy",
  "Steele County, North Dakota, United States",
  "96800, Rovaniemi, Lapland, Finland",
  "Macouria, Cayenne, French Guiana",
  "Louisat, Auriébat, Occitanie, France",
  "Steeles Tavern, Virginia, United States",
  "18106, Rostock, Mecklenburg-West Pomerania, Germany",
  "453356, Svobodnyy, Bashkortostan, Russia",
  "Burleson, Texas, United States",
  "Otero, Castile-La Mancha, Spain",
  "Volosovsky District, Leningrad, Russia",
  "Beşiri, Batman, Türkiye",
  "42100, Konya, Konya, Türkiye",
  "Șelimbăr, Sibiu, Romania",
  "Berlin, Connecticut, United States",
  "5125, Golden Grove, South Australia, Australia",
  "Leeds, Massachusetts, United States",
  "Elbasan, Albania",
  "20720, Turku, Southwest Finland, Finland",
  "70122, New Orleans, Louisiana, United States",
  "68193-000, Novo Progresso, Pará, Brazil",
  "6212 CE, Maastricht, Limburg, Netherlands",
  "Vaggeryds kommun, Jönköping County, Sweden",
  "Okhansk, Perm, Russia",
  "Chernyshëvka, Primorye, Russia",
  "Campbell River, British Columbia, Canada",
  "Fillmore, Saskatchewan, Canada",
  "Harper County, Kansas, United States",
  "Samoa",
  "Sirvan County, Ilam Province, Iran",
  "2595 WP, The Hague, South Holland, Netherlands",
  "359225, Kaspiyskiy, Kalmykia, Russia",
  "663183, Yeniseysk, Krasnoyarsk Krai, Russia",
  "08150, Boyarka, Kyiv, Ukraine",
  "Grande Prairie, Alberta, Canada",
  "Häädemeeste Parish, Pärnu maakond, Estonia",
  "Hitchcock, Oklahoma, United States",
  "Rutland, Georgia, United States",
  "Wakefield, Quebec, Canada",
  "86-308, Grudziądz, Kujawsko-pomorskie, Poland",
  "Williamston, North Carolina, United States",
  "Stockade, Schenectady, New York, United States",
  "20350, Metkovi, Dubrovnik-Neretva, Croatia",
  "94720, Kemi, Lapland, Finland",
  "Vaslui, Vaslui, Romania",
  "26060, Eskişehir, Eskişehir, Türkiye",
  "200040, Craiova, Dolj, Romania",
  "Taney, Alixan, Auvergne-Rhône-Alpes, France",
  "Stokes, North Carolina, United States",
  "Semiluki, Voronezh, Russia",
  "Steele Creek, Alaska, United States",
  "Florence, Colorado, United States",
  "Hasandede, Kırıkkale, Türkiye",
  "Barrón Centro, México, Mexico",
  "Cherkasy Raion, Cherkasy, Ukraine",
  "Brighton, Queensland, Australia",
  "Devanahalli, Karnataka, India",
  "47178, Duisburg, North Rhine-Westphalia, Germany",
  "Rockbridge Estates, Georgia, United States",
  "087170, Ogrezeni, Giurgiu, Romania",
  "North-West, South Africa",
  "Ocean, New Jersey, United States",
  "Martuk, Aqtöbe region, Kazakhstan",
  "Liechtenstein",
  "E9C 1T9, Storeytown, New Brunswick, Canada",
  "4630, Kristiansand, Agder, Norway",
  "8000-172, Faro, Faro, Portugal",
  "33613, Bielefeld, North Rhine-Westphalia, Germany",
  "Michurinskiy, Bashkortostan, Russia",
  "453301, Kumertau, Bashkortostan, Russia",
  "Jim Falls, Wisconsin, United States",
  "Camden Park, South Australia, Australia",
  "Montague, Ontario, Canada",
  "San Bernardino County, California, United States",
  "Giurgiu, Giurgiu, Romania",
  "Clearfield, Iowa, United States",
  "45128, Essen, North Rhine-Westphalia, Germany",
  "820119, Tulcea, Tulcea, Romania",
  "Novgorod, Novgorod, Russia",
  "Castro Daire, Viseu, Portugal",
  "Ruskin, Nebraska, United States",
  "Ardanuç, Artvin, Türkiye",
  "Viļāni, Rezekne, Latvia",
  "Gorishni Plavni, Poltava, Ukraine",
  "46326, Kaunas, Kauno, Lithuania",
  "C22A9M7, Atbasar, Akmola Region, Kazakhstan",
  "624602, Alapayevsk, Sverdlovsk, Russia",
  "Artyomovsky District, Sverdlovsk, Russia",
  "27930, Hertford, North Carolina, United States",
  "91118, San Marino, California, United States",
  "Boston, Massachusetts, United States",
  "Waseca County, Minnesota, United States",
  "Handsworth, Birmingham, England, United Kingdom",
  "TR18 3AJ, Penzance, England, United Kingdom",
  "Coaldale, Pennsylvania, United States",
  "Barbourville, Kentucky, United States",
  "680051, Khabarovsk, Khabarovsk, Russia",
  "R0K 0C3, Killarney, Manitoba, Canada",
  "14300, Hämeenlinna, Kanta-Häme, Finland",
  "Swain Oaks, California, United States",
  "Blairsville, Pennsylvania, United States",
  "Carroll County, Iowa, United States",
  "S0G 4V0, Strasbourg Station, Saskatchewan, Canada",
  "Hamburg, Arkansas, United States",
  "825, Selfoss, Southern Region, Iceland",
  "Cascades, Virginia, United States",
  "Young, Arizona, United States",
  "652488, Anzhero-Sudzhensk, Kemerovo, Russia",
  "Puketapu, Hawke's Bay, New Zealand",
  "75270, Dallas, Texas, United States",
  "143116, Baba Bakala, Punjab, India",
  "IP33 2QZ, Bury St Edmunds, England, United Kingdom",
  "34204, Kragujevac, Centralna Srbija, Serbia",
  "1160, Vienna, Vienna, Austria",
  "Coimbra, Coimbra, Portugal",
  "Pugachëv, Saratov, Russia",
  "Floyd County, Virginia, United States",
  "Bray, County Wicklow, Ireland",
  "Northwest Pontotoc, Oklahoma, United States",
  "11012, Cadiz, Andalusia, Spain",
  "Tuckerang, Queensland, Australia",
  "351 32, Lamía, Central Greece, Greece",
  "Lovell, Maine, United States",
  "74525, Atoka, Oklahoma, United States",
  "E4M 1M5, Coburg, New Brunswick, Canada",
  "Hopewell, New Jersey, United States",
  "Stark County, Illinois, United States",
  "215564, Safonovo, Smolensk, Russia",
  "48147, Münster, North Rhine-Westphalia, Germany",
  "357748, Kislovodsk, Stavropol, Russia",
  "Johnston, South Carolina, United States",
  "Fürthen, Rhineland-Palatinate, Germany",
  "Greeley, Colorado, United States",
  "Topki, Kemerovo, Russia",
  "22402, Fredericksburg, Virginia, United States",
  "Kent, New Brunswick, Canada",
  "Livingston, New Jersey, United States",
  "Grady, Alabama, United States",
  "SP11 0DA, Hurstbourne Tarrant, England, United Kingdom",
  "Sibiu, Sibiu, Romania",
  "13004, Marseille, Provence-Alpes-Côte d'Azur, France",
  "683002, Petropavlovsk-Kamchatskiy, Kamchatka, Russia",
  "Stans, Nidwalden, Switzerland",
  "Simpson, Kansas, United States",
  "BA3 5XD, Faulkland, England, United Kingdom",
  "Küssnacht am Rigi, Schwyz, Switzerland",
  "Bristol, Rhode Island, United States",
  "12903-250, Bragança Paulista, São Paulo, Brazil",
  "Hardy, Kentucky, United States",
  "Mas de Fauquier, Mauguio, Occitanie, France",
  "Youngtown, Arizona, United States",
  "632411, Forpost-Kargat, Novosibirsk, Russia",
  "Ust-Kamchatsk, Kamchatka, Russia",
  "Canadian Heights, Moncton, New Brunswick, Canada",
  "4688, Harding, KwaZulu-Natal, South Africa",
  "Kamianets-Podilska miska hromada, Khmelnytsky, Ukraine",
  "Belaya Kalitva, Rostov, Russia",
  "12000, Bingol, Bingöl, Türkiye",
  "1502, Krujë, Durres, Albania",
  "Habo kommun, Jönköping County, Sweden",
  "30700, Hakkâri, Hakkâri, Türkiye",
  "6010, Charleroi, Walloon Region, Belgium",
  "658109, Aleysky, Altai Krai, Russia",
  "211322, Babinichy, Vitsyebsk, Belarus",
  "Rogers County, Oklahoma, United States",
  "C40B3C2, Esil, Akmola Region, Kazakhstan",
  "59812, Missoula, Montana, United States",
  "423571, Nizhnekamsk, Tatarstan, Russia",
  "Livingston, Montana, United States",
  "Blagoveshchenskoye, Yaroslavl’, Russia",
  "27253, Graham, North Carolina, United States",
  "94598, Walnut Creek, California, United States",
  "428038, Cheboksary, Chuvashia, Russia",
  "Greater Fort Collins Area",
  "Barronstown (Ormond), County Tipperary, Ireland",
  "55009, Cannon Falls, Minnesota, United States",
  "75243, Dallas, Texas, United States",
  "Montague, New Jersey, United States",
  "Birmingham, England, United Kingdom",
  "Librazhd-Qendër, Elbasan, Albania",
  "07746, Marlboro, New Jersey, United States",
  "453307, Kumertau, Bashkortostan, Russia",
  "173016, Velikiy Novgorod, Novgorod, Russia",
  "456315, Miass, Chelyabinsk, Russia",
  "Levski, Pleven, Bulgaria",
  "Rapid City, South Dakota, United States",
  "Stoeneşti, Giurgiu, Romania",
  "69103, North Platte, Nebraska, United States",
  "Yanga-Salskoye, Tatarstan, Russia",
  "61822, Champaign, Illinois, United States",
  "Buckingham, Pennsylvania, United States",
  "171508, Kimry, Tver’, Russia",
  "686112, Palatka, Magadan, Russia",
  "14778, Allegany, New York, United States",
  "Bonneuil-sur-Marne, Île-de-France, France",
  "Rankin, Greensboro, North Carolina, United States",
  "‘Ar‘ar, Northern Frontier, Saudi Arabia",
  "43004, Tarragona, Catalonia, Spain",
  "Montour, Mauvezin-sur-Gupie, Nouvelle-Aquitaine, France",
  "Sequatchie County, Tennessee, United States",
  "Tuzla, Istanbul, Türkiye",
  "Greensboro, North Carolina, United States",
  "06410, Cheshire, Connecticut, United States",
  "Hall County, Nebraska, United States",
  "88104, Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Whitman-Mocine, Hayward, California, United States",
  "663935, Uyar, Krasnoyarsk Krai, Russia",
  "Foster, West Virginia, United States",
  "Kurunzulay, Zabaykalsky Krai, Russia",
  "Embid, Castile-La Mancha, Spain",
  "Mira, Coimbra, Portugal",
  "50825, Cologne, North Rhine-Westphalia, Germany",
  "958 04, Partizánske, Trenciansky, Slovakia",
  "Kursk, Altai Krai, Russia",
  "Carter, Oklahoma, United States",
  "33212, Gijón, Principality of Asturias, Spain",
  "Grafton, Wisconsin, United States",
  "62297, Walsh, Illinois, United States",
  "623104, Pervoural'sk, Sverdlovsk, Russia",
  "An Namur, Makkah, Saudi Arabia",
  "Mykolayiv, Mykolayiv, Ukraine",
  "21787, Taneytown, Maryland, United States",
  "S25C0H8, Ertis, Pavlodar Region, Kazakhstan",
  "Mossyrock, Washington, United States",
  "60323, Frankfurt, Hesse, Germany",
  "665409, Cheremkhovo, Irkutsk, Russia",
  "42389, Wuppertal, North Rhine-Westphalia, Germany",
  "Clermont, Durban, KwaZulu-Natal, South Africa",
  "Mason Vicentino, Veneto, Italy",
  "357566, Pyatigorsk, Stavropol, Russia",
  "453833, Sibay, Bashkortostan, Russia",
  "Valpovo, Osijek-Baranja, Croatia",
  "Wilson, Kansas, United States",
  "Jaén, Cajamarca, Peru",
  "Hesperange, Luxembourg, Luxembourg",
  "Guadalupe Victoria, Durango, Mexico",
  "Sunflower, North Las Vegas, Nevada, United States",
  "Greater Campinas",
  "252 20, Helsingborg, Skåne County, Sweden",
  "225707, Pinsk, Brest, Belarus",
  "188561, Slantsy, Leningrad, Russia",
  "658829, Slavgorod, Altai Krai, Russia",
  "5952, Nikopol, Pleven, Bulgaria",
  "412902, Vol'sk, Saratov, Russia",
  "Otsego, Indiana, United States",
  "Bobrov, Voronezh, Russia",
  "98135, Messina, Sicily, Italy",
  "Hancock, Michigan, United States",
  "Stuttgart, Arkansas, United States",
  "7102, Szekszárd, Tolna, Hungary",
  "34375, Istanbul, Istanbul, Türkiye",
  "16161, Wheatland, Pennsylvania, United States",
  "Vila-real, Valencian Community, Spain",
  "Cole County, Missouri, United States",
  "79517, Fluvanna, Texas, United States",
  "Haskell Groves, Highland City, Florida, United States",
  "658420, Gornyak, Altai Krai, Russia",
  "Smyadovo, Shumen, Bulgaria",
  "Chernihiv, Chernihiv, Ukraine",
  "V92 E038, Tralee, County Kerry, Ireland",
  "55131, Mainz, Rhineland-Palatinate, Germany",
  "Oktyabr'skiy, Minsk, Belarus",
  "24400, Erzincan, Erzincan, Türkiye",
  "67600, Kokkola, Central Ostrobothnia, Finland",
  "Camden, New York, United States",
  "320034, Reşiţa, Caraş-Severin, Romania",
  "BT27 4DQ, Lisburn, Northern Ireland, United Kingdom",
  "452696, Neftekamsk, Bashkortostan, Russia",
  "Assam, India",
  "5656 AE, Eindhoven, North Brabant, Netherlands",
  "347909, Taganrog, Rostov, Russia",
  "Port Sanilac, Michigan, United States",
  "Sakarya, Türkiye",
  "86633, Neuburg an der Donau, Bavaria, Germany",
  "Aleksandrow, Vladimir, Russia",
  "Vermont South, Victoria, Australia",
  "658823, Slavgorod, Altai Krai, Russia",
  "Green Bay, Wisconsin, United States",
  "Woods Cross, Utah, United States",
  "Winona, Kansas, United States",
  "Kaneohe, Hawaii, United States",
  "Trailling, Lam, Bavaria, Germany",
  "Syracuse, Sicily, Italy",
  "25115, Kanawha Falls, West Virginia, United States",
  "Bragado, Buenos Aires Province, Argentina",
  "Yekaterinburg Metropolitan Area",
  "Ulyanovsk, Ul’yanovsk, Russia",
  "238153, Chernyakhovskiy, Kaliningrad, Russia",
  "Clermont-Ferrand, Auvergne-Rhône-Alpes, France",
  "Cannon Ball, North Dakota, United States",
  "K4K 0A1, Rockland, Ontario, Canada",
  "1203, Geneva, Geneva, Switzerland",
  "Kecskemét, Hungary",
  "641466, Kurtamysh, Kurgan, Russia",
  "89131, Reggio di Calabria, Calabria, Italy",
  "Center District, Israel",
  "Bennettsbridge, County Kilkenny, Ireland",
  "225704, Pinsk, Brest, Belarus",
  "S25C0C8, Ertis, Pavlodar Region, Kazakhstan",
  "403532, Frolovo, Volgograd, Russia",
  "Bratsky District, Irkutsk, Russia",
  "Yatesboro, Pennsylvania, United States",
  "666788, Ust-Kut, Irkutsk, Russia",
  "X38H1M6, Kentau, Turkistan Region, Kazakhstan",
  "Warren, Ohio, United States",
  "Turgovishte, Bulgaria",
  "Hofors, Gävleborg County, Sweden",
  "385017, Maykop, Adygea, Russia",
  "4336 AC, Middelburg, Zeeland, Netherlands",
  "Crawford, Mississippi, United States",
  "Box, England, United Kingdom",
  "29003, Bamberg, South Carolina, United States",
  "59153, Grand-Fort-Philippe, Hauts-de-France, France",
  "Travis Park, Bryan–College Station, Texas, United States",
  "371 42, Karlskrona, Blekinge County, Sweden",
  "93926, Gonzales, California, United States",
  "Golf Crest, Galveston, Texas, United States",
  "3072 AB, Rotterdam, South Holland, Netherlands",
  "Nikolayevskoye, Krasnodar, Russia",
  "Jonesboro-Paragould Area",
  "Salavatskiy Rayon, Bashkortostan, Russia",
  "37600, Kastamonu, Kastamonu, Türkiye",
  "427621, Glazov, Udmurtia, Russia",
  "871 52, Härnösand, Västernorrland County, Sweden",
  "80-299, Gdańsk, Pomorskie, Poland",
  "Ozark County, Missouri, United States",
  "Lee County, Florida, United States",
  "Wilbarger County, Texas, United States",
  "167011, Syktyvkar, Komi, Russia",
  "Foster, Quebec, Canada",
  "Wright, Minnesota, United States",
  "346886, Bataysk, Rostov, Russia",
  "Guilford Township, Pennsylvania, United States",
  "Eisenstadt Area",
  "EH4 8DL, Edinburgh, Scotland, United Kingdom",
  "Hardinghen, Hauts-de-France, France",
  "A92 NRW7, Drogheda, Ireland",
  "La Rioja, Autonomía de La Rioja, Spain",
  "CV31 1TH, Radford Semele, England, United Kingdom",
  "Biganos, Nouvelle-Aquitaine, France",
  "75911, Ţurayf, Northern Frontier, Saudi Arabia",
  "29154, Sumter, South Carolina, United States",
  "659328, Biysk, Altai Krai, Russia",
  "302028, Orel, Orel, Russia",
  "Ərkivan, Lankaran-Astara, Azerbaijan",
  "71000, Sarajevo, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Montgomery County, Texas, United States",
  "Niagara-on-the-Lake, Ontario, Canada",
  "LV-3018, Ozolnieki, Jelgava Municipality, Latvia",
  "Grafton, New South Wales, Australia",
  "611 31, Nyköping, Södermanland County, Sweden",
  "Kaválla, Eastern Macedonia and Thrace, Greece",
  "Cedar Hill, Texas, United States",
  "6302, Memaliaj, Gjirokastër, Albania",
  "Harper Woods, Michigan, United States",
  "690066, Vladivostok, Primorye, Russia",
  "35330, İzmir, İzmir, Türkiye",
  "Ipotești, Botoşani, Romania",
  "Yuzhno-Yeniseyskiy, Krasnoyarsk Krai, Russia",
  "Venango County, Pennsylvania, United States",
  "R13M7G0, Beyneu, Mangystau Region, Kazakhstan",
  "61-245, Poznan, Wielkopolskie, Poland",
  "Seneca, Missouri, United States",
  "2790014, Urayasu, Chiba, Japan",
  "Alexandrovsk-Sakhalinsky District, Sakhalin, Russia",
  "İzmir, İzmir, Türkiye",
  "44138, Olmsted Falls, Ohio, United States",
  "506 35, Borås, Västra Götaland County, Sweden",
  "Ross-On-Wye, England, United Kingdom",
  "Davison Manor, Florida, United States",
  "9700-368, Angra do Heroísmo, Portugal",
  "678202, Vilyuysk, Sakha, Russia",
  "Verona, Virginia, United States",
  "30621, Kohtla-Järve, Ida-Virumaa, Estonia",
  "Huşi, Vaslui, Romania",
  "Muscat, Masqaţ, Oman",
  "Powderhorn, Minneapolis, Minnesota, United States",
  "430034, Saransk, Mordovia, Russia",
  "Benton, Louisiana, United States",
  "Gilliamstown, County Meath, Ireland",
  "Marathon, Ontario, Canada",
  "Sheboygan Falls, Wisconsin, United States",
  "Lyons, Illinois, United States",
  "671575, Taksimo, Buryatia, Russia",
  "Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "Hayes, Louisiana, United States",
  "Scott County, Kentucky, United States",
  "03052, Cottbus, Brandenburg, Germany",
  "Arraiolos, Évora, Portugal",
  "Balashikha, Moscow, Russia",
  "Colonial, Concordia, Entre Ríos Province, Argentina",
  "8543, Narvik, Nordland, Norway",
  "27601, Raleigh, North Carolina, United States",
  "152611, Uglich, Yaroslavl’, Russia",
  "Nîmes, Occitanie, France",
  "Newington, Craven Arms, England, United Kingdom",
  "Buckingham, Western Australia, Australia",
  "C25B5E7, Makinsk, Akmola Region, Kazakhstan",
  "18012, Granada, Andalusia, Spain",
  "Shuifu, Yunnan, China",
  "37918, Knoxville, Tennessee, United States",
  "Zaporizhzhya, Zaporizhzhya, Ukraine",
  "Reynolds, Indiana, United States",
  "Pepines, Hautefage-la-Tour, Nouvelle-Aquitaine, France",
  "663253, Turukhansk, Krasnoyarsk Krai, Russia",
  "13200, Hämeenlinna, Kanta-Häme, Finland",
  "12300, Butterworth, Penang, Malaysia",
  "Pordim Municipality, Pleven, Bulgaria",
  "10122, Turin, Piedmont, Italy",
  "Wilkesport, Ontario, Canada",
  "Goliad County, Texas, United States",
  "663332, Noril'sk, Taymyr, Russia",
  "Cameron, Missouri, United States",
  "62018, Potenza Picena, Marches, Italy",
  "2640, Kocherinovo, Kyustendil, Bulgaria",
  "C25B6C2, Makinsk, Akmola Region, Kazakhstan",
  "Svete, Jelgava Municipality, Latvia",
  "220141, Minsk, Minsk, Belarus",
  "Mowery, Mervin 499, Saskatchewan, Canada",
  "Rice Lake, Wisconsin, United States",
  "70569, Stuttgart, Baden-Württemberg, Germany",
  "051845, Bucharest, Bucharest, Romania",
  "Maple Park West, Chouteau, Missouri, United States",
  "373 42, Karlskrona, Blekinge County, Sweden",
  "Brindisi Montagna, Basilicata, Italy",
  "Whitman Junction, West Virginia, United States",
  "9500-010, Ponta Delgada, Portugal",
  "724 60, Västerås, Västmanland County, Sweden",
  "65201, Wiesbaden, Hesse, Germany",
  "676290, Tynda, Amur, Russia",
  "Fayette County, Ohio, United States",
  "Tatabányai Járás, Komárom-Esztergom, Hungary",
  "50100, Cherbourg-en-Cotentin, Normandy, France",
  "Griggs County, North Dakota, United States",
  "01067, Dresden, Saxony, Germany",
  "7820, Namsos, Trøndelag, Norway",
  "455019, Magnitogorsk, Chelyabinsk, Russia",
  "Vau-Dejës, Shkodër, Albania",
  "E25C7A0, Maqat, Atyrau Region, Kazakhstan",
  "Personville, Texas, United States",
  "Nizhniy Novgorod, Russia",
  "5213 AA, ’s-Hertogenbosch, North Brabant, Netherlands",
  "Wickham Bishops, England, United Kingdom",
  "6912, Lugano, Ticino, Switzerland",
  "Coos, Peyrehorade, Nouvelle-Aquitaine, France",
  "Wyoming, Rhode Island, United States",
  "95-100, Zgierz, Łódzkie, Poland",
  "Yoshkar-Ola, Mari El, Russia",
  "Ohio, United States",
  "60500, Tokat, Tokat, Türkiye",
  "694910, Uglegorsk, Sakhalin, Russia",
  "Bardal, Pipestone, Manitoba, Canada",
  "Shelby, Iowa, United States",
  "601 00, Katerini, Central Macedonia, Greece",
  "6015, Ålesund, Møre og Romsdal, Norway",
  "Windsor, New York, United States",
  "East Terrell Hills, San Antonio, Texas, United States",
  "416506, Akhtubinsk, Astrakhan’, Russia",
  "3303, Eger, Heves, Hungary",
  "2840-075, Seixal, Setúbal, Portugal",
  "Vikhorevka, Irkutsk, Russia",
  "28659, North Wilkesboro, North Carolina, United States",
  "662970, Zheleznogorsk, Krasnoyarsk Krai, Russia",
  "Pike County, Georgia, United States",
  "Fergusons Cove, Nova Scotia, Canada",
  "163000, Archangel, Arkhangel’sk, Russia",
  "302030, Orel, Orel, Russia",
  "802 62, Gävle, Gävleborg County, Sweden",
  "Scotland County, North Carolina, United States",
  "Treasure Lake, Pennsylvania, United States",
  "Campbelltown, New South Wales, Australia",
  "Surgutsky District, Khanty-Mansi, Russia",
  "665104, Nizhneudinsk, Irkutsk, Russia",
  "Huron, Tennessee, United States",
  "BD20 7EW, Sutton-In-Craven, England, United Kingdom",
  "34266, Sandershausen, Hesse, Germany",
  "662549, Lesosibirsk, Krasnoyarsk Krai, Russia",
  "Perm, Russia",
  "22203, Rogoznica, Sibenik-Knin, Croatia",
  "JJ Pearce, Richardson, Texas, United States",
  "SR4 0AA, Sunderland, England, United Kingdom",
  "Trinity, North Carolina, United States",
  "Oceanside, New York, United States",
  "Altdorf, Baden-Württemberg, Germany",
  "Novozybkovsky District, Bryansk, Russia",
  "De Soto County, Mississippi, United States",
  "Attalasiddavaram, Dakkili, Andhra Pradesh, India",
  "X0E 0N0, Fort Simpson, Northwest Territories, Canada",
  "J0B 3A0, Sawyerville, Quebec, Canada",
  "Doroțcaia, Dubăsari District, Moldova",
  "Isle of Wight County, Virginia, United States",
  "Heidelberg, Gauteng, South Africa",
  "Madison Heights, Michigan, United States",
  "Lucerne Metropolitan Area",
  "E7K 2W5, Gregg Settlement, New Brunswick, Canada",
  "24501, Lynchburg, Virginia, United States",
  "Villafranca di Verona, Veneto, Italy",
  "29200, Brest, Brittany, France",
  "Karakopru, Şanliurfa, Türkiye",
  "NR2 4RW, Norwich, England, United Kingdom",
  "3000, Vratsa, Vratsa, Bulgaria",
  "680504, Korfovskiy, Khabarovsk, Russia",
  "75020, Paris, Île-de-France, France",
  "Murrayville, Georgia, United States",
  "Grainger-Whittemore, Conway, South Carolina, United States",
  "7205, Bitola, Pelagonia, North Macedonia",
  "6811 AA, Arnhem, Gelderland, Netherlands",
  "89015, Henderson, Nevada, United States",
  "Griggsville Historic District, United States",
  "Győr District, Győr-Moson-Sopron, Hungary",
  "4843, Arendal, Agder, Norway",
  "Zheleznogorsk-Ilimskiy, Irkutsk, Russia",
  "46800, Kahramanmaraş, Kahraman Maras, Türkiye",
  "Pinhais, Paraná, Brazil",
  "Bresje, District of Pristina, Kosovo",
  "Cascade, Colorado, United States",
  "El Socorro, Guarico State, Venezuela",
  "Langlade, Saint-Pardoux-la-Rivière, Nouvelle-Aquitaine, France",
  "Montgomery County, North Carolina, United States",
  "3173, Tønsberg, Vestfold og Telemark, Norway",
  "46311, Dyer, Indiana, United States",
  "1000, Lausanne, Vaud, Switzerland",
  "648367, Severo-Yeniseyskiy, Krasnoyarsk Krai, Russia",
  "48001, Kaunas, Kauno, Lithuania",
  "Oliveira de Azemeis, Aveiro, Portugal",
  "Lucas County, Iowa, United States",
  "Edgefield Manor, Nashville, Tennessee, United States",
  "Refugio, Calatrava, Western Visayas, Philippines",
  "Santa Barbara-Santa Maria Area",
  "96275-000, Mostardas, Rio Grande do Sul, Brazil",
  "21541, McHenry, Maryland, United States",
  "416501, Akhtubinsk, Astrakhan’, Russia",
  "33702, St Petersburg, Florida, United States",
  "Maranhão, Brazil",
  "18071-359, Sorocaba, São Paulo, Brazil",
  "6710, Szeged, Csongrád, Hungary",
  "Wetzelsdorf, Brennberg, Bavaria, Germany",
  "Pakoštane, Zadar, Croatia",
  "Paynesville, Victoria, Australia",
  "Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Lake Charles-Jennings Area",
  "63339, Curryville, Missouri, United States",
  "Cedar Rapids, Iowa, United States",
  "M28C1D8, Aqtau, Karaganda Region, Kazakhstan",
  "Blair, Michigan, United States",
  "61075, Kharkiv, Kharkiv, Ukraine",
  "6009, Ålesund, Møre og Romsdal, Norway",
  "Manchester, Pennsylvania, United States",
  "Gökçebağ, Siirt, Türkiye",
  "Robertson, Queensland, Australia",
  "26135, Oldenburg, Lower Saxony, Germany",
  "25004, Kropyvnytskyi, Kirovohrad, Ukraine",
  "51800, Çarşı, Niğde, Türkiye",
  "Forrest, Victoria, Australia",
  "Sullivans Island, South Carolina, United States",
  "653 42, Karlstad, Värmland County, Sweden",
  "94034, Passau, Bavaria, Germany",
  "Bratislava-Nové Mesto, Bratislava, Slovakia",
  "Saint-Étienne-de-Villeréal, Nouvelle-Aquitaine, France",
  "SA3 4LA, Caswell, Wales, United Kingdom",
  "Florence, Tuscany, Italy",
  "Cabellal Número Tres, Veracruz, Mexico",
  "37300, Kastamonu, Kastamonu, Türkiye",
  "Chitagá, Norte de Santander, Colombia",
  "Elkhart, Indiana, United States",
  "Oconto, Wisconsin, United States",
  "19143, Philadelphia, Pennsylvania, United States",
  "Beckham County, Oklahoma, United States",
  "Petersburg, West Virginia, United States",
  "PE29 6XQ, Huntingdon, England, United Kingdom",
  "Gilliam County, Oregon, United States",
  "Ust’-Kamchatsk, Kamchatka, Russia",
  "357501, Pyatigorsk, Stavropol, Russia",
  "247772, Mazyr, Homel, Belarus",
  "80128, Naples, Campania, Italy",
  "Osage, Oklahoma, United States",
  "Námestovo, Zilina, Slovakia",
  "Abelardo Carrillo Zavala, Campeche, Mexico",
  "Jiménez Municipality, Chihuahua, Mexico",
  "Sanders, Kentucky, United States",
  "Reevesland Industrial Estate, Newport, Wales, United Kingdom",
  "456208, Zlatoust, Chelyabinsk, Russia",
  "Satu Mare, Suceava, Romania",
  "Rusko, Southwest Finland, Finland",
  "Pärnu maakond, Estonia",
  "21124, Novi Sad, Vojvodina, Serbia",
  "810210, Brăila, Brăila, Romania",
  "B78 2HR, Kingsbury, England, United Kingdom",
  "675027, Blagoveshchensk, Amur, Russia",
  "Pamplona, Durango, Mexico",
  "F39A7H8, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Toolestown, County Wicklow, Ireland",
  "61030, Forreston, Illinois, United States",
  "96014, Callahan, California, United States",
  "SS2 6RR, Southend-On-Sea, England, United Kingdom",
  "Egerton, Nakuru, Kenya",
  "Vance, Alabama, United States",
  "84123, Salerno, Campania, Italy",
  "422549, Zelënodol'sk, Mari El, Russia",
  "San Gregorio di Catania, Sicily, Italy",
  "Mount Vernon, New York, United States",
  "450092, Ufa, Bashkortostan, Russia",
  "Beğendik, Siirt, Türkiye",
  "Le Havre, Normandy, France",
  "Santa Ana, California, United States",
  "Fairwood, Hitchcock, Texas, United States",
  "Webster, Iowa, United States",
  "01225, Cheshire, Massachusetts, United States",
  "TS4 2AF, Middlesbrough, England, United Kingdom",
  "56751, Roseau, Minnesota, United States",
  "Cortland, Illinois, United States",
  "MD-3905, Cahul, Cahul District, Moldova",
  "Bend, Oregon, United States",
  "9701, Szombathely, Vas, Hungary",
  "Nassau East North, New Providence, The Bahamas",
  "427624, Glazov, Udmurtia, Russia",
  "09123, Cagliari, Sardinia, Italy",
  "2518 DJ, The Hague, South Holland, Netherlands",
  "Stansbury Park, Utah, United States",
  "98, Vadsø, Troms og Finnmark, Norway",
  "Randolph, Nebraska, United States",
  "Lebanon County, Pennsylvania, United States",
  "Horta de Sant Joan, Catalonia, Spain",
  "65-218, Zielona Góra, Lubuskie, Poland",
  "353480, Gelendzhik, Krasnodar, Russia",
  "Rockland, New York, United States",
  "86159, Augsburg, Bavaria, Germany",
  "62190, Lillers, Hauts-de-France, France",
  "424038, Yoshkar-Ola, Mari El, Russia",
  "Brighton, Victoria, Australia",
  "31000, Osijek, Osijek-Baranja, Croatia",
  "142211, Serpukhov, Moscow, Russia",
  "Shenandoah, Texas, United States",
  "47007, Valladolid, Castilla and Leon, Spain",
  "27400, Dmytrivka, Kirovohrad, Ukraine",
  "Box Elder, Montana, United States",
  "684412, Ust’-Kamchatsk, Kamchatka, Russia",
  "7105, Bitola, Pelagonia, North Macedonia",
  "236003, Kaliningrad, Kaliningrad, Russia",
  "Katerini, Central Macedonia, Greece",
  "Quadraro-Quartiere Tuscolano, Rome, Latium, Italy",
  "Daniels Crossing, Winter Garden, Florida, United States",
  "Trinity, Edinburgh, Scotland, United Kingdom",
  "301844, Yefremov, Tula, Russia",
  "25-004, Kielce, Świętokrzyskie, Poland",
  "Kuvandykskiy Rayon, Orenburg, Russia",
  "19075, Oreland, Pennsylvania, United States",
  "690089, Vladivostok, Primorye, Russia",
  "352700, Timashevsk, Krasnodar, Russia",
  "63304, St Charles, Missouri, United States",
  "456203, Zlatoust, Chelyabinsk, Russia",
  "Merricks, Saint Philip, Barbados",
  "Dundee, KwaZulu-Natal, South Africa",
  "Swansea, Massachusetts, United States",
  "Waterford, Pennsylvania, United States",
  "Wheatland, Indiana, United States",
  "Barreiro, Setúbal, Portugal",
  "Schwanden, Glarus, Switzerland",
  "Otero County, Colorado, United States",
  "Monmouthshire, Wales, United Kingdom",
  "Colorado, United States",
  "34947, Fort Pierce, Florida, United States",
  "369010, Cherkessk, Karachay-Cherkessia, Russia",
  "Egilsstaðir, Eastern Region, Iceland",
  "420082, Bistriţa, Bistriţa-Năsăud, Romania",
  "96190, Rovaniemi, Lapland, Finland",
  "Qianxinan Buyei and Miao Autonomous Prefecture, Guizhou, China",
  "368607, Derbent, Dagestan, Russia",
  "Farviews & Pattee Canyon, Missoula, Montana, United States",
  "F39C0Y1, Zaysan, East Kazakhstan Region, Kazakhstan",
  "10451, Bronx, New York, United States",
  "LaMoure County, North Dakota, United States",
  "Ramsey St Mary's, England, United Kingdom",
  "238158, Chernyakhovskiy, Kaliningrad, Russia",
  "C00M6F0, Kokshetau, Akmola Region, Kazakhstan",
  "Kürtün, Gümüşhane, Türkiye",
  "08555, Roosevelt, New Jersey, United States",
  "18766, Wilkes-Barre, Pennsylvania, United States",
  "424037, Yoshkar-Ola, Mari El, Russia",
  "14034, Chernihiv, Chernihiv, Ukraine",
  "450076, Ufa, Bashkortostan, Russia",
  "Sankt Gallen Metropolitan Area",
  "DeKalb Township, Illinois, United States",
  "Lubelski, Lubelskie, Poland",
  "Hill Haven, Chouteau, Missouri, United States",
  "Gemeente Soest, Utrecht, Netherlands",
  "Pettistree, England, United Kingdom",
  "33004, Oviedo, Principality of Asturias, Spain",
  "S2 1AA, Sheffield, England, United Kingdom",
  "45140, Loveland, Ohio, United States",
  "Jasper County, Indiana, United States",
  "Vatra Dornei, Suceava, Romania",
  "Mayesville, South Carolina, United States",
  "40075, Turners Station, Kentucky, United States",
  "37864, Sevierville, Tennessee, United States",
  "Stearns Park, Long Beach, California, United States",
  "73530, Davidson, Oklahoma, United States",
  "Black Hawk County, Iowa, United States",
  "Karlskrona, Blekinge County, Sweden",
  "Portage Township, Indiana, United States",
  "Tompkinsville, Kentucky, United States",
  "Scottsdale, Arizona, United States",
  "Pinsk District, Brest, Belarus",
  "55400, Samsun, Samsun, Türkiye",
  "185032, Petrozavodsk, Karelia, Russia",
  "75754, Ben Wheeler, Texas, United States",
  "Rasskazovo, Tambov, Russia",
  "Divide, Colorado, United States",
  "20815, Chevy Chase, Maryland, United States",
  "Greater Kiel Area",
  "Moore, Texas, United States",
  "80796, Munich, Bavaria, Germany",
  "652 21, Karlstad, Värmland County, Sweden",
  "Pembina Valley Region, Manitoba, Canada",
  "Moravia-Silesia, Czechia",
  "86704, Tori, Parnu, Estonia",
  "Bowling Green Metropolitan Area",
  "LS8 1AA, Leeds, England, United Kingdom",
  "Webster County, Kentucky, United States",
  "18800, Cankiri, Çankırı, Türkiye",
  "Andora, Liguria, Italy",
  "Bairro das Furnas, Riversul, São Paulo, Brazil",
  "163045, Archangel, Arkhangel’sk, Russia",
  "Emden, Lower Saxony, Germany",
  "352 44, Växjö, Kronoberg County, Sweden",
  "Mahilyow, Belarus",
  "V94 VH2D, Limerick, County Limerick, Ireland",
  "2910-456, Setúbal, Setúbal, Portugal",
  "254 37, Helsingborg, Skåne County, Sweden",
  "184041, Kandalaksha, Murmansk, Russia",
  "Rockbridge, Ohio, United States",
  "9018, Tromsø, Troms og Finnmark, Norway",
  "Shores North, Rockwall, Texas, United States",
  "Boyden, Iowa, United States",
  "41300, Krolevets, Sumy, Ukraine",
  "354053, Sochi, Krasnodar, Russia",
  "GL1 1AD, Gloucester, England, United Kingdom",
  "Yeysk, Krasnodar, Russia",
  "Paraguay",
  "Perry County, Illinois, United States",
  "75237, Dallas, Texas, United States",
  "65100, Vaasa, Ostrobothnia, Finland",
  "692906, Nakhodka, Primorye, Russia",
  "Tate de La Capilla, Ica, Peru",
  "60409, Calumet City, Illinois, United States",
  "Vitebsk District, Vitsyebsk, Belarus",
  "611 88, Nyköping, Södermanland County, Sweden",
  "Upper Freestone, Queensland, Australia",
  "Oxford, Ontario, Canada",
  "04006, Almería, Andalusia, Spain",
  "Blue Haven, New South Wales, Australia",
  "Pamplona, Cagayan Valley, Philippines",
  "Caswell, Wales, United Kingdom",
  "87100, Limoges, Nouvelle-Aquitaine, France",
  "Fort Lauderdale, Florida, United States",
  "6040, Charleroi, Walloon Region, Belgium",
  "Rice, Minnesota, United States",
  "Millstadt, Illinois, United States",
  "Kaluga, Kaluga, Russia",
  "Foynes, County Limerick, Ireland",
  "Slavkovichi, Pskov, Russia",
  "51107, Rijeka, Primorje-Gorski, Croatia",
  "Coosaun, County Roscommon, Ireland",
  "Goose Creek, South Carolina, United States",
  "632122, Tatarsk, Novosibirsk, Russia",
  "Junglinster, Grevenmacher, Luxembourg",
  "174403, Borovichi, Novgorod, Russia",
  "621 37, Visby, Gotland County, Sweden",
  "Ozark, Illinois, United States",
  "Hickory Point, Illinois, United States",
  "89110, Las Vegas, Nevada, United States",
  "00135, Rome, Latium, Italy",
  "Stansstad, Nidwalden, Switzerland",
  "Larnaca, Larnaca, Cyprus",
  "IP13 0HH, Pettistree, England, United Kingdom",
  "94551, Livermore, California, United States",
  "Nefteyuganskiy rayon, Khanty-Mansi, Russia",
  "81-185, Gdynia, Pomorskie, Poland",
  "185011, Petrozavodsk, Karelia, Russia",
  "Sheridan, Illinois, United States",
  "43007, Tarragona, Catalonia, Spain",
  "Magdeburg, Saxony-Anhalt, Germany",
  "Gmina Bakałarzewo, Podlaskie, Poland",
  "630008, Novosibirsk, Novosibirsk, Russia",
  "Sutton, Alaska, United States",
  "San Sebastián, Basque Country, Spain",
  "33204, Gijón, Principality of Asturias, Spain",
  "02-822, Warsaw, Mazowieckie, Poland",
  "Pierce County, Washington, United States",
  "Ilinden, Skopje Statistical Region, North Macedonia",
  "8260, Stein am Rhein, Schaffhausen, Switzerland",
  "SR2 0AA, Sunderland, England, United Kingdom",
  "Box Elder County, Utah, United States",
  "347801, Kamensk-Shakhtinskiy, Rostov, Russia",
  "Vitsyebsk, Belarus",
  "Cíbola del Mar, Baja California, Mexico",
  "NR25 6PJ, West Beckham, England, United Kingdom",
  "24101, Hardy, Virginia, United States",
  "7821, Namsos, Trøndelag, Norway",
  "R6W 1G3, Winkler, Manitoba, Canada",
  "Franklin, Indiana, United States",
  "22553, Spotsylvania, Virginia, United States",
  "662162, Achinsk, Krasnoyarsk Krai, Russia",
  "Fayette County, Iowa, United States",
  "Zeya, Amur, Russia",
  "40520, Jyväskylä, Central Finland, Finland",
  "835219, Patratu, Jharkhand, India",
  "3108, Salgótarján, Nógrád, Hungary",
  "Starks, Louisiana, United States",
  "Omaha, Nebraska, United States",
  "79031, Lviv, Lviv, Ukraine",
  "Jefferson County, Indiana, United States",
  "X38F9X7, Kentau, Turkistan Region, Kazakhstan",
  "Hampshire, Illinois, United States",
  "Valencia, Carabobo State, Venezuela",
  "Kostroma, Russia",
  "Serov, Sverdlovsk, Russia",
  "24600, Erzincan, Erzincan, Türkiye",
  "Greater Volos Area",
  "Centreville, Virginia, United States",
  "Beeton, Ontario, Canada",
  "Kahta, Adıyaman, Türkiye",
  "EH3 5AA, Edinburgh, Scotland, United Kingdom",
  "S13K2H3, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Schuyler, Nebraska, United States",
  "30701, Calhoun, Georgia, United States",
  "96049, Bamberg, Bavaria, Germany",
  "74871, Stonewall, Oklahoma, United States",
  "4656, Starrkirch-Wil, Solothurn, Switzerland",
  "38107, Santa Cruz de Tenerife, Canary Islands, Spain",
  "371 32, Karlskrona, Blekinge County, Sweden",
  "Albergaria-a-Velha, Aveiro, Portugal",
  "Lincoln, Ontario, Canada",
  "Burlington, Wisconsin, United States",
  "3000, Bern, Berne, Switzerland",
  "Tolima, Colombia",
  "Armagh City Banbridge And Craigavon, Northern Ireland, United Kingdom",
  "4030, Linz, Upper Austria, Austria",
  "Orange City, Florida, United States",
  "Sakiet Ezzit, Sfax, Tunisia",
  "Ower, England, United Kingdom",
  "00-001, Warsaw, Mazowieckie, Poland",
  "Frecăței, Brăila, Romania",
  "46200, Kahramanmaraş, Kahraman Maras, Türkiye",
  "Şabanözü, Çankırı, Türkiye",
  "35453, Cottondale, Alabama, United States",
  "Yegor'yevskoye, Tatarstan, Russia",
  "Salamanca, New York, United States",
  "Egersund, Rogaland, Norway",
  "641444, Kurtamysh, Kurgan, Russia",
  "54220, Manitowoc, Wisconsin, United States",
  "B6L 6S8, Onslow Mountain, Nova Scotia, Canada",
  "346905, Novoshakhtinsk, Rostov, Russia",
  "Pecq, Walloon Region, Belgium",
  "Swifterbant, Flevoland, Netherlands",
  "30815, Lorca, Región de Murcia, Spain",
  "Beijing, China",
  "Dzerzhinsky District, Krasnoyarsk Krai, Russia",
  "Klyuchi, Krasnoyarsk Krai, Russia",
  "Johnson City, Texas, United States",
  "Granite County, Montana, United States",
  "6200-004, Covilhã, Castelo Branco, Portugal",
  "ST18 0NJ, Little Haywood, England, United Kingdom",
  "Naberezhnyye Chelny, Tatarstan, Russia",
  "KA18 2PE, Ochiltree, Scotland, United Kingdom",
  "LN8 2AT, Caenby Corner, England, United Kingdom",
  "Lake County, California, United States",
  "01099, Dresden, Saxony, Germany",
  "30187, Winston, Georgia, United States",
  "33200, Mersin, İçel, Türkiye",
  "17058, Mifflin, Pennsylvania, United States",
  "30035, Decatur, Georgia, United States",
  "Middlesex, New Jersey, United States",
  "Tucker, Portage la Prairie, Manitoba, Canada",
  "Oranmore, County Galway, Ireland",
  "Waldbillig, Grevenmacher, Luxembourg",
  "Lower Onslow, Nova Scotia, Canada",
  "06083, Bastia, Umbria, Italy",
  "Lac La Biche, Alberta, Canada",
  "632865, Karasuk, Novosibirsk, Russia",
  "630030, Novosibirsk, Novosibirsk, Russia",
  "Denver, Colorado, United States",
  "30135, Venice, Veneto, Italy",
  "38121, Trento, Trentino-Alto Adige, Italy",
  "01741, Carlisle, Massachusetts, United States",
  "Seixal, Setúbal, Portugal",
  "Borzya, Zabaykalsky Krai, Russia",
  "Blairgowrie, Scotland, United Kingdom",
  "Oldham County, Texas, United States",
  "Jönköping County, Sweden",
  "Laneville, Texas, United States",
  "676061, Skovorodino, Amur, Russia",
  "Harding County, New Mexico, United States",
  "McDowell, Detroit, Michigan, United States",
  "CO16 0HU, Thorpe-Le-Soken, England, United Kingdom",
  "Chernyshevskiy, Sakha, Russia",
  "Mérida, Yucatán, Mexico",
  "Barysaw District, Minsk Province, Belarus",
  "Pryluky, Chernihiv, Ukraine",
  "Varna, Ontario, Canada",
  "Shirataka, Yamagata, Japan",
  "Addison, Alabama, United States",
  "Greggenbach, Bodnegg, Baden-Württemberg, Germany",
  "Goshen, New Hampshire, United States",
  "47928, Cayuga, Indiana, United States",
  "Troitske, Luhansk, Ukraine",
  "Grand-Fort-Philippe, Hauts-de-France, France",
  "22101, McLean, Virginia, United States",
  "Allendale, New Jersey, United States",
  "7305, Pogradec, Korçë, Albania",
  "Halifax County, Virginia, United States",
  "4868, Mount Sheridan, Queensland, Australia",
  "Nevada, Texas, United States",
  "184651, Polyarnyy, Murmansk, Russia",
  "Montgomery County, Maryland, United States",
  "Rutland, Vermont, United States",
  "BT78 1PG, Omagh, Northern Ireland, United Kingdom",
  "385011, Maykop, Adygea, Russia",
  "Poznań, Wielkopolskie, Poland",
  "Obuasi, Ashanti Region, Ghana",
  "Kumla, Örebro County, Sweden",
  "630090, Novosibirsk, Novosibirsk, Russia",
  "152311, Borisogleb, Yaroslavl’, Russia",
  "04355, Aïn Kercha, Oum El Bouaghi, Algeria",
  "Shiranuka, Hokkaido, Japan",
  "Novorossiyskiy, Khakassia, Russia",
  "Molovata, Dubăsari District, Moldova",
  "Teykovo, Ivanovo, Russia",
  "Kasimovskiy Rayon, Ryazan’, Russia",
  "665732, Bratsk, Irkutsk, Russia",
  "M28E5X2, Temirtau, Karaganda Region, Kazakhstan",
  "Bermuda",
  "Chester, South Carolina, United States",
  "Tsiolkovsky, Amur, Russia",
  "7041, Trondheim, Trøndelag, Norway",
  "Hartford, Huntingdon, England, United Kingdom",
  "97000, Mérida, Yucatán, Mexico",
  "Glazov, Udmurtia, Russia",
  "Graffard, Barneville-Carteret, Normandy, France",
  "Cottonwood, Fort Collins, Colorado, United States",
  "Budapest Metropolitan Area",
  "Broomefield, Saint Lucy, Barbados",
  "Chernihiv Metropolitan Area",
  "60389, Frankfurt, Hesse, Germany",
  "Berat, Berat, Albania",
  "85051, Ingolstadt, Bavaria, Germany",
  "28907-210, Cabo Frio, Rio de Janeiro, Brazil",
  "Loraine, Illinois, United States",
  "15137, North Versailles, Pennsylvania, United States",
  "Rensselaerville Historic District, New York, United States",
  "65129, Pescara, Abruzzi, Italy",
  "Lee County, North Carolina, United States",
  "Taoso, Pakke Kessang, Arunachal Pradesh, India",
  "Love County, Oklahoma, United States",
  "Oklahoma City, Oklahoma, United States",
  "Pocahontas, Iowa, United States",
  "Trenton, Texas, United States",
  "03213, Afyonkarahisar, Afyon, Türkiye",
  "9706, Szombathely, Vas, Hungary",
  "175205, Staraya Russa, Novgorod, Russia",
  "22009, Burke, Virginia, United States",
  "Peyia, Paphos, Cyprus",
  "C00T0T7, Kokshetau, Akmola Region, Kazakhstan",
  "Skopje Statistical Region, North Macedonia",
  "Măieru, Bistriţa-Năsăud, Romania",
  "SS1 1AA, Southend-On-Sea, England, United Kingdom",
  "Shannon, New Brunswick, Canada",
  "Les Parmerais, Le Grand-Bornand, Auvergne-Rhône-Alpes, France",
  "Harmonia, Rio Grande do Sul, Brazil",
  "48500, Muğla, Muğla, Türkiye",
  "Ryazanovskoye, Moscow City, Russia",
  "Dundee, Michigan, United States",
  "453129, Sterlitamak, Bashkortostan, Russia",
  "7044, Trondheim, Trøndelag, Norway",
  "Bradford County, Pennsylvania, United States",
  "Saint Andrew, Grenada",
  "Gjorče Petrov, Skopje Statistical Region, North Macedonia",
  "352803, Tuapse, Krasnodar, Russia",
  "30300, Hakkâri, Hakkâri, Türkiye",
  "27080, Gaziantep, Gaziantep, Türkiye",
  "Cabelles, Lugan, Occitanie, France",
  "Tampere, Pirkanmaa, Finland",
  "Eureka, Nova Scotia, Canada",
  "Columbia, Maryland, United States",
  "Russell Vale, New South Wales, Australia",
  "Priceville, Ontario, Canada",
  "Sweet Home, Oregon, United States",
  "San Pier Niceto, Sicily, Italy",
  "Uppsala kommun, Uppsala County, Sweden",
  "Krushari Municipality, Dobrich, Bulgaria",
  "Boulder Ridge, Champaign, Illinois, United States",
  "7020, Pustec, Korçë, Albania",
  "Iowa, United States",
  "6700, Esbjerg, Region of Southern Denmark, Denmark",
  "Taos Ski Valley, New Mexico, United States",
  "18400, Cankiri, Çankırı, Türkiye",
  "Washington County, Oregon, United States",
  "Mirny, Arkhangel’sk, Russia",
  "309293, Shebekino, Belgorod, Russia",
  "225701, Pinsk, Brest, Belarus",
  "700003, Iaşi, Iaşi, Romania",
  "98710, Kemijärvi, Lapland, Finland",
  "Wirtschaft zum Grösseltal, Engelsbrand, Baden-Württemberg, Germany",
  "252 50, Helsingborg, Skåne County, Sweden",
  "Zaragoza, Puebla, Mexico",
  "Upshur County, West Virginia, United States",
  "St Paul, Minnesota, United States",
  "Weston, Oregon, United States",
  "Golden Plains, Victoria, Australia",
  "3045-139, Coimbra, Coimbra, Portugal",
  "Apatity, Murmansk, Russia",
  "155901, Shuya, Ivanovo, Russia",
  "McDonald, Tennessee, United States",
  "Hampton, South Carolina, United States",
  "722 31, Västerås, Västmanland County, Sweden",
  "Brighton, Tennessee, United States",
  "Waukesha County, Wisconsin, United States",
  "Güroymak, Bitlis, Türkiye",
  "Petersburg, Ontario, Canada",
  "8523, Narvik, Nordland, Norway",
  "659333, Biysk, Altai Krai, Russia",
  "Yellowknife, Northwest Territories, Canada",
  "71122, Foggia, Apulia, Italy",
  "Valleyfield, Quebec, Canada",
  "Pennsalt Historic District, Natrona, Pennsylvania, United States",
  "30120, Hakkâri, Hakkâri, Türkiye",
  "Pender Harbour, Sunshine Coast A, British Columbia, Canada",
  "Sainte-Anne-sur-Brivet, Pays de la Loire, France",
  "Caldwell Parish County, Louisiana, United States",
  "Sumner, Maine, United States",
  "54046, Mykolaiv, Mykolayiv, Ukraine",
  "Vance County, North Carolina, United States",
  "Emery Corners, Lakeshore, Ontario, Canada",
  "620131, Yekaterinburg, Sverdlovsk, Russia",
  "Terry, Kansas, United States",
  "G3064AOH, Bandera, Santiago del Estero, Argentina",
  "Greater Erfurt Area",
  "61200, Trabzon, Trabzon, Türkiye",
  "HP17 0RA, Little Kimble, England, United Kingdom",
  "694627, Kholmsk, Sakhalin, Russia",
  "Corumbataí do Sul, Paraná, Brazil",
  "St. Joe's, Kenosha, Wisconsin, United States",
  "Gillespie Settlement, Grand Falls, New Brunswick, Canada",
  "Duchesne County, Utah, United States",
  "Ozark, Alabama, United States",
  "Meigs County, Tennessee, United States",
  "Sunshine Park, Steubenville, Ohio, United States",
  "Vilas, Brion, Galicia, Spain",
  "H34H3M6, Qulan, Jambyl Region, Kazakhstan",
  "Tijesno, Sibenik-Knin, Croatia",
  "Keyala, Eastern Equatoria, South Sudan",
  "Cache, Oklahoma, United States",
  "X03K6B0, Shymkent, Shymkent, Kazakhstan",
  "Benson, Vermont, United States",
  "IP13 0JQ, Pettistree, England, United Kingdom",
  "Pennington, England, United Kingdom",
  "V3H 4C7, Port Moody, British Columbia, Canada",
  "CA13 0XJ, Cockermouth, England, United Kingdom",
  "Archer, Iowa, United States",
  "39750, Kirklareli, Kırklareli, Türkiye",
  "Kusva, Pskov, Russia",
  "Taoshui, Hunan, China",
  "Mitchell, Oregon, United States",
  "Cimarron South, Florida, United States",
  "Sabina, Ohio, United States",
  "211417, Homyel’, Vitsyebsk, Belarus",
  "San Marino, San Marino",
  "60316, Frankfurt, Hesse, Germany",
  "1223, Tetovo, Polog, North Macedonia",
  "Arendal, Agder, Norway",
  "NG1 7FE, Nottingham, England, United Kingdom",
  "357700, Kislovodsk, Stavropol, Russia",
  "Sweetwater, Oklahoma, United States",
  "670011, Ulan-Ude, Buryatia, Russia",
  "Sweetser, Indiana, United States",
  "A0R 1A0, Churchill Falls, Newfoundland and Labrador, Canada",
  "Volgorechensk, Kostroma, Russia",
  "Cambridge, Maryland, United States",
  "El Alcornocal, Piedrabuena, Castile-La Mancha, Spain",
  "Realejos, Los, Canary Islands, Spain",
  "710010, Ipotești, Botoşani, Romania",
  "Greater Vancouver, British Columbia, Canada",
  "55350, Hutchinson, Minnesota, United States",
  "27070, Gaziantep, Gaziantep, Türkiye",
  "Sant Julià de Lòria, Andorra",
  "42-700, Lubliniec, Śląskie, Poland",
  "Inta, Komi, Russia",
  "423461, Almetyevsk, Tatarstan, Russia",
  "Ulm, Arkansas, United States",
  "Greater Cardiff Area",
  "La Rolette, Couy, Centre-Val de Loire, France",
  "74943, McCurtain, Oklahoma, United States",
  "Huron County, Ohio, United States",
  "LU1 2SE, Luton, England, United Kingdom",
  "34945, Fort Pierce, Florida, United States",
  "040 13, Košice, Kosice, Slovakia",
  "Great Kimble, England, United Kingdom",
  "Pecatonica, Illinois, United States",
  "44842, Loudonville, Ohio, United States",
  "51100, Reims, Grand Est, France",
  "Seneca, Virginia, United States",
  "32981, Ourense, Galicia, Spain",
  "692557, Ussuriysk, Primorye, Russia",
  "Piltene, Ventspils, Latvia",
  "Chesapeake, Virginia, United States",
  "Caledonia, New York, United States",
  "94300, Kemi, Lapland, Finland",
  "624994, Serov, Sverdlovsk, Russia",
  "357920, Zelenokumsk, Stavropol, Russia",
  "Lancaster Metropolitan Area",
  "Tskhinvali District, Georgia",
  "Tulln An Der Donau, Lower Austria, Austria",
  "Allendale, Illinois, United States",
  "Wyoming, Michigan, United States",
  "Kaya, Central-North, Burkina Faso",
  "Gilmer County, West Virginia, United States",
  "Alcobendas, Community of Madrid, Spain",
  "Woodend, Queensland, Australia",
  "Sokolov, Karlovy Vary, Czechia",
  "BT82 9NX, Sion Mills, Northern Ireland, United Kingdom",
  "São João da Madeira, Aveiro, Portugal",
  "Yenakiyeve, Donetsk, Ukraine",
  "Délégation Béja Nord, Béja, Tunisia",
  "Khilok, Zabaykalsky Krai, Russia",
  "27400, Gaziantep, Gaziantep, Türkiye",
  "9403, Harstad, Troms og Finnmark, Norway",
  "Dorchester County, South Carolina, United States",
  "Juneau Highlands, West Allis, Wisconsin, United States",
  "Burrell Creek, New South Wales, Australia",
  "Guatemala",
  "Velikiy Ustyug, Vologda, Russia",
  "6932, Lugano, Ticino, Switzerland",
  "72100, Le Mans, Pays de la Loire, France",
  "Taylor County, Texas, United States",
  "Avarua, Rarotonga, Cook Islands",
  "Iron City, Tennessee, United States",
  "Ravenna, Texas, United States",
  "Elkhart County, Indiana, United States",
  "Petrovsk-Zabaykal'skiy, Zabaykalsky Krai, Russia",
  "Tobolsky District, Tyumen’, Russia",
  "Carroll County, Ohio, United States",
  "Quitman, Louisiana, United States",
  "Stafford, New Jersey, United States",
  "673730, Mogocha, Zabaykalsky Krai, Russia",
  "Greater Oulu Area",
  "Yates Center, Kansas, United States",
  "70110, Kuopio, Northern Savonia, Finland",
  "6362, Stansstad, Nidwalden, Switzerland",
  "356425, Blagodarnyy, Stavropol, Russia",
  "Gage, Garlède-Mondebat, Nouvelle-Aquitaine, France",
  "73016, San Cesario di Lecce, Apulia, Italy",
  "Volgograd Metropolitan Area",
  "41553, Phelps, Kentucky, United States",
  "11400, Bilecik, Bilecik, Türkiye",
  "31011, Pamplona, Chartered Community of Navarre, Spain",
  "Williamstown, Massachusetts, United States",
  "Volgo-Kaspiyskiy, Astrakhan’, Russia",
  "46022, Valencia, Valencian Community, Spain",
  "Greater Munich Metropolitan Area",
  "Cuyahoga County, Ohio, United States",
  "27301, McLeansville, North Carolina, United States",
  "KA8 0AB, Ayr, Scotland, United Kingdom",
  "Midland City, Alabama, United States",
  "220177, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "32011, Callahan, Florida, United States",
  "LU3 2HP, Luton, England, United Kingdom",
  "Yevlakh, Central Aran, Azerbaijan",
  "Presidente Hayes, Paraguay",
  "Encamp, Andorra",
  "551, Mainz, Rhineland-Palatinate, Germany",
  "Babaeski, Kırklareli, Türkiye",
  "Engenheiro Navarro, Minas Gerais, Brazil",
  "Webster, Texas, United States",
  "694241, Poronaysk, Sakhalin, Russia",
  "Llano, New Mexico, United States",
  "New Tazewell, Tennessee, United States",
  "95027, San Gregorio di Catania, Sicily, Italy",
  "26100, Eskişehir, Eskişehir, Türkiye",
  "83301, Twin Falls, Idaho, United States",
  "Los Reyes Acaquilpan, México, Mexico",
  "Verona, Missouri, United States",
  "Mathews Estates, Swift Creek, North Carolina, United States",
  "28460, Sneads Ferry, North Carolina, United States",
  "142406, Noginsk, Moscow, Russia",
  "6214 PX, Maastricht, Limburg, Netherlands",
  "Tompkins, New York, United States",
  "Aberdeen, Eastern Cape, South Africa",
  "Shimanovsky District, Amur, Russia",
  "665010, Tayshet, Irkutsk, Russia",
  "Chalfont St Giles, England, United Kingdom",
  "Kemp, Texas, United States",
  "7100, Szekszárd, Tolna, Hungary",
  "BT78 5LU, Omagh, Northern Ireland, United Kingdom",
  "Orsovskiy, Orel, Russia",
  "04005, Almería, Andalusia, Spain",
  "Langlade, Tréjouls, Occitanie, France",
  "Tulancingo, Hidalgo, Mexico",
  "23701, Portsmouth, Virginia, United States",
  "88201, Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "23040, Elâzığ, Elazığ, Türkiye",
  "0367, Oslo, Oslo, Norway",
  "Llano de Brujas, Murcia, Región de Murcia, Spain",
  "Morrow Heights, Oklahoma, United States",
  "Linn County, Oregon, United States",
  "142207, Serpukhov, Moscow, Russia",
  "9001, Lushnjë, Fier, Albania",
  "400001, Cluj-Napoca, Cluj, Romania",
  "Pavlodar Region, Kazakhstan",
  "Saint Petersburg Metropolitan Area",
  "682800, Sovetskaya Gavan’, Khabarovsk, Russia",
  "200549, Craiova, Dolj, Romania",
  "429828, Alatyr’, Chuvashia, Russia",
  "Davidson County, North Carolina, United States",
  "Upper Rattlesnake, Missoula, Montana, United States",
  "Asparuhovo, Varna, Bulgaria",
  "Jackson, Georgia, United States",
  "Whitman County, Washington, United States",
  "Shoreview, Galveston, Texas, United States",
  "43615, Toledo, Ohio, United States",
  "Mala Vyska Raion, Kirovohrad, Ukraine",
  "40800, Jyväskylä, Central Finland, Finland",
  "L2E 0A1, Niagara Falls, Ontario, Canada",
  "31100, Treviso, Veneto, Italy",
  "413858, Balakovo, Saratov, Russia",
  "Huntsville-Decatur-Albertville Area",
  "Tazewell, Knoxville, Tennessee, United States",
  "Butler, Kentucky, United States",
  "Parma, Michigan, United States",
  "Fethiye Bucağı, Muğla, Türkiye",
  "94567, Pope Valley, California, United States",
  "Pershing-Government Hill, El Paso, Texas, United States",
  "679017, Birobidzhan, Birobijan, Russia",
  "23504, Norfolk, Virginia, United States",
  "08401, Atlantic City, New Jersey, United States",
  "Andrew, Iowa, United States",
  "Emmaboda, Kalmar County, Sweden",
  "Tula Municipality, Mexico",
  "Bila Tserkva Raion, Kyiv, Ukraine",
  "8016 AB, Zwolle, Overijssel, Netherlands",
  "Biloxi, Mississippi, United States",
  "10090, Zagreb, Zagreb, Croatia",
  "8753, Niederurnen, Glarus, Switzerland",
  "Sheffield, Massachusetts, United States",
  "Tayshet, Irkutsk, Russia",
  "Alessandria, Piedmont, Italy",
  "F63B9B8, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "Horgen, Zurich, Switzerland",
  "2318, Hamar, Innlandet, Norway",
  "Veliko Tarnovo Municipality, Veliko Turnovo, Bulgaria",
  "309950, Valuyki, Belgorod, Russia",
  "8907, Zalaegerszeg, Zala, Hungary",
  "Idaho, United States",
  "Saint-François, Pointe-à-Pitre, Guadeloupe",
  "Central Java, Indonesia",
  "Belize",
  "83852, Ponderay, Idaho, United States",
  "88061, Silver City, New Mexico, United States",
  "442246, Kamenka, Penza, Russia",
  "617438, Kungur, Perm, Russia",
  "Groningen, Saramacca, Suriname",
  "Gonzales, Tijeras, New Mexico, United States",
  "Lac, Chad",
  "06335, Gales Ferry, Connecticut, United States",
  "352 52, Växjö, Kronoberg County, Sweden",
  "392008, Tambov, Tambov, Russia",
  "Nässjö kommun, Jönköping County, Sweden",
  "Martinsville, Ohio, United States",
  "Aleysky, Altai Krai, Russia",
  "Greater León, Spain Area",
  "254 41, Helsingborg, Skåne County, Sweden",
  "Muskoka, Ontario, Canada",
  "MD-2068, Chişinău, Chișinău, Moldova",
  "Clallam Bay, Washington, United States",
  "Wright City, Missouri, United States",
  "Addison, Illinois, United States",
  "40474, Düsseldorf, North Rhine-Westphalia, Germany",
  "Starrs Point, Kings, Subdivision B, Nova Scotia, Canada",
  "19601, Reading, Pennsylvania, United States",
  "Shenandoah County, Virginia, United States",
  "83605, Caldwell, Idaho, United States",
  "Christiana, Tennessee, United States",
  "Preston, England, United Kingdom",
  "400, Ísafjörður, Westfjords, Iceland",
  "La Joséphine, Moutiers-les-Mauxfaits, Pays de la Loire, France",
  "Dixon, Missouri, United States",
  "Verkhoyansky District, Sakha, Russia",
  "Schleicherberg, Mehring, Rhineland-Palatinate, Germany",
  "670050, Ulan-Ude, Buryatia, Russia",
  "Bergen, Bavaria, Germany",
  "714 09, Iráklion, Crete, Greece",
  "Chesterfield Court House, Virginia, United States",
  "200800, Craiova, Dolj, Romania",
  "212001, Mahilyow, Mahilyow, Belarus",
  "Saint-Prosper-de-Dorchester, Quebec, Canada",
  "Zielonogórski, Lubuskie, Poland",
  "Kerchom”ya, Komi, Russia",
  "160000, Vologda, Vologda, Russia",
  "95692, Wheatland, California, United States",
  "Forreston, Texas, United States",
  "399260, Yelets-Malanino, Lipetsk, Russia",
  "Greater Mugla",
  "412304, Balashov, Saratov, Russia",
  "Aral İkinci, Central Aran, Azerbaijan",
  "Horki, Mahilyow, Belarus",
  "Buena Vista, Umatilla, Florida, United States",
  "SS2 4AA, Southend-On-Sea, England, United Kingdom",
  "403892, Kamyshin, Volgograd, Russia",
  "70506, Erathna, Sabaragamuwa Province, Sri Lanka",
  "Pesnica pri Mariboru, Pesnica, Slovenia",
  "Sterling, Massachusetts, United States",
  "33704, St Petersburg, Florida, United States",
  "Rankin, Texas, United States",
  "Coahoma County, Mississippi, United States",
  "T0L 2A0, Turner Valley, Alberta, Canada",
  "Boydton, Virginia, United States",
  "Torrance County, New Mexico, United States",
  "620112, Focşani, Vrancea, Romania",
  "678330, Zhigansk, Sakha, Russia",
  "Sandovales, Durango, Mexico",
  "Wasseramt, Solothurn, Switzerland",
  "Grant County, Oregon, United States",
  "905500, Mangalia, Constanţa, Romania",
  "42275, Wuppertal, North Rhine-Westphalia, Germany",
  "KA7 2AS, Ayr, Scotland, United Kingdom",
  "Oldenburg, Indiana, United States",
  "Victoria, Araucanía Region, Chile",
  "Nevyansky District, Sverdlovsk, Russia",
  "Fredericksburg, Texas, United States",
  "Maryland, United States",
  "Smorgon'skiy gorodskoy sovet, Hrodna, Belarus",
  "3140, Tønsberg, Vestfold og Telemark, Norway",
  "Stadt Hamburg, Hamburg, Germany",
  "Ward 5, Ho Chi Minh City, Vietnam",
  "06005, Badajoz, Extremadura, Spain",
  "Biel, Berne, Switzerland",
  "Traverse County, Minnesota, United States",
  "Weston, Massachusetts, United States",
  "79111, Freiburg, Baden-Württemberg, Germany",
  "02822, Exeter, Rhode Island, United States",
  "Bellevue, Nebraska, United States",
  "Preko, Zadar, Croatia",
  "Cincinnati, Ohio, United States",
  "48329, Waterford, Michigan, United States",
  "Kings, Prince Edward Island, Canada",
  "6665, Heidelberg, Western Cape, South Africa",
  "27333, Schweringen, Lower Saxony, Germany",
  "422551, Zelënodol'sk, Mari El, Russia",
  "5322, Koblenz, Aargau, Switzerland",
  "43938, Mingo Junction, Ohio, United States",
  "Etobicoke, Ontario, Canada",
  "5612 PW, Eindhoven, North Brabant, Netherlands",
  "Rafha, Northern Frontier, Saudi Arabia",
  "Otterburn Park, Quebec, Canada",
  "Perry, Oklahoma, United States",
  "Rafḩah, Northern Frontier, Saudi Arabia",
  "Cambridge, Massachusetts, United States",
  "Traille, Issor, Nouvelle-Aquitaine, France",
  "1303, Sofia, Sofia City, Bulgaria",
  "423821, Naberezhnyye Chelny, Tatarstan, Russia",
  "Danville, Illinois, United States",
  "6071, Glen Forrest, Western Australia, Australia",
  "Essex, Massachusetts, United States",
  "Cheyenne, Oklahoma, United States",
  "420141, Kazan, Tatarstan, Russia",
  "Troitsko-Pechorsk, Komi, Russia",
  "X0C 0A1, Rankin Inlet, Nunavut, Canada",
  "DeWitt, New York, United States",
  "184211, Apatity, Murmansk, Russia",
  "06095, Windsor, Connecticut, United States",
  "Hlohovec, Trnava Region, Slovakia",
  "7006, Bilisht, Korçë, Albania",
  "64200, Uşak, Uşak, Türkiye",
  "627308, Golyshmanovo, Tyumen’, Russia",
  "Ostrava - město, Moravia-Silesia, Czechia",
  "Crockett, Texas, United States",
  "222 20, Malmo, Skåne County, Sweden",
  "Galliano, Torricella Sicura, Abruzzi, Italy",
  "Barron Gorge, Queensland, Australia",
  "97031, Hood River, Oregon, United States",
  "Remich, Grevenmacher, Luxembourg",
  "Hakkâri, Türkiye",
  "FY4 4PH, Blackpool, England, United Kingdom",
  "Linzhang, Hebei, China",
  "Verkh-Kargat, Novosibirsk, Russia",
  "Greater Amiens Area",
  "Mid Murray, South Australia, Australia",
  "Dundee, Florida, United States",
  "Greater Oviedo Metropolitan Area",
  "9700-103, Angra do Heroísmo, Portugal",
  "Hudson, Florida, United States",
  "97295, Mérida, Yucatán, Mexico",
  "Medina, Ohio, United States",
  "Bamberg, Wellesley, Ontario, Canada",
  "650070, Kemerovo, Kemerovo, Russia",
  "78207, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "Columbia, Missouri, United States",
  "LV-3601, Tārgale, Ventspils, Latvia",
  "Kemper, Saint-Jean-Brévelay, Brittany, France",
  "Parker, Texas, United States",
  "389230, Lunavada, Gujarat, India",
  "Haskell County, Kansas, United States",
  "Point Chautauqua Historic District, New York, United States",
  "8020, Paphos Municipality, Paphos, Cyprus",
  "29536, Dillon, South Carolina, United States",
  "Oktyabr'skiy sel'skiy sovet, Vitsyebsk, Belarus",
  "97232, Le Lamentin, Fort-de-France, Martinique",
  "Zaragoza, Coahuila, Mexico",
  "74114, Tulsa, Oklahoma, United States",
  "627704, Tobolovo, Tyumen’, Russia",
  "99207, Spokane, Washington, United States",
  "Chatham, Illinois, United States",
  "Clarion County, Pennsylvania, United States",
  "Kartaly, Chelyabinsk, Russia",
  "Wake County, North Carolina, United States",
  "Kearney Woods, Wake Forest Township, North Carolina, United States",
  "Haarlem, North Holland, Netherlands",
  "19102, Philadelphia, Pennsylvania, United States",
  "347917, Taganrog, Rostov, Russia",
  "Oconee, Illinois, United States",
  "Altunhisar, Niğde, Türkiye",
  "627703, Mezenka, Tyumen’, Russia",
  "N0B 0B7, Ayr, Ontario, Canada",
  "97072, Würzburg, Bavaria, Germany",
  "Sweetwater, North Carolina, United States",
  "29835, McCormick, South Carolina, United States",
  "Niscemi, Sicily, Italy",
  "Yessey, Evenki, Russia",
  "Buckskin, Basingstoke, England, United Kingdom",
  "57124, Livorno, Tuscany, Italy",
  "Pécoste, Bahus-Soubiran, Nouvelle-Aquitaine, France",
  "Ljubljana, Ljubljana, Slovenia",
  "9700-096, Angra do Heroísmo, Portugal",
  "L-8521, Beckerich, Diekirch, Luxembourg",
  "Pierce City, Missouri, United States",
  "456216, Zlatoust, Chelyabinsk, Russia",
  "7310, Pogradec, Korçë, Albania",
  "Greater Wurzburg Area",
  "Yankton County, South Dakota, United States",
  "11500, Bilecik, Bilecik, Türkiye",
  "891, Örnsköldsvik, Västernorrland County, Sweden",
  "56673, Roosevelt, Minnesota, United States",
  "87507, Mariupol, Donetsk, Ukraine",
  "Turag Thana, Dhaka, Bangladesh",
  "23850, Elâzığ, Elazığ, Türkiye",
  "Carter, Montana, United States",
  "374, Gemeindefreies Gebiet Harz (Landkreis Göttingen), Lower Saxony, Germany",
  "141505, Solnechnogorsk, Moscow, Russia",
  "222522, Barysaw, Minsk, Belarus",
  "301832, Bogoroditsk, Tula, Russia",
  "665830, Angarsk, Irkutsk, Russia",
  "172503, Nelidovo, Tver’, Russia",
  "VLT 1170, Valletta, Valletta, Malta",
  "Lengerich, North Rhine-Westphalia, Germany",
  "Eastern, Saudi Arabia",
  "New Carlisle, Ohio, United States",
  "677022, Yakutsk, Sakha, Russia",
  "SN11 8YG, Yatesbury, England, United Kingdom",
  "95620, Parmain, Île-de-France, France",
  "Kings Park, Victoria, Australia",
  "Monastier di Treviso, Veneto, Italy",
  "Hendricks County, Indiana, United States",
  "61177, Kharkiv, Kharkiv, Ukraine",
  "11912, Tallinn, Harjumaa, Estonia",
  "Kharkiv, Kharkiv, Ukraine",
  "V0N 2M2, Pender Island, British Columbia, Canada",
  "88262, McDonald, New Mexico, United States",
  "7736, Steinkjer, Trøndelag, Norway",
  "Burleigh, Stroud, England, United Kingdom",
  "Desa Cibolang Kaler, Cisaat, West Java, Indonesia",
  "Jibert, Braşov, Romania",
  "424007, Yoshkar-Ola, Mari El, Russia",
  "Chautauqua, Kansas, United States",
  "8021 BT, Zwolle, Overijssel, Netherlands",
  "353906, Novorossiysk, Krasnodar, Russia",
  "Dombarovsky District, Orenburg, Russia",
  "422958, Chistopol’skiye Vyselki, Tatarstan, Russia",
  "82003, Cheyenne, Wyoming, United States",
  "Sutton-In-Craven, England, United Kingdom",
  "Sherman, Connecticut, United States",
  "Essendon, Victoria, Australia",
  "Kota Samarahan, Sarawak, Malaysia",
  "Belogorskiy, Amur, Russia",
  "23241, Zrenjanin, Vojvodina, Serbia",
  "33001, Oviedo, Principality of Asturias, Spain",
  "Yazoo County, Mississippi, United States",
  "Onega, Arkhangel’sk, Russia",
  "Wahkiakum County, Washington, United States",
  "Odessa, Florida, United States",
  "Allegheny West, Philadelphia, Pennsylvania, United States",
  "722 10, Västerås, Västmanland County, Sweden",
  "172383, Rzhev, Tver’, Russia",
  "E1A 1P3, Dieppe, New Brunswick, Canada",
  "Macon, Illinois, United States",
  "243010, Novozybkov, Bryansk, Russia",
  "Binaghadi, Baku Ekonomic Zone, Azerbaijan",
  "Rockville, Maryland, United States",
  "Montgomery County, Indiana, United States",
  "618244, Chusovoy, Perm, Russia",
  "73100, Lecce, Apulia, Italy",
  "25425, Harpers Ferry, West Virginia, United States",
  "636601, Kolpashevo, Tomsk, Russia",
  "Prairie du Chien, Wisconsin, United States",
  "Hanson Crescent, Saint George, Barbados",
  "Timiryazevskiy, Lipetsk, Russia",
  "636500, Kolpashevo, Tomsk, Russia",
  "Greater Indianapolis",
  "Wibaux, Montana, United States",
  "Webster, Kentucky, United States",
  "300013, Timişoara, Timiş, Romania",
  "01543, Rutland, Massachusetts, United States",
  "Ružić, Sibenik-Knin, Croatia",
  "44600, Guadalajara, Jalisco, Mexico",
  "682645, Amursk, Khabarovsk, Russia",
  "Ulukışla, Niğde, Türkiye",
  "Sainte-Sabine, Quebec, Canada",
  "71450, Kırıkkale, Kırıkkale, Türkiye",
  "Szombathelyi járás, Vas, Hungary",
  "61483, Toulon, Illinois, United States",
  "Krems An Der Donau, Lower Austria, Austria",
  "Montour, New York, United States",
  "Palermo, Sicily, Italy",
  "Gaylord, Michigan, United States",
  "High Barnes, Sunderland, England, United Kingdom",
  "Dover, Massachusetts, United States",
  "47000, Karlovac, Karlovac, Croatia",
  "37600, Mirgorod, Poltava, Ukraine",
  "Sumner, Mississippi, United States",
  "Great Haywood, England, United Kingdom",
  "Loudon, Parigné-l’Évêque, Pays de la Loire, France",
  "01030, Kyiv, Kyiv City, Ukraine",
  "Chistopol’ye, Kirov, Russia",
  "EH7 4AA, Edinburgh, Scotland, United Kingdom",
  "Battalgazi, Malatya, Türkiye",
  "89132, Reggio di Calabria, Calabria, Italy",
  "423252, Leninogorsk, Tatarstan, Russia",
  "Williamston, South Carolina, United States",
  "Jones County, Texas, United States",
  "26691, Tioga, West Virginia, United States",
  "1060, Brussels, Brussels Region, Belgium",
  "75486, Sumner, Texas, United States",
  "28911-280, Cabo Frio, Rio de Janeiro, Brazil",
  "427964, Sarapul, Udmurtia, Russia",
  "Eau Claire, Michigan, United States",
  "905 94, Umeå, Västerbotten County, Sweden",
  "8500-826, Portimão, Faro, Portugal",
  "8281 AA, Hasselt, Overijssel, Netherlands",
  "Podlesnoye, Kostroma, Russia",
  "430033, Saransk, Mordovia, Russia",
  "Forsyth County, North Carolina, United States",
  "Tartus, Tartus Governorate, Syria",
  "68825, Callaway, Nebraska, United States",
  "2609, Lillehammer, Innlandet, Norway",
  "Khatanga, Taymyr, Russia",
  "665412, Cheremkhovo, Irkutsk, Russia",
  "Wheeler's Hill, Radstock, England, United Kingdom",
  "155802, Kineshma, Ivanovo, Russia",
  "23263, Zrenjanin, Vojvodina, Serbia",
  "618232, Chusovoy, Perm, Russia",
  "Millersburg, Ohio, United States",
  "García, Nuevo León, Mexico",
  "353467, Gelendzhik, Krasnodar, Russia",
  "309996, Valuyki, Belgorod, Russia",
  "61-823, Poznan, Wielkopolskie, Poland",
  "410327, Oradea, Bihor, Romania",
  "GY2 4JW, St Sampson, Guernsey, Guernsey",
  "78701, Austin, Texas, United States",
  "F17Y4M6, Semey, Abay Region, Kazakhstan",
  "8310, Svolvær, Nordland, Norway",
  "Evangeline Parish County, Louisiana, United States",
  "6707, Robertson, Western Cape, South Africa",
  "Minnehaha Woods, Edina, Minnesota, United States",
  "Ponte de Lima, Viana do Castelo, Portugal",
  "Mosonmagyaróvár, Győr-Moson-Sopron, Hungary",
  "356407, Blagodarnyy, Stavropol, Russia",
  "666784, Ust-Kut, Irkutsk, Russia",
  "65-536, Zielona Góra, Lubuskie, Poland",
  "79000, Lviv, Lviv, Ukraine",
  "Harrison County, Kentucky, United States",
  "Vila Nova de Famalicão, Braga, Portugal",
  "Greater Ciudad Real Metropolitan Area",
  "44225, Dortmund, North Rhine-Westphalia, Germany",
  "Polotsk District, Vitsyebsk, Belarus",
  "Rutland, North Dakota, United States",
  "Burton, New Brunswick, Canada",
  "Woodstock, New York, United States",
  "44-152, Gliwice, Śląskie, Poland",
  "Riga, Michigan, United States",
  "Orange, Virginia, United States",
  "427968, Sarapul, Udmurtia, Russia",
  "Laurens County, South Carolina, United States",
  "Tyler Hill, Pennsylvania, United States",
  "Conchon, Astaffort, Nouvelle-Aquitaine, France",
  "5250, Odense, Region of Southern Denmark, Denmark",
  "452767, Tatar-Ulkanovo, Bashkortostan, Russia",
  "Stanton County, Nebraska, United States",
  "Ikiköprü, Siirt, Türkiye",
  "15890, Santiago de Compostela, Galicia, Spain",
  "658822, Slavgorod, Altai Krai, Russia",
  "Smithville, Texas, United States",
  "Daneş, Mureş, Romania",
  "77801, Bryan, Texas, United States",
  "Lycoming County, Pennsylvania, United States",
  "659350, Yeniseyskoye, Altai Krai, Russia",
  "Puketoi, Otago, New Zealand",
  "Vakfıkebir, Trabzon, Türkiye",
  "45030, Manisa, Manisa, Türkiye",
  "Henry, Virginia, United States",
  "Monmouth, Illinois, United States",
  "70800, Karaman, Karaman, Türkiye",
  "357919, Zelenokumsk, Stavropol, Russia",
  "Somerset, England, United Kingdom",
  "Logan, West Virginia, United States",
  "86167, Augsburg, Bavaria, Germany",
  "Naples, Texas, United States",
  "30534, Dawsonville, Georgia, United States",
  "X09F8C6, Shymkent, Shymkent, Kazakhstan",
  "Ust-Ilimsky District, Irkutsk, Russia",
  "24220, Subotica, Vojvodina, Serbia",
  "357569, Pyatigorsk, Stavropol, Russia",
  "Pokrovskoye, Sverdlovsk, Russia",
  "Nikolayevskoye, Belgorod, Russia",
  "LE4 0AA, Leicester, England, United Kingdom",
  "12846, Lake Luzerne, New York, United States",
  "Rockcliffe-Smythe, Toronto, Ontario, Canada",
  "Pettis County, Missouri, United States",
  "Norwich, Connecticut, United States",
  "Ashenat, Amhara Region, Ethiopia",
  "Ashalim, South District, Israel",
  "Wisconsin Rapids, Wisconsin, United States",
  "Shamkir District, Qazakh-Tovuz, Azerbaijan",
  "Dve Mogili, Ruse, Bulgaria",
  "Vigo County, Indiana, United States",
  "Yeyskoye Ukrepleniye, Krasnodar, Russia",
  "Lewis County, West Virginia, United States",
  "Newton County, Missouri, United States",
  "OL8 1LQ, Oldham, England, United Kingdom",
  "Wallace, North Carolina, United States",
  "Bristol, Vermont, United States",
  "544 54, Thessaloniki, Central Macedonia, Greece",
  "Katund i Ri, Durres, Albania",
  "Lipetsk, Lipetsk, Russia",
  "977 54, Luleå, Norrbotten County, Sweden",
  "Staraya Vichuga, Ivanovo, Russia",
  "Newcastle West, County Limerick, Ireland",
  "Clayton, California, United States",
  "034 01, Ružomberok, Zilina, Slovakia",
  "29104, Mayesville, South Carolina, United States",
  "33009, Oviedo, Principality of Asturias, Spain",
  "666702, Kirensk, Irkutsk, Russia",
  "Columbus, Ohio, United States",
  "Oulunkaari sub-region, North Ostrobothnia, Finland",
  "Maloliubashanska silska hromada, Rivne, Ukraine",
  "79018, Lviv, Lviv, Ukraine",
  "65189, Wiesbaden, Hesse, Germany",
  "350049, Krasnodar, Krasnodar, Russia",
  "Condeixa a Nova, Coimbra, Portugal",
  "Montague, California, United States",
  "Ashtabula County Courthouse Group, Jefferson, Ohio, United States",
  "Turhal, Tokat, Türkiye",
  "420000, Kazan, Tatarstan, Russia",
  "426068, Izhevsk, Udmurtia, Russia",
  "Burnet County, Texas, United States",
  "Tyler, Virginia, United States",
  "Kharkiv, Ukraine",
  "Fairfax, Virginia, United States",
  "Lake County, Indiana, United States",
  "Molina de Segura, Región de Murcia, Spain",
  "Hawkins Point, Baltimore, Maryland, United States",
  "Duchesne, Utah, United States",
  "184601, Severomorsk, Murmansk, Russia",
  "Vologodsky District, Vologda, Russia",
  "C19B7B8, Zhaltyr, Akmola Region, Kazakhstan",
  "4154, Ransome, Queensland, Australia",
  "06003, Badajoz, Extremadura, Spain",
  "Eforie Sud, Constanţa, Romania",
  "6031, Charleroi, Walloon Region, Belgium",
  "50018, Zaragoza, Aragon, Spain",
  "Parma, New York, United States",
  "182647, Borovichi, Pskov, Russia",
  "Broome, New York, United States",
  "Cooper's Camp, Ranaghat, West Bengal, India",
  "Lewis County, Tennessee, United States",
  "Wasco, Illinois, United States",
  "27837, Grimesland, North Carolina, United States",
  "Pendleton, New York, United States",
  "94705, Berkeley, California, United States",
  "28003, Madrid, Community of Madrid, Spain",
  "Norman County, Minnesota, United States",
  "Divjakë, Fier, Albania",
  "Szekszárd District, Tolna, Hungary",
  "Gilliam, Missouri, United States",
  "66000, Perpignan, Occitanie, France",
  "York County, Pennsylvania, United States",
  "Rutherford, Tennessee, United States",
  "Stoke St Gregory, England, United Kingdom",
  "70124, New Orleans, Louisiana, United States",
  "Montego Bay, Saint James, Jamaica",
  "33690, San Francisco de Conchos, Chihuahua, Mexico",
  "450511, Mikhaylovka, Bashkortostan, Russia",
  "90502, Haapsalu, Laane, Estonia",
  "49086, Osnabrück, Lower Saxony, Germany",
  "Hutchinson, Ubuntu, Northern Cape, South Africa",
  "Corumbiara, Rondônia, Brazil",
  "25050, Erzurum, Erzurum, Türkiye",
  "Kristiansand, Agder, Norway",
  "357703, Kislovodsk, Stavropol, Russia",
  "C40A8M7, Esil, Akmola Region, Kazakhstan",
  "Yeyskoukreplenskoye, Krasnodar, Russia",
  "Arlington, Massachusetts, United States",
  "Lake Rowan, Victoria, Australia",
  "Abiakpo Ikot Obionting, Akwa Ibom State, Nigeria",
  "2300, La Chaux-de-Fonds, Neuchâtel, Switzerland",
  "Phelps County, Missouri, United States",
  "681 32, Alexandroúpolis, Eastern Macedonia and Thrace, Greece",
  "Örnsköldsviks kommun, Västernorrland County, Sweden",
  "Portage, Utah, United States",
  "14508, Hamlin, New York, United States",
  "Karavayevo, Kostroma, Russia",
  "38922, Coffeeville, Mississippi, United States",
  "Monroe County, Mississippi, United States",
  "48509, Burton, Michigan, United States",
  "Slatiňany, Pardubice, Czechia",
  "Le Liège, Centre-Val de Loire, France",
  "Soron, Uttar Pradesh, India",
  "1582, Sofia, Sofia City, Bulgaria",
  "Blagoveshchensk, Bashkortostan, Russia",
  "46368, Portage, Indiana, United States",
  "X38H5A4, Kentau, Turkistan Region, Kazakhstan",
  "Crowley, Texas, United States",
  "98291, Snohomish, Washington, United States",
  "Josephine, Pennsylvania, United States",
  "Guilford Hills, Pennsylvania, United States",
  "663318, Noril'sk, Taymyr, Russia",
  "110393, Piteşti, Argeş, Romania",
  "Craiova, Dolj, Romania",
  "Andriivska silska rada, Sevastopol, Ukraine",
  "94930, Fairfax, California, United States",
  "Rainsars, Hauts-de-France, France",
  "99, Kirkenes, Troms og Finnmark, Norway",
  "Altdorf, Schaffhausen, Switzerland",
  "Tamsweg, Salzburg, Austria",
  "95746, Granite Bay, California, United States",
  "NR35 2PE, Broome, England, United Kingdom",
  "Megion, Khanty-Mansi, Russia",
  "Brüggen, North Rhine-Westphalia, Germany",
  "68420, Pawnee City, Nebraska, United States",
  "17754, Montoursville, Pennsylvania, United States",
  "Bedford, Western Australia, Australia",
  "352703, Timashevsk, Krasnodar, Russia",
  "Bradford, Tennessee, United States",
  "16001, Butler, Pennsylvania, United States",
  "Gage County, Nebraska, United States",
  "70-713, Szczecin, Zachodniopomorskie, Poland",
  "PE1 1AB, Peterborough, England, United Kingdom",
  "36040, Poltava, Poltava, Ukraine",
  "Galway, New York, United States",
  "Slantsy, Leningrad, Russia",
  "Almaty District, Astana, Kazakhstan",
  "Dalnerechensky District, Primorye, Russia",
  "9002, Varna, Varna, Bulgaria",
  "Niagara Falls, Ontario, Canada",
  "Wichita, Kansas Metropolitan Area",
  "61327, Hennepin, Illinois, United States",
  "31025, Elko, Georgia, United States",
  "West Bennett, South Dakota, United States",
  "Norton, Wales, United Kingdom",
  "Chambers County, Alabama, United States",
  "NE9 6DG, Gateshead, England, United Kingdom",
  "424033, Yoshkar-Ola, Mari El, Russia",
  "693019, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Vitoria, Brazil Metropolitan Area",
  "25430, Kearneysville, West Virginia, United States",
  "Odemira, Beja, Portugal",
  "169907, Vorkuta, Komi, Russia",
  "Gardenvale, Victoria, Australia",
  "9054-512, Funchal, Madeira Island, Portugal",
  "6403, Küssnacht am Rigi, Schwyz, Switzerland",
  "3410, Librazhd, Elbasan, Albania",
  "Centar, Skopje Statistical Region, North Macedonia",
  "Mitchell County, Texas, United States",
  "Kel’mentsi, Chernivtsi, Ukraine",
  "Maury, Pont-du-Casse, Nouvelle-Aquitaine, France",
  "Lane, Kansas, United States",
  "East Perth, Western Australia, Australia",
  "Chester-Le-Street, England, United Kingdom",
  "169905, Vorkuta, Komi, Russia",
  "Wyesham, Monmouth, Wales, United Kingdom",
  "Fulton County, Georgia, United States",
  "141302, Sergiyev Posad, Moscow, Russia",
  "NG13 9PE, Staunton In The Vale, England, United Kingdom",
  "456211, Zlatoust, Chelyabinsk, Russia",
  "Liski, Voronezh, Russia",
  "Pike County, Indiana, United States",
  "Bath, Michigan, United States",
  "Alexander, Manitoba, Canada",
  "90570, Oulu, North Ostrobothnia, Finland",
  "Brooksville, Kentucky, United States",
  "Le Roseau, Avelin, Hauts-de-France, France",
  "Mooresboro, North Carolina, United States",
  "94525, Crockett, California, United States",
  "Centro de Integración Territorial Villa Cabello, Posadas, Misiones, Argentina",
  "35000, Rennes, Brittany, France",
  "Liberty, Mississippi, United States",
  "225715, Pinsk, Brest, Belarus",
  "627756, Ishim, Tyumen’, Russia",
  "3527, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "88118, Floyd, New Mexico, United States",
  "Lensky District, Arkhangel’sk, Russia",
  "Columbiana, Ohio, United States",
  "Nottoway, Midwest City, Oklahoma, United States",
  "18972, Upper Black Eddy, Pennsylvania, United States",
  "Windsor, Wisconsin, United States",
  "Norton, West Virginia, United States",
  "618400, Berezniki, Perm, Russia",
  "Tuzara, Calarasi, Moldova",
  "78002, Atascosa, Texas, United States",
  "1111, Middelburg, Mpumalanga, South Africa",
  "Pleven, Pleven, Bulgaria",
  "St. Lucie County, Florida, United States",
  "Lenoir, North Carolina, United States",
  "Perşembe, Ordu, Türkiye",
  "18235, Lehighton, Pennsylvania, United States",
  "Roberts, Idaho, United States",
  "Zhigansky District, Sakha, Russia",
  "7716, Steinkjer, Trøndelag, Norway",
  "PE7 8BQ, Peterborough, England, United Kingdom",
  "461046, Buzuluk, Orenburg, Russia",
  "665653, Zheleznogorsk-Ilimskiy, Irkutsk, Russia",
  "Lebanon, Maine, United States",
  "450022, Ufa, Bashkortostan, Russia",
  "69125, Broadwater, Nebraska, United States",
  "LV-1058, Riga, Riga, Latvia",
  "Downtown Albermarle, Albemarle, North Carolina, United States",
  "29412, Charleston, South Carolina, United States",
  "Collin County, Texas, United States",
  "Morton, Washington, United States",
  "Barnes, Kansas, United States",
  "Sabine, Orsara Bormida, Piedmont, Italy",
  "Sibayo, Arequipa, Peru",
  "Saint-Loup-en-Comminges, Occitanie, France",
  "82-314, Elbląg, Warmińsko-mazurskie, Poland",
  "665841, Angarsk, Irkutsk, Russia",
  "PA5 0AA, Johnstone, Scotland, United Kingdom",
  "Amadora, Lisbon, Portugal",
  "Hutchinson County, South Dakota, United States",
  "Davie, Florida, United States",
  "Sant Julià de Lòria, Sant Julià de Lòria, Andorra",
  "5642, Darke Peak, South Australia, Australia",
  "15-337, Białystok, Podlaskie, Poland",
  "64015, Blue Springs, Missouri, United States",
  "Nassau, New Providence, The Bahamas",
  "Türkiye",
  "Glazovsky District, Udmurtia, Russia",
  "Speicher, Appenzell Outer-Rhoden, Switzerland",
  "57128, Livorno, Tuscany, Italy",
  "Zuchwil, Solothurn, Switzerland",
  "Melitopol Raion, Zaporizhzhya, Ukraine",
  "Khabarovsk, Russia",
  "353468, Gelendzhik, Krasnodar, Russia",
  "46011, Anderson, Indiana, United States",
  "Sumner, North Carolina, United States",
  "Bremen, Georgia, United States",
  "24129, Bergamo, Lombardy, Italy",
  "Douglas County, Wisconsin, United States",
  "Mitchell County, Georgia, United States",
  "Stanton, Michigan, United States",
  "93308, Bakersfield, California, United States",
  "62312, Barry, Illinois, United States",
  "Alekseyevka, Belgorod, Russia",
  "Krasnokamensk, Krasnoyarsk Krai, Russia",
  "Covington County, Mississippi, United States",
  "Bangkok, Bangkok City, Thailand",
  "Stadt Bremen, Bremen, Germany",
  "70785, Walker, Louisiana, United States",
  "23203, Zrenjanin, Vojvodina, Serbia",
  "61832, Danville, Illinois, United States",
  "Uşak, Türkiye",
  "McIntosh, Longmont, Colorado, United States",
  "10141, Turin, Piedmont, Italy",
  "36310, Abbeville, Alabama, United States",
  "Salem, Utah, United States",
  "7732, Steinkjer, Trøndelag, Norway",
  "9051 NC, Leeuwarden, Friesland, Netherlands",
  "Muromska silska rada, Crimea, Ukraine",
  "Krasnodar, Russia",
  "400007, Volgograd, Volgograd, Russia",
  "N0J 0A5, Straffordville, Ontario, Canada",
  "Saint Stephens Church, Virginia, United States",
  "238174, Chernyakhovskiy, Kaliningrad, Russia",
  "Shadrinskiy, Kurgan, Russia",
  "Durres, Albania",
  "Hennepin, Niagara Falls, Ontario, Canada",
  "Randall, Hamilton, Ontario, Canada",
  "P0L 1G0, Fauquier, Ontario, Canada",
  "Parker, South Dakota, United States",
  "Curry, County Mayo, Ireland",
  "Merrick Landing, Horizon West, Florida, United States",
  "Hicksford–Emporia Historic District, Virginia, United States",
  "Ny Esbjerg, Region Zealand, Denmark",
  "Kings County, New York, United States",
  "462281, Mednogorsk, Orenburg, Russia",
  "Center Sarajevo, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "920007, Slobozia, Ialomiţa, Romania",
  "Furnas, Extrema, Minas Gerais, Brazil",
  "Sunnyside, Hitchcock, Texas, United States",
  "Harrison, New Jersey, United States",
  "75014, Paris, Île-de-France, France",
  "Székesfehérvár, Fejér, Hungary",
  "Howell Township, Michigan, United States",
  "91611, Trousdale Estates, California, United States",
  "54410, Laneuveville-devant-Nancy, Grand Est, France",
  "Aldanskiy ulus, Sakha, Russia",
  "306432, Rusanovo, Kursk, Russia",
  "Miller County, Missouri, United States",
  "Halifax, Pennsylvania, United States",
  "R25F6M8, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "Marshall County, West Virginia, United States",
  "Presidio, Texas, United States",
  "Svalbard",
  "Hudson, Illinois, United States",
  "Tuscolano Nord, Rome, Latium, Italy",
  "90503, Haapsalu, Laane, Estonia",
  "McIntosh, Newport News, Virginia, United States",
  "CF23 5AA, Cardiff, Wales, United Kingdom",
  "416504, Akhtubinsk, Astrakhan’, Russia",
  "Zvolen, Banska Bystrica, Slovakia",
  "19000, Çorum, Çorum, Türkiye",
  "85-074, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "Khamis Mushait, 'Asir, Saudi Arabia",
  "Greater Fort Wayne",
  "673370, Shilka, Zabaykalsky Krai, Russia",
  "Rensselaer Falls, New York, United States",
  "32091, Starke, Florida, United States",
  "2985 BE, Ridderkerk, South Holland, Netherlands",
  "Nicaragua",
  "66930, Agenda, Kansas, United States",
  "357538, Pyatigorsk, Stavropol, Russia",
  "80435, Dillon, Colorado, United States",
  "77554, Galveston, Texas, United States",
  "410031, Saratov, Saratov, Russia",
  "918 03, Umeå, Västerbotten County, Sweden",
  "24087, Elliston, Virginia, United States",
  "3913, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Greggenhof, Buttenwiesen, Bavaria, Germany",
  "11202, Algeciras, Andalusia, Spain",
  "Canton of Bastia-2, Corsica, France",
  "Nizhnyaya Tura, Sverdlovsk, Russia",
  "Burke Centre, Virginia, United States",
  "Kilmacuagh (Cooke), County Westmeath, Ireland",
  "679016, Birobidzhan, Birobijan, Russia",
  "Waterford, Virginia, United States",
  "Wilson, Wisconsin, United States",
  "Brives, Centre-Val de Loire, France",
  "5201, Seekirchen Markt, Salzburg, Austria",
  "Conceição do Mato Dentro, Minas Gerais, Brazil",
  "300011, Timişoara, Timiş, Romania",
  "84074, Tooele, Utah, United States",
  "SA3 3BU, Caswell, Wales, United Kingdom",
  "East Slope, Cape Breton, Nova Scotia, Canada",
  "428015, Cheboksary, Chuvashia, Russia",
  "624471, Severoural’sk, Sverdlovsk, Russia",
  "353307, Kholmskaya, Krasnodar, Russia",
  "Stewart County, Tennessee, United States",
  "665702, Bratsk, Irkutsk, Russia",
  "Uzhhorod Raion, Zakarpattya, Ukraine",
  "Le Lamentin, Fort-de-France, Martinique",
  "Delaware, Ohio, United States",
  "Nicollet, Minnesota, United States",
  "Oktyabrsky District, Amur, Russia",
  "Cumberland, Rhode Island, United States",
  "58623, Bowman, North Dakota, United States",
  "Gregg, Indiana, United States",
  "Merrick Park, Pasco, Washington, United States",
  "74729, Caddo, Oklahoma, United States",
  "E28D9P6, Makhambet, Atyrau Region, Kazakhstan",
  "9700-666, Angra do Heroísmo, Portugal",
  "Oktyabr'skiy, Primorye, Russia",
  "Kurumoch, Samara, Russia",
  "Doroshivska silska hromada, Mykolayiv, Ukraine",
  "Waynesboro, Tennessee, United States",
  "461047, Buzuluk, Orenburg, Russia",
  "54500, Sakarya, Sakarya, Türkiye",
  "Shumska miska hromada, Ternopil, Ukraine",
  "456875, Kyshtym, Chelyabinsk, Russia",
  "West Corson, South Dakota, United States",
  "8011, Bodø, Nordland, Norway",
  "50190, Mikkeli, South Savo, Finland",
  "LU1 3FA, Luton, England, United Kingdom",
  "42638, Stearns, Kentucky, United States",
  "Clarksburg, West Virginia, United States",
  "Varna Metropolitan Area",
  "63025, Eureka, Missouri, United States",
  "Somerset, Massachusetts, United States",
  "Catawba Island, Ohio, United States",
  "Comanche County, Oklahoma, United States",
  "Wiesbaden, Hesse, Germany",
  "60322, Frankfurt, Hesse, Germany",
  "Pecs Metropolitan Area",
  "Covington, Indiana, United States",
  "Troyan Municipality, Lovech, Bulgaria",
  "427439, Votkinsk, Udmurtia, Russia",
  "77803, Bryan, Texas, United States",
  "08003, Makarov, Kyiv, Ukraine",
  "KW15 1NG, Kirkwall, Scotland, United Kingdom",
  "Potterton, Scotland, United Kingdom",
  "309291, Shebekino, Belgorod, Russia",
  "Georgia",
  "Mannheim, Baden-Württemberg, Germany",
  "Pawnee City, Nebraska, United States",
  "Tyrrell Park, Texas, United States",
  "628181, Nyagan’, Khanty-Mansi, Russia",
  "Gavião, Portalegre, Portugal",
  "676246, Zeya, Amur, Russia",
  "Qarğalı, Aqtöbe region, Kazakhstan",
  "43300, Kutahya, Kütahya, Türkiye",
  "Kosciusko County, Indiana, United States",
  "Nikolayevskiy, Amur, Russia",
  "15221, Pittsburgh, Pennsylvania, United States",
  "La Route de Carteret, Bricquebec-en-Cotentin, Normandy, France",
  "Central Bedfordshire, England, United Kingdom",
  "Greater Almería Metropolitan Area",
  "Collinsville, Oklahoma, United States",
  "35160, İzmir, İzmir, Türkiye",
  "Hampton, Arkansas, United States",
  "E10D0B9, Qulsarı, Atyrau Region, Kazakhstan",
  "3070, Holmestrand, Vestfold og Telemark, Norway",
  "Piraeus, Attiki, Greece",
  "Boudry, Neuchâtel, Switzerland",
  "Richmond City County, Virginia, United States",
  "Odivelas, Lisbon, Portugal",
  "M1B 0A1, Scarborough, Ontario, Canada",
  "Suntar, Sakha, Russia",
  "Arlington, Virginia, United States",
  "12345, Schenectady, New York, United States",
  "Logan Reserve, Queensland, Australia",
  "Greece",
  "Kimball Acres, Winston-Salem, North Carolina, United States",
  "Chouteau County, Montana, United States",
  "Oensingen, Solothurn, Switzerland",
  "Box, Llanelli, Wales, United Kingdom",
  "J1T 0A7, Asbestos, Quebec, Canada",
  "Le Roseau, Parné-sur-Roc, Pays de la Loire, France",
  "Carlisle, Indiana, United States",
  "690005, Vladivostok, Primorye, Russia",
  "B29B8X9, Esik, Almaty Region, Kazakhstan",
  "27170, Beaumont-le-Roger, Normandy, France",
  "Slovakia",
  "Allendale, Shreveport, Louisiana, United States",
  "455021, Magnitogorsk, Chelyabinsk, Russia",
  "Putnam Valley, New York, United States",
  "6400, Dimitrovgrad, Khaskovo, Bulgaria",
  "Carson, Virginia, United States",
  "Travisa, Gravedona ed Uniti, Lombardy, Italy",
  "171 76, Stockholm, Stockholm County, Sweden",
  "Adams, Indiana, United States",
  "Cambridge, Tasmania, Australia",
  "7301, Pogradec, Korçë, Albania",
  "694450, Nogliki, Sakhalin, Russia",
  "Kurtalan, Siirt, Türkiye",
  "Jerauld County, South Dakota, United States",
  "3183, Te Puke, Bay of Plenty, New Zealand",
  "Petropavlovskoye, Buryatia, Russia",
  "Horta Nord, Valencian Community, Spain",
  "Cardiff, Wales, United Kingdom",
  "V0E 1B0, Armstrong, British Columbia, Canada",
  "36050, Bolzano Vicentino, Veneto, Italy",
  "Greerton, Tauranga, Bay of Plenty, New Zealand",
  "Swain, New York, United States",
  "692909, Nakhodka, Primorye, Russia",
  "Ahwash Ad Daydeeb District, Northern Frontier, Saudi Arabia",
  "1020, Vienna, Vienna, Austria",
  "50900, Nevsehir, Nevşehir, Türkiye",
  "73628, Cheyenne, Oklahoma, United States",
  "Kirsanovka, Voronezh, Russia",
  "65200, Vaasa, Ostrobothnia, Finland",
  "Los Nobles, Callao, Constitutional Province of Callao, Peru",
  "357910, Zelenokumsk, Stavropol, Russia",
  "80525, Fort Collins, Colorado, United States",
  "Henry, South Dakota, United States",
  "Perkinston, Mississippi, United States",
  "Hidden Valley, Queensland, Australia",
  "Côte d'Ivoire",
  "Velikiy Novgorod, Novgorod, Russia",
  "Siena, Tuscany, Italy",
  "32131, East Palatka, Florida, United States",
  "Kambja Parish, Tartumaa, Estonia",
  "Kemerovsky District, Kemerovo, Russia",
  "693013, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Iron County, Utah, United States",
  "Rakovski, Plovdiv, Bulgaria",
  "St Thomas, Ontario, Canada",
  "Saint-Étienne-de-Corcoué, Pays de la Loire, France",
  "Greene County, Ohio, United States",
  "301660, Novomoskovsk, Tula, Russia",
  "27034, Galliavola, Lombardy, Italy",
  "Lavrentiya, Chukot, Russia",
  "Ellisville, Mississippi, United States",
  "157993, Chernyshëvo, Ivanovo, Russia",
  "28001, Albemarle, North Carolina, United States",
  "New Zealand",
  "16440, Venango, Pennsylvania, United States",
  "231300, Lida, Hrodna, Belarus",
  "Trousdale Estates, California, United States",
  "462428, Orsk, Orenburg, Russia",
  "77706, Bogorodchany, Ivano-Frankivsk, Ukraine",
  "Atlantic, North Carolina, United States",
  "06000, Bejaia, Bejaia, Algeria",
  "Mercer County, Illinois, United States",
  "54554, Phelps, Wisconsin, United States",
  "64064, Lees Summit, Missouri, United States",
  "Greater Magdeburg Area",
  "89101, Las Vegas, Nevada, United States",
  "37055, Dickson, Tennessee, United States",
  "Killarney, Victoria, Australia",
  "Jakarta Metropolitan Area",
  "Crowley County, Colorado, United States",
  "Yadkin, North Carolina, United States",
  "Rotterdam, South Holland, Netherlands",
  "Pokrovskoye, Bashkortostan, Russia",
  "49801, Iron Mountain, Michigan, United States",
  "Wayne County, North Carolina, United States",
  "61773, Sibley, Illinois, United States",
  "Severobaykal'sk, Buryatia, Russia",
  "Alfredo Chaves, Espírito Santo, Brazil",
  "386140, Nazran, Ingushetia, Russia",
  "NR24 2RW, Edgefield, England, United Kingdom",
  "PE8 5PU, Barnwell, England, United Kingdom",
  "446551, Surgut, Samara, Russia",
  "Eisenstadt, Burgenland, Austria",
  "618530, Solikamsk, Perm, Russia",
  "Lexington, Oklahoma, United States",
  "Dickeyville Historic District, United States",
  "22969, Schuyler, Virginia, United States",
  "65122, Pescara, Abruzzi, Italy",
  "Dover, Pennsylvania, United States",
  "Atotonilco de Tula, Hidalgo, Mexico",
  "Tipton, England, United Kingdom",
  "Belgium",
  "53593, Verona, Wisconsin, United States",
  "76140, Siauliai, Siauliu, Lithuania",
  "James Creek, Pennsylvania, United States",
  "Brest, Brest, Belarus",
  "Córdoba, Colombia",
  "Weber Ranch, California, United States",
  "Gemeente Zeist, Utrecht, Netherlands",
  "346464, Kerchik-Savrov, Rostov, Russia",
  "Boladi, Lankaran-Astara, Azerbaijan",
  "Sutton, Ontario, Canada",
  "652152, Mariinskiy, Kemerovo, Russia",
  "T4R 0A1, Red Deer, Alberta, Canada",
  "Grand Rapids Metropolitan Area",
  "Dundee, Scotland, United Kingdom",
  "Rivne, Ukraine",
  "Katowice Metropolitan Area",
  "Şebinkarahisar, Giresun, Türkiye",
  "59701, Butte, Montana, United States",
  "BR4 0JL, West Wickham, England, United Kingdom",
  "188572, Vyskatka, Leningrad, Russia",
  "8400, Karnobat, Burgas, Bulgaria",
  "Proença A Nova, Castelo Branco, Portugal",
  "Glasgow City, Scotland, United Kingdom",
  "Letnitsa Municipality, Lovech, Bulgaria",
  "18038, Danielsville, Pennsylvania, United States",
  "South Brazos, Texas, United States",
  "51009, Tartu, Tartumaa, Estonia",
  "23324, Chesapeake, Virginia, United States",
  "6434, Dimitrovgrad, Khaskovo, Bulgaria",
  "Kamenka-Dneprovskaya, Zaporizhzhya, Ukraine",
  "35740-000, Esmeraldas, Minas Gerais, Brazil",
  "346407, Novocherkassk, Rostov, Russia",
  "442890, Serdobsk, Penza, Russia",
  "12002, Castellón de la Plana, Valencian Community, Spain",
  "Columbia, Tennessee, United States",
  "Stokes, Illinois, United States",
  "Chambersburg, North Carolina, United States",
  "West Virginia, United States",
  "Victoria, Nova Scotia, Canada",
  "403879, Kamyshin, Volgograd, Russia",
  "Fayette County, West Virginia, United States",
  "Gölhisar, Burdur, Türkiye",
  "Les Nobles, Celon, Centre-Val de Loire, France",
  "76442, Comanche, Texas, United States",
  "72501, Batesville, Arkansas, United States",
  "Delaware Valley, New Jersey, United States",
  "6835 AA, Arnhem, Gelderland, Netherlands",
  "Livingston County, Kentucky, United States",
  "50019, Kaunas, Kauno, Lithuania",
  "1000-001, Lisbon, Lisbon, Portugal",
  "Bilbao, Basque Country, Spain",
  "425200, Năsăud, Bistriţa-Năsăud, Romania",
  "35113, Panevėžys, Panevėžio, Lithuania",
  "Ernestinovo, Osijek-Baranja, Croatia",
  "East Todd, South Dakota, United States",
  "C00B1Y0, Kokshetau, Akmola Region, Kazakhstan",
  "Bayanaul District, Pavlodar Region, Kazakhstan",
  "Opolski, Opolskie, Poland",
  "Bedford, Virginia, United States",
  "Ashland, Alabama, United States",
  "North 24 Parganas, West Bengal, India",
  "Weirton–Steubenville metropolitan area, Ohio, United States",
  "Camden County, Georgia, United States",
  "Greenwood, Delaware, United States",
  "Namurrua, Zambezia Province, Mozambique",
  "Caddo, Texas, United States",
  "357519, Pyatigorsk, Stavropol, Russia",
  "Greater Great Falls, MT Area",
  "Karlovac, Croatia",
  "R6W 4A6, Winkler, Manitoba, Canada",
  "5370-330, Mirandela, Bragança, Portugal",
  "385019, Maykop, Adygea, Russia",
  "Broscăuţi, Botoşani, Romania",
  "Mineralwells, West Virginia, United States",
  "Población Eduardo Yáñez Zavala, Valdivia, Los Ríos Region, Chile",
  "Goshen, Indiana, United States",
  "44090, Malatya, Malatya, Türkiye",
  "Simpson Cross, Wales, United Kingdom",
  "8006, Székesfehérvár, Fejér, Hungary",
  "694021, Korsakov, Sakhalin, Russia",
  "Simpson County, Mississippi, United States",
  "3123, Tønsberg, Vestfold og Telemark, Norway",
  "06570, Ankara, Ankara, Türkiye",
  "Randall Manor, New York, New York, United States",
  "Berezniki, Perm, Russia",
  "6042, Charleroi, Walloon Region, Belgium",
  "Marion, Indiana, United States",
  "Hennepin County, Minnesota, United States",
  "Vama, Suceava, Romania",
  "Cape Verde",
  "Dropull i Poshtëm, Gjirokastër, Albania",
  "Greater Leicester Area",
  "10245, Berlin, Berlin, Germany",
  "Jablonec n. Nisou, Liberec, Czechia",
  "Merrimack, New Hampshire, United States",
  "Ivanovo, Russia",
  "702 28, Örebro, Örebro County, Sweden",
  "Nancy, Grand Est, France",
  "K9J 0A1, Peterborough, Ontario, Canada",
  "León, Castilla and Leon, Spain",
  "Midland, Virginia, United States",
  "97007, Beaverton, Oregon, United States",
  "Bristol, Wisconsin, United States",
  "Fannin County, Texas, United States",
  "41006, Seville, Andalusia, Spain",
  "Kaufmannsommer, Lindlar, North Rhine-Westphalia, Germany",
  "Makanza, Équateur, Democratic Republic of the Congo",
  "Chistopol'skiy, Novosibirsk, Russia",
  "185009, Petrozavodsk, Karelia, Russia",
  "Calumet City, Illinois, United States",
  "Vialars, Laramière, Occitanie, France",
  "95233, Avery, California, United States",
  "Ripley Springs, Egham, England, United Kingdom",
  "La Sarine, Fribourg, Switzerland",
  "2031 BE, Haarlem, North Holland, Netherlands",
  "9024, Tromsø, Troms og Finnmark, Norway",
  "Nerchinskiy Zavod, Zabaykalsky Krai, Russia",
  "Midland, Michigan, United States",
  "The Pines of Cabarrus, Cooks Crossing, North Carolina, United States",
  "85254, Scottsdale, Arizona, United States",
  "141595, Solnechnogorsk, Moscow, Russia",
  "84501, Price, Utah, United States",
  "Fermoy, County Cork, Ireland",
  "29728, Pageland, South Carolina, United States",
  "DL2 2LU, Walworth, England, United Kingdom",
  "82, Benevento, Campania, Italy",
  "7973, Rørvik, Trøndelag, Norway",
  "Bilibino, Chukot, Russia",
  "37774, Loudon, Tennessee, United States",
  "Danielskuil, Northern Cape, South Africa",
  "Vanceburg, Kentucky, United States",
  "Keith Inch, Peterhead, Scotland, United Kingdom",
  "Northshore, Rockwall, Texas, United States",
  "Şavşat, Artvin, Türkiye",
  "Muisne Canton, Esmeraldas, Ecuador",
  "Khamīs Mushayţ, 'Asir, Saudi Arabia",
  "Boudous, Chélan, Occitanie, France",
  "430066, Baia Mare, Maramureş, Romania",
  "141303, Sergiyev Posad, Moscow, Russia",
  "Taylor County, West Virginia, United States",
  "Amapá, Brazil",
  "L10G1F6, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Partizanskiy, Minsk, Belarus",
  "Departamento General San Martín, Córdoba, Argentina",
  "X38F9K8, Kentau, Turkistan Region, Kazakhstan",
  "236039, Kaliningrad, Kaliningrad, Russia",
  "4405, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "49855, Marquette, Michigan, United States",
  "24184, Wirtz, Virginia, United States",
  "Woodbriar Estates, Gilmer, North Carolina, United States",
  "ZE2 9PL, Sandness, Scotland, United Kingdom",
  "Woodward Reach, Richmond, British Columbia, Canada",
  "58482, Steele, North Dakota, United States",
  "Howard, Ohio, United States",
  "Caroline Crossing, Queensland, Australia",
  "507 44, Borås, Västra Götaland County, Sweden",
  "Tishomingo, Oklahoma, United States",
  "Harmony, Minnesota, United States",
  "56122, Pisa, Tuscany, Italy",
  "Muğla, Türkiye",
  "Racine, Minnesota, United States",
  "369011, Cherkessk, Karachay-Cherkessia, Russia",
  "Gatesville, North Carolina, United States",
  "64870, Webb City, Missouri, United States",
  "8921 KD, Leeuwarden, Friesland, Netherlands",
  "2302, Hamar, Innlandet, Norway",
  "Nelson, Nelson, New Zealand",
  "Simi Valley, California, United States",
  "Hidalgo, Texas, United States",
  "Fulton, Mississippi, United States",
  "98396, Wilkeson, Washington, United States",
  "SS2 6TQ, Southend-On-Sea, England, United Kingdom",
  "Ust’-Abakan, Khakassia, Russia",
  "5519, Haugesund, Rogaland, Norway",
  "Elblag Metropolitan Area",
  "Socorro Cubao, Quezon City, National Capital Region, Philippines",
  "Greater Slavonski Brod Area",
  "Cheshire Center, Michigan, United States",
  "90-001, Łódź, Łódzkie, Poland",
  "457351, Kartaly, Chelyabinsk, Russia",
  "21704, Frederick, Maryland, United States",
  "3052, Vratsa, Vratsa, Bulgaria",
  "Mitchells Plain, Western Cape, South Africa",
  "Langlade, Brenoux, Occitanie, France",
  "428018, Cheboksary, Chuvashia, Russia",
  "Beaverton, Oregon, United States",
  "Albania",
  "East Koochiching, Minnesota, United States",
  "1097, Middelburg, Mpumalanga, South Africa",
  "220221, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "620150, Focşani, Vrancea, Romania",
  "690014, Vladivostok, Primorye, Russia",
  "461634, Buguruslan, Orenburg, Russia",
  "Genoa, Nevada, United States",
  "E28D0G7, Makhambet, Atyrau Region, Kazakhstan",
  "Harding, Manitoba, Canada",
  "Webster, New York, United States",
  "10410, Velika Gorica, Zagreb, Croatia",
  "Codru, Chișinău, Moldova",
  "423585, Nizhnekamsk, Tatarstan, Russia",
  "Amsterdam, North Holland, Netherlands",
  "Barbados",
  "96704, Captain Cook, Hawaii, United States",
  "Kimball, Nebraska, United States",
  "North Walsham, England, United Kingdom",
  "400005, Volgograd, Volgograd, Russia",
  "13003, Marseille, Provence-Alpes-Côte d'Azur, France",
  "238323, Gur'yevsk, Kaliningrad, Russia",
  "Ashtabula County, Ohio, United States",
  "Délégation Medjez El Bab, Béja, Tunisia",
  "Chase County, Nebraska, United States",
  "Morton, Illinois, United States",
  "2608, Lillehammer, Innlandet, Norway",
  "632327, Barabinsk, Novosibirsk, Russia",
  "Manchester, Kentucky, United States",
  "29015, Pyrohivtsi, Khmelnytsky, Ukraine",
  "Khabarovsky District, Khabarovsk, Russia",
  "Blandevar, India",
  "Redwood Valley, Tasman, New Zealand",
  "169849, Inta, Komi, Russia",
  "Twin Lakes Estates, Umatilla, Florida, United States",
  "04277, Leipzig, Saxony, Germany",
  "Ghana",
  "Redwood City, California, United States",
  "98124, Messina, Sicily, Italy",
  "21090, Diyarbakır, Diyarbakır, Türkiye",
  "152622, Otradnyy, Yaroslavl’, Russia",
  "83026, Rosenheim, Bavaria, Germany",
  "Partizansky District, Primorye, Russia",
  "Boone County, Missouri, United States",
  "Surry, Maine, United States",
  "37382, Morrison, Tennessee, United States",
  "Sandoval, Illinois, United States",
  "Lyman, New Hampshire, United States",
  "Curryville, Missouri, United States",
  "611 37, Nyköping, Södermanland County, Sweden",
  "Kurgan, Kurgan, Russia",
  "Smith County, Mississippi, United States",
  "Bobovdol, Kyustendil, Bulgaria",
  "Dallas County, Alabama, United States",
  "62090, Venice, Illinois, United States",
  "Bran, Braşov, Romania",
  "91413, Trousdale Estates, California, United States",
  "Great Northern, North Olmsted, Ohio, United States",
  "13008, Marseille, Provence-Alpes-Côte d'Azur, France",
  "Lowndesboro, Alabama, United States",
  "Bonlanden, Baden-Württemberg, Germany",
  "Maribor Metropolitan Area",
  "Campbelltown, South Australia, Australia",
  "South Carolina, United States",
  "Bodrum, Muğla, Türkiye",
  "211416, Polatsk, Vitsyebsk, Belarus",
  "Phelps, Springfield, Missouri, United States",
  "620176, Focşani, Vrancea, Romania",
  "Jackson County, North Carolina, United States",
  "41615, Konotop, Sumy, Ukraine",
  "Nubarashen, Yerevan, Armenia",
  "New Ulm, Texas, United States",
  "DG1 4FD, Dumfries, Scotland, United Kingdom",
  "B55A4G6, Zharkent, Jetisu Region, Kazakhstan",
  "Lower Chautauqua, Boulder, Colorado, United States",
  "23067, Louisa, Virginia, United States",
  "75-400, Koszalin, Zachodniopomorskie, Poland",
  "Thayer County, Nebraska, United States",
  "26080, Eskişehir, Eskişehir, Türkiye",
  "550315, Sibiu, Sibiu, Romania",
  "Potiretama, Ceará, Brazil",
  "Shelby, Ohio, United States",
  "Sweetwater Downs, Florida, United States",
  "309974, Valuyki, Belgorod, Russia",
  "Rotterdam and The Hague",
  "20905, Silver Spring, Maryland, United States",
  "29016, Málaga, Andalusia, Spain",
  "00100, Helsinki, Uusimaa, Finland",
  "Shumen, Bulgaria",
  "NE47 9PP, Allendale Town, England, United Kingdom",
  "1140, Brussels, Brussels Region, Belgium",
  "59103, Billings, Montana, United States",
  "Hickman County, Tennessee, United States",
  "Laayoune Province, Laâyoune-Sakia El Hamra, Morocco",
  "V92 WR6T, Tralee, County Kerry, Ireland",
  "9392, Finnsnes, Troms og Finnmark, Norway",
  "Greater Adapazarı",
  "185035, Petrozavodsk, Karelia, Russia",
  "V0G 1K0, Fauquier, British Columbia, Canada",
  "471, Ísafjörður, Westfjords, Iceland",
  "28609, Catawba, North Carolina, United States",
  "Pepin County, Wisconsin, United States",
  "Johnston County, Oklahoma, United States",
  "20717, Bowie, Maryland, United States",
  "Wilson, Michigan, United States",
  "3917, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Codnor, Ripley, England, United Kingdom",
  "78-400, Szczecinek, Zachodniopomorskie, Poland",
  "Svobodnyy, Bashkortostan, Russia",
  "Sodankylä, Lapland, Finland",
  "Samsun, Türkiye",
  "Kozloduy, Vratsa, Bulgaria",
  "5600, Troyan, Lovech, Bulgaria",
  "Windham, Ohio, United States",
  "Zalău, Sălaj, Romania",
  "Krasnoyarsk Krai, Russia",
  "446013, Syzran, Samara, Russia",
  "S13E6T5, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Leninsk-Kuznetskiy, Kemerovo, Russia",
  "Macon, North Carolina, United States",
  "57401, Aberdeen, South Dakota, United States",
  "810482, Brăila, Brăila, Romania",
  "364043, Groznyy, Chechnya, Russia",
  "53920, Lappeenranta, South Karelia, Finland",
  "675014, Blagoveshchensk, Amur, Russia",
  "Cambria, Illinois, United States",
  "95620, Dixon, California, United States",
  "694090, Dolinsk, Sakhalin, Russia",
  "CV31 1FQ, Radford Semele, England, United Kingdom",
  "Yaroslavl, Yaroslavl’, Russia",
  "Baia Mare, Maramureş, Romania",
  "Sheridan, Arkansas, United States",
  "Gregory, Blouberg, Limpopo, South Africa",
  "357563, Pyatigorsk, Stavropol, Russia",
  "Western Slope, Mundelein, Illinois, United States",
  "8313, Peshkopi, Dibrë, Albania",
  "142452, Noginsk, Moscow, Russia",
  "Winnebago County, Illinois, United States",
  "Tillman Hall, Peachtree Corners, Georgia, United States",
  "Holt County, Missouri, United States",
  "04673, Sargentville, Maine, United States",
  "6009, Gjirokastër, Gjirokastër, Albania",
  "A91 NR44, Dundalk, County Louth, Ireland",
  "433510, Dimitrovgrad, Ul’yanovsk, Russia",
  "Chasetown, Burntwood, England, United Kingdom",
  "Kudrinskaya, Kaluga, Russia",
  "Schaffhausen, Switzerland",
  "Qendër Tepelenë, Gjirokastër, Albania",
  "Pertek, Tunceli, Türkiye",
  "665730, Bratsk, Irkutsk, Russia",
  "Karaman, Karaman, Türkiye",
  "21624, Claiborne, Maryland, United States",
  "Gubakha, Perm, Russia",
  "Mahoning, Pennsylvania, United States",
  "Bogoroditsky District, Tula, Russia",
  "369004, Cherkessk, Karachay-Cherkessia, Russia",
  "52321, Onslow, Iowa, United States",
  "Cherry Tree, Pennsylvania, United States",
  "Kütahya, Türkiye",
  "Trippul East, County Kerry, Ireland",
  "89123, Reggio di Calabria, Calabria, Italy",
  "8014 ZN, Zwolle, Overijssel, Netherlands",
  "Fort Laramie, Wyoming, United States",
  "3009, Elbasan, Elbasan, Albania",
  "S21 3YA, Spinkhill, England, United Kingdom",
  "Kise Mill Bridge Historic District, Newberry, Pennsylvania, United States",
  "582 22, Linköping, Östergötland County, Sweden",
  "4370, Egersund, Rogaland, Norway",
  "346930, Novoshakhtinsk, Rostov, Russia",
  "Severo-Yeniseyskiy, Krasnoyarsk Krai, Russia",
  "Klamath, California, United States",
  "Eureka, Montana, United States",
  "Carteret County, North Carolina, United States",
  "75140, Grand Saline, Texas, United States",
  "347042, Belaya Kalitva, Rostov, Russia",
  "Midyat Bucağı, Mardin, Türkiye",
  "84124, Salerno, Campania, Italy",
  "Richmond County, Georgia, United States",
  "Sapareva Banya, Kyustendil, Bulgaria",
  "Wexford, County Wexford, Ireland",
  "Sutton, West Virginia, United States",
  "142108, Podolsk, Moscow, Russia",
  "Kirovohrad, Ukraine",
  "Braga, Rio Grande do Sul, Brazil",
  "Comodoro, Mato Grosso, Brazil",
  "301670, Novomoskovsk, Tula, Russia",
  "Astrakhanka, North Kazakhstan Region, Kazakhstan",
  "Upton, Torquay, England, United Kingdom",
  "SA69 9BP, Saundersfoot, Wales, United Kingdom",
  "424006, Yoshkar-Ola, Mari El, Russia",
  "02341, Hanson, Massachusetts, United States",
  "F94 X5Y9, Donegal, County Donegal, Ireland",
  "Stephens County, Oklahoma, United States",
  "Colleton, Chulmleigh, England, United Kingdom",
  "Pamlico Shores, North Carolina, United States",
  "100010, Ploieşti, Prahova, Romania",
  "84037, Kaysville, Utah, United States",
  "8007, Székesfehérvár, Fejér, Hungary",
  "66281, Tulungagung, East Java, Indonesia",
  "Otero, Galicia, Spain",
  "Calhoun, Kentucky, United States",
  "Kearneystown Lower, County Kildare, Ireland",
  "29360, Laurens, South Carolina, United States",
  "67190, Guadalupe, Nuevo León, Mexico",
  "10117, Tallinn, Harjumaa, Estonia",
  "Miras, Korçë, Albania",
  "632665, Chistopol’ye, Novosibirsk, Russia",
  "Belknap, Illinois, United States",
  "7100-532, Estremoz, Évora, Portugal",
  "NR1 1RW, Norwich, England, United Kingdom",
  "674600, Borzya, Zabaykalsky Krai, Russia",
  "Burt County, Nebraska, United States",
  "Buffalo, Texas, United States",
  "Fallon County, Montana, United States",
  "North Huntingdon, Pennsylvania, United States",
  "83451, Teton, Idaho, United States",
  "Brookesmith, Texas, United States",
  "45145, Essen, North Rhine-Westphalia, Germany",
  "02200, Adıyaman, Adıyaman, Türkiye",
  "Kukoboy, Yaroslavl’, Russia",
  "24608, Tazewell, Virginia, United States",
  "Atlantic Beach, New York, United States",
  "39842, Dawson, Georgia, United States",
  "Sweetwater, Peace River D, British Columbia, Canada",
  "654063, Novokuznetsk, Kemerovo, Russia",
  "Grantham, England, United Kingdom",
  "29053, Gaston, South Carolina, United States",
  "Meigs, Georgia, United States",
  "Ballysimon (Dickson), Limerick, County Limerick, Ireland",
  "Allen, Río Negro Province, Argentina",
  "Wardha district, India",
  "Marlboro, New York, United States",
  "452602, Oktyabrsky, Bashkortostan, Russia",
  "33028, Rivne, Rivne, Ukraine",
  "Fort Wayne, Indiana, United States",
  "29302, Spartanburg, South Carolina, United States",
  "Todd, Pennsylvania, United States",
  "3943, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "75011, Paris, Île-de-France, France",
  "238315, Gur'yevsk, Kaliningrad, Russia",
  "Atascosa County, Texas, United States",
  "8313, Svolvær, Nordland, Norway",
  "03004, Alicante, Valencian Community, Spain",
  "Antwerp Metropolitan Area",
  "Sherman County, Oregon, United States",
  "19131, Philadelphia, Pennsylvania, United States",
  "Barton Hill, Bristol, England, United Kingdom",
  "7401, Ersekë, Korçë, Albania",
  "06180, Badajoz, Extremadura, Spain",
  "32215, Čačak, Centralna Srbija, Serbia",
  "Nevada, Missouri, United States",
  "Morton Grove, Illinois, United States",
  "Bank Quay, Warrington, England, United Kingdom",
  "LS1 1BA, Leeds, England, United Kingdom",
  "8012, Bodø, Nordland, Norway",
  "678906, Aldan, Sakha, Russia",
  "21206, Baltimore, Maryland, United States",
  "Unterägeri, Zug, Switzerland",
  "Svendborg Municipality, Region of Southern Denmark, Denmark",
  "Lancaster County, South Carolina, United States",
  "59758, West Yellowstone, Montana, United States",
  "Kaliningrad, Russia",
  "18146, Rostock, Mecklenburg-West Pomerania, Germany",
  "37920, Knoxville, Tennessee, United States",
  "Whiteside County, Illinois, United States",
  "461121, Kirsanovka, Orenburg, Russia",
  "LV-4628, Briuci, Rēzekne Municipality, Latvia",
  "Berkeley, California, United States",
  "Les Converses, La Clusaz, Auvergne-Rhône-Alpes, France",
  "16923, Genesee, Pennsylvania, United States",
  "Hood Manor, Warrington, England, United Kingdom",
  "Bland, Missouri, United States",
  "731191, Bârlad, Vaslui, Romania",
  "37641, Limestone, Tennessee, United States",
  "İçel, Türkiye",
  "Bardejov, Presov, Slovakia",
  "20305, Narva, Ida-Virumaa, Estonia",
  "35007, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Rodna, Bistriţa-Năsăud, Romania",
  "Sharon Hill, Pennsylvania, United States",
  "La Bertie, St.-Yrieix-la-Perche, Nouvelle-Aquitaine, France",
  "45883, St Henry, Ohio, United States",
  "S8 0AJ, Sheffield, England, United Kingdom",
  "TS9 5JZ, Stokesley, England, United Kingdom",
  "85331, Cave Creek, Arizona, United States",
  "Bettendorf, Diekirch, Luxembourg",
  "37409, Tarazona de Guareña, Castilla and Leon, Spain",
  "Pulai, Johore, Malaysia",
  "Basque Country, Spain",
  "9500-310, Ponta Delgada, Portugal",
  "Fort McMurray, Alberta, Canada",
  "41-902, Bytom, Śląskie, Poland",
  "76300, Sotteville-lès-Rouen, Normandy, France",
  "15897, Santiago de Compostela, Galicia, Spain",
  "Greater Dresden Area",
  "Grachëvskiy, Stavropol, Russia",
  "735200, Negrești, Vaslui, Romania",
  "Greater Oslo Region",
  "Arnhem, Gelderland, Netherlands",
  "02138, Cambridge, Massachusetts, United States",
  "Greater Szombathely Area",
  "Yegoryevsky District, Altai Krai, Russia",
  "53200, Nikopol, Dnipropetrovsk, Ukraine",
  "Menard County, Texas, United States",
  "163055, Amderma, Nenets, Russia",
  "Stevenson, Alabama, United States",
  "636463, Kolpashevo, Tomsk, Russia",
  "56229, Cottonwood, Minnesota, United States",
  "76200, Dieppe, Normandy, France",
  "200144, Craiova, Dolj, Romania",
  "Union County, Mississippi, United States",
  "La Luzerne, Trévières, Normandy, France",
  "18700, Cankiri, Çankırı, Türkiye",
  "5900, Middelburg, Eastern Cape, South Africa",
  "M28C1H6, Aqtau, Karaganda Region, Kazakhstan",
  "Novotroitske, Donetsk, Ukraine",
  "34151, Trieste, Friuli-Venezia Giulia, Italy",
  "Troitskaya, Krasnodar, Russia",
  "Garvín, Extremadura, Spain",
  "Tipton County, Tennessee, United States",
  "General Luna, Caraga, Philippines",
  "L10F4F1, Chapaev, West Kazakhstan Region, Kazakhstan",
  "369014, Cherkessk, Karachay-Cherkessia, Russia",
  "Lamourette, La Cellette, Auvergne-Rhône-Alpes, France",
  "357101, Nevinnomyssk, Stavropol, Russia",
  "Bologoye, Omsk, Russia",
  "Hünenberg, Zug, Switzerland",
  "Howell, England, United Kingdom",
  "85328, Cibola, Arizona, United States",
  "Cartagena, Bolívar, Colombia",
  "453101, Sterlitamak, Bashkortostan, Russia",
  "Thomasville, Pennsylvania, United States",
  "26121, Oldenburg, Lower Saxony, Germany",
  "28115, Mooresville, North Carolina, United States",
  "10-437, Olsztyn, Warmińsko-mazurskie, Poland",
  "Aleysk, Altai Krai, Russia",
  "Aleksinskoye, Smolensk, Russia",
  "McCulloch County, Texas, United States",
  "Smyadovo Municipality, Shumen, Bulgaria",
  "Berlin, Berlin, Germany",
  "Battle, England, United Kingdom",
  "86-312, Grudziądz, Kujawsko-pomorskie, Poland",
  "75432, Cooper, Texas, United States",
  "42344, Zimapán, Hidalgo, Mexico",
  "453309, Kumertau, Bashkortostan, Russia",
  "398059, Lipetsk, Lipetsk, Russia",
  "6001, Kecskemét, Bács-Kiskun, Hungary",
  "94108, Klaipėda, Klaipėdos, Lithuania",
  "New Bandon Northumberland County, New Brunswick, Canada",
  "358013, Elista, Kalmykia, Russia",
  "North Albemarle, North Carolina, United States",
  "Limestone County, Texas, United States",
  "2232, Grays Point, New South Wales, Australia",
  "347821, Kamensk-Shakhtinskiy, Rostov, Russia",
  "Menardo, Monterosso Grana, Piedmont, Italy",
  "692503, Ussuriysk, Primorye, Russia",
  "McCormick Mile, Ocean Ridge, Florida, United States",
  "Garfield, Michigan, United States",
  "North Shore Country Club, Lake Oswego, Oregon, United States",
  "41-905, Bytom, Śląskie, Poland",
  "Armstrong, Illinois, United States",
  "Anderson, Indiana, United States",
  "Tyler County, Texas, United States",
  "Caió, Cacheu Region, Guinea-Bissau",
  "06620, Le Bar-sur-Loup, Provence-Alpes-Côte d'Azur, France",
  "Weberhof, Schönecken, Rhineland-Palatinate, Germany",
  "Hickory, Kentucky, United States",
  "Greenwich, Connecticut, United States",
  "Kanem, Kanem, Chad",
  "Chervonohrad Raion, Lviv, Ukraine",
  "130061, Târgovişte, Dîmboviţa, Romania",
  "KA18 2AT, Ochiltree, Scotland, United Kingdom",
  "Sumter County, Alabama, United States",
  "St-Georges, Quebec, Canada",
  "65791, Thayer, Missouri, United States",
  "00685, San Sebastian, Puerto Rico",
  "731020, Bârlad, Vaslui, Romania",
  "164512, Severodvinsk, Arkhangel’sk, Russia",
  "86-306, Grudziądz, Kujawsko-pomorskie, Poland",
  "Bilibinsky District, Chukot, Russia",
  "Chambray-lès-Tours, Centre-Val de Loire, France",
  "97355, Macouria, Cayenne, French Guiana",
  "35012, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "656038, Barnaul, Altai Krai, Russia",
  "676856, Belogorsk, Amur, Russia",
  "B55E3C6, Zharkent, Jetisu Region, Kazakhstan",
  "Saint Anne, Alderney, Guernsey",
  "60600, Clermont, Hauts-de-France, France",
  "302 52, Halmstad, Halland County, Sweden",
  "07420, Haskell, New Jersey, United States",
  "Nueces, Corpus Christi, Texas, United States",
  "Turners Falls, Massachusetts, United States",
  "78377, Refugio, Texas, United States",
  "Albufeira, Faro, Portugal",
  "Môtiers, Neuchâtel, Switzerland",
  "Nizhniy Novgorod, Nizhniy Novgorod, Russia",
  "Putnam County, Tennessee, United States",
  "Harper, Kansas, United States",
  "Walnut Creek, California, United States",
  "Ayvacık, Çanakkale, Türkiye",
  "Smith County, Tennessee, United States",
  "8500-299, Portimão, Faro, Portugal",
  "43506, Bryan, Ohio, United States",
  "682951, Vyazemskiy, Khabarovsk, Russia",
  "60-681, Poznan, Wielkopolskie, Poland",
  "24944, Flensburg, Schleswig-Holstein, Germany",
  "6407, Këlcyrë, Gjirokastër, Albania",
  "DD2 1SG, Dundee, Scotland, United Kingdom",
  "4815, Arendal, Agder, Norway",
  "Moscow, Pennsylvania, United States",
  "Greater Gijón Metropolitan Area",
  "9611, Hammerfest, Troms og Finnmark, Norway",
  "Zavalza, Durango, Mexico",
  "457657, Severnyy, Chelyabinsk, Russia",
  "99850, Juneau, Alaska, United States",
  "426063, Izhevsk, Udmurtia, Russia",
  "14005, Chernihiv, Chernihiv, Ukraine",
  "13009, Marseille, Provence-Alpes-Côte d'Azur, France",
  "Chaykovskiy, Perm, Russia",
  "Lawrenceburg, Tennessee, United States",
  "North Bennington, Vermont, United States",
  "90630, Oulu, North Ostrobothnia, Finland",
  "60053, Morton Grove, Illinois, United States",
  "677010, Yakutsk, Sakha, Russia",
  "21310, Omiš, Split-Dalmatia, Croatia",
  "6200-388, Covilhã, Castelo Branco, Portugal",
  "Jaén, Andalusia, Spain",
  "Meshtitsa, Pernik, Bulgaria",
  "73, Lecce, Apulia, Italy",
  "52169, Wadena, Iowa, United States",
  "07901, Summit, New Jersey, United States",
  "E28D5P3, Makhambet, Atyrau Region, Kazakhstan",
  "Moselle, Grand Est, France",
  "182660, Borovichi, Pskov, Russia",
  "Salem, Ohio, United States",
  "Prestonsburg, Kentucky, United States",
  "08027, Barcelona, Catalonia, Spain",
  "8905, Zalaegerszeg, Zala, Hungary",
  "HU6 9RW, Kingston Upon Hull, England, United Kingdom",
  "Braxton Square, Georgia, United States",
  "Antioch, Tennessee, United States",
  "Pirovac, Sibenik-Knin, Croatia",
  "Erzincan, Türkiye",
  "Wyoming, Ontario, Canada",
  "652323, Topki, Kemerovo, Russia",
  "00164, North Beltrami, Minnesota, United States",
  "L-8551, Beckerich, Diekirch, Luxembourg",
  "Saint-Étienne-du-Grès, Provence-Alpes-Côte d'Azur, France",
  "41-923, Bytom, Śląskie, Poland",
  "Southern Albemarle Rural Historic District, United States",
  "Beaufort, Sabah, Malaysia",
  "90510, Haapsalu, Laane, Estonia",
  "Cooley Highlands, Gallatin, Missouri, United States",
  "Tallinn, Harjumaa, Estonia",
  "Whitman, Midland, Texas, United States",
  "67058, Harper, Kansas, United States",
  "Rostov, Russia",
  "Walker, Missouri, United States",
  "Greater Glasgow Area",
  "Menominee County, Michigan, United States",
  "Hardy, Iowa, United States",
  "Caldas da Rainha, Leiria, Portugal",
  "5022, Namur, Walloon Region, Belgium",
  "Abbeville, Vaudeloges, Normandy, France",
  "Scioto County, Ohio, United States",
  "McLennan, Alberta, Canada",
  "Saint-Caprais-de-Bordeaux, Nouvelle-Aquitaine, France",
  "Pickaway, Ohio, United States",
  "14706, Allegany, New York, United States",
  "Mitchell County, North Carolina, United States",
  "Woodstock, Illinois, United States",
  "Pacific Beach, Washington, United States",
  "309290, Shebekino, Belgorod, Russia",
  "Barnes City, Iowa, United States",
  "São Bernardo do Campo, São Paulo, Brazil",
  "North Burnett, Queensland, Australia",
  "Härnösands kommun, Västernorrland County, Sweden",
  "Escaldes-Engordany, Escaldes-Engordany, Andorra",
  "04229, Leipzig, Saxony, Germany",
  "662543, Lesosibirsk, Krasnoyarsk Krai, Russia",
  "627312, Golyshmanovo, Tyumen’, Russia",
  "69100, Villeurbanne, Auvergne-Rhône-Alpes, France",
  "Bonnyrigg, Scotland, United Kingdom",
  "Potter Valley, California, United States",
  "225547, Kapani, Brest, Belarus",
  "79930-959, Aral Moreira, Mato Grosso do Sul, Brazil",
  "Lake Oswego, Oregon, United States",
  "Grayson County, Virginia, United States",
  "31765, Meigs, Georgia, United States",
  "South Burnett, Queensland, Australia",
  "359063, Chapayevskoye, Rostov, Russia",
  "MK18 1JA, Buckingham, England, United Kingdom",
  "LU2 8TD, Luton, England, United Kingdom",
  "2011 AA, Haarlem, North Holland, Netherlands",
  "362040, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "27105, Winston-Salem, North Carolina, United States",
  "R0C 1S0, Stonewall, Manitoba, Canada",
  "Pepino, Castile-La Mancha, Spain",
  "Oslo, Norway",
  "Sneads Ferry, North Carolina, United States",
  "Houston County, Minnesota, United States",
  "403536, Frolovo, Volgograd, Russia",
  "0470032, Otaru, Hokkaido, Japan",
  "Mifflinville, Pennsylvania, United States",
  "Payneham, South Australia, Australia",
  "Greater Campobasso Metropolitan Area",
  "2324, Hamar, Innlandet, Norway",
  "Yadkinville, North Carolina, United States",
  "Sutton Bridge, England, United Kingdom",
  "LU1 3JU, Luton, England, United Kingdom",
  "Umbakumba, Northern Territory, Australia",
  "40138, Bologna, Emilia-Romagna, Italy",
  "Barnwellstown, County Meath, Ireland",
  "Bennett, Iowa, United States",
  "49685, Traverse City, Michigan, United States",
  "70592, Youngsville, Louisiana, United States",
  "80-462, Gdańsk, Pomorskie, Poland",
  "393360, Kirsanov, Tambov, Russia",
  "633205, Iskitim, Novosibirsk, Russia",
  "Phillipsburg, Missouri, United States",
  "Port Gibson, Mississippi, United States",
  "Mosul, Nineveh Governorate, Iraq",
  "Sauk Rapids, Minnesota, United States",
  "Pickaway Rural Historic District, Pickaway, West Virginia, United States",
  "Radnevo, Stara Zagora, Bulgaria",
  "791 47, Falun, Dalarna County, Sweden",
  "Central Pennington, South Dakota, United States",
  "Atlantic County, New Jersey, United States",
  "Le Roseau, Château-Larcher, Nouvelle-Aquitaine, France",
  "31082, Sandersville, Georgia, United States",
  "Brazos Bend, Texas, United States",
  "79366, Ransom Canyon, Texas, United States",
  "47036, Oldenburg, Indiana, United States",
  "71960, Port Dickson, Negri Sembilan, Malaysia",
  "Kirensky District, Irkutsk, Russia",
  "Jasper, Tennessee, United States",
  "8073, Feldkirchen bei Graz, Styria, Austria",
  "Tikhvinsky District, Leningrad, Russia",
  "75082, Richardson, Texas, United States",
  "Armavir, Armavir Province, Armenia",
  "Sterling, Kansas, United States",
  "9500-700, Ponta Delgada, Portugal",
  "Williamstown, New Jersey, United States",
  "Marshall, Michigan, United States",
  "Eugene, Oregon, United States",
  "Erie, Michigan, United States",
  "30326, Kohtla-Järve, Ida-Virumaa, Estonia",
  "17603, Lancaster, Pennsylvania, United States",
  "9500-026, Ponta Delgada, Portugal",
  "Chambers, Greater Napanee, Ontario, Canada",
  "Breznik, Pernik, Bulgaria",
  "633520, Cherepanovo, Novosibirsk, Russia",
  "84749, Lyman, Utah, United States",
  "Bol’shevik, Magadan, Russia",
  "160034, Vologda, Vologda, Russia",
  "309261, Shebekino, Belgorod, Russia",
  "9302, Finnsnes, Troms og Finnmark, Norway",
  "Calumet County, Wisconsin, United States",
  "Thurstonfield, England, United Kingdom",
  "Big Sky Meadow Village, Montana, United States",
  "6962, Lugano, Ticino, Switzerland",
  "Bingöl, Bingöl, Türkiye",
  "Ashaiman Municipal District, Greater Accra Region, Ghana",
  "Redcar And Cleveland, England, United Kingdom",
  "CV7 7BW, Berkswell, England, United Kingdom",
  "Prince George, British Columbia, Canada",
  "Nashville, North Carolina, United States",
  "678725, Megino-Aldan, Sakha, Russia",
  "Embid de Ariza, Aragon, Spain",
  "Devanhalli, Karnataka, India",
  "Pyatigorsk, Stavropol, Russia",
  "685007, Magadan, Magadan, Russia",
  "8910, Zalaegerszeg, Zala, Hungary",
  "Stevensville, Michigan, United States",
  "76448, Eastland, Texas, United States",
  "79848, Sanderson, Texas, United States",
  "Pottawatomie County, Kansas, United States",
  "ZE1 0QN, Lerwick, Scotland, United Kingdom",
  "33394, Gijón, Principality of Asturias, Spain",
  "Warri, Delta State, Nigeria",
  "77581, Pearland, Texas, United States",
  "97801, Pendleton, Oregon, United States",
  "Morrison, Tennessee, United States",
  "25-150, Kielce, Świętokrzyskie, Poland",
  "Ivanovo, Ruse, Bulgaria",
  "Marlborough, New Zealand",
  "GU10 4LU, Bucks Horn Oak, England, United Kingdom",
  "Skadovsk, Kherson, Ukraine",
  "Ancón, Peru",
  "Vacaville, California, United States",
  "Pine Lake, Georgia, United States",
  "Steelend, Scotland, United Kingdom",
  "V0E 0A8, Armstrong, British Columbia, Canada",
  "McKinley, Muncie, Indiana, United States",
  "152191, Borisoglebskiy, Yaroslavl’, Russia",
  "Henderson, Kentucky, United States",
  "Stakhanov, Luhansk, Ukraine",
  "Dodgeville, Wisconsin, United States",
  "Williamsburg, Missouri, United States",
  "Crockett Trace, Texas, United States",
  "Mora, San Jose, Costa Rica",
  "Phelps, Kentucky, United States",
  "152912, Rybinsk, Yaroslavl’, Russia",
  "Woodford Green, England, United Kingdom",
  "98901, Yakima, Washington, United States",
  "607225, Arzamas, Nizhniy Novgorod, Russia",
  "KW15 1BB, Kirkwall, Scotland, United Kingdom",
  "17254, Chambersburg, Pennsylvania, United States",
  "Newberry, Fort Wayne, Indiana, United States",
  "18109, Rostock, Mecklenburg-West Pomerania, Germany",
  "6429, Molde, Møre og Romsdal, Norway",
  "49112, Dnipro, Dnipropetrovsk, Ukraine",
  "Gentio do Ouro, Bahia, Brazil",
  "Biggleswade, England, United Kingdom",
  "San Juan-Carolina Area",
  "BT1 1AA, Belfast, Northern Ireland, United Kingdom",
  "67705, Trippstadt, Rhineland-Palatinate, Germany",
  "Greifswald, Mecklenburg-West Pomerania, Germany",
  "7050-197, Montemor-o-Novo, Évora, Portugal",
  "Grays, England, United Kingdom",
  "Davier, Rannée, Brittany, France",
  "Latimer County, Oklahoma, United States",
  "Svishtov, Veliko Turnovo, Bulgaria",
  "Nolanbrook, Milford Mill, Maryland, United States",
  "94300, Kota Samarahan, Sarawak, Malaysia",
  "Greater Málaga Metropolitan Area",
  "Davier, Saint-Jean-sur-Erve, Pays de la Loire, France",
  "Holmes, Preston, England, United Kingdom",
  "665904, Slyudyanka, Irkutsk, Russia",
  "6227 EX, Maastricht, Limburg, Netherlands",
  "624595, Ivdel’, Sverdlovsk, Russia",
  "Hertford, England, United Kingdom",
  "BH11 8AA, Bournemouth, England, United Kingdom",
  "446031, Syzran, Samara, Russia",
  "Greater Melbourne Area",
  "Krakow, Wisconsin, United States",
  "97361, Monmouth, Oregon, United States",
  "Chervonohrad, Lviv, Ukraine",
  "48183, Trenton, Michigan, United States",
  "45775, Rutland, Ohio, United States",
  "347820, Kamensk-Shakhtinskiy, Rostov, Russia",
  "50-019, Wrocław, Dolnośląskie, Poland",
  "Greater Valencia Metropolitan Area",
  "Coles, Galicia, Spain",
  "Fanningstown, County Limerick, Ireland",
  "Midland Park, New Jersey, United States",
  "367018, Makhachkala, Dagestan, Russia",
  "Tamil Nadu, India",
  "Marshall, Illinois, United States",
  "28011, Madrid, Community of Madrid, Spain",
  "CV1 9GJ, Coventry, England, United Kingdom",
  "690087, Vladivostok, Primorye, Russia",
  "309289, Shebekino, Belgorod, Russia",
  "9411, Vlorë, Vlorë, Albania",
  "Scottsville, New York, United States",
  "Covington, Virginia, United States",
  "94062, Redwood City, California, United States",
  "420073, Kazan, Tatarstan, Russia",
  "Lindesbergs kommun, Örebro County, Sweden",
  "49525, Lengerich, North Rhine-Westphalia, Germany",
  "8618, Mo i Rana, Nordland, Norway",
  "Arrondissement of Hasselt, Flemish Region, Belgium",
  "357916, Zelenokumsk, Stavropol, Russia",
  "29115, Orangeburg, South Carolina, United States",
  "76133, Karlsruhe, Baden-Württemberg, Germany",
  "48469, Port Sanilac, Michigan, United States",
  "Hyderabad, Sindh, Pakistan",
  "Wilkeson, Washington, United States",
  "DeValls Bluff, Arkansas, United States",
  "156000, Kostroma, Kostroma, Russia",
  "53187, Waukesha, Wisconsin, United States",
  "50180, Mikkeli, South Savo, Finland",
  "Esil district, Astana, Kazakhstan",
  "G5R 0A1, Rivière-du-Loup, Quebec, Canada",
  "141507, Solnechnogorsk, Moscow, Russia",
  "Cuminge, Lavelanet, Occitanie, France",
  "3109, Salgótarján, Nógrád, Hungary",
  "Panola, Jawali, Himachal Pradesh, India",
  "Trumbull, Nebraska, United States",
  "Bandera County, Texas, United States",
  "Bell Gardens, California, United States",
  "82212, Fort Laramie, Wyoming, United States",
  "9013, Tromsø, Troms og Finnmark, Norway",
  "505800, Zărneşti, Braşov, Romania",
  "Oral Q.Ä., West Kazakhstan Region, Kazakhstan",
  "30328, Kohtla-Järve, Ida-Virumaa, Estonia",
  "Teton County, Wyoming, United States",
  "Wirthsmühle, Greding, Bavaria, Germany",
  "Nordics",
  "Silver Bow County, Montana, United States",
  "352520, Voznesenskaya, Krasnodar, Russia",
  "611 63, Nyköping, Södermanland County, Sweden",
  "Broadwater, Worthing, England, United Kingdom",
  "Novotroitskiy, Krasnoyarsk Krai, Russia",
  "Halluin, Hauts-de-France, France",
  "Manheim, Pennsylvania, United States",
  "624335, Krasnoural’sk, Sverdlovsk, Russia",
  "694009, Korsakov, Sakhalin, Russia",
  "Ishimbaysky District, Bashkortostan, Russia",
  "20841, Boyds, Maryland, United States",
  "62760, Emiliano Zapata, Morelos, Mexico",
  "682952, Vyazemskiy, Khabarovsk, Russia",
  "E1A 8W3, Dieppe, New Brunswick, Canada",
  "Greater Faro Area",
  "06009, Badajoz, Extremadura, Spain",
  "346422, Novocherkassk, Rostov, Russia",
  "Eastland Hills, Columbia Township, Missouri, United States",
  "76313, Rafḩah, Northern Frontier, Saudi Arabia",
  "Saratov Metropolitan Area",
  "Newport, Victoria, Australia",
  "453200, Ishimbay, Bashkortostan, Russia",
  "Spassk-Dal'niy, Primorye, Russia",
  "26241, Velyka Vyska, Kirovohrad, Ukraine",
  "Nyainrong County, Tibet, China",
  "453308, Kumertau, Bashkortostan, Russia",
  "628001, Khanty-Mansiysk, Khanty-Mansi, Russia",
  "70-413, Szczecin, Zachodniopomorskie, Poland",
  "Canchungo, Cacheu Region, Guinea-Bissau",
  "12003, Castellón de la Plana, Valencian Community, Spain",
  "Akçakent, Kırşehir, Türkiye",
  "Dawson Creek, British Columbia, Canada",
  "Cottondale, Alabama, United States",
  "IM1 1AF, Douglas, Douglas, Isle of Man",
  "Başyayla, Karaman, Türkiye",
  "Boyd, North Carolina, United States",
  "98147, Messina, Sicily, Italy",
  "665076, Polovino-Cheremkhovo, Irkutsk, Russia",
  "Overton, Wales, United Kingdom",
  "Şuhut, Afyon, Türkiye",
  "Converse County, Wyoming, United States",
  "97160, Le Moule, Pointe-à-Pitre, Guadeloupe",
  "Blue Earth County, Minnesota, United States",
  "34127, Trieste, Friuli-Venezia Giulia, Italy",
  "F18K3F9, Semey, Abay Region, Kazakhstan",
  "42349, Wuppertal, North Rhine-Westphalia, Germany",
  "74403, Muskogee, Oklahoma, United States",
  "Monte Giardino, San Marino",
  "78020, Karabuk, Karabuk, Türkiye",
  "Gorno-Altaisk, Altay, Russia",
  "33602, Bielefeld, North Rhine-Westphalia, Germany",
  "1016 HH, Amsterdam, North Holland, Netherlands",
  "66126, Saarbrücken, Saarland, Germany",
  "Brooksville, Florida, United States",
  "Saratoga, Arkansas, United States",
  "Büyükçekmece, Istanbul, Türkiye",
  "Carversville, Pennsylvania, United States",
  "Veradale, Spokane Valley, Washington, United States",
  "623110, Pervoural'sk, Sverdlovsk, Russia",
  "Intan Jaya Regency, Papua, Indonesia",
  "Hardeman County, Tennessee, United States",
  "Fluvanna, Texas, United States",
  "Condominios Las Nueces, Chihuahua, Mexico",
  "Nigeria",
  "Barcelos, Braga, Portugal",
  "Pécos, Ramouzens, Occitanie, France",
  "67715, Linares, Nuevo León, Mexico",
  "ME4 4AA, Chatham, England, United Kingdom",
  "80521, Fort Collins, Colorado, United States",
  "Barnesville, Minnesota, United States",
  "3529, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "29163, Vance, South Carolina, United States",
  "Carroll County, Tennessee, United States",
  "Weakley County, Tennessee, United States",
  "Ritchie, Northern Cape, South Africa",
  "Atlanta Metropolitan Area",
  "Bosque Farms, New Mexico, United States",
  "Pezinok, Bratislava, Slovakia",
  "Robertson, Wyoming, United States",
  "77967, Yoakum, Texas, United States",
  "Johnston County, North Carolina, United States",
  "Shu District, Jambyl Region, Kazakhstan",
  "Subate, Daugavpils, Latvia",
  "188304, Gatchina, Leningrad, Russia",
  "180019, Pskov, Pskov, Russia",
  "Chernivtsi, Ukraine",
  "N0B 1A0, Fergus, Ontario, Canada",
  "Petropavlovka, Buryatia, Russia",
  "Kendall, Florida, United States",
  "8020, Bodø, Nordland, Norway",
  "820190, Tulcea, Tulcea, Romania",
  "Tyndall, South Dakota, United States",
  "Marvao, Portalegre, Portugal",
  "Nance, Bourgogne-Franche-Comté, France",
  "Travis-Chelsea, New York, New York, United States",
  "O'Fallon, Illinois, United States",
  "Doorwerth, Gelderland, Netherlands",
  "H91 HRR9, Galway, County Galway, Ireland",
  "18439, Stralsund, Mecklenburg-West Pomerania, Germany",
  "Mathews, Louisiana, United States",
  "184604, Severomorsk, Murmansk, Russia",
  "Grafton, Massachusetts, United States",
  "69089, Dolinskoye, Zaporizhzhya, Ukraine",
  "13522, Tallinn, Harjumaa, Estonia",
  "Dewey Central, Hagerstown, Maryland, United States",
  "Old Seminole Heights, Tampa, Florida, United States",
  "Coal, Pennsylvania, United States",
  "Herkimer, New York, United States",
  "96052, Bamberg, Bavaria, Germany",
  "Borçka, Artvin, Türkiye",
  "56570, Doroshivka, Mykolayiv, Ukraine",
  "352385, Kropotkin, Krasnodar, Russia",
  "New Fribourg, Rio de Janeiro, Brazil",
  "Bahrain",
  "McKinleyville, California, United States",
  "Saratoga Springs, New York, United States",
  "Floyd, Virginia, United States",
  "Fairfax Station, Virginia, United States",
  "34500, Béziers, Occitanie, France",
  "Delta Junction, Alaska, United States",
  "Woodbury, New Jersey, United States",
  "Winchester, Nevada, United States",
  "PA15 4BY, Greenock, Scotland, United Kingdom",
  "Hansonville, Victoria, Australia",
  "Hanover, Illinois, United States",
  "450000, Ufa, Bashkortostan, Russia",
  "346914, Novoshakhtinsk, Rostov, Russia",
  "45677, Scioto Furnace, Ohio, United States",
  "J1T 2Z8, Asbestos, Quebec, Canada",
  "Saransk, Mordovia, Russia",
  "0470156, Otaru, Hokkaido, Japan",
  "624779, Verkhnyaya Salda, Sverdlovsk, Russia",
  "623425, Kamensk Uralskiy, Sverdlovsk, Russia",
  "Halmstad, Halland County, Sweden",
  "188309, Gatchina, Leningrad, Russia",
  "Monson, Massachusetts, United States",
  "Orange, California, United States",
  "33940, Mersin, İçel, Türkiye",
  "De Kalb County, Indiana, United States",
  "34284, Venice, Florida, United States",
  "Pinehurst, North Carolina, United States",
  "Garfield County, Colorado, United States",
  "167023, Syktyvkar, Komi, Russia",
  "Louisa, Virginia, United States",
  "301241, Shchëkino, Tula, Russia",
  "Durham, New York, United States",
  "Greater Ipswich Area",
  "9401 LE, Assen, Drenthe, Netherlands",
  "Gogolin, Kujawsko-pomorskie, Poland",
  "17933, Schuylkill Haven, Pennsylvania, United States",
  "Shannon, Georgia, United States",
  "Guadalupe, San Jose, Costa Rica",
  "19250-000, Sandovalina, São Paulo, Brazil",
  "KA18 2PR, Ochiltree, Scotland, United Kingdom",
  "R25A4C7, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "Thomaston, Maine, United States",
  "Rzeszów, Podkarpackie, Poland",
  "21203, Novi Sad, Vojvodina, Serbia",
  "403888, Kamyshin, Volgograd, Russia",
  "66273, Tulungagung, East Java, Indonesia",
  "65-410, Zielona Góra, Lubuskie, Poland",
  "Fife Keith, Keith, Scotland, United Kingdom",
  "Bitola, Pelagonia, North Macedonia",
  "Kenton County, Kentucky, United States",
  "Emery, Wisconsin, United States",
  "22314, Alexandria, Virginia, United States",
  "30120, Cartersville, Georgia, United States",
  "78638, Kingsbury, Texas, United States",
  "65185, Wiesbaden, Hesse, Germany",
  "Oglala Lakota County, South Dakota, United States",
  "Coalhurst, Alberta, Canada",
  "624220, Nizhnyaya Tura, Sverdlovsk, Russia",
  "50760, Nevsehir, Nevşehir, Türkiye",
  "9500, Ponta Delgada, Portugal",
  "Donetsk Metropolitan Area",
  "Kemerovo, Russia",
  "27300, Gaziantep, Gaziantep, Türkiye",
  "Lysychanska miska hromada, Luhansk, Ukraine",
  "Plaški, Karlovac, Croatia",
  "7621, Pécs, Baranya, Hungary",
  "10126, Turin, Piedmont, Italy",
  "Vila de Rei, Castelo Branco, Portugal",
  "24301, Pulaski, Virginia, United States",
  "Cherryvale, Kansas, United States",
  "Blair, Oklahoma, United States",
  "Nilüfer, Bursa, Türkiye",
  "Brighton, New York, United States",
  "Marseille, Provence-Alpes-Côte d'Azur, France",
  "Hidalgo, Michoacán, Mexico",
  "Otter Rock, Oregon, United States",
  "Mason, West Virginia, United States",
  "Saint-Léger-sur-Roanne, Auvergne-Rhône-Alpes, France",
  "Moraga, California, United States",
  "39116, Magdeburg, Saxony-Anhalt, Germany",
  "Avery County, North Carolina, United States",
  "37923, Knoxville, Tennessee, United States",
  "Powder Springs, Georgia, United States",
  "Maury, Miossens-Lanusse, Nouvelle-Aquitaine, France",
  "678145, Lensk, Sakha, Russia",
  "Cabecera del Llano, Comuna 12 - Cabecera del Llano, Santander, Colombia",
  "Chernyshevsk, Zabaykalsky Krai, Russia",
  "45122, Goshen, Ohio, United States",
  "Čadca, Zilina, Slovakia",
  "Montpelier, Vermont, United States",
  "LE1 2AA, Leicester, England, United Kingdom",
  "63826, Steele, Missouri, United States",
  "Royston, England, United Kingdom",
  "Amsterdam-West, North Holland, Netherlands",
  "Jonesboro, Louisiana, United States",
  "368614, Derbent, Dagestan, Russia",
  "399265, Yelets-Malanino, Lipetsk, Russia",
  "Choctaw County, Mississippi, United States",
  "6211 ND, Maastricht, Limburg, Netherlands",
  "Canton of Bastia-4, Corsica, France",
  "Lachen, Schwyz, Switzerland",
  "Mitchell, Ontario, Canada",
  "29803, Aiken, South Carolina, United States",
  "35750, İzmir, İzmir, Türkiye",
  "661, Saarbrücken, Saarland, Germany",
  "Norman, Oklahoma, United States",
  "Cluj-Napoca, Cluj, Romania",
  "Seminole, Texas, United States",
  "25-640, Kielce, Świętokrzyskie, Poland",
  "Phillips, Minneapolis, Minnesota, United States",
  "90507, Haapsalu, Laane, Estonia",
  "Greater Troyes Area",
  "42717, Burkesville, Kentucky, United States",
  "Otradnyy, Yaroslavl’, Russia",
  "694914, Uglegorsk, Sakhalin, Russia",
  "40-008, Katowice, Śląskie, Poland",
  "410389, Oradea, Bihor, Romania",
  "5525, Haugesund, Rogaland, Norway",
  "Pontotoc County, Oklahoma, United States",
  "49329, Howard City, Michigan, United States",
  "43614, Toledo, Ohio, United States",
  "Hudiksvalls kommun, Gävleborg County, Sweden",
  "44-121, Gliwice, Śląskie, Poland",
  "169500, Sosnogorsk, Komi, Russia",
  "44627, Fredericksburg, Ohio, United States",
  "357506, Zheleznovodak, Stavropol, Russia",
  "Zürich Metropolitan Area, Aargau, Switzerland",
  "Himachal Pradesh, India",
  "31027, Kohtla-Järve, Ida-Virumaa, Estonia",
  "Zaprešić, Zagreb, Croatia",
  "20304, Narva, Ida-Virumaa, Estonia",
  "33467, Lake Worth, Florida, United States",
  "152901, Rybinsk, Yaroslavl’, Russia",
  "65350, Vaasa, Ostrobothnia, Finland",
  "Francisco Morazán, Honduras",
  "Randolph County, Missouri, United States",
  "11206, Algeciras, Andalusia, Spain",
  "Fremont, Illinois, United States",
  "410279, Oradea, Bihor, Romania",
  "86152, Augsburg, Bavaria, Germany",
  "Winnebago, Illinois, United States",
  "10151, Tallinn, Harjumaa, Estonia",
  "C19B9H4, Zhaltyr, Akmola Region, Kazakhstan",
  "Daviess County, Kentucky, United States",
  "Mustang, Oklahoma, United States",
  "59715, Bozeman, Montana, United States",
  "Tolleshunt Major, England, United Kingdom",
  "Kavala, Trabzon, Türkiye",
  "06450, Ankara, Ankara, Türkiye",
  "Pointe-à-Pitre, Guadeloupe",
  "S25E6H3, Ertis, Pavlodar Region, Kazakhstan",
  "Volgodonsk, Rostov, Russia",
  "Norwich, New York, United States",
  "North Reading, Massachusetts, United States",
  "Polk County, Wisconsin, United States",
  "K0H 1J0, Clarendon Station, Ontario, Canada",
  "Çankırı, Çankırı, Türkiye",
  "Rozdol, Zaporizhzhya, Ukraine",
  "47057, Duisburg, North Rhine-Westphalia, Germany",
  "398000, Lipetsk, Lipetsk, Russia",
  "Northeast, Harrisonburg, Virginia, United States",
  "Ransom, North Carolina, United States",
  "43596, Horta de Sant Joan, Catalonia, Spain",
  "ST4 1JG, Stoke-On-Trent, England, United Kingdom",
  "Howell Township, New Jersey, United States",
  "07073, East Rutherford, New Jersey, United States",
  "5231 AA, ’s-Hertogenbosch, North Brabant, Netherlands",
  "S0G 1G0, Duval, Saskatchewan, Canada",
  "27215, Burlington, North Carolina, United States",
  "Pernik, Pernik, Bulgaria",
  "Plastovsky District, Chelyabinsk, Russia",
  "Sion, Occitanie, France",
  "Moorefield, West Virginia, United States",
  "Forsyth, Georgia, United States",
  "Cedar, Michigan, United States",
  "32103, Čačak, Centralna Srbija, Serbia",
  "30007, Murcia, Región de Murcia, Spain",
  "Rudnya, Volgograd, Russia",
  "841 05, Bratislava, Bratislava, Slovakia",
  "Patrick Henry, Virginia, United States",
  "1098, Middelburg, Mpumalanga, South Africa",
  "2567, Kyustendil, Kyustendil, Bulgaria",
  "Lawrenceburg, Kentucky, United States",
  "McDonald Park, Appleton, Wisconsin, United States",
  "Quinindé Canton, Esmeraldas, Ecuador",
  "66212, Tulungagung, East Java, Indonesia",
  "Aralaganwila, North Central Province, Sri Lanka",
  "Prokopyevsky District, Kemerovo, Russia",
  "Yelets-Malanino, Lipetsk, Russia",
  "Manisa, Türkiye",
  "142155, Podolsk, Moscow, Russia",
  "Kanevskaya, Krasnodar, Russia",
  "28630, Granite Falls, North Carolina, United States",
  "54710, Fléville-devant-Nancy, Grand Est, France",
  "Nice, California, United States",
  "Stantonbury, Milton Keynes, England, United Kingdom",
  "Hendersonville, North Carolina, United States",
  "Gornyak, Altai Krai, Russia",
  "Gryazinskiy Rayon, Lipetsk, Russia",
  "Nicholas County, Kentucky, United States",
  "Logan, Queensland, Australia",
  "Barton On Sea, England, United Kingdom",
  "Macon County, Illinois, United States",
  "601 33, Katerini, Central Macedonia, Greece",
  "13093, Oswego, New York, United States",
  "Çamardı, Niğde, Türkiye",
  "E7G 0A2, Plaster Rock, New Brunswick, Canada",
  "Toolera, Alwar, Rajasthan, India",
  "62763, Emiliano Zapata, Morelos, Mexico",
  "1394, Drammen, Viken, Norway",
  "Troitsk, Moscow, Russia",
  "Greater Nantes Metropolitan Area",
  "692040, Lesozavodsk, Primorye, Russia",
  "Hutchinson, Kansas, United States",
  "155805, Kineshma, Ivanovo, Russia",
  "Multnomah County, Oregon, United States",
  "B25C5D3, Esik, Almaty Region, Kazakhstan",
  "Klickitat, Washington, United States",
  "238522, Gur'yevsk, Kaliningrad, Russia",
  "566, Sauðárkrókur, Northwestern Region, Iceland",
  "Narva, Ida-Virumaa, Estonia",
  "03540, Alicante, Valencian Community, Spain",
  "08000, Artvin, Artvin, Türkiye",
  "Millard Grange, Port Elizabeth, Eastern Cape, South Africa",
  "Be'er Sheva, South District, Israel",
  "North Carolina, United States",
  "1170-001, Lisbon, Lisbon, Portugal",
  "Blandford, Massachusetts, United States",
  "Liberia",
  "24415, Rockbridge Baths, Virginia, United States",
  "Akhuryan region, Shirak Province, Armenia",
  "Potter Heigham, England, United Kingdom",
  "427625, Glazov, Udmurtia, Russia",
  "Merkezefendi, Denizli, Türkiye",
  "Guernsey",
  "Peterborough, England, United Kingdom",
  "125300, Râmnicu Sărat, Buzău, Romania",
  "Petropavlovskaya, Chechnya, Russia",
  "428020, Cheboksary, Chuvashia, Russia",
  "629755, Nadym, Yamalo-Nenets, Russia",
  "Malmo, Skåne County, Sweden",
  "Wheatland, North Dakota, United States",
  "South America",
  "Upton, Wyoming, United States",
  "Grundy County, Iowa, United States",
  "Arcos de Valdevez, Viana do Castelo, Portugal",
  "Selçikler, Uşak, Türkiye",
  "82073, Laramie, Wyoming, United States",
  "Ünye, Ordu, Türkiye",
  "Croatia",
  "52002, Melilla, Melilla, Spain",
  "40223, Düsseldorf, North Rhine-Westphalia, Germany",
  "399057, Gryazi, Lipetsk, Russia",
  "Carver-Kemp, Bryan, Texas, United States",
  "90138, Palermo, Sicily, Italy",
  "Karlıova, Bingöl, Türkiye",
  "Ankara, Türkiye",
  "Hamilton, Ohio, United States",
  "723 53, Västerås, Västmanland County, Sweden",
  "Assenheim, Hesse, Germany",
  "57066, Tyndall, South Dakota, United States",
  "Greater Patras Area",
  "03301, Concord, New Hampshire, United States",
  "Socorro, Jaboatão dos Guararapes, Pernambuco, Brazil",
  "Coos County, New Hampshire, United States",
  "Southampton, Massachusetts, United States",
  "Orangeburg Heights, Modesto, California, United States",
  "2206, Starše, Maribor, Slovenia",
  "Hokesiang, Great Nicobar, Andaman and Nicobar Islands, India",
  "Vila Real de Santo António, Faro, Portugal",
  "6501, Bellinzona, Ticino, Switzerland",
  "Chouteau, Courpignac, Nouvelle-Aquitaine, France",
  "Berezniki, Kursk, Russia",
  "162610, Cherepovets, Vologda, Russia",
  "Nevşehir, Türkiye",
  "Medina, Minas Gerais, Brazil",
  "BH6 3RH, Bournemouth, England, United Kingdom",
  "3924, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "French Southern and Antarctic Lands",
  "Urnäsch, Appenzell Outer-Rhoden, Switzerland",
  "Langlade County, Wisconsin, United States",
  "Ağrı, Ağrı, Türkiye",
  "0125, Oslo, Oslo, Norway",
  "V94 V1Y5, Limerick, County Limerick, Ireland",
  "Stanley, North Dakota, United States",
  "5411, Çorovodë, Berat, Albania",
  "Stillwater, Oklahoma, United States",
  "Kırıkkale, Türkiye",
  "Hampshire County, Massachusetts, United States",
  "Cannon Falls, Minnesota, United States",
  "Marshall, North Carolina, United States",
  "Rottach-Egern, Bavaria, Germany",
  "Belknap Acres, Texas, United States",
  "Maury, Port-Sainte-Marie, Nouvelle-Aquitaine, France",
  "60192, Hoffman Estates, Illinois, United States",
  "V0N 2M3, Pender Island, British Columbia, Canada",
  "80339, Munich, Bavaria, Germany",
  "8000, Székesfehérvár, Fejér, Hungary",
  "633204, Iskitim, Novosibirsk, Russia",
  "Cuyahoga Falls, Ohio, United States",
  "F17G5M5, Semey, Abay Region, Kazakhstan",
  "Barnesville, Ohio, United States",
  "Richmond Hill, Ontario, Canada",
  "86512, Chambers, Arizona, United States",
  "Suntarskiy, Sakha, Russia",
  "Glen McPherson 46, Saskatchewan, Canada",
  "Simpson Green, Bradford, England, United Kingdom",
  "13600, Bitlis, Bitlis, Türkiye",
  "55060, Samsun, Samsun, Türkiye",
  "659309, Biysk, Altai Krai, Russia",
  "Greater Salerno Metropolitan Area",
  "68005-050, Santarém, Pará, Brazil",
  "Saratoga, Illinois, United States",
  "621 51, Visby, Gotland County, Sweden",
  "Northumberland Historic District, United States",
  "Denton, Newhaven, England, United Kingdom",
  "26131, Oldenburg, Lower Saxony, Germany",
  "659300, Biysk, Altai Krai, Russia",
  "50860, Nevsehir, Nevşehir, Türkiye",
  "Perkinskoye, Ryazan’, Russia",
  "618265, Gubakha, Perm, Russia",
  "Clarksville, Maryland, United States",
  "500028, Hyderabad, Telangana, India",
  "Davis, West Virginia, United States",
  "Plymouth, California, United States",
  "Mathews, Alabama, United States",
  "Wirtsmühle, Hahnstätten, Rhineland-Palatinate, Germany",
  "Tishomingo County, Mississippi, United States",
  "981 39, Kiruna, Norrbotten County, Sweden",
  "617450, Kungur, Perm, Russia",
  "Greater Lexington Area",
  "93176, Beratzhausen, Bavaria, Germany",
  "6217 AB, Maastricht, Limburg, Netherlands",
  "R13E4P9, Beyneu, Mangystau Region, Kazakhstan",
  "Russell County, Alabama, United States",
  "658204, Rubtsovsk, Altai Krai, Russia",
  "AD200, Encamp, Encamp, Andorra",
  "Osage, West Virginia, United States",
  "Upton, Slough, England, United Kingdom",
  "Smythe Park, Toronto, Ontario, Canada",
  "GY2 4DG, St Sampson, Guernsey, Guernsey",
  "Greater Bielefeld Area",
  "Bordeaux, Nouvelle-Aquitaine, France",
  "NN15 5FF, Burton Latimer, England, United Kingdom",
  "Porto Metropolitan Area",
  "9700-059, Angra do Heroísmo, Portugal",
  "Cass County, Iowa, United States",
  "Hamburg, Germany",
  "Lake Worth, Florida, United States",
  "Mineral del Monte, Hidalgo, Mexico",
  "46016, Anderson, Indiana, United States",
  "Currituck County, North Carolina, United States",
  "Stanley County, South Dakota, United States",
  "Sol’-Iletskiy Rayon, Orenburg, Russia",
  "North Grafton, Massachusetts, United States",
  "West Overton, England, United Kingdom",
  "Verkhnevilyuyskiy, Sakha, Russia",
  "Kimball, Wisconsin, United States",
  "Angra do Heroísmo, Ilha de Terceira, Portugal",
  "Millsboro, Delaware, United States",
  "Korsakovskoye, Buryatia, Russia",
  "243001, Novozybkov, Bryansk, Russia",
  "74800, Chornomorivka, Kherson, Ukraine",
  "Lubelskie, Poland",
  "Montour, Toutlemonde, Pays de la Loire, France",
  "6363, Stansstad, Nidwalden, Switzerland",
  "9706, Saranda, Vlorë, Albania",
  "Simpsonville, Kentucky, United States",
  "423576, Nizhnekamsk, Tatarstan, Russia",
  "Redondo Beach, California, United States",
  "23004, Jaén, Andalusia, Spain",
  "367000, Makhachkala, Dagestan, Russia",
  "1300, Ilinden, Skopje Statistical Region, North Macedonia",
  "Cavalier, Ferrières-sur-Ariège, Occitanie, France",
  "453110, Sterlitamak, Bashkortostan, Russia",
  "8311, Svolvær, Nordland, Norway",
  "35200, Panevėžys, Panevėžio, Lithuania",
  "89052, Henderson, Nevada, United States",
  "Catawba, North Carolina, United States",
  "Orangeburg West, South Carolina, United States",
  "Limestone, Victoria, Australia",
  "Greater Jaén Metropolitan Area",
  "45130, Essen, North Rhine-Westphalia, Germany",
  "Morris, Alabama, United States",
  "Palo Pinto County, Texas, United States",
  "03010, Alicante, Valencian Community, Spain",
  "91100, Klaipėda, Klaipėdos, Lithuania",
  "Şardara, Turkistan Region, Kazakhstan",
  "Williamsburg, Virginia, United States",
  "59434, East Glacier Park, Montana, United States",
  "70820, Kuopio, Northern Savonia, Finland",
  "Frankfurt Rhine-Main Metropolitan Area",
  "Mecklenburgische Seenplatte, Mecklenburg-West Pomerania, Germany",
  "652324, Topki, Kemerovo, Russia",
  "Smolenskaya, Krasnodar, Russia",
  "Erfurt, Thuringia, Germany",
  "Rock Island County, Illinois, United States",
  "Greene County, Tennessee, United States",
  "Greater Tekirdag",
  "Wallaroo, South Australia, Australia",
  "Holt, Missouri, United States",
  "98230, Kemijärvi, Lapland, Finland",
  "Kosovo",
  "Krems an der Donau, Lower Austria, Austria",
  "Osinniki, Tomsk, Russia",
  "Brive-la-Gaillarde, Nouvelle-Aquitaine, France",
  "51249, Sibley, Iowa, United States",
  "94306, Palo Alto, California, United States",
  "Naryan-Mar, Nenets, Russia",
  "40923, Cannon, Kentucky, United States",
  "Kurmangazy district, Atyrau Region, Kazakhstan",
  "Salt, Catalonia, Spain",
  "Greeley County, Kansas, United States",
  "Graham, California, United States",
  "6200, Chirpan, Stara Zagora, Bulgaria",
  "E28D9X7, Makhambet, Atyrau Region, Kazakhstan",
  "3001, Elbasan, Elbasan, Albania",
  "B55D9X8, Zharkent, Jetisu Region, Kazakhstan",
  "72182, Tucker, Arkansas, United States",
  "77995, Yoakum, Texas, United States",
  "Luna, Cluj, Romania",
  "413843, Balakovo, Saratov, Russia",
  "Wharton, England, United Kingdom",
  "6524, Bellinzona, Ticino, Switzerland",
  "Villars-sur-Glâne, Fribourg, Switzerland",
  "70076, Angelina, Louisiana, United States",
  "Krasnoarmeyskoye, Krasnodar, Russia",
  "Les Rivières, Barneville-Carteret, Normandy, France",
  "Harper, Iowa, United States",
  "Georgetown, Penang, Malaysia",
  "Warren County, Mississippi, United States",
  "Howell, Evansville, Indiana, United States",
  "Chernyakhovsky District, Kaliningrad, Russia",
  "Pickens County, South Carolina, United States",
  "Uberaba, Minas Gerais, Brazil",
  "Flensburg, Minnesota, United States",
  "Nancefield Extension 1, Johannesburg, Gauteng, South Africa",
  "13090-150, Campinas, São Paulo, Brazil",
  "623382, Polevskoy, Sverdlovsk, Russia",
  "671 50, Xánthi, Eastern Macedonia and Thrace, Greece",
  "Hoodsport, Washington, United States",
  "29646, Greenwood, South Carolina, United States",
  "243144, Klintsy, Bryansk, Russia",
  "8230, Nesebar, Burgas, Bulgaria",
  "Palo Alto, California, United States",
  "Utrechtse Heuvelrug, Utrecht, Netherlands",
  "03016, Alicante, Valencian Community, Spain",
  "23570, Lübeck, Schleswig-Holstein, Germany",
  "346988, Bol’shaya Kirsanovka, Rostov, Russia",
  "76450, Graham, Texas, United States",
  "Vyborgsky District, Leningrad, Russia",
  "63341, Defiance, Missouri, United States",
  "Webbers Creek, New South Wales, Australia",
  "S2206AKF, Oliveros, Santa Fe, Argentina",
  "63558, New Cambria, Missouri, United States",
  "462401, Orsk, Orenburg, Russia",
  "APAC",
  "Friockheim, Scotland, United Kingdom",
  "Winston, Oregon, United States",
  "Senirkent, Isparta, Türkiye",
  "6215 JA, Maastricht, Limburg, Netherlands",
  "Haute-Savoie, Auvergne-Rhône-Alpes, France",
  "1260, Ljubljana, Ljubljana, Slovenia",
  "1202, Geneva, Geneva, Switzerland",
  "Toledo, Illinois, United States",
  "Montevideo, Uruguay",
  "16159, West Middlesex, Pennsylvania, United States",
  "B4V 0V7, Lunenburg, Nova Scotia, Canada",
  "628410, Surgut, Khanty-Mansi, Russia",
  "623426, Kamensk Uralskiy, Sverdlovsk, Russia",
  "Rices Landing, Pennsylvania, United States",
  "Allendale, Wisconsin, United States",
  "79007, Lviv, Lviv, Ukraine",
  "Avery, California, United States",
  "67567, Pawnee Rock, Kansas, United States",
  "Genoa, West Virginia, United States",
  "Martinique",
  "54813, Barronett, Wisconsin, United States",
  "Nelson County, North Dakota, United States",
  "6410, Molde, Møre og Romsdal, Norway",
  "665401, Cheremkhovo, Irkutsk, Russia",
  "Calumet Park, Illinois, United States",
  "Pagerageung, Kecamatan Pagerageung, West Java, Indonesia",
  "06750, Porvoo, Uusimaa, Finland",
  "652156, Mariinskiy, Kemerovo, Russia",
  "52004, Melilla, Melilla, Spain",
  "806 32, Gävle, Gävleborg County, Sweden",
  "25 Roseau, Guerrero, Mexico",
  "8012 AJ, Zwolle, Overijssel, Netherlands",
  "Dicksons Corners, Zorra, Ontario, Canada",
  "Lenningen, Grevenmacher, Luxembourg",
  "23816, Neversdorf, Schleswig-Holstein, Germany",
  "600 62, Katerini, Central Macedonia, Greece",
  "M28C5H3, Aqtau, Karaganda Region, Kazakhstan",
  "Golden, Mississippi, United States",
  "Boone County, Indiana, United States",
  "Cockenzie And Port Seton, Scotland, United Kingdom",
  "663612, Kansk, Krasnoyarsk Krai, Russia",
  "Powell River, British Columbia, Canada",
  "F18H5B2, Semey, Abay Region, Kazakhstan",
  "18701, Wilkes-Barre, Pennsylvania, United States",
  "09123, Chemnitz, Saxony, Germany",
  "08303, Mataró, Catalonia, Spain",
  "Lake Norman of Catawba, North Carolina, United States",
  "62915, Cambria, Illinois, United States",
  "46755, Kendallville, Indiana, United States",
  "Toolekalan, Ibrahimpatnam, Telangana, India",
  "Xromtaw district, Aqtöbe region, Kazakhstan",
  "Youghal, County Cork, Ireland",
  "98902, Yakima, Washington, United States",
  "ZE1 0SE, Lerwick, Scotland, United Kingdom",
  "301 31, Agrínio, Western Greece, Greece",
  "Clinton Heights, Gilmer, North Carolina, United States",
  "Şanlıurfa, Şanliurfa, Türkiye",
  "Pike County, Kentucky, United States",
  "60007, Elk Grove Village, Illinois, United States",
  "Roberts, Virginia, United States",
  "Cleveland, Mississippi, United States",
  "Richland County, North Dakota, United States",
  "Potenza Picena, Marches, Italy",
  "9020, Varna, Varna, Bulgaria",
  "686417, Omsukchan, Magadan, Russia",
  "Redlands, California, United States",
  "Johnson, Kansas, United States",
  "Fallon, Bourgogne-Franche-Comté, France",
  "Salem, Wisconsin, United States",
  "Sabine, Sainte-Croix-Volvestre, Occitanie, France",
  "DG2 8PN, Dumfries, Scotland, United Kingdom",
  "Starr Hill, Charlottesville, Virginia, United States",
  "Vorontsovo, Pskov, Russia",
  "Central African Republic",
  "R25F0C6, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "Buzău, Buzău, Romania",
  "Shira, Khakassia, Russia",
  "169726, Usinsk, Komi, Russia",
  "683551, Socorro, Santander, Colombia",
  "Guardamar de la Safor, Valencian Community, Spain",
  "Vyaz’ma, Smolensk, Russia",
  "Dundee, Oregon, United States",
  "M52B9H6, Osakarovka, Karaganda Region, Kazakhstan",
  "100225, Ploieşti, Prahova, Romania",
  "Big Stone Gap, Virginia, United States",
  "03046, Cottbus, Brandenburg, Germany",
  "Buguruslansky District, Orenburg, Russia",
  "8240, Thayngen, Schaffhausen, Switzerland",
  "301361, Aleksin, Kaluga, Russia",
  "Shenandoah, Virginia, United States",
  "Sasovo, Ryazan’, Russia",
  "Cedar Knolls, New Jersey, United States",
  "Umatilla, Oregon, United States",
  "Traill County, North Dakota, United States",
  "831 62, Östersund, Jämtland County, Sweden",
  "Limerick, Maine, United States",
  "Listowel, County Kerry, Ireland",
  "66272, Tulungagung, East Java, Indonesia",
  "9657, Hammerfest, Troms og Finnmark, Norway",
  "Yuzha, Ivanovo, Russia",
  "50007, Zaragoza, Aragon, Spain",
  "Lafayette, Louisiana Metropolitan Area",
  "414056, Astrakhan, Astrakhan’, Russia",
  "Kane County, Utah, United States",
  "10124, Turin, Piedmont, Italy",
  "Westmoreland, Jamaica",
  "34144, Trieste, Friuli-Venezia Giulia, Italy",
  "Heathfield, England, United Kingdom",
  "76180, North Richland Hills, Texas, United States",
  "George County, Mississippi, United States",
  "56164, Pipestone, Minnesota, United States",
  "Nolan County, Texas, United States",
  "18213, Aleksinac, Centralna Srbija, Serbia",
  "47304, Plaški, Karlovac, Croatia",
  "8752, Niederurnen, Glarus, Switzerland",
  "400464, Cluj-Napoca, Cluj, Romania",
  "Hotan Region, Xinjiang Uygur, China",
  "Warren County, Pennsylvania, United States",
  "2605, Lillehammer, Innlandet, Norway",
  "603 63, Norrköping, Östergötland County, Sweden",
  "R6W 1C7, Winkler, Manitoba, Canada",
  "236022, Kaliningrad, Kaliningrad, Russia",
  "394030, Voronezh, Voronezh, Russia",
  "Cooper, Michigan, United States",
  "Stokes Bay, Ontario, Canada",
  "Dzerzhinsk, Irkutsk, Russia",
  "143204, Baba Bakala, Punjab, India",
  "13803, Marathon, New York, United States",
  "Tazewell Avenue Historic District, Richlands, Virginia, United States",
  "Madison, Ohio, United States",
  "04774, Dickey, Maine, United States",
  "Federal District, Brazil",
  "81-525, Gdynia, Pomorskie, Poland",
  "Cassel, Hauts-de-France, France",
  "Fort Ransom, North Dakota, United States",
  "367032, Makhachkala, Dagestan, Russia",
  "Rïdder qalalıq äkimdigi, East Kazakhstan Region, Kazakhstan",
  "L1C 3C5, Bowmanville, Ontario, Canada",
  "B61D9E4, Sarqan, Jetisu Region, Kazakhstan",
  "692052, Lesozavodsk, Primorye, Russia",
  "Rio Verde, Goiás, Brazil",
  "Uglich, Yaroslavl’, Russia",
  "Downtown Manassas, Virginia, United States",
  "Garden City, Idaho, United States",
  "Smolensk, Smolensk, Russia",
  "Quay County, New Mexico, United States",
  "4024, Linz, Upper Austria, Austria",
  "Menlo Park, California, United States",
  "Otero County, New Mexico, United States",
  "Wheeler County, Nebraska, United States",
  "Pescara, Abruzzi, Italy",
  "347389, Volgodonsk, Rostov, Russia",
  "Ivano-Frankivskyi Raion, Ivano-Frankivsk, Ukraine",
  "25-432, Kielce, Świętokrzyskie, Poland",
  "35968, Fort Payne, Alabama, United States",
  "Nottingham, England, United Kingdom",
  "Wright City, Oklahoma, United States",
  "Taos, New Mexico, United States",
  "6002, Ålesund, Møre og Romsdal, Norway",
  "Mount Edgecombe Estate, Durban, KwaZulu-Natal, South Africa",
  "682032, Chegdomyn, Khabarovsk, Russia",
  "Burlington County, New Jersey, United States",
  "91024, Sierra Madre, California, United States",
  "216504, Roslavl’, Smolensk, Russia",
  "621010, Turaiyur, Tamil Nadu, India",
  "32007, Palatka, Florida, United States",
  "83024, Rosenheim, Bavaria, Germany",
  "Strafford, Missouri, United States",
  "393193, Kotovsk, Tambov, Russia",
  "04157, Leipzig, Saxony, Germany",
  "Halifax, North Carolina, United States",
  "Bryansk Metropolitan Area",
  "Saint-Georges, Hauts-de-France, France",
  "85927, Greer, Arizona, United States",
  "Delta County, Colorado, United States",
  "Clinton, Mississippi, United States",
  "Berkeley County, South Carolina, United States",
  "R13X0E3, Beyneu, Mangystau Region, Kazakhstan",
  "Andrews AFB, Maryland, United States",
  "LV-3001, Jelgava, Jelgava Municipality, Latvia",
  "SN15 4LQ, Langley Burrell, England, United Kingdom",
  "152621, Otradnyy, Yaroslavl’, Russia",
  "404125, Volzhskiy, Volgograd, Russia",
  "Borovskiy Sovkhoz selo, Kaluga, Russia",
  "ST4 1AA, Stoke-On-Trent, England, United Kingdom",
  "Halland County, Sweden",
  "Doğanşehir, Malatya, Türkiye",
  "679013, Birobidzhan, Birobijan, Russia",
  "Middlesex, Vermont, United States",
  "309263, Shebekino, Belgorod, Russia",
  "HX1 1AA, Halifax, England, United Kingdom",
  "Greenville, Indiana, United States",
  "Lawrence, Indiana, United States",
  "29620, Abbeville, South Carolina, United States",
  "50700, Nevsehir, Nevşehir, Türkiye",
  "Kemin, Chuy Region, Kyrgyzstan",
  "359402, Umantsevo, Kalmykia, Russia",
  "3309, Gramsh, Elbasan, Albania",
  "Maripasoula, Saint-Laurent-du-Maroni, French Guiana",
  "Borcea, Călăraşi, Romania",
  "F56 HD52, Ballymote, County Sligo, Ireland",
  "47700, Mardin, Mardin, Türkiye",
  "357722, Kislovodsk, Stavropol, Russia",
  "94522, Wallersdorf, Bavaria, Germany",
  "35226, Birmingham, Alabama, United States",
  "412905, Vol'sk, Saratov, Russia",
  "40-014, Katowice, Śląskie, Poland",
  "346519, Shakhty, Rostov, Russia",
  "Haakon County, South Dakota, United States",
  "Millerton, New York, United States",
  "75135, Caddo Mills, Texas, United States",
  "A92 DHD3, Drogheda, Ireland",
  "Sibleys Cove, Division 1, Subdivision F, Newfoundland and Labrador, Canada",
  "Vieux-Rouen-sur-Bresle, Normandy, France",
  "08302, Mataró, Catalonia, Spain",
  "Steeleville, Illinois, United States",
  "Rosebud, Missouri, United States",
  "78137, Siauliai, Siauliu, Lithuania",
  "Comallo, Río Negro Province, Argentina",
  "Kingfisher Shopping Centre, Redditch, England, United Kingdom",
  "Henry County, Virginia, United States",
  "Dal'negorsk, Primorye, Russia",
  "Kars, New Brunswick, Canada",
  "Belmont, Ontario, Canada",
  "33707, St Petersburg, Florida, United States",
  "Brooks, Alberta, Canada",
  "54-130, Wrocław, Dolnośląskie, Poland",
  "Nemaha County, Nebraska, United States",
  "3524, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Ajkai járás, Veszprém, Hungary",
  "400376, Cluj-Napoca, Cluj, Romania",
  "Miami, Florida, United States",
  "9307, Finnsnes, Troms og Finnmark, Norway",
  "Preston, Paignton, England, United Kingdom",
  "Brindisi, Apulia, Italy",
  "Shira, Bauchi State, Nigeria",
  "X38M9C8, Kentau, Turkistan Region, Kazakhstan",
  "61741, Forrest, Illinois, United States",
  "Sharm El Sheikh, South Sinai, Egypt",
  "10145, Tallinn, Harjumaa, Estonia",
  "Thomaston, Georgia, United States",
  "Burlington, New Jersey, United States",
  "665404, Cheremkhovo, Irkutsk, Russia",
  "Wabasha County, Minnesota, United States",
  "Grayson, Moose Jaw, Saskatchewan, Canada",
  "Huntsville, Texas, United States",
  "45200, Manisa, Manisa, Türkiye",
  "42250, Konya, Konya, Türkiye",
  "166000, Naryan Mar, Nenets, Russia",
  "24105, Subotica, Vojvodina, Serbia",
  "Mijdrecht, Utrecht, Netherlands",
  "80-175, Gdańsk, Pomorskie, Poland",
  "Kidder, Pennsylvania, United States",
  "Winkler, Manitoba, Canada",
  "Socorro, São Paulo, Brazil",
  "Normandy, France",
  "Lignan-de-Bordeaux, Nouvelle-Aquitaine, France",
  "61128, Kharkiv, Kharkiv, Ukraine",
  "652476, Anzhero-Sudzhensk, Kemerovo, Russia",
  "Slatina, Sofia City, Bulgaria",
  "Găgești, Vaslui, Romania",
  "Kirovsk, Mahilyow, Belarus",
  "Veendam, Groningen, Netherlands",
  "Los Angeles Metropolitan Area",
  "75201, Dallas, Texas, United States",
  "Keya Bagan, Kolkata, West Bengal, India",
  "11700, Georgetown, Penang, Malaysia",
  "1030, Brussels, Brussels Region, Belgium",
  "653036, Prokopyevsk, Kemerovo, Russia",
  "Burkett, Texas, United States",
  "Hudson, North Carolina, United States",
  "Comoros",
  "Linzi District, Shandong, China",
  "50180, Nevsehir, Nevşehir, Türkiye",
  "Kungursky District, Perm, Russia",
  "West Des Moines, Iowa, United States",
  "Sochivkovskiy sel'skiy sovet, Brest, Belarus",
  "Kanashskoye, Tyumen’, Russia",
  "617471, Kungur, Perm, Russia",
  "21076, Hanover, Maryland, United States",
  "7629, Pécs, Baranya, Hungary",
  "Bogotol, Krasnoyarsk Krai, Russia",
  "Syros-Ermoupoli, Southern Aegean, Greece",
  "23050, Elâzığ, Elazığ, Türkiye",
  "Richmond, Nova Scotia, Canada",
  "4411, Tuckerang, Queensland, Australia",
  "Olsztyn, Warmińsko-mazurskie, Poland",
  "Crookhorn, Waterlooville, England, United Kingdom",
  "Nagaland, India",
  "73564, Roosevelt, Oklahoma, United States",
  "Gloucester, Massachusetts, United States",
  "Bristol, Virginia, United States",
  "CO13 0DP, Kirkby-Le-Soken, England, United Kingdom",
  "335065, Sangaria, Rajasthan, India",
  "Tazewell, Tennessee, United States",
  "Karlivka, Donetsk, Ukraine",
  "9020-172, Funchal, Madeira Island, Portugal",
  "57107, Sioux Falls, South Dakota, United States",
  "Wheeler Heights, New South Wales, Australia",
  "Yellow Springs, Ohio, United States",
  "080303, Giurgiu, Giurgiu, Romania",
  "0993, Stillwater, Auckland, New Zealand",
  "Chatham County, North Carolina, United States",
  "Sangaree, South Carolina, United States",
  "23002, Jaén, Andalusia, Spain",
  "Eddyville, Iowa, United States",
  "Plymouth, Michigan, United States",
  "6725, Szeged, Csongrád, Hungary",
  "04619, Calais, Maine, United States",
  "DY11 5BE, Kidderminster, England, United Kingdom",
  "Dillonneau, Le Vigeant, Nouvelle-Aquitaine, France",
  "Plymouth, New Hampshire, United States",
  "Weber, Steinhausen an der Rottum, Baden-Württemberg, Germany",
  "08873, Somerset, New Jersey, United States",
  "Cottontown, Tennessee, United States",
  "Gregory, Queensland, Australia",
  "03011, Alicante, Valencian Community, Spain",
  "35051, Columbiana, Alabama, United States",
  "Brunswick, Georgia, United States",
  "Kamenka, Tambov, Russia",
  "Sharansky District, Bashkortostan, Russia",
  "6500, Mossel Bay, Western Cape, South Africa",
  "Stadt Berlin, Berlin, Germany",
  "Tipton, Kansas, United States",
  "Novhorod-Siverska miska hromada, Chernihiv, Ukraine",
  "Piketon, Ohio, United States",
  "06129, Perugia, Umbria, Italy",
  "Potirendaba, São Paulo, Brazil",
  "Weston County, Wyoming, United States",
  "76298, Siauliai, Siauliu, Lithuania",
  "Chistopol’, Tatarstan, Russia",
  "Burgos, Ilocos Region, Philippines",
  "360019, Nalchik, Kabardino-Balkaria, Russia",
  "Bryanka, Luhansk, Ukraine",
  "Greater Tours Area",
  "Salekhard, Yamalo-Nenets, Russia",
  "Blato, Dubrovnik-Neretva, Croatia",
  "546 35, Thessaloniki, Central Macedonia, Greece",
  "Akron, Ohio, United States",
  "Sublette, Illinois, United States",
  "30110, Murcia, Región de Murcia, Spain",
  "R0E 2B0, Tyndall, Manitoba, Canada",
  "33005, Oviedo, Principality of Asturias, Spain",
  "Johnson County, Wyoming, United States",
  "164528, Severodvinsk, Arkhangel’sk, Russia",
  "Bastia Mondovì, Piedmont, Italy",
  "08790, Artvin, Artvin, Türkiye",
  "North Port-Sarasota Area",
  "Stevensville, Maryland, United States",
  "04107, Leipzig, Saxony, Germany",
  "Day, Florida, United States",
  "Montgomery, Texas, United States",
  "58639, Hettinger, North Dakota, United States",
  "71415, Grayson, Louisiana, United States",
  "Kirsehir Centre, Kırşehir, Türkiye",
  "8030, Bodø, Nordland, Norway",
  "Kütahya, Kütahya, Türkiye",
  "188563, Slantsy, Leningrad, Russia",
  "Jefferson County, Alabama, United States",
  "427798, Mozhga, Udmurtia, Russia",
  "Baker, California, United States",
  "346512, Shakhty, Rostov, Russia",
  "624785, Verkhnyaya Salda, Sverdlovsk, Russia",
  "Gulfport, Mississippi, United States",
  "Nacogdoches, Texas, United States",
  "Augusta County, Virginia, United States",
  "6415, Molde, Møre og Romsdal, Norway",
  "Ranchos De Taos, New Mexico, United States",
  "2790041, Urayasu, Chiba, Japan",
  "Astrakhan District, Akmola Region, Kazakhstan",
  "Parkersburg, Iowa, United States",
  "Buena Park, California, United States",
  "20170, Denizli, Denizli, Türkiye",
  "623150, Pervoural'sk, Sverdlovsk, Russia",
  "Bolu, Bolu, Türkiye",
  "S25C0X5, Ertis, Pavlodar Region, Kazakhstan",
  "Kursky District, Stavropol, Russia",
  "Adair, Missouri, United States",
  "399056, Gryazi, Lipetsk, Russia",
  "ZE1 0GZ, Lerwick, Scotland, United Kingdom",
  "3045, Drammen, Viken, Norway",
  "80470, Dreuil-lès-Amiens, Hauts-de-France, France",
  "Ransom Canyon, Texas, United States",
  "Slobozia Mândra, Teleorman, Romania",
  "Cadiz, Ohio, United States",
  "E28D9Y0, Makhambet, Atyrau Region, Kazakhstan",
  "455001, Magnitogorsk, Chelyabinsk, Russia",
  "183038, Murmansk, Murmansk, Russia",
  "10700, Balıkesir, Balıkesir, Türkiye",
  "Allegheny County, Pennsylvania, United States",
  "80799, Munich, Bavaria, Germany",
  "Crowthorne, England, United Kingdom",
  "Appomattox County, Virginia, United States",
  "03006, Alicante, Valencian Community, Spain",
  "40210, Düsseldorf, North Rhine-Westphalia, Germany",
  "222301, Maladzyechna, Minsk, Belarus",
  "Tournai, Walloon Region, Belgium",
  "662548, Lesosibirsk, Krasnoyarsk Krai, Russia",
  "72297, ‘Ar‘ar, Northern Frontier, Saudi Arabia",
  "658131, Aleysk, Altai Krai, Russia",
  "Washington County, Minnesota, United States",
  "Ventspils, Latvia",
  "211402, Polatsk, Vitsyebsk, Belarus",
  "Barry Dock, Barry, Wales, United Kingdom",
  "Nikolayevskoye, Kamchatka, Russia",
  "N0L 1G3, Dorchester, Ontario, Canada",
  "Bryant, Arkansas, United States",
  "48437, Genesee, Michigan, United States",
  "GY2 4AE, St Sampson, Guernsey, Guernsey",
  "636465, Kolpashevo, Tomsk, Russia",
  "33012, Oviedo, Principality of Asturias, Spain",
  "R13C6C7, Beyneu, Mangystau Region, Kazakhstan",
  "Gümüşhane, Türkiye",
  "30909, Augusta, Georgia, United States",
  "172506, Nelidovo, Tver’, Russia",
  "95067, Scotts Valley, California, United States",
  "671840, Kyakhta, Buryatia, Russia",
  "30822, Perkins, Georgia, United States",
  "Beli Manastir, Osijek-Baranja, Croatia",
  "53100, Lappeenranta, South Karelia, Finland",
  "Serdobsk, Penza, Russia",
  "185 43, Piraeus, Attiki, Greece",
  "Cluj-Napoca Metropolitan Area",
  "Ferrara, Emilia-Romagna, Italy",
  "Nalchik, Kabardino-Balkaria, Russia",
  "Faulk County, South Dakota, United States",
  "70-777, Szczecin, Zachodniopomorskie, Poland",
  "20010-000, Rio de Janeiro, Rio de Janeiro, Brazil",
  "172002, Torzhok, Tver’, Russia",
  "424028, Yoshkar-Ola, Mari El, Russia",
  "66113, Saarbrücken, Saarland, Germany",
  "LE12 6QN, East Leake, England, United Kingdom",
  "Antelope, California, United States",
  "NR24 2AU, Edgefield Street, England, United Kingdom",
  "Forsyth, Illinois, United States",
  "309292, Shebekino, Belgorod, Russia",
  "66067, Ottawa, Kansas, United States",
  "Christiana, Manchester, Jamaica",
  "791 70, Falun, Dalarna County, Sweden",
  "Obion County, Tennessee, United States",
  "Cooper, Iowa, United States",
  "Ishimsky District, Tyumen’, Russia",
  "Canton of Troyes-5, Grand Est, France",
  "Washburn Creek, North Las Vegas, Nevada, United States",
  "9393, Finnsnes, Troms og Finnmark, Norway",
  "Harris Park, New South Wales, Australia",
  "68165, Mannheim, Baden-Württemberg, Germany",
  "35004, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "628109, Peregrebnoye, Khanty-Mansi, Russia",
  "Chipping Norton, England, United Kingdom",
  "Pierce, Colorado, United States",
  "El Paso County, Colorado, United States",
  "56557, Mahnomen, Minnesota, United States",
  "Bogotol selo, Krasnoyarsk Krai, Russia",
  "200110, Craiova, Dolj, Romania",
  "45458, Dayton, Ohio, United States",
  "Kingston Upon Hull, England, United Kingdom",
  "Sterling, Alaska, United States",
  "37845, Oliver Springs, Tennessee, United States",
  "De-Kastrinskoye, Khabarovsk, Russia",
  "La-Dade-Kotopon Municipal District, Greater Accra Region, Ghana",
  "Chatham, Michigan, United States",
  "CO16 0EA, Thorpe-Le-Soken, England, United Kingdom",
  "Chatham, New Jersey, United States",
  "Dallas-Fort Worth Metroplex",
  "Örsköy, Tunceli, Türkiye",
  "646330, Shimanovsk, Amur, Russia",
  "9650, Hammerfest, Troms og Finnmark, Norway",
  "Angelina, Puebla, Mexico",
  "Kaspichan, Shumen, Bulgaria",
  "Oswego, Illinois, United States",
  "Chesapeake Ranch Estates, Maryland, United States",
  "Powell County, Kentucky, United States",
  "Aral, Issyk-Kul Region, Kyrgyzstan",
  "68980, Trumbull, Nebraska, United States",
  "Yadkin Heights, Salisbury Township, North Carolina, United States",
  "06080, Ankara, Ankara, Türkiye",
  "Volosovo, Vitsyebsk, Belarus",
  "Riga, Bihar, India",
  "Blandford-Blenheim, Ontario, Canada",
  "53200, Lappeenranta, South Karelia, Finland",
  "Lubizhdë, Prizren District, Kosovo",
  "666902, Bodaybo, Irkutsk, Russia",
  "624573, Ivdel’, Sverdlovsk, Russia",
  "Upton, Alcester, England, United Kingdom",
  "25-435, Kielce, Świętokrzyskie, Poland",
  "Elk, Washington, United States",
  "169842, Inta, Komi, Russia",
  "216500, Roslavl’, Smolensk, Russia",
  "Handsworth Wood, Birmingham, England, United Kingdom",
  "Cherokee, South Abaco, The Bahamas",
  "623109, Pervoural'sk, Sverdlovsk, Russia",
  "542 92, Mariestad, Västra Götaland County, Sweden",
  "50829, Cologne, North Rhine-Westphalia, Germany",
  "24503, Lynchburg, Virginia, United States",
  "33714, St Petersburg, Florida, United States",
  "8410, Daniëlskuil, Northern Cape, South Africa",
  "658421, Gornyak, Altai Krai, Russia",
  "165300, Kotlas, Arkhangel’sk, Russia",
  "Lugano, Ticino, Switzerland",
  "Socorro, Texas, United States",
  "Pine Hurst, Hertford, England, United Kingdom",
  "Klyuchi, Tyumen’, Russia",
  "Downtown Missoula, Missoula, Montana, United States",
  "20121, Milan, Lombardy, Italy",
  "627302, Golyshmanovo, Tyumen’, Russia",
  "Morgan County, Utah, United States",
  "Frolovo, Kaluga, Russia",
  "Kohgiluye and Buyer Ahmad Province, Iran",
  "Pendra Road, Chhattisgarh, India",
  "Clarendon, Pennsylvania, United States",
  "Namsos, Trøndelag, Norway",
  "Greater Bengaluru Area",
  "185034, Petrozavodsk, Karelia, Russia",
  "Greenville-Spartanburg-Anderson, South Carolina Area",
  "01570, Webster, Massachusetts, United States",
  "Norton Canes, England, United Kingdom",
  "Beja, Beja, Portugal",
  "Unterer Wirtshof, Wolfach, Baden-Württemberg, Germany",
  "E28D6F7, Makhambet, Atyrau Region, Kazakhstan",
  "98296, Snohomish, Washington, United States",
  "2819, Gjøvik, Innlandet, Norway",
  "Limestone, North Carolina, United States",
  "658471, Cherepanovsky, Altai Krai, Russia",
  "301845, Yefremov, Tula, Russia",
  "McLeans Island, Christchurch, Canterbury, New Zealand",
  "Eastland, Charlotte, North Carolina, United States",
  "Severnyy, Amur, Russia",
  "31221, Kohtla-Järve, Ida-Virumaa, Estonia",
  "9700, Shumen, Shumen, Bulgaria",
  "18066, New Tripoli, Pennsylvania, United States",
  "429824, Alatyr’, Chuvashia, Russia",
  "28386, Shannon, North Carolina, United States",
  "610000, Kirov, Kirov, Russia",
  "Proença-a-Nova, Castelo Branco, Portugal",
  "354003, Sochi, Krasnodar, Russia",
  "Monaco, Monaco, Monaco",
  "722 14, Västerås, Västmanland County, Sweden",
  "Crawford County, Pennsylvania, United States",
  "PH33 6RQ, Fort William, Scotland, United Kingdom",
  "Perth East, Ontario, Canada",
  "422544, Zelënodol'sk, Mari El, Russia",
  "Decatur, Indiana, United States",
  "02907, Providence, Rhode Island, United States",
  "37100, Tours, Centre-Val de Loire, France",
  "Novotroitskoye, Omsk, Russia",
  "Seyhan, Adana, Türkiye",
  "456813, Verkhniy Ufaley, Chelyabinsk, Russia",
  "Essex, Illinois, United States",
  "Norfolk, Massachusetts, United States",
  "Genoa, New York, United States",
  "Greater Pokrovsk Area",
  "Rio Rancho, New Mexico, United States",
  "658707, Oktyabrsky, Altai Krai, Russia",
  "Calhoun County, Florida, United States",
  "N0K 1T0, Rostock, Ontario, Canada",
  "12900, Bingol, Bingöl, Türkiye",
  "077160, Popeşti-Leordeni, Ilfov, Romania",
  "F93 FK44, Lifford, County Donegal, Ireland",
  "Killorglin, County Kerry, Ireland",
  "393760, Michurinsk, Tambov, Russia",
  "04400, Agri, Ağrı, Türkiye",
  "Stonewall County, Texas, United States",
  "623416, Kamensk Uralskiy, Sverdlovsk, Russia",
  "Belogorskiy, Volgograd, Russia",
  "Powellton, West Virginia, United States",
  "43, Stavanger/Sandnes, Rogaland, Norway",
  "Devarkonda, Telangana, India",
  "Rutland, Wisconsin, United States",
  "44-113, Gliwice, Śląskie, Poland",
  "R25A6F4, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "Voznesensky, Voronezh, Russia",
  "Tompkins, Saskatchewan, Canada",
  "Yüksekova, Hakkâri, Türkiye",
  "Edgefield County, South Carolina, United States",
  "Redwood Park, South Australia, Australia",
  "Perth, Tasmania, Australia",
  "Prestonpans, Scotland, United Kingdom",
  "H00K4Y3, Taraz, Jambyl Region, Kazakhstan",
  "188571, Vyskatka, Leningrad, Russia",
  "50470, Rowan, Iowa, United States",
  "SG13 7NN, Hertford, England, United Kingdom",
  "Reagan County, Texas, United States",
  "7624, Pécs, Baranya, Hungary",
  "353460, Gelendzhik, Krasnodar, Russia",
  "Forest Park, Georgia, United States",
  "Sfântu Gheorghe, Covasna, Romania",
  "Vetovo, Ruse, Bulgaria",
  "Goshen, Virginia, United States",
  "414024, Astrakhan, Astrakhan’, Russia",
  "Ellisburg, New York, United States",
  "Chistopol’skiye Vyselki, Tatarstan, Russia",
  "Sarıağaş, Turkistan Region, Kazakhstan",
  "353031, Tikhoretsk, Krasnodar, Russia",
  "Martin County, Kentucky, United States",
  "Blue Mountains, New South Wales, Australia",
  "Kirovskoye, Crimea, Ukraine",
  "Rava-Ruska miska hromada, Lviv, Ukraine",
  "Echternach, Grevenmacher, Luxembourg",
  "Bennington, Indiana, United States",
  "50276, Woodward, Iowa, United States",
  "BD23 3TJ, Thornton-In-Craven, England, United Kingdom",
  "6460, Altdorf UR, Uri, Switzerland",
  "Zgierski, Łódzkie, Poland",
  "Yamaçlı, Yozgat, Türkiye",
  "Kondopozhsky District, Karelia, Russia",
  "Grenoble, Auvergne-Rhône-Alpes, France",
  "Bakalinskiy, Bashkortostan, Russia",
  "Polk County, Florida, United States",
  "Prince George, Virginia, United States",
  "Lensk, Sakha, Russia",
  "06101, Porvoo, Uusimaa, Finland",
  "2593 BS, The Hague, South Holland, Netherlands",
  "22201, Arlington, Virginia, United States",
  "Highland, Maryland, United States",
  "Verkhnosadivska silska rada, Sevastopol, Ukraine",
  "Calhoun County, Michigan, United States",
  "301835, Bogoroditsk, Tula, Russia",
  "Rarotonga, Cook Islands",
  "27884, Stokes, North Carolina, United States",
  "Progress 351, Saskatchewan, Canada",
  "Vanadzor Region, Lori Province, Armenia",
  "Kingsbury, Maine, United States",
  "Refugio Mall, Querétaro, Mexico",
  "Cooperativa 19, Paucarpata, Arequipa, Peru",
  "Arlington County, Virginia, United States",
  "E4M 1M3, Coburg, New Brunswick, Canada",
  "68816, Archer, Nebraska, United States",
  "Emirdağ, Afyon, Türkiye",
  "Valparaiso Metropolitan Area",
  "663960, Zaozërnyy, Krasnoyarsk Krai, Russia",
  "87538, Pecos, New Mexico, United States",
  "Cheyenne, Florida, United States",
  "28660, Pori, Satakunta, Finland",
  "Saundersfoot, Wales, United Kingdom",
  "6225 AA, Maastricht, Limburg, Netherlands",
  "Yuzhnoukrainsk, Mykolayiv, Ukraine",
  "SO14 0AA, Southampton, England, United Kingdom",
  "Holmesville, Ohio, United States",
  "72455, Pocahontas, Arkansas, United States",
  "7682000, Ayanot, Center District, Israel",
  "Dublin 17, County Dublin, Ireland",
  "LE1 5AA, Leicester, England, United Kingdom",
  "IP3 0AB, Ipswich, England, United Kingdom",
  "Harstad, Troms og Finnmark, Norway",
  "30100, Murcia, Región de Murcia, Spain",
  "Barton, Gloucester, England, United Kingdom",
  "Friona, Texas, United States",
  "46186, Wilkinson, Indiana, United States",
  "694202, Poronaysk, Sakhalin, Russia",
  "Longview-Marshall Area",
  "462432, Orsk, Orenburg, Russia",
  "06256, North Windham, Connecticut, United States",
  "Medinah, Illinois, United States",
  "173015, Velikiy Novgorod, Novgorod, Russia",
  "385000, Maykop, Adygea, Russia",
  "352932, Armavir, Krasnodar, Russia",
  "56284, Renville, Minnesota, United States",
  "6720, Szeged, Csongrád, Hungary",
  "Schwyz, Switzerland",
  "ST18 0FH, Little Haywood, England, United Kingdom",
  "Castello di Brianza, Lombardy, Italy",
  "Lexington, Texas, United States",
  "K0C 2E0, Summerstown, Ontario, Canada",
  "Hughes Springs, Texas, United States",
  "Dublin 12, County Dublin, Ireland",
  "Barbour Fields, Bulawayo Province, Zimbabwe",
  "155912, Shuya, Ivanovo, Russia",
  "Kacha, Sevastopol, Ukraine",
  "385 00, Vólos, Thessalia, Greece",
  "04317, Leipzig, Saxony, Germany",
  "15-424, Białystok, Podlaskie, Poland",
  "73020, Choctaw, Oklahoma, United States",
  "351 31, Lamía, Central Greece, Greece",
  "Sutton, St Helens, England, United Kingdom",
  "Cali, Valle del Cauca, Colombia",
  "Nelson, Virginia, United States",
  "HD4 6XD, Thurstonland, England, United Kingdom",
  "4632, Kristiansand, Agder, Norway",
  "Cochabamba, Bolivia",
  "Chapayevskoye, Karachay-Cherkessia, Russia",
  "Stafford Springs, Connecticut, United States",
  "243003, Novozybkov, Bryansk, Russia",
  "66-002, Zielona Góra, Lubuskie, Poland",
  "Austin, Texas, United States",
  "Monroe County, Illinois, United States",
  "47005, Valladolid, Castilla and Leon, Spain",
  "Forsyth Oaks, Azalea Park, Florida, United States",
  "WR10 2JX, Throckmorton, England, United Kingdom",
  "55118, Mainz, Rhineland-Palatinate, Germany",
  "Refugio, Veracruz, Mexico",
  "9025, Győr, Győr-Moson-Sopron, Hungary",
  "Bratska selyshchna hromada, Mykolayiv, Ukraine",
  "8314, Svolvær, Nordland, Norway",
  "Bruges, Flemish Region, Belgium",
  "24402, Staunton, Virginia, United States",
  "Beaufort, South Carolina, United States",
  "Sawyer, North Dakota, United States",
  "454016, Chelyabinsk, Chelyabinsk, Russia",
  "Seward, Nebraska, United States",
  "G41 1AA, Glasgow, Scotland, United Kingdom",
  "238162, Chernyakhovskiy, Kaliningrad, Russia",
  "61021, Dixon, Illinois, United States",
  "53535, Dodgeville, Wisconsin, United States",
  "Smyth, Timiskaming Unorganized West Part, Ontario, Canada",
  "Nowata County, Oklahoma, United States",
  "33534, Gibsonton, Florida, United States",
  "Lakeside, Lynchburg, Virginia, United States",
  "Coalisland, Northern Ireland, United Kingdom",
  "Tala, Paphos, Cyprus",
  "Mitchell, Manitoba, Canada",
  "Rutland, Illinois, United States",
  "Rutherford, New Jersey, United States",
  "5515, Haugesund, Rogaland, Norway",
  "NG7 4EQ, Nottingham, England, United Kingdom",
  "57580-000, Major Izidoro, Alagoas, Brazil",
  "98904, Yakima, Washington, United States",
  "440008, Penza, Penza, Russia",
  "07600, Antalya, Antalya, Türkiye",
  "155331, Vichuga, Ivanovo, Russia",
  "A92 K060, Drogheda, Ireland",
  "21208, Novi Sad, Vojvodina, Serbia",
  "88208, Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Hayshead, Arbroath, Scotland, United Kingdom",
  "Nyen, Northwest, Cameroon",
  "West Todd, South Dakota, United States",
  "38964, Vance, Mississippi, United States",
  "9600, Balchik, Dobrich, Bulgaria",
  "Staunton, Virginia, United States",
  "362048, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "341 50, Chalkida, Central Greece, Greece",
  "Burt, New York, United States",
  "53171, Kenosha, Wisconsin, United States",
  "Falkirk, Scotland, United Kingdom",
  "Sheffield, Pennsylvania, United States",
  "413841, Balakovo, Saratov, Russia",
  "M1P 0A1, Scarborough, Ontario, Canada",
  "Queen Creek, Arizona, United States",
  "90502, Torrance, California, United States",
  "713 04, Iráklion, Crete, Greece",
  "68154, Omaha, Nebraska, United States",
  "413101, Engels, Saratov, Russia",
  "Kubrat, Razgrad, Bulgaria",
  "Haywood County, Tennessee, United States",
  "Dodge County, Minnesota, United States",
  "97882, Umatilla, Oregon, United States",
  "15-087, Białystok, Podlaskie, Poland",
  "06460, Ankara, Ankara, Türkiye",
  "Safonovo, Arkhangel’sk, Russia",
  "78758, Austin, Texas, United States",
  "Deer River, Minnesota, United States",
  "Hrodna, Hrodna, Belarus",
  "01008, Blandford, Massachusetts, United States",
  "Linzhou, Henan, China",
  "689000, Anadyr’, Chukot, Russia",
  "50565, Marathon, Iowa, United States",
  "02382, Whitman, Massachusetts, United States",
  "Crawford County, Iowa, United States",
  "7313, Bitola, Pelagonia, North Macedonia",
  "Seward County, Nebraska, United States",
  "70600, Karaman, Karaman, Türkiye",
  "Mauritius",
  "45040, Mason, Ohio, United States",
  "Greenbrier West, Chesapeake, Virginia, United States",
  "TR18 4NG, Penzance, England, United Kingdom",
  "03005, Alicante, Valencian Community, Spain",
  "Dubuque County, Iowa, United States",
  "97317, Apatou, Saint-Laurent-du-Maroni, French Guiana",
  "356414, Blagodarnyy, Stavropol, Russia",
  "Greater Santa Cruz de Tenerife Metropolitan Area",
  "81249, Munich, Bavaria, Germany",
  "Bistriţa, Bistriţa-Năsăud, Romania",
  "60433, Frankfurt, Hesse, Germany",
  "Zavala, Nayarit, Mexico",
  "2790001, Urayasu, Chiba, Japan",
  "Dzaoudzi, Mayotte",
  "Mtsensk, Orel, Russia",
  "Osnabrück, Lower Saxony, Germany",
  "Lancaster, England, United Kingdom",
  "ZE1 0QG, Lerwick, Scotland, United Kingdom",
  "59807, Missoula, Montana, United States",
  "Seminole, Pennsylvania, United States",
  "50400, Nevsehir, Nevşehir, Türkiye",
  "Ahlat, Bitlis, Türkiye",
  "Elk County, Pennsylvania, United States",
  "652 14, Karlstad, Värmland County, Sweden",
  "Carleton-In-Craven, England, United Kingdom",
  "Scottsville, Kentucky, United States",
  "301657, Novomoskovsk, Tula, Russia",
  "Cassia County, Idaho, United States",
  "8521, Narvik, Nordland, Norway",
  "Jasper, Alberta, Canada",
  "456782, Kyshtym, Chelyabinsk, Russia",
  "96910, Rovaniemi, Lapland, Finland",
  "Saint-Georges-de-Noisné, Nouvelle-Aquitaine, France",
  "8309, Peshkopi, Dibrë, Albania",
  "Kruhlyanski Rayon, Mahilyow, Belarus",
  "Esmeraldas, Ecuador",
  "169607, Pechora, Komi, Russia",
  "Krasnoarmeyskoye, Volgograd, Russia",
  "303952, Bogoroditskoye, Orel, Russia",
  "Oneida County, New York, United States",
  "652, Wiesbaden, Hesse, Germany",
  "Montgomery County, Ohio, United States",
  "347803, Kamensk-Shakhtinskiy, Rostov, Russia",
  "98816, Chelan, Washington, United States",
  "Mirnyy, Sakha, Russia",
  "Myshkinsky District, Yaroslavl’, Russia",
  "303852, Livny, Orel, Russia",
  "Juaban Ii, Kampung Teiraplu, Papua, Indonesia",
  "7011, Trondheim, Trøndelag, Norway",
  "Morristown, New Jersey, United States",
  "SN8 1QR, Beckhampton, England, United Kingdom",
  "Portugal",
  "185005, Petrozavodsk, Karelia, Russia",
  "9650, Kavarna, Dobrich, Bulgaria",
  "352919, Armavir, Krasnodar, Russia",
  "Pendleton, Indiana, United States",
  "Fredericton, New Brunswick, Canada",
  "Vance, South Carolina, United States",
  "Tomaszowski, Łódzkie, Poland",
  "4760-519, Vila Nova de Famalicão, Braga, Portugal",
  "Älvkarleby kommun, Uppsala County, Sweden",
  "Koszaliński, Zachodniopomorskie, Poland",
  "Craven Walk, London, England, United Kingdom",
  "301873, Yefremov, Tula, Russia",
  "Sumner, Christchurch, Canterbury, New Zealand",
  "76800, Saint-Étienne-du-Rouvray, Normandy, France",
  "Sheridan, Montana, United States",
  "Hayesville, Oregon, United States",
  "Golem, Fier, Albania",
  "Davie Avenue/Broad Street Historic District, Statesville, North Carolina, United States",
  "59850, Tekirdağ, Tekirdağ, Türkiye",
  "624575, Ivdel’, Sverdlovsk, Russia",
  "02914, East Providence, Rhode Island, United States",
  "Hopkins, Missouri, United States",
  "08015, Barcelona, Catalonia, Spain",
  "SS1 2PG, Southend-On-Sea, England, United Kingdom",
  "Tlaltizapán de Zapata, Morelos, Mexico",
  "90001, Los Angeles, California, United States",
  "Roberts, Wisconsin, United States",
  "413725, Pugachëv, Saratov, Russia",
  "20144, Milan, Lombardy, Italy",
  "Oreland, Pennsylvania, United States",
  "22041, Falls Church, Virginia, United States",
  "243011, Novozybkov, Bryansk, Russia",
  "Hancock, New York, United States",
  "Garfield, New Jersey, United States",
  "Houston, Texas, United States",
  "Port-Gentil, Ogooué-Maritime Province, Gabon",
  "la glaciere, Tiaret, Tiaret, Algeria",
  "Ağlasun, Burdur, Türkiye",
  "Divide, Montana, United States",
  "Čepin, Osijek-Baranja, Croatia",
  "Stanton, Nebraska, United States",
  "640023, Kurgan, Kurgan, Russia",
  "Thomas County, Kansas, United States",
  "353640, Yeyskoye Ukrepleniye, Krasnodar, Russia",
  "Hamilton, Bermuda",
  "44700, Guadalajara, Jalisco, Mexico",
  "Leer, Lower Saxony, Germany",
  "Amherst, Nova Scotia, Canada",
  "Kendall Hills, San Bernardino, California, United States",
  "Güneyyurt, Karaman, Türkiye",
  "45138, Guadalajara, Jalisco, Mexico",
  "241010, Bryansk, Bryansk, Russia",
  "96270-000, Mostardas, Rio Grande do Sul, Brazil",
  "E02D0P3, Atyrau, Atyrau Region, Kazakhstan",
  "79117, Freiburg, Baden-Württemberg, Germany",
  "Radford, England, United Kingdom",
  "Sarnen, Obwalden, Switzerland",
  "Lane, England, United Kingdom",
  "Travis Park, Ensley, Florida, United States",
  "St Petersburg City, Russia",
  "08013, Barcelona, Catalonia, Spain",
  "17331, Hanover, Pennsylvania, United States",
  "Punjab, Pakistan",
  "Columbus, Michigan, United States",
  "Yoakum, Texas, United States",
  "26155, New Martinsville, West Virginia, United States",
  "Storey Park, Orlando, Florida, United States",
  "Brovary Raion, Kyiv, Ukraine",
  "Desnogorsk, Smolensk, Russia",
  "Lunenburg, Arkansas, United States",
  "455030, Magnitogorsk, Chelyabinsk, Russia",
  "309756, Rzhevka, Belgorod, Russia",
  "617073, Krasnokamsk, Perm, Russia",
  "33574, Dade City, Florida, United States",
  "49002, Portage, Michigan, United States",
  "EH12 6AB, Edinburgh, Scotland, United Kingdom",
  "Coal, Kecamatan Kuwus, East Nusa Tenggara, Indonesia",
  "Pamplona, Central Visayas, Philippines",
  "White County, Tennessee, United States",
  "59111, Billings, Montana, United States",
  "TS1 4DF, Middlesbrough, England, United Kingdom",
  "50200, Nevsehir, Nevşehir, Türkiye",
  "Meagher, Halifax, Nova Scotia, Canada",
  "1060, Skopje, Skopje Statistical Region, North Macedonia",
  "188960, Svetogorsk, Leningrad, Russia",
  "74048, Nowata, Oklahoma, United States",
  "7046, Trondheim, Trøndelag, Norway",
  "45459, Dayton, Ohio, United States",
  "Bergen, Limburg, Netherlands",
  "Isle-Saint-Georges, Nouvelle-Aquitaine, France",
  "68842, Greeley, Nebraska, United States",
  "Ivano-Frankivska miska hromada, Ivano-Frankivsk, Ukraine",
  "San Sebastián, Aragua State, Venezuela",
  "Vance, North Carolina, United States",
  "Monroe, New York, United States",
  "29010-900, Vitória, Espírito Santo, Brazil",
  "Pender, Nebraska, United States",
  "184682, Polyarnyy, Murmansk, Russia",
  "301382, Aleksin, Kaluga, Russia",
  "27154, Otero de Rey, Galicia, Spain",
  "Potigny, Normandy, France",
  "Alcobaça, Leiria, Portugal",
  "Roata de Jos, Giurgiu, Romania",
  "20146, Milan, Lombardy, Italy",
  "Hofors kommun, Gävleborg County, Sweden",
  "Tierp, Uppsala County, Sweden",
  "98040, Mercer Island, Washington, United States",
  "47882, Sullivan, Indiana, United States",
  "Sandoval, Taytay, Mimaropa, Philippines",
  "09131, Cagliari, Sardinia, Italy",
  "688868, Ayanka, Koryak, Russia",
  "2625, Lillehammer, Innlandet, Norway",
  "Chelyuskinskoye, Tyumen’, Russia",
  "64200, Biarritz, Nouvelle-Aquitaine, France",
  "Patratu, India",
  "Pulaski County, Missouri, United States",
  "H01T2B7, Taraz, Jambyl Region, Kazakhstan",
  "Prairie County, Arkansas, United States",
  "Nodaway County, Missouri, United States",
  "Elk Grove, California, United States",
  "30124, Venice, Veneto, Italy",
  "9010, Varna, Varna, Bulgaria",
  "73566, Snyder, Oklahoma, United States",
  "2855-216, Seixal, Setúbal, Portugal",
  "68062, Thurston, Nebraska, United States",
  "456873, Kyshtym, Chelyabinsk, Russia",
  "29009, Málaga, Andalusia, Spain",
  "423464, Almetyevsk, Tatarstan, Russia",
  "Steuben, Wisconsin, United States",
  "364049, Groznyy, Chechnya, Russia",
  "97970-959, Roque Gonzales, Rio Grande do Sul, Brazil",
  "664009, Irkutsk, Irkutsk, Russia",
  "G6B 1A2, Nantes, Quebec, Canada",
  "24700, Erzincan, Erzincan, Türkiye",
  "92240, Desert Hot Springs, California, United States",
  "38090, Kayseri, Kayseri, Türkiye",
  "Hotton, Walloon Region, Belgium",
  "Oak Creek, Lake Oswego, Oregon, United States",
  "14051, East Amherst, New York, United States",
  "Wallisellen / Hof, Zurich, Switzerland",
  "54153, Oconto, Wisconsin, United States",
  "582 31, Linköping, Östergötland County, Sweden",
  "45426, Tateposco, Jalisco, Mexico",
  "624270, Asbest, Sverdlovsk, Russia",
  "364013, Groznyy, Chechnya, Russia",
  "TS1 3DZ, Middlesbrough, England, United Kingdom",
  "173024, Velikiy Novgorod, Novgorod, Russia",
  "689425, Egvekinot, Chukot, Russia",
  "Rensselaer, Indiana, United States",
  "310013, Arad, Arad, Romania",
  "181327, Vorontsovo, Pskov, Russia",
  "53533, Dodgeville, Wisconsin, United States",
  "36361, Ozark, Alabama, United States",
  "Hamilton, Alabama, United States",
  "Prince Edward County, Virginia, United States",
  "Ulaanbaatar Hot, Mongolia",
  "North Monmouth, Maine, United States",
  "9583, Slopedown, New Zealand",
  "Greater Rivne Area",
  "Ugol’nyye Kopi, Chukot, Russia",
  "35078, Harpersville, Alabama, United States",
  "Montague Villas, New Providence, The Bahamas",
  "433508, Dimitrovgrad, Ul’yanovsk, Russia",
  "Safranbolu, Karabuk, Türkiye",
  "Beirut Governorate, Lebanon",
  "Ozark Estates North, Township 11, Arkansas, United States",
  "238151, Chernyakhovskiy, Kaliningrad, Russia",
  "43952, Steubenville, Ohio, United States",
  "Perkin's Village, England, United Kingdom",
  "VLT 1110, Valletta, Valletta, Malta",
  "8500-809, Portimão, Faro, Portugal",
  "Cambridge, Vermont, United States",
  "412421, Atkarsk, Saratov, Russia",
  "Story County, Iowa, United States",
  "Vernon, Texas, United States",
  "626102, Tobolsk, Tyumen’, Russia",
  "71013, Viljandi, Viljandimaa, Estonia",
  "Waupaca, Wisconsin, United States",
  "2500, Kyustendil, Kyustendil, Bulgaria",
  "Dupnitsa Municipality, Kyustendil, Bulgaria",
  "309296, Shebekino, Belgorod, Russia",
  "44-108, Gliwice, Śląskie, Poland",
  "54007, Mykolaiv, Mykolayiv, Ukraine",
  "Otterberg, Rhineland-Palatinate, Germany",
  "Rosebud Park, Mississippi, United States",
  "Custer, Michigan, United States",
  "7709, Steinkjer, Trøndelag, Norway",
  "F63C1B5, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "Portsmouth, Rhode Island, United States",
  "EMEA",
  "Divinópolis, Minas Gerais, Brazil",
  "Brussels, Brussels Region, Belgium",
  "Volosskoye, Dnipropetrovsk, Ukraine",
  "224009, Brest, Brest, Belarus",
  "825100, Babadag, Tulcea, Romania",
  "Blue Earth, Minnesota, United States",
  "238321, Gur'yevsk, Kaliningrad, Russia",
  "Kyzyl-Tayga, Tuva, Russia",
  "9651, Hammerfest, Troms og Finnmark, Norway",
  "Mytilini, Northern Aegean, Greece",
  "9500-020, Ponta Delgada, Portugal",
  "Greater Cheshire West and Chester Area",
  "Mexico",
  "S13E6H3, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Leek, Groningen, Netherlands",
  "364052, Groznyy, Chechnya, Russia",
  "Lunenburg, Nova Scotia, Canada",
  "249652, Kirsanovo-Pyatnitsa, Kaluga, Russia",
  "Wendell Estates, Umatilla, Florida, United States",
  "Uşak, Uşak, Türkiye",
  "Peterborough, New Hampshire, United States",
  "Buffalo-Niagara Falls Area",
  "94304, Palo Alto, California, United States",
  "184402, Nikel, Murmansk, Russia",
  "641216, Yurgamysh, Kurgan, Russia",
  "95123, Catania, Sicily, Italy",
  "57510, Savonlinna, South Savo, Finland",
  "351 10, Lamía, Central Greece, Greece",
  "Wormeldange, Grevenmacher, Luxembourg",
  "CO16 0AA, Thorpe-Le-Soken, England, United Kingdom",
  "First Street Historic District, Menominee, Michigan, United States",
  "Colnia Ernesto Herrera Zavala, Durango, Mexico",
  "Socorro County, New Mexico, United States",
  "Pointe-Brûlée, New Brunswick, Canada",
  "78629, Gonzales, Texas, United States",
  "Trempealeau County, Wisconsin, United States",
  "243140, Klintsy, Bryansk, Russia",
  "V3H 1T5, Port Moody, British Columbia, Canada",
  "K2P 0A1, Ottawa, Ontario, Canada",
  "Nolanville, Texas, United States",
  "Hopewell, Pennsylvania, United States",
  "13460-000, Nova Odessa, São Paulo, Brazil",
  "8011 XK, Zwolle, Overijssel, Netherlands",
  "172517, Nelidovo, Tver’, Russia",
  "KA7 1AA, Ayr, Scotland, United Kingdom",
  "14110, Wiset Chai Chan district, Ang Thong, Thailand",
  "Szczecinek County, Zachodniopomorskie, Poland",
  "692413, Kavalerovo, Primorye, Russia",
  "262 21, Patras, Western Greece, Greece",
  "5896, Dolni Dabnik, Pleven, Bulgaria",
  "Gryazinsky District, Lipetsk, Russia",
  "Chouteau, Lussac, Nouvelle-Aquitaine, France",
  "Conchounia, Ostabat-Asme, Nouvelle-Aquitaine, France",
  "Buchanan, Wisconsin, United States",
  "24382, Wytheville, Virginia, United States",
  "Greater Manchester, England, United Kingdom",
  "33706, St Petersburg, Florida, United States",
  "S21 3YL, Spinkhill, England, United Kingdom",
  "Wayne, New Jersey, United States",
  "Dixons Mills, Alabama, United States",
  "97120, Saint-Claude, Basse-Terre, Guadeloupe",
  "30017, Grayson, Georgia, United States",
  "Galati-Braila Metropolitan Area",
  "13900, Biella, Piedmont, Italy",
  "62400, Tunceli, Tunceli, Türkiye",
  "71010, Viljandi, Viljandimaa, Estonia",
  "504 34, Borås, Västra Götaland County, Sweden",
  "Ovacık, Karabuk, Türkiye",
  "Middlesbrough, England, United Kingdom",
  "Gemeente Boxtel, North Brabant, Netherlands",
  "7280, Tsar Kaloyan Municipality, Razgrad, Bulgaria",
  "Provincia Ángel Sandóval, Santa Cruz, Bolivia",
  "McPherson, Greenwich, New Brunswick, Canada",
  "Silverthorne, Colorado, United States",
  "Smolensk Metropolitan Area",
  "Óbidos, Leiria, Portugal",
  "676124, Magdagachi, Amur, Russia",
  "Plymouth, Ohio, United States",
  "Bayou Ridge, Hitchcock, Texas, United States",
  "Radford, Nottingham, England, United Kingdom",
  "Buchanan, Tennessee, United States",
  "Greater Sioux Falls Area",
  "Tarrant County, Texas, United States",
  "Fronteira, Minas Gerais, Brazil",
  "96320, Rovaniemi, Lapland, Finland",
  "Gorkovskiy gorodskoy sovet, Mahilyow, Belarus",
  "Sherman, Kansas, United States",
  "95224, Avery, California, United States",
  "403889, Kamyshin, Volgograd, Russia",
  "TR18 4DU, Penzance, England, United Kingdom",
  "Vinnytsya, Vinnytsya, Ukraine",
  "3073 AA, Rotterdam, South Holland, Netherlands",
  "Göttingen, Lower Saxony, Germany",
  "Lyudinovo, Kaluga, Russia",
  "Powell, Missouri, United States",
  "Little Genesee, New York, United States",
  "Cimarron, Asheville, North Carolina, United States",
  "47226, Duisburg, North Rhine-Westphalia, Germany",
  "IP13 0HZ, Pettistree, England, United Kingdom",
  "54726, Boyd, Wisconsin, United States",
  "Erzurum, Türkiye",
  "Greater Innsbruck",
  "Humboldt, Kansas, United States",
  "Nizhniy Yenangsk, Vologda, Russia",
  "74107, Tulsa, Oklahoma, United States",
  "Mirnyy, Chelyabinsk, Russia",
  "C25D0P7, Makinsk, Akmola Region, Kazakhstan",
  "21006, Narva, Ida-Virumaa, Estonia",
  "Mir, Hrodna, Belarus",
  "823001, Gaya, Bihar, India",
  "03244, Hillsborough, New Hampshire, United States",
  "E1A 6X4, Dieppe, New Brunswick, Canada",
  "IP4 1AD, Ipswich, England, United Kingdom",
  "620034, Focşani, Vrancea, Romania",
  "29336, Spartanburg, South Carolina, United States",
  "Poltavska Miska Hromada, Poltava, Ukraine",
  "Patratu, Jharkhand, India",
  "4839, Arendal, Agder, Norway",
  "M28B1C6, Temirtau, Karaganda Region, Kazakhstan",
  "Quitman, Arkansas, United States",
  "Tomah, Wisconsin, United States",
  "Sherman, Maine, United States",
  "Villa Hayes, Presidente Hayes, Paraguay",
  "80809, Munich, Bavaria, Germany",
  "42020, Konya, Konya, Türkiye",
  "Zhytomyr, Zhytomyr, Ukraine",
  "Tsentralny City District, Krasnodar, Russia",
  "Bursa, Türkiye",
  "33351, Fort Lauderdale, Florida, United States",
  "80101, Joensuu, North Karelia, Finland",
  "72903, Fort Smith, Arkansas, United States",
  "08034, Cherry Hill, New Jersey, United States",
  "Dunn, North Carolina, United States",
  "Strasburg, Ohio, United States",
  "Daviet, Saint-Hilaire-du-Maine, Pays de la Loire, France",
  "F91 FK82, Sligo, County Sligo, Ireland",
  "04007, Almería, Andalusia, Spain",
  "38863, Pontotoc, Mississippi, United States",
  "390048, Ryazan, Ryazan’, Russia",
  "Familia Zavala Guzmán, Baja California, Mexico",
  "65000, Tarbes, Occitanie, France",
  "Sawyer, Oklahoma, United States",
  "613051, Kirovo-Chepetsk, Kirov, Russia",
  "Edgefield, England, United Kingdom",
  "Uptown, Kenosha, Wisconsin, United States",
  "30606, Athens, Georgia, United States",
  "Sharpsburg, Georgia, United States",
  "Chitalwana, Rajasthan, India",
  "E7L 1L2, Divide, New Brunswick, Canada",
  "8500-069, Portimão, Faro, Portugal",
  "Surry Hills, New South Wales, Australia",
  "1216, Tetovo, Polog, North Macedonia",
  "Barabinskiy, Kurgan, Russia",
  "655153, Chernogorsk, Khakassia, Russia",
  "Coventry, England, United Kingdom",
  "Chelan County, Washington, United States",
  "16626, Vertiyevka, Chernihiv, Ukraine",
  "658210, Rubtsovsk, Altai Krai, Russia",
  "Floyd County, Texas, United States",
  "Rockingham County, Virginia, United States",
  "75765, Hawkins, Texas, United States",
  "S13E5P7, Ekibastuz, Pavlodar Region, Kazakhstan",
  "344068, Rostov, Rostov, Russia",
  "Alapayevskiy Rayon, Sverdlovsk, Russia",
  "Les Anges Bertières, Fomperron, Nouvelle-Aquitaine, France",
  "73071, Norman, Oklahoma, United States",
  "Arad, South District, Israel",
  "800694, Galaţi, Galaţi, Romania",
  "164501, Severodvinsk, Arkhangel’sk, Russia",
  "420140, Kazan, Tatarstan, Russia",
  "Decatur, Texas, United States",
  "Dijon, Bourgogne-Franche-Comté, France",
  "Cherlak, Bashkortostan, Russia",
  "Lensky District, Sakha, Russia",
  "49260, Umbaúba, Sergipe, Brazil",
  "9380, Aalborg, North Denmark Region, Denmark",
  "Greater Stockholm Metropolitan Area",
  "Santander de Quilichao, Cauca, Colombia",
  "80012, Aurora, Colorado, United States",
  "Emmons County, North Dakota, United States",
  "Gorod Vichuga, Ivanovo, Russia",
  "413727, Pugachëv, Saratov, Russia",
  "665771, Vikhorevka, Irkutsk, Russia",
  "Bradley Beach, New Jersey, United States",
  "Toledo, Oregon, United States",
  "Jim Hogg County, Texas, United States",
  "R95 P46P, Kilkenny, County Kilkenny, Ireland",
  "347370, Volgodonsk, Rostov, Russia",
  "Johanniskreuz, Trippstadt, Rhineland-Palatinate, Germany",
  "10-443, Olsztyn, Warmińsko-mazurskie, Poland",
  "Gallatin County, Kentucky, United States",
  "63129, St Louis, Missouri, United States",
  "Qazax, Qazakh-Tovuz, Azerbaijan",
  "Bucharest, Bucharest, Romania",
  "23211, Zrenjanin, Vojvodina, Serbia",
  "Meilen, Zurich, Switzerland",
  "Morris Plains, New Jersey, United States",
  "Ust’-Labinsk, Krasnodar, Russia",
  "Lowndes County, Alabama, United States",
  "Williamson, West Virginia, United States",
  "Atlantic Beach, Florida, United States",
  "Industriepark Böllinger Höfe, Heilbronn, Baden-Württemberg, Germany",
  "546 34, Thessaloniki, Central Macedonia, Greece",
  "Salford Quays, Salford, England, United Kingdom",
  "Wakefield, Massachusetts, United States",
  "Ames, Iowa, United States",
  "Siirt, Türkiye",
  "461049, Buzuluk, Orenburg, Russia",
  "Warsaw, Illinois, United States",
  "Lander, Wyoming, United States",
  "80160, Joensuu, North Karelia, Finland",
  "Middlesex County, Virginia, United States",
  "659311, Biysk, Altai Krai, Russia",
  "Vvedensko-Slobodskoye, Tatarstan, Russia",
  "Arsen’yevo, Tula, Russia",
  "75001, Addison, Texas, United States",
  "Caen, Normandy, France",
  "54937, Fond du Lac, Wisconsin, United States",
  "Wakefield, England, United Kingdom",
  "53154, Oak Creek, Wisconsin, United States",
  "16502, Erie, Pennsylvania, United States",
  "La Chapelle aux Pies, Bouillé-Ménard, Pays de la Loire, France",
  "Stark County, Ohio, United States",
  "Merzifon Bucağı, Amasya, Türkiye",
  "Millard, Plémy, Brittany, France",
  "Bakalauri, Province No. 1, Nepal",
  "15950, Lahti, Päijät-Häme, Finland",
  "53403, Racine, Wisconsin, United States",
  "BT78 5PN, Omagh, Northern Ireland, United Kingdom",
  "11010, Cadiz, Andalusia, Spain",
  "Karabuk, Türkiye",
  "110040, Piteşti, Argeş, Romania",
  "Chioselia Mare, Cahul District, Moldova",
  "169601, Pechora, Komi, Russia",
  "624475, Severoural’sk, Sverdlovsk, Russia",
  "Butler, Georgia, United States",
  "Perkins, Ohio, United States",
  "47249, Duisburg, North Rhine-Westphalia, Germany",
  "05700, Amasya, Amasya, Türkiye",
  "629758, Nadym, Yamalo-Nenets, Russia",
  "4410, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "Gibson, North Carolina, United States",
  "142412, Noginsk, Moscow, Russia",
  "Nizhny Novgorod, Nizhniy Novgorod, Russia",
  "309273, Shebekino, Belgorod, Russia",
  "NE1 1AD, Newcastle Upon Tyne, England, United Kingdom",
  "Beed, Maharashtra, India",
  "981 35, Kiruna, Norrbotten County, Sweden",
  "Jurupa Valley, California, United States",
  "Niagara, New York, United States",
  "Whatcombe, Blandford Forum, England, United Kingdom",
  "34120, Istanbul, Istanbul, Türkiye",
  "456311, Miass, Chelyabinsk, Russia",
  "Morrill County, Nebraska, United States",
  "88268, Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Leatherhead, England, United Kingdom",
  "03054, Merrimack, New Hampshire, United States",
  "Belashtitsa, Plovdiv, Bulgaria",
  "NR24 2AX, Edgefield Street, England, United Kingdom",
  "KA18 2RU, Ochiltree, Scotland, United Kingdom",
  "624239, Nizhnyaya Tura, Sverdlovsk, Russia",
  "East Chautauqua, Colorado, United States",
  "99096, Erfurt, Thuringia, Germany",
  "Reeves, Louisiana, United States",
  "624760, Verkhnyaya Salda, Sverdlovsk, Russia",
  "636461, Kolpashevo, Tomsk, Russia",
  "Polk County, Texas, United States",
  "The Presidios at Rancho Sahuarita, Sahuarita, Arizona, United States",
  "490 84, Kérkira, Ionian Islands, Greece",
  "Sioux County, Iowa, United States",
  "162626, Cherepovets, Vologda, Russia",
  "7242, Bitola, Pelagonia, North Macedonia",
  "Atlántico, Colombia",
  "32102, Čačak, Centralna Srbija, Serbia",
  "Stockholm, New Jersey, United States",
  "Monroe County, Pennsylvania, United States",
  "Korsakovsky District, Orel, Russia",
  "Randolph, Utah, United States",
  "Hardingstone, Northampton, England, United Kingdom",
  "Martin County, North Carolina, United States",
  "Delta, British Columbia, Canada",
  "Nicey-sur-Aire, Grand Est, France",
  "46060, Noblesville, Indiana, United States",
  "77807, Bryan, Texas, United States",
  "Blair, Nebraska, United States",
  "KW15 1HF, Kirkwall, Scotland, United Kingdom",
  "Le Bois Smyth, Bridoré, Centre-Val de Loire, France",
  "085100, Bolintin-Vale, Giurgiu, Romania",
  "Greater Strasbourg Metropolitan Area",
  "Murray, Iowa, United States",
  "500009, Hyderabad, Telangana, India",
  "Carrollton, Georgia, United States",
  "6721, Wallareenya, Western Australia, Australia",
  "15-440, Białystok, Podlaskie, Poland",
  "SM1 1AA, Sutton, England, United Kingdom",
  "ZE1 0QD, Lerwick, Scotland, United Kingdom",
  "Merzifon, Amasya, Türkiye",
  "453124, Sterlitamak, Bashkortostan, Russia",
  "71154, Berdyansk, Zaporizhzhya, Ukraine",
  "666673, Ust'-Ilimsk, Irkutsk, Russia",
  "McCormick, North Glengarry, Ontario, Canada",
  "420066, Kazan, Tatarstan, Russia",
  "Oktyabrsky District, Kalmykia, Russia",
  "23006, Jaén, Andalusia, Spain",
  "241 31, Kalamata, Peloponnese, Greece",
  "352127, Tikhoretsk, Krasnodar, Russia",
  "Rogers, Texas, United States",
  "101, Reykjavík, Capital Region, Iceland",
  "F56 D593, Ballymote, County Sligo, Ireland",
  "Lefke Belediyesi, Nicosia, Cyprus",
  "70-783, Szczecin, Zachodniopomorskie, Poland",
  "Pervouralsk, Sverdlovsk, Russia",
  "16510, Erie, Pennsylvania, United States",
  "Lincoln, Alabama, United States",
  "Ransom, Pennsylvania, United States",
  "Mason City, Illinois, United States",
  "Armstrong, Santa Fe, Argentina",
  "Nolan Hill, Calgary, Alberta, Canada",
  "Senec, Bratislava, Slovakia",
  "Lucas, Texas, United States",
  "Lincoln, Nebraska, United States",
  "682036, Chegdomyn, Khabarovsk, Russia",
  "672038, Chita, Zabaykalsky Krai, Russia",
  "Nancefield Industrial, Johannesburg, Gauteng, South Africa",
  "C40A1F9, Esil, Akmola Region, Kazakhstan",
  "Roseau River, Manitoba, Canada",
  "04318, Leipzig, Saxony, Germany",
  "624473, Severoural’sk, Sverdlovsk, Russia",
  "399771, Yelets, Lipetsk, Russia",
  "Kleberg, Dallas, Texas, United States",
  "BT26 6HX, Hillsborough, Northern Ireland, United Kingdom",
  "28056, Gastonia, North Carolina, United States",
  "Stokesby, England, United Kingdom",
  "300701, Timişoara, Timiş, Romania",
  "03467, Westmoreland, New Hampshire, United States",
  "Columbus, Mississippi, United States",
  "Pocahontas, Illinois, United States",
  "04207, Leipzig, Saxony, Germany",
  "20040, Denizli, Denizli, Türkiye",
  "25661, Williamson, West Virginia, United States",
  "39610, El Astillero, Cantabria, Spain",
  "Carroll County, New Hampshire, United States",
  "San Polo dei Cavalieri, Latium, Italy",
  "Genesee, Leduc County, Alberta, Canada",
  "658214, Rubtsovsk, Altai Krai, Russia",
  "341 32, Chalkida, Central Greece, Greece",
  "Kimry, Tver’, Russia",
  "Addison Township, Illinois, United States",
  "McClain Ridge at Woods Creek, Bentonville, Arkansas, United States",
  "38125, Kayseri, Kayseri, Türkiye",
  "164769, Mezen, Arkhangel’sk, Russia",
  "901 87, Umeå, Västerbotten County, Sweden",
  "Slobodskoy sel'skiy sovet, Minsk Province, Belarus",
  "96460, Rovaniemi, Lapland, Finland",
  "Saint-Étienne-d’Albagnan, Occitanie, France",
  "Kirov, Kaluga, Russia",
  "74330, Adair, Oklahoma, United States",
  "27703, Durham, North Carolina, United States",
  "3302, Gramsh, Elbasan, Albania",
  "Greenfield, Oldham, England, United Kingdom",
  "446115, Chapayevsk, Samara, Russia",
  "621 42, Visby, Gotland County, Sweden",
  "Roberts County, South Dakota, United States",
  "96200, Rovaniemi, Lapland, Finland",
  "22030, Fairfax, Virginia, United States",
  "F63D4P5, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "Darlington Point, New South Wales, Australia",
  "188800, Vyborg, Leningrad, Russia",
  "10200, Balıkesir, Balıkesir, Türkiye",
  "30110, Hakkâri, Hakkâri, Türkiye",
  "Levoča, Presov, Slovakia",
  "675016, Blagoveshchensk, Amur, Russia",
  "665902, Slyudyanka, Irkutsk, Russia",
  "Big Rock, Arkansas, United States",
  "H01X5C1, Taraz, Jambyl Region, Kazakhstan",
  "55305, Hopkins, Minnesota, United States",
  "Northern Frontier, Saudi Arabia",
  "Shirali, Qashqadaryo Region, Uzbekistan",
  "Le Millard, Jazennes, Nouvelle-Aquitaine, France",
  "B6720BLB, San Andrés de Giles, Buenos Aires Province, Argentina",
  "692048, Lesozavodsk, Primorye, Russia",
  "Saline, Louisiana, United States",
  "Milambert, Lingèvres, Normandy, France",
  "426009, Izhevsk, Udmurtia, Russia",
  "01880, Wakefield, Massachusetts, United States",
  "28803, Asheville, North Carolina, United States",
  "23148, Saint Stephens Church, Virginia, United States",
  "Roosevelt, Arizona, United States",
  "Stephenson County, Illinois, United States",
  "1490, Court-Saint-Étienne, Walloon Region, Belgium",
  "Tura, Meghalaya, India",
  "130011, Cartagena, Bolívar, Colombia",
  "35173, Panevėžys, Panevėžio, Lithuania",
  "Charlotte, Vermont, United States",
  "Drumcru (Dickson), County Monaghan, Ireland",
  "46720, Kahramanmaraş, Kahraman Maras, Türkiye",
  "Freestone, Queensland, Australia",
  "Leicester, New York, United States",
  "Martins Ferry, Ohio, United States",
  "32424, Blountstown, Florida, United States",
  "11530, Garden City, New York, United States",
  "67356, Oswego, Kansas, United States",
  "Sionne, Grand Est, France",
  "658920, Kulunda, Altai Krai, Russia",
  "Namur Metropolitan Area",
  "Beaufort, Missouri, United States",
  "Kyzylsky District, Tuva, Russia",
  "Westmoreland, New York, United States",
  "Greater Chemnitz Area",
  "215505, Safonovo, Smolensk, Russia",
  "Toulonjac, Occitanie, France",
  "Greenville, Pennsylvania, United States",
  "Santiago, Santiago Metropolitan Region, Chile",
  "Traille, Saint-Benin-d’Azy, Bourgogne-Franche-Comté, France",
  "Murmansk Metropolitan Area",
  "Otero, Castrillo de Cabrera, Castilla and Leon, Spain",
  "907 50, Umeå, Västerbotten County, Sweden",
  "Artashat, Ararat Province, Armenia",
  "Phelps County, Nebraska, United States",
  "Randolph County, West Virginia, United States",
  "Sainte-Adèle, Quebec, Canada",
  "30500, Molina de Segura, Región de Murcia, Spain",
  "Lamar, Mississippi, United States",
  "28915-000, Cabo Frio, Rio de Janeiro, Brazil",
  "Rodniki, Ivanovo, Russia",
  "34149, Trieste, Friuli-Venezia Giulia, Italy",
  "Kırıkkale, Kırıkkale, Türkiye",
  "McKean, Ohio, United States",
  "Aohan Banner, Inner Mongolia, China",
  "Frankfurt, Hesse, Germany",
  "Białystok, Podlaskie, Poland",
  "Cherokee County, Texas, United States",
  "Bergen County, New Jersey, United States",
  "Stoneville, North Carolina, United States",
  "26004, Logroño, Autonomía de La Rioja, Spain",
  "Boxing County, Shandong, China",
  "1395, Drammen, Viken, Norway",
  "Hampshire, England, United Kingdom",
  "Little London, Westmoreland, Jamaica",
  "EH11 1AA, Edinburgh, Scotland, United Kingdom",
  "FY3 8EN, Blackpool, England, United Kingdom",
  "11010, Belgrade, Serbia",
  "Batesburg-Leesville, South Carolina, United States",
  "Guarda, Guarda, Portugal",
  "61124, Kharkiv, Kharkiv, Ukraine",
  "Adams Estate, Victoria, Australia",
  "March, Schwyz, Switzerland",
  "41002, Seville, Andalusia, Spain",
  "686134, Palatka, Magadan, Russia",
  "15884, Santiago de Compostela, Galicia, Spain",
  "Galaxias, Chiapas, Mexico",
  "Bakałarzewo, Podlaskie, Poland",
  "Wyoming County, West Virginia, United States",
  "Fayetteville, Tennessee, United States",
  "Crawford, Tennessee, United States",
  "Platonovka, Tambov, Russia",
  "71500, Kırıkkale, Kırıkkale, Türkiye",
  "43917, Dillonvale, Ohio, United States",
  "Dzerzhinsky District, Kaluga, Russia",
  "662161, Achinsk, Krasnoyarsk Krai, Russia",
  "Oneida, Iowa, United States",
  "Millard, Chatuzange-le-Goubet, Auvergne-Rhône-Alpes, France",
  "Cookstown, New Jersey, United States",
  "44269, Dortmund, North Rhine-Westphalia, Germany",
  "347936, Taganrog, Rostov, Russia",
  "51109, Rijeka, Primorje-Gorski, Croatia",
  "Louisa, Kentucky, United States",
  "664002, Irkutsk, Irkutsk, Russia",
  "Viana do Castelo, Portugal",
  "C01Y0G9, Kokshetau, Akmola Region, Kazakhstan",
  "60-461, Poznan, Wielkopolskie, Poland",
  "Burket, Indiana, United States",
  "2603, Lillehammer, Innlandet, Norway",
  "Kyzyl Adyr, Talas Region, Kyrgyzstan",
  "Handy, Michigan, United States",
  "Mercer, Wisconsin, United States",
  "Warsaw, Ohio, United States",
  "Kiruna kommun, Norrbotten County, Sweden",
  "CF10 1AA, Cardiff, Wales, United Kingdom",
  "Stadt Hannover, Lower Saxony, Germany",
  "23502, Norfolk, Virginia, United States",
  "Pershing County, Nevada, United States",
  "Wascott, Wisconsin, United States",
  "666791, Ust-Kut, Irkutsk, Russia",
  "Ivanovskoye, Ivanovo, Russia",
  "Las Nueces, Hidalgo, Mexico",
  "Mozambique",
  "Eau Claire-Menomonie Area",
  "141570, Solnechnogorsk, Moscow, Russia",
  "403877, Kamyshin, Volgograd, Russia",
  "Garden Hills, Champaign, Illinois, United States",
  "Shumikha, Kurgan, Russia",
  "Williamsburg, Massachusetts, United States",
  "Belo Horizonte, Minas Gerais, Brazil",
  "Lučenec, Banska Bystrica, Slovakia",
  "Pukeatua, Waikato, New Zealand",
  "Laurens, Saint-Martin-d’Oney, Nouvelle-Aquitaine, France",
  "Plattenville, Louisiana, United States",
  "72471, Swifton, Arkansas, United States",
  "6475, Molde, Møre og Romsdal, Norway",
  "Lewisburg, West Virginia, United States",
  "Zhilinda, Sakha, Russia",
  "Sovetskiy, Minsk, Belarus",
  "Sovetskiy, Khanty-Mansi, Russia",
  "693022, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Rensselaer, New York, United States",
  "Faulkner, Manitoba, Canada",
  "San Francisco de Conchos, Chihuahua, Mexico",
  "800234, Galaţi, Galaţi, Romania",
  "Chase, British Columbia, Canada",
  "Stearns County, Minnesota, United States",
  "H01M9F1, Taraz, Jambyl Region, Kazakhstan",
  "Tăşnad, Satu Mare, Romania",
  "Peel, Ontario, Canada",
  "90508, Torrance, California, United States",
  "Sargento Cabral, Cordoba, Córdoba, Argentina",
  "172510, Nelidovo, Tver’, Russia",
  "Saarbrücken, Saarland, Germany",
  "21217, Baltimore, Maryland, United States",
  "Trinity Palmetto Point, Saint Kitts, Saint Kitts and Nevis",
  "PH33 6TY, Fort William, Scotland, United Kingdom",
  "S10 1AE, Sheffield, England, United Kingdom",
  "Kirovskoye, Dnipropetrovsk, Ukraine",
  "42774, Konya, Konya, Türkiye",
  "97220, Rovaniemi, Lapland, Finland",
  "48165, Münster, North Rhine-Westphalia, Germany",
  "Skamania County, Washington, United States",
  "Dăneasa, Olt, Romania",
  "4057, Basel, Basel, Switzerland",
  "Inverness, Subdivision B, Nova Scotia, Canada",
  "623414, Kamensk Uralskiy, Sverdlovsk, Russia",
  "655162, Chernogorsk, Khakassia, Russia",
  "Barabinsky District, Novosibirsk, Russia",
  "623302, Krasnoufimsk, Sverdlovsk, Russia",
  "Oliveira do Hospital, Coimbra, Portugal",
  "85900-005, Toledo, Paraná, Brazil",
  "Judith Cândido de Andrade, Lavras, Minas Gerais, Brazil",
  "Lucas County, Ohio, United States",
  "Mount Edgecombe North, Durban, KwaZulu-Natal, South Africa",
  "Vodice, Sibenik-Knin, Croatia",
  "Marin-Epagnier, Neuchâtel, Switzerland",
  "Randallstown, Maryland, United States",
  "Mazgirt, Tunceli, Türkiye",
  "Bogoroditskoye, Smolensk, Russia",
  "Mecklenburg-Strelitz, Mecklenburg-West Pomerania, Germany",
  "652870, Mezhdurechensk, Kemerovo, Russia",
  "2340, Pernik, Pernik, Bulgaria",
  "52400, Ordu, Ordu, Türkiye",
  "Sion, Maharashtra, India",
  "Vitimskiy, Irkutsk, Russia",
  "658219, Rubtsovsk, Altai Krai, Russia",
  "La Millardière, Heussé, Normandy, France",
  "Redwood, Wigan, England, United Kingdom",
  "Belmont, Michigan, United States",
  "Belfast, Prince Edward Island, Canada",
  "18011, Granada, Andalusia, Spain",
  "Montenegro",
  "Buckingham, Iowa, United States",
  "47080, Mardin, Mardin, Türkiye",
  "717060, Copălău, Botoşani, Romania",
  "Poshekhonsky District, Yaroslavl’, Russia",
  "Greater Victoria Metropolitan Area",
  "Metro Manila",
  "65128, Pescara, Abruzzi, Italy",
  "Petropavlovsk-Kamchatskiy, Kamchatka, Russia",
  "47051, Duisburg, North Rhine-Westphalia, Germany",
  "Garfield County, Utah, United States",
  "Livingston County, Missouri, United States",
  "Militello in Val di Catania, Sicily, Italy",
  "SR4 6LZ, Sunderland, England, United Kingdom",
  "Christmas Island",
  "356412, Blagodarnyy, Stavropol, Russia",
  "Fort Beaufort, Eastern Cape, South Africa",
  "231284, Lida, Hrodna, Belarus",
  "Davisville, West Virginia, United States",
  "Algeciras, Andalusia, Spain",
  "7823, Namsos, Trøndelag, Norway",
  "Colfax, Iowa, United States",
  "Sewards End, England, United Kingdom",
  "Columbia, South Carolina Metropolitan Area",
  "Arkhangelsk Metropolitan Area",
  "Bryansk, Bryansk, Russia",
  "Ürgüp, Nevşehir, Türkiye",
  "Greater Exeter Area",
  "LV-5414, Daugavpils, Daugavpils, Latvia",
  "671 10, Xánthi, Eastern Macedonia and Thrace, Greece",
  "658480, Zmeinogorsk, Altai Krai, Russia",
  "6200-506, Covilhã, Castelo Branco, Portugal",
  "Coahoma, Mississippi, United States",
  "04500, Agri, Ağrı, Türkiye",
  "75964, Nacogdoches, Texas, United States",
  "33720, Tampere, Pirkanmaa, Finland",
  "Niederurnen, Glarus, Switzerland",
  "LE12 6QG, East Leake, England, United Kingdom",
  "Saint Ann, Jamaica",
  "423403, Almetyevsk, Tatarstan, Russia",
  "Porirua City, Wellington, New Zealand",
  "Jakarta, Indonesia",
  "5001, Berat, Berat, Albania",
  "Bayfield Manor, James Island, South Carolina, United States",
  "Departamento San Justo, Córdoba, Argentina",
  "39200, Kirklareli, Kırklareli, Türkiye",
  "Laurensberg, Aachen, North Rhine-Westphalia, Germany",
  "B29E3Y3, Esik, Almaty Region, Kazakhstan",
  "Burrells, England, United Kingdom",
  "Crawfordville, Florida, United States",
  "42038, Eddyville, Kentucky, United States",
  "Mato Grosso do Sul, Brazil",
  "Paralía, Central Macedonia, Greece",
  "618202, Chusovoy, Perm, Russia",
  "62034, Glen Carbon, Illinois, United States",
  "90506, Torrance, California, United States",
  "Kastamonu, Kastamonu, Türkiye",
  "Taldykorgan, Jetisu Region, Kazakhstan",
  "Oğuzlar, Denizli, Türkiye",
  "Gorod Kyshtym, Chelyabinsk, Russia",
  "665724, Bratsk, Irkutsk, Russia",
  "Fulton County, New York, United States",
  "Aral Dalniy, Chuy Region, Kyrgyzstan",
  "371 31, Karlskrona, Blekinge County, Sweden",
  "Frederikshøj, Region of Southern Denmark, Denmark",
  "13800, Hämeenlinna, Kanta-Häme, Finland",
  "Tralee, County Kerry, Ireland",
  "Cavalier, Apt, Provence-Alpes-Côte d'Azur, France",
  "185 47, Piraeus, Attiki, Greece",
  "Lamoure, Prayssas, Nouvelle-Aquitaine, France",
  "Piute County, Utah, United States",
  "Madrid, Cundinamarca, Colombia",
  "Volsky District, Saratov, Russia",
  "Strafford County, New Hampshire, United States",
  "Subotica, Vojvodina, Serbia",
  "Ridder, East Kazakhstan Region, Kazakhstan",
  "86-310, Grudziądz, Kujawsko-pomorskie, Poland",
  "Jimma, Oromia Region, Ethiopia",
  "Basel Metropolitan Area",
  "Woodward Pointe, Charleston, South Carolina, United States",
  "Galveston County, Texas, United States",
  "Ellisville, Illinois, United States",
  "Major Sales, Rio Grande do Norte, Brazil",
  "41, Modena, Emilia-Romagna, Italy",
  "HP17 0XN, Little Kimble, England, United Kingdom",
  "Cartagena del Chairá, Caquetá, Colombia",
  "353910, Novorossiysk, Krasnodar, Russia",
  "Angersleigh, England, United Kingdom",
  "Potter, New York, United States",
  "Taraz, Jambyl Region, Kazakhstan",
  "Rhea County, Tennessee, United States",
  "391302, Kasimov, Ryazan’, Russia",
  "3074 AA, Rotterdam, South Holland, Netherlands",
  "450017, Zalău, Sălaj, Romania",
  "Sunflower Gardens, Bloomington, Indiana, United States",
  "36782, Sweet Water, Alabama, United States",
  "Mezentsevskiy, Altai Krai, Russia",
  "Chautauqua Park, Waterloo, Iowa, United States",
  "Udine, Friuli-Venezia Giulia, Italy",
  "Union, New Jersey, United States",
  "67100, Strasbourg, Grand Est, France",
  "Orne, Normandy, France",
  "25000, Besançon, Bourgogne-Franche-Comté, France",
  "977 51, Luleå, Norrbotten County, Sweden",
  "75682, Overton, Texas, United States",
  "49942, Calumet, Michigan, United States",
  "Perry, Florida, United States",
  "185016, Petrozavodsk, Karelia, Russia",
  "Durham, Ontario, Canada",
  "Greenville, New Hampshire, United States",
  "020331, Bucharest, Bucharest, Romania",
  "678185, Chernyshevskiy, Sakha, Russia",
  "WR10 2JU, Throckmorton, England, United Kingdom",
  "Greenbrier, Charlottesville, Virginia, United States",
  "Vacoas, Plaines Wilhems District, Mauritius",
  "HP17 9TX, Great Kimble, England, United Kingdom",
  "Rockland, Delaware, United States",
  "Cattaraugus County, New York, United States",
  "620146, Yekaterinburg, Sverdlovsk, Russia",
  "81122, Bayfield, Colorado, United States",
  "Price, Utah, United States",
  "Bennington County, Vermont, United States",
  "61530, Eureka, Illinois, United States",
  "Blue Ridge, Texas, United States",
  "Millsap, Texas, United States",
  "08534, Pennington, New Jersey, United States",
  "627306, Golyshmanovo, Tyumen’, Russia",
  "Mozdok, Severnaya Osetiya-Alaniya, Russia",
  "Queens, Nova Scotia, Canada",
  "Wood County, Wisconsin, United States",
  "2354, Maribor, Maribor, Slovenia",
  "Gaget, Monlezun, Occitanie, France",
  "Móstoles, Community of Madrid, Spain",
  "Bakalinsky District, Bashkortostan, Russia",
  "Bradley, Wales, United Kingdom",
  "9026, Győr, Győr-Moson-Sopron, Hungary",
  "54481, Stevens Point, Wisconsin, United States",
  "44141, Dortmund, North Rhine-Westphalia, Germany",
  "Nikola Kozlevo Municipality, Shumen, Bulgaria",
  "29510, Andrews, South Carolina, United States",
  "Baleares, Balearic Islands, Spain",
  "461042, Buzuluk, Orenburg, Russia",
  "Holmesdale, Dronfield, England, United Kingdom",
  "663282, Severo-Yeniseyskiy, Krasnoyarsk Krai, Russia",
  "Georgetown, Kentucky, United States",
  "27587, Wake Forest, North Carolina, United States",
  "Miller Creek, Missoula, Montana, United States",
  "Keyat Khanda, Falta, West Bengal, India",
  "Grayson County, Kentucky, United States",
  "Nevada City, California, United States",
  "Oymyakon, Sakha, Russia",
  "Borlänge kommun, Dalarna County, Sweden",
  "453831, Sibay, Bashkortostan, Russia",
  "823002, Gaya, Bihar, India",
  "Cafelândia, São Paulo, Brazil",
  "56083, Sanborn, Minnesota, United States",
  "110281, Piteşti, Argeş, Romania",
  "34109, Naples, Florida, United States",
  "T0K 0B0, Barnwell, Alberta, Canada",
  "61411, Adair, Illinois, United States",
  "694905, Uglegorsk, Sakhalin, Russia",
  "1150, Brussels, Brussels Region, Belgium",
  "55346, Eden Prairie, Minnesota, United States",
  "78118, Karnes City, Texas, United States",
  "UB4 0AA, Hayes, England, United Kingdom",
  "Camenca, Moldova",
  "Knox County, Kentucky, United States",
  "Nuremberg, Bavaria, Germany",
  "690090, Vladivostok, Primorye, Russia",
  "Teluk Intan, Perak, Malaysia",
  "9006, Tromsø, Troms og Finnmark, Norway",
  "Brookenby, England, United Kingdom",
  "College Hill, Hutchinson, Kansas, United States",
  "Comox, British Columbia, Canada",
  "211387, Orsha, Vitsyebsk, Belarus",
  "Meghalaya, India",
  "North Olmsted, Ohio, United States",
  "Stantonsburg, North Carolina, United States",
  "430414, Baia Mare, Maramureş, Romania",
  "Chesterfield, Michigan, United States",
  "404102, Volzhskiy, Volgograd, Russia",
  "Värnamo, Jönköping County, Sweden",
  "91055, Luhansk, Luhansk, Ukraine",
  "Byala Slatina, Vratsa, Bulgaria",
  "Zherdevka, Tambov, Russia",
  "28054, Gastonia, North Carolina, United States",
  "Chester, England, United Kingdom",
  "L4 0RA, Liverpool, England, United Kingdom",
  "55716, Calumet, Minnesota, United States",
  "Yemanzhelinka, Chelyabinsk, Russia",
  "Minnehaha, Minneapolis, Minnesota, United States",
  "86670, Comalcalco, Tabasco, Mexico",
  "602264, Murom, Vladimir, Russia",
  "09120, Chemnitz, Saxony, Germany",
  "Provideniya, Chukot, Russia",
  "211 44, Malmo, Skåne County, Sweden",
  "Strugi-Krasnyye, Pskov, Russia",
  "871 31, Härnösand, Västernorrland County, Sweden",
  "Merrylands, New South Wales, Australia",
  "Isperih Municipality, Razgrad, Bulgaria",
  "Rubtsovsky District, Altai Krai, Russia",
  "Stanton, Delaware, United States",
  "450056, Ufa, Bashkortostan, Russia",
  "Coos South, County Galway, Ireland",
  "Korsakov, Sakhalin, Russia",
  "Plaza Central de Suiqui Milamilani, Suiqui Milamilani, La Paz, Bolivia",
  "CF14 0HY, Cardiff, Wales, United Kingdom",
  "Bydgoszcz, Kujawsko-pomorskie, Poland",
  "62600, Tunceli, Tunceli, Türkiye",
  "Veszprém, Veszprém, Hungary",
  "Gazi, Crete, Greece",
  "A91 K584, Dundalk, County Louth, Ireland",
  "625002, Tyumen, Tyumen’, Russia",
  "Langlade, Saint-Lary, Occitanie, France",
  "76827, Brookesmith, Texas, United States",
  "Rockwall County, Texas, United States",
  "Greater Reims Area",
  "Hancock County, Mississippi, United States",
  "184046, Kandalaksha, Murmansk, Russia",
  "Töle bï awdanı, Turkistan Region, Kazakhstan",
  "Henrico, North Carolina, United States",
  "Greater Limoges Area",
  "6001, Gjirokastër, Gjirokastër, Albania",
  "Ironton, Missouri, United States",
  "75000, Paris, Île-de-France, France",
  "Alexander, Kansas, United States",
  "386101, Nazran, Ingushetia, Russia",
  "Nolans Ridge, New Jersey, United States",
  "Graham, Oklahoma, United States",
  "9500, General Toshevo, Dobrich, Bulgaria",
  "Triple Springs Subdivision, Columbiana, Alabama, United States",
  "636785, Strezhevoy, Tomsk, Russia",
  "93428, Cambria, California, United States",
  "51537, Harlan, Iowa, United States",
  "O'Fallon, Missouri, United States",
  "71834, Doddridge, Arkansas, United States",
  "Semeykinskoye, Ivanovo, Russia",
  "352122, Tikhoretsk, Krasnodar, Russia",
  "Huntingdon, Fayetteville, Arkansas, United States",
  "3101, Salgótarján, Nógrád, Hungary",
  "30800, Lorca, Región de Murcia, Spain",
  "Eruh, Siirt, Türkiye",
  "27690, Raleigh, North Carolina, United States",
  "Espinho, Aveiro, Portugal",
  "Lithuania",
  "Gregg, North Cypress-Langford, Manitoba, Canada",
  "Milan, Lombardy, Italy",
  "Kelkit, Gümüşhane, Türkiye",
  "677014, Yakutsk, Sakha, Russia",
  "Hanover, Pennsylvania, United States",
  "490, Qabalan, West Bank",
  "Greenwood, Louisiana, United States",
  "311, Borgarnes, Western Region, Iceland",
  "Chita, Aichi, Japan",
  "Waushara County, Wisconsin, United States",
  "Burgas, Bulgaria",
  "607230, Arzamas, Nizhniy Novgorod, Russia",
  "WF1 1AA, Wakefield, England, United Kingdom",
  "Clarence-Rockland, Ontario, Canada",
  "Kandalaksha, Murmansk, Russia",
  "221 00, Malmo, Skåne County, Sweden",
  "Ponte do Lima, Viana do Castelo, Portugal",
  "606418, Balakhna, Nizhniy Novgorod, Russia",
  "Mineral, Missouri, United States",
  "Scott County, Tennessee, United States",
  "Kokshetau, Akmola Region, Kazakhstan",
  "Doddridge, Arkansas, United States",
  "Karlstad, Minnesota, United States",
  "Luxembourg, Luxembourg, Luxembourg",
  "663690, Zelenogorsk, Krasnoyarsk Krai, Russia",
  "SN15 4LG, Langley Burrell, England, United Kingdom",
  "Monmouth, Iowa, United States",
  "Rennes-les-Bains, Occitanie, France",
  "Alexander, Ohio, United States",
  "2548 BB, The Hague, South Holland, Netherlands",
  "Cërrik, Elbasan, Albania",
  "462353, Novotroitsk, Orenburg, Russia",
  "Starrkirch-Wil, Solothurn, Switzerland",
  "Greater Charlottesville Area",
  "Novotroitskoye, Tatarstan, Russia",
  "51004, Ceuta, Ceuta, Spain",
  "Comanche Park, Tulsa, Oklahoma, United States",
  "456234, Zlatoust, Chelyabinsk, Russia",
  "630041, Novosibirsk, Novosibirsk, Russia",
  "960 02, Zvolen, Banska Bystrica, Slovakia",
  "Goldendale, Washington, United States",
  "A92 K8X0, Drogheda, Ireland",
  "F18K5X0, Semey, Abay Region, Kazakhstan",
  "644031, Omsk, Omsk, Russia",
  "Meigs, Ohio, United States",
  "28201, Bremen, Bremen, Germany",
  "Bolsheselsky District, Yaroslavl’, Russia",
  "70115, New Orleans, Louisiana, United States",
  "78631, Harper, Texas, United States",
  "94537, Fremont, California, United States",
  "6700, Szeged, Csongrád, Hungary",
  "Kapan, Syunik Province, Armenia",
  "692864, Partizansk, Primorye, Russia",
  "Timiryazevskoye, Chelyabinsk, Russia",
  "Humboldt, Iowa, United States",
  "40549, Düsseldorf, North Rhine-Westphalia, Germany",
  "Okha, Edo State, Nigeria",
  "Washita County, Oklahoma, United States",
  "76230, Bowie, Texas, United States",
  "636462, Kolpashevo, Tomsk, Russia",
  "Braxton Estates, North Carolina, United States",
  "Penza Metropolitan Area",
  "Williamsburg, Kansas, United States",
  "14476, Potsdam, Brandenburg, Germany",
  "Lake Mills, Wisconsin, United States",
  "49082, Osnabrück, Lower Saxony, Germany",
  "Spartanburg, South Carolina, United States",
  "663604, Kansk, Krasnoyarsk Krai, Russia",
  "Hoofddorp-Toolenburg-Zuid, Hoofddorp, North Holland, Netherlands",
  "Sussex, New Jersey, United States",
  "Nev'yansk, Sverdlovsk, Russia",
  "93049, Regensburg, Bavaria, Germany",
  "Moore Haven, Florida, United States",
  "Cherkasy Metropolitan Area",
  "32783, Titusville, Florida, United States",
  "Woodward Heights, Lexington, Kentucky, United States",
  "Sunny Slope, Whitestone, Ontario, Canada",
  "613046, Kirovo-Chepetsk, Kirov, Russia",
  "Voznesenskiy, Rostov, Russia",
  "678750, Oymyakon, Sakha, Russia",
  "623428, Kamensk Uralskiy, Sverdlovsk, Russia",
  "Aberdeen, Scotland, United Kingdom",
  "50002, Zaragoza, Aragon, Spain",
  "142438, Noginsk, Moscow, Russia",
  "Almeida, Guarda, Portugal",
  "36202, Vigo, Galicia, Spain",
  "Greater Shumen Area",
  "Matochkin Shar, Arkhangel’sk, Russia",
  "Colfax, Indiana, United States",
  "Natrona, Pennsylvania, United States",
  "Şarkikaraağaç, Isparta, Türkiye",
  "Cambridge, Minnesota, United States",
  "La Forest-Landerneau, Brittany, France",
  "Mountrail County, North Dakota, United States",
  "C19B7X7, Zhaltyr, Akmola Region, Kazakhstan",
  "66549, Westmoreland, Kansas, United States",
  "694434, Aleksandrovsk-Sakhalinskiy, Sakhalin, Russia",
  "47510, Mardin, Mardin, Türkiye",
  "R13D6C9, Beyneu, Mangystau Region, Kazakhstan",
  "Alfalfa, Casco Antiguo, Andalusia, Spain",
  "Sheridan, Michigan, United States",
  "Pamlico County, North Carolina, United States",
  "Y25 A500, Gorey, County Wexford, Ireland",
  "Sheridan County, Montana, United States",
  "60200, Tokat, Tokat, Türkiye",
  "24050, Erzincan, Erzincan, Türkiye",
  "Newton-Le-Willows, England, United Kingdom",
  "Sullivan, Indiana, United States",
  "Greeley, Nebraska, United States",
  "2469, Haystack, New South Wales, Australia",
  "5015, Poliçan, Berat, Albania",
  "665729, Bratsk, Irkutsk, Russia",
  "76023, Boyd, Texas, United States",
  "02895, Woonsocket, Rhode Island, United States",
  "Batam, Riau Islands, Indonesia",
  "Stewart, British Columbia, Canada",
  "623430, Kamensk Uralskiy, Sverdlovsk, Russia",
  "31001, Abbeville, Georgia, United States",
  "C00G7T1, Kokshetau, Akmola Region, Kazakhstan",
  "E7K 2P6, Gregg Settlement, New Brunswick, Canada",
  "Petropavlovka, Chelyabinsk, Russia",
  "Cayuga, North Dakota, United States",
  "1005, Lausanne, Vaud, Switzerland",
  "Vasylivka Raion, Zaporizhzhya, Ukraine",
  "6901, Lugano, Ticino, Switzerland",
  "Jackson, Tennessee, United States",
  "Clarkes Beach, Newfoundland and Labrador, Canada",
  "Craighead County, Arkansas, United States",
  "MD-3909, Cahul, Cahul District, Moldova",
  "Trenton, Ohio, United States",
  "97068, West Linn, Oregon, United States",
  "18006, Granada, Andalusia, Spain",
  "Poznan, Wielkopolskie, Poland",
  "BOSQUE, Sucre, Colombia",
  "Clarke County, Mississippi, United States",
  "80015, Aurora, Colorado, United States",
  "6706, Arlon, Walloon Region, Belgium",
  "Alekseyevka, Voronezh, Russia",
  "Sullivan, Ohio, United States",
  "07070, Rutherford, New Jersey, United States",
  "GY2 4HU, St Sampson, Guernsey, Guernsey",
  "S0N 0B1, Tompkins, Saskatchewan, Canada",
  "Grady/Antoine, Atlanta, Georgia, United States",
  "423251, Leninogorsk, Tatarstan, Russia",
  "Pierce County, North Dakota, United States",
  "4303, Padre Burgos, Calabarzon, Philippines",
  "93437, Furth im Wald, Bavaria, Germany",
  "East Haakon, South Dakota, United States",
  "9900-039, Horta, Portugal",
  "Dallas County, Iowa, United States",
  "Gorodskoy Okrug Mtsensk, Orel, Russia",
  "62349, Loraine, Illinois, United States",
  "Smyth County, Virginia, United States",
  "Floyd County, Indiana, United States",
  "72901, Fort Smith, Arkansas, United States",
  "Williamson, North Carolina, United States",
  "Walker County, Texas, United States",
  "35017, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "700 13, Iráklion, Crete, Greece",
  "Trinity County, California, United States",
  "04159, Leipzig, Saxony, Germany",
  "662153, Achinsk, Krasnoyarsk Krai, Russia",
  "86502, Chambers, Arizona, United States",
  "Antigua and Barbuda",
  "Coleshill, England, United Kingdom",
  "Providencia, Santiago Metropolitan Region, Chile",
  "La Nance, Jalisco, Mexico",
  "36212, Vigo, Galicia, Spain",
  "X38H2M0, Kentau, Turkistan Region, Kazakhstan",
  "Mortágua, Vale de Remígio, Cortegaça e Almaça, Viseu, Portugal",
  "Archangel, Arkhangel’sk, Russia",
  "5024, Namur, Walloon Region, Belgium",
  "Aikens, Colchester, Subdivision A, Nova Scotia, Canada",
  "Monongalia County, West Virginia, United States",
  "Coleman Falls, Virginia, United States",
  "Potters Bar, England, United Kingdom",
  "Kirsanovka, Orenburg, Russia",
  "Hickory, Arkansas, United States",
  "Dillon, Saskatchewan, Canada",
  "M52D0F0, Osakarovka, Karaganda Region, Kazakhstan",
  "Monmouth Junction, New Jersey, United States",
  "Camden, South Carolina, United States",
  "357926, Zelenokumsk, Stavropol, Russia",
  "260, Keflavík, Southern Peninsula, Iceland",
  "41012, Seville, Andalusia, Spain",
  "43065, Powell, Ohio, United States",
  "Eure, Normandy, France",
  "152916, Rybinsk, Yaroslavl’, Russia",
  "231294, Lida, Hrodna, Belarus",
  "46018, Valencia, Valencian Community, Spain",
  "55252, Wiesbaden, Hesse, Germany",
  "Uptown McCulloch Main Street, Lake Havasu City, Arizona, United States",
  "4640, Kristiansand, Agder, Norway",
  "424020, Yoshkar-Ola, Mari El, Russia",
  "831 37, Östersund, Jämtland County, Sweden",
  "78606, Blanco, Texas, United States",
  "Baniyas, Tartus Governorate, Syria",
  "27713, Durham, North Carolina, United States",
  "45152, Morrow, Ohio, United States",
  "20820, Deva, Basque Country, Spain",
  "72758, Rogers, Arkansas, United States",
  "Medina, Marrakesh-Safi, Morocco",
  "30100, Hakkâri, Hakkâri, Türkiye",
  "853, Sundsvall, Västernorrland County, Sweden",
  "800261, Galaţi, Galaţi, Romania",
  "31100, Toulouse, Occitanie, France",
  "Vila Clarion, Rondonópolis, Mato Grosso, Brazil",
  "Torrance, California, United States",
  "20457, Hamburg, Hamburg, Germany",
  "Plastiras, Thessalia, Greece",
  "Crawford County, Ohio, United States",
  "Livingston, Tennessee, United States",
  "462429, Orsk, Orenburg, Russia",
  "Pittsylvania County, Virginia, United States",
  "82-302, Elbląg, Warmińsko-mazurskie, Poland",
  "Madison County, Kentucky, United States",
  "27944, Hertford, North Carolina, United States",
  "15610, Lahti, Päijät-Häme, Finland",
  "7241, Bitola, Pelagonia, North Macedonia",
  "692524, Ussuriysk, Primorye, Russia",
  "678118, Olyokminsk, Sakha, Russia",
  "220047, Minsk, Minsk, Belarus",
  "33521, Coleman, Florida, United States",
  "Gorodskoy Okrug Kasimov, Ryazan’, Russia",
  "Zugdidi, Georgia",
  "28300, Giresun, Giresun, Türkiye",
  "39600, Potoky, Poltava, Ukraine",
  "Kampung Keyagalo, Papua, Indonesia",
  "Greater Drohobych Area",
  "665710, Bratsk, Irkutsk, Russia",
  "33761, Clearwater, Florida, United States",
  "62972, Ozark, Illinois, United States",
  "Santa Cruz de la Sierra, Santa Cruz, Bolivia",
  "Laurens, Marignac-Lasclares, Occitanie, France",
  "Walworth, New York, United States",
  "Dodges Ferry, Tasmania, Australia",
  "84127, Salerno, Campania, Italy",
  "Logan County, Illinois, United States",
  "Buffalo County, Nebraska, United States",
  "Kayes, Kayes, Mali",
  "G0Y 0A1, Nantes, Quebec, Canada",
  "Winchester, Virginia, United States",
  "907, Fürth, Bavaria, Germany",
  "Bostandıq District, Almaty, Kazakhstan",
  "Astillé, Pays de la Loire, France",
  "Reeves Plains, South Australia, Australia",
  "Trinity County, Texas, United States",
  "Glacier County, Montana, United States",
  "15105-000, Potirendaba, São Paulo, Brazil",
  "Uberlândia, Minas Gerais, Brazil",
  "50231, Randall, Iowa, United States",
  "Seekirchen Markt, Salzburg, Austria",
  "Greater Gaziantep",
  "Scottsburg, Indiana, United States",
  "53031, Sheboygan Falls, Wisconsin, United States",
  "820076, Tulcea, Tulcea, Romania",
  "Cavalier, Sigoyer, Provence-Alpes-Côte d'Azur, France",
  "Galanta, Trnava Region, Slovakia",
  "7901, Rørvik, Trøndelag, Norway",
  "Kırşehir, Türkiye",
  "Esch-sur-Alzette, Luxembourg, Luxembourg",
  "H34G8K6, Qulan, Jambyl Region, Kazakhstan",
  "Lunenburg, Massachusetts, United States",
  "Golden Valley, Arizona, United States",
  "Huntingdon, England, United Kingdom",
  "Daniels, West Virginia, United States",
  "74078, Stillwater, Oklahoma, United States",
  "Biysk, Altai Krai, Russia",
  "Mahnomen, Minnesota, United States",
  "Greater Stoke-on-Trent Area",
  "F39C3H4, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Greenwood, Western Australia, Australia",
  "Çerkezköy, Tekirdağ, Türkiye",
  "Nadym, Yamalo-Nenets, Russia",
  "Greater Umeå Metropolitan Area",
  "44720, Guadalajara, Jalisco, Mexico",
  "Booneville, Mississippi, United States",
  "59453, Judith Gap, Montana, United States",
  "San Cesario di Lecce, Apulia, Italy",
  "Sátão, Viseu, Portugal",
  "430003, Saransk, Mordovia, Russia",
  "Gaston, Hostens, Nouvelle-Aquitaine, France",
  "2400, Radomir, Pernik, Bulgaria",
  "Allendale, Arvada, Colorado, United States",
  "24019, Roanoke, Virginia, United States",
  "DY4 0AG, Tipton, England, United Kingdom",
  "Stoke Poges, England, United Kingdom",
  "Malatya, Türkiye",
  "Tvrdošín, Zilina, Slovakia",
  "08320, Vireux-Wallerand, Grand Est, France",
  "9406 RR, Assen, Drenthe, Netherlands",
  "725500, Siret, Suceava, Romania",
  "Benton, Kentucky, United States",
  "9500-036, Ponta Delgada, Portugal",
  "Crockett, Arkansas, United States",
  "42109, Wuppertal, North Rhine-Westphalia, Germany",
  "72335, Forrest City, Arkansas, United States",
  "Progresso, Rio Grande do Sul, Brazil",
  "Hampton City County, Virginia, United States",
  "Kargatsky District, Novosibirsk, Russia",
  "40211, Düsseldorf, North Rhine-Westphalia, Germany",
  "Sereď, Trnava Region, Slovakia",
  "Mikhaylovka, Cherkasy, Ukraine",
  "2983 AA, Ridderkerk, South Holland, Netherlands",
  "Hot Spring County, Arkansas, United States",
  "Niue",
  "719, Caddo, Arkansas, United States",
  "428009, Cheboksary, Chuvashia, Russia",
  "Dover Gardens, South Australia, Australia",
  "80-034, Gdańsk, Pomorskie, Poland",
  "VLT, Valletta, Valletta, Malta",
  "Kuçovë, Berat, Albania",
  "4633, Kristiansand, Agder, Norway",
  "Collingwood, Victoria, Australia",
  "Makat District, Atyrau Region, Kazakhstan",
  "Franklin, North Carolina, United States",
  "Niagara, Wisconsin, United States",
  "Russellville, Kentucky, United States",
  "75-847, Koszalin, Zachodniopomorskie, Poland",
  "Pipestone, Manitoba, Canada",
  "Henderson, Nevada, United States",
  "646100, Nazyvayevsk, Omsk, Russia",
  "Brno - město, South Moravia, Czechia",
  "Robertson Ranch, Carlsbad, California, United States",
  "3371 AL, Hardinxveld-Giessendam, South Holland, Netherlands",
  "658082, Novoaltaysk, Altai Krai, Russia",
  "CT7 0AA, St Nicholas At Wade, England, United Kingdom",
  "188810, Vyborg, Leningrad, Russia",
  "Holtville, California, United States",
  "Desa Hokeng Jaya, Kecamatan Wulanggitang, East Nusa Tenggara, Indonesia",
  "Binney, Pembina, Manitoba, Canada",
  "7304, Pogradec, Korçë, Albania",
  "Blenheim, Marlborough, New Zealand",
  "186223, Kondopoga, Karelia, Russia",
  "Warwat Bakal, Maharashtra, India",
  "Chapayevskoye, Omsk, Russia",
  "Royal Leamington Spa, England, United Kingdom",
  "Wise County, Texas, United States",
  "79060, Lviv, Lviv, Ukraine",
  "Sissach, Basel-Country, Switzerland",
  "Erie, Illinois, United States",
  "851 10, Bratislava, Bratislava, Slovakia",
  "357110, Nevinnomyssk, Stavropol, Russia",
  "Cassowary Coast, Queensland, Australia",
  "47201, Draganić, Karlovac, Croatia",
  "68550, Kokkola, Central Ostrobothnia, Finland",
  "poti, Georgia",
  "9060-108, Funchal, Madeira Island, Portugal",
  "33202, Gijón, Principality of Asturias, Spain",
  "Okmulgee County, Oklahoma, United States",
  "670 62, Xánthi, Eastern Macedonia and Thrace, Greece",
  "Mihai Eminescu, Botoşani, Romania",
  "Cascade, Idaho, United States",
  "Midland, Texas, United States",
  "Dawson County, Montana, United States",
  "81379, Munich, Bavaria, Germany",
  "Oldenburg-Land, Schleswig-Holstein, Germany",
  "Pamplin City, Virginia, United States",
  "Sitiawan, Perak, Malaysia",
  "Rankinston, Scotland, United Kingdom",
  "Sokołowski, Mazowieckie, Poland",
  "Nelidovo, Tver’, Russia",
  "630099, Novosibirsk, Novosibirsk, Russia",
  "2327, Rače, Maribor, Slovenia",
  "Fort William 52, Ontario, Canada",
  "Malaysia",
  "220019, Minsk, Minsk, Belarus",
  "Santarém, Portugal",
  "502001, Sangareddi, Telangana, India",
  "Rice County, Minnesota, United States",
  "99095, Erfurt, Thuringia, Germany",
  "29100, Gumushane, Gümüşhane, Türkiye",
  "Clermont-l’Hérault, Occitanie, France",
  "Shushufindi, Sucumbíos, Ecuador",
  "LE5 3HF, Leicester, England, United Kingdom",
  "35230, İzmir, İzmir, Türkiye",
  "7801, Namsos, Trøndelag, Norway",
  "658835, Slavgorod, Altai Krai, Russia",
  "Dover, New Jersey, United States",
  "17224, Fort Loudon, Pennsylvania, United States",
  "Simpsonville, North Carolina, United States",
  "0470261, Otaru, Hokkaido, Japan",
  "Lincoln County, North Carolina, United States",
  "Dublin 14, County Dublin, Ireland",
  "Sarapul'skiy, Udmurtia, Russia",
  "Monmouth County, New Jersey, United States",
  "24635, Pocahontas, Virginia, United States",
  "Vyazniki, Vladimir, Russia",
  "Arlington, Minnesota, United States",
  "Karaman, Zonguldak, Türkiye",
  "L02P6P2, Oral, West Kazakhstan Region, Kazakhstan",
  "Leesburg, Virginia, United States",
  "Artur Nogueira, São Paulo, Brazil",
  "Cosoba, Giurgiu, Romania",
  "CV5 6AA, Coventry, England, United Kingdom",
  "Lipova, Arad, Romania",
  "Wuppertal, North Rhine-Westphalia, Germany",
  "Jefferson County, Wisconsin, United States",
  "15520-000, Valentim Gentil, São Paulo, Brazil",
  "Harmony, Pennsylvania, United States",
  "7818, Namsos, Trøndelag, Norway",
  "24104, Subotica, Vojvodina, Serbia",
  "Kostanay, Kostanay Region, Kazakhstan",
  "Mason, Wisconsin, United States",
  "658207, Rubtsovsk, Altai Krai, Russia",
  "9009, Varna, Varna, Bulgaria",
  "412901, Vol'sk, Saratov, Russia",
  "D02P1Y7, Актобе, Aqtöbe region, Kazakhstan",
  "Crowle, England, United Kingdom",
  "Minot, North Dakota, United States",
  "456209, Zlatoust, Chelyabinsk, Russia",
  "2000, Oslo, Oslo, Norway",
  "658847, Slavgorod, Altai Krai, Russia",
  "19702, Newark, Delaware, United States",
  "Staunton On Arrow, England, United Kingdom",
  "617077, Krasnokamsk, Perm, Russia",
  "İskenderun, Hatay, Türkiye",
  "184042, Kandalaksha, Murmansk, Russia",
  "618351, Kizel, Perm, Russia",
  "14766, Cattaraugus, New York, United States",
  "Atkarsky District, Saratov, Russia",
  "Ponte da Barca, Viana do Castelo, Portugal",
  "Stoneham, Massachusetts, United States",
  "Sinj, Split-Dalmatia, Croatia",
  "Tipton, Pennsylvania, United States",
  "Broadfield, Saundersfoot, Wales, United Kingdom",
  "Jamestown, Rhode Island, United States",
  "8930, Nova Zagora, Sliven, Bulgaria",
  "Cluj-Napoca metropolitan area, Cluj, Romania",
  "694908, Uglegorsk, Sakhalin, Russia",
  "Shuya, Karelia, Russia",
  "Plast, Chelyabinsk, Russia",
  "430082, Baia Mare, Maramureş, Romania",
  "652 24, Karlstad, Värmland County, Sweden",
  "Greater Konya",
  "PA16 0EQ, Greenock, Scotland, United Kingdom",
  "73014, Calumet, Oklahoma, United States",
  "36248, Panevėžys, Panevėžio, Lithuania",
  "43359, Wharton, Ohio, United States",
  "Saluda, Indiana, United States",
  "20180, Lovettsville, Virginia, United States",
  "Parmeraan, Dolok, North Sumatra, Indonesia",
  "23600, Elâzığ, Elazığ, Türkiye",
  "S2508CZT, Armstrong, Santa Fe, Argentina",
  "Emery Heights, Howey in the Hills, Florida, United States",
  "130001, Cartagena, Bolívar, Colombia",
  "Fillmore County, Minnesota, United States",
  "Verona, New Jersey, United States",
  "Port Saunders, Newfoundland and Labrador, Canada",
  "185033, Petrozavodsk, Karelia, Russia",
  "Olsztyn, Śląskie, Poland",
  "Eddyville, Oregon, United States",
  "Sawgrass, Champaign, Illinois, United States",
  "DE5 3AA, Ripley, England, United Kingdom",
  "57710, Savonlinna, South Savo, Finland",
  "99803, Juneau, Alaska, United States",
  "Coosa County, Alabama, United States",
  "CV37 8JT, Clifford Chambers, England, United Kingdom",
  "32796, Titusville, Florida, United States",
  "92007, Cardiff-by-the-Sea, California, United States",
  "Meades Quarry, Knoxville, Tennessee, United States",
  "Madison, Indiana, United States",
  "Caldwell, England, United Kingdom",
  "70123, New Orleans, Louisiana, United States",
  "781, Höfn, Southern Region, Iceland",
  "Betim, Minas Gerais, Brazil",
  "260, Logroño, Autonomía de La Rioja, Spain",
  "Abakanovskoye, Vologda, Russia",
  "85-796, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "3200, Byala Slatina Municipality, Vratsa, Bulgaria",
  "Bogorodchany, Ivano-Frankivsk, Ukraine",
  "M1S 0A1, Scarborough, Ontario, Canada",
  "Pothia, Bihar, India",
  "Lviv, Ukraine",
  "Kirsinga, Adamawa State, Nigeria",
  "Geneva, Alabama, United States",
  "Sullivan County, Missouri, United States",
  "Walworth, Wisconsin, United States",
  "Oktyabr’, Yaroslavl’, Russia",
  "Belfast Metropolitan Area",
  "SA3 4RH, Caswell, Wales, United Kingdom",
  "2025 XE, Haarlem, North Holland, Netherlands",
  "164750, Mezen, Arkhangel’sk, Russia",
  "S25B8K8, Ertis, Pavlodar Region, Kazakhstan",
  "06239, Danielson, Connecticut, United States",
  "Wilkins Road, Mangum, North Carolina, United States",
  "Guardamar del Segura, Valencian Community, Spain",
  "Svobodnyy, Sverdlovsk, Russia",
  "59917, Eureka, Montana, United States",
  "AB55 5JZ, Keith, Scotland, United Kingdom",
  "80632, Greeley, Colorado, United States",
  "06050, Ankara, Ankara, Türkiye",
  "602267, Murom, Vladimir, Russia",
  "394088, Voronezh, Voronezh, Russia",
  "Clermont, Hauts-de-France, France",
  "48008, Bilbao, Basque Country, Spain",
  "663288, Severo-Yeniseyskiy, Krasnoyarsk Krai, Russia",
  "23009, Jaén, Andalusia, Spain",
  "GY2 4PE, St Sampson, Guernsey, Guernsey",
  "Wetzel County, West Virginia, United States",
  "Billings County, North Dakota, United States",
  "Fairfield, Pennsylvania, United States",
  "Ripley County, Missouri, United States",
  "Hacienda Las Nueces, Querétaro, Mexico",
  "Mason, Texas, United States",
  "Greater Adana",
  "Greater Yalta Area",
  "Murray County, Georgia, United States",
  "17844, Mifflinburg, Pennsylvania, United States",
  "Riverfront, Missoula, Montana, United States",
  "Sutton, England, United Kingdom",
  "Cartersville, Georgia, United States",
  "Sierra de Cádiz, Andalusia, Spain",
  "Kyzyl, Tuva, Russia",
  "Fauquier, Bourran, Nouvelle-Aquitaine, France",
  "Forsyth Forest, Winston-Salem, North Carolina, United States",
  "Florence, Oregon, United States",
  "Caroline County, Maryland, United States",
  "35-309, Rzeszów, Podkarpackie, Poland",
  "Wake, Okayama, Japan",
  "Kershaw, South Carolina, United States",
  "Muromtsevsky District, Omsk, Russia",
  "Nassau Village, New Providence, The Bahamas",
  "Pinhel, Guarda, Portugal",
  "663185, Yeniseysk, Krasnoyarsk Krai, Russia",
  "301847, Yefremov, Tula, Russia",
  "Linz am Rhein, Rhineland-Palatinate, Germany",
  "8913 BG, Leeuwarden, Friesland, Netherlands",
  "Huron County, Michigan, United States",
  "Kalývia Karnési, Gortynia, Peloponnese, Greece",
  "Thurstonland, England, United Kingdom",
  "28500, Giresun, Giresun, Türkiye",
  "960 01, Zvolen, Banska Bystrica, Slovakia",
  "Delfzijl, Groningen, Netherlands",
  "53127, Waukesha, Wisconsin, United States",
  "Deerfield Beach, Florida, United States",
  "29301, Spartanburg, South Carolina, United States",
  "Delta, Colorado, United States",
  "Maury, Saint-Projet, Occitanie, France",
  "Ocean Shores, Washington, United States",
  "98350, Kemijärvi, Lapland, Finland",
  "Erenler, Sakarya, Türkiye",
  "Votkinsky District, Udmurtia, Russia",
  "Greater Pescara Metropolitan Area",
  "Livingston County, Illinois, United States",
  "76203, Denton, Texas, United States",
  "611 35, Nyköping, Södermanland County, Sweden",
  "Pershing Park, West Palm Beach, Florida, United States",
  "182160, Velikiye Luki, Pskov, Russia",
  "Portage, Pennsylvania, United States",
  "417271, Toboliu, Bihor, Romania",
  "163059, Archangel, Arkhangel’sk, Russia",
  "P0T 2E0, Marathon, Ontario, Canada",
  "Krasnoufimsk, Sverdlovsk, Russia",
  "654084, Novokuznetsk, Kemerovo, Russia",
  "Morgan, Utah, United States",
  "B6L 5C7, Central Onslow, Nova Scotia, Canada",
  "Granite, Colorado, United States",
  "CB10 2LR, Sewards End, England, United Kingdom",
  "Ivdel’, Sverdlovsk, Russia",
  "Атырау Г.А., Atyrau Region, Kazakhstan",
  "Sion-les-Mines, Pays de la Loire, France",
  "Washington County, Arkansas, United States",
  "Chester County, South Carolina, United States",
  "Wilson County, Kansas, United States",
  "02007, Albacete, Castile-La Mancha, Spain",
  "70714, Baker, Louisiana, United States",
  "Erathna, Sabaragamuwa Province, Sri Lanka",
  "Buenavista, México, Mexico",
  "Atacames Canton, Esmeraldas, Ecuador",
  "831 32, Östersund, Jämtland County, Sweden",
  "462435, Orsk, Orenburg, Russia",
  "Umbaúba, Sergipe, Brazil",
  "633452, Toguchin, Novosibirsk, Russia",
  "Hardin, Missouri, United States",
  "Richardson, New Brunswick, Canada",
  "456302, Miass, Chelyabinsk, Russia",
  "Alta Verapaz, Guatemala",
  "Fiji",
  "301883, Yefremov, Tula, Russia",
  "66111, Saarbrücken, Saarland, Germany",
  "Bryansk, Russia",
  "Dared, Jamnagar, Gujarat, India",
  "Woodbridge, New Jersey, United States",
  "K9H 0A1, Peterborough, Ontario, Canada",
  "Monaco, Monaco",
  "Bouvet Island",
  "77007, Houston, Texas, United States",
  "Wyandotte-Oakdale, Louisville, Kentucky, United States",
  "Bosnia and Herzegovina",
  "Arrondissement of Namur, Walloon Region, Belgium",
  "S21 3YF, Spinkhill, England, United Kingdom",
  "X09H6C1, Shymkent, Shymkent, Kazakhstan",
  "68859, North Loup, Nebraska, United States",
  "188564, Slantsy, Leningrad, Russia",
  "Holmes, Pennsylvania, United States",
  "Grand Saline, Texas, United States",
  "504 53, Borås, Västra Götaland County, Sweden",
  "Hopkinsville, Kentucky, United States",
  "Addison, Ontario, Canada",
  "6009, Kecskemét, Bács-Kiskun, Hungary",
  "86150, Augsburg, Bavaria, Germany",
  "79114, Freiburg, Baden-Württemberg, Germany",
  "Astrakhan’, Russia",
  "57170, Savonlinna, South Savo, Finland",
  "00180, Helsinki, Uusimaa, Finland",
  "Durham, North Carolina, United States",
  "74944, McCurtain, Oklahoma, United States",
  "F91 W3K2, Sligo, County Sligo, Ireland",
  "Highland, New York, United States",
  "98123, Messina, Sicily, Italy",
  "80634, Greeley, Colorado, United States",
  "03148, Kyiv, Kyiv City, Ukraine",
  "215506, Safonovo, Smolensk, Russia",
  "Oğuzeli, Gaziantep, Türkiye",
  "Brooks County, Texas, United States",
  "Douglas, Georgia, United States",
  "186420, Segezha, Karelia, Russia",
  "South Converse, Spartanburg, South Carolina, United States",
  "6710, Esbjerg, Region of Southern Denmark, Denmark",
  "Plaza Saludable, Antofagasta, Antofagasta Region, Chile",
  "Kérkira, Ionian Islands, Greece",
  "78622, Kingsbury, Texas, United States",
  "Özbağ, Kırşehir, Türkiye",
  "Palo Alto County, Iowa, United States",
  "Upton, Southampton, England, United Kingdom",
  "Nevada County, California, United States",
  "22020, Edirne, Edirne, Türkiye",
  "Randall, Minnesota, United States",
  "Khmilnyk Raion, Vinnytsya, Ukraine",
  "Charlotte, Tennessee, United States",
  "56328, Flensburg, Minnesota, United States",
  "87557, Ranchos De Taos, New Mexico, United States",
  "V0H 1T2, Oliver, British Columbia, Canada",
  "Vertiyevka, Chernihiv, Ukraine",
  "169841, Inta, Komi, Russia",
  "Charleston, Mississippi, United States",
  "Simav, Kütahya, Türkiye",
  "Diyadin, Ağrı, Türkiye",
  "Tavsanli, Kütahya, Türkiye",
  "Bhutan",
  "Fredericksburg, Iowa, United States",
  "B55C7T7, Zharkent, Jetisu Region, Kazakhstan",
  "Le Mas-d’Agenais, Nouvelle-Aquitaine, France",
  "Essex, Vermont, United States",
  "Suffolk County, Massachusetts, United States",
  "48007, Bilbao, Basque Country, Spain",
  "27842, Henrico, North Carolina, United States",
  "Newton County, Georgia, United States",
  "82-312, Elbląg, Warmińsko-mazurskie, Poland",
  "Troitskoye, Altai Krai, Russia",
  "66127, Saarbrücken, Saarland, Germany",
  "Keith, Mesquite, Texas, United States",
  "07461, Sussex, New Jersey, United States",
  "Calvados, Normandy, France",
  "Wedde, Groningen, Netherlands",
  "H91 PNN4, Galway, County Galway, Ireland",
  "Lexington, Massachusetts, United States",
  "164840, Onega, Arkhangel’sk, Russia",
  "152332, Borisogleb, Yaroslavl’, Russia",
  "247783, Mazyr, Homel, Belarus",
  "Elbasan, Elbasan, Albania",
  "Arsen’yev, Primorye, Russia",
  "Granville, New York, United States",
  "67850, Zonguldak, Zonguldak, Türkiye",
  "Belgrade, Serbia",
  "Exeter, England, United Kingdom",
  "Pearblossom, California, United States",
  "Tarakan, East Kalimantan, Indonesia",
  "8322, Svolvær, Nordland, Norway",
  "Churu, Rajasthan, India",
  "54820, Brule, Wisconsin, United States",
  "Exeter, Missouri, United States",
  "00185, Rome, Latium, Italy",
  "Aerodrom, Skopje Statistical Region, North Macedonia",
  "021784, Bucharest, Bucharest, Romania",
  "Ferrara di Monte Baldo, Veneto, Italy",
  "7000, Ruse, Ruse, Bulgaria",
  "Mitchell County, Iowa, United States",
  "Adapazarı, Sakarya, Türkiye",
  "Lugazi, Central Region, Uganda",
  "Powhatan, Virginia, United States",
  "44-119, Gliwice, Śląskie, Poland",
  "Orange, Texas, United States",
  "Viranşehir, Şanliurfa, Türkiye",
  "Lake Charles, Louisiana, United States",
  "Muskegon County, Michigan, United States",
  "Saint-Fort, Pays de la Loire, France",
  "Norwich, Vermont, United States",
  "Vodice, Ljubljana, Slovenia",
  "McPherson County, South Dakota, United States",
  "452751, Staryye Tuymazy, Bashkortostan, Russia",
  "West Middlesex, Pennsylvania, United States",
  "Loudoun County, Virginia, United States",
  "White County, Georgia, United States",
  "Eastland Acres, Tulsa, Oklahoma, United States",
  "Kutulik, Ust-Ordyn-Buryat, Russia",
  "689470, Omolon, Chukot, Russia",
  "DG1 4ZZ, Dumfries, Scotland, United Kingdom",
  "Bath, Ontario, Canada",
  "42-256, Gmina Olsztyn, Śląskie, Poland",
  "Marshall County, Alabama, United States",
  "TA3 7SY, Angersleigh, England, United Kingdom",
  "Otterndorf, Lower Saxony, Germany",
  "301662, Novomoskovsk, Tula, Russia",
  "94064, Redwood City, California, United States",
  "Kyzylorda, Kyzylorda Region, Kazakhstan",
  "22924, Charlottesville, Virginia, United States",
  "79014, Canadian, Texas, United States",
  "403116, Uryupinsk, Volgograd, Russia",
  "Brügge, Schleswig-Holstein, Germany",
  "PH13 9PE, Burrelton, Scotland, United Kingdom",
  "Sheffield, New Brunswick, Canada",
  "Tillamook County, Oregon, United States",
  "20360, Turku, Southwest Finland, Finland",
  "Región de Murcia, Spain",
  "Belogorskoye, Ul’yanovsk, Russia",
  "Kidder County, North Dakota, United States",
  "07300, Antalya, Antalya, Türkiye",
  "Gorna Oryahovitsa Municipality, Veliko Turnovo, Bulgaria",
  "Klintholm, Region of Southern Denmark, Denmark",
  "48161, Münster, North Rhine-Westphalia, Germany",
  "LN8 2BU, Caenby Corner, England, United Kingdom",
  "Colonial, São Bento do Sul, Santa Catarina, Brazil",
  "452687, Neftekamsk, Bashkortostan, Russia",
  "19061, Schwerin, Mecklenburg-West Pomerania, Germany",
  "Khosta, Krasnodar, Russia",
  "35398, Giessen, Hesse, Germany",
  "Natrona County, Wyoming, United States",
  "Colonial Heights, Tennessee, United States",
  "60314, Frankfurt, Hesse, Germany",
  "Saint-Georges, Walloon Region, Belgium",
  "225413, Baranavichy, Brest, Belarus",
  "PH33 6TH, Fort William, Scotland, United Kingdom",
  "Teniente 1° Manuel Irala Fernández, Presidente Hayes, Paraguay",
  "424030, Yoshkar-Ola, Mari El, Russia",
  "SR6 0AA, Sunderland, England, United Kingdom",
  "H02P8F3, Taraz, Jambyl Region, Kazakhstan",
  "Glarus, Glarus, Switzerland",
  "Torul, Gümüşhane, Türkiye",
  "Kropyvnytskyi Raion, Kirovohrad, Ukraine",
  "97070, Wilsonville, Oregon, United States",
  "Uzhur, Krasnoyarsk Krai, Russia",
  "27576, Bremerhaven, Bremen, Germany",
  "Heidelberg, Pennsylvania, United States",
  "Alekseyevka, Orenburg, Russia",
  "Çayeli, Rize, Türkiye",
  "76557, Moody, Texas, United States",
  "Lincoln, California, United States",
  "Bolu, Türkiye",
  "Bosques Tarango, Álvaro Obregón, Mexico City, Mexico",
  "20721, Bowie, Maryland, United States",
  "7403, Kaposvár, Somogy, Hungary",
  "Humboldt, South Dakota, United States",
  "Lübeck, Schleswig-Holstein, Germany",
  "30121, Venice, Veneto, Italy",
  "429112, Shumerlya, Chuvashia, Russia",
  "Carroll County, Maryland, United States",
  "07621, Bergenfield, New Jersey, United States",
  "Kür Qəsəbəsi, Qazakh-Tovuz, Azerbaijan",
  "Bonningues-lès-Calais, Hauts-de-France, France",
  "462419, Orsk, Orenburg, Russia",
  "27985, Hertford, North Carolina, United States",
  "7950, Rørvik, Trøndelag, Norway",
  "68071, Winnebago, Nebraska, United States",
  "Presidio County, Texas, United States",
  "120001, Buzău, Buzău, Romania",
  "Crawford County, Kansas, United States",
  "404122, Volzhskiy, Volgograd, Russia",
  "155906, Semeykino, Ivanovo, Russia",
  "Altamira, Pará, Brazil",
  "Sassari, Sardinia, Italy",
  "Lamía, Central Greece, Greece",
  "Rennes-en-Grenouilles, Pays de la Loire, France",
  "0470152, Otaru, Hokkaido, Japan",
  "Vorë, Tirana, Albania",
  "Seward Place, Schenectady, New York, United States",
  "Saint John, Grenada",
  "781, Borlänge, Dalarna County, Sweden",
  "Severo-Kurilsk, Sakhalin, Russia",
  "32762, Oviedo, Florida, United States",
  "28702, San Sebastián de los Reyes, Community of Madrid, Spain",
  "Woodson Village-Pershing, Bryan–College Station, Texas, United States",
  "Tosya, Kastamonu, Türkiye",
  "Oldham, South Dakota, United States",
  "Terryville, New York, United States",
  "20689, Sunderland, Maryland, United States",
  "28522, Vaciamadrid, Community of Madrid, Spain",
  "Dinwiddie Extension 3, Brakpan, Gauteng, South Africa",
  "96075, Paynes Creek, California, United States",
  "Fayette County, Kentucky, United States",
  "Hays, Al Hudaydah Governorate, Yemen",
  "6010, Ålesund, Møre og Romsdal, Norway",
  "Cherepanovskoye, Tyumen’, Russia",
  "Djugyarli, Qazakh-Tovuz, Azerbaijan",
  "30893, Lorca, Región de Murcia, Spain",
  "McIntosh Historic District, McIntosh, Florida, United States",
  "Carlisle, Iowa, United States",
  "Zavodoukovskiy Rayon, Tyumen’, Russia",
  "Narvacan, Ilocos Region, Philippines",
  "İlkadım, Samsun, Türkiye",
  "184405, Nikel, Murmansk, Russia",
  "2790021, Urayasu, Chiba, Japan",
  "Waterford, California, United States",
  "Olsztyn Metropolitan Area",
  "Swansea, Wales, United Kingdom",
  "13960-000, Socorro, São Paulo, Brazil",
  "Arsen'yev, Primorye, Russia",
  "Arsen'yevskoye, Khabarovsk, Russia",
  "72022, Bryant, Arkansas, United States",
  "Fife, Scotland, United Kingdom",
  "Sterling, Michigan, United States",
  "Tarazona de Guareña, Castilla and Leon, Spain",
  "Koriša, Prizren District, Kosovo",
  "Bogorodsky District, Nizhniy Novgorod, Russia",
  "Syeverodonetsk, Luhansk, Ukraine",
  "155813, Kineshma, Ivanovo, Russia",
  "Murombedzi, Mashonaland West Province, Zimbabwe",
  "Rogers Park, Chicago, Illinois, United States",
  "393709, Chernyshëvka, Tambov, Russia",
  "Çerikli, Kırıkkale, Türkiye",
  "44210, Malatya, Malatya, Türkiye",
  "22026, Dumfries, Virginia, United States",
  "666535, Ulkan, Irkutsk, Russia",
  "Llanos, Cantabria, Spain",
  "40600, Jyväskylä, Central Finland, Finland",
  "Dimitrovgrad, Centralna Srbija, Serbia",
  "Buchanan, Missouri, United States",
  "Ransom Park, Nashville, Tennessee, United States",
  "23101, Zrenjanin, Vojvodina, Serbia",
  "Gillespie Park, Sarasota, Florida, United States",
  "98252, Granite Falls, Washington, United States",
  "Scioto, Ohio, United States",
  "346416, Novocherkassk, Rostov, Russia",
  "403535, Frolovo, Volgograd, Russia",
  "Pearlington, Mississippi, United States",
  "E7G 2G3, Plaster Rock, New Brunswick, Canada",
  "Ashtarak region, Aragatsotn Province, Armenia",
  "B55E6X4, Zharkent, Jetisu Region, Kazakhstan",
  "4003, Plovdiv, Plovdiv, Bulgaria",
  "Göyçay, Central Aran, Azerbaijan",
  "IP2 0AB, Ipswich, England, United Kingdom",
  "Macedo de Cavaleiros, Bragança, Portugal",
  "Barrio Simón Bolívar, Barcelona, Anzoategui State, Venezuela",
  "Klingenthal, Saxony, Germany",
  "Dossor, Atyrau Region, Kazakhstan",
  "Genoa, Wisconsin, United States",
  "Sumy, Sumy, Ukraine",
  "72503, Batesville, Arkansas, United States",
  "Gaston, South Carolina, United States",
  "Columbia County, New York, United States",
  "25009, Kropyvnytskyi, Kirovohrad, Ukraine",
  "Adıyaman, Türkiye",
  "Algeciras, Huila, Colombia",
  "67205, Wichita, Kansas, United States",
  "4817, Arendal, Agder, Norway",
  "Hubbardson, Michigan, United States",
  "2005-068, Santarém, Santarém, Portugal",
  "162601, Cherepovets, Vologda, Russia",
  "6200-552, Covilhã, Castelo Branco, Portugal",
  "Cambridge, Ohio, United States",
  "69168, Venango, Nebraska, United States",
  "79360, Seminole, Texas, United States",
  "Okhotska silska rada, Crimea, Ukraine",
  "Cameron, Montana, United States",
  "BA1 2QE, Bath, England, United Kingdom",
  "12400, Bingol, Bingöl, Türkiye",
  "Saratoga, Texas, United States",
  "8328, Svolvær, Nordland, Norway",
  "Hayes, Clarendon, Jamaica",
  "GY2 4NY, St Sampson, Guernsey, Guernsey",
  "71012, Viljandi, Viljandimaa, Estonia",
  "Kartalinsky District, Chelyabinsk, Russia",
  "Ajka, Veszprém, Hungary",
  "Salgótarján, Nógrád, Hungary",
  "Broadwater, Nebraska, United States",
  "Rockbridge, Columbia, Missouri, United States",
  "53850, Lappeenranta, South Karelia, Finland",
  "6061, Sarnen, Obwalden, Switzerland",
  "Forreston, Illinois, United States",
  "41015, Covington, Kentucky, United States",
  "Little Haywood, England, United Kingdom",
  "91200, Athis-Mons, Île-de-France, France",
  "Grand Forks County, North Dakota, United States",
  "Nelson, England, United Kingdom",
  "5957, Nikopol, Pleven, Bulgaria",
  "Richmond County, North Carolina, United States",
  "3000, Dundee, KwaZulu-Natal, South Africa",
  "29650, Greer, South Carolina, United States",
  "Provincia de Santiago, Santiago Metropolitan Region, Chile",
  "175204, Staraya Russa, Novgorod, Russia",
  "Henry County, Alabama, United States",
  "Viana do Castelo, Viana do Castelo, Portugal",
  "Bashkia Tepelenë, Gjirokastër, Albania",
  "06411, Cheshire, Connecticut, United States",
  "59300, Kitsman Raion, Chernivtsi, Ukraine",
  "G3 6AA, Glasgow, Scotland, United Kingdom",
  "6102, St James, Western Australia, Australia",
  "Uzbekistan",
  "694920, Uglegorsk, Sakhalin, Russia",
  "Ourense, Galicia, Spain",
  "430011, Saransk, Mordovia, Russia",
  "Defiance County, Ohio, United States",
  "Jefferson, Ohio, United States",
  "Pajovë, Elbasan, Albania",
  "Delaware County, Oklahoma, United States",
  "Commune de Barneville-Carteret, Barneville-Carteret, Normandy, France",
  "30907, Augusta, Georgia, United States",
  "29832, Johnston, South Carolina, United States",
  "Kukës, Albania",
  "L-8523, Beckerich, Diekirch, Luxembourg",
  "15-345, Białystok, Podlaskie, Poland",
  "Pine Grove, Pennsylvania, United States",
  "Boones Mill, Virginia, United States",
  "Hertford, North Carolina, United States",
  "606402, Balakhna, Nizhniy Novgorod, Russia",
  "Kline, Pennsylvania, United States",
  "Adams County, Washington, United States",
  "Greater Bergen Region",
  "Greater Clermont-Ferrand Area",
  "Juab County, Utah, United States",
  "Morgan County, Indiana, United States",
  "90509, Haapsalu, Laane, Estonia",
  "4012, Debrecen, Hajdú-Bihar, Hungary",
  "403343, Mikhaylovka Urban Okrug, Volgograd, Russia",
  "Holt, Hook, England, United Kingdom",
  "54016, Hudson, Wisconsin, United States",
  "Roseau, Minnesota, United States",
  "Morrow, Arkansas, United States",
  "Cannon, Kentucky, United States",
  "Henrico, Virginia, United States",
  "46514, Elkhart, Indiana, United States",
  "Çayıralan, Yozgat, Türkiye",
  "Fultondale, Alabama, United States",
  "4143, Rakovski, Plovdiv, Bulgaria",
  "Wakefield, Rhode Island, United States",
  "Ellis, Kansas, United States",
  "R13K9G6, Beyneu, Mangystau Region, Kazakhstan",
  "BD8 0AA, Bradford, England, United Kingdom",
  "Kingsbury, London, England, United Kingdom",
  "Slatina, Suceava, Romania",
  "Davis, California, United States",
  "Shakhty, Rostov, Russia",
  "6977, Lugano, Ticino, Switzerland",
  "Lesozavodsk, Primorye, Russia",
  "871 65, Härnösand, Västernorrland County, Sweden",
  "Garvin County, Oklahoma, United States",
  "72200, Batman, Batman, Türkiye",
  "Ozark Colony, Sulphur Springs, Arkansas, United States",
  "3109, Jaen, Central Luzon, Philippines",
  "Forest Hill, Victoria, Australia",
  "6404, Përmet, Gjirokastër, Albania",
  "Holly, Michigan, United States",
  "L10G7C4, Chapaev, West Kazakhstan Region, Kazakhstan",
  "24941, Flensburg, Schleswig-Holstein, Germany",
  "Athens County, Ohio, United States",
  "346894, Bataysk, Rostov, Russia",
  "Halifax, Virginia, United States",
  "658980, Klyuchi, Altai Krai, Russia",
  "Uman, Cherkasy, Ukraine",
  "27605, Raleigh, North Carolina, United States",
  "Dareda Kati, Manyara Region, Tanzania",
  "26123, Oldenburg, Lower Saxony, Germany",
  "La Ravallière, Chaudron-en-Mauges, Pays de la Loire, France",
  "Rogers, Nebraska, United States",
  "04299, Leipzig, Saxony, Germany",
  "165311, Kotlas, Arkhangel’sk, Russia",
  "Sargent Park, Winnipeg, Manitoba, Canada",
  "184048, Kandalaksha, Murmansk, Russia",
  "Guadalajara, Castile-La Mancha, Spain",
  "Isleworth, England, United Kingdom",
  "Simpson, Illinois, United States",
  "Greater Kalamata Area",
  "200421, Craiova, Dolj, Romania",
  "Toms River, New Jersey, United States",
  "Pokrovska miska hromada, Donetsk, Ukraine",
  "Thorpe-Le-Soken, England, United Kingdom",
  "55315, Carver, Minnesota, United States",
  "67100, Kokkola, Central Ostrobothnia, Finland",
  "Stoke St Michael, England, United Kingdom",
  "Huntingdon, Tennessee, United States",
  "Altınordu, Ordu, Türkiye",
  "Lane Cove West, New South Wales, Australia",
  "Medina, Al Madinah, Saudi Arabia",
  "Wilson, Texas, United States",
  "Grove, Oklahoma, United States",
  "70533, Erath, Louisiana, United States",
  "Ungrej, Lezhë, Albania",
  "Huntington County, Indiana, United States",
  "93950, Pacific Grove, California, United States",
  "628011, Khanty-Mansiysk, Khanty-Mansi, Russia",
  "C25C6D1, Makinsk, Akmola Region, Kazakhstan",
  "382 21, Vólos, Thessalia, Greece",
  "8041, Graz, Styria, Austria",
  "30125, Venice, Veneto, Italy",
  "28130, Pori, Satakunta, Finland",
  "Alcornocal, Cabañas del Castillo, Extremadura, Spain",
  "61840, Dewey, Illinois, United States",
  "Centre, Cameroon",
  "162623, Cherepovets, Vologda, Russia",
  "Stokes, Queensland, Australia",
  "Prairie Village, Kansas, United States",
  "Oconee, Georgia, United States",
  "Visby, Gotland County, Sweden",
  "65014, Bland, Missouri, United States",
  "Port Murray, New Jersey, United States",
  "Nyíregyházi Járás, Szabolcs-Szatmár-Bereg, Hungary",
  "9450, Tervel, Dobrich, Bulgaria",
  "Bandera, Tabasco, Mexico",
  "2705, Wiener Neustadt, Lower Austria, Austria",
  "Kozeletska selyshchna hromada, Chernihiv, Ukraine",
  "Jalaid Banner, Inner Mongolia, China",
  "53808, Dickeyville, Wisconsin, United States",
  "353907, Novorossiysk, Krasnodar, Russia",
  "Willington Quay, Wallsend, England, United Kingdom",
  "Burgos, Sardinia, Italy",
  "Pearland, South Antelope Valley, California, United States",
  "Grayson, Louisiana, United States",
  "CV37 8HR, Clifford Chambers, England, United Kingdom",
  "02370, Rockland, Massachusetts, United States",
  "MD-2069, Chişinău, Chișinău, Moldova",
  "Chase Cross, Romford, England, United Kingdom",
  "Scottsville, Virginia, United States",
  "McKinley County, New Mexico, United States",
  "96050, Klamath River, California, United States",
  "65187, Wiesbaden, Hesse, Germany",
  "Holt, Florida, United States",
  "Alb-Donau-Kreis, Baden-Württemberg, Germany",
  "60915, Bradley, Illinois, United States",
  "Bonneville County, Idaho, United States",
  "Zhylyoi District, Atyrau Region, Kazakhstan",
  "Fremont County, Idaho, United States",
  "Oxford, Florida, United States",
  "182107, Velikiye Luki, Pskov, Russia",
  "Cotton Tree, Colne, England, United Kingdom",
  "Pisarevskoye, Irkutsk, Russia",
  "346892, Bataysk, Rostov, Russia",
  "Mossley, England, United Kingdom",
  "Gäu, Solothurn, Switzerland",
  "30132, Venice, Veneto, Italy",
  "27000, Gaziantep, Gaziantep, Türkiye",
  "8300, Svolvær, Nordland, Norway",
  "53218, Milwaukee, Wisconsin, United States",
  "27604, Raleigh, North Carolina, United States",
  "90406, Haapsalu, Laane, Estonia",
  "65370, Vaasa, Ostrobothnia, Finland",
  "76859, Menard, Texas, United States",
  "M22 5HZ, Wythenshawe, England, United Kingdom",
  "North McKenzie, North Dakota, United States",
  "623108, Pervoural'sk, Sverdlovsk, Russia",
  "Coimbra, Portugal",
  "Brovary, Kyiv, Ukraine",
  "Eureka, South Dakota, United States",
  "Greater Arrecife Metropolitan Area",
  "ZE2 9YL, Sandness, Scotland, United Kingdom",
  "Linn, Kansas, United States",
  "39120, Magdeburg, Saxony-Anhalt, Germany",
  "Garvinwood, Evansville, Indiana, United States",
  "V93 C2Y0, Killarney, County Kerry, Ireland",
  "24091, Floyd, Virginia, United States",
  "9965, Sodankylä, Lapland, Finland",
  "671 31, Xánthi, Eastern Macedonia and Thrace, Greece",
  "Çanakkale, Çanakkale, Türkiye",
  "Barton, Maryland, United States",
  "Kropotkin, Irkutsk, Russia",
  "Poitiers, Nouvelle-Aquitaine, France",
  "630032, Novosibirsk, Novosibirsk, Russia",
  "Västerviks kommun, Kalmar County, Sweden",
  "8305, Peshkopi, Dibrë, Albania",
  "Gölbaşı, Adıyaman, Türkiye",
  "40320, Jyväskylä, Central Finland, Finland",
  "Gura Humorului, Suceava, Romania",
  "57730, Custer, South Dakota, United States",
  "38606, Batesville, Mississippi, United States",
  "601909, Kovrov, Vladimir, Russia",
  "Dolinskoye, Zaporizhzhya, Ukraine",
  "Avery Lakes, Fort Mill, South Carolina, United States",
  "Boyabat, Sinop, Türkiye",
  "Comodoro Rivadavia, Chubut Province, Argentina",
  "Meade, Kansas, United States",
  "188802, Vyborg, Leningrad, Russia",
  "47896, Faetano, San Marino",
  "907 52, Umeå, Västerbotten County, Sweden",
  "Zalaegerszegi járás, Zala, Hungary",
  "Strelka-Chunya, Evenki, Russia",
  "Salavat, Bashkortostan, Russia",
  "716 01, Iráklion, Crete, Greece",
  "Hof van Twente, Overijssel, Netherlands",
  "Upper Burrell, Pennsylvania, United States",
  "Krasnoarmeysk, Saratov, Russia",
  "231281, Lida, Hrodna, Belarus",
  "Cavalier Dale, Glendale, Arizona, United States",
  "Utah County, Utah, United States",
  "X12M5H2, Shymkent, Shymkent, Kazakhstan",
  "40122, Bologna, Emilia-Romagna, Italy",
  "Liège, Walloon Region, Belgium",
  "Upper Onslow, Nova Scotia, Canada",
  "74456, Okmulgee, Oklahoma, United States",
  "Braxton Park, Kendleton, Texas, United States",
  "Cockernhoe, England, United Kingdom",
  "Duvall, Washington, United States",
  "663614, Kansk, Krasnoyarsk Krai, Russia",
  "2615, Lillehammer, Innlandet, Norway",
  "20755, Fort George G. Meade, Maryland, United States",
  "Zelënodol'sk, Mari El, Russia",
  "Terry County, Texas, United States",
  "Çukurova, Adana, Türkiye",
  "Tomohon, North Sulawesi, Indonesia",
  "Troitskiy, Altai Krai, Russia",
  "Alexandria, Alabama, United States",
  "44822, Kovel’, Volyn, Ukraine",
  "Eton, England, United Kingdom",
  "187517, Tikhvin, Leningrad, Russia",
  "34755, Istanbul, Istanbul, Türkiye",
  "Rize, Türkiye",
  "Greater Orense Area",
  "Spinkery, Rabenkirchen-Faulück, Schleswig-Holstein, Germany",
  "Progress, Pennsylvania, United States",
  "623406, Kamensk Uralskiy, Sverdlovsk, Russia",
  "Nassau County, New York, United States",
  "655, Auglaize, Missouri, United States",
  "La Ravallière, Saint-Martin-du-Vieux-Bellême, Normandy, France",
  "Nyergesújfalu, Komárom-Esztergom, Hungary",
  "Palos Verdes Peninsula, California, United States",
  "Krasnoye, Kaluga, Russia",
  "Val-des-Sources, Quebec, Canada",
  "Nefteyugansk, Khanty-Mansi, Russia",
  "23700, Haisyn, Vinnytsya, Ukraine",
  "692928, Nakhodka, Primorye, Russia",
  "309280, Shebekino, Belgorod, Russia",
  "Sint Eustatius",
  "Saint Peter Port, Guernsey, Guernsey",
  "84101, Salt Lake City, Utah, United States",
  "Stari Grad, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "629807, Noyabrsk, Yamalo-Nenets, Russia",
  "Burnettsville, Indiana, United States",
  "E7L 1L8, Divide, New Brunswick, Canada",
  "Trenton, Michigan, United States",
  "84511, Artëmovsk, Donetsk, Ukraine",
  "Ul’yanovsk, Russia",
  "692523, Ussuriysk, Primorye, Russia",
  "53400, Rize, Rize, Türkiye",
  "Slobozia Bradului, Vrancea, Romania",
  "Zvenyhorodka Raion, Cherkasy, Ukraine",
  "09002, Burgos, Castilla and Leon, Spain",
  "95617, Davis, California, United States",
  "47800, Mardin, Mardin, Türkiye",
  "Svilengrad Municipality, Khaskovo, Bulgaria",
  "Davidson, North Carolina, United States",
  "75203, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Annecy, Auvergne-Rhône-Alpes, France",
  "Lovech, Bulgaria",
  "Kamensk-Shakhtinskiy, Rostov, Russia",
  "Miami-Fort Lauderdale Area",
  "80-126, Gdańsk, Pomorskie, Poland",
  "Preslav, Shumen, Bulgaria",
  "Greater Bursa",
  "623966, Tavda, Sverdlovsk, Russia",
  "North Governorate, Lebanon",
  "Roberts, Montana, United States",
  "09600-004, São Bernardo do Campo, São Paulo, Brazil",
  "Liberty County, Georgia, United States",
  "Greater Jackson Area",
  "300176, Timişoara, Timiş, Romania",
  "El Presidio, Durango, Mexico",
  "186220, Kondopoga, Karelia, Russia",
  "249033, Obninsk, Kaluga, Russia",
  "42503, Somerset, Kentucky, United States",
  "Galaxia, Risaralda, Colombia",
  "70100, Karaman, Karaman, Türkiye",
  "Karnes County, Texas, United States",
  "Cadiz, Western Visayas, Philippines",
  "BH10 4AA, Bournemouth, England, United Kingdom",
  "AB10 1UT, Aberdeen, Scotland, United Kingdom",
  "Akpazar, Tunceli, Türkiye",
  "6200-086, Covilhã, Castelo Branco, Portugal",
  "Pine County, Minnesota, United States",
  "27103, Winston-Salem, North Carolina, United States",
  "Koblenz, Aargau, Switzerland",
  "V93 YR26, Killarney, County Kerry, Ireland",
  "Mingo, Queensland, Australia",
  "55320, Clearwater, Minnesota, United States",
  "2624, Lillehammer, Innlandet, Norway",
  "3535, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "61516, Benson, Illinois, United States",
  "Wheatland Center, New York, United States",
  "43530, Defiance, Ohio, United States",
  "Szczecin, Zachodniopomorskie, Poland",
  "6900, Bregenz, Vorarlberg, Austria",
  "Emiliano Zapata, Morelos, Mexico",
  "37176, Panevėžys, Panevėžio, Lithuania",
  "3080-455, Figueira da Foz, Coimbra, Portugal",
  "Coffee Hall, Milton Keynes, England, United Kingdom",
  "679014, Birobidzhan, Birobijan, Russia",
  "45-001, Opole, Opolskie, Poland",
  "IV2 3AA, Inverness, Scotland, United Kingdom",
  "20210, Cavtat, Dubrovnik-Neretva, Croatia",
  "12962, Morrisonville, New York, United States",
  "Varna, Varna, Bulgaria",
  "7790, Steinkjer, Trøndelag, Norway",
  "St Columb Major, England, United Kingdom",
  "01376, Turners Falls, Massachusetts, United States",
  "84016, Clearfield, Utah, United States",
  "Tranås, Jönköping County, Sweden",
  "26133, Oldenburg, Lower Saxony, Germany",
  "Kąty Wrocławskie, Dolnośląskie, Poland",
  "Hällefors kommun, Örebro County, Sweden",
  "Henry County, Iowa, United States",
  "785, Höfn, Southern Region, Iceland",
  "Minerva, Ohio, United States",
  "Greater Presov Area",
  "Mezhdurechensky District, Kemerovo, Russia",
  "362015, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "HP17 9TN, Great Kimble, England, United Kingdom",
  "Warren, Pennsylvania, United States",
  "Hampton, Florida, United States",
  "Corjova, Dubăsari District, Moldova",
  "Reagan, Tennessee, United States",
  "55414, Minneapolis, Minnesota, United States",
  "55020, Samsun, Samsun, Türkiye",
  "33870, Tampere, Pirkanmaa, Finland",
  "40126, Bologna, Emilia-Romagna, Italy",
  "61-131, Poznan, Wielkopolskie, Poland",
  "County Sligo, Ireland",
  "Harper Green, Bolton, England, United Kingdom",
  "Toledo, Belize",
  "413859, Balakovo, Saratov, Russia",
  "Evenskjær, Troms og Finnmark, Norway",
  "452688, Neftekamsk, Bashkortostan, Russia",
  "Waterford Works, New Jersey, United States",
  "Starr Hill, Arkansas, United States",
  "052834, Bucharest, Bucharest, Romania",
  "Livorno, Paramaribo, Suriname",
  "Red Bluff, California, United States",
  "8935 KP, Leeuwarden, Friesland, Netherlands",
  "Catania, Sicily, Italy",
  "Tara, Omsk, Russia",
  "Kanem, Chad",
  "30260, Morrow, Georgia, United States",
  "9700-014, Angra do Heroísmo, Portugal",
  "Windham County, Vermont, United States",
  "452120, Rayevskiy, Bashkortostan, Russia",
  "B61D9G2, Sarqan, Jetisu Region, Kazakhstan",
  "Pomorie, Burgas, Bulgaria",
  "Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "442894, Serdobsk, Penza, Russia",
  "Allendale, South Carolina, United States",
  "Santarém, Pará, Brazil",
  "35-111, Rzeszów, Podkarpackie, Poland",
  "Robertson County, Texas, United States",
  "Rock Hill, South Carolina, United States",
  "Greater Aydin",
  "54564, Tripoli, Wisconsin, United States",
  "Nemaha, Iowa, United States",
  "Ses Salines, Balearic Islands, Spain",
  "86154, Augsburg, Bavaria, Germany",
  "85-005, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "Garfield, Georgia, United States",
  "Washoe County, Nevada, United States",
  "89609-000, Luzerna, Santa Catarina, Brazil",
  "Bejaia, Bejaia, Algeria",
  "63911, Klingenberg am Main, Bavaria, Germany",
  "40139, Bologna, Emilia-Romagna, Italy",
  "Pine Mountain, Georgia, United States",
  "23194, Jaén, Andalusia, Spain",
  "Vitsyebsk, Vitsyebsk, Belarus",
  "Clinton, Maryland, United States",
  "Watauga Street, Raleigh, North Carolina, United States",
  "T0H 0P0, Calais, Alberta, Canada",
  "4055, Basel, Basel, Switzerland",
  "21100, Diyarbakır, Diyarbakır, Türkiye",
  "Zimapán, Hidalgo, Mexico",
  "28804, Asheville, North Carolina, United States",
  "FY4 2RP, Blackpool, England, United Kingdom",
  "Fort Belknap Agency, Montana, United States",
  "02006, Albacete, Castile-La Mancha, Spain",
  "Kungur, Perm, Russia",
  "Millet, Alberta, Canada",
  "Rtishchevo, Saratov, Russia",
  "Shelby, Alabama, United States",
  "211 40, Malmo, Skåne County, Sweden",
  "Vienna, Virginia, United States",
  "462421, Orsk, Orenburg, Russia",
  "Cockett, Swansea, Wales, United Kingdom",
  "Derbentsky District, Dagestan, Russia",
  "652352, Topki, Kemerovo, Russia",
  "Major, Saskatchewan, Canada",
  "Lawgi Dawes, Queensland, Australia",
  "Franklin County, Missouri, United States",
  "Upper Duval, Key West, Florida, United States",
  "430044, Baia Mare, Maramureş, Romania",
  "Clarendon, New York, United States",
  "78609, Buchanan Dam, Texas, United States",
  "64646, Humphreys, Missouri, United States",
  "Dixon, Illinois, United States",
  "Garden Park, Champaign, Illinois, United States",
  "Buena Vista, Virginia, United States",
  "354340, Adler, Krasnodar, Russia",
  "Fillmore County, Nebraska, United States",
  "Artëmovskiy, Irkutsk, Russia",
  "542 31, Mariestad, Västra Götaland County, Sweden",
  "Winchester, Ontario, Canada",
  "Titus, Alabama, United States",
  "Gvardeyskiy, Kaliningrad, Russia",
  "Greater Nottingham",
  "24060, Erzincan, Erzincan, Türkiye",
  "Kogalym, Khanty-Mansi, Russia",
  "IP13 0HU, Pettistree, England, United Kingdom",
  "Canton of Troyes-3, Grand Est, France",
  "O'Fallon, St Louis, Missouri, United States",
  "39622, Potoky, Poltava, Ukraine",
  "270 64, Pírgos, Western Greece, Greece",
  "Kalach-na-Donu, Volgograd, Russia",
  "Lovelock, Nevada, United States",
  "174406, Borovichi, Novgorod, Russia",
  "France",
  "Logan, Utah, United States",
  "74058, Pawnee, Oklahoma, United States",
  "Woodbridge, Ontario, Canada",
  "Böyük Kolatan, Lankaran-Astara, Azerbaijan",
  "903 32, Umeå, Västerbotten County, Sweden",
  "Barrón, San Luis Potosí, Mexico",
  "Tatarskoye, Kostroma, Russia",
  "SA7 0AA, Swansea, Wales, United Kingdom",
  "2790002, Urayasu, Chiba, Japan",
  "Kingfisher Estate, Pretoria, Gauteng, South Africa",
  "Huron, Ontario, Canada",
  "Cache Bay, Ontario, Canada",
  "Tomsk Metropolitan Area",
  "23437, Suffolk, Virginia, United States",
  "430294, Baia Mare, Maramureş, Romania",
  "550052, Sibiu, Sibiu, Romania",
  "55987, Winona, Minnesota, United States",
  "Park Slope, New York, New York, United States",
  "682031, Chegdomyn, Khabarovsk, Russia",
  "Tetonbari Borbam, Dibrugarh West, Assam, India",
  "Nashville Metropolitan Area",
  "08012, Barcelona, Catalonia, Spain",
  "Bologoye, Tver’, Russia",
  "665836, Angarsk, Irkutsk, Russia",
  "184507, Monchegorsk, Murmansk, Russia",
  "Sandersdorf-Brehna, Saxony-Anhalt, Germany",
  "Ameliasburg, Ontario, Canada",
  "8042, Graz, Styria, Austria",
  "Augusta, Arkansas, United States",
  "00530, Helsinki, Uusimaa, Finland",
  "Kurram District, Khyber Pakhtunkhwa, Pakistan",
  "Slatina-Timiş, Caraş-Severin, Romania",
  "Mifflin County, Pennsylvania, United States",
  "4822, Burleigh, Queensland, Australia",
  "82220, Joensuu, North Karelia, Finland",
  "Treasure Hills, Little Rock, Arkansas, United States",
  "68229, Mannheim, Baden-Württemberg, Germany",
  "Patrick County, Virginia, United States",
  "Liversedge, England, United Kingdom",
  "B0M 0A4, Westchester Station, Nova Scotia, Canada",
  "Trumbull Village, Albuquerque, New Mexico, United States",
  "1100, Vienna, Vienna, Austria",
  "Catron, Missouri, United States",
  "611 60, Nyköping, Södermanland County, Sweden",
  "Shenandoah, Blacksburg, Virginia, United States",
  "Gallatin Gateway, Montana, United States",
  "456404, Timiryazevskiy, Chelyabinsk, Russia",
  "Pamlico Village, River Road, North Carolina, United States",
  "Greater Guildford Area, United Kingdom",
  "367027, Makhachkala, Dagestan, Russia",
  "Mercer, Missouri, United States",
  "Tirana, Albania",
  "410001, Oradea, Bihor, Romania",
  "Putnam County, West Virginia, United States",
  "95925-000, Progresso, Rio Grande do Sul, Brazil",
  "Schuyler, Virginia, United States",
  "Fraser-Fort George, British Columbia, Canada",
  "659316, Biysk, Altai Krai, Russia",
  "Quinto di Treviso, Veneto, Italy",
  "74553, Kiowa, Oklahoma, United States",
  "Sabine Parish County, Louisiana, United States",
  "39324, Waynesboro, Mississippi, United States",
  "301885, Yefremov, Tula, Russia",
  "8617, Mo i Rana, Nordland, Norway",
  "Kramatorska miska hromada, Donetsk, Ukraine",
  "Johnson County, Kentucky, United States",
  "Chelang, Jogindar Nagar, Himachal Pradesh, India",
  "Canal Winchester, Ohio, United States",
  "Hudson, New Hampshire, United States",
  "93532, Lake Hughes, California, United States",
  "Waller, Texas, United States",
  "Kaštela, Split-Dalmatia, Croatia",
  "CV6 1ET, Coventry, England, United Kingdom",
  "Merrimack County, New Hampshire, United States",
  "Jacksonville, Florida, United States",
  "Davidsonville, Maryland, United States",
  "Waseca, Minnesota, United States",
  "GY2 4RD, St Sampson, Guernsey, Guernsey",
  "45200, Kouvola, Kymenlaakso, Finland",
  "00198, Rome, Latium, Italy",
  "Rubtsovskiy, Altai Krai, Russia",
  "Los Tres Brazos, Santo Domingo, Dominican Republic",
  "Çavdır, Burdur, Türkiye",
  "Los Brazos, Duarte, Dominican Republic",
  "29805, Aiken, South Carolina, United States",
  "44-106, Gliwice, Śląskie, Poland",
  "Blount, Mono, Ontario, Canada",
  "KW15 1HR, Kirkwall, Scotland, United Kingdom",
  "78211, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "Pennington Gap, Virginia, United States",
  "Mamush, Prizren District, Kosovo",
  "Logan County, West Virginia, United States",
  "Gaylord, Minnesota, United States",
  "188300, Gatchina, Leningrad, Russia",
  "Harmanli, Khaskovo, Bulgaria",
  "Fulton, Maryland, United States",
  "300 21, Agrínio, Western Greece, Greece",
  "2536, Nyergesújfalu, Komárom-Esztergom, Hungary",
  "Butler, Western Australia, Australia",
  "Portsmouth, Ohio, United States",
  "38389, Dyer, Tennessee, United States",
  "41143, Grayson, Kentucky, United States",
  "9425, Harstad, Troms og Finnmark, Norway",
  "Lower Burrell, Pennsylvania, United States",
  "677013, Yakutsk, Sakha, Russia",
  "71356, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "8017 MP, Zwolle, Overijssel, Netherlands",
  "Greater Tartu Area",
  "13780, Guilford, New York, United States",
  "04175, Sibayo, Arequipa, Peru",
  "34787, Winter Garden, Florida, United States",
  "628680, Megion, Khanty-Mansi, Russia",
  "9021, Győr, Győr-Moson-Sopron, Hungary",
  "Douglas, Douglas, Isle of Man",
  "34434, Dunnellon, Florida, United States",
  "Fulton, Texas, United States",
  "Hamline-Midway, St Paul, Minnesota, United States",
  "Kiowa County, Oklahoma, United States",
  "43100, Tarragona, Catalonia, Spain",
  "Polevskoy Gorod, Sverdlovsk, Russia",
  "Borgholm, Kalmar County, Sweden",
  "42050, Hickman, Kentucky, United States",
  "Zavala, Yucatán, Mexico",
  "82001, Cheyenne, Wyoming, United States",
  "02017, Vilnius, Vilniaus, Lithuania",
  "Egypt",
  "34133, Trieste, Friuli-Venezia Giulia, Italy",
  "Chernyakhov, Zhytomyr, Ukraine",
  "Lafayette, Oregon, United States",
  "23556, Lübeck, Schleswig-Holstein, Germany",
  "Sullivan, Illinois, United States",
  "Josephine County, Oregon, United States",
  "663181, Yeniseysk, Krasnoyarsk Krai, Russia",
  "320, Borgarnes, Western Region, Iceland",
  "Blountville, Tennessee, United States",
  "Boğazlıyan, Yozgat, Türkiye",
  "100008, Ploieşti, Prahova, Romania",
  "Real County, Texas, United States",
  "BH1 1AA, Bournemouth, England, United Kingdom",
  "Varnamkhast, Isfahan Province, Iran",
  "Nielles-lès-Calais, Hauts-de-France, France",
  "R6W 4A9, Winkler, Manitoba, Canada",
  "Delta County, Michigan, United States",
  "78000, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "53115, Bonn, North Rhine-Westphalia, Germany",
  "Coleman, Florida, United States",
  "G5R 2X9, Rivière-du-Loup, Quebec, Canada",
  "Les Bertières, Saint-Georges-de-Noisné, Nouvelle-Aquitaine, France",
  "Chemung, Illinois, United States",
  "612744, Omutninsk, Kirov, Russia",
  "38240, Obion, Tennessee, United States",
  "Kayl, Luxembourg, Luxembourg",
  "Aberdeen, South Dakota, United States",
  "347932, Taganrog, Rostov, Russia",
  "07090, Antalya, Antalya, Türkiye",
  "Aral Birinci, Central Aran, Azerbaijan",
  "Grant, Illinois, United States",
  "Johnstons River, Prince Edward Island, Canada",
  "490, Osnabrück, Lower Saxony, Germany",
  "Hubbardsville, New York, United States",
  "Dewey, Illinois, United States",
  "Huntingdon, Quebec, Canada",
  "Komsomolets, Tatarstan, Russia",
  "399782, Yelets, Lipetsk, Russia",
  "Provincia de Iquique, Tarapacá Region, Chile",
  "44682, Tuscarawas, Ohio, United States",
  "LV-4601, Rēzekne, Rezekne, Latvia",
  "320147, Reşiţa, Caraş-Severin, Romania",
  "Platte, South Dakota, United States",
  "35101, Panevėžys, Panevėžio, Lithuania",
  "BT79 7SB, Omagh, Northern Ireland, United Kingdom",
  "E25C0T4, Maqat, Atyrau Region, Kazakhstan",
  "Greater Milwaukee",
  "San José Metropolitan Area",
  "7712, Steinkjer, Trøndelag, Norway",
  "Toole County, Montana, United States",
  "Hayscastle, Wales, United Kingdom",
  "429105, Shumerlya, Chuvashia, Russia",
  "Dadeville, Missouri, United States",
  "694052, Dolinsk, Sakhalin, Russia",
  "MD-2028, Chişinău, Chișinău, Moldova",
  "Stillwater Lake, Nova Scotia, Canada",
  "Krasnogorskiy, Altai Krai, Russia",
  "188811, Vyborg, Leningrad, Russia",
  "B0M 2A0, Westchester Station, Nova Scotia, Canada",
  "44500, Malatya, Malatya, Türkiye",
  "Cherry Valley, New York, United States",
  "62859, McLeansboro, Illinois, United States",
  "243110, Klintsy, Bryansk, Russia",
  "398024, Lipetsk, Lipetsk, Russia",
  "4600, Olten, Solothurn, Switzerland",
  "Sunny Slope, San Antonio, Texas, United States",
  "B78 2HS, Kingsbury, England, United Kingdom",
  "Clark County, Nevada, United States",
  "Wheeler, Texas, United States",
  "Parker, Washington, United States",
  "40356, Nicholasville, Kentucky, United States",
  "455034, Magnitogorsk, Chelyabinsk, Russia",
  "352692, Apsheronsk, Krasnodar, Russia",
  "Zgierz, Łódzkie, Poland",
  "Ranchito de Zavala, Sinaloa, Mexico",
  "Bristol, Connecticut, United States",
  "264 42, Patras, Western Greece, Greece",
  "174401, Borovichi, Novgorod, Russia",
  "76205, Denton, Texas, United States",
  "Sawyer, Kansas, United States",
  "Lebanon, Indiana, United States",
  "302 49, Halmstad, Halland County, Sweden",
  "McKinley Heights, St Louis, Missouri, United States",
  "Utica-Rome Area",
  "Vila de Furnas, Itumbiara, Goiás, Brazil",
  "6222 SR, Maastricht, Limburg, Netherlands",
  "VLT 1455, Valletta, Valletta, Malta",
  "Konyaaltı, Antalya, Türkiye",
  "M22 1BT, Wythenshawe, England, United Kingdom",
  "Höfe, Schwyz, Switzerland",
  "Zeyarthiri, Nay Pyi Taw Union Territory, Myanmar",
  "Sussex County, Virginia, United States",
  "Long Island City, New York, New York, United States",
  "Artigues-près-Bordeaux, Nouvelle-Aquitaine, France",
  "422541, Zelënodol'sk, Mari El, Russia",
  "55083, Stillwater, Minnesota, United States",
  "Allendale, Rhode Island, United States",
  "Fillmore 96, Saskatchewan, Canada",
  "Chester County, Pennsylvania, United States",
  "Hawkins, Wisconsin, United States",
  "LV-5403, Daugavpils, Daugavpils, Latvia",
  "Oradea Metropolitan Area",
  "39790, Kirklareli, Kırklareli, Türkiye",
  "65127, Pescara, Abruzzi, Italy",
  "MENA",
  "Cameron, Louisiana, United States",
  "60112, Cortland, Illinois, United States",
  "27100, Gaziantep, Gaziantep, Türkiye",
  "Gaston County, North Carolina, United States",
  "05500, Amasya, Amasya, Türkiye",
  "NR4 7TJ, Norwich, England, United Kingdom",
  "Manisa, Manisa, Türkiye",
  "Fairfield, Maine, United States",
  "H34E5Y8, Qulan, Jambyl Region, Kazakhstan",
  "2983 BV, Ridderkerk, South Holland, Netherlands",
  "791, Freiburg, Baden-Württemberg, Germany",
  "6005, Ålesund, Møre og Romsdal, Norway",
  "Vyborgsky District, St Petersburg, St Petersburg City, Russia",
  "Novosibirsk, Russia",
  "412904, Vol'sk, Saratov, Russia",
  "16-423, Bakałarzewo, Podlaskie, Poland",
  "55780, Sawyer, Minnesota, United States",
  "2231, Maribor, Maribor, Slovenia",
  "Byala, Ruse, Bulgaria",
  "Sherman County, Kansas, United States",
  "450005, Ufa, Bashkortostan, Russia",
  "La Bertière, Baracé, Pays de la Loire, France",
  "04347, Leipzig, Saxony, Germany",
  "Corson, Quelneuc, Brittany, France",
  "Harqin Banner, Inner Mongolia, China",
  "Taosu, Poli Polia, South East Sulawesi, Indonesia",
  "5602, Békéscsaba, Békés, Hungary",
  "676863, Belogorsk, Amur, Russia",
  "Kryvyy Rih Raion, Dnipropetrovsk, Ukraine",
  "678492, Zhilinda, Sakha, Russia",
  "Beltrami, Minneapolis, Minnesota, United States",
  "Söke Bucağı, Aydın, Türkiye",
  "Cimarron, San Jose, California, United States",
  "Brule, Nebraska, United States",
  "Rosebud Plantation, Wright, Florida, United States",
  "30624, Bowman, Georgia, United States",
  "Darlington, New South Wales, Australia",
  "La Forge Criquet, Grimesnil, Normandy, France",
  "79600, Kilis, Kilis, Türkiye",
  "P0T 0B5, Marathon, Ontario, Canada",
  "59135, Wallers, Hauts-de-France, France",
  "500025, Hyderabad, Telangana, India",
  "Bashkia Kurbin, Lezhë, Albania",
  "Obshtina Roman, Vratsa, Bulgaria",
  "59022, Crow Agency, Montana, United States",
  "302 59, Halmstad, Halland County, Sweden",
  "9700-610, Angra do Heroísmo, Portugal",
  "F39B3M5, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Limany, Mykolayiv, Ukraine",
  "Medina County, Ohio, United States",
  "141504, Solnechnogorsk, Moscow, Russia",
  "9603, Hammerfest, Troms og Finnmark, Norway",
  "121 44, Stockholm, Stockholm County, Sweden",
  "Salamanca, Castilla and Leon, Spain",
  "Summit County, Colorado, United States",
  "06530, Porvoo, Uusimaa, Finland",
  "Alhama de Almería, Andalusia, Spain",
  "14745, Fillmore, New York, United States",
  "Austin, Indiana, United States",
  "Rybnoye, Ryazan’, Russia",
  "Fairfax, Cleveland, Ohio, United States",
  "Saint-Pierre, Saint-Pierre, Reunion",
  "443003, Samara, Samara, Russia",
  "60-688, Poznan, Wielkopolskie, Poland",
  "47228, Duisburg, North Rhine-Westphalia, Germany",
  "50670, Mikkeli, South Savo, Finland",
  "53082, Sheboygan, Wisconsin, United States",
  "Dundee, New York, United States",
  "Monroe, Ohio, United States",
  "Vernon County, Wisconsin, United States",
  "Saint-Étienne-de-Mer-Morte, Pays de la Loire, France",
  "21207, Novi Sad, Vojvodina, Serbia",
  "Hays District, Al Hudaydah Governorate, Yemen",
  "Trinity, Florida, United States",
  "01205, Vilnius, Vilniaus, Lithuania",
  "Hakkâri, Hakkâri, Türkiye",
  "357524, Pyatigorsk, Stavropol, Russia",
  "IV2 4PL, Inverness, Scotland, United Kingdom",
  "Galabovo, Stara Zagora, Bulgaria",
  "216502, Roslavl’, Smolensk, Russia",
  "659303, Biysk, Altai Krai, Russia",
  "60154, Westchester, Illinois, United States",
  "Ust’ye, Tambov, Russia",
  "9700-351, Angra do Heroísmo, Portugal",
  "71350, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "Winchester, Illinois, United States",
  "Krasnokamsk, Perm, Russia",
  "Greater Pamplona Area",
  "67101, Kokkola, Central Ostrobothnia, Finland",
  "Oswego Village Square, Illinois, United States",
  "2301, Hamar, Innlandet, Norway",
  "Buyerahmad County, Kohgiluye and Buyer Ahmad Province, Iran",
  "Aalborg Municipality, North Denmark Region, Denmark",
  "Nancy, Kentucky, United States",
  "Soroti, Eastern Region, Uganda",
  "404111, Volzhskiy, Volgograd, Russia",
  "Galaxias Almecatla, Puebla, Mexico",
  "504 65, Borås, Västra Götaland County, Sweden",
  "65351, Sweet Springs, Missouri, United States",
  "416500, Akhtubinsk, Astrakhan’, Russia",
  "618551, Solikamsk, Perm, Russia",
  "H34G8T4, Qulan, Jambyl Region, Kazakhstan",
  "78550-470, Sinop, Mato Grosso, Brazil",
  "Baltimore metropolitan area, Maryland, United States",
  "Nizhnyaya Tavda, Tyumen’, Russia",
  "88460-000, Angelina, Santa Catarina, Brazil",
  "ZE1 0LA, Lerwick, Scotland, United Kingdom",
  "38201, McKenzie, Tennessee, United States",
  "410223, Oradea, Bihor, Romania",
  "Jasper, Missouri, United States",
  "Jimena de la Frontera, Andalusia, Spain",
  "Eastland Park, Lexington, Kentucky, United States",
  "Tiverek, Şanliurfa, Türkiye",
  "621 45, Visby, Gotland County, Sweden",
  "920086, Slobozia, Ialomiţa, Romania",
  "Zvolenská Slatina, Banska Bystrica, Slovakia",
  "M1M 0A1, Scarborough, Ontario, Canada",
  "Coventry, Connecticut, United States",
  "Helsinki sub-region, Uusimaa, Finland",
  "Pepin Town, Wisconsin, United States",
  "37374, Sequatchie, Tennessee, United States",
  "3810-845, Aveiro, Aveiro, Portugal",
  "Terenkol, Pavlodar Region, Kazakhstan",
  "Bandırma, Balıkesir, Türkiye",
  "Beaufort, Ebbw Vale, Wales, United Kingdom",
  "PE26 1AA, Ramsey, England, United Kingdom",
  "Budapest, Budapest, Hungary",
  "Blaine, Maine, United States",
  "3352, Mount Mercer, Victoria, Australia",
  "St. Joseph County, Indiana, United States",
  "59945-000, Major Sales, Rio Grande do Norte, Brazil",
  "6000-767, Castelo Branco, Castelo Branco, Portugal",
  "Potter Street, Harlow, England, United Kingdom",
  "618419, Berezniki, Perm, Russia",
  "34010, Istanbul, Istanbul, Türkiye",
  "646101, Nazyvayevsk, Omsk, Russia",
  "607220, Arzamas, Nizhniy Novgorod, Russia",
  "Chokurdakh, Sakha, Russia",
  "456867, Kyshtym, Chelyabinsk, Russia",
  "78550-007, Sinop, Mato Grosso, Brazil",
  "Altadena, California, United States",
  "Plymouth Meeting, Pennsylvania, United States",
  "631 00, Polygyros, Central Macedonia, Greece",
  "Pickens, Arkansas, United States",
  "29680, Simpsonville, South Carolina, United States",
  "Latimer, Iowa, United States",
  "Smithville, Tennessee, United States",
  "Newcastle, KwaZulu-Natal, South Africa",
  "652 20, Karlstad, Värmland County, Sweden",
  "Örebro County, Sweden",
  "Boone, Missouri, United States",
  "José Antonio Cháves, Zulia State, Venezuela",
  "Kotovskiy, Kursk, Russia",
  "Kaspiyskiy, Kalmykia, Russia",
  "Winchester, California, United States",
  "Ottawa, Ohio, United States",
  "M52G2T1, Osakarovka, Karaganda Region, Kazakhstan",
  "Kouroussa, Kankan Region, Guinea",
  "The Bridge, Guernsey, Guernsey",
  "309257, Shebekino, Belgorod, Russia",
  "686441, Evensk, Magadan, Russia",
  "Valsad, Gujarat, India",
  "Williamsburg County, South Carolina, United States",
  "Stewartsville, Missouri, United States",
  "Olteniţa, Călăraşi, Romania",
  "Köseli, Kırşehir, Türkiye",
  "09007, Burgos, Castilla and Leon, Spain",
  "61070, Kharkiv, Kharkiv, Ukraine",
  "Polevskoye, Birobijan, Russia",
  "Lawrence County, Alabama, United States",
  "Paphos, Cyprus",
  "Vinnytska miska hromada, Vinnytsya, Ukraine",
  "Szolnok, Jász-Nagykun-Szolnok, Hungary",
  "Richardson, Saskatchewan, Canada",
  "25080, Erzurum, Erzurum, Türkiye",
  "Moss Point, Mississippi, United States",
  "Lamar, Pennsylvania, United States",
  "Aqtau, Karaganda Region, Kazakhstan",
  "EH4 1AF, Edinburgh, Scotland, United Kingdom",
  "456812, Verkhniy Ufaley, Chelyabinsk, Russia",
  "Roseau Crossing, Emerson-Franklin, Manitoba, Canada",
  "Port Stephens, New South Wales, Australia",
  "241 50, Kalamata, Peloponnese, Greece",
  "Columbus, Nebraska, United States",
  "Salgótarjáni Járás, Nógrád, Hungary",
  "3500, Hasselt, Flemish Region, Belgium",
  "8500, Pápa, Veszprém, Hungary",
  "7808, Namsos, Trøndelag, Norway",
  "Rzhev, Tver’, Russia",
  "Forsyth, Missouri, United States",
  "Zenica, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Central Onslow, Nova Scotia, Canada",
  "Thessaloniki, Central Macedonia, Greece",
  "Cortlandville, New York, United States",
  "977 53, Luleå, Norrbotten County, Sweden",
  "Kapanga, Lualaba, Democratic Republic of the Congo",
  "Le Bois de Nancé, Brecé, Pays de la Loire, France",
  "32242, Čačak, Centralna Srbija, Serbia",
  "Fanning Brook, East River Part 2, Prince Edward Island, Canada",
  "403111, Uryupinsk, Volgograd, Russia",
  "Cherry County, Nebraska, United States",
  "N0B 1E0, Ayr, Ontario, Canada",
  "Troitskoye, Omsk, Russia",
  "05871, West Burke, Vermont, United States",
  "Bradley Stoke, England, United Kingdom",
  "Mikhaylovka, Voronezh, Russia",
  "Taos Pueblo, New Mexico, United States",
  "Cave Creek, Arizona, United States",
  "164523, Severodvinsk, Arkhangel’sk, Russia",
  "Quartier Kenedy, man, Montagnes, Côte d'Ivoire",
  "76116, Fort Worth, Texas, United States",
  "440273, Satu Mare, Satu Mare, Romania",
  "Chistopol’skiy, Tatarstan, Russia",
  "Ust’-Maya, Sakha, Russia",
  "Pointe-Calumet, Quebec, Canada",
  "Clonakilty, County Cork, Ireland",
  "33560, Tampere, Pirkanmaa, Finland",
  "2612, Turner, Australian Capital Territory, Australia",
  "76107, Fort Worth, Texas, United States",
  "75708, Tyler, Texas, United States",
  "Pennsylvania, United States",
  "141554, Solnechnogorsk, Moscow, Russia",
  "Enişdere, Adıyaman, Türkiye",
  "Summerstrand, Eastern Cape, South Africa",
  "Dawes, Alabama, United States",
  "Nizhneudinsky District, Irkutsk, Russia",
  "Bezhetsky District, Tver’, Russia",
  "Northern Mindanao, Philippines",
  "Cooperativa 17 de Diciembre, Santo Domingo de los Tsachilas, Santo Domingo de los Tsáchilas, Ecuador",
  "Grudziądz Gmina, Kujawsko-pomorskie, Poland",
  "Mingo, Ohio, United States",
  "20800, Denizli, Denizli, Türkiye",
  "155900, Shuya, Ivanovo, Russia",
  "08852, Monmouth Junction, New Jersey, United States",
  "59013, Boyd, Montana, United States",
  "Mont Ménard, Saint-Thibaud-de-Couz, Auvergne-Rhône-Alpes, France",
  "694600, Kholmsk, Sakhalin, Russia",
  "AB24 1AW, Aberdeen, Scotland, United Kingdom",
  "Seksna, Vologda, Russia",
  "Westchester, Saint Catherine, Jamaica",
  "Okanogan, Washington, United States",
  "T12 C5V6, Cork, County Cork, Ireland",
  "Alamance County, North Carolina, United States",
  "Qovlar, Qazakh-Tovuz, Azerbaijan",
  "Starr Pass, Tucson, Arizona, United States",
  "53226, Milwaukee, Wisconsin, United States",
  "34209, Kragujevac, Centralna Srbija, Serbia",
  "98122, Messina, Sicily, Italy",
  "01010, Adana, Adana, Türkiye",
  "Martinez, California, United States",
  "Russell, Pennsylvania, United States",
  "361350, Okhamandal, Gujarat, India",
  "33350, Gijón, Principality of Asturias, Spain",
  "Los Baños, Calabarzon, Philippines",
  "80516, Erie, Colorado, United States",
  "15222, Pittsburgh, Pennsylvania, United States",
  "Clarke, Lake Clarke Shores, Florida, United States",
  "Stará Ľubovňa, Presov, Slovakia",
  "8256, Nesebar, Burgas, Bulgaria",
  "610010, Piatra Neamţ, Neamţ, Romania",
  "13034, Cayuga, New York, United States",
  "550046, Sibiu, Sibiu, Romania",
  "665903, Slyudyanka, Irkutsk, Russia",
  "461630, Buguruslan, Orenburg, Russia",
  "Nesebar, Burgas, Bulgaria",
  "Stewart, Mississippi, United States",
  "Bowman County, North Dakota, United States",
  "Market Overton, England, United Kingdom",
  "Socorro, Goa, India",
  "462359, Novotroitsk, Orenburg, Russia",
  "M52C5Y5, Osakarovka, Karaganda Region, Kazakhstan",
  "Adams, Minnesota, United States",
  "623376, Polevskoy, Sverdlovsk, Russia",
  "Hennepin, Illinois, United States",
  "Lexington, Kentucky, United States",
  "Dillon, Colorado, United States",
  "Otllak, Berat, Albania",
  "Biarritz, Nouvelle-Aquitaine, France",
  "Lipetsky District, Lipetsk, Russia",
  "Rayong, Thailand",
  "507 45, Borås, Västra Götaland County, Sweden",
  "658828, Slavgorod, Altai Krai, Russia",
  "96600, Rovaniemi, Lapland, Finland",
  "Frederikshøj Mark, North Denmark Region, Denmark",
  "456870, Kyshtym, Chelyabinsk, Russia",
  "Yevlax, Central Aran, Azerbaijan",
  "Greater Badajoz Metropolitan Area",
  "Abbeville, Coldwell, Manitoba, Canada",
  "Guardamiglio, Lombardy, Italy",
  "Kamenka, Orenburg, Russia",
  "Ochiltree, Cariboo B, British Columbia, Canada",
  "Hof Ashkelon Regional Council, South District, Israel",
  "Bedford County, Tennessee, United States",
  "Ijevan Region, Tavush Province, Armenia",
  "3300, Oryakhovo, Vratsa, Bulgaria",
  "Tioga Center, New York, United States",
  "Beirut, Beirut Governorate, Lebanon",
  "R25A3X5, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "60385, Frankfurt, Hesse, Germany",
  "Ballyshannon, County Donegal, Ireland",
  "Səpnəkənar, Lankaran-Astara, Azerbaijan",
  "Walker, West Virginia, United States",
  "Cordoba, Argentina Metropolitan Area",
  "BT78 5HZ, Omagh, Northern Ireland, United Kingdom",
  "G0R 4H0, Sainte-Sabine-de-Bellechasse, Quebec, Canada",
  "Bayfield, Holt, England, United Kingdom",
  "Saint George, Grenada",
  "Târgu Mureş, Mureş, Romania",
  "Kouroukoto, Kayes, Mali",
  "DeKalb County, Georgia, United States",
  "Pavel Banya, Stara Zagora, Bulgaria",
  "28037, Madrid, Community of Madrid, Spain",
  "Curry Rivel, England, United Kingdom",
  "Kenosha, Wisconsin, United States",
  "64651, Laclede, Missouri, United States",
  "Butte Falls, Oregon, United States",
  "Tazewell County, Illinois, United States",
  "OL1 3EU, Oldham, England, United Kingdom",
  "Vitória da Conquista, Bahia, Brazil",
  "Iron Ridge, Wisconsin, United States",
  "Cannon AFB, New Mexico, United States",
  "S25C4F6, Ertis, Pavlodar Region, Kazakhstan",
  "385773, Maykop, Adygea, Russia",
  "BH11 0FJ, Bournemouth, England, United Kingdom",
  "Kazancı, Karaman, Türkiye",
  "Vernon, Vermont, United States",
  "Carlton, Georgia, United States",
  "Laclède, Mouhous, Nouvelle-Aquitaine, France",
  "935 82, Plášťovce, Nitra Region, Slovakia",
  "Hampton Roads, Virginia Metropolitan Area",
  "Maury, Saint-Georges-sur-Layon, Pays de la Loire, France",
  "14202, Buffalo, New York, United States",
  "Anson, Wisconsin, United States",
  "Kirov, Russia",
  "Dade County, Missouri, United States",
  "Pepin Woods Mobile Home Park, Red Wing, Minnesota, United States",
  "Sawyerville, Illinois, United States",
  "Erie, Pennsylvania, United States",
  "507 41, Borås, Västra Götaland County, Sweden",
  "Bamberger Mühle, Wiesener Forst, Bavaria, Germany",
  "169309, Ukhta, Komi, Russia",
  "56032, Freeborn, Minnesota, United States",
  "Wilkins Township, Pennsylvania, United States",
  "301 33, Agrínio, Western Greece, Greece",
  "632663, Chistopol’ye, Novosibirsk, Russia",
  "Greater Rennes Metropolitan Area",
  "Greater Alicante Area",
  "427436, Votkinsk, Udmurtia, Russia",
  "La Luzerne du Haut, Bricquebec-en-Cotentin, Normandy, France",
  "Dombarovskiy, Orenburg, Russia",
  "Allegany County, Maryland, United States",
  "Pembina County, North Dakota, United States",
  "Ayr, North Dakota, United States",
  "36004, Poltava, Poltava, Ukraine",
  "Sandy, Utah, United States",
  "603 60, Norrköping, Östergötland County, Sweden",
  "22700, Edirne, Edirne, Türkiye",
  "Ewart, Pipestone, Manitoba, Canada",
  "Engelsberg, Bavaria, Germany",
  "357108, Nevinnomyssk, Stavropol, Russia",
  "Humboldt, Nebraska, United States",
  "90506, Haapsalu, Laane, Estonia",
  "30325, Kohtla-Järve, Ida-Virumaa, Estonia",
  "Bosques de las Lomas, Cuajimalpa de Morelos, Mexico City, Mexico",
  "6211 XJ, Maastricht, Limburg, Netherlands",
  "31192, Pamplona, Chartered Community of Navarre, Spain",
  "Tompkins Street–Main Street Historic District, United States",
  "ZE1 0BB, Lerwick, Scotland, United Kingdom",
  "Ross Township, Pennsylvania, United States",
  "613154, Slobodskoy, Kirov, Russia",
  "130131, Târgovişte, Dîmboviţa, Romania",
  "Woodward Corridor, Michigan, United States",
  "Ceará, Brazil",
  "Houston, Mississippi, United States",
  "620016, Yekaterinburg, Sverdlovsk, Russia",
  "Northumberland, New York, United States",
  "582 46, Linköping, Östergötland County, Sweden",
  "Newcastle, Wyoming, United States",
  "23075, Henrico, Virginia, United States",
  "Johnson City, New York, United States",
  "Aikenside, Elton, Manitoba, Canada",
  "Marshall County, Iowa, United States",
  "Uganda",
  "39118, Magdeburg, Saxony-Anhalt, Germany",
  "Eger, Heves, Hungary",
  "220 27, Tripoli, Peloponnese, Greece",
  "Patrick, Patrick, Isle of Man",
  "Lauderdale County, Mississippi, United States",
  "Esmeraldas, Minas Gerais, Brazil",
  "Vermont, Illinois, United States",
  "665004, Tayshet, Irkutsk, Russia",
  "Greater Porto Alegre",
  "Jardim Angelina, Pindamonhangaba, São Paulo, Brazil",
  "164170, Mirny, Arkhangel’sk, Russia",
  "678770, Zyryanka, Sakha, Russia",
  "Chatham, Massachusetts, United States",
  "9700-301, Angra do Heroísmo, Portugal",
  "Rutherford, Pennsylvania, United States",
  "Hopkins County, Texas, United States",
  "H02B6A7, Taraz, Jambyl Region, Kazakhstan",
  "Kazan, Tatarstan, Russia",
  "Dickson Hill, Markham, Ontario, Canada",
  "Avery Street Historic District, West Virginia, United States",
  "18656, Sweet Valley, Pennsylvania, United States",
  "46090, Kahramanmaraş, Kahraman Maras, Türkiye",
  "Nemirov, Vinnytsya, Ukraine",
  "Ogarkovo, Kostroma, Russia",
  "Yurgamysh, Kurgan, Russia",
  "Sougueur, Tiaret, Algeria",
  "15022, Charleroi, Pennsylvania, United States",
  "MD-4579, Pîrîta, Dubăsari District, Moldova",
  "413726, Pugachëv, Saratov, Russia",
  "Ulster, Iowa, United States",
  "Şehitkamil, Gaziantep, Türkiye",
  "173014, Velikiy Novgorod, Novgorod, Russia",
  "DL2 2UD, Walworth Gate, England, United Kingdom",
  "Derik, Mardin, Türkiye",
  "Belene, Pleven, Bulgaria",
  "352708, Timashevsk, Krasnodar, Russia",
  "76199, Karlsruhe, Baden-Württemberg, Germany",
  "Hubbard, Ohio, United States",
  "Ashaiman, Greater Accra Region, Ghana",
  "352 35, Växjö, Kronoberg County, Sweden",
  "626033, Kanash, Tyumen’, Russia",
  "Ramsey, Isle of Man",
  "Olenyoksky District, Sakha, Russia",
  "Lousã, Coimbra, Portugal",
  "48011, Bilbao, Basque Country, Spain",
  "Guadalupe, Piauí, Brazil",
  "Wrightington, England, United Kingdom",
  "Korsakovskoye, Orel, Russia",
  "Huron, Michigan, United States",
  "45-124, Opole, Opolskie, Poland",
  "28017, Madrid, Community of Madrid, Spain",
  "Tripoli, Libya",
  "92325, Klaipėda, Klaipėdos, Lithuania",
  "Fajzë, Kukës, Albania",
  "Colonial Heights City County, Virginia, United States",
  "Taylor County, Iowa, United States",
  "Yeghegnadzor Community, Vayots Dzor Province, Armenia",
  "45600, Manisa, Manisa, Türkiye",
  "33705, St Petersburg, Florida, United States",
  "620102, Yekaterinburg, Sverdlovsk, Russia",
  "Catron County, New Mexico, United States",
  "Cibola Vista, Peoria, Arizona, United States",
  "Emery County, Utah, United States",
  "Dzerzhinsk, Nizhniy Novgorod, Russia",
  "05906, Lunenburg, Vermont, United States",
  "Bayfield, Ontario, Canada",
  "Haldimand-Norfolk, Ontario, Canada",
  "Amitesh Nagar, Indore, Madhya Pradesh, India",
  "3033 VH, Rotterdam, South Holland, Netherlands",
  "34713, Clermont, Florida, United States",
  "Kimball, Yonkers, New York, United States",
  "West Guilford, Dysart et al, Ontario, Canada",
  "Pulaski, Tennessee, United States",
  "7817, Namsos, Trøndelag, Norway",
  "LU2 0AA, Luton, England, United Kingdom",
  "Municipio Simón Bolívar, Anzoategui State, Venezuela",
  "83-000, Gmina Pruszcz Gdański, Pomorskie, Poland",
  "Bielsko-Biała Metropolitan Area",
  "Sully-sur-Loire, Centre-Val de Loire, France",
  "Dublin 13, County Dublin, Ireland",
  "700001, Iaşi, Iaşi, Romania",
  "Montana, United States",
  "LV-1082, Riga, Riga, Latvia",
  "399787, Yelets, Lipetsk, Russia",
  "Bayfield, Colorado, United States",
  "Pierceton, Indiana, United States",
  "Alchevsk, Luhansk, Ukraine",
  "Baker County, Florida, United States",
  "97867, North Powder, Oregon, United States",
  "Armstrong, Pennsylvania, United States",
  "1050, Middelburg, Mpumalanga, South Africa",
  "4481, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "241037, Bryansk, Bryansk, Russia",
  "27700, Gaziantep, Gaziantep, Türkiye",
  "42060, Konya, Konya, Türkiye",
  "663180, Yeniseysk, Krasnoyarsk Krai, Russia",
  "Potter Hill, Sheffield, England, United Kingdom",
  "Portageville, Missouri, United States",
  "5406, Çorovodë, Berat, Albania",
  "8300, Tapolca, Veszprém, Hungary",
  "Tartus Governorate, Syria",
  "Wharton, Texas, United States",
  "344091, Rostov, Rostov, Russia",
  "453316, Kumertau, Bashkortostan, Russia",
  "59600, Tekirdağ, Tekirdağ, Türkiye",
  "Jo Daviess County, Illinois, United States",
  "Turner's and Fox's Gaps Historic District, Middletown, Maryland, United States",
  "39044, Braxton, Mississippi, United States",
  "Clayton County, Georgia, United States",
  "357715, Kislovodsk, Stavropol, Russia",
  "309277, Shebekino, Belgorod, Russia",
  "Ecuador",
  "Berdyans'k, Zaporizhzhya, Ukraine",
  "Zhenning Buyeizu Miaozu Aut. County, Guizhou, China",
  "652305, Topki, Kemerovo, Russia",
  "Stoke, Quebec, Canada",
  "2805, Tatabánya, Komárom-Esztergom, Hungary",
  "662991, Zheleznogorsk, Krasnoyarsk Krai, Russia",
  "Saalfelden, Salzburg, Austria",
  "628285, Uray, Khanty-Mansi, Russia",
  "Douglas County, Oregon, United States",
  "369001, Cherkessk, Karachay-Cherkessia, Russia",
  "18711, Wilkes-Barre, Pennsylvania, United States",
  "Bucks County, Pennsylvania, United States",
  "403348, Mikhaylovka Urban Okrug, Volgograd, Russia",
  "Randolph County, Indiana, United States",
  "59030, Tekirdağ, Tekirdağ, Türkiye",
  "Lipetskoye, Odessa, Ukraine",
  "456313, Miass, Chelyabinsk, Russia",
  "11203, Algeciras, Andalusia, Spain",
  "216507, Roslavl’, Smolensk, Russia",
  "Palmares, Pernambuco, Brazil",
  "R7N 2X3, Dauphin, Manitoba, Canada",
  "Bath, Pennsylvania, United States",
  "Freestone County, Texas, United States",
  "Kaluga, Saratov, Russia",
  "VLT 1454, Valletta, Valletta, Malta",
  "Haystack, New South Wales, Australia",
  "Original Gillespie Park, Sarasota, Florida, United States",
  "917 02, Trnava, Trnava Region, Slovakia",
  "Frankfurt/Oder, Brandenburg, Germany",
  "Békéscsabai járás, Békés, Hungary",
  "89133, Reggio di Calabria, Calabria, Italy",
  "Kamyshinskiy, Kurgan, Russia",
  "Sainte-Anne, Pointe-à-Pitre, Guadeloupe",
  "427434, Votkinsk, Udmurtia, Russia",
  "La Butte, Nance, Bourgogne-Franche-Comté, France",
  "94534, Fairfield, California, United States",
  "51543, Kimballton, Iowa, United States",
  "Sapanca Bucağı, Sakarya, Türkiye",
  "Smithtown, New York, United States",
  "94301, Palo Alto, California, United States",
  "30900, Nîmes, Occitanie, France",
  "Gorodskoy Okrug Staraya Russa, Novgorod, Russia",
  "Trentola, Campania, Italy",
  "Camp Hill, Pennsylvania, United States",
  "44248, Kaunas, Kauno, Lithuania",
  "1680, Sofia, Sofia City, Bulgaria",
  "900003, Constanţa, Constanţa, Romania",
  "H00P4A0, Taraz, Jambyl Region, Kazakhstan",
  "346404, Novocherkassk, Rostov, Russia",
  "Hampton Hill, Hampton, England, United Kingdom",
  "Greater Lynchburg Area",
  "Terry North, Montana, United States",
  "53212, Milwaukee, Wisconsin, United States",
  "00-510, Warsaw, Mazowieckie, Poland",
  "8314, Peshkopi, Dibrë, Albania",
  "310001, Arad, Arad, Romania",
  "04330, Augusta, Maine, United States",
  "Bosque de Los Remedios, México, Mexico",
  "Gur’yevsk, Kemerovo, Russia",
  "Bodo, Lagunes, Côte d'Ivoire",
  "99212, Spokane, Washington, United States",
  "07731, Howell, New Jersey, United States",
  "34128, Kassel, Hesse, Germany",
  "14568, Walworth, New York, United States",
  "Harmonia, Canoas, Rio Grande do Sul, Brazil",
  "Williamson, Iowa, United States",
  "Colonial Iztapalapa, Iztapalapa, Mexico City, Mexico",
  "Caledonia, Missouri, United States",
  "Schuyler, New York, United States",
  "20108, Manassas, Virginia, United States",
  "Port Blair, Andaman and Nicobar Islands, India",
  "Zapata, Texas, United States",
  "C01A3Y2, Kokshetau, Akmola Region, Kazakhstan",
  "Banner, Arkansas, United States",
  "46112, Brownsburg, Indiana, United States",
  "Brezno, Banska Bystrica, Slovakia",
  "Granville, Iowa, United States",
  "V94 F3A8, Limerick, County Limerick, Ireland",
  "357912, Zelenokumsk, Stavropol, Russia",
  "78754, Austin, Texas, United States",
  "Sussex County, New Jersey, United States",
  "Thomasville, Georgia, United States",
  "Greenville, Ohio, United States",
  "Gorodskoy Okrug Torzhok, Tver’, Russia",
  "10468, Bronx, New York, United States",
  "Monsummano, Tuscany, Italy",
  "80100, Joensuu, North Karelia, Finland",
  "33777, Seminole, Florida, United States",
  "Tioga County, New York, United States",
  "7725, Steinkjer, Trøndelag, Norway",
  "668061, Ayangaty, Tuva, Russia",
  "3510-879, Viseu, Viseu, Portugal",
  "Custer County, South Dakota, United States",
  "Barnwell, England, United Kingdom",
  "Rockwall, McKinney, Texas, United States",
  "6003, Ålesund, Møre og Romsdal, Norway",
  "5008, Szolnok, Jász-Nagykun-Szolnok, Hungary",
  "Desa Panolan, Kedungtuban, Central Java, Indonesia",
  "636850, Kolpashevo, Tomsk, Russia",
  "South Dum-Dum, West Bengal, India",
  "187403, Volkhov, Leningrad, Russia",
  "15122, West Mifflin, Pennsylvania, United States",
  "Overton, Greenock, Scotland, United Kingdom",
  "74960, Annecy, Auvergne-Rhône-Alpes, France",
  "217 55, Malmo, Skåne County, Sweden",
  "652300, Topki, Kemerovo, Russia",
  "Darlington County, South Carolina, United States",
  "Jonestown, Pennsylvania, United States",
  "R13D4C9, Beyneu, Mangystau Region, Kazakhstan",
  "Hammerfest, Troms og Finnmark, Norway",
  "Bronxville, New York, United States",
  "28217, Bremen, Bremen, Germany",
  "309294, Shebekino, Belgorod, Russia",
  "658137, Aleysk, Altai Krai, Russia",
  "Homel, Belarus",
  "Uzunköprü, Edirne, Türkiye",
  "1116, Budapest, Budapest, Hungary",
  "Loraine, Martigny-le-Comte, Bourgogne-Franche-Comté, France",
  "03269, Sanbornton, New Hampshire, United States",
  "Sandness, Scotland, United Kingdom",
  "80992, Munich, Bavaria, Germany",
  "Linares de Riofrío, Castilla and Leon, Spain",
  "Frakull, Fier, Albania",
  "Kemerovo Metropolitan Area",
  "Torzhok, Tver’, Russia",
  "C40B0C4, Esil, Akmola Region, Kazakhstan",
  "4026, Debrecen, Hajdú-Bihar, Hungary",
  "141551, Solnechnogorsk, Moscow, Russia",
  "43713, Barnesville, Ohio, United States",
  "Beadle Greenway, Sioux Falls, South Dakota, United States",
  "Brooker, Florida, United States",
  "Grant County, New Mexico, United States",
  "Greenwood, Missouri, United States",
  "Arrondissement of Ghent, Flemish Region, Belgium",
  "Taraba State, Nigeria",
  "19100, Çorum, Çorum, Türkiye",
  "Midland-Odessa Area",
  "48123, Ravenna, Emilia-Romagna, Italy",
  "Kendall County, Illinois, United States",
  "658091, Novoaltaysk, Altai Krai, Russia",
  "Rendsburg-Eckernförde, Schleswig-Holstein, Germany",
  "28610, Pori, Satakunta, Finland",
  "2830-027, Barreiro, Setúbal, Portugal",
  "Pukerangi, Otago, New Zealand",
  "023833, Bucharest, Bucharest, Romania",
  "13460, Sherburne, New York, United States",
  "Swift Current, Newfoundland and Labrador, Canada",
  "Dauphin River 48 A, Manitoba, Canada",
  "413865, Balakovo, Saratov, Russia",
  "90749, Carson, California, United States",
  "Sukhumi, Georgia",
  "08560, Titusville, New Jersey, United States",
  "35011, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "636845, Asino, Tomsk, Russia",
  "Albany, Ohio, United States",
  "Wiltz, Diekirch, Luxembourg",
  "62689, Thayer, Illinois, United States",
  "187550, Tikhvin, Leningrad, Russia",
  "29540, Darlington, South Carolina, United States",
  "Bairin Left Banner, Inner Mongolia, China",
  "Vista Mar, Puerto Cabello, Carabobo State, Venezuela",
  "446113, Chapayevsk, Samara, Russia",
  "Greater Bari Metropolitan Area",
  "Dutchess County, New York, United States",
  "423556, Nizhnekamsk, Tatarstan, Russia",
  "Malta, Rezekne, Latvia",
  "308, Lorca, Región de Murcia, Spain",
  "76229, Karlsruhe, Baden-Württemberg, Germany",
  "Bergamo, Lombardy, Italy",
  "57230, Savonlinna, South Savo, Finland",
  "44263, Dortmund, North Rhine-Westphalia, Germany",
  "25857, Josephine, West Virginia, United States",
  "12308, Schenectady, New York, United States",
  "Broomedge, England, United Kingdom",
  "BH2 5AA, Bournemouth, England, United Kingdom",
  "Udachnyy, Sakha, Russia",
  "28320, Bladenboro, North Carolina, United States",
  "Merrickville-Wolford, Ontario, Canada",
  "90509, Torrance, California, United States",
  "43047, Mingo, Ohio, United States",
  "X12M9X9, Shymkent, Shymkent, Kazakhstan",
  "300 05, Agrínio, Western Greece, Greece",
  "617433, Kungur, Perm, Russia",
  "Slobozia, Stefan Voda, Moldova",
  "357551, Pyatigorsk, Stavropol, Russia",
  "18435, Stralsund, Mecklenburg-West Pomerania, Germany",
  "Lahore, Punjab, Pakistan",
  "Matoury, Cayenne, French Guiana",
  "North Emmons, North Dakota, United States",
  "184030, Kandalaksha, Murmansk, Russia",
  "Severny District, Novosibirsk, Russia",
  "36-050, Sokołów Małopolski, Podkarpackie, Poland",
  "Morrill, Kansas, United States",
  "8045 PB, Hasselt, Overijssel, Netherlands",
  "211 19, Malmo, Skåne County, Sweden",
  "692057, Lesozavodsk, Primorye, Russia",
  "02001, Albacete, Castile-La Mancha, Spain",
  "31216, Macon, Georgia, United States",
  "Flathead, East Kootenay A, British Columbia, Canada",
  "Meade County, South Dakota, United States",
  "16060, Bursa, Bursa, Türkiye",
  "Fort Smith, Arkansas, United States",
  "Beşikdüzü, Trabzon, Türkiye",
  "Campbell, California, United States",
  "Marshall County, Kansas, United States",
  "Burlington, North Carolina, United States",
  "Río Blanco, Matagalpa, Nicaragua",
  "21132, Novi Sad, Vojvodina, Serbia",
  "Allegany, Pennsylvania, United States",
  "Azay-le-Brûlé, Nouvelle-Aquitaine, France",
  "665708, Bratsk, Irkutsk, Russia",
  "33689, Bielefeld, North Rhine-Westphalia, Germany",
  "St Thomas, US Virgin Islands",
  "South Athens, Attiki, Greece",
  "79500, Kilis, Kilis, Türkiye",
  "Kaysville, Utah, United States",
  "01010, Kyiv, Kyiv City, Ukraine",
  "San Mateo County, California, United States",
  "Cavalier, Meota 468, Saskatchewan, Canada",
  "Dinwiddie County, Virginia, United States",
  "Obshtina Harmanli, Khaskovo, Bulgaria",
  "Livny, Orel, Russia",
  "Custer County, Colorado, United States",
  "Sierra-Bullones, Central Visayas, Philippines",
  "Verde Village, Arizona, United States",
  "Elva, Tartumaa, Estonia",
  "676451, Svobodnyy, Amur, Russia",
  "TA3 7SZ, Angersleigh, England, United Kingdom",
  "28773, Saluda, North Carolina, United States",
  "Shuangfeng County, Hunan, China",
  "8207, Schaffhausen, Schaffhausen, Switzerland",
  "15830, Lahti, Päijät-Häme, Finland",
  "Jefferson County, Illinois, United States",
  "5980, Cherven Bryag, Pleven, Bulgaria",
  "351 50, Lamía, Central Greece, Greece",
  "Burleigh Tract, California, United States",
  "Bucksburn, Aberdeen, Scotland, United Kingdom",
  "Potiyivs'ka Sils'ka Ob'yednana Terytorial'na Hromada, Zhytomyr, Ukraine",
  "10100, Balıkesir, Balıkesir, Türkiye",
  "Wakefield, Virginia, United States",
  "Prince Edward County, Ontario, Canada",
  "76874, Roosevelt, Texas, United States",
  "Peru",
  "428027, Cheboksary, Chuvashia, Russia",
  "50130, Mikkeli, South Savo, Finland",
  "Landerneau, Brittany, France",
  "La Tunita, Colima, Mexico",
  "39146, Pickens, Mississippi, United States",
  "22202, Arlington, Virginia, United States",
  "13290, Aix-en-Provence, Provence-Alpes-Côte d'Azur, France",
  "820, Selfoss, Southern Region, Iceland",
  "LU1 3PG, Luton, England, United Kingdom",
  "542 36, Mariestad, Västra Götaland County, Sweden",
  "35018, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "28900, Giresun, Giresun, Türkiye",
  "88119, Fort Sumner, New Mexico, United States",
  "Hardy, Ohio, United States",
  "356404, Blagodarnyy, Stavropol, Russia",
  "Saline, Scotland, United Kingdom",
  "Clarendon, New Brunswick, Canada",
  "396510, Kamenka, Voronezh, Russia",
  "Westchester, Charlotte, North Carolina, United States",
  "Woodbridge, England, United Kingdom",
  "Bowman, Georgia, United States",
  "75013, Allen, Texas, United States",
  "Lincoln County, Oregon, United States",
  "169532, Sosnogorsk, Komi, Russia",
  "29677, Pendleton, South Carolina, United States",
  "Comonfort, Guanajuato, Mexico",
  "Randolph County, Alabama, United States",
  "Yozgat, Türkiye",
  "Tomsk, Tomsk, Russia",
  "North Platte, Nebraska, United States",
  "Bennington, Nebraska, United States",
  "Atacames, Esmeraldas, Ecuador",
  "Grange (E. D. Kilmore), County Wexford, Ireland",
  "Perth, Scotland, United Kingdom",
  "SS2 5QF, Southend-On-Sea, England, United Kingdom",
  "Carroll County, Kentucky, United States",
  "98934, Kittitas, Washington, United States",
  "620100, Focşani, Vrancea, Romania",
  "184015, Kandalaksha, Murmansk, Russia",
  "La Croix Roseau, Saint-Georges-Buttavent, Pays de la Loire, France",
  "6514, Bellinzona, Ticino, Switzerland",
  "SE4 2DG, London, England, United Kingdom",
  "428024, Cheboksary, Chuvashia, Russia",
  "663615, Kansk, Krasnoyarsk Krai, Russia",
  "Tranås kommun, Jönköping County, Sweden",
  "Chatham-Kent, Ontario, Canada",
  "Portoviejo, Manabí, Ecuador",
  "9308, Finnsnes, Troms og Finnmark, Norway",
  "28805, Asheville, North Carolina, United States",
  "Manchester, England, United Kingdom",
  "15065, Natrona Heights, Pennsylvania, United States",
  "623405, Kamensk Uralskiy, Sverdlovsk, Russia",
  "ZE1 0PX, Lerwick, Scotland, United Kingdom",
  "McLeods, New Brunswick, Canada",
  "10-699, Olsztyn, Warmińsko-mazurskie, Poland",
  "430013, Saransk, Mordovia, Russia",
  "Carson City, Michigan, United States",
  "69121, Heidelberg, Baden-Württemberg, Germany",
  "611 94, Nyköping, Södermanland County, Sweden",
  "Flathead County, Montana, United States",
  "Liberty, Kentucky, United States",
  "241 34, Kalamata, Peloponnese, Greece",
  "42367, Powderly, Kentucky, United States",
  "Sierra Leone",
  "225705, Pinsk, Brest, Belarus",
  "624477, Severoural’sk, Sverdlovsk, Russia",
  "Yalobusha County, Mississippi, United States",
  "Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "665106, Nizhneudinsk, Irkutsk, Russia",
  "4626, Kristiansand, Agder, Norway",
  "184411, Nikel, Murmansk, Russia",
  "Bayrak, Kurgan, Russia",
  "Stockholm, Wisconsin, United States",
  "Navarro, California, United States",
  "77375, Tomball, Texas, United States",
  "677907, Yakutsk, Sakha, Russia",
  "Oneida County, Wisconsin, United States",
  "Hoke Landing, Panther Branch, North Carolina, United States",
  "Dunningen, Baden-Württemberg, Germany",
  "City of Washington County, District of Columbia, United States",
  "SA3 3BS, Caswell, Wales, United Kingdom",
  "Swain Green, Bradford, England, United Kingdom",
  "185 39, Piraeus, Attiki, Greece",
  "220040, Minsk, Minsk, Belarus",
  "04416, Bucksport, Maine, United States",
  "61110, Kharkiv, Kharkiv, Ukraine",
  "Akçadağ, Malatya, Türkiye",
  "12302, Schenectady, New York, United States",
  "504 54, Borås, Västra Götaland County, Sweden",
  "Wharton, Pennsylvania, United States",
  "BD7 1AA, Bradford, England, United Kingdom",
  "623100, Pervoural'sk, Sverdlovsk, Russia",
  "Greene County, Missouri, United States",
  "Stone Park, Illinois, United States",
  "692525, Ussuriysk, Primorye, Russia",
  "27858, Greenville, North Carolina, United States",
  "Les Noës-près-Troyes, Grand Est, France",
  "Valparaiso Region, Chile",
  "Natal, Rio Grande do Norte, Brazil",
  "Idaho Falls, Idaho, United States",
  "Nahirianska selyshchna hromada, Ternopil, Ukraine",
  "456860, Kyshtym, Chelyabinsk, Russia",
  "København Huse, Region of Southern Denmark, Denmark",
  "Gümüşgöze, Gümüşhane, Türkiye",
  "Blanco, New Mexico, United States",
  "50461, Osage, Iowa, United States",
  "65380, Vaasa, Ostrobothnia, Finland",
  "19711, Newark, Delaware, United States",
  "Campeche Shores, Galveston, Texas, United States",
  "42289, Wuppertal, North Rhine-Westphalia, Germany",
  "51600, Bor, Niğde, Türkiye",
  "Wrightsville Beach, North Carolina, United States",
  "64863, Saratoga, Missouri, United States",
  "Susumansky District, Magadan, Russia",
  "4860, Pepinster, Walloon Region, Belgium",
  "Barry, Michigan, United States",
  "Košice-Sídlisko Ťahanovce, Kosice, Slovakia",
  "Archer Park, Kannapolis, North Carolina, United States",
  "48842, Holt, Michigan, United States",
  "Bertiès, Lanrivoaré, Brittany, France",
  "79098, Freiburg, Baden-Württemberg, Germany",
  "647000, Dudinka, Krasnoyarsk Krai, Russia",
  "Craven, Saskatchewan, Canada",
  "Caroline, Arkansas, United States",
  "Aytos Municipality, Burgas, Bulgaria",
  "4900-777, Viana do Castelo, Viana do Castelo, Portugal",
  "37100, Kastamonu, Kastamonu, Türkiye",
  "Agrínio, Western Greece, Greece",
  "90150, Oulu, North Ostrobothnia, Finland",
  "83861, St Maries, Idaho, United States",
  "27110, Gaziantep, Gaziantep, Türkiye",
  "Borisoglebskiy, Yaroslavl’, Russia",
  "Sines, Setúbal, Portugal",
  "51766, Engelskirchen, North Rhine-Westphalia, Germany",
  "142184, Podolsk, Moscow, Russia",
  "722 23, Västerås, Västmanland County, Sweden",
  "8906, Zalaegerszeg, Zala, Hungary",
  "Bucks Creek, Indiana, United States",
  "958 01, Partizánske, Trenciansky, Slovakia",
  "Kecskeméti járás, Bács-Kiskun, Hungary",
  "Hancock County, Iowa, United States",
  "Schieren, Diekirch, Luxembourg",
  "9930, Kirkenes, Troms og Finnmark, Norway",
  "Vitória Brasil, São Paulo, Brazil",
  "Mitchell County, Kansas, United States",
  "Kyzyl-Ozekskoye, Altay, Russia",
  "74402, Muskogee, Oklahoma, United States",
  "33755, Clearwater, Florida, United States",
  "5409, Çorovodë, Berat, Albania",
  "Brighton, England, United Kingdom",
  "Tatvan Bucağı, Bitlis, Türkiye",
  "4500, Solothurn, Solothurn, Switzerland",
  "Lane, South Carolina, United States",
  "A92 K02X, Drogheda, Ireland",
  "Johnson County, Kansas, United States",
  "Stadt Karlsruhe, Baden-Württemberg, Germany",
  "Tucker, Arkansas, United States",
  "38010, Santa Cruz de Tenerife, Canary Islands, Spain",
  "Villa Colonial, Coronel Gregorio Albarracín lanchipa District, Tacna, Peru",
  "455047, Magnitogorsk, Chelyabinsk, Russia",
  "666687, Ust'-Ilimsk, Irkutsk, Russia",
  "Englewood, Colorado, United States",
  "Hof HaCarmel Regional Council, Haifa District, Israel",
  "Roosevelt County, Montana, United States",
  "K'alak'i T'bilisi, Georgia",
  "Walker, Michigan, United States",
  "Carroll, Ohio, United States",
  "Rocklands, Cape Town, Western Cape, South Africa",
  "Lunxhëri, Gjirokastër, Albania",
  "Coleman's Hatch, England, United Kingdom",
  "Callaway, Maryland, United States",
  "353526, Strelka, Krasnodar, Russia",
  "413724, Pugachëv, Saratov, Russia",
  "Ternopil urban hromada, Ternopil, Ukraine",
  "682643, Amursk, Khabarovsk, Russia",
  "53113, Bonn, North Rhine-Westphalia, Germany",
  "Magadan, Magadan, Russia",
  "309962, Valuyki, Belgorod, Russia",
  "Lankaran District, Lankaran-Astara, Azerbaijan",
  "11120, Bilecik, Bilecik, Türkiye",
  "Oceania",
  "Bakaly, Bashkortostan, Russia",
  "624931, Karpinsk, Sverdlovsk, Russia",
  "Roosevelt, New Jersey, United States",
  "Sunderland, England, United Kingdom",
  "243022, Novozybkov, Bryansk, Russia",
  "Ponoy, Murmansk, Russia",
  "13100, Aix-en-Provence, Provence-Alpes-Côte d'Azur, France",
  "20300, Denizli, Denizli, Türkiye",
  "305018, Kursk, Kursk, Russia",
  "Dolinskaya, Kirovohrad, Ukraine",
  "3174, Tønsberg, Vestfold og Telemark, Norway",
  "Alleghany County, North Carolina, United States",
  "HP17 0XS, Great Kimble, England, United Kingdom",
  "Sur les Prés, Sully, Normandy, France",
  "Wilkinstown, County Meath, Ireland",
  "Calais, Vermont, United States",
  "Fort Dodge, Iowa, United States",
  "Kane County, Illinois, United States",
  "Severo-Evensky District, Magadan, Russia",
  "55027, Goodhue, Minnesota, United States",
  "Lyman, Wyoming, United States",
  "Tompkinsville, Staten Island, New York, United States",
  "Norway",
  "Rădăuţi, Suceava, Romania",
  "606400, Balakhna, Nizhniy Novgorod, Russia",
  "BT13 1AA, Belfast, Northern Ireland, United Kingdom",
  "22902, Charlottesville, Virginia, United States",
  "722 16, Västerås, Västmanland County, Sweden",
  "SO15 0AB, Southampton, England, United Kingdom",
  "636840, Asino, Tomsk, Russia",
  "689100, Beringovskiy, Chukot, Russia",
  "Champaign Township, Illinois, United States",
  "24100, Erzincan, Erzincan, Türkiye",
  "694650, Kholmsk, Sakhalin, Russia",
  "69007, Lyon, Auvergne-Rhône-Alpes, France",
  "Riga, Latvia",
  "59059, Musselshell, Montana, United States",
  "682424, Mariinskoye, Khabarovsk, Russia",
  "10110, Zagreb, Zagreb, Croatia",
  "Babruyski Rayon, Mahilyow, Belarus",
  "Greater Toulouse Metropolitan Area",
  "Zlynka, Bryansk, Russia",
  "AD700, Escaldes-Engordany, Escaldes-Engordany, Andorra",
  "7411, Kaposvár, Somogy, Hungary",
  "Greeley, Iowa, United States",
  "M52B8E5, Osakarovka, Karaganda Region, Kazakhstan",
  "371 60, Karlskrona, Blekinge County, Sweden",
  "Berdyansk Raion, Zaporizhzhya, Ukraine",
  "Greater Edinburgh Area",
  "LU2 8DL, Luton, England, United Kingdom",
  "Domagnano, San Marino",
  "Ghent, Flemish Region, Belgium",
  "Ashaka, Delta State, Nigeria",
  "Cloghanboy (Cooke), Athlone, Ireland",
  "Shannon, Kentucky, United States",
  "Wells County, North Dakota, United States",
  "60917, Buckingham, Illinois, United States",
  "113, Reykjavík, Capital Region, Iceland",
  "Atlantic, Iowa, United States",
  "North Catawba, North Carolina, United States",
  "94065, Redwood City, California, United States",
  "Western Australia, Australia",
  "Tobolsk, Tyumen’, Russia",
  "CF14 0RG, Cardiff, Wales, United Kingdom",
  "Beek, Limburg, Netherlands",
  "10152, Turin, Piedmont, Italy",
  "Soure, Coimbra, Portugal",
  "Inderbor, Atyrau Region, Kazakhstan",
  "Abbeville, Hauts-de-France, France",
  "Brazil",
  "Năvodari, Constanţa, Romania",
  "Price Hill, Ohio, United States",
  "446026, Syzran, Samara, Russia",
  "665403, Cheremkhovo, Irkutsk, Russia",
  "Jim Thorpe, Pennsylvania, United States",
  "8003, Burrel, Dibrë, Albania",
  "Paşcani, Iaşi, Romania",
  "GY2 4GS, St Sampson, Guernsey, Guernsey",
  "Nossa Senhora do Socorro, Sergipe, Brazil",
  "Lenin District, Sevastopol, Sevastopol, Ukraine",
  "Ashland, New Hampshire, United States",
  "Ogeecheeton-Dawes Avenue, Savannah, Georgia, United States",
  "652340, Topki, Kemerovo, Russia",
  "72112, Zenica, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Cisterna d’Asti, Piedmont, Italy",
  "South Atlantic states, United States",
  "Greater Munster Area",
  "61930, Hindsboro, Illinois, United States",
  "90010-090, Porto Alegre, Rio Grande do Sul, Brazil",
  "Villanova d’Asti, Piedmont, Italy",
  "Klingsmoos, Bavaria, Germany",
  "Hudson, Ohio, United States",
  "OL1 1AA, Oldham, England, United Kingdom",
  "Robertsdale, Alabama, United States",
  "90766, Fürth, Bavaria, Germany",
  "66128, Saarbrücken, Saarland, Germany",
  "Waukesha, Wisconsin, United States",
  "618250, Gubakha, Perm, Russia",
  "Saratoga, Missouri, United States",
  "C19C3K9, Zhaltyr, Akmola Region, Kazakhstan",
  "47166, Duisburg, North Rhine-Westphalia, Germany",
  "South Yorkshire, England, United Kingdom",
  "Burnt Hills, New York, United States",
  "Liverpool, England, United Kingdom",
  "08003, Barcelona, Catalonia, Spain",
  "Delta County, Texas, United States",
  "Viseu de Sus, Maramureş, Romania",
  "Greater Antalya",
  "Kendall Park, New Jersey, United States",
  "E7G 4G9, Plaster Rock, New Brunswick, Canada",
  "FY4 2DP, Blackpool, England, United Kingdom",
  "Sainte-Sabine, Saint-Pompain, Nouvelle-Aquitaine, France",
  "46932, Galveston, Indiana, United States",
  "2342, Ruše, Ruše, Slovenia",
  "Perquimans County, North Carolina, United States",
  "5522, Haugesund, Rogaland, Norway",
  "DG1 4EZ, Dumfries, Scotland, United Kingdom",
  "Surakhany Raion, Baku Ekonomic Zone, Azerbaijan",
  "Roslavlsky District, Smolensk, Russia",
  "NN15 5YT, Burton Latimer, England, United Kingdom",
  "Ust-Labinsky District, Krasnodar, Russia",
  "Washtenaw Clubview, Michigan, United States",
  "39128, Magdeburg, Saxony-Anhalt, Germany",
  "Kyzyl-Ozyok, Altay, Russia",
  "403873, Kamyshin, Volgograd, Russia",
  "Onondaga Castle, New York, United States",
  "97970-000, Roque Gonzales, Rio Grande do Sul, Brazil",
  "21290, Rusko, Southwest Finland, Finland",
  "76149, Karlsruhe, Baden-Württemberg, Germany",
  "92365, Newberry Springs, California, United States",
  "663924, Uyar, Krasnoyarsk Krai, Russia",
  "21017, Samarate, Lombardy, Italy",
  "390039, Ryazan, Ryazan’, Russia",
  "Bogoroditskoye, Rostov, Russia",
  "Browns Mills, New Jersey, United States",
  "66-004, Zielona Góra, Lubuskie, Poland",
  "Richmond, British Columbia, Canada",
  "30321, Kohtla-Järve, Ida-Virumaa, Estonia",
  "Barat Daya, Penang, Malaysia",
  "Susquehanna County, Pennsylvania, United States",
  "Tiogan, Bacungan (Leon T. Postigo), Zamboanga Peninsula, Philippines",
  "KA18 2DE, Ochiltree, Scotland, United Kingdom",
  "Pulaski, Virginia, United States",
  "Snydertown, Pennsylvania, United States",
  "4225, Debrecen, Hajdú-Bihar, Hungary",
  "77536, Deer Park, Texas, United States",
  "Taylorville, Illinois, United States",
  "Briuci, Rēzekne Municipality, Latvia",
  "Vandoeuvre-lès-Nancy, Grand Est, France",
  "1011 BR, Amsterdam, North Holland, Netherlands",
  "DeWitt County, Texas, United States",
  "Rolette, North Dakota, United States",
  "7622, Pécs, Baranya, Hungary",
  "904 21, Umeå, Västerbotten County, Sweden",
  "1190, Brussels, Brussels Region, Belgium",
  "Darabani, Botoşani, Romania",
  "34113, Kragujevac, Centralna Srbija, Serbia",
  "Sussex, Virginia, United States",
  "77552, Galveston, Texas, United States",
  "Niagara, Toronto, Ontario, Canada",
  "Newport, Queensland, Australia",
  "Nizhniy Tagil, Sverdlovsk, Russia",
  "32990, Ourense, Galicia, Spain",
  "346472, Kerchik-Savrov, Rostov, Russia",
  "Oswego, New York, United States",
  "346414, Novocherkassk, Rostov, Russia",
  "393364, Kirsanov, Tambov, Russia",
  "457354, Kartaly, Chelyabinsk, Russia",
  "Vernon, Illinois, United States",
  "Stevens County, Kansas, United States",
  "4716, Lawgi Dawes, Queensland, Australia",
  "Highland, Indiana, United States",
  "City of Fairfax County, Virginia, United States",
  "Surahani, Baku Ekonomic Zone, Azerbaijan",
  "Greater Rostock Region",
  "C00P4Y0, Kokshetau, Akmola Region, Kazakhstan",
  "23707, Portsmouth, Virginia, United States",
  "Wheeler County, Oregon, United States",
  "629803, Noyabrsk, Yamalo-Nenets, Russia",
  "Jefferson City, Missouri, United States",
  "Cartagena, Valparaiso Region, Chile",
  "Saint Andrews, Manitoba, Canada",
  "Progress, Samara, Russia",
  "404378, Pugachëvskaya, Volgograd, Russia",
  "Kamenka, Chernivtsi, Ukraine",
  "Rancho Agrícola Zavala, Baja California, Mexico",
  "Madison, New Jersey, United States",
  "75705, Tyler, Texas, United States",
  "676121, Magdagachi, Amur, Russia",
  "446024, Syzran, Samara, Russia",
  "Emporia City County, Virginia, United States",
  "04013, Vilnius, Vilniaus, Lithuania",
  "City Of Portsmouth, England, United Kingdom",
  "55070, Samsun, Samsun, Türkiye",
  "99695, Sodankylä, Lapland, Finland",
  "Adıyaman, Adıyaman, Türkiye",
  "06066, Vernon Rockville, Connecticut, United States",
  "Randolph, Texas, United States",
  "25-437, Kielce, Świętokrzyskie, Poland",
  "33011, Oviedo, Principality of Asturias, Spain",
  "9715, Hammerfest, Troms og Finnmark, Norway",
  "624786, Verkhnyaya Salda, Sverdlovsk, Russia",
  "17026, Fredericksburg, Pennsylvania, United States",
  "217 75, Malmo, Skåne County, Sweden",
  "Kemptville, Ontario, Canada",
  "7210, Lake Leake, Tasmania, Australia",
  "652320, Topki, Kemerovo, Russia",
  "Staryye Tuymazy, Bashkortostan, Russia",
  "524540, Aldana, Nariño, Colombia",
  "Tripoli, Iowa, United States",
  "Otsego County, New York, United States",
  "301609, Novomoskovsk, Tula, Russia",
  "Petersburg, Virginia, United States",
  "40972, Oneida, Kentucky, United States",
  "Greater Annecy Area",
  "Almada, Setúbal, Portugal",
  "1101 EA, Amsterdam, North Holland, Netherlands",
  "29625, Anderson, South Carolina, United States",
  "Hood River, Oregon, United States",
  "Obshtina Nikopol, Pleven, Bulgaria",
  "13490, Westmoreland, New York, United States",
  "871 60, Härnösand, Västernorrland County, Sweden",
  "Shostkynska miska hromada, Sumy, Ukraine",
  "9230, Aalborg, North Denmark Region, Denmark",
  "Calhoun City, Mississippi, United States",
  "Cundinamarca, Colombia",
  "Covington City County, Virginia, United States",
  "Lyman, Washington, United States",
  "Evensk, Magadan, Russia",
  "Rennes, Brittany, France",
  "8626, Mo i Rana, Nordland, Norway",
  "42351, Zimapán, Hidalgo, Mexico",
  "79021, Solonka, Lviv, Ukraine",
  "368000, Makhachkala, Dagestan, Russia",
  "Renville West, County Galway, Ireland",
  "Tom Green County, Texas, United States",
  "Austria",
  "690048, Vladivostok, Primorye, Russia",
  "Avery–Helm Historic District, Corvallis, Oregon, United States",
  "Grant County, Kentucky, United States",
  "X38H8A0, Kentau, Turkistan Region, Kazakhstan",
  "17368, Wrightsville, Pennsylvania, United States",
  "Stafford, England, United Kingdom",
  "Hameau Caillot, Barneville-Carteret, Normandy, France",
  "Houten, Utrecht, Netherlands",
  "Grand Teton Village, Las Vegas, Nevada, United States",
  "Goshen, Arkansas, United States",
  "Narvik, Nordland, Norway",
  "Hidalgo, Mexico",
  "Seminole, Alabama, United States",
  "450009, Zalău, Sălaj, Romania",
  "Barry, Illinois, United States",
  "Voskresenskoye, Yaroslavl’, Russia",
  "353485, Gelendzhik, Krasnodar, Russia",
  "Tarazá, Antioquia, Colombia",
  "Turan, North District, Israel",
  "Frontier Colony, Karachi West District, Sindh, Pakistan",
  "Minnehaha, Spokane, Washington, United States",
  "Dayton, Ohio, United States",
  "96270, Mostardas, Rio Grande do Sul, Brazil",
  "Metzingen, Baden-Württemberg, Germany",
  "23262, Zrenjanin, Vojvodina, Serbia",
  "Nantes (Santa Baia), Galicia, Spain",
  "Khmelnytska miska hromada, Khmelnytsky, Ukraine",
  "Fulton, New York, United States",
  "Diyarbakır, Diyarbakır, Türkiye",
  "Notia Rodos, Southern Aegean, Greece",
  "Suva Reka, Prizren District, Kosovo",
  "01452, Hubbardston, Massachusetts, United States",
  "Lancaster, Massachusetts, United States",
  "6318, Walchwil, Zug, Switzerland",
  "Beltramina, Cittadella, Veneto, Italy",
  "4331 AA, Middelburg, Zeeland, Netherlands",
  "TS5 5DX, Middlesbrough, England, United Kingdom",
  "3775, Dixons Creek, Victoria, Australia",
  "Hawkins Hill, New Providence, The Bahamas",
  "Plastinka, Lipetsk, Russia",
  "Marathon City, Wisconsin, United States",
  "624238, Nizhnyaya Tura, Sverdlovsk, Russia",
  "Liptovsky Mikulas, Zilina, Slovakia",
  "3946, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Butler County, Iowa, United States",
  "633453, Toguchin, Novosibirsk, Russia",
  "Enniscorthy, Enniscorthy, County Wexford, Ireland",
  "Williamsburg City County, Virginia, United States",
  "Sherman County, Texas, United States",
  "390037, Ryazan, Ryazan’, Russia",
  "64429, Cameron, Missouri, United States",
  "Arrondissement of Bruges, Flemish Region, Belgium",
  "DT11 0PA, Higher Whatcombe, England, United Kingdom",
  "60101, Addison, Illinois, United States",
  "Aberdeen, Washington, United States",
  "Barisal District, Barisāl, Bangladesh",
  "184602, Severomorsk, Murmansk, Russia",
  "Sokele, Haut-Lomami, Democratic Republic of the Congo",
  "Upton, Quebec, Canada",
  "Miyaly, Atyrau Region, Kazakhstan",
  "Portage Park, Chicago, Illinois, United States",
  "1389, Drammen, Viken, Norway",
  "Charles Town, West Virginia, United States",
  "Stillwater, Auckland, New Zealand",
  "Vichy, Missouri, United States",
  "61953, Tuscola, Illinois, United States",
  "8517, Narvik, Nordland, Norway",
  "Caldwell County, Kentucky, United States",
  "Putnam County, Missouri, United States",
  "ST18 0NN, Little Haywood, England, United Kingdom",
  "23002, Amelia Court House, Virginia, United States",
  "Mercer, Waikato, New Zealand",
  "47881, Staunton, Indiana, United States",
  "Castle Hill, New South Wales, Australia",
  "Kapanewon Banguntapan, Yogyakarta, Indonesia",
  "41-903, Bytom, Śląskie, Poland",
  "715300, Săveni, Botoşani, Romania",
  "97114, Trois-Rivières, Basse-Terre, Guadeloupe",
  "23005, Jaén, Andalusia, Spain",
  "Las Palmas, Chaco, Argentina",
  "315300, Ineu, Arad, Romania",
  "Newport, Tennessee, United States",
  "Pápai járás, Veszprém, Hungary",
  "Bowie, Lubbock, Texas, United States",
  "Augusta, West Virginia, United States",
  "Lebanon, Pennsylvania, United States",
  "Zugres, Donetsk, Ukraine",
  "Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "Marion, Virginia, United States",
  "28603, Hickory, North Carolina, United States",
  "163015, Archangel, Arkhangel’sk, Russia",
  "Kursk, Kursk, Russia",
  "Greater Hamilton (Burlington) Metropolitan Area",
  "Pécoste, Hagetmau, Nouvelle-Aquitaine, France",
  "28633, Lenoir, North Carolina, United States",
  "Wyoming, United States",
  "663341, Noril'sk, Taymyr, Russia",
  "Konakovo, Tver’, Russia",
  "La Ville Audrain, Rohan, Brittany, France",
  "Orleans, Michigan, United States",
  "353309, Kholmskaya, Krasnodar, Russia",
  "Vernon, New York, United States",
  "Solothurn, Solothurn, Switzerland",
  "41011, Covington, Kentucky, United States",
  "14224, Buffalo, New York, United States",
  "Throckmorton, England, United Kingdom",
  "Berkeley County, West Virginia, United States",
  "Roosevelt, Kenosha, Wisconsin, United States",
  "Tavda, Sverdlovsk, Russia",
  "456596, Yemanzhelinka, Chelyabinsk, Russia",
  "06135, Perugia, Umbria, Italy",
  "18054, Green Lane, Pennsylvania, United States",
  "68633, Dodge, Nebraska, United States",
  "LE12 6RY, East Leake, England, United Kingdom",
  "Sevastopol, Wisconsin, United States",
  "Boyd Ponds, Arvada, Colorado, United States",
  "Dyersburg, Tennessee, United States",
  "Macon County, Georgia, United States",
  "Greater Burlington Area",
  "Chittenden, Gurnee, Illinois, United States",
  "Sutton St Nicholas, England, United Kingdom",
  "Greater Barcelona Metropolitan Area",
  "Valladolid, Castilla and Leon, Spain",
  "19607, Reading, Pennsylvania, United States",
  "05201, Bennington, Vermont, United States",
  "Novosibirsky District, Novosibirsk, Russia",
  "Sharq, Al Asimah, Kuwait",
  "Belgrade, Maine, United States",
  "25-636, Kielce, Świętokrzyskie, Poland",
  "Vila Judith, Itapetininga, São Paulo, Brazil",
  "634011, Mirnyy, Tomsk, Russia",
  "423574, Nizhnekamsk, Tatarstan, Russia",
  "81-411, Gdynia, Pomorskie, Poland",
  "42141, Glasgow, Kentucky, United States",
  "Doora, Kirawali, Uttar Pradesh, India",
  "Bosque, New Mexico, United States",
  "Shannon, Illinois, United States",
  "Choctaw, Arkansas, United States",
  "Ţurayf, Northern Frontier, Saudi Arabia",
  "A92 P702, Dundalk, County Louth, Ireland",
  "Madeira Island, Portugal",
  "Groningen, Groningen, Netherlands",
  "Greater Jyvaskyla Area",
  "624261, Asbest, Sverdlovsk, Russia",
  "Wirtlen, Lucerne, Switzerland",
  "Schoolcraft County, Michigan, United States",
  "20152, Milan, Lombardy, Italy",
  "59104, Billings, Montana, United States",
  "Greenbrier, High Point, North Carolina, United States",
  "Dallas, Georgia, United States",
  "Randall Park, Eau Claire, Wisconsin, United States",
  "Walkerton, Indiana, United States",
  "S0A 4J0, Wadena, Saskatchewan, Canada",
  "Adolfo Gonzáles Chaves, Buenos Aires Province, Argentina",
  "Kay County, Oklahoma, United States",
  "Aransas Pass, Texas, United States",
  "Lezhë, Lezhë, Albania",
  "Thessaloniki Metropolitan Area",
  "53408, Racine, Wisconsin, United States",
  "37073, Göttingen, Lower Saxony, Germany",
  "Montpellier-de-Médillan, Nouvelle-Aquitaine, France",
  "Kirsehir, Kırşehir, Türkiye",
  "9707, Szombathely, Vas, Hungary",
  "57006, Brookings, South Dakota, United States",
  "Okhamandal, Gujarat, India",
  "Yellow Jacket, Colorado, United States",
  "352121, Tikhoretsk, Krasnodar, Russia",
  "13845, Tioga Center, New York, United States",
  "659305, Biysk, Altai Krai, Russia",
  "Barnesville, Maryland, United States",
  "Cheremkhovskoye, Zabaykalsky Krai, Russia",
  "J1T 4A1, Asbestos, Quebec, Canada",
  "Danesmoor, Chesterfield, England, United Kingdom",
  "Beaverhead County, Montana, United States",
  "Batesville, Mississippi, United States",
  "Hokes Bluff, Alabama, United States",
  "Webster County, Missouri, United States",
  "BA9 8JW, Shepton Montague, England, United Kingdom",
  "British Virgin Islands",
  "Port Aransas, Texas, United States",
  "80538, Loveland, Colorado, United States",
  "Richmond, Tasman, New Zealand",
  "23207, Zrenjanin, Vojvodina, Serbia",
  "38005, Santa Cruz de Tenerife, Canary Islands, Spain",
  "929 01, Dunajská Streda, Trnava Region, Slovakia",
  "Rogersville, Missouri, United States",
  "90540, Oulu, North Ostrobothnia, Finland",
  "610180, Piatra Neamţ, Neamţ, Romania",
  "423803, Naberezhnyye Chelny, Tatarstan, Russia",
  "97817, Bates, Oregon, United States",
  "Beja, Portugal",
  "15044, Gibsonia, Pennsylvania, United States",
  "Fort Defiance, Arizona, United States",
  "Boxmeer, North Brabant, Netherlands",
  "Mogilev City Council, Mahilyow, Belarus",
  "Chouteau Estates, Missouri, United States",
  "Perth, Western Australia, Australia",
  "61106, Kharkiv, Kharkiv, Ukraine",
  "Këlcyrë, Gjirokastër, Albania",
  "10965, Pearl River, New York, United States",
  "Vagharshapat, Armavir Province, Armenia",
  "Landero y Cos, Veracruz, Mexico",
  "666793, Ust-Kut, Irkutsk, Russia",
  "140563, Kolomna, Moscow, Russia",
  "City of Johannesburg, Gauteng, South Africa",
  "Treasure Cay, North Abaco, The Bahamas",
  "Nerekhta, Kostroma, Russia",
  "7221, Bitola, Pelagonia, North Macedonia",
  "Maury, Affieux, Nouvelle-Aquitaine, France",
  "Gmina Grudziądz, Kujawsko-pomorskie, Poland",
  "Cotham, Grayson 184, Saskatchewan, Canada",
  "14719, Cattaraugus, New York, United States",
  "Uglichsky District, Yaroslavl’, Russia",
  "28055, Gastonia, North Carolina, United States",
  "Allentown, Pennsylvania, United States",
  "Traverse, Laroque-Timbaut, Nouvelle-Aquitaine, France",
  "Greater Liverpool Area",
  "29600, Pori, Satakunta, Finland",
  "Sibley Island Estates, North Dakota, United States",
  "82006, Cheyenne, Wyoming, United States",
  "Carolina, Maranhão, Brazil",
  "Wetzelhübel, Seiffen, Saxony, Germany",
  "679005, Birobidzhan, Birobijan, Russia",
  "627313, Golyshmanovo, Tyumen’, Russia",
  "42167, Tompkinsville, Kentucky, United States",
  "C40A4X7, Esil, Akmola Region, Kazakhstan",
  "75764, Rusk, Texas, United States",
  "618236, Chusovoy, Perm, Russia",
  "03002, Alicante, Valencian Community, Spain",
  "Rutherfords Mission City, Glencoe, Florida, United States",
  "309994, Valuyki, Belgorod, Russia",
  "North Cheam, Sutton, England, United Kingdom",
  "31323, Riceboro, Georgia, United States",
  "Wheeler County, Georgia, United States",
  "Madison, Connecticut, United States",
  "Grayson County, Texas, United States",
  "9401, Vlorë, Vlorë, Albania",
  "Umatilla, Florida, United States",
  "Marlborough District, Marlborough, New Zealand",
  "Raleigh County, West Virginia, United States",
  "M52C0T8, Osakarovka, Karaganda Region, Kazakhstan",
  "Berkswell, England, United Kingdom",
  "Hudson, Quebec, Canada",
  "Cockenzie, Prestonpans, Scotland, United Kingdom",
  "53220, Milwaukee, Wisconsin, United States",
  "GL4 5GE, Gloucester, England, United Kingdom",
  "Volosovichi, Homel, Belarus",
  "36320, Cottonwood, Alabama, United States",
  "Barton, Vermont, United States",
  "Phelps, New York, United States",
  "Shelby County, Indiana, United States",
  "Gemeente Bergen, Limburg, Netherlands",
  "Henry County, Missouri, United States",
  "Salt Lake City, Utah, United States",
  "Laurens, Came, Nouvelle-Aquitaine, France",
  "98290, Snohomish, Washington, United States",
  "Refugio County, Texas, United States",
  "Fort McPherson, Northwest Territories, Canada",
  "Webb, Saskatchewan, Canada",
  "89703, Carson City, Nevada, United States",
  "1612, Sofia, Sofia City, Bulgaria",
  "Addison, Ohio, United States",
  "7052, Trondheim, Trøndelag, Norway",
  "Oneida Mendes, Uberaba, Minas Gerais, Brazil",
  "674636, Onon-Borzya, Zabaykalsky Krai, Russia",
  "Rockingham, Missouri, Missouri, United States",
  "Fillmore, Utah, United States",
  "18090-180, Sorocaba, São Paulo, Brazil",
  "Osage, Missouri, United States",
  "Hopewell Junction, New York, United States",
  "Karlskrona kommun, Blekinge County, Sweden",
  "9310, Aalborg, North Denmark Region, Denmark",
  "Greater Kolkata Area",
  "Sabrosa, Vila Real, Portugal",
  "462403, Orsk, Orenburg, Russia",
  "70-406, Szczecin, Zachodniopomorskie, Poland",
  "Cibola Heights, Yuma, Arizona, United States",
  "67470, New Cambria, Kansas, United States",
  "Moody, Missouri, United States",
  "Aix-Marseille-Provence Metropolis, France",
  "East Liverpool, Ohio, United States",
  "St George, Utah, United States",
  "Carignan-de-Bordeaux, Nouvelle-Aquitaine, France",
  "Emmaboda kommun, Kalmar County, Sweden",
  "393957, Morshansk, Tambov, Russia",
  "55202, Pervomaysk, Mykolayiv, Ukraine",
  "Vratsa, Bulgaria",
  "Buchanan Dam, Texas, United States",
  "Dona Ana County, New Mexico, United States",
  "60470, Ransom, Illinois, United States",
  "90-132, Łódź, Łódzkie, Poland",
  "Novara, Piedmont, Italy",
  "29850, Aiken, South Carolina, United States",
  "Brussels Region, Belgium",
  "Springfield, Missouri, United States",
  "2502, Biel, Berne, Switzerland",
  "Cass County, Minnesota, United States",
  "Porvoo, Uusimaa, Finland",
  "4008, Vau i Dejës, Shkodër, Albania",
  "Franklin County, North Carolina, United States",
  "Teterinskiy sel'skiy sovet, Mahilyow, Belarus",
  "Haute-Vienne, Nouvelle-Aquitaine, France",
  "Sunflower, Delray Beach, Florida, United States",
  "75862, Trinity, Texas, United States",
  "Monroe, Georgia, United States",
  "El Progresso, Galápagos, Ecuador",
  "167000, Syktyvkar, Komi, Russia",
  "7004, Ruse, Ruse, Bulgaria",
  "357107, Nevinnomyssk, Stavropol, Russia",
  "Venice, Illinois, United States",
  "Sully, Civry-en-Montagne, Bourgogne-Franche-Comté, France",
  "346884, Bataysk, Rostov, Russia",
  "790 15, Falun, Dalarna County, Sweden",
  "Butler County, Alabama, United States",
  "Westmoreland, Kansas, United States",
  "Cité Louisa Hachemi, Ain Temouchent, Aïn Témouchent, Algeria",
  "665413, Cheremkhovo, Irkutsk, Russia",
  "Oxford, Maine, United States",
  "Catanzaro, Calabria, Italy",
  "Shelbyville, Tennessee, United States",
  "Mineral, California, United States",
  "Emiliano Zapata, Tabasco, Mexico",
  "Belogorsky, Arkhangel’sk, Russia",
  "38676, Tunica, Mississippi, United States",
  "41007, Seville, Andalusia, Spain",
  "Adana, Türkiye",
  "Korsakovo, Kaluga, Russia",
  "Aberdeen, Idaho, United States",
  "La Colle-sur-Loup, Provence-Alpes-Côte d'Azur, France",
  "9415, Harstad, Troms og Finnmark, Norway",
  "8004, Zurich, Zurich, Switzerland",
  "141300, Sergiyev Posad, Moscow, Russia",
  "Durankaya, Hakkâri, Türkiye",
  "Enclave del Snyder, Catalina Foothills, Arizona, United States",
  "398002, Lipetsk, Lipetsk, Russia",
  "Jamesville, New York, United States",
  "06001, Badajoz, Extremadura, Spain",
  "Balpınar, Batman, Türkiye",
  "Krasnoarmeysky District, Samara, Russia",
  "24514, Lynchburg, Virginia, United States",
  "IP32 6HQ, Bury St Edmunds, England, United Kingdom",
  "Snyder, Fort Erie, Ontario, Canada",
  "Poland",
  "2315, Hamar, Innlandet, Norway",
  "358902, Elista, Kalmykia, Russia",
  "61000, Trabzon, Trabzon, Türkiye",
  "E25C3C6, Maqat, Atyrau Region, Kazakhstan",
  "Rutland, Massachusetts, United States",
  "75083, Richardson, Texas, United States",
  "Nelson, New South Wales, Australia",
  "Clearfield County, Pennsylvania, United States",
  "Patrick Fogarty Catholic Secondary School, Orillia, Ontario, Canada",
  "352 49, Växjö, Kronoberg County, Sweden",
  "Langlade, Les Ternes, Auvergne-Rhône-Alpes, France",
  "Vác District, Pest, Hungary",
  "Bandera, Polanco, Zamboanga Peninsula, Philippines",
  "243025, Novozybkov, Bryansk, Russia",
  "McMinn, Saint Patrick, New Brunswick, Canada",
  "20-004, Lublin, Lubelskie, Poland",
  "5026, Porirua, Wellington, New Zealand",
  "20901, Silver Spring, Maryland, United States",
  "Paynesville, Zohulong, Montserrado County, Liberia",
  "Valuyki, Belgorod, Russia",
  "655160, Chernogorsk, Khakassia, Russia",
  "Cannon, Michigan, United States",
  "Greater Delhi Area",
  "12305, Schenectady, New York, United States",
  "Scioto Shores, Ohio, United States",
  "29801, Aiken, South Carolina, United States",
  "M28C4B3, Aqtau, Karaganda Region, Kazakhstan",
  "Assen, Drenthe, Netherlands",
  "Rossford, Ohio, United States",
  "Slobodskoy sel'skiy sovet, Vitsyebsk, Belarus",
  "V92 T2H4, Tralee, County Kerry, Ireland",
  "Cameron Highlands, Pahang, Malaysia",
  "R0M, Pipestone, Manitoba, Canada",
  "St Andrews Beach, Victoria, Australia",
  "97143, Tillamook, Oregon, United States",
  "Coal Valley, Illinois, United States",
  "J0S 1C0, Dewittville, Quebec, Canada",
  "Huntersville, North Carolina, United States",
  "37083, Göttingen, Lower Saxony, Germany",
  "03071, New Ipswich, New Hampshire, United States",
  "Creney-près-Troyes, Grand Est, France",
  "S/N, Esmeraldas, Esmeraldas, Ecuador",
  "Tripoli, Wisconsin, United States",
  "29902, Beaufort, South Carolina, United States",
  "693020, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Radford, Yonkers, New York, United States",
  "RG1 8DG, Reading, England, United Kingdom",
  "247790, Mazyr, Homel, Belarus",
  "Langen, Vorarlberg, Austria",
  "Presidio, Veracruz, Mexico",
  "3022 AA, Rotterdam, South Holland, Netherlands",
  "371 44, Karlskrona, Blekinge County, Sweden",
  "Kyzyl-Suu, Issyk-Kul Region, Kyrgyzstan",
  "McKinley, Kelowna, British Columbia, Canada",
  "85605, Bowie, Arizona, United States",
  "57548, Lower Brule, South Dakota, United States",
  "9270, Aalborg, North Denmark Region, Denmark",
  "Ross, Tasmania, Australia",
  "67500, Kokkola, Central Ostrobothnia, Finland",
  "22400, Edirne, Edirne, Türkiye",
  "Slavsk, Kaliningrad, Russia",
  "F18M7T9, Semey, Abay Region, Kazakhstan",
  "660001, Krasnoyarsk, Krasnoyarsk Krai, Russia",
  "35986, Rainsville, Alabama, United States",
  "8500-483, Portimão, Faro, Portugal",
  "Ward, Arkansas, United States",
  "Yelets, Lipetsk, Russia",
  "52100, Ordu, Ordu, Türkiye",
  "Budapest, Hungary",
  "54891, Washburn, Wisconsin, United States",
  "Gormanstown (Grady), County Limerick, Ireland",
  "446012, Syzran, Samara, Russia",
  "Rize, Rize, Türkiye",
  "68776, South Sioux City, Nebraska, United States",
  "Novotroitsk, Orenburg, Russia",
  "Beeville, Texas, United States",
  "41179, Vanceburg, Kentucky, United States",
  "29621, Anderson, South Carolina, United States",
  "M23 9RY, Wythenshawe, England, United Kingdom",
  "Pazaryeri, Bilecik, Türkiye",
  "46800, Kouvola, Kymenlaakso, Finland",
  "Sangarédi, Boké Region, Guinea",
  "Cameron, West Virginia, United States",
  "Orzysz, Warmińsko-mazurskie, Poland",
  "41018, Seville, Andalusia, Spain",
  "Sibley Park, Mankato, Minnesota, United States",
  "Székesfehérvári járás, Fejér, Hungary",
  "45701, Athens, Ohio, United States",
  "LN8 2EZ, Caenby, England, United Kingdom",
  "Plombières-lès-Dijon, Bourgogne-Franche-Comté, France",
  "Beerwah, Jammu & Kashmir, India",
  "Batman, Batman, Türkiye",
  "9900, Novi Pazar, Shumen, Bulgaria",
  "Asıqata, Turkistan Region, Kazakhstan",
  "806 26, Gävle, Gävleborg County, Sweden",
  "71000, Mâcon, Bourgogne-Franche-Comté, France",
  "184044, Kandalaksha, Murmansk, Russia",
  "Leicestershire, England, United Kingdom",
  "50500, Mikkeli, South Savo, Finland",
  "Goshen County, Wyoming, United States",
  "364014, Groznyy, Chechnya, Russia",
  "Carver, Northeast, District of Columbia, United States",
  "1330061, Urayasu, Chiba, Japan",
  "Stanley, England, United Kingdom",
  "Georgetown, Massachusetts, United States",
  "225330, Baranavichy, Brest, Belarus",
  "6044, Kecskemét, Bács-Kiskun, Hungary",
  "97143, Mérida, Yucatán, Mexico",
  "231282, Lida, Hrodna, Belarus",
  "29590, Málaga, Andalusia, Spain",
  "Sumter, South Carolina, United States",
  "100 44, Stockholm, Stockholm County, Sweden",
  "Middlesex, New York, United States",
  "8303, Peshkopi, Dibrë, Albania",
  "NR2 3PA, Norwich, England, United Kingdom",
  "655154, Chernogorsk, Khakassia, Russia",
  "629306, Novy Urengoy, Yamalo-Nenets, Russia",
  "Essexville, Michigan, United States",
  "5210, Odense, Region of Southern Denmark, Denmark",
  "Riga, New York, United States",
  "167004, Syktyvkar, Komi, Russia",
  "Bolívar, Colombia",
  "İkizdere, Rize, Türkiye",
  "629008, Salekhard, Yamalo-Nenets, Russia",
  "Colfax County, New Mexico, United States",
  "78746, Austin, Texas, United States",
  "40-018, Katowice, Śląskie, Poland",
  "171072, Bologoye, Tver’, Russia",
  "231286, Lida, Hrodna, Belarus",
  "Kaposvár, Somogy, Hungary",
  "C22M9G2, Atbasar, Akmola Region, Kazakhstan",
  "Valu lui Traian, Constanţa, Romania",
  "100062, Ploieşti, Prahova, Romania",
  "Cascade, Seychelles",
  "DY11 5AB, Kidderminster, England, United Kingdom",
  "652491, Anzhero-Sudzhensk, Kemerovo, Russia",
  "686110, Palatka, Magadan, Russia",
  "676151, Zeya, Amur, Russia",
  "Enköping, Uppsala County, Sweden",
  "430061, Baia Mare, Maramureş, Romania",
  "Klagenfurt-Villach Area",
  "Convenant Person, Coatréven, Brittany, France",
  "14467, Potsdam, Brandenburg, Germany",
  "Belgrade, Missouri, United States",
  "Callaway, Florida, United States",
  "Lyon County, Minnesota, United States",
  "Hardin, Arkansas, United States",
  "Licking, Ohio, United States",
  "Spink Hall, Sheffield, England, United Kingdom",
  "Genteng, East Java, Indonesia",
  "240 09, Kalamata, Peloponnese, Greece",
  "72395, Richardsons, Tennessee, United States",
  "Cheseaux-sur-Lausanne, Vaud, Switzerland",
  "Bradford, New Hampshire, United States",
  "57810, Savonlinna, South Savo, Finland",
  "675006, Blagoveshchensk, Amur, Russia",
  "Fayette County, Illinois, United States",
  "97006, Beaverton, Oregon, United States",
  "Catawba, Ohio, United States",
  "666681, Ust'-Ilimsk, Irkutsk, Russia",
  "Hickory Corners, Michigan, United States",
  "Cavalier, North Dakota, United States",
  "Kendallville, Indiana, United States",
  "Pisaflores, Hidalgo, Mexico",
  "Mondim de Basto, Vila Real, Portugal",
  "Lomas de Sargentillo, Guayas, Ecuador",
  "44339, Dortmund, North Rhine-Westphalia, Germany",
  "Isle Of Lewis, Scotland, United Kingdom",
  "TR18 2DE, Penzance, England, United Kingdom",
  "64759, Lamar, Missouri, United States",
  "Mccormick, Wichita, Kansas, United States",
  "Almaty Region, Kazakhstan",
  "Valley Village, Los Angeles, California, United States",
  "Cambria, Michigan, United States",
  "Celorico da Beira, Guarda, Portugal",
  "Broome, Western Australia, Australia",
  "Gümüşhane, Gümüşhane, Türkiye",
  "Camden, Minnesota, United States",
  "611 34, Nyköping, Södermanland County, Sweden",
  "Chernyshevskoye, Tatarstan, Russia",
  "04090, Wells, Maine, United States",
  "30238, Jonesboro, Georgia, United States",
  "Dunaivtsi, Khmelnytsky, Ukraine",
  "M28B9H9, Aqtau, Karaganda Region, Kazakhstan",
  "Artëmovskiy, Rostov, Russia",
  "59762, Wise River, Montana, United States",
  "Selo Chumikan, Khabarovsk, Russia",
  "Gracanica, District of Pristina, Kosovo",
  "Zadar, Croatia",
  "Pachuquilla, Hidalgo, Mexico",
  "618511, Solikamsk, Perm, Russia",
  "9911, Kirkenes, Troms og Finnmark, Norway",
  "Garden City, Kansas, United States",
  "Mercer County, Ohio, United States",
  "Grimesthorpe, Sheffield, England, United Kingdom",
  "Mednogorsk, Orenburg, Russia",
  "78400, Karabuk, Karabuk, Türkiye",
  "Van, Van, Türkiye",
  "Sibley, Illinois, United States",
  "Khovanshchina, Mordovia, Russia",
  "TR19 6AE, Penzance, England, United Kingdom",
  "15946, Portage, Pennsylvania, United States",
  "Sarpy County, Nebraska, United States",
  "Fort Concho East, Texas, United States",
  "Hickman, Nebraska, United States",
  "Törökszentmiklós, Jász-Nagykun-Szolnok, Hungary",
  "42630, Konya, Konya, Türkiye",
  "96047, Bamberg, Bavaria, Germany",
  "2321, Hamar, Innlandet, Norway",
  "91047, Luhansk, Luhansk, Ukraine",
  "42420, Henderson, Kentucky, United States",
  "Caswell Hill, Saskatoon, Saskatchewan, Canada",
  "93059, Regensburg, Bavaria, Germany",
  "Tioga, North Dakota, United States",
  "Hawkins Creek, Queensland, Australia",
  "692429, Kavalerovo, Primorye, Russia",
  "AB55 5FD, Keith, Scotland, United Kingdom",
  "53208, Milwaukee, Wisconsin, United States",
  "10463, Bronx, New York, United States",
  "546 30, Thessaloniki, Central Macedonia, Greece",
  "Greater Salta",
  "9410, Heiden, Appenzell Outer-Rhoden, Switzerland",
  "Huron, California, United States",
  "Chatham, Virginia, United States",
  "T0H 0C5, McLennan, Alberta, Canada",
  "Greensville, Hamilton, Ontario, Canada",
  "51367, Kaunas, Kauno, Lithuania",
  "Staunton, Indiana, United States",
  "Pîrîta, Dubăsari District, Moldova",
  "Lushnjë, Fier, Albania",
  "Lexington, Virginia, United States",
  "30811, Lorca, Región de Murcia, Spain",
  "08525, Hopewell, New Jersey, United States",
  "347005, Belaya Kalitva, Rostov, Russia",
  "Erebuni, Yerevan, Armenia",
  "22500, Edirne, Edirne, Türkiye",
  "Polatli, Ankara, Türkiye",
  "973 44, Luleå, Norrbotten County, Sweden",
  "38567, Hickman, Tennessee, United States",
  "Wirtz, Virginia, United States",
  "New Baltimore, Michigan, United States",
  "Gibsonville, North Carolina, United States",
  "Colfax, Illinois, United States",
  "Snyder, New York, United States",
  "Torzhoksky District, Tver’, Russia",
  "Eddyville, Illinois, United States",
  "Ura Vajgurore, Berat, Albania",
  "06074, Perugia, Umbria, Italy",
  "Kecskemét, Bács-Kiskun, Hungary",
  "Sandoval County, New Mexico, United States",
  "7714, Steinkjer, Trøndelag, Norway",
  "Davis, Oklahoma, United States",
  "Reading, Michigan, United States",
  "357550, Pyatigorsk, Stavropol, Russia",
  "Athens, Alabama, United States",
  "Weston, Connecticut, United States",
  "Minnehaha Shores, Maitland, Florida, United States",
  "Turangi, Waikato, New Zealand",
  "130115, Târgovişte, Dîmboviţa, Romania",
  "Aldanskiy, Sakha, Russia",
  "659302, Biysk, Altai Krai, Russia",
  "Birobijan, Russia",
  "446077, Usinsk, Samara, Russia",
  "Einsiedeln, Schwyz, Switzerland",
  "BH4 8DQ, Bournemouth, England, United Kingdom",
  "B0N 0A7, Meaghers Grant, Nova Scotia, Canada",
  "Sulina, Tulcea, Romania",
  "364031, Groznyy, Chechnya, Russia",
  "28001, Madrid, Community of Madrid, Spain",
  "610292, Piatra Neamţ, Neamţ, Romania",
  "Eureka, Illinois, United States",
  "Achinskiy, Novosibirsk, Russia",
  "Austin County, Texas, United States",
  "L10F7F6, Chapaev, West Kazakhstan Region, Kazakhstan",
  "364047, Groznyy, Chechnya, Russia",
  "Sandoval Moraes I, Guanambi, Bahia, Brazil",
  "Tambov, Tambov, Russia",
  "Departamento Confluencia, Neuquén Province, Argentina",
  "Greater Odense Area",
  "92630, Lake Forest, California, United States",
  "Lucas, Kansas, United States",
  "Maury, Saint-Colomb-de-Lauzun, Nouvelle-Aquitaine, France",
  "London, Kentucky, United States",
  "TS6 7LZ, Middlesbrough, England, United Kingdom",
  "Greenville, New York, United States",
  "Forsyth, Michigan, United States",
  "Sully, Challuy, Bourgogne-Franche-Comté, France",
  "Andrés Eloy BLanco, Merida State, Venezuela",
  "Saint-Étienne-le-Molard, Auvergne-Rhône-Alpes, France",
  "78455-000, Lucas do Rio Verde, Mato Grosso, Brazil",
  "CV7 7BG, Berkswell, England, United Kingdom",
  "DL2 2NA, Walworth, England, United Kingdom",
  "Sheridan, California, United States",
  "Tara, Ontario, Canada",
  "CF32 7SU, Price Town, Wales, United Kingdom",
  "Khanty-Mansiysk, Khanty-Mansi, Russia",
  "American Samoa",
  "162603, Cherepovets, Vologda, Russia",
  "90505, Torrance, California, United States",
  "Faro, Pará, Brazil",
  "Jefferson County, Washington, United States",
  "1992, Sion, Valais, Switzerland",
  "McKenzie Bench, British Columbia, Canada",
  "35390, İzmir, İzmir, Türkiye",
  "627427, Chelyuskintsev, Tyumen’, Russia",
  "461637, Buguruslan, Orenburg, Russia",
  "72732, Garfield, Arkansas, United States",
  "Caddo, Arkansas, United States",
  "399263, Yeletskaya Lozovka, Lipetsk, Russia",
  "11211, Belgrade, Serbia",
  "Maury, Fay-sur-Lignon, Auvergne-Rhône-Alpes, France",
  "184510, Monchegorsk, Murmansk, Russia",
  "Humboldt, Minnesota, United States",
  "32100, Isparta, Isparta, Türkiye",
  "Newport News, Virginia, United States",
  "Jersey",
  "Birobidzhansky District, Birobijan, Russia",
  "Crook County, Oregon, United States",
  "Altamira, Tamaulipas, Mexico",
  "23003, Jaén, Andalusia, Spain",
  "88203, Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "50100, Mikkeli, South Savo, Finland",
  "85-792, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "Erath, Leh, Ladakh, India",
  "Coronel Moldes, Córdoba, Argentina",
  "McCormick, Wood Buffalo, Alberta, Canada",
  "Wise River, Montana, United States",
  "Nottoway Village, Florida, United States",
  "7120, Vejle, Region of Southern Denmark, Denmark",
  "Vigo, Galicia, Spain",
  "Senecaville, Ohio, United States",
  "Les Terres, Barneville-Carteret, Normandy, France",
  "628415, Surgut, Khanty-Mansi, Russia",
  "Park County, Colorado, United States",
  "A36K3P7, Almaty, Kazakhstan",
  "Dyeratu, Southern Region, Malawi, Malawi",
  "66000, Yozgat, Yozgat, Türkiye",
  "22911, Charlottesville, Virginia, United States",
  "Walshford, England, United Kingdom",
  "300732, Timişoara, Timiş, Romania",
  "Reshetylivka, Poltava, Ukraine",
  "45543, Otero, Castile-La Mancha, Spain",
  "City Of Leicester, England, United Kingdom",
  "64467, Martinsville, Missouri, United States",
  "Powhatan, Iowa, United States",
  "97626, Fort Klamath, Oregon, United States",
  "087076, Stoeneşti, Giurgiu, Romania",
  "75142, Kaufman, Texas, United States",
  "Sublette County, Wyoming, United States",
  "Chickasaw Ranch Estates, Florida, United States",
  "72949, Ozark, Arkansas, United States",
  "Nyetimber, Bognor Regis, England, United Kingdom",
  "La Tascosa, Durango, Mexico",
  "4935-493, Viana do Castelo, Viana do Castelo, Portugal",
  "49264, Onondaga, Michigan, United States",
  "Le Moule, Pointe-à-Pitre, Guadeloupe",
  "Progress Village, Florida, United States",
  "Trogir, Split-Dalmatia, Croatia",
  "633203, Iskitim, Novosibirsk, Russia",
  "9443, Evenskjær, Troms og Finnmark, Norway",
  "Tulungagung, East Java, Indonesia",
  "Höken, Mörbylånga, Kalmar County, Sweden",
  "St. James Parish County, Louisiana, United States",
  "9026, Klagenfurt, Carinthia, Austria",
  "76185, Karlsruhe, Baden-Württemberg, Germany",
  "Degerfors kommun, Örebro County, Sweden",
  "07047, North Bergen, New Jersey, United States",
  "Woodstock, Ontario, Canada",
  "Tepebaşı, Eskişehir, Türkiye",
  "Gießen, Hesse, Germany",
  "Grimes, Iowa, United States",
  "North Yorkshire, England, United Kingdom",
  "Oulu sub-region, North Ostrobothnia, Finland",
  "44379, Dortmund, North Rhine-Westphalia, Germany",
  "Cherry Valley, Massachusetts, United States",
  "44143, Dortmund, North Rhine-Westphalia, Germany",
  "Greater Caserta Metropolitan Area",
  "47002, Valladolid, Castilla and Leon, Spain",
  "Oktyabr'skiy, Novosibirsk, Russia",
  "Redford, Michigan, United States",
  "Bratskoye, Mykolayiv, Ukraine",
  "Tucker Oaks, Winter Garden, Florida, United States",
  "Huddinge, Stockholm County, Sweden",
  "Bennington, Ohio, United States",
  "23188, Williamsburg, Virginia, United States",
  "Hertford Heath, England, United Kingdom",
  "Summersville, Missouri, United States",
  "665051, Biryusinsk, Irkutsk, Russia",
  "12147, Rensselaerville, New York, United States",
  "Burdur, Türkiye",
  "678960, Neryungri, Sakha, Russia",
  "European Economic Area",
  "Greater Pisa Metropolitan Area",
  "630112, Novosibirsk, Novosibirsk, Russia",
  "10312, Vilnius, Vilniaus, Lithuania",
  "Cheshire Foothills, Flagstaff, Arizona, United States",
  "8006, Zurich, Zurich, Switzerland",
  "R25H0F0, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "04176, Kyiv, Kyiv City, Ukraine",
  "Krasnogorsk, Moscow, Russia",
  "34500, Sarny, Rivne, Ukraine",
  "309184, Gubkin, Belgorod, Russia",
  "Crownsville, Maryland, United States",
  "Rockingham, Western Australia, Australia",
  "Ust-Abakansky District, Khakassia, Russia",
  "Greeley County, Nebraska, United States",
  "Galilée, Terrell 101, Saskatchewan, Canada",
  "HR6 0NX, Wharton, England, United Kingdom",
  "Martinsville, Indiana, United States",
  "B61B0E5, Sarqan, Jetisu Region, Kazakhstan",
  "981 38, Kiruna, Norrbotten County, Sweden",
  "Holts Summit, Missouri, United States",
  "606029, Dzerzhinsk, Nizhniy Novgorod, Russia",
  "Somerset, Ohio, United States",
  "93053, Regensburg, Bavaria, Germany",
  "Devakkottai, Tamil Nadu, India",
  "Eureka, Kansas, United States",
  "Camden, Maine, United States",
  "04275, Leipzig, Saxony, Germany",
  "Yakovlevo, Belgorod, Russia",
  "Walker County, Alabama, United States",
  "674607, Sherlovaya Gora, Zabaykalsky Krai, Russia",
  "Porto Seguro, Bahia, Brazil",
  "623113, Pervoural'sk, Sverdlovsk, Russia",
  "7000-400, Évora, Évora, Portugal",
  "Fillmore, New York, United States",
  "Neuchâtel, Neuchâtel, Switzerland",
  "Bielawa, Mazowieckie, Poland",
  "Plovdiv Metropolitan Area",
  "Holt, England, United Kingdom",
  "Berezniki, Ryazan’, Russia",
  "Bucaramanga Metropolitan Area",
  "13540, Aix-en-Provence, Provence-Alpes-Côte d'Azur, France",
  "KA18 2PL, Ochiltree, Scotland, United Kingdom",
  "76201, Denton, Texas, United States",
  "Russell County, Virginia, United States",
  "Trenton, Florida, United States",
  "Chemung, New York, United States",
  "9000-199, Funchal, Madeira Island, Portugal",
  "Lerwick, Scotland, United Kingdom",
  "430027, Saransk, Mordovia, Russia",
  "Borgholms kommun, Kalmar County, Sweden",
  "Sangarará, Cusco, Peru",
  "HX1 3TW, Halifax, England, United Kingdom",
  "C22C8T7, Atbasar, Akmola Region, Kazakhstan",
  "Greater Potenza Metropolitan Area",
  "Salawat, Bashkortostan, Russia",
  "51005, Ceuta, Ceuta, Spain",
  "5001, Aarau, Aargau, Switzerland",
  "CV2 1GJ, Coventry, England, United Kingdom",
  "04443, Guilford, Maine, United States",
  "J0V 0A4, Namur, Quebec, Canada",
  "Baranavichy, Brest, Belarus",
  "Greater Caen Area",
  "346909, Novoshakhtinsk, Rostov, Russia",
  "Téton, Les Belleville, Auvergne-Rhône-Alpes, France",
  "Lake Mathews, California, United States",
  "Cabella Ligure, Piedmont, Italy",
  "9500-180, Ponta Delgada, Portugal",
  "6007, Kecskemét, Bács-Kiskun, Hungary",
  "Minsk District, Minsk Province, Belarus",
  "NR24 2AS, Edgefield, England, United Kingdom",
  "5000, Namur, Walloon Region, Belgium",
  "71419, Converse, Louisiana, United States",
  "Municipio de San Lucas Sacatepéquez, Sacatepéquez, Guatemala",
  "Dona Angelina, Cláudio, Minas Gerais, Brazil",
  "72738, Hindsville, Arkansas, United States",
  "Neuburg an der Donau, Bavaria, Germany",
  "Mercer County, North Dakota, United States",
  "T0K 0M0, Coleman, Alberta, Canada",
  "Sheffield, Westmoreland, Jamaica",
  "Arhavi, Artvin, Türkiye",
  "Altenberg bei Linz, Upper Austria, Austria",
  "Novotroitskoye, Zabaykalsky Krai, Russia",
  "Swifton, Arkansas, United States",
  "23288, Henrico, Virginia, United States",
  "Niobrara County, Wyoming, United States",
  "Solhan Bucağı, Bingöl, Türkiye",
  "Vologda Metropolitan Area",
  "77588, Pearland, Texas, United States",
  "Scotts, Michigan, United States",
  "La Lande, Grimesnil, Normandy, France",
  "39122, Magdeburg, Saxony-Anhalt, Germany",
  "Chatham, Louisiana, United States",
  "A0K 0E8, Port Saunders, Newfoundland and Labrador, Canada",
  "Yates City, Illinois, United States",
  "412620, Svobodnyy, Saratov, Russia",
  "Kungurskiy okrug, Perm, Russia",
  "Antelope, Montana, United States",
  "Birsky District, Bashkortostan, Russia",
  "07743, Jena, Thuringia, Germany",
  "James, Ontario, Canada",
  "Sinop, Türkiye",
  "Kimball, Minnesota, United States",
  "76570, Rosebud, Texas, United States",
  "140414, Kolomna, Moscow, Russia",
  "Chesterfield, Missouri, United States",
  "Davies, Fargo, North Dakota, United States",
  "Tioga Township, Pennsylvania, United States",
  "South East Street Historic District, Culpeper, Virginia, United States",
  "Shelbyville, Indiana, United States",
  "Ward 1, Quang Tri, Vietnam",
  "Kizel, Perm, Russia",
  "Y21 E7V7, Enniscorthy, County Wexford, Ireland",
  "Furth an der Triesting, Lower Austria, Austria",
  "74146, Tulsa, Oklahoma, United States",
  "Pierce County, Nebraska, United States",
  "10125, Turin, Piedmont, Italy",
  "Opole Metropolitan Area",
  "423815, Naberezhnyye Chelny, Tatarstan, Russia",
  "Palm Beach Gardens, Florida, United States",
  "Benton County, Oregon, United States",
  "3040-223, Coimbra, Coimbra, Portugal",
  "Opolski, Lubelskie, Poland",
  "67218, Wichita, Kansas, United States",
  "Comanche, Texas, United States",
  "676306, Shimanovsk, Amur, Russia",
  "222521, Barysaw, Minsk, Belarus",
  "625007, Tyumen, Tyumen’, Russia",
  "Beckerich, Diekirch, Luxembourg",
  "634029, Tomsk, Tomsk, Russia",
  "82-315, Elbląg, Warmińsko-mazurskie, Poland",
  "Georgetown, Ohio, United States",
  "Washington County, Pennsylvania, United States",
  "Tuscola County, Michigan, United States",
  "76833, Walsheim, Rhineland-Palatinate, Germany",
  "9935, Vadsø, Troms og Finnmark, Norway",
  "98164, Messina, Sicily, Italy",
  "66801, Emporia, Kansas, United States",
  "4608, Rrëshen, Lezhë, Albania",
  "8002, Székesfehérvár, Fejér, Hungary",
  "Wheatland, Pennsylvania, United States",
  "Jasper, Alabama, United States",
  "Fosters Green, Skelmersdale, England, United Kingdom",
  "347939, Taganrog, Rostov, Russia",
  "420170, Bistriţa, Bistriţa-Năsăud, Romania",
  "L10G3F0, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Szekszárd, Tolna, Hungary",
  "Randolph County, North Carolina, United States",
  "Galliavola, Lombardy, Italy",
  "Nelson County, Virginia, United States",
  "346907, Novoshakhtinsk, Rostov, Russia",
  "142111, Podolsk, Moscow, Russia",
  "South Moravia, Czechia",
  "TS9 5PW, Stokesley, England, United Kingdom",
  "Pershing, Indiana, United States",
  "800008, Galaţi, Galaţi, Romania",
  "Sully-la-Chapelle, Centre-Val de Loire, France",
  "663234, Turukhansk, Krasnoyarsk Krai, Russia",
  "M1K 0A1, Scarborough, Ontario, Canada",
  "Sampsons Cove, Richmond, Subdivision C, Nova Scotia, Canada",
  "9016, Lushnjë, Fier, Albania",
  "Donetsk, Rostov, Russia",
  "Sayanogorsk Gorod, Khakassia, Russia",
  "23952, Lunenburg, Virginia, United States",
  "1673, Pepingen, Flemish Region, Belgium",
  "Pamplona, Chartered Community of Navarre, Spain",
  "410067, Oradea, Bihor, Romania",
  "Turgutlu Bucağı, Manisa, Türkiye",
  "92347, Klaipėda, Klaipėdos, Lithuania",
  "28682, Terrell, North Carolina, United States",
  "28002, Albemarle, North Carolina, United States",
  "677027, Yakutsk, Sakha, Russia",
  "Ritchie, New Brunswick, Canada",
  "77002, Houston, Texas, United States",
  "Perkinsville, Chester-Le-Street, England, United Kingdom",
  "Ozark Estates South, Township 11, Arkansas, United States",
  "72458, Powhatan, Arkansas, United States",
  "354014, Sochi, Krasnodar, Russia",
  "Obukhovo, Hrodna, Belarus",
  "Bowman, South Carolina, United States",
  "Vendas Novas, Évora, Portugal",
  "620002, Yekaterinburg, Sverdlovsk, Russia",
  "65101, Vaasa, Ostrobothnia, Finland",
  "052833, Bucharest, Bucharest, Romania",
  "413048, Algeciras, Huila, Colombia",
  "Amphoe Wang Wiset, Trang, Thailand",
  "Bogorodsky District, Kirov, Russia",
  "Arad, Romania",
  "Sāki Nāka, Maharashtra, India",
  "399775, Yelets, Lipetsk, Russia",
  "La Union, Ilocos Region, Philippines",
  "96700, Rovaniemi, Lapland, Finland",
  "55000, Samsun, Samsun, Türkiye",
  "11541, Korosten’, Zhytomyr, Ukraine",
  "Clinton, North Carolina, United States",
  "4836, Arendal, Agder, Norway",
  "420136, Bistriţa, Bistriţa-Năsăud, Romania",
  "Fronteiras, Piauí, Brazil",
  "Donaueschingen, Baden-Württemberg, Germany",
  "Rockford, Illinois, United States",
  "10-089, Olsztyn, Warmińsko-mazurskie, Poland",
  "Brest, Brittany, France",
  "31086, Forsyth, Georgia, United States",
  "Hampton, Georgia, United States",
  "J0E 2B0, Sainte-Anne-de-la-Rochelle, Quebec, Canada",
  "810230, Brăila, Brăila, Romania",
  "Düsseldorf, North Rhine-Westphalia, Germany",
  "33530, Tampere, Pirkanmaa, Finland",
  "169303, Ukhta, Komi, Russia",
  "Daviess County, Missouri, United States",
  "27208, Bennett, North Carolina, United States",
  "S13F0C2, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Lanexa, Virginia, United States",
  "Bryan, Ohio, United States",
  "8750, Glarus, Glarus, Switzerland",
  "Yancey County, North Carolina, United States",
  "Ílhavo, Aveiro, Portugal",
  "E25B3Y1, Maqat, Atyrau Region, Kazakhstan",
  "44400, Malatya, Malatya, Türkiye",
  "Novi di Modena, Emilia-Romagna, Italy",
  "Wilson County, Tennessee, United States",
  "Nizip, Gaziantep, Türkiye",
  "54110, Sakarya, Sakarya, Türkiye",
  "Fentress, North Carolina, United States",
  "2311, Spodnje Hoče, Maribor, Slovenia",
  "64865, Seneca, Missouri, United States",
  "28359, Bremen, Bremen, Germany",
  "346880, Bataysk, Rostov, Russia",
  "356419, Blagodarnyy, Stavropol, Russia",
  "Gayle, England, United Kingdom",
  "Grayshott, England, United Kingdom",
  "692426, Kavalerovo, Primorye, Russia",
  "Seward, Minneapolis, Minnesota, United States",
  "Sheffield, Iowa, United States",
  "Lubliniecki, Śląskie, Poland",
  "51603, Shenandoah, Iowa, United States",
  "Sargento Cabral, Santo Tomé, Santa Fe, Argentina",
  "Albany, New York Metropolitan Area",
  "Robinstown (Tyrrell), County Westmeath, Ireland",
  "Keithsburg, Illinois, United States",
  "653039, Prokopyevsk, Kemerovo, Russia",
  "Kurgan, Russia",
  "Columbiana, Alabama, United States",
  "Kamenka, Arkhangel’sk, Russia",
  "Hampton, New Hampshire, United States",
  "90745, Carson, California, United States",
  "Stanstead, Quebec, Canada",
  "Yazıkonak, Elazığ, Türkiye",
  "Pittsburg, New Hampshire, United States",
  "Stephens, Georgia, United States",
  "E1A 1P2, Dieppe, New Brunswick, Canada",
  "Davison-Schoolcraft, Detroit, Michigan, United States",
  "Toulouse, Occitanie, France",
  "57210, Savonlinna, South Savo, Finland",
  "Houston, Pennsylvania, United States",
  "McMinnville, Tennessee, United States",
  "San Sebastián, Retalhuleu, Guatemala",
  "30088, Stone Mountain, Georgia, United States",
  "347800, Kamensk-Shakhtinskiy, Rostov, Russia",
  "8616, Mo i Rana, Nordland, Norway",
  "610151, Piatra Neamţ, Neamţ, Romania",
  "Sovetskiy, Krasnodar, Russia",
  "Potter, Nebraska, United States",
  "Isle Of Arran, Scotland, United Kingdom",
  "692411, Kavalerovo, Primorye, Russia",
  "169336, Ukhta, Komi, Russia",
  "Tazovsky District, Yamalo-Nenets, Russia",
  "Crawford, Texas, United States",
  "Stanton, Tennessee, United States",
  "Otsego Township, Michigan, United States",
  "Los Lagos Region, Chile",
  "Sabine County, Texas, United States",
  "Liberty, Illinois, United States",
  "Novozybkov, Bryansk, Russia",
  "Pidhaietska miska hromada, Ternopil, Ukraine",
  "682571, Ayan, Khabarovsk, Russia",
  "Greater Portsmouth Area",
  "368826, Oguzer, Dagestan, Russia",
  "Sátao, Viseu, Portugal",
  "Sheffield, Alabama, United States",
  "Cherry Hill, New Jersey, United States",
  "Sumner, Texas, United States",
  "Cartagena, Región de Murcia, Spain",
  "Gorno-Altaysk, Altay, Russia",
  "East Leake, England, United Kingdom",
  "Linares, Napo, Ecuador",
  "3104, Salgótarján, Nógrád, Hungary",
  "68516, Lincoln, Nebraska, United States",
  "634045, Tomsk, Tomsk, Russia",
  "55116, Mainz, Rhineland-Palatinate, Germany",
  "Keşap, Giresun, Türkiye",
  "BH8 0AA, Bournemouth, England, United Kingdom",
  "981 43, Kiruna, Norrbotten County, Sweden",
  "Greater Irakleio Area",
  "76182, North Richland Hills, Texas, United States",
  "Kings Mountain, North Carolina, United States",
  "F42G0M9, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "Odunpazari, Eskişehir, Türkiye",
  "Elbląski, Warmińsko-mazurskie, Poland",
  "385018, Maykop, Adygea, Russia",
  "140483, Kolomna, Moscow, Russia",
  "Gibson County, Indiana, United States",
  "270 67, Pírgos, Western Greece, Greece",
  "404130, Volzhskiy, Volgograd, Russia",
  "Çorum, Türkiye",
  "Portoviejo Canton, Manabí, Ecuador",
  "15703, Santiago de Compostela, Galicia, Spain",
  "Coosan, County Westmeath, Ireland",
  "429123, Shumerlya, Chuvashia, Russia",
  "Ellisville, Missouri, United States",
  "Staudach, Bavaria, Germany",
  "45424, Dayton, Ohio, United States",
  "6004, Kecskemét, Bács-Kiskun, Hungary",
  "446114, Chapayevsk, Samara, Russia",
  "346530, Shakhty, Rostov, Russia",
  "686114, Palatka, Magadan, Russia",
  "78300, Karabuk, Karabuk, Türkiye",
  "Lebanon, Ohio, United States",
  "Penderyn, Wales, United Kingdom",
  "Hooker County, Nebraska, United States",
  "72371, Rozdol, Zaporizhzhya, Ukraine",
  "Coffeen, Illinois, United States",
  "Miziya, Vratsa, Bulgaria",
  "Winnebago, Minnesota, United States",
  "Holmes Hill, Oamaru, Otago, New Zealand",
  "624761, Verkhnyaya Salda, Sverdlovsk, Russia",
  "McHenry, Maryland, United States",
  "F18X6Y9, Semey, Abay Region, Kazakhstan",
  "152909, Rybinsk, Yaroslavl’, Russia",
  "Bell County, Texas, United States",
  "05452, Essex Junction, Vermont, United States",
  "Arhavi Bucağı, Artvin, Türkiye",
  "Boone, Iowa, United States",
  "Hasenberg, Trippstadt, Rhineland-Palatinate, Germany",
  "Rowans, Saint George, Barbados",
  "NR24 2RL, Edgefield, England, United Kingdom",
  "Kuznetsky District, Penza, Russia",
  "86-305, Grudziądz, Kujawsko-pomorskie, Poland",
  "Kurgan Metropolitan Area",
  "157, Santiago de Compostela, Galicia, Spain",
  "17489, Greifswald, Mecklenburg-West Pomerania, Germany",
  "456205, Zlatoust, Chelyabinsk, Russia",
  "77555, Galveston, Texas, United States",
  "3071 NT, Rotterdam, South Holland, Netherlands",
  "1261, Ljubljana, Ljubljana, Slovenia",
  "Franklin County, Massachusetts, United States",
  "412751, Severnyy, Saratov, Russia",
  "628007, Khanty-Mansiysk, Khanty-Mansi, Russia",
  "84121, Salt Lake City, Utah, United States",
  "Pavlodar District, Pavlodar Region, Kazakhstan",
  "35015, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Greater Hyderabad Area",
  "0470031, Otaru, Hokkaido, Japan",
  "Troitskiy, Penza, Russia",
  "IP13 0NW, Pettistree, England, United Kingdom",
  "Castelló d'Empúries, Catalonia, Spain",
  "Belomorsk, Karelia, Russia",
  "Lyubertsy, Moscow, Russia",
  "Winnebago, Nebraska, United States",
  "Fafe, Braga, Portugal",
  "72542, Hardy, Arkansas, United States",
  "9448, Evenskjær, Troms og Finnmark, Norway",
  "57108, Sioux Falls, South Dakota, United States",
  "97130, Rovaniemi, Lapland, Finland",
  "Garfield, Minnesota, United States",
  "Bel Meade Homes Association, San Antonio, Texas, United States",
  "Kazanlı, İçel, Türkiye",
  "Juniata, Pennsylvania, United States",
  "Bagdarinskoye, Buryatia, Russia",
  "A Coruña, Galicia, Spain",
  "IP13 0WD, Pettistree, England, United Kingdom",
  "45-020, Opole, Opolskie, Poland",
  "640014, Kurgan, Kurgan, Russia",
  "15-002, Białystok, Podlaskie, Poland",
  "Schleichern, Gerolsbach, Bavaria, Germany",
  "243012, Novozybkov, Bryansk, Russia",
  "187555, Tikhvin, Leningrad, Russia",
  "Wood County, Ohio, United States",
  "2421, Webbers Creek, New South Wales, Australia",
  "Richland County, Montana, United States",
  "Évora, Évora, Portugal",
  "Kirsinskoye, Chelyabinsk, Russia",
  "Wheelersburg, Ohio, United States",
  "01187, Dresden, Saxony, Germany",
  "Almaly District, Almaty, Kazakhstan",
  "South Australia, Australia",
  "Hood, Virginia, United States",
  "65125, Pescara, Abruzzi, Italy",
  "Hanover, Manitoba, Canada",
  "1042, Tirana, Tirana, Albania",
  "Wirtsmühle, Weiding, Bavaria, Germany",
  "EH7 4BN, Edinburgh, Scotland, United Kingdom",
  "29116, Orangeburg, South Carolina, United States",
  "Dallas, Oregon, United States",
  "8725, Mo i Rana, Nordland, Norway",
  "628301, Nefteyugansk, Khanty-Mansi, Russia",
  "Mercer Island, Washington, United States",
  "53400, Lappeenranta, South Karelia, Finland",
  "09224, Chemnitz, Saxony, Germany",
  "2660, Boboshevo, Kyustendil, Bulgaria",
  "67202, Wichita, Kansas, United States",
  "Perry County, Kentucky, United States",
  "40200, Jyväskylä, Central Finland, Finland",
  "87004, Bernalillo, New Mexico, United States",
  "167026, Syktyvkar, Komi, Russia",
  "628402, Surgut, Khanty-Mansi, Russia",
  "Rolette County, North Dakota, United States",
  "NE9 5AB, Gateshead, England, United Kingdom",
  "A92 NP73, Drogheda, Ireland",
  "3250, Rogaška Slatina, Šmarje pri Jelšah, Slovenia",
  "Yellow Pinch, New South Wales, Australia",
  "357112, Nevinnomyssk, Stavropol, Russia",
  "Bharat Petroleum Industri, Kanayannur, Kerala, India",
  "La Alfalfa, Zacatecas, Mexico",
  "424005, Yoshkar-Ola, Mari El, Russia",
  "920001, Slobozia, Ialomiţa, Romania",
  "Fluvanna County Courthouse Historic District, Virginia, United States",
  "Rochester, New York Metropolitan Area",
  "Limoges-Fourches, Île-de-France, France",
  "20609, Narva, Ida-Virumaa, Estonia",
  "63000, Clermont-Ferrand, Auvergne-Rhône-Alpes, France",
  "Carter County, Tennessee, United States",
  "Dar es-Salaam, Dar es Salaam, Tanzania",
  "692055, Lesozavodsk, Primorye, Russia",
  "Tikhvinka, Omsk, Russia",
  "Fribourg, Switzerland",
  "Trippul West, County Kerry, Ireland",
  "South Jordan, Utah, United States",
  "Polis Chrysochous, Paphos, Cyprus",
  "Lunas, Kedah, Malaysia",
  "676855, Belogorsk, Amur, Russia",
  "Dublin 10, County Dublin, Ireland",
  "Umbar Pada Nandade, India",
  "51593, Harlan, Iowa, United States",
  "676287, Tynda, Amur, Russia",
  "Dorbod Banner, Inner Mongolia, China",
  "Ryazan’, Russia",
  "40-005, Katowice, Śląskie, Poland",
  "Liberty, South Carolina, United States",
  "169306, Ukhta, Komi, Russia",
  "B2740AHF, Arrecifes, Buenos Aires Province, Argentina",
  "NE8 1LU, Gateshead, England, United Kingdom",
  "Partizanskiy, Bashkortostan, Russia",
  "Szombathelyi Járás, Vas, Hungary",
  "Pskov, Russia",
  "Tripp, South Dakota, United States",
  "20772, Upper Marlboro, Maryland, United States",
  "Shelby County, Texas, United States",
  "Cleveland, Oklahoma, United States",
  "Dnipriany, Kherson, Ukraine",
  "Murgul, Artvin, Türkiye",
  "Botswana",
  "Nassau Bay, Texas, United States",
  "98450, Kemijärvi, Lapland, Finland",
  "Greater Palermo Metropolitan Area",
  "29603, Marbella, Andalusia, Spain",
  "Karlstadt, Bavaria, Germany",
  "65130, Vaasa, Ostrobothnia, Finland",
  "El Refugio, Baja California, Mexico",
  "Converses, Vernosc-lès-Annonay, Auvergne-Rhône-Alpes, France",
  "Île-de-France, France",
  "Lucasville, Ohio, United States",
  "Hayes, England, United Kingdom",
  "Le Bladenq, Lédergues, Occitanie, France",
  "Sharkey County, Mississippi, United States",
  "9500-702, Ponta Delgada, Portugal",
  "700, Egilsstaðir, Eastern Region, Iceland",
  "Unecha, Bryansk, Russia",
  "352545, Labinsk, Krasnodar, Russia",
  "2022 EG, Haarlem, North Holland, Netherlands",
  "Reeves, County Kildare, Ireland",
  "93051, Regensburg, Bavaria, Germany",
  "Araladinni, Karnataka, India",
  "108, Reykjavík, Capital Region, Iceland",
  "San Roque Gonzáles de Santa Cruz, Department of Paraguari, Paraguay",
  "Bogotolsky District, Krasnoyarsk Krai, Russia",
  "5950, Nikopol, Pleven, Bulgaria",
  "Saranda, Vlorë, Albania",
  "602 33, Norrköping, Östergötland County, Sweden",
  "85-791, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "50678, Cologne, North Rhine-Westphalia, Germany",
  "Mainzac, Nouvelle-Aquitaine, France",
  "Миялы, Atyrau Region, Kazakhstan",
  "Bolintin Deal, Giurgiu, Romania",
  "Stewartsville, North Carolina, United States",
  "Andrews, North Carolina, United States",
  "Iron Station, North Carolina, United States",
  "13220, Hämeenlinna, Kanta-Häme, Finland",
  "Sangaria, Rajasthan, India",
  "73487, Hennepin, Oklahoma, United States",
  "Middle Lahave, Lunenburg, Nova Scotia, Canada",
  "67030, Zonguldak, Zonguldak, Türkiye",
  "Clatsop County, Oregon, United States",
  "79101, Prijedor, Serb Republic, Bosnia and Herzegovina",
  "Tunceli, Tunceli, Türkiye",
  "Cottonwood, Colorado, United States",
  "North Sumatra, Indonesia",
  "Artvin, Artvin, Türkiye",
  "Nelson County, Kentucky, United States",
  "Glacière, Belfort-du-Quercy, Occitanie, France",
  "Bee County, Texas, United States",
  "5700, Fort Beaufort, Eastern Cape, South Africa",
  "Omsk Metropolitan Area",
  "692854, Partizansk, Primorye, Russia",
  "626158, Tobolsk, Tyumen’, Russia",
  "Salem, Oregon, United States",
  "27127, Winston-Salem, North Carolina, United States",
  "G33 4UX, Glasgow, Scotland, United Kingdom",
  "Cimarron Run, Oklahoma, United States",
  "96782, Pearl City, Hawaii, United States",
  "Leeds, England, United Kingdom",
  "4823, Arendal, Agder, Norway",
  "Sterling, Virginia, United States",
  "Iaşi, Iaşi, Romania",
  "2838, Gjøvik, Innlandet, Norway",
  "692400, Kavalerovo, Primorye, Russia",
  "Joséphine, Les Lucs-sur-Boulogne, Pays de la Loire, France",
  "Alexandria, Scotland, United Kingdom",
  "Yellowhead County, Alberta, Canada",
  "Wells, Maine, United States",
  "9400, Aalborg, North Denmark Region, Denmark",
  "03003, Alicante, Valencian Community, Spain",
  "31220, Osijek, Osijek-Baranja, Croatia",
  "Bandirma, Balıkesir, Türkiye",
  "65207, Wiesbaden, Hesse, Germany",
  "655792, Sayanogorsk, Khakassia, Russia",
  "59803, Missoula, Montana, United States",
  "Tarrant Crawford, England, United Kingdom",
  "Darke Peak, South Australia, Australia",
  "Avery Place, Siloam Springs, Arkansas, United States",
  "Pendleton County, Kentucky, United States",
  "6056, Sarnen, Obwalden, Switzerland",
  "352 63, Växjö, Kronoberg County, Sweden",
  "50674, Cologne, North Rhine-Westphalia, Germany",
  "9389, Finnsnes, Troms og Finnmark, Norway",
  "17000, La Rochelle, Nouvelle-Aquitaine, France",
  "Lagos State, Nigeria",
  "05200, Amasya, Amasya, Türkiye",
  "Queens, Prince Edward Island, Canada",
  "Hanson County, South Dakota, United States",
  "60313, Frankfurt, Hesse, Germany",
  "452451, Birsk, Bashkortostan, Russia",
  "Nicosia, Cyprus",
  "Sunny Acres, Gentry, Arkansas, United States",
  "Norrköping, Östergötland County, Sweden",
  "Zelenodolsky District, Tatarstan, Russia",
  "Cottonwood Falls, Kansas, United States",
  "Thal, Solothurn, Switzerland",
  "San Pedro de Conchos, Rosales, Chihuahua, Mexico",
  "A92 E2W1, Drogheda, Ireland",
  "70174, Stuttgart, Baden-Württemberg, Germany",
  "Sheridan County, Kansas, United States",
  "369015, Cherkessk, Karachay-Cherkessia, Russia",
  "57200, Sinop, Sinop, Türkiye",
  "Delta, Michigan, United States",
  "Toledo, Washington, United States",
  "423254, Leninogorsk, Tatarstan, Russia",
  "462292, Mednogorsk, Orenburg, Russia",
  "Harlan Square, Ocean City, Florida, United States",
  "Saratoga, Wyoming, United States",
  "Kapaklı, Tekirdağ, Türkiye",
  "27603, Raleigh, North Carolina, United States",
  "426034, Izhevsk, Udmurtia, Russia",
  "Garden Valley, California, United States",
  "Novo Brdo, District of Pristina, Kosovo",
  "152900, Rybinsk, Yaroslavl’, Russia",
  "Chagda, Sakha, Russia",
  "Gliwice County, Śląskie, Poland",
  "Hickman Fields, Bentonville, Arkansas, United States",
  "Warri South, Delta State, Nigeria",
  "Mount Lebanon Governorate, Lebanon",
  "270, Yadkin, North Carolina, United States",
  "Pageruyung, Kecamatan Pageruyung, Central Java, Indonesia",
  "Frontier 19, Saskatchewan, Canada",
  "Amersfoort, Utrecht, Netherlands",
  "Blaine Lake 434, Saskatchewan, Canada",
  "Livingston, California, United States",
  "Carbondale-Marion Area",
  "Gorna Oryakhovitsa, Veliko Turnovo, Bulgaria",
  "Basel, Switzerland",
  "07055, Passaic, New Jersey, United States",
  "Rijssen-Holten, Overijssel, Netherlands",
  "692412, Kavalerovo, Primorye, Russia",
  "Les Abrets en Dauphiné, Auvergne-Rhône-Alpes, France",
  "53407, Racine, Wisconsin, United States",
  "Glacier Ridge, Madison, Wisconsin, United States",
  "39611, El Astillero, Cantabria, Spain",
  "141600, Klin, Moscow, Russia",
  "Kenedy, Campina das Missões, Rio Grande do Sul, Brazil",
  "7045, Trondheim, Trøndelag, Norway",
  "Pukekohe East, New Zealand",
  "South Slope, Brooklyn, New York, United States",
  "Waynesboro, Mississippi, United States",
  "Providence Forge, Virginia, United States",
  "Mons, Walloon Region, Belgium",
  "Pugachëvskiy, Voronezh, Russia",
  "45638, Pepino, Castile-La Mancha, Spain",
  "Swain, Etmadpur, Uttar Pradesh, India",
  "Lake Mary, Florida, United States",
  "Koplik, Shkodër, Albania",
  "Boztepe, Kırşehir, Türkiye",
  "Sandviken, Gävleborg County, Sweden",
  "352905, Armavir, Krasnodar, Russia",
  "138, Tompkins, New York, United States",
  "Linz, Upper Austria, Austria",
  "Sandıklı, Afyon, Türkiye",
  "Potterspury, England, United Kingdom",
  "Warsaw, Missouri, United States",
  "Schenectady, New York, United States",
  "Obshtina Rodopi, Plovdiv, Bulgaria",
  "B3Z 0B4, Stillwater Lake, Nova Scotia, Canada",
  "Baker City, Oregon, United States",
  "Millersville, Pennsylvania, United States",
  "Kovancılar, Elazığ, Türkiye",
  "29319, Spartanburg, South Carolina, United States",
  "Fulton County, Kentucky, United States",
  "Isle Of Wight, England, United Kingdom",
  "677004, Yakutsk, Sakha, Russia",
  "Artashat region, Ararat Province, Armenia",
  "13326, Cooperstown, New York, United States",
  "3800-032, Aveiro, Aveiro, Portugal",
  "Greater Le Mans Area",
  "Ryazansky District, Ryazan’, Russia",
  "Bandera, Kunda, Uttar Pradesh, India",
  "Sur, Ash Sharqiyah South Governorate, Oman",
  "Hancock, Vermont, United States",
  "353909, Novorossiysk, Krasnodar, Russia",
  "Southeast Asia",
  "62000, Arras, Hauts-de-France, France",
  "Upper Mifflin, Pennsylvania, United States",
  "Collinsville, Virginia, United States",
  "Arrondissement of Brussels-Capital, Brussels Region, Belgium",
  "Powells Point, North Carolina, United States",
  "Orsha District, Vitsyebsk, Belarus",
  "Tomar, Santarém, Portugal",
  "Hillsborough County, New Hampshire, United States",
  "Wickliffe, Ohio, United States",
  "1118, Budapest, Budapest, Hungary",
  "Casper, Wyoming, United States",
  "Cimarron, Colorado, United States",
  "7306, Middlesex, Tasmania, Australia",
  "82072, Laramie, Wyoming, United States",
  "Newport News City County, Virginia, United States",
  "Rezekne, Latvia",
  "Williamson County, Illinois, United States",
  "38343, Humboldt, Tennessee, United States",
  "Kaleida, Pembina, Manitoba, Canada",
  "Wilkinson Township, Minnesota, United States",
  "90547, Stein bei Nürnberg, Bavaria, Germany",
  "19342, Glen Mills, Pennsylvania, United States",
  "Tuymazy, Bashkortostan, Russia",
  "626153, Tobolsk, Tyumen’, Russia",
  "York County, South Carolina, United States",
  "423258, Leninogorsk, Tatarstan, Russia",
  "Turkmenistan",
  "162624, Cherepovets, Vologda, Russia",
  "Handforth, England, United Kingdom",
  "Fort Campbell, Tennessee, United States",
  "Pheasant Run, Harrisonburg, Virginia, United States",
  "25-501, Kielce, Świętokrzyskie, Poland",
  "Giurgiu, Romania",
  "Novo Progresso, Pará, Brazil",
  "68320, Kokkola, Central Ostrobothnia, Finland",
  "Butler, Pipestone, Manitoba, Canada",
  "PA16 0XA, Greenock, Scotland, United Kingdom",
  "8775, Schwanden, Glarus, Switzerland",
  "Alexandria, Egypt",
  "981 37, Kiruna, Norrbotten County, Sweden",
  "Clarke, Clarington, Ontario, Canada",
  "97315, Sinnamary, Cayenne, French Guiana",
  "Danville, Pennsylvania, United States",
  "16633, Defiance, Pennsylvania, United States",
  "LV-1002, Riga, Riga, Latvia",
  "647005, Dudinka, Krasnoyarsk Krai, Russia",
  "Juniata, Nebraska, United States",
  "Clearfield, Utah, United States",
  "53049, Torrita di Siena, Tuscany, Italy",
  "06121, Perugia, Umbria, Italy",
  "Austin, Texas Metropolitan Area",
  "Hendersonville, Tennessee, United States",
  "Balıkesir, Balıkesir, Türkiye",
  "Mörbylånga, Kalmar County, Sweden",
  "58070, Sivas, Sivas, Türkiye",
  "6121, Cadiz, Western Visayas, Philippines",
  "110058, Piteşti, Argeş, Romania",
  "Niš, Centralna Srbija, Serbia",
  "347922, Taganrog, Rostov, Russia",
  "Stephenston, South Australia, Australia",
  "49001, Kaunas, Kauno, Lithuania",
  "09726-430, São Bernardo do Campo, São Paulo, Brazil",
  "33213, Gijón, Principality of Asturias, Spain",
  "22807, Harrisonburg, Virginia, United States",
  "2605, Hughes, Australian Capital Territory, Australia",
  "Essey-lès-Nancy, Grand Est, France",
  "Todd County, Minnesota, United States",
  "Bristol, Illinois, United States",
  "Otar-Dubrovka, Tatarstan, Russia",
  "VLT 1444, Valletta, Valletta, Malta",
  "602257, Murom, Vladimir, Russia",
  "LE12 6AL, East Leake, England, United Kingdom",
  "871 32, Härnösand, Västernorrland County, Sweden",
  "40237, Düsseldorf, North Rhine-Westphalia, Germany",
  "Uman’, Cherkasy, Ukraine",
  "Korostyshiv Urban AmalgametedTerritorial Community, Zhytomyr, Ukraine",
  "La Trailla, Toudon, Provence-Alpes-Côte d'Azur, France",
  "95910, Alleghany, California, United States",
  "Blount, Illinois, United States",
  "Sainte-Marie, Quebec, Canada",
  "Langlade, Saint-Léonard-de-Noblat, Nouvelle-Aquitaine, France",
  "76429, Caddo, Texas, United States",
  "981 32, Kiruna, Norrbotten County, Sweden",
  "89706, Carson City, Nevada, United States",
  "McPherson County, Nebraska, United States",
  "Es Semara Province, Laâyoune-Sakia El Hamra, Morocco",
  "6260, Ålesund, Møre og Romsdal, Norway",
  "24150, Erzincan, Erzincan, Türkiye",
  "Judith Basin County, Montana, United States",
  "Aberdeen, North Carolina, United States",
  "S11 7AA, Sheffield, England, United Kingdom",
  "Hancock, Minnesota, United States",
  "Madison, Alabama, United States",
  "Bielefeld, North Rhine-Westphalia, Germany",
  "27500, Gaziantep, Gaziantep, Türkiye",
  "Jonesboro, Georgia, United States",
  "400001, Volgograd, Volgograd, Russia",
  "9500-082, Ponta Delgada, Portugal",
  "Andrews, Indiana, United States",
  "29013, Málaga, Andalusia, Spain",
  "Portsmouth, Saint John Parish, Dominica",
  "452689, Neftekamsk, Bashkortostan, Russia",
  "Egeris, Central Denmark Region, Denmark",
  "Bydgoski, Kujawsko-pomorskie, Poland",
  "25700, Erzurum, Erzurum, Türkiye",
  "Colibași, Cahul District, Moldova",
  "Gansevoort/East Steuben Streets Historic District, New York, United States",
  "Villeurbanne, Auvergne-Rhône-Alpes, France",
  "Park Ridge, Illinois, United States",
  "Montgomery County, Tennessee, United States",
  "542 42, Mariestad, Västra Götaland County, Sweden",
  "Warsaw, Indiana, United States",
  "46005, Kaunas, Kauno, Lithuania",
  "655015, Abakan, Khakassia, Russia",
  "Lovere, Lombardy, Italy",
  "694440, Aleksandrovsk-Sakhalinskiy, Sakhalin, Russia",
  "Yeniseysky District, Krasnoyarsk Krai, Russia",
  "8970, Poperinge, Flemish Region, Belgium",
  "Graham County, Arizona, United States",
  "18702, Wilkes-Barre, Pennsylvania, United States",
  "352335, Ust’-Labinsk, Krasnodar, Russia",
  "8500, Frauenfeld, Thurgau, Switzerland",
  "Moinești, Bacău, Romania",
  "Northampton, England, United Kingdom",
  "Caswell Bay, Swansea, Wales, United Kingdom",
  "Turner, Australian Capital Territory, Australia",
  "Caraffa di Catanzaro, Calabria, Italy",
  "Oymyakonsky District, Sakha, Russia",
  "Buenavista East, Candelaria, Calabarzon, Philippines",
  "44-151, Gliwice, Śląskie, Poland",
  "McKinley, Galimuyod, Ilocos Region, Philippines",
  "Turner County, South Dakota, United States",
  "Taylor, Texas, United States",
  "730006, Vaslui, Vaslui, Romania",
  "456206, Zlatoust, Chelyabinsk, Russia",
  "Otero, Herrerías, Cantabria, Spain",
  "V3H 5B1, Port Moody, British Columbia, Canada",
  "R13A9C6, Beyneu, Mangystau Region, Kazakhstan",
  "R13A4B9, Beyneu, Mangystau Region, Kazakhstan",
  "Luganville, Sanma, Vanuatu",
  "629811, Noyabrsk, Yamalo-Nenets, Russia",
  "Brazoria County, Texas, United States",
  "5247 VC, ’s-Hertogenbosch, North Brabant, Netherlands",
  "NN1 5FQ, Northampton, England, United Kingdom",
  "86-304, Grudziądz, Kujawsko-pomorskie, Poland",
  "Georgetown, California, United States",
  "Moore County, North Carolina, United States",
  "Morrill, San Jose, California, United States",
  "74074, Stillwater, Oklahoma, United States",
  "429102, Shumerlya, Chuvashia, Russia",
  "York, England, United Kingdom",
  "21629, Denton, Maryland, United States",
  "2704, Narvacan, Ilocos Region, Philippines",
  "47119, Floyds Knobs, Indiana, United States",
  "223 57, Malmo, Skåne County, Sweden",
  "Wood County, West Virginia, United States",
  "Horry County, South Carolina, United States",
  "641217, Yurgamysh, Kurgan, Russia",
  "Caldwell, New Jersey, United States",
  "02330, Carver, Massachusetts, United States",
  "Caddo Village, Texas, United States",
  "93314, Bakersfield, California, United States",
  "Athens, Ohio, United States",
  "302 20, Halmstad, Halland County, Sweden",
  "Lillehammer, Innlandet, Norway",
  "97-300, Piotrków Trybunalski, Łódzkie, Poland",
  "Memaliaj, Gjirokastër, Albania",
  "Louisa County, Virginia, United States",
  "Claiborne Parish County, Louisiana, United States",
  "Chase Mills, New York, United States",
  "Angers, Pays de la Loire, France",
  "Limestone, New South Wales, Australia",
  "Kalachinsky District, Omsk, Russia",
  "44005, Ashtabula, Ohio, United States",
  "Les Parmeries, Carlux, Nouvelle-Aquitaine, France",
  "9380, Finnsnes, Troms og Finnmark, Norway",
  "39417-000, Engenheiro Navarro, Minas Gerais, Brazil",
  "Wrightstown, Wisconsin, United States",
  "Ansião, Leiria, Portugal",
  "Kane, Iowa, United States",
  "34211, Kragujevac, Centralna Srbija, Serbia",
  "Wayne County, Pennsylvania, United States",
  "56500, Siirt, Siirt, Türkiye",
  "Athens, Georgia, United States",
  "80126, Naples, Campania, Italy",
  "S9H 0A1, Swift Current, Saskatchewan, Canada",
  "42409, Dixon, Kentucky, United States",
  "Lyon, Auvergne-Rhône-Alpes, France",
  "5000, Aarau, Aargau, Switzerland",
  "Clarksville, Tennessee, United States",
  "Tres Brazos, Tabasco, Mexico",
  "367026, Makhachkala, Dagestan, Russia",
  "Otterup, Region of Southern Denmark, Denmark",
  "Sevastopol, Sevastopol, Ukraine",
  "Severomorsk, Murmansk, Russia",
  "Salem, New Hampshire, United States",
  "Les Hameaux de la Mer, Barneville-Carteret, Normandy, France",
  "Waltham Chase, England, United Kingdom",
  "Aïn Kercha, Oum El Bouaghi, Algeria",
  "Eschenbach, Lucerne, Switzerland",
  "Yellow Grass, Saskatchewan, Canada",
  "Blandford St Mary, Blandford Forum, England, United Kingdom",
  "Halifax, Massachusetts, United States",
  "Crook County, Wyoming, United States",
  "Benelux",
  "Benson, Arizona, United States",
  "Kamez, Tirana, Albania",
  "Price, Wisconsin, United States",
  "New Caledonia",
  "Pershing Heights, Florida, United States",
  "45870-000, Potiraguá, Bahia, Brazil",
  "TR18 2HB, Penzance, England, United Kingdom",
  "75067, Lewisville, Texas, United States",
  "665835, Angarsk, Irkutsk, Russia",
  "5964, Nikopol, Pleven, Bulgaria",
  "423575, Nizhnekamsk, Tatarstan, Russia",
  "E3G 5X6, McLeod Hill, New Brunswick, Canada",
  "84309, Deweyville, Utah, United States",
  "Moradabad, Uttar Pradesh, India",
  "21230, Baltimore, Maryland, United States",
  "New Hampshire, United States",
  "606410, Balakhna, Nizhniy Novgorod, Russia",
  "Saint-Georges-sur-Eure, Centre-Val de Loire, France",
  "33970, Lehigh Acres, Florida, United States",
  "22033, Fairfax, Virginia, United States",
  "352 31, Växjö, Kronoberg County, Sweden",
  "98145, Messina, Sicily, Italy",
  "Charters Towers, Queensland, Australia",
  "Greater Morgantown Area",
  "Hawkinsville, Georgia, United States",
  "Dorchester, England, United Kingdom",
  "Huntington, Indiana, United States",
  "3977, Cannons Creek, Victoria, Australia",
  "Ferguson, Missouri, United States",
  "Barton County, Missouri, United States",
  "Les Rolettes, Venesmes, Centre-Val de Loire, France",
  "Oktyabrsky, Altai Krai, Russia",
  "Claiborne East, Loveland, Ohio, United States",
  "San Martín, Peru",
  "8043, Graz, Styria, Austria",
  "364051, Groznyy, Chechnya, Russia",
  "Pendleton, Missouri, United States",
  "Karkaralinsk, Karaganda Region, Kazakhstan",
  "Saline County, Nebraska, United States",
  "Stewartby, England, United Kingdom",
  "2511 BT, The Hague, South Holland, Netherlands",
  "Çiğdemli, Yozgat, Türkiye",
  "Crawford County, Missouri, United States",
  "Ust’-Ishimskiy Rayon, Omsk, Russia",
  "Maury, Caussade, Occitanie, France",
  "Beaumont-Port Arthur Area",
  "5097, Redwood Park, South Australia, Australia",
  "Sully, Virginia, United States",
  "Grand-Santi, Saint-Laurent-du-Maroni, French Guiana",
  "76108, Fort Worth, Texas, United States",
  "Dundalk, County Louth, Ireland",
  "Tatarskoye, Smolensk, Russia",
  "632861, Karasuk, Novosibirsk, Russia",
  "Mogocha, Zabaykalsky Krai, Russia",
  "Llano Chico, Pichincha, Ecuador",
  "Greater Västerås Metropolitan Area",
  "Stokes Valley, Lower Hutt, Wellington, New Zealand",
  "78230, Le Pecq, Île-de-France, France",
  "403895, Kamyshin, Volgograd, Russia",
  "455045, Magnitogorsk, Chelyabinsk, Russia",
  "County Donegal, Ireland",
  "McCook County, South Dakota, United States",
  "Guernsey, Iowa, United States",
  "44001, Amherst, Ohio, United States",
  "Genesee, Idaho, United States",
  "Noble County, Indiana, United States",
  "Hyderabad, Telangana, India",
  "Churchill, Ontario, Canada",
  "Essex, Connecticut, United States",
  "Union County, South Carolina, United States",
  "Greater Madrid Metropolitan Area",
  "S0A 1E0, Grayson, Saskatchewan, Canada",
  "714 08, Iráklion, Crete, Greece",
  "Jönköpings kommun, Jönköping County, Sweden",
  "89100, Reggio di Calabria, Calabria, Italy",
  "Grahams Creek, Queensland, Australia",
  "Apsheronsky District, Krasnodar, Russia",
  "Appenzell Outer-Rhoden, Switzerland",
  "353684, Yeysk, Krasnodar, Russia",
  "Sunflower Phase1, Pune, Maharashtra, India",
  "68641, Howells, Nebraska, United States",
  "Tuscarawas, Ohio, United States",
  "9408, Harstad, Troms og Finnmark, Norway",
  "Sokol’skoye, Ivanovo, Russia",
  "SG13 9BE, Hertford, England, United Kingdom",
  "Murter-Kornati, Sibenik-Knin, Croatia",
  "Garfield County, Oklahoma, United States",
  "Polk County, Arkansas, United States",
  "Mooresville, North Carolina, United States",
  "Powell County, Montana, United States",
  "49125, Sawyer, Michigan, United States",
  "Cambridge Gulf, Western Australia, Australia",
  "38601, Abbeville, Mississippi, United States",
  "20-819, Lublin, Lubelskie, Poland",
  "Orenburg, Russia",
  "Birmingham metropolitan area, Alabama, United States",
  "54216, Kewaunee, Wisconsin, United States",
  "Sterlitamak, Bashkortostan, Russia",
  "200017, Craiova, Dolj, Romania",
  "70422, Amite, Louisiana, United States",
  "141371, Sergiyev Posad, Moscow, Russia",
  "Velika Gorica, Zagreb, Croatia",
  "92331, Klaipėda, Klaipėdos, Lithuania",
  "Arras, British Columbia, Canada",
  "98530, Kemijärvi, Lapland, Finland",
  "Moscow Mills, Missouri, United States",
  "Dundyvan, Coatbridge, Scotland, United Kingdom",
  "542 93, Mariestad, Västra Götaland County, Sweden",
  "452681, Neftekamsk, Bashkortostan, Russia",
  "254 40, Helsingborg, Skåne County, Sweden",
  "Calumet, Missouri, United States",
  "Gloucester, England, United Kingdom",
  "692506, Ussuriysk, Primorye, Russia",
  "Rubtsovsk, Altai Krai, Russia",
  "66100, Yozgat, Yozgat, Türkiye",
  "84066, Roosevelt, Utah, United States",
  "394000, Voronezh, Voronezh, Russia",
  "Genoa City, Wisconsin, United States",
  "V14 V562, Shannon, County Clare, Ireland",
  "14131, Ransomville, New York, United States",
  "40141, Bologna, Emilia-Romagna, Italy",
  "652900, Mundybash, Kemerovo, Russia",
  "22923, Barboursville, Virginia, United States",
  "Yozgat, Yozgat, Türkiye",
  "2307, Hamar, Innlandet, Norway",
  "11390, Algeciras, Andalusia, Spain",
  "La Glane, Fribourg, Switzerland",
  "Cooperativa Numero 14, Miraflores, Arequipa, Peru",
  "456394, Miass, Chelyabinsk, Russia",
  "95100, Catania, Sicily, Italy",
  "Rače, Maribor, Slovenia",
  "Makinsk, Akmola Region, Kazakhstan",
  "5610, Assens, Region of Southern Denmark, Denmark",
  "Hamilton, Montana, United States",
  "33150, Mersin, İçel, Türkiye",
  "Dixon, Montana, United States",
  "75554, Avery, Texas, United States",
  "Lafayette, Indiana, United States",
  "Saint-Georges-d’Orques, Occitanie, France",
  "06447, Marlborough, Connecticut, United States",
  "183001, Murmansk, Murmansk, Russia",
  "Tambovsky District, Tambov, Russia",
  "531035, S Rayavaram, Andhra Pradesh, India",
  "Orel, Orel, Russia",
  "Trousdale County, Tennessee, United States",
  "37400, Kastamonu, Kastamonu, Türkiye",
  "53111, Bonn, North Rhine-Westphalia, Germany",
  "Mooresville, Indiana, United States",
  "0585, Oslo, Oslo, Norway",
  "5603, Békéscsaba, Békés, Hungary",
  "Scarborough, England, United Kingdom",
  "Gradyville, Kentucky, United States",
  "Alleghany, California, United States",
  "CV4 7ES, Coventry, England, United Kingdom",
  "429107, Shumerlya, Chuvashia, Russia",
  "161115, Nikol’skiy Torzhok, Vologda, Russia",
  "Sioux Falls, South Dakota, United States",
  "Preble County, Ohio, United States",
  "141580, Solnechnogorsk, Moscow, Russia",
  "89012, Henderson, Nevada, United States",
  "Oak Creek, Wisconsin, United States",
  "Suşehri, Sivas, Türkiye",
  "M1C 0A1, Scarborough, Ontario, Canada",
  "Dawson, Illinois, United States",
  "33200, Tampere, Pirkanmaa, Finland",
  "CO13 0ED, Kirkby-Le-Soken, England, United Kingdom",
  "9027, Klagenfurt, Carinthia, Austria",
  "03044, Cottbus, Brandenburg, Germany",
  "HP22 5XS, Little Kimble, England, United Kingdom",
  "Cameron, North Carolina, United States",
  "23327, Chesapeake, Virginia, United States",
  "89124, Reggio di Calabria, Calabria, Italy",
  "DY11 7RA, Kidderminster, England, United Kingdom",
  "52040, Dyersville, Iowa, United States",
  "Higher Whatcombe, England, United Kingdom",
  "26723, Emden, Lower Saxony, Germany",
  "46142, Greenwood, Indiana, United States",
  "TS5 4AB, Middlesbrough, England, United Kingdom",
  "Ruskopolianska silska hromada, Cherkasy, Ukraine",
  "74756, Sawyer, Oklahoma, United States",
  "142209, Serpukhov, Moscow, Russia",
  "24116, Kiel, Schleswig-Holstein, Germany",
  "461051, Buzuluk, Orenburg, Russia",
  "EX2 4AA, Exeter, England, United Kingdom",
  "84316, Howell, Utah, United States",
  "47200, Kouvola, Kymenlaakso, Finland",
  "34080, Istanbul, Istanbul, Türkiye",
  "Claiborne County, Tennessee, United States",
  "75849, Crockett, Texas, United States",
  "Brown City, Michigan, United States",
  "GL4 0AZ, Gloucester, England, United Kingdom",
  "88-108, Inowrocław, Kujawsko-pomorskie, Poland",
  "S13H5Y8, Ekibastuz, Pavlodar Region, Kazakhstan",
  "30812, Lorca, Región de Murcia, Spain",
  "La Paz, Bolivia",
  "636842, Asino, Tomsk, Russia",
  "Grevenmacher, Luxembourg",
  "Ulubey, Uşak, Türkiye",
  "430021, Saransk, Mordovia, Russia",
  "DD3 6AQ, Dundee, Scotland, United Kingdom",
  "368221, Buynaksk, Dagestan, Russia",
  "28816, Asheville, North Carolina, United States",
  "Augusta Margaret River, Western Australia, Australia",
  "34293, Venice, Florida, United States",
  "95688, Vacaville, California, United States",
  "2845-232, Seixal, Setúbal, Portugal",
  "44070, North Olmsted, Ohio, United States",
  "07100, Sassari, Sardinia, Italy",
  "90401, Haapsalu, Laane, Estonia",
  "Frederikshåb, Region Zealand, Denmark",
  "Isle Of Harris, Scotland, United Kingdom",
  "21241, Novi Sad, Vojvodina, Serbia",
  "652 25, Karlstad, Värmland County, Sweden",
  "Nyagan', Khanty-Mansi, Russia",
  "NR11 8SF, East Beckham, England, United Kingdom",
  "690068, Vladivostok, Primorye, Russia",
  "9709, Szombathely, Vas, Hungary",
  "Robertsville, Missouri, United States",
  "Korçë, Albania",
  "Žarnovica, Banska Bystrica, Slovakia",
  "Donetsk Raion, Donetsk, Ukraine",
  "630132, Novosibirsk, Novosibirsk, Russia",
  "LU4 0DZ, Luton, England, United Kingdom",
  "Parkville, Maryland, United States",
  "678144, Lensk, Sakha, Russia",
  "F92 X6V6, Letterkenny, County Donegal, Ireland",
  "81100, Caserta, Campania, Italy",
  "Bosques Centro, Bosques, Buenos Aires Province, Argentina",
  "Tuffley, Gloucester, England, United Kingdom",
  "Zəyəm, Qazakh-Tovuz, Azerbaijan",
  "District of Columbia, United States",
  "Kolpashevo, Tomsk, Russia",
  "Fort Gregg Adams, Virginia, United States",
  "659315, Biysk, Altai Krai, Russia",
  "39104, Magdeburg, Saxony-Anhalt, Germany",
  "676285, Tynda, Amur, Russia",
  "38070, Kayseri, Kayseri, Türkiye",
  "Houston County, Georgia, United States",
  "10190, Balıkesir, Balıkesir, Türkiye",
  "Shannon, Manawatū-Whanganui, New Zealand",
  "MD-4578, Oxentea, Dubăsari District, Moldova",
  "350072, Krasnodar, Krasnodar, Russia",
  "Nolangeni, Umzimkhulu, KwaZulu-Natal, South Africa",
  "Savage-Glover, Sumter, South Carolina, United States",
  "Nashik Rural, Nashik, Maharashtra, India",
  "07801, Dover, New Jersey, United States",
  "368222, Buynaksk, Dagestan, Russia",
  "Maçka, Trabzon, Türkiye",
  "Stoke Gifford, England, United Kingdom",
  "C25E3C4, Makinsk, Akmola Region, Kazakhstan",
  "Geneva, Florida, United States",
  "Furnas, São Paulo, São Paulo, Brazil",
  "Tulung, Central Java, Indonesia",
  "Bodaybo, Irkutsk, Russia",
  "Price County, Wisconsin, United States",
  "Deva, Basque Country, Spain",
  "90146, Palermo, Sicily, Italy",
  "357907, Zelenokumsk, Stavropol, Russia",
  "New Tripoli, Pennsylvania, United States",
  "652515, Leninsk-Kuznetskiy, Kemerovo, Russia",
  "1100, Belfast, Mpumalanga, South Africa",
  "Bytom, Śląskie, Poland",
  "Thayer, Indiana, United States",
  "99650, Sodankylä, Lapland, Finland",
  "76008, Ivano-Frankivsk, Ivano-Frankivsk, Ukraine",
  "644074, Omsk, Omsk, Russia",
  "Val-d'Oise, Île-de-France, France",
  "CF44 9YX, Penderyn, Wales, United Kingdom",
  "Vigone, Piedmont, Italy",
  "Berlin, New Hampshire, United States",
  "15500, Burdur, Burdur, Türkiye",
  "Bensonville City, Montserrado County, Liberia",
  "Artyomovskiy, Sverdlovsk, Russia",
  "63069, Pacific, Missouri, United States",
  "Algiers, Algiers, Algeria",
  "Sampson County, North Carolina, United States",
  "30580, Clarkesville, Georgia, United States",
  "Adairville, Kentucky, United States",
  "54400, Sakarya, Sakarya, Türkiye",
  "169711, Usinsk, Komi, Russia",
  "Saint-Georges-lès-Baillargeaux, Nouvelle-Aquitaine, France",
  "252 52, Helsingborg, Skåne County, Sweden",
  "5900, Levski, Pleven, Bulgaria",
  "Divide, Frontier 19, Saskatchewan, Canada",
  "Gates Mills, Ohio, United States",
  "Warren, Oregon, United States",
  "28801, Asheville, North Carolina, United States",
  "Kotel, Sliven, Bulgaria",
  "Shubrā al Khaymah, Cairo, Egypt",
  "Kemper, Ahlen, North Rhine-Westphalia, Germany",
  "Powderville, Montana, United States",
  "DACH",
  "666682, Ust'-Ilimsk, Irkutsk, Russia",
  "New Bern, North Carolina, United States",
  "21117, Owings Mills, Maryland, United States",
  "Richmond, Texas, United States",
  "Vác, Pest, Hungary",
  "Szekszárdi járás, Tolna, Hungary",
  "23350, Elâzığ, Elazığ, Türkiye",
  "Goshen, Massachusetts, United States",
  "Omsukchansky District, Magadan, Russia",
  "Essex, Maryland, United States",
  "94036, Passau, Bavaria, Germany",
  "141335, Sergiyev Posad, Moscow, Russia",
  "NE1 2JG, Newcastle Upon Tyne, England, United Kingdom",
  "Camden, Alabama, United States",
  "Jefferson County, Missouri, United States",
  "54964, Pickett, Wisconsin, United States",
  "56700, Siirt, Siirt, Türkiye",
  "Smythesdale, Victoria, Australia",
  "Douglas County, Kansas, United States",
  "Nessebar, Burgas, Bulgaria",
  "La Joséphine, Velaux, Provence-Alpes-Côte d'Azur, France",
  "Iron River, Michigan, United States",
  "Kuşadası, Aydın, Türkiye",
  "La Gare, Barneville-Carteret, Normandy, France",
  "1040, Vienna, Vienna, Austria",
  "Solai ward, Nakuru, Kenya",
  "61030, Trabzon, Trabzon, Türkiye",
  "Nelson, New Hampshire, United States",
  "17500, Çanakkale, Çanakkale, Türkiye",
  "33210, Tampere, Pirkanmaa, Finland",
  "Haapsalu, Laane, Estonia",
  "Palmares, Alajuela, Costa Rica",
  "14580, Webster, New York, United States",
  "46528, Goshen, Indiana, United States",
  "658842, Slavgorod, Altai Krai, Russia",
  "Greater Preston Area",
  "Egerkingen, Solothurn, Switzerland",
  "97229, Portland, Oregon, United States",
  "G64 4AH, Torrance, Scotland, United Kingdom",
  "400006, Cluj-Napoca, Cluj, Romania",
  "Gloucester, New Brunswick, Canada",
  "51004, Onegama, North Central Province, Sri Lanka",
  "Parker, Arizona, United States",
  "San Leandro, California, United States",
  "8800, Viborg, Central Denmark Region, Denmark",
  "42113, Wuppertal, North Rhine-Westphalia, Germany",
  "Union County, North Carolina, United States",
  "9210, Aalborg, North Denmark Region, Denmark",
  "02909, Providence, Rhode Island, United States",
  "211412, Polatsk, Vitsyebsk, Belarus",
  "Teton Bari, Helem, Assam, India",
  "PE3 6AB, Peterborough, England, United Kingdom",
  "Comuna 3 - Sur Oriental, Norte de Santander, Colombia",
  "352923, Armavir, Krasnodar, Russia",
  "Greater Malatya",
  "Harrison County, Missouri, United States",
  "Summerstown, Ontario, Canada",
  "33767, Clearwater, Florida, United States",
  "Dmytrushky, Cherkasy, Ukraine",
  "23242, Zrenjanin, Vojvodina, Serbia",
  "SO43 7GA, Emery Down, England, United Kingdom",
  "H34F2H4, Qulan, Jambyl Region, Kazakhstan",
  "Franklin County, Vermont, United States",
  "65100, Pescara, Abruzzi, Italy",
  "DY4 0UA, Tipton, England, United Kingdom",
  "Cimarron, Broomfield, Colorado, United States",
  "78144, Karnes City, Texas, United States",
  "Moss Beach, California, United States",
  "424036, Yoshkar-Ola, Mari El, Russia",
  "Slobodskoy sel'skiy sovet, Homel, Belarus",
  "Sokehs, Pohnpei, Federated States of Micronesia",
  "Edgefield, South Carolina, United States",
  "74401, Muskogee, Oklahoma, United States",
  "Limoges, Nouvelle-Aquitaine, France",
  "59370, Mons-en-Barœul, Hauts-de-France, France",
  "23552, Lübeck, Schleswig-Holstein, Germany",
  "Bradley County, Tennessee, United States",
  "Stone, England, United Kingdom",
  "DT1 1AA, Dorchester, England, United Kingdom",
  "Beersel, Flemish Region, Belgium",
  "641080, Borovichi, Kurgan, Russia",
  "07320, Antalya, Antalya, Türkiye",
  "Dietikon, Zurich, Switzerland",
  "Sainte-Marie, Sainte-Marie, Reunion",
  "446023, Syzran, Samara, Russia",
  "61700, Trabzon, Trabzon, Türkiye",
  "37460, Le Liège, Centre-Val de Loire, France",
  "Barrytown, New York, United States",
  "Wilkinson, Indiana, United States",
  "Beltrami, Minnesota, United States",
  "Pepín, Cazás (San Xulián), Galicia, Spain",
  "Kirov, Kirov, Russia",
  "5247 HA, ’s-Hertogenbosch, North Brabant, Netherlands",
  "623370, Polevskoy, Sverdlovsk, Russia",
  "Fayette, Alabama, United States",
  "Louisa Pointe, Florida, United States",
  "80325, Toboloto, Sinaloa, Mexico",
  "309951, Valuyki, Belgorod, Russia",
  "Çerkeş, Çankırı, Türkiye",
  "3950, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Cide, Kastamonu, Türkiye",
  "Marion, Ohio, United States",
  "644003, Omsk, Omsk, Russia",
  "353880, Svobodnyy, Krasnodar, Russia",
  "Lynchburg City County, Virginia, United States",
  "23139, Powhatan, Virginia, United States",
  "Foster, Pennsylvania, United States",
  "32000, Sitiawan, Perak, Malaysia",
  "97035, Lake Oswego, Oregon, United States",
  "Pereyaslavka, Khabarovsk, Russia",
  "29007, Málaga, Andalusia, Spain",
  "Humphreys, Missouri, United States",
  "Greater Dublin",
  "633522, Cherepanovo, Novosibirsk, Russia",
  "Kimball, South Dakota, United States",
  "Boyd Acres, Bend, Oregon, United States",
  "412315, Balashov, Saratov, Russia",
  "Greater Albany, Georgia Area",
  "692508, Ussuriysk, Primorye, Russia",
  "Medgidia, Constanţa, Romania",
  "Jackson, Mississippi, United States",
  "4650, Grahams Creek, Queensland, Australia",
  "Cascade, Maryland, United States",
  "Hardy County, West Virginia, United States",
  "Chupulún, Puerto Cabello, Carabobo State, Venezuela",
  "305021, Kursk, Kursk, Russia",
  "Bottineau, Minneapolis, Minnesota, United States",
  "22554, Stafford, Virginia, United States",
  "Corumbá, Mato Grosso do Sul, Brazil",
  "Adams, Oregon, United States",
  "SA3 4RD, Caswell, Wales, United Kingdom",
  "56517, Beltrami, Minnesota, United States",
  "Goochland County, Virginia, United States",
  "Pevek, Chukot, Russia",
  "2400, Le Locle, Neuchâtel, Switzerland",
  "Shelby County, Missouri, United States",
  "Chowan County, North Carolina, United States",
  "81-359, Gdynia, Pomorskie, Poland",
  "L10G0Y4, Chapaev, West Kazakhstan Region, Kazakhstan",
  "27300, Mala Vyska, Kirovohrad, Ukraine",
  "Rockingham County, North Carolina, United States",
  "Lewiston, New York, United States",
  "Goochland, Virginia, United States",
  "48061, Port Huron, Michigan, United States",
  "84306, Kramatorsk, Donetsk, Ukraine",
  "NN15 5WP, Burton Latimer, England, United Kingdom",
  "Plastun, Primorye, Russia",
  "59510, Tekirdağ, Tekirdağ, Türkiye",
  "192211, Dooru, Jammu & Kashmir, India",
  "Dogubayazit, Ağrı, Türkiye",
  "Yuzhno-Yeniseysk, Krasnoyarsk Krai, Russia",
  "Kemi, Lapland, Finland",
  "NG5 9DY, Nottingham, England, United Kingdom",
  "BT28 2EX, Lisburn, Northern Ireland, United Kingdom",
  "Osijek, Osijek-Baranja, Croatia",
  "Amite County, Mississippi, United States",
  "F91 FK88, Sligo, County Sligo, Ireland",
  "Kyustendil, Bulgaria",
  "Wilson, North Carolina, United States",
  "Sioux Center, Iowa, United States",
  "Balaklava, Sevastopol, Ukraine",
  "10th & Page, Charlottesville, Virginia, United States",
  "Vilyuysky District, Sakha, Russia",
  "160004, Vologda, Vologda, Russia",
  "Sintra, Lisbon, Portugal",
  "Graham, Washington, United States",
  "North Slope Borough County, Alaska, United States",
  "5400-631, Chaves, Vila Real, Portugal",
  "HX3 0AB, Halifax, England, United Kingdom",
  "1211, Ljubljana, Ljubljana, Slovenia",
  "Hamar Weyne District, Banaadir, Somalia",
  "Cumberland, Wisconsin, United States",
  "Leavenworth, Washington, United States",
  "36456, McKenzie, Alabama, United States",
  "Lane County, Kansas, United States",
  "Ransom, Illinois, United States",
  "Harrisonburg-Staunton-Waynesboro Area",
  "182111, Velikiye Luki, Pskov, Russia",
  "Loudon, Queenstown, Eastern Cape, South Africa",
  "Turner, Montana, United States",
  "9430, Aalborg, North Denmark Region, Denmark",
  "04000, Agri, Ağrı, Türkiye",
  "Vyzhnytsia Raion, Chernivtsi, Ukraine",
  "Clarendon, North Carolina, United States",
  "Storey, Rockwood, Manitoba, Canada",
  "13005, Ciudad Real, Castile-La Mancha, Spain",
  "Gorodskoy Okrug Velikiye Luki, Pskov, Russia",
  "500200, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "Tokat, Tokat, Türkiye",
  "11510, Puerto Real, Andalusia, Spain",
  "910161, Călăraşi, Călăraşi, Romania",
  "Borgo Maggiore, San Marino",
  "243013, Novozybkov, Bryansk, Russia",
  "Rockland, New Brunswick, Canada",
  "30133, Venice, Veneto, Italy",
  "95624, Elk Grove, California, United States",
  "64720, Monterrey, Nuevo León, Mexico",
  "2754, The Slopes, New South Wales, Australia",
  "Alexandria, Alexandria, Egypt",
  "455049, Magnitogorsk, Chelyabinsk, Russia",
  "74868, Seminole, Oklahoma, United States",
  "Zug, Zug, Switzerland",
  "Alaska, United States",
  "Ugol’naya Zyryanka, Sakha, Russia",
  "07008, Palma, Balearic Islands, Spain",
  "La Joséphine, Luçon, Pays de la Loire, France",
  "M1T 0A1, Scarborough, Ontario, Canada",
  "Portalegre, Rio Grande do Norte, Brazil",
  "Midland, North Carolina, United States",
  "Livingston Parish County, Louisiana, United States",
  "Treasure Cove, New Providence, The Bahamas",
  "Aurora, Oregon, United States",
  "3061 AA, Rotterdam, South Holland, Netherlands",
  "24333, Galax, Virginia, United States",
  "Pikeville, Tennessee, United States",
  "247780, Mazyr, Homel, Belarus",
  "18216, Aleksinac, Centralna Srbija, Serbia",
  "Sweet Bay, Newfoundland and Labrador, Canada",
  "Colonial Beach, Virginia, United States",
  "305048, Kursk, Kursk, Russia",
  "Sumy, Ukraine",
  "821 08, Bratislava, Bratislava, Slovakia",
  "Champaigné, Notre-Dame-d’Oé, Centre-Val de Loire, France",
  "Hayes, South Dakota, United States",
  "12157, Schoharie, New York, United States",
  "98045, San Pier Niceto, Sicily, Italy",
  "Nishio, Aichi, Japan",
  "Sunderland Bridge, England, United Kingdom",
  "Hutchinson Place, South Carolina, United States",
  "Banská Štiavnica, Banska Bystrica, Slovakia",
  "37378, McMinnville, Tennessee, United States",
  "Fort Winnebago, Wisconsin, United States",
  "71078, Stonewall, Louisiana, United States",
  "09320, Vilnius, Vilniaus, Lithuania",
  "186222, Kondopoga, Karelia, Russia",
  "Burt, Iowa, United States",
  "14215, Buffalo, New York, United States",
  "Scioto Trace, Columbus, Ohio, United States",
  "40740, Jyväskylä, Central Finland, Finland",
  "09001, Burgos, Castilla and Leon, Spain",
  "Villa Angelina, San Miguel de Tucumán, Tucumán, Argentina",
  "Sumner, Missouri, United States",
  "13090, Liverpool, New York, United States",
  "B0E 2W0, Port Hood, Nova Scotia, Canada",
  "666926, Artëmovskiy, Irkutsk, Russia",
  "Edinburgh, Scotland, United Kingdom",
  "Kaniv, Cherkasy, Ukraine",
  "8939 DM, Leeuwarden, Friesland, Netherlands",
  "Departamento Punilla, Córdoba, Argentina",
  "Laclede County, Missouri, United States",
  "Sumner, Iowa, United States",
  "Wetzelsdorf, Graz, Styria, Austria",
  "620160, Focşani, Vrancea, Romania",
  "Terrytown, Louisiana, United States",
  "MD-4401, Tuzara, Calarasi, Moldova",
  "153000, Ivanovo, Ivanovo, Russia",
  "B55C3D0, Zharkent, Jetisu Region, Kazakhstan",
  "34035, Istanbul, Istanbul, Türkiye",
  "37694, Watauga, Tennessee, United States",
  "Galax, Emalahleni, Eastern Cape, South Africa",
  "965 01, Žiar nad Hronom, Banska Bystrica, Slovakia",
  "420087, Kazan, Tatarstan, Russia",
  "641435, Kurtamysh, Kurgan, Russia",
  "673005, Petrovsk-Zabaykal’skiy, Zabaykalsky Krai, Russia",
  "La Broye, Fribourg, Switzerland",
  "Hunter's Quay, Dunoon, Scotland, United Kingdom",
  "Providence, Virginia, United States",
  "Slobozia, Ialomiţa, Romania",
  "00133, Rome, Latium, Italy",
  "130077, Târgovişte, Dîmboviţa, Romania",
  "49128, Dnipro, Dnipropetrovsk, Ukraine",
  "Krasnoarmeysk, Donetsk, Ukraine",
  "02328, Albacete, Castile-La Mancha, Spain",
  "Novosil’, Orel, Russia",
  "48900, Autlán de Navarro, Jalisco, Mexico",
  "Blagodarnyy, Stavropol, Russia",
  "Iráklion, Crete, Greece",
  "Greater Catanzaro Metropolitan Area",
  "El Bosque, Andalusia, Spain",
  "7017, Korçë, Korçë, Albania",
  "40906, Barbourville, Kentucky, United States",
  "Krasnoarmeyskoye, Mordovia, Russia",
  "Alekseyevka, Krasnoyarsk Krai, Russia",
  "Srednekolymsk, Sakha, Russia",
  "Denton, Maryland, United States",
  "Dzerzhinskogo, Leningrad, Russia",
  "744101, Port Blair, Andaman and Nicobar Islands, India",
  "12908-751, Bragança Paulista, São Paulo, Brazil",
  "108840, Troitsk, Moscow, Russia",
  "Newton, New Jersey, United States",
  "Christiansbjerg, Aarhus, Central Denmark Region, Denmark",
  "605200, Comăneşti, Bacău, Romania",
  "54482, Stevens Point, Wisconsin, United States",
  "E25C4D2, Maqat, Atyrau Region, Kazakhstan",
  "Trippeenagh, County Kerry, Ireland",
  "Amherst, Quebec, Canada",
  "Wetzels Mühle, Harztor, Thuringia, Germany",
  "Prokopyevsk, Kemerovo, Russia",
  "Crawford, Nebraska, United States",
  "3154, Tønsberg, Vestfold og Telemark, Norway",
  "Greater Hamilton Area",
  "06010, Badajoz, Extremadura, Spain",
  "Wright County, Missouri, United States",
  "3936, Arthurs Seat, Victoria, Australia",
  "452455, Birsk, Bashkortostan, Russia",
  "Columbia County, Wisconsin, United States",
  "400002, Cluj-Napoca, Cluj, Romania",
  "Carlisle, Kentucky, United States",
  "Greater Linköping Metropolitan Area",
  "Shackelford County Courthouse Historic District, Albany, Texas, United States",
  "Orem, Utah, United States",
  "6512, Bellinzona, Ticino, Switzerland",
  "344000, Rostov, Rostov, Russia",
  "Dewey, Wisconsin, United States",
  "Pépin, Dampierre-sous-Bouhy, Bourgogne-Franche-Comté, France",
  "53185, Waterford, Wisconsin, United States",
  "M52C7K8, Osakarovka, Karaganda Region, Kazakhstan",
  "06786, Terryville, Connecticut, United States",
  "Fulton County, Ohio, United States",
  "Sebastopol, Sevastopol, Ukraine",
  "Greenhill, Kidderminster, England, United Kingdom",
  "Saunders, Virginia, United States",
  "Grundy County, Missouri, United States",
  "Brule de Daniel, Lévignac-de-Guyenne, Nouvelle-Aquitaine, France",
  "230005, Hrodna, Hrodna, Belarus",
  "220004, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "169934, Vorkuta, Komi, Russia",
  "Isatay District, Atyrau Region, Kazakhstan",
  "347810, Kamensk-Shakhtinskiy, Rostov, Russia",
  "29649, Greenwood, South Carolina, United States",
  "South Burlington, Vermont, United States",
  "62000, Tunceli, Tunceli, Türkiye",
  "Fort Hood, Texas, United States",
  "R0G 0W0, Haywood, Manitoba, Canada",
  "93536, Lancaster, California, United States",
  "582 26, Linköping, Östergötland County, Sweden",
  "Clark County, Arkansas, United States",
  "620095, Focşani, Vrancea, Romania",
  "Big Rapids, Michigan, United States",
  "Trinity, Scotland, United Kingdom",
  "Dyat’kovo, Bryansk, Russia",
  "Eleja, Jelgava Municipality, Latvia",
  "Sarapulsky District, Udmurtia, Russia",
  "38344, Huntingdon, Tennessee, United States",
  "69009, Lyon, Auvergne-Rhône-Alpes, France",
  "33800, Bordeaux, Nouvelle-Aquitaine, France",
  "33100, Udine, Friuli-Venezia Giulia, Italy",
  "Shelby County, Tennessee, United States",
  "Salem, Missouri, United States",
  "Amherst, New Hampshire, United States",
  "Kidderminster, England, United Kingdom",
  "69120, Heidelberg, Baden-Württemberg, Germany",
  "Grimès, Lias, Occitanie, France",
  "Goshen, Utah, United States",
  "Nicollet Island-East Bank, Minneapolis, Minnesota, United States",
  "02100, Tarazona de la Mancha, Castile-La Mancha, Spain",
  "14100, Asti, Piedmont, Italy",
  "Bonner County, Idaho, United States",
  "Red Lion, Pennsylvania, United States",
  "Pisacoma, Puno, Peru",
  "Pope, Mississippi, United States",
  "Rayagada, Odisha, India",
  "Clermont County, Ohio, United States",
  "US Virgin Islands",
  "182106, Velikiye Luki, Pskov, Russia",
  "Roslavl’, Smolensk, Russia",
  "Beckery, Glastonbury, England, United Kingdom",
  "01010, Vitoria-Gasteiz, Basque Country, Spain",
  "Divide, New Brunswick, Canada",
  "694454, Nogliki, Sakhalin, Russia",
  "Haskell County, Oklahoma, United States",
  "Philadelphia, Pennsylvania, United States",
  "Daniëlskuil, Northern Cape, South Africa",
  "584 31, Linköping, Östergötland County, Sweden",
  "78552, Sinop, Mato Grosso, Brazil",
  "Handsacre, England, United Kingdom",
  "Starksboro, Vermont, United States",
  "31300, Toulouse, Occitanie, France",
  "New Cambria, Kansas, United States",
  "White Hall, Arkansas, United States",
  "Mahoning Heights, Youngstown, Ohio, United States",
  "M22 1TQ, Wythenshawe, England, United Kingdom",
  "Rutland, South Dakota, United States",
  "Parque Duval de Barros, Ibirité, Minas Gerais, Brazil",
  "B25A1E1, Esik, Almaty Region, Kazakhstan",
  "Lisbon, Maine, United States",
  "Galveston, Chandler, Arizona, United States",
  "Shirahama, Chiba, Japan",
  "8899, Tvarditsa, Sliven, Bulgaria",
  "666901, Bodaybo, Irkutsk, Russia",
  "S0G 0G9, Strasbourg Station, Saskatchewan, Canada",
  "Nottingham, Maryland, United States",
  "Sangar, Sakha, Russia",
  "Wells County, Indiana, United States",
  "Rosières-près-Troyes, Grand Est, France",
  "11415, Tallinn, Harjumaa, Estonia",
  "9804, Vadsø, Troms og Finnmark, Norway",
  "4838, Arendal, Agder, Norway",
  "617470, Kungur, Perm, Russia",
  "Essex, Ontario, Canada",
  "Ochiltree, Scotland, United Kingdom",
  "PH13 9NY, Burrelton, Scotland, United Kingdom",
  "429101, Shumerlya, Chuvashia, Russia",
  "Braşov, Romania",
  "Ravenna, Ontario, Canada",
  "80100, Abbeville, Hauts-de-France, France",
  "McDonald, Pennsylvania, United States",
  "Shirati, Mara Region, Tanzania",
  "452755, Tuymazy, Bashkortostan, Russia",
  "610126, Piatra Neamţ, Neamţ, Romania",
  "169908, Vorkuta, Komi, Russia",
  "Wilkinson County, Mississippi, United States",
  "Voronezh Metropolitan Area",
  "Queensburgh, KwaZulu-Natal, South Africa",
  "00520, Helsinki, Uusimaa, Finland",
  "Woodward, Iowa, United States",
  "59421, Cascade, Montana, United States",
  "3119, Te Puke, Bay of Plenty, New Zealand",
  "Lavacant, Pavie, Occitanie, France",
  "Goodhue, Preeceville 334, Saskatchewan, Canada",
  "Brownsville, Tennessee, United States",
  "Hyderabad District, Sindh, Pakistan",
  "09130, Chemnitz, Saxony, Germany",
  "Brighton, Illinois, United States",
  "Bilisht, Korçë, Albania",
  "Lucas, Victoria, Australia",
  "8500-708, Portimão, Faro, Portugal",
  "30016, Covington, Georgia, United States",
  "76550, Lampasas, Texas, United States",
  "McLean County, North Dakota, United States",
  "184250, Kirovsk, Murmansk, Russia",
  "B55C4E6, Zharkent, Jetisu Region, Kazakhstan",
  "CB10 2LF, Sewards End, England, United Kingdom",
  "Petrovsk-Zabaykal’skiy, Zabaykalsky Krai, Russia",
  "6200-340, Covilhã, Castelo Branco, Portugal",
  "Hamblen, Indiana, United States",
  "Killynan (Cooke), County Westmeath, Ireland",
  "08340, Vilassar de Mar, Catalonia, Spain",
  "Loures, Lisbon, Portugal",
  "Danville, Alabama, United States",
  "Greater Grenoble Metropolitan Area",
  "09122, Chemnitz, Saxony, Germany",
  "48060, Port Huron, Michigan, United States",
  "Gillespie Place, Sarasota, Florida, United States",
  "76158, Siauliai, Siauliu, Lithuania",
  "32002, Ourense, Galicia, Spain",
  "Szczecinek, Zachodniopomorskie, Poland",
  "55300, Samsun, Samsun, Türkiye",
  "56029, Emmons, Minnesota, United States",
  "06880, Porvoo, Uusimaa, Finland",
  "Bennington, New York, United States",
  "23231, Henrico, Virginia, United States",
  "Troitskoye, Odessa, Ukraine",
  "Cherrybrook, New South Wales, Australia",
  "20-064, Lublin, Lubelskie, Poland",
  "Chistopolyanskoye, Voronezh, Russia",
  "Strelka, Krasnoyarsk Krai, Russia",
  "Guadalupe, California, United States",
  "86326, Cottonwood, Arizona, United States",
  "LV-1079, Riga, Riga, Latvia",
  "369012, Cherkessk, Karachay-Cherkessia, Russia",
  "Voznesenskiy, Kurgan, Russia",
  "Washburn, Illinois, United States",
  "663605, Kansk, Krasnoyarsk Krai, Russia",
  "346506, Shakhty, Rostov, Russia",
  "8000, Burgas, Burgas, Bulgaria",
  "144, Potsdam, Brandenburg, Germany",
  "72000, Heróica Puebla de Zaragoza, Puebla, Mexico",
  "Gönen, Balıkesir, Türkiye",
  "77614, Deweyville, Texas, United States",
  "90403, Haapsalu, Laane, Estonia",
  "9020-614, Funchal, Madeira Island, Portugal",
  "Beckermet, England, United Kingdom",
  "Wasco, Oregon, United States",
  "49316, Caledonia, Michigan, United States",
  "624482, Severoural’sk, Sverdlovsk, Russia",
  "4623, Kristiansand, Agder, Norway",
  "Lower Meaghers Grant, Halifax, Nova Scotia, Canada",
  "Cernier, Neuchâtel, Switzerland",
  "309187, Gubkin, Belgorod, Russia",
  "36207, Vigo, Galicia, Spain",
  "Columbus, Texas, United States",
  "Shëngjergj, Tirana, Albania",
  "220036, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "94014, Nicosia, Sicily, Italy",
  "Fayetteville, North Carolina Metropolitan Area",
  "Joya de los Sachas Canton, Orellana, Ecuador",
  "Le Gosier, Pointe-à-Pitre, Guadeloupe",
  "Batagay-Alyta, Sakha, Russia",
  "Coffee Springs, Alabama, United States",
  "91617, Trousdale Estates, California, United States",
  "66119, Saarbrücken, Saarland, Germany",
  "Nassau, Offenberg, Bavaria, Germany",
  "Wadena, Minnesota, United States",
  "320208, Reşiţa, Caraş-Severin, Romania",
  "48314, Sterling Heights, Michigan, United States",
  "Muğla, Muğla, Türkiye",
  "02906, Providence, Rhode Island, United States",
  "Tulantepec de Lugo Guerrero, Hidalgo, Mexico",
  "618205, Chusovoy, Perm, Russia",
  "Gemeente Ridderkerk, South Holland, Netherlands",
  "Lille Esbjerg, Region of Southern Denmark, Denmark",
  "Wheelerstreet, Godalming, England, United Kingdom",
  "446305, Otradnyy, Samara, Russia",
  "54729, Chippewa Falls, Wisconsin, United States",
  "347900, Taganrog, Rostov, Russia",
  "Cumberland County, Illinois, United States",
  "Tatenice, Pardubice, Czechia",
  "5002, Namur, Walloon Region, Belgium",
  "Castlecomer, County Kilkenny, Ireland",
  "77008, Houston, Texas, United States",
  "Greater Koblenz Area",
  "Roma Tuscolano, Rome, Latium, Italy",
  "Box Springs, Georgia, United States",
  "Rockbridge Crossing, Georgia, United States",
  "S0E 0N0, Chelan, Saskatchewan, Canada",
  "Millerstown, Pennsylvania, United States",
  "052 01, Spišská Nová Ves, Kosice, Slovakia",
  "185007, Petrozavodsk, Karelia, Russia",
  "2319, Hamar, Innlandet, Norway",
  "Guayas, Ecuador",
  "33765, Clearwater, Florida, United States",
  "9395, Finnsnes, Troms og Finnmark, Norway",
  "8405, Daniëlskuil, Northern Cape, South Africa",
  "Ipswich, Queensland, Australia",
  "20-001, Lublin, Lubelskie, Poland",
  "Mostardas, Rio Grande do Sul, Brazil",
  "Slope County, North Dakota, United States",
  "731147, Bârlad, Vaslui, Romania",
  "Haisynska miska hromada, Vinnytsya, Ukraine",
  "Hudsonville, Michigan, United States",
  "8003, Bodø, Nordland, Norway",
  "Linares, Nuevo León, Mexico",
  "La Corniche, Barneville-Carteret, Normandy, France",
  "Brownsville, Pennsylvania, United States",
  "Defiance, Sint Maarten",
  "Győri járás, Győr-Moson-Sopron, Hungary",
  "623956, Tavda, Sverdlovsk, Russia",
  "Christiansfeld, Region of Southern Denmark, Denmark",
  "62800, Tunceli, Tunceli, Türkiye",
  "65121, Pescara, Abruzzi, Italy",
  "Penzance, Saskatchewan, Canada",
  "Trenton, Georgia, United States",
  "7451, Kaposvár, Somogy, Hungary",
  "Fairfield County, Connecticut, United States",
  "Webers, Três Passos, Rio Grande do Sul, Brazil",
  "Patrang, East Java, Indonesia",
  "3120, Tønsberg, Vestfold og Telemark, Norway",
  "44280, Kaunas, Kauno, Lithuania",
  "Hopewell City County, Virginia, United States",
  "N0B 2M1, Heidelberg, Ontario, Canada",
  "692033, Lesozavodsk, Primorye, Russia",
  "61311, Ancona, Illinois, United States",
  "Frontier, Missouri, United States",
  "84770, St George, Utah, United States",
  "346448, Novocherkassk, Rostov, Russia",
  "309185, Gubkin, Belgorod, Russia",
  "Fort Myers, Florida, United States",
  "SG14 1AB, Hertford, England, United Kingdom",
  "172522, Nelidovo, Tver’, Russia",
  "Oktyabr'skiy, Krasnodar, Russia",
  "831 06, Bratislava, Bratislava, Slovakia",
  "386106, Nazran, Ingushetia, Russia",
  "33712, St Petersburg, Florida, United States",
  "Hamilton, Michigan, United States",
  "77477, Stafford, Texas, United States",
  "Saint-Évariste-de-Forsyth, Quebec, Canada",
  "01069, Dresden, Saxony, Germany",
  "CF10 4QQ, Cardiff, Wales, United Kingdom",
  "33006, Oviedo, Principality of Asturias, Spain",
  "16340, Bursa, Bursa, Türkiye",
  "Quitman County, Georgia, United States",
  "Savonlinna sub-region, South Savo, Finland",
  "Douglas County, Colorado, United States",
  "Henderson, Colorado, United States",
  "03001, Vilnius, Vilniaus, Lithuania",
  "Woodstock, England, United Kingdom",
  "66293, Tulungagung, East Java, Indonesia",
  "BT79 0HH, Omagh, Northern Ireland, United Kingdom",
  "40-003, Katowice, Śląskie, Poland",
  "Bolivar, South Australia, Australia",
  "4051, Basel, Basel, Switzerland",
  "420101, Kazan, Tatarstan, Russia",
  "NR25 6PL, West Beckham, England, United Kingdom",
  "Schweringen, Lower Saxony, Germany",
  "Wollerau, Schwyz, Switzerland",
  "453203, Ishimbay, Bashkortostan, Russia",
  "678175, Mirnyy, Sakha, Russia",
  "SA5 4AA, Swansea, Wales, United Kingdom",
  "Pembina, Edmonton, Alberta, Canada",
  "E1A 1M1, Dieppe, New Brunswick, Canada",
  "Isperih, Razgrad, Bulgaria",
  "Henderson County, North Carolina, United States",
  "Providence, North Carolina, United States",
  "Granite Springs, New York, United States",
  "Yerevan, Armenia",
  "A91 TW71, Dundalk, County Louth, Ireland",
  "50700, Mikkeli, South Savo, Finland",
  "Le Pécos, Ladevèze-Ville, Occitanie, France",
  "23294, Henrico, Virginia, United States",
  "Bahagian Samarahan, Sarawak, Malaysia",
  "Granville, Illinois, United States",
  "20300, Turku, Southwest Finland, Finland",
  "Penne-d’Agenais, Nouvelle-Aquitaine, France",
  "Kotel'nikovskoye, Volgograd, Russia",
  "391798, Gornyak, Ryazan’, Russia",
  "9700-069, Angra do Heroísmo, Portugal",
  "50130, Cherbourg, Normandy, France",
  "27616, Raleigh, North Carolina, United States",
  "C19C3K3, Zhaltyr, Akmola Region, Kazakhstan",
  "Coos, Audaux, Nouvelle-Aquitaine, France",
  "Kyakhta, Buryatia, Russia",
  "66650, Yozgat, Yozgat, Türkiye",
  "676243, Zeya, Amur, Russia",
  "1113, Sofia, Sofia City, Bulgaria",
  "24473, Rockbridge Baths, Virginia, United States",
  "Severo-Kurilsky District, Sakhalin, Russia",
  "Oryol Metropolitan Area",
  "Carver, Waco, Texas, United States",
  "10522, Dobbs Ferry, New York, United States",
  "13141, Preble, New York, United States",
  "Southern Shores, Umatilla, Florida, United States",
  "665651, Zheleznogorsk-Ilimskiy, Irkutsk, Russia",
  "Matagorda, Peravia, Dominican Republic",
  "88862-000, Treviso, Santa Catarina, Brazil",
  "Vladimirovka, Mykolayiv, Ukraine",
  "07039, Livingston, New Jersey, United States",
  "73444, Hennepin, Oklahoma, United States",
  "Şarbaqtı, Pavlodar Region, Kazakhstan",
  "Fort Loudon, Pennsylvania, United States",
  "29512, Bennettsville, South Carolina, United States",
  "601916, Kovrov, Vladimir, Russia",
  "Greater Guadalupe Area",
  "SR2 0DG, Sunderland, England, United Kingdom",
  "Shirako, Chiba, Japan",
  "185001, Petrozavodsk, Karelia, Russia",
  "68199, Mannheim, Baden-Württemberg, Germany",
  "Astrakhan Metropolitan Area",
  "White County, Arkansas, United States",
  "1752, Villars-sur-Glâne, Fribourg, Switzerland",
  "Wardenburg, Lower Saxony, Germany",
  "10469, Bronx, New York, United States",
  "273 25, Zvoleněves, Central Bohemia, Czechia",
  "Qarataw district, Shymkent, Kazakhstan",
  "Campbellfield, Victoria, Australia",
  "Zonguldak, Türkiye",
  "54530, Hawkins, Wisconsin, United States",
  "77374, Saratoga, Texas, United States",
  "Geri, Nicosia, Cyprus",
  "Jonesborough, Tennessee, United States",
  "6331, Hünenberg, Zug, Switzerland",
  "County Kerry, Ireland",
  "19128, Philadelphia, Pennsylvania, United States",
  "IP4 5PD, Ipswich, England, United Kingdom",
  "Solnechnogorsk, Moscow, Russia",
  "Redding-Red Bluff Area",
  "Greater Norwich Area, United Kingdom",
  "Sutton In Ashfield, England, United Kingdom",
  "674684, Nerchinskiy Zavod, Zabaykalsky Krai, Russia",
  "Staunton Harold, England, United Kingdom",
  "423812, Naberezhnyye Chelny, Tatarstan, Russia",
  "Västerås kommun, Västmanland County, Sweden",
  "Bucak, Burdur, Türkiye",
  "Barbour County, Alabama, United States",
  "700671, Iaşi, Iaşi, Romania",
  "Intan Jaya, Papua, Indonesia",
  "Shunan, Yamaguchi, Japan",
  "Presidio, Plan de Libres, Veracruz, Mexico",
  "456584, Yemanzhelinsk, Chelyabinsk, Russia",
  "38120, Santa Cruz de Tenerife, Canary Islands, Spain",
  "La Rampinnière, Grimesnil, Normandy, France",
  "456204, Zlatoust, Chelyabinsk, Russia",
  "Brives-sur-Charente, Nouvelle-Aquitaine, France",
  "Roosevelt-NDSU, Fargo, North Dakota, United States",
  "Arakonak, Bingöl, Türkiye",
  "Eau Claire County, Wisconsin, United States",
  "2017, Durrës, Durres, Albania",
  "23325, Chesapeake, Virginia, United States",
  "84100, Salerno, Campania, Italy",
  "Ozersk, Chelyabinsk, Russia",
  "5770, Lukovit, Lovech, Bulgaria",
  "33325, Fort Lauderdale, Florida, United States",
  "70435, Covington, Louisiana, United States",
  "Lickingville, Pennsylvania, United States",
  "44265, Dortmund, North Rhine-Westphalia, Germany",
  "28697, Wilkesboro, North Carolina, United States",
  "Krasnoturyinsk, Sverdlovsk, Russia",
  "1034, Tirana, Tirana, Albania",
  "44137, Dortmund, North Rhine-Westphalia, Germany",
  "56215, Benson, Minnesota, United States",
  "57348, Hitchcock, South Dakota, United States",
  "CF5 1AA, Cardiff, Wales, United Kingdom",
  "8200, Schaffhausen, Schaffhausen, Switzerland",
  "Ashland County, Wisconsin, United States",
  "Ravenna, Minnesota, United States",
  "Donegal, Pennsylvania, United States",
  "Martinsville City County, Virginia, United States",
  "352387, Kropotkin, Krasnodar, Russia",
  "85142, Queen Creek, Arizona, United States",
  "Gourbeyre, Basse-Terre, Guadeloupe",
  "4402, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "59107, Billings, Montana, United States",
  "89480-000, Major Vieira, Santa Catarina, Brazil",
  "Voznesenskoye, Nizhniy Novgorod, Russia",
  "Mossel Bay, Western Cape, South Africa",
  "60350, Saint-Etienne-Roilaye, Hauts-de-France, France",
  "15200, Lahti, Päijät-Häme, Finland",
  "Hancock, Iowa, United States",
  "Fort Lee, New Jersey, United States",
  "8630, Mo i Rana, Nordland, Norway",
  "662062, Bogotol, Krasnoyarsk Krai, Russia",
  "Governador Archer, Maranhão, Brazil",
  "663184, Yeniseysk, Krasnoyarsk Krai, Russia",
  "Clackamas, Oregon, United States",
  "League City, Texas, United States",
  "Garkalne, Riga, Latvia",
  "Gregory, Muskoka Falls, Ontario, Canada",
  "Blountstown, Florida, United States",
  "Lynchburg, Tennessee, United States",
  "Conchos, Chihuahua, Mexico",
  "Albany, Georgia, United States",
  "Kragujevac Metropolitan Area",
  "39117, Morton, Mississippi, United States",
  "Kamyshino-Kurskoye, Omsk, Russia",
  "Nyagan’, Khanty-Mansi, Russia",
  "Vyborgskaya Dubrovka, Leningrad, Russia",
  "19200, Çorum, Çorum, Türkiye",
  "Saint-Quay-Perros, Brittany, France",
  "12857, Olmstedville, New York, United States",
  "Old Hickory, Tennessee, United States",
  "80011, Pärnu, Parnu, Estonia",
  "Benin",
  "Aksakovo, Varna, Bulgaria",
  "665122, Nizhneudinsk, Irkutsk, Russia",
  "Greater Nimes Area",
  "Wellsford, Victoria, Australia",
  "72350, Batman, Batman, Türkiye",
  "Oliver, Missouri, United States",
  "22656, Stephenson, Virginia, United States",
  "Tallinn Metropolitan Area",
  "Genoa, Nebraska, United States",
  "32210, Čačak, Centralna Srbija, Serbia",
  "37210, Kastamonu, Kastamonu, Türkiye",
  "Wheeler, Michigan, United States",
  "Vinton County, Ohio, United States",
  "EX2 4UF, Exeter, England, United Kingdom",
  "Cuming County, Nebraska, United States",
  "Giresun, Türkiye",
  "Hämeenlinna sub-region, Kanta-Häme, Finland",
  "Roanoke, Louisiana, United States",
  "9700-187, Angra do Heroísmo, Portugal",
  "8607, Mo i Rana, Nordland, Norway",
  "43215, Columbus, Ohio, United States",
  "Katowice, Śląskie, Poland",
  "636782, Strezhevoy, Tomsk, Russia",
  "10370, Dugo Selo, Zagreb, Croatia",
  "Kiowa Rural, Kansas, United States",
  "Omagh, Northern Ireland, United Kingdom",
  "Turner, Michigan, United States",
  "Arthur, Ontario, Canada",
  "25571, West Hamlin, West Virginia, United States",
  "66538, Seneca, Kansas, United States",
  "29118, Orangeburg, South Carolina, United States",
  "Novorossiysk, Krasnodar, Russia",
  "Eritrea",
  "4502, Lezhë, Lezhë, Albania",
  "44000, Malatya, Malatya, Türkiye",
  "B6623AED, Navarro, Buenos Aires Province, Argentina",
  "Newport Beach, California, United States",
  "Juneau Borough County, Alaska, United States",
  "82435, Powell, Wyoming, United States",
  "Pickens, South Carolina, United States",
  "Prince Frederick, Maryland, United States",
  "70634, De Ridder, Louisiana, United States",
  "Luna County, New Mexico, United States",
  "8400, Winterthur, Zurich, Switzerland",
  "120003, Buzău, Buzău, Romania",
  "Inverness Highlands South, Florida, United States",
  "16263, Yatesboro, Pennsylvania, United States",
  "Pará, Brazil",
  "Newport, Wales, United Kingdom",
  "Hampton, New Jersey, United States",
  "309255, Shebekino, Belgorod, Russia",
  "Kropyvnytskyi, Kirovohrad, Ukraine",
  "Scott County, Missouri, United States",
  "DG1 4QF, Dumfries, Scotland, United Kingdom",
  "Klyuchi, Bashkortostan, Russia",
  "Morrow, Louisiana, United States",
  "Pechbonnieu, Occitanie, France",
  "623951, Tavda, Sverdlovsk, Russia",
  "Brule Shore, Colchester, Subdivision B, Nova Scotia, Canada",
  "Holiday, Florida, United States",
  "48000, Muğla, Muğla, Türkiye",
  "Delta, Central Macedonia, Greece",
  "Ilgaz, Çankırı, Türkiye",
  "Aurora, Central Luzon, Philippines",
  "462431, Orsk, Orenburg, Russia",
  "303933, Bogoroditskoye, Orel, Russia",
  "216505, Roslavl’, Smolensk, Russia",
  "156901, Volgorechensk, Kostroma, Russia",
  "Šiauliai, Siauliu, Lithuania",
  "652518, Leninsk-Kuznetskiy, Kemerovo, Russia",
  "48116, Brighton, Michigan, United States",
  "Miami Beach, Florida, United States",
  "Georgetown, Texas, United States",
  "Salzburg, Austria",
  "Cimarron, Turlock, California, United States",
  "Bourges, Centre-Val de Loire, France",
  "7713, Steinkjer, Trøndelag, Norway",
  "FY4 1AB, Blackpool, England, United Kingdom",
  "EX1 1AL, Exeter, England, United Kingdom",
  "OL8 1AA, Oldham, England, United Kingdom",
  "460000, Orenburg, Orenburg, Russia",
  "9024, Győr, Győr-Moson-Sopron, Hungary",
  "28215, Bremen, Bremen, Germany",
  "E25C7G9, Maqat, Atyrau Region, Kazakhstan",
  "CF5 6EP, Cardiff, Wales, United Kingdom",
  "353481, Gelendzhik, Krasnodar, Russia",
  "Mihăileşti, Giurgiu, Romania",
  "Leeds, Alabama, United States",
  "692913, Nakhodka, Primorye, Russia",
  "Horqin Left Middle Banner, Inner Mongolia, China",
  "Valencia, Central Visayas, Philippines",
  "5500, Lovech, Lovech, Bulgaria",
  "641450, Kurtamysh, Kurgan, Russia",
  "Irvine, Scotland, United Kingdom",
  "Guryevsky District, Kemerovo, Russia",
  "Wallerawang, New South Wales, Australia",
  "Harrison, Arkansas, United States",
  "Payne, West Deland, Florida, United States",
  "Nancekuke, Redruth, England, United Kingdom",
  "Cape Girardeau, Missouri, United States",
  "026 01, Dolný Kubín, Zilina, Slovakia",
  "Forest, Mississippi, United States",
  "Weston, England, United Kingdom",
  "Monroe, Washington, United States",
  "53-333, Wrocław, Dolnośląskie, Poland",
  "Vienna, West Virginia, United States",
  "30830, Waynesboro, Georgia, United States",
  "058 01, Poprad, Presov, Slovakia",
  "28905-330, Cabo Frio, Rio de Janeiro, Brazil",
  "L01X6C6, Oral, West Kazakhstan Region, Kazakhstan",
  "50131, Johnston, Iowa, United States",
  "L3 5QD, Liverpool, England, United Kingdom",
  "Powder Springs, Tennessee, United States",
  "Little London, North Walsham, England, United Kingdom",
  "Stonehaven, Scotland, United Kingdom",
  "Rutland County, Vermont, United States",
  "Chenango Canal Prism and Lock 107, New York, United States",
  "Treasure Island Shores, Florida, United States",
  "Gregory, Arkansas, United States",
  "Antonio Spinetti Dini, Merida State, Venezuela",
  "Dover, New Hampshire, United States",
  "Sheridan, Texas, United States",
  "Rosebud, South Dakota, United States",
  "Lengerich, Lower Saxony, Germany",
  "Poltava, Poltava, Ukraine",
  "C00T0E1, Kokshetau, Akmola Region, Kazakhstan",
  "Darlington, England, United Kingdom",
  "Kerrobert, Saskatchewan, Canada",
  "Chouteau, Missouri, United States",
  "Cookernup, Western Australia, Australia",
  "633192, Boyarka, Novosibirsk, Russia",
  "Sequoyah Hill, Tulsa, Oklahoma, United States",
  "4311, Patrick Estate, Queensland, Australia",
  "658227, Rubtsovsk, Altai Krai, Russia",
  "13016, Marseille, Provence-Alpes-Côte d'Azur, France",
  "10013, Vilnius, Vilniaus, Lithuania",
  "84106, Salt Lake City, Utah, United States",
  "Épagny, Auvergne-Rhône-Alpes, France",
  "357911, Zelenokumsk, Stavropol, Russia",
  "Kansas City, Missouri, United States",
  "80330, Joensuu, North Karelia, Finland",
  "Columbus, Wisconsin, United States",
  "Sawyers Bay, Dunedin, Otago, New Zealand",
  "Greenbelt, Maryland, United States",
  "Central Region, Uganda",
  "3015, Drammen, Viken, Norway",
  "SS2 5FY, Southend-On-Sea, England, United Kingdom",
  "12525-234, Potim, São Paulo, Brazil",
  "20309, Narva, Ida-Virumaa, Estonia",
  "Greater Bern Area",
  "C25C6P7, Makinsk, Akmola Region, Kazakhstan",
  "24149, Kiel, Schleswig-Holstein, Germany",
  "542 44, Mariestad, Västra Götaland County, Sweden",
  "8001, Burrel, Dibrë, Albania",
  "Sandershausen, Hesse, Germany",
  "Peć, District of Peja, Kosovo",
  "Lappeenranta sub-region, South Karelia, Finland",
  "79208, Prijedor, Serb Republic, Bosnia and Herzegovina",
  "Craig County, Oklahoma, United States",
  "851 05, Bratislava, Bratislava, Slovakia",
  "23834, Colonial Heights, Virginia, United States",
  "Caenby, England, United Kingdom",
  "Tikhoretsk, Krasnodar, Russia",
  "Jenah Rural District, Hormozgan Province, Iran",
  "Westmoreland, Tennessee, United States",
  "320011, Reşiţa, Caraş-Severin, Romania",
  "Somerset, Queensland, Australia",
  "Sydling St Nicholas, England, United Kingdom",
  "Serebryanskoye, Omsk, Russia",
  "Krasnoarmeysky District, Chuvashia, Russia",
  "Bolivar, Missouri, United States",
  "38663, Ripley, Mississippi, United States",
  "9801, Vadsø, Troms og Finnmark, Norway",
  "West Kalimantan, Indonesia",
  "Aradan, Semnan Province, Iran",
  "9910, Kirkenes, Troms og Finnmark, Norway",
  "8061 AA, Hasselt, Overijssel, Netherlands",
  "Butel, Skopje Statistical Region, North Macedonia",
  "Talas Bucağı, Kayseri, Türkiye",
  "Bern, Berne, Switzerland",
  "678480, Olenyok, Sakha, Russia",
  "33615, Bielefeld, North Rhine-Westphalia, Germany",
  "Wiseman, Arkansas, United States",
  "29812, Barnwell, South Carolina, United States",
  "36209, Vigo, Galicia, Spain",
  "Ferry Pass, Florida, United States",
  "Boxford, Massachusetts, United States",
  "Chapayevskiy, Orenburg, Russia",
  "Milam Estates, Mesquite, Texas, United States",
  "Artëmovsk, Krasnoyarsk Krai, Russia",
  "57660, Watauga, South Dakota, United States",
  "La Converserie, Quatre-Champs, Grand Est, France",
  "Pulaski County, Illinois, United States",
  "34148, Trieste, Friuli-Venezia Giulia, Italy",
  "Davis Junction, Illinois, United States",
  "Il’inskoye-Khovanskoye, Ivanovo, Russia",
  "LE12 6QU, East Leake, England, United Kingdom",
  "Paskuqan, Tirana, Albania",
  "Tucker Farm Historic District, Dartmouth, Massachusetts, United States",
  "71009, Viljandi, Viljandimaa, Estonia",
  "Powhatan, Arkansas, United States",
  "2650, Sapareva Banya, Kyustendil, Bulgaria",
  "33570, Ruskin, Florida, United States",
  "7441, Cape Town, Western Cape, South Africa",
  "Deerfield, Illinois, United States",
  "East Corson, South Dakota, United States",
  "51670, Mikkeli, South Savo, Finland",
  "BT79 7GS, Omagh, Northern Ireland, United Kingdom",
  "James City County, Virginia, United States",
  "76100, Rouen, Normandy, France",
  "Oleksandriya, Kirovohrad, Ukraine",
  "Fălciu, Vaslui, Romania",
  "Bongaigaon, Assam, India",
  "Stonewall, Louisiana, United States",
  "3301, Gramsh, Elbasan, Albania",
  "9610, Hammerfest, Troms og Finnmark, Norway",
  "461420, Tatarskaya Kargala, Orenburg, Russia",
  "9406, Harstad, Troms og Finnmark, Norway",
  "Reunion",
  "Asbestos, Quebec, Canada",
  "Treasure Cove, Florida, United States",
  "Cambridge, Waikato, New Zealand",
  "Halmstads kommun, Halland County, Sweden",
  "Ermenek, Karaman, Türkiye",
  "59685-000, Triunfo Potiguar, Rio Grande do Norte, Brazil",
  "Morrow County, Oregon, United States",
  "Antelope Heights, Arizona, United States",
  "Guilford, Indiana, United States",
  "04074, Scarborough, Maine, United States",
  "Clermont, Quebec, Canada",
  "Lamar County, Georgia, United States",
  "18631, Mifflinville, Pennsylvania, United States",
  "Charlottesville City County, Virginia, United States",
  "Butterworth, Penang, Malaysia",
  "Manchester, New Jersey, United States",
  "429106, Shumerlya, Chuvashia, Russia",
  "Fairfax, Iowa, United States",
  "8201, Schaffhausen, Schaffhausen, Switzerland",
  "25019, Kropyvnytskyi, Kirovohrad, Ukraine",
  "Pend Oreille County, Washington, United States",
  "7729, Steinkjer, Trøndelag, Norway",
  "44256, Medina, Ohio, United States",
  "S21 3YH, Spinkhill, England, United Kingdom",
  "Macon, Mississippi, United States",
  "14304, Niagara Falls, New York, United States",
  "Grand Anse, Saint George, Grenada",
  "Hampton, England, United Kingdom",
  "Ramsey End, Huntingdon, England, United Kingdom",
  "69000, Zaporizhzhya, Zaporizhzhya, Ukraine",
  "Walt Whitman, Tulsa, Oklahoma, United States",
  "185 35, Piraeus, Attiki, Greece",
  "Klingenberg am Main, Bavaria, Germany",
  "Toolenburg-West, Hoofddorp, North Holland, Netherlands",
  "Westchester, Cumberland, Subdivision D, Nova Scotia, Canada",
  "East Rolette, North Dakota, United States",
  "12866, Saratoga Springs, New York, United States",
  "456219, Zlatoust, Chelyabinsk, Russia",
  "426035, Izhevsk, Udmurtia, Russia",
  "142401, Noginsk, Moscow, Russia",
  "653007, Prokopyevsk, Kemerovo, Russia",
  "03051, Cottbus, Brandenburg, Germany",
  "Sun Prairie, Wisconsin, United States",
  "Amherst, Nebraska, United States",
  "307352, Shchëkino, Kursk, Russia",
  "659321, Biysk, Altai Krai, Russia",
  "Gateshead, England, United Kingdom",
  "14701, Jamestown, New York, United States",
  "Shimanovsk, Amur, Russia",
  "75762, Tyler, Texas, United States",
  "Razgrad, Bulgaria",
  "Cumberland, Subdivision D, Nova Scotia, Canada",
  "Greater Trento Metropolitan Area",
  "Klaipėdos, Lithuania",
  "B61D5H5, Sarqan, Jetisu Region, Kazakhstan",
  "Fayette County, Texas, United States",
  "Putnam, Illinois, United States",
  "Malta",
  "Carson, North Dakota, United States",
  "Newark, Delaware, United States",
  "Merkez, Karabuk, Türkiye",
  "692406, Kavalerovo, Primorye, Russia",
  "Monroe, Wisconsin, United States",
  "Coleridge, Nebraska, United States",
  "Кентау Г.А., Turkistan Region, Kazakhstan",
  "McKenzie Towne, Calgary, Alberta, Canada",
  "46013, Anderson, Indiana, United States",
  "H02P3C6, Taraz, Jambyl Region, Kazakhstan",
  "35001, Panevėžys, Panevėžio, Lithuania",
  "98052, Redmond, Washington, United States",
  "4545, Korfi, Limassol, Cyprus",
  "Harris County, Texas, United States",
  "662935, Krasnokamensk, Krasnoyarsk Krai, Russia",
  "Menteşe, Muğla, Türkiye",
  "97395, Umán, Yucatán, Mexico",
  "Alexandria, Rio Grande do Norte, Brazil",
  "Slobodskoy, Lipetsk, Russia",
  "Hamilton, Victoria, Australia",
  "Bülach, Zurich, Switzerland",
  "Mirny, Altai Krai, Russia",
  "60-001, Poznan, Wielkopolskie, Poland",
  "06500, Porvoo, Uusimaa, Finland",
  "Toledo, Ontario, Canada",
  "Nantes-en-Ratier, Auvergne-Rhône-Alpes, France",
  "MD-4573, Doroțcaia, Dubăsari District, Moldova",
  "Castelo de Vide, Portalegre, Portugal",
  "Vyborg, Leningrad, Russia",
  "674676, Krasnokamensk, Zabaykalsky Krai, Russia",
  "Hand County, South Dakota, United States",
  "Essex County, New Jersey, United States",
  "2410-832, Leiria, Leiria, Portugal",
  "V0H 0A2, Oliver, British Columbia, Canada",
  "23242, Posedarje, Zadar, Croatia",
  "456862, Kyshtym, Chelyabinsk, Russia",
  "Baylor, Waco, Texas, United States",
  "Egernsund, Region of Southern Denmark, Denmark",
  "Dnipro Raion, Dnipropetrovsk, Ukraine",
  "Marshall, Virginia, United States",
  "Donji Miholjac, Osijek-Baranja, Croatia",
  "Fushë-Krujë, Durres, Albania",
  "Lynchburg, Missouri, United States",
  "Pustoshka, Pskov, Russia",
  "C19B8H0, Zhaltyr, Akmola Region, Kazakhstan",
  "Gornyak, Lviv, Ukraine",
  "L6 6DH, Liverpool, England, United Kingdom",
  "Saratoga Springs, Utah, United States",
  "E1A 1E4, Dieppe, New Brunswick, Canada",
  "Carrick-on-Shannon, Ireland",
  "Lynchburg, Virginia, United States",
  "E7K 2P1, Gregg Settlement, New Brunswick, Canada",
  "20011, San Sebastián, Basque Country, Spain",
  "Berlin, New Jersey, United States",
  "671843, Kyakhta, Buryatia, Russia",
  "Sully, Iowa, United States",
  "542 32, Mariestad, Västra Götaland County, Sweden",
  "Crevedia Mare, Giurgiu, Romania",
  "1011, Amsterdam, North Holland, Netherlands",
  "Bowmans, Dartford, England, United Kingdom",
  "58431, Dickey, North Dakota, United States",
  "Aurora, Colorado, United States",
  "Giresun, Giresun, Türkiye",
  "East Asia",
  "Marquette-en-Ostrevant, Hauts-de-France, France",
  "New Blaine, Arkansas, United States",
  "15714, Northern Cambria, Pennsylvania, United States",
  "Mala Vyska, Kirovohrad, Ukraine",
  "Dubrovnik, Dubrovnik-Neretva, Croatia",
  "Centre-Val de Loire, France",
  "Beaumont Mill Village, Spartanburg, South Carolina, United States",
  "19510, Blandon, Pennsylvania, United States",
  "Zrenjanin, Vojvodina, Serbia",
  "Arkhangel’sk, Russia",
  "346410, Novocherkassk, Rostov, Russia",
  "3740021, Tatebayashi, Gunma, Japan",
  "Coalville, England, United Kingdom",
  "59730, Gallatin Gateway, Montana, United States",
  "247789, Mazyr, Homel, Belarus",
  "6584, Bellinzona, Ticino, Switzerland",
  "8890, Tvarditsa, Sliven, Bulgaria",
  "Lower Mifflin, Pennsylvania, United States",
  "Pagerbarang, Pagerbarang, Central Java, Indonesia",
  "B20 1AA, Birmingham, England, United Kingdom",
  "457642, Severnyy, Chelyabinsk, Russia",
  "X5001FLB, Cordoba, Córdoba, Argentina",
  "77042, Houston, Texas, United States",
  "Langlade, Juzet-de-Luchon, Occitanie, France",
  "38010, Kayseri, Kayseri, Türkiye",
  "Grant, South Australia, Australia",
  "Akhisar, Manisa, Türkiye",
  "103, Reykjavík, Capital Region, Iceland",
  "Atoka, Tennessee, United States",
  "79, Rørvik, Trøndelag, Norway",
  "Zhmerinka, Vinnytsya, Ukraine",
  "23328, Chesapeake, Virginia, United States",
  "23435, Suffolk, Virginia, United States",
  "37770, Kastamonu, Kastamonu, Türkiye",
  "32114, Daytona Beach, Florida, United States",
  "24630, North Tazewell, Virginia, United States",
  "44512, Youngstown, Ohio, United States",
  "Zaporizhzhya, Ukraine",
  "Fulton, Michigan, United States",
  "Split Lake, Manitoba, Canada",
  "6224 AA, Maastricht, Limburg, Netherlands",
  "67460, McPherson, Kansas, United States",
  "Gura Teghii, Buzău, Romania",
  "Mikhaylovsk, Stavropol, Russia",
  "Schleichersmühle, Neustadt, Thuringia, Germany",
  "3936, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "H34F3K7, Qulan, Jambyl Region, Kazakhstan",
  "64079, Platte City, Missouri, United States",
  "São José dos Campos, São Paulo, Brazil",
  "346517, Shakhty, Rostov, Russia",
  "Isabela, Cagayan Valley, Philippines",
  "Roberts, Illinois, United States",
  "59725, Dillon, Montana, United States",
  "GY2 4SS, St Sampson, Guernsey, Guernsey",
  "97100, Ragusa, Sicily, Italy",
  "FY2 9QT, Blackpool, England, United Kingdom",
  "173002, Velikiy Novgorod, Novgorod, Russia",
  "Warren County, Virginia, United States",
  "Tomelloso, Castile-La Mancha, Spain",
  "Choctaw, Oklahoma, United States",
  "Departamento Yerba Buena, Tucumán, Argentina",
  "4331 VA, Middelburg, Zeeland, Netherlands",
  "Villa de García, Nuevo León, Mexico",
  "Buchanan County, Missouri, United States",
  "Sievierodonetska miska hromada, Luhansk, Ukraine",
  "Irakleio, Attiki, Greece",
  "D43C3Y8, Şalqar, Aqtöbe region, Kazakhstan",
  "70422, Dolinskoye, Zaporizhzhya, Ukraine",
  "N0E 0A5, Port Rowan, Ontario, Canada",
  "Körfez Bucağı, Kocaeli, Türkiye",
  "211389, Orsha, Vitsyebsk, Belarus",
  "34613, Brooksville, Florida, United States",
  "80260, Joensuu, North Karelia, Finland",
  "694494, Okha, Sakhalin, Russia",
  "2325, Hamar, Innlandet, Norway",
  "36020, Teluk Intan, Perak, Malaysia",
  "Gaza Strip",
  "West Jordan, Utah, United States",
  "Martinsburg, West Virginia, United States",
  "12618, Tallinn, Harjumaa, Estonia",
  "Schoolcraft, Michigan, United States",
  "Stargard County, Zachodniopomorskie, Poland",
  "2720111, Urayasu, Chiba, Japan",
  "Bogorodskiy, Moscow, Russia",
  "Gallia County, Ohio, United States",
  "Stanley Common, England, United Kingdom",
  "Latvia",
  "163002, Archangel, Arkhangel’sk, Russia",
  "Bozüyük, Bilecik, Türkiye",
  "Al-Farabi District, Shymkent, Kazakhstan",
  "Steinkjer, Trøndelag, Norway",
  "500004, Hyderabad, Telangana, India",
  "Hidalgo County, Texas, United States",
  "30205, Brooks, Georgia, United States",
  "Akyazı, Sakarya, Türkiye",
  "Greater Canberra Area",
  "Lake Forest, California, United States",
  "8005, Zurich, Zurich, Switzerland",
  "Brunswick East, Victoria, Australia",
  "32618, Archer, Florida, United States",
  "Miami-Dade County, Florida, United States",
  "Ozaukee County, Wisconsin, United States",
  "Hopewell, New Brunswick, Canada",
  "211404, Polatsk, Vitsyebsk, Belarus",
  "Elbeyli, Kilis, Türkiye",
  "Shchëkino, Tula, Russia",
  "430009, Saransk, Mordovia, Russia",
  "08029, Barcelona, Catalonia, Spain",
  "70500, Karaman, Karaman, Türkiye",
  "Constanţa, Constanţa, Romania",
  "Becker, Dois Irmãos, Rio Grande do Sul, Brazil",
  "Potim, São Paulo, Brazil",
  "680030, Khabarovsk, Khabarovsk, Russia",
  "Upton Upon Severn, England, United Kingdom",
  "Macon County, Alabama, United States",
  "Le Mont-sur-Lausanne, Vaud, Switzerland",
  "301 10, Agrínio, Western Greece, Greece",
  "Baylor County, Texas, United States",
  "F91 K659, Sligo, County Sligo, Ireland",
  "412309, Balashov, Saratov, Russia",
  "Honduras",
  "142114, Podolsk, Moscow, Russia",
  "Martin, Zilina, Slovakia",
  "Chelan, Saskatchewan, Canada",
  "Wayne County, Ohio, United States",
  "390005, Ryazan, Ryazan’, Russia",
  "252 49, Helsingborg, Skåne County, Sweden",
  "83832, Genesee, Idaho, United States",
  "Dublin 16, County Dublin, Ireland",
  "620036, Focşani, Vrancea, Romania",
  "Lyngate, North Walsham, England, United Kingdom",
  "Sterling, Ohio, United States",
  "Staryy Sibay, Bashkortostan, Russia",
  "78886, Yancey, Texas, United States",
  "Kendall County, Texas, United States",
  "Greenville-Washington, North Carolina Area",
  "8932 EX, Leeuwarden, Friesland, Netherlands",
  "861, Augsburg, Bavaria, Germany",
  "Belfast, Maine, United States",
  "169540, Sosnogorsk, Komi, Russia",
  "356402, Blagodarnyy, Stavropol, Russia",
  "Chelyabinsk Metropolitan Area",
  "Osnezhitskiy sel'skiy sovet, Brest, Belarus",
  "OX39 4RZ, Kingston Blount, England, United Kingdom",
  "Burnette, Saint-Julien-Labrousse, Auvergne-Rhône-Alpes, France",
  "5531, Haugesund, Rogaland, Norway",
  "Shelby, Montana, United States",
  "Tarsus, İçel, Türkiye",
  "424002, Yoshkar-Ola, Mari El, Russia",
  "5000-725, Vila Real, Vila Real, Portugal",
  "49269, Parma, Michigan, United States",
  "47897, Fiorentino, San Marino",
  "4033, Linz, Upper Austria, Austria",
  "Salsky District, Rostov, Russia",
  "Catawba Crest, Lake Wylie, South Carolina, United States",
  "91246, Klaipėda, Klaipėdos, Lithuania",
  "Harrisonburg, Louisiana, United States",
  "78029, Kerrville, Texas, United States",
  "Zvyozdny, Irkutsk, Russia",
  "Licking Riverside, Covington, Kentucky, United States",
  "19053, Schwerin, Mecklenburg-West Pomerania, Germany",
  "NR24 2AY, Edgefield Street, England, United Kingdom",
  "Newcastle-Under-Lyme, England, United Kingdom",
  "Dalaman, Muğla, Türkiye",
  "EH6 4AU, Edinburgh, Scotland, United Kingdom",
  "North Collins, New York, United States",
  "655614, Sayanogorsk, Khakassia, Russia",
  "Dunnigan, California, United States",
  "Ruše, Ruše, Slovenia",
  "646905, Kalachinsk, Omsk, Russia",
  "SR3 1QQ, Sunderland, England, United Kingdom",
  "220208, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "Steeles, Toronto, Ontario, Canada",
  "83607, Caldwell, Idaho, United States",
  "Knox County, Maine, United States",
  "Wallace, Nebraska, United States",
  "9012, Tromsø, Troms og Finnmark, Norway",
  "Cedar City, Utah, United States",
  "18411, Clarks Summit, Pennsylvania, United States",
  "SO19 0SB, Southampton, England, United Kingdom",
  "Adana, Adana, Türkiye",
  "617062, Krasnokamsk, Perm, Russia",
  "23469, Cabo San Lucas, Baja California Sur, Mexico",
  "Lifford, County Donegal, Ireland",
  "Pavlodar, Pavlodar Region, Kazakhstan",
  "Timiryazevskiy, Nizhniy Novgorod, Russia",
  "Cascais, Lisbon, Portugal",
  "GY2 4HT, St Sampson, Guernsey, Guernsey",
  "Wyandotte, Oklahoma, United States",
  "Kopavogur, Capital Region, Iceland",
  "Unknown",
  "Atkarsk, Saratov, Russia",
  "28035, Davidson, North Carolina, United States",
  "Dickey County, North Dakota, United States",
  "Baker, Florida, United States",
  "35-001, Rzeszów, Podkarpackie, Poland",
  "633209, Iskitim, Novosibirsk, Russia",
  "Rankin Location 15 D, Ontario, Canada",
  "Laurens, Masseilles, Nouvelle-Aquitaine, France",
  "Lee, Virginia, United States",
  "McHenry Vista West, Modesto, California, United States",
  "628309, Nefteyugansk, Khanty-Mansi, Russia",
  "Horad Baranavichy, Brest, Belarus",
  "59901, Kalispell, Montana, United States",
  "19111, Philadelphia, Pennsylvania, United States",
  "Ağlı, Kastamonu, Türkiye",
  "3500-082, Viseu, Viseu, Portugal",
  "Jefferson County, New York, United States",
  "Wyoming, Illinois, United States",
  "23007, Jaén, Andalusia, Spain",
  "Kaspichan Municipality, Shumen, Bulgaria",
  "Waterford, New York, United States",
  "301243, Shchëkino, Tula, Russia",
  "Erie County, Pennsylvania, United States",
  "570, West Yankton, South Dakota, United States",
  "J0B 3E2, Stanstead, Quebec, Canada",
  "Mirnyy, Kirov, Russia",
  "86-307, Grudziądz, Kujawsko-pomorskie, Poland",
  "07050, Antalya, Antalya, Türkiye",
  "Dodge, Nebraska, United States",
  "30143, Jasper, Georgia, United States",
  "413857, Balakovo, Saratov, Russia",
  "Cumberland County, Tennessee, United States",
  "Fındıklı, Rize, Türkiye",
  "Novotroitske, Kherson, Ukraine",
  "Lake County, Florida, United States",
  "Falun, Kansas, United States",
  "Lexington County, South Carolina, United States",
  "371 43, Karlskrona, Blekinge County, Sweden",
  "628404, Surgut, Khanty-Mansi, Russia",
  "Wadena, Saskatchewan, Canada",
  "20150, Denizli, Denizli, Türkiye",
  "49858, Menominee, Michigan, United States",
  "AB55 5EX, Keith, Scotland, United Kingdom",
  "Vancé, Pays de la Loire, France",
  "15-483, Białystok, Podlaskie, Poland",
  "74818, Seminole, Oklahoma, United States",
  "Krasnoufimskiy Rayon, Sverdlovsk, Russia",
  "Montague, Nashville, Tennessee, United States",
  "M22 5RG, Wythenshawe, England, United Kingdom",
  "24000, Mohyliv-Podil’s’kyy, Vinnytsya, Ukraine",
  "Golden Glades, Florida, United States",
  "4824, Arendal, Agder, Norway",
  "Erie-Meadville Area",
  "Charlottesville, Virginia, United States",
  "Grafton, Vermont, United States",
  "Holmesfield, England, United Kingdom",
  "Muş, Türkiye",
  "33132, Miami, Florida, United States",
  "385008, Maykop, Adygea, Russia",
  "623415, Kamensk Uralskiy, Sverdlovsk, Russia",
  "362020, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "Minerbe, Veneto, Italy",
  "20236, Dubrovnik, Dubrovnik-Neretva, Croatia",
  "Doorn, Utrecht, Netherlands",
  "S25B2M0, Ertis, Pavlodar Region, Kazakhstan",
  "9545-341, Ponta Delgada, Portugal",
  "05280, Mingorría, Castilla and Leon, Spain",
  "Beringovskiy, Chukot, Russia",
  "428010, Cheboksary, Chuvashia, Russia",
  "664007, Irkutsk, Irkutsk, Russia",
  "Maldives",
  "Murray County, Minnesota, United States",
  "89000, Auxerre, Bourgogne-Franche-Comté, France",
  "Kirs, Kirov, Russia",
  "Socorro, Mimaropa, Philippines",
  "Chickasaw Manor, Greenacres, Florida, United States",
  "359053, Elista, Kalmykia, Russia",
  "Nolans, Montague, Ontario, Canada",
  "Campbellford, Ontario, Canada",
  "80110, Joensuu, North Karelia, Finland",
  "Birchen Coppice, Kidderminster, England, United Kingdom",
  "663601, Kansk, Krasnoyarsk Krai, Russia",
  "Makarovskaya, Arkhangel’sk, Russia",
  "39474, Prentiss, Mississippi, United States",
  "686117, Palatka, Magadan, Russia",
  "Cherokee County, Kansas, United States",
  "Killarney, Ontario, Canada",
  "F91 E8YN, Sligo, County Sligo, Ireland",
  "7420, Kaposvár, Somogy, Hungary",
  "70130, New Orleans, Louisiana, United States",
  "Fairfax, South Carolina, United States",
  "Rusk County, Texas, United States",
  "623384, Polevskoy, Sverdlovsk, Russia",
  "Metz, Grand Est, France",
  "Lincoln Parish County, Louisiana, United States",
  "X38B3G1, Kentau, Turkistan Region, Kazakhstan",
  "06400, Porvoo, Uusimaa, Finland",
  "76466, Eastland, Texas, United States",
  "78550-070, Sinop, Mato Grosso, Brazil",
  "670031, Ulan-Ude, Buryatia, Russia",
  "Nottingham, New Hampshire, United States",
  "Hanson, South Australia, Australia",
  "46381, Thayer, Indiana, United States",
  "Hampton Park, Victoria, Australia",
  "53406, Racine, Wisconsin, United States",
  "694007, Korsakov, Sakhalin, Russia",
  "Tver, Tver’, Russia",
  "Põhja-Tallinn, Harjumaa, Estonia",
  "Shymkent, Kazakhstan",
  "Howwood Road, Johnstone, Scotland, United Kingdom",
  "54143, Marinette, Wisconsin, United States",
  "491 31, Kérkira, Ionian Islands, Greece",
  "73036, Muro Leccese, Apulia, Italy",
  "Călăraşi, Moldova",
  "Ladakh, India",
  "Montague, Texas, United States",
  "07010, Palma, Balearic Islands, Spain",
  "Bogoroditskoye, Lipetsk, Russia",
  "Nizhyn, Chernihiv, Ukraine",
  "Pecos Aldea, Chandler, Arizona, United States",
  "Navapolatsk, Vitsyebsk, Belarus",
  "Tovuz, Qazakh-Tovuz, Azerbaijan",
  "68760, Niobrara, Nebraska, United States",
  "6301, Tepelenë, Gjirokastër, Albania",
  "Pershing, Detroit, Michigan, United States",
  "99560, Sodankylä, Lapland, Finland",
  "35114, Panevėžys, Panevėžio, Lithuania",
  "H34F0P1, Qulan, Jambyl Region, Kazakhstan",
  "442543, Kuznetsk, Penza, Russia",
  "Voznesenskoye, Khabarovsk, Russia",
  "McHenry Estates, Modesto, California, United States",
  "HP17 0XL, Little Kimble, England, United Kingdom",
  "6062, Sarnen, Obwalden, Switzerland",
  "Wythenshawe, England, United Kingdom",
  "171075, Bologoye, Tver’, Russia",
  "Clermont, Iowa, United States",
  "Harding, Corvallis, Oregon, United States",
  "271 31, Pírgos, Western Greece, Greece",
  "70340, Kuopio, Northern Savonia, Finland",
  "Oswego, Kansas, United States",
  "E14 0AA, London, England, United Kingdom",
  "SS1 1ND, Southend-On-Sea, England, United Kingdom",
  "924 01, Galanta, Trnava Region, Slovakia",
  "C25A4Y1, Makinsk, Akmola Region, Kazakhstan",
  "23255, Henrico, Virginia, United States",
  "Hardy, Arkansas, United States",
  "83022, Rosenheim, Bavaria, Germany",
  "Voloshovo, Leningrad, Russia",
  "Clarion, Massals, Occitanie, France",
  "Norfolk County, Massachusetts, United States",
  "68008, Blair, Nebraska, United States",
  "Osakarovka, Karaganda Region, Kazakhstan",
  "Belfast, New York, United States",
  "Glen Robertson, Ontario, Canada",
  "85-111, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "659330, Biysk, Altai Krai, Russia",
  "Tuscola, Texas, United States",
  "24223, Subotica, Vojvodina, Serbia",
  "671842, Kyakhta, Buryatia, Russia",
  "37773, New Tazewell, Tennessee, United States",
  "75118, Terrell, Texas, United States",
  "GL54 5EB, Toddington, England, United Kingdom",
  "Johnson County, Texas, United States",
  "Barrys Bay, Ontario, Canada",
  "57301, Mitchell, South Dakota, United States",
  "173023, Velikiy Novgorod, Novgorod, Russia",
  "Osceola Park, Umatilla, Florida, United States",
  "831 35, Östersund, Jämtland County, Sweden",
  "677009, Yakutsk, Sakha, Russia",
  "Gmina Starogard Gdański, Pomorskie, Poland",
  "238546, Gur'yevsk, Kaliningrad, Russia",
  "Property Owners of Northhampton, Converse, Texas, United States",
  "V3H 5K3, Port Moody, British Columbia, Canada",
  "Riga, Riga, Latvia",
  "12004, Castellón de la Plana, Valencian Community, Spain",
  "Nieuwegein, Utrecht, Netherlands",
  "48015, Bilbao, Basque Country, Spain",
  "DL2 2UA, Walworth Gate, England, United Kingdom",
  "8520, Frauenfeld, Thurgau, Switzerland",
  "456217, Zlatoust, Chelyabinsk, Russia",
  "42800, Tula, Hidalgo, Mexico",
  "Jasper, Georgia, United States",
  "Cedar Vale, Queensland, Australia",
  "44236, Hudson, Ohio, United States",
  "18462, Starrucca, Pennsylvania, United States",
  "Brookings County, South Dakota, United States",
  "SA1 2ED, Swansea, Wales, United Kingdom",
  "25667, Williamson, West Virginia, United States",
  "LU1 2AW, Luton, England, United Kingdom",
  "02004, Albacete, Castile-La Mancha, Spain",
  "Kostroma, Kostroma, Russia",
  "14218, Buffalo, New York, United States",
  "Dickson Street, Fayetteville, Arkansas, United States",
  "07030, Antalya, Antalya, Türkiye",
  "78278, Siauliai, Siauliu, Lithuania",
  "76139, Karlsruhe, Baden-Württemberg, Germany",
  "Pine, Arizona, United States",
  "4335 JV, Middelburg, Zeeland, Netherlands",
  "18328, Dingmans Ferry, Pennsylvania, United States",
  "Åland Islands",
  "238300, Gur'yevsk, Kaliningrad, Russia",
  "90017, Los Angeles, California, United States",
  "601902, Kovrov, Vladimir, Russia",
  "33110, Mersin, İçel, Türkiye",
  "Tatarsko-Sugutskoye, Chuvashia, Russia",
  "Presidio in The Pines, Flagstaff, Arizona, United States",
  "Morgan County, Tennessee, United States",
  "Greater Ruse Area",
  "456664, Kanashevo, Chelyabinsk, Russia",
  "60486, Frankfurt, Hesse, Germany",
  "FY3 8NR, Blackpool, England, United Kingdom",
  "Archer Heights, Chicago, Illinois, United States",
  "N0H 2M0, Stokes Bay, Ontario, Canada",
  "Sully County, South Dakota, United States",
  "Emery, Leeds and the Thousand Islands, Ontario, Canada",
  "64900, Uşak, Uşak, Türkiye",
  "Preston, Victoria, Australia",
  "Jacka, Australian Capital Territory, Australia",
  "Real Sitio de San Ildefonso, Castilla and Leon, Spain",
  "Batagayskiy, Sakha, Russia",
  "Reading, England, United Kingdom",
  "Beaver, Washington, United States",
  "93047, Regensburg, Bavaria, Germany",
  "40035, Sumy, Sumy, Ukraine",
  "624592, Ivdel’, Sverdlovsk, Russia",
  "78076, Zapata, Texas, United States",
  "8540, Narvik, Nordland, Norway",
  "30052, Loganville, Georgia, United States",
  "Deer Island, Oregon, United States",
  "422222, Nashik, Maharashtra, India",
  "Tatarskaya Bashmakovka, Astrakhan’, Russia",
  "28800, Giresun, Giresun, Türkiye",
  "Dmytrivska silska hromada, Kirovohrad, Ukraine",
  "Pampa de Tate, Ica, Peru",
  "62900, Tunceli, Tunceli, Türkiye",
  "Redmond, Oregon, United States",
  "Adams, Tennessee, United States",
  "SS2 6UN, Southend-On-Sea, England, United Kingdom",
  "Mikhaylovka, Rostov, Russia",
  "456476, Mirnyy, Chelyabinsk, Russia",
  "Greater Taranto Metropolitan Area",
  "V93 KPF7, Killarney, County Kerry, Ireland",
  "Solhan, Bingöl, Türkiye",
  "Sullivan City, Texas, United States",
  "70375, Mathews, Louisiana, United States",
  "Belarus",
  "Morrill, Nebraska, United States",
  "Ellis, Idaho, United States",
  "602205, Murom, Vladimir, Russia",
  "Western Visayas, Philippines",
  "Aydın Subregion, Türkiye",
  "Summit County, Utah, United States",
  "Burleson Farms, North Carolina, United States",
  "Shule, Xinjiang Uygur, China",
  "653004, Prokopyevsk, Kemerovo, Russia",
  "05403, South Burlington, Vermont, United States",
  "Bradley, South Dakota, United States",
  "BT27 4AL, Lisburn, Northern Ireland, United Kingdom",
  "Smith County, Texas, United States",
  "Casselberry, Florida, United States",
  "Cannonville, Utah, United States",
  "456238, Zlatoust, Chelyabinsk, Russia",
  "Bingol, Bingöl, Türkiye",
  "Jyväskylä, Central Finland, Finland",
  "06011, Badajoz, Extremadura, Spain",
  "Polatlı, Ankara, Türkiye",
  "666781, Ust-Kut, Irkutsk, Russia",
  "Rankinipur, Nandigram-II, West Bengal, India",
  "9759, Kemijärvi, Lapland, Finland",
  "Valencia, Santa Clarita, California, United States",
  "Kyshtym, Chelyabinsk, Russia",
  "542 94, Mariestad, Västra Götaland County, Sweden",
  "Carroll County, Arkansas, United States",
  "701, Egilsstaðir, Eastern Region, Iceland",
  "357921, Zelenokumsk, Stavropol, Russia",
  "Onondaga, Michigan, United States",
  "Bennett Woods, Smyrna, Georgia, United States",
  "Carver, South Carolina, United States",
  "65, Bellinzona, Ticino, Switzerland",
  "Mills River, North Carolina, United States",
  "Merrimack, Huntsville, Alabama, United States",
  "Montour County, Pennsylvania, United States",
  "Oliver, Edmonton, Alberta, Canada",
  "Linn County, Kansas, United States",
  "52158, Marquette, Iowa, United States",
  "Vladimirskaya, Krasnodar, Russia",
  "678290, Suntar, Sakha, Russia",
  "IP33 3RL, Bury St Edmunds, England, United Kingdom",
  "320192, Reşiţa, Caraş-Severin, Romania",
  "G5R 5E6, Rivière-du-Loup, Quebec, Canada",
  "142271, Serpukhov, Moscow, Russia",
  "Kirsanovo-Pyatnitsa, Kaluga, Russia",
  "SS2 4EJ, Southend-On-Sea, England, United Kingdom",
  "236005, Kaliningrad, Kaliningrad, Russia",
  "29304, Spartanburg, South Carolina, United States",
  "Crooksville, Ohio, United States",
  "E1A 4X5, Dieppe, New Brunswick, Canada",
  "Bottineau, North Dakota, United States",
  "ST18 0TA, Great Haywood, England, United Kingdom",
  "N0E 1M0, Port Rowan, Ontario, Canada",
  "27608, Raleigh, North Carolina, United States",
  "Greater Coventry Area",
  "666774, Ust-Kut, Irkutsk, Russia",
  "50674, Sumner, Iowa, United States",
  "Crookes, Sheffield, England, United Kingdom",
  "Timiryazeva, Adygea, Russia",
  "Lauberhof-Gutenbrunnerhof, Trippstadt, Rhineland-Palatinate, Germany",
  "676854, Belogorsk, Amur, Russia",
  "C19B7G9, Zhaltyr, Akmola Region, Kazakhstan",
  "Cambria Heights, New York, New York, United States",
  "Cacheu Region, Guinea-Bissau",
  "Horta of Valencia, Valencian Community, Spain",
  "352691, Apsheronsk, Krasnodar, Russia",
  "Cook County, Illinois, United States",
  "Washburn, North Dakota, United States",
  "20880, Turku, Southwest Finland, Finland",
  "Tampere Metropolitan Area",
  "55050, Samsun, Samsun, Türkiye",
  "Auxerre, Bourgogne-Franche-Comté, France",
  "Klyuchi, Kamchatka, Russia",
  "2790011, Urayasu, Chiba, Japan",
  "659832, Novoaltaysk, Altai Krai, Russia",
  "29405, North Charleston, South Carolina, United States",
  "Petroleum County, Montana, United States",
  "B3K 1N7, Halifax, Nova Scotia, Canada",
  "Bowie County, Texas, United States",
  "01217, Dresden, Saxony, Germany",
  "Syrskiy, Lipetsk, Russia",
  "6513, Bellinzona, Ticino, Switzerland",
  "Rooty Hill, New South Wales, Australia",
  "Bejaia, Algeria",
  "E28D6H8, Makhambet, Atyrau Region, Kazakhstan",
  "357914, Zelenokumsk, Stavropol, Russia",
  "152920, Rybinsk, Yaroslavl’, Russia",
  "78741, Austin, Texas, United States",
  "Kelesgamuwa, Western Province, Sri Lanka",
  "Artëmovsk, Luhansk, Ukraine",
  "92100, Agrigento, Sicily, Italy",
  "Olmsted Subdivision Historic District, Swampscott, Massachusetts, United States",
  "Hickman, Arkansas, United States",
  "Porto Alegre, Rio Grande do Sul, Brazil",
  "Ménic an Tri Person, Lesneven, Brittany, France",
  "7000, Chur, Graubünden, Switzerland",
  "Dresden, New York, United States",
  "Nicholasville, Kentucky, United States",
  "Hutchinson Acres, Arizona, United States",
  "Orshanskiy gorodskoy sovet, Vitsyebsk, Belarus",
  "Dresden, Tennessee, United States",
  "Des Moines, Iowa, United States",
  "Turku, Southwest Finland, Finland",
  "Yayladere, Bingöl, Türkiye",
  "Košice-Juh, Kosice, Slovakia",
  "Henryetta, Oklahoma, United States",
  "112, Algeciras, Andalusia, Spain",
  "Barinas State, Venezuela",
  "47980, Reynolds, Indiana, United States",
  "50670, Cologne, North Rhine-Westphalia, Germany",
  "17400, Çanakkale, Çanakkale, Türkiye",
  "Lublin, Lubelskie, Poland",
  "612743, Omutninsk, Kirov, Russia",
  "Győr-Moson-Sopron, Hungary",
  "Seneca County, New York, United States",
  "Auglaize County, Ohio, United States",
  "Eastland Park, Tulsa, Oklahoma, United States",
  "M28C3G5, Aqtau, Karaganda Region, Kazakhstan",
  "90143, Palermo, Sicily, Italy",
  "F39B2M1, Zaysan, East Kazakhstan Region, Kazakhstan",
  "04103, Leipzig, Saxony, Germany",
  "Polevskoy, Altai Krai, Russia",
  "Donaldsonville, Louisiana, United States",
  "Rutherford County, North Carolina, United States",
  "Huntsville, Alabama, United States",
  "39110, Magdeburg, Saxony-Anhalt, Germany",
  "Rosenheim, Bavaria, Germany",
  "Bonnyville, Alberta, Canada",
  "Miami, Oklahoma, United States",
  "Fergus, Ontario, Canada",
  "84121, Salerno, Campania, Italy",
  "Grand Forks, North Dakota, United States",
  "152308, Borisogleb, Yaroslavl’, Russia",
  "55126, Mainz, Rhineland-Palatinate, Germany",
  "10000, Pristina, District of Pristina, Kosovo",
  "633010, Iskitim, Novosibirsk, Russia",
  "Eñbekşi awdanı, Shymkent, Kazakhstan",
  "Novopolotskiy gorodskoy sovet, Vitsyebsk, Belarus",
  "Montour, Saint-Denis-d’Orques, Pays de la Loire, France",
  "676407, Svobodnyy, Amur, Russia",
  "Dewey Hill, Edina, Minnesota, United States",
  "Bradford County, Florida, United States",
  "71435, Grayson, Louisiana, United States",
  "658087, Novoaltaysk, Altai Krai, Russia",
  "Michurinska silska rada, Crimea, Ukraine",
  "Butler County, Pennsylvania, United States",
  "06230, Ankara, Ankara, Türkiye",
  "Hindsboro, Illinois, United States",
  "Guilford, Baltimore, Maryland, United States",
  "Hanover, Jamaica",
  "Corson, Saint-Laurent-en-Brionnais, Bourgogne-Franche-Comté, France",
  "Novokuznetsk, Kemerovo, Russia",
  "PE8 5QB, Barnwell, England, United Kingdom",
  "E4M 1M8, Coburg, New Brunswick, Canada",
  "582 24, Linköping, Östergötland County, Sweden",
  "30140, Frontera Comalapa, Chiapas, Mexico",
  "S1 9TJ, Sheffield, England, United Kingdom",
  "79549, Snyder, Texas, United States",
  "Ertis, Pavlodar Region, Kazakhstan",
  "633454, Toguchin, Novosibirsk, Russia",
  "35001, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Kavarna Municipality, Dobrich, Bulgaria",
  "Pugachëvskaya, Volgograd, Russia",
  "233, Keflavík, Southern Peninsula, Iceland",
  "Gouveia, Guarda, Portugal",
  "44223, Cuyahoga Falls, Ohio, United States",
  "Venango Township, Pennsylvania, United States",
  "Gjoçaj, Elbasan, Albania",
  "791 56, Falun, Dalarna County, Sweden",
  "South Side Slopes, Pittsburgh, Pennsylvania, United States",
  "Elkton, Maryland, United States",
  "Latimer, Mississippi, United States",
  "Barrio Estación Wilkinson, San Juan, Argentina",
  "57000, Sinop, Sinop, Türkiye",
  "431450, Ruzayevka, Mordovia, Russia",
  "74106, Tulsa, Oklahoma, United States",
  "Greenwood, California, United States",
  "89081, Ulm, Baden-Württemberg, Germany",
  "Colfax, Washington, United States",
  "Geneva, New York, United States",
  "Powdersville, South Carolina, United States",
  "40129, Bologna, Emilia-Romagna, Italy",
  "Summerstown, London, England, United Kingdom",
  "Čačak, Centralna Srbija, Serbia",
  "618204, Chusovoy, Perm, Russia",
  "453107, Sterlitamak, Bashkortostan, Russia",
  "90762, Fürth, Bavaria, Germany",
  "F39A8Y4, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Istočni Mostar, Serb Republic, Bosnia and Herzegovina",
  "Matagorda, Puerto Real, Andalusia, Spain",
  "Lake Hinds, Western Australia, Australia",
  "74116, Tulsa, Oklahoma, United States",
  "80010, Pärnu, Parnu, Estonia",
  "Anoka County, Minnesota, United States",
  "Clarendon, Texas, United States",
  "8019, Székesfehérvár, Fejér, Hungary",
  "43122, Parma, Emilia-Romagna, Italy",
  "Bloomington, Indiana, United States",
  "50173, Montour, Iowa, United States",
  "C19C3E3, Zhaltyr, Akmola Region, Kazakhstan",
  "164522, Severodvinsk, Arkhangel’sk, Russia",
  "59000, Lille, Hauts-de-France, France",
  "262 25, Patras, Western Greece, Greece",
  "Belogorsky District, Amur, Russia",
  "48137, Gregory, Michigan, United States",
  "Mingo Valley, Tulsa, Oklahoma, United States",
  "8203, Schaffhausen, Schaffhausen, Switzerland",
  "50111, Grimes, Iowa, United States",
  "48820, DeWitt, Michigan, United States",
  "Red Bank, New Jersey, United States",
  "Skierniewice, Łódzkie, Poland",
  "56251, Kandiyohi, Minnesota, United States",
  "AD600, Sant Julià de Lòria, Sant Julià de Lòria, Andorra",
  "Elkhorn, Omaha, Nebraska, United States",
  "47006, Batesville, Indiana, United States",
  "9388, Finnsnes, Troms og Finnmark, Norway",
  "Tempo Ranchos, Hickman, California, United States",
  "Frankfurt am Main, Hesse, Germany",
  "Mowbray, Pembina, Manitoba, Canada",
  "Darlington, Pennsylvania, United States",
  "9403 NK, Assen, Drenthe, Netherlands",
  "65580, Vichy, Missouri, United States",
  "M1 1EP, Manchester, England, United Kingdom",
  "Partizansky District, Krasnoyarsk Krai, Russia",
  "Roraima, Brazil",
  "Tripoli, Tripoli, Libya",
  "Yatesville, Georgia, United States",
  "Stockholm, Stockholm County, Sweden",
  "54137, Krakow, Wisconsin, United States",
  "Durrës, Durres, Albania",
  "Towner Park, Haverhill, Florida, United States",
  "Ironwood, Michigan, United States",
  "21006, Huelva, Andalusia, Spain",
  "629740, Nadym, Yamalo-Nenets, Russia",
  "970, Würzburg, Bavaria, Germany",
  "681000, Komsomolsk, Khabarovsk, Russia",
  "27578, Bremerhaven, Bremen, Germany",
  "02139, Cambridge, Massachusetts, United States",
  "Bilajari, Baku Ekonomic Zone, Azerbaijan",
  "Vilassar de Mar, Catalonia, Spain",
  "Clarke County, Alabama, United States",
  "Brazos Shores, Texas, United States",
  "Enbekshikazakh District, Almaty Region, Kazakhstan",
  "Brighton, South Australia, Australia",
  "41379, Brüggen, North Rhine-Westphalia, Germany",
  "427628, Glazov, Udmurtia, Russia",
  "Rankin County, Mississippi, United States",
  "Carlisle County, Kentucky, United States",
  "3727, Lake Rowan, Victoria, Australia",
  "Otsego, Michigan, United States",
  "McCook, Nebraska, United States",
  "54702, Eau Claire, Wisconsin, United States",
  "6063, Sarnen, Obwalden, Switzerland",
  "614066, Perm, Perm, Russia",
  "Luton, England, United Kingdom",
  "E25C1C2, Maqat, Atyrau Region, Kazakhstan",
  "141980, Dubna, Tver’, Russia",
  "77640, Port Arthur, Texas, United States",
  "Oral, West Kazakhstan Region, Kazakhstan",
  "Esilalei, Arusha, Tanzania",
  "Valencia, Pennsylvania, United States",
  "360015, Nalchik, Kabardino-Balkaria, Russia",
  "McLean, Nebraska, United States",
  "23000, Zadar, Zadar, Croatia",
  "Parker Heights, Callaway, Florida, United States",
  "Santarém, Santarém, Portugal",
  "94038, Moss Beach, California, United States",
  "7000, Mons, Walloon Region, Belgium",
  "624540, Ivdel’, Sverdlovsk, Russia",
  "Lebanon",
  "63500, Şanlıurfa, Şanliurfa, Türkiye",
  "633126, Sarapulka, Novosibirsk, Russia",
  "Potter County, South Dakota, United States",
  "SO43 7DT, Emery Down, England, United Kingdom",
  "2595 AN, The Hague, South Holland, Netherlands",
  "Thuringia, Germany",
  "Winchester, Kentucky, United States",
  "79800, Salles-en-Toulon, Nouvelle-Aquitaine, France",
  "Tulunan, Soccsksargen, Philippines",
  "110216, Piteşti, Argeş, Romania",
  "Whitefish, Montana, United States",
  "North Hamlin, New York, United States",
  "Velika Pisanica, Bjelovar-Bilogora, Croatia",
  "Ovacık, Tunceli, Türkiye",
  "Greenbrier, Madison, Mississippi, United States",
  "Marathon, Iowa, United States",
  "3876, Puketotara, Waikato, New Zealand",
  "Tioga County, Pennsylvania, United States",
  "Mercan, Erzincan, Türkiye",
  "121 27, Stockholm, Stockholm County, Sweden",
  "05022, Amelia, Umbria, Italy",
  "Hardin County, Texas, United States",
  "660118, Krasnoyarsk, Krasnoyarsk Krai, Russia",
  "39836, Coleman, Georgia, United States",
  "City Of Nottingham, England, United Kingdom",
  "Rørvik, Trøndelag, Norway",
  "Carterton District, Wellington, New Zealand",
  "N0C 0A1, Priceville, Ontario, Canada",
  "10437, Berlin, Berlin, Germany",
  "Makarska, Split-Dalmatia, Croatia",
  "Baker, Louisiana, United States",
  "Knin, Sibenik-Knin, Croatia",
  "53574, New Glarus, Wisconsin, United States",
  "Tatabányai járás, Komárom-Esztergom, Hungary",
  "EN6 1AE, Potters Bar, England, United Kingdom",
  "68928, Bladen, Nebraska, United States",
  "Beaufort County, North Carolina, United States",
  "Pecquencourt, Hauts-de-France, France",
  "Upton, England, United Kingdom",
  "4631, Kristiansand, Agder, Norway",
  "86169, Augsburg, Bavaria, Germany",
  "364020, Groznyy, Chechnya, Russia",
  "Barton, New York, United States",
  "1011 MA, Amsterdam, North Holland, Netherlands",
  "641877, Shadrinsk, Kurgan, Russia",
  "CF24 0AA, Cardiff, Wales, United Kingdom",
  "Bowiesen, Wittighausen, Baden-Württemberg, Germany",
  "21031, Hamburg, Hamburg, Germany",
  "Rogaška Slatina, Šmarje pri Jelšah, Slovenia",
  "Ottersberg, Lower Saxony, Germany",
  "Becker, Minnesota, United States",
  "623383, Polevskoy, Sverdlovsk, Russia",
  "Glacier North, Idaho, United States",
  "Chesterfield Township, Michigan, United States",
  "Mulhouse, Grand Est, France",
  "34321, Kragujevac, Centralna Srbija, Serbia",
  "Stevens County, Washington, United States",
  "352 36, Växjö, Kronoberg County, Sweden",
  "Yellow Springs, Pennsylvania, United States",
  "Park County, Wyoming, United States",
  "Gospersgrün, Treuen, Saxony, Germany",
  "Obshtina Byala Slatina, Vratsa, Bulgaria",
  "60121, Ancona, Marches, Italy",
  "Cisnădie, Sibiu, Romania",
  "Ocean City, New Jersey, United States",
  "Mahoning Commons, Youngstown, Ohio, United States",
  "Yeşilyurt, Tokat, Türkiye",
  "Ovidiu, Constanţa, Romania",
  "352382, Kropotkin, Krasnodar, Russia",
  "Lucasville, Nova Scotia, Canada",
  "F42G8H0, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "Titus Flats, Ithaca, New York, United States",
  "36210, Vigo, Galicia, Spain",
  "MD-2012, Chişinău, Chișinău, Moldova",
  "BT28 2AA, Lisburn, Northern Ireland, United Kingdom",
  "Comanche County, Texas, United States",
  "22025, Dumfries, Virginia, United States",
  "28200, Pori, Satakunta, Finland",
  "15540, Lahti, Päijät-Häme, Finland",
  "Cook, Minnesota, United States",
  "21335, Lunenburg, Lower Saxony, Germany",
  "Temirtau, Karaganda Region, Kazakhstan",
  "Lake Minnehaha Chain O'Lakes, Clermont, Florida, United States",
  "36102, Panevėžys, Panevėžio, Lithuania",
  "Knoxville Metropolitan Area",
  "603 80, Norrköping, Östergötland County, Sweden",
  "500461, Braşov, Braşov, Romania",
  "Fargo-Moorhead",
  "346406, Novocherkassk, Rostov, Russia",
  "Heidelberg, Ontario, Canada",
  "652 26, Karlstad, Värmland County, Sweden",
  "Kukës, Kukës, Albania",
  "57350, Huron, South Dakota, United States",
  "2837, Gjøvik, Innlandet, Norway",
  "Kirovsk, Leningrad, Russia",
  "06450, Porvoo, Uusimaa, Finland",
  "Hökenbrügge, Kirchlengern, North Rhine-Westphalia, Germany",
  "Darende, Malatya, Türkiye",
  "Derzhavinskoye, Tatarstan, Russia",
  "05000, Amasya, Amasya, Türkiye",
  "Blerim, Shkodër, Albania",
  "Seaford, England, United Kingdom",
  "Kars, Ontario, Canada",
  "Clay County, North Carolina, United States",
  "Les Rhéautés, Bournezeau, Pays de la Loire, France",
  "399774, Yeletskiy, Lipetsk, Russia",
  "Beeston, England, United Kingdom",
  "Cabo San Lucas, Baja California Sur, Mexico",
  "Brăila, Romania",
  "663852, Kansk, Krasnoyarsk Krai, Russia",
  "Darenth Valley, Dartford, England, United Kingdom",
  "07643, Little Ferry, New Jersey, United States",
  "25800, Erzurum, Erzurum, Türkiye",
  "Terrell County, Georgia, United States",
  "R1N 0A1, Portage la Prairie, Manitoba, Canada",
  "Mednogorsky, Karachay-Cherkessia, Russia",
  "629752, Nadym, Yamalo-Nenets, Russia",
  "Latimer, England, United Kingdom",
  "Richmond, Michigan, United States",
  "Defiance, Pennsylvania, United States",
  "Fondo, Trentino-Alto Adige, Italy",
  "Washington County, Rhode Island, United States",
  "Bastia Umbra, Umbria, Italy",
  "9372, Finnsnes, Troms og Finnmark, Norway",
  "Chisago County, Minnesota, United States",
  "452750, Tuymazy, Bashkortostan, Russia",
  "Mingo Junction, Ohio, United States",
  "Dikson, Taymyr, Russia",
  "Niagara County, New York, United States",
  "Staten Island, New York, United States",
  "Vadsø, Troms og Finnmark, Norway",
  "222515, Barysaw, Minsk, Belarus",
  "Isle Of Bute, Scotland, United Kingdom",
  "Ogulin, Karlovac, Croatia",
  "Licking, Missouri, United States",
  "Le Petit Malheur, Bourgeauville, Normandy, France",
  "Calumet, Oklahoma, United States",
  "Ridderkerk, South Holland, Netherlands",
  "05072, Strafford, Vermont, United States",
  "Straffordville, Ontario, Canada",
  "Clarion, Maurrin, Nouvelle-Aquitaine, France",
  "35-051, Rzeszów, Podkarpackie, Poland",
  "65719, Hofheim am Taunus, Hesse, Germany",
  "Mercer County, Missouri, United States",
  "Veliko Turnovo, Bulgaria",
  "MD-2020, Chişinău, Chișinău, Moldova",
  "S13E5A7, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Mallow, County Cork, Ireland",
  "Tsar Kaloyan Municipality, Razgrad, Bulgaria",
  "Heidelberg, Western Cape, South Africa",
  "Dmytrivka, Kirovohrad, Ukraine",
  "58430, Savonlinna, South Savo, Finland",
  "Iron County, Wisconsin, United States",
  "456805, Verkhniy Ufaley, Chelyabinsk, Russia",
  "120130, Buzău, Buzău, Romania",
  "Wiseton, Saskatchewan, Canada",
  "162396, Velikiy Ustyug, Vologda, Russia",
  "Kirovsky District, Leningrad, Russia",
  "Horad Hrodna, Hrodna, Belarus",
  "181324, Vorontsovo, Pskov, Russia",
  "Valle de Banderas, Nayarit, Mexico",
  "Chippewa, Ohio, United States",
  "23503, Norfolk, Virginia, United States",
  "662074, Bogotol selo, Krasnoyarsk Krai, Russia",
  "94550, Livermore, California, United States",
  "Vaasa, Ostrobothnia, Finland",
  "17700, Çanakkale, Çanakkale, Türkiye",
  "1340085, Urayasu, Chiba, Japan",
  "Pulaski, Georgia, United States",
  "48903, Autlán de Navarro, Jalisco, Mexico",
  "31029, Forsyth, Georgia, United States",
  "Pondera County, Montana, United States",
  "Desa Pagedangan, Kecamatan Pagedangan, Banten, Indonesia",
  "87312, Continental Divide, New Mexico, United States",
  "Denver Court, Gentry, Arkansas, United States",
  "Klaipeda, Klaipėdos, Lithuania",
  "34121, Kassel, Hesse, Germany",
  "Shiraz, Fars Province, Iran",
  "Gusev, Kaliningrad, Russia",
  "Aberdeen, Mississippi, United States",
  "Vanderburgh County, Indiana, United States",
  "Haute-Garonne, Occitanie, France",
  "386103, Nazran, Ingushetia, Russia",
  "Johnstone, Scotland, United Kingdom",
  "46143, Greenwood, Indiana, United States",
  "9845, Kemijärvi, Lapland, Finland",
  "Travis Oaks, Texas, United States",
  "11008, Cadiz, Andalusia, Spain",
  "Corumbataí, São Paulo, Brazil",
  "452614, Oktyabrsky, Bashkortostan, Russia",
  "Little Traverse, Michigan, United States",
  "Southern Africa",
  "7944, Rørvik, Trøndelag, Norway",
  "17000, Çanakkale, Çanakkale, Türkiye",
  "82201, Wheatland, Wyoming, United States",
  "Fort Stewart, Georgia, United States",
  "Medvode, Ljubljana, Slovenia",
  "Cecina, Tuscany, Italy",
  "KW15 1EW, Kirkwall, Scotland, United Kingdom",
  "Crowborough, England, United Kingdom",
  "Sterling, Connecticut, United States",
  "606039, Dzerzhinsk, Nizhniy Novgorod, Russia",
  "Nyertete, Central Darfur State, Sudan",
  "225708, Pinsk, Brest, Belarus",
  "Greater Cheyenne Area",
  "Cache Junction, Utah, United States",
  "Lakatamia, Nicosia, Cyprus",
  "33775, Seminole, Florida, United States",
  "63100, Clermont-Ferrand, Auvergne-Rhône-Alpes, France",
  "82007, Cheyenne, Wyoming, United States",
  "Appomattox River, Virginia, United States",
  "Callaway, Minnesota, United States",
  "León, Guanajuato, Mexico",
  "Equatorial Guinea",
  "Church Point, Louisiana, United States",
  "City municipality of Novi Sad, Vojvodina, Serbia",
  "Harlan, Wilmington, Delaware, United States",
  "30287, Morrow, Georgia, United States",
  "Kings Garden, Hutchinson, Kansas, United States",
  "Harpersville, Alabama, United States",
  "Daré Salam, Tahoua Region, Niger",
  "Dublin, Virginia, United States",
  "NG2 1AA, Nottingham, England, United Kingdom",
  "90250, Oulu, North Ostrobothnia, Finland",
  "3081 AA, Rotterdam, South Holland, Netherlands",
  "H34G1G6, Qulan, Jambyl Region, Kazakhstan",
  "628408, Surgut, Khanty-Mansi, Russia",
  "Treasure County, Montana, United States",
  "Huntingdon, Pennsylvania, United States",
  "4002, Debrecen, Hajdú-Bihar, Hungary",
  "46500, Kahramanmaraş, Kahraman Maras, Türkiye",
  "AB55 5AN, Keith, Scotland, United Kingdom",
  "Frontera Comalapa, Chiapas, Mexico",
  "Adair County, Oklahoma, United States",
  "2126, Cherrybrook, New South Wales, Australia",
  "Bedford, Indiana, United States",
  "Dunajská Streda, Trnava Region, Slovakia",
  "Värnamo kommun, Jönköping County, Sweden",
  "Mcleod, Mississippi, United States",
  "Embilipitiya, Sabaragamuwa Province, Sri Lanka",
  "44388, Dortmund, North Rhine-Westphalia, Germany",
  "McDonald Park, Boynton Beach, Florida, United States",
  "Lewis County, Kentucky, United States",
  "692519, Ussuriysk, Primorye, Russia",
  "E25C2F7, Maqat, Atyrau Region, Kazakhstan",
  "Williams, California, United States",
  "50401, Mason City, Iowa, United States",
  "Sweetwater Villas, Plantation, Florida, United States",
  "Davies Industrial West, Edmonton, Alberta, Canada",
  "Richmond County, New York, United States",
  "53922, Burnett, Wisconsin, United States",
  "Parker Dam, California, United States",
  "90530, Oulu, North Ostrobothnia, Finland",
  "Orleans Parish County, Louisiana, United States",
  "9028, Győr, Győr-Moson-Sopron, Hungary",
  "Beringen, Schaffhausen, Switzerland",
  "Greater Iskenderun",
  "13001, Marseille, Provence-Alpes-Côte d'Azur, France",
  "Blue Bell, Pennsylvania, United States",
  "Virginia, United States",
  "7623, Pécs, Baranya, Hungary",
  "32960, Ourense, Galicia, Spain",
  "Haugesund, Rogaland, Norway",
  "694051, Dolinsk, Sakhalin, Russia",
  "Sion, Valais, Switzerland",
  "678761, Ugol’naya Zyryanka, Sakha, Russia",
  "Avery, Texas, United States",
  "Brăila, Brăila, Romania",
  "69352, Lyman, Nebraska, United States",
  "628183, Nyagan’, Khanty-Mansi, Russia",
  "Cottonwood, Irving, Texas, United States",
  "Campos dos Goytacazes, Rio de Janeiro, Brazil",
  "353466, Gelendzhik, Krasnodar, Russia",
  "Kit Carson County, Colorado, United States",
  "3166, Hughesdale, Victoria, Australia",
  "9500-786, Ponta Delgada, Portugal",
  "8020, Graz, Styria, Austria",
  "Vitebsk City Council, Vitsyebsk, Belarus",
  "Wausau-Stevens Point Area",
  "357560, Pyatigorsk, Stavropol, Russia",
  "Shushufindi Canton, Sucumbíos, Ecuador",
  "462426, Orsk, Orenburg, Russia",
  "62912, Buncombe, Illinois, United States",
  "694062, Dolinsk, Sakhalin, Russia",
  "44100, Kaunas, Kauno, Lithuania",
  "9616, Hammerfest, Troms og Finnmark, Norway",
  "Rio Grande do Sul, Brazil",
  "Dreuil-lès-Amiens, Hauts-de-France, France",
  "Garfield, Kansas, United States",
  "El Presidio, Tucson, Arizona, United States",
  "09127, Cagliari, Sardinia, Italy",
  "413111, Engels, Saratov, Russia",
  "8900, Nova Zagora, Sliven, Bulgaria",
  "429572, Mariinskiy Posad, Chuvashia, Russia",
  "Graham, North Carolina, United States",
  "Ménard, Biran, Occitanie, France",
  "Kotovskiy, Volgograd, Russia",
  "2601, Lillehammer, Innlandet, Norway",
  "502294, Sangareddi, Telangana, India",
  "Oktyabrskoe, Kyrgyzstan",
  "Crookhill, Ryton, England, United Kingdom",
  "Deurne, Antwerp, Flemish Region, Belgium",
  "Uster, Zurich, Switzerland",
  "Asharat Sudair, Riyadh, Saudi Arabia",
  "99223, Spokane, Washington, United States",
  "665103, Nizhneudinsk, Irkutsk, Russia",
  "Thomas, West Virginia, United States",
  "Brooks, Kentucky, United States",
  "95419, Camp Meeker, California, United States",
  "Mosson, Bourgogne-Franche-Comté, France",
  "602 18, Norrköping, Östergötland County, Sweden",
  "Oliver County, North Dakota, United States",
  "Hilliard, Ohio, United States",
  "626157, Tobolsk, Tyumen’, Russia",
  "Ferry, Alaska, United States",
  "Villeneuve-St.-Georges, Île-de-France, France",
  "Newcastle, England, United Kingdom",
  "Otero de Bodas, Castilla and Leon, Spain",
  "Saunders Park, Philadelphia, Pennsylvania, United States",
  "07960, Morristown, New Jersey, United States",
  "2821, Gjøvik, Innlandet, Norway",
  "Sangar, Ghazni Province, Afghanistan",
  "M28K2D4, Temirtau, Karaganda Region, Kazakhstan",
  "F39B4X6, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Mount Wilson, California, United States",
  "Odintsovo, Moscow, Russia",
  "673461, Chernyshevsk, Zabaykalsky Krai, Russia",
  "Tartus District, Tartus Governorate, Syria",
  "456800, Verkhniy Ufaley, Chelyabinsk, Russia",
  "Hattiesburg, Mississippi, United States",
  "Howard County, Indiana, United States",
  "48636, Luzerne, Michigan, United States",
  "632332, Barabinsk, Novosibirsk, Russia",
  "Beaver, Ohio, United States",
  "7710, Steinkjer, Trøndelag, Norway",
  "Court-Saint-Étienne, Walloon Region, Belgium",
  "80140, Joensuu, North Karelia, Finland",
  "15-349, Białystok, Podlaskie, Poland",
  "Delaware County, Indiana, United States",
  "M52D3E7, Osakarovka, Karaganda Region, Kazakhstan",
  "8501, Kukës, Kukës, Albania",
  "DeKalb, Illinois, United States",
  "82-303, Elbląg, Warmińsko-mazurskie, Poland",
  "Prairie, Missouri, United States",
  "3040, Essendon, Victoria, Australia",
  "Engelschoff, Lower Saxony, Germany",
  "Oviedo, Pedernales, Dominican Republic",
  "7000, Eisenstadt, Burgenland, Austria",
  "628484, Kogalym, Khanty-Mansi, Russia",
  "43023, Granville, Ohio, United States",
  "Tapolca, Veszprém, Hungary",
  "North America",
  "San Pedro Sochiapam, Oaxaca, Mexico",
  "Ulyanovsk Metropolitan Area",
  "Barronstown, County Louth, Ireland",
  "Acquaviva, San Marino",
  "Hitchcock, Santa Barbara, California, United States",
  "38004, Atoka, Tennessee, United States",
  "Hope Mills, North Carolina, United States",
  "Leninskiy, Brest, Belarus",
  "Nakhimov District, Sevastopol, Sevastopol, Ukraine",
  "Troyes, Grand Est, France",
  "80123, Naples, Campania, Italy",
  "Hubbard, Oregon, United States",
  "Greater Vancouver Metropolitan Area",
  "Safranbolu Bucağı, Karabuk, Türkiye",
  "Parkersburg, West Virginia, United States",
  "Taksimo, Buryatia, Russia",
  "33716, St Petersburg, Florida, United States",
  "67504, Hutchinson, Kansas, United States",
  "Hawkins Ridge, South Carolina, United States",
  "C25D4P2, Makinsk, Akmola Region, Kazakhstan",
  "54946, Waupaca, Wisconsin, United States",
  "236023, Kaliningrad, Kaliningrad, Russia",
  "Tekirdağ, Tekirdağ, Türkiye",
  "59806, Missoula, Montana, United States",
  "20720, Bowie, Maryland, United States",
  "810009, Brăila, Brăila, Romania",
  "West Rutland, Vermont, United States",
  "Tours-en-Vimeu, Hauts-de-France, France",
  "Portage, Wisconsin, United States",
  "Olmsted County, Minnesota, United States",
  "Fairfield, Ohio, United States",
  "82009, Cheyenne, Wyoming, United States",
  "2482, Tran, Pernik, Bulgaria",
  "18013, Granada, Andalusia, Spain",
  "689400, Pevek, Chukot, Russia",
  "Yerköy, Yozgat, Türkiye",
  "L10G4C2, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Saline, Michigan, United States",
  "Bryson Ridge, Gilmer, North Carolina, United States",
  "Lexington, South Carolina, United States",
  "Glasgow, Kentucky, United States",
  "225730, Pinsk, Brest, Belarus",
  "Trenton, Missouri, United States",
  "Gelendost, Isparta, Türkiye",
  "76133, Fort Worth, Texas, United States",
  "Graham, Alabama, United States",
  "Dawson County, Texas, United States",
  "06126, Perugia, Umbria, Italy",
  "Hampton, Tennessee, United States",
  "Kearneysbay, County Kilkenny, Ireland",
  "Danville, West Virginia, United States",
  "58000, Nevers, Bourgogne-Franche-Comté, France",
  "Khmelnytskyi Metropolitan Area",
  "40000, Sumy, Sumy, Ukraine",
  "412800, Krasnoarmeysk, Saratov, Russia",
  "56283, Redwood Falls, Minnesota, United States",
  "24800, Erzincan, Erzincan, Türkiye",
  "658974, Kulunda, Altai Krai, Russia",
  "36528, Dauphin Island, Alabama, United States",
  "Eureka, Missouri, United States",
  "Biberist, Solothurn, Switzerland",
  "Zelenogorsk, Krasnoyarsk Krai, Russia",
  "V92 PC98, Tralee, County Kerry, Ireland",
  "77000, Melun, Île-de-France, France",
  "663286, Severo-Yeniseyskiy, Krasnoyarsk Krai, Russia",
  "McKenzie, Alabama, United States",
  "Badajoz, Extremadura, Spain",
  "LV-5401, Daugavpils, Daugavpils, Latvia",
  "658921, Kulunda, Altai Krai, Russia",
  "Cameron, Arizona, United States",
  "Dolna Mitropoliya Municipality, Pleven, Bulgaria",
  "357565, Pyatigorsk, Stavropol, Russia",
  "Waupaca County, Wisconsin, United States",
  "Edremit, Balıkesir, Türkiye",
  "Peckville, Pennsylvania, United States",
  "352504, Labinsk, Krasnodar, Russia",
  "Saarde vald, Pärnu maakond, Estonia",
  "Kayan, Yangon Region, Myanmar",
  "Broadwaters, Kidderminster, England, United Kingdom",
  "623101, Pervoural'sk, Sverdlovsk, Russia",
  "Wetzelsberg, Fischbachau, Bavaria, Germany",
  "Krasnogorskiy, Mari El, Russia",
  "31023, Kohtla-Järve, Ida-Virumaa, Estonia",
  "81-472, Gdynia, Pomorskie, Poland",
  "BT9 5AA, Belfast, Northern Ireland, United Kingdom",
  "1070-076, Lisbon, Lisbon, Portugal",
  "41-901, Bytom, Śląskie, Poland",
  "Olmsted Falls, Ohio, United States",
  "551, Sauðárkrókur, Northwestern Region, Iceland",
  "Pershing Manor, Florida, United States",
  "Franklin County, Virginia, United States",
  "Mount Pearl, Newfoundland and Labrador, Canada",
  "Kovel’, Volyn, Ukraine",
  "652311, Topki, Kemerovo, Russia",
  "Haskell, Texas, United States",
  "352800, Tuapse, Krasnodar, Russia",
  "Nevada, Iowa, United States",
  "30816, Lorca, Región de Murcia, Spain",
  "Eckernförde, Schleswig-Holstein, Germany",
  "624943, Karpinsk, Sverdlovsk, Russia",
  "Ralls, Texas, United States",
  "Winterthur, Zurich, Switzerland",
  "Cibola County, New Mexico, United States",
  "Murray Hill, New York, New York, United States",
  "Bertièrie, Parigné, Brittany, France",
  "Gilmer, Montenay, Pays de la Loire, France",
  "McLean, Illinois, United States",
  "400560, Cluj-Napoca, Cluj, Romania",
  "40830, Yancey, Kentucky, United States",
  "Powderly, Kentucky, United States",
  "309966, Valuyki, Belgorod, Russia",
  "New Castle, Delaware, United States",
  "Church Crookham, Fleet, England, United Kingdom",
  "V92 K7FD, Tralee, County Kerry, Ireland",
  "Braga, Braga, Portugal",
  "8031 XM, Zwolle, Overijssel, Netherlands",
  "238178, Chernyakhovskiy, Kaliningrad, Russia",
  "Bragança, Pará, Brazil",
  "Weber, Iowa, United States",
  "RG6 1AA, Reading, England, United Kingdom",
  "Caledonia, Illinois, United States",
  "Guadalupe, Nuevo León, Mexico",
  "6401, Përmet, Gjirokastër, Albania",
  "20810, Turku, Southwest Finland, Finland",
  "Los Llanos de Aridane, Canary Islands, Spain",
  "Donauwörth, Bavaria, Germany",
  "Weber-Haus, Melle, Lower Saxony, Germany",
  "IV3 8TS, Inverness, Scotland, United Kingdom",
  "Geauga County, Ohio, United States",
  "Stanley, Wisconsin, United States",
  "629761, Nadym, Yamalo-Nenets, Russia",
  "57103, Sioux Falls, South Dakota, United States",
  "03012, Alicante, Valencian Community, Spain",
  "58003, Chernivtsi, Chernivtsi, Ukraine",
  "Kimball Place, Kannapolis, North Carolina, United States",
  "Atka, Magadan, Russia",
  "Eddyville, Kentucky, United States",
  "Yankton, South Dakota, United States",
  "IP33 2PY, Bury St Edmunds, England, United Kingdom",
  "Hancock County, Indiana, United States",
  "Livingston Manor, New York, United States",
  "Volokonovka, Belgorod, Russia",
  "3004, Melbourne, Victoria, Australia",
  "15896, Santiago de Compostela, Galicia, Spain",
  "543 51, Thessaloniki, Central Macedonia, Greece",
  "Santa Clara County, California, United States",
  "South Cayuga, Haldimand County, Ontario, Canada",
  "Tioga, Kembu, Papua, Indonesia",
  "24506, Lynchburg, Virginia, United States",
  "South Perth, Western Australia, Australia",
  "11003, Cadiz, Andalusia, Spain",
  "731210, Bârlad, Vaslui, Romania",
  "Lyon County, Nevada, United States",
  "Doora, Mysore, Karnataka, India",
  "12144, Rensselaer, New York, United States",
  "Johnson County, Missouri, United States",
  "Kingfisher, North Okanagan F, British Columbia, Canada",
  "Oxfordshire, England, United Kingdom",
  "Woodstock, Connecticut, United States",
  "805300, Tecuci, Galaţi, Romania",
  "Sulakyurt, Kırıkkale, Türkiye",
  "222511, Barysaw, Minsk, Belarus",
  "Ashenhurst, Huddersfield, England, United Kingdom",
  "Satu Mare, Satu Mare, Romania",
  "Ellis County, Oklahoma, United States",
  "Kearneystown, County Louth, Ireland",
  "Greater Tarragona Area",
  "Wells, Minnesota, United States",
  "Razgrad, Razgrad, Bulgaria",
  "3103, Salgótarján, Nógrád, Hungary",
  "Uluborlu, Isparta, Türkiye",
  "Sioux, Missouri, United States",
  "Tambov Metropolitan Area",
  "48706, Muğla, Muğla, Türkiye",
  "222516, Barysaw, Minsk, Belarus",
  "Rockingham, Halifax, Nova Scotia, Canada",
  "X38G5P9, Kentau, Turkistan Region, Kazakhstan",
  "McKinley, Long Beach, California, United States",
  "Kolomyya, Ivano-Frankivsk, Ukraine",
  "Rivne Raion, Rivne, Ukraine",
  "Forest Lake, Queensland, Australia",
  "Essen, Lower Saxony, Germany",
  "Woodwards, Richmond, British Columbia, Canada",
  "641440, Kurtamysh, Kurgan, Russia",
  "Aşağı Nazilli, Aydın, Türkiye",
  "725 91, Västerås, Västmanland County, Sweden",
  "Bryan County, Oklahoma, United States",
  "Brazos, Boiro, Galicia, Spain",
  "800643, Galaţi, Galaţi, Romania",
  "Laurens, Miramont-d’Astarac, Occitanie, France",
  "2811, Gjøvik, Innlandet, Norway",
  "Alaplı, Zonguldak, Türkiye",
  "Tarrant City, Alabama, United States",
  "06002, Badajoz, Extremadura, Spain",
  "Tuckerman, Arkansas, United States",
  "Jackson, Michigan, United States",
  "Aginskoye, Krasnoyarsk Krai, Russia",
  "Catawba Village, South Carolina, United States",
  "Tarfaya Province, Laâyoune-Sakia El Hamra, Morocco",
  "624782, Verkhnyaya Salda, Sverdlovsk, Russia",
  "B3H 1Z9, Halifax, Nova Scotia, Canada",
  "78759, Austin, Texas, United States",
  "139, Chenango, New York, United States",
  "457024, Plast, Chelyabinsk, Russia",
  "22803, Harrisonburg, Virginia, United States",
  "Belmont, California, United States",
  "Marinette, Halifax, Nova Scotia, Canada",
  "Ward County, North Dakota, United States",
  "Thurston Park, Whitstable, England, United Kingdom",
  "60030, Tokat, Tokat, Türkiye",
  "Montgomery, Alabama, United States",
  "71286, Transylvania, Louisiana, United States",
  "Lenoir City, Tennessee, United States",
  "Carbonia, Sardinia, Italy",
  "96280-000, Mostardas, Rio Grande do Sul, Brazil",
  "71008, Viljandi, Viljandimaa, Estonia",
  "Sanborn, New York, United States",
  "La Cour Duchesne, Saint-Ouen-le-Houx, Normandy, France",
  "02155, Albacete, Castile-La Mancha, Spain",
  "Lincoln, Massachusetts, United States",
  "2617, Lillehammer, Innlandet, Norway",
  "413856, Balakovo, Saratov, Russia",
  "16360, Bursa, Bursa, Türkiye",
  "Cracow, Małopolskie, Poland",
  "Sioux Lookout, Ontario, Canada",
  "81248, Sargents, Colorado, United States",
  "53085, Sheboygan Falls, Wisconsin, United States",
  "Franklin County, Ohio, United States",
  "Weld County, Colorado, United States",
  "44-117, Gliwice, Śląskie, Poland",
  "35-119, Rzeszów, Podkarpackie, Poland",
  "Michurinskiy, Chelyabinsk, Russia",
  "Cherokee County, South Carolina, United States",
  "1012 AA, Amsterdam, North Holland, Netherlands",
  "5600, Békéscsaba, Békés, Hungary",
  "Fort-de-France, Martinique",
  "Lincoln County, Nebraska, United States",
  "9013, Lushnjë, Fier, Albania",
  "Slyudyanka, Irkutsk, Russia",
  "Missouri City, Texas, United States",
  "Bennett, North Carolina, United States",
  "Archer, Nebraska, United States",
  "IP8 3SG, Ipswich, England, United Kingdom",
  "61166, Kharkiv, Kharkiv, Ukraine",
  "Campania, Italy",
  "Trento, Trentino-Alto Adige, Italy",
  "G4 0AA, Glasgow, Scotland, United Kingdom",
  "Andorra, Aragon, Spain",
  "628145, Saranpaul’, Khanty-Mansi, Russia",
  "Uzınağaş, Almaty Region, Kazakhstan",
  "Hood County, Texas, United States",
  "6722, Szeged, Csongrád, Hungary",
  "Mingo, West Virginia, United States",
  "Silver Springs, Florida, United States",
  "8320, Svolvær, Nordland, Norway",
  "70-416, Szczecin, Zachodniopomorskie, Poland",
  "Mamaivtsi, Chernivtsi, Ukraine",
  "NR25 6NY, West Beckham, England, United Kingdom",
  "831 61, Östersund, Jämtland County, Sweden",
  "C19B8T2, Zhaltyr, Akmola Region, Kazakhstan",
  "C25C1E7, Makinsk, Akmola Region, Kazakhstan",
  "7043, Trondheim, Trøndelag, Norway",
  "Mount Wise, Plymouth, England, United Kingdom",
  "05720, Amasya, Amasya, Türkiye",
  "5605, Békéscsaba, Békés, Hungary",
  "Sochi, Niigata, Japan",
  "Stanton County, Kansas, United States",
  "26127, Oldenburg, Lower Saxony, Germany",
  "47025, Zholoby, Ternopil, Ukraine",
  "152334, Borisogleb, Yaroslavl’, Russia",
  "Saltillo, Coahuila, Mexico",
  "Ust’-Kyakhta, Buryatia, Russia",
  "6702, Bellinzona, Ticino, Switzerland",
  "10473, Bronx, New York, United States",
  "678946, Aldan, Sakha, Russia",
  "423570, Nizhnekamsk, Tatarstan, Russia",
  "CA1 1AA, Carlisle, England, United Kingdom",
  "542 73, Mariestad, Västra Götaland County, Sweden",
  "617050, Krasnokamsk, Perm, Russia",
  "29804, Aiken, South Carolina, United States",
  "NE4 6ST, Newcastle Upon Tyne, England, United Kingdom",
  "Aleksinac, Centralna Srbija, Serbia",
  "Serravalle, San Marino",
  "658220, Rubtsovsk, Altai Krai, Russia",
  "6970, Beaufort West, Western Cape, South Africa",
  "Kocasinan, Kayseri, Türkiye",
  "141364, Sergiyev Posad, Moscow, Russia",
  "La Marinetterie, Irai, Normandy, France",
  "Urad Middle Banner, Inner Mongolia, China",
  "Barboursville, Virginia, United States",
  "Severoural’sk, Sverdlovsk, Russia",
  "Yadkin Valley, North Carolina, United States",
  "51000, Rijeka, Primorje-Gorski, Croatia",
  "346535, Shakhty, Rostov, Russia",
  "Dent, Ohio, United States",
  "M1V 0A2, Scarborough, Ontario, Canada",
  "2807, Tatabánya, Komárom-Esztergom, Hungary",
  "Clarion Township, Pennsylvania, United States",
  "Perevalsk Raion, Luhansk, Ukraine",
  "12531, Holmes, New York, United States",
  "29445, Goose Creek, South Carolina, United States",
  "34111, Kragujevac, Centralna Srbija, Serbia",
  "81641, Meeker, Colorado, United States",
  "R25A3K7, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "Frio County, Texas, United States",
  "Box Elder, South Dakota, United States",
  "40700, Jyväskylä, Central Finland, Finland",
  "394036, Voronezh, Voronezh, Russia",
  "45694, Wheelersburg, Ohio, United States",
  "Jönköping, Jönköping County, Sweden",
  "420111, Kazan, Tatarstan, Russia",
  "Arrondissement of Liège, Walloon Region, Belgium",
  "94250, Gentilly, Île-de-France, France",
  "13005, Marseille, Provence-Alpes-Côte d'Azur, France",
  "28601, Hickory, North Carolina, United States",
  "29153, Sumter, South Carolina, United States",
  "22601, Winchester, Virginia, United States",
  "Devarapalle, Andhra Pradesh, India",
  "214 25, Malmo, Skåne County, Sweden",
  "Hubbard County, Minnesota, United States",
  "Fushë-Arrëz, Shkodër, Albania",
  "Bologna, Emilia-Romagna, Italy",
  "Upton, Widnes, England, United Kingdom",
  "169901, Vorkuta, Komi, Russia",
  "North Merrick, New York, United States",
  "Hawkins Estate Extension 1, Johannesburg, Gauteng, South Africa",
  "2595 XJ, The Hague, South Holland, Netherlands",
  "12533, Hopewell Junction, New York, United States",
  "Cherokee County, Georgia, United States",
  "Bartonsville, Pennsylvania, United States",
  "Niagara University, New York, United States",
  "02825, South Foster, Rhode Island, United States",
  "Park Lann Corson, Coadout, Brittany, France",
  "Christiansburg, Virginia, United States",
  "Jardim Judith, Sorocaba, São Paulo, Brazil",
  "238316, Gur'yevsk, Kaliningrad, Russia",
  "Neu-Ulm, Bavaria, Germany",
  "Zagreb, Croatia",
  "Kurskaya, Stavropol, Russia",
  "Brooke County, West Virginia, United States",
  "Cortland Woods at Providence, Florida, United States",
  "Novo Hamburgo, Rio Grande do Sul, Brazil",
  "13460, Saintes-Maries-de-la-Mer, Provence-Alpes-Côte d'Azur, France",
  "921 01, Piešťany, Trnava Region, Slovakia",
  "Fermanagh And Omagh, Northern Ireland, United Kingdom",
  "68230, Kokkola, Central Ostrobothnia, Finland",
  "Noblessa, Plounéour-Trez, Brittany, France",
  "Wayne, Nebraska, United States",
  "Schuylkill Haven, Pennsylvania, United States",
  "659322, Biysk, Altai Krai, Russia",
  "Sterlitamaksky District, Bashkortostan, Russia",
  "La Gruyere, Fribourg, Switzerland",
  "44230-959, Amélia Rodrigues, Bahia, Brazil",
  "Finnsnes, Troms og Finnmark, Norway",
  "45771, Racine, Ohio, United States",
  "Fier, Albania",
  "22103, McLean, Virginia, United States",
  "Sandvikens kommun, Gävleborg County, Sweden",
  "20142, Milan, Lombardy, Italy",
  "6008, Kecskemét, Bács-Kiskun, Hungary",
  "91210, Klaipėda, Klaipėdos, Lithuania",
  "48328, Waterford, Michigan, United States",
  "Hunt County, Texas, United States",
  "Ruskin Heights, Washington, Missouri, United States",
  "Alekseyevka, Chelyabinsk, Russia",
  "452757, Tuymazy, Bashkortostan, Russia",
  "665821, Angarsk, Irkutsk, Russia",
  "24014, Roanoke, Virginia, United States",
  "Cobblestone, Gentry, Arkansas, United States",
  "Gonzales County, Texas, United States",
  "Ransom, Kansas, United States",
  "6200-095, Covilhã, Castelo Branco, Portugal",
  "Azerbaijan",
  "La Cense des Nobles, Landouzy-la-Ville, Hauts-de-France, France",
  "164847, Onega, Arkhangel’sk, Russia",
  "Crowley, Louisiana, United States",
  "Hopewell Hill, Bristol, England, United Kingdom",
  "Mineral de la Reforma, Hidalgo, Mexico",
  "344092, Rostov, Rostov, Russia",
  "Osmaneli, Bilecik, Türkiye",
  "Dawson County, Nebraska, United States",
  "Mohmand District, Khyber Pakhtunkhwa, Pakistan",
  "Llano, Texas, United States",
  "07026, Garfield, New Jersey, United States",
  "Broome Manor, Swindon, England, United Kingdom",
  "169902, Vorkuta, Komi, Russia",
  "Decatur County, Iowa, United States",
  "Renville, Minnesota, United States",
  "22909, Charlottesville, Virginia, United States",
  "423586, Nizhnekamsk, Tatarstan, Russia",
  "Temirtau, Kemerovo, Russia",
  "Makarovo, Irkutsk, Russia",
  "610227, Piatra Neamţ, Neamţ, Romania",
  "Ploče, Dubrovnik-Neretva, Croatia",
  "413851, Balakovo, Saratov, Russia",
  "Loudon, New Hampshire, United States",
  "90230, Oulu, North Ostrobothnia, Finland",
  "Buguruslan, Orenburg, Russia",
  "Harris County, Georgia, United States",
  "00250, Helsinki, Uusimaa, Finland",
  "Naples, Florida, United States",
  "Korsakovsky District, Sakhalin, Russia",
  "33270, Tampere, Pirkanmaa, Finland",
  "629805, Noyabrsk, Yamalo-Nenets, Russia",
  "6000, Charleroi, Walloon Region, Belgium",
  "Eaubonne, Île-de-France, France",
  "175203, Staraya Russa, Novgorod, Russia",
  "77573, League City, Texas, United States",
  "305040, Kursk, Kursk, Russia",
  "34601, Brooksville, Florida, United States",
  "172008, Torzhok, Tver’, Russia",
  "641203, Yurgamysh, Kurgan, Russia",
  "G12 0AA, Glasgow, Scotland, United Kingdom",
  "68417, Otoe, Nebraska, United States",
  "Örebro kommun, Örebro County, Sweden",
  "Crawfordsville, Indiana, United States",
  "East Parke, Callaway, Florida, United States",
  "4246, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "Gubkino, Orel, Russia",
  "Svobodnyy, Chelyabinsk, Russia",
  "14735, Fillmore, New York, United States",
  "Tungiro-Olyokminsky District, Zabaykalsky Krai, Russia",
  "173007, Velikiy Novgorod, Novgorod, Russia",
  "HU9 4ET, Kingston Upon Hull, England, United Kingdom",
  "654038, Novokuznetsk, Kemerovo, Russia",
  "75-306, Koszalin, Zachodniopomorskie, Poland",
  "Velikaya Pisarevka, Sumy, Ukraine",
  "301 00, Agrínio, Western Greece, Greece",
  "427626, Glazov, Udmurtia, Russia",
  "69008, Lyon, Auvergne-Rhône-Alpes, France",
  "45742, Little Hocking, Ohio, United States",
  "Mons, Queensland, Australia",
  "97135, Pacific City, Oregon, United States",
  "Medina, New York, United States",
  "08890, Artvin, Artvin, Türkiye",
  "KA18 2RT, Ochiltree, Scotland, United Kingdom",
  "68305, Mannheim, Baden-Württemberg, Germany",
  "MD-2044, Chişinău, Chișinău, Moldova",
  "Miller, South Dakota, United States",
  "Minnehaha, St Louis Park, Minnesota, United States",
  "32400, Isparta, Isparta, Türkiye",
  "Borisoglebskoye, Yaroslavl’, Russia",
  "Kirovskoye, Saratov, Russia",
  "3501, Peqin, Elbasan, Albania",
  "605100, Buhuşi, Bacău, Romania",
  "Lillestrøm, Viken, Norway",
  "Inowrocławski, Kujawsko-pomorskie, Poland",
  "Coal Aston, Dronfield, England, United Kingdom",
  "NN4 4EP, Northampton, England, United Kingdom",
  "412411, Atkarsk, Saratov, Russia",
  "172389, Rzhev, Tver’, Russia",
  "Balygychan, Magadan, Russia",
  "75792, Winona, Texas, United States",
  "05832, East Burke, Vermont, United States",
  "Bandera, Maheshwar, Madhya Pradesh, India",
  "Östersund, Jämtland County, Sweden",
  "95122, Catania, Sicily, Italy",
  "Popeswood, Bracknell, England, United Kingdom",
  "60125, Ancona, Marches, Italy",
  "79562, Tuscola, Texas, United States",
  "Pendurthi, Andhra Pradesh, India",
  "120187, Buzău, Buzău, Romania",
  "2790013, Urayasu, Chiba, Japan",
  "Dinwiddie Extension 4, Brakpan, Gauteng, South Africa",
  "Amsterdam-Centrum, North Holland, Netherlands",
  "Region of Southern Denmark, Denmark",
  "Kalmar County, Sweden",
  "PH33 7NN, Fort William, Scotland, United Kingdom",
  "Exeter, Ontario, Canada",
  "5532, Haugesund, Rogaland, Norway",
  "Clearwater, Nebraska, United States",
  "427797, Mozhga, Udmurtia, Russia",
  "Juniata, Philadelphia, Pennsylvania, United States",
  "17272, Waynesboro, Pennsylvania, United States",
  "164505, Severodvinsk, Arkhangel’sk, Russia",
  "06195, Badajoz, Extremadura, Spain",
  "21055, Narva, Ida-Virumaa, Estonia",
  "682030, Chegdomyn, Khabarovsk, Russia",
  "Highland County, Ohio, United States",
  "585225, Chitapur, Karnataka, India",
  "01930, Gloucester, Massachusetts, United States",
  "678230, Verkhnevilyuysk, Sakha, Russia",
  "Burnett Acres, Cocoa West, Florida, United States",
  "164500, Severodvinsk, Arkhangel’sk, Russia",
  "309965, Valuyki, Belgorod, Russia",
  "68259, Mannheim, Baden-Württemberg, Germany",
  "Patras, Western Greece, Greece",
  "87571, Taos, New Mexico, United States",
  "Tyler, Texas, United States",
  "97076, Würzburg, Bavaria, Germany",
  "Polvorín, Puerto Cabello, Carabobo State, Venezuela",
  "BH1 1PS, Bournemouth, England, United Kingdom",
  "Veyselkarani, Siirt, Türkiye",
  "14111, North Collins, New York, United States",
  "Thailand",
  "Ust-Kutsky District, Irkutsk, Russia",
  "30327, Kohtla-Järve, Ida-Virumaa, Estonia",
  "357701, Kislovodsk, Stavropol, Russia",
  "East Beckham, England, United Kingdom",
  "34134, Trieste, Friuli-Venezia Giulia, Italy",
  "Tyumen’, Russia",
  "78873, Leakey, Texas, United States",
  "Le Roseau, Chavagnes, Pays de la Loire, France",
  "Venice, Florida, United States",
  "404118, Volzhskiy, Volgograd, Russia",
  "Bismarck, North Dakota, United States",
  "Northampton, New Brunswick, Canada",
  "Oconto County, Wisconsin, United States",
  "Te Puke, Bay of Plenty, New Zealand",
  "35013, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Lake Louisa Park, Florida, United States",
  "66125, Saarbrücken, Saarland, Germany",
  "ZE1 0SA, Lerwick, Scotland, United Kingdom",
  "Buckingham, Quebec, Canada",
  "454091, Chelyabinsk, Chelyabinsk, Russia",
  "Richmond, California, United States",
  "Trinity Ville, Saint Thomas, Jamaica",
  "Fremont, New Hampshire, United States",
  "Fentress County, Tennessee, United States",
  "Calumet, Illinois, United States",
  "Barra de São Miguel, Paraíba, Brazil",
  "Churchdown, England, United Kingdom",
  "629309, Novy Urengoy, Yamalo-Nenets, Russia",
  "Allende, Nuevo León, Mexico",
  "8989, Kotel, Sliven, Bulgaria",
  "07401, Allendale, New Jersey, United States",
  "Jasper County, Iowa, United States",
  "97070, Würzburg, Bavaria, Germany",
  "185026, Petrozavodsk, Karelia, Russia",
  "Sheboygan, Wisconsin, United States",
  "Lugait, Northern Mindanao, Philippines",
  "Hopa, Artvin, Türkiye",
  "38003, Santa Cruz de Tenerife, Canary Islands, Spain",
  "Kyiv Metropolitan Area",
  "LS12 1AA, Leeds, England, United Kingdom",
  "754 37, Uppsala, Uppsala County, Sweden",
  "641206, Yurgamysh, Kurgan, Russia",
  "Tatar-Ulkanovskiy, Bashkortostan, Russia",
  "Columbus County, North Carolina, United States",
  "McCormick Place, Fort Wayne, Indiana, United States",
  "21108, Millersville, Maryland, United States",
  "1015 AA, Amsterdam, North Holland, Netherlands",
  "Ust'-Aleyskiy, Altai Krai, Russia",
  "36205, Vigo, Galicia, Spain",
  "Lyon, Michigan, United States",
  "Novara di Sicilia, Sicily, Italy",
  "Hardin, Iowa, United States",
  "Sarqan, Jetisu Region, Kazakhstan",
  "Barryville, New York, United States",
  "Blair, South Carolina, United States",
  "236029, Kaliningrad, Kaliningrad, Russia",
  "185000, Petrozavodsk, Karelia, Russia",
  "680025, Michurinskoye, Khabarovsk, Russia",
  "C25D1A7, Makinsk, Akmola Region, Kazakhstan",
  "Exeter, New Hampshire, United States",
  "73068, Noble, Oklahoma, United States",
  "Petit-Canal, Pointe-à-Pitre, Guadeloupe",
  "357528, Pyatigorsk, Stavropol, Russia",
  "Rrethinat, Shkodër, Albania",
  "Metro Jacksonville",
  "624472, Severoural’sk, Sverdlovsk, Russia",
  "L-6776, Grevenmacher, Grevenmacher, Luxembourg",
  "Saint-Laurent-du-Maroni, Saint-Laurent-du-Maroni, French Guiana",
  "641225, Yurgamysh, Kurgan, Russia",
  "Livingston County, Michigan, United States",
  "352 47, Växjö, Kronoberg County, Sweden",
  "Uintah County, Utah, United States",
  "Nantes, Quebec, Canada",
  "627750, Ishim, Tyumen’, Russia",
  "Multnomah, Portland, Oregon, United States",
  "Vreshtas, Korçë, Albania",
  "2807, Gjøvik, Innlandet, Norway",
  "185014, Petrozavodsk, Karelia, Russia",
  "Sinop, Mato Grosso, Brazil",
  "70400, Karaman, Karaman, Türkiye",
  "Tlanalapa, Hidalgo, Mexico",
  "Mount Sequoyah South, Fayetteville, Arkansas, United States",
  "Piešťany, Trnava Region, Slovakia",
  "8306, Peshkopi, Dibrë, Albania",
  "Texas, United States",
  "Jimeta, Adamawa State, Nigeria",
  "36776, Sawyerville, Alabama, United States",
  "98908, Yakima, Washington, United States",
  "F42G1M6, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "Bernau, Brandenburg, Germany",
  "M28F2C8, Temirtau, Karaganda Region, Kazakhstan",
  "33442, Deerfield Beach, Florida, United States",
  "Campbell County, Wyoming, United States",
  "85-044, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "Langley Burrell, England, United Kingdom",
  "Ziyaret, Amasya, Türkiye",
  "Upton, Massachusetts, United States",
  "Rrajcë, Elbasan, Albania",
  "Obshtina Provadia, Varna, Bulgaria",
  "Bremen, Maine, United States",
  "8281, Hasselt, Overijssel, Netherlands",
  "NN1 1AF, Northampton, England, United Kingdom",
  "Dorneck, Solothurn, Switzerland",
  "216506, Roslavl’, Smolensk, Russia",
  "163009, Archangel, Arkhangel’sk, Russia",
  "Oliver Paipoonge, Ontario, Canada",
  "Michurinskoye, Chelyabinsk, Russia",
  "Morrison, Oklahoma, United States",
  "9423, Harstad, Troms og Finnmark, Norway",
  "58458, Lamoure, North Dakota, United States",
  "55602-000, Vitória de Santo Antão, Pernambuco, Brazil",
  "Gentryville, Indiana, United States",
  "Ruše, Slovenia",
  "Douglas County, Washington, United States",
  "6525, Bellinzona, Ticino, Switzerland",
  "Cheatham County, Tennessee, United States",
  "Kairouan, Tunisia",
  "Brierfield (Toole), County Galway, Ireland",
  "Chatham County, Georgia, United States",
  "53818, Platteville, Wisconsin, United States",
  "Grimesnil, Cherbourg, Normandy, France",
  "E7L 1K6, Divide, New Brunswick, Canada",
  "85-024, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "Kings County, California, United States",
  "412903, Vol'sk, Saratov, Russia",
  "Vance, Biggar 347, Saskatchewan, Canada",
  "Pawnee, Illinois, United States",
  "McHenry County, Illinois, United States",
  "55330, Elk River, Minnesota, United States",
  "Bollingerfähr, Dörpen, Lower Saxony, Germany",
  "249230, Kudrinskaya, Kaluga, Russia",
  "42103, Wuppertal, North Rhine-Westphalia, Germany",
  "Sequoyah Ridge, Grenelefe, Florida, United States",
  "Gallatin County, Illinois, United States",
  "152331, Borisogleb, Yaroslavl’, Russia",
  "Redwood Valley, California, United States",
  "43351, Upper Sandusky, Ohio, United States",
  "PE8 5PP, Barnwell, England, United Kingdom",
  "Swansea, Illinois, United States",
  "428005, Cheboksary, Chuvashia, Russia",
  "Quininde, Esmeraldas, Ecuador",
  "Saline, Missouri, United States",
  "Sullivan County, New York, United States",
  "Beetsterzwaag, Friesland, Netherlands",
  "V92 HX30, Tralee, County Kerry, Ireland",
  "TS9 7AB, Stokesley, England, United Kingdom",
  "623969, Tavda, Sverdlovsk, Russia",
  "172386, Rzhev, Tver’, Russia",
  "Borovsk, Kaluga, Russia",
  "Kaposvári járás, Somogy, Hungary",
  "Morganton, North Carolina, United States",
  "Otter Lake, Michigan, United States",
  "Crookston, Minnesota, United States",
  "06000, Nice, Provence-Alpes-Côte d'Azur, France",
  "Mainz, Rhineland-Palatinate, Germany",
  "974 11, Banská Bystrica, Banska Bystrica, Slovakia",
  "Greene County, Pennsylvania, United States",
  "Loudon, Illinois, United States",
  "Brookeborough, Northern Ireland, United Kingdom",
  "76869, Pontotoc, Texas, United States",
  "Cache Creek, British Columbia, Canada",
  "Greer County, Oklahoma, United States",
  "Greene County, Virginia, United States",
  "Saint-Benoît, Nouvelle-Aquitaine, France",
  "Kerr County, Texas, United States",
  "Forest Highlands, Lake Oswego, Oregon, United States",
  "Wallowa County, Oregon, United States",
  "Burnett County, Wisconsin, United States",
  "Guadalajara De Buga, Valle del Cauca, Colombia",
  "Halton, Ontario, Canada",
  "Dromcollogher, County Limerick, Ireland",
  "112, Reykjavík, Capital Region, Iceland",
  "Oxford, Pennsylvania, United States",
  "300566, Timişoara, Timiş, Romania",
  "Kanash, Chuvashia, Russia",
  "33841, Fort Meade, Florida, United States",
  "71764, Stephens, Arkansas, United States",
  "10143, Tallinn, Harjumaa, Estonia",
  "24277, Pennington Gap, Virginia, United States",
  "84032, Altdorf, Bavaria, Germany",
  "Lagoa, Faro, Portugal",
  "Livberze, Jelgava Municipality, Latvia",
  "8315, Peshkopi, Dibrë, Albania",
  "8935 NC, Leeuwarden, Friesland, Netherlands",
  "94060, Sochiapa, Veracruz, Mexico",
  "Târgovişte, Dîmboviţa, Romania",
  "Cheshire, Connecticut, United States",
  "Braga, Portugal",
  "Lawrence, Michigan, United States",
  "Arzamastsevka, Samara, Russia",
  "Tate County, Mississippi, United States",
  "Slopedown, New Zealand",
  "1700, Sofia, Sofia City, Bulgaria",
  "Cleveland, Queensland, Australia",
  "Linze County, Gansu, China",
  "7000, Bitola, Pelagonia, North Macedonia",
  "Bairin Right Banner, Inner Mongolia, China",
  "Troisvierges, Diekirch, Luxembourg",
  "97603, Klamath Falls, Oregon, United States",
  "353691, Yeysk, Krasnodar, Russia",
  "Aleksandrovsk-Sakhalinskiy, Sakhalin, Russia",
  "33314, Fort Lauderdale, Florida, United States",
  "Billings, Missouri, United States",
  "Tyndall, Manitoba, Canada",
  "La Traillère, La Tessoualle, Pays de la Loire, France",
  "Otero, Voto, Cantabria, Spain",
  "13126, Oswego, New York, United States",
  "Pírgos, Western Greece, Greece",
  "77993, Goliad, Texas, United States",
  "Tvarditsa, Sliven, Bulgaria",
  "Rochester, Minnesota, United States",
  "Burlington, Ontario, Canada",
  "05447, East Berkshire, Vermont, United States",
  "169304, Ukhta, Komi, Russia",
  "93312, Bakersfield, California, United States",
  "65113, Odesa, Odessa, Ukraine",
  "Bordeaux-Saint-Clair, Normandy, France",
  "Somerset, Kentucky, United States",
  "78206, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "Lakeview Summit, Rockwall, Texas, United States",
  "Cardiff, Ontario, Canada",
  "70125, Bari, Apulia, Italy",
  "Karatina, Nyeri, Kenya",
  "San Francisco County, California, United States",
  "Montgomery, Minnesota, United States",
  "613047, Kirovo-Chepetsk, Kirov, Russia",
  "420095, Kazan, Tatarstan, Russia",
  "2600, Dupnitsa Municipality, Kyustendil, Bulgaria",
  "21102, Novi Sad, Vojvodina, Serbia",
  "73744, Hitchcock, Oklahoma, United States",
  "Aarau, Aargau, Switzerland",
  "668216, Aryskan, Tuva, Russia",
  "Teton County, Montana, United States",
  "32970, Ourense, Galicia, Spain",
  "8027, Bodø, Nordland, Norway",
  "North Anson, Maine, United States",
  "S0M 3A0, Waseca, Saskatchewan, Canada",
  "8611, Mo i Rana, Nordland, Norway",
  "397103, Rusanovo, Voronezh, Russia",
  "Presidio, Aquiraz, Ceará, Brazil",
  "Dzerzhinskoye, Leningrad, Russia",
  "70110, Karaman, Karaman, Türkiye",
  "85627, Benson, Arizona, United States",
  "Montgomery County, New York, United States",
  "Maribor, Slovenia",
  "94704, Berkeley, California, United States",
  "Toddville, Maryland, United States",
  "Granada, Meta, Colombia",
  "97343, Eddyville, Oregon, United States",
  "110072, Piteşti, Argeş, Romania",
  "618206, Chusovoy, Perm, Russia",
  "British Columbia, Canada",
  "Ajapnyak, Yerevan, Armenia",
  "Gaston, Indiana, United States",
  "Tura, Pest, Hungary",
  "Galatia, Illinois, United States",
  "Turukhansk, Krasnoyarsk Krai, Russia",
  "85-794, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "81052, Lamar, Colorado, United States",
  "Sundsvall, Västernorrland County, Sweden",
  "2013, Oslo, Oslo, Norway",
  "K4K 1K7, Rockland, Ontario, Canada",
  "Sandusky, Ohio, United States",
  "68711, Amelia, Nebraska, United States",
  "Lappeenranta, South Karelia, Finland",
  "618417, Berezniki, Perm, Russia",
  "455008, Magnitogorsk, Chelyabinsk, Russia",
  "Douglasville, Georgia, United States",
  "30813, Lorca, Región de Murcia, Spain",
  "La Libertad, Peru",
  "29912, Coosawhatchie, South Carolina, United States",
  "70565, Stuttgart, Baden-Württemberg, Germany",
  "Nikolayevskoye, Severnaya Osetiya-Alaniya, Russia",
  "Chitaguppa, Karnataka, India",
  "V0B 1T3, Elko, British Columbia, Canada",
  "Wadena, Iowa, United States",
  "Otero de Rey, Galicia, Spain",
  "243006, Novozybkov, Bryansk, Russia",
  "693004, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "29810, Allendale, South Carolina, United States",
  "6411, Molde, Møre og Romsdal, Norway",
  "Luna Pier, Michigan, United States",
  "Ashland, Montana, United States",
  "625046, Tyumen, Tyumen’, Russia",
  "Patos, Fier, Albania",
  "184413, Nikel, Murmansk, Russia",
  "67000, Zonguldak, Zonguldak, Türkiye",
  "Four Points, South Antelope Valley, California, United States",
  "Kimblesworth, England, United Kingdom",
  "58057, McLeod, North Dakota, United States",
  "Chenango, New York, United States",
  "Stewartstown, Pennsylvania, United States",
  "658084, Novoaltaysk, Altai Krai, Russia",
  "Libonik, Korçë, Albania",
  "28806, Asheville, North Carolina, United States",
  "79520, Hamlin, Texas, United States",
  "North Curry, England, United Kingdom",
  "Putnam County, Georgia, United States",
  "Broadwater County, Montana, United States",
  "Krasnoural'sk, Sverdlovsk, Russia",
  "McHenry Township, Illinois, United States",
  "80013, Aurora, Colorado, United States",
  "Coshocton, Ohio, United States",
  "Callahan County, Texas, United States",
  "Brabantine City Row",
  "Sandusky County, Ohio, United States",
  "71100, Port Dickson, Negri Sembilan, Malaysia",
  "Wyoming County, Pennsylvania, United States",
  "346889, Bataysk, Rostov, Russia",
  "97386, Sweet Home, Oregon, United States",
  "Shira, Oromia Region, Ethiopia",
  "Juaba, Cametá, Pará, Brazil",
  "171505, Kimry, Tver’, Russia",
  "Patrick, South Carolina, United States",
  "18650, Cankiri, Çankırı, Türkiye",
  "20166, Sterling, Virginia, United States",
  "Belogorskoye, Volgograd, Russia",
  "Beltrami, San Giovanni Ilarione, Veneto, Italy",
  "Davie Little Ranches, Davie, Florida, United States",
  "Norte de Santander, Colombia",
  "Morton, Carlisle, England, United Kingdom",
  "Peterborough, Victoria, Australia",
  "Oldenburg in Holstein, Schleswig-Holstein, Germany",
  "600, Akureyri, Northeastern Region, Iceland",
  "Paris, Île-de-France, France",
  "Pike County, Alabama, United States",
  "S13M1A9, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Metzervisse, Grand Est, France",
  "07749, Jena, Thuringia, Germany",
  "McCulloch, Kelowna, British Columbia, Canada",
  "Greater Bolzano Metropolitan Area",
  "Verkhnyaya Salda, Sverdlovsk, Russia",
  "141500, Solnechnogorsk, Moscow, Russia",
  "Wiener Neustadt, Lower Austria, Austria",
  "South Grundy, Virginia, United States",
  "Usinsk, Komi, Russia",
  "Limestone, Tennessee, United States",
  "Niceville, Florida, United States",
  "614087, Perm, Perm, Russia",
  "Blue Point, New York, United States",
  "Baia Mare Metropolitan Area",
  "Dillon, Montana, United States",
  "81087, Vilas, Colorado, United States",
  "Juaban I, Kampung Teiraplu, Papua, Indonesia",
  "Jasper County, Illinois, United States",
  "45387, Yellow Springs, Ohio, United States",
  "Palandoken, Erzurum, Türkiye",
  "413100, Engels, Saratov, Russia",
  "670010, Ulan-Ude, Buryatia, Russia",
  "34093, Istanbul, Istanbul, Türkiye",
  "19057, Schwerin, Mecklenburg-West Pomerania, Germany",
  "Gardena, California, United States",
  "Coleman County, Texas, United States",
  "Guanling Buyeizu Miaozu Aut. County, Guizhou, China",
  "Hardin County, Ohio, United States",
  "Burketts First Addition, Callaway, Florida, United States",
  "Cherokee County, Iowa, United States",
  "172388, Rzhev, Tver’, Russia",
  "İznik, Bursa, Türkiye",
  "Gjirokastër, Gjirokastër, Albania",
  "92327, Daggett, California, United States",
  "346903, Novoshakhtinsk, Rostov, Russia",
  "8500-554, Portimão, Faro, Portugal",
  "Voronezh, Sumy, Ukraine",
  "Srednyaya Borzya, Zabaykalsky Krai, Russia",
  "Shermans Dale, Pennsylvania, United States",
  "MD-2071, Chişinău, Chișinău, Moldova",
  "394077, Voronezh, Voronezh, Russia",
  "60169, Hoffman Estates, Illinois, United States",
  "Alekseyevka, Kursk, Russia",
  "6030, Charleroi, Walloon Region, Belgium",
  "28719, Cherokee, North Carolina, United States",
  "Crooked Hill, Susquehanna, Pennsylvania, United States",
  "Vratsa, Vratsa, Bulgaria",
  "Cumberland, British Columbia, Canada",
  "Danville, Quebec, Canada",
  "Danesbury, Welwyn, England, United Kingdom",
  "Schengen Area",
  "Rockingham, Queensland, Australia",
  "141532, Solnechnogorsk, Moscow, Russia",
  "Arsenyevsky District, Tula, Russia",
  "61824, Champaign, Illinois, United States",
  "Barronstown (Laffan), County Tipperary, Ireland",
  "Vernon, Alabama, United States",
  "Dolynska Raion, Kirovohrad, Ukraine",
  "236006, Kaliningrad, Kaliningrad, Russia",
  "Tikhoretskiy Rayon, Krasnodar, Russia",
  "97148, Yamhill, Oregon, United States",
  "Ray, Michigan, United States",
  "617320, Piatra -oimului, Neamţ, Romania",
  "Merricks Beach, Victoria, Australia",
  "Bosque, Cachoeira do Sul, Rio Grande do Sul, Brazil",
  "6312, Steinhausen, Zug, Switzerland",
  "Caldwell, Ohio, United States",
  "98100, Messina, Sicily, Italy",
  "Kozlu, Zonguldak, Türkiye",
  "624265, Asbest, Sverdlovsk, Russia",
  "21000, Novi Sad, Vojvodina, Serbia",
  "Mărgineni, Bacău, Romania",
  "410412, Oradea, Bihor, Romania",
  "Costigliole d’Asti, Piedmont, Italy",
  "Hot Springs, South Dakota, United States",
  "29160, Swansea, South Carolina, United States",
  "674677, Krasnokamensk, Zabaykalsky Krai, Russia",
  "Rowan, Sundar Nagar, Himachal Pradesh, India",
  "Hubbard, Iowa, United States",
  "1200, Tetovo, Polog, North Macedonia",
  "362047, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "566, Northwest Koochiching, Minnesota, United States",
  "R6W 1H1, Winkler, Manitoba, Canada",
  "Marion, North Carolina, United States",
  "346902, Novoshakhtinsk, Rostov, Russia",
  "Petroleum, West Virginia, United States",
  "7020, Trondheim, Trøndelag, Norway",
  "Greater Foggia Metropolitan Area",
  "Burdur, Burdur, Türkiye",
  "75090, Sherman, Texas, United States",
  "4403, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "87552, Pecos, New Mexico, United States",
  "Tuscola, Illinois, United States",
  "Pasquotank County, North Carolina, United States",
  "429820, Alatyr’, Chuvashia, Russia",
  "Cherry Log, Georgia, United States",
  "Vanuatu",
  "Fauquier County, Virginia, United States",
  "22000, Šibenik, Sibenik-Knin, Croatia",
  "48178, South Lyon, Michigan, United States",
  "Newton, North Carolina, United States",
  "410458, Oradea, Bihor, Romania",
  "Ferguslie Park, Paisley, Scotland, United Kingdom",
  "161110, Nikol’skiy Torzhok, Vologda, Russia",
  "659319, Biysk, Altai Krai, Russia",
  "34160, Istanbul, Istanbul, Türkiye",
  "Vimmerby kommun, Kalmar County, Sweden",
  "90507, Torrance, California, United States",
  "Kukura Chowanara, Amguri, Assam, India",
  "403114, Uryupinsk, Volgograd, Russia",
  "01277, Dresden, Saxony, Germany",
  "18370, Swiftwater, Pennsylvania, United States",
  "Augusta, Georgia, United States",
  "Xánthi, Eastern Macedonia and Thrace, Greece",
  "Davis County, Iowa, United States",
  "38873, Tishomingo, Mississippi, United States",
  "Kewaunee, Wisconsin, United States",
  "Iceland",
  "Zapata County, Texas, United States",
  "Duisburg, North Rhine-Westphalia, Germany",
  "353641, Yeyskoye Ukrepleniye, Krasnodar, Russia",
  "629001, Salekhard, Yamalo-Nenets, Russia",
  "Huron East, Ontario, Canada",
  "Hawkinstown, County Meath, Ireland",
  "E28D8M8, Makhambet, Atyrau Region, Kazakhstan",
  "Gorodskoy Okrug L'gov, Kursk, Russia",
  "455037, Magnitogorsk, Chelyabinsk, Russia",
  "Bila Tserkva, Kyiv, Ukraine",
  "LS6 1AA, Leeds, England, United Kingdom",
  "B61B7F3, Sarqan, Jetisu Region, Kazakhstan",
  "3400, Hillerød, Capital Region of Denmark, Denmark",
  "Salonica, Central Macedonia, Greece",
  "Keminmaa, Lapland, Finland",
  "Voznesenskiy, Novosibirsk, Russia",
  "36700, Kars, Kars, Türkiye",
  "658208, Rubtsovsk, Altai Krai, Russia",
  "Pokrovskiy, Orenburg, Russia",
  "E7K 2W2, Gregg Settlement, New Brunswick, Canada",
  "Novokuznetsky District, Kemerovo, Russia",
  "35010, Alexander City, Alabama, United States",
  "Saskylakh, Sakha, Russia",
  "61350, Ottawa, Illinois, United States",
  "10001, Zhytomyr, Zhytomyr, Ukraine",
  "S0E 0B0, Archerwill, Saskatchewan, Canada",
  "3301, Oryakhovo, Vratsa, Bulgaria",
  "70718, Gonzales, Louisiana, United States",
  "Monaco-Ville, Monaco",
  "Drohobych, Lviv, Ukraine",
  "44200, Malatya, Malatya, Türkiye",
  "461040, Buzuluk, Orenburg, Russia",
  "Kislovodsk, Stavropol, Russia",
  "S0G 1A0, Davidson, Saskatchewan, Canada",
  "Pichincha, Ecuador",
  "Choctawmar, Lake Lorraine, Florida, United States",
  "666703, Kirensk, Irkutsk, Russia",
  "Alexander Bay, Northern Cape, South Africa",
  "24458, McDowell, Virginia, United States",
  "Greater Zilina Area",
  "5632 AA, Eindhoven, North Brabant, Netherlands",
  "Bremen, Bremen, Germany",
  "Cimarron Hills, Colorado, United States",
  "Monroe County, Michigan, United States",
  "7700, Targovište, Turgovishte, Bulgaria",
  "Novotroitskoye, Kherson, Ukraine",
  "F39A3P0, Zaysan, East Kazakhstan Region, Kazakhstan",
  "6515, Bellinzona, Ticino, Switzerland",
  "Oswego County, New York, United States",
  "14690, Hämeenlinna, Kanta-Häme, Finland",
  "624590, Ivdel’, Sverdlovsk, Russia",
  "Port Moody, British Columbia, Canada",
  "Sabine Pass, Texas, United States",
  "80331, Munich, Bavaria, Germany",
  "Greater Hamburg Area",
  "19030, Çorum, Çorum, Türkiye",
  "Spruževa, Rezekne, Latvia",
  "Vantaa, Uusimaa, Finland",
  "22680, Saint-Quay-Portrieux, Brittany, France",
  "30177, Tate, Georgia, United States",
  "2000, Maribor, Maribor, Slovenia",
  "Mckenzie Lake, Calgary, Alberta, Canada",
  "15170, Lahti, Päijät-Häme, Finland",
  "Greater Missoula Area",
  "Grainger, Drummond/North Elmsley, Ontario, Canada",
  "08006, Barcelona, Catalonia, Spain",
  "65049, Lake Ozark, Missouri, United States",
  "80805, Munich, Bavaria, Germany",
  "E9C 1T1, Storeytown, New Brunswick, Canada",
  "06012, Città di Castello, Umbria, Italy",
  "40472, Ravenna, Kentucky, United States",
  "15219, Pittsburgh, Pennsylvania, United States",
  "Greensburg, Pennsylvania, United States",
  "Greater Bologna Metropolitan Area",
  "13007, Marseille, Provence-Alpes-Côte d'Azur, France",
  "50800, Mikkeli, South Savo, Finland",
  "Sukhumi District, Georgia",
  "Bafran, Isfahan Province, Iran",
  "Kingston, New York, United States",
  "Craig, Missouri, United States",
  "110424, Piteşti, Argeş, Romania",
  "CH5 4JL, Connah's Quay, Wales, United Kingdom",
  "Ulan-Ude, Buryatia, Russia",
  "Lee County, Illinois, United States",
  "15206, Pittsburgh, Pennsylvania, United States",
  "676850, Belogorsk, Amur, Russia",
  "Giles County, Tennessee, United States",
  "Holmes Chapel, England, United Kingdom",
  "Clay County, Minnesota, United States",
  "53217, Milwaukee, Wisconsin, United States",
  "Cooperstown, North Dakota, United States",
  "70327, Stuttgart, Baden-Württemberg, Germany",
  "766, Reagan, Texas, United States",
  "Buffalo Grove, Illinois, United States",
  "Wayne County, New York, United States",
  "Mingo County, West Virginia, United States",
  "Blagoveshchenka, Krasnoyarsk Krai, Russia",
  "Simpson County, Kentucky, United States",
  "Meade Meadows East, Appleton, Wisconsin, United States",
  "Settlement, Sheboygan, Wisconsin, United States",
  "Palos Heights, Illinois, United States",
  "Traverse, Plieux, Occitanie, France",
  "Verkhnevilyuysk, Sakha, Russia",
  "34322, Kragujevac, Centralna Srbija, Serbia",
  "Alcornocalejos, San José del Valle, Andalusia, Spain",
  "Bland County, Virginia, United States",
  "162609, Cherepovets, Vologda, Russia",
  "European Union",
  "Miguel Otero Silva, Anzoategui State, Venezuela",
  "Lod, Center District, Israel",
  "85344, Parker, Arizona, United States",
  "456581, Yemanzhelinsk, Chelyabinsk, Russia",
  "Bates, Oregon, United States",
  "South Albemarle, North Carolina, United States",
  "White Plains, New York, United States",
  "93-620, Łódź, Łódzkie, Poland",
  "48004, Bilbao, Basque Country, Spain",
  "Berdychiv, Zhytomyr, Ukraine",
  "Iowa County, Iowa, United States",
  "CV7 7DD, Berkswell, England, United Kingdom",
  "196233, St Petersburg, St Petersburg City, Russia",
  "McMinn County, Tennessee, United States",
  "Sargentville, Maine, United States",
  "27570, Bremerhaven, Bremen, Germany",
  "00053, Civitavecchia, Latium, Italy",
  "Novosibirsk, Novosibirsk, Russia",
  "Cotton Valley, Louisiana, United States",
  "BS1 4DT, Bristol, England, United Kingdom",
  "75155, Rice, Texas, United States",
  "Granada, Andalusia, Spain",
  "Belleville, Illinois, United States",
  "Hof HaSharon Regional Council, Center District, Israel",
  "Diekirch, Diekirch, Luxembourg",
  "L02H2T4, Oral, West Kazakhstan Region, Kazakhstan",
  "7300-236, Portalegre, Portalegre, Portugal",
  "4002, Plovdiv, Plovdiv, Bulgaria",
  "Rossland, British Columbia, Canada",
  "Windsor Mill, Maryland, United States",
  "Makarov, Kyiv, Ukraine",
  "Novohrad-Volyns’kyy, Zhytomyr, Ukraine",
  "South Central, Sheboygan, Wisconsin, United States",
  "Saratov, Russia",
  "Ritchie Lake, Quispamsis, New Brunswick, Canada",
  "165305, Kotlas, Arkhangel’sk, Russia",
  "Nikel’, Murmansk, Russia",
  "28538, Hookerton, North Carolina, United States",
  "Pulaski County, Virginia, United States",
  "Washington, United States",
  "North Richland Hills, Texas, United States",
  "169712, Usinsk, Komi, Russia",
  "Menard, Texas, United States",
  "Worth County, Georgia, United States",
  "6004, Ålesund, Møre og Romsdal, Norway",
  "90505, Haapsalu, Laane, Estonia",
  "Bollinger County, Missouri, United States",
  "Dawson, Pennsylvania, United States",
  "GY2 4JA, St Sampson, Guernsey, Guernsey",
  "606403, Balakhna, Nizhniy Novgorod, Russia",
  "SO14 0DD, Southampton, England, United Kingdom",
  "KW15 1NS, Kirkwall, Scotland, United Kingdom",
  "Hampton, Iowa, United States",
  "Rockland, Massachusetts, United States",
  "1040, Skopje, Skopje Statistical Region, North Macedonia",
  "665813, Angarsk, Irkutsk, Russia",
  "Greater Zadar Area",
  "423838, Naberezhnyye Chelny, Tatarstan, Russia",
  "38024, Dyersburg, Tennessee, United States",
  "Gregg County, Texas, United States",
  "Gaya, Bihar, India",
  "H91 RD6V, Galway, County Galway, Ireland",
  "3320, Kozloduy, Vratsa, Bulgaria",
  "15893, Santiago de Compostela, Galicia, Spain",
  "FY2 0HB, Blackpool, England, United Kingdom",
  "Treasure Island, San Francisco, California, United States",
  "678967, Neryungri, Sakha, Russia",
  "8501, Frauenfeld, Thurgau, Switzerland",
  "Palanan, Cagayan Valley, Philippines",
  "Port-Louis, Pointe-à-Pitre, Guadeloupe",
  "352506, Labinsk, Krasnodar, Russia",
  "446112, Chapayevsk, Samara, Russia",
  "618460, Berezniki, Perm, Russia",
  "Buyeo County, South Chungcheong, South Korea",
  "GL2 0AF, Gloucester, England, United Kingdom",
  "Bradley, Maine, United States",
  "Campeche, Campeche, Mexico",
  "Kemper, Münster, North Rhine-Westphalia, Germany",
  "R13C5H1, Beyneu, Mangystau Region, Kazakhstan",
  "Krichevskiy gorodskoy sovet, Mahilyow, Belarus",
  "La Milamesure, Luant, Centre-Val de Loire, France",
  "Roanoke, Illinois, United States",
  "Wichita, Kansas, United States",
  "Loganville, Georgia, United States",
  "Ul’yanovsk, Ul’yanovsk, Russia",
  "Panola County, Mississippi, United States",
  "Obshtina Gorna Oryahovitsa, Veliko Turnovo, Bulgaria",
  "184505, Monchegorsk, Murmansk, Russia",
  "South Weber, Utah, United States",
  "Karayazı, Erzurum, Türkiye",
  "Valencian Community, Spain",
  "06183, Badajoz, Extremadura, Spain",
  "54300, Sakarya, Sakarya, Türkiye",
  "462411, Orsk, Orenburg, Russia",
  "13516, Tallinn, Harjumaa, Estonia",
  "10-457, Olsztyn, Warmińsko-mazurskie, Poland",
  "20718, Bowie, Maryland, United States",
  "644010, Omsk, Omsk, Russia",
  "Münchenstein, Basel-Country, Switzerland",
  "18057, Rostock, Mecklenburg-West Pomerania, Germany",
  "Austin, Arkansas, United States",
  "32781, Titusville, Florida, United States",
  "Saint-Nicéphore, Quebec, Canada",
  "187556, Tikhvin, Leningrad, Russia",
  "Pope, Ventspils, Latvia",
  "Northumberland Heath, Erith, England, United Kingdom",
  "L02A5T5, Oral, West Kazakhstan Region, Kazakhstan",
  "23208, Zrenjanin, Vojvodina, Serbia",
  "665008, Tayshet, Irkutsk, Russia",
  "Ust’-Kut, Irkutsk, Russia",
  "Jones, Cagayan Valley, Philippines",
  "Stanley, Tasmania, Australia",
  "Shëngjin, Lezhë, Albania",
  "110, Reykjavík, Capital Region, Iceland",
  "Belmont, North Carolina, United States",
  "75132, Rockwall, Texas, United States",
  "06370, Ankara, Ankara, Türkiye",
  "24-300, Opole Lubelskie, Lubelskie, Poland",
  "KW15 1BZ, Kirkwall, Scotland, United Kingdom",
  "Kouvola, Kymenlaakso, Finland",
  "47892, Acquaviva, San Marino",
  "Smolensky District, Smolensk, Russia",
  "678901, Aldan, Sakha, Russia",
  "59349, Terry, Montana, United States",
  "628103, Peregrebnoye, Khanty-Mansi, Russia",
  "29002, Málaga, Andalusia, Spain",
  "Scurry County, Texas, United States",
  "30310, Cartagena, Región de Murcia, Spain",
  "FY1 1AN, Blackpool, England, United Kingdom",
  "21230, Sinj, Split-Dalmatia, Croatia",
  "Corumbaíba, Goiás, Brazil",
  "33201, Gijón, Principality of Asturias, Spain",
  "65-305, Zielona Góra, Lubuskie, Poland",
  "7005-138, Évora, Évora, Portugal",
  "McCormick County, South Carolina, United States",
  "64014, Blue Springs, Missouri, United States",
  "Vintondale, Pennsylvania, United States",
  "Coopers Plains, Queensland, Australia",
  "Otary, Mari El, Russia",
  "71340, Harrisonburg, Louisiana, United States",
  "47449, Newberry, Indiana, United States",
  "Velyka Vyska, Kirovohrad, Ukraine",
  "Burgos, Castilla and Leon, Spain",
  "Jasper County, South Carolina, United States",
  "652471, Anzhero-Sudzhensk, Kemerovo, Russia",
  "Petropavlovsk-Kamchatsky, Kamchatka, Russia",
  "4810, Arendal, Agder, Norway",
  "Powder River County, Montana, United States",
  "Gemeente Woerden, Utrecht, Netherlands",
  "Rankin Inlet, Nunavut, Canada",
  "Ansonbrook, North Carolina, United States",
  "Spartanburg County, South Carolina, United States",
  "27572, Bremerhaven, Bremen, Germany",
  "Greer, Indiana, United States",
  "Concho, La Meauffe, Normandy, France",
  "24315, Bland, Virginia, United States",
  "Saint-Étienne-des-Grès, Quebec, Canada",
  "7924, Rørvik, Trøndelag, Norway",
  "Ivano-Frankivsk Raion, Ivano-Frankivsk, Ukraine",
  "9020-063, Funchal, Madeira Island, Portugal",
  "9900-090, Horta, Portugal",
  "Perkins, Georgia, United States",
  "Cadiz, Andalusia, Spain",
  "Portage County, Ohio, United States",
  "Yakima County, Washington, United States",
  "R0C 3K0, Stonewall, Manitoba, Canada",
  "Lutska miska hromada, Volyn, Ukraine",
  "01011, Kyiv, Kyiv City, Ukraine",
  "Cassis, Provence-Alpes-Côte d'Azur, France",
  "27557, Middlesex, North Carolina, United States",
  "Midland, Columbus, Georgia, United States",
  "21000, Dijon, Bourgogne-Franche-Comté, France",
  "Yemanzhelinsky District, Chelyabinsk, Russia",
  "McHenry, Pennsylvania, United States",
  "Angelina, Sánchez Ramírez, Dominican Republic",
  "143402, Krasnogorsk, Moscow, Russia",
  "Empa, Paphos, Cyprus",
  "40-035, Katowice, Śląskie, Poland",
  "Stokesley, England, United Kingdom",
  "00120, Helsinki, Uusimaa, Finland",
  "Rama 32, Chippewas of Rama First Nation, Ontario, Canada",
  "Portsmouth, New Hampshire, United States",
  "53080, Saukville, Wisconsin, United States",
  "Çiçekdağı, Kırşehir, Türkiye",
  "Warren County, New York, United States",
  "Tarazona de la Mancha, Castile-La Mancha, Spain",
  "3334, Palanan, Cagayan Valley, Philippines",
  "01150, Adana, Adana, Türkiye",
  "LV-5420, Daugavpils, Daugavpils, Latvia",
  "29303, Spartanburg, South Carolina, United States",
  "HU5 1AA, Kingston Upon Hull, England, United Kingdom",
  "8200-042, Albufeira, Faro, Portugal",
  "3926, Merricks Beach, Victoria, Australia",
  "Slavonski Šamac, Brod-Posavina, Croatia",
  "30141, Venice, Veneto, Italy",
  "2982 BM, Ridderkerk, South Holland, Netherlands",
  "8021, Paphos Municipality, Paphos, Cyprus",
  "Zürich Metropolitan Area",
  "Qonaev, Almaty Region, Kazakhstan",
  "5015, Bergen, Vestland, Norway",
  "Autlán de Navarro, Jalisco, Mexico",
  "79556, Sweetwater, Texas, United States",
  "28604, Banner Elk, North Carolina, United States",
  "Bath, Maine, United States",
  "Karpoš, Skopje Statistical Region, North Macedonia",
  "692856, Partizansk, Primorye, Russia",
  "Colonial, Eunápolis, Bahia, Brazil",
  "Treasure Cay, North Carolina, United States",
  "Silmala Parish, Rēzekne Municipality, Latvia",
  "Woodstock, Maryland, United States",
  "Bremen, Kentucky, United States",
  "Prizren District, Kosovo",
  "Grant County, West Virginia, United States",
  "Windsor, Maine, United States",
  "Broomehill-Tambellup, Western Australia, Australia",
  "Plymouth County, Massachusetts, United States",
  "Granville, Normandy, France",
  "93307, Bakersfield, California, United States",
  "Aldana, Nariño, Colombia",
  "Bamberg, Bavaria, Germany",
  "623537, Krasnoufimsk, Sverdlovsk, Russia",
  "Israel",
  "46300, Kahramanmaraş, Kahraman Maras, Türkiye",
  "24073, Christiansburg, Virginia, United States",
  "Municipio Guaraque, Merida State, Venezuela",
  "Tomsky District, Tomsk, Russia",
  "21410, Novi Sad, Vojvodina, Serbia",
  "Park County, Montana, United States",
  "Caldwell County, North Carolina, United States",
  "Santiago Metropolitan Region, Chile",
  "37005, Salamanca, Castilla and Leon, Spain",
  "634034, Tomsk, Tomsk, Russia",
  "Brivezac, Nouvelle-Aquitaine, France",
  "301 50, Agrínio, Western Greece, Greece",
  "665451, Usol'ye-Sibirskoye, Irkutsk, Russia",
  "385077, Maykop, Adygea, Russia",
  "02-677, Warsaw, Mazowieckie, Poland",
  "Banner, North Carolina, United States",
  "Pendleton, Salford, England, United Kingdom",
  "Nueva Loja, Sucumbíos, Ecuador",
  "Pierce, Ohio, United States",
  "1220, Sofia, Sofia City, Bulgaria",
  "Artvin, Türkiye",
  "Netishyn, Khmelnytsky, Ukraine",
  "09121, Cagliari, Sardinia, Italy",
  "Alta, Wyoming, United States",
  "4000, Roskilde, Region Zealand, Denmark",
  "Krujë, Durres, Albania",
  "94032, Passau, Bavaria, Germany",
  "35-330, Rzeszów, Podkarpackie, Poland",
  "14301, Niagara Falls, New York, United States",
  "Rowan Oak, Hewitt, Texas, United States",
  "Omutninsky District, Kirov, Russia",
  "Saline County, Illinois, United States",
  "Ajaccio, Corsica, France",
  "Sargé-lès-le-Mans, Pays de la Loire, France",
  "Reggio di Calabria, Calabria, Italy",
  "Barbour County, West Virginia, United States",
  "BA2 5QZ, Bath, England, United Kingdom",
  "455023, Magnitogorsk, Chelyabinsk, Russia",
  "Barnwell, South Carolina, United States",
  "461043, Buzuluk, Orenburg, Russia",
  "429103, Shumerlya, Chuvashia, Russia",
  "33317, Fort Lauderdale, Florida, United States",
  "MD-2059, Chişinău, Chișinău, Moldova",
  "Saratoga, Wisconsin, United States",
  "31002, Pamplona, Chartered Community of Navarre, Spain",
  "Montsinéry-Tonnegrande, Cayenne, French Guiana",
  "41013, Seville, Andalusia, Spain",
  "Raleigh, North Carolina, United States",
  "03050, Cottbus, Brandenburg, Germany",
  "Spectrum, Sumter, South Carolina, United States",
  "53900, Lappeenranta, South Karelia, Finland",
  "68661, Schuyler, Nebraska, United States",
  "Valdepeñas de Jaén, Andalusia, Spain",
  "39477, Sandersville, Mississippi, United States",
  "10308, Vilnius, Vilniaus, Lithuania",
  "Newton Falls, Ohio, United States",
  "Park City, Utah, United States",
  "72473, Tuckerman, Arkansas, United States",
  "72000, Le Mans, Pays de la Loire, France",
  "4031, Debrecen, Hajdú-Bihar, Hungary",
  "2347, Pernik, Pernik, Bulgaria",
  "Antelope County, Nebraska, United States",
  "Terrell, Texas, United States",
  "Crookhall, Consett, England, United Kingdom",
  "IP31 3RX, Thurston, England, United Kingdom",
  "Hot Springs Village, Arkansas, United States",
  "Lincoln, Nebraska Metropolitan Area",
  "Seychelles",
  "9411, Harstad, Troms og Finnmark, Norway",
  "Kyzyl-Tayginskiy, Tuva, Russia",
  "427961, Sarapul, Udmurtia, Russia",
  "Custer, Kentucky, United States",
  "Hanson, Massachusetts, United States",
  "93055, Regensburg, Bavaria, Germany",
  "722014, Jeti-Ögüz, Issyk-Kul Region, Kyrgyzstan",
  "Rosebud County, Montana, United States",
  "455038, Magnitogorsk, Chelyabinsk, Russia",
  "Sauk Centre, Minnesota, United States",
  "Greater St. George Area",
  "70576, Ville Platte, Louisiana, United States",
  "Falls Church City County, Virginia, United States",
  "7717, Steinkjer, Trøndelag, Norway",
  "43040, Kutahya, Kütahya, Türkiye",
  "675002, Blagoveshchensk, Amur, Russia",
  "185002, Petrozavodsk, Karelia, Russia",
  "C40A6G0, Esil, Akmola Region, Kazakhstan",
  "M22 9AJ, Wythenshawe, England, United Kingdom",
  "72060, Batman, Batman, Türkiye",
  "Comal, Central Java, Indonesia",
  "Kerrville, Texas, United States",
  "Belgrade Metropolitan Area",
  "694072, Dolinsk, Sakhalin, Russia",
  "Tulcea, Romania",
  "636460, Kolpashevo, Tomsk, Russia",
  "Madison, Wisconsin, United States",
  "24145, Kiel, Schleswig-Holstein, Germany",
  "L'Aquila, Abruzzi, Italy",
  "37862, Sevierville, Tennessee, United States",
  "Arada, Wadi Fira, Chad",
  "400104, Cluj-Napoca, Cluj, Romania",
  "Clearwater, Kansas, United States",
  "Rockland All Saints, England, United Kingdom",
  "Seneca, Illinois, United States",
  "Decatur, Georgia, United States",
  "Concord, New Hampshire, United States",
  "La Ravallière, Saint-Martin-en-Haut, Auvergne-Rhône-Alpes, France",
  "28701, Alexander, North Carolina, United States",
  "Greater Lahti Area",
  "28379, Rockingham, North Carolina, United States",
  "23569, Lübeck, Schleswig-Holstein, Germany",
  "28360, Pori, Satakunta, Finland",
  "Saluda, North Carolina, United States",
  "Heidelberg, Mississippi, United States",
  "Hardings Wood, Stoke-On-Trent, England, United Kingdom",
  "97212, Saint-Joseph, Fort-de-France, Martinique",
  "94708, Berkeley, California, United States",
  "Barletta, Apulia, Italy",
  "Prairieville, Louisiana, United States",
  "Currituck, North Carolina, United States",
  "93221, Exeter, California, United States",
  "Pike Road, Alabama, United States",
  "Prince Albert, Saskatchewan, Canada",
  "667903, Kungur-Tuk, Tuva, Russia",
  "353688, Yeysk, Krasnodar, Russia",
  "TS2 1XA, Middlesbrough, England, United Kingdom",
  "Aldan, Sakha, Russia",
  "Marion County, Indiana, United States",
  "Stevensville, Montana, United States",
  "Wharton, Gainsborough, England, United Kingdom",
  "Semey, Abay Region, Kazakhstan",
  "66123, Saarbrücken, Saarland, Germany",
  "Milam, West Virginia, United States",
  "Loughrea, County Galway, Ireland",
  "5020, Salzburg, Salzburg, Austria",
  "22043, Falls Church, Virginia, United States",
  "Chatham, Chicago, Illinois, United States",
  "Plymouth, Pennsylvania, United States",
  "368600, Derbent, Dagestan, Russia",
  "Chernihiv Raion, Chernihiv, Ukraine",
  "Kansas City Metropolitan Area",
  "Greensboro--Winston-Salem--High Point Area",
  "Rusanovo, Voronezh, Russia",
  "Nikolayevskoye, Karachay-Cherkessia, Russia",
  "Kandalakshsky District, Murmansk, Russia",
  "Zheleznogorsk, Kursk, Russia",
  "7400, Isperih, Razgrad, Bulgaria",
  "Esch-sur-Sûre, Diekirch, Luxembourg",
  "6006, Ålesund, Møre og Romsdal, Norway",
  "Saint-Quay-Portrieux, Brittany, France",
  "Loudonville, New York, United States",
  "Aruba",
  "Chelang, Padhar, Himachal Pradesh, India",
  "Bayırköy, Bilecik, Türkiye",
  "33711, St Petersburg, Florida, United States",
  "Morin Dawa Daur Autonomous Banner, Inner Mongolia, China",
  "Blair, Nevada, United States",
  "Lanesborough, Massachusetts, United States",
  "Nantes, Pays de la Loire, France",
  "Bamberg County, South Carolina, United States",
  "Pogradec, Korçë, Albania",
  "542 34, Mariestad, Västra Götaland County, Sweden",
  "Nowata, Oklahoma, United States",
  "Huntington, West Virginia, United States",
  "8970, Kotel, Sliven, Bulgaria",
  "Nyeng, North Denmark Region, Denmark",
  "871 42, Härnösand, Västernorrland County, Sweden",
  "Tartu vald, Tartumaa, Estonia",
  "19020, Çorum, Çorum, Türkiye",
  "West Linn, Oregon, United States",
  "Fort William, Scotland, United Kingdom",
  "01360, Adana, Adana, Türkiye",
  "71800, Kırıkkale, Kırıkkale, Türkiye",
  "LS2 3AD, Leeds, England, United Kingdom",
  "677001, Yakutsk, Sakha, Russia",
  "619000, Kudymkar, Komi-Permyak, Russia",
  "Treasure Beach, Saint Elizabeth, Jamaica",
  "309952, Valuyki, Belgorod, Russia",
  "Grant, Florida, United States",
  "6330, Cham, Zug, Switzerland",
  "693904, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Hillsborough, New Brunswick, Canada",
  "Ottawa County, Kansas, United States",
  "Northwest Yellowstone, Montana, United States",
  "75008, Paris, Île-de-France, France",
  "399773, Yelets, Lipetsk, Russia",
  "Handa, Tokushima, Japan",
  "New London County, Connecticut, United States",
  "Olaine, Riga, Latvia",
  "Sotteville-lès-Rouen, Normandy, France",
  "23205, Zrenjanin, Vojvodina, Serbia",
  "Caledonia, Michigan, United States",
  "Uelen, Chukot, Russia",
  "34126, Trieste, Friuli-Venezia Giulia, Italy",
  "Alapayevsk, Sverdlovsk, Russia",
  "791 61, Falun, Dalarna County, Sweden",
  "Poronaysk, Sakhalin, Russia",
  "McPherson, Dayton, Ohio, United States",
  "Moorestown, New Jersey, United States",
  "2000, Millers Point, New South Wales, Australia",
  "Winston, England, United Kingdom",
  "1080, Brussels, Brussels Region, Belgium",
  "Cockerton, Darlington, England, United Kingdom",
  "19063, Schwerin, Mecklenburg-West Pomerania, Germany",
  "620063, Focşani, Vrancea, Romania",
  "Ashampstead, England, United Kingdom",
  "Reynoldsville, Pennsylvania, United States",
  "Pembina, Manitoba, Canada",
  "Newton Stewart, Scotland, United Kingdom",
  "Presidio Heights, San Francisco, California, United States",
  "8513, Narvik, Nordland, Norway",
  "8505, Narvik, Nordland, Norway",
  "181335, Vorontsovo, Pskov, Russia",
  "27199, Winston-Salem, North Carolina, United States",
  "32004, Ourense, Galicia, Spain",
  "Elbląg, Warmińsko-mazurskie, Poland",
  "Preston, Mississippi, United States",
  "West Canje, Queenstown - Fort Ordance - Smythfield, East Berbice-Corentyne, Guyana",
  "06200, Nice, Provence-Alpes-Côte d'Azur, France",
  "HU1 2BQ, Kingston Upon Hull, England, United Kingdom",
  "V92 E8F5, Tralee, County Kerry, Ireland",
  "8888, Sliven, Sliven, Bulgaria",
  "L-1840, Luxembourg, Luxembourg, Luxembourg",
  "Bilecik, Bilecik, Türkiye",
  "85258, Scottsdale, Arizona, United States",
  "Whitehaven, England, United Kingdom",
  "Stuttgart Region",
  "R6W 4A5, Winkler, Manitoba, Canada",
  "352693, Apsheronsk, Krasnodar, Russia",
  "Weston, Texas, United States",
  "29011, Málaga, Andalusia, Spain",
  "Summersville, West Virginia, United States",
  "Grudziądzki, Kujawsko-pomorskie, Poland",
  "301367, Aleksin, Kaluga, Russia",
  "Canton of Troyes-4, Grand Est, France",
  "Vernon, British Columbia, Canada",
  "EC2V 5AA, London, England, United Kingdom",
  "65-419, Zielona Góra, Lubuskie, Poland",
  "Olsztynek, Warmińsko-mazurskie, Poland",
  "39363, Stonewall, Mississippi, United States",
  "Bremerhaven, Bremen, Germany",
  "Harrison County, Ohio, United States",
  "H53 D954, Ballinasloe, County Galway, Ireland",
  "Forrest Beach, Western Australia, Australia",
  "A1N 0A1, Mount Pearl, Newfoundland and Labrador, Canada",
  "Pacific, Washington, United States",
  "Meekerville Historic District, Lake View, Illinois, United States",
  "Bath, New Hampshire, United States",
  "Hofu, Yamaguchi, Japan",
  "Bielsko County, Śląskie, Poland",
  "Kotelnichsky District, Kirov, Russia",
  "Nisku, Alberta, Canada",
  "Karlstad, Värmland County, Sweden",
  "Sheridan, New York, United States",
  "308015, Belgorod, Belgorod, Russia",
  "Jeti-Ögüz, Issyk-Kul Region, Kyrgyzstan",
  "M40 0AB, Manchester, England, United Kingdom",
  "29108, Newberry, South Carolina, United States",
  "97255, Umán, Yucatán, Mexico",
  "85259, Scottsdale, Arizona, United States",
  "Eureka County, Nevada, United States",
  "Monroe County, Wisconsin, United States",
  "F39A2X6, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Lake Louisa Estates, Florida, United States",
  "Labinskoye, Tyumen’, Russia",
  "Newton, Kansas, United States",
  "Temple Court, Hitchcock, Texas, United States",
  "Atlantic Highlands, New Jersey, United States",
  "34110, Istanbul, Istanbul, Türkiye",
  "Nolan Drive, Paducah, Kentucky, United States",
  "Greater Harrisburg Area",
  "Santa Barbara County, California, United States",
  "F17T5F8, Semey, Abay Region, Kazakhstan",
  "94720, Berkeley, California, United States",
  "730223, Vaslui, Vaslui, Romania",
  "03051, Hudson, New Hampshire, United States",
  "Luzerne Township, Pennsylvania, United States",
  "Calhoun County, Georgia, United States",
  "15-399, Białystok, Podlaskie, Poland",
  "Chelan Falls, Washington, United States",
  "Westchester, Texas, United States",
  "Vilnius, Vilniaus, Lithuania",
  "41-906, Bytom, Śląskie, Poland",
  "Freiberg, Saxony, Germany",
  "Woodward, Oklahoma, United States",
  "47926, Burnettsville, Indiana, United States",
  "2008, Darlington, New South Wales, Australia",
  "28139, Rutherfordton, North Carolina, United States",
  "Schwäbisch Hall, Baden-Württemberg, Germany",
  "Blyth, England, United Kingdom",
  "Van Cortlandt Village, New York, United States",
  "Loudon, Ohio, United States",
  "Riceboro, Georgia, United States",
  "Kadiogo, Centre, Burkina Faso",
  "Blanco, Texas, United States",
  "Rayong, Rayong, Thailand",
  "South Gastonia, North Carolina, United States",
  "Goliadka, Karnataka, India",
  "98240, Custer, Washington, United States",
  "Pawtucket, Rhode Island, United States",
  "821, Bollnäs, Gävleborg County, Sweden",
  "Dumfries, Virginia, United States",
  "Fayette County, Georgia, United States",
  "Grenada Village, Wellington, Wellington, New Zealand",
  "410002, Oradea, Bihor, Romania",
  "Gloucester, New Jersey, United States",
  "Lynchburg, Ohio, United States",
  "Morshansky District, Tambov, Russia",
  "NR11 8GA, East Beckham, England, United Kingdom",
  "13014, Marseille, Provence-Alpes-Côte d'Azur, France",
  "Leicester Forest East, England, United Kingdom",
  "36211, Vigo, Galicia, Spain",
  "Gillespie, Illinois, United States",
  "Shenandoah, West Virginia, United States",
  "50970, Mikkeli, South Savo, Finland",
  "Chouteau, Illinois, United States",
  "Lowell, Massachusetts, United States",
  "546 43, Thessaloniki, Central Macedonia, Greece",
  "346429, Novocherkassk, Rostov, Russia",
  "Greater Geelong, Victoria, Australia",
  "Le Sauk, Minnesota, United States",
  "Dorchester, Ontario, Canada",
  "Greater Benevento Metropolitan Area",
  "37500, Kastamonu, Kastamonu, Türkiye",
  "54814, Bayfield, Wisconsin, United States",
  "172523, Nelidovo, Tver’, Russia",
  "Kane, Illinois, United States",
  "20109, Manassas, Virginia, United States",
  "Fürth, Hesse, Germany",
  "Holovanivsk Raion, Kirovohrad, Ukraine",
  "16501, Erie, Pennsylvania, United States",
  "Voivres-lès-le-Mans, Pays de la Loire, France",
  "Povorino, Voronezh, Russia",
  "362031, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "Durham, New Hampshire, United States",
  "Rowan Mills, Norfolk County, Ontario, Canada",
  "Lamar, South Carolina, United States",
  "9436, Evenskjær, Troms og Finnmark, Norway",
  "167905, Syktyvkar, Komi, Russia",
  "Inverness, Florida, United States",
  "Brule Corner, Colchester, Subdivision B, Nova Scotia, Canada",
  "Ulaanbaatar, Ulaanbaatar Hot, Mongolia",
  "Morrill, Maine, United States",
  "97034, Lake Oswego, Oregon, United States",
  "Brisbane City, Queensland, Australia",
  "5114, Craigmore, South Australia, Australia",
  "99664, Seward, Alaska, United States",
  "Hampton Bays, New York, United States",
  "Parma, Idaho, United States",
  "54657, Steuben, Wisconsin, United States",
  "Ayan, Khabarovsk, Russia",
  "386102, Nazran, Ingushetia, Russia",
  "Klamath Falls, Oregon, United States",
  "Charlottetown, Prince Edward Island, Canada",
  "610252, Piatra Neamţ, Neamţ, Romania",
  "L10G3Y5, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Radford Semele, England, United Kingdom",
  "68030-060, Santarém, Pará, Brazil",
  "Kazan, Ankara, Türkiye",
  "R0B 0E0, Churchill, Manitoba, Canada",
  "652153, Mariinskiy, Kemerovo, Russia",
  "DG2 7AY, Dumfries, Scotland, United Kingdom",
  "66263, Tulungagung, East Java, Indonesia",
  "353465, Gelendzhik, Krasnodar, Russia",
  "38071, Atoka, Tennessee, United States",
  "Krasnoarmeysky District, Primorye, Russia",
  "Mineral County, West Virginia, United States",
  "East Mahoning, Pennsylvania, United States",
  "Ashern, Manitoba, Canada",
  "Grady, Arkansas, United States",
  "Anson County, North Carolina, United States",
  "04841, Rockland, Maine, United States",
  "169316, Ukhta, Komi, Russia",
  "Focşani, Vrancea, Romania",
  "Brandon, Mississippi, United States",
  "9500-735, Ponta Delgada, Portugal",
  "353146, Tikhoretsk, Krasnodar, Russia",
  "G32 0AA, Glasgow, Scotland, United Kingdom",
  "53130, Lappeenranta, South Karelia, Finland",
  "ST18 0RB, Great Haywood, England, United Kingdom",
  "Stanton Hill, England, United Kingdom",
  "Athens, Tennessee, United States",
  "53923, Cambria, Wisconsin, United States",
  "41124, Modena, Emilia-Romagna, Italy",
  "Webster Parish County, Louisiana, United States",
  "Dieppe, New Brunswick, Canada",
  "8895, Tvarditsa, Sliven, Bulgaria",
  "30085, Tucker, Georgia, United States",
  "57741, Fort Meade, South Dakota, United States",
  "Umeå, Västerbotten County, Sweden",
  "Limestone, Illinois, United States",
  "8854, Galgenen, Schwyz, Switzerland",
  "010 08, Žilina, Zilina, Slovakia",
  "Otoe County, Nebraska, United States",
  "692917, Nakhodka, Primorye, Russia",
  "Hot district, Chiang Mai, Thailand",
  "399785, Yelets, Lipetsk, Russia",
  "32205, Čačak, Centralna Srbija, Serbia",
  "130164, Târgovişte, Dîmboviţa, Romania",
  "26721, Emden, Lower Saxony, Germany",
  "184512, Monchegorsk, Murmansk, Russia",
  "Carbon County, Pennsylvania, United States",
  "Mingoyo, Lindi Region, Tanzania",
  "59800, Lille, Hauts-de-France, France",
  "628414, Surgut, Khanty-Mansi, Russia",
  "142180, Podolsk, Moscow, Russia",
  "4032, Debrecen, Hajdú-Bihar, Hungary",
  "Treasures, Thousand Oaks, California, United States",
  "Maury, Occitanie, France",
  "21703, Frederick, Maryland, United States",
  "50049, Chariton, Iowa, United States",
  "Okhotsk Subprefecture, Hokkaido, Japan",
  "Neuchâtel, Switzerland",
  "70433, Covington, Louisiana, United States",
  "Starrat, Ryerson, Ontario, Canada",
  "Carei, Satu Mare, Romania",
  "Defiance, Ohio, United States",
  "157992, Chernyshëvo, Ivanovo, Russia",
  "18001, Granada, Andalusia, Spain",
  "Slope Road, Minto, New Brunswick, Canada",
  "HU4 6TW, Kingston Upon Hull, England, United Kingdom",
  "Luna, Cagayan Valley, Philippines",
  "74029, Dewey, Oklahoma, United States",
  "21007, Huelva, Andalusia, Spain",
  "29800, Gumushane, Gümüşhane, Türkiye",
  "Lutuhynska miska hromada, Luhansk, Ukraine",
  "7751, Namsos, Trøndelag, Norway",
  "Dent, Minnesota, United States",
  "03600, Afyonkarahisar, Afyon, Türkiye",
  "Carter County, Montana, United States",
  "Ransom Town, Agasthiswaram, Tamil Nadu, India",
  "97320, Saint-Laurent-du-Maroni, Saint-Laurent-du-Maroni, French Guiana",
  "Powderham, England, United Kingdom",
  "Ternopil Raion, Ternopil, Ukraine",
  "Olhão, Faro, Portugal",
  "2870-430, Montijo, Setúbal, Portugal",
  "Fauquier, Ontario, Canada",
  "Wiseton, England, United Kingdom",
  "Hickory, Mississippi, United States",
  "Huron Shores, Ontario, Canada",
  "SA45 9NG, New Quay, Wales, United Kingdom",
  "142115, Podolsk, Moscow, Russia",
  "Greater Toulon Metropolitan Area",
  "James Madison University: Main Campus, Harrisonburg, Virginia, United States",
  "Marquette, Michigan, United States",
  "L13 0AP, Liverpool, England, United Kingdom",
  "Derzhavinskiy, Orenburg, Russia",
  "Grenada North, Wellington, Wellington, New Zealand",
  "600001, Bacău, Bacău, Romania",
  "Peebles Square, Wilkinsburg, Pennsylvania, United States",
  "427960, Sarapul, Udmurtia, Russia",
  "692510, Ussuriysk, Primorye, Russia",
  "613050, Kirovo-Chepetsk, Kirov, Russia",
  "Lowdermilk Area, Gilmer, North Carolina, United States",
  "London, Ohio, United States",
  "La Millardière, Saint-Sylvain-d'Anjou, Pays de la Loire, France",
  "Obshtina General Toshevo, Dobrich, Bulgaria",
  "Nova Odessa, São Paulo, Brazil",
  "275, Bremerhaven, Bremen, Germany",
  "140415, Kolomna, Moscow, Russia",
  "422003, Nashik, Maharashtra, India",
  "17018, Dauphin, Pennsylvania, United States",
  "7409, Kaposvár, Somogy, Hungary",
  "Geneva, Ohio, United States",
  "Northampton County, North Carolina, United States",
  "Arlington, Vermont, United States",
  "R7N 2Z1, Dauphin, Manitoba, Canada",
  "Duval, Saskatchewan, Canada",
  "173013, Velikiy Novgorod, Novgorod, Russia",
  "Innsbruck-Stadt, Tyrol, Austria",
  "50554, Laurens, Iowa, United States",
  "162606, Cherepovets, Vologda, Russia",
  "Cologne, Lombardy, Italy",
  "Bolivar, Tennessee, United States",
  "Christian Fields, Gravesend, England, United Kingdom",
  "Furnas, Mairiporã, São Paulo, Brazil",
  "La Marouzaie, Bouillé-Ménard, Pays de la Loire, France",
  "390026, Ryazan, Ryazan’, Russia",
  "Concho County, Texas, United States",
  "8010, Burrel, Dibrë, Albania",
  "Bihar, India",
  "Douglas, Northern Cape, South Africa",
  "617064, Krasnokamsk, Perm, Russia",
  "Dickson, Red Deer County, Alberta, Canada",
  "20700, Turku, Southwest Finland, Finland",
  "Big Horn County, Montana, United States",
  "Marlborough, Massachusetts, United States",
  "Manchester, Iowa, United States",
  "142100, Podolsk, Moscow, Russia",
  "Worthing, England, United Kingdom",
  "Sherburne Historic District, New York, United States",
  "Les Grimes, Loulans-Verchamp, Bourgogne-Franche-Comté, France",
  "606524, Balakhna, Nizhniy Novgorod, Russia",
  "Greater Wichita Falls, Texas Area",
  "152903, Rybinsk, Yaroslavl’, Russia",
  "Göreme, Nevşehir, Türkiye",
  "Greene County, Indiana, United States",
  "Burke, South Dakota, United States",
  "80401, Golden, Colorado, United States",
  "Hughesdale, Victoria, Australia",
  "Rajasthan, India",
  "Muratpaşa Bucağı, Antalya, Türkiye",
  "15500, Lahti, Päijät-Häme, Finland",
  "Woodland, California, United States",
  "Tashtagol, Kemerovo, Russia",
  "352930, Armavir, Krasnodar, Russia",
  "Adair Gardens, Knoxville, Tennessee, United States",
  "04915, Belfast, Maine, United States",
  "DD1 4NZ, Dundee, Scotland, United Kingdom",
  "83000, Toulon, Provence-Alpes-Côte d'Azur, France",
  "Greene County, North Carolina, United States",
  "Sherman, Illinois, United States",
  "R0C 2Z0, Stonewall, Manitoba, Canada",
  "973 46, Luleå, Norrbotten County, Sweden",
  "Rains County, Texas, United States",
  "14132, Sanborn, New York, United States",
  "Gibraltar",
  "Westchester, Little Rock, Arkansas, United States",
  "Romeo, Michigan, United States",
  "97084, Würzburg, Bavaria, Germany",
  "369003, Cherkessk, Karachay-Cherkessia, Russia",
  "Abrantes, Santarém, Portugal",
  "Onegama, North Central Province, Sri Lanka",
  "Wright County, Minnesota, United States",
  "Anson, Texas, United States",
  "48013, Bilbao, Basque Country, Spain",
  "165340, Kotlas, Arkhangel’sk, Russia",
  "Manheim Township, Pennsylvania, United States",
  "Morne-à-l'Eau, Pointe-à-Pitre, Guadeloupe",
  "3740061, Tatebayashi, Gunma, Japan",
  "Mali",
  "Taos County, New Mexico, United States",
  "Parma, Ohio, United States",
  "01003, Vitoria-Gasteiz, Basque Country, Spain",
  "Randall County, Texas, United States",
  "presidio plan de libres, Veracruz, Mexico",
  "353960, Novorossiysk, Krasnodar, Russia",
  "82061, Cheyenne, Wyoming, United States",
  "Clarion Village, Florida, United States",
  "Lake Luzerne, New York, United States",
  "23321, Chesapeake, Virginia, United States",
  "B3M 0A1, Halifax, Nova Scotia, Canada",
  "62442, Martinsville, Illinois, United States",
  "Otegen batyr, Almaty Region, Kazakhstan",
  "Bonn, North Rhine-Westphalia, Germany",
  "Clackamas Lake Ranger Station Historic District, Oregon, United States",
  "8500-286, Portimão, Faro, Portugal",
  "Ashtarak, Aragatsotn Province, Armenia",
  "7201, Blenheim, Marlborough, New Zealand",
  "Brulea, County Cork, Ireland",
  "C22D8D9, Atbasar, Akmola Region, Kazakhstan",
  "11312, Tallinn, Harjumaa, Estonia",
  "Shenandoah Farms, Virginia, United States",
  "Timbwani ward, Mombasa County, Kenya",
  "Blairs, Virginia, United States",
  "Lunan District, Hebei, China",
  "Kemaman, Terengganu, Malaysia",
  "22833, Harrisonburg, Virginia, United States",
  "660077, Krasnoyarsk, Krasnoyarsk Krai, Russia",
  "Doña Ana, San Cristóbal, Dominican Republic",
  "St Nicholas At Wade, England, United Kingdom",
  "6044, Charleroi, Walloon Region, Belgium",
  "Morton, Pennsylvania, United States",
  "Tomé-Açu, Pará, Brazil",
  "Rio Branco, Acre, Brazil",
  "Arlon, Walloon Region, Belgium",
  "20610, Turku, Southwest Finland, Finland",
  "Söderhamn, Gävleborg County, Sweden",
  "Piteşti, Argeş, Romania",
  "Peqin, Elbasan, Albania",
  "Antelope Run, Albuquerque, New Mexico, United States",
  "Travis County, Texas, United States",
  "Berkshire County, Massachusetts, United States",
  "Genthod, Geneva, Switzerland",
  "7634, Pécs, Baranya, Hungary",
  "45879, Paulding, Ohio, United States",
  "43553, Pettisville, Ohio, United States",
  "21242, Novi Sad, Vojvodina, Serbia",
  "13746, Chenango Forks, New York, United States",
  "38063, Ripley, Tennessee, United States",
  "Svenskoye, Bryansk, Russia",
  "02142, Cambridge, Massachusetts, United States",
  "97396, Umán, Yucatán, Mexico",
  "Handan, Hebei, China",
  "Roannes-Saint-Mary, Auvergne-Rhône-Alpes, France",
  "Vernon, Normandy, France",
  "4905-383, Viana do Castelo, Viana do Castelo, Portugal",
  "Burleigh Falls, Trent Lakes, Ontario, Canada",
  "Copenhagen, Capital Region of Denmark, Denmark",
  "6705, Robertson, Western Cape, South Africa",
  "Chase City, Virginia, United States",
  "Gates, Tennessee, United States",
  "Greenbrier East, Chesapeake, Virginia, United States",
  "Slobozia Moara, Dîmboviţa, Romania",
  "Boumalne Dades, Drâa-Tafilalet, Morocco",
  "R13B4A5, Beyneu, Mangystau Region, Kazakhstan",
  "Westmoreland, West Virginia, United States",
  "Macon, Arkansas, United States",
  "430223, Baia Mare, Maramureş, Romania",
  "Davison, Michigan, United States",
  "Holt, Wales, United Kingdom",
  "Kežmarok, Presov, Slovakia",
  "Stillwater, Minnesota, United States",
  "36080, Titus, Alabama, United States",
  "Webster County, Iowa, United States",
  "9612, Hammerfest, Troms og Finnmark, Norway",
  "27609, Raleigh, North Carolina, United States",
  "02040, Adıyaman, Adıyaman, Türkiye",
  "26030, Eskişehir, Eskişehir, Türkiye",
  "4000, Basel, Basel, Switzerland",
  "Satu Mare metropolitan area, Satu Mare, Romania",
  "Anderson, Alabama, United States",
  "Devoll, Korçë, Albania",
  "Palmela, Setúbal, Portugal",
  "Rockingham, Brudenell, Lyndoch and Raglan, Ontario, Canada",
  "Pickett, Wisconsin, United States",
  "37353, McDonald, Tennessee, United States",
  "Pearl City, Illinois, United States",
  "88, Sandnessjøen, Nordland, Norway",
  "Našice, Osijek-Baranja, Croatia",
  "99811, Juneau, Alaska, United States",
  "Kristiansand Region",
  "225766, Pinsk, Brest, Belarus",
  "Staunton On Wye, England, United Kingdom",
  "Xromtaw, Aqtöbe region, Kazakhstan",
  "Cernavodă, Constanţa, Romania",
  "142106, Podolsk, Moscow, Russia",
  "43907, Cadiz, Ohio, United States",
  "624551, Ivdel’, Sverdlovsk, Russia",
  "Ermoúpolis, Southern Aegean, Greece",
  "Korosten’, Zhytomyr, Ukraine",
  "Mitchell, England, United Kingdom",
  "De Witt County, Illinois, United States",
  "Kavajë, Tirana, Albania",
  "Bajram Curri, Kukës, Albania",
  "Borisogleb, Vladimir, Russia",
  "10462, Bronx, New York, United States",
  "Rio Grande do Norte, Brazil",
  "Redwood, New York, United States",
  "V0N 0C1, Pender Island, British Columbia, Canada",
  "La Tuilerie, Grimesnil, Normandy, France",
  "Orleans, Indiana, United States",
  "Vaughan, Ontario, Canada",
  "Chickasaw Oaks, Florida, United States",
  "Norfolk, England, United Kingdom",
  "Palma Campania, Campania, Italy",
  "152907, Rybinsk, Yaroslavl’, Russia",
  "Andrușul de Sus, Cahul District, Moldova",
  "Yellow Medicine County, Minnesota, United States",
  "Verkhoyansk, Sakha, Russia",
  "1111, Sofia, Sofia City, Bulgaria",
  "Korfez, Kocaeli, Türkiye",
  "Morris, Illinois, United States",
  "5240, Odense, Region of Southern Denmark, Denmark",
  "Kyiv, Kyiv City, Ukraine",
  "Swift Creek, North Carolina, United States",
  "Rappahannock County, Virginia, United States",
  "8222, Beringen, Schaffhausen, Switzerland",
  "Garfield, Pittsburgh, Pennsylvania, United States",
  "Mckinley, Catarman, Eastern Visayas, Philippines",
  "78600, Karabuk, Karabuk, Türkiye",
  "28703, San Sebastián de los Reyes, Community of Madrid, Spain",
  "26651, Summersville, West Virginia, United States",
  "8939 AA, Leeuwarden, Friesland, Netherlands",
  "P0H 1G0, Cache Bay, Ontario, Canada",
  "694002, Korsakov, Sakhalin, Russia",
  "2024 CB, Haarlem, North Holland, Netherlands",
  "Unna, North Rhine-Westphalia, Germany",
  "Pawnee, Oklahoma, United States",
  "Travis, New York, United States",
  "624763, Verkhnyaya Salda, Sverdlovsk, Russia",
  "V92 HD4V, Tralee, County Kerry, Ireland",
  "8783, Schwanden, Glarus, Switzerland",
  "220004, Minsk, Minsk, Belarus",
  "Poriyya Ilit, North District, Israel",
  "Ethiopia",
  "Ivanovo, Ivanovo, Russia",
  "29813, Barnwell, South Carolina, United States",
  "Foardal, County Kerry, Ireland",
  "Sauka, Limbdi, Gujarat, India",
  "Livingston County, New York, United States",
  "Yenişehir, Diyarbakır, Türkiye",
  "Ward 1, Ho Chi Minh City, Vietnam",
  "7107, Bitola, Pelagonia, North Macedonia",
  "6723, Szeged, Csongrád, Hungary",
  "687000, Aginskoye, Zabaykalsky Krai, Russia",
  "67012, Cagnano Amiterno, Abruzzi, Italy",
  "Fillmore, Indiana, United States",
  "8307, Peshkopi, Dibrë, Albania",
  "Collingwood, Ontario, Canada",
  "Cassano d'Adda, Lombardy, Italy",
  "48155, Münster, North Rhine-Westphalia, Germany",
  "Elk River, Minnesota, United States",
  "Humboldt, Santa Fe, Argentina",
  "9408 AA, Assen, Drenthe, Netherlands",
  "Tulsa, Oklahoma, United States",
  "12074, Galway, New York, United States",
  "82214, Guernsey, Wyoming, United States",
  "Jászapáti, Jász-Nagykun-Szolnok, Hungary",
  "89117, Las Vegas, Nevada, United States",
  "San Francisco de Conchos Municipality, Chihuahua, Mexico",
  "184610, Severomorsk, Murmansk, Russia",
  "60600, Tokat, Tokat, Türkiye",
  "Grayson Woods, California, United States",
  "Kudymkar, Komi-Permyak, Russia",
  "Gallianta, Cavarzere, Veneto, Italy",
  "725400, Rădăuţi, Suceava, Romania",
  "La Gohardière, Bouillé-Ménard, Pays de la Loire, France",
  "705200, Paşcani, Iaşi, Romania",
  "Powell, Wyoming, United States",
  "155334, Vichuga, Ivanovo, Russia",
  "Paris, Kentucky, United States",
  "38369, Rutherford, Tennessee, United States",
  "Wrightstown, New Jersey, United States",
  "97415, Brookings, Oregon, United States",
  "Warsaw, Mazowieckie, Poland",
  "629804, Noyabrsk, Yamalo-Nenets, Russia",
  "43126, Parma, Emilia-Romagna, Italy",
  "Trabzon, Trabzon, Türkiye",
  "Calhoun, Georgia, United States",
  "02600, Adıyaman, Adıyaman, Türkiye",
  "00210, Helsinki, Uusimaa, Finland",
  "Westmoreland County, Virginia, United States",
  "Nolan, Texas, United States",
  "Uttar Pradesh, India",
  "Ripley, Mississippi, United States",
  "Logan Central, Queensland, Australia",
  "Exeter, California, United States",
  "Manturovo, Kostroma, Russia",
  "Salem, South Carolina, United States",
  "Chemnitz, Saxony, Germany",
  "01260, Adana, Adana, Türkiye",
  "Terras de Bouro, Braga, Portugal",
  "Albergaria-A-Velha, Aveiro, Portugal",
  "Las Nueces, San Luis Potosí, Mexico",
  "33205, Gijón, Principality of Asturias, Spain",
  "831 46, Östersund, Jämtland County, Sweden",
  "Tallahatchie County, Mississippi, United States",
  "Yamhill County, Oregon, United States",
  "85264, Fort McDowell, Arizona, United States",
  "Miami County, Indiana, United States",
  "70668, Vinton, Louisiana, United States",
  "Swifts Creek, Victoria, Australia",
  "Qahar Right Front Banner, Inner Mongolia, China",
  "Cherokee, Oklahoma, United States",
  "Caruth Lake, Rockwall, Texas, United States",
  "Rankin, North Algona Wilberforce, Ontario, Canada",
  "Orenburg, Orenburg, Russia",
  "Anguilla",
  "CV7 7AZ, Berkswell, England, United Kingdom",
  "34123, Trieste, Friuli-Venezia Giulia, Italy",
  "Wilkes County, Georgia, United States",
  "Saline County, Kansas, United States",
  "Sumter County, Florida, United States",
  "Lahti, Päijät-Häme, Finland",
  "291, Kristianstad, Skåne County, Sweden",
  "21215, Baltimore, Maryland, United States",
  "Nolan's Station, Montague, Ontario, Canada",
  "Bellinzago Lombardo, Lombardy, Italy",
  "DG2 0AA, Dumfries, Scotland, United Kingdom",
  "Walkervale, Queensland, Australia",
  "Dawes County, Nebraska, United States",
  "96100, Rovaniemi, Lapland, Finland",
  "430013, Baia Mare, Maramureş, Romania",
  "47060, Mardin, Mardin, Türkiye",
  "Snyder Estate Natural Cement Historic District, Rosendale, New York, United States",
  "34202, Kragujevac, Centralna Srbija, Serbia",
  "230002, Slatina, Olt, Romania",
  "98390, Sumner, Washington, United States",
  "06260, Ankara, Ankara, Türkiye",
  "Cedar Creek, Texas, United States",
  "New Haven County, Connecticut, United States",
  "Douglas, Queensland, Australia",
  "15-444, Białystok, Podlaskie, Poland",
  "Slavyansk, Donetsk, Ukraine",
  "4407, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "Doornfontein, Johannesburg, Gauteng, South Africa",
  "Overton, Texas, United States",
  "Dal'nerechensk, Primorye, Russia",
  "24853, Kimball, West Virginia, United States",
  "Uray, Khanty-Mansi, Russia",
  "Greater Rockford Area",
  "Cattaraugus, New York, United States",
  "Dyer, Arkansas, United States",
  "Galaxia, Hidalgo, Mexico",
  "Çiftlik, Niğde, Türkiye",
  "Harper County, Oklahoma, United States",
  "399772, Yelets, Lipetsk, Russia",
  "32300, Isparta, Isparta, Türkiye",
  "Kirovskiy, Kursk, Russia",
  "Warren, Indiana, United States",
  "70200, Embilipitiya, Sabaragamuwa Province, Sri Lanka",
  "DT1 1QX, Dorchester, England, United Kingdom",
  "Oneida County, Idaho, United States",
  "1052, Le Mont-sur-Lausanne, Vaud, Switzerland",
  "Valence, Auvergne-Rhône-Alpes, France",
  "Terryville, Connecticut, United States",
  "658837, Slavgorod, Altai Krai, Russia",
  "618541, Solikamsk, Perm, Russia",
  "Moscow, Ohio, United States",
  "Kerchik-Savrov, Rostov, Russia",
  "Debrecen Metropolitan Area",
  "Oktyabr'skiy, Belgorod, Russia",
  "Allen County, Indiana, United States",
  "Butler County, Missouri, United States",
  "CF11 0AA, Cardiff, Wales, United Kingdom",
  "Fremont, Wisconsin, United States",
  "682469, Nikolayevsk-na-Amure, Khabarovsk, Russia",
  "Turaif, Northern Frontier, Saudi Arabia",
  "Pocahontas, Virginia, United States",
  "Forest Town, England, United Kingdom",
  "Blaine County, Oklahoma, United States",
  "Ryazanovskiy, Moscow, Russia",
  "San Diego, California, United States",
  "90134, Palermo, Sicily, Italy",
  "Greeley, Stillwater, Minnesota, United States",
  "Thurston County, Washington, United States",
  "Huron, New York, United States",
  "Binagady Raion, Baku Ekonomic Zone, Azerbaijan",
  "Burrel, Dibrë, Albania",
  "Cavalier, Labarthète, Occitanie, France",
  "4108, Coopers Plains, Queensland, Australia",
  "X0E 0J0, Fort McPherson, Northwest Territories, Canada",
  "John's Woods, Lake Oswego, Oregon, United States",
  "Devanakonda, Andhra Pradesh, India",
  "Krasnoufimsky District, Sverdlovsk, Russia",
  "02090, Kyiv, Kyiv City, Ukraine",
  "306430, Rusanovo, Kursk, Russia",
  "4150, Rakovski, Plovdiv, Bulgaria",
  "641471, Kurtamysh, Kurgan, Russia",
  "4848, Arendal, Agder, Norway",
  "Salles-en-Toulon, Nouvelle-Aquitaine, France",
  "Sumner, Nebraska, United States",
  "San Nicolás de Los Garza, Nuevo León, Mexico",
  "52338, Swisher, Iowa, United States",
  "Jefferson County, Ohio, United States",
  "584 32, Linköping, Östergötland County, Sweden",
  "Kingsbury, Quebec, Canada",
  "Charlestown, Boston, Massachusetts, United States",
  "Măcin, Tulcea, Romania",
  "404540, Volgodonskoy, Volgograd, Russia",
  "Vyshniy Volochek, Tver’, Russia",
  "236001, Kaliningrad, Kaliningrad, Russia",
  "Kondopoga, Karelia, Russia",
  "715 00, Iráklion, Crete, Greece",
  "97311, Roura, Cayenne, French Guiana",
  "Küssnacht, Schwyz, Switzerland",
  "73069, Norman, Oklahoma, United States",
  "76187, Karlsruhe, Baden-Württemberg, Germany",
  "Pine Island, Minnesota, United States",
  "Arabkir, Yerevan, Armenia",
  "Warren, Michigan, United States",
  "Northumberland, Alnwick/Haldimand, Ontario, Canada",
  "66211, Tulungagung, East Java, Indonesia",
  "85-067, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "Oktyabr'skiy, Sakha, Russia",
  "2563, Kyustendil, Kyustendil, Bulgaria",
  "Pori, Satakunta, Finland",
  "Laneuveville-devant-Nancy, Grand Est, France",
  "456802, Verkhniy Ufaley, Chelyabinsk, Russia",
  "27360, Thomasville, North Carolina, United States",
  "Hampton Hargate, Peterborough, England, United Kingdom",
  "Greater Karlovac Area",
  "550003, Sibiu, Sibiu, Romania",
  "TR18 4DZ, Penzance, England, United Kingdom",
  "Altınyayla, Burdur, Türkiye",
  "Grozny, Chechnya, Russia",
  "Mason County, Michigan, United States",
  "Bristol County, Massachusetts, United States",
  "M28G5E1, Temirtau, Karaganda Region, Kazakhstan",
  "Sterling, New York, United States",
  "31699, Moody Air Force Base, Georgia, United States",
  "1200, Vienna, Vienna, Austria",
  "3519, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "HU4 6AB, Kingston Upon Hull, England, United Kingdom",
  "82071, Laramie, Wyoming, United States",
  "6976, Lugano, Ticino, Switzerland",
  "4601, Rrëshen, Lezhë, Albania",
  "Miami County, Ohio, United States",
  "601 34, Katerini, Central Macedonia, Greece",
  "San Francisco, California, United States",
  "Hill County, Texas, United States",
  "NR5 0AA, Norwich, England, United Kingdom",
  "Newton, New Hampshire, United States",
  "Saint-Jean de Gallatin, Geneva, Switzerland",
  "4607, Rrëshen, Lezhë, Albania",
  "4701, Laç, Lezhë, Albania",
  "Deleni, Iaşi, Romania",
  "Hofgeismar, Hesse, Germany",
  "Halifax, England, United Kingdom",
  "Howard, Wisconsin, United States",
  "34125, Kassel, Hesse, Germany",
  "26001, Logroño, Autonomía de La Rioja, Spain",
  "424003, Yoshkar-Ola, Mari El, Russia",
  "627016, Yalutorovsk, Tyumen’, Russia",
  "2806, Gjøvik, Innlandet, Norway",
  "Medina del Campo, Castilla and Leon, Spain",
  "05737, Chittenden, Vermont, United States",
  "El Astillero, Cantabria, Spain",
  "427974, Sarapul, Udmurtia, Russia",
  "28340, Giresun, Giresun, Türkiye",
  "New Jersey, United States",
  "NN3 9JQ, Northampton, England, United Kingdom",
  "31643, Quitman, Georgia, United States",
  "Richmond, England, United Kingdom",
  "456803, Verkhniy Ufaley, Chelyabinsk, Russia",
  "E6H 1H2, Ritchie, New Brunswick, Canada",
  "Buncrana, County Donegal, Ireland",
  "Sierra Vista, Arizona, United States",
  "Hillsboro, Oregon, United States",
  "24256, Haysi, Virginia, United States",
  "78624, Fredericksburg, Texas, United States",
  "M28C5D9, Aqtau, Karaganda Region, Kazakhstan",
  "Warren County, New Jersey, United States",
  "360016, Nalchik, Kabardino-Balkaria, Russia",
  "70597, Stuttgart, Baden-Württemberg, Germany",
  "50735, Cologne, North Rhine-Westphalia, Germany",
  "Dillon, Missouri, United States",
  "Carsonville, Michigan, United States",
  "M1G 0A1, Scarborough, Ontario, Canada",
  "Belluno, Veneto, Italy",
  "Greater Rio de Janeiro",
  "5000, Veliko Tŭrnovo, Veliko Turnovo, Bulgaria",
  "Spring Hill, Florida, United States",
  "Hopewell, Nova Scotia, Canada",
  "Danville, Arkansas, United States",
  "North Dakota, United States",
  "Tours-en-Savoie, Auvergne-Rhône-Alpes, France",
  "Coalinga, California, United States",
  "Kenosha County, Wisconsin, United States",
  "Chelan, Caubous, Occitanie, France",
  "010 07, Žilina, Zilina, Slovakia",
  "SA3 4RJ, Caswell, Wales, United Kingdom",
  "951 22, Alekšince, Nitra Region, Slovakia",
  "Tejerías, Puerto Cabello, Carabobo State, Venezuela",
  "433505, Dimitrovgrad, Ul’yanovsk, Russia",
  "RM16 2WJ, Grays, England, United Kingdom",
  "45380, Versailles, Ohio, United States",
  "Athens, Ontario, Canada",
  "97111, Morne-à-l'Eau, Pointe-à-Pitre, Guadeloupe",
  "Librazhd, Elbasan, Albania",
  "Eforie, Constanţa, Romania",
  "18703, Wilkes-Barre, Pennsylvania, United States",
  "33050, Mersin, İçel, Türkiye",
  "61100, Trabzon, Trabzon, Türkiye",
  "Dubna, Tver’, Russia",
  "Anderson, Missouri, United States",
  "Municipio Bolívar, Yaracuy State, Venezuela",
  "Glacier Highlands Greenbrier, Ann Arbor, Michigan, United States",
  "Putnam County, Florida, United States",
  "Orangeburg Park, Modesto, California, United States",
  "390013, Ryazan, Ryazan’, Russia",
  "81-362, Gdynia, Pomorskie, Poland",
  "Rovaniemi sub-region, Lapland, Finland",
  "Lihula linn, Pärnu maakond, Estonia",
  "412424, Atkarsk, Saratov, Russia",
  "48471, Sandusky, Michigan, United States",
  "Faulkland, Frome, England, United Kingdom",
  "Eksjö kommun, Jönköping County, Sweden",
  "Dundyvan Industrial Estate, Coatbridge, Scotland, United Kingdom",
  "Eemsdelta, Groningen, Netherlands",
  "Hanson, Kentucky, United States",
  "13796, Laurens, New York, United States",
  "Neversink, New York, United States",
  "35080, İzmir, İzmir, Türkiye",
  "41-900, Bytom, Śląskie, Poland",
  "DT11 8BY, Tarrant Launceston, England, United Kingdom",
  "French Polynesia",
  "Chester, New Jersey, United States",
  "542 74, Mariestad, Västra Götaland County, Sweden",
  "Hanover Park, Illinois, United States",
  "37203, Nashville, Tennessee, United States",
  "4507, Lezhë, Lezhë, Albania",
  "Basel-Country, Switzerland",
  "78145, Pawnee, Texas, United States",
  "352 42, Växjö, Kronoberg County, Sweden",
  "Belknap County, New Hampshire, United States",
  "Carteret, New Jersey, United States",
  "Dickeyville, Wisconsin, United States",
  "Butte County, California, United States",
  "South Ziebach, South Dakota, United States",
  "40-001, Katowice, Śląskie, Poland",
  "9010, Lushnjë, Fier, Albania",
  "Walkerville, Gauteng, South Africa",
  "35243, Birmingham, Alabama, United States",
  "624330, Krasnoural’sk, Sverdlovsk, Russia",
  "200030, Craiova, Dolj, Romania",
  "48143, Münster, North Rhine-Westphalia, Germany",
  "171504, Kimry, Tver’, Russia",
  "Feira Nova, Pernambuco, Brazil",
  "Brown County, Kansas, United States",
  "3937, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "28500, Dolynska, Kirovohrad, Ukraine",
  "676852, Belogorsk, Amur, Russia",
  "Sibley, Missouri, United States",
  "43812, Coshocton, Ohio, United States",
  "35392, Giessen, Hesse, Germany",
  "42107, Wuppertal, North Rhine-Westphalia, Germany",
  "Wasco, California, United States",
  "360004, Nalchik, Kabardino-Balkaria, Russia",
  "84111, Salt Lake City, Utah, United States",
  "Constanţa, Romania",
  "SA69 9AB, Saundersfoot, Wales, United Kingdom",
  "Forest, Ontario, Canada",
  "166723, Indiga, Nenets, Russia",
  "95519, McKinleyville, California, United States",
  "Burton Joyce, England, United Kingdom",
  "68370, Kokkola, Central Ostrobothnia, Finland",
  "Grant, Nebraska, United States",
  "Howard Springs, Northern Territory, Australia",
  "Terebovlya, Ternopil, Ukraine",
  "658089, Novoaltaysk, Altai Krai, Russia",
  "623116, Pervoural'sk, Sverdlovsk, Russia",
  "BT78 1HE, Omagh, Northern Ireland, United Kingdom",
  "100003, Ploieşti, Prahova, Romania",
  "Karnes City, Texas, United States",
  "McLeod 185, Saskatchewan, Canada",
  "Larnaca, Cyprus",
  "972 32, Luleå, Norrbotten County, Sweden",
  "B25C1T9, Esik, Almaty Region, Kazakhstan",
  "163071, Archangel, Arkhangel’sk, Russia",
  "DL2 2UE, Walworth Gate, England, United Kingdom",
  "50500, Nevsehir, Nevşehir, Türkiye",
  "B25B4F2, Esik, Almaty Region, Kazakhstan",
  "56000, Siirt, Siirt, Türkiye",
  "Hills at Hunterdon, West Amwell, New Jersey, United States",
  "433513, Dimitrovgrad, Ul’yanovsk, Russia",
  "Galaxídhion, Central Greece, Greece",
  "Altunhisar Bucağı, Niğde, Türkiye",
  "Kallithea, Southern Aegean, Greece",
  "Trabzon, Türkiye",
  "307170, Zheleznogorsk, Kursk, Russia",
  "Waynesboro, Georgia, United States",
  "Lanesville, Indiana, United States",
  "188306, Gatchina, Leningrad, Russia",
  "Greene, Rhode Island, United States",
  "Lake Arthur, Louisiana, United States",
  "12496, Windham, New York, United States",
  "Përmet, Gjirokastër, Albania",
  "Shelby, North Carolina, United States",
  "41005, Seville, Andalusia, Spain",
  "364037, Groznyy, Chechnya, Russia",
  "71005, Viljandi, Viljandimaa, Estonia",
  "Voznesenskoye, Rostov, Russia",
  "42241, Hopkinsville, Kentucky, United States",
  "V3H 3J7, Port Moody, British Columbia, Canada",
  "37874, Sweetwater, Tennessee, United States",
  "Morris County, Texas, United States",
  "Lillesand, Agder, Norway",
  "Noblesville, Indiana, United States",
  "44839, Huron, Ohio, United States",
  "Kaufman, Texas, United States",
  "Neu-Bamberg, Rhineland-Palatinate, Germany",
  "Snyder, Texas, United States",
  "55246, Wiesbaden, Hesse, Germany",
  "61279, Reynolds, Illinois, United States",
  "Ellison Bay, Wisconsin, United States",
  "582 27, Linköping, Östergötland County, Sweden",
  "Richland, Michigan, United States",
  "200425, Craiova, Dolj, Romania",
  "231292, Lida, Hrodna, Belarus",
  "Maury, Condat-sur-Vienne, Nouvelle-Aquitaine, France",
  "Bacau Metropolitan Area",
  "Sorong Barat, West Papua, Indonesia",
  "H34E7C5, Qulan, Jambyl Region, Kazakhstan",
  "01864, North Reading, Massachusetts, United States",
  "42030, Konya, Konya, Türkiye",
  "Gorodskoy Okrug Livny, Orel, Russia",
  "Çorlu Bucağı, Tekirdağ, Türkiye",
  "Howard, Colorado, United States",
  "Tompkins County, New York, United States",
  "Leakesville, Mississippi, United States",
  "Sparks, Nevada, United States",
  "Curry, County Roscommon, Ireland",
  "Chautauqua, Colorado, United States",
  "SA69 9LS, Saundersfoot, Wales, United Kingdom",
  "252 21, Helsingborg, Skåne County, Sweden",
  "Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Clayton South, Victoria, Australia",
  "211394, Orsha, Vitsyebsk, Belarus",
  "Biysky District, Altai Krai, Russia",
  "Angelina Quirino, Cláudio, Minas Gerais, Brazil",
  "94662, Emeryville, California, United States",
  "Vlorë, Vlorë, Albania",
  "Schuyler County, Illinois, United States",
  "LU4 9DL, Luton, England, United Kingdom",
  "1201, Geneva, Geneva, Switzerland",
  "7080-132, Vendas Novas, Évora, Portugal",
  "Wickwar, England, United Kingdom",
  "Stark, New York, United States",
  "CB10 2LE, Sewards End, England, United Kingdom",
  "Söderhamns kommun, Gävleborg County, Sweden",
  "50435, Floyd, Iowa, United States",
  "Otterbein, Indiana, United States",
  "Indre-et-Loire, Centre-Val de Loire, France",
  "South Africa",
  "Saline, Illinois, United States",
  "1600-001, Lisbon, Lisbon, Portugal",
  "55421, Minneapolis, Minnesota, United States",
  "29407, Charleston, South Carolina, United States",
  "Davidson, Missouri, United States",
  "65757, Strafford, Missouri, United States",
  "C00T1B3, Kokshetau, Akmola Region, Kazakhstan",
  "44124, Cleveland, Ohio, United States",
  "Dunning, Nebraska, United States",
  "Parkerville, Western Australia, Australia",
  "33739, Bielefeld, North Rhine-Westphalia, Germany",
  "29010-001, Vitória, Espírito Santo, Brazil",
  "Severnyy, Nizhniy Novgorod, Russia",
  "Lagos, Lagos State, Nigeria",
  "Syktyvkar, Komi, Russia",
  "Camden County, New Jersey, United States",
  "86153, Augsburg, Bavaria, Germany",
  "Carver, Mobile, Alabama, United States",
  "300667, Timişoara, Timiş, Romania",
  "Beckhampton, England, United Kingdom",
  "Golden, Illinois, United States",
  "N1M 2P9, Fergus, Ontario, Canada",
  "45144, Essen, North Rhine-Westphalia, Germany",
  "58030, Sivas, Sivas, Türkiye",
  "423577, Nizhnekamsk, Tatarstan, Russia",
  "Kotovskoye, Volgograd, Russia",
  "Trinity, Alabama, United States",
  "Morgan County, Kentucky, United States",
  "Popeşti, Bihor, Romania",
  "PA15 4RJ, Greenock, Scotland, United Kingdom",
  "85251, Scottsdale, Arizona, United States",
  "Kurtamysh, Kurgan, Russia",
  "Kingsley, Pembina, Manitoba, Canada",
  "Kendall Lakes, Florida, United States",
  "Montgomery County, Pennsylvania, United States",
  "Sargents, New Hampshire, United States",
  "22206, Arlington, Virginia, United States",
  "84757, New Harmony, Utah, United States",
  "450031, Zalău, Sălaj, Romania",
  "Laguna Woods, California, United States",
  "21080, Diyarbakır, Diyarbakır, Türkiye",
  "Sullivan, New York, United States",
  "Danville, Indiana, United States",
  "Gjinar, Elbasan, Albania",
  "654044, Novokuznetsk, Kemerovo, Russia",
  "Danville, Ohio, United States",
  "BT26 6AE, Hillsborough, Northern Ireland, United Kingdom",
  "Vimmerby, Kalmar County, Sweden",
  "Privolzhsk, Ivanovo, Russia",
  "Les Pièces Nobles, Brunet, Provence-Alpes-Côte d'Azur, France",
  "357115, Nevinnomyssk, Stavropol, Russia",
  "51104, Sioux City, Iowa, United States",
  "Dooradoyle, Limerick, County Limerick, Ireland",
  "453837, Sibay, Bashkortostan, Russia",
  "78217, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "4183, Puketapu, Hawke's Bay, New Zealand",
  "Monaco",
  "Florence, Massachusetts, United States",
  "Kirkwall, Scotland, United Kingdom",
  "Chita, Morogoro Region, Tanzania",
  "LU2 7AZ, Luton, England, United Kingdom",
  "Hughesville, Missouri, United States",
  "9023, Győr, Győr-Moson-Sopron, Hungary",
  "694211, Poronaysk, Sakhalin, Russia",
  "Wheatland, Oklahoma, United States",
  "72851, New Blaine, Arkansas, United States",
  "Iowa City-Cedar Rapids Area",
  "Cambria, California, United States",
  "Metz-le-Comte, Bourgogne-Franche-Comté, France",
  "Dawson, Nebraska, United States",
  "39573, Perkinston, Mississippi, United States",
  "75963, Nacogdoches, Texas, United States",
  "306431, Rusanovo, Kursk, Russia",
  "Leake County, Mississippi, United States",
  "Plastunovskaya, Krasnodar, Russia",
  "Šibenik, Sibenik-Knin, Croatia",
  "Hudson, Massachusetts, United States",
  "Haskell, Oklahoma, United States",
  "32303, Čačak, Centralna Srbija, Serbia",
  "43420, Fremont, Ohio, United States",
  "8008, Zurich, Zurich, Switzerland",
  "692539, Ussuriysk, Primorye, Russia",
  "Nuckolls County, Nebraska, United States",
  "304 00, Agrínio, Western Greece, Greece",
  "Chitaraque, Boyacá, Colombia",
  "Kendall, New York, United States",
  "33525, Dade City, Florida, United States",
  "Esposende, Braga, Portugal",
  "Chistopolsky District, Tatarstan, Russia",
  "Floyds Knobs, Indiana, United States",
  "OX39 4RU, Kingston Blount, England, United Kingdom",
  "Abaza, Khakassia, Russia",
  "Kokkola, Central Ostrobothnia, Finland",
  "600000, Vladimir, Vladimir, Russia",
  "810001, Brăila, Brăila, Romania",
  "624764, Verkhnyaya Salda, Sverdlovsk, Russia",
  "Kiel, Wisconsin, United States",
  "Cagayan, Cagayan Valley, Philippines",
  "Kerrs Creek, Virginia, United States",
  "Saki, Crimea, Ukraine",
  "58220, Cavalier, North Dakota, United States",
  "Staunton City County, Virginia, United States",
  "Veenendaal, Utrecht, Netherlands",
  "452616, Oktyabrsky, Bashkortostan, Russia",
  "Emery, South Dakota, United States",
  "Arthur, Nebraska, United States",
  "2032 GC, Haarlem, North Holland, Netherlands",
  "33540, Tampere, Pirkanmaa, Finland",
  "99655, Sodankylä, Lapland, Finland",
  "61467, Oneida, Illinois, United States",
  "Rosebud, Texas, United States",
  "9008, Tromsø, Troms og Finnmark, Norway",
  "5610, Békéscsaba, Békés, Hungary",
  "95639, Hood, California, United States",
  "347812, Kamensk-Shakhtinskiy, Rostov, Russia",
  "Baker County, Oregon, United States",
  "450151, Zalău, Sălaj, Romania",
  "Mississippi Mills, Ontario, Canada",
  "Clay County, Missouri, United States",
  "Qendër Bilisht, Korçë, Albania",
  "Mountainous Shirvan, Azerbaijan",
  "Petropavlovskaya Borshchagovka, Kyiv, Ukraine",
  "Shijak, Durres, Albania",
  "Aberdeen, Maryland, United States",
  "6200-001, Covilhã, Castelo Branco, Portugal",
  "Limestone Creek, Florida, United States",
  "Dixon, Iowa, United States",
  "Karabük, Karabuk, Türkiye",
  "Sveti Filip i Jakov, Zadar, Croatia",
  "Leland Acres, North Albemarle, North Carolina, United States",
  "655007, Abakan, Khakassia, Russia",
  "Sumter County, South Carolina, United States",
  "Ireland",
  "Strafford Park, Buenaventura Lakes, Florida, United States",
  "Harmanli Municipality, Khaskovo, Bulgaria",
  "Luleå, Norrbotten County, Sweden",
  "Petersburg, Illinois, United States",
  "Cavalier, Gorgo al Monticano, Veneto, Italy",
  "Caldwell, Texas, United States",
  "Beclean, Bistriţa-Năsăud, Romania",
  "3053, Carlton, Victoria, Australia",
  "Amurska silska rada, Crimea, Ukraine",
  "Olmstedville, New York, United States",
  "Timiryazevskiy, Altai Krai, Russia",
  "Johnston City, Illinois, United States",
  "Carbonear, Newfoundland and Labrador, Canada",
  "Graham County, Kansas, United States",
  "Waynesboro, Pennsylvania, United States",
  "53144, Kenosha, Wisconsin, United States",
  "364046, Groznyy, Chechnya, Russia",
  "Valletta, Malta",
  "00500, Helsinki, Uusimaa, Finland",
  "692918, Nakhodka, Primorye, Russia",
  "Comisión de Fomento de Gobernador Duval, La Pampa, Argentina",
  "Taney County, Missouri, United States",
  "La Couderquie, Laramière, Occitanie, France",
  "Grant Parish County, Louisiana, United States",
  "656039, Barnaul, Altai Krai, Russia",
  "Bates City, Missouri, United States",
  "Cologne, North Rhine-Westphalia, Germany",
  "Sion Mills, Northern Ireland, United Kingdom",
  "Lincoln, Illinois, United States",
  "50854, Mount Ayr, Iowa, United States",
  "725200, Fălticeni, Suceava, Romania",
  "Kandiyohi County, Minnesota, United States",
  "Nicholas Denys, New Brunswick, Canada",
  "Zemen, Pernik, Bulgaria",
  "Teton Bari Railway Colony, Helem, Assam, India",
  "20320, Turku, Southwest Finland, Finland",
  "75204, Dallas, Texas, United States",
  "Redwood, Christchurch, Canterbury, New Zealand",
  "692050, Lesozavodsk, Primorye, Russia",
  "Otter, Arkansas, United States",
  "Tarragona, Davao Region, Philippines",
  "Kırklareli, Türkiye",
  "Harlan, Kentucky, United States",
  "Humboldt County, Iowa, United States",
  "Partizanskiy, Kalmykia, Russia",
  "Colfax, North Dakota, United States",
  "Aldán (San Cibrán), Galicia, Spain",
  "Meeker County, Minnesota, United States",
  "Lac-Saint-Jean-Est Regional County Municipality, Quebec, Canada",
  "6023, Innsbruck, Tyrol, Austria",
  "43029, Traversetolo, Emilia-Romagna, Italy",
  "Port Elizabeth Metropolitan Area",
  "Molde, Møre og Romsdal, Norway",
  "08057, Moorestown, New Jersey, United States",
  "Kayes, Mali",
  "Aberdeen, Saskatchewan, Canada",
  "Kapustin Yar, Astrakhan’, Russia",
  "Menlo Park, Walla Walla, Washington, United States",
  "38020, Kayseri, Kayseri, Türkiye",
  "Aghstafa District, Qazakh-Tovuz, Azerbaijan",
  "45662, Portsmouth, Ohio, United States",
  "Twin Falls County, Idaho, United States",
  "Tarrant Rushton, England, United Kingdom",
  "33971, Lehigh Acres, Florida, United States",
  "Eastland, Fort Worth, Texas, United States",
  "Fayette, Missouri, United States",
  "169347, Ukhta, Komi, Russia",
  "South Sulawesi, Indonesia",
  "243014, Novozybkov, Bryansk, Russia",
  "1505, Krujë, Durres, Albania",
  "Kingsbury, Texas, United States",
  "00380, Helsinki, Uusimaa, Finland",
  "08001, Vilnius, Vilniaus, Lithuania",
  "Yates Meadow, Concord, North Carolina, United States",
  "Greater Vigo Metropolitan Area",
  "Webb, Iowa, United States",
  "Tunisia",
  "300 10, Agrínio, Western Greece, Greece",
  "12600, Bingol, Bingöl, Türkiye",
  "34219, Kragujevac, Centralna Srbija, Serbia",
  "Shamkir, Qazakh-Tovuz, Azerbaijan",
  "Callaway, Virginia, United States",
  "Ternopil, Ukraine",
  "Dwight–Hooker Avenue Historic District, Poughkeepsie, New York, United States",
  "Windham, Vermont, United States",
  "76112, Fort Worth, Texas, United States",
  "Fall River, Massachusetts, United States",
  "Beyneu District, Mangystau Region, Kazakhstan",
  "53223, Milwaukee, Wisconsin, United States",
  "2500-261, Caldas da Rainha, Leiria, Portugal",
  "648482, Strelka-Chunya, Evenki, Russia",
  "San Lorenzo, Esmeraldas, Ecuador",
  "Como, North Carolina, United States",
  "7110, Bitola, Pelagonia, North Macedonia",
  "Toropets, Tver’, Russia",
  "06360, Norwich, Connecticut, United States",
  "831 47, Östersund, Jämtland County, Sweden",
  "S0G 0H9, Davidson, Saskatchewan, Canada",
  "665459, Usol'ye-Sibirskoye, Irkutsk, Russia",
  "94102, Klaipėda, Klaipėdos, Lithuania",
  "Kanash, Tyumen’, Russia",
  "629763, Nadym, Yamalo-Nenets, Russia",
  "New Brunswick, Canada",
  "392 47, Kalmar, Kalmar County, Sweden",
  "652475, Anzhero-Sudzhensk, Kemerovo, Russia",
  "Bennett, Lafayette, Indiana, United States",
  "353482, Gelendzhik, Krasnodar, Russia",
  "40530, Jyväskylä, Central Finland, Finland",
  "70575, Abbeville, Louisiana, United States",
  "South Walsham, England, United Kingdom",
  "3500, Krems an der Donau, Lower Austria, Austria",
  "61728, Colfax, Illinois, United States",
  "Greenwood, Arkansas, United States",
  "57125, Livorno, Tuscany, Italy",
  "84122, Salerno, Campania, Italy",
  "Timiryazevskoye, Irkutsk, Russia",
  "Dzerzhinskiy, Krasnoyarsk Krai, Russia",
  "Oktyabrsky, Khabarovsk, Russia",
  "Borisoglebsky District, Yaroslavl’, Russia",
  "47631, New Harmony, Indiana, United States",
  "DeWitt Charter Township, Michigan, United States",
  "423259, Leninogorsk, Tatarstan, Russia",
  "Teton, Gohad, Madhya Pradesh, India",
  "Durham County, North Carolina, United States",
  "Arthurs Seat, Victoria, Australia",
  "Howard, Michigan, United States",
  "Severnyy, Tomsk, Russia",
  "B25D7H1, Esik, Almaty Region, Kazakhstan",
  "Portland, Oregon Metropolitan Area",
  "Sandersdorf, Saxony-Anhalt, Germany",
  "Williamsburg, Ohio, United States",
  "Sainte-Sabine-de-Bellechasse, Quebec, Canada",
  "693005, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Kaposvári Járás, Somogy, Hungary",
  "Tulameen, British Columbia, Canada",
  "Peshkopi, Dibrë, Albania",
  "44309, Dortmund, North Rhine-Westphalia, Germany",
  "Calhoun, Missouri, United States",
  "412755, Severnyy, Saratov, Russia",
  "180004, Pskov, Pskov, Russia",
  "Kudymkarsky District, Perm, Russia",
  "S13M7G0, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Canada",
  "37922, Knoxville, Tennessee, United States",
  "Seneca, Kansas, United States",
  "710014, Botoşani, Botoşani, Romania",
  "Muscogee County, Georgia, United States",
  "Rutherford County, Tennessee, United States",
  "Erie, Colorado, United States",
  "24418, Subotica, Vojvodina, Serbia",
  "Clinton County, Ohio, United States",
  "IP13 0NJ, Pettistree, England, United Kingdom",
  "40127, Bologna, Emilia-Romagna, Italy",
  "Gorodskoy Okrug Klintsy, Bryansk, Russia",
  "8500-313, Portimão, Faro, Portugal",
  "Stone Mills, Ontario, Canada",
  "Berlin, Wisconsin, United States",
  "Lake Elsinore, California, United States",
  "10466, Bronx, New York, United States",
  "Arezzo, Tuscany, Italy",
  "Cleveland, Georgia, United States",
  "34123, Kassel, Hesse, Germany",
  "CF11 9DS, Cardiff, Wales, United Kingdom",
  "Champaign County, Illinois, United States",
  "02879, Wakefield, Rhode Island, United States",
  "Greater Naples Metropolitan Area",
  "665452, Usol'ye-Sibirskoye, Irkutsk, Russia",
  "Natrona, Wyoming, United States",
  "Swainbost, Scotland, United Kingdom",
  "Newberry, Michigan, United States",
  "Buenaventura, Valle del Cauca, Colombia",
  "Youngstown, Ohio, United States",
  "Golden Bay, Western Australia, Australia",
  "678965, Neryungri, Sakha, Russia",
  "Wilson, Virginia, United States",
  "Vanceboro, Maine, United States",
  "Wisewood, Sheffield, England, United Kingdom",
  "94107, Klaipėda, Klaipėdos, Lithuania",
  "49821, Daggett, Michigan, United States",
  "Amelia Court House, Virginia, United States",
  "Tbilisi, Georgia",
  "M52D2T6, Osakarovka, Karaganda Region, Kazakhstan",
  "72040, Batman, Batman, Türkiye",
  "TR18 3AB, Penzance, England, United Kingdom",
  "Chester, Massachusetts, United States",
  "Öskemen q.ä., East Kazakhstan Region, Kazakhstan",
  "11005, Cadiz, Andalusia, Spain",
  "Troyan, Lovech, Bulgaria",
  "54100, Nancy, Grand Est, France",
  "606417, Balakhna, Nizhniy Novgorod, Russia",
  "Omsk, Omsk, Russia",
  "86300, Comalcalco, Tabasco, Mexico",
  "13374, Semenivka, Zhytomyr, Ukraine",
  "76483, Throckmorton, Texas, United States",
  "Yevpatoriya, Crimea, Ukraine",
  "182640, Borovichi, Pskov, Russia",
  "Pecos, Texas, United States",
  "29492, Charleston, South Carolina, United States",
  "Kalnciems Parish, Jelgava Municipality, Latvia",
  "Novomoskovsk Raion, Dnipropetrovsk, Ukraine",
  "29401, Charleston, South Carolina, United States",
  "12304, Schenectady, New York, United States",
  "35616, Cherokee, Alabama, United States",
  "35100, Panevėžys, Panevėžio, Lithuania",
  "710005, Botoşani, Botoşani, Romania",
  "Whatcom, British Columbia, Canada",
  "Darechok, Nepal",
  "Holo Crossing, Pembina, Manitoba, Canada",
  "Schoolcraft Southfield, Detroit, Michigan, United States",
  "6001, Charleroi, Walloon Region, Belgium",
  "Big Sandy, Texas, United States",
  "Duval-Eagle Eyes Crime Watch, Gainesville, Florida, United States",
  "B2763ABA, San Andrés de Giles, Buenos Aires Province, Argentina",
  "1037, Budapest, Budapest, Hungary",
  "Muromtsevo, Vladimir, Russia",
  "Espírito Santo, Brazil",
  "Sandersleben, Saxony-Anhalt, Germany",
  "Riceville, Iowa, United States",
  "Bucks Horn Oak, England, United Kingdom",
  "Barronett, Wisconsin, United States",
  "Heard Island and McDonald Islands",
  "Leander, Texas, United States",
  "Edgefield Street, England, United Kingdom",
  "Heby, Uppsala County, Sweden",
  "Anderson, Texas, United States",
  "Custer, Wisconsin, United States",
  "McHenry Village, Modesto, California, United States",
  "Tuguro-Chumikansky District, Khabarovsk, Russia",
  "Greater Dundee Area",
  "46020, Valencia, Valencian Community, Spain",
  "Perth South, Ontario, Canada",
  "Campobasso, Molise, Italy",
  "Goarem Person, Leuhan, Brittany, France",
  "Kerala, India",
  "Valley Cottage, New York, United States",
  "PH33 6JZ, Fort William, Scotland, United Kingdom",
  "Saint Martin (France)",
  "360022, Nalchik, Kabardino-Balkaria, Russia",
  "Sharjah Emirate, United Arab Emirates",
  "Weber-Hof, Hillesheim, Rhineland-Palatinate, Germany",
  "Sullivan, Missouri, United States",
  "6700, Arlon, Walloon Region, Belgium",
  "456221, Zlatoust, Chelyabinsk, Russia",
  "88000, Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "32001, Ourense, Galicia, Spain",
  "Magadan, Russia",
  "629303, Novy Urengoy, Yamalo-Nenets, Russia",
  "187406, Volkhov, Leningrad, Russia",
  "98000, Monaco, Monaco, Monaco",
  "Delaware County, New York, United States",
  "Alekseyevka, Novosibirsk, Russia",
  "43600, Kutahya, Kütahya, Türkiye",
  "Dewey, Fraser-Fort George F, British Columbia, Canada",
  "6430, Schwyz, Schwyz, Switzerland",
  "65191, Wiesbaden, Hesse, Germany",
  "Roskilde Municipality, Region Zealand, Denmark",
  "Steinhausen, Zug, Switzerland",
  "Visby, North Denmark Region, Denmark",
  "Saludan, Kecamatan Lumbis, North Kalimantan, Indonesia",
  "427971, Sarapul, Udmurtia, Russia",
  "Akureyri, Northeastern Region, Iceland",
  "Burshtyn, Ivano-Frankivsk, Ukraine",
  "Turukhansky District, Krasnoyarsk Krai, Russia",
  "24121, Bergamo, Lombardy, Italy",
  "972 36, Luleå, Norrbotten County, Sweden",
  "DE14 1AA, Burton Upon Trent, England, United Kingdom",
  "Burton On The Wolds, England, United Kingdom",
  "28334, Dunn, North Carolina, United States",
  "Solin, Split-Dalmatia, Croatia",
  "Râmnicu Sărat, Buzău, Romania",
  "30084, Tucker, Georgia, United States",
  "Middlesex County, Connecticut, United States",
  "62273, Pocahontas, Illinois, United States",
  "R95 FH96, Kilkenny, County Kilkenny, Ireland",
  "Kilimli, Zonguldak, Türkiye",
  "Ravalli, Montana, United States",
  "95464, Nice, California, United States",
  "Henderson, Texas, United States",
  "Petrovsk-Zabaykalsky District, Zabaykalsky Krai, Russia",
  "Ugāle, Ventspils, Latvia",
  "Furth, Bavaria, Germany",
  "HP17 0UF, Little Kimble, England, United Kingdom",
  "Littleton, Colorado, United States",
  "Duga Resa, Karlovac, Croatia",
  "6603, Lake Hinds, Western Australia, Australia",
  "Hamilton city, Bermuda",
  "Cooperstown, New York, United States",
  "70051, Barletta, Apulia, Italy",
  "357620, Yessentuki, Stavropol, Russia",
  "Le Lion, Pays de la Loire, France",
  "Loveland, Ohio, United States",
  "67400, Kokkola, Central Ostrobothnia, Finland",
  "352129, Tikhoretsk, Krasnodar, Russia",
  "C0A 0A5, Montague, Prince Edward Island, Canada",
  "7724, Steinkjer, Trøndelag, Norway",
  "51730, Bor, Niğde, Türkiye",
  "Tarazona, Aragon, Spain",
  "Lyaskovets, Veliko Turnovo, Bulgaria",
  "Korgas, Xinjiang Uygur, China",
  "OL9 6AA, Oldham, England, United Kingdom",
  "Covington, Kentucky, United States",
  "Sherman Oaks, Los Angeles, California, United States",
  "Pepinet, Venteuges, Auvergne-Rhône-Alpes, France",
  "368582, Derbent, Dagestan, Russia",
  "5400-017, Chaves, Vila Real, Portugal",
  "Harper, Oregon, United States",
  "Adamstown, New South Wales, Australia",
  "Mineral County, Montana, United States",
  "Çankırı, Türkiye",
  "68350, Mulhouse, Grand Est, France",
  "34110, Kragujevac, Centralna Srbija, Serbia",
  "6421, Molde, Møre og Romsdal, Norway",
  "Archer River, Queensland, Australia",
  "Cass County, Michigan, United States",
  "41126, Modena, Emilia-Romagna, Italy",
  "16631, Vertiyevka, Chernihiv, Ukraine",
  "652061, Yurga Vtoraya, Kemerovo, Russia",
  "Charleston, Missouri, United States",
  "Konispol, Vlorë, Albania",
  "Trípolis, Peloponnese, Greece",
  "78268, Kolomyya, Ivano-Frankivsk, Ukraine",
  "429125, Shumerlya, Chuvashia, Russia",
  "Piatra -oimului, Neamţ, Romania",
  "Kirovskiy, Astrakhan’, Russia",
  "Norton-On-Derwent, England, United Kingdom",
  "49084, Osnabrück, Lower Saxony, Germany",
  "Seneca, Pennsylvania, United States",
  "Pecqueuse, Île-de-France, France",
  "Campana, Buenos Aires Province, Argentina",
  "Biguaçu, Santa Catarina, Brazil",
  "Grenada",
  "C22D3G9, Atbasar, Akmola Region, Kazakhstan",
  "658222, Rubtsovsk, Altai Krai, Russia",
  "Korfovskiy, Khabarovsk, Russia",
  "Macon County, Missouri, United States",
  "Sosnogorsk, Komi, Russia",
  "Tonga",
  "Martinsville, Virginia, United States",
  "Curry, County Longford, Ireland",
  "Ashanti Region, Ghana",
  "Adair Place, Winter Park, Florida, United States",
  "Kotel’nikovo, Volgograd, Russia",
  "4432, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "65-076, Zielona Góra, Lubuskie, Poland",
  "1003, Lausanne, Vaud, Switzerland",
  "Coffee Bay, Umtata, Eastern Cape, South Africa",
  "Clark County, Wisconsin, United States",
  "Kings Park, Western Australia, Australia",
  "7100, Vejle, Region of Southern Denmark, Denmark",
  "84134, Salerno, Campania, Italy",
  "Brazoria, Texas, United States",
  "33719, Bielefeld, North Rhine-Westphalia, Germany",
  "Genç, Bingöl, Türkiye",
  "F93 XT9T, Lifford, County Donegal, Ireland",
  "Juniata, Perdue 346, Saskatchewan, Canada",
  "49074, Osnabrück, Lower Saxony, Germany",
  "Grafton, Ohio, United States",
  "7231, Jenaz, Graubünden, Switzerland",
  "40270, Jyväskylä, Central Finland, Finland",
  "Aleksandro-Slobodskoye, Tatarstan, Russia",
  "34946, Fort Pierce, Florida, United States",
  "Mesão Frio, Vila Real, Portugal",
  "Martin-Hickman, Des Moines, Iowa, United States",
  "19124, Philadelphia, Pennsylvania, United States",
  "1292, Ig, Ljubljana, Slovenia",
  "45140, Manisa, Manisa, Türkiye",
  "Caswell, Wisconsin, United States",
  "4202, Kirchschlag bei Linz, Upper Austria, Austria",
  "San Potito Sannitico, Campania, Italy",
  "FY4 3EQ, Blackpool, England, United Kingdom",
  "18292, Krakow am See, Mecklenburg-West Pomerania, Germany",
  "Allegany County, New York, United States",
  "Loudon Road Historic District, New York, United States",
  "Parmeraan, Hulu Sihapas, North Sumatra, Indonesia",
  "32372-160, Contagem, Minas Gerais, Brazil",
  "Rhodes, Southern Aegean, Greece",
  "49260-000, Umbaúba, Sergipe, Brazil",
  "33209, Gijón, Principality of Asturias, Spain",
  "77585, Saratoga, Texas, United States",
  "666764, Ust-Kut, Irkutsk, Russia",
  "50, Aarau, Aargau, Switzerland",
  "00-202, Warsaw, Mazowieckie, Poland",
  "Palatka, Magadan, Russia",
  "Bennetts End, Hemel Hempstead, England, United Kingdom",
  "77056, Houston, Texas, United States",
  "430382, Baia Mare, Maramureş, Romania",
  "629736, Nadym, Yamalo-Nenets, Russia",
  "357736, Kislovodsk, Stavropol, Russia",
  "Craig, Demerara-Mahaica, Guyana",
  "Greater Eskisehir",
  "Zelënodol'sk, Dnipropetrovsk, Ukraine",
  "160014, Vologda, Vologda, Russia",
  "Tajura, Tripoli, Libya",
  "20340, Ploče, Dubrovnik-Neretva, Croatia",
  "Tioga Pointe, Lake Wylie, South Carolina, United States",
  "Palermo, Maine, United States",
  "423831, Naberezhnyye Chelny, Tatarstan, Russia",
  "29006, Batesburg, South Carolina, United States",
  "353923, Novorossiysk, Krasnodar, Russia",
  "60051, McHenry, Illinois, United States",
  "PA15 3EF, Greenock, Scotland, United Kingdom",
  "Palotina, Paraná, Brazil",
  "Glasgow, Montana, United States",
  "200003, Craiova, Dolj, Romania",
  "Barnes Cray, Dartford, England, United Kingdom",
  "629735, Nadym, Yamalo-Nenets, Russia",
  "Stephenson, Virginia, United States",
  "Cumberland County, New Jersey, United States",
  "Vermont, United States",
  "56241, Granite Falls, Minnesota, United States",
  "Cass County, North Dakota, United States",
  "Shenandoah Junction, West Virginia, United States",
  "36314, Vigo, Galicia, Spain",
  "34090, Montpellier, Occitanie, France",
  "Covington, Georgia, United States",
  "44-122, Gliwice, Śląskie, Poland",
  "31792, Thomasville, Georgia, United States",
  "Majorganj, Bihar, India",
  "29014, Málaga, Andalusia, Spain",
  "Barcelona-Puerto La Cruz Metropolitan Area",
  "680001, Bucaramanga, Santander, Colombia",
  "Golden Meadow, Louisiana, United States",
  "Edgecombe County, North Carolina, United States",
  "03007, Alicante, Valencian Community, Spain",
  "Carlisle, Arkansas, United States",
  "655751, Abaza, Khakassia, Russia",
  "York County, Maine, United States",
  "Hasselt, Flemish Region, Belgium",
  "Noginsk, Moscow, Russia",
  "Nashik, Maharashtra, India",
  "Çukurca, Hakkâri, Türkiye",
  "Vinton Woods, Lafayette, Indiana, United States",
  "38760, Los Llanos de Aridane, Canary Islands, Spain",
  "Bërdicë, Shkodër, Albania",
  "505400, Râşnov, Braşov, Romania",
  "Vejlebyskov, Region Zealand, Denmark",
  "653 46, Karlstad, Värmland County, Sweden",
  "Nemaha, Nebraska, United States",
  "Roane County, Tennessee, United States",
  "52349, Vinton, Iowa, United States",
  "Béja, Béja, Tunisia",
  "367012, Makhachkala, Dagestan, Russia",
  "43006, Tarragona, Catalonia, Spain",
  "Chambers County, Texas, United States",
  "Amherst, Wisconsin, United States",
  "Odunpazarı, Eskişehir, Türkiye",
  "Petropavlovskoye, Chechnya, Russia",
  "220157, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "630049, Novosibirsk, Novosibirsk, Russia",
  "Perry Hall, Maryland, United States",
  "Montgomery, New York, United States",
  "187553, Tikhvin, Leningrad, Russia",
  "Lubeck, Victoria, Australia",
  "Sudbury, England, United Kingdom",
  "Chippewa, Savannah, Georgia, United States",
  "75158, Scurry, Texas, United States",
  "641212, Yurgamysh, Kurgan, Russia",
  "403893, Kamyshin, Volgograd, Russia",
  "225407, Baranavichy, Brest, Belarus",
  "29607, Greenville, South Carolina, United States",
  "Luzerne, Pennsylvania, United States",
  "Gold Coast, Queensland, Australia",
  "71014, Viljandi, Viljandimaa, Estonia",
  "230, Keflavík, Southern Peninsula, Iceland",
  "Ellispontos, Western Macedonia, Greece",
  "Saint-Georges-d’Oléron, Nouvelle-Aquitaine, France",
  "80651, Platteville, Colorado, United States",
  "Norton, Kansas, United States",
  "Cherven Bryag Municipality, Pleven, Bulgaria",
  "Grenville, Saint Andrew, Grenada",
  "46012, Anderson, Indiana, United States",
  "78641, Leander, Texas, United States",
  "Les Duchesnes, La Mancelière, Centre-Val de Loire, France",
  "6005, Kecskemét, Bács-Kiskun, Hungary",
  "Birsk, Bashkortostan, Russia",
  "658218, Rubtsovsk, Altai Krai, Russia",
  "398036, Lipetsk, Lipetsk, Russia",
  "Bytyç, Kukës, Albania",
  "Allen County, Ohio, United States",
  "Monroe County, Indiana, United States",
  "Barton, Nova Scotia, Canada",
  "7102, Bitola, Pelagonia, North Macedonia",
  "Towner, North Dakota, United States",
  "Kramatorsk, Donetsk, Ukraine",
  "368301, Kaspiysk, Dagestan, Russia",
  "Tatar-Ulkanovo, Bashkortostan, Russia",
  "Ust-Aldansky District, Sakha, Russia",
  "33308, Fort Lauderdale, Florida, United States",
  "23860, Hopewell, Virginia, United States",
  "Walworth County, Wisconsin, United States",
  "Mardin, Türkiye",
  "Suure-Jaani, Viljandimaa, Estonia",
  "Harlan, Iowa, United States",
  "18007, Granada, Andalusia, Spain",
  "Hanson, Kinistino 459, Saskatchewan, Canada",
  "27937, Gates, North Carolina, United States",
  "Brest, Belarus",
  "Henry County, Illinois, United States",
  "48662, Wheeler, Michigan, United States",
  "11073, Belgrade, Serbia",
  "427630, Glazov, Udmurtia, Russia",
  "Cherry Creek, New York, United States",
  "Anniston-Oxford Metropolitan Area",
  "LV-3615, Ugāle, Ventspils, Latvia",
  "40200, Kirsehir Centre, Kırşehir, Türkiye",
  "353676, Yeysk, Krasnodar, Russia",
  "Atchison County, Kansas, United States",
  "90135, Palermo, Sicily, Italy",
  "629007, Salekhard, Yamalo-Nenets, Russia",
  "Southampton, New Brunswick, Canada",
  "624264, Asbest, Sverdlovsk, Russia",
  "Live Oak, California, United States",
  "Pagelaran, West Java, Indonesia",
  "238175, Chernyakhovskiy, Kaliningrad, Russia",
  "Wirtschaft, Steinhausen an der Rottum, Baden-Württemberg, Germany",
  "403342, Mikhaylovka Urban Okrug, Volgograd, Russia",
  "Wallerstein, Bavaria, Germany",
  "LV-3621, Tārgale, Ventspils, Latvia",
  "Zima, Irkutsk, Russia",
  "Ramsey Mereside, England, United Kingdom",
  "Potter County, Pennsylvania, United States",
  "Sheridan County, Wyoming, United States",
  "Lankaran, Lankaran-Astara, Azerbaijan",
  "Brownsville Metropolitan Area",
  "Eureka, Utah, United States",
  "Troitsk, Irkutsk, Russia",
  "Vilas Boas, Três Corações, Minas Gerais, Brazil",
  "130160, Târgovişte, Dîmboviţa, Romania",
  "23229, Henrico, Virginia, United States",
  "33400, Mersin, İçel, Türkiye",
  "462422, Orsk, Orenburg, Russia",
  "E4M 3C1, Bayfield, New Brunswick, Canada",
  "681 33, Alexandroúpolis, Eastern Macedonia and Thrace, Greece",
  "Popel, Kampong Chhnang, Cambodia",
  "16332, Lickingville, Pennsylvania, United States",
  "S21 3YB, Spinkhill, England, United Kingdom",
  "Dominica",
  "60543, Oswego, Illinois, United States",
  "8311, Peshkopi, Dibrë, Albania",
  "Kassel'skoye, Chelyabinsk, Russia",
  "Addison, New York, United States",
  "Holt County, Nebraska, United States",
  "30400, Hakkâri, Hakkâri, Türkiye",
  "Gallatin Street Area, Mississippi, United States",
  "2023 AA, Haarlem, North Holland, Netherlands",
  "Ellis County, Kansas, United States",
  "65410, Vaasa, Ostrobothnia, Finland",
  "Sokol, Vologda, Russia",
  "Ipswich, England, United Kingdom",
  "E9C 1V3, Storeytown, New Brunswick, Canada",
  "76271, Tioga, Texas, United States",
  "New Suffolk, New York, United States",
  "Gaziantep, Türkiye",
  "Cottonwood, Minnesota, United States",
  "8003, Zurich, Zurich, Switzerland",
  "County Dublin, Ireland",
  "1016, Budapest, Budapest, Hungary",
  "Boyd, Minnesota, United States",
  "30300, Cartagena, Región de Murcia, Spain",
  "Milam Place, Oklahoma City, Oklahoma, United States",
  "10550, Mount Vernon, New York, United States",
  "F39B7B6, Zaysan, East Kazakhstan Region, Kazakhstan",
  "09131, Chemnitz, Saxony, Germany",
  "Guadalupe, Panama Oeste, Panama",
  "Coal Center, Pennsylvania, United States",
  "71000, Port Dickson, Negri Sembilan, Malaysia",
  "Floyd County, Kentucky, United States",
  "Sarapul, Udmurtia, Russia",
  "G6B 3E1, Nantes, Quebec, Canada",
  "Kingsbury County, South Dakota, United States",
  "Webster, South Dakota, United States",
  "97602, Klamath Falls, Oregon, United States",
  "Haysi, Virginia, United States",
  "Romny, Sumy, Ukraine",
  "B61B3T2, Sarqan, Jetisu Region, Kazakhstan",
  "Greater Mataró Metropolitan Area",
  "23666, Hampton, Virginia, United States",
  "M28C1B7, Aqtau, Karaganda Region, Kazakhstan",
  "Normanton, England, United Kingdom",
  "662974, Zheleznogorsk, Krasnoyarsk Krai, Russia",
  "5402, Çorovodë, Berat, Albania",
  "217 66, Malmo, Skåne County, Sweden",
  "652303, Topki, Kemerovo, Russia",
  "Greater Pittsburgh Region",
  "La Marinière de Haut, Grimesnil, Normandy, France",
  "6370, Stans, Nidwalden, Switzerland",
  "Waterloo-Cedar Falls Area",
  "241 32, Kalamata, Peloponnese, Greece",
  "Powell, Ohio, United States",
  "Knoxville, Tennessee, United States",
  "Sterling Heights, Michigan, United States",
  "Page, North Dakota, United States",
  "36000, Teluk Intan, Perak, Malaysia",
  "21009, Vinnytsya, Vinnytsya, Ukraine",
  "66510, Vaasa, Ostrobothnia, Finland",
  "20603, Narva, Ida-Virumaa, Estonia",
  "35050, İzmir, İzmir, Türkiye",
  "Astigarraga, Basque Country, Spain",
  "Sainte-Marie, Reunion",
  "Bilbao, Chimborazo, Ecuador",
  "352389, Kropotkin, Krasnodar, Russia",
  "Dover, Delaware, United States",
  "63080, Sullivan, Missouri, United States",
  "76000, Rouen, Normandy, France",
  "Weston-Super-Mare, England, United Kingdom",
  "LV-5417, Daugavpils, Daugavpils, Latvia",
  "213830, Bobruysk, Mahilyow, Belarus",
  "Klyuchinskiy, Krasnoyarsk Krai, Russia",
  "Vintonville Historic District, United States",
  "Davidson, Oklahoma, United States",
  "59810-959, Portalegre, Rio Grande do Norte, Brazil",
  "Gemeente Haarlemmermeer, North Holland, Netherlands",
  "Tavşanlı, Kütahya, Türkiye",
  "China",
  "2323, Hamar, Innlandet, Norway",
  "Boboshevo, Kyustendil, Bulgaria",
  "2800, Tatabánya, Komárom-Esztergom, Hungary",
  "Omchak, Magadan, Russia",
  "Obshtina Vetovo, Ruse, Bulgaria",
  "Santa Rosalía de Palermo, Federal District, Venezuela",
  "Mönsterås, Kalmar County, Sweden",
  "Brasov Metropolitan Area",
  "SA45 9SB, New Quay, Wales, United Kingdom",
  "B56K3B5, Zharkent, Jetisu Region, Kazakhstan",
  "694433, Aleksandrovsk-Sakhalinskiy, Sakhalin, Russia",
  "West Yellowstone, Montana, United States",
  "MD-5313, Burlăceni, Cahul District, Moldova",
  "E4M 1M4, Coburg, New Brunswick, Canada",
  "155815, Kineshma, Ivanovo, Russia",
  "Price, Quebec, Canada",
  "Cambridgeshire, England, United Kingdom",
  "2014 KP, Haarlem, North Holland, Netherlands",
  "Florence, New Jersey, United States",
  "Lgovsky District, Kursk, Russia",
  "Webster County, Nebraska, United States",
  "676014, Skovorodino, Amur, Russia",
  "Sawyers Valley, Western Australia, Australia",
  "23566, Lübeck, Schleswig-Holstein, Germany",
  "Gayo Lues Regency, Aceh, Indonesia",
  "300125, Timişoara, Timiş, Romania",
  "Chickasaw Farms, Florida, United States",
  "Reeveston Place Historic District, Richmond, Indiana, United States",
  "42000, Romny, Sumy, Ukraine",
  "80526, Fort Collins, Colorado, United States",
  "TR18 4PD, Penzance, England, United Kingdom",
  "Tetonia, Idaho, United States",
  "413722, Pugachëv, Saratov, Russia",
  "90100, Oulu, North Ostrobothnia, Finland",
  "85255, Scottsdale, Arizona, United States",
  "462351, Novotroitsk, Orenburg, Russia",
  "98230, Blaine, Washington, United States",
  "43850, Kutahya, Kütahya, Türkiye",
  "33764, Clearwater, Florida, United States",
  "666711, Kirensk, Irkutsk, Russia",
  "Monção, Viana do Castelo, Portugal",
  "Portage, Maine, United States",
  "Grant County, South Dakota, United States",
  "Canton of Bastia-1, Corsica, France",
  "33181, Miami, Florida, United States",
  "Tiraspol, Transnistria autonomous territorial unit, Moldova",
  "Asha, Chelyabinsk, Russia",
  "810526, Brăila, Brăila, Romania",
  "09124, Cagliari, Sardinia, Italy",
  "Siontapina, South East Sulawesi, Indonesia",
  "Pinetown, KwaZulu-Natal, South Africa",
  "Barron County, Wisconsin, United States",
  "Tennessee, United States",
  "Essen, North Rhine-Westphalia, Germany",
  "R25A5H1, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "52300, Ordu, Ordu, Türkiye",
  "Hughes, Arkansas, United States",
  "61270, Morrison, Illinois, United States",
  "Howard Beach, New York, New York, United States",
  "Bowmansville, New York, United States",
  "EX1 2DR, Exeter, England, United Kingdom",
  "653046, Prokopyevsk, Kemerovo, Russia",
  "Preston, Minnesota, United States",
  "Prince George County, Virginia, United States",
  "33607, Bielefeld, North Rhine-Westphalia, Germany",
  "Cham, Zug, Switzerland",
  "152313, Borisogleb, Yaroslavl’, Russia",
  "Onon-Borzya, Zabaykalsky Krai, Russia",
  "Vernon, New Jersey, United States",
  "Gemerek, Sivas, Türkiye",
  "68390, Kokkola, Central Ostrobothnia, Finland",
  "480, Bilbao, Basque Country, Spain",
  "80807, Munich, Bavaria, Germany",
  "Maribor, Maribor, Slovenia",
  "Sequoyah, Oklahoma City, Oklahoma, United States",
  "Jamestown, Tennessee, United States",
  "Chesterfield, New Hampshire, United States",
  "6315, Oberägeri, Zug, Switzerland",
  "Becker, Borken, North Rhine-Westphalia, Germany",
  "18229, Jim Thorpe, Pennsylvania, United States",
  "Cahul, Cahul District, Moldova",
  "67805, Linares, Nuevo León, Mexico",
  "20903, Silver Spring, Maryland, United States",
  "Merrick County, Nebraska, United States",
  "Silver City, New Mexico, United States",
  "Garfield, Kentucky, United States",
  "Fürth, Bavaria, Germany",
  "400117, Volgograd, Volgograd, Russia",
  "LU3 1AB, Luton, England, United Kingdom",
  "74134, Tulsa, Oklahoma, United States",
  "307171, Zheleznogorsk, Kursk, Russia",
  "70119, New Orleans, Louisiana, United States",
  "Bogoroditskoye, Orel, Russia",
  "Whitefield, England, United Kingdom",
  "Beaufort West, Western Cape, South Africa",
  "Bowie, Maryland, United States",
  "188801, Vyborg, Leningrad, Russia",
  "Krivodol, Vratsa, Bulgaria",
  "44221, Cuyahoga Falls, Ohio, United States",
  "Dixon County, Nebraska, United States",
  "Woodstock, Virginia, United States",
  "10121, Turin, Piedmont, Italy",
  "249039, Obninsk, Kaluga, Russia",
  "Swift Current, Saskatchewan, Canada",
  "Noble Park, Victoria, Australia",
  "IP31 3SH, Thurston, England, United Kingdom",
  "37772, Lenoir City, Tennessee, United States",
  "Erbaa, Tokat, Türkiye",
  "271 10, Pírgos, Western Greece, Greece",
  "Tyrrell, Norfolk County, Ontario, Canada",
  "Kirovgrad, Sverdlovsk, Russia",
  "70376, Stuttgart, Baden-Württemberg, Germany",
  "77095, Houston, Texas, United States",
  "400394, Cluj-Napoca, Cluj, Romania",
  "Batumi, Ajaria, Georgia",
  "35220, İzmir, İzmir, Türkiye",
  "Alekseyevka, Tambov, Russia",
  "618100, Okhansk, Perm, Russia",
  "424000, Yoshkar-Ola, Mari El, Russia",
  "38110, Kayseri, Kayseri, Türkiye",
  "CV7 7DG, Berkswell, England, United Kingdom",
  "E9C 1V1, Storeytown, New Brunswick, Canada",
  "Adams County, Nebraska, United States",
  "Sherman, Wisconsin, United States",
  "429104, Shumerlya, Chuvashia, Russia",
  "50127, Florence, Tuscany, Italy",
  "Goshen, Pennsylvania, United States",
  "066, Trumbull, Connecticut, United States",
  "94732, Tenango de Río Blanco, Veracruz, Mexico",
  "Lamouret, Orthez, Nouvelle-Aquitaine, France",
  "Košice-okolie, Kosice, Slovakia",
  "Refugio del Sol, Junín de los Andes, Neuquén Province, Argentina",
  "Charleston, South Carolina Metropolitan Area",
  "357739, Kislovodsk, Stavropol, Russia",
  "38123, Trento, Trentino-Alto Adige, Italy",
  "74447, Okmulgee, Oklahoma, United States",
  "28630-304, New Fribourg, Rio de Janeiro, Brazil",
  "Titusville, Florida, United States",
  "341 33, Chalkida, Central Greece, Greece",
  "169728, Usinsk, Komi, Russia",
  "Bates County, Missouri, United States",
  "78335, Aransas Pass, Texas, United States",
  "Sankt Johann im Pongau, Salzburg, Austria",
  "Upton, Wirral, England, United Kingdom",
  "30001, Murcia, Región de Murcia, Spain",
  "Starkville, Mississippi, United States",
  "Marquette, Wisconsin, United States",
  "162391, Velikiy Ustyug, Vologda, Russia",
  "Armavir, Krasnodar, Russia",
  "90142, Palermo, Sicily, Italy",
  "São Pedro do Sul, Viseu, Portugal",
  "238318, Gur'yevsk, Kaliningrad, Russia",
  "Konavle, Dubrovnik-Neretva, Croatia",
  "Kaluga Metropolitan Area",
  "Malatia-Sebastia, Yerevan, Armenia",
  "307179, Zheleznogorsk, Kursk, Russia",
  "Municipio Lander, Miranda State, Venezuela",
  "Radomir, Pernik, Bulgaria",
  "6965, Lugano, Ticino, Switzerland",
  "H34F0P9, Qulan, Jambyl Region, Kazakhstan",
  "Demene, Daugavpils, Latvia",
  "95071, Saratoga, California, United States",
  "SA69 9EU, Saundersfoot, Wales, United Kingdom",
  "24651, Tazewell, Virginia, United States",
  "40128, Bologna, Emilia-Romagna, Italy",
  "15300, Lahti, Päijät-Häme, Finland",
  "48005, Bilbao, Basque Country, Spain",
  "Chippewas of Rama First Nation, Ontario, Canada",
  "Gilmerton, Edinburgh, Scotland, United Kingdom",
  "Nazaré, Leiria, Portugal",
  "36052, Mathews, Alabama, United States",
  "Davis County, Utah, United States",
  "71233, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "Seymchan, Magadan, Russia",
  "4825, Arendal, Agder, Norway",
  "Gracemor-Randolph Corners, Chouteau, Missouri, United States",
  "72749, Morrow, Arkansas, United States",
  "DeWitt, Virginia, United States",
  "39112, Magdeburg, Saxony-Anhalt, Germany",
  "164502, Severodvinsk, Arkhangel’sk, Russia",
  "08024, Barcelona, Catalonia, Spain",
  "Titusville, New Jersey, United States",
  "Kars, Kars, Türkiye",
  "Bayğanïn District, Aqtöbe region, Kazakhstan",
  "Isle, Minnesota, United States",
  "Novotroitskoye, Kirov, Russia",
  "9402, Vlorë, Vlorë, Albania",
  "620072, Yekaterinburg, Sverdlovsk, Russia",
  "Greater Saginaw-Midland-Bay City Area",
  "Warsaw, North Carolina, United States",
  "Salamanca, Miranda State, Venezuela",
  "6416, Molde, Møre og Romsdal, Norway",
  "Mérida, Extremadura, Spain",
  "453116, Sterlitamak, Bashkortostan, Russia",
  "2626, Lillehammer, Innlandet, Norway",
  "403886, Kamyshin, Volgograd, Russia",
  "Navarro, Valle del Cauca, Colombia",
  "54515, Catawba, Wisconsin, United States",
  "33713, St Petersburg, Florida, United States",
  "Wickede, North Rhine-Westphalia, Germany",
  "Pike County, Missouri, United States",
  "5100, Namur, Walloon Region, Belgium",
  "66440, Vaasa, Ostrobothnia, Finland",
  "972 38, Luleå, Norrbotten County, Sweden",
  "2031 AA, Haarlem, North Holland, Netherlands",
  "89073, Ulm, Baden-Württemberg, Germany",
  "Argentina",
  "Guam",
  "Oneida, Illinois, United States",
  "Curry, Pili, Bicol Region, Philippines",
  "Ansonia, Connecticut, United States",
  "Hardinsburg, Kentucky, United States",
  "Gallatin Township, Missouri, United States",
  "Batovo, Dobrich, Bulgaria",
  "Harlan Spring Historic District, Hedgesville, West Virginia, United States",
  "CV5 8AA, Coventry, England, United Kingdom",
  "21915, Chesapeake City, Maryland, United States",
  "54868, Rice Lake, Wisconsin, United States",
  "Decatur, Tennessee, United States",
  "10459, Bronx, New York, United States",
  "Ravenna, Nebraska, United States",
  "665112, Nizhneudinsk, Irkutsk, Russia",
  "352394, Kropotkin, Krasnodar, Russia",
  "Scioto Estates, Dublin, Ohio, United States",
  "Walsheim, Rhineland-Palatinate, Germany",
  "Kenedy County, Texas, United States",
  "Holmes County, Ohio, United States",
  "Ocean Shores, New South Wales, Australia",
  "İscehisar, Afyon, Türkiye",
  "Perkins County, South Dakota, United States",
  "346934, Novoshakhtinsk, Rostov, Russia",
  "Wyoming, Minnesota, United States",
  "Pagentan, Kecamatan Pagentan, Central Java, Indonesia",
  "Cayenne, Cayenne, French Guiana",
  "Oktyabrsky District, Kursk, Russia",
  "Guadalupe, Zacatecas, Mexico",
  "744, Muskogee, Oklahoma, United States",
  "GY2 4AP, St Sampson, Guernsey, Guernsey",
  "Turner County, Georgia, United States",
  "Trenton, Illinois, United States",
  "Tran, Pernik, Bulgaria",
  "22551, Spotsylvania, Virginia, United States",
  "21500, Diyarbakır, Diyarbakır, Türkiye",
  "Balashov, Saratov, Russia",
  "PE26 2NW, Ramsey, England, United Kingdom",
  "Ocean Park, Washington, United States",
  "Lindesberg, Örebro County, Sweden",
  "Krasnotur'insk, Sverdlovsk, Russia",
  "Bennett, Fargo, North Dakota, United States",
  "225416, Baranavichy, Brest, Belarus",
  "Rogers City, Michigan, United States",
  "423455, Almetyevsk, Tatarstan, Russia",
  "Nobles County, Minnesota, United States",
  "68007, Bennington, Nebraska, United States",
  "516, Nodaway, Iowa, United States",
  "Hillerød Municipality, Capital Region of Denmark, Denmark",
  "Pendang, Kedah, Malaysia",
  "Kholmsky District, Novgorod, Russia",
  "68860, Oconto, Nebraska, United States",
  "Chhattisgarh, India",
  "70460, Kuopio, Northern Savonia, Finland",
  "34130, Kassel, Hesse, Germany",
  "Greater Flensburg Area",
  "81-572, Gdynia, Pomorskie, Poland",
  "N1M 2W5, Fergus, Ontario, Canada",
  "B78 2NP, Kingsbury, England, United Kingdom",
  "Pendleton County, West Virginia, United States",
  "Karahallı, Uşak, Türkiye",
  "DeKalb County, Illinois, United States",
  "57035, Humboldt, South Dakota, United States",
  "KA18 2AU, Ochiltree, Scotland, United Kingdom",
  "Benson, North Carolina, United States",
  "Limerick, Saskatchewan, Canada",
  "00430, Helsinki, Uusimaa, Finland",
  "346885, Bataysk, Rostov, Russia",
  "Williamsburg, Kentucky, United States",
  "682972, Bikin, Khabarovsk, Russia",
  "48176, Saline, Michigan, United States",
  "Medzhybizh, Khmelnytsky, Ukraine",
  "12910-480, Bragança Paulista, São Paulo, Brazil",
  "Departamento Sargento Cabral, Chaco, Argentina",
  "10472, Bronx, New York, United States",
  "731137, Bârlad, Vaslui, Romania",
  "Hopkins, South Carolina, United States",
  "Wake, Virginia, United States",
  "871 03, Härnösand, Västernorrland County, Sweden",
  "Keyamora, Bihpuria, Assam, India",
  "OX10 6EQ, Benson, England, United Kingdom",
  "Valley Center, California, United States",
  "Nigde Centre, Niğde, Türkiye",
  "Dinar, Afyon, Türkiye",
  "Newport, Rhode Island, United States",
  "Queens Park, New South Wales, Australia",
  "404104, Volzhskiy, Volgograd, Russia",
  "Szombathely District, Vas, Hungary",
  "Sweetwater Farms, Pennsylvania, United States",
  "689350, Egvekinot, Chukot, Russia",
  "Howard County, Texas, United States",
  "Loudon County, Tennessee, United States",
  "SS2 6AS, Southend-On-Sea, England, United Kingdom",
  "453300, Kumertau, Bashkortostan, Russia",
  "40134, Bologna, Emilia-Romagna, Italy",
  "428034, Cheboksary, Chuvashia, Russia",
  "Redange, Diekirch, Luxembourg",
  "3083 AM, Rotterdam, South Holland, Netherlands",
  "Warsaw, Virginia, United States",
  "Highland, Scotland, United Kingdom",
  "Stanly County, North Carolina, United States",
  "641468, Kurtamysh, Kurgan, Russia",
  "Lucas, Kentucky, United States",
  "Orangeburg, South Carolina, United States",
  "44-141, Gliwice, Śląskie, Poland",
  "Collooney, County Sligo, Ireland",
  "Howell, Michigan, United States",
  "Krakow am See, Mecklenburg-West Pomerania, Germany",
  "6058, Forrestfield, Western Australia, Australia",
  "39600, Kirklareli, Kırklareli, Türkiye",
  "Cleveland, Wisconsin, United States",
  "211405, Polatsk, Vitsyebsk, Belarus",
  "71342, Jena, Louisiana, United States",
  "Taylor, Pennsylvania, United States",
  "Moscow, Texas, United States",
  "Caddo County, Oklahoma, United States",
  "Chester, Maryland, United States",
  "Slobodskoye, Smolensk, Russia",
  "171507, Kimry, Tver’, Russia",
  "Essex Junction, Vermont, United States",
  "Gilliam, Louisiana, United States",
  "400686, Cluj-Napoca, Cluj, Romania",
  "13050, Butterworth, Penang, Malaysia",
  "HR1 3AY, Sutton St Nicholas, England, United Kingdom",
  "Pitcairn Islands",
  "Schaffhausen, Schaffhausen, Switzerland",
  "58538, Fort Yates, North Dakota, United States",
  "17268, Waynesboro, Pennsylvania, United States",
  "Tor Fiscale Quartiere Tuscolano, Rome, Latium, Italy",
  "Alter do Chão, Portalegre, Portugal",
  "67710, Linares, Nuevo León, Mexico",
  "410010, Saratov, Saratov, Russia",
  "Cass County, Missouri, United States",
  "60100, Tokat, Tokat, Türkiye",
  "627705, Ishim, Tyumen’, Russia",
  "89127, Reggio di Calabria, Calabria, Italy",
  "Horta, Portugal",
  "Sokolniki, Łódzkie, Poland",
  "Scotts Valley, California, United States",
  "8312, Svolvær, Nordland, Norway",
  "143420, Krasnogorsk, Moscow, Russia",
  "14225, Buffalo, New York, United States",
  "74122, Taranto, Apulia, Italy",
  "Page Moss, Liverpool, England, United Kingdom",
  "Slatina, Olt, Romania",
  "Youngsville, North Carolina, United States",
  "Greater Zaragoza Metropolitan Area",
  "90120, Oulu, North Ostrobothnia, Finland",
  "140093, Dzerzhinskiy, Moscow, Russia",
  "76310, Wichita Falls, Texas, United States",
  "50676, Tripoli, Iowa, United States",
  "Jefferson Davis Parish County, Louisiana, United States",
  "Central Washita, Oklahoma, United States",
  "64653, Laclede, Missouri, United States",
  "Peterborough, South Australia, Australia",
  "652477, Anzhero-Sudzhensk, Kemerovo, Russia",
  "Greenwood, Wisconsin, United States",
  "02160, Kyiv, Kyiv City, Ukraine",
  "Kazanlŭk, Stara Zagora, Bulgaria",
  "Shishtavec, Kukës, Albania",
  "Dunning, Scotland, United Kingdom",
  "22106, McLean, Virginia, United States",
  "Loraine, Maruleng, Limpopo, South Africa",
  "McDonald County, Missouri, United States",
  "McDonald, Saint Andrews, Manitoba, Canada",
  "H02P8D5, Taraz, Jambyl Region, Kazakhstan",
  "Slobodskoye, Ryazan’, Russia",
  "426060, Izhevsk, Udmurtia, Russia",
  "62288, Steeleville, Illinois, United States",
  "Dade City, Florida, United States",
  "Esmeraldas Canton, Esmeraldas, Ecuador",
  "Cook, Queensland, Australia",
  "57461, Mellette, South Dakota, United States",
  "Otter, Lower Saxony, Germany",
  "L-8538, Beckerich, Diekirch, Luxembourg",
  "DT11 8JA, Tarrant Hinton, England, United Kingdom",
  "301843, Yefremov, Tula, Russia",
  "CV2 1AA, Coventry, England, United Kingdom",
  "Pendleton, South Carolina, United States",
  "Stoney Creek, Ontario, Canada",
  "26010, Eskişehir, Eskişehir, Türkiye",
  "McKinley, Minneapolis, Minnesota, United States",
  "Gates County, North Carolina, United States",
  "Camden, New South Wales, Australia",
  "67857, Kendall, Kansas, United States",
  "694912, Uglegorsk, Sakhalin, Russia",
  "R0M 1Y0, Pipestone, Manitoba, Canada",
  "29329, Spartanburg, South Carolina, United States",
  "Les Millards, La Selle-en-Hermoy, Centre-Val de Loire, France",
  "Morton, Mississippi, United States",
  "Ruskov, Kosice, Slovakia",
  "Colonial Cumbres, Nuevo León, Mexico",
  "Millers Point, New South Wales, Australia",
  "Hausen, Bavaria, Germany",
  "22800, Edirne, Edirne, Türkiye",
  "Penzensky District, Penza, Russia",
  "200032, Craiova, Dolj, Romania",
  "Catawba View, North Carolina, United States",
  "Chautauqua Park, Des Moines, Iowa, United States",
  "31680-130, Belo Horizonte, Minas Gerais, Brazil",
  "76341, Siauliai, Siauliu, Lithuania",
  "3011 WM, Rotterdam, South Holland, Netherlands",
  "Robeson, Pennsylvania, United States",
  "40215, Düsseldorf, North Rhine-Westphalia, Germany",
  "678440, Saskylakh, Sakha, Russia",
  "C25D3P8, Makinsk, Akmola Region, Kazakhstan",
  "36700, Salamanca, Guanajuato, Mexico",
  "43700, Kutahya, Kütahya, Türkiye",
  "Karaganda, Karaganda Region, Kazakhstan",
  "Dimitrovgrad, Khaskovo, Bulgaria",
  "Shiraoka, Saitama, Japan",
  "Voronezh, Russia",
  "Chernyshevsky District, Zabaykalsky Krai, Russia",
  "Dajt, Tirana, Albania",
  "E25C0X3, Maqat, Atyrau Region, Kazakhstan",
  "620122, Focşani, Vrancea, Romania",
  "Weston, Idaho, United States",
  "692905, Nakhodka, Primorye, Russia",
  "Rivas-Vaciamadrid, Community of Madrid, Spain",
  "67212, Wichita, Kansas, United States",
  "Elliston, South Australia, Australia",
  "B61D9E1, Sarqan, Jetisu Region, Kazakhstan",
  "39562, Moss Point, Mississippi, United States",
  "Qulan, Jambyl Region, Kazakhstan",
  "33207, Gijón, Principality of Asturias, Spain",
  "7404, Ersekë, Korçë, Albania",
  "462404, Orsk, Orenburg, Russia",
  "1291, Škofljica, Ljubljana, Slovenia",
  "Reynolds, North Dakota, United States",
  "560, Sauðárkrókur, Northwestern Region, Iceland",
  "Stearns, Kentucky, United States",
  "Grayson 184, Saskatchewan, Canada",
  "892, Örnsköldsvik, Västernorrland County, Sweden",
  "Dayton Metropolitan Area",
  "Smithville, Missouri, United States",
  "Renville East, County Galway, Ireland",
  "NN15 5QY, Burton Latimer, England, United Kingdom",
  "Krasnogorskoye, Udmurtia, Russia",
  "Verkhniy Kargat, Novosibirsk, Russia",
  "3580000, Linares, Maule Region, Chile",
  "Westmoreland County, Pennsylvania, United States",
  "Calhoun, Tennessee, United States",
  "Vol'sk, Saratov, Russia",
  "Butler, Tennessee, United States",
  "Galliano, Louisiana, United States",
  "Alekseyevka, Rostov, Russia",
  "Bussy-Saint-Georges, Île-de-France, France",
  "Tripp Park, Scranton, Pennsylvania, United States",
  "Tarrant Monkton, England, United Kingdom",
  "50240, Nevsehir, Nevşehir, Türkiye",
  "Nassau, North Sumatra, Indonesia",
  "31015, Pamplona, Chartered Community of Navarre, Spain",
  "Dunn County, North Dakota, United States",
  "42327, Wuppertal, North Rhine-Westphalia, Germany",
  "Roosevelt, Utah, United States",
  "Juneau, Alaska, United States",
  "Klyuchi, Tatarstan, Russia",
  "8852, Altendorf, Schwyz, Switzerland",
  "03042, Cottbus, Brandenburg, Germany",
  "974 05, Malachov, Banska Bystrica, Slovakia",
  "22904, Charlottesville, Virginia, United States",
  "McIntosh, Alabama, United States",
  "Erzurum, Erzurum, Türkiye",
  "Langlade, Sexcles, Nouvelle-Aquitaine, France",
  "Swain County, North Carolina, United States",
  "Amursk, Khabarovsk, Russia",
  "Nikolayevsky District, Volgograd, Russia",
  "F63B1D1, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "B61D3A4, Sarqan, Jetisu Region, Kazakhstan",
  "Hamilton, Massachusetts, United States",
  "Bellingham Metropolitan Area",
  "Caledonia, Wisconsin, United States",
  "T0E 0Y0, Grande Cache, Alberta, Canada",
  "35260, İzmir, İzmir, Türkiye",
  "14884, Swain, New York, United States",
  "Lapseki, Çanakkale, Türkiye",
  "Dundalk, Maryland, United States",
  "Davisburg, Michigan, United States",
  "Lamouret, Lieoux, Occitanie, France",
  "18228, Aleksinac, Centralna Srbija, Serbia",
  "Titustown, Norfolk, Virginia, United States",
  "Dane End, England, United Kingdom",
  "3916, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "G64 4HQ, Torrance, Scotland, United Kingdom",
  "Kalupe, Daugavpils, Latvia",
  "Scott County, Minnesota, United States",
  "Newberry, County Cork, Ireland",
  "Watauga County, North Carolina, United States",
  "Dyer, Indiana, United States",
  "22235, Šibenik, Sibenik-Knin, Croatia",
  "67000, Strasbourg, Grand Est, France",
  "Tervel Municipality, Dobrich, Bulgaria",
  "20723, Horodyshche, Cherkasy, Ukraine",
  "Henry, Illinois, United States",
  "35-312, Rzeszów, Podkarpackie, Poland",
  "Wilson, Pennsylvania, United States",
  "Moratuwa, Western Province, Sri Lanka",
  "Carlisle, Pennsylvania, United States",
  "McIntosh, Florida, United States",
  "Seminole County, Oklahoma, United States",
  "SA1 1AA, Swansea, Wales, United Kingdom",
  "Deer Park, Texas, United States",
  "1407, Sofia, Sofia City, Bulgaria",
  "Ust-Kamenogorsk, East Kazakhstan Region, Kazakhstan",
  "Chickasaw, Alabama, United States",
  "Snohomish County, Washington, United States",
  "Ottawa, Ontario, Canada",
  "Barton, Torquay, England, United Kingdom",
  "Powderhorn, Colorado, United States",
  "Fulton, California, United States",
  "NN15 5FA, Burton Latimer, England, United Kingdom",
  "40-368, Katowice, Śląskie, Poland",
  "75-244, Koszalin, Zachodniopomorskie, Poland",
  "Buckinghamshire, England, United Kingdom",
  "Stukeley, Huntingdon, England, United Kingdom",
  "Cherskiy, Sakha, Russia",
  "Reading, Vermont, United States",
  "Bellevue, Washington, United States",
  "La Chevallerie, Grimesnil, Normandy, France",
  "Gentry, Missouri, United States",
  "SA3 4JY, Swansea, Wales, United Kingdom",
  "371 46, Karlskrona, Blekinge County, Sweden",
  "89128, Reggio di Calabria, Calabria, Italy",
  "452685, Neftekamsk, Bashkortostan, Russia",
  "Youngstown, New York, United States",
  "423810, Naberezhnyye Chelny, Tatarstan, Russia",
  "Lackawanna, Jacksonville, Florida, United States",
  "Coal, Missouri, United States",
  "Lamego, Viseu, Portugal",
  "22038, Fairfax, Virginia, United States",
  "391308, Kasimov, Ryazan’, Russia",
  "29670, Marbella, Andalusia, Spain",
  "BT79 7AH, Omagh, Northern Ireland, United Kingdom",
  "51527, Defiance, Iowa, United States",
  "636780, Strezhevoy, Tomsk, Russia",
  "Greater Suceava Botosani Area",
  "800080, Galaţi, Galaţi, Romania",
  "KA18 2PS, Ochiltree, Scotland, United Kingdom",
  "Bodocó, Pernambuco, Brazil",
  "Lamourette, Gaujac, Nouvelle-Aquitaine, France",
  "Lyon County, Kansas, United States",
  "8008, Bodø, Nordland, Norway",
  "Cimarron Hills, Florida, United States",
  "KA18 2RY, Ochiltree, Scotland, United Kingdom",
  "Vancouver, British Columbia, Canada",
  "Novi Sad, Vojvodina, Serbia",
  "Menominee, Wisconsin, United States",
  "Persons Heights, Florida, United States",
  "Les Rains, Chevillon-sur-Huillard, Centre-Val de Loire, France",
  "Dillondale, Kingston, Ontario, Canada",
  "871 40, Härnösand, Västernorrland County, Sweden",
  "11729, Deer Park, New York, United States",
  "51110, Rijeka, Primorje-Gorski, Croatia",
  "Appomattox, Virginia, United States",
  "Keith County, Nebraska, United States",
  "57126, Livorno, Tuscany, Italy",
  "11030, Belgrade, Serbia",
  "20-632, Lublin, Lubelskie, Poland",
  "Ulmi, Dîmboviţa, Romania",
  "Choctaw County, Alabama, United States",
  "24414, Subotica, Vojvodina, Serbia",
  "392540, Sovkhoza Komsomolets poselok, Tambov, Russia",
  "Santa Catarina, Brazil",
  "Korsakovo, Orel, Russia",
  "00049, Klamath, California, United States",
  "L10F6K8, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Seversk, Tomsk, Russia",
  "95066, Scotts Valley, California, United States",
  "80-809, Gdańsk, Pomorskie, Poland",
  "Stevenson, Maryland, United States",
  "Cəlilabad, Lankaran-Astara, Azerbaijan",
  "Sheridan County, Nebraska, United States",
  "Welwyn Garden City, England, United Kingdom",
  "South Williamson, Kentucky, United States",
  "BT9 6JY, Belfast, Northern Ireland, United Kingdom",
  "Kotovsk, Tambov, Russia",
  "Pas-de-Calais, Hauts-de-France, France",
  "27607, Raleigh, North Carolina, United States",
  "13010, Marseille, Provence-Alpes-Côte d'Azur, France",
  "Sweet Springs, Missouri, United States",
  "San Jose, Costa Rica",
  "Monmouth Beach, New Jersey, United States",
  "Murmashi, Murmansk, Russia",
  "Kansky District, Krasnoyarsk Krai, Russia",
  "602 24, Norrköping, Östergötland County, Sweden",
  "Ménard, Sarron, Nouvelle-Aquitaine, France",
  "Los Altos, California, United States",
  "Davies Plantation, Bartlett, Tennessee, United States",
  "Madrid, Community of Madrid, Spain",
  "BT27 5BJ, Lisburn, Northern Ireland, United Kingdom",
  "6460, Molde, Møre og Romsdal, Norway",
  "1200, Brussels, Brussels Region, Belgium",
  "50500, Tarazona, Aragon, Spain",
  "Strelka, Krasnodar, Russia",
  "Nikolayevskoye, Omsk, Russia",
  "Royal Tunbridge Wells, England, United Kingdom",
  "B0N 1K0, Meaghers Grant, Nova Scotia, Canada",
  "Vladimirovka, Volyn, Ukraine",
  "00-695, Warsaw, Mazowieckie, Poland",
  "30204, Barnesville, Georgia, United States",
  "052837, Bucharest, Bucharest, Romania",
  "Sovetskiy, Leningrad, Russia",
  "9022, Tromsø, Troms og Finnmark, Norway",
  "693009, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Southwest Middlesex, Ontario, Canada",
  "28100, Pori, Satakunta, Finland",
  "63017, Chesterfield, Missouri, United States",
  "Plymouth, England, United Kingdom",
  "66748, Humboldt, Kansas, United States",
  "69005, Lyon, Auvergne-Rhône-Alpes, France",
  "Marlborough, New Hampshire, United States",
  "Mikhaylovka, Orenburg, Russia",
  "Hot Springs, North Carolina, United States",
  "446301, Otradnyy, Samara, Russia",
  "Shadrinskiy, Altai Krai, Russia",
  "37617, Blountville, Tennessee, United States",
  "169912, Vorkuta, Komi, Russia",
  "Comanche, Oklahoma, United States",
  "Sierra Gorda, Antofagasta Region, Chile",
  "450075, Ufa, Bashkortostan, Russia",
  "7100, Byala, Ruse, Bulgaria",
  "01803, Burlington, Massachusetts, United States",
  "09111, Chemnitz, Saxony, Germany",
  "Dzerzhinskoye, Irkutsk, Russia",
  "Coal Run, Pennsylvania, United States",
  "Old Keith, Keith, Scotland, United Kingdom",
  "76680, Reagan, Texas, United States",
  "450076, Zalău, Sălaj, Romania",
  "Menominee, Michigan, United States",
  "X08M5X6, Shymkent, Shymkent, Kazakhstan",
  "Verkhnyaya Sinyachikha, Sverdlovsk, Russia",
  "Athens, New York, United States",
  "654034, Novokuznetsk, Kemerovo, Russia",
  "Mathews, Virginia, United States",
  "080011, Giurgiu, Giurgiu, Romania",
  "47269, Duisburg, North Rhine-Westphalia, Germany",
  "Biletska silska hromada, Ternopil, Ukraine",
  "Greater Aalborg Area",
  "Crook, England, United Kingdom",
  "Boyarka, Kyiv, Ukraine",
  "Greenville, Illinois, United States",
  "238324, Gur'yevsk, Kaliningrad, Russia",
  "Mauritania",
  "Lehi, Utah, United States",
  "27106, Winston-Salem, North Carolina, United States",
  "64300, Uşak, Uşak, Türkiye",
  "6726, Szeged, Csongrád, Hungary",
  "23562, Lübeck, Schleswig-Holstein, Germany",
  "EX2 9SL, Exeter, England, United Kingdom",
  "Crihana Veche, Cahul District, Moldova",
  "Marquette, Flemish Region, Belgium",
  "Niterói, Rio de Janeiro, Brazil",
  "Grainger Park, Newcastle Upon Tyne, England, United Kingdom",
  "79204, Prijedor, Serb Republic, Bosnia and Herzegovina",
  "Crook Of Devon, Scotland, United Kingdom",
  "391793, Gornyak, Ryazan’, Russia",
  "San Andrés and Providencia, Colombia",
  "Greater Lille Metropolitan Area",
  "Imotski, Split-Dalmatia, Croatia",
  "Saukhor, Gola, Uttar Pradesh, India",
  "Northwest Aitkin, Minnesota, United States",
  "49686, Traverse City, Michigan, United States",
  "Gislaved, Jönköping County, Sweden",
  "Uglegorsk, Donetsk, Ukraine",
  "Bradley, Penrith, England, United Kingdom",
  "Postribë, Shkodër, Albania",
  "Borisoglebsky Urban Okrug, Voronezh, Russia",
  "238173, Chernyakhovskiy, Kaliningrad, Russia",
  "75092, Sherman, Texas, United States",
  "309286, Shebekino, Belgorod, Russia",
  "Lewis County, New York, United States",
  "Greater La Coruña Area",
  "Jefferson County, West Virginia, United States",
  "Whitman, Massachusetts, United States",
  "Taylor County, Wisconsin, United States",
  "M28C2Y9, Aqtau, Karaganda Region, Kazakhstan",
  "Market Weighton, England, United Kingdom",
  "Schwerin, Mecklenburg-West Pomerania, Germany",
  "161112, Nikol’skiy Torzhok, Vologda, Russia",
  "694005, Korsakov, Sakhalin, Russia",
  "01159, Dresden, Saxony, Germany",
  "70-234, Szczecin, Zachodniopomorskie, Poland",
  "413105, Engels, Saratov, Russia",
  "M23 9RW, Wythenshawe, England, United Kingdom",
  "8500, Portimão, Faro, Portugal",
  "80090, Amiens, Hauts-de-France, France",
  "678400, Tiksi, Sakha, Russia",
  "Potoky, Poltava, Ukraine",
  "Burt, Michigan, United States",
  "Llano Enmedio, Veracruz, Mexico",
  "Arrecife, Canary Islands, Spain",
  "Greater Alexandria, Louisiana",
  "Gastonia, North Carolina, United States",
  "457665, Kassel’skiy, Chelyabinsk, Russia",
  "Santa Maria, Rio Grande do Sul, Brazil",
  "K1S 0A2, Ottawa, Ontario, Canada",
  "905700, Năvodari, Constanţa, Romania",
  "49260-959, Umbaúba, Sergipe, Brazil",
  "40400, Jyväskylä, Central Finland, Finland",
  "141560, Solnechnogorsk, Moscow, Russia",
  "L9V 2K4, Dundalk, Ontario, Canada",
  "L1C 0A1, Bowmanville, Ontario, Canada",
  "McLean, Saskatchewan, Canada",
  "Borås kommun, Västra Götaland County, Sweden",
  "93016, Fillmore, California, United States",
  "172508, Nelidovo, Tver’, Russia",
  "Visby Huse, Region of Southern Denmark, Denmark",
  "Tuvalu",
  "693001, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "820004, Tulcea, Tulcea, Romania",
  "3515, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "55353, Kimball, Minnesota, United States",
  "Scioto Farms, Ohio, United States",
  "Gemeindefreies Gebiet Harz (Landkreis Göttingen), Lower Saxony, Germany",
  "Grodno City Council, Hrodna, Belarus",
  "Hotan County, Xinjiang Uygur, China",
  "Hitchcock, Texas, United States",
  "Edirne, Türkiye",
  "75080, Richardson, Texas, United States",
  "Graniteville, South Carolina, United States",
  "Allendale, Edmonton, Alberta, Canada",
  "B61D5E6, Sarqan, Jetisu Region, Kazakhstan",
  "080 01, Presov, Presov, Slovakia",
  "Bitlis, Bitlis, Türkiye",
  "Eastland, Tulsa, Oklahoma, United States",
  "Howell, New South Wales, Australia",
  "Moura, Beja, Portugal",
  "50600, Mikkeli, South Savo, Finland",
  "PE6 7HH, Peterborough, England, United Kingdom",
  "632863, Karasuk, Novosibirsk, Russia",
  "185 42, Piraeus, Attiki, Greece",
  "Azad Kashmir, Pakistan",
  "169302, Ukhta, Komi, Russia",
  "96038, Grenada, California, United States",
  "Laramie County, Wyoming, United States",
  "Bremen, Indiana, United States",
  "16400, Bursa, Bursa, Türkiye",
  "Saunders County, Nebraska, United States",
  "Valencia, Valencian Community, Spain",
  "72058, Greenbrier, Arkansas, United States",
  "2316, Hamar, Innlandet, Norway",
  "47009, Valladolid, Castilla and Leon, Spain",
  "Stoke St. Gregory, Taunton, England, United Kingdom",
  "Greater Split Area",
  "Smythstown - Pope St to Vryheid St, East Berbice-Corentyne, Guyana",
  "3084, Heidelberg, Victoria, Australia",
  "53213, Milwaukee, Wisconsin, United States",
  "Lacey, Washington, United States",
  "F92 R7PN, Letterkenny, County Donegal, Ireland",
  "Wirt County, West Virginia, United States",
  "McHenry, North Dakota, United States",
  "Coleford, England, United Kingdom",
  "34231, Kragujevac, Centralna Srbija, Serbia",
  "95757, Elk Grove, California, United States",
  "Granville County, North Carolina, United States",
  "Hickory Grove, South Carolina, United States",
  "CA17 4LQ, Wharton, England, United Kingdom",
  "393194, Kotovsk, Tambov, Russia",
  "Stewarton, Scotland, United Kingdom",
  "79705, Midland, Texas, United States",
  "F63A2H9, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "33680, San Francisco de Conchos, Chihuahua, Mexico",
  "61-251, Poznan, Wielkopolskie, Poland",
  "Graz, Styria, Austria",
  "413040, Algeciras, Huila, Colombia",
  "Beaumont-le-Roger, Normandy, France",
  "Slobozia-Dușca, Criuleni District, Moldova",
  "677018, Yakutsk, Sakha, Russia",
  "Mikhaylovsky District, Volgograd, Russia",
  "Sauk County, Wisconsin, United States",
  "Cooper Landing, Alaska, United States",
  "Ayanbey, Konya, Türkiye",
  "Dawson, Alabama, United States",
  "37003, Salamanca, Castilla and Leon, Spain",
  "Artuklu, Mardin, Türkiye",
  "41-909, Bytom, Śląskie, Poland",
  "Laç, Lezhë, Albania",
  "Gentofte Municipality, Capital Region of Denmark, Denmark",
  "B25D6X8, Esik, Almaty Region, Kazakhstan",
  "620142, Yekaterinburg, Sverdlovsk, Russia",
  "Makarovka, Kursk, Russia",
  "74012, Broken Arrow, Oklahoma, United States",
  "74145, Tulsa, Oklahoma, United States",
  "Pope Valley, California, United States",
  "Rostov, Rostov, Russia",
  "Assendelft, North Holland, Netherlands",
  "Biwer, Grevenmacher, Luxembourg",
  "171080, Bologoye, Tver’, Russia",
  "Shuyang, Jiangsu, China",
  "Guernsey, Guernsey",
  "Sully, Pohénégamook, Quebec, Canada",
  "52772, Tipton, Iowa, United States",
  "Kysucké Nové Mesto, Zilina, Slovakia",
  "Harrison, New York, United States",
  "28100, Novara, Piedmont, Italy",
  "Greater San Sebastian Area",
  "52003, Melilla, Melilla, Spain",
  "54055, Mykolaiv, Mykolayiv, Ukraine",
  "462748, Tobol’skiy, Orenburg, Russia",
  "3310, Gramsh, Elbasan, Albania",
  "Greater Biella Metropolitan Area",
  "Nyemba, Tanganyika, Democratic Republic of the Congo",
  "Basel, Basel, Switzerland",
  "Uglegorskiy Rayon, Sakhalin, Russia",
  "Lafayette, Louisiana, United States",
  "301 03, Halmstad, Halland County, Sweden",
  "Elkridge, Maryland, United States",
  "FY1 1QW, Blackpool, England, United Kingdom",
  "E25C4C6, Maqat, Atyrau Region, Kazakhstan",
  "Ugale, Ventspils, Latvia",
  "Jasper County, Texas, United States",
  "LS1 4HQ, Leeds, England, United Kingdom",
  "T0E 0A6, Grande Cache, Alberta, Canada",
  "9711 AA, Groningen, Groningen, Netherlands",
  "Zhaltyr, Akmola Region, Kazakhstan",
  "Bafra, Samsun, Türkiye",
  "24300, Erzincan, Erzincan, Türkiye",
  "17000, Kozelets, Chernihiv, Ukraine",
  "31036, Hawkinsville, Georgia, United States",
  "M90 4AN, Wythenshawe, England, United Kingdom",
  "Klyuchi, Altai Krai, Russia",
  "243021, Novozybkov, Bryansk, Russia",
  "21075, Elkridge, Maryland, United States",
  "143444, Krasnogorsk, Moscow, Russia",
  "Šuto Orizari, Skopje Statistical Region, North Macedonia",
  "Ansonia, Ohio, United States",
  "Polygyros, Central Macedonia, Greece",
  "A92 F9PV, Drogheda, Ireland",
  "Yevlakh District, Central Aran, Azerbaijan",
  "Webster, Florida, United States",
  "Nances, Auvergne-Rhône-Alpes, France",
  "Laborie du Bois, Laramière, Occitanie, France",
  "Ocean Grove, Victoria, Australia",
  "46072, Tipton, Indiana, United States",
  "69002, Zaporizhzhya, Zaporizhzhya, Ukraine",
  "Obshtina Byala, Ruse, Bulgaria",
  "Webbville, Kentucky, United States",
  "Vanino, Khabarovsk, Russia",
  "Lyman County, South Dakota, United States",
  "429120, Shumerlya, Chuvashia, Russia",
  "Gibson, Georgia, United States",
  "71000, Kırıkkale, Kırıkkale, Türkiye",
  "620161, Focşani, Vrancea, Romania",
  "Tiraspolul Nou, Transnistria autonomous territorial unit, Moldova",
  "Sakiet Sidi Youssef, Al Kaf, Tunisia",
  "LE5 1EH, Leicester, England, United Kingdom",
  "356413, Blagodarnyy, Stavropol, Russia",
  "Ust'-Ilimsk, Irkutsk, Russia",
  "Mfoundi, Centre, Cameroon",
  "82937, Lyman, Wyoming, United States",
  "Sauteurs, Saint Patrick, Grenada",
  "Ulyanovsky District, Ul’yanovsk, Russia",
  "18320, Dimitrovgrad, Centralna Srbija, Serbia",
  "09170, Bila Tserkva, Kyiv, Ukraine",
  "3533, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "426004, Izhevsk, Udmurtia, Russia",
  "93015, Fillmore, California, United States",
  "17952, Reevesdale, Pennsylvania, United States",
  "8235, Lohn, Schaffhausen, Switzerland",
  "Szombathely, Vas, Hungary",
  "84107, Salt Lake City, Utah, United States",
  "M90 4EG, Wythenshawe, England, United Kingdom",
  "700415, Iaşi, Iaşi, Romania",
  "Okulovka, Tver’, Russia",
  "50557, Lehigh, Iowa, United States",
  "Roane County, West Virginia, United States",
  "Port Dickson, Negri Sembilan, Malaysia",
  "Okhotsk, Khabarovsk, Russia",
  "Columbus, New Jersey, United States",
  "Rzeszow Metropolitan Area",
  "19606, Reading, Pennsylvania, United States",
  "Shadrinsky District, Kurgan, Russia",
  "Paris, Texas, United States",
  "Bordeaux-en-Gâtinais, Centre-Val de Loire, France",
  "CV3 6JY, Coventry, England, United Kingdom",
  "Crawford River, New South Wales, Australia",
  "1045, Tirana, Tirana, Albania",
  "680 13, Alexandroúpolis, Eastern Macedonia and Thrace, Greece",
  "Łódź, Łódzkie, Poland",
  "County Wexford, Ireland",
  "San Antonio, Texas, United States",
  "368602, Derbent, Dagestan, Russia",
  "Sainte-Sabine-Born, Beaumontois-en-Périgord, Nouvelle-Aquitaine, France",
  "Borodianka Raion, Kyiv, Ukraine",
  "446103, Chapayevsk, Samara, Russia",
  "Noble, Oklahoma, United States",
  "BT82 9LT, Sion Mills, Northern Ireland, United Kingdom",
  "Masally District, Lankaran-Astara, Azerbaijan",
  "East Bennett, South Dakota, United States",
  "Turner Valley, Alberta, Canada",
  "Ancona, Illinois, United States",
  "75961, Nacogdoches, Texas, United States",
  "Patrick Brompton, England, United Kingdom",
  "Saukem, Porbandar, Gujarat, India",
  "010 15, Žilina, Zilina, Slovakia",
  "Newton, British Columbia, Canada",
  "Wells, Nevada, United States",
  "Horta Sud, Valencian Community, Spain",
  "15-421, Białystok, Podlaskie, Poland",
  "Norton, Ohio, United States",
  "Gonzales, California, United States",
  "El Paso, Texas, United States",
  "302000, Orel, Orel, Russia",
  "Oslo, Oslo, Norway",
  "Bragança, Bragança, Portugal",
  "85-097, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "665714, Bratsk, Irkutsk, Russia",
  "Nassau County, Florida, United States",
  "Klingenberg, Saxony, Germany",
  "28645, Lenoir, North Carolina, United States",
  "LE12 6HD, East Leake, England, United Kingdom",
  "Moss, Viken, Norway",
  "Maardu linn, Harjumaa, Estonia",
  "S0J 0J0, Blaine Lake, Saskatchewan, Canada",
  "Pülümür, Tunceli, Türkiye",
  "Galaxias Toluca, México, Mexico",
  "Ramsey, Minnesota, United States",
  "Bashkia Përmet, Gjirokastër, Albania",
  "84522, Emery, Utah, United States",
  "L-4137, Esch-sur-Alzette, Luxembourg, Luxembourg",
  "917 01, Trnava, Trnava Region, Slovakia",
  "169936, Vorkuta, Komi, Russia",
  "VLT 1436, Valletta, Valletta, Malta",
  "Box Hill, Victoria, Australia",
  "06830, Porvoo, Uusimaa, Finland",
  "Marathon, Attiki, Greece",
  "TA12 6AU, Kingsbury Episcopi, England, United Kingdom",
  "Rogers, Minnesota, United States",
  "658201, Rubtsovsk, Altai Krai, Russia",
  "Linn, Iowa, United States",
  "368604, Derbent, Dagestan, Russia",
  "Georgetown County, South Carolina, United States",
  "BH11 9UF, Bournemouth, England, United Kingdom",
  "Gmina Elbląg, Warmińsko-mazurskie, Poland",
  "Cookes Meadow, Fort Worth, Texas, United States",
  "89125, Reggio di Calabria, Calabria, Italy",
  "Darenth, England, United Kingdom",
  "Addison, Wisconsin, United States",
  "Nolana, Depalpur, Madhya Pradesh, India",
  "238154, Chernyakhovskiy, Kaliningrad, Russia",
  "Bladen County, North Carolina, United States",
  "Santa Cruz, Bolivia",
  "TS4 3RS, Middlesbrough, England, United Kingdom",
  "Chitarpur, India",
  "6913, Lugano, Ticino, Switzerland",
  "59714, Belgrade, Montana, United States",
  "Clay County, Iowa, United States",
  "Boone, Colorado, United States",
  "South Patrick Shores, Florida, United States",
  "Karlsruhe, North Dakota, United States",
  "Washburn & Lawrence, North Las Vegas, Nevada, United States",
  "Pendang District, Kedah, Malaysia",
  "Mariestads kommun, Västra Götaland County, Sweden",
  "625048, Tyumen, Tyumen’, Russia",
  "52, Arezzo, Tuscany, Italy",
  "641093, Borovichi, Kurgan, Russia",
  "74104, Tulsa, Oklahoma, United States",
  "Smith Brook, Western Australia, Australia",
  "5627 AB, Eindhoven, North Brabant, Netherlands",
  "Logan County, Arkansas, United States",
  "McCormick Woods, Florida, United States",
  "Nouméa, South Province, New Caledonia",
  "Ayr, Nebraska, United States",
  "07747, Jena, Thuringia, Germany",
  "11001, Cadiz, Andalusia, Spain",
  "Moss, England, United Kingdom",
  "City Of Stoke-On-Trent, England, United Kingdom",
  "Hampton, New Brunswick, Canada",
  "98006, Bellevue, Washington, United States",
  "Tuapse, Krasnodar, Russia",
  "Vance River, Siparia, Trinidad and Tobago",
  "0470001, Otaru, Hokkaido, Japan",
  "8605, Krumë, Kukës, Albania",
  "King's Lynn, England, United Kingdom",
  "Greater Huelva Metropolitan Area",
  "9439, Evenskjær, Troms og Finnmark, Norway",
  "352903, Armavir, Krasnodar, Russia",
  "Greater Le Havre Area",
  "40250, Jyväskylä, Central Finland, Finland",
  "Eaux-Bonnes, Nouvelle-Aquitaine, France",
  "Tuam, County Galway, Ireland",
  "Évora, Portugal",
  "Simferopol', Crimea, Ukraine",
  "Archerwill, Saskatchewan, Canada",
  "53947, Marquette, Wisconsin, United States",
  "Wirtsjockenhof, Steinach, Baden-Württemberg, Germany",
  "Greater Plymouth Area",
  "65195, Wiesbaden, Hesse, Germany",
  "Barysawshchyna, Homel, Belarus",
  "Bolzano Vicentino, Veneto, Italy",
  "723 56, Västerås, Västmanland County, Sweden",
  "Greater Cádiz Metropolitan Area",
  "Newberry, South Carolina, United States",
  "110438, Piteşti, Argeş, Romania",
  "BD20 8DG, Sutton-In-Craven, England, United Kingdom",
  "Barrio Bartolomé Salóm, Puerto Cabello, Carabobo State, Venezuela",
  "Nikol’sk, Penza, Russia",
  "97014, Cascade Locks, Oregon, United States",
  "Trinity Fields, Stafford, England, United Kingdom",
  "67211, Wichita, Kansas, United States",
  "Hudson, Wisconsin, United States",
  "St Charles, Missouri, United States",
  "404127, Volzhskiy, Volgograd, Russia",
  "Lebanon, Illinois, United States",
  "172001, Torzhok, Tver’, Russia",
  "41003, Seville, Andalusia, Spain",
  "453102, Sterlitamak, Bashkortostan, Russia",
  "Forsyth, Chapel Hill, North Carolina, United States",
  "Urzhar District, Abay Region, Kazakhstan",
  "Province of Jaén, Cajamarca, Peru",
  "352126, Tikhoretsk, Krasnodar, Russia",
  "21672, Toddville, Maryland, United States",
  "82801, Sheridan, Wyoming, United States",
  "Zayach'ye-Kholmskoye, Yaroslavl’, Russia",
  "Pulaski County, Kentucky, United States",
  "West Yorkshire, England, United Kingdom",
  "CF44 9JA, Penderyn, Wales, United Kingdom",
  "Portalegre, Portugal",
  "06124, Perugia, Umbria, Italy",
  "30330, Hakkâri, Hakkâri, Türkiye",
  "352131, Tikhoretsk, Krasnodar, Russia",
  "Atizapán de Zaragoza, México, Mexico",
  "90141, Palermo, Sicily, Italy",
  "Kiruna, Norrbotten County, Sweden",
  "Berkeley, Scunthorpe, England, United Kingdom",
  "SP11 0AA, Hurstbourne Tarrant, England, United Kingdom",
  "309975, Valuyki, Belgorod, Russia",
  "Les Chouteaux, Dhuys-et-Morin-en-Brie, Hauts-de-France, France",
  "9710, Szombathely, Vas, Hungary",
  "Floyd County, Iowa, United States",
  "41016, Novi di Modena, Emilia-Romagna, Italy",
  "27278, Hillsborough, North Carolina, United States",
  "Ward 2, Ho Chi Minh City, Vietnam",
  "Weberberg, St Gallen, Switzerland",
  "Missoula County, Montana, United States",
  "Ben Wheeler, Texas, United States",
  "60932, Rankin, Illinois, United States",
  "Dorchester County, Maryland, United States",
  "Korgan, Ordu, Türkiye",
  "Ottawa Lake, Michigan, United States",
  "45147, Essen, North Rhine-Westphalia, Germany",
  "Chippewa-on-the-Lake, Ohio, United States",
  "Gentry County, Missouri, United States",
  "9401 AR, Assen, Drenthe, Netherlands",
  "Egerág, Baranya, Hungary",
  "67500, Zonguldak, Zonguldak, Türkiye",
  "47245, Rakovica, Karlovac, Croatia",
  "ST18 0WF, Great Haywood, England, United Kingdom",
  "Curry, Lope de Vega, Eastern Visayas, Philippines",
  "Wheeler End, England, United Kingdom",
  "LE2 6QQ, Leicester, England, United Kingdom",
  "653024, Prokopyevsk, Kemerovo, Russia",
  "44009, Guadalajara, Jalisco, Mexico",
  "Inverness, Mississippi, United States",
  "Valenzuela, National Capital Region, Philippines",
  "49078, Osnabrück, Lower Saxony, Germany",
  "17877, Snydertown, Pennsylvania, United States",
  "Sequoyah, Tulsa, Oklahoma, United States",
  "Nerchinsko-Zavodsky District, Zabaykalsky Krai, Russia",
  "Oconee County, Georgia, United States",
  "Walker, Minnesota, United States",
  "040 12, Košice, Kosice, Slovakia",
  "BT2 7ES, Belfast, Northern Ireland, United Kingdom",
  "301654, Novomoskovsk, Tula, Russia",
  "31004, Pamplona, Chartered Community of Navarre, Spain",
  "Stanley, New Brunswick, Canada",
  "71123, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "Ulashanivka, Khmelnytsky, Ukraine",
  "Sheboygan Town, Wisconsin, United States",
  "43010, Catawba, Ohio, United States",
  "46064, Pendleton, Indiana, United States",
  "Newport, Arkansas, United States",
  "Victoria County, Texas, United States",
  "25-001, Kielce, Świętokrzyskie, Poland",
  "Barrón, Hidalgo, Mexico",
  "La Ravallière, La Membrolle-sur-Longuenée, Pays de la Loire, France",
  "167016, Syktyvkar, Komi, Russia",
  "247773, Mazyr, Homel, Belarus",
  "347562, Bogoroditskoye, Rostov, Russia",
  "BT82 9PA, Sion Mills, Northern Ireland, United Kingdom",
  "X09B4C2, Shymkent, Shymkent, Kazakhstan",
  "46600, Kahramanmaraş, Kahraman Maras, Türkiye",
  "Monastyryska miska hromada, Ternopil, Ukraine",
  "9051 AA, Leeuwarden, Friesland, Netherlands",
  "Freiburg, Lower Saxony, Germany",
  "Southampton, Western Australia, Australia",
  "Denver Metropolitan Area",
  "Indonesia",
  "8324, Svolvær, Nordland, Norway",
  "347383, Volgodonsk, Rostov, Russia",
  "Córdoba, Veracruz, Mexico",
  "Põhja-Sakala vald, Viljandimaa, Estonia",
  "Aveiro, Pará, Brazil",
  "Ciudad Real, Castile-La Mancha, Spain",
  "Oregon, Wisconsin, United States",
  "Bielsko-Biała, Śląskie, Poland",
  "Saint-Denis, Reunion",
  "M28T8M4, Temirtau, Karaganda Region, Kazakhstan",
  "Barra Bonita, São Paulo, Brazil",
  "94523, Pleasant Hill, California, United States",
  "Yahşihan, Kırıkkale, Türkiye",
  "Délégation Sakiet Eddaier, Sfax, Tunisia",
  "689380, Egvekinot, Chukot, Russia",
  "4635, Kristiansand, Agder, Norway",
  "Nizhyn Raion, Chernihiv, Ukraine",
  "Augsburg, Bavaria, Germany",
  "53700, Rize, Rize, Türkiye",
  "FY2 0AA, Blackpool, England, United Kingdom",
  "KA8 8JL, Ayr, Scotland, United Kingdom",
  "33762, Clearwater, Florida, United States",
  "Amherst County, Virginia, United States",
  "Kemper, Menden, North Rhine-Westphalia, Germany",
  "Newberry, Ohio, United States",
  "Saint-Georges-de-Reneins, Auvergne-Rhône-Alpes, France",
  "4034, Debrecen, Hajdú-Bihar, Hungary",
  "Weston, West Virginia, United States",
  "Druzhkovka, Donetsk, Ukraine",
  "28656, North Wilkesboro, North Carolina, United States",
  "Erpeldange-sur-Sûre, Diekirch, Luxembourg",
  "243018, Novozybkov, Bryansk, Russia",
  "367023, Makhachkala, Dagestan, Russia",
  "75815, Ţurayf, Northern Frontier, Saudi Arabia",
  "Sayanogorsk, Khakassia, Russia",
  "71662, Pickens, Arkansas, United States",
  "New Quay, Wales, United Kingdom",
  "LV-3002, Jelgava, Jelgava Municipality, Latvia",
  "Burnet, La Chapelle-du-Mont-de-France, Bourgogne-Franche-Comté, France",
  "44053, Lorain, Ohio, United States",
  "97, Ragusa, Sicily, Italy",
  "Galliano, Mongrando, Piedmont, Italy",
  "210, Huelva, Andalusia, Spain",
  "Wharton, Winsford, England, United Kingdom",
  "Bradley, Florida, United States",
  "APJ",
  "Kursk Metropolitan Area",
  "Pulaski, Wisconsin, United States",
  "PE8 5PL, Barnwell, England, United Kingdom",
  "220030, Minsk, Minsk, Belarus",
  "SA3 3BT, Caswell, Wales, United Kingdom",
  "35800, Ostrog, Rivne, Ukraine",
  "Wyandot, Mapleton, Ontario, Canada",
  "2982 AA, Ridderkerk, South Holland, Netherlands",
  "Gloucester County, Virginia, United States",
  "8601, Mo i Rana, Nordland, Norway",
  "Nicollet, Ugine, Auvergne-Rhône-Alpes, France",
  "Pointe à la Luzerne, South Province, New Caledonia",
  "Blair County, Pennsylvania, United States",
  "142191, Troitsk, Moscow, Russia",
  "Nance, Brecé, Pays de la Loire, France",
  "690092, Vladivostok, Primorye, Russia",
  "Coleman, Wisconsin, United States",
  "Kings, Subdivision A, Nova Scotia, Canada",
  "11207, Algeciras, Andalusia, Spain",
  "Montigny-lès-Metz, Grand Est, France",
  "Otterbourne, England, United Kingdom",
  "Steele City, Nebraska, United States",
  "M52C7K1, Osakarovka, Karaganda Region, Kazakhstan",
  "E9C 1V9, Storeytown, New Brunswick, Canada",
  "663305, Noril'sk, Taymyr, Russia",
  "27370, Trinity, North Carolina, United States",
  "29324, Spartanburg, South Carolina, United States",
  "Billingsley, Alabama, United States",
  "Greenland",
  "50580, Fierville-les-Mines, Normandy, France",
  "33701, St Petersburg, Florida, United States",
  "M28A2A3, Temirtau, Karaganda Region, Kazakhstan",
  "Yellowstone County, Montana, United States",
  "S0G 4G0, Richardson, Saskatchewan, Canada",
  "666924, Artëmovskiy, Irkutsk, Russia",
  "Cole, Bruton, England, United Kingdom",
  "624997, Serov, Sverdlovsk, Russia",
  "18025-000, Sorocaba, São Paulo, Brazil",
  "Podujevo, District of Pristina, Kosovo",
  "12900-260, Bragança Paulista, São Paulo, Brazil",
  "679011, Birobidzhan, Birobijan, Russia",
  "Daman and Diu, India",
  "Church Village, Wales, United Kingdom",
  "617451, Kungur, Perm, Russia",
  "Charles City, Iowa, United States",
  "Madison–Barbour Rural Historic District, United States",
  "Asheville, North Carolina, United States",
  "Collinsville, Illinois, United States",
  "Connah's Quay, Wales, United Kingdom",
  "429124, Shumerlya, Chuvashia, Russia",
  "00820, Christiansted, St Croix, US Virgin Islands",
  "Sofia, Sofia City, Bulgaria",
  "Grafton, New Brunswick, Canada",
  "Albany County, Wyoming, United States",
  "Kataysk, Kurgan, Russia",
  "225401, Baranavichy, Brest, Belarus",
  "GL1 2NZ, Gloucester, England, United Kingdom",
  "Milwaukee, Wisconsin, United States",
  "Denmark",
  "447230, Păuleşti, Satu Mare, Romania",
  "12884, Schuylerville, New York, United States",
  "243017, Novozybkov, Bryansk, Russia",
  "47342, Gaston, Indiana, United States",
  "Morrison, Illinois, United States",
  "Parma Heights, Ohio, United States",
  "404131, Volzhskiy, Volgograd, Russia",
  "Abdulino, Orenburg, Russia",
  "Zeist, Utrecht, Netherlands",
  "Morrillo, Puerto Cabello, Carabobo State, Venezuela",
  "Bağlar, Diyarbakır, Türkiye",
  "Palos Hills, Illinois, United States",
  "13012, Marseille, Provence-Alpes-Côte d'Azur, France",
  "Valença, Viana do Castelo, Portugal",
  "3004, Bern, Berne, Switzerland",
  "562110, Devanahalli, Karnataka, India",
  "67661, Phillipsburg, Kansas, United States",
  "36376, Webb, Alabama, United States",
  "Port-au-Prince, Département de l'Ouest, Haiti",
  "347368, Volgodonsk, Rostov, Russia",
  "28466, Wallace, North Carolina, United States",
  "10567, Cortlandt Manor, New York, United States",
  "Swains Island, American Samoa",
  "M22 0AD, Wythenshawe, England, United Kingdom",
  "6826 KG, Arnhem, Gelderland, Netherlands",
  "Marquette, Nebraska, United States",
  "Ransome, Queensland, Australia",
  "426033, Izhevsk, Udmurtia, Russia",
  "13600, Hämeenlinna, Kanta-Häme, Finland",
  "Haywood, Virginia, United States",
  "Buena Fe Canton, Los Ríos, Ecuador",
  "Switzerland",
  "16154, Genoa, Liguria, Italy",
  "9102, Herisau, Appenzell Outer-Rhoden, Switzerland",
  "09129, Cagliari, Sardinia, Italy",
  "Trippa Bella, Pontelandolfo, Campania, Italy",
  "Northampton, Pennsylvania, United States",
  "602 40, Norrköping, Östergötland County, Sweden",
  "75287, Dallas, Texas, United States",
  "Anson, Maine, United States",
  "169940, Vorkuta, Komi, Russia",
  "Winschoten, Groningen, Netherlands",
  "653 40, Karlstad, Värmland County, Sweden",
  "Ostashkov, Tver’, Russia",
  "Emiliano Zapata, Veracruz, Mexico",
  "412621, Svobodnyy, Saratov, Russia",
  "300551, Timişoara, Timiş, Romania",
  "Bennettsville, South Carolina, United States",
  "East Burke, Vermont, United States",
  "Pike County, Illinois, United States",
  "Chaumiere, Gallatin, Missouri, United States",
  "Rostock, Mecklenburg-West Pomerania, Germany",
  "685 00, Alexandroúpolis, Eastern Macedonia and Thrace, Greece",
  "IV3 8AW, Inverness, Scotland, United Kingdom",
  "67134, Sawyer, Kansas, United States",
  "99660, Sodankylä, Lapland, Finland",
  "Chase Terrace, Burntwood, England, United Kingdom",
  "169313, Ukhta, Komi, Russia",
  "Troitskiy, Sverdlovsk, Russia",
  "Lublin, Wisconsin, United States",
  "P7J 1G8, Fort William 52, Ontario, Canada",
  "60030, Grayslake, Illinois, United States",
  "Umba, Murmansk, Russia",
  "104 05, Stockholm, Stockholm County, Sweden",
  "Kayabağlar, Siirt, Türkiye",
  "620012, Yekaterinburg, Sverdlovsk, Russia",
  "Gentilly, Île-de-France, France",
  "75007, Carrollton, Texas, United States",
  "3030-155, Coimbra, Coimbra, Portugal",
  "8811, Philousa Khrysokhous, Paphos, Cyprus",
  "70191, Stuttgart, Baden-Württemberg, Germany",
  "662164, Achinsk, Krasnoyarsk Krai, Russia",
  "Chernogorsk, Khakassia, Russia",
  "423255, Leninogorsk, Tatarstan, Russia",
  "Klyuchi, Omsk, Russia",
  "Ravallioni Society, Patan, Gujarat, India",
  "456905, Bakal, Chelyabinsk, Russia",
  "Bucharest, Romania",
  "368608, Derbent, Dagestan, Russia",
  "24469, Fort Defiance, Virginia, United States",
  "Vinton Street, Omaha, Nebraska, United States",
  "06100, Nice, Provence-Alpes-Côte d'Azur, France",
  "352 33, Växjö, Kronoberg County, Sweden",
  "30438, Manassas, Georgia, United States",
  "Chernogorsk Gorod, Khakassia, Russia",
  "Alberetti, Ponderano, Piedmont, Italy",
  "Bakikhanov, Baku Ekonomic Zone, Azerbaijan",
  "Ayr, Scotland, United Kingdom",
  "7630, Pécs, Baranya, Hungary",
  "690109, Vladivostok, Primorye, Russia",
  "Crow, England, United Kingdom",
  "Ballinasloe, County Galway, Ireland",
  "Perry County, Pennsylvania, United States",
  "Watonwan County, Minnesota, United States",
  "Versailles, Missouri, United States",
  "S9H 2G6, Swift Current, Saskatchewan, Canada",
  "03055, Cottbus, Brandenburg, Germany",
  "B6L 5B8, Central Onslow, Nova Scotia, Canada",
  "Guernsey County, Ohio, United States",
  "Brazos, Waco, Texas, United States",
  "Victoria, Texas, United States",
  "Medina, Northern Mindanao, Philippines",
  "Carlisle, South Carolina, United States",
  "Sussex, Wisconsin, United States",
  "Fonda, New York, United States",
  "27580, Bremerhaven, Bremen, Germany",
  "681 50, Alexandroúpolis, Eastern Macedonia and Thrace, Greece",
  "Benton County, Missouri, United States",
  "56482, Wadena, Minnesota, United States",
  "Popeşti-Leordeni, Ilfov, Romania",
  "Cameron County, Texas, United States",
  "LN8 2AR, Caenby Corner, England, United Kingdom",
  "Tulsa County, Oklahoma, United States",
  "Sweet Grass County, Montana, United States",
  "Pokrovsk, Sakha, Russia",
  "Vinton, Cazères, Occitanie, France",
  "676853, Belogorsk, Amur, Russia",
  "GY2 4BW, St Sampson, Guernsey, Guernsey",
  "Aleksandrovsk, Murmansk, Russia",
  "Butler County, Kansas, United States",
  "300244, Timişoara, Timiş, Romania",
  "Mezenskiy Rayon, Arkhangel’sk, Russia",
  "Faetano, San Marino",
  "Ayangba, Kogi State, Nigeria",
  "Tyrrell's Wood, Leatherhead, England, United Kingdom",
  "54500, Vandoeuvre-lès-Nancy, Grand Est, France",
  "Dixon Green, Bolton, England, United Kingdom",
  "Verona, New York, United States",
  "692943, Nakhodka, Primorye, Russia",
  "27249, Gibsonville, North Carolina, United States",
  "BT79 7LF, Omagh, Northern Ireland, United Kingdom",
  "Mecklenburg County, Virginia, United States",
  "Palikir, Pohnpei, Federated States of Micronesia",
  "Panevėžys District Municipality, Panevėžio, Lithuania",
  "356415, Blagodarnyy, Stavropol, Russia",
  "44-102, Gliwice, Śląskie, Poland",
  "452000, Belebey, Bashkortostan, Russia",
  "V92 A725, Tralee, County Kerry, Ireland",
  "Sakarya, Sakarya, Türkiye",
  "Sandoval, Bataraza, Mimaropa, Philippines",
  "Orangeburg, New York, United States",
  "Linzeux, Hauts-de-France, France",
  "247779, Mazyr, Homel, Belarus",
  "Japan",
  "Nevershuse, Region of Southern Denmark, Denmark",
  "7803, Namsos, Trøndelag, Norway",
  "Grande Cache, Alberta, Canada",
  "6100, Kazanlŭk, Stara Zagora, Bulgaria",
  "Oral, South Dakota, United States",
  "Gölköy, Ordu, Türkiye",
  "Laclede, Missouri, United States",
  "663610, Kansk, Krasnoyarsk Krai, Russia",
  "188310, Gatchina, Leningrad, Russia",
  "Greater Bergamo Metropolitan Area",
  "238150, Chernyakhovskiy, Kaliningrad, Russia",
  "42115, Wuppertal, North Rhine-Westphalia, Germany",
  "LV-3602, Ventspils, Ventspils, Latvia",
  "682861, Vanino, Khabarovsk, Russia",
  "O'Higgins Region, Chile",
  "Oeiras, Lisbon, Portugal",
  "Marshalltown, Iowa, United States",
  "Bateshwar, Bah, Uttar Pradesh, India",
  "Chumaky, Dnipropetrovsk, Ukraine",
  "7945, Cape Town, Western Cape, South Africa",
  "Granite Bay, California, United States",
  "Brown County, Minnesota, United States",
  "Sheridan, Indiana, United States",
  "Jarud Banner, Inner Mongolia, China",
  "6020, Charleroi, Walloon Region, Belgium",
  "Mykolaiv, Mykolayiv, Ukraine",
  "Gates, North Carolina, United States",
  "Khmelnytskyy, Khmelnytsky, Ukraine",
  "LA2 0ED, Cockerham, England, United Kingdom",
  "Stone Hill, Sumter, South Carolina, United States",
  "K2C 0A1, Ottawa, Ontario, Canada",
  "Crawford County, Indiana, United States",
  "Perkins, Oklahoma, United States",
  "06266, South Windham, Connecticut, United States",
  "45619, Chesapeake, Ohio, United States",
  "40100, Jyväskylä, Central Finland, Finland",
  "628481, Kogalym, Khanty-Mansi, Russia",
  "453303, Kumertau, Bashkortostan, Russia",
  "85257, Scottsdale, Arizona, United States",
  "Maloarkhangel’sk, Orel, Russia",
  "346510, Shakhty, Rostov, Russia",
  "Greater Besancon Area",
  "65124, Pescara, Abruzzi, Italy",
  "430073, Baia Mare, Maramureş, Romania",
  "McDowell, Virginia, United States",
  "249450, Kirsanovo-Pyatnitsa, Kaluga, Russia",
  "North Lanarkshire, Scotland, United Kingdom",
  "712 02, Iráklion, Crete, Greece",
  "Clearfield, Pennsylvania, United States",
  "Olney, England, United Kingdom",
  "06756, Goshen, Connecticut, United States",
  "Maglizh, Stara Zagora, Bulgaria",
  "Greater Kempten Area",
  "16354, Titusville, Pennsylvania, United States",
  "Batesland, South Dakota, United States",
  "Pawnee County, Oklahoma, United States",
  "Namibia",
  "Stillwater, Pennsylvania, United States",
  "Angelina, Louisiana, United States",
  "658849, Slavgorod, Altai Krai, Russia",
  "165303, Kotlas, Arkhangel’sk, Russia",
  "12300, Bingol, Bingöl, Türkiye",
  "Filyos, Zonguldak, Türkiye",
  "629759, Nadym, Yamalo-Nenets, Russia",
  "Tuscolano Sud, Rome, Latium, Italy",
  "710 02, Iráklion, Crete, Greece",
  "Málaga, Santander, Colombia",
  "915400, Oltenița, Călăraşi, Romania",
  "Aston Rowant, England, United Kingdom",
  "960, Bamberg, Bavaria, Germany",
  "462712, Dombarovskiy, Orenburg, Russia",
  "Warsaw, Kentucky, United States",
  "67208, Wichita, Kansas, United States",
  "Dunnellon, Florida, United States",
  "692042, Lesozavodsk, Primorye, Russia",
  "Tocantins, Brazil",
  "Marcăuți, Dubăsari District, Moldova",
  "65759, Taneyville, Missouri, United States",
  "Cooke City, Montana, United States",
  "Toddville, Iowa, United States",
  "346901, Novoshakhtinsk, Rostov, Russia",
  "658138, Aleysk, Altai Krai, Russia",
  "22849, Shenandoah, Virginia, United States",
  "Pearl River, New York, United States",
  "9700-066, Angra do Heroísmo, Portugal",
  "66500, Yozgat, Yozgat, Türkiye",
  "26594, An Namur, Makkah, Saudi Arabia",
  "Old Haywoods, Sheffield, England, United Kingdom",
  "TA12 6AT, Kingsbury Episcopi, England, United Kingdom",
  "Mason County, Washington, United States",
  "Stadtbredimus, Grevenmacher, Luxembourg",
  "16120, Bursa, Bursa, Türkiye",
  "L10F9E8, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Vilniaus, Lithuania",
  "Granite Falls, Washington, United States",
  "49868, Newberry, Michigan, United States",
  "Şubarqudıq, Aqtöbe region, Kazakhstan",
  "71935, Caddo Gap, Arkansas, United States",
  "Strezhevoy, Tomsk, Russia",
  "5100, Gorna Oryahovitsa Municipality, Veliko Turnovo, Bulgaria",
  "Craiova Metropolitan Area",
  "29601, Marbella, Andalusia, Spain",
  "169729, Usinsk, Komi, Russia",
  "Polk County, Oregon, United States",
  "V3H 0E4, Port Moody, British Columbia, Canada",
  "Yagodnoye, Magadan, Russia",
  "Borisoglebskiy, Voronezh, Russia",
  "Amelia, Umbria, Italy",
  "C40A2M2, Esil, Akmola Region, Kazakhstan",
  "Thayer, Iowa, United States",
  "Round Rock, Texas, United States",
  "9304, Finnsnes, Troms og Finnmark, Norway",
  "Ramsey Forty Foot, England, United Kingdom",
  "YO12 4AA, Scarborough, England, United Kingdom",
  "E1A 7N1, Dieppe, New Brunswick, Canada",
  "1028, Budapest, Budapest, Hungary",
  "Augusta, Sicily, Italy",
  "Okhahlamba, KwaZulu-Natal, South Africa",
  "Windsor, Colorado, United States",
  "62032, Fillmore, Illinois, United States",
  "Naples, Maine, United States",
  "Shannon County, Missouri, United States",
  "Nassau, New York, United States",
  "Tuzla Canton, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "5700, Teteven, Lovech, Bulgaria",
  "Presov, Presov, Slovakia",
  "72941, Lavaca, Arkansas, United States",
  "Bantry, County Cork, Ireland",
  "31540, Donji Miholjac, Osijek-Baranja, Croatia",
  "Loudon, Tennessee, United States",
  "52001, Melilla, Melilla, Spain",
  "791 34, Falun, Dalarna County, Sweden",
  "25200, Erzurum, Erzurum, Türkiye",
  "02-495, Warsaw, Mazowieckie, Poland",
  "2354, Pernik, Pernik, Bulgaria",
  "Craven, Jacksonville, Florida, United States",
  "Çemişgezek, Tunceli, Türkiye",
  "47011, Valladolid, Castilla and Leon, Spain",
  "12765, Neversink, New York, United States",
  "Omutninskiy Rayon, Kirov, Russia",
  "625022, Tyumen, Tyumen’, Russia",
  "142279, Serpukhov, Moscow, Russia",
  "74470, Webbers Falls, Oklahoma, United States",
  "Montgomery County, Alabama, United States",
  "29369, Moore, South Carolina, United States",
  "Kimble County, Texas, United States",
  "78028, Kerrville, Texas, United States",
  "Klyuchi, Irkutsk, Russia",
  "617065, Krasnokamsk, Perm, Russia",
  "78802, Uvalde, Texas, United States",
  "Greenwood, Nova Scotia, Canada",
  "Norwich, Kansas, United States",
  "07400, Antalya, Antalya, Türkiye",
  "09113, Chemnitz, Saxony, Germany",
  "24142, Radford, Virginia, United States",
  "692537, Ussuriysk, Primorye, Russia",
  "De Doorns, Western Cape, South Africa",
  "694210, Poronaysk, Sakhalin, Russia",
  "Maury, Lignairolles, Occitanie, France",
  "Lancaster, New Hampshire, United States",
  "Morris, Oklahoma, United States",
  "70-540, Szczecin, Zachodniopomorskie, Poland",
  "Orenburgsky District, Orenburg, Russia",
  "41-914, Bytom, Śląskie, Poland",
  "700006, Iaşi, Iaşi, Romania",
  "Presidio, San Francisco, California, United States",
  "Rensselaerville, New York, United States",
  "Iasi Metropolitan Area",
  "532322, Patrapur, Odisha, India",
  "Carast Acres, Gentry, Arkansas, United States",
  "Dimmitt's Grove, Bloomington, Illinois, United States",
  "57355, Kimball, South Dakota, United States",
  "Wadi Al Rabie, Tripoli, Libya",
  "39451, Leakesville, Mississippi, United States",
  "Delta, Ohio, United States",
  "Furmanov, Ivanovo, Russia",
  "Pacific Heights, San Francisco, California, United States",
  "Funchal (Santa Luzia), Madeira Island, Portugal",
  "628311, Nefteyugansk, Khanty-Mansi, Russia",
  "Itawamba County, Mississippi, United States",
  "Duval County, Florida, United States",
  "NR25 6NX, West Beckham, England, United Kingdom",
  "Colonial, Criciúma, Santa Catarina, Brazil",
  "A91 TRF3, Dundalk, County Louth, Ireland",
  "78704, Austin, Texas, United States",
  "Val-de-Travers, Neuchâtel, Switzerland",
  "75051, Grand Prairie, Texas, United States",
  "Polk City, Iowa, United States",
  "8840, Einsiedeln, Schwyz, Switzerland",
  "625035, Tyumen, Tyumen’, Russia",
  "Decatur County, Georgia, United States",
  "35550, İzmir, İzmir, Türkiye",
  "249292, Frolovo, Kaluga, Russia",
  "Monroe County, Tennessee, United States",
  "Harlan, Indiana, United States",
  "84304, Cache Junction, Utah, United States",
  "SA6 7QQ, Swansea, Wales, United Kingdom",
  "L6 0AA, Liverpool, England, United Kingdom",
  "31021, Kohtla-Järve, Ida-Virumaa, Estonia",
  "Llano Grande District, Cartago, Costa Rica",
  "29018, Bowman, South Carolina, United States",
  "33585, Sumterville, Florida, United States",
  "628310, Nefteyugansk, Khanty-Mansi, Russia",
  "Kamenka, Penza, Russia",
  "Yemanzhelinsk, Chelyabinsk, Russia",
  "14000, Caen, Normandy, France",
  "17900, Çanakkale, Çanakkale, Türkiye",
  "Norton, England, United Kingdom",
  "034, Stoddard, New Hampshire, United States",
  "4033, Debrecen, Hajdú-Bihar, Hungary",
  "Krasnoyarskoye, Rostov, Russia",
  "Aberdeen City, Scotland, United Kingdom",
  "Jackson County, Michigan, United States",
  "Astrakhanskiy, Astrakhan’, Russia",
  "429822, Alatyr’, Chuvashia, Russia",
  "Illichivska silska rada, Crimea, Ukraine",
  "S0M 0S0, Dillon, Saskatchewan, Canada",
  "Benson, Saskatchewan, Canada",
  "Kolpino, Leningrad, Russia",
  "Roosevelt, Washington, United States",
  "KW15 1NY, Kirkwall, Scotland, United Kingdom",
  "Oconto, Central Frontenac, Ontario, Canada",
  "Hyde Park, Boston, Massachusetts, United States",
  "Tirebolu, Giresun, Türkiye",
  "607316, Mărgineni, Bacău, Romania",
  "Municipio Caroní, Bolivar State, Venezuela",
  "Merkez, Kastamonu, Türkiye",
  "Akpınar, Kırşehir, Türkiye",
  "29138, Saluda, South Carolina, United States",
  "52005, Melilla, Melilla, Spain",
  "357561, Pyatigorsk, Stavropol, Russia",
  "Emery Down, England, United Kingdom",
  "Palma, Cabo Delgado Province, Mozambique",
  "3322, Burgos, Cagayan Valley, Philippines",
  "397160, Borisoglebskiy, Voronezh, Russia",
  "Lahore District, Punjab, Pakistan",
  "664033, Irkutsk, Irkutsk, Russia",
  "Swain, Semiliguda, Odisha, India",
  "Villas Cibolas del Mar, Baja California, Mexico",
  "Sargent County, North Dakota, United States",
  "Kiel, Schleswig-Holstein, Germany",
  "8700-178, Olhão, Faro, Portugal",
  "Novoshakhtinsky, Primorye, Russia",
  "618235, Chusovoy, Perm, Russia",
  "629750, Nadym, Yamalo-Nenets, Russia",
  "Marion County, Illinois, United States",
  "Rose Bay, New South Wales, Australia",
  "Košice, Kosice, Slovakia",
  "2511, Rrogozhinë, Tirana, Albania",
  "Newberry, Florida, United States",
  "Crockett, California, United States",
  "Arrondissement of Charleroi, Walloon Region, Belgium",
  "Nevelsky District, Sakhalin, Russia",
  "Greater Florence, South Carolina Area",
  "Penza, Penza, Russia",
  "171987, Bezhetsk, Tver’, Russia",
  "Arlington Heights, Illinois, United States",
  "Grahamstown, Eastern Cape, South Africa",
  "Manvel, Texas, United States",
  "Heby kommun, Uppsala County, Sweden",
  "Avery Gardens North, Ridgeland, Mississippi, United States",
  "Lavaca, San Antonio, Texas, United States",
  "Mount Edgecombe, Westmoreland, Jamaica",
  "Curry, County Sligo, Ireland",
  "CV3 1AA, Coventry, England, United Kingdom",
  "Šamorín, Trnava Region, Slovakia",
  "Sioux County, North Dakota, United States",
  "Prairie du Sac, Wisconsin, United States",
  "169312, Ukhta, Komi, Russia",
  "B25D9A9, Esik, Almaty Region, Kazakhstan",
  "Palma, Balearic Islands, Spain",
  "Perry County, Mississippi, United States",
  "347070, Uglegorskiy, Rostov, Russia",
  "Belmont, Ohio, United States",
  "Marshall County, Mississippi, United States",
  "Conjunto Habitacional Santa Angelina, São Carlos, São Paulo, Brazil",
  "9900-011, Horta, Portugal",
  "B25D3D8, Esik, Almaty Region, Kazakhstan",
  "Białostocki, Podlaskie, Poland",
  "Orange, Connecticut, United States",
  "Carver County, Minnesota, United States",
  "78100, Karabuk, Karabuk, Türkiye",
  "Beecroft, New South Wales, Australia",
  "Komsomolsk-on-Amur, Khabarovsk, Russia",
  "83522, Cottonwood, Idaho, United States",
  "Davie County, North Carolina, United States",
  "30110-926, Belo Horizonte, Minas Gerais, Brazil",
  "Camden, North Carolina, United States",
  "72336, Forrest City, Arkansas, United States",
  "McKenzie Lake, Calgary, Alberta, Canada",
  "E1A 0A1, Dieppe, New Brunswick, Canada",
  "B25B6A8, Esik, Almaty Region, Kazakhstan",
  "Kittitas, Washington, United States",
  "Santa Cruz County, California, United States",
  "23883, Surry, Virginia, United States",
  "Person County, North Carolina, United States",
  "Serdivan, Sakarya, Türkiye",
  "Saint Barthélemy",
  "Addison, Indiana, United States",
  "Ewa Gentry, Hawaii, United States",
  "Níkaia, Attiki, Greece",
  "Severoural'sk, Sverdlovsk, Russia",
  "Bodrum Bucağı, Muğla, Türkiye",
  "North Sulawesi, Indonesia",
  "662980, Zheleznogorsk, Krasnoyarsk Krai, Russia",
  "Hillsborough, Northern Ireland, United Kingdom",
  "Rovagnate, Lombardy, Italy",
  "Nepal",
  "5407, Çorovodë, Berat, Albania",
  "220006, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "73040, Kherson, Kherson, Ukraine",
  "EH12 0AE, Edinburgh, Scotland, United Kingdom",
  "Shenandoah, Roberta Mill, North Carolina, United States",
  "82005, Cheyenne, Wyoming, United States",
  "Mirandela, Bragança, Portugal",
  "89496, Fallon, Nevada, United States",
  "Hong Kong SAR",
  "40055, Pendleton, Kentucky, United States",
  "Pittsburg, Illinois, United States",
  "Wallareenya, Western Australia, Australia",
  "72210, Louplande, Pays de la Loire, France",
  "Crawford County, Wisconsin, United States",
  "Gijón/Xixón, Principality of Asturias, Spain",
  "Naryan Mar, Nenets, Russia",
  "Burns-Downs, Sumter, South Carolina, United States",
  "Arlington, Tennessee, United States",
  "Morrison, Wisconsin, United States",
  "Jeffersonville, Indiana, United States",
  "LN8 2AS, Caenby Corner, England, United Kingdom",
  "Qazyqurt, Turkistan Region, Kazakhstan",
  "646784, Bologoye, Omsk, Russia",
  "Villeneuve-lès-Béziers, Occitanie, France",
  "67801, Dodge City, Kansas, United States",
  "33611, Bielefeld, North Rhine-Westphalia, Germany",
  "456863, Kyshtym, Chelyabinsk, Russia",
  "Mardin, Mardin, Türkiye",
  "Covington, Tennessee, United States",
  "Blairstown, New Jersey, United States",
  "DH2 3QF, Kimblesworth, England, United Kingdom",
  "Mahilyow, Mahilyow, Belarus",
  "Belknap Lookout, Grand Rapids, Michigan, United States",
  "Greater Osijek Area",
  "Petit-Bourg, Basse-Terre, Guadeloupe",
  "Greater Perpignan Area",
  "Granville, Ohio, United States",
  "50968, Cologne, North Rhine-Westphalia, Germany",
  "5629 HC, Eindhoven, North Brabant, Netherlands",
  "140400, Kolomna, Moscow, Russia",
  "Phillips, Maine, United States",
  "Norton, Mashonaland West Province, Zimbabwe",
  "Province of Tarragona, Catalonia, Spain",
  "Sanders, Montana, United States",
  "Constance, Baden-Württemberg, Germany",
  "Büyükçiftlik, Hakkâri, Türkiye",
  "Székesfehérvár, Hungary",
  "Albemarle Acres West, Chesapeake, Virginia, United States",
  "085 01, Bardejov, Presov, Slovakia",
  "Avgan, Uşak, Türkiye",
  "456780, Ozersk, Chelyabinsk, Russia",
  "Onondaga, Brant, Ontario, Canada",
  "Schleswig-Flensburg, Schleswig-Holstein, Germany",
  "352 46, Växjö, Kronoberg County, Sweden",
  "Toledo, Ohio, United States",
  "12073, Schoharie, New York, United States",
  "Barboursville, West Virginia, United States",
  "75958, Nacogdoches, Texas, United States",
  "51119, Rijeka, Primorje-Gorski, Croatia",
  "Popenguine-Ndayane, Thiès Region, Senegal",
  "Pittsburg, Texas, United States",
  "Hood River County, Oregon, United States",
  "Lehigh Acres, Florida, United States",
  "L9V 2K3, Dundalk, Ontario, Canada",
  "171081, Bologoye, Tver’, Russia",
  "Greater Ankara",
  "Platte County, Wyoming, United States",
  "Tomskiy, Amur, Russia",
  "Campbell, Ohio, United States",
  "Reevesville, South Carolina, United States",
  "Kanabec County, Minnesota, United States",
  "Kearney, Ontario, Canada",
  "5050-100, Peso da Régua, Vila Real, Portugal",
  "410056, Saratov, Saratov, Russia",
  "711 10, Iráklion, Crete, Greece",
  "Middle East",
  "66776, Thayer, Kansas, United States",
  "Gmina Korfantów, Opolskie, Poland",
  "172382, Rzhev, Tver’, Russia",
  "Aldansky District, Sakha, Russia",
  "Iowa County, Wisconsin, United States",
  "Bălţi, Moldova",
  "HU7 0AA, Kingston Upon Hull, England, United Kingdom",
  "5632 GM, Eindhoven, North Brabant, Netherlands",
  "18100, Cankiri, Çankırı, Türkiye",
  "5631 DA, Eindhoven, North Brabant, Netherlands",
  "94050, Sochiapa, Veracruz, Mexico",
  "Las Vegas, Nevada, United States",
  "692527, Ussuriysk, Primorye, Russia",
  "38330, Dyer, Tennessee, United States",
  "Sharp County, Arkansas, United States",
  "71004, Viljandi, Viljandimaa, Estonia",
  "Prairie Grove, Arkansas, United States",
  "03458, Peterborough, New Hampshire, United States",
  "16-100, Sokółka, Podlaskie, Poland",
  "Alatyr', Chuvashia, Russia",
  "Beechboro, Western Australia, Australia",
  "Kassela, Koulikoro, Mali",
  "Gloucester Point, Virginia, United States",
  "Genoa, Ohio, United States",
  "Grimes County, Texas, United States",
  "98817, Chelan Falls, Washington, United States",
  "12301, Schenectady, New York, United States",
  "Burton, Christchurch, England, United Kingdom",
  "Danville, California, United States",
  "Doroshivka, Mykolayiv, Ukraine",
  "Staraya Russa, Novgorod, Russia",
  "11090, Belgrade, Serbia",
  "Ekibastuz, Pavlodar Region, Kazakhstan",
  "Bergen, North Holland, Netherlands",
  "Henry County, Tennessee, United States",
  "75-423, Koszalin, Zachodniopomorskie, Poland",
  "Savonlinna, South Savo, Finland",
  "39150, Port Gibson, Mississippi, United States",
  "Franklin, Kentucky, United States",
  "Tovuz District, Qazakh-Tovuz, Azerbaijan",
  "Almaty, Kazakhstan",
  "426050, Izhevsk, Udmurtia, Russia",
  "Wickliffe, Kentucky, United States",
  "LS7 1AB, Leeds, England, United Kingdom",
  "Decatur County, Indiana, United States",
  "Mézens, Occitanie, France",
  "Coopersville, Michigan, United States",
  "Yates County, New York, United States",
  "12540, Vila-real, Valencian Community, Spain",
  "Omsk, Russia",
  "Crownhill, Milton Keynes, England, United Kingdom",
  "Rivière-du-Loup Regional County Municipality, Quebec, Canada",
  "West Minnehaha, Vancouver, Washington, United States",
  "678962, Neryungri, Sakha, Russia",
  "Surry County, North Carolina, United States",
  "410464, Oradea, Bihor, Romania",
  "623408, Kamensk Uralskiy, Sverdlovsk, Russia",
  "Voznesenska silska hromada, Cherkasy, Ukraine",
  "352501, Labinsk, Krasnodar, Russia",
  "Greater Halifax Metropolitan Area",
  "85057, Ingolstadt, Bavaria, Germany",
  "Trumbull County, Ohio, United States",
  "38778, Sunflower, Mississippi, United States",
  "9050, Appenzell, Appenzell Inner-Rhoden, Switzerland",
  "Granite, Oklahoma, United States",
  "56132, Garvin, Minnesota, United States",
  "19300, Çorum, Çorum, Türkiye",
  "Henry County, Georgia, United States",
  "Şarkışla, Sivas, Türkiye",
  "Madison County, Mississippi, United States",
  "Burleigh Hill, St. Catharines, Ontario, Canada",
  "Salihli Bucağı, Manisa, Türkiye",
  "Alekseyevka, Tatarstan, Russia",
  "629730, Nadym, Yamalo-Nenets, Russia",
  "72038, Crocketts Bluff, Arkansas, United States",
  "Al Madinah, Saudi Arabia",
  "Perry, Georgia, United States",
  "301836, Bogoroditsk, Tula, Russia",
  "75002, Allen, Texas, United States",
  "Fort Duchesne, Utah, United States",
  "36000, Kars, Kars, Türkiye",
  "Cheyenne Wells, Colorado, United States",
  "Scott, Louisiana, United States",
  "Palo, Eastern Visayas, Philippines",
  "Regensburg, Bavaria, Germany",
  "Greater Madison Area",
  "403876, Kamyshin, Volgograd, Russia",
  "E28D0E7, Makhambet, Atyrau Region, Kazakhstan",
  "692919, Nakhodka, Primorye, Russia",
  "Millard, Kentucky, United States",
  "R95 RP83, Kilkenny, County Kilkenny, Ireland",
  "Walkersville, Maryland, United States",
  "Scott County, Mississippi, United States",
  "Winona, Texas, United States",
  "Greenbrier, Brantford, Ontario, Canada",
  "5000, Odense, Region of Southern Denmark, Denmark",
  "2313, Rače, Maribor, Slovenia",
  "620137, Yekaterinburg, Sverdlovsk, Russia",
  "624992, Serov, Sverdlovsk, Russia",
  "Progress, British Columbia, Canada",
  "Rainsough, Manchester, England, United Kingdom",
  "Cimarron County, Oklahoma, United States",
  "4011, Shkodër, Shkodër, Albania",
  "52997, Atizapán de Zaragoza, México, Mexico",
  "Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "655004, Abakan, Khakassia, Russia",
  "9409, Selenicë, Vlorë, Albania",
  "2986 AA, Ridderkerk, South Holland, Netherlands",
  "39300, Kirklareli, Kırklareli, Türkiye",
  "Staunton Township, Illinois, United States",
  "Kearney, Nebraska, United States",
  "35-210, Rzeszów, Podkarpackie, Poland",
  "Sürmene, Trabzon, Türkiye",
  "DeKalb Junction, New York, United States",
  "Zamora de Hidalgo, Michoacán, Mexico",
  "Tetovo, Polog, North Macedonia",
  "655017, Abakan, Khakassia, Russia",
  "Dane, Wisconsin, United States",
  "Stevens Point, Wisconsin, United States",
  "Tucker, Saint James, Jamaica",
  "0581, Oslo, Oslo, Norway",
  "810173, Brăila, Brăila, Romania",
  "Danville, Virginia, United States",
  "06800, Mérida, Extremadura, Spain",
  "02871, Portsmouth, Rhode Island, United States",
  "74137, Tulsa, Oklahoma, United States",
  "671510, Bagdarin, Buryatia, Russia",
  "18008, Granada, Andalusia, Spain",
  "Brulé, Bubry, Brittany, France",
  "03001, Alicante, Valencian Community, Spain",
  "Oconto Falls, Wisconsin, United States",
  "C40B2F6, Esil, Akmola Region, Kazakhstan",
  "29830, Gumushane, Gümüşhane, Türkiye",
  "43724, Caldwell, Ohio, United States",
  "29650, Gumushane, Gümüşhane, Türkiye",
  "Guilford County, North Carolina, United States",
  "Dundee, Ohio, United States",
  "Mineral Ridge, Ohio, United States",
  "Yurga, Kemerovo, Russia",
  "Lawrence County, Ohio, United States",
  "5400-379, Chaves, Vila Real, Portugal",
  "Rasulzade, Baku Ekonomic Zone, Azerbaijan",
  "Kemijärvi, Lapland, Finland",
  "Harrisonburg, Virginia, United States",
  "Price, Pennsylvania, United States",
  "28150, Shelby, North Carolina, United States",
  "48001, Bilbao, Basque Country, Spain",
  "35396, Giessen, Hesse, Germany",
  "50548, Humboldt, Iowa, United States",
  "73750, Kingfisher, Oklahoma, United States",
  "Warrensburg, Missouri, United States",
  "Cascade, Virginia, United States",
  "Stevenson Ranch, California, United States",
  "28053, Gastonia, North Carolina, United States",
  "Decatur, Alabama, United States",
  "Hanover, Maryland, United States",
  "Highland, California, United States",
  "Dewitt, Hamilton, Ontario, Canada",
  "Coburg, Victoria, Australia",
  "SR1 1AE, Sunderland, England, United Kingdom",
  "Lane, Oklahoma, United States",
  "33441, Deerfield Beach, Florida, United States",
  "80539, Munich, Bavaria, Germany",
  "9803, Vadsø, Troms og Finnmark, Norway",
  "Gara Ianca, Brăila, Romania",
  "27893, Wilson, North Carolina, United States",
  "674674, Krasnokamensk, Zabaykalsky Krai, Russia",
  "30323, Kohtla-Järve, Ida-Virumaa, Estonia",
  "Winona Park, Ontario, Canada",
  "352900, Armavir, Krasnodar, Russia",
  "Kuopio sub-region, Northern Savonia, Finland",
  "Baar, Zug, Switzerland",
  "Monschau, North Rhine-Westphalia, Germany",
  "Suluova, Amasya, Türkiye",
  "36916, Pennington, Alabama, United States",
  "38400-010, Uberlândia, Minas Gerais, Brazil",
  "Nevinnomyssk, Stavropol, Russia",
  "C40A2D9, Esil, Akmola Region, Kazakhstan",
  "30002, Murcia, Región de Murcia, Spain",
  "22032, Fairfax, Virginia, United States",
  "10-684, Olsztyn, Warmińsko-mazurskie, Poland",
  "Poznański, Wielkopolskie, Poland",
  "71200, Kırıkkale, Kırıkkale, Türkiye",
  "Sokolova Gora, Zhytomyr, Ukraine",
  "Elkhorn, Wisconsin, United States",
  "Clearwater County, Minnesota, United States",
  "Pennington, Umdoni, KwaZulu-Natal, South Africa",
  "Sevastopol, Ukraine",
  "2602, Lillehammer, Innlandet, Norway",
  "Tārgale, Ventspils, Latvia",
  "4400, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "Alta, Iowa, United States",
  "45-720, Opole, Opolskie, Poland",
  "Calle Blancos, San Jose, Costa Rica",
  "Plymouth, Massachusetts, United States",
  "211390, Orsha, Vitsyebsk, Belarus",
  "Granville Summit, Pennsylvania, United States",
  "Clayton, North Carolina, United States",
  "Würzburg, Bavaria, Germany",
  "1950, Sion, Valais, Switzerland",
  "Bristol, New Hampshire, United States",
  "68167, Mannheim, Baden-Württemberg, Germany",
  "Le Locle, Neuchâtel, Switzerland",
  "De Kalb, Texas, United States",
  "84021, Duchesne, Utah, United States",
  "Medina County, Texas, United States",
  "TS9 5AA, Stokesley, England, United Kingdom",
  "53529, Dane, Wisconsin, United States",
  "Lehigh, Iowa, United States",
  "Marion County, Missouri, United States",
  "4010, Linz, Upper Austria, Austria",
  "Orange, New Jersey, United States",
  "N0G 1A0, Arthur, Ontario, Canada",
  "426039, Izhevsk, Udmurtia, Russia",
  "627324, Golyshmanovo, Tyumen’, Russia",
  "Nye County, Nevada, United States",
  "Omolon, Chukot, Russia",
  "347931, Taganrog, Rostov, Russia",
  "Harmonsburg, Pennsylvania, United States",
  "356422, Blagodarnyy, Stavropol, Russia",
  "Oktyabr'skiy gorodskoy sovet, Homel, Belarus",
  "29437, Dorchester, South Carolina, United States",
  "Kubrat Municipality, Razgrad, Bulgaria",
  "Getafe, Community of Madrid, Spain",
  "Moniteau, Missouri, United States",
  "Pipestone, Wetaskiwin County 10, Alberta, Canada",
  "Fernando Gómez Sandoval, Oaxaca, Mexico",
  "Toledo, Central Visayas, Philippines",
  "Sumners, Harlow, England, United Kingdom",
  "694140, Makarov, Sakhalin, Russia",
  "49401, Allendale, Michigan, United States",
  "Rainsh, Lansdowne, Uttarakhand, India",
  "BT26 6GR, Hillsborough, Northern Ireland, United Kingdom",
  "Bærum, Viken, Norway",
  "Adamsville, Alabama, United States",
  "602 20, Norrköping, Östergötland County, Sweden",
  "Broadmoor, Hitchcock, Texas, United States",
  "36307, Teluk Intan, Perak, Malaysia",
  "M52C5M7, Osakarovka, Karaganda Region, Kazakhstan",
  "671521, Bagdarin, Buryatia, Russia",
  "430017, Saransk, Mordovia, Russia",
  "67700, Linares, Nuevo León, Mexico",
  "Dorchester, Nebraska, United States",
  "60126, Ancona, Marches, Italy",
  "Cape May County, New Jersey, United States",
  "61080, Trabzon, Trabzon, Türkiye",
  "Bratislava Metropolitan Area",
  "Pyrohivtsi, Khmelnytsky, Ukraine",
  "Fonda, Iowa, United States",
  "40-004, Katowice, Śląskie, Poland",
  "94533, Fairfield, California, United States",
  "Onslow, Colchester, Subdivision B, Nova Scotia, Canada",
  "M52D2B6, Osakarovka, Karaganda Region, Kazakhstan",
  "Brunswick, Maine, United States",
  "Armstrong, Texas, United States",
  "36028, Poltava, Poltava, Ukraine",
  "Rowan, County Meath, Ireland",
  "Bentonville, Arkansas, United States",
  "347809, Kamensk-Shakhtinskiy, Rostov, Russia",
  "Kamenka, Irkutsk, Russia",
  "Kopeysk, Chelyabinsk, Russia",
  "18081-228, Sorocaba, São Paulo, Brazil",
  "Cheshire East, England, United Kingdom",
  "627741, Zaozërnyy, Tyumen’, Russia",
  "Northumberland, Ontario, Canada",
  "Augusta, Wisconsin, United States",
  "Vilas, Cedeira, Galicia, Spain",
  "1215, Medvode, Ljubljana, Slovenia",
  "Hanover, Ontario, Canada",
  "84126, Salerno, Campania, Italy",
  "20330, Denizli, Denizli, Türkiye",
  "52231, Harper, Iowa, United States",
  "Cabo Frio, Rio de Janeiro, Brazil",
  "23518, Norfolk, Virginia, United States",
  "Port Huron, Michigan, United States",
  "Little Ferry, New Jersey, United States",
  "Zaysan, East Kazakhstan Region, Kazakhstan",
  "77344, Kalush, Ivano-Frankivsk, Ukraine",
  "Deuel County, South Dakota, United States",
  "Hardin, Kentucky, United States",
  "04004, Almería, Andalusia, Spain",
  "CV1 1AH, Coventry, England, United Kingdom",
  "301880, Yefremov, Tula, Russia",
  "ST18 0RS, Great Haywood, England, United Kingdom",
  "V8A 1B2, Powell River, British Columbia, Canada",
  "Chatham, New York, United States",
  "0060852, Otaru, Hokkaido, Japan",
  "431451, Ruzayevka, Mordovia, Russia",
  "Moncton, New Brunswick, Canada",
  "Clarendon Park, Leicester, England, United Kingdom",
  "Carbon County, Montana, United States",
  "Bern-Mittelland, Berne, Switzerland",
  "182456, Orsha, Pskov, Russia",
  "Leninogorsky District, Tatarstan, Russia",
  "Hopkins-Tifft, Buffalo, New York, United States",
  "Obshtina Yablanitsa, Lovech, Bulgaria",
  "Avery, Medstead 497, Saskatchewan, Canada",
  "Cherbourg, Normandy, France",
  "27235, Colfax, North Carolina, United States",
  "301848, Yefremov, Tula, Russia",
  "Osnabrück Land",
  "Lake Leake, Tasmania, Australia",
  "Village of Duval, The Villages, Florida, United States",
  "Le Pâlis, La Chapelle-Rainsouin, Pays de la Loire, France",
  "Lamar, Oklahoma, United States",
  "40067, Simpsonville, Kentucky, United States",
  "R6W 4A7, Winkler, Manitoba, Canada",
  "NP25 3AB, Monmouth, Wales, United Kingdom",
  "Mexico City, Mexico",
  "163030, Archangel, Arkhangel’sk, Russia",
  "686135, Palatka, Magadan, Russia",
  "Chemung Township, Illinois, United States",
  "Dimos Chios, Northern Aegean, Greece",
  "X09D6C0, Shymkent, Shymkent, Kazakhstan",
  "362021, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "Piauí, Brazil",
  "Greenville, Rhode Island, United States",
  "71268, Quitman, Louisiana, United States",
  "Cook Islands",
  "Kungurskiy, Perm, Russia",
  "Wallace, South Carolina, United States",
  "18015, Granada, Andalusia, Spain",
  "Villa Cabello, Posadas, Misiones, Argentina",
  "Lee, Massachusetts, United States",
  "Mason, Virginia, United States",
  "C40A2Y6, Esil, Akmola Region, Kazakhstan",
  "Saskylakhskiy, Sakha, Russia",
  "690105, Vladivostok, Primorye, Russia",
  "Ray Nkonyeni, KwaZulu-Natal, South Africa",
  "Armstrong, Ontario, Canada",
  "Denton, Texas, United States",
  "64866, Stark City, Missouri, United States",
  "243020, Novozybkov, Bryansk, Russia",
  "589 31, Linköping, Östergötland County, Sweden",
  "9020-271, Funchal, Madeira Island, Portugal",
  "624947, Karpinsk, Sverdlovsk, Russia",
  "Nazran, Ingushetia, Russia",
  "93065, Simi Valley, California, United States",
  "542 50, Thessaloniki, Central Macedonia, Greece",
  "19070, Morton, Pennsylvania, United States",
  "Saint-Georges-sur-Renon, Auvergne-Rhône-Alpes, France",
  "Yates, New York, United States",
  "4404, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "E9C 1V7, Storeytown, New Brunswick, Canada",
  "Buyengero, Rumonge, Burundi",
  "Rankin, Arkansas, United States",
  "Furth, Lower Austria, Austria",
  "Port Harcourt, Rivers State, Nigeria",
  "08014, Barcelona, Catalonia, Spain",
  "Saint-Paul, Reunion",
  "977 52, Luleå, Norrbotten County, Sweden",
  "Los Ríos Region, Chile",
  "Buchanan, Georgia, United States",
  "Minnehaha, Washington, United States",
  "R13D9K0, Beyneu, Mangystau Region, Kazakhstan",
  "Davidson, Saskatchewan, Canada",
  "61820, Champaign, Illinois, United States",
  "Taranto, Apulia, Italy",
  "44122, Ferrara, Emilia-Romagna, Italy",
  "63301, St Charles, Missouri, United States",
  "Buffalo, New York, United States",
  "9006, Lushnjë, Fier, Albania",
  "Crotone, Calabria, Italy",
  "169926, Vorkuta, Komi, Russia",
  "V92 XN15, Tralee, County Kerry, Ireland",
  "Downtown, Spartanburg, South Carolina, United States",
  "455036, Magnitogorsk, Chelyabinsk, Russia",
  "Greater Mulhouse Area",
  "58300, Savonlinna, South Savo, Finland",
  "Box Butte County, Nebraska, United States",
  "15954, Seward, Pennsylvania, United States",
  "39367, Waynesboro, Mississippi, United States",
  "262 22, Patras, Western Greece, Greece",
  "02140, Cambridge, Massachusetts, United States",
  "Holt, Prescot, England, United Kingdom",
  "Greater Dessau-Rosslau Area",
  "85-080, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "23320, Chesapeake, Virginia, United States",
  "Kendall, Wisconsin, United States",
  "C00P9T1, Kokshetau, Akmola Region, Kazakhstan",
  "3921, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "4044, Debrecen, Hajdú-Bihar, Hungary",
  "Ogrezeni, Giurgiu, Romania",
  "Orleans, Ontario, Canada",
  "Atyrau, Atyrau Region, Kazakhstan",
  "McLeod County, Minnesota, United States",
  "29365, Lyman, South Carolina, United States",
  "07070, Antalya, Antalya, Türkiye",
  "Mainz-Bingen, Rhineland-Palatinate, Germany",
  "8032 DB, Zwolle, Overijssel, Netherlands",
  "United Kingdom",
  "302 55, Halmstad, Halland County, Sweden",
  "431445, Ruzayevka, Mordovia, Russia",
  "Sokyrianska miska hromada, Chernivtsi, Ukraine",
  "34714, Clermont, Florida, United States",
  "70900, Kuopio, Northern Savonia, Finland",
  "Hardy, Karen ward, Nairobi County, Kenya",
  "33760, Lugasson, Nouvelle-Aquitaine, France",
  "1231, Ljubljana, Ljubljana, Slovenia",
  "nolandubi f v, Kokrajhar, Assam, India",
  "802 67, Gävle, Gävleborg County, Sweden",
  "652154, Mariinskiy, Kemerovo, Russia",
  "80144, Naples, Campania, Italy",
  "26050, Eskişehir, Eskişehir, Türkiye",
  "646906, Kalachinsk, Omsk, Russia",
  "Dewey County, South Dakota, United States",
  "Esmeralda County, Nevada, United States",
  "446010, Syzran, Samara, Russia",
  "Ayr, Ontario, Canada",
  "Samsun, Samsun, Türkiye",
  "Sangareddy, Telangana, India",
  "Ayr, Pennsylvania, United States",
  "230000, Obukhovo, Hrodna, Belarus",
  "Chippewa Lake, Michigan, United States",
  "Sawyer County, Wisconsin, United States",
  "309181, Gubkin, Belgorod, Russia",
  "Moulins-lès-Metz, Grand Est, France",
  "Little Lehigh, Allentown, Pennsylvania, United States",
  "La Fontaine Ménard, Yffiniac, Brittany, France",
  "Nerchinsky District, Zabaykalsky Krai, Russia",
  "57105, Sioux Falls, South Dakota, United States",
  "28348, Hope Mills, North Carolina, United States",
  "Lille Heddinge, Region Zealand, Denmark",
  "5963, Nikopol, Pleven, Bulgaria",
  "Charleston, South Carolina, United States",
  "02000, Adıyaman, Adıyaman, Türkiye",
  "Gemeente Veendam, Groningen, Netherlands",
  "371 34, Karlskrona, Blekinge County, Sweden",
  "636763, Severnyy, Tomsk, Russia",
  "50122, Hubbard, Iowa, United States",
  "30030, Decatur, Georgia, United States",
  "162390, Velikiy Ustyug, Vologda, Russia",
  "05070, South Strafford, Vermont, United States",
  "Dewitt, Kentucky, United States",
  "16505, Erie, Pennsylvania, United States",
  "452794, Tuymazy, Bashkortostan, Russia",
  "59230, Saint-Amand-les-Eaux, Hauts-de-France, France",
  "Lozano Zavala (La Campana), Durango, Mexico",
  "00581, Helsinki, Uusimaa, Finland",
  "Guardavalle, Calabria, Italy",
  "Funchal (Santa Maria Maior), Madeira Island, Portugal",
  "62765, Emiliano Zapata, Morelos, Mexico",
  "17202, Chambersburg, Pennsylvania, United States",
  "Cameron, New York, United States",
  "429570, Mariinskiy Posad, Chuvashia, Russia",
  "43606, Toledo, Ohio, United States",
  "95127, Catania, Sicily, Italy",
  "Podgorica, Montenegro",
  "Barton-Upon-Humber, England, United Kingdom",
  "Greater Newport Area",
  "Zolotonosha, Cherkasy, Ukraine",
  "Aci Castello, Sicily, Italy",
  "Thayer, Kansas, United States",
  "Wrocławski, Dolnośląskie, Poland",
  "Carrollton, Kentucky, United States",
  "632335, Barabinsk, Novosibirsk, Russia",
  "Shunde District, Guangdong, China",
  "45-071, Opole, Opolskie, Poland",
  "VLT 1122, Valletta, Valletta, Malta",
  "Békéscsaba District, Békés, Hungary",
  "Yakima Hill, Tacoma, Washington, United States",
  "Cherry, Illinois, United States",
  "430030, Saransk, Mordovia, Russia",
  "Shal’skiy, Karelia, Russia",
  "Powell, Tennessee, United States",
  "94500, Keminmaa, Lapland, Finland",
  "8804, Sliven, Sliven, Bulgaria",
  "Westchester, Florida, United States",
  "Nobleton, Ontario, Canada",
  "Bozeman, Montana, United States",
  "800556, Galaţi, Galaţi, Romania",
  "440004, Satu Mare, Satu Mare, Romania",
  "427438, Votkinsk, Udmurtia, Russia",
  "692053, Lesozavodsk, Primorye, Russia",
  "Starrenhof, Schwäbisch Gmünd, Baden-Württemberg, Germany",
  "53020, Rize, Rize, Türkiye",
  "Cartagena, Cienfuegos, Cuba",
  "M22 9AL, Wythenshawe, England, United Kingdom",
  "Wilkes-Barre, Pennsylvania, United States",
  "Broughty Ferry, Scotland, United Kingdom",
  "23270, Elâzığ, Elazığ, Türkiye",
  "79550, Snyder, Texas, United States",
  "665102, Nizhneudinsk, Irkutsk, Russia",
  "7406, Kaposvár, Somogy, Hungary",
  "Ust’-Buzulukskaya, Tambov, Russia",
  "187552, Tikhvin, Leningrad, Russia",
  "56663, Pennington, Minnesota, United States",
  "Hallett Cove, South Australia, Australia",
  "Greenwood Lake, New York, United States",
  "G3064AEA, Bandera, Santiago del Estero, Argentina",
  "Brunswick West, Victoria, Australia",
  "Montague Harbour, Southern Gulf Islands, British Columbia, Canada",
  "4970-105, Arcos de Valdevez, Viana do Castelo, Portugal",
  "Bedford County, Virginia, United States",
  "96022, Cottonwood, California, United States",
  "Lowndes County, Mississippi, United States",
  "Lymanska miska hromada, Donetsk, Ukraine",
  "Wyandotte Towne Centre, Windsor, Ontario, Canada",
  "56128, Pisa, Tuscany, Italy",
  "Kyzylkoga District, Atyrau Region, Kazakhstan",
  "Passaic County, New Jersey, United States",
  "08030, Gloucester City, New Jersey, United States",
  "Tyler-Jacksonville Area",
  "Fulton, Missouri, United States",
  "Dewey, Oklahoma, United States",
  "Pepin, Wisconsin, United States",
  "Castelo, Espírito Santo, Brazil",
  "Montague, Massachusetts, United States",
  "446021, Syzran, Samara, Russia",
  "Yaroslavl’, Russia",
  "Midland County, Michigan, United States",
  "49090, Osnabrück, Lower Saxony, Germany",
  "693021, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "18147, Rostock, Mecklenburg-West Pomerania, Germany",
  "360010, Nalchik, Kabardino-Balkaria, Russia",
  "Linn Grove, Iowa, United States",
  "Sharekurd County, Chaharmahal and Bakhtiyari Province, Iran",
  "Mississippi, United States",
  "Suffolk Hill, Dumfries, Scotland, United Kingdom",
  "Bradford, Ontario, Canada",
  "440186, Satu Mare, Satu Mare, Romania",
  "Duque de Caxias, Rio de Janeiro, Brazil",
  "21133, Randallstown, Maryland, United States",
  "98326, Clallam Bay, Washington, United States",
  "Wythe County, Virginia, United States",
  "Camden, New Jersey, United States",
  "500002, Braşov, Braşov, Romania",
  "140560, Kolomna, Moscow, Russia",
  "67206, Wichita, Kansas, United States",
  "Millard Cove, North Carolina, United States",
  "S03K0K9, Pavlodar, Pavlodar Region, Kazakhstan",
  "79019, Lviv, Lviv, Ukraine",
  "Hayes County, Nebraska, United States",
  "Buhuşi, Bacău, Romania",
  "Greater London, England, United Kingdom",
  "99085, Erfurt, Thuringia, Germany",
  "653 50, Karlstad, Värmland County, Sweden",
  "La Petite Bertière, Gy-en-Sologne, Centre-Val de Loire, France",
  "Celâliye, Kırklareli, Türkiye",
  "16701, Bradford, Pennsylvania, United States",
  "Yefremovo-Zykovskiy, Orenburg, Russia",
  "61009, Kharkiv, Kharkiv, Ukraine",
  "Nikolayevskoye, Irkutsk, Russia",
  "Ïnder district, Atyrau Region, Kazakhstan",
  "El Blanco, Lara State, Venezuela",
  "Presidios de Abajo, Durango, Mexico",
  "5010, Berat, Berat, Albania",
  "Fremont, Nebraska, United States",
  "646104, Nazyvayevsk, Omsk, Russia",
  "Kanash, Tatarstan, Russia",
  "34134, Istanbul, Istanbul, Türkiye",
  "164757, Mezen, Arkhangel’sk, Russia",
  "Sumner, Georgia, United States",
  "Cortland County, New York, United States",
  "Meeker, Oklahoma, United States",
  "R7N 2T9, Dauphin, Manitoba, Canada",
  "Shepton Montague, England, United Kingdom",
  "50-529, Wrocław, Dolnośląskie, Poland",
  "24113, Martinsville, Virginia, United States",
  "652161, Mariinskiy, Kemerovo, Russia",
  "Stein bei Nürnberg, Bavaria, Germany",
  "Zambia",
  "81625, Craig, Colorado, United States",
  "43149, Rockbridge, Ohio, United States",
  "Greater Kitchener-Cambridge-Waterloo Metropolitan Area",
  "Pernik, Bulgaria",
  "Rzhevka, Belgorod, Russia",
  "Ineu, Arad, Romania",
  "92285, Landers, California, United States",
  "301846, Yefremov, Tula, Russia",
  "Trumbull Center, Connecticut, United States",
  "Boone County, Illinois, United States",
  "96300, Rovaniemi, Lapland, Finland",
  "630007, Novosibirsk, Novosibirsk, Russia",
  "Greater Cleveland",
  "Mastaga, Baku Ekonomic Zone, Azerbaijan",
  "94536, Fremont, California, United States",
  "Genthin, Saxony-Anhalt, Germany",
  "44102, Cleveland, Ohio, United States",
  "271 50, Pírgos, Western Greece, Greece",
  "76028, Burleson, Texas, United States",
  "Curryville, New Brunswick, Canada",
  "21000, Split, Split-Dalmatia, Croatia",
  "140412, Kolomna, Moscow, Russia",
  "39130, Magdeburg, Saxony-Anhalt, Germany",
  "08041, Makarov, Kyiv, Ukraine",
  "98391, Bonney Lake, Washington, United States",
  "13400, Butterworth, Penang, Malaysia",
  "78745, Austin, Texas, United States",
  "Korfantów, Opolskie, Poland",
  "Blue Ridge, Virginia, United States",
  "Whatcom County, Washington, United States",
  "5237 LD, ’s-Hertogenbosch, North Brabant, Netherlands",
  "Tromsø, Troms og Finnmark, Norway",
  "309754, Rzhevka, Belgorod, Russia",
  "692049, Lesozavodsk, Primorye, Russia",
  "Eureka Center, Wisconsin, United States",
  "Cimarron, Kansas, United States",
  "Sharbaqty District, Pavlodar Region, Kazakhstan",
  "Clinton County, Michigan, United States",
  "León, León, Nicaragua",
  "301360, Aleksin, Kaluga, Russia",
  "Canadian Lead, New South Wales, Australia",
  "09800, Aydın, Aydın, Türkiye",
  "Bono Region, Ghana",
  "Kecamatan Jenamas, Central Kalimantan, Indonesia",
  "Morrow, Georgia, United States",
  "Shumerlya, Chuvashia, Russia",
  "PA15 2AD, Greenock, Scotland, United Kingdom",
  "Oxford, England, United Kingdom",
  "Bradford, Ohio, United States",
  "Kamyshinsky District, Volgograd, Russia",
  "Danewal, Shahkot, Punjab, India",
  "Vitim, Sakha, Russia",
  "Portico di Caserta, Campania, Italy",
  "Sumy Metropolitan Area",
  "BT28 2HU, Lisburn, Northern Ireland, United Kingdom",
  "Windsor, Vermont, United States",
  "Kırıkkale Bucağı, Kırıkkale, Türkiye",
  "403871, Kamyshin, Volgograd, Russia",
  "Sandusky, Illinois, United States",
  "Ward 3, Ho Chi Minh City, Vietnam",
  "Victoria Municipality, Tamaulipas, Mexico",
  "10154, Turin, Piedmont, Italy",
  "301840, Yefremov, Tula, Russia",
  "Ashaga-Stal'skiy, Dagestan, Russia",
  "Non Pariel, Westmoreland, Jamaica",
  "62017, Coffeen, Illinois, United States",
  "Ménard, Chavagne, Brittany, France",
  "E6H 1H1, Ritchie, New Brunswick, Canada",
  "Galich, Kostroma, Russia",
  "Jenaz, Graubünden, Switzerland",
  "Hämeenlinna, Kanta-Häme, Finland",
  "Whitewater, Wisconsin, United States",
  "Lyman, Nebraska, United States",
  "54812, Barron, Wisconsin, United States",
  "Lafayette Parish County, Louisiana, United States",
  "6151, South Perth, Western Australia, Australia",
  "Clayton, Victoria, Australia",
  "F42G9B5, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "Odeskyi Raion, Odessa, Ukraine",
  "63951, Lowndes, Missouri, United States",
  "Ironbridge, Telford, England, United Kingdom",
  "6967, Lugano, Ticino, Switzerland",
  "636843, Asino, Tomsk, Russia",
  "71011, Viljandi, Viljandimaa, Estonia",
  "28000, Priyutovka, Kirovohrad, Ukraine",
  "Arad, Muharraq Governorate, Bahrain",
  "Progress, Tula, Russia",
  "BT27 4AA, Lisburn, Northern Ireland, United Kingdom",
  "01220, Adana, Adana, Türkiye",
  "3305, Gramsh, Elbasan, Albania",
  "Lukovit Municipality, Lovech, Bulgaria",
  "Greater Minneapolis-St. Paul Area",
  "28269, Charlotte, North Carolina, United States",
  "674603, Borzya, Zabaykalsky Krai, Russia",
  "646900, Kalachinsk, Omsk, Russia",
  "Tovstenska selyshchna hromada, Ternopil, Ukraine",
  "Hafik, Sivas, Türkiye",
  "Pecos Ranch, Chandler, Arizona, United States",
  "Mount Sheridan, Queensland, Australia",
  "Magnitogorsk, Chelyabinsk, Russia",
  "676859, Belogorsk, Amur, Russia",
  "Tula, Tula, Russia",
  "09370, Aydın, Aydın, Türkiye",
  "The Slopes, New South Wales, Australia",
  "Parmer County, Texas, United States",
  "Orsha, Tver’, Russia",
  "G6B 3C9, Nantes, Quebec, Canada",
  "241035, Bryansk, Bryansk, Russia",
  "Dar es Salaam, Tanzania",
  "Geneva Metropolitan Area",
  "97078, Würzburg, Bavaria, Germany",
  "Bălți, Moldova",
  "Leninskiy, Hrodna, Belarus",
  "386105, Nazran, Ingushetia, Russia",
  "9925, Kirkenes, Troms og Finnmark, Norway",
  "Fort Gibson, Oklahoma, United States",
  "393761, Michurinsk, Tambov, Russia",
  "301842, Yefremov, Tula, Russia",
  "Timashyovsky District, Krasnodar, Russia",
  "694615, Kholmsk, Sakhalin, Russia",
  "13130, Hämeenlinna, Kanta-Häme, Finland",
  "Kargat, Novosibirsk, Russia",
  "353905, Novorossiysk, Krasnodar, Russia",
  "Mount Alexander, Victoria, Australia",
  "Şahinbey, Gaziantep, Türkiye",
  "369000, Cherkessk, Karachay-Cherkessia, Russia",
  "40380, Stanton, Kentucky, United States",
  "Eddy, Texas, United States",
  "90504, Haapsalu, Laane, Estonia",
  "Steele, Alabama, United States",
  "Transylvania County, North Carolina, United States",
  "Cleveland, Tennessee, United States",
  "68853, Loup City, Nebraska, United States",
  "13148, Seneca Falls, New York, United States",
  "Vilas, Colorado, United States",
  "Gägelow, Mecklenburg-West Pomerania, Germany",
  "Mariinsky District, Kemerovo, Russia",
  "Karshi, Qashqadaryo Region, Uzbekistan",
  "16685, Todd, Pennsylvania, United States",
  "9900, Kirkenes, Troms og Finnmark, Norway",
  "1000-171, Lisbon, Lisbon, Portugal",
  "24413, Subotica, Vojvodina, Serbia",
  "35242, Birmingham, Alabama, United States",
  "48543, Nahirianka, Ternopil, Ukraine",
  "Luzerna, Santa Catarina, Brazil",
  "80219, Denver, Colorado, United States",
  "Kostanay Region, Kazakhstan",
  "676301, Shimanovsk, Amur, Russia",
  "Chautauqua, New York, United States",
  "18009, Granada, Andalusia, Spain",
  "Price Town, Wales, United Kingdom",
  "Le Millard, Cottance, Auvergne-Rhône-Alpes, France",
  "Shirvan - Salyan, Azerbaijan",
  "Kohtla-Järve linn, Ida-Virumaa, Estonia",
  "H54 WV84, Tuam, County Galway, Ireland",
  "Ulster, New York, United States",
  "Wakeman, Ohio, United States",
  "Ettelbruck, Diekirch, Luxembourg",
  "Cartagena Del Chairá, Caquetá, Colombia",
  "347924, Taganrog, Rostov, Russia",
  "37357, Morrison, Tennessee, United States",
  "B25C6B8, Esik, Almaty Region, Kazakhstan",
  "180000, Pskov, Pskov, Russia",
  "Bradford Woods, Pennsylvania, United States",
  "Reservoir, Harrisonburg, Virginia, United States",
  "422985, Chistopol’, Tatarstan, Russia",
  "Barabinsk, Novosibirsk, Russia",
  "Tononoka ward, Mombasa County, Kenya",
  "Rosario de Lerma, Salta, Argentina",
  "Treasure Township, Girwa, Rajasthan, India",
  "Salem, Illinois, United States",
  "31370, Beaufort, Occitanie, France",
  "Socorro do Piauí, Piauí, Brazil",
  "6727, Szeged, Csongrád, Hungary",
  "F91 R6K6, Sligo, County Sligo, Ireland",
  "7048, Trondheim, Trøndelag, Norway",
  "Oliveira de Frades, Viseu, Portugal",
  "47222, Kaunas, Kauno, Lithuania",
  "Isanti County, Minnesota, United States",
  "8903, Zalaegerszeg, Zala, Hungary",
  "'s-Hertogenbosch, North Brabant, Netherlands",
  "Tîrgu Jiu, Gorj, Romania",
  "Sainte-Thérèse, Quebec, Canada",
  "73843, Gage, Oklahoma, United States",
  "28041, Madrid, Community of Madrid, Spain",
  "De Witt, Iowa, United States",
  "Rusk, Texas, United States",
  "12170, Stillwater, New York, United States",
  "Brookston, Texas, United States",
  "McIntosh Lake, Bee Ridge, Florida, United States",
  "59860, Tekirdağ, Tekirdağ, Türkiye",
  "360012, Nalchik, Kabardino-Balkaria, Russia",
  "Yazoo City, Mississippi, United States",
  "Berlin, Germany",
  "653053, Prokopyevsk, Kemerovo, Russia",
  "Shirak Province, Armenia",
  "McPherson County, Kansas, United States",
  "Wheatland County, Montana, United States",
  "Montour, Nercillac, Nouvelle-Aquitaine, France",
  "Lively, Ontario, Canada",
  "Vau i Dejës, Shkodër, Albania",
  "Stadt Osnabrück, Lower Saxony, Germany",
  "Belgorodsky District, Belgorod, Russia",
  "Liberty, Texas, United States",
  "142110, Podolsk, Moscow, Russia",
  "Fanning Shores, Lake Alfred, Florida, United States",
  "Leon County, Florida, United States",
  "09200, Aydın, Aydın, Türkiye",
  "Franklin County, Pennsylvania, United States",
  "4201, Benkovski, Plovdiv, Bulgaria",
  "75707, Tyler, Texas, United States",
  "Larimer County, Colorado, United States",
  "412316, Balashov, Saratov, Russia",
  "34117, Kassel, Hesse, Germany",
  "8304, Peshkopi, Dibrë, Albania",
  "Albany, New York, United States",
  "Joya de los Sachas, Orellana, Ecuador",
  "33649, Bielefeld, North Rhine-Westphalia, Germany",
  "Rhode Island, United States",
  "Plymouth, Connecticut, United States",
  "35-002, Rzeszów, Podkarpackie, Poland",
  "Opole, Opolskie, Poland",
  "Putnamville, Indiana, United States",
  "BT28 2XY, Lisburn, Northern Ireland, United Kingdom",
  "6408, Përmet, Gjirokastër, Albania",
  "653045, Prokopyevsk, Kemerovo, Russia",
  "Ponta Delgada (São José), Ilha de São Miguel, Portugal",
  "152917, Rybinsk, Yaroslavl’, Russia",
  "Remire-Montjoly, Cayenne, French Guiana",
  "Itasca, Illinois, United States",
  "63700, Şanlıurfa, Şanliurfa, Türkiye",
  "920 01, Hlohovec, Trnava Region, Slovakia",
  "Catawba Acres, South Carolina, United States",
  "36312, Vigo, Galicia, Spain",
  "Pukekawa, Waikato, New Zealand",
  "46400, Kahramanmaraş, Kahraman Maras, Türkiye",
  "Udomlya, Tver’, Russia",
  "Fillmore District, San Francisco, California, United States",
  "Fillmore, Missouri, United States",
  "Huntington Park, California, United States",
  "8500, Aytos, Burgas, Bulgaria",
  "225417, Baranavichy, Brest, Belarus",
  "35106, Slavonski Brod, Brod-Posavina, Croatia",
  "Cabarrus Woods, North Carolina, United States",
  "Hopewell, Virginia, United States",
  "Zell, Lucerne, Switzerland",
  "Győrújbarát, Győr-Moson-Sopron, Hungary",
  "23233, Henrico, Virginia, United States",
  "Socorro, Quezon City, National Capital Region, Philippines",
  "Ivdel', Sverdlovsk, Russia",
  "Kozluk, Batman, Türkiye",
  "56475, Randall, Minnesota, United States",
  "1217, Vodice, Ljubljana, Slovenia",
  "San Antonio Cambridge Village East Terrell Hills, San Antonio, Texas, United States",
  "SS1 1BF, Southend-On-Sea, England, United Kingdom",
  "Hayes, Virginia, United States",
  "Huntington Beach, California, United States",
  "453839, Sibay, Bashkortostan, Russia",
  "94539, Fremont, California, United States",
  "4001, Shkodër, Shkodër, Albania",
  "67637, Ellis, Kansas, United States",
  "Rankinville, Inverness, Subdivision B, Nova Scotia, Canada",
  "Anderson County, Kentucky, United States",
  "Randall Park, Appleton, Wisconsin, United States",
  "211445, Navapolatsk, Vitsyebsk, Belarus",
  "Cassola, Veneto, Italy",
  "Davis, Illinois, United States",
  "Oxford, Alabama, United States",
  "6060, Charleroi, Walloon Region, Belgium",
  "Shirahama, Wakayama, Japan",
  "Murcia, Western Visayas, Philippines",
  "59527, Hays, Montana, United States",
  "Samarang, West Java, Indonesia",
  "59345, Powderville, Montana, United States",
  "Grant County, Indiana, United States",
  "220078, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "629831, Gubkinskiy, Yamalo-Nenets, Russia",
  "45970, Manisa, Manisa, Türkiye",
  "40831, Harlan, Kentucky, United States",
  "Bury St Edmunds, England, United Kingdom",
  "M2 5GU, Manchester, England, United Kingdom",
  "Calhoun County, South Carolina, United States",
  "Cottondale, Florida, United States",
  "84089, Clearfield, Utah, United States",
  "Saint-Etienne-Roilaye, Hauts-de-France, France",
  "53108, Caledonia, Wisconsin, United States",
  "66274, Tulungagung, East Java, Indonesia",
  "69115, Heidelberg, Baden-Württemberg, Germany",
  "02400, Adıyaman, Adıyaman, Türkiye",
  "Saint Catherine, Jamaica",
  "Perry County, Missouri, United States",
  "McKenzie Corner, New Brunswick, Canada",
  "Carrigaline, County Cork, Ireland",
  "Porto de Mós, Leiria, Portugal",
  "791 74, Falun, Dalarna County, Sweden",
  "669002, Ordynskiy, Ust-Ordyn-Buryat, Russia",
  "Znamensk, Astrakhan’, Russia",
  "Ipswich, Massachusetts, United States",
  "Bladenboro, North Carolina, United States",
  "Kenya",
  "Alderney, Guernsey",
  "Fort Klamath, Oregon, United States",
  "309190, Gubkin, Belgorod, Russia",
  "20710, Bladensburg, Maryland, United States",
  "Crawford, Colorado, United States",
  "73901, Hooker, Oklahoma, United States",
  "142400, Noginsk, Moscow, Russia",
  "427431, Votkinsk, Udmurtia, Russia",
  "Gonzales Prada, Ciudad Nueva, Tacna, Peru",
  "Cache County, Utah, United States",
  "F42G7M7, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "Panola County, Texas, United States",
  "Provincia Burnet O'Connor, Tarija, Bolivia",
  "7711, Steinkjer, Trøndelag, Norway",
  "Dodge County, Nebraska, United States",
  "Woodward County, Oklahoma, United States",
  "9713 AA, Groningen, Groningen, Netherlands",
  "62033, Gillespie, Illinois, United States",
  "357118, Nevinnomyssk, Stavropol, Russia",
  "347916, Taganrog, Rostov, Russia",
  "59353, Wibaux, Montana, United States",
  "Sorocaba, São Paulo, Brazil",
  "Kiselevskoye, Khabarovsk, Russia",
  "42368, Reynolds Station, Kentucky, United States",
  "G64 4AY, Torrance, Scotland, United Kingdom",
  "38868, Shannon, Mississippi, United States",
  "X38H8M5, Kentau, Turkistan Region, Kazakhstan",
  "Malawi",
  "Les Nobles, Nuret-le-Ferron, Centre-Val de Loire, France",
  "South Sudan",
  "214 45, Malmo, Skåne County, Sweden",
  "346527, Shakhty, Rostov, Russia",
  "Natarabana, Northern Province, Rwanda",
  "67070, Kiowa, Kansas, United States",
  "Columbus, Indiana, United States",
  "Detva, Banska Bystrica, Slovakia",
  "41503, South Williamson, Kentucky, United States",
  "1150, Vienna, Vienna, Austria",
  "J0B 3E0, Stanstead, Quebec, Canada",
  "62950, Tunceli, Tunceli, Türkiye",
  "Stokes, Ohio, United States",
  "Krasnoarmeyskiy, Chukot, Russia",
  "Mercersburg, Pennsylvania, United States",
  "06125, Perugia, Umbria, Italy",
  "69118, Zaporizhzhya, Zaporizhzhya, Ukraine",
  "452122, Rayevskiy, Bashkortostan, Russia",
  "Tikhvin, Leningrad, Russia",
  "Doornkop, Potchefstroom, North-West, South Africa",
  "163013, Archangel, Arkhangel’sk, Russia",
  "Greater Perugia Metropolitan Area",
  "Vaasa sub-region, Ostrobothnia, Finland",
  "Barcelona, Anzoategui State, Venezuela",
  "Frome, Westmoreland, Jamaica",
  "23266, Zrenjanin, Vojvodina, Serbia",
  "Baglar, Diyarbakır, Türkiye",
  "Denizli, Türkiye",
  "652470, Anzhero-Sudzhensk, Kemerovo, Russia",
  "Ust’-Ishim, Omsk, Russia",
  "Chariton County, Missouri, United States",
  "Oxford, Wisconsin, United States",
  "Rockland County, New York, United States",
  "1294, Oslo, Oslo, Norway",
  "Blacksburg-Christiansburg-Radford Area",
  "Orel, Russia",
  "Hochdorf, Lucerne, Switzerland",
  "Johnson City, Tennessee, United States",
  "Vietri di Potenza, Basilicata, Italy",
  "Graham County, North Carolina, United States",
  "654066, Novokuznetsk, Kemerovo, Russia",
  "53039, Juneau, Wisconsin, United States",
  "Yeysky District, Krasnodar, Russia",
  "Coal City, Indiana, United States",
  "Nordöstra Göteborg, Västra Götaland County, Sweden",
  "Martin, Western Australia, Australia",
  "68159, Mannheim, Baden-Württemberg, Germany",
  "Saline County, Arkansas, United States",
  "185 34, Piraeus, Attiki, Greece",
  "658213, Rubtsovsk, Altai Krai, Russia",
  "10119, Berlin, Berlin, Germany",
  "Humphreys County, Tennessee, United States",
  "Essex County, Virginia, United States",
  "McPherson, Kansas, United States",
  "F42G1H6, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "Florence, Arizona, United States",
  "Hickory Flat, Mississippi, United States",
  "22300, Knin, Sibenik-Knin, Croatia",
  "1404, Sofia, Sofia City, Bulgaria",
  "Ostrog, Rivne, Ukraine",
  "11002, Cadiz, Andalusia, Spain",
  "03040, Afyonkarahisar, Afyon, Türkiye",
  "PH33 6DG, Fort William, Scotland, United Kingdom",
  "628690, Megion, Khanty-Mansi, Russia",
  "391, Magdeburg, Saxony-Anhalt, Germany",
  "PE29 3TD, Huntingdon, England, United Kingdom",
  "Lidatorp och Klövsta, Stockholm County, Sweden",
  "Ulster Park, New York, United States",
  "Blaine County, Idaho, United States",
  "39771, Walthall, Mississippi, United States",
  "Beverly Hills, California, United States",
  "Combe St Nicholas, England, United Kingdom",
  "Karcag District, Jász-Nagykun-Szolnok, Hungary",
  "Sectorul Ciocana, Chișinău, Moldova",
  "L2H 0A1, Niagara Falls, Ontario, Canada",
  "Scioto Woods, Ohio, United States",
  "Yeniseysk, Krasnoyarsk Krai, Russia",
  "TS3 0QL, Middlesbrough, England, United Kingdom",
  "70118, New Orleans, Louisiana, United States",
  "Iredell County, North Carolina, United States",
  "Noble, Louisiana, United States",
  "27470, Gaziantep, Gaziantep, Türkiye",
  "Tatarskaya Kargala, Orenburg, Russia",
  "Blanco, María Trinidad Sánchez, Dominican Republic",
  "Karesi, Balıkesir, Türkiye",
  "358000, Elista, Kalmykia, Russia",
  "Livingston, Scotland, United Kingdom",
  "86-309, Grudziądz, Kujawsko-pomorskie, Poland",
  "6836 NC, Arnhem, Gelderland, Netherlands",
  "Harland, Zolling, Bavaria, Germany",
  "363755, Mozdok, Severnaya Osetiya-Alaniya, Russia",
  "76189, Karlsruhe, Baden-Württemberg, Germany",
  "57110, Sioux Falls, South Dakota, United States",
  "74723, Bennington, Oklahoma, United States",
  "Sweet Water, Alabama, United States",
  "4305, Koplik, Shkodër, Albania",
  "Southampton, Ontario, Canada",
  "Manchester, Tennessee, United States",
  "Floyd, Iowa, United States",
  "England, United Kingdom",
  "38110, Santa Cruz de Tenerife, Canary Islands, Spain",
  "Somerset County, Maine, United States",
  "McIntosh County, Oklahoma, United States",
  "04001, Almería, Andalusia, Spain",
  "Christiana, Pennsylvania, United States",
  "Diyarbakır, Türkiye",
  "Schoharie Valley Railroad Complex, New York, United States",
  "6906, Lugano, Ticino, Switzerland",
  "Montpellier, Occitanie, France",
  "20099, Hamburg, Hamburg, Germany",
  "41-911, Bytom, Śląskie, Poland",
  "Tula Metropolitan Area",
  "3060-671, Cantanhede, Coimbra, Portugal",
  "09128, Chemnitz, Saxony, Germany",
  "98148, Messina, Sicily, Italy",
  "Manassas City County, Virginia, United States",
  "Murmansk, Murmansk, Russia",
  "301365, Aleksin, Kaluga, Russia",
  "060 01, Kezmarok, Presov, Slovakia",
  "977 55, Luleå, Norrbotten County, Sweden",
  "14728, Dewittville, New York, United States",
  "Clinton, Connecticut, United States",
  "052439, Bucharest, Bucharest, Romania",
  "99091, Erfurt, Thuringia, Germany",
  "Refugio, México, Mexico",
  "Grafton Township, Illinois, United States",
  "1503, Krujë, Durres, Albania",
  "Tatarskiye Gorenki, Ul’yanovsk, Russia",
  "Greer Meadows, Nashville, Tennessee, United States",
  "Weberen, Appenzell Inner-Rhoden, Switzerland",
  "25-031, Kielce, Świętokrzyskie, Poland",
  "OX39 4SJ, Kingston Blount, England, United Kingdom",
  "77001, Houston, Texas, United States",
  "907 29, Umeå, Västerbotten County, Sweden",
  "61091, Kharkiv, Kharkiv, Ukraine",
  "Hayes End, Uxbridge, England, United Kingdom",
  "20500, Denizli, Denizli, Türkiye",
  "Muromtsevo, Omsk, Russia",
  "450045, Zalău, Sălaj, Romania",
  "Venice, Louisiana, United States",
  "38030, Kayseri, Kayseri, Türkiye",
  "93309, Bakersfield, California, United States",
  "33526, Dade City, Florida, United States",
  "Pukë, Shkodër, Albania",
  "Fairfield, Alabama, United States",
  "Coronado Haskell, Rockford, Illinois, United States",
  "35490, Vance, Alabama, United States",
  "Oliveira de Azeméis, Aveiro, Portugal",
  "Greensville County, Virginia, United States",
  "310299, Arad, Arad, Romania",
  "B61C8X1, Sarqan, Jetisu Region, Kazakhstan",
  "6055, Stara Zagora, Stara Zagora, Bulgaria",
  "90100, Palermo, Sicily, Italy",
  "Uman Raion, Cherkasy, Ukraine",
  "31022, Kohtla-Järve, Ida-Virumaa, Estonia",
  "Gage, Oklahoma, United States",
  "52530, Agency, Iowa, United States",
  "Nevelsky District, Pskov, Russia",
  "F63C9T4, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "Sahinbey, Gaziantep, Türkiye",
  "Haverhill, England, United Kingdom",
  "Ploiesti Metropolitan Area",
  "601 31, Katerini, Central Macedonia, Greece",
  "06128, Perugia, Umbria, Italy",
  "03015, Alicante, Valencian Community, Spain",
  "429110, Shumerlya, Chuvashia, Russia",
  "Marlboro County, South Carolina, United States",
  "225410, Baranavichy, Brest, Belarus",
  "Camp Swift, Texas, United States",
  "CV6 1AB, Coventry, England, United Kingdom",
  "Greater Fortaleza",
  "2305, Hamar, Innlandet, Norway",
  "Ebersdorf bei Coburg, Bavaria, Germany",
  "90407, Haapsalu, Laane, Estonia",
  "IP1 1AA, Ipswich, England, United Kingdom",
  "6200-502, Covilhã, Castelo Branco, Portugal",
  "Little Rock, Arkansas, United States",
  "Pipestone, Minnesota, United States",
  "56218, Boyd, Minnesota, United States",
  "Thornton-In-Craven, England, United Kingdom",
  "21005, Huelva, Andalusia, Spain",
  "São Tomé and Príncipe",
  "Waller, Washington, United States",
  "Limerick, Pennsylvania, United States",
  "50858, Cologne, North Rhine-Westphalia, Germany",
  "Bodó, Rio Grande do Norte, Brazil",
  "FY3 7AA, Blackpool, England, United Kingdom",
  "Burkeville, Virginia, United States",
  "84766, Sevier, Utah, United States",
  "Vilas, Fecha, Galicia, Spain",
  "23093, Louisa, Virginia, United States",
  "78613, Cedar Park, Texas, United States",
  "Vert-Toulon, Grand Est, France",
  "82-305, Elbląg, Warmińsko-mazurskie, Poland",
  "23250, Povljana, Zadar, Croatia",
  "40123, Bologna, Emilia-Romagna, Italy",
  "Kaolinovo Municipality, Shumen, Bulgaria",
  "Bodo, Rivers State, Nigeria",
  "Mirny, Bryansk, Russia",
  "9419, Harstad, Troms og Finnmark, Norway",
  "Howell, New Jersey, United States",
  "Judith Gap, Montana, United States",
  "39108, Magdeburg, Saxony-Anhalt, Germany",
  "427430, Votkinsk, Udmurtia, Russia",
  "Jones County, Iowa, United States",
  "TA3 6HA, Stoke St Gregory, England, United Kingdom",
  "07320, Porvoo, Uusimaa, Finland",
  "Alijó, Vila Real, Portugal",
  "Ust'-Buzulukskoye, Volgograd, Russia",
  "Langlade, Marnac, Nouvelle-Aquitaine, France",
  "Wiener Neustadt-Land, Lower Austria, Austria",
  "5250, Svishtov, Veliko Turnovo, Bulgaria",
  "49024, Portage, Michigan, United States",
  "25-020, Kielce, Świętokrzyskie, Poland",
  "810220, Brăila, Brăila, Romania",
  "30123, Venice, Veneto, Italy",
  "309274, Shebekino, Belgorod, Russia",
  "St. Lawrence County, New York, United States",
  "5953, Nikopol, Pleven, Bulgaria",
  "Hardeman County, Texas, United States",
  "Gloucester, Virginia, United States",
  "71417, Colfax, Louisiana, United States",
  "Gibson City, Illinois, United States",
  "Calhoun County, Iowa, United States",
  "4960, Butterworth, Eastern Cape, South Africa",
  "Dobrichka Municipality, Dobrich, Bulgaria",
  "Campbell County, Tennessee, United States",
  "Tekirdağ, Türkiye",
  "Namur, Walloon Region, Belgium",
  "641200, Yurgamysh, Kurgan, Russia",
  "Howard Lake, Minnesota, United States",
  "Carlton, New South Wales, Australia",
  "San Donà di Piave, Veneto, Italy",
  "422980, Chistopol’, Tatarstan, Russia",
  "Chase, Wisconsin, United States",
  "McCormick, South Carolina, United States",
  "X01H3C7, Shymkent, Shymkent, Kazakhstan",
  "446110, Chapayevsk, Samara, Russia",
  "Clarendon Hills, Illinois, United States",
  "21131, Novi Sad, Vojvodina, Serbia",
  "Asipovichy, Mahilyow, Belarus",
  "Winchester, Oregon, United States",
  "Polk County, Georgia, United States",
  "Woodway, Texas, United States",
  "Karsog, Himachal Pradesh, India",
  "Dodge County, Georgia, United States",
  "Macon County, North Carolina, United States",
  "Stockholm County, Sweden",
  "Niğde, Niğde, Türkiye",
  "14055, Costigliole d’Asti, Piedmont, Italy",
  "663321, Noril'sk, Taymyr, Russia",
  "Tarabganj, Uttar Pradesh, India",
  "Cadereyta Jiménez, Nuevo León, Mexico",
  "357925, Zelenokumsk, Stavropol, Russia",
  "Lucas Heights, Auckland, Auckland, New Zealand",
  "Greenline - Spartanburg, Greenville, South Carolina, United States",
  "ZE1 0RA, Lerwick, Scotland, United Kingdom",
  "Tipton, Indiana, United States",
  "Kotovskoye, Novgorod, Russia",
  "Oliver, Pennsylvania, United States",
  "80-858, Gdańsk, Pomorskie, Poland",
  "Laurens, Boos, Nouvelle-Aquitaine, France",
  "620026, Yekaterinburg, Sverdlovsk, Russia",
  "Brussels, Ontario, Canada",
  "01250, Adana, Adana, Türkiye",
  "Radford, Coventry, England, United Kingdom",
  "SR3 1AA, Sunderland, England, United Kingdom",
  "21021, Vinnytsya, Vinnytsya, Ukraine",
  "400120, Volgograd, Volgograd, Russia",
  "9007, Tromsø, Troms og Finnmark, Norway",
  "Ozark, Missouri, United States",
  "Clarkston, Michigan, United States",
  "Paynes Creek, California, United States",
  "24536, Coleman Falls, Virginia, United States",
  "Caldwell, North Carolina, United States",
  "Rio das Ostras, Rio de Janeiro, Brazil",
  "Lohne, Lower Saxony, Germany",
  "Bertière, Ossages, Nouvelle-Aquitaine, France",
  "Foster, Missouri, United States",
  "Ust-Ishimsky District, Omsk, Russia",
  "Ust-Bolsheretsky District, Kamchatka, Russia",
  "F42H0C8, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "52006, Melilla, Melilla, Spain",
  "652509, Leninsk-Kuznetskiy, Kemerovo, Russia",
  "8918 AA, Leeuwarden, Friesland, Netherlands",
  "Slobozia, Moldova",
  "49747, Hubbard Lake, Michigan, United States",
  "Whatcombe, Wantage, England, United Kingdom",
  "28326, Cameron, North Carolina, United States",
  "Tripoli District, North Governorate, Lebanon",
  "353925, Novorossiysk, Krasnodar, Russia",
  "152644, Otradnyy, Yaroslavl’, Russia",
  "660000, Krasnoyarsk, Krasnoyarsk Krai, Russia",
  "Spotsylvania Court House Historic District, Spotsylvania Courthouse, Virginia, United States",
  "Oliveros, Santa Fe, Argentina",
  "05257, North Bennington, Vermont, United States",
  "Tulunsky District, Irkutsk, Russia",
  "De-Kastri, Khabarovsk, Russia",
  "Melilla, Spain",
  "Genoa, Liguria, Italy",
  "Cherry Hills, Champaign, Illinois, United States",
  "Petropavl, North Kazakhstan Region, Kazakhstan",
  "29333, Spartanburg, South Carolina, United States",
  "169840, Inta, Komi, Russia",
  "6019, Innsbruck, Tyrol, Austria",
  "Huron Township, Ohio, United States",
  "Valladolid, Western Visayas, Philippines",
  "Community of Madrid, Spain",
  "500082, Hyderabad, Telangana, India",
  "79770, Pecos, Texas, United States",
  "Yasnyy, Orenburg, Russia",
  "Oberägeri, Zug, Switzerland",
  "636451, Kolpashevo, Tomsk, Russia",
  "6152, Waterford, Western Australia, Australia",
  "Aurora, Missouri, United States",
  "54051, Mykolaiv, Mykolayiv, Ukraine",
  "City Municipality of Maribor, Slovenia",
  "Stephens, Arkansas, United States",
  "22602, Winchester, Virginia, United States",
  "171984, Bezhetsk, Tver’, Russia",
  "Lugano Metropolitan Area",
  "03100, Afyonkarahisar, Afyon, Türkiye",
  "L36 6DU, Liverpool, England, United Kingdom",
  "77057, Houston, Texas, United States",
  "Windham, New York, United States",
  "Paget Parish, Bermuda",
  "Pytalovo, Pskov, Russia",
  "Karamanlı, Burdur, Türkiye",
  "Grays Point, New South Wales, Australia",
  "Coalgate, Oklahoma, United States",
  "28634, Harmony, North Carolina, United States",
  "309276, Shebekino, Belgorod, Russia",
  "665046, Polovino-Cheremkhovo, Irkutsk, Russia",
  "Trebišov, Kosice, Slovakia",
  "Somerset West, Western Cape, South Africa",
  "694023, Korsakov, Sakhalin, Russia",
  "Luhanska miska hromada, Luhansk, Ukraine",
  "462413, Orsk, Orenburg, Russia",
  "Bingöl, Türkiye",
  "39170, Terry, Mississippi, United States",
  "Kanel, Matam Region, Senegal",
  "Arzularkabaköy, Gümüşhane, Türkiye",
  "Gilmer, Roanoke, Virginia, United States",
  "Union City, New Jersey, United States",
  "100002, Ploieşti, Prahova, Romania",
  "2265, Martinsville, New South Wales, Australia",
  "Sinnamary, Cayenne, French Guiana",
  "8762, Schwanden, Glarus, Switzerland",
  "Karşıyaka, İzmir, Türkiye",
  "231291, Lida, Hrodna, Belarus",
  "10135, Turin, Piedmont, Italy",
  "PO1 9JP, Portsmouth, England, United Kingdom",
  "247777, Mazyr, Homel, Belarus",
  "Kudymkarskiy, Perm, Russia",
  "San Jose, California, United States",
  "Lenskiy, Sakha, Russia",
  "Newcastle, Maine, United States",
  "607221, Arzamas, Nizhniy Novgorod, Russia",
  "Lymanska selyshchna hromada, Odessa, Ukraine",
  "Alderney, Alderney, Guernsey",
  "Cayuga, Indiana, United States",
  "Vetlanda, Jönköping County, Sweden",
  "62241, Ellis Grove, Illinois, United States",
  "68508, Lincoln, Nebraska, United States",
  "79263, Prijedor, Serb Republic, Bosnia and Herzegovina",
  "La Traille, Geneva, Switzerland",
  "624930, Karpinsk, Sverdlovsk, Russia",
  "Fort Defiance, Virginia, United States",
  "Attala, Dharmabad, Maharashtra, India",
  "Dolný Kubín, Zilina, Slovakia",
  "E5M 1A1, Gagetown, New Brunswick, Canada",
  "301348, Aleksin, Kaluga, Russia",
  "Bistriţa Bârgăului, Bistriţa-Năsăud, Romania",
  "693010, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Rome, Latium, Italy",
  "85260, Scottsdale, Arizona, United States",
  "Okha, Gujarat, India",
  "Cortland, New York, United States",
  "76524, Eddy, Texas, United States",
  "15705, Santiago de Compostela, Galicia, Spain",
  "347382, Volgodonsk, Rostov, Russia",
  "Houston County, Texas, United States",
  "780, Höfn, Southern Region, Iceland",
  "24106, Kiel, Schleswig-Holstein, Germany",
  "Asbest, Sverdlovsk, Russia",
  "623965, Tavda, Sverdlovsk, Russia",
  "Philadelphia County, Pennsylvania, United States",
  "Igrim, Khanty-Mansi, Russia",
  "5612 AA, Eindhoven, North Brabant, Netherlands",
  "60143, Itasca, Illinois, United States",
  "Wheatland, California, United States",
  "F42H2G7, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "750 02, Uppsala, Uppsala County, Sweden",
  "440000, Penza, Penza, Russia",
  "981 33, Kiruna, Norrbotten County, Sweden",
  "15237, Pittsburgh, Pennsylvania, United States",
  "Barros Blancos, Canelones, Uruguay",
  "02700, Adıyaman, Adıyaman, Türkiye",
  "821 09, Bratislava, Bratislava, Slovakia",
  "01938, Ipswich, Massachusetts, United States",
  "Canton of Bastia-3, Corsica, France",
  "West Walworth, South Dakota, United States",
  "Hardinxveld-Giessendam, South Holland, Netherlands",
  "29307, Spartanburg, South Carolina, United States",
  "McClain County, Oklahoma, United States",
  "Chez Burnet, Sciez, Auvergne-Rhône-Alpes, France",
  "Wallan, Victoria, Australia",
  "Rustavi, Georgia",
  "Yates Mill, Swift Creek, North Carolina, United States",
  "04400, Alhama de Almería, Andalusia, Spain",
  "450098, Zalău, Sălaj, Romania",
  "Kingston upon Hull, England Metropolitan Area",
  "653035, Prokopyevsk, Kemerovo, Russia",
  "Kirovsky, Altai Krai, Russia",
  "367010, Makhachkala, Dagestan, Russia",
  "65230, Vaasa, Ostrobothnia, Finland",
  "Kauno, Lithuania",
  "Jász-Nagykun-Szolnok, Hungary",
  "Titusville, Pennsylvania, United States",
  "427792, Mozhga, Udmurtia, Russia",
  "Albany, Oregon, United States",
  "Verona, Kentucky, United States",
  "Le Bout aux Nobles, Fontaine-les-Bassets, Normandy, France",
  "Pond Eddy, New York, United States",
  "4846, Arendal, Agder, Norway",
  "Chautauqua Park Historic District, United States",
  "Greenbrier, Tennessee, United States",
  "96285-000, Mostardas, Rio Grande do Sul, Brazil",
  "658224, Rubtsovsk, Altai Krai, Russia",
  "367008, Makhachkala, Dagestan, Russia",
  "Kemperhof, Vettweiß, North Rhine-Westphalia, Germany",
  "181, Rostock, Mecklenburg-West Pomerania, Germany",
  "57007, Brookings, South Dakota, United States",
  "Bexar, Alabama, United States",
  "Butler County, Ohio, United States",
  "Kiowa County, Colorado, United States",
  "Berkshire, Ohio, United States",
  "Webb, New York, United States",
  "Portage la Prairie, Manitoba, Canada",
  "Lexington, Tennessee, United States",
  "230025, Hrodna, Hrodna, Belarus",
  "37848, Powder Springs, Tennessee, United States",
  "Georgiyevsk, Stavropol, Russia",
  "72213, Zenica, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Coosada, Alabama, United States",
  "CV4 7AL, Coventry, England, United Kingdom",
  "45120, Kouvola, Kymenlaakso, Finland",
  "Mercedes Umaña, Usulután, El Salvador",
  "Piotrków Trybunalski, Łódzkie, Poland",
  "8519, Narvik, Nordland, Norway",
  "Galveston, Texas, United States",
  "79785, Pecos, Texas, United States",
  "47546, Jasper, Indiana, United States",
  "Jefferson County, Pennsylvania, United States",
  "Ballysadare, County Sligo, Ireland",
  "58577, Washburn, North Dakota, United States",
  "Livingston, Alabama, United States",
  "Leninogorsk, Tatarstan, Russia",
  "Kraków Old Town, Małopolskie, Poland",
  "Grant County, Washington, United States",
  "Saint-Georges-Haute-Ville, Auvergne-Rhône-Alpes, France",
  "Devakottai, Tamil Nadu, India",
  "225406, Baranavichy, Brest, Belarus",
  "Ishimbayevskiy, Bashkortostan, Russia",
  "Dover, Tennessee, United States",
  "Randolph, Massachusetts, United States",
  "Bonth, Odisha, India",
  "Obshtina Lyubimets, Khaskovo, Bulgaria",
  "Sandoval, Baja California, Mexico",
  "Rice County, Kansas, United States",
  "Troitsk, Sakha, Russia",
  "Orshansky District, Mari El, Russia",
  "Daviette, Saint-Martin-des-Bois, Centre-Val de Loire, France",
  "K1N 0A1, Ottawa, Ontario, Canada",
  "Hadiya, Uttar Pradesh, India",
  "9500-342, Ponta Delgada, Portugal",
  "Burlington, Kentucky, United States",
  "37209, Nashville, Tennessee, United States",
  "Pike County, Mississippi, United States",
  "665824, Angarsk, Irkutsk, Russia",
  "Ordynskiy, Ust-Ordyn-Buryat, Russia",
  "Millersburg, Pennsylvania, United States",
  "Greeleyville, South Carolina, United States",
  "140090, Dzerzhinskiy, Moscow, Russia",
  "Greater Lecce Metropolitan Area",
  "Laramie, Texas, United States",
  "16167, Genoa, Liguria, Italy",
  "75108, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "7970, Rørvik, Trøndelag, Norway",
  "Tavira, Faro, Portugal",
  "040 01, Košice, Kosice, Slovakia",
  "Mutki, Bitlis, Türkiye",
  "456318, Miass, Chelyabinsk, Russia",
  "Pont Person, Guiscriff, Brittany, France",
  "Kimball, Michigan, United States",
  "352915, Armavir, Krasnodar, Russia",
  "626022, Nizhnyaya Tavda, Tyumen’, Russia",
  "80-880, Gdańsk, Pomorskie, Poland",
  "618546, Solikamsk, Perm, Russia",
  "4411, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "Redcar, England, United Kingdom",
  "Pinsk, Brest, Belarus",
  "York, New Brunswick, Canada",
  "Greater Saint-Etienne Metropolitan Area",
  "Lehliu Gară, Călăraşi, Romania",
  "49006, Dnipro, Dnipropetrovsk, Ukraine",
  "Posedarje, Zadar, Croatia",
  "Agrigento, Sicily, Italy",
  "Caldwell, Idaho, United States",
  "San Antonio, Texas Metropolitan Area",
  "Foley Park, Kidderminster, England, United Kingdom",
  "Dodge Center, Minnesota, United States",
  "Anson, Minerbe, Veneto, Italy",
  "413866, Balakovo, Saratov, Russia",
  "Le Bois Lorain, Villemandeur, Centre-Val de Loire, France",
  "Carter County, Missouri, United States",
  "68219, Mannheim, Baden-Württemberg, Germany",
  "Petroleum, Indiana, United States",
  "Clarendon, Quebec, Canada",
  "2013, Shijak, Durres, Albania",
  "Monroe County, Florida, United States",
  "41558, Ransom, Kentucky, United States",
  "Industrial Valley-Cuyahoga Heights, Cuyahoga Heights, Ohio, United States",
  "LE65 1RT, Staunton Harold, England, United Kingdom",
  "Zielona Gora Metropolitan Area",
  "71-004, Szczecin, Zachodniopomorskie, Poland",
  "Risaralda, Colombia",
  "694626, Kholmsk, Sakhalin, Russia",
  "Pervomayckiy, Vitsyebsk, Belarus",
  "Haywood County, North Carolina, United States",
  "Montijo, Setúbal, Portugal",
  "Gülşehir, Nevşehir, Türkiye",
  "Bielski, Podlaskie, Poland",
  "45143, Essen, North Rhine-Westphalia, Germany",
  "910146, Călăraşi, Călăraşi, Romania",
  "Port Rowan, Ontario, Canada",
  "Webster, Massachusetts, United States",
  "Bondowoso, East Java, Indonesia",
  "636464, Kolpashevo, Tomsk, Russia",
  "Ruse, Bulgaria",
  "Parker, South Carolina, United States",
  "5100, Jászberény, Jász-Nagykun-Szolnok, Hungary",
  "Calumet, Michigan, United States",
  "443099, Samara, Samara, Russia",
  "18004, Granada, Andalusia, Spain",
  "46023, Valencia, Valencian Community, Spain",
  "Graham, West Virginia, United States",
  "Santander, Cantabria, Spain",
  "6583, Bellinzona, Ticino, Switzerland",
  "Fremont, Michigan, United States",
  "1046, Okhahlamba, KwaZulu-Natal, South Africa",
  "Tatarskiy, Novosibirsk, Russia",
  "Coos, Rion-des-Landes, Nouvelle-Aquitaine, France",
  "Grove Park, Umatilla, Florida, United States",
  "24066, Buchanan, Virginia, United States",
  "693023, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "Chittenden County, Vermont, United States",
  "Obshtina Razgrad, Razgrad, Bulgaria",
  "53143, Kenosha, Wisconsin, United States",
  "Zdolbunivska miska hromada, Rivne, Ukraine",
  "Engelskirchen, North Rhine-Westphalia, Germany",
  "58500, Savonlinna, South Savo, Finland",
  "Florence, Kentucky, United States",
  "Capanne di Cosola, Cabella Ligure, Piedmont, Italy",
  "79203, Prijedor, Serb Republic, Bosnia and Herzegovina",
  "45219, Essen, North Rhine-Westphalia, Germany",
  "27574, Bremerhaven, Bremen, Germany",
  "Greater New Orleans Region",
  "2986 TL, Ridderkerk, South Holland, Netherlands",
  "Fargo, North Dakota, United States",
  "89074, Henderson, Nevada, United States",
  "Russell Springs, Kentucky, United States",
  "Oliver Springs, Arkansas, United States",
  "413840, Balakovo, Saratov, Russia",
  "Hot Springs, Virginia, United States",
  "Columbus, Ohio Metropolitan Area",
  "Palana, Koryak, Russia",
  "Los Ríos, Ecuador",
  "Angarsk, Irkutsk, Russia",
  "Hardingham, England, United Kingdom",
  "Wharton, Cumberland, Subdivision A, Nova Scotia, Canada",
  "453642, Staryy Sibay, Bashkortostan, Russia",
  "Kayseri, Türkiye",
  "Enez, Edirne, Türkiye",
  "100023, Ploieşti, Prahova, Romania",
  "Tunica, Mississippi, United States",
  "142210, Serpukhov, Moscow, Russia",
  "09600, Aydın, Aydın, Türkiye",
  "CF62 3AA, Barry, Wales, United Kingdom",
  "02007, Vilnius, Vilniaus, Lithuania",
  "74112, Tulsa, Oklahoma, United States",
  "628684, Megion, Khanty-Mansi, Russia",
  "Alexander, Maine, United States",
  "20164, Sterling, Virginia, United States",
  "Kenedy Sur, Wanchaq, Cusco, Peru",
  "77084, Houston, Texas, United States",
  "Mangalia, Constanţa, Romania",
  "PO2 0AA, Portsmouth, England, United Kingdom",
  "Parma, Emilia-Romagna, Italy",
  "32003, Ourense, Galicia, Spain",
  "121 22, Stockholm, Stockholm County, Sweden",
  "E3G 9E2, Killarney Road, New Brunswick, Canada",
  "Ross, North Dakota, United States",
  "50638, Grundy Center, Iowa, United States",
  "Baie-Mahault, Basse-Terre, Guadeloupe",
  "676862, Belogorsk, Amur, Russia",
  "Appenzell, Appenzell Inner-Rhoden, Switzerland",
  "68701, Norfolk, Nebraska, United States",
  "442539, Kuznetsk, Penza, Russia",
  "Cannon County, Tennessee, United States",
  "Kaufmann & Broad, Davis, California, United States",
  "Zaragoza, Chimaltenango, Guatemala",
  "143408, Krasnogorsk, Moscow, Russia",
  "1012, Budapest, Budapest, Hungary",
  "32400, Dunaivtsi, Khmelnytsky, Ukraine",
  "677015, Yakutsk, Sakha, Russia",
  "45303, Ansonia, Ohio, United States",
  "Terry Lee Park, Enochville, North Carolina, United States",
  "North Windham, Connecticut, United States",
  "302040, Orel, Orel, Russia",
  "Monmouth, Maine, United States",
  "456300, Miass, Chelyabinsk, Russia",
  "Beaver Creek, Oregon, United States",
  "41001, Seville, Andalusia, Spain",
  "09112, Chemnitz, Saxony, Germany",
  "The Hague, South Holland, Netherlands",
  "90-131, Łódź, Łódzkie, Poland",
  "Beltrami County, Minnesota, United States",
  "684415, Ust’-Kamchatsk, Kamchatka, Russia",
  "C01E8P4, Kokshetau, Akmola Region, Kazakhstan",
  "3124, Tønsberg, Vestfold og Telemark, Norway",
  "Wick, Scotland, United Kingdom",
  "Ripley, Ohio, United States",
  "Parma, Missouri, United States",
  "Petropavlovskoye, Altai Krai, Russia",
  "452773, Tatar-Ulkanovo, Bashkortostan, Russia",
  "65081, Tipton, Missouri, United States",
  "IP13 0JF, Pettistree, England, United Kingdom",
  "55800, Samsun, Samsun, Türkiye",
  "Lafayette, New Jersey, United States",
  "Danei District, Tainan City, Taiwan",
  "90405, Haapsalu, Laane, Estonia",
  "Minski Rayon, Minsk Province, Belarus",
  "BT26 6PJ, Hillsborough, Northern Ireland, United Kingdom",
  "Vitória de Santo Antão, Pernambuco, Brazil",
  "Meta, Colombia",
  "Ellis Grove, Illinois, United States",
  "Tolanaro, Anosy, Madagascar",
  "J1T 3Z5, Asbestos, Quebec, Canada",
  "385001, Maykop, Adygea, Russia",
  "630 73, Polygyros, Central Macedonia, Greece",
  "Reading, Pennsylvania, United States",
  "Clinton, Massachusetts, United States",
  "Hall County, Georgia, United States",
  "Viljandi vald, Viljandimaa, Estonia",
  "Pennington, New Jersey, United States",
  "981 91, Kiruna, Norrbotten County, Sweden",
  "Valladolid, Zamora Chinchipe, Ecuador",
  "21030, Cockeysville, Maryland, United States",
  "Jonesboro, Arkansas, United States",
  "B61C3K8, Sarqan, Jetisu Region, Kazakhstan",
  "423873, Komsomolets, Tatarstan, Russia",
  "5401, Çorovodë, Berat, Albania",
  "06070, Perugia, Umbria, Italy",
  "West Godavari, Andhra Pradesh, India",
  "León, Mexico Metropolitan Area",
  "Pikedale, Queensland, Australia",
  "Young, New South Wales, Australia",
  "Arthurs Creek, Victoria, Australia",
  "E28D0K4, Makhambet, Atyrau Region, Kazakhstan",
  "47021, Versailles, Indiana, United States",
  "Nelson Mandela Bay, Eastern Cape, South Africa",
  "Cannons Creek, Victoria, Australia",
  "Hillsborough 132, Saskatchewan, Canada",
  "Sauk Village, Illinois, United States",
  "Greater Brisbane Area",
  "Mekong River Delta Metropolitan Region",
  "Lovelady, Texas, United States",
  "Buchanan, Michigan, United States",
  "Uyarsky District, Krasnoyarsk Krai, Russia",
  "80127, Naples, Campania, Italy",
  "Morris County, New Jersey, United States",
  "Monaco, Nelson, Nelson, New Zealand",
  "2525, Le Landeron, Neuchâtel, Switzerland",
  "4433, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "5606, Békéscsaba, Békés, Hungary",
  "43110, Canal Winchester, Ohio, United States",
  "636200, Kolpashevo, Tomsk, Russia",
  "35700, Rennes, Brittany, France",
  "Chez le Cossier, Fallon, Bourgogne-Franche-Comté, France",
  "Ipswich, South Dakota, United States",
  "53024, Grafton, Wisconsin, United States",
  "Lexington City County, Virginia, United States",
  "Gdański, Pomorskie, Poland",
  "Balakhna, Nizhniy Novgorod, Russia",
  "45141, Essen, North Rhine-Westphalia, Germany",
  "Norwood Young America, Minnesota, United States",
  "Schoharie, New York, United States",
  "03200, Vichy, Auvergne-Rhône-Alpes, France",
  "169510, Sosnogorsk, Komi, Russia",
  "82520, Lander, Wyoming, United States",
  "Qabala District, Sheki-Zaqatala, Azerbaijan",
  "546 21, Thessaloniki, Central Macedonia, Greece",
  "McHenry Highlands, Modesto, California, United States",
  "95070, Saratoga, California, United States",
  "Minerbio, Emilia-Romagna, Italy",
  "Wrightsville, Pennsylvania, United States",
  "Poquoson City County, Virginia, United States",
  "212 18, Malmo, Skåne County, Sweden",
  "Turatea, South Sulawesi, Indonesia",
  "26228, Kanawha Head, West Virginia, United States",
  "Shumen Municipality, Shumen, Bulgaria",
  "Kineshma, Ivanovo, Russia",
  "Greater Sebring-Avon Park Area",
  "Cumberland, Virginia, United States",
  "5940, Nikopol, Pleven, Bulgaria",
  "Malheur Junction, Oregon, United States",
  "Bratskiy, Krasnodar, Russia",
  "Beyneu, Mangystau Region, Kazakhstan",
  "Bradley, England, United Kingdom",
  "752 37, Uppsala, Uppsala County, Sweden",
  "97392, Turner, Oregon, United States",
  "65-101, Zielona Góra, Lubuskie, Poland",
  "Exeter, Rhode Island, United States",
  "Bastrop, Louisiana, United States",
  "Jones County, Mississippi, United States",
  "Ripley, Oklahoma, United States",
  "172502, Nelidovo, Tver’, Russia",
  "Jelezïn district, Pavlodar Region, Kazakhstan",
  "72050, Batman, Batman, Türkiye",
  "Transnistria autonomous territorial unit, Moldova",
  "6105, Perth Airport, Western Australia, Australia",
  "West Haven, Champaign, Illinois, United States",
  "Le Roseau, Génis, Nouvelle-Aquitaine, France",
  "422982, Chistopol’, Tatarstan, Russia",
  "Potterne, England, United Kingdom",
  "P0A 0B1, Kearney, Ontario, Canada",
  "42742, Gradyville, Kentucky, United States",
  "7718, Steinkjer, Trøndelag, Norway",
  "Santa Cruz de Tenerife, Canary Islands, Spain",
  "960 07, Zvolen, Banska Bystrica, Slovakia",
  "26007, Logroño, Autonomía de La Rioja, Spain",
  "Washburn, Tennessee, United States",
  "Carbondale, Pennsylvania, United States",
  "9017, Tromsø, Troms og Finnmark, Norway",
  "Ottershaw, England, United Kingdom",
  "67800, Zonguldak, Zonguldak, Türkiye",
  "Collins, Mississippi, United States",
  "Shumen, Shumen, Bulgaria",
  "36360, Ozark, Alabama, United States",
  "The Gambia",
  "Potsdam, New York, United States",
  "364024, Groznyy, Chechnya, Russia",
  "Young Harris, Georgia, United States",
  "Galliate, Piedmont, Italy",
  "22655, Stephens City, Virginia, United States",
  "53810, Lappeenranta, South Karelia, Finland",
  "Iaşi, Romania",
  "M28G4K2, Temirtau, Karaganda Region, Kazakhstan",
  "Catawba Pointe, Rock Hill, South Carolina, United States",
  "Redondo, Évora, Portugal",
  "58450, Savonlinna, South Savo, Finland",
  "Upton, Northampton, England, United Kingdom",
  "Mille Lacs County, Minnesota, United States",
  "78373, Port Aransas, Texas, United States",
  "100285, Ploieşti, Prahova, Romania",
  "617472, Kungur, Perm, Russia",
  "92840, Garden Grove, California, United States",
  "C22E5K2, Atbasar, Akmola Region, Kazakhstan",
  "La Joséphine, Talmont-Saint-Hilaire, Pays de la Loire, France",
  "Vibo Valentia, Calabria, Italy",
  "54-129, Wrocław, Dolnośląskie, Poland",
  "Steuben County, New York, United States",
  "40384, Versailles, Kentucky, United States",
  "06122, Perugia, Umbria, Italy",
  "676015, Skovorodino, Amur, Russia",
  "5004, Aarau, Aargau, Switzerland",
  "Bataysk, Rostov, Russia",
  "Nye, Montana, United States",
  "427631, Glazov, Udmurtia, Russia",
  "Strafford Glen, Triangle, North Carolina, United States",
  "Moggio Udinese, Friuli-Venezia Giulia, Italy",
  "78140, Siauliai, Siauliu, Lithuania",
  "Greater Viseu Area",
  "Mariinskiy, Kemerovo, Russia",
  "VLT 1111, Valletta, Valletta, Malta",
  "Rains, Tharali, Uttarakhand, India",
  "44100, Nantes, Pays de la Loire, France",
  "Greeley, Pennsylvania, United States",
  "6199, Gurkovo, Stara Zagora, Bulgaria",
  "360009, Nalchik, Kabardino-Balkaria, Russia",
  "9301, Fier, Fier, Albania",
  "Mamurras, Lezhë, Albania",
  "Kamenka, Mahilyow, Belarus",
  "6006, Kecskemét, Bács-Kiskun, Hungary",
  "Vernon County, Missouri, United States",
  "Howard County, Nebraska, United States",
  "59500, Tekirdağ, Tekirdağ, Türkiye",
  "Stadt Würzburg, Bavaria, Germany",
  "McKean, Pennsylvania, United States",
  "Kapanewon Samigaluh, Yogyakarta, Indonesia",
  "399770, Yelets, Lipetsk, Russia",
  "Nikolayevskiy, Bashkortostan, Russia",
  "Danville, Georgia, United States",
  "54774, Chippewa Falls, Wisconsin, United States",
  "53860, Zelënodol'sk, Dnipropetrovsk, Ukraine",
  "Greene, New York, United States",
  "La Subletterie, La Meignanne, Pays de la Loire, France",
  "Major Izidoro, Alagoas, Brazil",
  "Oskarshamns kommun, Kalmar County, Sweden",
  "Tătărești, Cahul District, Moldova",
  "Asheboro, North Carolina, United States",
  "8047, Hart bei Graz, Styria, Austria",
  "M28H7D9, Temirtau, Karaganda Region, Kazakhstan",
  "Cedar Lake, Indiana, United States",
  "Hardin, Illinois, United States",
  "33703, St Petersburg, Florida, United States",
  "65330, Gilliam, Missouri, United States",
  "17363, Stewartstown, Pennsylvania, United States",
  "Bajaur District, Khyber Pakhtunkhwa, Pakistan",
  "052460, Tarazá, Antioquia, Colombia",
  "Plovdiv, Plovdiv, Bulgaria",
  "SR5 1AB, Sunderland, England, United Kingdom",
  "Stevens, Pennsylvania, United States",
  "10456, Bronx, New York, United States",
  "Kidder, Missouri, United States",
  "Albany, California, United States",
  "694620, Kholmsk, Sakhalin, Russia",
  "352696, Apsheronsk, Krasnodar, Russia",
  "821 01, Bratislava, Bratislava, Slovakia",
  "7796, Steinkjer, Trøndelag, Norway",
  "BT28 2SA, Lisburn, Northern Ireland, United Kingdom",
  "Crawford County, Arkansas, United States",
  "21701, Frederick, Maryland, United States",
  "7300, Kubrat, Razgrad, Bulgaria",
  "Sven’, Bryansk, Russia",
  "Springfield-Branson, Missouri Area",
  "Steele, North Carolina, United States",
  "357532, Pyatigorsk, Stavropol, Russia",
  "79521, Haskell, Texas, United States",
  "18067, Northampton, Pennsylvania, United States",
  "16132, Genoa, Liguria, Italy",
  "1018, Lausanne, Vaud, Switzerland",
  "456864, Kyshtym, Chelyabinsk, Russia",
  "68210, Kokkola, Central Ostrobothnia, Finland",
  "Cabarrus Acres, Midland, North Carolina, United States",
  "446015, Syzran, Samara, Russia",
  "624611, Alapayevsk, Sverdlovsk, Russia",
  "Buyant-Uhaa, Dornogovi Province, Mongolia",
  "4682, Harding, KwaZulu-Natal, South Africa",
  "Usakos, Erongo Region, Namibia",
  "11300, Bilecik, Bilecik, Türkiye",
  "El’dikan, Sakha, Russia",
  "Haskell, New Jersey, United States",
  "Lafayette, Indiana Metropolitan Area",
  "Fannin, Bellmead, Texas, United States",
  "Punjab, India",
  "Exeter, Nebraska, United States",
  "127456, Pârscov, Buzău, Romania",
  "Greater Stavanger Region",
  "Wallace County, Kansas, United States",
  "Webb City, Missouri, United States",
  "386132, Nazran, Ingushetia, Russia",
  "99352, Richland, Washington, United States",
  "Russell, Ontario, Canada",
  "L02C4G4, Oral, West Kazakhstan Region, Kazakhstan",
  "960 06, Zvolen, Banska Bystrica, Slovakia",
  "Humphreys Wharf, Cambridge-Narrows, New Brunswick, Canada",
  "Arnhem-Nijmegen Region",
  "H91 D8P9, Galway, County Galway, Ireland",
  "624762, Verkhnyaya Salda, Sverdlovsk, Russia",
  "Bradley, Arkansas, United States",
  "360032, Nalchik, Kabardino-Balkaria, Russia",
  "Summit County, Ohio, United States",
  "55080, Samsun, Samsun, Türkiye",
  "Ünye Bucağı, Ordu, Türkiye",
  "Washington, England, United Kingdom",
  "Belgrade, Montana, United States",
  "X38A8A0, Kentau, Turkistan Region, Kazakhstan",
  "7636, Pécs, Baranya, Hungary",
  "44, Ferrara, Emilia-Romagna, Italy",
  "Logan, Iowa, United States",
  "McLean, Virginia, United States",
  "29072, Lexington, South Carolina, United States",
  "Lawrence County, Indiana, United States",
  "Milwaukee County, Wisconsin, United States",
  "5000-705, Vila Real, Vila Real, Portugal",
  "18008, Cherkasy, Cherkasy, Ukraine",
  "Mora, Minnesota, United States",
  "Plovdiv, Bulgaria",
  "628405, Surgut, Khanty-Mansi, Russia",
  "00165, Rome, Latium, Italy",
  "Morrisonville, New York, United States",
  "Martök district, Aqtöbe region, Kazakhstan",
  "Redwood County, Minnesota, United States",
  "Boujdour Province, Laâyoune-Sakia El Hamra, Morocco",
  "Sutton, Massachusetts, United States",
  "15702, Santiago de Compostela, Galicia, Spain",
  "33004, Rivne, Rivne, Ukraine",
  "165304, Kotlas, Arkhangel’sk, Russia",
  "27701, Durham, North Carolina, United States",
  "33973, Lehigh Acres, Florida, United States",
  "Gagen, Bonrepos-sur-Aussonnelle, Occitanie, France",
  "Johnson, Vermont, United States",
  "30087, Stone Mountain, Georgia, United States",
  "Morgan County, Georgia, United States",
  "Fort Meade, Florida, United States",
  "Minnehaha Terrace, Florida, United States",
  "Harlan, Ohio, United States",
  "B55B4B5, Zharkent, Jetisu Region, Kazakhstan",
  "220025, Minsk, Minsk, Belarus",
  "General Toshevo, Dobrich, Bulgaria",
  "Barrancabermeja, Santander, Colombia",
  "Tarrant Launceston, England, United Kingdom",
  "23662, Poquoson, Virginia, United States",
  "Southampton, England, United Kingdom",
  "455051, Magnitogorsk, Chelyabinsk, Russia",
  "75050, Grand Prairie, Texas, United States",
  "LU1 5FT, Luton, England, United Kingdom",
  "Terrell 101, Saskatchewan, Canada",
  "St Giles, London, England, United Kingdom",
  "99801, Juneau, Alaska, United States",
  "Ketovsky District, Kurgan, Russia",
  "Surrey, British Columbia, Canada",
  "Shenandoah, Louisiana, United States",
  "Henderson County, Texas, United States",
  "McCurtain, Oklahoma, United States",
  "Alexandria, Louisiana, United States",
  "Hopkins County, Kentucky, United States",
  "19610, Reading, Pennsylvania, United States",
  "62684, Sherman, Illinois, United States",
  "Weston, Oamaru, Otago, New Zealand",
  "Amelia, Sasolburg, Free State, South Africa",
  "Les Bertières, Secondigny, Nouvelle-Aquitaine, France",
  "Suffolk County, New York, United States",
  "7607, Pécs, Baranya, Hungary",
  "Velabisht, Berat, Albania",
  "T0L 0A5, Turner Valley, Alberta, Canada",
  "Milledgeville, Georgia, United States",
  "TS8 9AA, Middlesbrough, England, United Kingdom",
  "44135, Dortmund, North Rhine-Westphalia, Germany",
  "Buffalo, Wyoming, United States",
  "664043, Irkutsk, Irkutsk, Russia",
  "Greater Southampton Area",
  "B3H 0A2, Halifax, Nova Scotia, Canada",
  "Tulungselapan, South Sumatra, Indonesia",
  "Penang, Malaysia",
  "S13H7A1, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Ravenna, Emilia-Romagna, Italy",
  "Prince William County, Virginia, United States",
  "The City Of Brighton And Hove, England, United Kingdom",
  "Botoşani, Romania",
  "Wilkes, Portland, Oregon, United States",
  "Stavanger/Sandnes, Rogaland, Norway",
  "Zenica-Doboj Canton, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "13003, Ciudad Real, Castile-La Mancha, Spain",
  "King-Keyano, Wood Buffalo, Alberta, Canada",
  "Norfolk County, Ontario, Canada",
  "Balatonfúzfó, Veszprém, Hungary",
  "Al'met'yevskoye, Tatarstan, Russia",
  "Oslob, Central Visayas, Philippines",
  "Les Clarionds, Méolans-Revel, Provence-Alpes-Côte d'Azur, France",
  "Arad, Arad, Romania",
  "Chapayevskiy, Sakha, Russia",
  "Kent, British Columbia, Canada",
  "Weber, Puerto Rico, Misiones, Argentina",
  "187402, Volkhov, Leningrad, Russia",
  "Atlantic City, New Jersey, United States",
  "97350, Iracoubo, Cayenne, French Guiana",
  "Akçaşehir, Karaman, Türkiye",
  "Schuyler County, New York, United States",
  "601915, Kovrov, Vladimir, Russia",
  "E3A 9E9, Killarney Road, New Brunswick, Canada",
  "665034, Polovino-Cheremkhovo, Irkutsk, Russia",
  "Lewis County, Missouri, United States",
  "Dublin 20, County Dublin, Ireland",
  "33772, Seminole, Florida, United States",
  "79330-020, Corumbá, Mato Grosso do Sul, Brazil",
  "37111, McMinnville, Tennessee, United States",
  "Llano, California, United States",
  "Swiftwater, Pennsylvania, United States",
  "Nyköping, Södermanland County, Sweden",
  "Seward Park, Seattle, Washington, United States",
  "Varve, Ventspils, Latvia",
  "Pamplona, Bicol Region, Philippines",
  "Zărneşti, Braşov, Romania",
  "7406, Ersekë, Korçë, Albania",
  "Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "EH1 1AD, Edinburgh, Scotland, United Kingdom",
  "Chloraka, Paphos, Cyprus",
  "53042, Kiel, Wisconsin, United States",
  "791 30, Falun, Dalarna County, Sweden",
  "457020, Plast, Chelyabinsk, Russia",
  "Gilgit-Baltistan, Pakistan",
  "611 66, Nyköping, Södermanland County, Sweden",
  "Tipton, Iowa, United States",
  "Karadag Raion, Baku Ekonomic Zone, Azerbaijan",
  "Lagos, Faro, Portugal",
  "Bastia, Umbria, Italy",
  "33313, Fort Lauderdale, Florida, United States",
  "Sankt Johann Im Pongau, Salzburg, Austria",
  "Altdorf UR, Uri, Switzerland",
  "Miskolc Metropolitan Area",
  "Ottawa, Kansas, United States",
  "3030, Drammen, Viken, Norway",
  "24122, Bergamo, Lombardy, Italy",
  "353483, Gelendzhik, Krasnodar, Russia",
  "SS1 2BB, Southend-On-Sea, England, United Kingdom",
  "7600, Pécs, Baranya, Hungary",
  "50027, Barnes City, Iowa, United States",
  "Bakun, Cordillera Admin Region, Philippines",
  "90402, Haapsalu, Laane, Estonia",
  "10453, Bronx, New York, United States",
  "B55A7P4, Zharkent, Jetisu Region, Kazakhstan",
  "26000, Kamenka, Kirovohrad, Ukraine",
  "58010, Sivas, Sivas, Türkiye",
  "Urayfjan Sahuq, Al Qasim, Saudi Arabia",
  "Kaliningrad Metropolitan Area",
  "1039, Vorë, Tirana, Albania",
  "666679, Ust'-Ilimsk, Irkutsk, Russia",
  "2540, Grenchen, Solothurn, Switzerland",
  "Jackson County, Oregon, United States",
  "Dickson, Tennessee, United States",
  "36206, Vigo, Galicia, Spain",
  "33013, Oviedo, Principality of Asturias, Spain",
  "613040, Kirovo-Chepetsk, Kirov, Russia",
  "H34E6A8, Qulan, Jambyl Region, Kazakhstan",
  "Dewey Beach, Washington, United States",
  "Karasu, Sakarya, Türkiye",
  "Cheboksary, Chuvashia, Russia",
  "Markova, Irkutsk, Russia",
  "500044, Hyderabad, Telangana, India",
  "Payne, Ohio, United States",
  "Licking, Pennsylvania, United States",
  "Monroe County, New York, United States",
  "64401, Agency, Missouri, United States",
  "7307, Pogradec, Korçë, Albania",
  "33605, Bielefeld, North Rhine-Westphalia, Germany",
  "665654, Zheleznogorsk-Ilimskiy, Irkutsk, Russia",
  "Härlanda, Gothenburg, Västra Götaland County, Sweden",
  "Schuyler Falls, New York, United States",
  "IP13 0HY, Pettistree, England, United Kingdom",
  "60124, Ancona, Marches, Italy",
  "15550, Lahti, Päijät-Häme, Finland",
  "Grants Pass, Oregon, United States",
  "Charlotte, North Carolina, United States",
  "Yeşilyurt, Malatya, Türkiye",
  "Nazranovsky District, Ingushetia, Russia",
  "45-316, Opole, Opolskie, Poland",
  "28220, Pori, Satakunta, Finland",
  "Ash Sharyah District, Al Bayda' Governorate, Yemen",
  "Mineral Bluff, Georgia, United States",
  "70205, Embilipitiya, Sabaragamuwa Province, Sri Lanka",
  "Harpers Ferry, Iowa, United States",
  "67200, Strasbourg, Grand Est, France",
  "Greater Subotica Area",
  "G0J 1Z0, Price, Quebec, Canada",
  "14753, Limestone, New York, United States",
  "B56H3X2, Zharkent, Jetisu Region, Kazakhstan",
  "627017, Yalutorovsk, Tyumen’, Russia",
  "MD-2002, Chişinău, Chișinău, Moldova",
  "Personpur, Alapur, Uttar Pradesh, India",
  "Lamentin, Basse-Terre, Guadeloupe",
  "8008, Székesfehérvár, Fejér, Hungary",
  "Titusville, New Brunswick, Canada",
  "Essex, England, United Kingdom",
  "352503, Labinsk, Krasnodar, Russia",
  "Agnew, Pipestone, Manitoba, Canada",
  "Fairfax, California, United States",
  "Khandyga, Sakha, Russia",
  "Minas Gerais, Brazil",
  "628187, Nyagan’, Khanty-Mansi, Russia",
  "Essex Fells, New Jersey, United States",
  "Portalegre, Portalegre, Portugal",
  "9303, Finnsnes, Troms og Finnmark, Norway",
  "2022, Shijak, Durres, Albania",
  "31076, Reynolds, Georgia, United States",
  "Old Lycoming, Pennsylvania, United States",
  "352695, Apsheronsk, Krasnodar, Russia",
  "Bernheze, North Brabant, Netherlands",
  "Nisou, Nicosia, Cyprus",
  "97419, Cheshire, Oregon, United States",
  "X38K7B1, Kentau, Turkistan Region, Kazakhstan",
  "Suntarsky District, Sakha, Russia",
  "94565, Pittsburg, California, United States",
  "Pagani, Campania, Italy",
  "97117, Port-Louis, Pointe-à-Pitre, Guadeloupe",
  "Butler, Ohio, United States",
  "Minervino Murge, Apulia, Italy",
  "Tobol’skiy, Orenburg, Russia",
  "220145, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "Newton Aycliffe, England, United Kingdom",
  "Esmeraldas, Esmeraldas, Ecuador",
  "29471, Reevesville, South Carolina, United States",
  "14471, Potsdam, Brandenburg, Germany",
  "Kırklareli, Kırklareli, Türkiye",
  "Howard City, Michigan, United States",
  "26161, Petroleum, West Virginia, United States",
  "União da Vitória, Paraná, Brazil",
  "22202, Primošten, Sibenik-Knin, Croatia",
  "Furnas, Ticino, Switzerland",
  "10020, Zhytomyr, Zhytomyr, Ukraine",
  "66430, Vaasa, Ostrobothnia, Finland",
  "01219, Dresden, Saxony, Germany",
  "09145, Sargentes de la Lora, Castilla and Leon, Spain",
  "Aytos, Burgas, Bulgaria",
  "Pokrovskoye, Yaroslavl’, Russia",
  "Shymkent, Shymkent, Kazakhstan",
  "Lauderdale County, Tennessee, United States",
  "28624, Ferguson, North Carolina, United States",
  "45138, Essen, North Rhine-Westphalia, Germany",
  "Anapa, Krasnodar, Russia",
  "310256, Arad, Arad, Romania",
  "Cotton End, England, United Kingdom",
  "Allendale, Michigan, United States",
  "98152, Messina, Sicily, Italy",
  "Oregon, United States",
  "01752, Marlborough, Massachusetts, United States",
  "Homyel’, Vitsyebsk, Belarus",
  "301650, Novomoskovsk, Tula, Russia",
  "01100, Vilnius, Vilniaus, Lithuania",
  "98165, Messina, Sicily, Italy",
  "503 34, Borås, Västra Götaland County, Sweden",
  "357913, Zelenokumsk, Stavropol, Russia",
  "10310, Vilnius, Vilniaus, Lithuania",
  "04900, Agri, Ağrı, Türkiye",
  "5020, Namur, Walloon Region, Belgium",
  "Bogotá D.C. Metropolitan Area",
  "Live Oak County, Texas, United States",
  "Klamath River, California, United States",
  "01002, Vitoria-Gasteiz, Basque Country, Spain",
  "Espiye, Giresun, Türkiye",
  "682429, De-Kastri, Khabarovsk, Russia",
  "50-001, Wrocław, Dolnośląskie, Poland",
  "Oktyabr'skiy sel'skiy sovet, Homel, Belarus",
  "SS1 1DB, Southend-On-Sea, England, United Kingdom",
  "27109, Winston-Salem, North Carolina, United States",
  "Summersdale, Chichester, England, United Kingdom",
  "Kirovo-Chepetsk, Kirov, Russia",
  "DL2 2LY, Walworth, England, United Kingdom",
  "652507, Leninsk-Kuznetskiy, Kemerovo, Russia",
  "Golden Grove, South Australia, Australia",
  "Pétange, Canton of Esch-sur-Alzette, Luxembourg",
  "624970, Serov, Sverdlovsk, Russia",
  "Mitchell, Georgia, United States",
  "358003, Elista, Kalmykia, Russia",
  "Skovorodinsky District, Amur, Russia",
  "Nicosia, Nicosia, Cyprus",
  "89801, Elko, Nevada, United States",
  "Şanliurfa, Türkiye",
  "10-692, Olsztyn, Warmińsko-mazurskie, Poland",
  "660125, Krasnoyarsk, Krasnoyarsk Krai, Russia",
  "368220, Buynaksk, Dagestan, Russia",
  "184421, Nikel, Murmansk, Russia",
  "Shuya, Ivanovo, Russia",
  "CV3 6BX, Coventry, England, United Kingdom",
  "Adams County, Illinois, United States",
  "5516, Haugesund, Rogaland, Norway",
  "Augusta, Ontario, Canada",
  "45344, New Carlisle, Ohio, United States",
  "Lancaster County, Nebraska, United States",
  "Malkara, Tekirdağ, Türkiye",
  "Toledo, Minas Gerais, Brazil",
  "Chornobaivka, Kherson, Ukraine",
  "Coburg, New Brunswick, Canada",
  "Von Steuben, Detroit, Michigan, United States",
  "53590, Sun Prairie, Wisconsin, United States",
  "Brooks, Maine, United States",
  "Genoa, Michigan, United States",
  "Glasgow, Scotland, United Kingdom",
  "981 31, Kiruna, Norrbotten County, Sweden",
  "Odessa, Missouri, United States",
  "Gravina di Catania, Sicily, Italy",
  "61-827, Poznan, Wielkopolskie, Poland",
  "Washington County, Maryland, United States",
  "Rice, Washington, United States",
  "Phra Nakhon, Bangkok City, Thailand",
  "Alexeyevsky District, Belgorod, Russia",
  "Greater Turin Metropolitan Area",
  "Wilkins, Queens, Nova Scotia, Canada",
  "Northumberland Business Park, Cramlington, England, United Kingdom",
  "Heróica Puebla de Zaragoza, Puebla, Mexico",
  "Rusanovo, Kursk, Russia",
  "Kotlassky District, Arkhangel’sk, Russia",
  "462430, Orsk, Orenburg, Russia",
  "G6B 1L7, Nantes, Quebec, Canada",
  "08024, Makarov, Kyiv, Ukraine",
  "Sumner County, Tennessee, United States",
  "Bandera, Albarracín, Aragon, Spain",
  "Schoharie County, New York, United States",
  "66115, Saarbrücken, Saarland, Germany",
  "Kirovskoye, Volgograd, Russia",
  "Monte Carlo, Monaco",
  "863, Sundsvall, Västernorrland County, Sweden",
  "Verona, Pennsylvania, United States",
  "Scottsboro, Alabama, United States",
  "184210, Apatity, Murmansk, Russia",
  "10467, Bronx, New York, United States",
  "141508, Solnechnogorsk, Moscow, Russia",
  "McPherson Estates, Midland, Texas, United States",
  "Swainsboro, Georgia, United States",
  "Toulouse-le-Château, Bourgogne-Franche-Comté, France",
  "86165, Augsburg, Bavaria, Germany",
  "85545, Roosevelt, Arizona, United States",
  "5003, Namur, Walloon Region, Belgium",
  "9302, Fier, Fier, Albania",
  "9721, Finiq, Vlorë, Albania",
  "Potiskum, Yobe, Nigeria",
  "LV-5410, Daugavpils, Daugavpils, Latvia",
  "11004, Cadiz, Andalusia, Spain",
  "PH33 6UQ, Fort William, Scotland, United Kingdom",
  "22900, Edirne, Edirne, Türkiye",
  "Latonia, Covington, Kentucky, United States",
  "Summers County, West Virginia, United States",
  "23273, Henrico, Virginia, United States",
  "Fort Erie, Ontario, Canada",
  "La Veveyse, Fribourg, Switzerland",
  "Ayrshire, Iowa, United States",
  "56521, Callaway, Minnesota, United States",
  "Challes-les-Eaux, Auvergne-Rhône-Alpes, France",
  "Pârscov, Buzău, Romania",
  "SR1 3PU, Sunderland, England, United Kingdom",
  "Sharjah, Sharjah Emirate, United Arab Emirates",
  "Rosebud, Montana, United States",
  "Lancaster, Virginia, United States",
  "Selçuklu, Konya, Türkiye",
  "Blandinsville, Illinois, United States",
  "E9C 2A7, New Bandon Northumberland County, New Brunswick, Canada",
  "Rogers, North Dakota, United States",
  "Granite Falls, Minnesota, United States",
  "871 41, Härnösand, Västernorrland County, Sweden",
  "86040, Page, Arizona, United States",
  "65-001, Zielona Góra, Lubuskie, Poland",
  "Unterklettgau, Schaffhausen, Switzerland",
  "Chickasaw County, Iowa, United States",
  "Addison, Texas, United States",
  "IP32 7AR, Bury St Edmunds, England, United Kingdom",
  "84321, Logan, Utah, United States",
  "41009, Seville, Andalusia, Spain",
  "78054, Atascosa, Texas, United States",
  "26101, Parkersburg, West Virginia, United States",
  "39439, Heidelberg, Mississippi, United States",
  "23714, Alexandria, Alexandria, Egypt",
  "North Grundy, Virginia, United States",
  "45142, Lynchburg, Ohio, United States",
  "71356, Morrow, Louisiana, United States",
  "655003, Abakan, Khakassia, Russia",
  "618221, Chusovoy, Perm, Russia",
  "G2 9SS, Glasgow, Scotland, United Kingdom",
  "175202, Staraya Russa, Novgorod, Russia",
  "618242, Chusovoy, Perm, Russia",
  "72300, Batman, Batman, Türkiye",
  "38180, Kayseri, Kayseri, Türkiye",
  "Makarovo, Altai Krai, Russia",
  "735100, Huşi, Vaslui, Romania",
  "Tipton, Michigan, United States",
  "Kars, Türkiye",
  "01214, Vilnius, Vilniaus, Lithuania",
  "Peccioli, Tuscany, Italy",
  "352 40, Växjö, Kronoberg County, Sweden",
  "Rankin, Papineau-Cameron, Ontario, Canada",
  "Hillsborough, Christchurch, Canterbury, New Zealand",
  "Sangarrén, Aragon, Spain",
  "Robertson, New South Wales, Australia",
  "IP32 7EA, Bury St Edmunds, England, United Kingdom",
  "SG13 7AA, Hertford, England, United Kingdom",
  "Alexander, New York, United States",
  "22243, Murter-Kornati, Sibenik-Knin, Croatia",
  "238314, Gur'yevsk, Kaliningrad, Russia",
  "Foster County, North Dakota, United States",
  "G1 9AG, Glasgow, Scotland, United Kingdom",
  "Karaganda Region, Kazakhstan",
  "652190, Mariinskiy, Kemerovo, Russia",
  "440011, Penza, Penza, Russia",
  "13350, Herkimer, New York, United States",
  "Vitória, Espírito Santo, Brazil",
  "38368, Reagan, Tennessee, United States",
  "Paris, Tennessee, United States",
  "Williamston, Michigan, United States",
  "Barrio 20 de Junio (Barrio AMPE 20 de Junio - Asociación Mutual del Personal del Estado), San Juan, Argentina",
  "652523, Leninsk-Kuznetskiy, Kemerovo, Russia",
  "Roanoke, Virginia, United States",
  "56460, Siirt, Siirt, Türkiye",
  "English Bazar, West Bengal, India",
  "82010, Cheyenne, Wyoming, United States",
  "Kittson County, Minnesota, United States",
  "Strafford, New Hampshire, United States",
  "Morgan County, Colorado, United States",
  "Dunn County, Wisconsin, United States",
  "Albacete, Castile-La Mancha, Spain",
  "130015, Cartagena, Bolívar, Colombia",
  "Jaen, Central Luzon, Philippines",
  "423832, Naberezhnyye Chelny, Tatarstan, Russia",
  "Karangintan, South Kalimantan, Indonesia",
  "Mazıdağı, Mardin, Türkiye",
  "06076, Stafford Springs, Connecticut, United States",
  "453128, Sterlitamak, Bashkortostan, Russia",
  "Kemperborn, Langsur, Rhineland-Palatinate, Germany",
  "Niger",
  "Morgantown, West Virginia, United States",
  "66523, Osage City, Kansas, United States",
  "E6E 1N4, Hawkins Corner, New Brunswick, Canada",
  "6900, Lugano, Ticino, Switzerland",
  "Pachuca, Hidalgo, Mexico",
  "Georgetown, South Carolina, United States",
  "712 01, Iráklion, Crete, Greece",
  "Boone County, Kentucky, United States",
  "Jacksonville Beach, Florida, United States",
  "C22D2A7, Atbasar, Akmola Region, Kazakhstan",
  "10900, Balıkesir, Balıkesir, Türkiye",
  "McCone County, Montana, United States",
  "Bellingham, Washington, United States",
  "422656, Novyy Arysh, Tatarstan, Russia",
  "Cepo, Gjirokastër, Albania",
  "Deva, Hunedoara, Romania",
  "Maine, United States",
  "54935, Fond du Lac, Wisconsin, United States",
  "34134, Kassel, Hesse, Germany",
  "Dolyna Raion, Ivano-Frankivsk, Ukraine",
  "Glasgow, Virginia, United States",
  "Little Kimble, England, United Kingdom",
  "Bellflower, California, United States",
  "Municipio Angostura, Bolivar State, Venezuela",
  "Shawano County, Wisconsin, United States",
  "70200, Kuopio, Northern Savonia, Finland",
  "630, Akureyri, Northeastern Region, Iceland",
  "225409, Baranavichy, Brest, Belarus",
  "Camden County, North Carolina, United States",
  "8500-608, Portimão, Faro, Portugal",
  "143430, Krasnogorsk, Moscow, Russia",
  "9309, Finnsnes, Troms og Finnmark, Norway",
  "Campo Maior, Portalegre, Portugal",
  "Plastinskiy, Lipetsk, Russia",
  "Martin County, Florida, United States",
  "Sierra de Yeguas, Andalusia, Spain",
  "Sherlovaya Gora, Zabaykalsky Krai, Russia",
  "20-005, Lublin, Lubelskie, Poland",
  "56800, Siirt, Siirt, Türkiye",
  "NN15 5JR, Burton Latimer, England, United Kingdom",
  "Tate, Ohio, United States",
  "164842, Onega, Arkhangel’sk, Russia",
  "Popel, Takeo, Cambodia",
  "Dallas County, Texas, United States",
  "20260, Korčula, Dubrovnik-Neretva, Croatia",
  "606427, Balakhna, Nizhniy Novgorod, Russia",
  "Kumasi Metropolitan District, Ashanti Region, Ghana",
  "Catawba, Wisconsin, United States",
  "Hot Springs, Arkansas, United States",
  "Keyari, Kotkhai, Himachal Pradesh, India",
  "8003, Székesfehérvár, Fejér, Hungary",
  "Davie Heights, Davie, Florida, United States",
  "10020, Zagreb, Zagreb, Croatia",
  "Loves Park, Illinois, United States",
  "57121, Livorno, Tuscany, Italy",
  "Mingo, Missouri, United States",
  "46321, Munster, Indiana, United States",
  "346523, Shakhty, Rostov, Russia",
  "Lieuran-lès-Béziers, Occitanie, France",
  "44100, Malatya, Malatya, Türkiye",
  "403112, Uryupinsk, Volgograd, Russia",
  "Swain House, Bradford, England, United Kingdom",
  "Lamoure, Labarrère, Occitanie, France",
  "188966, Svetogorsk, Leningrad, Russia",
  "Dubăsari, Transnistria autonomous territorial unit, Moldova",
  "L1C 3B5, Bowmanville, Ontario, Canada",
  "96270-959, Mostardas, Rio Grande do Sul, Brazil",
  "Randolph, Wisconsin, United States",
  "Dawesley, South Australia, Australia",
  "Cheremkhovo, Irkutsk, Russia",
  "688866, Manily, Koryak, Russia",
  "Fergus Falls, Minnesota, United States",
  "De Kalb County, Alabama, United States",
  "De Witt, Arkansas, United States",
  "4000, Liège, Walloon Region, Belgium",
  "Murray County, Oklahoma, United States",
  "2984 EV, Ridderkerk, South Holland, Netherlands",
  "Bayramiç, Çanakkale, Türkiye",
  "64500, Uşak, Uşak, Türkiye",
  "Valencia, Northern Mindanao, Philippines",
  "618422, Berezniki, Perm, Russia",
  "55000, Yuzhnoukrainsk, Mykolayiv, Ukraine",
  "4331 SB, Middelburg, Zeeland, Netherlands",
  "48618, Coleman, Michigan, United States",
  "99670, Sodankylä, Lapland, Finland",
  "Starr Gardens of Oakland, Oakland, Florida, United States",
  "0661, Oslo, Oslo, Norway",
  "Oktyabrskoe, Chuy Region, Kyrgyzstan",
  "Nizhneudinsk, Irkutsk, Russia",
  "Summerseat, England, United Kingdom",
  "621 55, Visby, Gotland County, Sweden",
  "SA3 4RL, Caswell, Wales, United Kingdom",
  "Uttarakhand, India",
  "093 01, Vranov nad Topľou, Presov, Slovakia",
  "40500, Jyväskylä, Central Finland, Finland",
  "Trempealeau Town, Wisconsin, United States",
  "Hökele Mahallesi, Ayancık, Sinop, Türkiye",
  "Harnett, North Carolina, United States",
  "F92 XD91, Letterkenny, County Donegal, Ireland",
  "Luftinjë, Gjirokastër, Albania",
  "Zmeinogorsk, Altai Krai, Russia",
  "Persone, Valvestino, Lombardy, Italy",
  "4337 AA, Middelburg, Zeeland, Netherlands",
  "Lubny, Poltava, Ukraine",
  "35440, Vance, Alabama, United States",
  "423458, Almetyevsk, Tatarstan, Russia",
  "Līvbērze, Jelgava Municipality, Latvia",
  "Lamoure, Canton of Montaigu-de-Quercy, Occitanie, France",
  "Laguna, Calabarzon, Philippines",
  "393366, Kirsanov, Tambov, Russia",
  "Lancaster, California, United States",
  "Paulding County, Georgia, United States",
  "Midleton, County Cork, Ireland",
  "83003, Donetsk, Donetsk, Ukraine",
  "9900-323, Horta, Portugal",
  "4700-315, Braga, Braga, Portugal",
  "75101, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "906 28, Umeå, Västerbotten County, Sweden",
  "L1C 4V3, Bowmanville, Ontario, Canada",
  "S25B2K4, Ertis, Pavlodar Region, Kazakhstan",
  "3470, Drammen, Viken, Norway",
  "69127, Brule, Nebraska, United States",
  "Staunton In The Vale, England, United Kingdom",
  "21220, Trogir, Split-Dalmatia, Croatia",
  "140408, Kolomna, Moscow, Russia",
  "Weber, Manawatū-Whanganui, New Zealand",
  "446028, Syzran, Samara, Russia",
  "20123, Milan, Lombardy, Italy",
  "Henry County, Kentucky, United States",
  "Brightons, Scotland, United Kingdom",
  "Departamento Capital, Tucumán, Argentina",
  "38902, Grenada, Mississippi, United States",
  "626109, Tobolsk, Tyumen’, Russia",
  "Lorca, Región de Murcia, Spain",
  "E25C3B1, Maqat, Atyrau Region, Kazakhstan",
  "Ula, Muğla, Türkiye",
  "Aydın, Aydın, Türkiye",
  "Tefenni, Burdur, Türkiye",
  "Prince Rupert, British Columbia, Canada",
  "Singapore",
  "Hunterdon County, New Jersey, United States",
  "48121, Ravenna, Emilia-Romagna, Italy",
  "60134, Geneva, Illinois, United States",
  "Mińsk Mazowiecki, Mazowieckie, Poland",
  "Khmilnyk, Vinnytsya, Ukraine",
  "86199, Augsburg, Bavaria, Germany",
  "Gregory, South Dakota, United States",
  "71909, Hot Springs Village, Arkansas, United States",
  "22100, Edirne, Edirne, Türkiye",
  "Stone County, Arkansas, United States",
  "1011 AG, Amsterdam, North Holland, Netherlands",
  "Athens Metropolitan Area",
  "11968, Southampton, New York, United States",
  "Taylorsville, North Carolina, United States",
  "Qulsarı, Atyrau Region, Kazakhstan",
  "Dillon, Carling, Ontario, Canada",
  "Timiryazevskoye, Adygea, Russia",
  "Medford-Grants Pass Area",
  "41083, Sanders, Kentucky, United States",
  "Benton County, Arkansas, United States",
  "Sanderson, Texas, United States",
  "Greater Billings Area",
  "82100, Benevento, Campania, Italy",
  "80601, Brighton, Colorado, United States",
  "1385, Drammen, Viken, Norway",
  "Gage, Lozanne, Auvergne-Rhône-Alpes, France",
  "Karaman, Türkiye",
  "392000, Tambov, Tambov, Russia",
  "300030, Timişoara, Timiş, Romania",
  "428023, Cheboksary, Chuvashia, Russia",
  "Salem City County, Virginia, United States",
  "679015, Birobidzhan, Birobijan, Russia",
  "Galaxias, Tabasco, Mexico",
  "Amherst Town, Massachusetts, United States",
  "Crawford, Georgia, United States",
  "CF10 1TL, Cardiff, Wales, United Kingdom",
  "Munich, Bavaria, Germany",
  "10458, Bronx, New York, United States",
  "Enköpings kommun, Uppsala County, Sweden",
  "AB16 5AF, Aberdeen, Scotland, United Kingdom",
  "Eastland Gardens, Washington, District of Columbia, United States",
  "HP17 9TP, Great Kimble, England, United Kingdom",
  "Alexandria, Minnesota, United States",
  "Palmas, Tocantins, Brazil",
  "Westmorland, New Brunswick, Canada",
  "Pine, Colorado, United States",
  "Wolford Chapel, Merrickville-Wolford, Ontario, Canada",
  "Upper Marlboro, Maryland, United States",
  "20-126, Lublin, Lubelskie, Poland",
  "9373, Finnsnes, Troms og Finnmark, Norway",
  "Sovetsky District, Stavropol, Russia",
  "04786, Washburn, Maine, United States",
  "Kent County, Delaware, United States",
  "Fall River County, South Dakota, United States",
  "Andrew County, Missouri, United States",
  "Uvalde, Texas, United States",
  "N0R 1C0, Emeryville, Ontario, Canada",
  "V93 C9X5, Killarney, County Kerry, Ireland",
  "15150, Lahti, Päijät-Häme, Finland",
  "Kourou, Cayenne, French Guiana",
  "Ruska Lozova, Kharkiv, Ukraine",
  "Akçaabat Bucağı, Trabzon, Türkiye",
  "63200, Şanlıurfa, Şanliurfa, Türkiye",
  "Spokane Valley, Washington, United States",
  "188305, Gatchina, Leningrad, Russia",
  "7940, Rørvik, Trøndelag, Norway",
  "398050, Lipetsk, Lipetsk, Russia",
  "MD-5316, Colibași, Cahul District, Moldova",
  "Tampere sub-region, Pirkanmaa, Finland",
  "Iraq",
  "80121, Naples, Campania, Italy",
  "Zyryanovskiy, Altai Krai, Russia",
  "9601, Hammerfest, Troms og Finnmark, Norway",
  "520, Melilla, Melilla, Spain",
  "455000, Magnitogorsk, Chelyabinsk, Russia",
  "9406 AA, Assen, Drenthe, Netherlands",
  "104, Reykjavík, Capital Region, Iceland",
  "Dawson, Yukon Territory, Canada",
  "R95 HE0X, Kilkenny, County Kilkenny, Ireland",
  "Twin Falls Area",
  "309960, Valuyki, Belgorod, Russia",
  "Penzance, England, United Kingdom",
  "48225, Harper Woods, Michigan, United States",
  "S25A7X7, Ertis, Pavlodar Region, Kazakhstan",
  "Cherven Bryag, Pleven, Bulgaria",
  "Yates Development, Salisbury Township, North Carolina, United States",
  "367029, Makhachkala, Dagestan, Russia",
  "NG23 5DJ, Long Bennington, England, United Kingdom",
  "C22B9A2, Atbasar, Akmola Region, Kazakhstan",
  "Portsmouth, England, United Kingdom",
  "15961, Vintondale, Pennsylvania, United States",
  "10003, Zhytomyr, Zhytomyr, Ukraine",
  "Union County, New Jersey, United States",
  "Mirnyy, Rostov, Russia",
  "Shar’ya, Kostroma, Russia",
  "Eau Galle, Wisconsin, United States",
  "Fanningsbog, County Tipperary, Ireland",
  "A91 A3P6, Dundalk, County Louth, Ireland",
  "Buchanan, Virginia, United States",
  "694923, Uglegorsk, Sakhalin, Russia",
  "92307, Klaipėda, Klaipėdos, Lithuania",
  "Fort-Mahon-Plage, Hauts-de-France, France",
  "Novoanninskiy, Volgograd, Russia",
  "76834, Coleman, Texas, United States",
  "01259, Dresden, Saxony, Germany",
  "211396, Orsha, Vitsyebsk, Belarus",
  "85250, Scottsdale, Arizona, United States",
  "56052, Kilkenny, Minnesota, United States",
  "Limerick Township, Pennsylvania, United States",
  "Rossett, Wales, United Kingdom",
  "Mingou, Dschang, West, Cameroon",
  "B3L 0A1, Halifax, Nova Scotia, Canada",
  "Vienna, Ohio, United States",
  "Poperinge, Flemish Region, Belgium",
  "34431, Dunnellon, Florida, United States",
  "McKinley Park, Chicago, Illinois, United States",
  "8701, Astë, Kukës, Albania",
  "Wagoner, Oklahoma, United States",
  "Solothurn, Switzerland",
  "Mississauga, Ontario, Canada",
  "69004, Lyon, Auvergne-Rhône-Alpes, France",
  "646580, Ust’-Ishim, Omsk, Russia",
  "167002, Syktyvkar, Komi, Russia",
  "Stevenston, Scotland, United Kingdom",
  "28701, San Sebastián de los Reyes, Community of Madrid, Spain",
  "Venets Municipality, Shumen, Bulgaria",
  "627309, Golyshmanovo, Tyumen’, Russia",
  "20904, Silver Spring, Maryland, United States",
  "C00M4A4, Kokshetau, Akmola Region, Kazakhstan",
  "9900-341, Horta, Portugal",
  "Peregrebnoye, Khanty-Mansi, Russia",
  "Cavalier, Capdenac-Gare, Occitanie, France",
  "25-900, Kielce, Świętokrzyskie, Poland",
  "76352, Siauliai, Siauliu, Lithuania",
  "Guilford, Connecticut, United States",
  "Wirtshüsli, Aargau, Switzerland",
  "665709, Bratsk, Irkutsk, Russia",
  "Cape Breton, Nova Scotia, Canada",
  "Cottonwood, Pense 160, Saskatchewan, Canada",
  "76001, Siauliai, Siauliu, Lithuania",
  "665717, Bratsk, Irkutsk, Russia",
  "Novozybkovskiy Gorodskoy Okrug, Bryansk, Russia",
  "Clermont-Dessous, Nouvelle-Aquitaine, France",
  "Essen, Flemish Region, Belgium",
  "28521, Vaciamadrid, Community of Madrid, Spain",
  "21310, Diyarbakır, Diyarbakır, Türkiye",
  "X0E 0A3, Fort McPherson, Northwest Territories, Canada",
  "164520, Severodvinsk, Arkhangel’sk, Russia",
  "Slavgorod, Altai Krai, Russia",
  "Alatyrsky District, Chuvashia, Russia",
  "44-103, Gliwice, Śląskie, Poland",
  "Mezdra, Vratsa, Bulgaria",
  "Richland, Washington, United States",
  "45-222, Opole, Opolskie, Poland",
  "Fairfax County, Virginia, United States",
  "Greenville, Michigan, United States",
  "Bayfield, Antigonish, Subdivision B, Nova Scotia, Canada",
  "Igarka, Krasnoyarsk Krai, Russia",
  "Pickens, Mississippi, United States",
  "320110, Reşiţa, Caraş-Severin, Romania",
  "95021, Aci Castello, Sicily, Italy",
  "Georgia, United States",
  "Pendleton, Oregon, United States",
  "Marshall County, Indiana, United States",
  "19103, Philadelphia, Pennsylvania, United States",
  "Carlisle, Ohio, United States",
  "Vila de REI, Castelo Branco, Portugal",
  "2120, Pukekohe, New Zealand",
  "Russell, Northland, New Zealand",
  "Košice-Dargovských hrdinov, Kosice, Slovakia",
  "Burnham, Slough, England, United Kingdom",
  "702 18, Örebro, Örebro County, Sweden",
  "Saline County, Missouri, United States",
  "Suffolk, Virginia, United States",
  "La Luzerne, Presle, Auvergne-Rhône-Alpes, France",
  "37186, Westmoreland, Tennessee, United States",
  "666783, Ust-Kut, Irkutsk, Russia",
  "2234, Lucas Heights, New South Wales, Australia",
  "Chez les Nobles, Vanzac, Nouvelle-Aquitaine, France",
  "41121, Modena, Emilia-Romagna, Italy",
  "9917, Kirkenes, Troms og Finnmark, Norway",
  "Jiménez, Chihuahua, Mexico",
  "249664, Kirsanovo-Pyatnitsa, Kaluga, Russia",
  "16508, Erie, Pennsylvania, United States",
  "132, Onondaga, New York, United States",
  "J0J 2B0, Sainte-Sabine, Quebec, Canada",
  "2613, Lillehammer, Innlandet, Norway",
  "Gilmer County, Georgia, United States",
  "British Indian Ocean Territory",
  "Greater Verona Metropolitan Area",
  "A92 E3C4, Drogheda, Ireland",
  "Aransas County, Texas, United States",
  "Penderf, Bignan, Brittany, France",
  "88100, Catanzaro, Calabria, Italy",
  "Hallsberg, Örebro County, Sweden",
  "Manitowoc Rapids, Wisconsin, United States",
  "Burton, Ohio, United States",
  "Woodward, Muskoka Falls, Ontario, Canada",
  "NR24 2AG, Edgefield, England, United Kingdom",
  "Chase, Michigan, United States",
  "LE16 8TG, Rockingham, England, United Kingdom",
  "030, Cottbus, Brandenburg, Germany",
  "Stara Zagora, Bulgaria",
  "Población La Bandera, San Ramón, Santiago Metropolitan Region, Chile",
  "86320, Comalcalco, Tabasco, Mexico",
  "173003, Velikiy Novgorod, Novgorod, Russia",
  "184070, Kandalaksha, Murmansk, Russia",
  "Campbell, Missouri, United States",
  "A92 A099, Drogheda, Ireland",
  "677005, Yakutsk, Sakha, Russia",
  "72100, Brindisi, Apulia, Italy",
  "Cayman Islands",
  "682803, Sovetskaya Gavan’, Khabarovsk, Russia",
  "Triunfo Potiguar, Rio Grande do Norte, Brazil",
  "AD400, La Massana, La Massana, Andorra",
  "51108, Rijeka, Primorje-Gorski, Croatia",
  "92055, Camp Pendleton South, California, United States",
  "54000, Nancy, Grand Est, France",
  "Vilasavilli, Uppalaguptam, Andhra Pradesh, India",
  "Mersch, Luxembourg, Luxembourg",
  "Lafayette, California, United States",
  "Grant County, Wisconsin, United States",
  "Istanbul, Türkiye",
  "49087, Schoolcraft, Michigan, United States",
  "Le Grand Bois Loudon, Saint-Mars-la-Brière, Pays de la Loire, France",
  "Meaders, Dallas, Texas, United States",
  "New South Wales, Australia",
  "Pulaski County, Indiana, United States",
  "Poliçan, Berat, Albania",
  "Clinton, Missouri, United States",
  "Johnstown-Somerset Area",
  "Liestal, Basel-Country, Switzerland",
  "9800, Vadsø, Troms og Finnmark, Norway",
  "Kropotkin, Krasnodar, Russia",
  "15217, Pittsburgh, Pennsylvania, United States",
  "Vladivostok, Primorye, Russia",
  "831 81, Östersund, Jämtland County, Sweden",
  "Carlisle, New York, United States",
  "Still Waters Village, Umatilla, Florida, United States",
  "Lower Brule, South Dakota, United States",
  "6811 DJ, Arnhem, Gelderland, Netherlands",
  "21222, Dundalk, Maryland, United States",
  "200008, Craiova, Dolj, Romania",
  "Palermo, California, United States",
  "40-007, Katowice, Śląskie, Poland",
  "644123, Omsk, Omsk, Russia",
  "249032, Obninsk, Kaluga, Russia",
  "462433, Orsk, Orenburg, Russia",
  "IP33 3TU, Bury St Edmunds, England, United Kingdom",
  "Noblesville Township, Indiana, United States",
  "10th of Ramadan, Sharkia, Egypt",
  "V94 W3CK, Limerick, County Limerick, Ireland",
  "Reykjavik, Capital Region, Iceland",
  "6828 AA, Arnhem, Gelderland, Netherlands",
  "Northern Cape, South Africa",
  "Genesee, Colorado, United States",
  "Tuscola Township, Illinois, United States",
  "Košice-Nad jazerom, Kosice, Slovakia",
  "Pacifica, California, United States",
  "Greater Modena Metropolitan Area",
  "Benton County, Tennessee, United States",
  "1383, Drammen, Viken, Norway",
  "Kasimovsky District, Ryazan’, Russia",
  "74123, Taranto, Apulia, Italy",
  "99094, Erfurt, Thuringia, Germany",
  "Cibola, Arizona, United States",
  "Williamsburg, West Virginia, United States",
  "Caswell Woods, Hinesville, Georgia, United States",
  "73190, Challes-les-Eaux, Auvergne-Rhône-Alpes, France",
  "Almeria, Eastern Visayas, Philippines",
  "Forest Hills, Harrisonburg, Virginia, United States",
  "Velikiye Luki, Pskov, Russia",
  "Payneville, Kentucky, United States",
  "Pekela, Groningen, Netherlands",
  "633521, Cherepanovo, Novosibirsk, Russia",
  "Howell County, Missouri, United States",
  "Borovichi, Kurgan, Russia",
  "Shelby County, Alabama, United States",
  "F17A5H1, Semey, Abay Region, Kazakhstan",
  "Horta Oest, Valencian Community, Spain",
  "Kirovskiy, Samara, Russia",
  "Sokolo, Ségou Region, Mali",
  "Boxholm, Östergötland County, Sweden",
  "Pallasovka, Volgograd, Russia",
  "N0M 1G0, Bayfield, Ontario, Canada",
  "32766, Oviedo, Florida, United States",
  "Winchester, Massachusetts, United States",
  "Fort George G. Meade, Maryland, United States",
  "10-691, Olsztyn, Warmińsko-mazurskie, Poland",
  "412616, Svobodnyy, Saratov, Russia",
  "WR10 2JH, Throckmorton, England, United Kingdom",
  "Heidelberg, Baden-Württemberg, Germany",
  "Kansk, Krasnoyarsk Krai, Russia",
  "Logan County, Oklahoma, United States",
  "Preston, Weymouth, England, United Kingdom",
  "55127, Mainz, Rhineland-Palatinate, Germany",
  "362043, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "Astrakhan metropolitan area, Astrakhan’, Russia",
  "Nodaway, Iowa, United States",
  "28066, Galliate, Piedmont, Italy",
  "Slunj, Karlovac, Croatia",
  "Williamsburg, Iowa, United States",
  "MD-4822, Dubăsarii Vechi, Criuleni District, Moldova",
  "429337, Kanash, Chuvashia, Russia",
  "46035, Colfax, Indiana, United States",
  "220017, Minsk, Minsk, Belarus",
  "Crooks, South Dakota, United States",
  "Clarkston, Georgia, United States",
  "Bakersfield, California, United States",
  "Dnistrovskyi Raion, Chernivtsi, Ukraine",
  "Delta State, Nigeria",
  "Piketberg, Western Cape, South Africa",
  "62985, Simpson, Illinois, United States",
  "353680, Yeysk, Krasnodar, Russia",
  "Ager Vejle, North Denmark Region, Denmark",
  "Presov, Slovakia",
  "Cherokee, Texas, United States",
  "Patrick Springs, Virginia, United States",
  "358008, Elista, Kalmykia, Russia",
  "621 41, Visby, Gotland County, Sweden",
  "Tarrant Gunville, England, United Kingdom",
  "Broomehill East, Western Australia, Australia",
  "03009, Alicante, Valencian Community, Spain",
  "B56H6Y7, Zharkent, Jetisu Region, Kazakhstan",
  "19006, Huntingdon Valley, Pennsylvania, United States",
  "X38G7C3, Kentau, Turkistan Region, Kazakhstan",
  "Cedar County, Iowa, United States",
  "Lancaster, Ohio, United States",
  "Moore, South Carolina, United States",
  "710 04, Iráklion, Crete, Greece",
  "Labinsky District, Krasnodar, Russia",
  "Togo",
  "Greater Freiburg Area",
  "Medina, Texas, United States",
  "624605, Alapayevsk, Sverdlovsk, Russia",
  "Albemarle Station, Falkland Islands",
  "Kalkandere, Rize, Türkiye",
  "627753, Ishim, Tyumen’, Russia",
  "S0G 1N0, Fillmore, Saskatchewan, Canada",
  "Hozat, Tunceli, Türkiye",
  "Kingston Blount, England, United Kingdom",
  "Nordwestmecklenburg, Mecklenburg-West Pomerania, Germany",
  "5404, Palanas, Bicol Region, Philippines",
  "55988, Winona, Minnesota, United States",
  "403531, Frolovo, Volgograd, Russia",
  "Nolan, Prince George, British Columbia, Canada",
  "McDonalds Corners, Ontario, Canada",
  "Pocahontas County, West Virginia, United States",
  "Dublin 6, County Dublin, Ireland",
  "Foard County, Texas, United States",
  "423453, Almetyevsk, Tatarstan, Russia",
  "Buchanan County, Iowa, United States",
  "Van, Türkiye",
  "Sargent Avenue, Fort Worth, Texas, United States",
  "Prizren, Prizren District, Kosovo",
  "Clark County, Illinois, United States",
  "Hallam, Victoria, Australia",
  "23300, Elâzığ, Elazığ, Türkiye",
  "694240, Poronaysk, Sakhalin, Russia",
  "Vila Pouca de Aguiar, Vila Real, Portugal",
  "Kane, Pennsylvania, United States",
  "Landero y Coss, Veracruz, Mexico",
  "Yurga Vtoraya, Kemerovo, Russia",
  "1040, Brussels, Brussels Region, Belgium",
  "6017, Ålesund, Møre og Romsdal, Norway",
  "Nashville, Tennessee, United States",
  "141301, Sergiyev Posad, Moscow, Russia",
  "680555, Korfovskiy, Khabarovsk, Russia",
  "Astrakhanka, Akmola Region, Kazakhstan",
  "Zimandu Nou, Arad, Romania",
  "70700, Kuopio, Northern Savonia, Finland",
  "Saint-Julien-de-Toursac, Auvergne-Rhône-Alpes, France",
  "Valletta, Valletta, Malta",
  "8011 AA, Zwolle, Overijssel, Netherlands",
  "412311, Balashov, Saratov, Russia",
  "Beaufort, Occitanie, France",
  "Mirgorod, Poltava, Ukraine",
  "Washington, Pennsylvania, United States",
  "E6H 1G9, Ritchie, New Brunswick, Canada",
  "Maastricht, Limburg, Netherlands",
  "Petropavlovskoye, Voronezh, Russia",
  "L10G5M3, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Colonial Heights, Virginia, United States",
  "IP33 3PH, Bury St Edmunds, England, United Kingdom",
  "34715, Clermont, Florida, United States",
  "Santa Rosa, Carabobo State, Venezuela",
  "80102, Bennett, Colorado, United States",
  "Sungurlu, Çorum, Türkiye",
  "232 32, Malmo, Skåne County, Sweden",
  "212020, Mahilyow, Mahilyow, Belarus",
  "B55C3K7, Zharkent, Jetisu Region, Kazakhstan",
  "78950, New Ulm, Texas, United States",
  "Burgas Metropolitan Area",
  "Trieste, Friuli-Venezia Giulia, Italy",
  "Berdychiv Raion, Zhytomyr, Ukraine",
  "Cercle de Boulmane-Dadès, Drâa-Tafilalet, Morocco",
  "North Macedonia",
  "152934, Rybinsk, Yaroslavl’, Russia",
  "Camden Wyoming, Delaware, United States",
  "225528, Kapani, Brest, Belarus",
  "E3V 0A1, Edmundston, New Brunswick, Canada",
  "51105, Rijeka, Primorje-Gorski, Croatia",
  "Cumberland County, Maine, United States",
  "67745, McDonald, Kansas, United States",
  "SA5 7AT, Swansea, Wales, United Kingdom",
  "Boxholms kommun, Östergötland County, Sweden",
  "Montour, Iowa, United States",
  "10-693, Olsztyn, Warmińsko-mazurskie, Poland",
  "New Monmouth, New Jersey, United States",
  "3129, Box Hill North, Victoria, Australia",
  "Sokoľ, Kosice, Slovakia",
  "142117, Podolsk, Moscow, Russia",
  "37824, New Tazewell, Tennessee, United States",
  "Oude Pekela, Groningen, Netherlands",
  "7012, Trondheim, Trøndelag, Norway",
  "Macon, Missouri, United States",
  "Cayenne, French Guiana",
  "03014, Alicante, Valencian Community, Spain",
  "142460, Noginsk, Moscow, Russia",
  "Loup County, Nebraska, United States",
  "Pocahontas, Tennessee, United States",
  "68047, Pender, Nebraska, United States",
  "Sopron District, Győr-Moson-Sopron, Hungary",
  "Stoddard County, Missouri, United States",
  "665053, Biryusinsk, Irkutsk, Russia",
  "185 36, Piraeus, Attiki, Greece",
  "28080, Iron Station, North Carolina, United States",
  "617407, Kungur, Perm, Russia",
  "Şelek, Almaty Region, Kazakhstan",
  "353993, Novorossiysk, Krasnodar, Russia",
  "85-790, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "Rayevskiy, Penza, Russia",
  "Santa Fe, Argentina",
  "Furnas County, Nebraska, United States",
  "V8A 0A1, Powell River, British Columbia, Canada",
  "Borgarnes, Western Region, Iceland",
  "20100, Turku, Southwest Finland, Finland",
  "Hampton East, Victoria, Australia",
  "Lisbon, Portugal",
  "169721, Usinsk, Komi, Russia",
  "M28B2G1, Temirtau, Karaganda Region, Kazakhstan",
  "Oklahoma City metropolitan area, Oklahoma, United States",
  "Keçiborlu, Isparta, Türkiye",
  "Vila Nova de Cerveira, Viana do Castelo, Portugal",
  "Sondori, Rēzekne Municipality, Latvia",
  "678200, Vilyuysk, Sakha, Russia",
  "18611, Stillwater, Pennsylvania, United States",
  "152612, Uglich, Yaroslavl’, Russia",
  "Clinton, New Jersey, United States",
  "Church Hill, Tennessee, United States",
  "playa cibola del mar, Baja California, Mexico",
  "Ashland, Missouri, United States",
  "626152, Tobolsk, Tyumen’, Russia",
  "7626, Pécs, Baranya, Hungary",
  "55124, Mainz, Rhineland-Palatinate, Germany",
  "70586, Ville Platte, Louisiana, United States",
  "Giessen, Hesse, Germany",
  "Beaver County, Utah, United States",
  "M52C6T1, Osakarovka, Karaganda Region, Kazakhstan",
  "Clarendon Station, Ontario, Canada",
  "KA18 2DA, Ochiltree, Scotland, United Kingdom",
  "618426, Berezniki, Perm, Russia",
  "Penza, Russia",
  "Broadwater Down, Royal Tunbridge Wells, England, United Kingdom",
  "238310, Gur'yevsk, Kaliningrad, Russia",
  "550350, Sibiu, Sibiu, Romania",
  "Sheffield Lake, Ohio, United States",
  "Prrenjas, Elbasan, Albania",
  "97110, Les Abymes, Pointe-à-Pitre, Guadeloupe",
  "Londonderry, New Hampshire, United States",
  "Nancemellin, Camborne, England, United Kingdom",
  "130104, Târgovişte, Dîmboviţa, Romania",
  "686442, Omsukchan, Magadan, Russia",
  "Osage County, Missouri, United States",
  "Pokrovska miska hromada, Dnipropetrovsk, Ukraine",
  "Artëmovsk, Donetsk, Ukraine",
  "McDowell County, North Carolina, United States",
  "Lenoir Woods, Columbia Township, Missouri, United States",
  "Năsăud, Bistriţa-Năsăud, Romania",
  "Kosice, Slovakia",
  "Pugachevskoye, Volgograd, Russia",
  "Yakima, Washington, United States",
  "Pershing Terrace, Florida, United States",
  "Greater Oldenburg Area",
  "Alexandria, Teleorman, Romania",
  "Lake Pickett Manor, University, Florida, United States",
  "50120, Mikkeli, South Savo, Finland",
  "Ağstafa, Qazakh-Tovuz, Azerbaijan",
  "39760, Starkville, Mississippi, United States",
  "Heart of Missoula, Missoula, Montana, United States",
  "694020, Korsakov, Sakhalin, Russia",
  "16509, Erie, Pennsylvania, United States",
  "905200, Cernavodă, Constanţa, Romania",
  "678790, Srednekolymsk, Sakha, Russia",
  "F63D4M9, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "542 30, Mariestad, Västra Götaland County, Sweden",
  "37841, Oneida, Tennessee, United States",
  "346411, Novocherkassk, Rostov, Russia",
  "Košice-Sídlisko Košického vládneho programu, Kosice, Slovakia",
  "Starkweather, North Dakota, United States",
  "Kazanka, Mykolayiv, Ukraine",
  "Lee County, Mississippi, United States",
  "Karpinsk, Sverdlovsk, Russia",
  "6300, Zug, Zug, Switzerland",
  "8503, Frauenfeld, Thurgau, Switzerland",
  "152306, Borisogleb, Yaroslavl’, Russia",
  "7012, Ruse, Ruse, Bulgaria",
  "423582, Nizhnekamsk, Tatarstan, Russia",
  "Dodge, North Dakota, United States",
  "Semenivska silska hromada, Zhytomyr, Ukraine",
  "C1N 0A1, Summerside, Prince Edward Island, Canada",
  "95-200, Pabianice, Łódzkie, Poland",
  "DT11 9JE, Tarrant Keyneston, England, United Kingdom",
  "611 32, Nyköping, Södermanland County, Sweden",
  "185030, Petrozavodsk, Karelia, Russia",
  "Adelaide Hills, South Australia, Australia",
  "Versailles, Ohio, United States",
  "Makarov, Sakhalin, Russia",
  "Solikamsky District, Perm, Russia",
  "Batman, Türkiye",
  "Wakema, Ayeyawady Region, Myanmar",
  "79106, Freiburg, Baden-Württemberg, Germany",
  "B25E6B7, Esik, Almaty Region, Kazakhstan",
  "Kiselyovsk, Kemerovo, Russia",
  "Brule County, South Dakota, United States",
  "54730, Colfax, Wisconsin, United States",
  "1115, Budapest, Budapest, Hungary",
  "Ashland, Wisconsin, United States",
  "52050, Greeley, Iowa, United States",
  "2800, Delémont, Jura, Switzerland",
  "Turkestan, Turkistan Region, Kazakhstan",
  "Faulkner Estates, League City, Texas, United States",
  "347045, Belaya Kalitva, Rostov, Russia",
  "Kudymkar, Perm, Russia",
  "Titusville Historic District, Pennsylvania, United States",
  "Gorod Verkhniy Ufaley, Chelyabinsk, Russia",
  "303031, Mtsensk, Orel, Russia",
  "Billings, Montana, United States",
  "Eşme, Uşak, Türkiye",
  "Ryazan, Ryazan’, Russia",
  "57600, Savonlinna, South Savo, Finland",
  "Democratic Republic of the Congo",
  "Major Gercino, Santa Catarina, Brazil",
  "624581, Ivdel’, Sverdlovsk, Russia",
  "SY7 9RL, Craven Arms, England, United Kingdom",
  "08025, Barcelona, Catalonia, Spain",
  "6704, Arlon, Walloon Region, Belgium",
  "20833, Brookeville, Maryland, United States",
  "Fremont, California, United States",
  "Kimball County, Nebraska, United States",
  "Progress, Novgorod, Russia",
  "31026, Kohtla-Järve, Ida-Virumaa, Estonia",
  "185 38, Piraeus, Attiki, Greece",
  "Wharton, Ohio, United States",
  "North Versailles, Pennsylvania, United States",
  "Mingot, Druy-Parigny, Bourgogne-Franche-Comté, France",
  "DG1 1AA, Dumfries, Scotland, United Kingdom",
  "Surgut, Khanty-Mansi, Russia",
  "LU5 6AB, Toddington, England, United Kingdom",
  "Carver, Dothan, Alabama, United States",
  "Carson County, Texas, United States",
  "580 02, Linköping, Östergötland County, Sweden",
  "12542, Marlboro, New York, United States",
  "694060, Dolinsk, Sakhalin, Russia",
  "161114, Nikol’skiy Torzhok, Vologda, Russia",
  "Berkshire, New York, United States",
  "Colombier, Neuchâtel, Switzerland",
  "07004, Palma, Balearic Islands, Spain",
  "17214, Alt Schwerin, Mecklenburg-West Pomerania, Germany",
  "Sharon, Massachusetts, United States",
  "Cherepanovsky District, Novosibirsk, Russia",
  "Novi Grad, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "41123, Modena, Emilia-Romagna, Italy",
  "456801, Verkhniy Ufaley, Chelyabinsk, Russia",
  "Armenia",
  "694913, Uglegorsk, Sakhalin, Russia",
  "X0C 0G0, Rankin Inlet, Nunavut, Canada",
  "32005, Ourense, Galicia, Spain",
  "Andrews County, Texas, United States",
  "Belleville, Michigan, United States",
  "Lake Louisa Oaks, Florida, United States",
  "Oktyabrsky District, Volgograd, Russia",
  "Sinop, Sinop, Türkiye",
  "Mc Leod, Montana, United States",
  "44-114, Gliwice, Śląskie, Poland",
  "446007, Syzran, Samara, Russia",
  "Bellinzona, Ticino, Switzerland",
  "Szentivánpuszta, Attala, Tolna, Hungary",
  "404110, Volzhskiy, Volgograd, Russia",
  "G0G 1H0, Clarke City, Quebec, Canada",
  "169707, Usinsk, Komi, Russia",
  "Chandigarh, India",
  "Lexington Park, Maryland, United States",
  "358001, Elista, Kalmykia, Russia",
  "37931, Knoxville, Tennessee, United States",
  "Saukville, Wisconsin, United States",
  "Bom Progresso, Rio Grande do Sul, Brazil",
  "99550, Sodankylä, Lapland, Finland",
  "Mirnyy, Tver’, Russia",
  "57376, Tripp, South Dakota, United States",
  "Risch, Zug, Switzerland",
  "2360, Breznik, Pernik, Bulgaria",
  "84629, Horlivka, Donetsk, Ukraine",
  "Davidson, Quebec, Canada",
  "06132, Perugia, Umbria, Italy",
  "44100, Guadalajara, Jalisco, Mexico",
  "North Denmark Region, Denmark",
  "Partido de Navarro, Buenos Aires Province, Argentina",
  "Pokrovsk, Donetsk, Ukraine",
  "78119, Kenedy, Texas, United States",
  "Wrightsville, Georgia, United States",
  "Silver Lake, Wisconsin, United States",
  "Sangarebougou, Koulikoro, Mali",
  "Le Chêne Millard, Chéronvilliers, Normandy, France",
  "416309, Volgo-Kaspiyskiy, Astrakhan’, Russia",
  "Bălăuşeri, Mureş, Romania",
  "Shenandoah, Pennsylvania, United States",
  "Treasure Valley, Idaho, United States",
  "302 34, Halmstad, Halland County, Sweden",
  "Monseñor Nouel, Dominican Republic",
  "44240, Guadalajara, Jalisco, Mexico",
  "Overton, Nebraska, United States",
  "Johnson County, Tennessee, United States",
  "49078, Otsego, Michigan, United States",
  "Kemerhisar, Niğde, Türkiye",
  "Chistopol'sko-Vysel'skoye, Tatarstan, Russia",
  "9010, Tromsø, Troms og Finnmark, Norway",
  "25-002, Kielce, Świętokrzyskie, Poland",
  "Phelpston, Springwater, Ontario, Canada",
  "Tralee, New South Wales, Australia",
  "23500, Elâzığ, Elazığ, Türkiye",
  "Estremoz, Évora, Portugal",
  "83866, St Maries, Idaho, United States",
  "6715, Esbjerg, Region of Southern Denmark, Denmark",
  "Çeltikçi, Burdur, Türkiye",
  "01170, Adana, Adana, Türkiye",
  "665772, Vikhorevka, Irkutsk, Russia",
  "Tuckerton, New Jersey, United States",
  "05648, Calais, Vermont, United States",
  "Duluth, Minnesota, United States",
  "65065, Osage Beach, Missouri, United States",
  "Elko, Nevada, United States",
  "Sanderson, Florida, United States",
  "R7N 2V2, Dauphin, Manitoba, Canada",
  "31500, Našice, Osijek-Baranja, Croatia",
  "75656, Hughes Springs, Texas, United States",
  "28516, Beaufort, North Carolina, United States",
  "Buckskin, Indiana, United States",
  "Exeter Mills, Maine, United States",
  "Grazzanise, Campania, Italy",
  "Almeirim, Santarém, Portugal",
  "654032, Novokuznetsk, Kemerovo, Russia",
  "62535, Forsyth, Illinois, United States",
  "Bandung, West Java, Indonesia",
  "Alta Gracia, Córdoba, Argentina",
  "74136, Tulsa, Oklahoma, United States",
  "Sofia, Bulgaria",
  "Bradford, Rhode Island, United States",
  "6200-585, Covilhã, Castelo Branco, Portugal",
  "Minersville, Pennsylvania, United States",
  "Ohio County, West Virginia, United States",
  "07885, Wharton, New Jersey, United States",
  "Clarksburg, Maryland, United States",
  "L10G1X4, Chapaev, West Kazakhstan Region, Kazakhstan",
  "611 57, Nyköping, Södermanland County, Sweden",
  "Pulaski County, Georgia, United States",
  "Walkertown, North Carolina, United States",
  "61826, Champaign, Illinois, United States",
  "Leflore County, Mississippi, United States",
  "Le Landeron, Neuchâtel, Switzerland",
  "75216, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "692910, Nakhodka, Primorye, Russia",
  "164521, Severodvinsk, Arkhangel’sk, Russia",
  "Newcastle Local Municipality, KwaZulu-Natal, South Africa",
  "505100, Codlea, Braşov, Romania",
  "Straw-Smyth, Manchester, New Hampshire, United States",
  "Belovo, Kemerovo, Russia",
  "Bayfield, Chepstow, Wales, United Kingdom",
  "Butler, Alabama, United States",
  "4020, Liège, Walloon Region, Belgium",
  "Onondaga, New York, United States",
  "Dourados, Mato Grosso do Sul, Brazil",
  "Asante-Akim Central Municipal District, Ashanti Region, Ghana",
  "Clarion, Iowa, United States",
  "141544, Solnechnogorsk, Moscow, Russia",
  "Pärnu, Parnu, Estonia",
  "Dakota County, Minnesota, United States",
  "Langlade, Lisle-sur-Tarn, Occitanie, France",
  "2503, Biel, Berne, Switzerland",
  "BH7 6AA, Bournemouth, England, United Kingdom",
  "550119, Sibiu, Sibiu, Romania",
  "601 10, Katerini, Central Macedonia, Greece",
  "89119, Las Vegas, Nevada, United States",
  "Winona North, Hamilton, Ontario, Canada",
  "MD-4501, Corjova, Dubăsari District, Moldova",
  "Sivas, Sivas, Türkiye",
  "01240, Adana, Adana, Türkiye",
  "71121, Foggia, Apulia, Italy",
  "Novocherkassk, Orenburg, Russia",
  "3000, Carlton, Victoria, Australia",
  "C22D7A4, Atbasar, Akmola Region, Kazakhstan",
  "Bronx County, New York, United States",
  "SS2 4AR, Southend-On-Sea, England, United Kingdom",
  "Sovkhoza Komsomolets poselok, Tambov, Russia",
  "Yartsevo, Smolensk, Russia",
  "623961, Tavda, Sverdlovsk, Russia",
  "Humboldt, Saskatchewan, Canada",
  "Bergen, New York, United States",
  "Sumterville, Florida, United States",
  "56570, Osage, Minnesota, United States",
  "Millersport, Ohio, United States",
  "Greater Oxford Area",
  "3354, Okhahlamba, KwaZulu-Natal, South Africa",
  "Menominee County, Wisconsin, United States",
  "Newberrytown, Pennsylvania, United States",
  "C22E0Y9, Atbasar, Akmola Region, Kazakhstan",
  "624332, Krasnoural’sk, Sverdlovsk, Russia",
  "Woodbury County, Iowa, United States",
  "Clarke County, Georgia, United States",
  "36330-000, Coronel Xavier Chaves, Minas Gerais, Brazil",
  "Eskipazar, Karabuk, Türkiye",
  "Hinds County, Mississippi, United States",
  "161111, Nikol’skiy Torzhok, Vologda, Russia",
  "72070, Batman, Batman, Türkiye",
  "49696, Traverse City, Michigan, United States",
  "674602, Borzya, Zabaykalsky Krai, Russia",
  "Kent, England, United Kingdom",
  "CA2 4AA, Carlisle, England, United Kingdom",
  "Stara Zagora, Stara Zagora, Bulgaria",
  "06611, Trumbull, Connecticut, United States",
  "Pernambuco, Brazil",
  "Starr Historic District, Richmond, Indiana, United States",
  "6229 ER, Maastricht, Limburg, Netherlands",
  "BS40 5HB, Churchill, England, United Kingdom",
  "22130, Edirne, Edirne, Türkiye",
  "Hamilton, Scotland, United Kingdom",
  "33139, Miami Beach, Florida, United States",
  "Tateoka, Japan",
  "Ménard, Campagne, Nouvelle-Aquitaine, France",
  "7224, Bitola, Pelagonia, North Macedonia",
  "LE4 6FP, Leicester, England, United Kingdom",
  "Luna, Ilocos Region, Philippines",
  "Ross, Ohio, United States",
  "13110, Hämeenlinna, Kanta-Häme, Finland",
  "Lamar County, Texas, United States",
  "Richmond, Virginia, United States",
  "Stittsville, Ottawa, Ontario, Canada",
  "51601, Shenandoah, Iowa, United States",
  "71015, Viljandi, Viljandimaa, Estonia",
  "48170, Plymouth, Michigan, United States",
  "50117, Hamlin, Iowa, United States",
  "162618, Cherepovets, Vologda, Russia",
  "New Providence, The Bahamas",
  "Campbell Hall, New York, United States",
  "Preston County, West Virginia, United States",
  "68845, Kearney, Nebraska, United States",
  "Butterworth, Eastern Cape, South Africa",
  "9020-102, Funchal, Madeira Island, Portugal",
  "9701, Saranda, Vlorë, Albania",
  "Blanding, Utah, United States",
  "Nikolayevsk-na-Amure, Khabarovsk, Russia",
  "12309, Schenectady, New York, United States",
  "Mampong Municipal District, Ashanti Region, Ghana",
  "1132, Budapest, Budapest, Hungary",
  "99812, Juneau, Alaska, United States",
  "Smolenskoye, Zabaykalsky Krai, Russia",
  "06796, Sandersdorf, Saxony-Anhalt, Germany",
  "433506, Dimitrovgrad, Ul’yanovsk, Russia",
  "Vert-St-Denis, Île-de-France, France",
  "167904, Syktyvkar, Komi, Russia",
  "Ayangaty, Tuva, Russia",
  "1111, Budapest, Budapest, Hungary",
  "630001, Novosibirsk, Novosibirsk, Russia",
  "South Wagoner, Oklahoma, United States",
  "70117, New Orleans, Louisiana, United States",
  "652473, Anzhero-Sudzhensk, Kemerovo, Russia",
  "682950, Vyazemskiy, Khabarovsk, Russia",
  "32800, Isparta, Isparta, Türkiye",
  "Pavlodarskoe, Pavlodar Region, Kazakhstan",
  "Samara, Samara, Russia",
  "7632, Pécs, Baranya, Hungary",
  "Troyeshchina, Kyiv, Ukraine",
  "78550-045, Sinop, Mato Grosso, Brazil",
  "23600, Tul’chin, Vinnytsya, Ukraine",
  "Somerset, Pennsylvania, United States",
  "Atyrau Region, Kazakhstan",
  "47300, Mardin, Mardin, Türkiye",
  "56379, Sauk Rapids, Minnesota, United States",
  "Thierstein, Solothurn, Switzerland",
  "Rosso, Trarza, Mauritania",
  "Beaver Dam, Wisconsin, United States",
  "Preston, Connecticut, United States",
  "22903, Charlottesville, Virginia, United States",
  "Southwest, Cameroon",
  "Bergen op Zoom, North Brabant, Netherlands",
  "Bari, Somalia",
  "13069, Fulton, New York, United States",
  "City Of Kingston Upon Hull, England, United Kingdom",
  "Sehzedeler, Manisa, Türkiye",
  "21217, Kaštela, Split-Dalmatia, Croatia",
  "De Witt, Illinois, United States",
  "Quitman, Texas, United States",
  "Juaben Municipal District, Ashanti Region, Ghana",
  "652062, Yurga Vtoraya, Kemerovo, Russia",
  "Boyarka, Novosibirsk, Russia",
  "Galax Commercial Historic District, Virginia, United States",
  "65542, Licking, Missouri, United States",
  "Mahoning Valley, Ohio, United States",
  "Hitchcock Heights, Boardman, Ohio, United States",
  "6260, Radnevo, Stara Zagora, Bulgaria",
  "Genesee, Michigan, United States",
  "Shenandoah, Iowa, United States",
  "Eure-et-Loir, Centre-Val de Loire, France",
  "Barboursville Historic District, Barboursville, West Virginia, United States",
  "412913, Vol'sk, Saratov, Russia",
  "Adair, Illinois, United States",
  "Metzer, Haifa District, Israel",
  "78359, Gregory, Texas, United States",
  "Săveni, Botoşani, Romania",
  "Lebedenco, Cahul District, Moldova",
  "Pécos, Termes-d’Armagnac, Occitanie, France",
  "Spokane, Missouri, United States",
  "Qatar",
  "18427, Hamlin, Pennsylvania, United States",
  "11764, Miller Place, New York, United States",
  "Clermont, Florida, United States",
  "Fannin County, Georgia, United States",
  "450001, Ufa, Bashkortostan, Russia",
  "Nichols Farms Historic District, Trumbull, Connecticut, United States",
  "59100, Tekirdağ, Tekirdağ, Türkiye",
  "PH13 9PP, Burrelton, Scotland, United Kingdom",
  "Zholoby, Ternopil, Ukraine",
  "Warsaw Metropolitan Area",
  "Kirov Metropolitan Area",
  "431443, Ruzayevka, Mordovia, Russia",
  "Ponderano, Piedmont, Italy",
  "Sesimbra, Setúbal, Portugal",
  "Judith’s Paarl, Johannesburg, Gauteng, South Africa",
  "Lamoure, Mazières-Naresse, Nouvelle-Aquitaine, France",
  "Morrison, Iowa, United States",
  "Târgu Ocna, Bacău, Romania",
  "Copenhagen Municipality, Capital Region of Denmark, Denmark",
  "385016, Maykop, Adygea, Russia",
  "Gaston, Barran, Occitanie, France",
  "Arthur, Illinois, United States",
  "R0K 1G0, Killarney, Manitoba, Canada",
  "Greers Homeland, Sarasota, Florida, United States",
  "Rivne, Rivne, Ukraine",
  "Kaman, Kırşehir, Türkiye",
  "Stafford County, Virginia, United States",
  "Gregory, Northern Territory, Australia",
  "Pulaski, Mississippi, United States",
  "Batmanovskoye, Ivanovo, Russia",
  "Greater Kremenchuk Area",
  "301870, Yefremov, Tula, Russia",
  "1340088, Urayasu, Chiba, Japan",
  "602 21, Norrköping, Östergötland County, Sweden",
  "38400, Kayseri, Kayseri, Türkiye",
  "81-173, Gdynia, Pomorskie, Poland",
  "Ar Horqin Banner, Inner Mongolia, China",
  "Spink, County Offaly, Ireland",
  "AB55 5FY, Keith, Scotland, United Kingdom",
  "238170, Chernyakhovskiy, Kaliningrad, Russia",
  "Potterville, Michigan, United States",
  "Iowa, Louisiana, United States",
  "Актобе, Aqtöbe region, Kazakhstan",
  "67835, Cimarron, Kansas, United States",
  "Metzing, Grand Est, France",
  "07130, Porvoo, Uusimaa, Finland",
  "Délégation Sakiet Ezzit, Sfax, Tunisia",
  "Lamar, Colorado, United States",
  "S40 1AA, Chesterfield, England, United Kingdom",
  "C22C4K0, Atbasar, Akmola Region, Kazakhstan",
  "461632, Buguruslan, Orenburg, Russia",
  "44222, Cuyahoga Falls, Ohio, United States",
  "16130, Bursa, Bursa, Türkiye",
  "Sivas, Türkiye",
  "Ayanavaram, Tamil Nadu, India",
  "Caledonia, Minnesota, United States",
  "Belmonte, Castelo Branco, Portugal",
  "63000, Şanlıurfa, Şanliurfa, Türkiye",
  "9703, Szombathely, Vas, Hungary",
  "Steubenville, Ohio, United States",
  "48640, Midland, Michigan, United States",
  "Arrondissement of Arlon, Walloon Region, Belgium",
  "624945, Karpinsk, Sverdlovsk, Russia",
  "Aybastı, Ordu, Türkiye",
  "Calais, Alberta, Canada",
  "Rosebud, Central Kootenay H, British Columbia, Canada",
  "Wickham, Quebec, Canada",
  "B25C0K1, Esik, Almaty Region, Kazakhstan",
  "Fauquier-Strickland, Ontario, Canada",
  "13200, Bitlis, Bitlis, Türkiye",
  "Kent County, Michigan, United States",
  "39759, Starkville, Mississippi, United States",
  "309970, Valuyki, Belgorod, Russia",
  "Svetlovodsk, Kirovohrad, Ukraine",
  "446116, Chapayevsk, Samara, Russia",
  "E7K 2N8, Gregg Settlement, New Brunswick, Canada",
  "Davidson Woods, Rock Hill, South Carolina, United States",
  "60528, Frankfurt, Hesse, Germany",
  "Ozeritsko-Slobodskoy sel'skiy sovet, Minsk Province, Belarus",
  "75217, Dallas, Texas, United States",
  "02125, Kyiv, Kyiv City, Ukraine",
  "E7M 4V5, McKenzie Corner, New Brunswick, Canada",
  "Granite City Township, Illinois, United States",
  "19120, Philadelphia, Pennsylvania, United States",
  "2351, Pernik, Pernik, Bulgaria",
  "Kerchikskoye, Rostov, Russia",
  "73027, Kherson, Kherson, Ukraine",
  "624334, Krasnoural’sk, Sverdlovsk, Russia",
  "Monchegorsk, Murmansk, Russia",
  "Karlstads kommun, Värmland County, Sweden",
  "24463, Staunton, Virginia, United States",
  "06690, Ankara, Ankara, Türkiye",
  "6006, North Perth, Western Australia, Australia",
  "Amherst, New York, United States",
  "Dauphin Island, Alabama, United States",
  "618430, Berezniki, Perm, Russia",
  "Averyanovka, Dagestan, Russia",
  "Aginskoye, Zabaykalsky Krai, Russia",
  "48100, Kaunas, Kauno, Lithuania",
  "152639, Otradnyy, Yaroslavl’, Russia",
  "97074, Würzburg, Bavaria, Germany",
  "Tatvan, Bitlis, Türkiye",
  "694445, Aleksandrovsk-Sakhalinskiy, Sakhalin, Russia",
  "Nyíregyházi járás, Szabolcs-Szatmár-Bereg, Hungary",
  "Rockingham, England, United Kingdom",
  "Mingo Plaza, Tulsa, Oklahoma, United States",
  "28262, Charlotte, North Carolina, United States",
  "Sharurah, Najran, Saudi Arabia",
  "662955, Krasnokamensk, Krasnoyarsk Krai, Russia",
  "Çarşamba, Samsun, Türkiye",
  "Blount County, Tennessee, United States",
  "F42G2T6, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "677016, Yakutsk, Sakha, Russia",
  "NN15 5GB, Burton Latimer, England, United Kingdom",
  "59101, Billings, Montana, United States",
  "629305, Novy Urengoy, Yamalo-Nenets, Russia",
  "Lake County, Illinois, United States",
  "Suluova Bucağı, Amasya, Türkiye",
  "Trevanion Terrace, Taneytown, Maryland, United States",
  "Daniels Farm, Connecticut, United States",
  "Shenandoah, Florida, United States",
  "Flushing, Queens, New York, United States",
  "6270-096, Seia, Guarda, Portugal",
  "37604, Johnson City, Tennessee, United States",
  "40472, Düsseldorf, North Rhine-Westphalia, Germany",
  "Converselle, Castrocaro Terme e Terra del Sole, Emilia-Romagna, Italy",
  "Aurora, Ohio, United States",
  "Slavgorod, Dnipropetrovsk, Ukraine",
  "367015, Makhachkala, Dagestan, Russia",
  "Toledo, Spain Metropolitan Area",
  "Windham Centre, Ontario, Canada",
  "Blairsville, Georgia, United States",
  "Lucas Heights, New South Wales, Australia",
  "67334, Chautauqua, Kansas, United States",
  "Wellston, Ohio, United States",
  "65197, Wiesbaden, Hesse, Germany",
  "72769, Summers, Arkansas, United States",
  "G6B 3C4, Nantes, Quebec, Canada",
  "Stillwater, Greymouth, West Coast, New Zealand",
  "Kaliningrad, Kaliningrad, Russia",
  "Danielson, Connecticut, United States",
  "423579, Nizhnekamsk, Tatarstan, Russia",
  "A92 YAC6, Drogheda, Ireland",
  "Karaköprü, Şanliurfa, Türkiye",
  "652050, Yurga Vtoraya, Kemerovo, Russia",
  "Brule Point, Colchester, Subdivision B, Nova Scotia, Canada",
  "Aralagash, North Kazakhstan Region, Kazakhstan",
  "76123, Siauliai, Siauliu, Lithuania",
  "Highland Lakes, New Jersey, United States",
  "238161, Chernyakhovskiy, Kaliningrad, Russia",
  "Harris, Minnesota, United States",
  "Daren Township, Taiwan, Taiwan",
  "Donetsk, Ukraine",
  "NN15 5ND, Burton Latimer, England, United Kingdom",
  "24148, Kiel, Schleswig-Holstein, Germany",
  "673201, Khilok, Zabaykalsky Krai, Russia",
  "Klinok, Minsk, Belarus",
  "3408, Librazhd, Elbasan, Albania",
  "Kirovskoye, Donetsk, Ukraine",
  "Harrison County, West Virginia, United States",
  "C19C0T5, Zhaltyr, Akmola Region, Kazakhstan",
  "426000, Izhevsk, Udmurtia, Russia",
  "Harpersfield, New York, United States",
  "4455, Orallo, Queensland, Australia",
  "8868, Niederurnen, Glarus, Switzerland",
  "634061, Tomsk, Tomsk, Russia",
  "990, Erfurt, Thuringia, Germany",
  "40213, Düsseldorf, North Rhine-Westphalia, Germany",
  "Blagoveshchenskiy, Krasnoyarsk Krai, Russia",
  "Osage City, Kansas, United States",
  "Pecetto, Piedmont, Italy",
  "Onslow Mountain, Nova Scotia, Canada",
  "Tolyatti, Samara, Russia",
  "Northern Cambria, Pennsylvania, United States",
  "618451, Berezniki, Perm, Russia",
  "30006, Murcia, Región de Murcia, Spain",
  "Polk County, Missouri, United States",
  "Grafton, New Hampshire, United States",
  "692405, Kavalerovo, Primorye, Russia",
  "93306, Bakersfield, California, United States",
  "Tucker Hill, McKinney, Texas, United States",
  "Paphos Municipality, Paphos, Cyprus",
  "Jefferson Davis County, Mississippi, United States",
  "V3H 0E1, Port Moody, British Columbia, Canada",
  "Lisbon Metropolitan Area",
  "620021, Focşani, Vrancea, Romania",
  "Kanash, Samara, Russia",
  "Belfast, Mpumalanga, South Africa",
  "14027, Chernihiv, Chernihiv, Ukraine",
  "97080, Würzburg, Bavaria, Germany",
  "Russell, Iowa, United States",
  "Dorchester, Wisconsin, United States",
  "2012 AA, Haarlem, North Holland, Netherlands",
  "Wisconsin, United States",
  "7990, Rørvik, Trøndelag, Norway",
  "629733, Nadym, Yamalo-Nenets, Russia",
  "Browns Summit, North Carolina, United States",
  "80220, Joensuu, North Karelia, Finland",
  "70469, Stuttgart, Baden-Württemberg, Germany",
  "Sandovales, Aguascalientes, Mexico",
  "640022, Kurgan, Kurgan, Russia",
  "6225, Buckingham, Western Australia, Australia",
  "Borisogleb, Yaroslavl’, Russia",
  "18036, Coopersburg, Pennsylvania, United States",
  "222306, Maladzyechna, Minsk, Belarus",
  "Fairfield, Connecticut, United States",
  "40121, Bologna, Emilia-Romagna, Italy",
  "617071, Krasnokamsk, Perm, Russia",
  "Tervel, Dobrich, Bulgaria",
  "04128, Kyiv, Kyiv City, Ukraine",
  "59300, Tekirdağ, Tekirdağ, Türkiye",
  "Klina, District of Peja, Kosovo",
  "40124, Bologna, Emilia-Romagna, Italy",
  "8308, Peshkopi, Dibrë, Albania",
  "72131, Quitman, Arkansas, United States",
  "63320, Şanlıurfa, Şanliurfa, Türkiye",
  "Isle of Man",
  "Preston, Brighton, England, United Kingdom",
  "Coffee County, Alabama, United States",
  "462732, Dombarovskiy, Orenburg, Russia",
  "Clay, New York, United States",
  "Saluda, Virginia, United States",
  "Sequatchie, Tennessee, United States",
  "Kimblewick, Aylesbury, England, United Kingdom",
  "694630, Kholmsk, Sakhalin, Russia",
  "Gorodishche, Volgograd, Russia",
  "Pueblo Joven Conchopata, Andres Avelino Caceres Dorregaray, Ayacucho, Peru",
  "617473, Kungur, Perm, Russia",
  "4058, Basel, Basel, Switzerland",
  "Greater Nyiregyhaza Area",
  "Buchanan, Grand Bassa County, Liberia",
  "Snowdons Corners, Merrickville-Wolford, Ontario, Canada",
  "37709, Blaine, Tennessee, United States",
  "656023, Barnaul, Altai Krai, Russia",
  "93200, St.-Denis, Île-de-France, France",
  "West Burke, Vermont, United States",
  "413119, Engels, Saratov, Russia",
  "Olenyok, Sakha, Russia",
  "Michurinsky, Novosibirsk, Russia",
  "Cookham, England, United Kingdom",
  "340 02, Chalkida, Central Greece, Greece",
  "86161, Augsburg, Bavaria, Germany",
  "Johnston, Rhode Island, United States",
  "Brighton East, Victoria, Australia",
  "Gage, Montaut, Occitanie, France",
  "Montserrat",
  "Richardson Park, Vallejo, California, United States",
  "OL4 1AA, Oldham, England, United Kingdom",
  "423465, Almetyevsk, Tatarstan, Russia",
  "09122, Cagliari, Sardinia, Italy",
  "77963, Goliad, Texas, United States",
  "Grady, Montcuq-en-Quercy-Blanc, Occitanie, France",
  "Ust'inskiy, Tambov, Russia",
  "Chernyshevskiy, Tambov, Russia",
  "3409, Librazhd, Elbasan, Albania",
  "429826, Alatyr’, Chuvashia, Russia",
  "142407, Noginsk, Moscow, Russia",
  "Rains, Himachal Pradesh, India",
  "Bielawa, Dolnośląskie, Poland",
  "624777, Verkhnyaya Salda, Sverdlovsk, Russia",
  "06200, Ankara, Ankara, Türkiye",
  "Kiribati",
  "Caroline, Alberta, Canada",
  "601903, Kovrov, Vladimir, Russia",
  "Mönsterås kommun, Kalmar County, Sweden",
  "Williams Lake, British Columbia, Canada",
  "504 37, Borås, Västra Götaland County, Sweden",
  "Foster, Victoria, Australia",
  "347935, Taganrog, Rostov, Russia",
  "Stadt Regensburg, Bavaria, Germany",
  "1671, Pepingen, Flemish Region, Belgium",
  "Sovetskaya Gavan’, Khabarovsk, Russia",
  "97340, Grand-Santi, Saint-Laurent-du-Maroni, French Guiana",
  "Prince, Prince Edward Island, Canada",
  "M1R 0A1, Scarborough, Ontario, Canada",
  "664058, Markova, Irkutsk, Russia",
  "Butler, Pennsylvania, United States",
  "Dawson County, Georgia, United States",
  "29732, Rock Hill, South Carolina, United States",
  "37170, Chambray-lès-Tours, Centre-Val de Loire, France",
  "662973, Zheleznogorsk, Krasnoyarsk Krai, Russia",
  "Ipatinga, Minas Gerais, Brazil",
  "37230, Fondettes, Centre-Val de Loire, France",
  "9409, Harstad, Troms og Finnmark, Norway",
  "Cimarron Ranch, San Bernardino, California, United States",
  "97316, Papaichton, Saint-Laurent-du-Maroni, French Guiana",
  "Lawrence, Massachusetts, United States",
  "91210-000, Porto Alegre, Rio Grande do Sul, Brazil",
  "L01M9D8, Oral, West Kazakhstan Region, Kazakhstan",
  "Zadar, Zadar, Croatia",
  "610200, Piatra Neamţ, Neamţ, Romania",
  "353147, Tikhoretsk, Krasnodar, Russia",
  "Hindsbury Road, Saint Michael, Barbados",
  "Gmina Bytom Odrzański, Lubuskie, Poland",
  "Mathews County Courthouse Square, Virginia, United States",
  "Walthall, Mississippi, United States",
  "Lake Grove, New York, United States",
  "Lawrence, Kansas, United States",
  "75-243, Koszalin, Zachodniopomorskie, Poland",
  "11000, Miguel Hidalgo, Mexico City, Mexico",
  "Lacs, Maritime Region, Togo",
  "628146, Igrim, Khanty-Mansi, Russia",
  "660049, Krasnoyarsk, Krasnoyarsk Krai, Russia",
  "15140, Lahti, Päijät-Häme, Finland",
  "141305, Sergiyev Posad, Moscow, Russia",
  "Santa Comba Dão, Viseu, Portugal",
  "16200, Bursa, Bursa, Türkiye",
  "NN2 6AA, Northampton, England, United Kingdom",
  "TS1 3BA, Middlesbrough, England, United Kingdom",
  "Camden County, Missouri, United States",
  "Isil’kul’, Omsk, Russia",
  "Monmouth, Wales, United Kingdom",
  "3919, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Kembla Grange, New South Wales, Australia",
  "96440, Rovaniemi, Lapland, Finland",
  "Poltava Raion, Poltava, Ukraine",
  "El Llano, Merida State, Venezuela",
  "8021, Székesfehérvár, Fejér, Hungary",
  "North Middlesex, Ontario, Canada",
  "Wibaux County, Montana, United States",
  "565, Sauðárkrókur, Northwestern Region, Iceland",
  "5600, Sankt Johann im Pongau, Salzburg, Austria",
  "398046, Lipetsk, Lipetsk, Russia",
  "Jharkhand, India",
  "Arzamassky District, Nizhniy Novgorod, Russia",
  "Eureka, California, United States",
  "E6H 1G8, Ritchie, New Brunswick, Canada",
  "670018, Ulan-Ude, Buryatia, Russia",
  "19901, Dover, Delaware, United States",
  "Charlotte, New Brunswick, Canada",
  "Curacao",
  "Bucks Hill, England, United Kingdom",
  "22408, Fredericksburg, Virginia, United States",
  "22910, Charlottesville, Virginia, United States",
  "Silverton, Gauteng, South Africa",
  "652051, Yurga Vtoraya, Kemerovo, Russia",
  "Partido de Adolfo Gonzales Chaves, Buenos Aires Province, Argentina",
  "44106, Cleveland, Ohio, United States",
  "9764, Kemijärvi, Lapland, Finland",
  "Stansbury, South Australia, Australia",
  "06010, Ankara, Ankara, Türkiye",
  "Hinterland, Appenzell Outer-Rhoden, Switzerland",
  "346513, Shakhty, Rostov, Russia",
  "Albany, Kentucky, United States",
  "Bilecik, Türkiye",
  "Konotopska miska hromada, Sumy, Ukraine",
  "73237, Ahwash Ad Daydeeb District, Northern Frontier, Saudi Arabia",
  "683024, Petropavlovsk-Kamchatskiy, Kamchatka, Russia",
  "Jamestown, California, United States",
  "Madison, Georgia, United States",
  "Greater Accra Region, Ghana",
  "Ankara, Ankara, Türkiye",
  "445100, Carei, Satu Mare, Romania",
  "Baldone, Riga, Latvia",
  "690012, Vladivostok, Primorye, Russia",
  "Colinas de Santa Cruz, Puerto Cabello, Carabobo State, Venezuela",
  "75052, Grand Prairie, Texas, United States",
  "Clearwater County, Idaho, United States",
  "Briarcliff West, Gallatin, Missouri, United States",
  "Weston, Ohio, United States",
  "L10G0Y0, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Lysychansk, Luhansk, Ukraine",
  "Cartier, Chélan, Occitanie, France",
  "Kapangan, Cordillera Admin Region, Philippines",
  "Crooked River, Victoria, Australia",
  "301659, Novomoskovsk, Tula, Russia",
  "Narva, Krasnoyarsk Krai, Russia",
  "Drammen, Viken, Norway",
  "632402, Kargat, Novosibirsk, Russia",
  "Bedford, Massachusetts, United States",
  "Neu Ulm, Bavaria, Germany",
  "Usol'ye-Sibirskoye, Irkutsk, Russia",
  "R0C 2S0, Stonewall, Manitoba, Canada",
  "67213, Wichita, Kansas, United States",
  "44052, Lorain, Ohio, United States",
  "Rainçon, Blancafort, Centre-Val de Loire, France",
  "37138, Old Hickory, Tennessee, United States",
  "Liberty County, Florida, United States",
  "Maries County, Missouri, United States",
  "Rockingham Demesne, County Roscommon, Ireland",
  "Camino Real de Tetelpan, La Magdalena Contreras, Mexico City, Mexico",
  "C00T0A3, Kokshetau, Akmola Region, Kazakhstan",
  "Gur'yevsk, Kaliningrad, Russia",
  "Tikhoretsky District, Krasnodar, Russia",
  "33976, Lehigh Acres, Florida, United States",
  "Randolph, Vermont, United States",
  "Bath, New York, United States",
  "Kaspiysk, Dagestan, Russia",
  "Zielona Góra, Lubuskie, Poland",
  "MD-5321, Vadul lui Isac, Cahul District, Moldova",
  "Vernon, Florida, United States",
  "Grand Tunis Metropolitan Area",
  "Wilkins, Bethel Heights, Arkansas, United States",
  "445200, Negrești-Oaș, Satu Mare, Romania",
  "Hungary",
  "31012, Pamplona, Chartered Community of Navarre, Spain",
  "825400, Sulina, Tulcea, Romania",
  "216509, Roslavl’, Smolensk, Russia",
  "Bedford, New York, United States",
  "051048, Bucharest, Bucharest, Romania",
  "Pine Bush, New York, United States",
  "Lewisham, New South Wales, Australia",
  "35-006, Rzeszów, Podkarpackie, Poland",
  "Granville Ferry, Nova Scotia, Canada",
  "74000, Annecy, Auvergne-Rhône-Alpes, France",
  "Neustadt bei Coburg, Bavaria, Germany",
  "602252, Murom, Vladimir, Russia",
  "HR4 7PU, Swainshill, England, United Kingdom",
  "5958, Nikopol, Pleven, Bulgaria",
  "Borba, Évora, Portugal",
  "Anson, Downtown Core, Central Singapore Community Development Council, Singapore",
  "Greater Poza Rica de Hidalgo Area",
  "55100, Samsun, Samsun, Türkiye",
  "Karamanas, Tashkent Region, Uzbekistan",
  "352391, Kropotkin, Krasnodar, Russia",
  "791 72, Falun, Dalarna County, Sweden",
  "Hawkins Corners, Severn, Ontario, Canada",
  "Sumner, Illinois, United States",
  "Hasselt, Overijssel, Netherlands",
  "99356, Roosevelt, Washington, United States",
  "678805, Polyarnyy, Sakha, Russia",
  "9700-179, Angra do Heroísmo, Portugal",
  "Mount Mckenzie, South Australia, Australia",
  "4990-174, Ponte do Lima, Viana do Castelo, Portugal",
  "658130, Aleysk, Altai Krai, Russia",
  "38007, Santa Cruz de Tenerife, Canary Islands, Spain",
  "Virginia Beach, Virginia, United States",
  "La Grande Ville Audrain, Loudéac, Brittany, France",
  "30034, Decatur, Georgia, United States",
  "Marolles-lès-Saint-Calais, Pays de la Loire, France",
  "164755, Mezen, Arkhangel’sk, Russia",
  "Tanoba, Tokat, Türkiye",
  "Greater Logroño Metropolitan Area",
  "Pike, Indiana, United States",
  "Fayetteville, Arkansas, United States",
  "57950, Montigny-lès-Metz, Grand Est, France",
  "Pinskiy gorodskoy sovet, Brest, Belarus",
  "LS6 4EL, Leeds, England, United Kingdom",
  "Bragança, Portugal",
  "SS1 2QW, Southend-On-Sea, England, United Kingdom",
  "352705, Timashevsk, Krasnodar, Russia",
  "51069, Cologne, North Rhine-Westphalia, Germany",
  "Whitebridge, New South Wales, Australia",
  "722 46, Västerås, Västmanland County, Sweden",
  "Gdańsk, Pomorskie, Poland",
  "Pulaski County, Arkansas, United States",
  "8004, Székesfehérvár, Fejér, Hungary",
  "Winchester, Indiana, United States",
  "Dawson Springs, Kentucky, United States",
  "44220, Guadalajara, Jalisco, Mexico",
  "51-354, Wrocław, Dolnośląskie, Poland",
  "Yalta, Crimea, Ukraine",
  "694446, Aleksandrovsk-Sakhalinskiy, Sakhalin, Russia",
  "Benton, Illinois, United States",
  "6228 CW, Maastricht, Limburg, Netherlands",
  "Kendall, Kansas, United States",
  "McLennan South, Richmond, British Columbia, Canada",
  "Ramsey, Ramsey, Isle of Man",
  "32060, Live Oak, Florida, United States",
  "Pereslavl-Zalessky, Yaroslavl’, Russia",
  "55-080, Kąty Wrocławskie, Dolnośląskie, Poland",
  "Rwanda",
  "Hizan, Bitlis, Türkiye",
  "Madison County, New York, United States",
  "99092, Erfurt, Thuringia, Germany",
  "Mariinsko-Posadsky District, Chuvashia, Russia",
  "81-133, Gdynia, Pomorskie, Poland",
  "692031, Lesozavodsk, Primorye, Russia",
  "Boyd, Wisconsin, United States",
  "Arlington, Washington, United States",
  "34453, Inverness, Florida, United States",
  "347902, Taganrog, Rostov, Russia",
  "Alexander County, Illinois, United States",
  "Privolzhskiy, Saratov, Russia",
  "Sovetskoye, Volgograd, Russia",
  "155801, Kineshma, Ivanovo, Russia",
  "222517, Barysaw, Minsk, Belarus",
  "19134, Philadelphia, Pennsylvania, United States",
  "Bafra Bucağı, Samsun, Türkiye",
  "Kurseong, West Bengal, India",
  "3071 AA, Rotterdam, South Holland, Netherlands",
  "624272, Asbest, Sverdlovsk, Russia",
  "Newberry County, South Carolina, United States",
  "Bradford, Arkansas, United States",
  "Cavalier Hills, Tempe, Arizona, United States",
  "Halle, North Rhine-Westphalia, Germany",
  "Murmansk, Russia",
  "6915, Lugano, Ticino, Switzerland",
  "Petersburg, Alaska, United States",
  "Geneva, Illinois, United States",
  "78200, Karabuk, Karabuk, Türkiye",
  "Porto Feliz, São Paulo, Brazil",
  "Fayetteville, North Carolina, United States",
  "641443, Kurtamysh, Kurgan, Russia",
  "130056, Târgovişte, Dîmboviţa, Romania",
  "Fonds-des-Nègres, Département des Nippes, Haiti",
  "88-109, Inowrocław, Kujawsko-pomorskie, Poland",
  "368551, Derbent, Dagestan, Russia",
  "Duplín, Presov, Slovakia",
  "140402, Kolomna, Moscow, Russia",
  "Orangeburg Plaza, Modesto, California, United States",
  "Amherst, Victoria, Australia",
  "Atlantic Beach, North Carolina, United States",
  "C00P8F6, Kokshetau, Akmola Region, Kazakhstan",
  "Pawnee, Missouri, United States",
  "Garden Reach, West Bengal, India",
  "629830, Gubkinskiy, Yamalo-Nenets, Russia",
  "Rakovica, Karlovac, Croatia",
  "Sidi M'Hamed District, Algiers, Algeria",
  "41531, Hardy, Kentucky, United States",
  "Wals-Siezenheim, Salzburg, Austria",
  "Inverness, Subdivision A, Nova Scotia, Canada",
  "3170, Tønsberg, Vestfold og Telemark, Norway",
  "Summit, New Jersey, United States",
  "86602, Pärnu, Parnu, Estonia",
  "Kurumkan, Buryatia, Russia",
  "HU5 3DZ, Kingston Upon Hull, England, United Kingdom",
  "Cassino, Latium, Italy",
  "Marinette, Fontaine-Bellenger, Normandy, France",
  "Woodward Park, Tulsa, Oklahoma, United States",
  "Strasbourg, Grand Est, France",
  "09134, Cagliari, Sardinia, Italy",
  "Richmond, Indiana, United States",
  "422540, Zelënodol'sk, Mari El, Russia",
  "Williamsburg, East Berbice-Corentyne, Guyana",
  "Winder, Georgia, United States",
  "Wheeler, Wisconsin, United States",
  "Haisyn Raion, Vinnytsya, Ukraine",
  "428003, Cheboksary, Chuvashia, Russia",
  "Braxton County, West Virginia, United States",
  "85100, Potenza, Basilicata, Italy",
  "68116, Omaha, Nebraska, United States",
  "Aqsw Q.Ä., Pavlodar Region, Kazakhstan",
  "Coopers Shoot, New South Wales, Australia",
  "M1H 0A1, Scarborough, Ontario, Canada",
  "Zagreb Metropolitan Area",
  "Rockhampton Area",
  "Stanley, New York, United States",
  "Audrain County, Missouri, United States",
  "Hopkinstown, Ammanford, Wales, United Kingdom",
  "Aurora, Illinois, United States",
  "E28D6E9, Makhambet, Atyrau Region, Kazakhstan",
  "23323, Chesapeake, Virginia, United States",
  "Parmaksian, North Sumatra, Indonesia",
  "Fallon, Nevada, United States",
  "49307, Big Rapids, Michigan, United States",
  "Boone County, West Virginia, United States",
  "353920, Novorossiysk, Krasnodar, Russia",
  "17979, Schuylkill Haven, Pennsylvania, United States",
  "9001 LP, Leeuwarden, Friesland, Netherlands",
  "Altdorf, Rhineland-Palatinate, Germany",
  "McIntosh, Howick, Ontario, Canada",
  "Cologne, Minnesota, United States",
  "Walkerville, South Australia, Australia",
  "Orsha, Vitsyebsk, Belarus",
  "18425, Greeley, Pennsylvania, United States",
  "Novorzhev, Pskov, Russia",
  "Como, Lombardy, Italy",
  "3174, Noble Park, Victoria, Australia",
  "Hudson Waterfront, New Jersey, United States",
  "Krasnoarmeyskoye, Orel, Russia",
  "Kalynivka, Vinnytsya, Ukraine",
  "18220, Aleksinac, Centralna Srbija, Serbia",
  "Forpost-Kargat, Novosibirsk, Russia",
  "Taylor, British Columbia, Canada",
  "Snyder, Pennsylvania, United States",
  "Bayfield, New Brunswick, Canada",
  "Novoshakhtinsk, Rostov, Russia",
  "Waterford, Nova Scotia, Canada",
  "Phillips, Nebraska, United States",
  "G64 4EE, Torrance, Scotland, United Kingdom",
  "Green Valley, Waukesha, Wisconsin, United States",
  "Moscow, Moscow City, Russia",
  "88-104, Inowrocław, Kujawsko-pomorskie, Poland",
  "A91 F2C7, Dundalk, County Louth, Ireland",
  "85, Horben bei Frauenfeld, Thurgau, Switzerland",
  "40115, Custer, Kentucky, United States",
  "Blount, Saint-Georges-de-Gréhaigne, Brittany, France",
  "Weaverville, North Carolina, United States",
  "Hamilton County, Indiana, United States",
  "Newport, Michigan, United States",
  "Thielemans Heights, Waukesha, Wisconsin, United States",
  "602251, Murom, Vladimir, Russia",
  "VLT 1171, Valletta, Valletta, Malta",
  "6518, Bellinzona, Ticino, Switzerland",
  "422521, Zelënodol'sk, Mari El, Russia",
  "Konya, Konya, Türkiye",
  "665827, Angarsk, Irkutsk, Russia",
  "Saül, Saint-Laurent-du-Maroni, French Guiana",
  "Westchester Station, Nova Scotia, Canada",
  "Fremont, North Carolina, United States",
  "Cockeysville, Maryland, United States",
  "979 01, Rimavská Sobota, Banska Bystrica, Slovakia",
  "629810, Noyabrsk, Yamalo-Nenets, Russia",
  "22905, Charlottesville, Virginia, United States",
  "58000, Sivas, Sivas, Türkiye",
  "692515, Ussuriysk, Primorye, Russia",
  "171988, Bezhetsk, Tver’, Russia",
  "Ewenki Autonomous Banner, Inner Mongolia, China",
  "Oltenița, Călăraşi, Romania",
  "Victoria, British Columbia, Canada",
  "40137, Bologna, Emilia-Romagna, Italy",
  "Łódzkie, Poland",
  "Greater Novara Metropolitan Area",
  "Wielkopolskie, Poland",
  "Galliana, Marradi, Tuscany, Italy",
  "33556, Odessa, Florida, United States",
  "Tyumen, Tyumen’, Russia",
  "11341, Vilnius, Vilniaus, Lithuania",
  "Clarke County, Iowa, United States",
  "Västervik, Kalmar County, Sweden",
  "29584, Patrick, South Carolina, United States",
  "5235 KK, ’s-Hertogenbosch, North Brabant, Netherlands",
  "21208, Pikesville, Maryland, United States",
  "6200-665, Covilhã, Castelo Branco, Portugal",
  "Wilayat Sur, Ash Sharqiyah South Governorate, Oman",
  "Valley Springs, California, United States",
  "680009, Khabarovsk, Khabarovsk, Russia",
  "Cherkasy, Ukraine",
  "Charlotte County, Florida, United States",
  "424039, Yoshkar-Ola, Mari El, Russia",
  "33050, Marathon, Florida, United States",
  "Colfax, Louisiana, United States",
  "17015, Carlisle, Pennsylvania, United States",
  "264 41, Patras, Western Greece, Greece",
  "Crockett County, Tennessee, United States",
  "Woods County, Oklahoma, United States",
  "Merida Metropolitan Area",
  "636700, Kolpashevo, Tomsk, Russia",
  "E7L 1L6, Divide, New Brunswick, Canada",
  "Ménard, Notre-Dame-de-la-Rouvière, Occitanie, France",
  "2815, Gjøvik, Innlandet, Norway",
  "50040, Nevsehir, Nevşehir, Türkiye",
  "33756, Clearwater, Florida, United States",
  "Comal County, Texas, United States",
  "43035, Lewis Center, Ohio, United States",
  "Bolivar, New York, United States",
  "Greater Rouen Metropolitan Area",
  "9100, Tromsø, Troms og Finnmark, Norway",
  "692901, Nakhodka, Primorye, Russia",
  "694916, Uglegorsk, Sakhalin, Russia",
  "Kempsey, New South Wales, Australia",
  "Ansongo, Mali",
  "Hancock, New Hampshire, United States",
  "Conchouso, Brués (San Fiz), Galicia, Spain",
  "78671, Stonewall, Texas, United States",
  "4401, Pukë, Shkodër, Albania",
  "211386, Orsha, Vitsyebsk, Belarus",
  "Szeged, Csongrád, Hungary",
  "LE2 0AA, Leicester, England, United Kingdom",
  "24107, Subotica, Vojvodina, Serbia",
  "75959, Milam, Texas, United States",
  "Shtiqën, Kukës, Albania",
  "Stans, Tyrol, Austria",
  "Krasnoural’sk, Sverdlovsk, Russia",
  "Chelyabinsk, Chelyabinsk, Russia",
  "09114, Chemnitz, Saxony, Germany",
  "Eddystone, Pennsylvania, United States",
  "08016, Burlington, New Jersey, United States",
  "99084, Erfurt, Thuringia, Germany",
  "164503, Severodvinsk, Arkhangel’sk, Russia",
  "35002, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Yablanitsa Municipality, Lovech, Bulgaria",
  "5101, Namur, Walloon Region, Belgium",
  "Siauliu, Lithuania",
  "57766, Oral, South Dakota, United States",
  "60061, Vernon Hills, Illinois, United States",
  "Barbé, Chélan, Occitanie, France",
  "666785, Ust-Kut, Irkutsk, Russia",
  "Kirklareli, Kırklareli, Türkiye",
  "Williams Landing, Victoria, Australia",
  "1618, Sofia, Sofia City, Bulgaria",
  "Smithers, British Columbia, Canada",
  "658469, Rubtsovsk, Altai Krai, Russia",
  "352067, Severnyy, Krasnodar, Russia",
  "82002, Cheyenne, Wyoming, United States",
  "Fond du Lac, Wisconsin, United States",
  "Wakefield, South Australia, Australia",
  "Camden, Arkansas, United States",
  "462410, Orsk, Orenburg, Russia",
  "110217, Piteşti, Argeş, Romania",
  "972 42, Luleå, Norrbotten County, Sweden",
  "Chitungwiza, Harare, Zimbabwe",
  "Pittsburg County, Oklahoma, United States",
  "Greenwood, South Carolina, United States",
  "Kecamatan Kemiling, Lampung, Indonesia",
  "Ferguson, Iowa, United States",
  "Delémont, Jura, Switzerland",
  "70800, Kuopio, Northern Savonia, Finland",
  "Distrito Central, Francisco Morazán, Honduras",
  "Loiret, Centre-Val de Loire, France",
  "Greater Istanbul",
  "Sully, Bourgogne-Franche-Comté, France",
  "20126, Milan, Lombardy, Italy",
  "56210, Barry, Minnesota, United States",
  "4123, Allschwil, Basel-Country, Switzerland",
  "353497, Gelendzhik, Krasnodar, Russia",
  "Kings Park, New York, United States",
  "6506, Mossel Bay, Western Cape, South Africa",
  "Shannon, Western Australia, Australia",
  "Castellolí, Catalonia, Spain",
  "Rossano Veneto, Veneto, Italy",
  "38570-000, Guarda-Mor, Minas Gerais, Brazil",
  "75019, Paris, Île-de-France, France",
  "Wheatland County, Alberta, Canada",
  "Taylor County, Georgia, United States",
  "Sublette, Kansas, United States",
  "6200-142, Covilhã, Castelo Branco, Portugal",
  "Hermansverk/Leikanger, Vestland, Norway",
  "678141, Lensk, Sakha, Russia",
  "Sussex Corner, New Brunswick, Canada",
  "Ujmisht, Kukës, Albania",
  "NR25 6PE, West Beckham, England, United Kingdom",
  "Isparta, Isparta, Türkiye",
  "610209, Piatra Neamţ, Neamţ, Romania",
  "2548, Yellow Pinch, New South Wales, Australia",
  "Glen Forrest, Western Australia, Australia",
  "362039, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "3371 JJ, Hardinxveld-Giessendam, South Holland, Netherlands",
  "94526, Danville, California, United States",
  "Jackson, Ohio, United States",
  "6014, Ålesund, Møre og Romsdal, Norway",
  "R0E 2A0, Traverse Bay, Manitoba, Canada",
  "71-795, Szczecin, Zachodniopomorskie, Poland",
  "Alfalfares, La Serena, Coquimbo Region, Chile",
  "North 5th Ward, DeKalb, Illinois, United States",
  "Rutherford, Edmonton, Alberta, Canada",
  "Fairfax, Missouri, United States",
  "141315, Sergiyev Posad, Moscow, Russia",
  "94400, Keminmaa, Lapland, Finland",
  "974 01, Banská Bystrica, Banska Bystrica, Slovakia",
  "632412, Verkh-Kargat, Novosibirsk, Russia",
  "Othón P. Blanco, Quintana Roo, Mexico",
  "Otar, Jambyl Region, Kazakhstan",
  "49019, Dnipro, Dnipropetrovsk, Ukraine",
  "Kazachinsko-Lensky District, Irkutsk, Russia",
  "Nolan's Corners, Montague, Ontario, Canada",
  "Aurora, Zamboanga Peninsula, Philippines",
  "7101, Szekszárd, Tolna, Hungary",
  "Tripura, India",
  "Puerto Real, Andalusia, Spain",
  "Curry, Santa Margarita, Eastern Visayas, Philippines",
  "Amsterdam Area",
  "546 22, Thessaloniki, Central Macedonia, Greece",
  "Penderry, Swansea, Wales, United Kingdom",
  "24115, Martinsville, Virginia, United States",
  "DD4 7BY, Dundee, Scotland, United Kingdom",
  "423452, Almetyevsk, Tatarstan, Russia",
  "Liege Metropolitan Area",
  "Palompon, Eastern Visayas, Philippines",
  "53551, Lake Mills, Wisconsin, United States",
  "Brule, Wisconsin, United States",
  "L02D2G8, Oral, West Kazakhstan Region, Kazakhstan",
  "Caminha, Viana do Castelo, Portugal",
  "3512, Hasselt, Flemish Region, Belgium",
  "Okhaldhunga District, Province No. 1, Nepal",
  "8922 GS, Leeuwarden, Friesland, Netherlands",
  "21211, Novi Sad, Vojvodina, Serbia",
  "Chemung County, New York, United States",
  "Becker Township, Minnesota, United States",
  "Woodland Hills, Los Angeles, California, United States",
  "Palm Court, Umatilla, Florida, United States",
  "425250, Orshanka, Mari El, Russia",
  "Oliver, British Columbia, Canada",
  "Porvoo sub-region, Uusimaa, Finland",
  "Franz Josef Glacier, West Coast, New Zealand",
  "Colebrook, New Hampshire, United States",
  "Oneida, Tennessee, United States",
  "7750, Namsos, Trøndelag, Norway",
  "Clarke, Piura, Piura, Peru",
  "Zalaegerszeg, Hungary",
  "Monkton, Maryland, United States",
  "8509, Narvik, Nordland, Norway",
  "16255, Sligo, Pennsylvania, United States",
  "Covilhã, Castelo Branco, Portugal",
  "Fort Meade, South Dakota, United States",
  "Portage County, Wisconsin, United States",
  "Lubliniec, Śląskie, Poland",
  "Ponta Delgada (São Pedro), Ilha de São Miguel, Portugal",
  "50452, Latimer, Iowa, United States",
  "L1C 1Z9, Bowmanville, Ontario, Canada",
  "Gregg Settlement, New Brunswick, Canada",
  "Carroll County, Illinois, United States",
  "Dimitrovgrad Municipality, Khaskovo, Bulgaria",
  "2839, Gjøvik, Innlandet, Norway",
  "Umán, Yucatán, Mexico",
  "Kearney Lake, Halifax, Nova Scotia, Canada",
  "68300, Kokkola, Central Ostrobothnia, Finland",
  "Kempen, North Rhine-Westphalia, Germany",
  "Mezensky District, Arkhangel’sk, Russia",
  "Lawrence, New York, United States",
  "Zell am See, Salzburg, Austria",
  "Bytom Odrzański, Lubuskie, Poland",
  "Cimarron, Vancouver, Washington, United States",
  "Iskitim, Novosibirsk, Russia",
  "Addison, Pennsylvania, United States",
  "Davie Village, Vancouver, British Columbia, Canada",
  "Keskin, Kırıkkale, Türkiye",
  "04120, Almería, Andalusia, Spain",
  "163961, Archangel, Arkhangel’sk, Russia",
  "Molovata Nouă, Dubăsari District, Moldova",
  "Varbitsa Municipality, Shumen, Bulgaria",
  "357745, Kislovodsk, Stavropol, Russia",
  "18014, Granada, Andalusia, Spain",
  "685030, Magadan, Magadan, Russia",
  "247608, Barysawshchyna, Homel, Belarus",
  "Caledonia, Nova Scotia, Canada",
  "21137, Novi Sad, Vojvodina, Serbia",
  "9900-017, Horta, Portugal",
  "95131, Catania, Sicily, Italy",
  "Qulansiyah wa `Abd al Kuri, Socotra Governorate, Yemen",
  "694455, Nogliki, Sakhalin, Russia",
  "Oberklettgau, Schaffhausen, Switzerland",
  "Jones, Oklahoma, United States",
  "Roger Mills County, Oklahoma, United States",
  "Northern Ireland, United Kingdom",
  "Allendale County, South Carolina, United States",
  "86650, Comalcalco, Tabasco, Mexico",
  "Comberton, Kidderminster, England, United Kingdom",
  "Santa Terezinha do Progresso, Santa Catarina, Brazil",
  "Salavatsky District, Bashkortostan, Russia",
  "Foster, Illinois, United States",
  "Andhra Pradesh, India",
  "Nye, Jönköping County, Sweden",
  "Upper Swainswick, England, United Kingdom",
  "NR24 2AE, Edgefield, England, United Kingdom",
  "Calhoun Isles, Minnesota, United States",
  "27606, Raleigh, North Carolina, United States",
  "00150, Helsinki, Uusimaa, Finland",
  "8043 AA, Zwolle, Overijssel, Netherlands",
  "White Rock, British Columbia, Canada",
  "Wallern an der Trattnach, Upper Austria, Austria",
  "21337, Lunenburg, Lower Saxony, Germany",
  "663330, Noril'sk, Taymyr, Russia",
  "Cruz Alta, Rio Grande do Sul, Brazil",
  "KW15 1AA, Kirkwall, Scotland, United Kingdom",
  "22801, Harrisonburg, Virginia, United States",
  "77210, Houston, Texas, United States",
  "23170, Louisa, Virginia, United States",
  "Tripp County, South Dakota, United States",
  "Malacky, Bratislava, Slovakia",
  "Zavala, Inhambane Province, Mozambique",
  "8880, Sliven, Sliven, Bulgaria",
  "Redditch, England, United Kingdom",
  "Goiás, Brazil",
  "Stanton, England, United Kingdom",
  "692401, Kavalerovo, Primorye, Russia",
  "97041, Mount Hood, Oregon, United States",
  "174404, Borovichi, Novgorod, Russia",
  "8853, Lachen, Schwyz, Switzerland",
  "Amherst, Massachusetts, United States",
  "Aitkin, Minnesota, United States",
  "Windygates, Pembina, Manitoba, Canada",
  "614000, Perm, Perm, Russia",
  "307176, Zheleznogorsk, Kursk, Russia",
  "611 38, Nyköping, Södermanland County, Sweden",
  "22945, Charlottesville, Virginia, United States",
  "14423, Caledonia, New York, United States",
  "360030, Nalchik, Kabardino-Balkaria, Russia",
  "Danville, Vermont, United States",
  "Manassas, Virginia, United States",
  "Borås, Västra Götaland County, Sweden",
  "505200, Făgăraş, Braşov, Romania",
  "Franklin, Wisconsin, United States",
  "NW6 1HS, London, England, United Kingdom",
  "Minnehaha County, South Dakota, United States",
  "40422, Otero de Herreros, Castilla and Leon, Spain",
  "Lafayette, Alabama, United States",
  "Făurei, Brăila, Romania",
  "Mercer, Tennessee, United States",
  "BS40 5HD, Churchill, England, United Kingdom",
  "Tisul’, Kemerovo, Russia",
  "33759, Clearwater, Florida, United States",
  "Sunderland, Massachusetts, United States",
  "E3A 9E5, Killarney Road, New Brunswick, Canada",
  "Unicoi County, Tennessee, United States",
  "Polk, Pennsylvania, United States",
  "Harlan Heights, Tucson, Arizona, United States",
  "Münster, North Rhine-Westphalia, Germany",
  "4606, Rrëshen, Lezhë, Albania",
  "Murviel-lès-Montpellier, Occitanie, France",
  "346518, Shakhty, Rostov, Russia",
  "Callaway-Garrison, Baltimore, Maryland, United States",
  "Leawood, Kansas, United States",
  "Butler, New Jersey, United States",
  "Bambergerhof, Breitenbach, Rhineland-Palatinate, Germany",
  "44130, Cleveland, Ohio, United States",
  "Greene, Maine, United States",
  "Spink County, South Dakota, United States",
  "Tiksi, Sakha, Russia",
  "15628, Donegal, Pennsylvania, United States",
  "Mytishchi, Moscow, Russia",
  "Greater Trnava Area",
  "6224 HH, Maastricht, Limburg, Netherlands",
  "50-142, Wrocław, Dolnośląskie, Poland",
  "60325, Frankfurt, Hesse, Germany",
  "Knoxville, Iowa, United States",
  "Harmony, Ohio, United States",
  "Nueces County, Texas, United States",
  "Laurens, Pondaurat, Nouvelle-Aquitaine, France",
  "31315, Fort Stewart, Georgia, United States",
  "63877, Steele, Missouri, United States",
  "Aberdeen, Ohio, United States",
  "Wallerfing, Bavaria, Germany",
  "Barton, Newport, England, United Kingdom",
  "Burrell, Pennsylvania, United States",
  "30322, Kohtla-Järve, Ida-Virumaa, Estonia",
  "358012, Elista, Kalmykia, Russia",
  "Bradford, England, United Kingdom",
  "353660, Yeysk, Krasnodar, Russia",
  "Northwest Koochiching, Minnesota, United States",
  "Granite Quarry, North Carolina, United States",
  "Potiraguá, Bahia, Brazil",
  "L’Aquila, Abruzzi, Italy",
  "Lenskiy Rayon, Arkhangel’sk, Russia",
  "Grykë-Çajë, Kukës, Albania",
  "Obuasi Municipal District, Ashanti Region, Ghana",
  "25-011, Kielce, Świętokrzyskie, Poland",
  "Greater Danville, IL Area",
  "3100-409, Pombal, Leiria, Portugal",
  "457353, Kartaly, Chelyabinsk, Russia",
  "Obshtina Galabovo, Stara Zagora, Bulgaria",
  "7370-122, Campo Maior, Portalegre, Portugal",
  "1020, Brussels, Brussels Region, Belgium",
  "52033, Cascade, Iowa, United States",
  "130082, Târgovişte, Dîmboviţa, Romania",
  "06680, Ankara, Ankara, Türkiye",
  "Marshall, Minnesota, United States",
  "Fraser Valley, British Columbia, Canada",
  "652 22, Karlstad, Värmland County, Sweden",
  "Ocean View, Delaware, United States",
  "446637, Arzamastsevka, Samara, Russia",
  "Karasuk, Novosibirsk, Russia",
  "Ag. Nikolaos Soleas, Nicosia, Cyprus",
  "678530, Verkhoyansk, Sakha, Russia",
  "Arrondissement of Tournai-Mouscron, Walloon Region, Belgium",
  "Pottawattamie County, Iowa, United States",
  "249040, Obninsk, Kaluga, Russia",
  "59252, Marquette-en-Ostrevant, Hauts-de-France, France",
  "Gothenburg, Västra Götaland County, Sweden",
  "Greater Messina Metropolitan Area",
  "89121, Reggio di Calabria, Calabria, Italy",
  "65772, Washburn, Missouri, United States",
  "Córdoba, Andalusia, Spain",
  "Las Alfalfas, Michoacán, Mexico",
  "Perkinsville, Vermont, United States",
  "3511, Hasselt, Flemish Region, Belgium",
  "Isparta, Türkiye",
  "Norfolk City County, Virginia, United States",
  "52300, Mikkeli, South Savo, Finland",
  "2827, Gjøvik, Innlandet, Norway",
  "423814, Naberezhnyye Chelny, Tatarstan, Russia",
  "37103, Panevėžys, Panevėžio, Lithuania",
  "48423, Davison, Michigan, United States",
  "Haywood, County Tipperary, Ireland",
  "13210, Hämeenlinna, Kanta-Häme, Finland",
  "Inishcrone, County Sligo, Ireland",
  "Yessentuki, Stavropol, Russia",
  "Lubeck, West Virginia, United States",
  "East Griggs, Arkansas, United States",
  "43008, Tarragona, Catalonia, Spain",
  "Franklin County, Washington, United States",
  "Chouteau, Oklahoma, United States",
  "Pershing Oaks, Conway, Florida, United States",
  "NR25 6PG, West Beckham, England, United Kingdom",
  "64081, Lees Summit, Missouri, United States",
  "98440, Kemijärvi, Lapland, Finland",
  "Barnaul, Altai Krai, Russia",
  "346132, Millerovo, Rostov, Russia",
  "49328, Hopkins, Michigan, United States",
  "Glen Mills, Pennsylvania, United States",
  "Dnipro Metropolitan Area",
  "Navarro County, Texas, United States",
  "3660, Kerrisdale, Victoria, Australia",
  "185 31, Piraeus, Attiki, Greece",
  "Lorain, Ohio, United States",
  "69068, Zaporizhzhya, Zaporizhzhya, Ukraine",
  "Floydada, Texas, United States",
  "692921, Nakhodka, Primorye, Russia",
  "Patrick Green, Leeds, England, United Kingdom",
  "79102, Prijedor, Serb Republic, Bosnia and Herzegovina",
  "Miller County, Arkansas, United States",
  "Cambridge, New York, United States",
  "6602, Padre Burgos, Eastern Visayas, Philippines",
  "Camp Oconto, South Frontenac, Ontario, Canada",
  "Rovaniemi, Lapland, Finland",
  "Oneida, Pennsylvania, United States",
  "44227, Dortmund, North Rhine-Westphalia, Germany",
  "35210, İzmir, İzmir, Türkiye",
  "670002, Ulan-Ude, Buryatia, Russia",
  "Klingnau, Aargau, Switzerland",
  "Savanna-La-Mar, Westmoreland, Jamaica",
  "Barbourne, Worcester, England, United Kingdom",
  "78336, Aransas Pass, Texas, United States",
  "Srednekolymsky District, Sakha, Russia",
  "74434, Fort Gibson, Oklahoma, United States",
  "25-516, Kielce, Świętokrzyskie, Poland",
  "90746, Carson, California, United States",
  "8608, Mo i Rana, Nordland, Norway",
  "Barry County, Michigan, United States",
  "23265, Zrenjanin, Vojvodina, Serbia",
  "9901, Kirkenes, Troms og Finnmark, Norway",
  "48180, Taylor, Michigan, United States",
  "Tatarskaya Laka, Penza, Russia",
  "24125, Bergamo, Lombardy, Italy",
  "50002, Adair, Iowa, United States",
  "48192, Wyandotte, Michigan, United States",
  "617072, Krasnokamsk, Perm, Russia",
  "Lancaster, Wisconsin, United States",
  "61-249, Poznan, Wielkopolskie, Poland",
  "65-548, Zielona Góra, Lubuskie, Poland",
  "62629, Chatham, Illinois, United States",
  "Neubrandenburg, Mecklenburg-West Pomerania, Germany",
  "8801, Sliven, Sliven, Bulgaria",
  "45130, Kouvola, Kymenlaakso, Finland",
  "Provincia General Bernardino Bilbao Rioja, Potosí, Bolivia",
  "623391, Polevskoy, Sverdlovsk, Russia",
  "Bosque Apoquindo, Las Condes, Santiago Metropolitan Region, Chile",
  "Stokes County, North Carolina, United States",
  "S6 1AA, Sheffield, England, United Kingdom",
  "Floyd County, Georgia, United States",
  "4551, Nyíregyháza, Szabolcs-Szatmár-Bereg, Hungary",
  "Caldwell County, Missouri, United States",
  "Metztitlán, Hidalgo, Mexico",
  "79300-002, Corumbá, Mato Grosso do Sul, Brazil",
  "McKinley, Missouri, United States",
  "Galliani, Ripa Teatina, Abruzzi, Italy",
  "McLean, Texas, United States",
  "Walker County, Georgia, United States",
  "Cotton, England, United Kingdom",
  "68193-959, Novo Progresso, Pará, Brazil",
  "Ulster, Pennsylvania, United States",
  "Fredericksburg City County, Virginia, United States",
  "Gallatin County, Montana, United States",
  "346400, Novocherkassk, Rostov, Russia",
  "Arthur, North Carolina, United States",
  "Stafford-Albemarle Glebe, Virginia, United States",
  "Oktyabr'skiy, Lipetsk, Russia",
  "42647, Stearns, Kentucky, United States",
  "307751, Lgov, Kursk, Russia",
  "04282, Turner, Maine, United States",
  "97319, Awala-Yalimapo, Saint-Laurent-du-Maroni, French Guiana",
  "Gorodskoy Okrug Kovrov, Vladimir, Russia",
  "Bradley, South Carolina, United States",
  "9381, Finnsnes, Troms og Finnmark, Norway",
  "Greater Asheville",
  "Corroccioni, Cagnano Amiterno, Abruzzi, Italy",
  "Gayam, East Java, Indonesia",
  "Ulster County, New York, United States",
  "21201, Novi Sad, Vojvodina, Serbia",
  "48843, Howell, Michigan, United States",
  "Dorchester, Boston, Massachusetts, United States",
  "LU1 3LU, Luton, England, United Kingdom",
  "Clermont, Kentucky, United States",
  "53209, Milwaukee, Wisconsin, United States",
  "26008, Logroño, Autonomía de La Rioja, Spain",
  "Greater Pleven Area",
  "Harrisburg, Pennsylvania, United States",
  "18706, Wilkes-Barre, Pennsylvania, United States",
  "54890, Wascott, Wisconsin, United States",
  "Cortaillod, Neuchâtel, Switzerland",
  "Trancoso, Guarda, Portugal",
  "Stafford, Texas, United States",
  "Paredes de Coura, Viana do Castelo, Portugal",
  "Henderson, North Carolina, United States",
  "37200, Kastamonu, Kastamonu, Türkiye",
  "Mezhdurechensk, Kemerovo, Russia",
  "Comăneşti, Bacău, Romania",
  "BH2 6PB, Bournemouth, England, United Kingdom",
  "Uncha, Bryansk, Russia",
  "663824, Kansk, Krasnoyarsk Krai, Russia",
  "Melitopol, Zaporizhzhya, Ukraine",
  "Booneville, Arkansas, United States",
  "Bradford, Illinois, United States",
  "Greenwood, Indiana, United States",
  "Vernier, Geneva, Switzerland",
  "Richmond, Lehigh Acres, Florida, United States",
  "22538, Rappahannock Academy, Virginia, United States",
  "S0K 2A0, Humboldt, Saskatchewan, Canada",
  "Cabarrus Crossing, Odell School, North Carolina, United States",
  "B0N 0A8, Meaghers Grant, Nova Scotia, Canada",
  "32177, Palatka, Florida, United States",
  "Caddo Heights/South Highlands, Shreveport, Louisiana, United States",
  "Mount Hood, Oregon, United States",
  "353690, Yeysk, Krasnodar, Russia",
  "Baker, Montana, United States",
  "80035, Pärnu, Parnu, Estonia",
  "33002, Oviedo, Principality of Asturias, Spain",
  "Kanepokhari Rural Municipality, Nepal",
  "Banska Bystrica, Slovakia",
  "Oktyabrsky District, Birobijan, Russia",
  "692511, Ussuriysk, Primorye, Russia",
  "Wadenapur, Hirekerur, Karnataka, India",
  "399784, Yelets, Lipetsk, Russia",
  "Sawyer, Kootenay Boundary E/West Boundary, British Columbia, Canada",
  "Kirkby-Le-Soken, England, United Kingdom",
  "Maury, Salon-la-Tour, Nouvelle-Aquitaine, France",
  "34370, Cazouls-lès-Béziers, Occitanie, France",
  "44871, Sandusky, Ohio, United States",
  "Webbwood, Ontario, Canada",
  "53211, Milwaukee, Wisconsin, United States",
  "Kursky District, Kursk, Russia",
  "647501, Dudinka, Krasnoyarsk Krai, Russia",
  "Fălticeni, Suceava, Romania",
  "46590, Winona Lake, Indiana, United States",
  "Kyzyl-Mazhalykskiy, Tuva, Russia",
  "5534, Haugesund, Rogaland, Norway",
  "Buffalo Creek, Northern Territory, Australia",
  "Hall Green, Birmingham, England, United Kingdom",
  "07827, Montague, New Jersey, United States",
  "Maury County, Tennessee, United States",
  "Nyeri, Nyeri, Kenya",
  "05800, Amasya, Amasya, Türkiye",
  "662172, Achinsk, Krasnoyarsk Krai, Russia",
  "54154, Oconto Falls, Wisconsin, United States",
  "Miner County, South Dakota, United States",
  "Alapayevsky District, Sverdlovsk, Russia",
  "Saint Pierre and Miquelon",
  "95503, Eureka, California, United States",
  "Highland Falls, New York, United States",
  "28217, Charlotte, North Carolina, United States",
  "413121, Engels, Saratov, Russia",
  "22320, Ružić, Sibenik-Knin, Croatia",
  "Chalkida, Central Greece, Greece",
  "Kovrovsky District, Vladimir, Russia",
  "Sarana, Sverdlovsk, Russia",
  "427967, Sarapul, Udmurtia, Russia",
  "75208, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "49913, Calumet, Michigan, United States",
  "Bari Sadri, Rajasthan, India",
  "SA1 1AF, Swansea, Wales, United Kingdom",
  "Bakal, Chelyabinsk, Russia",
  "Whitman, Spokane, Washington, United States",
  "Cottonwood, Idaho, United States",
  "Las Vegas Metropolitan Area",
  "70563, Stuttgart, Baden-Württemberg, Germany",
  "666769, Ust-Kut, Irkutsk, Russia",
  "Kotel’nich, Kirov, Russia",
  "Phillips County, Colorado, United States",
  "59020, Cooke City, Montana, United States",
  "Mykolaiv Raion, Mykolayiv, Ukraine",
  "Voznesensk Raion, Mykolayiv, Ukraine",
  "623131, Pervoural'sk, Sverdlovsk, Russia",
  "Radford City County, Virginia, United States",
  "Vila Nova de Gaia, Porto, Portugal",
  "58104, Fargo, North Dakota, United States",
  "Baba Bakala, India",
  "676454, Svobodnyy, Amur, Russia",
  "4052, Münchenstein, Basel-Country, Switzerland",
  "Lac la Biche County, Alberta, Canada",
  "Lugasson, Nouvelle-Aquitaine, France",
  "03087, Windham, New Hampshire, United States",
  "Yeniseyskiy, Altai Krai, Russia",
  "Versailles, Illinois, United States",
  "4680, Harding, KwaZulu-Natal, South Africa",
  "94700, Kemi, Lapland, Finland",
  "654007, Novokuznetsk, Kemerovo, Russia",
  "428037, Cheboksary, Chuvashia, Russia",
  "Faulkner Ridge, South Carolina, United States",
  "R13C9T9, Beyneu, Mangystau Region, Kazakhstan",
  "613045, Kirovo-Chepetsk, Kirov, Russia",
  "Kaunas, Kauno, Lithuania",
  "23433, Suffolk, Virginia, United States",
  "Harney County, Oregon, United States",
  "K0A 0C2, Limoges, Ontario, Canada",
  "97036, Lake Oswego, Oregon, United States",
  "Topkinskoye, Buryatia, Russia",
  "Biryusinskoye, Irkutsk, Russia",
  "97032, Hubbard, Oregon, United States",
  "23554, Lübeck, Schleswig-Holstein, Germany",
  "8512, Narvik, Nordland, Norway",
  "Valle del Cauca, Colombia",
  "Coos North, County Galway, Ireland",
  "32765, Oviedo, Florida, United States",
  "Gorodskoy Okrug Obninsk, Kaluga, Russia",
  "65459, Dixon, Missouri, United States",
  "Drohobych Raion, Lviv, Ukraine",
  "Nantes, São Paulo, Brazil",
  "97190, Le Gosier, Pointe-à-Pitre, Guadeloupe",
  "Chariton, Iowa, United States",
  "Slobodskoye, Yaroslavl’, Russia",
  "Roque Gonzales, Rio Grande do Sul, Brazil",
  "Cattle King Estates, Choctaw Valley, California, United States",
  "30722, Kohtla-Järve, Ida-Virumaa, Estonia",
  "Örenyurt, Sivas, Türkiye",
  "02094, Kyiv, Kyiv City, Ukraine",
  "Klyuchi, Samara, Russia",
  "Ross, Pennsylvania, United States",
  "Morton County, North Dakota, United States",
  "Gallia, Pieve del Cairo, Lombardy, Italy",
  "Smolenskoye, Altai Krai, Russia",
  "Nogliksky District, Sakhalin, Russia",
  "681, Mannheim, Baden-Württemberg, Germany",
  "Binney Wirt Spencer, Omaha, Nebraska, United States",
  "Obshtina Isperih, Razgrad, Bulgaria",
  "BT12 4AA, Belfast, Northern Ireland, United Kingdom",
  "Henderson, Tennessee, United States",
  "428000, Cheboksary, Chuvashia, Russia",
  "Lucas do Rio Verde, Mato Grosso, Brazil",
  "Sutton, Nebraska, United States",
  "Antalya, Türkiye",
  "Socorro, Caraga, Philippines",
  "98420, Kemijärvi, Lapland, Finland",
  "2410, Burgos, Ilocos Region, Philippines",
  "Wayne, Michigan, United States",
  "Chambers, Arizona, United States",
  "West Midlands, England, United Kingdom",
  "Meurthe-et-Moselle, Grand Est, France",
  "03112, Alicante, Valencian Community, Spain",
  "666925, Artëmovskiy, Irkutsk, Russia",
  "Kent County, Maryland, United States",
  "02030, Adıyaman, Adıyaman, Türkiye",
  "Angelina County, Texas, United States",
  "14538, Williamson, New York, United States",
  "222304, Maladzyechna, Minsk, Belarus",
  "LV-4630, Malta, Rezekne, Latvia",
  "Tatabánya, Hungary",
  "Olmsted, Illinois, United States",
  "403122, Kotovskiy, Volgograd, Russia",
  "Faulkland, New South Wales, Australia",
  "41122, Modena, Emilia-Romagna, Italy",
  "Faro, Faro, Portugal",
  "Mellette, South Dakota, United States",
  "Oslo, Minnesota, United States",
  "Cockey Moor, Bury, England, United Kingdom",
  "452684, Neftekamsk, Bashkortostan, Russia",
  "George Town, George Town, Cayman Islands",
  "PE28 0DB, Huntingdon, England, United Kingdom",
  "74425, Canadian, Oklahoma, United States",
  "36204, Vigo, Galicia, Spain",
  "Mullinavat, County Kilkenny, Ireland",
  "84087, Woods Cross, Utah, United States",
  "Schuylerville, New York, United States",
  "Lüleburgaz, Kırklareli, Türkiye",
  "Straccarella, Ponderano, Piedmont, Italy",
  "Val-de-Ruz, Neuchâtel, Switzerland",
  "LV-3003, Līvbērze, Jelgava Municipality, Latvia",
  "49220, Addison, Michigan, United States",
  "LU4 8EU, Luton, England, United Kingdom",
  "4528, Zuchwil, Solothurn, Switzerland",
  "Blaine County, Montana, United States",
  "628013, Khanty-Mansiysk, Khanty-Mansi, Russia",
  "SO41 8AL, Lower Pennington, England, United Kingdom",
  "Gales Ferry, Connecticut, United States",
  "8615, Mo i Rana, Nordland, Norway",
  "Dunn Loring, Virginia, United States",
  "4362, Berat, Queensland, Australia",
  "623111, Pervoural'sk, Sverdlovsk, Russia",
  "Lekebergs kommun, Örebro County, Sweden",
  "Kamyshin, Volgograd, Russia",
  "Virginia Beach City County, Virginia, United States",
  "59347, Rosebud, Montana, United States",
  "99821, Juneau, Alaska, United States",
  "BN1 1NE, Brighton, England, United Kingdom",
  "Sovetsky District, Altai Krai, Russia",
  "Shadrinskiy Rayon, Kurgan, Russia",
  "57123, Livorno, Tuscany, Italy",
  "56378, Sauk Centre, Minnesota, United States",
  "29000, Gumushane, Gümüşhane, Türkiye",
  "Agen, Nouvelle-Aquitaine, France",
  "Steinfeld, Lower Saxony, Germany",
  "Pavlikeni Municipality, Veliko Turnovo, Bulgaria",
  "Swisher County, Texas, United States",
  "683031, Petropavlovsk-Kamchatskiy, Kamchatka, Russia",
  "Handcross, England, United Kingdom",
  "683038, Petropavlovsk-Kamchatskiy, Kamchatka, Russia",
  "Burleigh, Queensland, Australia",
  "01204, Vilnius, Vilniaus, Lithuania",
  "Norman Gardens, Queensland, Australia",
  "68005-110, Santarém, Pará, Brazil",
  "8050, Zurich, Zurich, Switzerland",
  "6271, Forrest Beach, Western Australia, Australia",
  "Hood Green, England, United Kingdom",
  "Greater Bordeaux Metropolitan Area",
  "663921, Uyar, Krasnoyarsk Krai, Russia",
  "620085, Yekaterinburg, Sverdlovsk, Russia",
  "L01P5B0, Oral, West Kazakhstan Region, Kazakhstan",
  "Marmara Ereğlisi, Tekirdağ, Türkiye",
  "Reagan, San Angelo, Texas, United States",
  "IP32 7AB, Bury St Edmunds, England, United Kingdom",
  "Campina Grande, Paraíba, Brazil",
  "GU10 4LW, Bucks Horn Oak, England, United Kingdom",
  "7306, Pogradec, Korçë, Albania",
  "F43G6B7, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "Woodstock, New Brunswick, Canada",
  "Pereslegino, Pskov, Russia",
  "A0K 4H0, Port Saunders, Newfoundland and Labrador, Canada",
  "Lipjan, District of Pristina, Kosovo",
  "Pinellas Park, Florida, United States",
  "Lidskiy gorodskoy sovet, Hrodna, Belarus",
  "Granville, Western Australia, Australia",
  "44044, Grafton, Ohio, United States",
  "Sabinera, Jalisco, Mexico",
  "79090, Kilis, Kilis, Türkiye",
  "37037, Christiana, Tennessee, United States",
  "Coffee County, Tennessee, United States",
  "BH9 3SA, Bournemouth, England, United Kingdom",
  "North Powder, Oregon, United States",
  "Newcastle, Oklahoma, United States",
  "20520, Turku, Southwest Finland, Finland",
  "08032, Makarov, Kyiv, Ukraine",
  "Santa Clarita, California, United States",
  "6824 AA, Arnhem, Gelderland, Netherlands",
  "66292, Tulungagung, East Java, Indonesia",
  "Manavgat Bucağı, Antalya, Türkiye",
  "22042, Falls Church, Virginia, United States",
  "Attala County, Mississippi, United States",
  "Puerto Rico",
  "56600, Lanester, Brittany, France",
  "Ottawa County, Ohio, United States",
  "Nicholashayne, England, United Kingdom",
  "Gjirokastër, Albania",
  "Elk, California, United States",
  "Rains, Joncy, Bourgogne-Franche-Comté, France",
  "Fauquier, British Columbia, Canada",
  "30014, Covington, Georgia, United States",
  "Niobrara, Nebraska, United States",
  "Arlington, Texas, United States",
  "Yazckonak, Elazığ, Türkiye",
  "Port Allegany, Pennsylvania, United States",
  "Steele, North Dakota, United States",
  "Old Sevier, Knoxville, Tennessee, United States",
  "610240, Piatra Neamţ, Neamţ, Romania",
  "Shemonaikha District, East Kazakhstan Region, Kazakhstan",
  "64082, Lees Summit, Missouri, United States",
  "37081, Göttingen, Lower Saxony, Germany",
  "Realeza, Paraná, Brazil",
  "East Ayrshire, Scotland, United Kingdom",
  "Wood Dale, Illinois, United States",
  "Weberlis, Bad Wurzach, Baden-Württemberg, Germany",
  "346918, Novoshakhtinsk, Rostov, Russia",
  "48854, Mason, Michigan, United States",
  "11201, Algeciras, Andalusia, Spain",
  "Birobidzhan, Birobijan, Russia",
  "Sertã, Castelo Branco, Portugal",
  "Spennells, Kidderminster, England, United Kingdom",
  "Ancona, Victoria, Australia",
  "694230, Poronaysk, Sakhalin, Russia",
  "Socorro, Santander, Colombia",
  "Reynolds, Michigan, United States",
  "623151, Pervoural'sk, Sverdlovsk, Russia",
  "KA18 2NS, Ochiltree, Scotland, United Kingdom",
  "38506, Cookeville, Tennessee, United States",
  "120002, Buzău, Buzău, Romania",
  "7011, Nelson, Nelson, New Zealand",
  "Canary Islands, Spain",
  "Linn, Missouri, United States",
  "Shunyi District, Beijing, China",
  "346516, Shakhty, Rostov, Russia",
  "166004, Naryan Mar, Nenets, Russia",
  "456582, Yemanzhelinsk, Chelyabinsk, Russia",
  "Stuttgart, Baden-Württemberg, Germany",
  "905600, Medgidia, Constanţa, Romania",
  "Yoakum County, Texas, United States",
  "PA16 0XN, Greenock, Scotland, United Kingdom",
  "33647, Bielefeld, North Rhine-Westphalia, Germany",
  "34139, Trieste, Friuli-Venezia Giulia, Italy",
  "Churubusco, Indiana, United States",
  "6836 AA, Arnhem, Gelderland, Netherlands",
  "27879, Simpson, North Carolina, United States",
  "47000, Agen, Nouvelle-Aquitaine, France",
  "Divide Park Society, Surat, Gujarat, India",
  "171076, Bologoye, Tver’, Russia",
  "Hexigten Banner, Inner Mongolia, China",
  "Travis, Alabama, United States",
  "27357, Stokesdale, North Carolina, United States",
  "17200, Çanakkale, Çanakkale, Türkiye",
  "North Bergen, New Jersey, United States",
  "550370, Sibiu, Sibiu, Romania",
  "Trinity, Trinity, Jersey",
  "51010, Tartu, Tartumaa, Estonia",
  "29700, Vélez-Málaga, Andalusia, Spain",
  "665826, Angarsk, Irkutsk, Russia",
  "CO16 0NR, Thorpe-Le-Soken, England, United Kingdom",
  "68144, Omaha, Nebraska, United States",
  "Hasland, Chesterfield, England, United Kingdom",
  "Pulaski, Pennsylvania, United States",
  "Atascosa, Texas, United States",
  "3505, Peqin, Elbasan, Albania",
  "68848, Kearney, Nebraska, United States",
  "352659, Apsheronsk, Krasnodar, Russia",
  "Stephens City, Virginia, United States",
  "7856, Namsos, Trøndelag, Norway",
  "St.-Denis, Île-de-France, France",
  "Galaxia Toluca, México, Mexico",
  "79357, Ralls, Texas, United States",
  "Popeşti, Iaşi, Romania",
  "01157, Dresden, Saxony, Germany",
  "Llantwit Major, Wales, United Kingdom",
  "M22 1TY, Wythenshawe, England, United Kingdom",
  "Monserrato, Sardinia, Italy",
  "St Gallen, St Gallen, Switzerland",
  "Kingfisher, Oklahoma, United States",
  "09125, Cagliari, Sardinia, Italy",
  "50823, Cologne, North Rhine-Westphalia, Germany",
  "Vitoria-Gasteiz, Basque Country, Spain",
  "Montemor-o-Velho, Coimbra, Portugal",
  "La Joséphine, Falleron, Pays de la Loire, France",
  "88-106, Inowrocław, Kujawsko-pomorskie, Poland",
  "Roane, Arkansas, United States",
  "Deer Park, Washington, United States",
  "71220, Bastrop, Louisiana, United States",
  "85307, Pokrovsk, Donetsk, Ukraine",
  "Crooklets, Bude, England, United Kingdom",
  "Granville, North Dakota, United States",
  "5528, Haugesund, Rogaland, Norway",
  "54658, Stoddard, Wisconsin, United States",
  "Alleghany County, Virginia, United States",
  "NR2 2BQ, Norwich, England, United Kingdom",
  "66208, Prairie Village, Kansas, United States",
  "99610, Sodankylä, Lapland, Finland",
  "20716, Bowie, Maryland, United States",
  "27617, Raleigh, North Carolina, United States",
  "Horlivka Metropolitan Area",
  "County Galway, Ireland",
  "Haskell Ridge, Highland City, Florida, United States",
  "Belmonte, Bahia, Brazil",
  "Cass County, Indiana, United States",
  "34982, Fort Pierce, Florida, United States",
  "595, Fort Belknap Agency, Montana, United States",
  "Provincia de Linares, Maule Region, Chile",
  "08020, Barcelona, Catalonia, Spain",
  "9441, Evenskjær, Troms og Finnmark, Norway",
  "8500-802, Portimão, Faro, Portugal",
  "666685, Ust'-Ilimsk, Irkutsk, Russia",
  "Nancefield Extension 2, Musina, Limpopo, South Africa",
  "A92 KPK6, Drogheda, Ireland",
  "Kemiri, Central Java, Indonesia",
  "Cranston, Rhode Island, United States",
  "61360, Seneca, Illinois, United States",
  "Ravenna, Ohio, United States",
  "Monteroni di Lecce, Apulia, Italy",
  "Danebank, Stockport, England, United Kingdom",
  "2988 BL, Ridderkerk, South Holland, Netherlands",
  "18600, Cankiri, Çankırı, Türkiye",
  "15800, Lahti, Päijät-Häme, Finland",
  "981 45, Kiruna, Norrbotten County, Sweden",
  "84511, Blanding, Utah, United States",
  "Big Lake, Minnesota, United States",
  "Bernalillo County, New Mexico, United States",
  "Bertrange, Luxembourg, Luxembourg",
  "6757, Szeged, Csongrád, Hungary",
  "Győrzámoly, Győr-Moson-Sopron, Hungary",
  "Chester, New Hampshire, United States",
  "Timiryazevskiy, Chelyabinsk, Russia",
  "Greater Gyor Area",
  "46062, Noblesville, Indiana, United States",
  "97407, Coos Bay, Oregon, United States",
  "80080, Amiens, Hauts-de-France, France",
  "542 49, Thessaloniki, Central Macedonia, Greece",
  "162625, Cherepovets, Vologda, Russia",
  "97233, Schœlcher, Fort-de-France, Martinique",
  "Presidios de San Nicolás, Durango, Mexico",
  "Brookend, Chipping Norton, England, United Kingdom",
  "Amite, Louisiana, United States",
  "Dewey County, Oklahoma, United States",
  "Gileston, Wales, United Kingdom",
  "5671, Békéscsaba, Békés, Hungary",
  "Valley, Nova Scotia, Canada",
  "8500-430, Portimão, Faro, Portugal",
  "60035, Highland Park, Illinois, United States",
  "Windsor, England, United Kingdom",
  "33659, Bielefeld, North Rhine-Westphalia, Germany",
  "Scurry, Texas, United States",
  "Alexandria, Kentucky, United States",
  "Kurush, Dagestan, Russia",
  "Buckingham, England, United Kingdom",
  "34050, Istanbul, Istanbul, Türkiye",
  "42090, Konya, Konya, Türkiye",
  "Greenbrier, Saratoga, California, United States",
  "666683, Ust'-Ilimsk, Irkutsk, Russia",
  "Red Deer, Alberta, Canada",
  "Ramsey County, North Dakota, United States",
  "81-310, Gdynia, Pomorskie, Poland",
  "Swan Lake, Sumter, South Carolina, United States",
  "5538, Haugesund, Rogaland, Norway",
  "70567, Stuttgart, Baden-Württemberg, Germany",
  "Benton Harbor, Michigan, United States",
  "Caddo Mills, Texas, United States",
  "66121, Saarbrücken, Saarland, Germany",
  "8900-239, Vila Real de Santo António, Faro, Portugal",
  "628151, Igrim, Khanty-Mansi, Russia",
  "974 04, Banská Bystrica, Banska Bystrica, Slovakia",
  "Divide Halli, Madhugiri, Karnataka, India",
  "Harlan Ranch, California, United States",
  "5200, Pavlikeni, Veliko Turnovo, Bulgaria",
  "9022, Győr, Győr-Moson-Sopron, Hungary",
  "Wheeler, Illinois, United States",
  "West Java, Indonesia",
  "184606, Severomorsk, Murmansk, Russia",
  "Municipio Valencia, Carabobo State, Venezuela",
  "L1C 3Z7, Bowmanville, Ontario, Canada",
  "New Ulm, Minnesota, United States",
  "8756, Schwanden, Glarus, Switzerland",
  "74370, Wyandotte, Oklahoma, United States",
  "Campbell County, Kentucky, United States",
  "Lime Acres SP, Daniëlskuil, Northern Cape, South Africa",
  "Richland County, South Carolina, United States",
  "Barry County, Missouri, United States",
  "47167, Duisburg, North Rhine-Westphalia, Germany",
  "647002, Dudinka, Krasnoyarsk Krai, Russia",
  "Lincoln, Rhode Island, United States",
  "NR24 2RP, Edgefield, England, United Kingdom",
  "429127, Shumerlya, Chuvashia, Russia",
  "Preston, Georgia, United States",
  "Le Logis de Nancé, Brecé, Pays de la Loire, France",
  "33009, Hallandale Beach, Florida, United States",
  "City of Cape Town, Western Cape, South Africa",
  "8230, Balatonfüred, Veszprém, Hungary",
  "Cookshire-Eaton, Quebec, Canada",
  "Greer, Arizona, United States",
  "163060, Archangel, Arkhangel’sk, Russia",
  "Bratsk, Irkutsk, Russia",
  "AB55 5LW, Keith, Scotland, United Kingdom",
  "Lebanon, Kentucky, United States",
  "Caldwell, Kansas, United States",
  "142263, Serpukhov, Moscow, Russia",
  "Butte County, South Dakota, United States",
  "652486, Anzhero-Sudzhensk, Kemerovo, Russia",
  "20811, Mikhaylovka, Cherkasy, Ukraine",
  "Modena, Utah, United States",
  "Coronel Xavier Chaves, Minas Gerais, Brazil",
  "44121, Ferrara, Emilia-Romagna, Italy",
  "450010, Zalău, Sălaj, Romania",
  "362025, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "167001, Syktyvkar, Komi, Russia",
  "5301, Kuçovë, Berat, Albania",
  "41-933, Bytom, Śląskie, Poland",
  "Bosque County, Texas, United States",
  "665770, Vikhorevka, Irkutsk, Russia",
  "Jasper, Texas, United States",
  "Shoyna, Nenets, Russia",
  "624481, Severoural’sk, Sverdlovsk, Russia",
  "46552, New Carlisle, Indiana, United States",
  "Bryan, Texas, United States",
  "Mason, Michigan, United States",
  "Campbellsville, Kentucky, United States",
  "DY4 7QH, Tipton, England, United Kingdom",
  "72846, Lamar, Arkansas, United States",
  "Oliver Springs, Tennessee, United States",
  "45740, Kouvola, Kymenlaakso, Finland",
  "71000, Lemseid, Laâyoune-Sakia El Hamra, Morocco",
  "457100, Troitsk, Chelyabinsk, Russia",
  "22030, Edirne, Edirne, Türkiye",
  "Chatham, New Brunswick, Canada",
  "24213, Subotica, Vojvodina, Serbia",
  "76520, Cameron, Texas, United States",
  "B6L 6L1, Onslow Mountain, Nova Scotia, Canada",
  "Brunei",
  "Perugia, Umbria, Italy",
  "40477, Düsseldorf, North Rhine-Westphalia, Germany",
  "Negrești, Vaslui, Romania",
  "Sainte-Sophie, Quebec, Canada",
  "AB55 6QS, Keith, Scotland, United Kingdom",
  "7309, Pogradec, Korçë, Albania",
  "Ust’-Ordynskiy, Ust-Ordyn-Buryat, Russia",
  "Dewey, Bolinao, Ilocos Region, Philippines",
  "662155, Achinsk, Krasnoyarsk Krai, Russia",
  "152919, Rybinsk, Yaroslavl’, Russia",
  "Kirktown Of Mortlach, Keith, Scotland, United Kingdom",
  "Hopewell, Richmond, KwaZulu-Natal, South Africa",
  "Mikkeli, South Savo, Finland",
  "53393, Pokrovske, Dnipropetrovsk, Ukraine",
  "412314, Balashov, Saratov, Russia",
  "L10F4P2, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Cúcuta Metropolitan Area",
  "Henderson County, Tennessee, United States",
  "75701, Tyler, Texas, United States",
  "60128, Ancona, Marches, Italy",
  "Hancock County, Ohio, United States",
  "51230, Kaunas, Kauno, Lithuania",
  "Roanoke County, Virginia, United States",
  "Winnebago County, Wisconsin, United States",
  "Millard County, Utah, United States",
  "Kingholm Quay, Dumfries, Scotland, United Kingdom",
  "Oldham, England, United Kingdom",
  "Kamenka, Kirovohrad, Ukraine",
  "Greater Nice Metropolitan Area",
  "0168, Oslo, Oslo, Norway",
  "652337, Topki, Kemerovo, Russia",
  "C25A8T9, Makinsk, Akmola Region, Kazakhstan",
  "628406, Surgut, Khanty-Mansi, Russia",
  "SA3 4RP, Caswell, Wales, United Kingdom",
  "Archer, Florida, United States",
  "Jardim Kenedy II, Poços de Caldas, Minas Gerais, Brazil",
  "Barry, Minnesota, United States",
  "Qendër Vlorë, Vlorë, Albania",
  "Uryupinsk, Volgograd, Russia",
  "169310, Ukhta, Komi, Russia",
  "360017, Nalchik, Kabardino-Balkaria, Russia",
  "McKenzie Bench, Kelowna, British Columbia, Canada",
  "181331, Vorontsovo, Pskov, Russia",
  "6401, Molde, Møre og Romsdal, Norway",
  "690003, Vladivostok, Primorye, Russia",
  "Horad Homyel’, Homel, Belarus",
  "Emporia, Kansas, United States",
  "40132, Bologna, Emilia-Romagna, Italy",
  "PO1 1AA, Portsmouth, England, United Kingdom",
  "West Oak, Gentry, Arkansas, United States",
  "Jalilabad District, Lankaran-Astara, Azerbaijan",
  "606000, Dzerzhinsk, Nizhniy Novgorod, Russia",
  "254 76, Helsingborg, Skåne County, Sweden",
  "90129, Palermo, Sicily, Italy",
  "Kenosha Crossing, Broken Arrow, Oklahoma, United States",
  "san nicolas de presidio presidio de arriba, Presidios de San Nicolás, Durango, Mexico",
  "Borovichi, Novgorod, Russia",
  "Jasper, Arkansas, United States",
  "300 15, Agrínio, Western Greece, Greece",
  "Wallerfangen, Saarland, Germany",
  "H34F8C0, Qulan, Jambyl Region, Kazakhstan",
  "36500, Kars, Kars, Türkiye",
  "Macon County, Tennessee, United States",
  "Newberry, Indiana, United States",
  "ST16 1AA, Stafford, England, United Kingdom",
  "PH33 6AA, Fort William, Scotland, United Kingdom",
  "653014, Prokopyevsk, Kemerovo, Russia",
  "583 30, Linköping, Östergötland County, Sweden",
  "663923, Uyar, Krasnoyarsk Krai, Russia",
  "Ralls Island, Kelsey, Manitoba, Canada",
  "49684, Traverse City, Michigan, United States",
  "Queenstown - Fort Ordance - Smythfield, East Berbice-Corentyne, Guyana",
  "Cocos (Keeling) Islands",
  "Gatchina, Leningrad, Russia",
  "42071, Murray, Kentucky, United States",
  "Labinskiy Rayon, Krasnodar, Russia",
  "Blairgowrie, Victoria, Australia",
  "37849, Powell, Tennessee, United States",
  "Kherson Raion, Kherson, Ukraine",
  "Zabrat, Baku Ekonomic Zone, Azerbaijan",
  "Khrisoúpolis, Eastern Macedonia and Thrace, Greece",
  "Ashland, Mississippi, United States",
  "Duval County, Texas, United States",
  "Walland, Tennessee, United States",
  "Lengerich Dorf, Lower Saxony, Germany",
  "Talgar, Almaty Region, Kazakhstan",
  "Gays Mills, Wisconsin, United States",
  "46006, Valencia, Valencian Community, Spain",
  "Barnes, England, United Kingdom",
  "F42H3G6, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "88202, Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Sovetskiy, Crimea, Ukraine",
  "Opole Lubelskie, Lubelskie, Poland",
  "Klyuchi-Bulakskoye, Irkutsk, Russia",
  "Finiq, Vlorë, Albania",
  "E7N 2C1, McKenzie Corner, New Brunswick, Canada",
  "309992, Valuyki, Belgorod, Russia",
  "Ul'yanovskoye, Tver’, Russia",
  "Bongcheon-dong, Seoul, South Korea",
  "220007, Minsk, Minsk, Belarus",
  "356421, Blagodarnyy, Stavropol, Russia",
  "NN15 5TE, Burton Latimer, England, United Kingdom",
  "686440, Omsukchan, Magadan, Russia",
  "Lezhë, Albania",
  "Tazewell, Virginia, United States",
  "Augsburg-Universitätsviertel, Bavaria, Germany",
  "28370, Pori, Satakunta, Finland",
  "Pike County, Arkansas, United States",
  "Bennett, Colorado, United States",
  "656050, Barnaul, Altai Krai, Russia",
  "Tateyama, Chiba, Japan",
  "22700, Saint-Quay-Perros, Brittany, France",
  "KW15 1LX, Kirkwall, Scotland, United Kingdom",
  "422542, Zelënodol'sk, Mari El, Russia",
  "60-687, Poznan, Wielkopolskie, Poland",
  "453302, Kumertau, Bashkortostan, Russia",
  "19055, Schwerin, Mecklenburg-West Pomerania, Germany",
  "Sheboygan Metropolitan Area",
  "265 00, Patras, Western Greece, Greece",
  "19170, Philadelphia, Pennsylvania, United States",
  "41125, Modena, Emilia-Romagna, Italy",
  "Stone Creek, Rockwall, Texas, United States",
  "30818, Lorca, Región de Murcia, Spain",
  "Haryana, India",
  "65123, Pescara, Abruzzi, Italy",
  "S25C0D1, Ertis, Pavlodar Region, Kazakhstan",
  "140492, Kolomna, Moscow, Russia",
  "Middlesex, Tasmania, Australia",
  "E28E1K7, Makhambet, Atyrau Region, Kazakhstan",
  "Rybinsk, Yaroslavl’, Russia",
  "03140, Guardamar del Segura, Valencian Community, Spain",
  "662547, Lesosibirsk, Krasnoyarsk Krai, Russia",
  "28332, Bladenboro, North Carolina, United States",
  "165308, Kotlas, Arkhangel’sk, Russia",
  "Marathon, New York, United States",
  "160400, Kentau, Turkistan Region, Kazakhstan",
  "M28E3K1, Temirtau, Karaganda Region, Kazakhstan",
  "Winchester, Tennessee, United States",
  "Spinkwang, Schwesing, Schleswig-Holstein, Germany",
  "185 41, Piraeus, Attiki, Greece",
  "Pennington, Lymington, England, United Kingdom",
  "4614, Kristiansand, Agder, Norway",
  "Aurora, Ontario, Canada",
  "80-860, Gdańsk, Pomorskie, Poland",
  "658041, Novoaltaysk, Altai Krai, Russia",
  "Rostov-on-Don Metropolitan Area",
  "Hancock, Maryland, United States",
  "856, Sundsvall, Västernorrland County, Sweden",
  "Brooke, England, United Kingdom",
  "45429, Dayton, Ohio, United States",
  "Jasper, Ontario, Canada",
  "Cumberland County, Virginia, United States",
  "Sanborn, North Dakota, United States",
  "Palana, Kamchatka, Russia",
  "E25C2C3, Maqat, Atyrau Region, Kazakhstan",
  "VLT 1102, Valletta, Valletta, Malta",
  "70176, Stuttgart, Baden-Württemberg, Germany",
  "Greater Philadelphia",
  "678188, Udachnyy, Sakha, Russia",
  "142190, Troitsk, Moscow, Russia",
  "Sovetsk, Kirov, Russia",
  "Payne, Horton, Ontario, Canada",
  "Bruges Metropolitan Area",
  "542 95, Mariestad, Västra Götaland County, Sweden",
  "623105, Pervoural'sk, Sverdlovsk, Russia",
  "50400, Granville, Normandy, France",
  "Furnas, Piedade, São Paulo, Brazil",
  "Granville, Massachusetts, United States",
  "Berkeley, New Jersey, United States",
  "Huntley, New South Wales, Australia",
  "673731, Mogocha, Zabaykalsky Krai, Russia",
  "Miasskoye, Chelyabinsk, Russia",
  "04952, Morrill, Maine, United States",
  "LV-1021, Riga, Riga, Latvia",
  "Bor, Niğde, Türkiye",
  "4404, Fushë-Arrëz, Shkodër, Albania",
  "456200, Zlatoust, Chelyabinsk, Russia",
  "44437, McDonald, Ohio, United States",
  "Makhnëvo, Sverdlovsk, Russia",
  "53230, Astillé, Pays de la Loire, France",
  "212 16, Malmo, Skåne County, Sweden",
  "42105, Wuppertal, North Rhine-Westphalia, Germany",
  "Woodward, Pennsylvania, United States",
  "14057, Berlin, Berlin, Germany",
  "01519, Grafton, Massachusetts, United States",
  "666684, Ust'-Ilimsk, Irkutsk, Russia",
  "15738, Northern Cambria, Pennsylvania, United States",
  "46919, Converse, Indiana, United States",
  "212030, Mahilyow, Mahilyow, Belarus",
  "89010, Dyer, Nevada, United States",
  "Tate, Georgia, United States",
  "IP31 3GB, Thurston, England, United Kingdom",
  "Horlivka Raion, Donetsk, Ukraine",
  "Kirsovo, Moldova",
  "Rome, Georgia, United States",
  "Gubkinskiy, Yamalo-Nenets, Russia",
  "12640, Concho, Junín, Peru",
  "00139, Rome, Latium, Italy",
  "5253, Murray Bridge, South Australia, Australia",
  "309995, Valuyki, Belgorod, Russia",
  "431787, Krasino, Mordovia, Russia",
  "Vila Angelina, Contagem, Minas Gerais, Brazil",
  "23513, Norfolk, Virginia, United States",
  "Tyumensky District, Tyumen’, Russia",
  "360024, Nalchik, Kabardino-Balkaria, Russia",
  "Sovetskoye, Altai Krai, Russia",
  "44147, Dortmund, North Rhine-Westphalia, Germany",
  "Winsum, Groningen, Netherlands",
  "76208, Denton, Texas, United States",
  "Škofljica, Ljubljana, Slovenia",
  "Omiš, Split-Dalmatia, Croatia",
  "Sainte-Geneviève-des-Bois, Île-de-France, France",
  "Dombarovskiy sel'sovet, Orenburg, Russia",
  "Fort Bend County, Texas, United States",
  "Chippewa County, Minnesota, United States",
  "627759, Ishim, Tyumen’, Russia",
  "Gemeente Bergen op Zoom, North Brabant, Netherlands",
  "Reynolds County, Missouri, United States",
  "Johnsons Mill, West Virginia, United States",
  "Dare County, North Carolina, United States",
  "27712, Durham, North Carolina, United States",
  "8301, Svolvær, Nordland, Norway",
  "Nikolayevskoye, Zabaykalsky Krai, Russia",
  "Walla Walla County, Washington, United States",
  "Stoke Prior, England, United Kingdom",
  "Mahoning County, Ohio, United States",
  "M28H2H8, Temirtau, Karaganda Region, Kazakhstan",
  "Rainsuan, Baleshwar, Odisha, India",
  "03500, Afyonkarahisar, Afyon, Türkiye",
  "Lake Minnehaha Shores, Florida, United States",
  "Hendek, Sakarya, Türkiye",
  "71700, Kırıkkale, Kırıkkale, Türkiye",
  "Weiswampach, Diekirch, Luxembourg",
  "564, Northwest Aitkin, Minnesota, United States",
  "Carterville, Illinois, United States",
  "Lawrence County, Arkansas, United States",
  "McHenry, Mississippi, United States",
  "Waterford, Maine, United States",
  "Forsyth Ganj, Dharamshala, Himachal Pradesh, India",
  "8866, Niederurnen, Glarus, Switzerland",
  "Leyte, Eastern Visayas, Philippines",
  "Darlington, Wisconsin, United States",
  "665001, Tayshet, Irkutsk, Russia",
  "Spodnje Hoče, Maribor, Slovenia",
  "LE5 0AA, Leicester, England, United Kingdom",
  "30033, Decatur, Georgia, United States",
  "Tripp, Uri, Switzerland",
  "Winston, Missouri, United States",
  "Dallas, North Carolina, United States",
  "G43 1RQ, Glasgow, Scotland, United Kingdom",
  "Pavia di Udine, Friuli-Venezia Giulia, Italy",
  "Ljubljana Metropolitan Area",
  "West Palm Beach, Florida, United States",
  "Birmingham, Alabama, United States",
  "Newport, Oregon, United States",
  "68880-000, Chaves, Pará, Brazil",
  "Greenville, Texas, United States",
  "456303, Miass, Chelyabinsk, Russia",
  "Downtown Schenectady, Schenectady, New York, United States",
  "Port Hardy, British Columbia, Canada",
  "Spotsylvania County, Virginia, United States",
  "3508, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Sunflower County, Mississippi, United States",
  "155905, Shuya, Ivanovo, Russia",
  "Esmeralda, Camagüey, Cuba",
  "37300, Joué-lès-Tours, Centre-Val de Loire, France",
  "Menominee South, Oshkosh, Wisconsin, United States",
  "Wardha, Maharashtra, India",
  "926 01, Sereď, Trnava Region, Slovakia",
  "76137, Karlsruhe, Baden-Württemberg, Germany",
  "551002, Mediaş, Sibiu, Romania",
  "Northumberland, New Brunswick, Canada",
  "623979, Tavda, Sverdlovsk, Russia",
  "Eastland Acres 1, Tulsa, Oklahoma, United States",
  "Antelope Heights, Parker, Colorado, United States",
  "43710, Kutahya, Kütahya, Türkiye",
  "3941, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "78801, Uvalde, Texas, United States",
  "06194, Badajoz, Extremadura, Spain",
  "7031, Trondheim, Trøndelag, Norway",
  "Colonial, Contagem, Minas Gerais, Brazil",
  "Gage Park, Chicago, Illinois, United States",
  "Mellette County, South Dakota, United States",
  "Miller, Missouri, United States",
  "18300, Cankiri, Çankırı, Türkiye",
  "Bradley County, Arkansas, United States",
  "74750, Garvin, Oklahoma, United States",
  "Norfolk, Nebraska, United States",
  "Anderson County, Kansas, United States",
  "Carroll County, Georgia, United States",
  "Lamoure, North Dakota, United States",
  "Terrell County, Texas, United States",
  "Watauga, South Dakota, United States",
  "621 56, Visby, Gotland County, Sweden",
  "664020, Irkutsk, Irkutsk, Russia",
  "Moscow City, Russia",
  "Harding Park, New York, New York, United States",
  "Whitman, Nebraska, United States",
  "2803, Tatabánya, Komárom-Esztergom, Hungary",
  "602 26, Norrköping, Östergötland County, Sweden",
  "905900, Ovidiu, Constanţa, Romania",
  "Niagara Falls, New York, United States",
  "Salto, Buenos Aires Province, Argentina",
  "62323, Chambersburg, Illinois, United States",
  "57160, Moulins-lès-Metz, Grand Est, France",
  "Bucaramanga, Santander, Colombia",
  "Motley, Minnesota, United States",
  "584 36, Linköping, Östergötland County, Sweden",
  "Adler, Krasnodar, Russia",
  "Gornye Klyuchi, Primorye, Russia",
  "Greater Karlsruhe Area",
  "Sitía, Crete, Greece",
  "694610, Kholmsk, Sakhalin, Russia",
  "Lewistown, Pennsylvania, United States",
  "Biga, Çanakkale, Türkiye",
  "3350, Lucas, Victoria, Australia",
  "E1A 6W6, Dieppe, New Brunswick, Canada",
  "Forpost-Kargatskiy, Novosibirsk, Russia",
  "415408, Shirala, Maharashtra, India",
  "Stavropol, Russia",
  "Stafford Heights, Queensland, Australia",
  "S21 3YG, Spinkhill, England, United Kingdom",
  "Kourouma, Sikasso, Mali",
  "Lefkoşa Türk Belediyesi, Nicosia, Cyprus",
  "Saint-Calais-du-Désert, Pays de la Loire, France",
  "Slonimskiy gorodskoy sovet, Hrodna, Belarus",
  "Turku sub-region, Southwest Finland, Finland",
  "89121, Las Vegas, Nevada, United States",
  "Barletta-Andria-Trani, Apulia, Italy",
  "St James, New York, United States",
  "Puducherry, India",
  "607228, Arzamas, Nizhniy Novgorod, Russia",
  "Rutherford, Dawn-Euphemia, Ontario, Canada",
  "Marlborough, Connecticut, United States",
  "44145, Dortmund, North Rhine-Westphalia, Germany",
  "Orangeburg County, South Carolina, United States",
  "20113, Manassas, Virginia, United States",
  "89014, Henderson, Nevada, United States",
  "Ironton, Ohio, United States",
  "Arthur, North Dakota, United States",
  "47978, Rensselaer, Indiana, United States",
  "Hardyston, New Jersey, United States",
  "Day County, South Dakota, United States",
  "9480, Harstad, Troms og Finnmark, Norway",
  "S25C4E5, Ertis, Pavlodar Region, Kazakhstan",
  "Washington, Michigan, United States",
  "Klaipėda, Klaipėdos, Lithuania",
  "Milan, Michigan, United States",
  "Limerick, County Limerick, Ireland",
  "5930, Belene, Pleven, Bulgaria",
  "1050, Vienna, Vienna, Austria",
  "5220, Odense, Region of Southern Denmark, Denmark",
  "Daggett Villa, California, United States",
  "Saint-Éloy-la-Glacière, Auvergne-Rhône-Alpes, France",
  "Aqtöbe region, Kazakhstan",
  "Aktau, Mangystau Region, Kazakhstan",
  "Reguengos de Monsaraz, Évora, Portugal",
  "Limestone, New York, United States",
  "90501, Torrance, California, United States",
  "9446, Evenskjær, Troms og Finnmark, Norway",
  "Ray County, Missouri, United States",
  "346428, Novocherkassk, Rostov, Russia",
  "San José De Cúcuta, Norte de Santander, Colombia",
  "Foster-Powell, Portland, Oregon, United States",
  "E9C 1T5, Storeytown, New Brunswick, Canada",
  "Yakutiye, Erzurum, Türkiye",
  "Minneapolis, Minnesota, United States",
  "Sorochinskiy Rayon, Orenburg, Russia",
  "Bonao, Monseñor Nouel, Dominican Republic",
  "31200, Toulouse, Occitanie, France",
  "442891, Serdobsk, Penza, Russia",
  "Chester, New York, United States",
  "Bulancak, Giresun, Türkiye",
  "Dresden, Ontario, Canada",
  "L-8522, Beckerich, Diekirch, Luxembourg",
  "Stevenson, Washington, United States",
  "Ontario, California, United States",
  "Brunswick, Lower Saxony, Germany",
  "5245 AN, ’s-Hertogenbosch, North Brabant, Netherlands",
  "Fierbinţi de Sus, Ialomiţa, Romania",
  "Cedar Falls, Iowa, United States",
  "Cúcuta, Norte de Santander, Colombia",
  "Béja, Tunisia",
  "Vance, Mississippi, United States",
  "Lawrence, New Jersey, United States",
  "Bulgaria",
  "Buckskin, Ohio, United States",
  "55129, Mainz, Rhineland-Palatinate, Germany",
  "Smithfield, Utah, United States",
  "37825, New Tazewell, Tennessee, United States",
  "8624, Mo i Rana, Nordland, Norway",
  "Sokólski, Podlaskie, Poland",
  "100001, Ploieşti, Prahova, Romania",
  "673400, Nerchinsk, Zabaykalsky Krai, Russia",
  "07010, Antalya, Antalya, Türkiye",
  "Lincoln County, Maine, United States",
  "76011, Arlington, Texas, United States",
  "Residencial Dona Tunica, Pará de Minas, Minas Gerais, Brazil",
  "43201, Columbus, Ohio, United States",
  "Lancaster, Texas, United States",
  "79039, Lviv, Lviv, Ukraine",
  "21105, Novi Sad, Vojvodina, Serbia",
  "49930, Hancock, Michigan, United States",
  "01309, Dresden, Saxony, Germany",
  "453120, Sterlitamak, Bashkortostan, Russia",
  "Hughes County, Oklahoma, United States",
  "06260, Putnam, Connecticut, United States",
  "06408, Cheshire, Connecticut, United States",
  "Deeping St Nicholas, England, United Kingdom",
  "665719, Bratsk, Irkutsk, Russia",
  "Hamilton County, Tennessee, United States",
  "A0R 0A2, Churchill Falls, Newfoundland and Labrador, Canada",
  "Fontaine-lès-Dijon, Bourgogne-Franche-Comté, France",
  "Chernihivka, Zaporizhzhya, Ukraine",
  "347942, Taganrog, Rostov, Russia",
  "Joensuu, North Karelia, Finland",
  "1012, Lausanne, Vaud, Switzerland",
  "391430, Sasovo, Ryazan’, Russia",
  "456317, Miass, Chelyabinsk, Russia",
  "Mineral Wells, Texas, United States",
  "Williamsburg, Michigan, United States",
  "Staffordshire, England, United Kingdom",
  "Mangualde, Viseu, Portugal",
  "Sharma, Tabuk, Saudi Arabia",
  "37620, Bristol, Tennessee, United States",
  "28100, Giresun, Giresun, Türkiye",
  "3060, Krivodol, Vratsa, Bulgaria",
  "Kağızman, Kars, Türkiye",
  "Krasnoarmeyskiy, Dagestan, Russia",
  "Marion, Illinois, United States",
  "St. George's, Saint George, Grenada",
  "4616, Kristiansand, Agder, Norway",
  "Cowlitz County, Washington, United States",
  "79537, Nolan, Texas, United States",
  "Irons, Michigan, United States",
  "58781, Sawyer, North Dakota, United States",
  "Rozdolska silska hromada, Zaporizhzhya, Ukraine",
  "33370, Artigues-près-Bordeaux, Nouvelle-Aquitaine, France",
  "Boyd County, Nebraska, United States",
  "44109, Cleveland, Ohio, United States",
  "Lake Havasu City, Arizona, United States",
  "Kurşunlu, Çankırı, Türkiye",
  "Valley Stream, New York, United States",
  "New Mexico, United States",
  "78136, Siauliai, Siauliu, Lithuania",
  "38009, Santa Cruz de Tenerife, Canary Islands, Spain",
  "Aydıncık, İçel, Türkiye",
  "72734, Gentry, Arkansas, United States",
  "694015, Korsakov, Sakhalin, Russia",
  "404121, Volzhskiy, Volgograd, Russia",
  "04131, Almería, Andalusia, Spain",
  "St Andrews, Scotland, United Kingdom",
  "Harland Terrace, Atlanta, Georgia, United States",
  "142403, Noginsk, Moscow, Russia",
  "32223, Čačak, Centralna Srbija, Serbia",
  "756 21, Kateřinice, Zlin, Czechia",
  "Dublin, California, United States",
  "L-7759, Colmar, Diekirch, Luxembourg",
  "Umanskyi raion, Cherkasy, Ukraine",
  "26170, Eskişehir, Eskişehir, Türkiye",
  "618416, Berezniki, Perm, Russia",
  "Antonio Vaca Díez, Beni, Bolivia",
  "Alekseyevka, Saratov, Russia",
  "Comanche County, Kansas, United States",
  "6914, Lugano, Ticino, Switzerland",
  "Dawson, Minnesota, United States",
  "43512, Defiance, Ohio, United States",
  "Perry County, Ohio, United States",
  "Laurens, L’Isle-Jourdain, Occitanie, France",
  "Colerne, England, United Kingdom",
  "Indianapolis, Indiana, United States",
  "17059, Mifflintown, Pennsylvania, United States",
  "Monroe, Michigan, United States",
  "North Clackamas, Oregon, United States",
  "13647, Potsdam, New York, United States",
  "Priyutovka, Kirovohrad, Ukraine",
  "Nodaway, Missouri, United States",
  "658136, Aleysk, Altai Krai, Russia",
  "Santiago de Compostela, Galicia, Spain",
  "21729, Freiburg, Lower Saxony, Germany",
  "28605-000, New Fribourg, Rio de Janeiro, Brazil",
  "Stonewall, Oklahoma, United States",
  "2800-630, Almada, Setúbal, Portugal",
  "PA15 2BX, Greenock, Scotland, United Kingdom",
  "Greater Samsun",
  "Winter Garden, Florida, United States",
  "Lincolnton, North Carolina, United States",
  "Polk, Ohio, United States",
  "175207, Staraya Russa, Novgorod, Russia",
  "618350, Kizel, Perm, Russia",
  "35270, İzmir, İzmir, Türkiye",
  "IP2 0LH, Ipswich, England, United Kingdom",
  "624606, Alapayevsk, Sverdlovsk, Russia",
  "546 31, Thessaloniki, Central Macedonia, Greece",
  "164504, Severodvinsk, Arkhangel’sk, Russia",
  "Forest, Tasmania, Australia",
  "Maykop, Adygea, Russia",
  "E9C 2P2, Storeytown, New Brunswick, Canada",
  "Sundsvall Municipality, Västernorrland County, Sweden",
  "42369, Wuppertal, North Rhine-Westphalia, Germany",
  "Salem, New Jersey, United States",
  "Bonney Lake, Washington, United States",
  "Wincrange, Diekirch, Luxembourg",
  "CV7 7BA, Berkswell, England, United Kingdom",
  "Carlton County, Minnesota, United States",
  "2001, Durrës, Durres, Albania",
  "34128, Trieste, Friuli-Venezia Giulia, Italy",
  "Greater Treviso Metropolitan Area",
  "SA2 0AW, Swansea, Wales, United Kingdom",
  "Clermont, Queensland, Australia",
  "624993, Serov, Sverdlovsk, Russia",
  "Tuapsinsky District, Krasnodar, Russia",
  "Carboneras, Andalusia, Spain",
  "Izmayil, Odessa, Ukraine",
  "Seminole County, Georgia, United States",
  "9900-401, Horta, Portugal",
  "Swift County, Minnesota, United States",
  "Limestone Township, Illinois, United States",
  "ZE1 0TB, Lerwick, Scotland, United Kingdom",
  "692502, Ussuriysk, Primorye, Russia",
  "Condeixa-A-Nova, Coimbra, Portugal",
  "61572, Yates City, Illinois, United States",
  "29906, Beaufort, South Carolina, United States",
  "82140, Joensuu, North Karelia, Finland",
  "Obshtina Pavlikeni, Veliko Turnovo, Bulgaria",
  "Comalapa, Chimaltenango, Guatemala",
  "676460, Svobodnyy, Amur, Russia",
  "Cambria County, Pennsylvania, United States",
  "75-818, Koszalin, Zachodniopomorskie, Poland",
  "Athens, Illinois, United States",
  "5644 CB, Eindhoven, North Brabant, Netherlands",
  "51373, Kaunas, Kauno, Lithuania",
  "423806, Naberezhnyye Chelny, Tatarstan, Russia",
  "Huocheng County, Xinjiang Uygur, China",
  "Saintes, Nouvelle-Aquitaine, France",
  "Sawyerville, Quebec, Canada",
  "673403, Nerchinsk, Zabaykalsky Krai, Russia",
  "Duval, Missouri, United States",
  "70150, Kuopio, Northern Savonia, Finland",
  "8006, Bodø, Nordland, Norway",
  "CA13 0AA, Cockermouth, England, United Kingdom",
  "500018, Hyderabad, Telangana, India",
  "15010, Beaver Falls, Pennsylvania, United States",
  "15-753, Białystok, Podlaskie, Poland",
  "45720, Kouvola, Kymenlaakso, Finland",
  "Alt Schwerin, Mecklenburg-West Pomerania, Germany",
  "Ağrı Subregion, Türkiye",
  "211381, Orsha, Vitsyebsk, Belarus",
  "Kanashsky District, Chuvashia, Russia",
  "31140, Pechbonnieu, Occitanie, France",
  "86609, Donauwörth, Bavaria, Germany",
  "346130, Millerovo, Rostov, Russia",
  "Cheshire, Oregon, United States",
  "72655, Altdorf, Baden-Württemberg, Germany",
  "Sherman, New York, United States",
  "Jones, Alabama, United States",
  "6846 ER, Arnhem, Gelderland, Netherlands",
  "McLeod Lake, British Columbia, Canada",
  "Nelson, British Columbia, Canada",
  "54580, Sakarya, Sakarya, Türkiye",
  "44900, Malatya, Malatya, Türkiye",
  "8754, Glarus, Glarus, Switzerland",
  "Boone, Virginia, United States",
  "3411, Librazhd, Elbasan, Albania",
  "Ivanovsky District, Ivanovo, Russia",
  "51003, Tartu, Tartumaa, Estonia",
  "663602, Kansk, Krasnoyarsk Krai, Russia",
  "34138, Trieste, Friuli-Venezia Giulia, Italy",
  "5212 AA, ’s-Hertogenbosch, North Brabant, Netherlands",
  "Greater Cagliari Metropolitan Area",
  "9100, Herisau, Appenzell Outer-Rhoden, Switzerland",
  "Meeker North, Ames, Iowa, United States",
  "PH33 6TR, Fort William, Scotland, United Kingdom",
  "Mifflin, Ohio, United States",
  "31400, Đakovo, Osijek-Baranja, Croatia",
  "33131, Miami, Florida, United States",
  "Galaţi, Galaţi, Romania",
  "C0B 0A1, Coleman, Prince Edward Island, Canada",
  "Major Isidoro, Major Izidoro, Alagoas, Brazil",
  "Canadian National Resource Council, Ottawa, Ontario, Canada",
  "Costa Rica",
  "Agena, Southern Nations, Nationalities, and Peoples, Ethiopia",
  "12929-317, Bragança Paulista, São Paulo, Brazil",
  "238172, Chernyakhovskiy, Kaliningrad, Russia",
  "456305, Miass, Chelyabinsk, Russia",
  "PH33 6XZ, Fort William, Scotland, United Kingdom",
  "Nassau Village-Ratliff, Florida, United States",
  "Sommer Pines, Sheboygan, Wisconsin, United States",
  "49111, Eau Claire, Michigan, United States",
  "310389, Arad, Arad, Romania",
  "352331, Ust’-Labinsk, Krasnodar, Russia",
  "65588, Winona, Missouri, United States",
  "54423, Custer, Wisconsin, United States",
  "Pula, Istria, Croatia",
  "08339, Vilassar de Dalt, Catalonia, Spain",
  "36790, Salamanca, Guanajuato, Mexico",
  "Panama",
  "Sandusky, Michigan, United States",
  "74463, Haskell, Oklahoma, United States",
  "Pagedongan, Central Java, Indonesia",
  "452683, Neftekamsk, Bashkortostan, Russia",
  "Aksu, Pavlodar Region, Kazakhstan",
  "Clarendon, Arkansas, United States",
  "Rennes-le-Château, Occitanie, France",
  "Västerås, Västmanland County, Sweden",
  "Ross, California, United States",
  "671700, Severobaykal’sk, Buryatia, Russia",
  "Stanley, Wakefield, England, United Kingdom",
  "50574, Pocahontas, Iowa, United States",
  "Appenzell Inner-Rhoden, Switzerland",
  "R6W 4A8, Winkler, Manitoba, Canada",
  "7986, Rørvik, Trøndelag, Norway",
  "MD-2060, Chişinău, Chișinău, Moldova",
  "Caserta, Campania, Italy",
  "141336, Sergiyev Posad, Moscow, Russia",
  "Cagnano Amiterno, Abruzzi, Italy",
  "184207, Apatity, Murmansk, Russia",
  "76123, Fort Worth, Texas, United States",
  "28208, Charlotte, North Carolina, United States",
  "429827, Alatyr’, Chuvashia, Russia",
  "680021, Khabarovsk, Khabarovsk, Russia",
  "Grand Rapids, Michigan, United States",
  "Fillmore, California, United States",
  "South Koochiching, Minnesota, United States",
  "C00H2M6, Kokshetau, Akmola Region, Kazakhstan",
  "630055, Novosibirsk, Novosibirsk, Russia",
  "DL3 8QD, Darlington, England, United Kingdom",
  "611 65, Nyköping, Södermanland County, Sweden",
  "Kherson, Ukraine",
  "433515, Dimitrovgrad, Ul’yanovsk, Russia",
  "Winona Park, Auburndale, Florida, United States",
  "40100, Kirsehir, Kırşehir, Türkiye",
  "169606, Pechora, Komi, Russia",
  "8610, Mo i Rana, Nordland, Norway",
  "Burgas, Burgas, Bulgaria",
  "38874, Pontotoc, Mississippi, United States",
  "Ripley, New York, United States",
  "403894, Kamyshin, Volgograd, Russia",
  "37450, Panevėžys, Panevėžio, Lithuania",
  "Kingsbury, New York, United States",
  "Wichita County, Texas, United States",
  "Montgomery, Illinois, United States",
  "8014 AA, Zwolle, Overijssel, Netherlands",
  "09006, Burgos, Castilla and Leon, Spain",
  "42544, Nancy, Kentucky, United States",
  "Peso da Régua, Vila Real, Portugal",
  "8031 AA, Zwolle, Overijssel, Netherlands",
  "628418, Surgut, Khanty-Mansi, Russia",
  "Wayne, Indiana, United States",
  "Stryy, Lviv, Ukraine",
  "Athens, West Virginia, United States",
  "Greater Helsingborg Metropolitan Area",
  "Mõisaküla, Viljandimaa, Estonia",
  "414040, Astrakhan, Astrakhan’, Russia",
  "Phillips County, Arkansas, United States",
  "Elko County, Nevada, United States",
  "E28D6M3, Makhambet, Atyrau Region, Kazakhstan",
  "Harding County, South Dakota, United States",
  "Sterling, Colorado, United States",
  "363760, Mozdok, Severnaya Osetiya-Alaniya, Russia",
  "Sutton County, Texas, United States",
  "1010, Lausanne, Vaud, Switzerland",
  "46900, Kouvola, Kymenlaakso, Finland",
  "77551, Galveston, Texas, United States",
  "Moreno Valley, California, United States",
  "Ogden, Utah, United States",
  "Mount Pleasant, Schenectady, New York, United States",
  "8001, Cape Town, Western Cape, South Africa",
  "Becker, Panambi, Rio Grande do Sul, Brazil",
  "Nakhodka, Primorye, Russia",
  "93100, Lysychansk, Luhansk, Ukraine",
  "658826, Slavgorod, Altai Krai, Russia",
  "163051, Archangel, Arkhangel’sk, Russia",
  "673200, Khilok, Zabaykalsky Krai, Russia",
  "Grenada, California, United States",
  "Fall City, Washington, United States",
  "Steele County, Minnesota, United States",
  "5400, Chaves, Vila Real, Portugal",
  "Osage County, Kansas, United States",
  "Lipetsk, Russia",
  "Jelgava, Latvia",
  "Cole Camp, Missouri, United States",
  "56126, Pisa, Tuscany, Italy",
  "5951, Nikopol, Pleven, Bulgaria",
  "Vlorë, Albania",
  "309830, Alekseyevka, Belgorod, Russia",
  "Cuyahoga Heights, Ohio, United States",
  "Grant, Alabama, United States",
  "247775, Mazyr, Homel, Belarus",
  "E25C3E6, Maqat, Atyrau Region, Kazakhstan",
  "Bedford, Pennsylvania, United States",
  "Agenda, Kansas, United States",
  "Pickett County, Tennessee, United States",
  "8309, Svolvær, Nordland, Norway",
  "644043, Omsk, Omsk, Russia",
  "Becher Bay 1, British Columbia, Canada",
  "Karstädt, Mecklenburg-West Pomerania, Germany",
  "31210, Macon, Georgia, United States",
  "5601, Békéscsaba, Békés, Hungary",
  "628425, Surgut, Khanty-Mansi, Russia",
  "28007, Ansonville, North Carolina, United States",
  "Kendall, New South Wales, Australia",
  "618255, Gubakha, Perm, Russia",
  "Carson, Washington, United States",
  "Buck's Mills, England, United Kingdom",
  "Newberry, Pennsylvania, United States",
  "28074, Rutherfordton, North Carolina, United States",
  "6846 AA, Arnhem, Gelderland, Netherlands",
  "Friedrichsholm, Schleswig-Holstein, Germany",
  "Caddo Parish County, Louisiana, United States",
  "Chişinău, Chișinău, Moldova",
  "San Pablo, Calabarzon, Philippines",
  "Kyzyl-Tangi, Batken Region, Kyrgyzstan",
  "550, Sauðárkrókur, Northwestern Region, Iceland",
  "12001, Castellón de la Plana, Valencian Community, Spain",
  "71901, Hot Springs, Arkansas, United States",
  "The Randstad, Netherlands",
  "Lake Stevens, Washington, United States",
  "Governador Valadares, Minas Gerais, Brazil",
  "Lorain Avenue, Cleveland, Ohio, United States",
  "Benson, Illinois, United States",
  "Greater Nancy Area",
  "8401, Kalsdorf bei Graz, Styria, Austria",
  "570, Sauðárkrókur, Northwestern Region, Iceland",
  "670024, Ulan-Ude, Buryatia, Russia",
  "35540, Addison, Alabama, United States",
  "03800, Afyonkarahisar, Afyon, Türkiye",
  "Middlesex, North Carolina, United States",
  "Tambov, Russia",
  "Buffalo, Missouri, United States",
  "Putnam, Michigan, United States",
  "662972, Zheleznogorsk, Krasnoyarsk Krai, Russia",
  "Gmina Pruszcz Gdański, Pomorskie, Poland",
  "Barry, Scotland, United Kingdom",
  "75713, Tyler, Texas, United States",
  "981 44, Kiruna, Norrbotten County, Sweden",
  "04179, Leipzig, Saxony, Germany",
  "Iredell, Texas, United States",
  "Qandıağaş, Aqtöbe region, Kazakhstan",
  "45620, Cheshire, Ohio, United States",
  "Barranquilla, Atlántico, Colombia",
  "Tula, Hidalgo, Mexico",
  "601014, Oneşti, Bacău, Romania",
  "7802, Namsos, Trøndelag, Norway",
  "13045, Cortland, New York, United States",
  "Greer, South Carolina, United States",
  "Tver Metropolitan Area",
  "Duval, Tourville-sur-Sienne, Normandy, France",
  "Gilmer, Texas, United States",
  "35116, Panevėžys, Panevėžio, Lithuania",
  "City Of Edinburgh, Scotland, United Kingdom",
  "44139, Dortmund, North Rhine-Westphalia, Germany",
  "1000, Ljubljana, Ljubljana, Slovenia",
  "Forrest County, Mississippi, United States",
  "East Merrimack, New Hampshire, United States",
  "Killarney Road, New Brunswick, Canada",
  "72515, Bexar, Arkansas, United States",
  "Bondowoso Regency, East Java, Indonesia",
  "R6W 4A4, Winkler, Manitoba, Canada",
  "Obilic, District of Pristina, Kosovo",
  "99089, Erfurt, Thuringia, Germany",
  "Melikgazi, Kayseri, Türkiye",
  "19121, Philadelphia, Pennsylvania, United States",
  "Pickaway, West Virginia, United States",
  "13090, Aix-en-Provence, Provence-Alpes-Côte d'Azur, France",
  "Fox Chase, Philadelphia, Pennsylvania, United States",
  "84015, Clearfield, Utah, United States",
  "86801, Tori, Parnu, Estonia",
  "431783, Krasino, Mordovia, Russia",
  "Chickasaw, Ohio, United States",
  "400495, Cluj-Napoca, Cluj, Romania",
  "430031, Saransk, Mordovia, Russia",
  "Pachalik de Boumalne Dades, Drâa-Tafilalet, Morocco",
  "Ross County, Ohio, United States",
  "Predgornyy, Stavropol, Russia",
  "V3H 0B7, Port Moody, British Columbia, Canada",
  "Elistanzhinskoye, Chechnya, Russia",
  "676307, Shimanovsk, Amur, Russia",
  "Palmas, Paraná, Brazil",
  "Marlboro, New Jersey, United States",
  "SR1 1AG, Sunderland, England, United Kingdom",
  "Nelson, Santa Fe, Argentina",
  "221 32, Tripoli, Peloponnese, Greece",
  "Broomehill Village, Western Australia, Australia",
  "41-907, Bytom, Śląskie, Poland",
  "29899, McCormick, South Carolina, United States",
  "24505, Lynchburg, Virginia, United States",
  "Platte City, Missouri, United States",
  "Shosh, Shkodër, Albania",
  "Greater Bournemouth Area",
  "7033, Trondheim, Trøndelag, Norway",
  "Gazi Baba, Skopje Statistical Region, North Macedonia",
  "South Sumatra, Indonesia",
  "211384, Orsha, Vitsyebsk, Belarus",
  "Fulton County, Illinois, United States",
  "Sense, Fribourg, Switzerland",
  "EC4R 0AN, London, England, United Kingdom",
  "Calumet, East Chicago, Indiana, United States",
  "99216, Spokane, Washington, United States",
  "Williamson County, Tennessee, United States",
  "Zheleznodorozhnyy Rayon, Vitsyebsk, Belarus",
  "346134, Millerovo, Rostov, Russia",
  "34325, Kragujevac, Centralna Srbija, Serbia",
  "59343, Mamaivtsi, Chernivtsi, Ukraine",
  "Granada, Granada, Nicaragua",
  "Pearland, Texas, United States",
  "404133, Volzhskiy, Volgograd, Russia",
  "Grazac, Occitanie, France",
  "Greater Nuremberg Metropolitan Area",
  "Astrakhan, Astrakhan’, Russia",
  "Overton, Frodsham, England, United Kingdom",
  "Rock Island, Illinois, United States",
  "Dauphin County, Pennsylvania, United States",
  "Greene County, Alabama, United States",
  "50150, Mikkeli, South Savo, Finland",
  "Cocke County, Tennessee, United States",
  "AD500, Andorra la Vella, Andorra",
  "76131, Fort Worth, Texas, United States",
  "Milan, Illinois, United States",
  "Tulsa Metropolitan Area",
  "910001, Călăraşi, Călăraşi, Romania",
  "Italy",
  "10140, Tallinn, Harjumaa, Estonia",
  "Frederick, Oklahoma, United States",
  "618215, Chusovoy, Perm, Russia",
  "59102, Billings, Montana, United States",
  "Belfast, Tennessee, United States",
  "La Millardière, Saint-Prix, Auvergne-Rhône-Alpes, France",
  "Spateston, Johnstone, Scotland, United Kingdom",
  "Greater Lorient Area",
  "Nice, Provence-Alpes-Côte d'Azur, France",
  "Marshall Islands",
  "618222, Chusovoy, Perm, Russia",
  "Sozopol, Burgas, Bulgaria",
  "78103, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "Livingstone, Queensland, Australia",
  "Burlington, Kansas, United States",
  "Mineral, Washington, United States",
  "Oldenburg, Lower Saxony, Germany",
  "Timiryazevskoye, Voronezh, Russia",
  "94285, Klaipėda, Klaipėdos, Lithuania",
  "Brown County, Wisconsin, United States",
  "70661, Starks, Louisiana, United States",
  "CV7 7BE, Berkswell, England, United Kingdom",
  "57000, Metz, Grand Est, France",
  "Comoé, Côte d'Ivoire",
  "Ottery St Mary, England, United Kingdom",
  "Treasure Cove, Upper Grand Lagoon, Florida, United States",
  "624932, Karpinsk, Sverdlovsk, Russia",
  "T0J 2T0, Rosebud, Alberta, Canada",
  "Chapayevsk, Samara, Russia",
  "Trippodi Marianello, Licata, Sicily, Italy",
  "B61B8K7, Sarqan, Jetisu Region, Kazakhstan",
  "65-012, Zielona Góra, Lubuskie, Poland",
  "Vadu Pașii, Buzău, Romania",
  "Pendra, Chhattisgarh, India",
  "H34F0X9, Qulan, Jambyl Region, Kazakhstan",
  "39400, Kirklareli, Kırklareli, Türkiye",
  "South Mahoning, Pennsylvania, United States",
  "Liberty County, Texas, United States",
  "Valencia County, New Mexico, United States",
  "623303, Krasnoufimsk, Sverdlovsk, Russia",
  "Polkowicki, Dolnośląskie, Poland",
  "12800, Bingol, Bingöl, Türkiye",
  "Iron Mountain, Michigan, United States",
  "010 01, Žilina, Zilina, Slovakia",
  "184637, Severomorsk, Murmansk, Russia",
  "423826, Naberezhnyye Chelny, Tatarstan, Russia",
  "Beaver Dam, New Brunswick, Canada",
  "710356, Botoşani, Botoşani, Romania",
  "Brookeville, Maryland, United States",
  "Minnehaha, Parkdale 498, Saskatchewan, Canada",
  "Žakanje, Karlovac, Croatia",
  "Oberhammer, Trippstadt, Rhineland-Palatinate, Germany",
  "620003, Focşani, Vrancea, Romania",
  "Lesozavodskiy, Murmansk, Russia",
  "47055, Duisburg, North Rhine-Westphalia, Germany",
  "162622, Cherepovets, Vologda, Russia",
  "544 53, Thessaloniki, Central Macedonia, Greece",
  "5202, Neumarkt am Wallersee, Salzburg, Austria",
  "29672, Seneca, South Carolina, United States",
  "Vladimir Metropolitan Area",
  "Lackawanna Avenue Commercial Historic District, Pennsylvania, United States",
  "623960, Tavda, Sverdlovsk, Russia",
  "Greater Augusta Area",
  "Žilina, Zilina, Slovakia",
  "83200, Le Revest-les-Eaux, Provence-Alpes-Côte d'Azur, France",
  "86163, Augsburg, Bavaria, Germany",
  "Dodge City, Kansas, United States",
  "21210, Solin, Split-Dalmatia, Croatia",
  "Padre Burgos, Eastern Visayas, Philippines",
  "Sason, Batman, Türkiye",
  "Kamianets-Podilskyi Raion, Khmelnytsky, Ukraine",
  "Keyano, Baie-James, Quebec, Canada",
  "Chester, Virginia, United States",
  "Madison County, Tennessee, United States",
  "Adams, New York, United States",
  "Darlington, Western Australia, Australia",
  "02766, Norton, Massachusetts, United States",
  "Fannini, Pizzoferrato, Abruzzi, Italy",
  "Aarhus Municipality, Central Denmark Region, Denmark",
  "McDowell County, West Virginia, United States",
  "Humboldt County, Nevada, United States",
  "Baku, Baku Ekonomic Zone, Azerbaijan",
  "423822, Naberezhnyye Chelny, Tatarstan, Russia",
  "B6L 6T7, Onslow Mountain, Nova Scotia, Canada",
  "Hamlin County, South Dakota, United States",
  "Adilcevaz, Bitlis, Türkiye",
  "Colmar, Diekirch, Luxembourg",
  "30810, Lorca, Región de Murcia, Spain",
  "7204, Bitola, Pelagonia, North Macedonia",
  "54600, Sakarya, Sakarya, Türkiye",
  "Garviniou, Plounévez-Quintin, Brittany, France",
  "Emden, Illinois, United States",
  "Pinerolo, Piedmont, Italy",
  "Amursky District, Khabarovsk, Russia",
  "J0V 1N0, Namur, Quebec, Canada",
  "Tucker Chase, North Carolina, United States",
  "172003, Torzhok, Tver’, Russia",
  "Bastian, Virginia, United States",
  "McKenzie Settlement, Colchester, Subdivision B, Nova Scotia, Canada",
  "Turabah, Makkah, Saudi Arabia",
  "141307, Sergiyev Posad, Moscow, Russia",
  "Bill Quay, Gateshead, England, United Kingdom",
  "East Umatilla, Florida, United States",
  "665834, Angarsk, Irkutsk, Russia",
  "EH12 0AA, Edinburgh, Scotland, United Kingdom",
  "803 11, Gävle, Gävleborg County, Sweden",
  "Baba Bakala, Punjab, India",
  "Greater Los Angeles, California, United States",
  "Williams County, North Dakota, United States",
  "80537, Loveland, Colorado, United States",
  "663310, Noril'sk, Taymyr, Russia",
  "Chambers, Montrose 315, Saskatchewan, Canada",
  "606782, Severnyy, Nizhniy Novgorod, Russia",
  "Brown County, Indiana, United States",
  "Fallon, Montana, United States",
  "Mons-en-Barœul, Hauts-de-France, France",
  "South Bend-Mishawaka Region",
  "689202, Egvekinot, Chukot, Russia",
  "Crémerie du Glacier, Chamonix-Mont-Blanc, Auvergne-Rhône-Alpes, France",
  "300 03, Agrínio, Western Greece, Greece",
  "Blairmore, Alberta, Canada",
  "Silver Creek, Georgia, United States",
  "Wilson County, North Carolina, United States",
  "670040, Ulan-Ude, Buryatia, Russia",
  "Staunton, Illinois, United States",
  "4024, Debrecen, Hajdú-Bihar, Hungary",
  "453103, Sterlitamak, Bashkortostan, Russia",
  "8622, Mo i Rana, Nordland, Norway",
  "27104, Winston-Salem, North Carolina, United States",
  "27707, Durham, North Carolina, United States",
  "Barangay 10-A (Kingfisher-A), Cavite City, Calabarzon, Philippines",
  "24012, Roanoke, Virginia, United States",
  "Karlovac, Karlovac, Croatia",
  "Kanawha, Iowa, United States",
  "Odisha, India",
  "2343, Pernik, Pernik, Bulgaria",
  "Joensuu sub-region, North Karelia, Finland",
  "48735, Gagetown, Michigan, United States",
  "445000, Togliatti, Samara, Russia",
  "80809, Cascade, Colorado, United States",
  "WF2 0AA, Wakefield, England, United Kingdom",
  "Guadalupe, La Libertad, Peru",
  "Griggs Park, Brookline, Massachusetts, United States",
  "Voloshinka, North Kazakhstan Region, Kazakhstan",
  "Genesee, Seattle, Washington, United States",
  "Newcastle West, New South Wales, Australia",
  "Union City, Georgia, United States",
  "84133, Salerno, Campania, Italy",
  "412911, Vol'sk, Saratov, Russia",
  "627311, Golyshmanovo, Tyumen’, Russia",
  "De Kalb County, Missouri, United States",
  "West Kewaunee, Wisconsin, United States",
  "Slonim, Hrodna, Belarus",
  "Erie, Kansas, United States",
  "Bastrop County, Texas, United States",
  "Kyivskyi District, Donetsk, Ukraine",
  "McIntosh Mills, Front of Yonge, Ontario, Canada",
  "9000-768, Funchal, Madeira Island, Portugal",
  "Inkerman, Sevastopol, Ukraine",
  "Sarnenska miska hromada, Rivne, Ukraine",
  "Nazyvayevsky District, Omsk, Russia",
  "Kyzyl-Kyya, Osh Region, Kyrgyzstan",
  "74940, Annecy, Auvergne-Rhône-Alpes, France",
  "9200, Provadia, Varna, Bulgaria",
  "423256, Leninogorsk, Tatarstan, Russia",
  "Jefferson County, Tennessee, United States",
  "Gorodskoy Okrug Borovichi, Novgorod, Russia",
  "Tobolovo, Tyumen’, Russia",
  "Tercan, Erzincan, Türkiye",
  "Zheleznovodak, Stavropol, Russia",
  "64700, Uşak, Uşak, Türkiye",
  "453105, Sterlitamak, Bashkortostan, Russia",
  "6300, Haskovo, Khaskovo, Bulgaria",
  "Big Spring, Texas, United States",
  "Bahía de Banderas, Nayarit, Mexico",
  "38502, Cookeville, Tennessee, United States",
  "Hatfield, England, United Kingdom",
  "03400, Afyonkarahisar, Afyon, Türkiye",
  "40-026, Katowice, Śląskie, Poland",
  "54701, Eau Claire, Wisconsin, United States",
  "26040, Eskişehir, Eskişehir, Türkiye",
  "8530, Narvik, Nordland, Norway",
  "346900, Novoshakhtinsk, Rostov, Russia",
  "Aljustrel, Beja, Portugal",
  "30324, Kohtla-Järve, Ida-Virumaa, Estonia",
  "Aryskan, Tuva, Russia",
  "Meriç, Edirne, Türkiye",
  "Boulder County, Colorado, United States",
  "Glasgow, Missouri, United States",
  "Sunflower, Alabama, United States",
  "06401, Ansonia, Connecticut, United States",
  "Granite Falls, North Carolina, United States",
  "9500-052, Ponta Delgada, Portugal",
  "Pearl District, Portland, Oregon, United States",
  "4054, Basel, Basel, Switzerland",
  "Bradford, Maine, United States",
  "Sutton Farm, Kidderminster, England, United Kingdom",
  "36700, Teluk Intan, Perak, Malaysia",
  "61024, Kharkiv, Kharkiv, Ukraine",
  "Larnaca Municipality, Larnaca, Cyprus",
  "692054, Lesozavodsk, Primorye, Russia",
  "21070, Diyarbakır, Diyarbakır, Türkiye",
  "423450, Almetyevsk, Tatarstan, Russia",
  "31204, Macon, Georgia, United States",
  "54100, Lappeenranta, South Karelia, Finland",
  "84753, Modena, Utah, United States",
  "632868, Karasuk, Novosibirsk, Russia",
  "Partizánske, Trenciansky, Slovakia",
  "67750, Linares, Nuevo León, Mexico",
  "Lexington, Missouri, United States",
  "500032, Hyderabad, Telangana, India",
  "Harmony, Maine, United States",
  "Newhaven, England, United Kingdom",
  "52553, Eddyville, Iowa, United States",
  "Jefferson County, Kentucky, United States",
  "20620, Callaway, Maryland, United States",
  "Beech Grove, Indiana, United States",
  "8911 AA, Leeuwarden, Friesland, Netherlands",
  "Athenry, County Galway, Ireland",
  "P0V 2B0, McKenzie Island, Ontario, Canada",
  "91005, Luhansk, Luhansk, Ukraine",
  "98166, Messina, Sicily, Italy",
  "624230, Nizhnyaya Tura, Sverdlovsk, Russia",
  "SS2 6EU, Southend-On-Sea, England, United Kingdom",
  "S41 0AA, Chesterfield, England, United Kingdom",
  "Râmnicu Vâlcea, Vâlcea, Romania",
  "Altdorf, Uri, Switzerland",
  "M1W 0A1, Scarborough, Ontario, Canada",
  "Besni, Adıyaman, Türkiye",
  "Chapaev, West Kazakhstan Region, Kazakhstan",
  "Kirchschlag bei Linz, Upper Austria, Austria",
  "152190, Borisoglebskiy, Yaroslavl’, Russia",
  "Chernihiv, Ukraine",
  "IP3 9DH, Ipswich, England, United Kingdom",
  "36201, Vigo, Galicia, Spain",
  "629802, Noyabrsk, Yamalo-Nenets, Russia",
  "Hamburg, New Jersey, United States",
  "Greater Livorno(Leghorn) Metropolitan Area",
  "Narva-Jõesuu linn, Ida-Virumaa, Estonia",
  "71400, Kırıkkale, Kırıkkale, Türkiye",
  "Snyder, Nebraska, United States",
  "Gonzales, Louisiana, United States",
  "99505, Joint Base Elmendorf Richardson, Alaska, United States",
  "310224, Arad, Arad, Romania",
  "Perry, Iowa, United States",
  "2010, Surry Hills, New South Wales, Australia",
  "607222, Arzamas, Nizhniy Novgorod, Russia",
  "Gida Ayana, Oromia Region, Ethiopia",
  "357113, Nevinnomyssk, Stavropol, Russia",
  "641454, Kurtamysh, Kurgan, Russia",
  "Verona, Veneto, Italy",
  "71070, Saline, Louisiana, United States",
  "172524, Nelidovo, Tver’, Russia",
  "6004, Stara Zagora, Stara Zagora, Bulgaria",
  "Cedarburg, Wisconsin, United States",
  "Rayevskiy, Bashkortostan, Russia",
  "76262, Roanoke, Texas, United States",
  "55500, Samsun, Samsun, Türkiye",
  "Chistopol'skiy, Krasnoyarsk Krai, Russia",
  "655010, Abakan, Khakassia, Russia",
  "Suriname",
  "Petersburg, Tennessee, United States",
  "Polk, Missouri, United States",
  "47240, Slunj, Karlovac, Croatia",
  "32784, Umatilla, Florida, United States",
  "Hanover County, Virginia, United States",
  "646844, Cherlakskiy, Omsk, Russia",
  "Winona County, Minnesota, United States",
  "Tiptonville, Tennessee, United States",
  "LN8 2EE, Caenby, England, United Kingdom",
  "Santa María del Refugio, Guanajuato, Mexico",
  "11009, Cadiz, Andalusia, Spain",
  "40824, Harlan, Kentucky, United States",
  "Daugavpils, Daugavpils, Latvia",
  "82-311, Elbląg, Warmińsko-mazurskie, Poland",
  "DT11 0PB, Lower Whatcombe, England, United Kingdom",
  "Eindhoven, North Brabant, Netherlands",
  "Langlade, Busserolles, Nouvelle-Aquitaine, France",
  "Windham, New Hampshire, United States",
  "Ulmeni, Maramureş, Romania",
  "Le Manoir, Barneville-Carteret, Normandy, France",
  "Dayton, New Jersey, United States",
  "24114, Kiel, Schleswig-Holstein, Germany",
  "430022, Baia Mare, Maramureş, Romania",
  "Vernon Rockville, Connecticut, United States",
  "Allen County, Kentucky, United States",
  "5305, Brooke”s Point, Mimaropa, Philippines",
  "Kentucky, United States",
  "632400, Kargat, Novosibirsk, Russia",
  "Burton, British Columbia, Canada",
  "Allen, Texas, United States",
  "00132, Rome, Latium, Italy",
  "Estarreja, Aveiro, Portugal",
  "Avan, Yerevan, Armenia",
  "Newton, Alabama, United States",
  "60700, Tokat, Tokat, Türkiye",
  "Pocahontas County, Iowa, United States",
  "24214, Subotica, Vojvodina, Serbia",
  "2308, Pernik, Pernik, Bulgaria",
  "Fort-de-France, Fort-de-France, Martinique",
  "84026, Fort Duchesne, Utah, United States",
  "5221 HK, ’s-Hertogenbosch, North Brabant, Netherlands",
  "Novyy Nekouz, Yaroslavl’, Russia",
  "Burton Upon Trent, England, United Kingdom",
  "10014, Zhytomyr, Zhytomyr, Ukraine",
  "440066, Penza, Penza, Russia",
  "Kosi Zone, Nepal",
  "22015, Burke, Virginia, United States",
  "Bernex, Auvergne-Rhône-Alpes, France",
  "03307, Loudon, New Hampshire, United States",
  "Liverpool, New York, United States",
  "08753, Toms River, New Jersey, United States",
  "Kolomyia Raion, Ivano-Frankivsk, Ukraine",
  "New York, New York, United States",
  "Bariri, São Paulo, Brazil",
  "01002, Amherst, Massachusetts, United States",
  "453204, Ishimbay, Bashkortostan, Russia",
  "Rama Road, Chippewas of Rama First Nation, Ontario, Canada",
  "Rovere della Luna, Trentino-Alto Adige, Italy",
  "Armstrong County, Pennsylvania, United States",
  "Oshimili South, Delta State, Nigeria",
  "Greater Fayetteville, AR Area",
  "95121, Catania, Sicily, Italy",
  "Davidson Township, North Carolina, United States",
  "Polovino-Cheremkhovskoye, Irkutsk, Russia",
  "Kotlas, Arkhangel’sk, Russia",
  "Bello, Antioquia, Colombia",
  "Babadag, Tulcea, Romania",
  "White, Georgia, United States",
  "Troitskoye, Khabarovsk, Russia",
  "Dane In Shaw, Congleton, England, United Kingdom",
  "Betzdorf, Grevenmacher, Luxembourg",
  "Sweet Home, Arkansas, United States",
  "Letterkenny, County Donegal, Ireland",
  "TS4 3BW, Middlesbrough, England, United Kingdom",
  "155330, Vichuga, Ivanovo, Russia",
  "Afyon, Türkiye",
  "8400, Ajka, Veszprém, Hungary",
  "Warren County, Iowa, United States",
  "56098, Winnebago, Minnesota, United States",
  "Kemi-Tornio sub-region, Lapland, Finland",
  "27504, Benson, North Carolina, United States",
  "Pocahontas, Arkansas, United States",
  "Dubrovnik-Neretva, Croatia",
  "Seward Hall, Tennessee, United States",
  "Lutsk, Volyn, Ukraine",
  "Colerain, Ohio, United States",
  "Jackson, Wyoming, United States",
  "413853, Balakovo, Saratov, Russia",
  "23568, Lübeck, Schleswig-Holstein, Germany",
  "Bikinsky District, Khabarovsk, Russia",
  "Cherokee County, Oklahoma, United States",
  "Guimarães, Braga, Portugal",
  "Ho Chi Minh City, Vietnam",
  "Northamptonshire, England, United Kingdom",
  "Robertson, Three Rivers, Prince Edward Island, Canada",
  "Botoşani, Botoşani, Romania",
  "Kiowa County, Kansas, United States",
  "Suffolk City County, Virginia, United States",
  "79714, Andrews, Texas, United States",
  "169725, Usinsk, Komi, Russia",
  "450077, Ufa, Bashkortostan, Russia",
  "Holmes County, Florida, United States",
  "Summerside, Ohio, United States",
  "33523, Dade City, Florida, United States",
  "Edmond, Oklahoma, United States",
  "Allen, Eastern Visayas, Philippines",
  "50600, Nevsehir, Nevşehir, Türkiye",
  "30800, Hakkâri, Hakkâri, Türkiye",
  "423250, Leninogorsk, Tatarstan, Russia",
  "C40B2A3, Esil, Akmola Region, Kazakhstan",
  "Pula, Sardinia, Italy",
  "Porto Real, Rio de Janeiro, Brazil",
  "Falun, Dalarna County, Sweden",
  "Kapanewon Mlati, Yogyakarta, Indonesia",
  "33820, Tampere, Pirkanmaa, Finland",
  "658422, Gornyak, Altai Krai, Russia",
  "South Slope, Burnaby, British Columbia, Canada",
  "Polkowice, Dolnośląskie, Poland",
  "Fort Mill, South Carolina, United States",
  "624615, Alapayevsk, Sverdlovsk, Russia",
  "F18X7K5, Semey, Abay Region, Kazakhstan",
  "671702, Severobaykal’sk, Buryatia, Russia",
  "ST18 0TY, Little Haywood, England, United Kingdom",
  "Redwood Shores, Redwood City, California, United States",
  "R25A3Y3, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "33729, Bielefeld, North Rhine-Westphalia, Germany",
  "Gornyak, Donetsk, Ukraine",
  "40-019, Katowice, Śląskie, Poland",
  "Fatsa, Ordu, Türkiye",
  "Yerba Buena, Tucumán, Argentina",
  "423454, Almetyevsk, Tatarstan, Russia",
  "40339, Versailles, Kentucky, United States",
  "Marathon, Florida, United States",
  "Guryevsky District, Kaliningrad, Russia",
  "F42G2K6, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "601910, Kovrov, Vladimir, Russia",
  "2822, Gjøvik, Innlandet, Norway",
  "80000, Amiens, Hauts-de-France, France",
  "21750, Diyarbakır, Diyarbakır, Türkiye",
  "Arzamas, Nizhniy Novgorod, Russia",
  "Luxembourg, Luxembourg",
  "Arrondissement of Tournai, Walloon Region, Belgium",
  "04738, Washburn, Maine, United States",
  "Sint Maarten",
  "Belmont, Auvergne-Rhône-Alpes, France",
  "Motley County, Texas, United States",
  "Gemeente Nieuwegein, Utrecht, Netherlands",
  "5212 CJ, ’s-Hertogenbosch, North Brabant, Netherlands",
  "33391, Gijón, Principality of Asturias, Spain",
  "Metropolitan City of Bologna, Emilia-Romagna, Italy",
  "Redwood Falls, Minnesota, United States",
  "Eden Prairie, Minnesota, United States",
  "Toddington, England, United Kingdom",
  "692656, Novoshakhtinsky, Primorye, Russia",
  "LV-4600, Rēzekne, Rezekne, Latvia",
  "72555, Metzingen, Baden-Württemberg, Germany",
  "DY10 1RS, Kidderminster, England, United Kingdom",
  "352505, Labinsk, Krasnodar, Russia",
  "Alcornocalejo, San José del Valle, Andalusia, Spain",
  "HP17 8SX, Kimble Wick, England, United Kingdom",
  "Leakey, Texas, United States",
  "Westchester, New York, New York, United States",
  "B55F5A2, Zharkent, Jetisu Region, Kazakhstan",
  "Richardsons, Tennessee, United States",
  "Sully, Normandy, France",
  "450061, Zalău, Sălaj, Romania",
  "651, Wiesbaden, Hesse, Germany",
  "981 02, Kiruna, Norrbotten County, Sweden",
  "Lunenburg, Virginia, United States",
  "Miński, Mazowieckie, Poland",
  "Oxford, New Jersey, United States",
  "628403, Surgut, Khanty-Mansi, Russia",
  "Aïn Kechra District, Oum El Bouaghi, Algeria",
  "Toulon-sur-Allier, Auvergne-Rhône-Alpes, France",
  "Iracoubo, Cayenne, French Guiana",
  "433509, Dimitrovgrad, Ul’yanovsk, Russia",
  "San Sebastián de los Reyes, Community of Madrid, Spain",
  "Sakiet Eddaier, Sfax, Tunisia",
  "Wimbledon Chase, London, England, United Kingdom",
  "Chapayevskiy, Samara, Russia",
  "Pearl River, Louisiana, United States",
  "Warrenton, Virginia, United States",
  "Župa dubrovačka, Dubrovnik-Neretva, Croatia",
  "6032, Charleroi, Walloon Region, Belgium",
  "Hubbardston, Massachusetts, United States",
  "68725, Chambers, Nebraska, United States",
  "5021, Namur, Walloon Region, Belgium",
  "663416, Yuzhno-Yeniseysk, Krasnoyarsk Krai, Russia",
  "700273, Iaşi, Iaşi, Romania",
  "Otter Lake, Quebec, Canada",
  "86-311, Grudziądz, Kujawsko-pomorskie, Poland",
  "Wells, England, United Kingdom",
  "22100, Como, Lombardy, Italy",
  "Torrita di Siena, Tuscany, Italy",
  "Sainte-Sabine, Saint-Rome, Occitanie, France",
  "Al Maqatirah District, Lahij Governorate, Yemen",
  "618200, Chusovoy, Perm, Russia",
  "84332, Providence, Utah, United States",
  "55308, Becker, Minnesota, United States",
  "E1A 2K1, Dieppe, New Brunswick, Canada",
  "59450, Sin-le-Noble, Hauts-de-France, France",
  "Crockett County, Texas, United States",
  "628485, Kogalym, Khanty-Mansi, Russia",
  "Mariestad, Västra Götaland County, Sweden",
  "5000, Szolnok, Jász-Nagykun-Szolnok, Hungary",
  "Platteville, Wisconsin, United States",
  "V0N 2M0, Pender Island, British Columbia, Canada",
  "Baden-Württemberg, Germany",
  "León, Nicaragua",
  "38170, Santa Cruz de Tenerife, Canary Islands, Spain",
  "The Preserve, Rockwall, Texas, United States",
  "Denver County, Colorado, United States",
  "Petropavlovka, Chuy Region, Kyrgyzstan",
  "Vize, Kırklareli, Türkiye",
  "658924, Kulunda, Altai Krai, Russia",
  "169933, Vorkuta, Komi, Russia",
  "356 01, Sokolov, Karlovy Vary, Czechia",
  "Duplin County, North Carolina, United States",
  "Majorstuen, Oslo, Oslo, Norway",
  "Abbeville County, South Carolina, United States",
  "34115, Kragujevac, Centralna Srbija, Serbia",
  "06792, Sandersdorf, Saxony-Anhalt, Germany",
  "456901, Bakal, Chelyabinsk, Russia",
  "247771, Mazyr, Homel, Belarus",
  "97246, Mérida, Yucatán, Mexico",
  "427627, Glazov, Udmurtia, Russia",
  "76135, Karlsruhe, Baden-Württemberg, Germany",
  "Storozhynetska miska hromada, Chernivtsi, Ukraine",
  "Papaichton, Saint-Laurent-du-Maroni, French Guiana",
  "Greater Idaho Falls",
  "Sainte-Foy, Pays de la Loire, France",
  "Saunderstown, Rhode Island, United States",
  "300124, Timişoara, Timiş, Romania",
  "McLean County, Illinois, United States",
  "Manëz, Durres, Albania",
  "Seine-St.-Denis, Île-de-France, France",
  "Palanas, Bicol Region, Philippines",
  "56760, Siirt, Siirt, Türkiye",
  "Volosovo, Leningrad, Russia",
  "62901, Carbondale, Illinois, United States",
  "Galway Metropolitan Area",
  "04966, Phillips, Maine, United States",
  "Bugul’ma, Tatarstan, Russia",
  "Yeletskaya Lozovka, Lipetsk, Russia",
  "1210, Vienna, Vienna, Austria",
  "Emeryville, California, United States",
  "Kilingi-Nõmme, Pärnu maakond, Estonia",
  "Brighton, Colorado, United States",
  "Los Angeles County, California, United States",
  "78000, Versailles, Île-de-France, France",
  "73432, Coleman, Oklahoma, United States",
  "Shipilovo, Yaroslavl’, Russia",
  "Clinton, Oklahoma, United States",
  "Sembium-Ayanavaram municipality, India",
  "39100, Bolzano, Trentino-Alto Adige, Italy",
  "7902, Rørvik, Trøndelag, Norway",
  "Bowie, Arizona, United States",
  "46030, Kahramanmaraş, Kahraman Maras, Türkiye",
  "Tulare, California, United States",
  "Vila do Presídio, Hortolândia, São Paulo, Brazil",
  "Orel, Illinois, United States",
  "15123, West Mifflin, Pennsylvania, United States",
  "Bolintin-Vale, Giurgiu, Romania",
  "13738, Cortland, New York, United States",
  "HU3 2QA, Kingston Upon Hull, England, United Kingdom",
  "Dyersville, Iowa, United States",
  "052835, Bucharest, Bucharest, Romania",
  "Sandoval, Oaxaca, Mexico",
  "5211 AA, ’s-Hertogenbosch, North Brabant, Netherlands",
  "800, Selfoss, Southern Region, Iceland",
  "Derbent, Dagestan, Russia",
  "169935, Vorkuta, Komi, Russia",
  "Romania",
  "Hillsborough, California, United States",
  "656006, Barnaul, Altai Krai, Russia",
  "Mills County, Iowa, United States",
  "Bragança Paulista, São Paulo, Brazil",
  "Nikolayevsky District, Khabarovsk, Russia",
  "Carver, Richmond, Virginia, United States",
  "7201, Bitola, Pelagonia, North Macedonia",
  "Yalvaç, Isparta, Türkiye",
  "Kisújszállás, Jász-Nagykun-Szolnok, Hungary",
  "Newton Centre, Massachusetts, United States",
  "Gay, Orenburg, Russia",
  "427966, Sarapul, Udmurtia, Russia",
  "Helsinki, Uusimaa, Finland",
  "8900, Zalaegerszeg, Zala, Hungary",
  "66762, Pittsburg, Kansas, United States",
  "44, Liestal, Basel-Country, Switzerland",
  "668160, Aldan-Maadyr, Tuva, Russia",
  "217 43, Malmo, Skåne County, Sweden",
  "Severnyy, Krasnodar, Russia",
  "45307, Essen, North Rhine-Westphalia, Germany",
  "4603, Rrëshen, Lezhë, Albania",
  "KW15 1DN, Kirkwall, Scotland, United Kingdom",
  "Vieille-Toulouse, Occitanie, France",
  "Nebraska City, Nebraska, United States",
  "92346, Highland, California, United States",
  "BT28 2GN, Lisburn, Northern Ireland, United Kingdom",
  "Hays, Alberta, Canada",
  "2604, Lillehammer, Innlandet, Norway",
  "Muscatine, Iowa, United States",
  "8602, Mo i Rana, Nordland, Norway",
  "Robertson County, Kentucky, United States",
  "222512, Barysaw, Minsk, Belarus",
  "70499, Stuttgart, Baden-Württemberg, Germany",
  "76559, Nolanville, Texas, United States",
  "Garfield, New Mexico, United States",
  "Schwaig, Bavaria, Germany",
  "Belmont, Mississippi, United States",
  "626128, Tobolsk, Tyumen’, Russia",
  "169338, Ukhta, Komi, Russia",
  "689430, Pevek, Chukot, Russia",
  "23505, Norfolk, Virginia, United States",
  "69145, Kimball, Nebraska, United States",
  "38006, Santa Cruz de Tenerife, Canary Islands, Spain",
  "Cumhuriyet, Kırşehir, Türkiye",
  "692512, Ussuriysk, Primorye, Russia",
  "Matagorda, Texas, United States",
  "34452, Inverness, Florida, United States",
  "Intalı, Turkistan Region, Kazakhstan",
  "310, Borgarnes, Western Region, Iceland",
  "677008, Yakutsk, Sakha, Russia",
  "680054, Khabarovsk, Khabarovsk, Russia",
  "188991, Svetogorsk, Leningrad, Russia",
  "2613, Kapangan, Cordillera Admin Region, Philippines",
  "Nadvirna Raion, Ivano-Frankivsk, Ukraine",
  "Custer, Washington, United States",
  "Abay awdanı, Shymkent, Kazakhstan",
  "75012, Paris, Île-de-France, France",
  "Converse, Texas, United States",
  "36033-250, Juiz de Fora, Minas Gerais, Brazil",
  "Buena Vista, Colorado, United States",
  "Columbia County, Oregon, United States",
  "420126, Kazan, Tatarstan, Russia",
  "Serebryanskiy, Novosibirsk, Russia",
  "Andrew, Alberta, Canada",
  "01890, Winchester, Massachusetts, United States",
  "71428, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "Dodgeville, Michigan, United States",
  "Linnich, North Rhine-Westphalia, Germany",
  "Old Fort Stoddard Mount Vernon Landing, Alabama, United States",
  "94577, San Leandro, California, United States",
  "Starše, Maribor, Slovenia",
  "88-107, Inowrocław, Kujawsko-pomorskie, Poland",
  "110030, Piteşti, Argeş, Romania",
  "39780, Kirklareli, Kırklareli, Türkiye",
  "WR10 2JZ, Throckmorton, England, United Kingdom",
  "Abbeville Historic District, Abbeville, South Carolina, United States",
  "43828, Coshocton, Ohio, United States",
  "Broadwater, Victoria, Australia",
  "Hinds, Canterbury, New Zealand",
  "641447, Kurtamysh, Kurgan, Russia",
  "Dundee City, Scotland, United Kingdom",
  "641445, Kurtamysh, Kurgan, Russia",
  "Torsås kommun, Kalmar County, Sweden",
  "97001, Antelope, Oregon, United States",
  "Oneida, Kansas, United States",
  "Cherepanovo, Novosibirsk, Russia",
  "Nice metropolitan area, Provence-Alpes-Côte d'Azur, France",
  "00802, Charlotte Amalie East, St Thomas, US Virgin Islands",
  "Big Pine, Florida, United States",
  "Hays, Kansas, United States",
  "Florence, Mississippi, United States",
  "633456, Toguchin, Novosibirsk, Russia",
  "8500-311, Portimão, Faro, Portugal",
  "17737, Hughesville, Pennsylvania, United States",
  "Sherman, Texas, United States",
  "Hancock County, Kentucky, United States",
  "51111, Rijeka, Primorje-Gorski, Croatia",
  "91381, Stevenson Ranch, California, United States",
  "Forrest Station, Manitoba, Canada",
  "Miami, Queensland, Australia",
  "Baltimore City County, Maryland, United States",
  "Erie County, New York, United States",
  "Mas de l'Escloupié, Laramière, Occitanie, France",
  "Nelsonville, Ohio, United States",
  "9915, Kirkenes, Troms og Finnmark, Norway",
  "68200, Mulhouse, Grand Est, France",
  "68869, Ravenna, Nebraska, United States",
  "71200, Zimatlán de Álvarez, Oaxaca, Mexico",
  "Astara, Lankaran-Astara, Azerbaijan",
  "399059, Gryazi, Lipetsk, Russia",
  "07110, Porvoo, Uusimaa, Finland",
  "7252, Attala, Tolna, Hungary",
  "Fredericktown, Missouri, United States",
  "601901, Kovrov, Vladimir, Russia",
  "Sweetwater, Florida, United States",
  "V0N 0A9, Port Hardy, British Columbia, Canada",
  "Kalamata, Peloponnese, Greece",
  "Orange County, Texas, United States",
  "Loudon-Melrose, Roanoke, Virginia, United States",
  "T23 N129, Cork, County Cork, Ireland",
  "Harding, New Jersey, United States",
  "Marion, Arkansas, United States",
  "Targale, Ventspils, Latvia",
  "Travis Ranch, Texas, United States",
  "Fayette County, Pennsylvania, United States",
  "Summit Hill, Pennsylvania, United States",
  "Calhoun County, Illinois, United States",
  "57641, McIntosh, South Dakota, United States",
  "Dieppe, Normandy, France",
  "Pickens, West Virginia, United States",
  "Licenciado Desiré San Román Duval, Hidalgo, Mexico",
  "Bennington, Vermont, United States",
  "Teton, Idaho, United States",
  "Vologda, Russia",
  "97390, Umán, Yucatán, Mexico",
  "F63B4H8, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "Emery, Utah, United States",
  "Powell, Texas, United States",
  "51600, Mikkeli, South Savo, Finland",
  "H34F6M7, Qulan, Jambyl Region, Kazakhstan",
  "M52C1B9, Osakarovka, Karaganda Region, Kazakhstan",
  "Defiance, Missouri, United States",
  "Blagoveshchenskoye, Arkhangel’sk, Russia",
  "169530, Sosnogorsk, Komi, Russia",
  "MK18 1AA, Buckingham, England, United Kingdom",
  "23966, Rice, Virginia, United States",
  "Jackson County, Mississippi, United States",
  "9802, Vadsø, Troms og Finnmark, Norway",
  "Skellefteå kommun, Västerbotten County, Sweden",
  "Russell, Massachusetts, United States",
  "Dyer County, Tennessee, United States",
  "Novoaltaysk, Altai Krai, Russia",
  "Muğaljar district, Aqtöbe region, Kazakhstan",
  "Sheridan, Oregon, United States",
  "48310, Sterling Heights, Michigan, United States",
  "Lefke, Nicosia, Cyprus",
  "Hökeberg, Sterup, Schleswig-Holstein, Germany",
  "Baltimore County, Maryland, United States",
  "654067, Novokuznetsk, Kemerovo, Russia",
  "1100000, Iquique, Tarapacá Region, Chile",
  "Reagan, Texas, United States",
  "Montana, Bulgaria",
  "Saint-Bonnet-les-Tours-de-Merle, Nouvelle-Aquitaine, France",
  "Magdagachinsky District, Amur, Russia",
  "84043, Lehi, Utah, United States",
  "Tulare County, California, United States",
  "152601, Otradnyy, Yaroslavl’, Russia",
  "SR5 3HL, Sunderland, England, United Kingdom",
  "Kersha, Tambov, Russia",
  "Hirkan, Lankaran-Astara, Azerbaijan",
  "Travis White, El Paso, Texas, United States",
  "Banner Elk, North Carolina, United States",
  "94101, Klaipėda, Klaipėdos, Lithuania",
  "South Calumet, Sheboygan, Wisconsin, United States",
  "Fort McPherson, Atlanta, Georgia, United States",
  "Ourém, Santarém, Portugal",
  "71155, Altdorf, Baden-Württemberg, Germany",
  "68163, Mannheim, Baden-Württemberg, Germany",
  "La Millardière, Rochecorbon, Centre-Val de Loire, France",
  "Ivano-Frankivsk, Ukraine",
  "McLeod Hill, New Brunswick, Canada",
  "75-450, Koszalin, Zachodniopomorskie, Poland",
  "Robeson Crossing, Pennsylvania, United States",
  "Hunt Valley, Maryland, United States",
  "23841, Dinwiddie, Virginia, United States",
  "Y35 NX96, Wexford, County Wexford, Ireland",
  "LN8 2EF, Caenby, England, United Kingdom",
  "Hanson, Barbados",
  "Lucerne, Switzerland",
  "Karcag, Jász-Nagykun-Szolnok, Hungary",
  "413116, Engels, Saratov, Russia",
  "Salt Lake County, Utah, United States",
  "Fiera di Primiero, Trentino-Alto Adige, Italy",
  "Lake Plastiras, Thessalia, Greece",
  "J0S 0B3, Huntingdon, Quebec, Canada",
  "Inverness, Illinois, United States",
  "Ho Chi Minh City Metropolitan Area",
  "3115, Tønsberg, Vestfold og Telemark, Norway",
  "Nicollet County, Minnesota, United States",
  "PE3 6WT, Peterborough, England, United Kingdom",
  "Meeker, Colorado, United States",
  "Wilmington, Delaware, United States",
  "67900, Zonguldak, Zonguldak, Türkiye",
  "47400, Mardin, Mardin, Türkiye",
  "Kemah, Texas, United States",
  "Long Bennington, England, United Kingdom",
  "Norton, Vermont, United States",
  "632121, Tatarsk, Novosibirsk, Russia",
  "414004, Astrakhan, Astrakhan’, Russia",
  "654041, Novokuznetsk, Kemerovo, Russia",
  "Major County, Oklahoma, United States",
  "Crockett Forest, Texas, United States",
  "Steuben County, Indiana, United States",
  "Otter Tail County, Minnesota, United States",
  "4028, Debrecen, Hajdú-Bihar, Hungary",
  "Allegany, New York, United States",
  "6450, Harmanli, Khaskovo, Bulgaria",
  "Paraíba, Brazil",
  "29802, Aiken, South Carolina, United States",
  "03087, Kyiv, Kyiv City, Ukraine",
  "142214, Serpukhov, Moscow, Russia",
  "181326, Vorontsovo, Pskov, Russia",
  "Denton, Gravesend, England, United Kingdom",
  "Eureka, Waikato, New Zealand",
  "80633, Greeley, Colorado, United States",
  "Seattle, Washington, United States",
  "Coose, County Monaghan, Ireland",
  "HU8 0AA, Kingston Upon Hull, England, United Kingdom",
  "Wasatch, Cottonwood Heights, Utah, United States",
  "CO16 0JA, Thorpe-Le-Soken, England, United Kingdom",
  "2700, Wiener Neustadt, Lower Austria, Austria",
  "43003, Tarragona, Catalonia, Spain",
  "Belogorskiy, Orenburg, Russia",
  "184433, Nikel, Murmansk, Russia",
  "20041, Sully, Virginia, United States",
  "Newcastle, Northern Ireland, United Kingdom",
  "Golyshmanovo, Tyumen’, Russia",
  "Ashland, Illinois, United States",
  "16652, Huntingdon, Pennsylvania, United States",
  "Van Nuys, Los Angeles, California, United States",
  "47890, San Marino, San Marino",
  "400003, Cluj-Napoca, Cluj, Romania",
  "Timashevskiy Rayon, Krasnodar, Russia",
  "658923, Kulunda, Altai Krai, Russia",
  "Belleville, Ontario, Canada",
  "Hardyville, Virginia, United States",
  "La Joséphine, Somloire, Pays de la Loire, France",
  "Captain Cook, Hawaii, United States",
  "16253, Seminole, Pennsylvania, United States",
  "48009, Birmingham, Michigan, United States",
  "TR18 3JT, Penzance, England, United Kingdom",
  "66515, Morrill, Kansas, United States",
  "L00X8T0, Oral, West Kazakhstan Region, Kazakhstan",
  "Arunachal Pradesh, India",
  "Zimatlán de Álvarez, Oaxaca, Mexico",
  "Newport, England, United Kingdom",
  "Grotton, Oldham, England, United Kingdom",
  "Granville, West Virginia, United States",
  "Tondela, Viseu, Portugal",
  "6500, Bellinzona, Ticino, Switzerland",
  "75081, Richardson, Texas, United States",
  "Ransom, Kentucky, United States",
  "169511, Sosnogorsk, Komi, Russia",
  "Patrapur, Odisha, India",
  "Çorovodë, Berat, Albania",
  "721 89, Västerås, Västmanland County, Sweden",
  "44408, Columbiana, Ohio, United States",
  "Thomastown, County Kilkenny, Ireland",
  "80131, Naples, Campania, Italy",
  "Williamsburg, Indiana, United States",
  "453840, Sibay, Bashkortostan, Russia",
  "Crooked Creek, Alberta, Canada",
  "Berkeley Township, New Jersey, United States",
  "Montería, Córdoba, Colombia",
  "1018 VV, Amsterdam, North Holland, Netherlands",
  "Page County, Virginia, United States",
  "7985, Rørvik, Trøndelag, Norway",
  "72340, Heróica Puebla de Zaragoza, Puebla, Mexico",
  "Xhafzotaj, Durres, Albania",
  "32600-002, Betim, Minas Gerais, Brazil",
  "Highlands, Texas, United States",
  "4638, Kristiansand, Agder, Norway",
  "Montour, Brassy, Bourgogne-Franche-Comté, France",
  "1135, Budapest, Budapest, Hungary",
  "644033, Omsk, Omsk, Russia",
  "06181, Badajoz, Extremadura, Spain",
  "620018, Focşani, Vrancea, Romania",
  "Bryanston, Gauteng, South Africa",
  "Versailles, Indiana, United States",
  "McCormick Place, Michigan, United States",
  "South Province, New Caledonia",
  "Colonial, Sapucaia do Sul, Rio Grande do Sul, Brazil",
  "19104, Philadelphia, Pennsylvania, United States",
  "9027, Győr, Győr-Moson-Sopron, Hungary",
  "Weidhausen bei Coburg, Bavaria, Germany",
  "301661, Novomoskovsk, Tula, Russia",
  "410003, Oradea, Bihor, Romania",
  "33203, Gijón, Principality of Asturias, Spain",
  "Kateřinice, Zlin, Czechia",
  "32971, Ourense, Galicia, Spain",
  "689360, Egvekinot, Chukot, Russia",
  "Snyder County, Pennsylvania, United States",
  "605400, Moinești, Bacău, Romania",
  "165341, Kotlas, Arkhangel’sk, Russia",
  "22722, Haywood, Virginia, United States",
  "143405, Krasnogorsk, Moscow, Russia",
  "Greater Angers Area",
  "169307, Ukhta, Komi, Russia",
  "Powellhurst-Gilbert, Portland, Oregon, United States",
  "Hyde, England, United Kingdom",
  "Belmont, Massachusetts, United States",
  "Aradeo, Apulia, Italy",
  "Coffee Bluff, Georgia, United States",
  "Beckering, England, United Kingdom",
  "630089, Novosibirsk, Novosibirsk, Russia",
  "617066, Krasnokamsk, Perm, Russia",
  "603 62, Norrköping, Östergötland County, Sweden",
  "164509, Severodvinsk, Arkhangel’sk, Russia",
  "Richards Bay, KwaZulu-Natal, South Africa",
  "Balatonfüred, Veszprém, Hungary",
  "Galaţi, Romania",
  "68104, Omaha, Nebraska, United States",
  "Marshall, Missouri, United States",
  "61462, Monmouth, Illinois, United States",
  "Lafayette County, Mississippi, United States",
  "21209, Novi Sad, Vojvodina, Serbia",
  "S25A8B8, Ertis, Pavlodar Region, Kazakhstan",
  "19040, Çorum, Çorum, Türkiye",
  "Gorodskoy Okrug Sasovo, Ryazan’, Russia",
  "Wick, England, United Kingdom",
  "Córdoba Tetón, Bolívar, Colombia",
  "98121, Messina, Sicily, Italy",
  "430392, Baia Mare, Maramureş, Romania",
  "665388, Zima, Irkutsk, Russia",
  "456874, Kyshtym, Chelyabinsk, Russia",
  "623103, Pervoural'sk, Sverdlovsk, Russia",
  "20147, Ashburn, Virginia, United States",
  "82070, Laramie, Wyoming, United States",
  "9615, Hammerfest, Troms og Finnmark, Norway",
  "36853, Dadeville, Alabama, United States",
  "Shufu, Xinjiang Uygur, China",
  "66-015, Zielona Góra, Lubuskie, Poland",
  "New Washoe City, Nevada, United States",
  "Central Corfu & the Diapontian Islands, Ionian Islands, Greece",
  "665700, Bratsk, Irkutsk, Russia",
  "Nevers, Bourgogne-Franche-Comté, France",
  "Eastland County, Texas, United States",
  "Ile District, Almaty Region, Kazakhstan",
  "DT11 8RX, Tarrant Monkton, England, United Kingdom",
  "London, Ontario, Canada",
  "Accomack County, Virginia, United States",
  "Faribault County, Minnesota, United States",
  "Győr, Győr-Moson-Sopron, Hungary",
  "462420, Orsk, Orenburg, Russia",
  "Sanbornville, New Hampshire, United States",
  "35-082, Rzeszów, Podkarpackie, Poland",
  "Poringland, England, United Kingdom",
  "Khimki, Moscow, Russia",
  "162612, Cherepovets, Vologda, Russia",
  "Richardson Heights, Richardson, Texas, United States",
  "Appenzell Inner Rhodes, Appenzell Inner-Rhoden, Switzerland",
  "26104, Parkersburg, West Virginia, United States",
  "La Coaille, Laramière, Occitanie, France",
  "Korosten Raion, Zhytomyr, Ukraine",
  "6918, Lugano, Ticino, Switzerland",
  "V92 DHD6, Tralee, County Kerry, Ireland",
  "20324, Dmytrushky, Cherkasy, Ukraine",
  "98101, Kemijärvi, Lapland, Finland",
  "44870, Sandusky, Ohio, United States",
  "20133, Milan, Lombardy, Italy",
  "653 01, Karlstad, Värmland County, Sweden",
  "73527, Cache, Oklahoma, United States",
  "Pennington, Alabama, United States",
  "Glarus Nord, Switzerland",
  "Toddin, Mecklenburg-West Pomerania, Germany",
  "Lachine, Montreal, Quebec, Canada",
  "8516, Kukës, Kukës, Albania",
  "Venice, Veneto, Italy",
  "182643, Borovichi, Pskov, Russia",
  "620172, Focşani, Vrancea, Romania",
  "59802, Missoula, Montana, United States",
  "Perkins, Michigan, United States",
  "41015, Seville, Andalusia, Spain",
  "49086, Dnipro, Dnipropetrovsk, Ukraine",
  "İskenderun Bucağı, Hatay, Türkiye",
  "Ansonia, Huron Shores, Ontario, Canada",
  "100356, Ploieşti, Prahova, Romania",
  "Abbeville, Georgia, United States",
  "Monroe, Louisiana, United States",
  "50009, Zaragoza, Aragon, Spain",
  "Chesterfield, Massachusetts, United States",
  "43020, Kutahya, Kütahya, Türkiye",
  "Seri Chowan, Mandi, Jammu & Kashmir, India",
  "Rosebud, Alberta, Canada",
  "Anadyr’, Chukot, Russia",
  "X1A 3H4, Yellowknife, Northwest Territories, Canada",
  "48909, Autlán de Navarro, Jalisco, Mexico",
  "Šiauliai District Municipality, Siauliu, Lithuania",
  "San Donato di Lecce, Apulia, Italy",
  "Kaunas District Municipality, Kauno, Lithuania",
  "37352, Lynchburg, Tennessee, United States",
  "M28D1D7, Temirtau, Karaganda Region, Kazakhstan",
  "686332, Susuman, Magadan, Russia",
  "5211 NE, ’s-Hertogenbosch, North Brabant, Netherlands",
  "Inverness, Montana, United States",
  "Chaves, Pará, Brazil",
  "20014, San Sebastián, Basque Country, Spain",
  "Columbia, South Carolina, United States",
  "Maarssen, Utrecht, Netherlands",
  "South Pier, Sheboygan, Wisconsin, United States",
  "89122, Reggio di Calabria, Calabria, Italy",
  "São Gonçalo, Rio de Janeiro, Brazil",
  "8316, Svolvær, Nordland, Norway",
  "Aydın, Türkiye",
  "Old Allegheny Rows Historic District, Pittsburgh, Pennsylvania, United States",
  "Greater Bakersfield Area",
  "Penderff, Lignol, Brittany, France",
  "21950, Diyarbakır, Diyarbakır, Türkiye",
  "309189, Gubkin, Belgorod, Russia",
  "Timiryazeva, Irkutsk, Russia",
  "28205, Charlotte, North Carolina, United States",
  "Usinsk, Samara, Russia",
  "Hickory Valley, Tennessee, United States",
  "Békéscsabai Járás, Békés, Hungary",
  "Kastamonu, Türkiye",
  "Fairfield County, South Carolina, United States",
  "48845, Hubbardson, Michigan, United States",
  "Makarovo, Saratov, Russia",
  "Qarasay District, Almaty Region, Kazakhstan",
  "Saint Johns, Florida, United States",
  "Tropojë, Kukës, Albania",
  "Nizhny Novgorod Metropolitan Area",
  "Louisville, Kentucky, United States",
  "São Paulo, Brazil",
  "23700, Linares, Andalusia, Spain",
  "70510, Abbeville, Louisiana, United States",
  "662150, Achinsk, Krasnoyarsk Krai, Russia",
  "49080, Osnabrück, Lower Saxony, Germany",
  "Otarka, Tatarstan, Russia",
  "Steuben, Maine, United States",
  "30221, Kohtla-Järve, Ida-Virumaa, Estonia",
  "446377, Mirnyy, Samara, Russia",
  "Bowie, Arkansas, United States",
  "S9H 4Z1, Swift Current, Saskatchewan, Canada",
  "Severnyy, Saratov, Russia",
  "SG13 8BN, Hertford, England, United Kingdom",
  "Yellowstone, North Dakota, United States",
  "69117, Heidelberg, Baden-Württemberg, Germany",
  "75-122, Koszalin, Zachodniopomorskie, Poland",
  "184204, Apatity, Murmansk, Russia",
  "Cape Girardeau County, Missouri, United States",
  "Tomsk, Russia",
  "Ortamahalle, Çankırı, Türkiye",
  "Jasper, Indiana, United States",
  "Hannover, Lower Saxony, Germany",
  "Orange, New South Wales, Australia",
  "Vyaz'ma-Bryanskoye, Smolensk, Russia",
  "45160, Kouvola, Kymenlaakso, Finland",
  "871 92, Härnösand, Västernorrland County, Sweden",
  "Kent County, Rhode Island, United States",
  "38926, Grenada, Mississippi, United States",
  "Allegheny, Pennsylvania, United States",
  "1000, Sofia, Sofia City, Bulgaria",
  "Malzi, Kukës, Albania",
  "U9000CED, Comodoro Rivadavia, Chubut Province, Argentina",
  "Pembina Strip, Winnipeg, Manitoba, Canada",
  "50168, Mingo, Iowa, United States",
  "Chickasaw Forest, Florida, United States",
  "Ulyanovsky District, Kaluga, Russia",
  "453121, Sterlitamak, Bashkortostan, Russia",
  "Bateshwar Rural Municipality, Janakpur, Nepal",
  "Nelson, Wales, United Kingdom",
  "17600, Çanakkale, Çanakkale, Türkiye",
  "40-750, Katowice, Śląskie, Poland",
  "5270, Odense, Region of Southern Denmark, Denmark",
  "35500, Arrecife, Canary Islands, Spain",
  "416507, Akhtubinsk, Astrakhan’, Russia",
  "Ross (Fallon), County Mayo, Ireland",
  "Campinas, São Paulo, Brazil",
  "BT28 2BP, Lisburn, Northern Ireland, United Kingdom",
  "96064, Montague, California, United States",
  "28216, Charlotte, North Carolina, United States",
  "358004, Elista, Kalmykia, Russia",
  "Kalmar kommun, Kalmar County, Sweden",
  "Tartu, Tartumaa, Estonia",
  "Orduña-Urduña, Basque Country, Spain",
  "624333, Krasnoural’sk, Sverdlovsk, Russia",
  "Scott Depot, West Virginia, United States",
  "Beed district, India",
  "S13K0A5, Ekibastuz, Pavlodar Region, Kazakhstan",
  "R25G2B8, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "55927, Dodge Center, Minnesota, United States",
  "302 45, Halmstad, Halland County, Sweden",
  "653000, Prokopyevsk, Kemerovo, Russia",
  "77979, Port Lavaca, Texas, United States",
  "110001, Piteşti, Argeş, Romania",
  "Greater Toronto Area, Canada",
  "367009, Makhachkala, Dagestan, Russia",
  "Yenipazar, Bilecik, Türkiye",
  "831 52, Östersund, Jämtland County, Sweden",
  "Koriukivka Raion, Chernihiv, Ukraine",
  "427794, Mozhga, Udmurtia, Russia",
  "Henry, Tennessee, United States",
  "Danville, Kentucky, United States",
  "67422, Bennington, Kansas, United States",
  "624981, Serov, Sverdlovsk, Russia",
  "Lebanon, New Jersey, United States",
  "Belmont, Queensland, Australia",
  "10333, Garvín, Extremadura, Spain",
  "Arras-en-Lavedan, Occitanie, France",
  "2629, Dupnitsa Municipality, Kyustendil, Bulgaria",
  "Silver Creek, New York, United States",
  "24143, Radford, Virginia, United States",
  "Kunszentmárton District, Jász-Nagykun-Szolnok, Hungary",
  "167005, Syktyvkar, Komi, Russia",
  "F91 TN0C, Sligo, County Sligo, Ireland",
  "Waterman, Harrisonburg, Virginia, United States",
  "74337, Chouteau, Oklahoma, United States",
  "450059, Ufa, Bashkortostan, Russia",
  "PE8 5PG, Barnwell, England, United Kingdom",
  "Vinton, Louisiana, United States",
  "Sainte-Julie, Quebec, Canada",
  "WA4 4AD, Daresbury, England, United Kingdom",
  "55082, Stillwater, Minnesota, United States",
  "3216, Belmont, Victoria, Australia",
  "35031, Blountsville, Alabama, United States",
  "655011, Abakan, Khakassia, Russia",
  "44-100, Gliwice, Śląskie, Poland",
  "Baranovichskiy gorodskoy sovet, Brest, Belarus",
  "Panevėžys, Panevėžio, Lithuania",
  "99090, Erfurt, Thuringia, Germany",
  "Anderson County, Tennessee, United States",
  "Oxford, North Carolina, United States",
  "Titus County, Texas, United States",
  "T12 DW3R, Cork, County Cork, Ireland",
  "Lukovit, Lovech, Bulgaria",
  "Dicle, Diyarbakır, Türkiye",
  "Cardiff, Pennsylvania, United States",
  "Achinsky District, Krasnoyarsk Krai, Russia",
  "Progressovskoye, Rostov, Russia",
  "Phillipsville, California, United States",
  "03884, Strafford, New Hampshire, United States",
  "358011, Elista, Kalmykia, Russia",
  "188565, Slantsy, Leningrad, Russia",
  "Blandebjerg, Region of Southern Denmark, Denmark",
  "Dillon Beach, California, United States",
  "Greater Lyon Area",
  "Timiryazevsky District, Moscow City, Russia",
  "Fort Simpson, Northwest Territories, Canada",
  "662971, Zheleznogorsk, Krasnoyarsk Krai, Russia",
  "659335, Biysk, Altai Krai, Russia",
  "634009, Tomsk, Tomsk, Russia",
  "Carbon, Alberta, Canada",
  "97393, Umán, Yucatán, Mexico",
  "Sweetwater, Texas, United States",
  "78611, Burnet, Texas, United States",
  "Dmitriyev-L’govskiy, Kursk, Russia",
  "Stoddard, Wisconsin, United States",
  "Major Vieira, Santa Catarina, Brazil",
  "Arthur, Iowa, United States",
  "89002, Henderson, Nevada, United States",
  "Bledsoe County, Tennessee, United States",
  "Licking, Indiana, United States",
  "Stanton, Texas, United States",
  "15400, Burdur, Burdur, Türkiye",
  "Cantanhede, Coimbra, Portugal",
  "28045, Madrid, Community of Madrid, Spain",
  "Balaklava District, Sevastopol, Sevastopol, Ukraine",
  "456900, Bakal, Chelyabinsk, Russia",
  "Canadian, Texas, United States",
  "6230-200, Fundão, Castelo Branco, Portugal",
  "652353, Topki, Kemerovo, Russia",
  "Volzhskiy, Orenburg, Russia",
  "24016, Roanoke, Virginia, United States",
  "Medina, Washington, United States",
  "Belgrade, Minnesota, United States",
  "Victoria, Seychelles",
  "623409, Kamensk Uralskiy, Sverdlovsk, Russia",
  "27615, Raleigh, North Carolina, United States",
  "Castelvetro di Modena, Emilia-Romagna, Italy",
  "B61E0A6, Sarqan, Jetisu Region, Kazakhstan",
  "Grahamdale, Manitoba, Canada",
  "Anadia, Aveiro, Portugal",
  "Tülkibas District, Turkistan Region, Kazakhstan",
  "Ain Zara, Tripoli, Libya",
  "74736, Garvin, Oklahoma, United States",
  "Hayesville, North Carolina, United States",
  "Newton, Iowa, United States",
  "Ashland, Massachusetts, United States",
  "Akhtubinsky District, Astrakhan’, Russia",
  "Bradley, Illinois, United States",
  "Valley, Nebraska, United States",
  "542 40, Mariestad, Västra Götaland County, Sweden",
  "Hart bei Graz, Styria, Austria",
  "Orangeburg Gardens, Modesto, California, United States",
  "Atoka, Oklahoma, United States",
  "35040, İzmir, İzmir, Türkiye",
  "67200, Kokkola, Central Ostrobothnia, Finland",
  "Krasnoarmeysk, Volgograd, Russia",
  "Bowie, Texas, United States",
  "Jonesville, Michigan, United States",
  "Morton, England, United Kingdom",
  "Owings Mills, Maryland, United States",
  "DT11 0NZ, Higher Whatcombe, England, United Kingdom",
  "Pokhvistnevo, Orenburg, Russia",
  "37888, Washburn, Tennessee, United States",
  "Little Rock Metropolitan Area",
  "Guinea",
  "Lampasas, Texas, United States",
  "507 64, Borås, Västra Götaland County, Sweden",
  "Hughes, Australian Capital Territory, Australia",
  "Forest, Virginia, United States",
  "32900, Isparta, Isparta, Türkiye",
  "Dzhankoy Raion, Crimea, Ukraine",
  "Seneca County, Ohio, United States",
  "665408, Cheremkhovo, Irkutsk, Russia",
  "30127, Powder Springs, Georgia, United States",
  "Gavarret-sur-Aulouste, Occitanie, France",
  "461041, Buzuluk, Orenburg, Russia",
  "655163, Chernogorsk, Khakassia, Russia",
  "Lerik District, Lankaran-Astara, Azerbaijan",
  "F63C6D8, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "Lancaster, New York, United States",
  "694496, Okha, Sakhalin, Russia",
  "Thayer, Illinois, United States",
  "29003, Málaga, Andalusia, Spain",
  "Taganrog, Rostov, Russia",
  "50525, Clarion, Iowa, United States",
  "CF3 0UG, Cardiff, Wales, United Kingdom",
  "9408 AJ, Assen, Drenthe, Netherlands",
  "2020, Shijak, Durres, Albania",
  "Armstrong, Missouri, United States",
  "New Ipswich, New Hampshire, United States",
  "EX4 8LW, Exeter, England, United Kingdom",
  "Churchill Falls, Newfoundland and Labrador, Canada",
  "Rossville, Georgia, United States",
  "Gornja Rijeka, Koprivnicko-Krizevacka, Croatia",
  "PE1 9LJ, Peterborough, England, United Kingdom",
  "58013, Cayuga, North Dakota, United States",
  "Nishinomiya, Hyogo, Japan",
  "Dudinka, Krasnoyarsk Krai, Russia",
  "753 18, Uppsala, Uppsala County, Sweden",
  "Hickman, Tennessee, United States",
  "Millers Creek, North Carolina, United States",
  "Vale de Cambra, Aveiro, Portugal",
  "Dobrova, Ljubljana, Slovenia",
  "02005, Albacete, Castile-La Mancha, Spain",
  "Silver Spring, Maryland, United States",
  "B61D5E7, Sarqan, Jetisu Region, Kazakhstan",
  "47014, Valladolid, Castilla and Leon, Spain",
  "Greene County, Georgia, United States",
  "Simpson, Saskatchewan, Canada",
  "Şalqar, Aqtöbe region, Kazakhstan",
  "Chirpan, Stara Zagora, Bulgaria",
  "694424, Aleksandrovsk-Sakhalinskiy, Sakhalin, Russia",
  "Rutger–Steuben Park Historic District, New York, United States",
  "Livingston, Texas, United States",
  "SS2 5RN, Southend-On-Sea, England, United Kingdom",
  "Hoodoo 401, Saskatchewan, Canada",
  "9260, Aalborg, North Denmark Region, Denmark",
  "Dortmund, North Rhine-Westphalia, Germany",
  "Decatur County, Tennessee, United States",
  "53215, Milwaukee, Wisconsin, United States",
  "Holtsville, New York, United States",
  "Greater Szeged Area",
  "Harrison, Tennessee, United States",
  "Greater Columbus Area",
  "3150, Tønsberg, Vestfold og Telemark, Norway",
  "Kutaisi, Georgia",
  "Rio Maior, Santarém, Portugal",
  "G0Y 1G0, Nantes, Quebec, Canada",
  "57078, Yankton, South Dakota, United States",
  "82646, Natrona, Wyoming, United States",
  "Aqtaw Q.Ä., Mangystau Region, Kazakhstan",
  "R0C 0Y0, Faulkner, Manitoba, Canada",
  "46117, Charlottesville, Indiana, United States",
  "150000, Yaroslavl, Yaroslavl’, Russia",
  "P0A 1M0, Kearney, Ontario, Canada",
  "Tyrrellspass, County Westmeath, Ireland",
  "29080, Lynchburg, South Carolina, United States",
  "37150, Kastamonu, Kastamonu, Türkiye",
  "Dresden, Ohio, United States",
  "McLeod, Kitimat-Stikine B, British Columbia, Canada",
  "La Grande Béroche, Neuchâtel, Switzerland",
  "9107, Urnäsch, Appenzell Outer-Rhoden, Switzerland",
  "Louisa Woods, Florida, United States",
  "Wood Buffalo, Alberta, Canada",
  "99202, Spokane, Washington, United States",
  "Greenbrier, Canaan 225, Saskatchewan, Canada",
  "Laos",
  "Nizhniy Lomov, Penza, Russia",
  "IP33 1YG, Bury St Edmunds, England, United Kingdom",
  "80403, Golden, Colorado, United States",
  "22304, Alexandria, Virginia, United States",
  "Harlan County, Nebraska, United States",
  "49437, Montague, Michigan, United States",
  "2000, Neuchâtel, Neuchâtel, Switzerland",
  "99201, Spokane, Washington, United States",
  "Wicklow, County Wicklow, Ireland",
  "Galway, County Galway, Ireland",
  "3371 AA, Hardinxveld-Giessendam, South Holland, Netherlands",
  "Lincoln Park, Michigan, United States",
  "Redwood Meadows, Alberta, Canada",
  "Blountsville, Alabama, United States",
  "180024, Pskov, Pskov, Russia",
  "080001, Barranquilla, Atlántico, Colombia",
  "IP31 3TJ, Thurston, England, United Kingdom",
  "347905, Taganrog, Rostov, Russia",
  "2818, Gjøvik, Innlandet, Norway",
  "Jelgava Municipality, Latvia",
  "Tioga, Adjala-Tosorontio, Ontario, Canada",
  "23112, Chesterfield, Virginia, United States",
  "825300, Măcin, Tulcea, Romania",
  "Bodø, Nordland, Norway",
  "G1 1AB, Glasgow, Scotland, United Kingdom",
  "23917, Boydton, Virginia, United States",
  "57106, Sioux Falls, South Dakota, United States",
  "55407, Minneapolis, Minnesota, United States",
  "Bollnäs, Gävleborg County, Sweden",
  "42330, Zimapán, Hidalgo, Mexico",
  "Oulu, North Ostrobothnia, Finland",
  "2600, Russell, Australian Capital Territory, Australia",
  "Taylors, South Carolina, United States",
  "Salem County, New Jersey, United States",
  "Golf Shores, Galveston, Texas, United States",
  "Scottish Borders, Scotland, United Kingdom",
  "Chitari Dungarpur, India",
  "Alcorn, Burton, New Brunswick, Canada",
  "Canton of Angers-1, Pays de la Loire, France",
  "627757, Mezenka, Tyumen’, Russia",
  "Burlacu, Cahul District, Moldova",
  "634050, Tomsk, Tomsk, Russia",
  "606037, Dzerzhinsk, Nizhniy Novgorod, Russia",
  "Greater Cumberland Area",
  "Belleville, New Jersey, United States",
  "UB4 0HN, Hayes, England, United Kingdom",
  "Richland County, Illinois, United States",
  "Les Malheuries, Courdemanche, Pays de la Loire, France",
  "Olyokminsk, Sakha, Russia",
  "Providence, Kentucky, United States",
  "Eskişehir, Eskişehir, Türkiye",
  "65009, Odesa, Odessa, Ukraine",
  "Bonneville, Auvergne-Rhône-Alpes, France",
  "P7J 1A5, Fort William 52, Ontario, Canada",
  "Fort Meade, Maryland, United States",
  "Oneida, Kentucky, United States",
  "09119, Chemnitz, Saxony, Germany",
  "Shelby, Mississippi, United States",
  "9005, Lushnjë, Fier, Albania",
  "Oğuzlar, Çorum, Türkiye",
  "141321, Sergiyev Posad, Moscow, Russia",
  "Aurora, Nebraska, United States",
  "247611, Barysawshchyna, Homel, Belarus",
  "7400-034, Ponte de Sor, Portalegre, Portugal",
  "11130, Bilecik, Bilecik, Türkiye",
  "Beltrami, Valperga, Piedmont, Italy",
  "Zavala, Chiapas, Mexico",
  "Powhatan Rural Historic District, United States",
  "75-257, Koszalin, Zachodniopomorskie, Poland",
  "65170, Vaasa, Ostrobothnia, Finland",
  "Providence, Rhode Island, United States",
  "Newton, Mississippi, United States",
  "8865, Niederurnen, Glarus, Switzerland",
  "45910, Kouvola, Kymenlaakso, Finland",
  "Akdağmadeni, Yozgat, Türkiye",
  "Cascade Locks, Oregon, United States",
  "8502, Frauenfeld, Thurgau, Switzerland",
  "624980, Serov, Sverdlovsk, Russia",
  "Parmana, Guarico State, Venezuela",
  "Ventava, Ventspils, Latvia",
  "Tillman County, Oklahoma, United States",
  "Weston, Bath, England, United Kingdom",
  "s-Hertogenbosch Area",
  "692041, Lesozavodsk, Primorye, Russia",
  "X38F9G4, Kentau, Turkistan Region, Kazakhstan",
  "4910-185, Caminha, Viana do Castelo, Portugal",
  "130146, Târgovişte, Dîmboviţa, Romania",
  "906 55, Umeå, Västerbotten County, Sweden",
  "BA3 5UX, Faulkland, England, United Kingdom",
  "80335, Munich, Bavaria, Germany",
  "Park Meigs, Rochester, New York, United States",
  "51248, Sanborn, Iowa, United States",
  "Rzeszowski, Podkarpackie, Poland",
  "Carlton, Victoria, Australia",
  "Archer County, Texas, United States",
  "Rrëshen, Lezhë, Albania",
  "Granada, Nicaragua",
  "Nicollet Island, Minneapolis, Minnesota, United States",
  "Oktyabrsky, Vladimir, Russia",
  "Clifford Chambers, England, United Kingdom",
  "Newberry Springs, California, United States",
  "R95 W6YY, Kilkenny, County Kilkenny, Ireland",
  "Kapanewon Girimulyo, Yogyakarta, Indonesia",
  "83802, Avery, Idaho, United States",
  "50447, Kanawha, Iowa, United States",
  "Florence, Alabama, United States",
  "N0C 1B0, Dundalk, Ontario, Canada",
  "653002, Prokopyevsk, Kemerovo, Russia",
  "357746, Kislovodsk, Stavropol, Russia",
  "Kamenets-Podol'skiy, Khmelnytsky, Ukraine",
  "İlkadım, Samsun, Türkiye",
  "Darlington, Indiana, United States",
  "ZE1 0NS, Lerwick, Scotland, United Kingdom",
  "75161, Terrell, Texas, United States",
  "Martin, Georgia, United States",
  "Marion County, Iowa, United States",
  "403534, Frolovo, Volgograd, Russia",
  "627754, Ishim, Tyumen’, Russia",
  "Siret, Suceava, Romania",
  "Randolph County, Arkansas, United States",
  "Forest Park, Illinois, United States",
  "Stewartstown, Northern Ireland, United Kingdom",
  "Greater Pula Area",
  "Shannon City, Iowa, United States",
  "Primošten, Sibenik-Knin, Croatia",
  "97180, Mérida, Yucatán, Mexico",
  "Refugio Viejo, Oaxaca, Mexico",
  "McDowell Lake, Kenora, Unorganized, Ontario, Canada",
  "20124, Milan, Lombardy, Italy",
  "Bol’shaya Kirsanovka, Rostov, Russia",
  "Musselshell, Montana, United States",
  "Kassel’skiy, Chelyabinsk, Russia",
  "359221, Kaspiyskiy, Kalmykia, Russia",
  "Yemen",
  "16873, Clearfield, Pennsylvania, United States",
  "Faulkton, South Dakota, United States",
  "Faulkland, County Monaghan, Ireland",
  "20001, San Sebastián, Basque Country, Spain",
  "414000, Astrakhan, Astrakhan’, Russia",
  "Lgov, Kursk, Russia",
  "Lanovetska miska hromada, Ternopil, Ukraine",
  "Logan County, Ohio, United States",
  "Lewis Center, Ohio, United States",
  "7314, Bitola, Pelagonia, North Macedonia",
  "St. Louis City County, Missouri, United States",
  "352738, Timashevsk, Krasnodar, Russia",
  "Cambridge, Ontario, Canada",
  "Richlands, Virginia, United States",
  "02366, Carver, Massachusetts, United States",
  "Dobbs Ferry, New York, United States",
  "Dover, Arkansas, United States",
  "5800, Pleven, Pleven, Bulgaria",
  "Shupiyan, Jammu & Kashmir, India",
  "Harmony, California, United States",
  "Terry, Mississippi, United States",
  "689450, Bilibino, Chukot, Russia",
  "Treasure Island, Florida, United States",
  "Saunders, Sint Maarten",
  "75706, Tyler, Texas, United States",
  "45000, Orléans, Centre-Val de Loire, France",
  "Jardim Kenedy I, Poços de Caldas, Minas Gerais, Brazil",
  "Somerset East, Eastern Cape, South Africa",
  "Hauteville-lès-Dijon, Bourgogne-Franche-Comté, France",
  "Athens, Texas, United States",
  "Greater Ancona Metropolitan Area",
  "90503, Torrance, California, United States",
  "Butler, Wisconsin, United States",
  "Greater Santander Metropolitan Area",
  "Noginskoye, Vologda, Russia",
  "174409, Borovichi, Novgorod, Russia",
  "454000, Chelyabinsk, Chelyabinsk, Russia",
  "632793, Karasuk, Novosibirsk, Russia",
  "La Ville Audrain, Plessala, Brittany, France",
  "Grant, Michigan, United States",
  "Ferme Lenoir, Saint-Jacques-sur-Darnétal, Normandy, France",
  "167019, Syktyvkar, Komi, Russia",
  "429122, Shumerlya, Chuvashia, Russia",
  "84790, St George, Utah, United States",
  "186210, Kondopoga, Karelia, Russia",
  "Nikolayevsk, Volgograd, Russia",
  "52005, Kaunas, Kauno, Lithuania",
  "Gallipoli, Çanakkale, Türkiye",
  "1991, Sion, Valais, Switzerland",
  "Şolaqqorğan, Turkistan Region, Kazakhstan",
  "IV1 1DW, Inverness, Scotland, United Kingdom",
  "Hutchinson, Minnesota, United States",
  "Bristol, Tennessee, United States",
  "Sierra 6, Selangor, Malaysia",
  "186424, Segezha, Karelia, Russia",
  "Ceuta, Ceuta, Spain",
  "Grodno District, Hrodna, Belarus",
  "9700-068, Angra do Heroísmo, Portugal",
  "Mayeste, Pomarez, Nouvelle-Aquitaine, France",
  "77642, Port Arthur, Texas, United States",
  "200009, Craiova, Dolj, Romania",
  "20500, Turku, Southwest Finland, Finland",
  "453315, Kumertau, Bashkortostan, Russia",
  "357905, Zelenokumsk, Stavropol, Russia",
  "Ovanåkers kommun, Gävleborg County, Sweden",
  "676455, Svobodnyy, Amur, Russia",
  "Murray, Nebraska, United States",
  "188805, Vyborg, Leningrad, Russia",
  "Mayberry, Terrell 101, Saskatchewan, Canada",
  "Athens, Attiki, Greece",
  "Grayson Valley, Alabama, United States",
  "87515, Mariupol, Donetsk, Ukraine",
  "460052, Orenburg, Orenburg, Russia",
  "Pearl, Mississippi, United States",
  "356418, Blagodarnyy, Stavropol, Russia",
  "Parakar, Armavir Province, Armenia",
  "02368, Randolph, Massachusetts, United States",
  "61078, Shannon, Illinois, United States",
  "612740, Omutninsk, Kirov, Russia",
  "Šakiai District Municipality, Marijampolės, Lithuania",
  "624479, Severoural’sk, Sverdlovsk, Russia",
  "185 45, Piraeus, Attiki, Greece",
  "Oviedo, Principality of Asturias, Spain",
  "Beadle County, South Dakota, United States",
  "Starrberg, St Gallen, Switzerland",
  "Riceville, Tennessee, United States",
  "Karnataka, India",
  "Goshen, New York, United States",
  "658080, Novoaltaysk, Altai Krai, Russia",
  "Wakefield, New York, New York, United States",
  "871 50, Härnösand, Västernorrland County, Sweden",
  "Karsin, Pomorskie, Poland",
  "442895, Serdobsk, Penza, Russia",
  "E7L 1L7, Divide, New Brunswick, Canada",
  "Coleman, Oklahoma, United States",
  "641432, Kurtamysh, Kurgan, Russia",
  "Reeves County, Texas, United States",
  "6029, Innsbruck, Tyrol, Austria",
  "302 31, Halmstad, Halland County, Sweden",
  "648581, Turukhansk, Krasnoyarsk Krai, Russia",
  "Bina, Baku Ekonomic Zone, Azerbaijan",
  "97065, Wasco, Oregon, United States",
  "43100, Kutahya, Kütahya, Türkiye",
  "Woodstock, Georgia, United States",
  "606420, Balakhna, Nizhniy Novgorod, Russia",
  "654 65, Karlstad, Värmland County, Sweden",
  "557260, Șelimbăr, Sibiu, Romania",
  "Crookfur, Glasgow, Scotland, United Kingdom",
  "X5000MVM, Cordoba, Córdoba, Argentina",
  "Beaver, Oklahoma, United States",
  "Pierce County, Wisconsin, United States",
  "33580, Tampere, Pirkanmaa, Finland",
  "8852, Sliven, Sliven, Bulgaria",
  "79102, Freiburg, Baden-Württemberg, Germany",
  "Butler, Indiana, United States",
  "Dixons Creek, Victoria, Australia",
  "Rice, Ohio, United States",
  "Jena, Thuringia, Germany",
  "Kampung Jenagur, Terengganu, Malaysia",
  "Bristol, Indiana, United States",
  "PH33 6HR, Fort William, Scotland, United Kingdom",
  "307250, Kurchatov, Kursk, Russia",
  "Warsaw, New York, United States",
  "Albemarle, Brakpan, Gauteng, South Africa",
  "360002, Nalchik, Kabardino-Balkaria, Russia",
  "Zyryanka, Sakha, Russia",
  "Reims-la-Brûlée, Grand Est, France",
  "Passaic, New Jersey, United States",
  "423820, Naberezhnyye Chelny, Tatarstan, Russia",
  "95616, Davis, California, United States",
  "Irkutsk, Russia",
  "79930-000, Aral Moreira, Mato Grosso do Sul, Brazil",
  "Putnam County, Illinois, United States",
  "Meagher County, Montana, United States",
  "2317, Port Stephens, New South Wales, Australia",
  "44357, Dortmund, North Rhine-Westphalia, Germany",
  "Johnstonebridge, Scotland, United Kingdom",
  "72168, Tucker, Arkansas, United States",
  "624269, Asbest, Sverdlovsk, Russia",
  "69358, Morrill, Nebraska, United States",
  "Angola",
  "Afghanistan",
  "16147, Genoa, Liguria, Italy",
  "Pennington County, Minnesota, United States",
  "Cortland Park, Winter Park, Florida, United States",
  "BH9 1AA, Bournemouth, England, United Kingdom",
  "Wallersdorf, Bavaria, Germany",
  "98310, Kemijärvi, Lapland, Finland",
  "Oktyabr'skiy, Moscow, Russia",
  "99218, Spokane, Washington, United States",
  "Rogers, Ohio, United States",
  "Grayvoron, Belgorod, Russia",
  "Kissonerga, Paphos, Cyprus",
  "84320, Kramatorsk, Donetsk, Ukraine",
  "Pecos Park, Gilbert, Arizona, United States",
  "25832, Daniels, West Virginia, United States",
  "Garfield County, Montana, United States",
  "367014, Makhachkala, Dagestan, Russia",
  "Sawyer, Michigan, United States",
  "C22C0X8, Atbasar, Akmola Region, Kazakhstan",
  "Roven’ki, Belgorod, Russia",
  "OX10 6LX, Benson, England, United Kingdom",
  "Cooke County, Texas, United States",
  "McIntosh, Minnesota, United States",
  "Greater Santiago de Compostela Metropolitan Area",
  "453830, Sibay, Bashkortostan, Russia",
  "Dillon County, South Carolina, United States",
  "21702, Frederick, Maryland, United States",
  "Rondônia, Brazil",
  "Timor-Leste",
  "Hindsgavlsbro, Region of Southern Denmark, Denmark",
  "45-030, Opole, Opolskie, Poland",
  "80-382, Gdańsk, Pomorskie, Poland",
  "Noble County, Oklahoma, United States",
  "Somalia",
  "Randall Mill, Atlanta, Georgia, United States",
  "C25A5C9, Makinsk, Akmola Region, Kazakhstan",
  "North Goa, Goa, India",
  "301364, Aleksin, Kaluga, Russia",
  "Rogers, Round Rock, Texas, United States",
  "Corsons, Kawartha Lakes, Ontario, Canada",
  "Oktyabrsky, Bashkortostan, Russia",
  "Funchal, Madeira Island, Portugal",
  "07750, Monmouth Beach, New Jersey, United States",
  "Harmony, New Jersey, United States",
  "2312, Spodnje Hoče, Maribor, Slovenia",
  "368302, Kaspiysk, Dagestan, Russia",
  "676283, Tynda, Amur, Russia",
  "Mayotte",
  "Les Bertières, Saint-Aubin-le-Cloud, Nouvelle-Aquitaine, France",
  "220051, Minsk, Minsk, Belarus",
  "2304, Pernik, Pernik, Bulgaria",
  "452680, Neftekamsk, Bashkortostan, Russia",
  "385002, Maykop, Adygea, Russia",
  "98125, Messina, Sicily, Italy",
  "54010, Mykolaiv, Mykolayiv, Ukraine",
  "8560, Peyia, Paphos, Cyprus",
  "A92 ED6V, Drogheda, Ireland",
  "Robesonia Furnace Historic District, Robesonia, Pennsylvania, United States",
  "17601, Lancaster, Pennsylvania, United States",
  "Churchville, New York, United States",
  "C22A2A6, Atbasar, Akmola Region, Kazakhstan",
  "McDonald, New Mexico, United States",
  "Miller, Indiana, United States",
  "Bobruysk, Mahilyow, Belarus",
  "8315, Svolvær, Nordland, Norway",
  "Lamouré, Moncaup, Nouvelle-Aquitaine, France",
  "Lander County, Nevada, United States",
  "491 50, Kérkira, Ionian Islands, Greece",
  "Ljusdal, Gävleborg County, Sweden",
  "39500, Kirklareli, Kırklareli, Türkiye",
  "22039, Fairfax Station, Virginia, United States",
  "06480, Ankara, Ankara, Türkiye",
  "28602, Hickory, North Carolina, United States",
  "Burnt Yates, England, United Kingdom",
  "Şefaatli, Yozgat, Türkiye",
  "446011, Syzran, Samara, Russia",
  "Cimarron, Florida, United States",
  "43002, Tarragona, Catalonia, Spain",
  "37085, Göttingen, Lower Saxony, Germany",
  "Vichuga, Ivanovo, Russia",
  "Custer, Montana, United States",
  "Towner County, North Dakota, United States",
  "Neryungri, Sakha, Russia",
  "00560, Helsinki, Uusimaa, Finland",
  "Oktyabr’sk, Samara, Russia",
  "BT28 1SA, Lisburn, Northern Ireland, United Kingdom",
  "22102, McLean, Virginia, United States",
  "Coffeeville, Mississippi, United States",
  "820114, Tulcea, Tulcea, Romania",
  "67670, Zonguldak, Zonguldak, Türkiye",
  "St.-Calais, Pays de la Loire, France",
  "Ferryden Park, South Australia, Australia",
  "Clearfield, Wisconsin, United States",
  "Guilford, New York, United States",
  "Los Mochis, Sinaloa, Mexico",
  "McCook Field, Dayton, Ohio, United States",
  "Sandersville, Georgia, United States",
  "Brown County, Texas, United States",
  "433512, Dimitrovgrad, Ul’yanovsk, Russia",
  "Zhytomyr Metropolitan Area",
  "10146, Turin, Piedmont, Italy",
  "Camden, Tennessee, United States",
  "369009, Cherkessk, Karachay-Cherkessia, Russia",
  "Hawkins, Texas, United States",
  "Edmunds County, South Dakota, United States",
  "Pineville, North Carolina, United States",
  "Muskogee, Oklahoma, United States",
  "Sandusky South, Ohio, United States",
  "Sutton, Quebec, Canada",
  "Oklahoma City Metropolitan Area",
  "428025, Cheboksary, Chuvashia, Russia",
  "Liverpool Plains, New South Wales, Australia",
  "410554, Oradea, Bihor, Romania",
  "Wibaux Commercial Historic District, Wibaux, Montana, United States",
  "Buchanan, North Dakota, United States",
  "658083, Novoaltaysk, Altai Krai, Russia",
  "Starrn, Oberneukirchen, Bavaria, Germany",
  "Basse-Terre, Basse-Terre, Guadeloupe",
  "491 32, Kérkira, Ionian Islands, Greece",
  "Sauðárkrókur, Northwestern Region, Iceland",
  "48003, Bilbao, Basque Country, Spain",
  "Ryazan Metropolitan Area",
  "Grady County, Oklahoma, United States",
  "26130, Eskişehir, Eskişehir, Türkiye",
  "60129, Ancona, Marches, Italy",
  "Graniteville, Vermont, United States",
  "Granite City, Illinois, United States",
  "5529, Haugesund, Rogaland, Norway",
  "45100, Kouvola, Kymenlaakso, Finland",
  "47280, Ozalj, Karlovac, Croatia",
  "Cottonport, Louisiana, United States",
  "Ivano-Frankivska selyshchna hromada, Lviv, Ukraine",
  "34137, Trieste, Friuli-Venezia Giulia, Italy",
  "Norman Park, Queensland, Australia",
  "628412, Surgut, Khanty-Mansi, Russia",
  "Youngstown, Florida, United States",
  "34000, Montpellier, Occitanie, France",
  "Converse, Louisiana, United States",
  "Oregon, Ohio, United States",
  "59718, Bozeman, Montana, United States",
  "Webster, Minnesota, United States",
  "West Haakon, South Dakota, United States",
  "Hanover, Michigan, United States",
  "Williamson, Arizona, United States",
  "75835, Crockett, Texas, United States",
  "Fribourg, Fribourg, Switzerland",
  "DT11 8JF, Tarrant Hinton, England, United Kingdom",
  "8520, Narvik, Nordland, Norway",
  "320119, Reşiţa, Caraş-Severin, Romania",
  "426072, Izhevsk, Udmurtia, Russia",
  "Smolensk, Russia",
  "22203, Arlington, Virginia, United States",
  "Greenwood County, South Carolina, United States",
  "90441, Haapsalu, Laane, Estonia",
  "Ayano-Maysky District, Khabarovsk, Russia",
  "Greater Florence-Muscle Shoals, Alabama Area",
  "22443, Colonial Beach, Virginia, United States",
  "Abbéville-la-Rivière, Île-de-France, France",
  "618553, Solikamsk, Perm, Russia",
  "Albemarle, North Carolina, United States",
  "Wallis and Futuna",
  "Greater Kecskemet Area",
  "262 23, Patras, Western Greece, Greece",
  "Otero, Soba, Cantabria, Spain",
  "Gillespie, Abernethy 186, Saskatchewan, Canada",
  "Gloucester County, New Jersey, United States",
  "671 32, Xánthi, Eastern Macedonia and Thrace, Greece",
  "Oxentea, Dubăsari District, Moldova",
  "2491, The Hague, South Holland, Netherlands",
  "Benson County, North Dakota, United States",
  "Greater Viana do Castelo Area",
  "Les Nobles, Montclar, Provence-Alpes-Côte d'Azur, France",
  "Callaway, Nebraska, United States",
  "Greater Dusseldorf Area",
  "305000, Kursk, Kursk, Russia",
  "Cookley, England, United Kingdom",
  "Esmeralda, Santa Cruz do Sul, Rio Grande do Sul, Brazil",
  "83452, Tetonia, Idaho, United States",
  "61570, Washburn, Illinois, United States",
  "C19C1A7, Zhaltyr, Akmola Region, Kazakhstan",
  "9711 LP, Groningen, Groningen, Netherlands",
  "38967, Winona, Mississippi, United States",
  "Greater Kayseri",
  "Gulf Palms, Galveston, Texas, United States",
  "7403, Ersekë, Korçë, Albania",
  "470, Ísafjörður, Westfjords, Iceland",
  "56362, Paynesville, Minnesota, United States",
  "656000, Barnaul, Altai Krai, Russia",
  "Cagayan Valley, Philippines",
  "Alexandria, Virginia, United States",
  "Toledo, Paraná, Brazil",
  "10178, Berlin, Berlin, Germany",
  "Dundy County, Nebraska, United States",
  "Daye, Hubei, China",
  "Stephens County, Texas, United States",
  "422550, Zelënodol'sk, Mari El, Russia",
  "Nikël, Durres, Albania",
  "44055, Lorain, Ohio, United States",
  "Biel-Benken (Basel-Land), Basel-Country, Switzerland",
  "8001, Székesfehérvár, Fejér, Hungary",
  "73945, Hooker, Oklahoma, United States",
  "Kalsdorf bei Graz, Styria, Austria",
  "362007, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "South Strafford, Vermont, United States",
  "Reagan's Run, Florida, United States",
  "624607, Alapayevsk, Sverdlovsk, Russia",
  "Kumasi, Ashanti Region, Ghana",
  "08400, Artvin, Artvin, Türkiye",
  "DT1 2TD, Dorchester, England, United Kingdom",
  "410000, Saratov, Saratov, Russia",
  "Актобе Г.А., Aqtöbe region, Kazakhstan",
  "Kadıköy, Istanbul, Türkiye",
  "Tydd St Giles, England, United Kingdom",
  "La Planche de l'Oudon, Mittois, Normandy, France",
  "Amazonas, Brazil",
  "652519, Leninsk-Kuznetskiy, Kemerovo, Russia",
  "236010, Kaliningrad, Kaliningrad, Russia",
  "352390, Kropotkin, Krasnodar, Russia",
  "65038, Odesa, Odessa, Ukraine",
  "S25B6P6, Ertis, Pavlodar Region, Kazakhstan",
  "51106, Sioux City, Iowa, United States",
  "457352, Kartaly, Chelyabinsk, Russia",
  "Pécs, Hungary",
  "19146, Philadelphia, Pennsylvania, United States",
  "46-020, Opole, Opolskie, Poland",
  "Dankov, Lipetsk, Russia",
  "75709, Tyler, Texas, United States",
  "Greenbrier, Lexington, Kentucky, United States",
  "23031, Saluda, Virginia, United States",
  "Vejlemade, Region Zealand, Denmark",
  "49032, Dnipro, Dnipropetrovsk, Ukraine",
  "Dorchester, South Carolina, United States",
  "Petrovsk, Saratov, Russia",
  "San Martino in Converseto, Borghi, Emilia-Romagna, Italy",
  "73666, Sweetwater, Oklahoma, United States",
  "Hinchingbrooke, Huntingdon, England, United Kingdom",
  "Oak Ridge, Hitchcock, Texas, United States",
  "Bedfordale, Western Australia, Australia",
  "Marathon, Texas, United States",
  "33230, Tampere, Pirkanmaa, Finland",
  "368305, Kaspiysk, Dagestan, Russia",
  "694458, Nogliki, Sakhalin, Russia",
  "Polk County, Nebraska, United States",
  "74133, Tulsa, Oklahoma, United States",
  "Košice-Západ, Kosice, Slovakia",
  "6422, Molde, Møre og Romsdal, Norway",
  "9300, Dobrich, Dobrich, Bulgaria",
  "Kourouba, Koulikoro, Mali",
  "98909, Yakima, Washington, United States",
  "Upper Pennington, Lymington, England, United Kingdom",
  "Trenton, South Carolina, United States",
  "Barinas, Barinas State, Venezuela",
  "Lausanne Metropolitan Area",
  "Tura, Krasnoyarsk Krai, Russia",
  "49673, Traverse City, Michigan, United States",
  "Racine, Ohio, United States",
  "Logroño, Morona Santiago, Ecuador",
  "Yellowstone, Alberta, Canada",
  "Ayancık, Sinop, Türkiye",
  "M1J 0A1, Scarborough, Ontario, Canada",
  "Boone, North Carolina, United States",
  "Page, Nebraska, United States",
  "Racine County, Wisconsin, United States",
  "Bollnäs kommun, Gävleborg County, Sweden",
  "75965, Nacogdoches, Texas, United States",
  "0470011, Otaru, Hokkaido, Japan",
  "Ryazhsk, Ryazan’, Russia",
  "Carlton In Lindrick, England, United Kingdom",
  "Hillsborough, Auckland, Auckland, New Zealand",
  "973 41, Luleå, Norrbotten County, Sweden",
  "46080, Kahramanmaraş, Kahraman Maras, Türkiye",
  "7886, Franz Josef Glacier, West Coast, New Zealand",
  "90531, Haapsalu, Laane, Estonia",
  "2013 XK, Haarlem, North Holland, Netherlands",
  "Alekseyevka, Mordovia, Russia",
  "Stephens County, Georgia, United States",
  "3941, St Andrews Beach, Victoria, Australia",
  "Geneva, Switzerland",
  "Ahlat Bucağı, Bitlis, Türkiye",
  "Huelva, Andalusia, Spain",
  "Polk, Nebraska, United States",
  "Asău, Bacău, Romania",
  "618513, Solikamsk, Perm, Russia",
  "141342, Sergiyev Posad, Moscow, Russia",
  "LaFayette, New York, United States",
  "Musselshell County, Montana, United States",
  "Pagerwojo, East Java, Indonesia",
  "65779, Wheatland, Missouri, United States",
  "01375, Sunderland, Massachusetts, United States",
  "Crawford County, Georgia, United States",
  "97115, Sainte-Rose, Basse-Terre, Guadeloupe",
  "40340, Jyväskylä, Central Finland, Finland",
  "67900, Kokkola, Central Ostrobothnia, Finland",
  "22907, Charlottesville, Virginia, United States",
  "Mariinskoye, Khabarovsk, Russia",
  "Skellefteå, Västerbotten County, Sweden",
  "95843, Antelope, California, United States",
  "Leavenworth, Kansas, United States",
  "Carlton Colville, Lowestoft, England, United Kingdom",
  "Schengen, Grevenmacher, Luxembourg",
  "Andrews, Texas, United States",
  "29684, Starr, South Carolina, United States",
  "627305, Golyshmanovo, Tyumen’, Russia",
  "OL1 9AA, Oldham, England, United Kingdom",
  "Michurinskiy, Novosibirsk, Russia",
  "903 36, Umeå, Västerbotten County, Sweden",
  "Lower Swainswick, Bath, England, United Kingdom",
  "Ruskington, England, United Kingdom",
  "Entlebuch, Lucerne, Switzerland",
  "Coimbra, Minas Gerais, Brazil",
  "05100, Amasya, Amasya, Türkiye",
  "Forest City, North Carolina, United States",
  "617410, Kungur, Perm, Russia",
  "21001, Aberdeen, Maryland, United States",
  "425300, Sângeorz-Băi, Bistriţa-Năsăud, Romania",
  "Grayson, Kentucky, United States",
  "Weston, Florida, United States",
  "Fulton, Kentucky, United States",
  "Parker City, Indiana, United States",
  "368586, Derbent, Dagestan, Russia",
  "08390, Artvin, Artvin, Türkiye",
  "Dyer, Nevada, United States",
  "Cook County, Minnesota, United States",
  "47169, Duisburg, North Rhine-Westphalia, Germany",
  "21001, Huelva, Andalusia, Spain",
  "353685, Yeysk, Krasnodar, Russia",
  "200051, Craiova, Dolj, Romania",
  "CA13 9HB, Cockermouth, England, United Kingdom",
  "McLeod Industrial, Winnipeg, Manitoba, Canada",
  "08836, Martinsville, New Jersey, United States",
  "97590, Kemijärvi, Lapland, Finland",
  "89701, Carson City, Nevada, United States",
  "Fayette County, Indiana, United States",
  "Onondaga Highlands–Swaneola Heights Historic District, Syracuse, New York, United States",
  "Greater Funchal Area",
  "Juaboso District, Western North Region, Ghana",
  "62378, Versailles, Illinois, United States",
  "24127, Bergamo, Lombardy, Italy",
  "Lackawanna, New York, United States",
  "Bradley, Nelson, England, United Kingdom",
  "Greater Erzurum",
  "8005, Székesfehérvár, Fejér, Hungary",
  "Randall, Kansas, United States",
  "Perry, Indiana, United States",
  "Pike County, Pennsylvania, United States",
  "Cambira, Paraná, Brazil",
  "Issaquena County, Mississippi, United States",
  "Hickory Hills, Illinois, United States",
  "360006, Nalchik, Kabardino-Balkaria, Russia",
  "186225, Kondopoga, Karelia, Russia",
  "3126, Tønsberg, Vestfold og Telemark, Norway",
  "309850, Alekseyevka, Belgorod, Russia",
  "Burleigh County, North Dakota, United States",
  "Cottbus, Brandenburg, Germany",
  "247795, Mazyr, Homel, Belarus",
  "Luzerne County, Pennsylvania, United States",
  "Vianden, Diekirch, Luxembourg",
  "Bell County, Kentucky, United States",
  "Caledonia, Mississippi, United States",
  "Desa Cibolang, Desa Gunungguruh, West Java, Indonesia",
  "20607, Narva, Ida-Virumaa, Estonia",
  "Oriental, Morocco",
  "Kingston, Kingston, Jamaica",
  "6008, Ålesund, Møre og Romsdal, Norway",
  "Howard, South Dakota, United States",
  "X1A 0A1, Yellowknife, Northwest Territories, Canada",
  "Waterford Metropolitan Area",
  "142408, Noginsk, Moscow, Russia",
  "C22C3P1, Atbasar, Akmola Region, Kazakhstan",
  "Chernivtsi, Chernivtsi, Ukraine",
  "76210, Denton, Texas, United States",
  "NN15 5YH, Burton Latimer, England, United Kingdom",
  "Genesee, Lansing, Michigan, United States",
  "35-205, Rzeszów, Podkarpackie, Poland",
  "Como, Texas, United States",
  "Beadle, Kindersley 290, Saskatchewan, Canada",
  "Noginskaya, Vologda, Russia",
  "Saint-Eustache, Quebec, Canada",
  "185 44, Piraeus, Attiki, Greece",
  "Sherman, West Virginia, United States",
  "Hamburg-Nord, Hamburg, Germany",
  "Meagher, Queens, Nova Scotia, Canada",
  "670009, Ulan-Ude, Buryatia, Russia",
  "393192, Kotovsk, Tambov, Russia",
  "601979, Kovrov, Vladimir, Russia",
  "23238, Henrico, Virginia, United States",
  "Creek County, Oklahoma, United States",
  "Los Cabos, Baja California Sur, Mexico",
  "Somerset, Tasmania, Australia",
  "SN8 1QL, Beckhampton, England, United Kingdom",
  "142402, Noginsk, Moscow, Russia",
  "20-091, Lublin, Lubelskie, Poland",
  "Chernobyl, Kyiv, Ukraine",
  "Mineral County, Nevada, United States",
  "Kamyshinskiy, Kursk, Russia",
  "656037, Barnaul, Altai Krai, Russia",
  "9600, Hammerfest, Troms og Finnmark, Norway",
  "Tashtagolsky District, Kemerovo, Russia",
  "Vyazemsky District, Smolensk, Russia",
  "Custer, South Dakota, United States",
  "82-309, Elbląg, Warmińsko-mazurskie, Poland",
  "Dickson County, Tennessee, United States",
  "51103, Cologne, North Rhine-Westphalia, Germany",
  "Holton, Kansas, United States",
  "Youngsville, Louisiana, United States",
  "8064 EA, Hasselt, Overijssel, Netherlands",
  "8820, Polis Chrysochous, Paphos, Cyprus",
  "Price, Coverdale, New Brunswick, Canada",
  "Cedar, Minnesota, United States",
  "359220, Kaspiyskiy, Kalmykia, Russia",
  "692922, Nakhodka, Primorye, Russia",
  "Augusta, Kentucky, United States",
  "Al Ritchie, Regina, Saskatchewan, Canada",
  "Eindhoven Area",
  "Laurens, Les Martys, Occitanie, France",
  "628486, Kogalym, Khanty-Mansi, Russia",
  "653033, Prokopyevsk, Kemerovo, Russia",
  "220121, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "Penderff, Saint-Gérand, Brittany, France",
  "Nyewood, England, United Kingdom",
  "Sarıağaş District, Turkistan Region, Kazakhstan",
  "EH3 7BJ, Edinburgh, Scotland, United Kingdom",
  "Perth Amboy, New Jersey, United States",
  "Nikopol Raion, Dnipropetrovsk, Ukraine",
  "Cuba",
  "Somerset County, Maryland, United States",
  "54166, Shawano, Wisconsin, United States",
  "79720, Big Spring, Texas, United States",
  "11000, Belgrade, Serbia",
  "23270, Zrenjanin, Vojvodina, Serbia",
  "Asia",
  "San Diego Metropolitan Area",
  "Skopin, Ryazan’, Russia",
  "Blanco County, Texas, United States",
  "Newark, New Jersey, United States",
  "346504, Shakhty, Rostov, Russia",
  "Shalqar, Navoiy Region, Uzbekistan",
  "Fairfield West, New South Wales, Australia",
  "Volzhskiy, Astrakhan’, Russia",
  "64453, Gentry, Missouri, United States",
  "Dolinsk, Sakhalin, Russia",
  "12922-800, Bragança Paulista, São Paulo, Brazil",
  "Greater Paris Metropolitan Region",
  "Sullivan, Maine, United States",
  "Tenango de Río Blanco, Veracruz, Mexico",
  "67464, Marquette, Kansas, United States",
  "Essex County, New York, United States",
  "39355, Quitman, Mississippi, United States",
  "791 53, Falun, Dalarna County, Sweden",
  "Norfolk, Connecticut, United States",
  "20020, Denizli, Denizli, Türkiye",
  "67800, Kokkola, Central Ostrobothnia, Finland",
  "21202, Baltimore, Maryland, United States",
  "362027, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "84118, Salt Lake City, Utah, United States",
  "Pageland, South Carolina, United States",
  "Deer Lodge, Montana, United States",
  "346881, Bataysk, Rostov, Russia",
  "Tokelau",
  "Snohomish, Washington, United States",
  "Yegoryevsky District, Moscow, Russia",
  "655152, Chernogorsk, Khakassia, Russia",
  "Osage, Minnesota, United States",
  "1784, Sofia, Sofia City, Bulgaria",
  "8250, Empa, Paphos, Cyprus",
  "Kirzhach, Vladimir, Russia",
  "85-098, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "55981, Wabasha, Minnesota, United States",
  "65126, Pescara, Abruzzi, Italy",
  "Isle Of Whithorn, Scotland, United Kingdom",
  "84045, Saratoga Springs, Utah, United States",
  "Frederick, Maryland, United States",
  "Langlade, Céaux-d’Allègre, Auvergne-Rhône-Alpes, France",
  "Yegor’yevsk, Moscow, Russia",
  "86-301, Grudziądz, Kujawsko-pomorskie, Poland",
  "1037, Tirana, Tirana, Albania",
  "Handewitt, Schleswig-Holstein, Germany",
  "152643, Otradnyy, Yaroslavl’, Russia",
  "301665, Novomoskovsk, Tula, Russia",
  "Yegor'yevskiy, Kursk, Russia",
  "Whitman, College Place, Washington, United States",
  "Gilmer, North Carolina, United States",
  "Windsor, Quebec, Canada",
  "Qanaqer-Zeytun, Yerevan, Armenia",
  "General Toshevo Municipality, Dobrich, Bulgaria",
  "Milot, Lezhë, Albania",
  "Voloshino, Rostov, Russia",
  "24103, Kiel, Schleswig-Holstein, Germany",
  "West Barnes, New Malden, England, United Kingdom",
  "Diekirch, Luxembourg",
  "Bålsta, Uppsala County, Sweden",
  "Leninsk-Kuznetskiy okrug, Kemerovo, Russia",
  "Brunswick County, North Carolina, United States",
  "Colfax, California, United States",
  "9011, Tromsø, Troms og Finnmark, Norway",
  "77027, Houston, Texas, United States",
  "Larvotto, Monaco, Monaco",
  "48642, Midland, Michigan, United States",
  "CF63 4LL, Barry, Wales, United Kingdom",
  "Delta, Utah, United States",
  "45-401, Opole, Opolskie, Poland",
  "658594, Mezentsevo, Altai Krai, Russia",
  "152908, Rybinsk, Yaroslavl’, Russia",
  "45500, Manisa, Manisa, Türkiye",
  "Burlington, Vermont, United States",
  "Şebinkarahisar District, Giresun, Türkiye",
  "Petrovskoye, Yaroslavl’, Russia",
  "Hallsville, Texas, United States",
  "Refugio, Tolima, Colombia",
  "Agia Varvara, Crete, Greece",
  "Richardson, Texas, United States",
  "Dolinsky District, Sakhalin, Russia",
  "04048, Limerick, Maine, United States",
  "Seward County, Kansas, United States",
  "634012, Tomsk, Tomsk, Russia",
  "Mozdoksky District, Severnaya Osetiya-Alaniya, Russia",
  "Wytheville, Virginia, United States",
  "MD-2005, Chişinău, Chișinău, Moldova",
  "Voznesenskaya, Krasnodar, Russia",
  "45610, Kouvola, Kymenlaakso, Finland",
  "80524, Fort Collins, Colorado, United States",
  "353490, Gelendzhik, Krasnodar, Russia",
  "11600, Bilecik, Bilecik, Türkiye",
  "75085, Richardson, Texas, United States",
  "Greater Brest Area",
  "4306, Ironbark, Queensland, Australia",
  "Sevier County, Tennessee, United States",
  "43085, Columbus, Ohio, United States",
  "Kraków, Łódzkie, Poland",
  "Todd County, Kentucky, United States",
  "Reed-Cooke, District of Columbia, United States",
  "Çankaya, Ankara, Türkiye",
  "450078, Ufa, Bashkortostan, Russia",
  "Lugareño, Camagüey, Cuba",
  "Tarare, Auvergne-Rhône-Alpes, France",
  "Sweetwater Creek, St Cloud, Florida, United States",
  "Nottingham, Pennsylvania, United States",
  "658846, Slavgorod, Altai Krai, Russia",
  "Brooklyn, New York, United States",
  "ME5 0AA, Chatham, England, United Kingdom",
  "Altai Krai, Russia",
  "626025, Cherepanovo, Tyumen’, Russia",
  "28036, Davidson, North Carolina, United States",
  "21201, Baltimore, Maryland, United States",
  "Marion County, Oregon, United States",
  "DD4 0AD, Dundee, Scotland, United Kingdom",
  "Zavet, Razgrad, Bulgaria",
  "Griggs, Arkansas, United States",
  "456810, Verkhniy Ufaley, Chelyabinsk, Russia",
  "28200, Giresun, Giresun, Türkiye",
  "Grayson Ridge, Locke, North Carolina, United States",
  "Kielecki, Świętokrzyskie, Poland",
  "Wilkinson, Central Frontenac, Ontario, Canada",
  "Friol, Galicia, Spain",
  "Crawford County, Illinois, United States",
  "Canton of Esch-sur-Alzette, Luxembourg",
  "64600, Uşak, Uşak, Türkiye",
  "Bertievale, Matatiele, Eastern Cape, South Africa",
  "Glen Allen, Virginia, United States",
  "Brampton, Ontario, Canada",
  "Trinidad and Tobago",
  "PE2 5UB, Peterborough, England, United Kingdom",
  "Allendale, Jacksonville, Florida, United States",
  "29704, Catawba, South Carolina, United States",
  "Blairmount, New South Wales, Australia",
  "V0H 1T1, Oliver, British Columbia, Canada",
  "Detroit Metropolitan Area",
  "Roseau County, Minnesota, United States",
  "Pearlah, South Australia, Australia",
  "ZE1 0AA, Lerwick, Scotland, United Kingdom",
  "84631, Fillmore, Utah, United States",
  "Staunton, Ohio, United States",
  "B25B5D9, Esik, Almaty Region, Kazakhstan",
  "Sully, Hauts-de-France, France",
  "Leninskiy, Mahilyow, Belarus",
  "Pawnee Rock, Kansas, United States",
  "Ponta Delgada, Portugal",
  "36574, Seminole, Alabama, United States",
  "Lausanne, Vaud, Switzerland",
  "Desa Jenar, Central Java, Indonesia",
  "ZE1 0PY, Lerwick, Scotland, United Kingdom",
  "L'Oudon, Saint-Pierre-sur-Dives, Normandy, France",
  "Waterloo, Ontario, Canada",
  "Le Roseau, Le Fuilet, Pays de la Loire, France",
  "16221, Sligo, Pennsylvania, United States",
  "Cockersdale, Bradford, England, United Kingdom",
  "15680, Lahti, Päijät-Häme, Finland",
  "Richmond Upon Thames, England, United Kingdom",
  "Pavlodar Q.Ä., Pavlodar Region, Kazakhstan",
  "Türksib District, Almaty, Kazakhstan",
  "Parkland Ridge, Champaign, Illinois, United States",
  "Exeter, Pennsylvania, United States",
  "Commerce, Michigan, United States",
  "Le Maine Duval, Celles, Nouvelle-Aquitaine, France",
  "Tyler, Minnesota, United States",
  "A92 FK6V, Drogheda, Ireland",
  "Amsterdam, New York, United States",
  "Liberty, New York, United States",
  "807240, Pechea, Galaţi, Romania",
  "82-317, Elbląg, Warmińsko-mazurskie, Poland",
  "Linares, Andalusia, Spain",
  "5223 AA, ’s-Hertogenbosch, North Brabant, Netherlands",
  "17020, Çanakkale, Çanakkale, Türkiye",
  "Highland, Michigan, United States",
  "C19C3Y2, Zhaltyr, Akmola Region, Kazakhstan",
  "Zlatoust, Chelyabinsk, Russia",
  "Dublin 5, County Dublin, Ireland",
  "62410, Allendale, Illinois, United States",
  "Sokolovce, Trnava Region, Slovakia",
  "Severnyy Rayon, Orenburg, Russia",
  "Sangar, Gilan Province, Iran",
  "Pozzolio, Ponderano, Piedmont, Italy",
  "Sângeorz-Băi, Bistriţa-Năsăud, Romania",
  "Foster, Oregon, United States",
  "1396, Drammen, Viken, Norway",
  "623968, Tavda, Sverdlovsk, Russia",
  "Murray, Utah, United States",
  "County Wicklow, Ireland",
  "SA2 8PP, Swansea, Wales, United Kingdom",
  "Kearney, Missouri, United States",
  "72, Brindisi, Apulia, Italy",
  "St. Louis County, Missouri, United States",
  "KA18 2PP, Ochiltree, Scotland, United Kingdom",
  "2512 KE, The Hague, South Holland, Netherlands",
  "08100, Artvin, Artvin, Türkiye",
  "78109, Siauliai, Siauliu, Lithuania",
  "Yancey, Kentucky, United States",
  "Bulle, Fribourg, Switzerland",
  "Bennington, New Hampshire, United States",
  "130007, Târgovişte, Dîmboviţa, Romania",
  "Arlington, Nebraska, United States",
  "5857, Dolna Mitropoliya, Pleven, Bulgaria",
  "Sainte-Sabine, Bourgogne-Franche-Comté, France",
  "Winston County, Alabama, United States",
  "Metropolitan City of Turin, Piedmont, Italy",
  "Rainson, Brinon-sur-Sauldre, Centre-Val de Loire, France",
  "5007, Ura Vajgurore, Berat, Albania",
  "Granville, Vermont, United States",
  "33178, Miami, Florida, United States",
  "Chebarkul’, Chelyabinsk, Russia",
  "Nehoiu, Buzău, Romania",
  "647460, Noril'sk, Taymyr, Russia",
  "25208, Wharton, West Virginia, United States",
  "Forsyth, Montana, United States",
  "51231, Archer, Iowa, United States",
  "Odense Kommune, Region of Southern Denmark, Denmark",
  "678300, Sangar, Sakha, Russia",
  "53210, Milwaukee, Wisconsin, United States",
  "Goodhue County, Minnesota, United States",
  "Team Valley, Gateshead, England, United Kingdom",
  "Bedford, Texas, United States",
  "Gloucester City, New Jersey, United States",
  "99224, Spokane, Washington, United States",
  "South Lyon, Michigan, United States",
  "Rainsola, Delang, Odisha, India",
  "442534, Kuznetsk, Penza, Russia",
  "61754, McLean, Illinois, United States",
  "6061, Charleroi, Walloon Region, Belgium",
  "653010, Prokopyevsk, Kemerovo, Russia",
  "91, Herisau, Appenzell Outer-Rhoden, Switzerland",
  "182645, Borovichi, Pskov, Russia",
  "Mount Edgecombe, Durban, KwaZulu-Natal, South Africa",
  "Permata, Aceh, Indonesia",
  "393954, Morshansk, Tambov, Russia",
  "658133, Aleysk, Altai Krai, Russia",
  "Komsomolsk, Khabarovsk, Russia",
  "46526, Goshen, Indiana, United States",
  "1006, Lausanne, Vaud, Switzerland",
  "Klickitat County, Washington, United States",
  "Avrig, Sibiu, Romania",
  "Zirc, Veszprém, Hungary",
  "Vila Nova de Fozcoa, Guarda, Portugal",
  "Murviel-lès-Béziers, Occitanie, France",
  "Silverdale, Washington, United States",
  "Giles County, Virginia, United States",
  "Shkodër, Albania",
  "627320, Golyshmanovo, Tyumen’, Russia",
  "29824, Edgefield, South Carolina, United States",
  "Terrell Heights, San Antonio, Texas, United States",
  "1230, Vienna, Vienna, Austria",
  "20740, Turku, Southwest Finland, Finland",
  "Mifflinburg, Pennsylvania, United States",
  "8501, Narvik, Nordland, Norway",
  "Uryupinskiy, Altai Krai, Russia",
  "Faulkenberry Estates, Oklahoma, United States",
  "Teteven Municipality, Lovech, Bulgaria",
  "629746, Nadym, Yamalo-Nenets, Russia",
  "Linn Creek, Missouri, United States",
  "Aiken's Tavern Historic District, Glasgow, Delaware, United States",
  "Delta Amacuro State, Venezuela",
  "Bogotol’skiy Zavod, Krasnoyarsk Krai, Russia",
  "Dali, Nicosia, Cyprus",
  "Klagenfurt-Land, Carinthia, Austria",
  "02008, Albacete, Castile-La Mancha, Spain",
  "67451, Lymanske, Odessa, Ukraine",
  "507113, Jibert, Braşov, Romania",
  "PH13 9NX, Burrelton, Scotland, United Kingdom",
  "Chez Burnet, Saint-Martin-Bellevue, Auvergne-Rhône-Alpes, France",
  "Salem, Virginia, United States",
  "2816, Gjøvik, Innlandet, Norway",
  "584 35, Linköping, Östergötland County, Sweden",
  "26886, Seneca Rocks, West Virginia, United States",
  "41075, Fort Thomas, Kentucky, United States",
  "Ruseștii Noi, Ialoveni District, Moldova",
  "585412, Chitaguppa, Karnataka, India",
  "Eğirdir, Isparta, Türkiye",
  "Linn, Texas, United States",
  "Zavala County, Texas, United States",
  "BT14 6AA, Belfast, Northern Ireland, United Kingdom",
  "South Heidelberg, Pennsylvania, United States",
  "678654, Khara-Aldan, Sakha, Russia",
  "Meaghers Grant, Nova Scotia, Canada",
  "T4107DOQ, Yerba Buena, Tucumán, Argentina",
  "Wexford County, Michigan, United States",
  "97118, Saint-François, Pointe-à-Pitre, Guadeloupe",
  "Robertsfors kommun, Västerbotten County, Sweden",
  "29414, Charleston, South Carolina, United States",
  "37211, Nashville, Tennessee, United States",
  "Slovenia",
  "Tooleybuc, New South Wales, Australia",
  "Yellowwood Park, Durban, KwaZulu-Natal, South Africa",
  "Câmpulung Moldovenesc, Suceava, Romania",
  "Sheridan, Colorado, United States",
  "Vintonia, City of Mbombela, Mpumalanga, South Africa",
  "Ponderay, Idaho, United States",
  "Coffee Plot, Ferrargunj, Andaman and Nicobar Islands, India",
  "Annecy-le-Vieux, Auvergne-Rhône-Alpes, France",
  "5616 VC, Eindhoven, North Brabant, Netherlands",
  "Pontotoc County, Mississippi, United States",
  "71029, Gilliam, Louisiana, United States",
  "Pamplona, Norte de Santander, Colombia",
  "Ellistown, England, United Kingdom",
  "29907, Beaufort, South Carolina, United States",
  "32104, Čačak, Centralna Srbija, Serbia",
  "31067, Oconee, Georgia, United States",
  "Vinton, Ohio, United States",
  "Roura, Cayenne, French Guiana",
  "2981 AA, Ridderkerk, South Holland, Netherlands",
  "Nikopol, Dnipropetrovsk, Ukraine",
  "Edirne, Edirne, Türkiye",
  "346888, Bataysk, Rostov, Russia",
  "Morrow, McKinney, Texas, United States",
  "Amasya, Amasya, Türkiye",
  "A92 PC9X, Drogheda, Ireland",
  "021211, Bucharest, Bucharest, Romania",
  "Golf Estates, Galveston, Texas, United States",
  "Wabasha, Minnesota, United States",
  "Luna, Luna, Cordillera Admin Region, Philippines",
  "Greater Palma de Mallorca Metropolitan Area",
  "710001, Botoşani, Botoşani, Romania",
  "Dovzhans’kyy, Luhansk, Ukraine",
  "Sangareddi, Telangana, India",
  "Lesotho",
  "Münster, Valais, Switzerland",
  "Belmont County, Ohio, United States",
  "Reykjavík, Capital Region, Iceland",
  "369006, Cherkessk, Karachay-Cherkessia, Russia",
  "Hawkins County, Tennessee, United States",
  "Winseler, Diekirch, Luxembourg",
  "Gallatin, Tennessee, United States",
  "74129, Tulsa, Oklahoma, United States",
  "Walshville, Illinois, United States",
  "265 04, Patras, Western Greece, Greece",
  "Kapan Community, Syunik Province, Armenia",
  "Split, Split-Dalmatia, Croatia",
  "Nusaybin, Mardin, Türkiye",
  "Helsingborgs kommun, Skåne County, Sweden",
  "626155, Tobolsk, Tyumen’, Russia",
  "Sabinov, Presov, Slovakia",
  "Tours-sur-Meymont, Auvergne-Rhône-Alpes, France",
  "48145, Münster, North Rhine-Westphalia, Germany",
  "Lyons, Australian Capital Territory, Australia",
  "75001, Paris, Île-de-France, France",
  "Stanly Ranch, Napa, California, United States",
  "Greater Stara Zagora Area",
  "Dubăsarii Vechi, Criuleni District, Moldova",
  "IP33 1UZ, Bury St Edmunds, England, United Kingdom",
  "Dugo Selo, Zagreb, Croatia",
  "356405, Blagodarnyy, Stavropol, Russia",
  "R95 X4PV, Kilkenny, County Kilkenny, Ireland",
  "Churchill, England, United Kingdom",
  "Albany County, New York, United States",
  "Bratislava, Slovakia",
  "Patran, Punjab, India",
  "Le Roseau, Rougeou, Centre-Val de Loire, France",
  "24437, Fort Defiance, Virginia, United States",
  "A92 P9C2, Drogheda, Ireland",
  "24118, Kiel, Schleswig-Holstein, Germany",
  "Polateli, Kilis, Türkiye",
  "Högsby kommun, Kalmar County, Sweden",
  "12700, Bingol, Bingöl, Türkiye",
  "1047, Skopje, Skopje Statistical Region, North Macedonia",
  "Satu Mare, Harghita, Romania",
  "09116, Chemnitz, Saxony, Germany",
  "33309, Fort Lauderdale, Florida, United States",
  "8500-801, Portimão, Faro, Portugal",
  "22901, Charlottesville, Virginia, United States",
  "Brown County, South Dakota, United States",
  "Greater Marseille Metropolitan Area",
  "190 05, Marathónas, Attiki, Greece",
  "West Main Street Historic District, Oconto, Wisconsin, United States",
  "Sergipe, Brazil",
  "48151, Münster, North Rhine-Westphalia, Germany",
  "Egerup, Region of Southern Denmark, Denmark",
  "09003, Burgos, Castilla and Leon, Spain",
  "Michurinskiy, Orenburg, Russia",
  "Atakum, Samsun, Türkiye",
  "08824, Kendall Park, New Jersey, United States",
  "SR6 7TW, Sunderland, England, United Kingdom",
  "Verona, Ontario, Canada",
  "Treasure Bay, Madison, Mississippi, United States",
  "1117, Budapest, Budapest, Hungary",
  "Mariupol Raion, Donetsk, Ukraine",
  "72935, Dyer, Arkansas, United States",
  "Callahaniska, County Kerry, Ireland",
  "Rowan, Iowa, United States",
  "Hoogezand, Groningen, Netherlands",
  "74900, Dnipriany, Kherson, Ukraine",
  "Oguzer, Dagestan, Russia",
  "45070, Guadalajara, Jalisco, Mexico",
  "692902, Nakhodka, Primorye, Russia",
  "Saint-Georges-de-Didonne, Nouvelle-Aquitaine, France",
  "302 30, Halmstad, Halland County, Sweden",
  "Greater Richmond Region",
  "68307, Mannheim, Baden-Württemberg, Germany",
  "84125, Salerno, Campania, Italy",
  "Artemovskaya, Pskov, Russia",
  "Cavalier, Porte-du-Quercy, Occitanie, France",
  "F63B6T4, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "Pickaway County, Ohio, United States",
  "689310, Uelen, Chukot, Russia",
  "19043, Holmes, Pennsylvania, United States",
  "Guadalajara, Jalisco, Mexico",
  "450075, Zalău, Sălaj, Romania",
  "Thumanë, Durres, Albania",
  "19059, Schwerin, Mecklenburg-West Pomerania, Germany",
  "7810, Namsos, Trøndelag, Norway",
  "Foster, Oklahoma, United States",
  "2707, Wiener Neustadt, Lower Austria, Austria",
  "Pylaia-Chortiatis, Central Macedonia, Greece",
  "14305, Niagara Falls, New York, United States",
  "368300, Kaspiysk, Dagestan, Russia",
  "Kuopio, Northern Savonia, Finland",
  "104 31, Athens, Attiki, Greece",
  "Eastland Gardens, Fort Wayne, Indiana, United States",
  "S10 5FZ, Sheffield, England, United Kingdom",
  "430122, Baia Mare, Maramureş, Romania",
  "Beykonağı, Edirne, Türkiye",
  "Putnam County, New York, United States",
  "39561, McHenry, Mississippi, United States",
  "29306, Spartanburg, South Carolina, United States",
  "63300, Şanlıurfa, Şanliurfa, Türkiye",
  "08290, Artvin, Artvin, Türkiye",
  "Emden, Missouri, United States",
  "371 30, Karlskrona, Blekinge County, Sweden",
  "200010, Craiova, Dolj, Romania",
  "South Versailles, Pennsylvania, United States",
  "7000-873, Évora, Évora, Portugal",
  "Erie County, Ohio, United States",
  "Aleysky District, Altai Krai, Russia",
  "47001, Valladolid, Castilla and Leon, Spain",
  "74121, Taranto, Apulia, Italy",
  "130066, Târgovişte, Dîmboviţa, Romania",
  "Hilton Head Island, South Carolina Area",
  "Ferme du Glacier, Saint-Martin-du-Tertre, Bourgogne-Franche-Comté, France",
  "Camdenton, Missouri, United States",
  "Charleston, West Virginia, United States",
  "Norton, Virginia, United States",
  "Cole Harbour, Nova Scotia, Canada",
  "Stary Oskol, Belgorod, Russia",
  "68854, Marquette, Nebraska, United States",
  "Limestone, Maine, United States",
  "Las Palmas, Canary Islands, Spain",
  "907 30, Umeå, Västerbotten County, Sweden",
  "6413, Molde, Møre og Romsdal, Norway",
  "500001, Hyderabad, Telangana, India",
  "Cleveland, Ohio, United States",
  "R7N 0A1, Dauphin, Manitoba, Canada",
  "97601, Klamath Falls, Oregon, United States",
  "51900, Kamianske, Dnipropetrovsk, Ukraine",
  "452317, Cherlak, Bashkortostan, Russia",
  "43028, Howard, Ohio, United States",
  "666940, Kropotkin, Irkutsk, Russia",
  "Woodinville, Washington, United States",
  "Lake Hughes, California, United States",
  "052284, Bucharest, Bucharest, Romania",
  "Mayen-Koblenz, Rhineland-Palatinate, Germany",
  "Snohomish Cascade, Washington, United States",
  "5604, Békéscsaba, Békés, Hungary",
  "LV-5471, Subate, Daugavpils, Latvia",
  "Concho, Arizona, United States",
  "40324, Georgetown, Kentucky, United States",
  "Batumi, Georgia",
  "9708, Szombathely, Vas, Hungary",
  "Donaustadt, Vienna, Vienna, Austria",
  "Pushmataha County, Oklahoma, United States",
  "33015, Moggio Udinese, Friuli-Venezia Giulia, Italy",
  "South Ayrshire, Scotland, United Kingdom",
  "78283, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "Izhevsk, Udmurtia, Russia",
  "43500, Kutahya, Kütahya, Türkiye",
  "Cedartown, Georgia, United States",
  "68863, Overton, Nebraska, United States",
  "24067, Callaway, Virginia, United States",
  "29006, Málaga, Andalusia, Spain",
  "29681, Simpsonville, South Carolina, United States",
  "CH45 0JA, Wallasey, England, United Kingdom",
  "Manitowoc, Wisconsin, United States",
  "352808, Tuapse, Krasnodar, Russia",
  "Murfreesboro, Tennessee, United States",
  "Charlotte, Michigan, United States",
  "97180, Sainte-Anne, Pointe-à-Pitre, Guadeloupe",
  "Warren County, Tennessee, United States",
  "Nassau, Peterborough, Ontario, Canada",
  "Montague, Prince Edward Island, Canada",
  "14010, Cisterna d’Asti, Piedmont, Italy",
  "Tyrrellstown, County Kildare, Ireland",
  "Eddyville, Nebraska, United States",
  "Berlin, Massachusetts, United States",
  "Kharkiv Raion, Kharkiv, Ukraine",
  "Eastern Cape, South Africa",
  "Lewiston, Idaho, United States",
  "54600, Villers-lès-Nancy, Grand Est, France",
  "Claiborne County, Mississippi, United States",
  "Albany, Minnesota, United States",
  "Berdorf, Grevenmacher, Luxembourg",
  "38180, Santa Cruz de Tenerife, Canary Islands, Spain",
  "931, Skellefteå, Västerbotten County, Sweden",
  "Slobodskoy, Kirov, Russia",
  "Elazığ, Elazığ, Türkiye",
  "Mcleod Ganj, Dharamshala, Himachal Pradesh, India",
  "Yangtze Delta, China",
  "Çaycuma, Zonguldak, Türkiye",
  "Sochi, Krasnodar, Russia",
  "79206, Prijedor, Serb Republic, Bosnia and Herzegovina",
  "Greene County, Mississippi, United States",
  "Hays, North Carolina, United States",
  "V92 D8H4, Tralee, County Kerry, Ireland",
  "9020-291, Funchal, Madeira Island, Portugal",
  "Haysboro, Calgary, Alberta, Canada",
  "BT27 5QB, Lisburn, Northern Ireland, United Kingdom",
  "454084, Chelyabinsk, Chelyabinsk, Russia",
  "Greenville, Kentucky, United States",
  "461631, Buguruslan, Orenburg, Russia",
  "Noyabrsk, Yamalo-Nenets, Russia",
  "352802, Tuapse, Krasnodar, Russia",
  "Brunswick, Maryland, United States",
  "Balashovo, Ingushetia, Russia",
  "McIntosh Corner Historic District, Needham, Massachusetts, United States",
  "SO16 2AF, Southampton, England, United Kingdom",
  "30113, Buchanan, Georgia, United States",
  "41647, McDowell, Kentucky, United States",
  "West Valley City, Utah, United States",
  "L10G3E8, Chapaev, West Kazakhstan Region, Kazakhstan",
  "Minnehaha Cove, Maitland, Florida, United States",
  "Delaware County, Ohio, United States",
  "McKenzie Island, Ontario, Canada",
  "Ain Zara center, Tripoli, Libya",
  "363756, Mozdok, Severnaya Osetiya-Alaniya, Russia",
  "Ashland County, Ohio, United States",
  "Bernese Jura, Berne, Switzerland",
  "225706, Pinsk, Brest, Belarus",
  "Dodge County, Wisconsin, United States",
  "Robesonia, Pennsylvania, United States",
  "Kayseri, Kayseri, Türkiye",
  "300 27, Agrínio, Western Greece, Greece",
  "St Maries, Idaho, United States",
  "423823, Naberezhnyye Chelny, Tatarstan, Russia",
  "62185, Nielles-lès-Calais, Hauts-de-France, France",
  "Moss Landing, California, United States",
  "Schuylkill County, Pennsylvania, United States",
  "74105, Tulsa, Oklahoma, United States",
  "75211, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "680013, Khabarovsk, Khabarovsk, Russia",
  "309183, Gubkin, Belgorod, Russia",
  "Mossurize, Manica Province, Mozambique",
  "02816, Coventry, Rhode Island, United States",
  "52341, Toddville, Iowa, United States",
  "Rusheylake, Saundersfoot, Wales, United Kingdom",
  "WR10 2JS, Throckmorton, England, United Kingdom",
  "Telangana, India",
  "South Asia",
  "21212, Baltimore, Maryland, United States",
  "682973, Bikin, Khabarovsk, Russia",
  "Western Africa",
  "Howell, Arkansas, United States",
  "Oviedo, Florida, United States",
  "Vorsino, Kaluga, Russia",
  "662255, Uzhur, Krasnoyarsk Krai, Russia",
  "357904, Zelenokumsk, Stavropol, Russia",
  "53950, Lappeenranta, South Karelia, Finland",
  "Lampasas County, Texas, United States",
  "Grand Prairie, Texas, United States",
  "Ménard, La Chapelle-de-Surieu, Auvergne-Rhône-Alpes, France",
  "29001, Málaga, Andalusia, Spain",
  "Glazovo, Voronezh, Russia",
  "B6720BNH, San Andrés de Giles, Buenos Aires Province, Argentina",
  "Grand Teton Valley, North Las Vegas, Nevada, United States",
  "658067, Novoaltaysk, Altai Krai, Russia",
  "Ruen Municipality, Burgas, Bulgaria",
  "Watauga, North Carolina, United States",
  "Toledo, Castile-La Mancha, Spain",
  "3300, Eger, Heves, Hungary",
  "Greater Ferrara Metropolitan Area",
  "660012, Krasnoyarsk, Krasnoyarsk Krai, Russia",
  "1397, Drammen, Viken, Norway",
  "N7L 0A1, Chatham, Ontario, Canada",
  "23014, Goochland, Virginia, United States",
  "95618, Davis, California, United States",
  "18827, Susquehanna, Pennsylvania, United States",
  "Sandoval, Chihuahua, Mexico",
  "5408, Çorovodë, Berat, Albania",
  "453126, Sterlitamak, Bashkortostan, Russia",
  "Carlton, Minnesota, United States",
  "Akhtubinskoye, Saratov, Russia",
  "Storeytown, New Brunswick, Canada",
  "23838, Chesterfield, Virginia, United States",
  "Coleman, Michigan, United States",
  "10430, Samobor, Zagreb, Croatia",
  "Ochiltree County, Texas, United States",
  "646903, Kalachinsk, Omsk, Russia",
  "29671, Pickens, South Carolina, United States",
  "165306, Kotlas, Arkhangel’sk, Russia",
  "35094, Leeds, Alabama, United States",
  "662978, Zheleznogorsk, Krasnoyarsk Krai, Russia",
  "Meram, Konya, Türkiye",
  "Adair Park, Georgia, United States",
  "Oskarshamn, Kalmar County, Sweden",
  "Cavalier Park, Tulsa, Oklahoma, United States",
  "Ward 9, Ho Chi Minh City, Vietnam",
  "Dolynska, Kirovohrad, Ukraine",
  "Riabai ward, Kiambu, Kenya",
  "Carver, Massachusetts, United States",
  "Tateshina, Nagano, Japan",
  "Potsdam-Mittelmark, Brandenburg, Germany",
  "North Province, New Caledonia",
  "5623 AA, Eindhoven, North Brabant, Netherlands",
  "Washington County, Tennessee, United States",
  "95323, Hickman, California, United States",
  "3308, Gramsh, Elbasan, Albania",
  "Cumberland County, North Carolina, United States",
  "Tatarskoye, Omsk, Russia",
  "Eswatini",
  "Dayton, Texas, United States",
  "Petropavlovskaya, Krasnodar, Russia",
  "Zaragoza, San Luis Potosí, Mexico",
  "Rio de Janeiro, Rio de Janeiro, Brazil",
  "Isanti Township, Minnesota, United States",
  "225767, Pinsk, Brest, Belarus",
  "Kerch, Crimea, Ukraine",
  "61300, Trabzon, Trabzon, Türkiye",
  "Mayestre, Lasalle, Occitanie, France",
  "Călărași, Botoşani, Romania",
  "Le Glacier, Mons, Occitanie, France",
  "Romanel-sur-Lausanne, Vaud, Switzerland",
  "Libohovë, Gjirokastër, Albania",
  "45686, Vinton, Ohio, United States",
  "97119, Gaston, Oregon, United States",
  "Ust-Kamchatsky District, Kamchatka, Russia",
  "22701, Culpeper, Virginia, United States",
  "142405, Noginsk, Moscow, Russia",
  "Île de la Navasse / Navassa Island (Disputed area USA-HAITI)",
  "Cocieri, Dubăsari District, Moldova",
  "PE8 5QF, Barnwell, England, United Kingdom",
  "Nürnberger Land, Bavaria, Germany",
  "Biella, Piedmont, Italy",
  "Christiansburg, Ohio, United States",
  "9402, Harstad, Troms og Finnmark, Norway",
  "430005, Saransk, Mordovia, Russia",
  "665383, Zima, Irkutsk, Russia",
  "Hanson Heights, Saint George, Barbados",
  "Makhachkala, Dagestan, Russia",
  "Kurchatov, Kursk, Russia",
  "Delta, Minas Gerais, Brazil",
  "47500, Mardin, Mardin, Türkiye",
  "423587, Nizhnekamsk, Tatarstan, Russia",
  "Burgos, Cagayan Valley, Philippines",
  "44805, Ashland, Ohio, United States",
  "Gregg Park, Columbia, South Carolina, United States",
  "66100, Perpignan, Occitanie, France",
  "Lafayette, Colorado, United States",
  "Atoka County, Oklahoma, United States",
  "Charlottesville, Indiana, United States",
  "Mezőtúr District, Jász-Nagykun-Szolnok, Hungary",
  "86413, Golden Valley, Arizona, United States",
  "Nässjö, Jönköping County, Sweden",
  "29841, North Augusta, South Carolina, United States",
  "Pacífico, Madrid, Community of Madrid, Spain",
  "Fort Duchesne, Analamanga, Madagascar",
  "66500, Vaasa, Ostrobothnia, Finland",
  "364029, Groznyy, Chechnya, Russia",
  "Greater Las Palmas Metropolitan Area",
  "27205, Asheboro, North Carolina, United States",
  "Youngstown-Warren area",
  "Ugarčin, Lovech, Bulgaria",
  "8928, Nova Zagora, Sliven, Bulgaria",
  "South Darenth, England, United Kingdom",
  "Gibsonburg, Ohio, United States",
  "Pristina, District of Pristina, Kosovo",
  "Cibola Park, North Las Vegas, Nevada, United States",
  "6414, Molde, Møre og Romsdal, Norway",
  "51274, Slavgorod, Dnipropetrovsk, Ukraine",
  "142205, Serpukhov, Moscow, Russia",
  "Butte, North Dakota, United States",
  "Vicuña, Coquimbo Region, Chile",
  "5658 ED, Eindhoven, North Brabant, Netherlands",
  "Nobleboro, Maine, United States",
  "169945, Vorkuta, Komi, Russia",
  "New Taipei City, Taiwan",
  "2810, Gjøvik, Innlandet, Norway",
  "Obshtina Maritsa, Plovdiv, Bulgaria",
  "220192, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "230077, Slatina, Olt, Romania",
  "Chita, Boyacá, Colombia",
  "Treviso Bresciano, Lombardy, Italy",
  "8500-356, Portimão, Faro, Portugal",
  "185 32, Piraeus, Attiki, Greece",
  "Chisago City, Minnesota, United States",
  "Amherstview, Ontario, Canada",
  "Ripley, Ontario, Canada",
  "Alcornocalejos, Jerez de la Frontera, Andalusia, Spain",
  "76207, Denton, Texas, United States",
  "Wayne County, Michigan, United States",
  "A91 A9N7, Dundalk, County Louth, Ireland",
  "Shebekino, Belgorod, Russia",
  "3915, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Bucharest Metropolitan Area",
  "Y21 DN36, Enniscorthy, County Wexford, Ireland",
  "Woodward Park, California, United States",
  "Klagenfurt, Carinthia, Austria",
  "30100, Netishyn, Khmelnytsky, Ukraine",
  "431444, Ruzayevka, Mordovia, Russia",
  "Donau-Ries, Bavaria, Germany",
  "79000, Kilis, Kilis, Türkiye",
  "Taneytown Historic District, Taneytown, Maryland, United States",
  "G0M 1S0, Saint-Évariste-de-Forsyth, Quebec, Canada",
  "Maladzyechna, Minsk, Belarus",
  "1505, Sofia, Sofia City, Bulgaria",
  "Hawkins Estate, Johannesburg, Gauteng, South Africa",
  "617442, Kungur, Perm, Russia",
  "Makarovskoye, Saratov, Russia",
  "Oxford, Michigan, United States",
  "Progress, Rostov, Russia",
  "803 10, Gävle, Gävleborg County, Sweden",
  "Erath, Louisiana, United States",
  "Salineville, Ohio, United States",
  "C00T3P8, Kokshetau, Akmola Region, Kazakhstan",
  "7100, Cape Town, Western Cape, South Africa",
  "45370, Kouvola, Kymenlaakso, Finland",
  "Colorado Springs, Colorado, United States",
  "PE1 5UA, Peterborough, England, United Kingdom",
  "51116, Rijeka, Primorje-Gorski, Croatia",
  "02903, Providence, Rhode Island, United States",
  "Reno, Nevada, United States",
  "Wyoming County, New York, United States",
  "66002, Atchison, Kansas, United States",
  "628416, Surgut, Khanty-Mansi, Russia",
  "Kavaklı, Kırklareli, Türkiye",
  "Clarendon, Dover, England, United Kingdom",
  "34292, Venice, Florida, United States",
  "35478, Vance, Alabama, United States",
  "Balearic Islands, Spain",
  "Greater Sassari Metropolitan Area",
  "56125, Pisa, Tuscany, Italy",
  "84601, Horlivka, Donetsk, Ukraine",
  "172527, Nelidovo, Tver’, Russia",
  "65199, Wiesbaden, Hesse, Germany",
  "Albemarle Extension 2, Brakpan, Gauteng, South Africa",
  "9012, Lushnjë, Fier, Albania",
  "Leicester, England, United Kingdom",
  "35203, Birmingham, Alabama, United States",
  "65653, Forsyth, Missouri, United States",
  "Deweyville, Texas, United States",
  "Rudyard, Pembina, Manitoba, Canada",
  "Krasnoyarskiy, Astrakhan’, Russia",
  "Clark County, Indiana, United States",
  "Gurkovo, Stara Zagora, Bulgaria",
  "Winona South, Hamilton, Ontario, Canada",
  "McPherson, Napa, California, United States",
  "Fort Yates, North Dakota, United States",
  "Höfn, Southern Region, Iceland",
  "Washington, District of Columbia, United States",
  "Foggia, Apulia, Italy",
  "655016, Abakan, Khakassia, Russia",
  "Goodhue, Minnesota, United States",
  "5700, Svendborg, Region of Southern Denmark, Denmark",
  "5216 CJ, ’s-Hertogenbosch, North Brabant, Netherlands",
  "353475, Gelendzhik, Krasnodar, Russia",
  "North Augusta, South Carolina, United States",
  "18003, Granada, Andalusia, Spain",
  "Hamlin, West Virginia, United States",
  "Cannon Beach, Oregon, United States",
  "303850, Livny, Orel, Russia",
  "Seneca, Oregon, United States",
  "26005, Logroño, Autonomía de La Rioja, Spain",
  "542 48, Thessaloniki, Central Macedonia, Greece",
  "169927, Vorkuta, Komi, Russia",
  "47152, Valladolid, Castilla and Leon, Spain",
  "Novoukrainka Raion, Kirovohrad, Ukraine",
  "Kızıltepe, Mardin, Türkiye",
  "Bishop Judith Craig City, Mamgbabli, Margibi County, Liberia",
  "193221, Handwara, Jammu & Kashmir, India",
  "117461, Moscow, Moscow City, Russia",
  "Uglegorsk, Sakhalin, Russia",
  "Kirkenes, Troms og Finnmark, Norway",
  "Redmond, Washington, United States",
  "Knox, Pennsylvania, United States",
  "North Olmsted West, North Olmsted, Ohio, United States",
  "Harrisonburg City County, Virginia, United States",
  "60311, Frankfurt, Hesse, Germany",
  "Geytepe, Lankaran-Astara, Azerbaijan",
  "Serovsky District, Sverdlovsk, Russia",
  "66261, Tulungagung, East Java, Indonesia",
  "Halmstad, Viken, Norway",
  "Solomon Islands",
  "Bland, New South Wales, Australia",
  "78109, Converse, Texas, United States",
  "65-018, Zielona Góra, Lubuskie, Poland",
  "1050, Skopje, Skopje Statistical Region, North Macedonia",
  "453104, Sterlitamak, Bashkortostan, Russia",
  "Fayette County, Alabama, United States",
  "624612, Alapayevsk, Sverdlovsk, Russia",
  "Madagascar",
  "Ålesund, Møre og Romsdal, Norway",
  "Howard County, Arkansas, United States",
  "Moscow Metropolitan Area",
  "Cassano delle Murge, Apulia, Italy",
  "An der Weidenmühle, Neu-Bamberg, Rhineland-Palatinate, Germany",
  "Nelson City, Nelson, New Zealand",
  "Isle Of Anglesey, Wales, United Kingdom",
  "Custer County, Nebraska, United States",
  "Glacier View, Everett, Washington, United States",
  "53870, Rize, Rize, Türkiye",
  "352395, Kropotkin, Krasnodar, Russia",
  "Oghuz District, Sheki-Zaqatala, Azerbaijan",
  "Aurora, Ceará, Brazil",
  "6011, Ålesund, Møre og Romsdal, Norway",
  "15030, Burdur, Burdur, Türkiye",
  "McKean Township, Pennsylvania, United States",
  "Potter, Arkansas, United States",
  "Baniyas District, Tartus Governorate, Syria",
  "EH6 4AA, Edinburgh, Scotland, United Kingdom",
  "10031, Zhytomyr, Zhytomyr, Ukraine",
  "09510, Makarov, Kyiv, Ukraine",
  "95785-000, Harmonia, Rio Grande do Sul, Brazil",
  "022, Albacete, Castile-La Mancha, Spain",
  "23508, Norfolk, Virginia, United States",
  "8012 AA, Zwolle, Overijssel, Netherlands",
  "Greenville, South Carolina, United States",
  "Burrelton, Scotland, United Kingdom",
  "Smithfield, Rhode Island, United States",
  "Uinta County, Wyoming, United States",
  "Hindsville, Arkansas, United States",
  "211388, Orsha, Vitsyebsk, Belarus",
  "44270, Saint-Étienne-de-Mer-Morte, Pays de la Loire, France",
  "Ravalli County, Montana, United States",
  "427440, Votkinsk, Udmurtia, Russia",
  "R6W 4B4, Winkler, Manitoba, Canada",
  "Mondorf-les-Bains, Grevenmacher, Luxembourg",
  "Linn County, Missouri, United States",
  "Bremen, Ohio, United States",
  "Cheshire, Massachusetts, United States",
  "Cascade, Michigan, United States",
  "Tulancingo de Bravo, Hidalgo, Mexico",
  "37008, Salamanca, Castilla and Leon, Spain",
  "Səməd Vurğun Qəsəbəsi, Qazakh-Tovuz, Azerbaijan",
  "33211, Gijón, Principality of Asturias, Spain",
  "Newport, Kentucky, United States",
  "Wharton County, Texas, United States",
  "665407, Cheremkhovo, Irkutsk, Russia",
  "2825, Gjøvik, Innlandet, Norway",
  "L2G 0A1, Niagara Falls, Ontario, Canada",
  "427620, Glazov, Udmurtia, Russia",
  "34120, Naples, Florida, United States",
  "Cambria, Pennsylvania, United States",
  "6000, Stara Zagora, Stara Zagora, Bulgaria",
  "Champaign, Illinois, United States",
  "Chariton, Missouri, United States",
  "94002, Belmont, California, United States",
  "Tripoli, North Governorate, Lebanon",
  "Comanche, La Paz, Bolivia",
  "08005, Barcelona, Catalonia, Spain",
  "346536, Shakhty, Rostov, Russia",
  "Caledonia, Ontario, Canada",
  "Forrest, Illinois, United States",
  "Dillon, Ranbirsinghpora, Jammu & Kashmir, India",
  "40020, Orta, Kırşehir, Türkiye",
  "Dyer, Tennessee, United States",
  "Fierbinţi-Târg, Ialomiţa, Romania",
  "678174, Mirnyy, Sakha, Russia",
  "Grants, New Mexico, United States",
  "Condomínio Sunflower, Anápolis, Goiás, Brazil",
  "Waterford, Ohio, United States",
  "1388, Drammen, Viken, Norway",
  "Nyeri, Kenya",
  "86-303, Grudziądz, Kujawsko-pomorskie, Poland",
  "Jonesville, Louisiana, United States",
  "95463, Navarro, California, United States",
  "Elliston, Virginia, United States",
  "352333, Ust’-Labinsk, Krasnodar, Russia",
  "Weberreut, Tiefenbach, Bavaria, Germany",
  "309295, Shebekino, Belgorod, Russia",
  "Kutahya, Kütahya, Türkiye",
  "Martin, Tennessee, United States",
  "142277, Serpukhov, Moscow, Russia",
  "344090, Rostov, Rostov, Russia",
  "Carvers Creek, North Carolina, United States",
  "Midlands Province, Zimbabwe",
  "65-119, Zielona Góra, Lubuskie, Poland",
  "Sanborn, Wisconsin, United States",
  "Carter Lake, Iowa, United States",
  "640003, Kurgan, Kurgan, Russia",
  "48047, New Baltimore, Michigan, United States",
  "22767, Hamburg, Hamburg, Germany",
  "317425, Zimandu Nou, Arad, Romania",
  "Darlington, Maryland, United States",
  "6043, Charleroi, Walloon Region, Belgium",
  "Petukhovo, Kurgan, Russia",
  "Hotan, Xinjiang Uygur, China",
  "Sokolac, Serb Republic, Bosnia and Herzegovina",
  "54221, Manitowoc, Wisconsin, United States",
  "Slobozia Ciorăști, Vrancea, Romania",
  "Wasco County, Oregon, United States",
  "302 56, Halmstad, Halland County, Sweden",
  "352 50, Växjö, Kronoberg County, Sweden",
  "Ivano-Frankivsk, Ivano-Frankivsk, Ukraine",
  "Mtsensky District, Orel, Russia",
  "Oaș Country, Satu Mare, Romania",
  "67207, Wichita, Kansas, United States",
  "Nancelle, La Roche-Vineuse, Bourgogne-Franche-Comté, France",
  "Hardy, Virginia, United States",
  "Orsha, Pskov, Russia",
  "84132, Salerno, Campania, Italy",
  "79115, Freiburg, Baden-Württemberg, Germany",
  "Korcë, Korçë, Albania",
  "550148, Sibiu, Sibiu, Romania",
  "Sullivan County, Tennessee, United States",
  "Greene County, Illinois, United States",
  "Pechora, Komi, Russia",
  "Washtenaw County, Michigan, United States",
  "Cambridge, Illinois, United States",
  "Gagetown, Michigan, United States",
  "Prentiss, Mississippi, United States",
  "Kourouninkoto, Kayes, Mali",
  "20604, Narva, Ida-Virumaa, Estonia",
  "23452, Virginia Beach, Virginia, United States",
  "Scott County, Virginia, United States",
  "244001, Moradabad, Uttar Pradesh, India",
  "109, Reykjavík, Capital Region, Iceland",
  "Samara Metropolitan Area",
  "225411, Baranavichy, Brest, Belarus",
  "C19C2K1, Zhaltyr, Akmola Region, Kazakhstan",
  "Terry Fox Business Park, Ottawa, Ontario, Canada",
  "Nadvornaya, Ivano-Frankivsk, Ukraine",
  "EX5 2JG, Perkin's Village, England, United Kingdom",
  "62951, Johnston City, Illinois, United States",
  "Aqsu, Jetisu Region, Kazakhstan",
  "8902, Zalaegerszeg, Zala, Hungary",
  "413720, Pugachëv, Saratov, Russia",
  "303035, Mtsensk, Orel, Russia",
  "Dover, Ohio, United States",
  "Svetogorsk, Leningrad, Russia",
  "68847, Kearney, Nebraska, United States",
  "Törökszentmiklós District, Jász-Nagykun-Szolnok, Hungary",
  "8310, Peshkopi, Dibrë, Albania",
  "1508, Krujë, Durres, Albania",
  "456660, Miasskoye, Chelyabinsk, Russia",
  "77600, Bussy-Saint-Georges, Île-de-France, France",
  "400690, Cluj-Napoca, Cluj, Romania",
  "413860, Balakovo, Saratov, Russia",
  "Villa Amelia, Santa Fe, Argentina",
  "90-368, Łódź, Łódzkie, Poland",
  "Blandford Forum, England, United Kingdom",
  "Rambrouch, Diekirch, Luxembourg",
  "658824, Slavgorod, Altai Krai, Russia",
  "Personas, Calapan, Mimaropa, Philippines",
  "S13E8H0, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Samuil Municipality, Razgrad, Bulgaria",
  "694071, Dolinsk, Sakhalin, Russia",
  "04684, Surry, Maine, United States",
  "96400, Rovaniemi, Lapland, Finland",
  "446300, Otradnyy, Samara, Russia",
  "Kolomna, Moscow, Russia",
  "Zmeinogorsky District, Altai Krai, Russia",
  "656015, Barnaul, Altai Krai, Russia",
  "Veszprém, Hungary",
  "36006, Billingsley, Alabama, United States",
  "Marsala, Sicily, Italy",
  "67764, Winona, Kansas, United States",
  "Pershing, Orlando, Florida, United States",
  "Stephenson, West Virginia, United States",
  "NG6 0AA, Nottingham, England, United Kingdom",
  "307750, Lgov, Kursk, Russia",
  "Metropolitan City of Genoa, Liguria, Italy",
  "Barron, Gerderest, Nouvelle-Aquitaine, France",
  "368303, Kaspiysk, Dagestan, Russia",
  "Desa Sumberdawesari, Grati, East Java, Indonesia",
  "38617, Coahoma, Mississippi, United States",
  "624593, Ivdel’, Sverdlovsk, Russia",
  "Baker, West Virginia, United States",
  "Pukekohe, New Zealand",
  "06150, Porvoo, Uusimaa, Finland",
  "Middlesex, Pennsylvania, United States",
  "Greenbrier River, West Virginia, United States",
  "Cacheu, Cacheu Region, Guinea-Bissau",
  "43920, East Liverpool, Ohio, United States",
  "Saki West, Oyo State, Nigeria",
  "77457, Matagorda, Texas, United States",
  "Hosingen, Diekirch, Luxembourg",
  "Vladimirci, Centralna Srbija, Serbia",
  "353915, Novorossiysk, Krasnodar, Russia",
  "101000, Moscow, Moscow City, Russia",
  "Cherepanovsky, Altai Krai, Russia",
  "Greater São Paulo Area",
  "225700, Pinsk, Brest, Belarus",
  "Sanilac County, Michigan, United States",
  "Scott County, Iowa, United States",
  "Caddo Park, Plano, Texas, United States",
  "Sampson Flat, South Australia, Australia",
  "Alexander City, Alabama, United States",
  "Fallon Heights, Siloam Springs, Arkansas, United States",
  "13088, Liverpool, New York, United States",
  "Nikopol Municipality, Pleven, Bulgaria",
  "Chesapeake Beach, Virginia Beach, Virginia, United States",
  "72584, Violet Hill, Arkansas, United States",
  "Summit, Mississippi, United States",
  "6110-123, Vila de Rei, Castelo Branco, Portugal",
  "North Beltrami, Minnesota, United States",
  "Gorodskoy Okrug Buynaksk, Dagestan, Russia",
  "Buffalo–Niagara Falls metropolitan area, New York, United States",
  "SN15 4LL, Langley Burrell, England, United Kingdom",
  "Partizanskiy, Altai Krai, Russia",
  "Lafayette Hill, Pennsylvania, United States",
  "Marseilles, Illinois, United States",
  "Bradley, California, United States",
  "97082, Würzburg, Bavaria, Germany",
  "Union County, Arkansas, United States",
  "76648, Hubbard, Texas, United States",
  "20115, Astigarraga, Basque Country, Spain",
  "Tipton County, Indiana, United States",
  "43125, Parma, Emilia-Romagna, Italy",
  "Genting Highlands, Pahang, Malaysia",
  "155800, Kineshma, Ivanovo, Russia",
  "Toulon-sur-Arroux, Bourgogne-Franche-Comté, France",
  "Jamestown, New York, United States",
  "Hancock County, Georgia, United States",
  "Wharton, West Virginia, United States",
  "Armstrong County, Texas, United States",
  "Turner, Oregon, United States",
  "San Sebastián del Coca, Orellana, Ecuador",
  "Cadiz, Kentucky, United States",
  "641875, Shadrinsk, Kurgan, Russia",
  "Cavalier, Savignac, Nouvelle-Aquitaine, France",
  "Pulaski, New York, United States",
  "Dimmit County, Texas, United States",
  "Windsor, Missouri, United States",
  "Wright County, Iowa, United States",
  "32622, Brooker, Florida, United States",
  "Vejlebro, Region Zealand, Denmark",
  "Eregli, Zonguldak, Türkiye",
  "33617, Bielefeld, North Rhine-Westphalia, Germany",
  "Buncombe, Illinois, United States",
  "80170, Joensuu, North Karelia, Finland",
  "Letea Veche, Bacău, Romania",
  "46015, Valencia, Valencian Community, Spain",
  "Calais, Maine, United States",
  "35009, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Lebanon, Oregon, United States",
  "Maykopskoye, Krasnodar, Russia",
  "Déville-lès-Rouen, Normandy, France",
  "685918, Magadan, Magadan, Russia",
  "100521, Ploieşti, Prahova, Romania",
  "Tooele, Utah, United States",
  "Zug, Switzerland",
  "Grayslake, Illinois, United States",
  "31551, Belišce, Osijek-Baranja, Croatia",
  "2275, Lille, Flemish Region, Belgium",
  "Becker County, Minnesota, United States",
  "Bluefield, West Virginia, United States",
  "Rogers, Arkansas, United States",
  "Topki, Orel, Russia",
  "SA3 4RU, Caswell, Wales, United Kingdom",
  "Lille Skensved, Region Zealand, Denmark",
  "58033, Fort Ransom, North Dakota, United States",
  "51114, Rijeka, Primorje-Gorski, Croatia",
  "20615, Broomes Island, Maryland, United States",
  "Bournemouth, England, United Kingdom",
  "652332, Topki, Kemerovo, Russia",
  "Lewisburg, Tennessee, United States",
  "53750, Rize, Rize, Türkiye",
  "15750, Josephine, Pennsylvania, United States",
  "Dakota County, Nebraska, United States",
  "65052, Linn Creek, Missouri, United States",
  "Jefferson County, Colorado, United States",
  "Taylorsville, Kentucky, United States",
  "1207, Geneva, Geneva, Switzerland",
  "Polevskoy, Kursk, Russia",
  "143409, Krasnogorsk, Moscow, Russia",
  "Greater St. Louis",
  "Pierce, Idaho, United States",
  "3940, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "8590, Narvik, Nordland, Norway",
  "216336, Bogoroditskoye, Smolensk, Russia",
  "42354, McHenry, Kentucky, United States",
  "678100, Olyokminsk, Sakha, Russia",
  "French Guiana",
  "2790023, Urayasu, Chiba, Japan",
  "Jasper County, Missouri, United States",
  "Stanton, North Dakota, United States",
  "Columbus, Kenosha, Wisconsin, United States",
  "F19H2Y9, Semey, Abay Region, Kazakhstan",
  "20678, Prince Frederick, Maryland, United States",
  "Ikpoba-Okha, Edo State, Nigeria",
  "352901, Armavir, Krasnodar, Russia",
  "648594, Noril'sk, Taymyr, Russia",
  "33208, Gijón, Principality of Asturias, Spain",
  "R13B0C3, Beyneu, Mangystau Region, Kazakhstan",
  "6000, Kecskemét, Bács-Kiskun, Hungary",
  "Luhansk, Luhansk, Ukraine",
  "Escaldes-Engordany, Andorra",
  "Benkovski, Plovdiv, Bulgaria",
  "Vilas, North Carolina, United States",
  "Qendër Ersekë, Korçë, Albania",
  "54703, Eau Claire, Wisconsin, United States",
  "603, Akureyri, Northeastern Region, Iceland",
  "352507, Labinsk, Krasnodar, Russia",
  "Golden, Colorado, United States",
  "50251, Sully, Iowa, United States",
  "693012, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "South Dorchester, Boston, Massachusetts, United States",
  "Boyd, Montana, United States",
  "Kakhovska miska hromada, Kherson, Ukraine",
  "B55F0B9, Zharkent, Jetisu Region, Kazakhstan",
  "Oconto, Nebraska, United States",
  "91495, Trousdale Estates, California, United States",
  "Seminole, Oklahoma, United States",
  "Rappahannock Academy, Virginia, United States",
  "75772, Rusk, Texas, United States",
  "13013, Marseille, Provence-Alpes-Côte d'Azur, France",
  "Sydney, New South Wales, Australia",
  "655156, Chernogorsk, Khakassia, Russia",
  "429334, Kanash, Chuvashia, Russia",
  "655012, Abakan, Khakassia, Russia",
  "Greater Narva Area",
  "Canton of Troyes-2, Grand Est, France",
  "Madison County, Indiana, United States",
  "Gastonia Township, North Carolina, United States",
  "Tillman Estates, Yuma, Arizona, United States",
  "75-426, Koszalin, Zachodniopomorskie, Poland",
  "Vilasantar, Galicia, Spain",
  "800402, Galaţi, Galaţi, Romania",
  "Bryan County, Georgia, United States",
  "364060, Groznyy, Chechnya, Russia",
  "Craigsville, Virginia, United States",
  "4053, Stafford Heights, Queensland, Australia",
  "628157, Igrim, Khanty-Mansi, Russia",
  "Washakie County, Wyoming, United States",
  "99098, Erfurt, Thuringia, Germany",
  "Mogadouro, Bragança, Portugal",
  "Jardim Residencial Sunflower, Rondonópolis, Mato Grosso, Brazil",
  "Carlton, Texas, United States",
  "Coosane, County Cork, Ireland",
  "Shiraoi, Hokkaido, Japan",
  "06134, Perugia, Umbria, Italy",
  "Grafton, North Dakota, United States",
  "V92 YF68, Tralee, County Kerry, Ireland",
  "618254, Gubakha, Perm, Russia",
  "Wayne County, Indiana, United States",
  "19100, Brive-la-Gaillarde, Nouvelle-Aquitaine, France",
  "Reiat, Schaffhausen, Switzerland",
  "Graysville, Alabama, United States",
  "George, Western Cape, South Africa",
  "Partido de Almirante Brown, Buenos Aires Province, Argentina",
  "636450, Kolpashevo, Tomsk, Russia",
  "610004, Piatra Neamţ, Neamţ, Romania",
  "London Area, United Kingdom",
  "8012, Székesfehérvár, Fejér, Hungary",
  "665808, Angarsk, Irkutsk, Russia",
  "Capital, British Columbia, Canada",
  "Bruceville-Eddy, Texas, United States",
  "Progreso, Yucatán, Mexico",
  "Morton, Sandown, England, United Kingdom",
  "Sarıkaya, Yozgat, Türkiye",
  "169903, Vorkuta, Komi, Russia",
  "19422, Blue Bell, Pennsylvania, United States",
  "73160, Oklahoma City, Oklahoma, United States",
  "Clarendon County, South Carolina, United States",
  "63100, Şanlıurfa, Şanliurfa, Türkiye",
  "Bilotserkivskyi raion, Kyiv, Ukraine",
  "Miller County, Georgia, United States",
  "84112, Salt Lake City, Utah, United States",
  "Hanover, New Hampshire, United States",
  "Fushe Kosove, District of Pristina, Kosovo",
  "300254, Timişoara, Timiş, Romania",
  "627317, Golyshmanovo, Tyumen’, Russia",
  "Semeykino, Ivanovo, Russia",
  "Elvas, Portalegre, Portugal",
  "Northumberland, England, United Kingdom",
  "Kamensk Uralskiy, Sverdlovsk, Russia",
  "M52D4F8, Osakarovka, Karaganda Region, Kazakhstan",
  "Richland Parish County, Louisiana, United States",
  "620171, Focşani, Vrancea, Romania",
  "85635, Sierra Vista, Arizona, United States",
  "346887, Bataysk, Rostov, Russia",
  "Tatabánya, Komárom-Esztergom, Hungary",
  "307623, Glazovo, Kursk, Russia",
  "663200, Igarka, Krasnoyarsk Krai, Russia",
  "Port Arthur, Texas, United States",
  "96930, Rovaniemi, Lapland, Finland",
  "Rosée, La Chapelle-Rainsouin, Pays de la Loire, France",
  "45-061, Opole, Opolskie, Poland",
  "Swainsthorpe, England, United Kingdom",
  "Charleston, Arkansas, United States",
  "Tomares, Andalusia, Spain",
  "Carroll, Iowa, United States",
  "Grand Beach, Galveston, Texas, United States",
  "keyamora, Narayanpur, Assam, India",
  "Dyer, North Stormont, Ontario, Canada",
  "IP31 3SX, Thurston, England, United Kingdom",
  "013302, Bucharest, Bucharest, Romania",
  "Flensburg, Schleswig-Holstein, Germany",
  "74855, Meeker, Oklahoma, United States",
  "Partizanskoye, Krasnoyarsk Krai, Russia",
  "335063, Sangaria, Rajasthan, India",
  "Usman, Lipetsk, Russia",
  "Ust’-Kuyga, Sakha, Russia",
  "Daresbury, England, United Kingdom",
  "Valle de Yerri – Deierri, Chartered Community of Navarre, Spain",
  "302 24, Halmstad, Halland County, Sweden",
  "Greater Columbia, Missouri Area",
  "Barry Island, Barry, Wales, United Kingdom",
  "99111, Colfax, Washington, United States",
  "Nelson, Wisconsin, United States",
  "Coal County, Oklahoma, United States",
  "Ljubljana, Slovenia",
  "35019, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Knox, Indiana, United States",
  "Cavalier, Puyloubier, Provence-Alpes-Côte d'Azur, France",
  "4710-438, Braga, Braga, Portugal",
  "26125, Oldenburg, Lower Saxony, Germany",
  "32578, Niceville, Florida, United States",
  "IP1 2BX, Ipswich, England, United Kingdom",
  "Oddar Meanchey, Cambodia",
  "Cheshire West And Chester, England, United Kingdom",
  "B3K 0A1, Halifax, Nova Scotia, Canada",
  "03054, Cottbus, Brandenburg, Germany",
  "Travis Park, Tulsa, Oklahoma, United States",
  "Tobolovskoye, Tyumen’, Russia",
  "Westchester, Charleston, South Carolina, United States",
  "Morris, New Jersey, United States",
  "Guilford or Davidstown, County Westmeath, Ireland",
  "Luleå kommun, Norrbotten County, Sweden",
  "Stepanki, Cherkasy, Ukraine",
  "Novyy Arysh, Tatarstan, Russia",
  "Vyazemskiy, Khabarovsk, Russia",
  "Leghorn, Tuscany, Italy",
  "Kizilsky District, Chelyabinsk, Russia",
  "04105, Leipzig, Saxony, Germany",
  "43069, Reynoldsburg, Ohio, United States",
  "7627, Pécs, Baranya, Hungary",
  "662253, Uzhur, Krasnoyarsk Krai, Russia",
  "Rigaud, Quebec, Canada",
  "7408, Kaposvár, Somogy, Hungary",
  "32588, Niceville, Florida, United States",
  "95687, Vacaville, California, United States",
  "İpsala, Edirne, Türkiye",
  "Schuyler County, Missouri, United States",
  "7233, Jenaz, Graubünden, Switzerland",
  "24180, Erzincan, Erzincan, Türkiye",
  "624600, Alapayevsk, Sverdlovsk, Russia",
  "Newport, New Hampshire, United States",
  "02002, Albacete, Castile-La Mancha, Spain",
  "Burnet, Texas, United States",
  "Svēte, Jelgava Municipality, Latvia",
  "Bilohirsk, Crimea, Ukraine",
  "37681, Limestone, Tennessee, United States",
  "410005, Saratov, Saratov, Russia",
  "Howard County, Iowa, United States",
  "Houston County, Alabama, United States",
  "5004, Namur, Walloon Region, Belgium",
  "Ironbark, Queensland, Australia",
  "344013, Rostov, Rostov, Russia",
  "Luxembourg",
  "6343, Risch, Zug, Switzerland",
  "51063, Cologne, North Rhine-Westphalia, Germany",
  "Lodz Metropolitan Area",
  "Topkinsky District, Kemerovo, Russia",
  "29829, Graniteville, South Carolina, United States",
  "PE8 5PF, Barnwell, England, United Kingdom",
  "Gramsh, Elbasan, Albania",
  "Upton, London, England, United Kingdom",
  "Dillon, South Carolina, United States",
  "45810, Kouvola, Kymenlaakso, Finland",
  "Claiborne, Louisiana, United States",
  "16140, Bursa, Bursa, Türkiye",
  "23204, Zrenjanin, Vojvodina, Serbia",
  "A32M6K4, Almaty, Almaty, Kazakhstan",
  "75684, Overton, Texas, United States",
  "UB3 1AA, Hayes, England, United Kingdom",
  "Barnwell County, South Carolina, United States",
  "97170, Petit-Bourg, Basse-Terre, Guadeloupe",
  "429100, Shumerlya, Chuvashia, Russia",
  "56127, Pisa, Tuscany, Italy",
  "Toulon, Provence-Alpes-Côte d'Azur, France",
  "694046, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "4501, Lezhë, Lezhë, Albania",
  "612741, Omutninsk, Kirov, Russia",
  "Vienna, Illinois, United States",
  "54420, Saulxures-lès-Nancy, Grand Est, France",
  "8800, Sliven, Sliven, Bulgaria",
  "Lankaran-Astara, Azerbaijan",
  "DD3 7BR, Dundee, Scotland, United Kingdom",
  "Genesee, Pennsylvania, United States",
  "Blackpool Corner, England, United Kingdom",
  "301875, Yefremov, Tula, Russia",
  "NN3 2AA, Northampton, England, United Kingdom",
  "80117, Kiowa, Colorado, United States",
  "00199, Rome, Latium, Italy",
  "2801, Gjøvik, Innlandet, Norway",
  "Adams County, Ohio, United States",
  "Yadkin County, North Carolina, United States",
  "Tarn Taran district, India",
  "27153, Otero de Rey, Galicia, Spain",
  "Algeria",
  "Timisoara Metropolitan Area",
  "454014, Chelyabinsk, Chelyabinsk, Russia",
  "Hutchinson Island, Savannah, Georgia, United States",
  "Bathinda, Punjab, India",
  "606415, Balakhna, Nizhniy Novgorod, Russia",
  "Andorra la Vella, Andorra",
  "McPhersons Mills, Pictou, Subdivision C, Nova Scotia, Canada",
  "86340, Comalcalco, Tabasco, Mexico",
  "Mercer County, Pennsylvania, United States",
  "Cumberland, Subdivision B, Nova Scotia, Canada",
  "2352, Selnica ob Dravi, Ruše, Slovenia",
  "Cordoba, Mexico Metropolitan Area",
  "Yardymli District, Lankaran-Astara, Azerbaijan",
  "Lamoille, Nevada, United States",
  "Daniels County, Montana, United States",
  "76013, Arlington, Texas, United States",
  "Freienbach, Schwyz, Switzerland",
  "05900, Amasya, Amasya, Türkiye",
  "222302, Maladzyechna, Minsk, Belarus",
  "Butte City, California, United States",
  "38080, Kayseri, Kayseri, Türkiye",
  "28120, Pori, Satakunta, Finland",
  "R7N 3B3, Dauphin, Manitoba, Canada",
  "Stanton, Alabama, United States",
  "Brooks, Minnesota, United States",
  "Dzerzhinsk, Zhytomyr, Ukraine",
  "641870, Shadrinsk, Kurgan, Russia",
  "15325, Burdur, Burdur, Türkiye",
  "Marion County, Ohio, United States",
  "96007, Anderson, California, United States",
  "City Municipality of Ljubljana, Slovenia",
  "Cass County, Nebraska, United States",
  "Burlington, Washington, United States",
  "LE12 6LD, East Leake, England, United Kingdom",
  "Dixon, Kentucky, United States",
  "Sharkia, Egypt",
  "6200-209, Covilhã, Castelo Branco, Portugal",
  "Pikeville, Kentucky, United States",
  "Upton County, Texas, United States",
  "Merida State, Venezuela",
  "Crow Agency, Montana, United States",
  "R0H 0V0, Marquette, Manitoba, Canada",
  "Monselice, Veneto, Italy",
  "Mecklenburg County, North Carolina, United States",
  "Chambersburg, Pennsylvania, United States",
  "67300, Kokkola, Central Ostrobothnia, Finland",
  "Skibbereen, County Cork, Ireland",
  "9426, Harstad, Troms og Finnmark, Norway",
  "70707, Gonzales, Louisiana, United States",
  "48012, Bilbao, Basque Country, Spain",
  "Dublin 7, County Dublin, Ireland",
  "B15 1AE, Birmingham, England, United Kingdom",
  "Brazos County, Texas, United States",
  "810003, Brăila, Brăila, Romania",
  "Departamento Colón, Córdoba, Argentina",
  "81243, Powderhorn, Colorado, United States",
  "60015, Deerfield, Illinois, United States",
  "39088, Yazoo City, Mississippi, United States",
  "24126, Bergamo, Lombardy, Italy",
  "Calhoun County, West Virginia, United States",
  "142450, Noginsk, Moscow, Russia",
  "70-781, Szczecin, Zachodniopomorskie, Poland",
  "91025, Marsala, Sicily, Italy",
  "Chernyakhovskiy, Kaliningrad, Russia",
  "452697, Neftekamsk, Bashkortostan, Russia",
  "Kent, Washington, United States",
  "Susquehanna, Pennsylvania, United States",
  "KW15 1SD, Kirkwall, Scotland, United Kingdom",
  "88103, Cannon AFB, New Mexico, United States",
  "77553, Galveston, Texas, United States",
  "L02P4F7, Oral, West Kazakhstan Region, Kazakhstan",
  "Harpers Ferry, West Virginia, United States",
  "Novi Sad Metropolitan Area",
  "130002, Cartagena, Bolívar, Colombia",
  "Partizansk, Krasnoyarsk Krai, Russia",
  "Swainshill, England, United Kingdom",
  "654005, Novokuznetsk, Kemerovo, Russia",
  "Mirny, Bashkortostan, Russia",
  "Sectorul Rîșcani, Chișinău, Moldova",
  "53214, Milwaukee, Wisconsin, United States",
  "Barrio Ezequiel Zamora, Puerto Cabello, Carabobo State, Venezuela",
  "Monastyrshchina, Smolensk, Russia",
  "252 45, Helsingborg, Skåne County, Sweden",
  "Rostock-Lichtenhagen, Mecklenburg-West Pomerania, Germany",
  "Sovetskiy, Mari El, Russia",
  "35003, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Neuchâtel District, Neuchâtel, Switzerland",
  "B56P7G8, Zharkent, Jetisu Region, Kazakhstan",
  "629757, Nadym, Yamalo-Nenets, Russia",
  "87801, Socorro, New Mexico, United States",
  "Centreville–Fentress Historic District, Virginia, United States",
  "Sovetsk, Kaliningrad, Russia",
  "Starr, South Carolina, United States",
  "CB10 2LQ, Sewards End, England, United Kingdom",
  "NR24 2RN, Edgefield, England, United Kingdom",
  "051102, Bucharest, Bucharest, Romania",
  "Knivsta kommun, Uppsala County, Sweden",
  "Horben bei Frauenfeld, Thurgau, Switzerland",
  "Gagetown, New Brunswick, Canada",
  "BT79 7SJ, Omagh, Northern Ireland, United Kingdom",
  "Vejle Municipality, Region of Southern Denmark, Denmark",
  "21101, Novi Sad, Vojvodina, Serbia",
  "Senica, Trnava Region, Slovakia",
  "Mirnyy, Arkhangel’sk, Russia",
  "54638, Kendall, Wisconsin, United States",
  "92294, Klaipėda, Klaipėdos, Lithuania",
  "Belgorod, Russia",
  "38111, Santa Cruz de Tenerife, Canary Islands, Spain",
  "647340, Noril'sk, Taymyr, Russia",
  "14825, Chemung, New York, United States",
  "Seward, Pennsylvania, United States",
  "Lutsk Raion, Volyn, Ukraine",
  "Vyazemsky District, Khabarovsk, Russia",
  "Pequena Valley Estates, Gentry, Arkansas, United States",
  "165313, Kotlas, Arkhangel’sk, Russia",
  "MD-3311, Tiraspolul Nou, Transnistria autonomous territorial unit, Moldova",
  "8808, Freienbach, Schwyz, Switzerland",
  "Michurinskoye, Leningrad, Russia",
  "Gloucestershire, England, United Kingdom",
  "58528, Cannon Ball, North Dakota, United States",
  "831 36, Östersund, Jämtland County, Sweden",
  "G64 4LW, Torrance, Scotland, United Kingdom",
  "Ash Sharqiyah South Governorate, Oman",
  "33900, Tampere, Pirkanmaa, Finland",
  "A91 XR7X, Dundalk, County Louth, Ireland",
  "357111, Nevinnomyssk, Stavropol, Russia",
  "167010, Syktyvkar, Komi, Russia",
  "Cherokee, North Carolina, United States",
  "37001, Salamanca, Castilla and Leon, Spain",
  "Harrisburg, North Carolina, United States",
  "Columbia County, Pennsylvania, United States",
  "Rainsville, New Mexico, United States",
  "Menard County, Illinois, United States",
  "Morrow County, Ohio, United States",
  "629307, Novy Urengoy, Yamalo-Nenets, Russia",
  "Kentron, Yerevan, Armenia",
  "Departamento Presidente Roque Sáenz Peña, Córdoba, Argentina",
  "Calumet, Wisconsin, United States",
  "Hayston, Glasgow, Scotland, United Kingdom",
  "28607, Boone, North Carolina, United States",
  "Livingstone, Southern Province, Zambia",
  "Kulykivska selyshchna hromada, Chernihiv, Ukraine",
  "3525, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Gdansk Metropolitan Area",
  "Whitehorse, Yukon Territory, Canada",
  "Falkland Islands",
  "07230, Antalya, Antalya, Türkiye",
  "37212, Nashville, Tennessee, United States",
  "Marion County, Alabama, United States",
  "Muskegon, Michigan, United States",
  "78748, Austin, Texas, United States",
  "2491 AA, The Hague, South Holland, Netherlands",
  "The Oldenburg Munsterland",
  "170030, Tver, Tver’, Russia",
  "Blagoveshchenskoye, Ivanovo, Russia",
  "77584, Pearland, Texas, United States",
  "M28C1T3, Aqtau, Karaganda Region, Kazakhstan",
  "Pendleton, Kentucky, United States",
  "190, Schwerin, Mecklenburg-West Pomerania, Germany",
  "Sheffield, Ontario, Canada",
  "Memphis, Tennessee, United States",
  "Stanley, Manitoba, Canada",
  "Wexford, Pennsylvania, United States",
  "Montournais, Pays de la Loire, France",
  "Oneida, Pointe-Claire, Quebec, Canada",
  "31431, Čepin, Osijek-Baranja, Croatia",
  "20050, Denizli, Denizli, Türkiye",
  "524547, Aldana, Nariño, Colombia",
  "618544, Solikamsk, Perm, Russia",
  "142200, Serpukhov, Moscow, Russia",
  "2229, Maribor, Maribor, Slovenia",
  "Snyder, Colorado, United States",
  "69123, Heidelberg, Baden-Württemberg, Germany",
  "20902, Silver Spring, Maryland, United States",
  "Belgorod Metropolitan Area",
  "Sorø Municipality, Region Zealand, Denmark",
  "618263, Gubakha, Perm, Russia",
  "Cumberland Center, Maine, United States",
  "169900, Vorkuta, Komi, Russia",
  "Pakistan",
  "Choctaw Valley, California, United States",
  "SG13 7NU, Hertford, England, United Kingdom",
  "PE8 5PD, Barnwell, England, United Kingdom",
  "546 45, Thessaloniki, Central Macedonia, Greece",
  "Mataró, Catalonia, Spain",
  "Kremenchuk Raion, Poltava, Ukraine",
  "5400-001, Chaves, Vila Real, Portugal",
  "Berkeley, New South Wales, Australia",
  "Makhambet District, Atyrau Region, Kazakhstan",
  "Vannes, Brittany, France",
  "110010, Piteşti, Argeş, Romania",
  "Bezhetsk, Tver’, Russia",
  "15600, Saint-Julien-de-Toursac, Auvergne-Rhône-Alpes, France",
  "Askersunds kommun, Örebro County, Sweden",
  "170100, Tver, Tver’, Russia",
  "Second Mill, Sumter, South Carolina, United States",
  "86000, Poitiers, Nouvelle-Aquitaine, France",
  "Municipio Puerto Cabello, Carabobo State, Venezuela",
  "Broadwater, New South Wales, Australia",
  "692860, Partizansk, Primorye, Russia",
  "Natrona Heights, Pennsylvania, United States",
  "214036, Smolensk, Smolensk, Russia",
  "Fredericksburg, Pennsylvania, United States",
  "Florești-Stoenești, Giurgiu, Romania",
  "Söğüt, Bilecik, Türkiye",
  "Pendleton, England, United Kingdom",
  "613155, Slobodskoy, Kirov, Russia",
  "50411, Tartu, Tartumaa, Estonia",
  "Polyarnyye Zori, Murmansk, Russia",
  "K0B 1H0, Glen Robertson, Ontario, Canada",
  "La Borde Joséphine, Arques, Occitanie, France",
  "Franklin to The Fort, Missoula, Montana, United States",
  "24502, Lynchburg, Virginia, United States",
  "TR18 2AJ, Penzance, England, United Kingdom",
  "CF32 7SS, Price Town, Wales, United Kingdom",
  "Colonial, São Paulo, São Paulo, Brazil",
  "810223, Brăila, Brăila, Romania",
  "Verrières-en-Anjou, Pays de la Loire, France",
  "841 00, Ermoúpolis, Southern Aegean, Greece",
  "3080, Holmestrand, Vestfold og Telemark, Norway",
  "Dalheim, Grevenmacher, Luxembourg",
  "225415, Baranavichy, Brest, Belarus",
  "Obninsk, Kaluga, Russia",
  "Rockingham Ridge, Halifax, Nova Scotia, Canada",
  "620015, Focşani, Vrancea, Romania",
  "Greater Montpellier Metropolitan Area",
  "Tyrrell County, North Carolina, United States",
  "624222, Nizhnyaya Tura, Sverdlovsk, Russia",
  "77422, Brazoria, Texas, United States",
  "Teton Pukhuri, Helem, Assam, India",
  "Madison, Tennessee, United States",
  "70126, Bari, Apulia, Italy",
  "Lacombe, Alberta, Canada",
  "97351, Matoury, Cayenne, French Guiana",
  "01097, Dresden, Saxony, Germany",
  "Varbitsa, Shumen, Bulgaria",
  "Grenada, Mississippi, United States",
  "Eyyübiye, Şanliurfa, Türkiye",
  "Weber County, Utah, United States",
  "0470033, Otaru, Hokkaido, Japan",
  "44950, Guadalajara, Jalisco, Mexico",
  "Parnu, Estonia",
  "Departamento Río Cuarto, Córdoba, Argentina",
  "VLT 1213, Valletta, Valletta, Malta",
  "Fulton County, Arkansas, United States",
  "446001, Syzran, Samara, Russia",
  "Albemarle–Kenmore Terraces Historic District, Brooklyn, New York, United States",
  "15110, Lahti, Päijät-Häme, Finland",
  "Polk County, Tennessee, United States",
  "8500-504, Portimão, Faro, Portugal",
  "Lovettsville, Virginia, United States",
  "Kumla kommun, Örebro County, Sweden",
  "184650, Polyarnyy, Murmansk, Russia",
  "38503, Cookeville, Tennessee, United States",
  "Mora, Castile-La Mancha, Spain",
  "Winston-Salem, North Carolina, United States",
  "Tamaqua Historic District, Schuylkill, Pennsylvania, United States",
  "Genoa, Illinois, United States",
  "23150, Valdepeñas de Jaén, Andalusia, Spain",
  "1010, Vienna, Vienna, Austria",
  "69001, McCook, Nebraska, United States",
  "20719, Bowie, Maryland, United States",
  "Western Area Urban, Western Area, Sierra Leone",
  "142256, Serpukhov, Moscow, Russia",
  "Brunswick, Victoria, Australia",
  "644046, Omsk, Omsk, Russia",
  "19107, Philadelphia, Pennsylvania, United States",
  "66798, Wallerfangen, Saarland, Germany",
  "Maykopsky District, Adygea, Russia",
  "920016, Slobozia, Ialomiţa, Romania",
  "Qazakh-Tovuz, Azerbaijan",
  "C25C9X9, Makinsk, Akmola Region, Kazakhstan",
  "Putnam County, Indiana, United States",
  "Powder Horn Lake, Illinois, United States",
  "Krasnogorskoye, Altai Krai, Russia",
  "Reevesdale, Pennsylvania, United States",
  "Saratov, Saratov, Russia",
  "65203, Wiesbaden, Hesse, Germany",
  "623389, Polevskoy, Sverdlovsk, Russia",
  "Denton, England, United Kingdom",
  "Atoka Historic District, Atoka, Virginia, United States",
  "Gubkinskoye, Orel, Russia",
  "Carterville, Missouri, United States",
  "Keyang, Longding Koling (Pipsorang), Arunachal Pradesh, India",
  "Fond Du Lac County, Wisconsin, United States",
  "Kyiv City, Ukraine",
  "09127, Chemnitz, Saxony, Germany",
  "Uppsala, Uppsala County, Sweden",
  "78050, Karabuk, Karabuk, Türkiye",
  "T12 V32K, Cork, County Cork, Ireland",
  "União das Vilas, Uruguaiana, Rio Grande do Sul, Brazil",
  "Barisāl, Bangladesh",
  "831 42, Östersund, Jämtland County, Sweden",
  "61172, Kharkiv, Kharkiv, Ukraine",
  "B78 2PJ, Kingsbury, England, United Kingdom",
  "Banaz, Uşak, Türkiye",
  "2013, Colombier, Neuchâtel, Switzerland",
  "Johnson County, Arkansas, United States",
  "29678, Seneca, South Carolina, United States",
  "9015, Tromsø, Troms og Finnmark, Norway",
  "Bellmore, New York, United States",
  "Noble County, Ohio, United States",
  "5412, Çorovodë, Berat, Albania",
  "Randall, Blue, Missouri, United States",
  "80810, Cheyenne Wells, Colorado, United States",
  "White River Junction, Vermont, United States",
  "18705, Wilkes-Barre, Pennsylvania, United States",
  "Winona Bay, Clermont, Florida, United States",
  "Bursa, Bursa, Türkiye",
  "Seville, Ohio, United States",
  "36600, Teluk Intan, Perak, Malaysia",
  "89134, Reggio di Calabria, Calabria, Italy",
  "Somerset County, New Jersey, United States",
  "Abbeville, Louisiana, United States",
  "Robertsbridge, England, United Kingdom",
  "Greater Trabzon",
  "Greater Fort Smith Area",
  "4219, West Burleigh, Queensland, Australia",
  "647470, Noril'sk, Taymyr, Russia",
  "Ménard, Bretagne-d’Armagnac, Occitanie, France",
  "23206, Zrenjanin, Vojvodina, Serbia",
  "Windsor, Connecticut, United States",
  "Kamenka, Tyumen’, Russia",
  "68741, Hubbard, Nebraska, United States",
  "Yeşilova, Burdur, Türkiye",
  "692941, Nakhodka, Primorye, Russia",
  "Brookings, South Dakota, United States",
  "Tripp Settlement, Bright, New Brunswick, Canada",
  "Ersekë, Korçë, Albania",
  "21214, Kaštela, Split-Dalmatia, Croatia",
  "Pineda de Mar, Catalonia, Spain",
  "Washburn, Kingston, Ontario, Canada",
  "Parmain, Île-de-France, France",
  "Sanders County, Montana, United States",
  "Wise Creek 77, Saskatchewan, Canada",
  "La Joséphine, Saint-Christophe-du-Ligneron, Pays de la Loire, France",
  "41004, Seville, Andalusia, Spain",
  "Tatarsk, Smolensk, Russia",
  "Lawrence County, Missouri, United States",
  "Lower Whatcombe, England, United Kingdom",
  "Lancaster, Kentucky, United States",
  "M28C4T0, Aqtau, Karaganda Region, Kazakhstan",
  "Faribault, Minnesota, United States",
  "185 37, Piraeus, Attiki, Greece",
  "Blind Bay, Nova Scotia, Canada",
  "Staryy Oskol, Belgorod, Russia",
  "08865, Phillipsburg, New Jersey, United States",
  "692853, Partizansk, Primorye, Russia",
  "Chickasaw Shipyard Village Historic District, Chickasaw, Alabama, United States",
  "48010, Bilbao, Basque Country, Spain",
  "3307, Gramsh, Elbasan, Albania",
  "Salta, Salta, Argentina",
  "Blagoveshchensky District, Bashkortostan, Russia",
  "Pasarkemis, Banten, Indonesia",
  "9486, Harstad, Troms og Finnmark, Norway",
  "Djibouti",
  "Garfield County, Washington, United States",
  "Bayfield, Bartons Creek, North Carolina, United States",
  "Adams County, Wisconsin, United States",
  "Ponte de Sor, Portalegre, Portugal",
  "2017, Boudry, Neuchâtel, Switzerland",
  "96101, Rovaniemi, Lapland, Finland",
  "Holmes County, Mississippi, United States",
  "CO13 0DR, Kirkby-Le-Soken, England, United Kingdom",
  "La Tétonie, Escandolières, Occitanie, France",
  "50160, Mikkeli, South Savo, Finland",
  "Čair, Skopje Statistical Region, North Macedonia",
  "Benton, Pennsylvania, United States",
  "Avery Hill, London, England, United Kingdom",
  "Puerto Cabello, Carabobo State, Venezuela",
  "1309, Sofia, Sofia City, Bulgaria",
  "T23 HRD2, Cork, County Cork, Ireland",
  "624221, Nizhnyaya Tura, Sverdlovsk, Russia",
  "91616, Trousdale Estates, California, United States",
  "Waynesboro, Virginia, United States",
  "Mirnyy, Tomsk, Russia",
  "Barton, England, United Kingdom",
  "453100, Sterlitamak, Bashkortostan, Russia",
  "98720, Kemijärvi, Lapland, Finland",
  "P0C 1M0, Torrance, Ontario, Canada",
  "Lake Minnehaha, Cheyenne, Wyoming, United States",
  "Coruche, Santarém, Portugal",
  "37857, Rogersville, Tennessee, United States",
  "Calumet, Minnesota, United States",
  "33321, Fort Lauderdale, Florida, United States",
  "Merricks North, Victoria, Australia",
  "2023 ZT, Haarlem, North Holland, Netherlands",
  "Winkler County, Texas, United States",
  "669001, Ordynskiy, Ust-Ordyn-Buryat, Russia",
  "30009, Murcia, Región de Murcia, Spain",
  "854, Sundsvall, Västernorrland County, Sweden",
  "Santander De Quilichao, Cauca, Colombia",
  "81-577, Gdynia, Pomorskie, Poland",
  "73301, Austin, Texas, United States",
  "Chester, Pennsylvania, United States",
  "611, Akureyri, Northeastern Region, Iceland",
  "Haskovo Municipality, Khaskovo, Bulgaria",
  "Tayshetsky District, Irkutsk, Russia",
  "Glazovo, Kursk, Russia",
  "La Rochelle-Normande, Normandy, France",
  "Georgetown, Illinois, United States",
  "Wilkin County, Minnesota, United States",
  "Kondrovo, Kaluga, Russia",
  "Roosevelt, Texas, United States",
  "243035, Novozybkov, Bryansk, Russia",
  "38050, Kayseri, Kayseri, Türkiye",
  "P0L 0B6, Fauquier, Ontario, Canada",
  "44149, Dortmund, North Rhine-Westphalia, Germany",
  "Ulm, Baden-Württemberg, Germany",
  "Beebe, Arkansas, United States",
  "385020, Maykop, Adygea, Russia",
  "61088, Winnebago, Illinois, United States",
  "02920, Cranston, Rhode Island, United States",
  "LV-5412, Daugavpils, Daugavpils, Latvia",
  "97139, Les Abymes, Pointe-à-Pitre, Guadeloupe",
  "29018, Málaga, Andalusia, Spain",
  "02904, Providence, Rhode Island, United States",
  "LV-5442, Demene, Daugavpils, Latvia",
  "Broadwater, Central Kootenay K, British Columbia, Canada",
  "5955, Nikopol, Pleven, Bulgaria",
  "Lexington, Mississippi, United States",
  "06004, Badajoz, Extremadura, Spain",
  "Safita District, Tartus Governorate, Syria",
  "95713, Colfax, California, United States",
  "Bernkastel-Wittlich, Rhineland-Palatinate, Germany",
  "29055-275, Vitória, Espírito Santo, Brazil",
  "399783, Yelets, Lipetsk, Russia",
  "67-115, Bytom Odrzański, Lubuskie, Poland",
  "23510, Norfolk, Virginia, United States",
  "610118, Piatra Neamţ, Neamţ, Romania",
  "62546, Morrisonville, Illinois, United States",
  "Hamar, Innlandet, Norway",
  "Hayes, Wimborne Minster, England, United Kingdom",
  "34420, Villeneuve-lès-Béziers, Occitanie, France",
  "Starrucca, Pennsylvania, United States",
  "Yadkin College, North Carolina, United States",
  "7900, Rørvik, Trøndelag, Norway",
  "Mason County, Kentucky, United States",
  "Wirtsmühle, Breitnau, Baden-Württemberg, Germany",
  "Farkë, Tirana, Albania",
  "Midyat, Mardin, Türkiye",
  "1210, Ljubljana, Ljubljana, Slovenia",
  "Old Millard East, Omaha, Nebraska, United States",
  "H34F4X3, Qulan, Jambyl Region, Kazakhstan",
  "Wirtshof, Huisheim, Bavaria, Germany",
  "618220, Chusovoy, Perm, Russia",
  "Tippah County, Mississippi, United States",
  "51100, Yukari Kayabasi, Niğde, Türkiye",
  "33177, Miami, Florida, United States",
  "84600-000, União da Vitória, Paraná, Brazil",
  "Greer Park, Georgia, United States",
  "810234, Brăila, Brăila, Romania",
  "Milam County, Texas, United States",
  "County Cork, Ireland",
  "Silverton, Oregon, United States",
  "Erath County, Texas, United States",
  "Tazewell County, Virginia, United States",
  "20-122, Lublin, Lubelskie, Poland",
  "400131, Volgograd, Volgograd, Russia",
  "Tuguro-Chumikanskiy Rayon, Khabarovsk, Russia",
  "Khilok, Leningrad, Russia",
  "05777, West Rutland, Vermont, United States",
  "353303, Kholmskaya, Krasnodar, Russia",
  "Békéscsaba, Békés, Hungary",
  "56123, Pisa, Tuscany, Italy",
  "Kanashevo, Chelyabinsk, Russia",
  "Ashland, Pennsylvania, United States",
  "Isle Of Skye, Scotland, United Kingdom",
  "Viborg, South Dakota, United States",
  "Nadymsky District, Yamalo-Nenets, Russia",
  "98520, Aberdeen, Washington, United States",
  "Obshtina Svishtov, Veliko Turnovo, Bulgaria",
  "Pukengahu, Taranaki, New Zealand",
  "Idanha-a-Nova, Castelo Branco, Portugal",
  "4816, Arendal, Agder, Norway",
  "Scott AFB, Illinois, United States",
  "671 33, Xánthi, Eastern Macedonia and Thrace, Greece",
  "08015, Browns Mills, New Jersey, United States",
  "Asti, Piedmont, Italy",
  "Skovorodino, Amur, Russia",
  "50003, Zaragoza, Aragon, Spain",
  "623964, Tavda, Sverdlovsk, Russia",
  "500001, Braşov, Braşov, Romania",
  "44410, Cortland, Ohio, United States",
  "Yrgyz, Aqtöbe region, Kazakhstan",
  "1160, Brussels, Brussels Region, Belgium",
  "Corson County, South Dakota, United States",
  "66522, Oneida, Kansas, United States",
  "663333, Noril'sk, Taymyr, Russia",
  "Fallon, Roanoke, Virginia, United States",
  "Pokrovskiy, Dagestan, Russia",
  "423457, Almetyevsk, Tatarstan, Russia",
  "New Delhi, Delhi, India",
  "Lamarão, Bahia, Brazil",
  "Box Hill North, Victoria, Australia",
  "Varna, Bulgaria",
  "Sauka, Jekabpils, Latvia",
  "Kuwait",
  "DL3 0AA, Darlington, England, United Kingdom",
  "34951, Fort Pierce, Florida, United States",
  "33000, Bordeaux, Nouvelle-Aquitaine, France",
  "Graham, Texas, United States",
  "Moray, Scotland, United Kingdom",
  "Fredericksburg, Ohio, United States",
  "Piatra Neamţ, Neamţ, Romania",
  "686333, Susuman, Magadan, Russia",
  "Oneida, New York, United States",
  "MD-2004, Chişinău, Chișinău, Moldova",
  "Stansted Mountfitchet, England, United Kingdom",
  "Union, South Carolina, United States",
  "98907, Yakima, Washington, United States",
  "Camden, Indiana, United States",
  "Bayfield, Wisconsin, United States",
  "Neshoba County, Mississippi, United States",
  "7401, Kaposvár, Somogy, Hungary",
  "Burtonwood, England, United Kingdom",
  "Rogers, Kentucky, United States",
  "Georgetown, Delaware, United States",
  "La Bertié, Saint-Jean-de-Vals, Occitanie, France",
  "IP13 0JJ, Pettistree, England, United Kingdom",
  "34950, Fort Pierce, Florida, United States",
  "5948, Nikopol, Pleven, Bulgaria",
  "57220, Savonlinna, South Savo, Finland",
  "6000, Perth, Western Australia, Australia",
  "692428, Kavalerovo, Primorye, Russia",
  "63390, Wright City, Missouri, United States",
  "45014, Fairfield, Ohio, United States",
  "7405, Ersekë, Korçë, Albania",
  "Murom, Vladimir, Russia",
  "NR24 2AL, Edgefield, England, United Kingdom",
  "75704, Tyler, Texas, United States",
  "Haakoninlahti, Helsinki, Uusimaa, Finland",
  "4823, Stokes, Queensland, Australia",
  "County Waterford, Ireland",
  "Treviso, Veneto, Italy",
  "Pawnee, Texas, United States",
  "Johnston, New Brunswick, Canada",
  "Blāzma, Ventspils Municipality, Latvia",
  "70114, New Orleans, Louisiana, United States",
  "71-246, Szczecin, Zachodniopomorskie, Poland",
  "Mikhaylovka, Primorye, Russia",
  "LV-4641, Spruževa, Rezekne, Latvia",
  "Lamar, Missouri, United States",
  "1138, Budapest, Budapest, Hungary",
  "456207, Zlatoust, Chelyabinsk, Russia",
  "120029, Buzău, Buzău, Romania",
  "Faulkner, Maryland, United States",
  "9100, Aalborg, North Denmark Region, Denmark",
  "665005, Tayshet, Irkutsk, Russia",
  "Lviv, Lviv, Ukraine",
  "Eastland Park, Casa Grande, Arizona, United States",
  "Ceyhan, Adana, Türkiye",
  "Pskov, Pskov, Russia",
  "Linares, Maule Region, Chile",
  "Erie–Meadville combined statistical area, Pennsylvania, United States",
  "Billingshurst, England, United Kingdom",
  "Ransom County, North Dakota, United States",
  "Rockingham, Vermont, United States",
  "155808, Kineshma, Ivanovo, Russia",
  "Dadeldhura, Nepal",
  "Romeoville, Illinois, United States",
  "82604, Zavala, Sinaloa, Mexico",
  "141506, Solnechnogorsk, Moscow, Russia",
  "Vossevangen, Vestland, Norway",
  "Luga, Leningrad, Russia",
  "Sargento Aldea, Chillán, Ñuble Region, Chile",
  "La Butême, La Chapelle-Rainsouin, Pays de la Loire, France",
  "905 01, Senica, Trnava Region, Slovakia",
  "628426, Surgut, Khanty-Mansi, Russia",
  "19005, Guadalajara, Castile-La Mancha, Spain",
  "031 01, Liptovsky Mikulas, Zilina, Slovakia",
  "Teton County, Idaho, United States",
  "29403, Charleston, South Carolina, United States",
  "Lárisa, Thessalia, Greece",
  "Nemaha County, Kansas, United States",
  "Lincolnshire, England, United Kingdom",
  "Krasnodar, Krasnodar, Russia",
  "25240, Erzurum, Erzurum, Türkiye",
  "3412, Librazhd, Elbasan, Albania",
  "Blackpool Area",
  "Harlan, Frenchman Butte 501, Saskatchewan, Canada",
  "Dover, England, United Kingdom",
  "Valentim Gentil, São Paulo, Brazil",
  "Elazığ, Türkiye",
  "Frauenfeld, Thurgau, Switzerland",
  "Durham, California, United States",
  "Yur’yevets, Ivanovo, Russia",
  "Orleans County, New York, United States",
  "Kayankulam, Kerala, India",
  "73013, Edmond, Oklahoma, United States",
  "Steuben, Pennsylvania, United States",
  "75645, Gilmer, Texas, United States",
  "666832, Vitimskiy, Irkutsk, Russia",
  "Connecticut, United States",
  "720166, Suceava, Suceava, Romania",
  "614022, Perm, Perm, Russia",
  "Deer Park, New York, United States",
  "15600, Burdur, Burdur, Türkiye",
  "96863, Kaneohe, Hawaii, United States",
  "542 45, Mariestad, Västra Götaland County, Sweden",
  "Hurstbourne Tarrant, England, United Kingdom",
  "Uvalde County, Texas, United States",
  "48122, Ravenna, Emilia-Romagna, Italy",
  "Fayette, Mississippi, United States",
  "200801, Craiova, Dolj, Romania",
  "694080, Dolinsk, Sakhalin, Russia",
  "Ebor, Pipestone, Manitoba, Canada",
  "Windham County, Connecticut, United States",
  "352801, Tuapse, Krasnodar, Russia",
  "Pipestone County, Minnesota, United States",
  "Bowmanville, Ontario, Canada",
  "976 31, Luleå, Norrbotten County, Sweden",
  "93234, Huron, California, United States",
  "1053, Middelburg, Mpumalanga, South Africa",
  "71100, Kırıkkale, Kırıkkale, Türkiye",
  "77351, Livingston, Texas, United States",
  "4030, Liège, Walloon Region, Belgium",
  "Nerchinsk, Zabaykalsky Krai, Russia",
  "Indiga, Nenets, Russia",
  "Highland, Illinois, United States",
  "641210, Yurgamysh, Kurgan, Russia",
  "Gasconade County, Missouri, United States",
  "SO15 3LE, Southampton, England, United Kingdom",
  "75785, Rusk, Texas, United States",
  "Clermont, Georgia, United States",
  "15-004, Białystok, Podlaskie, Poland",
  "181330, Vorontsovo, Pskov, Russia",
  "1220, Vienna, Vienna, Austria",
  "169710, Usinsk, Komi, Russia",
  "Phelps, Wisconsin, United States",
  "Verin Artashat, Ararat Province, Armenia",
  "Vila Viçosa, Évora, Portugal",
  "5071, Wals-Siezenheim, Salzburg, Austria",
  "677901, Yakutsk, Sakha, Russia",
  "Irkutsk, Irkutsk, Russia",
  "363759, Mozdok, Severnaya Osetiya-Alaniya, Russia",
  "Elbląg Gmina, Warmińsko-mazurskie, Poland",
  "100568, Ploieşti, Prahova, Romania",
  "677903, Yakutsk, Sakha, Russia",
  "Cardiff-by-the-Sea, California, United States",
  "393959, Morshansk, Tambov, Russia",
  "Forest Grove, Oregon, United States",
  "28692, Vilas, North Carolina, United States",
  "Beesel, Limburg, Netherlands",
  "Las Mercedes del Llano, Guarico State, Venezuela",
  "Faulkner County, Arkansas, United States",
  "Baia Mare metropolitan area, Romania",
  "N0C 1K0, Priceville, Ontario, Canada",
  "Altieylul, Balıkesir, Türkiye",
  "Wakefield, New Brunswick, Canada",
  "Civitavecchia, Latium, Italy",
  "97015, Clackamas, Oregon, United States",
  "27090, Gaziantep, Gaziantep, Türkiye",
  "85-858, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "Ishimbay, Bashkortostan, Russia",
  "Racines, Trentino-Alto Adige, Italy",
  "Akhtubinskiy, Astrakhan’, Russia",
  "59326, Fallon, Montana, United States",
  "72401, Jonesboro, Arkansas, United States",
  "Garfield Ridge, Chicago, Illinois, United States",
  "Davtashen, Yerevan, Armenia",
  "ST1 6JD, Stoke-On-Trent, England, United Kingdom",
  "Buckshaw Village, Chorley, England, United Kingdom",
  "Eagles Nest Estates, Gentry, Arkansas, United States",
  "9020, Tromsø, Troms og Finnmark, Norway",
  "427632, Glazov, Udmurtia, Russia",
  "Lamothe-Landerron, Nouvelle-Aquitaine, France",
  "656066, Barnaul, Altai Krai, Russia",
  "14469, Potsdam, Brandenburg, Germany",
  "Plášťovce, Nitra Region, Slovakia",
  "38501, Cookeville, Tennessee, United States",
  "8603, Mo i Rana, Nordland, Norway",
  "Rockdale County, Georgia, United States",
  "Westchester, Indiana, United States",
  "Downtown Wyandotte, Wyandotte, Michigan, United States",
  "Cabelli, Santa Sofia, Emilia-Romagna, Italy",
  "Turks and Caicos Islands",
  "4000, Plovdiv, Plovdiv, Bulgaria",
  "0470027, Otaru, Hokkaido, Japan",
  "694903, Uglegorsk, Sakhalin, Russia",
  "52200, Ordu, Ordu, Türkiye",
  "Forest, Ohio, United States",
  "215500, Safonovo, Smolensk, Russia",
  "Morriston, Swansea, Wales, United Kingdom",
  "Ottersweier, Baden-Württemberg, Germany",
  "PA15 1AJ, Greenock, Scotland, United Kingdom",
  "412921, Vol'sk, Saratov, Russia",
  "4841, Arendal, Agder, Norway",
  "141 86, Stockholm, Stockholm County, Sweden",
  "Fayetteville, Georgia, United States",
  "Wilson, Wyoming, United States",
  "Granite Shoals, Texas, United States",
  "Bedford County, Pennsylvania, United States",
  "88862, Treviso, Santa Catarina, Brazil",
  "29604, Marbella, Andalusia, Spain",
  "Kirsanov, Tambov, Russia",
  "Louplande, Pays de la Loire, France",
  "Sheksna, Vologda, Russia",
  "Columbus, Montana, United States",
  "NR25 6PF, West Beckham, England, United Kingdom",
  "Centre County, Pennsylvania, United States",
  "Muskingum County, Ohio, United States",
  "V92 F2KT, Tralee, County Kerry, Ireland",
  "Tipton Green, Tipton, England, United Kingdom",
  "460529, Pugachëvskiy, Orenburg, Russia",
  "LV-3623, Ventava, Ventspils, Latvia",
  "02300, Adıyaman, Adıyaman, Türkiye",
  "23072, Hayes, Virginia, United States",
  "Abakan, Khakassia, Russia",
  "Lanett, Alabama, United States",
  "34131, Kassel, Hesse, Germany",
  "Sankt Gallen, St Gallen, Switzerland",
  "7941, Rørvik, Trøndelag, Norway",
  "Marbella, Andalusia, Spain",
  "Denton, Michigan, United States",
  "NR28 0AE, North Walsham, England, United Kingdom",
  "Henry, Indiana, United States",
  "Muscatine County, Iowa, United States",
  "Arth, Schwyz, Switzerland",
  "Brulenz, Sankt Peter, Baden-Württemberg, Germany",
  "SR6 8DW, Sunderland, England, United Kingdom",
  "Gmina Inowrocław, Kujawsko-pomorskie, Poland",
  "Pamukkale, Denizli, Türkiye",
  "09600, Lunas, Kedah, Malaysia",
  "Pennington, Leigh, England, United Kingdom",
  "Stewartville, Minnesota, United States",
  "4645, Kristiansand, Agder, Norway",
  "Stephenson, Michigan, United States",
  "01568, Upton, Massachusetts, United States",
  "F92 X202, Letterkenny, County Donegal, Ireland",
  "8911 JW, Leeuwarden, Friesland, Netherlands",
  "LV-1010, Riga, Riga, Latvia",
  "6003, Kecskemét, Bács-Kiskun, Hungary",
  "Daugavpils, Latvia",
  "Petersburg, Kentucky, United States",
  "143202, Baba Bakala, Punjab, India",
  "Saint-Georges, French Guiana",
  "618203, Chusovoy, Perm, Russia",
  "Yusufeli Bucağı, Artvin, Türkiye",
  "E4H 0A2, Hillsborough, New Brunswick, Canada",
  "Tandlehill, Johnstone, Scotland, United Kingdom",
  "674357, Srednyaya Borzya, Zabaykalsky Krai, Russia",
  "Almería, Andalusia, Spain",
  "Moss, Tennessee, United States",
  "37370, Riceville, Tennessee, United States",
  "43005, Bladensburg, Ohio, United States",
  "Kaluga, Russia",
  "Hudson, Iowa, United States",
  "629745, Nadym, Yamalo-Nenets, Russia",
  "Norq Marash, Yerevan, Armenia",
  "Krasnyy Luch, Luhansk, Ukraine",
  "Hamlin, Texas, United States",
  "Price, South Australia, Australia",
  "305004, Kursk, Kursk, Russia",
  "Kasimov, Ryazan’, Russia",
  "Murray, Kentucky, United States",
  "423260, Leninogorsk, Tatarstan, Russia",
  "14801, Addison, New York, United States",
  "Millard, Tocane-Saint-Apre, Nouvelle-Aquitaine, France",
  "North Burnet, Austin, Texas, United States",
  "47003, Valladolid, Castilla and Leon, Spain",
  "Byala Slatina Municipality, Vratsa, Bulgaria",
  "33206, Gijón, Principality of Asturias, Spain",
  "Le Plateau, Abidjan, Côte d'Ivoire",
  "Portage Des Sioux, Missouri, United States",
  "Galveston, Indiana, United States",
  "3510, Hasselt, Flemish Region, Belgium",
  "Colombo District, Western Province, Sri Lanka",
  "Vladimir, Russia",
  "Union County, Ohio, United States",
  "Surgut, Samara, Russia",
  "Asha, Famagusta, Cyprus",
  "222519, Barysaw, Minsk, Belarus",
  "Hudson, Indiana, United States",
  "77423, Brookshire, Texas, United States",
  "Woodridge, Illinois, United States",
  "Nikol’skiy Torzhok, Vologda, Russia",
  "Harlington, Hayes, England, United Kingdom",
  "Deer Lake, Newfoundland and Labrador, Canada",
  "Halifax, Nova Scotia, Canada",
  "Pokrovskoye, Rostov, Russia",
  "HU8 7RR, Kingston Upon Hull, England, United Kingdom",
  "41124, Blaine, Kentucky, United States",
  "445030, Togliatti, Samara, Russia",
  "18586, Gager, Mecklenburg-West Pomerania, Germany",
  "Clarke, Santa Fe, Argentina",
  "Davison, Detroit, Michigan, United States",
  "352734, Derbentskiy, Krasnodar, Russia",
  "8514, Narvik, Nordland, Norway",
  "Edikli, Niğde, Türkiye",
  "Clinton, Iowa, United States",
  "Burleson County, Texas, United States",
  "99203, Spokane, Washington, United States",
  "Nassau East South, New Providence, The Bahamas",
  "Hopewell, Westmoreland, Jamaica",
  "58788, Towner, North Dakota, United States",
  "Gregory, Texas, United States",
  "722010, Jeti-Ögüz, Issyk-Kul Region, Kyrgyzstan",
  "155806, Kineshma, Ivanovo, Russia",
  "7200, Razgrad, Razgrad, Bulgaria",
  "907295, Tuzla, Constanţa, Romania",
  "20906, Silver Spring, Maryland, United States",
  "S9H 0Y5, Swift Current, Saskatchewan, Canada",
  "541, Menominee, Wisconsin, United States",
  "9386, Finnsnes, Troms og Finnmark, Norway",
  "28015, Madrid, Community of Madrid, Spain",
  "Dzhankoy, Crimea, Ukraine",
  "B25A8T5, Esik, Almaty Region, Kazakhstan",
  "99720, Sodankylä, Lapland, Finland",
  "309991, Valuyki, Belgorod, Russia",
  "Hood, California, United States",
  "5022, Porirua, Wellington, New Zealand",
  "Sibay, Bashkortostan, Russia",
  "352701, Timashevsk, Krasnodar, Russia",
  "Atbasar District, Akmola Region, Kazakhstan",
  "40-153, Katowice, Śląskie, Poland",
  "74477, Wagoner, Oklahoma, United States",
  "Sherburne, College Place, Washington, United States",
  "69101, North Platte, Nebraska, United States",
  "57130, Savonlinna, South Savo, Finland",
  "665838, Angarsk, Irkutsk, Russia",
  "Marquette, Iowa, United States",
  "Bonaire",
  "903 65, Umeå, Västerbotten County, Sweden",
  "Deschutes County, Oregon, United States",
  "Charleston County, South Carolina, United States",
  "115372, Moscow, Moscow City, Russia",
  "J1T 1T2, Asbestos, Quebec, Canada",
  "Nikolayevskiy, Altai Krai, Russia",
  "Mercer County, West Virginia, United States",
  "Stonewall, Texas, United States",
  "V93 PY00, Killarney, County Kerry, Ireland",
  "R13D1B5, Beyneu, Mangystau Region, Kazakhstan",
  "Platte County, Nebraska, United States",
  "EC2N 3AR, London, England, United Kingdom",
  "Oktyabr'skiy, Vitsyebsk, Belarus",
  "Anadyr, Chukot, Russia",
  "United States",
  "Greater Asti Metropolitan Area",
  "Mount Louisa, Queensland, Australia",
  "Kearney County, Nebraska, United States",
  "Smara, Laâyoune-Sakia El Hamra, Morocco",
  "McLeod Crossing, Albuquerque, New Mexico, United States",
  "Novorossiyskoye, Omsk, Russia",
  "65571, Summersville, Missouri, United States",
  "Passau, Bavaria, Germany",
  "31400, Toulouse, Occitanie, France",
  "L0L 1K0, Churchill, Ontario, Canada",
  "Kocalar, Elazığ, Türkiye",
  "94929, Dillon Beach, California, United States",
  "42413, Hanson, Kentucky, United States",
  "900038, Constanţa, Constanţa, Romania",
  "99086, Erfurt, Thuringia, Germany",
  "8220, Balatonalmádi, Veszprém, Hungary",
  "Mikhaylovka, Astrakhan’, Russia",
  "Dentergem, Flemish Region, Belgium",
  "Bucks, Ohio, United States",
  "Wilkesboro, North Carolina, United States",
  "11670, El Bosque, Andalusia, Spain",
  "Morocco",
  "Çamlıhemşin, Rize, Türkiye",
  "Tobercurry, County Sligo, Ireland",
  "Provadia, Varna, Bulgaria",
  "141304, Sergiyev Posad, Moscow, Russia",
  "Opolskie, Poland",
  "Porterstown (Cooke), County Westmeath, Ireland",
  "Prešov, Presov, Slovakia",
  "65183, Wiesbaden, Hesse, Germany",
  "P51 X5A0, Mallow, County Cork, Ireland",
  "674601, Borzya, Zabaykalsky Krai, Russia",
  "Southend-On-Sea, England, United Kingdom",
  "Dimitrovgrad, Ul’yanovsk, Russia",
  "Oktyabr'skoye, Yaroslavl’, Russia",
  "Berkeley Heights, New Jersey, United States",
  "Severny District, Orenburg, Russia",
  "44004, Ashtabula, Ohio, United States",
  "BT28 2XZ, Lisburn, Northern Ireland, United Kingdom",
  "Chippewa County, Michigan, United States",
  "Şardara District, Turkistan Region, Kazakhstan",
  "12985, Schuyler Falls, New York, United States",
  "Providence County, Rhode Island, United States",
  "36750, Teluk Intan, Perak, Malaysia",
  "423451, Almetyevsk, Tatarstan, Russia",
  "Moita, Setúbal, Portugal",
  "Kosli, Haryana, India",
  "Lexington, Indiana, United States",
  "Lewisburg, Pennsylvania, United States",
  "Mitchell, Indiana, United States",
  "Vadul lui Isac, Cahul District, Moldova",
  "51002, Ceuta, Ceuta, Spain",
  "Votkinskiy Rayon, Udmurtia, Russia",
  "Uglegorsky District, Sakhalin, Russia",
  "Rio de Janeiro, Brazil",
  "United States Minor Outlying Islands",
  "48125, Ravenna, Emilia-Romagna, Italy",
  "Neverskiy, Amur, Russia",
  "Varna, Illinois, United States",
  "Simpson, North Carolina, United States",
  "Castellón, Valencian Community, Spain",
  "82-300, Elbląg, Warmińsko-mazurskie, Poland",
  "Lovech, Lovech, Bulgaria",
  "629809, Noyabrsk, Yamalo-Nenets, Russia",
  "Eastland Park-Dixie Plantation, Lexington, Kentucky, United States",
  "Armstrong, Manitoba, Canada",
  "Washington County, Vermont, United States",
  "36553, McIntosh, Alabama, United States",
  "Neumarkt am Wallersee, Salzburg, Austria",
  "Avery Park, Fort Collins, Colorado, United States",
  "Henry County, Indiana, United States",
  "45326, Essen, North Rhine-Westphalia, Germany",
  "23564, Lübeck, Schleswig-Holstein, Germany",
  "Ripley, Tennessee, United States",
  "PO6 1AE, Portsmouth, England, United Kingdom",
  "Manchester, Maryland, United States",
  "Humboldt County, California, United States",
  "Garvin, Oklahoma, United States",
  "02908, Providence, Rhode Island, United States",
  "Mahnomen County, Minnesota, United States",
  "68169, Mannheim, Baden-Württemberg, Germany",
  "1501, Ljubljana, Ljubljana, Slovenia",
  "Gaya, South Gyeongsang, South Korea",
  "Glogovac, District of Pristina, Kosovo",
  "Kristianstads kommun, Skåne County, Sweden",
  "685000, Magadan, Magadan, Russia",
  "L02A2E8, Oral, West Kazakhstan Region, Kazakhstan",
  "Polatsk, Vitsyebsk, Belarus",
  "36010, Teluk Intan, Perak, Malaysia",
  "Thal, Styria, Austria",
  "Bonita Springs, Florida, United States",
  "Village of Scioto, Dublin, Ohio, United States",
  "Maury, Marquefave, Occitanie, France",
  "371 36, Karlskrona, Blekinge County, Sweden",
  "8050, Bodø, Nordland, Norway",
  "Zonguldak, Zonguldak, Türkiye",
  "Coburg, Bavaria, Germany",
  "80014, Aurora, Colorado, United States",
  "32200, Isparta, Isparta, Türkiye",
  "La Bertière, Tresboeuf, Brittany, France",
  "1092, Belmont-sur-Lausanne, Vaud, Switzerland",
  "355, Bexar, Alabama, United States",
  "Shannon, Mississippi, United States",
  "95600, Eaubonne, Île-de-France, France",
  "831 31, Östersund, Jämtland County, Sweden",
  "Navarro, General Trias, Calabarzon, Philippines",
  "08861, Perth Amboy, New Jersey, United States",
  "Dolna Mitropoliya, Pleven, Bulgaria",
  "Mikhaylovka, Tomsk, Russia",
  "78062, Karnes City, Texas, United States",
  "Marquette County, Michigan, United States",
  "Mineral'nyye Vody, Stavropol, Russia",
  "Handia, Madhya Pradesh, India",
  "San Bernardino, California, United States",
  "33140, Mersin, İçel, Türkiye",
  "Manchester, Jamaica",
  "Osage, Iowa, United States",
  "Hamburg, Michigan, United States",
  "VLT 1117, Valletta, Valletta, Malta",
  "Cottonwood West, Utah, United States",
  "60089, Buffalo Grove, Illinois, United States",
  "KW15 1SX, Kirkwall, Scotland, United Kingdom",
  "64060, Kearney, Missouri, United States",
  "Ottawa County, Oklahoma, United States",
  "Northumberland County, Virginia, United States",
  "8904, Zalaegerszeg, Zala, Hungary",
  "Florence, South Carolina, United States",
  "Buckingham, Virginia, United States",
  "Vilas County, Wisconsin, United States",
  "85-050, Bydgoszcz, Kujawsko-pomorskie, Poland",
  "Asipovichy District, Mahilyow, Belarus",
  "NR25 6AN, West Beckham, England, United Kingdom",
  "57533, Gregory, South Dakota, United States",
  "Hickory-Lenoir-Morganton-Marion Area",
  "Thurston, England, United Kingdom",
  "Ayr, Queensland, Australia",
  "Gates Center, New York, United States",
  "18053-220, Sorocaba, São Paulo, Brazil",
  "ZE1 0RE, Lerwick, Scotland, United Kingdom",
  "353494, Gelendzhik, Krasnodar, Russia",
  "Santa Amélia, Paraná, Brazil",
  "Parker, Pennsylvania, United States",
  "Esil, Akmola Region, Kazakhstan",
  "169521, Sosnogorsk, Komi, Russia",
  "Perrigny-lès-Dijon, Bourgogne-Franche-Comté, France",
  "Winona, Missouri, United States",
  "McPherson Acres, Laredo, Texas, United States",
  "Schuylerville, New York, New York, United States",
  "RM17 5AA, Grays, England, United Kingdom",
  "Jordan",
  "Greater Palm Bay-Melbourne-Titusville Area",
  "Irkutsky District, Irkutsk, Russia",
  "Faro, Portugal",
  "Saint-Léger-près-Troyes, Grand Est, France",
  "M28C5G9, Aqtau, Karaganda Region, Kazakhstan",
  "Vilas, Madison, Wisconsin, United States",
  "Lisburn And Castlereagh, Northern Ireland, United Kingdom",
  "Bottineau County, North Dakota, United States",
  "TR19 6JT, Penzance, England, United Kingdom",
  "Humboldt, Illinois, United States",
  "Borovichi, Pskov, Russia",
  "Poronaysky District, Sakhalin, Russia",
  "184430, Nikel, Murmansk, Russia",
  "Gauteng, South Africa",
  "Wallowa, Oregon, United States",
  "Semenivka, Zhytomyr, Ukraine",
  "Lebanon, Missouri, United States",
  "Nizhnekamsk, Tatarstan, Russia",
  "Kimballton, Iowa, United States",
  "Krasnogorsk, Sakhalin, Russia",
  "Hallandale Beach, Florida, United States",
  "DG1 2AT, Dumfries, Scotland, United Kingdom",
  "9609, Hammerfest, Troms og Finnmark, Norway",
  "La Rivière, Pembina, Manitoba, Canada",
  "IIPM Indian Institute of Petroleum, Dehradun, Uttarakhand, India",
  "352380, Kropotkin, Krasnodar, Russia",
  "Pawnee County, Kansas, United States",
  "689300, Lavrentiya, Chukot, Russia",
  "627014, Yalutorovsk, Tyumen’, Russia",
  "23210, Polača, Zadar, Croatia",
  "27613, Raleigh, North Carolina, United States",
  "Chitato, Lunda Norte Province, Angola",
  "1050-001, Lisbon, Lisbon, Portugal",
  "79235, Floydada, Texas, United States",
  "Polyarnyy, Murmansk, Russia",
  "Jammu & Kashmir, India",
  "Sabunchu Raion, Baku Ekonomic Zone, Azerbaijan",
  "09104, Bila Tserkva, Kyiv, Ukraine",
  "48-317, Korfantów, Opolskie, Poland",
  "Clinton, Illinois, United States",
  "50840, Clearfield, Iowa, United States",
  "Qendër Bulgarec, Korçë, Albania",
  "Wickenburg, Arizona, United States",
  "Red Sea State, Sudan",
  "Versailles, Kentucky, United States",
  "X38B3Y2, Kentau, Turkistan Region, Kazakhstan",
  "430011, Baia Mare, Maramureş, Romania",
  "Oktyabrsky, Ryazan’, Russia",
  "694242, Poronaysk, Sakhalin, Russia",
  "412756, Severnyy, Saratov, Russia",
  "Turku Metropolitan Area",
  "Cortland, Ohio, United States",
  "4686, Harding, KwaZulu-Natal, South Africa",
  "Greenbrier, Lafayette, Indiana, United States",
  "6425, Molde, Møre og Romsdal, Norway",
  "Callahan, Florida, United States",
  "20010, Denizli, Denizli, Türkiye",
  "Sweden",
  "4910-011, Caminha, Viana do Castelo, Portugal",
  "156005, Kostroma, Kostroma, Russia",
  "Linköpings kommun, Östergötland County, Sweden",
  "155814, Kineshma, Ivanovo, Russia",
  "169915, Vorkuta, Komi, Russia",
  "Trondheim Region",
  "Rocklin, California, United States",
  "6477, Harmanli, Khaskovo, Bulgaria",
  "18850, Ulster, Pennsylvania, United States",
  "Starke County, Indiana, United States",
  "Greater Kramatorsk Area",
  "Mecklenburg, Mecklenburg-West Pomerania, Germany",
  "360903, Nalchik, Kabardino-Balkaria, Russia",
  "34203, Kragujevac, Centralna Srbija, Serbia",
  "Coleraine, Northern Ireland, United Kingdom",
  "Crow Wing County, Minnesota, United States",
  "Martinsville, New South Wales, Australia",
  "4046, Linz, Upper Austria, Austria",
  "230070, Slatina, Olt, Romania",
  "2619, Lillehammer, Innlandet, Norway",
  "98231, Blaine, Washington, United States",
  "49015, Battle Creek, Michigan, United States",
  "Harlan County, Kentucky, United States",
  "Columbiana Ridge, Columbia, South Carolina, United States",
  "Cortlandt Manor, New York, United States",
  "Sunderland, Maryland, United States",
  "Williamsburg, Ontario, Canada",
  "Waynesboro Downtown Historic District, Virginia, United States",
  "187551, Tikhvin, Leningrad, Russia",
  "53189, Waukesha, Wisconsin, United States",
  "680031, Khabarovsk, Khabarovsk, Russia",
  "Dorchester, Iowa, United States",
  "68100, Mulhouse, Grand Est, France",
  "Williamson, Georgia, United States",
  "28203, Bremen, Bremen, Germany",
  "V0E 1B2, Armstrong, British Columbia, Canada",
  "Hamilton, Queensland, Australia",
  "6814 LB, Arnhem, Gelderland, Netherlands",
  "440026, Satu Mare, Satu Mare, Romania",
  "613049, Kirovo-Chepetsk, Kirov, Russia",
  "Gilesgate, Durham, England, United Kingdom",
  "28117, Mooresville, North Carolina, United States",
  "Marion, Massachusetts, United States",
  "La Joséphine, Les Sables-d’Olonne, Pays de la Loire, France",
  "Oxford, New York, United States",
  "93311, Bakersfield, California, United States",
  "Bland, Virginia, United States",
  "Albemarle Extension 1, Brakpan, Gauteng, South Africa",
  "430, Tarragona, Catalonia, Spain",
  "13800, Bitlis, Bitlis, Türkiye",
  "El Zaqaziq, Sharkia, Egypt",
  "Petushinsky District, Vladimir, Russia",
  "353488, Gelendzhik, Krasnodar, Russia",
  "White County, Indiana, United States",
  "Sainte-Maxime, Provence-Alpes-Côte d'Azur, France",
  "Thomastown, Victoria, Australia",
  "Pokrovske Raion, Dnipropetrovsk, Ukraine",
  "Harding, KwaZulu-Natal, South Africa",
  "656002, Barnaul, Altai Krai, Russia",
  "49320, Chippewa Lake, Michigan, United States",
  "Olenekskiy Natsional'nyy ulus, Sakha, Russia",
  "683023, Petropavlovsk-Kamchatskiy, Kamchatka, Russia",
  "Montour Guille, Nercillac, Nouvelle-Aquitaine, France",
  "F39C2P4, Zaysan, East Kazakhstan Region, Kazakhstan",
  "784, Borlänge, Dalarna County, Sweden",
  "628303, Nefteyugansk, Khanty-Mansi, Russia",
  "Lake Mary Estates, Umatilla, Florida, United States",
  "Buckingham, Illinois, United States",
  "Sarapul'skiy, Novosibirsk, Russia",
  "32213, Čačak, Centralna Srbija, Serbia",
  "Shirala, Maharashtra, India",
  "Randolph, Maine, United States",
  "Coos Bay, Oregon, United States",
  "Nazımiye, Tunceli, Türkiye",
  "66252, Tulungagung, East Java, Indonesia",
  "Fulton County, Indiana, United States",
  "13811, Tallinn, Harjumaa, Estonia",
  "13100, Hämeenlinna, Kanta-Häme, Finland",
  "44200, Nantes, Pays de la Loire, France",
  "Douglas, Massachusetts, United States",
  "30523, Clarkesville, Georgia, United States",
  "Kent, Connecticut, United States",
  "Olmsted Falls Center, Olmsted Falls, Ohio, United States",
  "Chusovoy, Perm, Russia",
  "48348, Kaunas, Kauno, Lithuania",
  "185031, Petrozavodsk, Karelia, Russia",
  "07011, Palma, Balearic Islands, Spain",
  "430024, Saransk, Mordovia, Russia",
  "35000, Slavonski Brod, Brod-Posavina, Croatia",
  "Eloy Alfaro Canton, Esmeraldas, Ecuador",
  "Sheridan County, North Dakota, United States",
  "BT6 0AA, Belfast, Northern Ireland, United Kingdom",
  "76544, Fort Hood, Texas, United States",
  "Bremen, Illinois, United States",
  "71357, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "22204, Arlington, Virginia, United States",
  "The Morrow Estates, Baytown, Texas, United States",
  "Greater Örebro Metropolitan Area",
  "Amasya, Türkiye",
  "TS9 5JN, Stokesley, England, United Kingdom",
  "40640, Jyväskylä, Central Finland, Finland",
  "79772, Pecos, Texas, United States",
  "40597, Düsseldorf, North Rhine-Westphalia, Germany",
  "3304, Gramsh, Elbasan, Albania",
  "Macao SAR",
  "618547, Solikamsk, Perm, Russia",
  "Cairo, Cairo, Egypt",
  "34450, Inverness, Florida, United States",
  "5200, Törökszentmiklós, Jász-Nagykun-Szolnok, Hungary",
  "8200, Veszprém, Veszprém, Hungary",
  "San Giovanni, Cagnano Amiterno, Abruzzi, Italy",
  "Amiens, Hauts-de-France, France",
  "66900, Yozgat, Yozgat, Türkiye",
  "N0E 0A2, Port Rowan, Ontario, Canada",
  "Box Hill South, Victoria, Australia",
  "HU6 7RX, Kingston Upon Hull, England, United Kingdom",
  "Vilasboas, Campo Grande, Mato Grosso do Sul, Brazil",
  "442060, Timiryazevo, Penza, Russia",
  "Harrison, Michigan, United States",
  "Allen, Nebraska, United States",
  "6002, Kecskemét, Bács-Kiskun, Hungary",
  "Leiria, Portugal",
  "50-002, Wrocław, Dolnośląskie, Poland",
  "E4M 1M2, Coburg, New Brunswick, Canada",
  "Benton, Missouri, United States",
  "Greater Birmingham, Alabama Area",
  "El Salvador",
  "34285, Venice, Florida, United States",
  "C19B9T6, Zhaltyr, Akmola Region, Kazakhstan",
  "B6L 6N6, Onslow Mountain, Nova Scotia, Canada",
  "04130, Almería, Andalusia, Spain",
  "456580, Yemanzhelinsk, Chelyabinsk, Russia",
  "624601, Alapayevsk, Sverdlovsk, Russia",
  "Cankiri, Çankırı, Türkiye",
  "LN8 2AP, Caenby, England, United Kingdom",
  "Morris, Minnesota, United States",
  "623373, Polevskoy, Sverdlovsk, Russia",
  "Wisconsin Dells, Wisconsin, United States",
  "Olney-Oak Lane, Philadelphia, Pennsylvania, United States",
  "2801, Tatabánya, Komárom-Esztergom, Hungary",
  "Gelendzhik, Krasnodar, Russia",
  "4051, Gaythorne, Queensland, Australia",
  "Nishitokyo, Tokyo, Japan",
  "93860, Landero y Cos, Veracruz, Mexico",
  "Caswell, Fraser-Fort George G, British Columbia, Canada",
  "99540, Sodankylä, Lapland, Finland",
  "Durham, England, United Kingdom",
  "37840, Oliver Springs, Tennessee, United States",
  "Selcuklu, Konya, Türkiye",
  "81-549, Gdynia, Pomorskie, Poland",
  "S13M0H1, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Huércal de Almería, Andalusia, Spain",
  "Deredolu, Gümüşhane, Türkiye",
  "20715, Bowie, Maryland, United States",
  "Ciénega de Zimatlán, Oaxaca, Mexico",
  "Omsukchan, Magadan, Russia",
  "Morgan County, Ohio, United States",
  "Pervari, Siirt, Türkiye",
  "Onslow Village, Guildford, England, United Kingdom",
  "184205, Apatity, Murmansk, Russia",
  "654080, Novokuznetsk, Kemerovo, Russia",
  "04155, Leipzig, Saxony, Germany",
  "Jimo, Shandong, China",
  "34070, Montpellier, Occitanie, France",
  "214000, Smolensk, Smolensk, Russia",
  "Çay, Afyon, Türkiye",
  "353218, Plastunovskaya, Krasnodar, Russia",
  "Mertert, Grevenmacher, Luxembourg",
  "Back Loop, Juneau, Alaska, United States",
  "10300, Balıkesir, Balıkesir, Türkiye",
  "16426, McKean, Pennsylvania, United States",
  "Odesa, Odessa, Ukraine",
  "Greenville, Mississippi, United States",
  "KW15 1DH, Kirkwall, Scotland, United Kingdom",
  "25-502, Kielce, Świętokrzyskie, Poland",
  "15300, Burdur, Burdur, Türkiye",
  "Shannon, Quebec, Canada",
  "Bratislava Old Town, Bratislava, Slovakia",
  "Zheleznogorsky District, Kursk, Russia",
  "NE6 1AA, Newcastle Upon Tyne, England, United Kingdom",
  "Calhoun County, Alabama, United States",
  "Greater Esbjerg Area",
  "Hubbard, Nebraska, United States",
  "Sopron, Győr-Moson-Sopron, Hungary",
  "Mazyrski Rayon, Homel, Belarus",
  "Kyiv, Ukraine",
  "Newton, Illinois, United States",
  "Tarbes, Occitanie, France",
  "Saunders Cove, Glovertown, Newfoundland and Labrador, Canada",
  "23438, Suffolk, Virginia, United States",
  "Lunenburg, Vermont, United States",
  "64063, Lees Summit, Missouri, United States",
  "385012, Maykop, Adygea, Russia",
  "Howard County, Maryland, United States",
  "Forrest City, Arkansas, United States",
  "Marquette County, Wisconsin, United States",
  "Laurens, South Carolina, United States",
  "SA3 4RY, Caswell, Wales, United Kingdom",
  "403320, Mikhaylovka Urban Okrug, Volgograd, Russia",
  "Colonial Place, Richmond, Virginia, United States",
  "Chitalmari Upazila, Khulna, Bangladesh",
  "164524, Severodvinsk, Arkhangel’sk, Russia",
  "Susuman, Magadan, Russia",
  "06480, La Colle-sur-Loup, Provence-Alpes-Côte d'Azur, France",
  "3102, Salgótarján, Nógrád, Hungary",
  "Burnett Heads, Queensland, Australia",
  "627010, Yalutorovsk, Tyumen’, Russia",
  "73802, Woodward, Oklahoma, United States",
  "Greater Clarksville Area",
  "Barranquilla Metropolitan Area",
  "Clinton County, Iowa, United States",
  "Bertie County, North Carolina, United States",
  "Magdagachi, Amur, Russia",
  "3511, Furth, Lower Austria, Austria",
  "Y35 NW65, Wexford, County Wexford, Ireland",
  "Webber-Camden, Minneapolis, Minnesota, United States",
  "40340, Nicholasville, Kentucky, United States",
  "60127, Ancona, Marches, Italy",
  "Perry, New York, United States",
  "IP32 6AD, Bury St Edmunds, England, United Kingdom",
  "Atlantı, Tunceli, Türkiye",
  "Manitowoc County, Wisconsin, United States",
  "Octeville, Normandy, France",
  "20637, Hughesville, Maryland, United States",
  "62080, Ramsey, Illinois, United States",
  "Manchester Center, Vermont, United States",
  "Bandera, Santiago del Estero, Argentina",
  "99037, Spokane Valley, Washington, United States",
  "Harrison Township, Michigan, United States",
  "Perkins Hill, North Carolina, United States",
  "Denton, Montana, United States",
  "Langlade, Portet, Nouvelle-Aquitaine, France",
  "54759, Pepin, Wisconsin, United States",
  "Bornova, İzmir, Türkiye",
  "New York City Metropolitan Area",
  "A91 WP65, Dundalk, County Louth, Ireland",
  "2011 XJ, Haarlem, North Holland, Netherlands",
  "546 40, Thessaloniki, Central Macedonia, Greece",
  "Şiran, Gümüşhane, Türkiye",
  "McLennan Lake, Division 18, Unorganized, Saskatchewan, Canada",
  "Revúca, Banska Bystrica, Slovakia",
  "652310, Topki, Kemerovo, Russia",
  "Humboldt, Tennessee, United States",
  "Vinton, Iowa, United States",
  "Pazaryolu, Erzurum, Türkiye",
  "Cumberland, Maryland, United States",
  "Mykolayiv, Lviv, Ukraine",
  "Hopewell, Ohio, United States",
  "412307, Balashov, Saratov, Russia",
  "67220, Wichita, Kansas, United States",
  "Sukošan, Zadar, Croatia",
  "Málaga, Andalusia, Spain",
  "Greater Denizli",
  "7002, Ruse, Ruse, Bulgaria",
  "Sibley, Iowa, United States",
  "E25C0X6, Maqat, Atyrau Region, Kazakhstan",
  "54200, Sakarya, Sakarya, Türkiye",
  "44050, Malatya, Malatya, Türkiye",
  "100330, Ploieşti, Prahova, Romania",
  "Toledo, Ohio Metropolitan Area",
  "462723, Dombarovskiy, Orenburg, Russia",
  "Craig, Colorado, United States",
  "618382, Kizel, Perm, Russia",
  "169319, Ukhta, Komi, Russia",
  "Devarkadra, Telangana, India",
  "Kholmsky District, Sakhalin, Russia",
  "68239, Mannheim, Baden-Württemberg, Germany",
  "Chickasaw, Louisville, Kentucky, United States",
  "249037, Obninsk, Kaluga, Russia",
  "Vila Nova da Cerveira, Viana do Castelo, Portugal",
  "H34F1E7, Qulan, Jambyl Region, Kazakhstan",
  "65742, Rogersville, Missouri, United States",
  "18055, Rostock, Mecklenburg-West Pomerania, Germany",
  "Chesterfield County, Virginia, United States",
  "South Bucks, England, United Kingdom",
  "Dayeuhluhur, Kecamatan Dayeuhluhur, Central Java, Indonesia",
  "8201, Tarragona, Davao Region, Philippines",
  "6211 AA, Maastricht, Limburg, Netherlands",
  "Montoursville, Pennsylvania, United States",
  "Spokane, Washington, United States",
  "454021, Chelyabinsk, Chelyabinsk, Russia",
  "Ravenna, Michigan, United States",
  "Onslow, Western Australia, Australia",
  "Harmony, North Carolina, United States",
  "Siirt, Siirt, Türkiye",
  "PE11 3ES, Deeping St Nicholas, England, United Kingdom",
  "Devon Lane, Harrisonburg, Virginia, United States",
  "White Plains, Maryland, United States",
  "301698, Novomoskovsk, Tula, Russia",
  "Stevens County, Minnesota, United States",
  "Pope County, Illinois, United States",
  "20095, Hamburg, Hamburg, Germany",
  "678550, Ust’-Kuyga, Sakha, Russia",
  "PE2 5EH, Peterborough, England, United Kingdom",
  "79700, Kilis, Kilis, Türkiye",
  "8030, Zurich, Zurich, Switzerland",
  "Saint-Louis, Pointe-à-Pitre, Guadeloupe",
  "Bladensburg, Ohio, United States",
  "Barneville-Carteret, Normandy, France",
  "Bucks Run, Florida, United States",
  "33500, Tampere, Pirkanmaa, Finland",
  "357902, Zelenokumsk, Stavropol, Russia",
  "Raleigh, Mississippi, United States",
  "652991, Tashtagol, Kemerovo, Russia",
  "Halle, Saxony-Anhalt, Germany",
  "Brisbane, Queensland, Australia",
  "Cortland, Flagstaff, Arizona, United States",
  "353762, Umanskiy, Rostov, Russia",
  "Perry, Ontario, Canada",
  "Putnam, Connecticut, United States",
  "40157, Payneville, Kentucky, United States",
  "140407, Kolomna, Moscow, Russia",
  "Jefferson, Georgia, United States",
  "12902-300, Bragança Paulista, São Paulo, Brazil",
  "Tarapacá Region, Chile",
  "Babinichy, Vitsyebsk, Belarus",
  "Potter County, Texas, United States",
  "Anoka, Minnesota, United States",
  "Porto Velho, Rondônia, Brazil",
  "NG1 1AA, Nottingham, England, United Kingdom",
  "Perry, Michigan, United States",
  "Gibsonia, Pennsylvania, United States",
  "Sibiu, Romania",
  "Chitapur, Karnataka, India",
  "Conchotte, Esmoulières, Bourgogne-Franche-Comté, France",
  "38004, Santa Cruz de Tenerife, Canary Islands, Spain",
  "Helena, Montana, United States",
  "Keyakona, Magrahat-I, West Bengal, India",
  "Red Oak, Texas, United States",
  "Cazouls-lès-Béziers, Occitanie, France",
  "34119, Naples, Florida, United States",
  "Dauphin, Pennsylvania, United States",
  "76179, Fort Worth, Texas, United States",
  "Nolant, Firfol, Normandy, France",
  "Innsbruck, Tyrol, Austria",
  "St Peter Port, Guernsey, Guernsey",
  "Cliff-Cannon, Spokane, Washington, United States",
  "140404, Kolomna, Moscow, Russia",
  "Evansville, Indiana, United States",
  "Le Mans, Pays de la Loire, France",
  "9405 HW, Assen, Drenthe, Netherlands",
  "69006, Zaporizhzhya, Zaporizhzhya, Ukraine",
  "Angelina, Santa Catarina, Brazil",
  "99087, Erfurt, Thuringia, Germany",
  "Klintsy, Bryansk, Russia",
  "18000, Bourges, Centre-Val de Loire, France",
  "80602, Brighton, Colorado, United States",
  "Pine Grove, California, United States",
  "Perrysburg, Ohio, United States",
  "700443, Iaşi, Iaşi, Romania",
  "64400, Uşak, Uşak, Türkiye",
  "665463, Usol'ye-Sibirskoye, Irkutsk, Russia",
  "8028, Bodø, Nordland, Norway",
  "981 40, Kiruna, Norrbotten County, Sweden",
  "15898, Santiago de Compostela, Galicia, Spain",
  "357915, Zelenokumsk, Stavropol, Russia",
  "59327, Forsyth, Montana, United States",
  "40-086, Katowice, Śląskie, Poland",
  "Adair, Iowa, United States",
  "169533, Sosnogorsk, Komi, Russia",
  "Tecuci, Galaţi, Romania",
  "Pottawatomie County, Oklahoma, United States",
  "85650, Sierra Vista, Arizona, United States",
  "Scioto Furnace, Ohio, United States",
  "75160, Terrell, Texas, United States",
  "Preston, Maryland, United States",
  "94608, Emeryville, California, United States",
  "Raymore, Missouri, United States",
  "92637, Laguna Woods, California, United States",
  "Aiken Point, Arkansas, United States",
  "Mariinskiy Rayon, Kemerovo, Russia",
  "M22 1BY, Wythenshawe, England, United Kingdom",
  "F18G3G8, Semey, Abay Region, Kazakhstan",
  "Grande Comore, Comoros",
  "73097, Wheatland, Oklahoma, United States",
  "Bedford, Kentucky, United States",
  "Snyderville, Utah, United States",
  "00501, Holtsville, New York, United States",
  "Sheridan Park, Sheboygan, Wisconsin, United States",
  "Fremont, Indiana, United States",
  "403881, Kamyshin, Volgograd, Russia",
  "Avery Creek, North Carolina, United States",
  "Alta, Troms og Finnmark, Norway",
  "6003, Stara Zagora, Stara Zagora, Bulgaria",
  "Bell Ville, Córdoba, Argentina",
  "Nashua, New Hampshire, United States",
  "E1A 1P6, Dieppe, New Brunswick, Canada",
  "Cagliari, Sardinia, Italy",
  "Bamberg, South Carolina, United States",
  "8032 AA, Zwolle, Overijssel, Netherlands",
  "Klaipėda District Municipality, Klaipėdos, Lithuania",
  "B6605BLK, Navarro, Buenos Aires Province, Argentina",
  "450141, Zalău, Sălaj, Romania",
  "7030, Trondheim, Trøndelag, Norway",
  "Kingsbury, Tulsa, Oklahoma, United States",
  "Cape Coral, Florida, United States",
  "9381, Aalborg, North Denmark Region, Denmark",
  "Boa Vista, Roraima, Brazil",
  "17315, Dover, Pennsylvania, United States",
  "Mamaivska silska hromada, Chernivtsi, Ukraine",
  "Macon, Georgia, United States",
  "Carlton, Oregon, United States",
  "6221 AA, Maastricht, Limburg, Netherlands",
  "624550, Ivdel’, Sverdlovsk, Russia",
  "F63A7P0, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "53600, Lappeenranta, South Karelia, Finland",
  "Grant, Kenosha, Wisconsin, United States",
  "424019, Yoshkar-Ola, Mari El, Russia",
  "Slobozia-Conache, Galaţi, Romania",
  "Buzuluksky District, Orenburg, Russia",
  "90460, Oulu, North Ostrobothnia, Finland",
  "21560, Diyarbakır, Diyarbakır, Türkiye",
  "58744, Karlsruhe, North Dakota, United States",
  "Saint James, Jamaica",
  "Kırşehir, Kırşehir, Türkiye",
  "Cambrian Heights, Calgary, Alberta, Canada",
  "03031, Amherst, New Hampshire, United States",
  "McKinleyville, New Brunswick, Canada",
  "713 05, Iráklion, Crete, Greece",
  "662063, Bogotol, Krasnoyarsk Krai, Russia",
  "Pulaski, Gary, Indiana, United States",
  "Greater Middlesbrough and Stockton Area",
  "Jiménez, Cartago, Costa Rica",
  "Galatina, Apulia, Italy",
  "32103, Ourense, Galicia, Spain",
  "Turners Hill, England, United Kingdom",
  "Klink, Mecklenburg-West Pomerania, Germany",
  "18310, Cankiri, Çankırı, Türkiye",
  "53340, Rize, Rize, Türkiye",
  "McKinley, Michigan, United States",
  "369002, Cherkessk, Karachay-Cherkessia, Russia",
  "6500, Svilengrad, Khaskovo, Bulgaria",
  "72101, Zenica, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "La Chaux-de-Fonds, Neuchâtel, Switzerland",
  "28922-600, Cabo Frio, Rio de Janeiro, Brazil",
  "Franklin, Virginia, United States",
  "Mulgi vald, Viljandimaa, Estonia",
  "673732, Mogocha, Zabaykalsky Krai, Russia",
  "5237 BA, ’s-Hertogenbosch, North Brabant, Netherlands",
  "Gumushane, Gümüşhane, Türkiye",
  "Buchanan, Saskatchewan, Canada",
  "95501, Eureka, California, United States",
  "C00B3E4, Kokshetau, Akmola Region, Kazakhstan",
  "S25B7X7, Ertis, Pavlodar Region, Kazakhstan",
  "Corumbá de Goiás, Goiás, Brazil",
  "Chitarpur, Jharkhand, India",
  "La Pierre Duchesne, La Celle-sur-Morin, Île-de-France, France",
  "La Quinetterie, Grimesnil, Normandy, France",
  "Hovsan, Baku Ekonomic Zone, Azerbaijan",
  "Whatcom Falls, Bellingham, Washington, United States",
  "20129, Milan, Lombardy, Italy",
  "5533, Haugesund, Rogaland, Norway",
  "R7N 3J5, Dauphin, Manitoba, Canada",
  "70180, Stuttgart, Baden-Württemberg, Germany",
  "80138, Parker, Colorado, United States",
  "Kelkit Bucağı, Gümüşhane, Türkiye",
  "62700, Tunceli, Tunceli, Türkiye",
  "423827, Naberezhnyye Chelny, Tatarstan, Russia",
  "Gislaveds kommun, Jönköping County, Sweden",
  "Sandovalina, São Paulo, Brazil",
  "362019, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "410012, Saratov, Saratov, Russia",
  "Linköping, Östergötland County, Sweden",
  "871 61, Härnösand, Västernorrland County, Sweden",
  "Trollhättan, Västra Götaland County, Sweden",
  "429113, Shumerlya, Chuvashia, Russia",
  "Dewittville, New York, United States",
  "690013, Vladivostok, Primorye, Russia",
  "80465, Morrison, Colorado, United States",
  "2300, Pernik, Pernik, Bulgaria",
  "Dane County, Wisconsin, United States",
  "Killarney-Turtle Mountain, Manitoba, Canada",
  "656060, Barnaul, Altai Krai, Russia",
  "03464, Stoddard, New Hampshire, United States",
  "Esik, Almaty Region, Kazakhstan",
  "Sousel, Portalegre, Portugal",
  "Figueira da Foz, Coimbra, Portugal",
  "Sioux City, Iowa, United States",
  "871 91, Härnösand, Västernorrland County, Sweden",
  "Altendorf, Schwyz, Switzerland",
  "Chumakivska silska hromada, Dnipropetrovsk, Ukraine",
  "Sauk City, Wisconsin, United States",
  "399060, Gryazi, Lipetsk, Russia",
  "Richardson County, Nebraska, United States",
  "9060-140, Funchal, Madeira Island, Portugal",
  "4702, Oensingen, Solothurn, Switzerland",
  "Sciotot, Les Pieux, Normandy, France",
  "Sainte-Foy-lès-Lyon, Auvergne-Rhône-Alpes, France",
  "Mercer County, New Jersey, United States",
  "910088, Călăraşi, Călăraşi, Romania",
  "6215-150, Covilhã, Castelo Branco, Portugal",
  "Buena Fe, Los Ríos, Ecuador",
  "454080, Chelyabinsk, Chelyabinsk, Russia",
  "Guarda, Portugal",
  "Zaporizhia Raion, Zaporizhzhya, Ukraine",
  "954 41, Luleå, Norrbotten County, Sweden",
  "B55B3H1, Zharkent, Jetisu Region, Kazakhstan",
  "352931, Armavir, Krasnodar, Russia",
  "48327, Waterford, Michigan, United States",
  "M52D4F2, Osakarovka, Karaganda Region, Kazakhstan",
  "73801, Woodward, Oklahoma, United States",
  "43600, Tulancingo, Hidalgo, Mexico",
  "Bexar County, Texas, United States",
  "Óbuda-Békásmegyer, Budapest, Hungary",
  "Plymouth, Wisconsin, United States",
  "Overton, Nevada, United States",
  "Desert Hot Springs, California, United States",
  "Kiğı, Bingöl, Türkiye",
  "70121, Bari, Apulia, Italy",
  "Votkinsk, Udmurtia, Russia",
  "McLeod Lake 5, Fraser-Fort George G, British Columbia, Canada",
  "Adams County, Colorado, United States",
  "Massachusetts, United States",
  "156016, Kostroma, Kostroma, Russia",
  "Bladensburg, Maryland, United States",
  "02720, Fall River, Massachusetts, United States",
  "8011, Burrel, Dibrë, Albania",
  "Yefremov, Tula, Russia",
  "Atlantique Department, Benin",
  "663340, Noril'sk, Taymyr, Russia",
  "9407, Harstad, Troms og Finnmark, Norway",
  "Meade County, Kentucky, United States",
  "48163, Münster, North Rhine-Westphalia, Germany",
  "Burton Latimer, England, United Kingdom",
  "Veterans Park, Sheboygan, Wisconsin, United States",
  "42329, Wuppertal, North Rhine-Westphalia, Germany",
  "Saint-Étienne-au-Mont, Hauts-de-France, France",
  "6057, Ålesund, Møre og Romsdal, Norway",
  "0657, Oslo, Oslo, Norway",
  "Orléans, Centre-Val de Loire, France",
  "85058, Vietri di Potenza, Basilicata, Italy",
  "6226 AB, Maastricht, Limburg, Netherlands",
  "35600, İzmir, İzmir, Türkiye",
  "6135, Stans, Tyrol, Austria",
  "S21 3YD, Spinkhill, England, United Kingdom",
  "9385, Finnsnes, Troms og Finnmark, Norway",
  "70780, Kuopio, Northern Savonia, Finland",
  "83714, Garden City, Idaho, United States",
  "Petit Nobles, La Chapelle-sous-Brancion, Bourgogne-Franche-Comté, France",
  "Powhatan County, Virginia, United States",
  "Goshen, Connecticut, United States",
  "Roosevelt, New York, United States",
  "Highlands County, Florida, United States",
  "28195, Bremen, Bremen, Germany",
  "Albemarle Point, Charleston, South Carolina, United States",
  "9000, Aalborg, North Denmark Region, Denmark",
  "09126, Chemnitz, Saxony, Germany",
  "E1A 1Z3, Dieppe, New Brunswick, Canada",
  "Bombarral, Leiria, Portugal",
  "920005, Slobozia, Ialomiţa, Romania",
  "Pustomyty Raion, Lviv, Ukraine",
  "Greek Rows, DeKalb Township, Illinois, United States",
  "Mezentsevo, Altai Krai, Russia",
  "82100, Drohobych, Lviv, Ukraine",
  "Nolan, Detroit, Michigan, United States",
  "Ritchie County, West Virginia, United States",
  "Sullivan County, Pennsylvania, United States",
  "14722, Chautauqua, New York, United States",
  "Garden Route, Western Cape, South Africa",
  "Petosky-Otsego, Detroit, Michigan, United States",
  "Nance, Céreste, Provence-Alpes-Côte d'Azur, France",
  "McLeansville, North Carolina, United States",
  "Stephensport, Kentucky, United States",
  "3530, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "02478, Belmont, Massachusetts, United States",
  "19904, Dover, Delaware, United States",
  "Engelssky District, Saratov, Russia",
  "Hickman County, Kentucky, United States",
  "Centro, Angelina, Santa Catarina, Brazil",
  "Adams, Massachusetts, United States",
  "Dillonvale, Ohio, United States",
  "Mahilyowski Rayon, Mahilyow, Belarus",
  "Marquette-lez-Lille, Hauts-de-France, France",
  "Cherkasy, Cherkasy, Ukraine",
  "Franklin, Ohio, United States",
  "Pennington County, South Dakota, United States",
  "Rensselaer County, New York, United States",
  "Erzincan, Erzincan, Türkiye",
  "Bladen, Nebraska, United States",
  "462290, Mednogorsk, Orenburg, Russia",
  "Rockland, Pennsylvania, United States",
  "95028, Hof, Bavaria, Germany",
  "County Limerick, Ireland",
  "Juneau, Wisconsin, United States",
  "South Georgia And South Sandwich Islands",
  "Lafayette, Tennessee, United States",
  "2317, Hamar, Innlandet, Norway",
  "Toledo, Iowa, United States",
  "Potter, Pennsylvania, United States",
  "Cabarrus County, North Carolina, United States",
  "La Marière, Grimesnil, Normandy, France",
  "6318, Broomehill West, Western Australia, Australia",
  "27896, Wilson, North Carolina, United States",
  "Langlade, Occitanie, France",
  "Altamura, Apulia, Italy",
  "675005, Blagoveshchensk, Amur, Russia",
  "Burke County, Georgia, United States",
  "62990-000, Potiretama, Ceará, Brazil",
  "Fluvanna County, Virginia, United States",
  "Quito Canton, Pichincha, Ecuador",
  "Kerrie, Victoria, Australia",
  "Suceava, Suceava, Romania",
  "Como, Colorado, United States",
  "OL1 1UN, Oldham, England, United Kingdom",
  "37879, Tazewell, Tennessee, United States",
  "962 01, Zvolenská Slatina, Banska Bystrica, Slovakia",
  "Neversdorf, Schleswig-Holstein, Germany",
  "Zorleni, Vaslui, Romania",
  "Merrick Moore, Durham, North Carolina, United States",
  "Torrance, Ontario, Canada",
  "Sumter Ridge, Kannapolis, North Carolina, United States",
  "Perry Barr, Birmingham, England, United Kingdom",
  "Stanley, Falkland Islands",
  "Kokkola sub-region, Central Ostrobothnia, Finland",
  "Horlivka, Donetsk, Ukraine",
  "2097, Wheeler Heights, New South Wales, Australia",
  "Chernivtsi Raion, Chernivtsi, Ukraine",
  "Mckinley, Sulop, Davao Region, Philippines",
  "975 96, Luleå, Norrbotten County, Sweden",
  "Araluen, New South Wales, Australia",
  "Altamonte Springs, Florida, United States",
  "Greater Valladolid Metropolitan Area",
  "Gallian, Bazordan, Occitanie, France",
  "Brunswick County, Virginia, United States",
  "Crockett, Virginia, United States",
  "Guadalajara, Mexico Metropolitan Area",
  "Saint-Georges, Saint-Georges, French Guiana",
  "730207, Vaslui, Vaslui, Romania",
  "East Lake Estates, Umatilla, Florida, United States",
  "462274, Mednogorsk, Orenburg, Russia",
  "Goodhue Township, Minnesota, United States",
  "33186, Miami, Florida, United States",
  "1205, Geneva, Geneva, Switzerland",
  "Canadian Estates, Oklahoma, United States",
  "Saluda, South Carolina, United States",
  "15100, Burdur, Burdur, Türkiye",
  "692422, Kavalerovo, Primorye, Russia",
  "8604, Mo i Rana, Nordland, Norway",
  "Almus, Tokat, Türkiye",
  "Samara, Russia",
  "50047, Carlisle, Iowa, United States",
  "York, Ontario, Canada",
  "Meade Vue, Nashville, Tennessee, United States",
  "Inowrocław, Kujawsko-pomorskie, Poland",
  "58400, Sivas, Sivas, Türkiye",
  "23228, Henrico, Virginia, United States",
  "Burundi",
  "Bayfield Inlet, The Archipelago, Ontario, Canada",
  "Greater Salamanca Metropolitan Area",
  "Robertsfors, Västerbotten County, Sweden",
  "KA18 2QR, Ochiltree, Scotland, United Kingdom",
  "Snyder, Oklahoma, United States",
  "29839, Aiken, South Carolina, United States",
  "4032, Linz, Upper Austria, Austria",
  "Mikhaylovka, Bashkortostan, Russia",
  "Walsham Le Willows, England, United Kingdom",
  "Petrozavodskoye, Chelyabinsk, Russia",
  "Ufa, Bashkortostan, Russia",
  "Đakovo, Osijek-Baranja, Croatia",
  "91612, Trousdale Estates, California, United States",
  "Petropavlovskoye, Chelyabinsk, Russia",
  "453109, Sterlitamak, Bashkortostan, Russia",
  "9025, Klagenfurt, Carinthia, Austria",
  "Aidu, Viljandimaa, Estonia",
  "601914, Kovrov, Vladimir, Russia",
  "55054, Elko New Market, Minnesota, United States",
  "Hopkins, Illinois, United States",
  "Bathurst, New South Wales, Australia",
  "Chelyabinsk, Russia",
  "33324, Fort Lauderdale, Florida, United States",
  "Callaway County, Missouri, United States",
  "23149, Saluda, Virginia, United States",
  "Wu-shê, Taiwan",
  "Yukari Kayabasi, Niğde, Türkiye",
  "Geneva, Nebraska, United States",
  "F17E0B5, Semey, Abay Region, Kazakhstan",
  "Graham, Kentucky, United States",
  "Pagelaran, Kecamatan Pagelaran, Banten, Indonesia",
  "Vladimirets, Rivne, Ukraine",
  "Kovdal, Central Denmark Region, Denmark",
  "78550-001, Sinop, Mato Grosso, Brazil",
  "Cumberland Bridge, Georgia, United States",
  "20762, Andrews AFB, Maryland, United States",
  "24105, Kiel, Schleswig-Holstein, Germany",
  "Crowland, England, United Kingdom",
  "77802, Bryan, Texas, United States",
  "42117, Wuppertal, North Rhine-Westphalia, Germany",
  "182648, Borovichi, Pskov, Russia",
  "45167, Ripley, Ohio, United States",
  "Leninsk-Kuznetsky, Kemerovo, Russia",
  "Vanadzor, Lori Province, Armenia",
  "Northampton, Western Australia, Australia",
  "Webster, Wisconsin, United States",
  "Atchison County, Missouri, United States",
  "Esendere, Hakkâri, Türkiye",
  "35000, Mala Liubasha, Rivne, Ukraine",
  "Elistanzhi, Chechnya, Russia",
  "Foster, Virginia, United States",
  "Stein, Schaffhausen, Switzerland",
  "PH7 3NB, Gilmerton, Scotland, United Kingdom",
  "Putnam Station, New York, United States",
  "363757, Mozdok, Severnaya Osetiya-Alaniya, Russia",
  "51103, Rijeka, Primorje-Gorski, Croatia",
  "V0K 1H0, Cache Creek, British Columbia, Canada",
  "Mo i Rana, Nordland, Norway",
  "367030, Makhachkala, Dagestan, Russia",
  "Preston, Iowa, United States",
  "NG7 1AA, Nottingham, England, United Kingdom",
  "Təklə, Lankaran-Astara, Azerbaijan",
  "Angelina, Baja California, Mexico",
  "37000, Tours, Centre-Val de Loire, France",
  "Vinnytsya, Ukraine",
  "F42H0F3, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "St Gallen, Switzerland",
  "Falls Church, Virginia, United States",
  "Karabuk, Karabuk, Türkiye",
  "650000, Kemerovo, Kemerovo, Russia",
  "601 50, Katerini, Central Macedonia, Greece",
  "Traille, Laveraët, Occitanie, France",
  "25070, Erzurum, Erzurum, Türkiye",
  "76097, Burleson, Texas, United States",
  "173009, Velikiy Novgorod, Novgorod, Russia",
  "Camp Meeker, California, United States",
  "Morrow, Missouri, United States",
  "West Glacier, Montana, United States",
  "Águeda, Aveiro, Portugal",
  "48855, Howell, Michigan, United States",
  "38000, Grenoble, Auvergne-Rhône-Alpes, France",
  "5518, Haugesund, Rogaland, Norway",
  "Cherokee Village, Arkansas, United States",
  "624561, Ivdel’, Sverdlovsk, Russia",
  "11230, Bilecik, Bilecik, Türkiye",
  "3100, Salgótarján, Nógrád, Hungary",
  "Staunton River, Virginia, United States",
  "Turate, Lombardy, Italy",
  "502295, Sangareddi, Telangana, India",
  "Hillsborough, North Carolina, United States",
  "S9H 3X6, Swift Current, Saskatchewan, Canada",
  "35-505, Rzeszów, Podkarpackie, Poland",
  "20240, Turku, Southwest Finland, Finland",
  "1967, Sion, Valais, Switzerland",
  "Stewart, Tennessee, United States",
  "Wilkinsburg, Pennsylvania, United States",
  "36367, Angersbach, Hesse, Germany",
  "Radford, Virginia, United States",
  "Andorra",
  "184220, Polyarnyye Zori, Murmansk, Russia",
  "Dixonville, Pennsylvania, United States",
  "F93 PHW3, Lifford, County Donegal, Ireland",
  "Çerkezköy Bucağı, Tekirdağ, Türkiye",
  "Anderson, South Carolina, United States",
  "152613, Uglich, Yaroslavl’, Russia",
  "55347, Eden Prairie, Minnesota, United States",
  "Taşköprü, Kastamonu, Türkiye",
  "CM24 8AA, Stansted Mountfitchet, England, United Kingdom",
  "670023, Ulan-Ude, Buryatia, Russia",
  "Tambovskiy, Amur, Russia",
  "04491, Vanceboro, Maine, United States",
  "37004, Salamanca, Castilla and Leon, Spain",
  "Ioannina, Epirus, Greece",
  "Comondú, Baja California Sur, Mexico",
  "Bogoroditsk, Tula, Russia",
  "903 20, Umeå, Västerbotten County, Sweden",
  "Orotukan, Magadan, Russia",
  "398001, Lipetsk, Lipetsk, Russia",
  "Chur, Graubünden, Switzerland",
  "163065, Archangel, Arkhangel’sk, Russia",
  "Georgiyevsky District, Stavropol, Russia",
  "Milan, Indiana, United States",
  "Rocky Mount, North Carolina, United States",
  "Tynda, Amur, Russia",
  "Palermo, Huila, Colombia",
  "Sarajevo, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "90765, Fürth, Bavaria, Germany",
  "Brown County, Illinois, United States",
  "11780, St James, New York, United States",
  "Genesee, Wisconsin, United States",
  "14221, Buffalo, New York, United States",
  "20606, Narva, Ida-Virumaa, Estonia",
  "Bonn, Victoria, Australia",
  "Hawkins Corner, New Brunswick, Canada",
  "03013, Alicante, Valencian Community, Spain",
  "Marquette, Manitoba, Canada",
  "15707, Santiago de Compostela, Galicia, Spain",
  "MD-2000, Chişinău, Chișinău, Moldova",
  "May Pen, Clarendon, Jamaica",
  "Smolenskiy, Altai Krai, Russia",
  "Rutland, New York, United States",
  "S25C2A7, Ertis, Pavlodar Region, Kazakhstan",
  "Benton, Tennessee, United States",
  "39148, Braxton, Mississippi, United States",
  "433315, Timiryazevskiy, Ul’yanovsk, Russia",
  "Chavarriaga Wilkin, Comuna 1, Valle del Cauca, Colombia",
  "Tom Price, Western Australia, Australia",
  "Orleans, Vermont, United States",
  "Kanawha Head, West Virginia, United States",
  "Pearl-Meigs-Monroe, Rochester, New York, United States",
  "48124, Ravenna, Emilia-Romagna, Italy",
  "Ozark Hills, Nashville, Tennessee, United States",
  "612742, Omutninsk, Kirov, Russia",
  "Sri Lanka",
  "Agen-d’Aveyron, Occitanie, France",
  "0010, Oslo, Oslo, Norway",
  "Toguchinsky District, Novosibirsk, Russia",
  "58852, Tioga, North Dakota, United States",
  "Wasatch, Provo, Utah, United States",
  "B29F4K7, Esik, Almaty Region, Kazakhstan",
  "Nash, Texas, United States",
  "Hamburg, Hamburg, Germany",
  "3020-872, Coimbra, Coimbra, Portugal",
  "130005, Cartagena, Bolívar, Colombia",
  "7400, Kaposvár, Somogy, Hungary",
  "47100, Mardin, Mardin, Türkiye",
  "Sun Valley, Los Angeles, California, United States",
  "Ocean Isle Beach, North Carolina, United States",
  "618404, Berezniki, Perm, Russia",
  "160022, Vologda, Vologda, Russia",
  "Xiyu Township, Taiwan, Taiwan",
  "972 41, Luleå, Norrbotten County, Sweden",
  "12500, Bingol, Bingöl, Türkiye",
  "Norfolk, Virginia, United States",
  "50526, Clarion, Iowa, United States",
  "175206, Staraya Russa, Novgorod, Russia",
  "Tokat, Türkiye",
  "Morton, Gainsborough, England, United Kingdom",
  "Gorey, County Wexford, Ireland",
  "Greater Milan Metropolitan Area",
  "90210, Beverly Hills, California, United States",
  "184710, Umba, Murmansk, Russia",
  "684414, Ust’-Kamchatsk, Kamchatka, Russia",
  "14532, Phelps, New York, United States",
  "09270, Aydın, Aydın, Türkiye",
  "79763, Odessa, Texas, United States",
  "Athis-Mons, Île-de-France, France",
  "9842, Kemijärvi, Lapland, Finland",
  "Walworth, London, England, United Kingdom",
  "W6 8JB, London, England, United Kingdom",
  "Taosang, Nungba, Manipur, India",
  "Bydgoszcz Metropolitan Area",
  "72000, Zenica, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "37452, Panevėžys, Panevėžio, Lithuania",
  "13300, Hämeenlinna, Kanta-Häme, Finland",
  "Sariçam, Adana, Türkiye",
  "Coulanges-lès-Nevers, Bourgogne-Franche-Comté, France",
  "Greater Reading Area",
  "42332, Zimapán, Hidalgo, Mexico",
  "2987 AA, Ridderkerk, South Holland, Netherlands",
  "40227, Düsseldorf, North Rhine-Westphalia, Germany",
  "76131, Karlsruhe, Baden-Württemberg, Germany",
  "12871, Schuylerville, New York, United States",
  "42338, Zimapán, Hidalgo, Mexico",
  "Wirt, Minnesota, United States",
  "16149, Genoa, Liguria, Italy",
  "30814, Lorca, Región de Murcia, Spain",
  "400074, Volgograd, Volgograd, Russia",
  "Karadeniz Ereğli, Zonguldak, Türkiye",
  "Calhoun, Louisiana, United States",
  "618381, Kizel, Perm, Russia",
  "46100, Kahramanmaraş, Kahraman Maras, Türkiye",
  "629801, Noyabrsk, Yamalo-Nenets, Russia",
  "300021, Timişoara, Timiş, Romania",
  "Yekaterinburg, Sverdlovsk, Russia",
  "623395, Polevskoy, Sverdlovsk, Russia",
  "Nor Norq, Yerevan, Armenia",
  "85055, Ingolstadt, Bavaria, Germany",
  "843327, Riga, Bihar, India",
  "353461, Gelendzhik, Krasnodar, Russia",
  "8032 PD, Zwolle, Overijssel, Netherlands",
  "647503, Dudinka, Krasnoyarsk Krai, Russia",
  "97354, Rémire-Montjoly, Cayenne, French Guiana",
  "652502, Leninsk-Kuznetskiy, Kemerovo, Russia",
  "Carver, Minnesota, United States",
  "618409, Berezniki, Perm, Russia",
  "167009, Syktyvkar, Komi, Russia",
  "Oswego Town, New York, United States",
  "Mezen, Arkhangel’sk, Russia",
  "8509, Kukës, Kukës, Albania",
  "Aginskoye, Agin-Buryat, Russia",
  "Horta-Guinardó, Catalonia, Spain",
  "Cameron, South Carolina, United States",
  "Cheshire, Ohio, United States",
  "North Holland, Netherlands",
  "40930, Dewitt, Kentucky, United States",
  "Tula de Allende, Hidalgo, Mexico",
  "Laclède, Sainte-Marie-de-Gosse, Nouvelle-Aquitaine, France",
  "Murcia, Región de Murcia, Spain",
  "Loraine, Texas, United States",
  "Gyumri, Shirak Province, Armenia",
  "Alcorneo, Valencia de Alcántara, Extremadura, Spain",
  "66132, Saarbrücken, Saarland, Germany",
  "120069, Buzău, Buzău, Romania",
  "45020, Manisa, Manisa, Türkiye",
  "Verkhnevilyuysky District, Sakha, Russia",
  "Eddy County, New Mexico, United States",
  "06824, Fairfield, Connecticut, United States",
  "Shujalpur, Madhya Pradesh, India",
  "Krolevets, Sumy, Ukraine",
  "Saratoga County, New York, United States",
  "Ross, Indiana, United States",
  "Greater Rome Metropolitan Area",
  "320197, Reşiţa, Caraş-Severin, Romania",
  "Frederickson, Washington, United States",
  "42240, Hopkinsville, Kentucky, United States",
  "8009, Bodø, Nordland, Norway",
  "238177, Chernyakhovskiy, Kaliningrad, Russia",
  "Grafton, Nova Scotia, Canada",
  "Chad",
  "Nevşehir, Nevşehir, Türkiye",
  "692871, Partizansk, Primorye, Russia",
  "Doğubayazıt, Ağrı, Türkiye",
  "FY4 5EG, Blackpool, England, United Kingdom",
  "Volzhskiy Utes, Samara, Russia",
  "24000, Subotica, Vojvodina, Serbia",
  "Haliliye, Şanliurfa, Türkiye",
  "Bradford-On-Avon, England, United Kingdom",
  "Forsyth Cove, Azalea Park, Florida, United States",
  "64086, Lees Summit, Missouri, United States",
  "Petersburg, Indiana, United States",
  "R25A5H9, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "Darechera, Amhara Region, Ethiopia",
  "623418, Kamensk Uralskiy, Sverdlovsk, Russia",
  "66291, Tulungagung, East Java, Indonesia",
  "West Beckham, England, United Kingdom",
  "Windham, Maine, United States",
  "629003, Salekhard, Yamalo-Nenets, Russia",
  "Gornyak, Ryazan’, Russia",
  "Karlovo, Plovdiv, Bulgaria",
  "19551, Robesonia, Pennsylvania, United States",
  "SA3 4SD, Caswell, Wales, United Kingdom",
  "Kremenchutska miska hromada, Poltava, Ukraine",
  "Mingo, North Carolina, United States",
  "Murray, Western Australia, Australia",
  "347044, Belaya Kalitva, Rostov, Russia",
  "91011, Luhansk, Luhansk, Ukraine",
  "Szczecin Metropolitan Area",
  "Ulm, Montana, United States",
  "Greater Roanoke Area",
  "NG8 1AA, Nottingham, England, United Kingdom",
  "Çayırlı, Erzincan, Türkiye",
  "65543, Lynchburg, Missouri, United States",
  "76649, Iredell, Texas, United States",
  "Marinette, Wisconsin, United States",
  "Hameau Tollemer, Barneville-Carteret, Normandy, France",
  "Monsey, New York, United States",
  "4333 EA, Middelburg, Zeeland, Netherlands",
  "St Sampson, Guernsey, Guernsey",
  "Logan County, Colorado, United States",
  "Tingley, Wakefield, England, United Kingdom",
  "Murray, Missouri, United States",
  "Sevier, Utah, United States",
  "Serebryansk, East Kazakhstan Region, Kazakhstan",
  "27060, Gaziantep, Gaziantep, Türkiye",
  "47852, Sullivan, Indiana, United States",
  "Biryusinsk, Irkutsk, Russia",
  "10460, Bronx, New York, United States",
  "82200, Joensuu, North Karelia, Finland",
  "Barysaw, Minsk, Belarus",
  "Toguchin, Novosibirsk, Russia",
  "Broken Arrow, Oklahoma, United States",
  "Özkürtün, Gümüşhane, Türkiye",
  "243146, Klintsy, Bryansk, Russia",
  "Samobor, Zagreb, Croatia",
  "H53 D297, Ballinasloe, County Galway, Ireland",
  "Serebryanskoye, Novosibirsk, Russia",
  "21002, Huelva, Andalusia, Spain",
  "510, Ceuta, Ceuta, Spain",
  "50567, Nemaha, Iowa, United States",
  "Saint Kitts and Nevis",
  "29061, Hopkins, South Carolina, United States",
  "172505, Nelidovo, Tver’, Russia",
  "Olsztyński, Warmińsko-mazurskie, Poland",
  "N1M 2W3, Fergus, Ontario, Canada",
  "7804, Namsos, Trøndelag, Norway",
  "01001, Kyiv, Kyiv City, Ukraine",
  "Hertford County, North Carolina, United States",
  "Colfax County, Nebraska, United States",
  "Westmoreland, New Hampshire, United States",
  "Ust-Kut, Irkutsk, Russia",
  "Shackelford, California, United States",
  "61400, Trabzon, Trabzon, Türkiye",
  "140413, Kolomna, Moscow, Russia",
  "Dudinka, Taymyr, Russia",
  "Winchester City County, Virginia, United States",
  "Curry, County Leitrim, Ireland",
  "Oliveira de Frades, Souto de Lafões e Sejães, Viseu, Portugal",
  "16350, Bursa, Bursa, Türkiye",
  "453832, Sibay, Bashkortostan, Russia",
  "Cabiro, Chélan, Occitanie, France",
  "7407, Kaposvár, Somogy, Hungary",
  "29240-959, Alfredo Chaves, Espírito Santo, Brazil",
  "Dauphin, Manitoba, Canada",
  "Edmundston, New Brunswick, Canada",
  "184410, Nikel, Murmansk, Russia",
  "NG3 1AA, Nottingham, England, United Kingdom",
  "6550, Lyubimets, Khaskovo, Bulgaria",
  "8281 EJ, Hasselt, Overijssel, Netherlands",
  "14530, Hämeenlinna, Kanta-Häme, Finland",
  "98258, Lake Stevens, Washington, United States",
  "5001, Namur, Walloon Region, Belgium",
  "Walker, Newcastle Upon Tyne, England, United Kingdom",
  "Montemor-o-Novo, Évora, Portugal",
  "National Capital Region, Philippines",
  "347371, Volgodonsk, Rostov, Russia",
  "Westchester, Los Angeles, California, United States",
  "50170, Mikkeli, South Savo, Finland",
  "653050, Prokopyevsk, Kemerovo, Russia",
  "Culpeper Historic District, Culpeper, Virginia, United States",
  "717, Caddo, Arkansas, United States",
  "Mariinskiy, Novosibirsk, Russia",
  "40235, Düsseldorf, North Rhine-Westphalia, Germany",
  "352 45, Växjö, Kronoberg County, Sweden",
  "Ramsey, Indiana, United States",
  "907 51, Umeå, Västerbotten County, Sweden",
  "602263, Murom, Vladimir, Russia",
  "Kirovsk, Murmansk, Russia",
  "Maharashtra, India",
  "Solna, Stockholm County, Sweden",
  "77077, Houston, Texas, United States",
  "Tippecanoe County, Indiana, United States",
  "Webber, Illinois, United States",
  "Fallon, Lucan Biddulph, Ontario, Canada",
  "Kocherinovo Municipality, Kyustendil, Bulgaria",
  "347374, Volgodonsk, Rostov, Russia",
  "Irkutsk Metropolitan Area",
  "Chatham, Ontario, Canada",
  "613150, Slobodskoy, Kirov, Russia",
  "Beerse, Flemish Region, Belgium",
  "73011, Kherson, Kherson, Ukraine",
  "Bondy, Île-de-France, France",
  "462408, Orsk, Orenburg, Russia",
  "51012, Cherokee, Iowa, United States",
  "655750, Abaza, Khakassia, Russia",
  "80016, Aurora, Colorado, United States",
  "Latimer, Squamish-Lillooet C, British Columbia, Canada",
  "01462, Lunenburg, Massachusetts, United States",
  "662159, Achinsk, Krasnoyarsk Krai, Russia",
  "Lyubimets, Khaskovo, Bulgaria",
  "76018, Ivano-Frankivsk, Ivano-Frankivsk, Ukraine",
  "Lee County, Iowa, United States",
  "628186, Nyagan’, Khanty-Mansi, Russia",
  "142206, Serpukhov, Moscow, Russia",
  "403538, Frolovo, Volgograd, Russia",
  "220193, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "353682, Yeysk, Krasnodar, Russia",
  "X45C2B7, Turkestan, Turkistan Region, Kazakhstan",
  "185003, Petrozavodsk, Karelia, Russia",
  "75016, Paris, Île-de-France, France",
  "184049, Kandalaksha, Murmansk, Russia",
  "Anderson County, Texas, United States",
  "Park View Estates, Hitchcock, Texas, United States",
  "Tillmans Corner, Alabama, United States",
  "13000, Bitlis, Bitlis, Türkiye",
  "Barrio Brazos Unidos, Montevideo, Montevideo, Uruguay",
  "413721, Pugachëv, Saratov, Russia",
  "Tucker Woods, Georgia, United States",
  "Karakoçan, Elazığ, Türkiye",
  "Sfântu Gheorghe, Tulcea, Romania",
  "678142, Lensk, Sakha, Russia",
  "H91 X5CX, Galway, County Galway, Ireland",
  "Australia",
  "Viljandi, Viljandimaa, Estonia",
  "Russellville, Arkansas, United States",
  "Beaver, West Virginia, United States",
  "Daviet, Saint-Gervais, Nouvelle-Aquitaine, France",
  "2205, Starše, Maribor, Slovenia",
  "652055, Yurga Vtoraya, Kemerovo, Russia",
  "301 32, Agrínio, Western Greece, Greece",
  "Kostyantynivka, Mykolayiv, Ukraine",
  "8304, Wallisellen / Hof, Zurich, Switzerland",
  "91001, Klaipėda, Klaipėdos, Lithuania",
  "429330, Kanash, Chuvashia, Russia",
  "165301, Kotlas, Arkhangel’sk, Russia",
  "686314, Susuman, Magadan, Russia",
  "80469, Munich, Bavaria, Germany",
  "Cologne Bonn Region",
  "22200, Edirne, Edirne, Türkiye",
  "06950, Porvoo, Uusimaa, Finland",
  "10452, Bronx, New York, United States",
  "Allen Parish County, Louisiana, United States",
  "Slobodskoy rayon, Kirov, Russia",
  "Licking County, Ohio, United States",
  "A0A 1W0, Clarkes Beach, Newfoundland and Labrador, Canada",
  "K1A 0A1, Ottawa, Ontario, Canada",
  "666931, Bodaybo, Irkutsk, Russia",
  "29532, Darlington, South Carolina, United States",
  "Essex County, Vermont, United States",
  "Moose Can Gully, Missoula, Montana, United States",
  "Ełk, Warmińsko-mazurskie, Poland",
  "Beaver, Utah, United States",
  "626021, Nizhnyaya Tavda, Tyumen’, Russia",
  "Kayin State, Myanmar",
  "94710, Berkeley, California, United States",
  "36556, McIntosh, Alabama, United States",
  "371 38, Karlskrona, Blekinge County, Sweden",
  "Russell County, Kansas, United States",
  "Summerside, Edmonton, Alberta, Canada",
  "Greater Castellón de la Plana Area",
  "Robertson County, Tennessee, United States",
  "79761, Odessa, Texas, United States",
  "5527, Haugesund, Rogaland, Norway",
  "WR10 2JY, Throckmorton, England, United Kingdom",
  "44300, Nantes, Pays de la Loire, France",
  "670042, Ulan-Ude, Buryatia, Russia",
  "164510, Severodvinsk, Arkhangel’sk, Russia",
  "84135, Salerno, Campania, Italy",
  "Lander Keema, Kaithal, Haryana, India",
  "301668, Novomoskovsk, Tula, Russia",
  "Livorno, Tuscany, Italy",
  "351 33, Lamía, Central Greece, Greece",
  "Europe",
  "Gates, Oregon, United States",
  "Dayton, Western Australia, Australia",
  "Obshtina Kocherinovo, Kyustendil, Bulgaria",
  "Carlisle, Massachusetts, United States",
  "9900, Frederikshavn, North Denmark Region, Denmark",
  "424, Nordöstra Göteborg, Västra Götaland County, Sweden",
  "Chenango Forks, New York, United States",
  "Shawano, Wisconsin, United States",
  "Ramsey, Illinois, United States",
  "Colleton, Barbados",
  "Rogers, New Mexico, United States",
  "28920-238, Cabo Frio, Rio de Janeiro, Brazil",
  "BD20 7EU, Sutton-In-Craven, England, United Kingdom",
  "Asher, Oklahoma, United States",
  "Frederick, Colorado, United States",
  "Crowsnest Pass, Alberta, Canada",
  "Cap Malheureux, Rivière du Rempart, Mauritius",
  "7002, Eisenstadt, Burgenland, Austria",
  "Midland County, Texas, United States",
  "McLeod, Edmonton, Alberta, Canada",
  "9704, Szombathely, Vas, Hungary",
  "Greenwood, Florida, United States",
  "Carbon Hill, Alabama, United States",
  "Hickory Grove, Illinois, United States",
  "70112, New Orleans, Louisiana, United States",
  "140406, Kolomna, Moscow, Russia",
  "Borisoglebskoye, Vladimir, Russia",
  "Chickasaw Trails, Florida, United States",
  "Orikum, Vlorë, Albania",
  "LE12 6NX, East Leake, England, United Kingdom",
  "22405, Fredericksburg, Virginia, United States",
  "225521, Kapani, Brest, Belarus",
  "Alagoas, Brazil",
  "67226, Wichita, Kansas, United States",
  "58040, Sivas, Sivas, Türkiye",
  "0484, Oslo, Oslo, Norway",
  "Borceag, Cahul District, Moldova",
  "5623, Békéscsaba, Békés, Hungary",
  "694490, Okha, Sakhalin, Russia",
  "9001 AA, Leeuwarden, Friesland, Netherlands",
  "01200, Adana, Adana, Türkiye",
  "Pike County, Ohio, United States",
  "1007, Lausanne, Vaud, Switzerland",
  "689251, Provideniya, Chukot, Russia",
  "8050, Székesfehérvár, Fejér, Hungary",
  "610148, Piatra Neamţ, Neamţ, Romania",
  "87522, Mariupol, Donetsk, Ukraine",
  "Vaslui, Romania",
  "160002, Vologda, Vologda, Russia",
  "723 00, Sitía, Crete, Greece",
  "694222, Poronaysk, Sakhalin, Russia",
  "35-315, Rzeszów, Podkarpackie, Poland",
  "Polk County, Iowa, United States",
  "Sumner, Michigan, United States",
  "Brașov metropolitan area, Braşov, Romania",
  "Benton County, Iowa, United States",
  "Martina Franca, Apulia, Italy",
  "5400, Mezőtúr, Jász-Nagykun-Szolnok, Hungary",
  "02350, Hanson, Massachusetts, United States",
  "683006, Petropavlovsk-Kamchatskiy, Kamchatka, Russia",
  "Popel, Siem Reap, Cambodia",
  "28380, Rockingham, North Carolina, United States",
  "624282, Asbest, Sverdlovsk, Russia",
  "South Foster, Rhode Island, United States",
  "Municipiul Tiraspol, Transnistria autonomous territorial unit, Moldova",
  "Viļāni Parish, Rēzekne Municipality, Latvia",
  "Segezha, Karelia, Russia",
  "Anenii Noi District, Moldova",
  "72102, Zenica, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "31014, Pamplona, Chartered Community of Navarre, Spain",
  "Sandoval, Narra, Mimaropa, Philippines",
  "652155, Mariinskiy, Kemerovo, Russia",
  "Greater Nikopol Area",
  "627011, Yalutorovsk, Tyumen’, Russia",
  "Onslow, Iowa, United States",
  "9700, Szombathely, Vas, Hungary",
  "Durağan, Sinop, Türkiye",
  "Broomehill West, Western Australia, Australia",
  "Wilkes County, North Carolina, United States",
  "Kanashevskoye, Chelyabinsk, Russia",
  "Portage, Ohio, United States",
  "670035, Ulan-Ude, Buryatia, Russia",
  "Les Nobles, Villard-de-Lans, Auvergne-Rhône-Alpes, France",
  "Tillman, South Carolina, United States",
  "Thomas County, Georgia, United States",
  "655001, Abakan, Khakassia, Russia",
  "Tønsberg, Vestfold og Telemark, Norway",
  "623967, Tavda, Sverdlovsk, Russia",
  "Oldtown, Lake Oswego, Oregon, United States",
  "Floyd, New Mexico, United States",
  "77484, Waller, Texas, United States",
  "Tootsi, Pärnu maakond, Estonia",
  "6060, Sarnen, Obwalden, Switzerland",
  "Hitchcock, South Dakota, United States",
  "East Rutherford, New Jersey, United States",
  "374 00, Vólos, Thessalia, Greece",
  "4004, Plovdiv, Plovdiv, Bulgaria",
  "Chorlton-Cum-Hardy, Manchester, England, United Kingdom",
  "32224, Čačak, Centralna Srbija, Serbia",
  "020, Albacete, Castile-La Mancha, Spain",
  "16320, Bursa, Bursa, Türkiye",
  "10579, Putnam Valley, New York, United States",
  "Banská Bystrica, Banska Bystrica, Slovakia",
  "Pustec, Korçë, Albania",
  "Bogoroditskoye, Ryazan’, Russia",
  "Bengeo, Hertford, England, United Kingdom",
  "64100, Uşak, Uşak, Türkiye",
  "Fulton County, Pennsylvania, United States",
  "Winwick Quay, Warrington, England, United Kingdom",
  "Antakya, Hatay, Türkiye",
  "Belmont, New York, New York, United States",
  "Bath, England, United Kingdom",
  "McMullen County, Texas, United States",
  "173004, Velikiy Novgorod, Novgorod, Russia",
  "Krasnoarmeyskaya, Krasnodar, Russia",
  "Sântana, Arad, Romania",
  "353206, Plastunovskaya, Krasnodar, Russia",
  "Kuşadası Bucağı, Aydın, Türkiye",
  "909 01, Skalica, Trnava Region, Slovakia",
  "Mitchell, South Dakota, United States",
  "84128, Salerno, Campania, Italy",
  "55330, Lappeenranta, South Karelia, Finland",
  "M52B8A2, Osakarovka, Karaganda Region, Kazakhstan",
  "Stanton, Iowa, United States",
  "Linklater, Pipestone, Manitoba, Canada",
  "052836, Bucharest, Bucharest, Romania",
  "Gallatin, Missouri, United States",
  "Karatay, Konya, Türkiye",
  "LV-4604, Sondori, Rēzekne Municipality, Latvia",
  "249036, Obninsk, Kaluga, Russia",
  "Greater Colorado Springs Area",
  "Westchester, Fort Wayne, Indiana, United States",
  "Petroleum city, Qazvin, Qazvin Province, Iran",
  "Quitman, Georgia, United States",
  "Orange Park, Florida, United States",
  "7308, Pogradec, Korçë, Albania",
  "Duvall-Edison, Dearborn, Michigan, United States",
  "694201, Poronaysk, Sakhalin, Russia",
  "Wadena County, Minnesota, United States",
  "Seneca, South Carolina, United States",
  "Kanawha County, West Virginia, United States",
  "Manchester, Michigan, United States",
  "Sainte-Rose, Basse-Terre, Guadeloupe",
  "Köçvəlli, Qazakh-Tovuz, Azerbaijan",
  "Nashville metropolitan area, Tennessee, United States",
  "Nikolayevskaya, Krasnodar, Russia",
  "Ayanfuri, Central Region, Ghana",
  "Korčula, Dubrovnik-Neretva, Croatia",
  "972 51, Luleå, Norrbotten County, Sweden",
  "Perkinskiy, Tambov, Russia",
  "Yeletsky District, Lipetsk, Russia",
  "Hickman, California, United States",
  "VLT 1446, Valletta, Valletta, Malta",
  "V0C 1B0, Arras, British Columbia, Canada",
  "Manchester, Georgia, United States",
  "LV-1063, Riga, Riga, Latvia",
  "Fremont County, Iowa, United States",
  "9009, Lushnjë, Fier, Albania",
  "Throckmorton, Texas, United States",
  "33210, Gijón, Principality of Asturias, Spain",
  "Klis, Split-Dalmatia, Croatia",
  "Pickens County, Alabama, United States",
  "Willacy County, Texas, United States",
  "186421, Segezha, Karelia, Russia",
  "Yurgamyshsky District, Kurgan, Russia",
  "Balakovo, Saratov, Russia",
  "United Arab Emirates",
  "Yatesbury, England, United Kingdom",
  "Pacific, Missouri, United States",
  "Washburn, Arkansas, United States",
  "686410, Omsukchan, Magadan, Russia",
  "Saint Andrew, Jamaica",
  "400219, Cluj-Napoca, Cluj, Romania",
  "Musabeyli, Kilis, Türkiye",
  "Patrick, Isle of Man",
  "676456, Svobodnyy, Amur, Russia",
  "Licking Creek, Pennsylvania, United States",
  "Wyandotte Woods, Dublin, Ohio, United States",
  "Carbonita, Minas Gerais, Brazil",
  "Les Côtes-d'Arey, Auvergne-Rhône-Alpes, France",
  "Adapazarı Bucağı, Sakarya, Türkiye",
  "Sullivan, New Hampshire, United States",
  "413729, Pugachëv, Saratov, Russia",
  "Kingsbury Episcopi, England, United Kingdom",
  "Alekseyevka, Altai Krai, Russia",
  "Okha, Sakhalin, Russia",
  "Williamsburg, Pennsylvania, United States",
  "249035, Obninsk, Kaluga, Russia",
  "Cotton, Minnesota, United States",
  "Uspen District, Pavlodar Region, Kazakhstan",
  "Henryville, Pennsylvania, United States",
  "Salem, Indiana, United States",
  "West Burleigh, Queensland, Australia",
  "Baku City, Baku Ekonomic Zone, Azerbaijan",
  "Turners Station, Kentucky, United States",
  "Glarus, Switzerland",
  "352120, Tikhoretsk, Krasnodar, Russia",
  "48014, Bilbao, Basque Country, Spain",
  "Krasnoufimskiy, Sverdlovsk, Russia",
  "707020, Aroneanu, Iaşi, Romania",
  "Greater Genoa Metropolitan Area",
  "26200, Eskişehir, Eskişehir, Türkiye",
  "43123, Parma, Emilia-Romagna, Italy",
  "1421, Sofia, Sofia City, Bulgaria",
  "352690, Apsheronsk, Krasnodar, Russia",
  "Krasnoural’skiy, Orenburg, Russia",
  "74059, Perkins, Oklahoma, United States",
  "Saulxures-lès-Nancy, Grand Est, France",
  "70173, Stuttgart, Baden-Württemberg, Germany",
  "6314, Unterägeri, Zug, Switzerland",
  "De Ronde Venen, Utrecht, Netherlands",
  "Kotovsky District, Volgograd, Russia",
  "79059, Lviv, Lviv, Ukraine",
  "Bakum, Lower Saxony, Germany",
  "Efeler, Aydın, Türkiye",
  "IP33 3YU, Bury St Edmunds, England, United Kingdom",
  "Wolford, Merrickville-Wolford, Ontario, Canada",
  "Hays County, Texas, United States",
  "Kaneshie, Greater Accra Region, Ghana",
  "455017, Magnitogorsk, Chelyabinsk, Russia",
  "26006, Logroño, Autonomía de La Rioja, Spain",
  "Karlskoga kommun, Örebro County, Sweden",
  "22404, Fredericksburg, Virginia, United States",
  "Lewis and Clark County, Montana, United States",
  "MD-3901, Cahul, Cahul District, Moldova",
  "Greene County, Arkansas, United States",
  "Bryant, Alabama, United States",
  "Shelbyville, Kentucky, United States",
  "59112, Billings, Montana, United States",
  "Craigmore, South Australia, Australia",
  "4030, Debrecen, Hajdú-Bihar, Hungary",
  "Chelana, Satlasana, Gujarat, India",
  "Galaxia Cancún, Quintana Roo, Mexico",
  "165309, Kotlas, Arkhangel’sk, Russia",
  "Uniondale, New York, United States",
  "Blaine, Kentucky, United States",
  "692522, Ussuriysk, Primorye, Russia",
  "Platte County, Missouri, United States",
  "99506, Joint Base Elmendorf Richardson, Alaska, United States",
  "45300, Manisa, Manisa, Türkiye",
  "7014, Trondheim, Trøndelag, Norway",
  "Obshtina Loznitsa, Razgrad, Bulgaria",
  "37919, Knoxville, Tennessee, United States",
  "Gallias, Sainte-Colombe-en-Bruilhois, Nouvelle-Aquitaine, France",
  "Sumska Miska Hromada, Sumy, Ukraine",
  "414057, Astrakhan, Astrakhan’, Russia",
  "225716, Pinsk, Brest, Belarus",
  "East Nassau, New York, United States",
  "Chesapeake City, Maryland, United States",
  "Vagharshapat region, Armavir Province, Armenia",
  "50006, Kryvyi Rih, Dnipropetrovsk, Ukraine",
  "603 50, Norrköping, Östergötland County, Sweden",
  "Lowndesville, South Carolina, United States",
  "33142, Miami, Florida, United States",
  "Bennett, Wisconsin, United States",
  "Gdynia, Pomorskie, Poland",
  "76528, Gatesville, Texas, United States",
  "Thézan-lès-Béziers, Occitanie, France",
  "Surry County, Virginia, United States",
  "Tver’, Russia",
  "BT79 7GL, Omagh, Northern Ireland, United Kingdom",
  "Culpeper County, Virginia, United States",
  "Dover-Foxcroft, Maine, United States",
  "Campo Grande, Mato Grosso do Sul, Brazil",
  "52777, Wheatland, Iowa, United States",
  "Gages Lake, Illinois, United States",
  "26090, Eskişehir, Eskişehir, Türkiye",
  "Ripley County, Indiana, United States",
  "Yakutsk, Sakha, Russia",
  "Tipton, Oklahoma, United States",
  "57017, Livorno, Tuscany, Italy",
  "Manche, Normandy, France",
  "Meiserspring, Trippstadt, Rhineland-Palatinate, Germany",
  "Wilensteinermühle, Trippstadt, Rhineland-Palatinate, Germany",
  "39114, Magdeburg, Saxony-Anhalt, Germany",
  "Carson City City County, Nevada, United States",
  "76137, Fort Worth, Texas, United States",
  "Montgomery County, Illinois, United States",
  "R7N 2T5, Dauphin, Manitoba, Canada",
  "V92 N7C0, Tralee, County Kerry, Ireland",
  "Carsamba, Samsun, Türkiye",
  "Manily, Koryak, Russia",
  "130049, Târgovişte, Dîmboviţa, Romania",
  "Cafelândia, Paraná, Brazil",
  "Caldwell, West Virginia, United States",
  "Davidson's Mains, Edinburgh, Scotland, United Kingdom",
  "Perth, New Brunswick, Canada",
  "7691, Pécs, Baranya, Hungary",
  "69001, Lyon, Auvergne-Rhône-Alpes, France",
  "Frankfurt an der Oder, Brandenburg, Germany",
  "Gävle kommun, Gävleborg County, Sweden",
  "Northumberland, Pennsylvania, United States",
  "404101, Volzhskiy, Volgograd, Russia",
  "Dickenson County, Virginia, United States",
  "Batesburg, South Carolina, United States",
  "Kemerli, Qazakh-Tovuz, Azerbaijan",
  "1340084, Urayasu, Chiba, Japan",
  "Korfi, Limassol, Cyprus",
  "Okhotskiy Perevoz, Sakha, Russia",
  "656049, Barnaul, Altai Krai, Russia",
  "46266, Kahramanmaraş, Kahraman Maras, Türkiye",
  "Bialystok Metropolitan Area",
  "10138, Turin, Piedmont, Italy",
  "Utrecht, Utrecht, Netherlands",
  "PH33 6BF, Fort William, Scotland, United Kingdom",
  "Otero, Siero, Principality of Asturias, Spain",
  "Novogrudokskiy gorodskoy sovet, Hrodna, Belarus",
  "Le Malheur, Grézet-Cavagnan, Nouvelle-Aquitaine, France",
  "353912, Novorossiysk, Krasnodar, Russia",
  "01213, Vilnius, Vilniaus, Lithuania",
  "Onondaga County, New York, United States",
  "Modena, New York, United States",
  "Nazyvayevsk, Omsk, Russia",
  "Newcastle Upon Tyne, England, United Kingdom",
  "Decatur, Arkansas, United States",
  "Heidelberg, Victoria, Australia",
  "Kemerovo, Kemerovo, Russia",
  "96, Hammerfest, Troms og Finnmark, Norway",
  "Usinskoye, Tatarstan, Russia",
  "94044, Pacifica, California, United States",
  "Sangardo, Faranah Region, Guinea",
  "455044, Magnitogorsk, Chelyabinsk, Russia",
  "Hillsborough, New Jersey, United States",
  "Vila Franca de Xira, Lisbon, Portugal",
  "Avery Bay, Wood Buffalo, Alberta, Canada",
  "620144, Focşani, Vrancea, Romania",
  "320225, Reşiţa, Caraş-Severin, Romania",
  "Stutsman County, North Dakota, United States",
  "Tuzla, Constanţa, Romania",
  "Treasure Beach, Durban, KwaZulu-Natal, South Africa",
  "Brooksville, Mississippi, United States",
  "Orsk, Orenburg, Russia",
  "13250, Hämeenlinna, Kanta-Häme, Finland",
  "32340, Medzhybizh, Khmelnytsky, Ukraine",
  "Grundy Center, Iowa, United States",
  "05702, Rutland, Vermont, United States",
  "Bryn Mawr on the Lake, Umatilla, Florida, United States",
  "Walsham-Le-Willows, United Kingdom",
  "Bolivar, Ohio, United States",
  "173021, Velikiy Novgorod, Novgorod, Russia",
  "Traverse, Saint-Clément, Auvergne-Rhône-Alpes, France",
  "542 43, Mariestad, Västra Götaland County, Sweden",
  "Shannonville, Ontario, Canada",
  "Poznan Metropolitan Area",
  "Little Hocking, Ohio, United States",
  "South Holland, Netherlands",
  "621 49, Visby, Gotland County, Sweden",
  "St Giles On The Heath, England, United Kingdom",
  "Pender County, North Carolina, United States",
  "33100, Tampere, Pirkanmaa, Finland",
  "Khiloksky District, Zabaykalsky Krai, Russia",
  "Montemor-O-Novo, Évora, Portugal",
  "Saint-Claude, Basse-Terre, Guadeloupe",
  "65205, Wiesbaden, Hesse, Germany",
  "Metropolitan City of Reggio Calabria, Calabria, Italy",
  "54653, Rockland, Wisconsin, United States",
  "47537, Gentryville, Indiana, United States",
  "Genesee Rapids, Chisholm, Ontario, Canada",
  "35030, İzmir, İzmir, Türkiye",
  "Bollingerkamp, Saterland, Lower Saxony, Germany",
  "6070, Darlington, Western Australia, Australia",
  "Mercer, North Dakota, United States",
  "CF44 9QB, Penderyn, Wales, United Kingdom",
  "Gibson County, Tennessee, United States",
  "Prizren Municipality, Kosovo",
  "63735, Valle de Banderas, Nayarit, Mexico",
  "654031, Novokuznetsk, Kemerovo, Russia",
  "347386, Volgodonsk, Rostov, Russia",
  "624485, Severoural’sk, Sverdlovsk, Russia",
  "Doddridge, East Hants, Nova Scotia, Canada",
  "550018, Sibiu, Sibiu, Romania",
  "80145, Naples, Campania, Italy",
  "1202, Tetovo, Polog, North Macedonia",
  "Fier, Fier, Albania",
  "Burke, New York, United States",
  "Pulaski, Illinois, United States",
  "35967, Fort Payne, Alabama, United States",
  "7805, Namsos, Trøndelag, Norway",
  "Highland Park, Illinois, United States",
  "Nance County, Nebraska, United States",
  "243111, Klintsy, Bryansk, Russia",
  "Mossoró, Rio Grande do Norte, Brazil",
  "460050, Orenburg, Orenburg, Russia",
  "Lomas de Sargentillo Canton, Guayas, Ecuador",
  "300500, Timişoara, Timiş, Romania",
  "7800, Namsos, Trøndelag, Norway",
  "Kearneysville, West Virginia, United States",
  "353922, Novorossiysk, Krasnodar, Russia",
  "363758, Mozdok, Severnaya Osetiya-Alaniya, Russia",
  "47013, Valladolid, Castilla and Leon, Spain",
  "Rockwall, Texas, United States",
  "440060, Penza, Penza, Russia",
  "141502, Solnechnogorsk, Moscow, Russia",
  "Sussex East, New Brunswick, Canada",
  "5400-708, Chaves, Vila Real, Portugal",
  "18437, Stralsund, Mecklenburg-West Pomerania, Germany",
  "Portland, Oregon, United States",
  "Loznitsa Municipality, Razgrad, Bulgaria",
  "Voznesensky District, Nizhniy Novgorod, Russia",
  "Robertsganj, Uttar Pradesh, India",
  "188307, Gatchina, Leningrad, Russia",
  "Windsor, Pennsylvania, United States",
  "TR18 2PW, Penzance, England, United Kingdom",
  "Newton Abbot, England, United Kingdom",
  "602265, Murom, Vladimir, Russia",
  "5231 BZ, ’s-Hertogenbosch, North Brabant, Netherlands",
  "2430-102, Marinha Grande, Leiria, Portugal",
  "164515, Severodvinsk, Arkhangel’sk, Russia",
  "184701, Umba, Murmansk, Russia",
  "Hickman Mills South, Kansas City, Missouri, United States",
  "K0G 1M0, McDonalds Corners, Ontario, Canada",
  "Kleberg County, Texas, United States",
  "44425, Hubbard, Ohio, United States",
  "Hamlin, New York, United States",
  "Charleston metropolitan area, West Virginia, United States",
  "9404, Harstad, Troms og Finnmark, Norway",
  "69006, Lyon, Auvergne-Rhône-Alpes, France",
  "Payne, San Jose, California, United States",
  "Berat, Albania",
  "32700, Isparta, Isparta, Türkiye",
  "38655, Oxford, Mississippi, United States",
  "Thayngen, Schaffhausen, Switzerland",
  "446142, Chapayevskiy, Samara, Russia",
  "Monroe, Connecticut, United States",
  "Steele, Kentucky, United States",
  "23063, Goochland, Virginia, United States",
  "450018, Zalău, Sălaj, Romania",
  "71520, Kırıkkale, Kırıkkale, Türkiye",
  "96465, Neustadt bei Coburg, Bavaria, Germany",
  "Russia",
  "355040, Stavropol, Stavropol, Russia",
  "37131, Verona, Veneto, Italy",
  "Ashe, England, United Kingdom",
  "LE12 6HY, East Leake, England, United Kingdom",
  "1030, Kamez, Tirana, Albania",
  "450006, Zalău, Sălaj, Romania",
  "Bitlis, Türkiye",
  "Blounts Creek, North Carolina, United States",
  "Barry, Texas, United States",
  "7960, Rørvik, Trøndelag, Norway",
  "Petersburg, Michigan, United States",
  "Pirili, Qazakh-Tovuz, Azerbaijan",
  "Michurinskoye, Khabarovsk, Russia",
  "Ljusdals kommun, Gävleborg County, Sweden",
  "692509, Ussuriysk, Primorye, Russia",
  "V94 T2YA, Limerick, County Limerick, Ireland",
  "Pokrovsk-Ural’skiy, Sverdlovsk, Russia",
  "Louisa County, Iowa, United States",
  "Stokesdale, North Carolina, United States",
  "Lancaster, Pennsylvania, United States",
  "385060, Maykop, Adygea, Russia",
  "East Juniata, Altoona, Pennsylvania, United States",
  "L1 1NH, Liverpool, England, United Kingdom",
  "Terry, Saint-Aubin, Nouvelle-Aquitaine, France",
  "Gerze Bucağı, Sinop, Türkiye",
  "Dover, Minnesota, United States",
  "San Sebastián de la Gomera, Canary Islands, Spain",
  "Rockbridge County, Virginia, United States",
  "Pass Christian, Mississippi, United States",
  "Serebryanskoye, Leningrad, Russia",
  "Christian County, Kentucky, United States",
  "66117, Saarbrücken, Saarland, Germany",
  "678152, Vitim, Sakha, Russia",
  "100087, Ploieşti, Prahova, Romania",
  "Nebraska, United States",
  "44300, Malatya, Malatya, Türkiye",
  "35209, Birmingham, Alabama, United States",
  "Millen, Georgia, United States",
  "Santa Monica, California, United States",
  "BH8 9EW, Bournemouth, England, United Kingdom",
  "1030, Vienna, Vienna, Austria",
  "70452, Pearl River, Louisiana, United States",
  "Cherokee County, North Carolina, United States",
  "Benjamin Benson, Gilmer, North Carolina, United States",
  "Greenock, South Australia, Australia",
  "347805, Kamensk-Shakhtinskiy, Rostov, Russia",
  "Sargents, Colorado, United States",
  "88113, Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Kyustendil, Kyustendil, Bulgaria",
  "20200, Turku, Southwest Finland, Finland",
  "715200, Broscăuţi, Botoşani, Romania",
  "1043, Tirana, Tirana, Albania",
  "Sheffield, Illinois, United States",
  "Charlestown, Indiana, United States",
  "394068, Voronezh, Voronezh, Russia",
  "446100, Chapayevsk, Samara, Russia",
  "Greater Lincoln Area, United Kingdom",
  "Aurora, Cagayan Valley, Philippines",
  "Lipetsk Metropolitan Area",
  "Gentry Estates, Bloomington, Indiana, United States",
  "Cape Dauphin, Nova Scotia, Canada",
  "28915-380, Cabo Frio, Rio de Janeiro, Brazil",
  "Lynchburg, South Carolina, United States",
  "Qaskeleñ, Almaty Region, Kazakhstan",
  "Warren County, Ohio, United States",
  "Lincoln, England, United Kingdom",
  "25600, Erzurum, Erzurum, Türkiye",
  "654011, Novokuznetsk, Kemerovo, Russia",
  "Mersin, İçel, Türkiye",
  "Plaza Central de Comanche, Comanche, La Paz, Bolivia",
  "19645-000, Nantes, São Paulo, Brazil",
  "Wright, Florida, United States",
  "Hickory County, Missouri, United States",
  "CV7 7BN, Berkswell, England, United Kingdom",
  "57570, Rosebud, South Dakota, United States",
  "Derecik, Hakkâri, Türkiye",
  "172521, Nelidovo, Tver’, Russia",
  "48009, Bilbao, Basque Country, Spain",
  "346524, Shakhty, Rostov, Russia",
  "90420, Haapsalu, Laane, Estonia",
  "Cherryville, North Carolina, United States",
  "R95 ED25, Kilkenny, County Kilkenny, Ireland",
  "Perry, Ohio, United States",
  "652351, Topki, Kemerovo, Russia",
  "69104, Zaporizhzhya, Zaporizhzhya, Ukraine",
  "Belmont, New York, United States",
  "4000, Debrecen, Hajdú-Bihar, Hungary",
  "Albany, Western Australia, Australia",
  "Fredericksburg, Indiana, United States",
  "Leesburg, Florida, United States",
  "Poprad, Presov, Slovakia",
  "Coaldale, Alberta, Canada",
  "MD-4571, Cocieri, Dubăsari District, Moldova",
  "76620, Le Havre, Normandy, France",
  "97130, Capesterre-Belle-Eau, Basse-Terre, Guadeloupe",
  "Wallasey, England, United Kingdom",
  "10405, Berlin, Berlin, Germany",
  "Belgorod, Belgorod, Russia",
  "Clarke City, Quebec, Canada",
  "73027, Minervino di Lecce, Apulia, Italy",
  "Florence, Italy Metropolitan Area",
  "142116, Podolsk, Moscow, Russia",
  "Leavenworth County, Kansas, United States",
  "11314, Tallinn, Harjumaa, Estonia",
  "Columbus metropolitan area, Ohio, United States",
  "IP33 1AE, Bury St Edmunds, England, United Kingdom",
  "Grevenmacher, Grevenmacher, Luxembourg",
  "644073, Omsk, Omsk, Russia",
  "252 77, Helsingborg, Skåne County, Sweden",
  "71204, Zimatlán de Álvarez, Oaxaca, Mexico",
  "GY1 1EB, Saint Peter Port, Guernsey, Guernsey",
  "Slobozia, Argeş, Romania",
  "Mezőtúr, Jász-Nagykun-Szolnok, Hungary",
  "Salamanca, Guanajuato, Mexico",
  "Franklin, Tennessee, United States",
  "98400, Kemijärvi, Lapland, Finland",
  "09000, Aydın, Aydın, Türkiye",
  "Greenwood, Nebraska, United States",
  "Catawba Springs, North Carolina, United States",
  "629002, Salekhard, Yamalo-Nenets, Russia",
  "172381, Rzhev, Tver’, Russia",
  "Stafford County, Kansas, United States",
  "30448, Swainsboro, Georgia, United States",
  "Bathnaha, Bihar, India",
  "Marathónas, Attiki, Greece",
  "Haisyn, Vinnytsya, Ukraine",
  "Bregenz, Vorarlberg, Austria",
  "420100, Kazan, Tatarstan, Russia",
  "Silves, Faro, Portugal",
  "1010, Tirana, Tirana, Albania",
  "Narva-Jõesuu, Ida-Virumaa, Estonia",
  "23801, Fort Gregg Adams, Virginia, United States",
  "Charlotte Metro",
  "2987 TR, Ridderkerk, South Holland, Netherlands",
  "Perkins County, Nebraska, United States",
  "Brulet, Pujols, Nouvelle-Aquitaine, France",
  "Tioga, Texas, United States",
  "4053, Basel, Basel, Switzerland",
  "Lymanska silska hromada, Odessa, Ukraine",
  "236038, Kaliningrad, Kaliningrad, Russia",
  "Archer City, Texas, United States",
  "75978, Nacogdoches, Texas, United States",
  "06200, Porvoo, Uusimaa, Finland",
  "4812, Arendal, Agder, Norway",
  "Visby, Region of Southern Denmark, Denmark",
  "Bryansky District, Bryansk, Russia",
  "Blaine, Washington, United States",
  "18349, Swiftwater, Pennsylvania, United States",
  "Mifflintown, Pennsylvania, United States",
  "641446, Kurtamysh, Kurgan, Russia",
  "Hamlin, Iowa, United States",
  "56514, Barnesville, Minnesota, United States",
  "R6W 4B3, Winkler, Manitoba, Canada",
  "9053, Teufen, Appenzell Outer-Rhoden, Switzerland",
  "Turano Lodigiano, Lombardy, Italy",
  "Leicester, North Carolina, United States",
  "Washburn, La Crosse, Wisconsin, United States",
  "04109, Leipzig, Saxony, Germany",
  "190 07, Marathónas, Attiki, Greece",
  "Saint John, New Brunswick, Canada",
  "Bonnyrigg, New South Wales, Australia",
  "Yunusemre, Manisa, Türkiye",
  "12325, Schenectady, New York, United States",
  "Fairfield, Illinois, United States",
  "67790, Linares, Nuevo León, Mexico",
  "89406, Fallon, Nevada, United States",
  "71-050, Szczecin, Zachodniopomorskie, Poland",
  "Johnston, Wales, United Kingdom",
  "Kingston, Ontario, Canada",
  "Alexandroúpolis, Eastern Macedonia and Thrace, Greece",
  "33100, Bordeaux, Nouvelle-Aquitaine, France",
  "Burkeville, Texas, United States",
  "442537, Kuznetsk, Penza, Russia",
  "Bontang, East Kalimantan, Indonesia",
  "Deer Valley, Phoenix, Arizona, United States",
  "Palo Cedro, California, United States",
  "13699, Potsdam, New York, United States",
  "Kirovskoye, Rostov, Russia",
  "613151, Slobodskoy, Kirov, Russia",
  "Aberdeenshire, Scotland, United Kingdom",
  "Václavovice u Frýdku, Moravia-Silesia, Czechia",
  "Callaway, Missouri, United States",
  "44250, Guadalajara, Jalisco, Mexico",
  "Shpakovskoye, Stavropol, Russia",
  "Lublin Metropolitan Area",
  "06520, Ankara, Ankara, Türkiye",
  "Wheeler County, Texas, United States",
  "Moher (Gregg), County Leitrim, Ireland",
  "6821 BT, Arnhem, Gelderland, Netherlands",
  "98903, Yakima, Washington, United States",
  "Putnam County, Ohio, United States",
  "Jones County, Georgia, United States",
  "14473, Potsdam, Brandenburg, Germany",
  "Morristown, Tennessee, United States",
  "Saplyk Tatarskiy, Tatarstan, Russia",
  "5032, Aarau, Aargau, Switzerland",
  "06238, Coventry, Connecticut, United States",
  "810379, Brăila, Brăila, Romania",
  "Walthall County, Mississippi, United States",
  "Cook, Nebraska, United States",
  "Blaine, Muncie, Indiana, United States",
  "Kholmskaya, Krasnodar, Russia",
  "Norwich, England, United Kingdom",
  "Gloucester, North Carolina, United States",
  "14015, San Damiano d’Asti, Piedmont, Italy",
  "Otsego, Minnesota, United States",
  "Severobaykal’sk, Buryatia, Russia",
  "Blagoveshchenka, Kabardino-Balkaria, Russia",
  "95380-000, Esmeralda, Rio Grande do Sul, Brazil",
  "F63B4D4, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "623102, Pervoural'sk, Sverdlovsk, Russia",
  "690091, Vladivostok, Primorye, Russia",
  "Angarskiy, Krasnoyarsk Krai, Russia",
  "Baykan, Siirt, Türkiye",
  "Taylor County, Florida, United States",
  "42746, Hardyville, Kentucky, United States",
  "Darlington, South Carolina, United States",
  "77598, Webster, Texas, United States",
  "X15G4T4, Shymkent, Shymkent, Kazakhstan",
  "Esil District, North Kazakhstan Region, Kazakhstan",
  "Sarny, Rivne, Ukraine",
  "Gaston, Oregon, United States",
  "Fredericksburg, New Brunswick, Canada",
  "Nowy Bytom, Śląskie, Poland",
  "Santa Maria da Feira, Aveiro, Portugal",
  "Zaozërnyy, Tyumen’, Russia",
  "Barcelona, Catalonia, Spain",
  "26838, Milam, West Virginia, United States",
  "Asher, Kentucky, United States",
  "Curry, Indiana, United States",
  "6917, Lugano, Ticino, Switzerland",
  "7350-478, Elvas, Portalegre, Portugal",
  "25031, Kropyvnytskyi, Kirovohrad, Ukraine",
  "8302, Peshkopi, Dibrë, Albania",
  "301680, Novomoskovsk, Tula, Russia",
  "352913, Armavir, Krasnodar, Russia",
  "04003, Almería, Andalusia, Spain",
  "8755, Glarus, Glarus, Switzerland",
  "Sully, Nanton, Bourgogne-Franche-Comté, France",
  "Grand Traverse County, Michigan, United States",
  "Fort Worth, Texas, United States",
  "Ferryhill, England, United Kingdom",
  "Bayfield, Eastern Kings, Prince Edward Island, Canada",
  "Blue Jay, California, United States",
  "Ancona, Marches, Italy",
  "2775, Wisemans Ferry, New South Wales, Australia",
  "India",
  "Cahul District, Moldova",
  "15240, Lahti, Päijät-Häme, Finland",
  "Persondy, Newport, Wales, United Kingdom",
  "Kings, New Brunswick, Canada",
  "Walshind, Bhiwandi, Maharashtra, India",
  "Greenville County, South Carolina, United States",
  "47009, Kaunas, Kauno, Lithuania",
  "03048, Cottbus, Brandenburg, Germany",
  "Whitehall, Pennsylvania, United States",
  "E9C 2C2, New Bandon Northumberland County, New Brunswick, Canada",
  "Sligo, Pennsylvania, United States",
  "IP31 3PP, Thurston, England, United Kingdom",
  "Coosaun, County Galway, Ireland",
  "Ardeşen, Rize, Türkiye",
  "San Francisco Bay Area",
  "Brunswick, Ohio, United States",
  "Union County, South Dakota, United States",
  "Halle, Flemish Region, Belgium",
  "Shushan District, Anhui, China",
  "Lewiston, Maine, United States",
  "65610, Billings, Missouri, United States",
  "PE29 1AA, Huntingdon, England, United Kingdom",
  "Drogheda, Ireland",
  "Chur’yakovo, Yaroslavl’, Russia",
  "Freiburg, Baden-Württemberg, Germany",
  "Mount Edgecombe Estate 1, Durban, KwaZulu-Natal, South Africa",
  "Belogorsk, Amur, Russia",
  "88120, Grady, New Mexico, United States",
  "41600, Konotop, Sumy, Ukraine",
  "Tomago, New South Wales, Australia",
  "Bronx, New York, United States",
  "Clay County, Illinois, United States",
  "94709, Berkeley, California, United States",
  "455026, Magnitogorsk, Chelyabinsk, Russia",
  "41017, Fort Mitchell, Kentucky, United States",
  "42803, Tula, Hidalgo, Mexico",
  "Moody, Alabama, United States",
  "45146, Martinsville, Ohio, United States",
  "Shalë, Shkodër, Albania",
  "38293, Santa Cruz de Tenerife, Canary Islands, Spain",
  "353142, Tikhoretsk, Krasnodar, Russia",
  "Kingsbury, England, United Kingdom",
  "70658, Reeves, Louisiana, United States",
  "Suffolk, Prince Edward Island, Canada",
  "Coos County, Oregon, United States",
  "5615 HH, Eindhoven, North Brabant, Netherlands",
  "Lida, Sud-Ubangi, Democratic Republic of the Congo",
  "40-006, Katowice, Śląskie, Poland",
  "87000, Limoges, Nouvelle-Aquitaine, France",
  "PE8 5PH, Barnwell, England, United Kingdom",
  "Ocean Springs, Mississippi, United States",
  "Kitsman', Chernivtsi, Ukraine",
  "Allendale Charter Township, Michigan, United States",
  "13430, Hämeenlinna, Kanta-Häme, Finland",
  "Ocean City, Maryland, United States",
  "18214, Barnesville, Pennsylvania, United States",
  "Agenebode, Edo State, Nigeria",
  "80100, Naples, Campania, Italy",
  "6963, Lugano, Ticino, Switzerland",
  "446020, Syzran, Samara, Russia",
  "Novokuybyshevsk, Samara, Russia",
  "Los Lunas, New Mexico, United States",
  "Michurinsk, Tambov, Russia",
  "Union County, Georgia, United States",
  "Annelinn, Tartumaa, Estonia",
  "Gay, West Virginia, United States",
  "Bolivar State, Venezuela",
  "Page, Arizona, United States",
  "Palo del Colle, Apulia, Italy",
  "Puerto Francisco de Orellana, Orellana, Ecuador",
  "Barangay 10-B (Kingfisher-B), Cavite City, Calabarzon, Philippines",
  "28608, Boone, North Carolina, United States",
  "Prince Edward Island, Canada",
  "301651, Novomoskovsk, Tula, Russia",
  "Sierra Grande, Río Negro Province, Argentina",
  "Vorkuta, Komi, Russia",
  "Karşıyaka, Adana, Türkiye",
  "173022, Velikiy Novgorod, Novgorod, Russia",
  "DG2 0BQ, Dumfries, Scotland, United Kingdom",
  "Terry Acres, Bethel Heights, Arkansas, United States",
  "Torres Novas, Santarém, Portugal",
  "Wise County, Virginia, United States",
  "Saint-Georges-du-Vièvre, Normandy, France",
  "163020, Archangel, Arkhangel’sk, Russia",
  "Alchevska miska hromada, Luhansk, Ukraine",
  "89407, Fallon, Nevada, United States",
  "Puebla, Puebla, Mexico",
  "Klyuchi, Penza, Russia",
  "SA1 6FU, Swansea, Wales, United Kingdom",
  "Sully le Haut, Nanton, Bourgogne-Franche-Comté, France",
  "220016, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "San Marino",
  "Lamoure, Dognen, Nouvelle-Aquitaine, France",
  "2344, Pernik, Pernik, Bulgaria",
  "Lakshadweep, India",
  "Danville City County, Virginia, United States",
  "EC4N 1SA, London, England, United Kingdom",
  "Pleasantside, British Columbia, Canada",
  "13630, DeKalb Junction, New York, United States",
  "Bennett County, South Dakota, United States",
  "13500, Bitlis, Bitlis, Türkiye",
  "Zharkent, Jetisu Region, Kazakhstan",
  "12005, Castellón de la Plana, Valencian Community, Spain",
  "Greater Passau Area",
  "185504, Shuya, Karelia, Russia",
  "Vouzela, Viseu, Portugal",
  "903 31, Umeå, Västerbotten County, Sweden",
  "3516, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Lawrenceville, Georgia, United States",
  "01351, Montague, Massachusetts, United States",
  "Jelgava, Jelgava Municipality, Latvia",
  "Kosi division, India",
  "New Juaben South Municipal District, Eastern Region, Ghana",
  "Babinichskiy sel'skiy sovet, Vitsyebsk, Belarus",
  "07008, Carteret, New Jersey, United States",
  "Nogliki, Sakhalin, Russia",
  "Shepetivka Raion, Khmelnytsky, Ukraine",
  "Arras-sur-Rhône, Auvergne-Rhône-Alpes, France",
  "2218, Carlton, New South Wales, Australia",
  "671560, Taksimo, Buryatia, Russia",
  "22401, Fredericksburg, Virginia, United States",
  "Voznesenskiy, Bashkortostan, Russia",
  "ZE1 0LZ, Lerwick, Scotland, United Kingdom",
  "33200, Bordeaux, Nouvelle-Aquitaine, France",
  "Dawson, Texas, United States",
  "Walla Walla Area",
  "624579, Ivdel’, Sverdlovsk, Russia",
  "Strasbourg Station, Saskatchewan, Canada",
  "Juniata Iron Works, Porter, Pennsylvania, United States",
  "Republic of the Congo",
  "171980, Bezhetsk, Tver’, Russia",
  "Maury, Thégra, Occitanie, France",
  "Nyenyen, Bong County, Liberia",
  "79707, Midland, Texas, United States",
  "57127, Livorno, Tuscany, Italy",
  "KA18 2PB, Ochiltree, Scotland, United Kingdom",
  "Huntingdon, Canterbury, New Zealand",
  "Belgrade City, Centralna Srbija, Serbia",
  "Kamenka, Voronezh, Russia",
  "Bradford, Pennsylvania, United States",
  "Montgomery Village, Maryland, United States",
  "Santa Marta de Penaguião, Vila Real, Portugal",
  "Westchester, Lubbock, Texas, United States",
  "Asinovsky District, Tomsk, Russia",
  "63890, Saint-Éloy-la-Glacière, Auvergne-Rhône-Alpes, France",
  "South Lanarkshire, Scotland, United Kingdom",
  "S25B1D0, Ertis, Pavlodar Region, Kazakhstan",
  "Vinton, Virginia, United States",
  "Cordoba, Córdoba, Argentina",
  "450025, Zalău, Sălaj, Romania",
  "Williams County, Ohio, United States",
  "Bergen, Lower Saxony, Germany",
  "33593, Dade City, Florida, United States",
  "353761, Umanskiy, Rostov, Russia",
  "Beaver Bank, Nova Scotia, Canada",
  "456293, Zlatoust, Chelyabinsk, Russia",
  "Kilmallock, County Limerick, Ireland",
  "Çanakkale, Türkiye",
  "Venango, Pennsylvania, United States",
  "Tikhvinskoye, Omsk, Russia",
  "410214, Oradea, Bihor, Romania",
  "Mississippi State, Mississippi, United States",
  "Randolph County, Georgia, United States",
  "141503, Solnechnogorsk, Moscow, Russia",
  "Split-Dalmatia, Croatia",
  "Barryton, Michigan, United States",
  "Adams County, Mississippi, United States",
  "Ritchie Acres, Richfield, North Carolina, United States",
  "Minervino di Lecce, Apulia, Italy",
  "Becker, Río Gallegos, Santa Cruz Province, Argentina",
  "Lexington, Michigan, United States",
  "Doornspijk, Gelderland, Netherlands",
  "47119, Duisburg, North Rhine-Westphalia, Germany",
  "78202, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "Arras, Dibrë, Albania",
  "Jyväskylä sub-region, Central Finland, Finland",
  "624223, Nizhnyaya Tura, Sverdlovsk, Russia",
  "Coblenz, Rhineland-Palatinate, Germany",
  "20010-903, Rio de Janeiro, Rio de Janeiro, Brazil",
  "2306, Hamar, Innlandet, Norway",
  "352706, Timashevsk, Krasnodar, Russia",
  "688863, Manily, Koryak, Russia",
  "Slobodskoy District, Kirov, Russia",
  "Zalaegerszeg, Zala, Hungary",
  "Engels, Saratov, Russia",
  "Nances, Moyrazès, Occitanie, France",
  "Sang",
  "Scott, Quebec, Canada",
  "5300-518, Bragança, Bragança, Portugal",
  "North Ayrshire, Scotland, United Kingdom",
  "Jardim Santa Angelina, Campina Grande do Sul, Paraná, Brazil",
  "305029, Kursk, Kursk, Russia",
  "Hancock, Wisconsin, United States",
  "Cameron County, Pennsylvania, United States",
  "346883, Bataysk, Rostov, Russia",
  "56556, McIntosh, Minnesota, United States",
  "48765, Turner, Michigan, United States",
  "Palma di Montechiaro, Sicily, Italy",
  "Lisburn, Northern Ireland, United Kingdom",
  "Chickasaw Trail Estates, Florida, United States",
  "96744, Kaneohe, Hawaii, United States",
  "53650, Rize, Rize, Türkiye",
  "47250, Duga Resa, Karlovac, Croatia",
  "Moore County, Texas, United States",
  "14480, Potsdam, Brandenburg, Germany",
  "E1 8LX, London, England, United Kingdom",
  "Timiryazevskoye, Ul’yanovsk, Russia",
  "Graham, Missouri, United States",
  "353032, Tikhoretsk, Krasnodar, Russia",
  "Sosnogorsk rayon, Komi, Russia",
  "59000, Tekirdağ, Tekirdağ, Türkiye",
  "Kepez, Antalya, Türkiye",
  "05759, North Clarendon, Vermont, United States",
  "1180, Brussels, Brussels Region, Belgium",
  "York, Pennsylvania, United States",
  "Zurich, Zurich, Switzerland",
  "41010, Seville, Andalusia, Spain",
  "Aalborg, North Denmark Region, Denmark",
  "Mount Mercer, Victoria, Australia",
  "Kirovskyi District, Donetsk, Ukraine",
  "Greater Vitoria Area",
  "8522, Narvik, Nordland, Norway",
  "6703, Szeged, Csongrád, Hungary",
  "371 33, Karlskrona, Blekinge County, Sweden",
  "Beaufort, Auvergne-Rhône-Alpes, France",
  "920093, Slobozia, Ialomiţa, Romania",
  "Dawson, Iowa, United States",
  "9020-014, Funchal, Madeira Island, Portugal",
  "623385, Polevskoy, Sverdlovsk, Russia",
  "8932 AA, Leeuwarden, Friesland, Netherlands",
  "El Refugio, Ahuachapán, El Salvador",
  "Berlin, Pennsylvania, United States",
  "18900, Cankiri, Çankırı, Türkiye",
  "Genesee County, New York, United States",
  "75-500, Koszalin, Zachodniopomorskie, Poland",
  "Middlesex Township, Pennsylvania, United States",
  "Fanningstown, County Kilkenny, Ireland",
  "Union City, California, United States",
  "23109, Mathews, Virginia, United States",
  "Ragusa, Sicily, Italy",
  "Boyacá, Colombia",
  "235, Lübeck, Schleswig-Holstein, Germany",
  "87736, Rainsville, New Mexico, United States",
  "6744, Personico, Ticino, Switzerland",
  "Pembina, North Dakota, United States",
  "Brighton And Hove, England, United Kingdom",
  "Leicester, Massachusetts, United States",
  "3128, Tønsberg, Vestfold og Telemark, Norway",
  "24293, Wise, Virginia, United States",
  "Harmon, Illinois, United States",
  "Toledo, Canelones, Uruguay",
  "371 35, Karlskrona, Blekinge County, Sweden",
  "Armavir region, Armavir Province, Armenia",
  "Greater Cartagena Metropolitan Area",
  "Otsego, New York, United States",
  "Semeyskoye, Voronezh, Russia",
  "Cherry Valley, Illinois, United States",
  "Shirakawa, Fukushima, Japan",
  "23059, Glen Allen, Virginia, United States",
  "Canton of Caen-1, Normandy, France",
  "15899, Santiago de Compostela, Galicia, Spain",
  "Oneşti, Bacău, Romania",
  "48312, Sterling Heights, Michigan, United States",
  "Harrisonburg Downtown Historic District, Virginia, United States",
  "403260, Ust’-Buzulukskaya, Tambov, Russia",
  "238750, Sovetsk, Kaliningrad, Russia",
  "184420, Nikel, Murmansk, Russia",
  "Mana, Saint-Laurent-du-Maroni, French Guiana",
  "Krasnokamensky District, Zabaykalsky Krai, Russia",
  "171071, Bologoye, Tver’, Russia",
  "Kahramanmaraş, Kahraman Maras, Türkiye",
  "Quitman, Mississippi, United States",
  "67073, Lehigh, Kansas, United States",
  "12900-002, Bragança Paulista, São Paulo, Brazil",
  "Cookeville, Tennessee, United States",
  "Sevier County, Utah, United States",
  "Elko, British Columbia, Canada",
  "791 41, Falun, Dalarna County, Sweden",
  "Nevada, United States",
  "243108, Klintsy, Bryansk, Russia",
  "44-112, Gliwice, Śląskie, Poland",
  "02003, Albacete, Castile-La Mancha, Spain",
  "Morgan County, West Virginia, United States",
  "40489, Düsseldorf, North Rhine-Westphalia, Germany",
  "Anderson, Ohio, United States",
  "39563, Moss Point, Mississippi, United States",
  "403874, Kamyshin, Volgograd, Russia",
  "655158, Chernogorsk, Khakassia, Russia",
  "McKinney, Texas, United States",
  "Delhi, India",
  "504 30, Borås, Västra Götaland County, Sweden",
  "15706, Santiago de Compostela, Galicia, Spain",
  "352 51, Växjö, Kronoberg County, Sweden",
  "Greater Northampton Area, United Kingdom",
  "65-056, Zielona Góra, Lubuskie, Poland",
  "40391, Winchester, Kentucky, United States",
  "Shannon, North Carolina, United States",
  "Harbour Isle at Hutchinson Island, Fort Pierce, Florida, United States",
  "Almetyevsky District, Tatarstan, Russia",
  "40000, Çiçekdağı, Kırşehir, Türkiye",
  "Vélez-Málaga, Andalusia, Spain",
  "7001, Korçë, Korçë, Albania",
  "Covington County, Alabama, United States",
  "Mikhaylovka Urban Okrug, Volgograd, Russia",
  "Sorong, West Papua, Indonesia",
  "Cherbourg, Queensland, Australia",
  "39011, Santander, Cantabria, Spain",
  "98047, Pacific, Washington, United States",
  "Marquette, Kansas, United States",
  "Boxtel, North Brabant, Netherlands",
  "29700, Gumushane, Gümüşhane, Türkiye",
  "Monroe, New Jersey, United States",
  "Ferguson, North Carolina, United States",
  "Lebanon, New Hampshire, United States",
  "09126, Cagliari, Sardinia, Italy",
  "Salvaterra de Magos, Santarém, Portugal",
  "LV-1011, Riga, Riga, Latvia",
  "Morrisville, North Carolina, United States",
  "Davies Industrial East, Edmonton, Alberta, Canada",
  "Friolzheim, Baden-Württemberg, Germany",
  "Hancock County, Tennessee, United States",
  "Ola, Magadan, Russia",
  "34015, Istanbul, Istanbul, Türkiye",
  "76036, Crowley, Texas, United States",
  "Allegheny West, Pittsburgh, Pennsylvania, United States",
  "C40A8A0, Esil, Akmola Region, Kazakhstan",
  "Coșnița, Dubăsari District, Moldova",
  "El Paso County, Texas, United States",
  "Hamilton, New South Wales, Australia",
  "Charlotte Amalie East, St Thomas, US Virgin Islands",
  "9916, Kirkenes, Troms og Finnmark, Norway",
  "Cannon Hill, Birmingham, England, United Kingdom",
  "431441, Ruzayevka, Mordovia, Russia",
  "Blagoveshchensky District, Altai Krai, Russia",
  "Radford, Queensland, Australia",
  "Vērēmi Parish, Rēzekne Municipality, Latvia",
  "Dublin, Ohio, United States",
  "DG1 4AP, Dumfries, Scotland, United Kingdom",
  "624610, Alapayevsk, Sverdlovsk, Russia",
  "33100, Mersin, İçel, Türkiye",
  "Sparta, Peloponnese, Greece",
  "Stark, Kansas, United States",
  "Eastland Estate, Barrackpur-I, West Bengal, India",
  "73099, Yukon, Oklahoma, United States",
  "63400, Şanlıurfa, Şanliurfa, Türkiye",
  "Lavaca, Arkansas, United States",
  "Cherepovets, Vologda, Russia",
  "Crownpoint, New Mexico, United States",
  "98000, Guadalupe, Zacatecas, Mexico",
  "Goliad, Texas, United States",
  "Calais, Hauts-de-France, France",
  "169520, Sosnogorsk, Komi, Russia",
  "Kimball, Dallas, Texas, United States",
  "Helsinge, Capital Region of Denmark, Denmark",
  "SN8 1QJ, Beckhampton, England, United Kingdom",
  "Kittitas County, Washington, United States",
  "Stewart County, Georgia, United States",
  "Walker, Iowa, United States",
  "NR11 8RP, East Beckham, England, United Kingdom",
  "652920, Temirtau, Kemerovo, Russia",
  "346937, Novoshakhtinsk, Rostov, Russia",
  "184404, Nikel, Murmansk, Russia",
  "72002, Alexander, Arkansas, United States",
  "Cotton County, Oklahoma, United States",
  "357534, Pyatigorsk, Stavropol, Russia",
  "Walworth Industrial Estate, Andover, England, United Kingdom",
  "Richmond, Kentucky, United States",
  "Buckingham County, Virginia, United States",
  "Catawba, Virginia, United States",
  "Ig, Ljubljana, Slovenia",
  "Aveiro, Portugal",
  "56538, Fergus Falls, Minnesota, United States",
  "99362, Walla Walla, Washington, United States",
  "Beaver, Pennsylvania, United States",
  "42050, Konya, Konya, Türkiye",
  "26122, Oldenburg, Lower Saxony, Germany",
  "186532, Belomorsk, Karelia, Russia",
  "Custer County, Idaho, United States",
  "Vacaria, Rio Grande do Sul, Brazil",
  "11204, Algeciras, Andalusia, Spain",
  "Pokrovske, Dnipropetrovsk, Ukraine",
  "Ward Meade, Topeka, Kansas, United States",
  "3371 TK, Hardinxveld-Giessendam, South Holland, Netherlands",
  "11070, Belgrade, Serbia",
  "186500, Belomorsk, Karelia, Russia",
  "Dickey, North Dakota, United States",
  "34119, Kassel, Hesse, Germany",
  "Summit, Michigan, United States",
  "37917, Knoxville, Tennessee, United States",
  "15210, Lahti, Päijät-Häme, Finland",
  "45010-110, Vitória da Conquista, Bahia, Brazil",
  "75-950, Koszalin, Zachodniopomorskie, Poland",
  "8921 AA, Leeuwarden, Friesland, Netherlands",
  "98058, Novara di Sicilia, Sicily, Italy",
  "Pershing Villas, Florida, United States",
  "24015, Roanoke, Virginia, United States",
  "80639, Greeley, Colorado, United States",
  "53650, Lappeenranta, South Karelia, Finland",
  "Montague, Cape Town, Western Cape, South Africa",
  "Gentry, Arkansas, United States",
  "67410, Kokkola, Central Ostrobothnia, Finland",
  "Greater Albacete Metropolitan Area",
  "353539, Strelka, Krasnodar, Russia",
  "Nevyansk, Sverdlovsk, Russia",
  "Cameron, Wisconsin, United States",
  "Hayes, Bromley, England, United Kingdom",
  "68955, Juniata, Nebraska, United States",
  "Arkalyk, Kostanay Region, Kazakhstan",
  "Tulang Bawang Regency, Lampung, Indonesia",
  "Perth, Ontario, Canada",
  "F39C5E0, Zaysan, East Kazakhstan Region, Kazakhstan",
  "724 80, Västerås, Västmanland County, Sweden",
  "Pleasants County, West Virginia, United States",
  "Cheshire Village, Saint Andrew, Jamaica",
  "DL15 0TR, Crook, England, United Kingdom",
  "64461, Hopkins, Missouri, United States",
  "19608, Reading, Pennsylvania, United States",
  "Greater Monroe Area",
  "Pamlico Plantation, Long Acre, North Carolina, United States",
  "391301, Kasimov, Ryazan’, Russia",
  "352 48, Växjö, Kronoberg County, Sweden",
  "Blaine County, Nebraska, United States",
  "33936, Lehigh Acres, Florida, United States",
  "20900, Turku, Southwest Finland, Finland",
  "Kostroma Metropolitan Area",
  "Les Millards, Cenves, Auvergne-Rhône-Alpes, France",
  "44160, Guadalajara, Jalisco, Mexico",
  "Shelby County, Illinois, United States",
  "Franklin, Michigan, United States",
  "40225, Düsseldorf, North Rhine-Westphalia, Germany",
  "Belmont North, New South Wales, Australia",
  "Kerrick, Minnesota, United States",
  "Scotts Bluff County, Nebraska, United States",
  "3110, Tønsberg, Vestfold og Telemark, Norway",
  "Schuylkill, Philadelphia, Pennsylvania, United States",
  "Weston, Runcorn, England, United Kingdom",
  "PE8 5PS, Barnwell, England, United Kingdom",
  "Leeds and the Thousand Islands, Ontario, Canada",
  "Stadt Coburg, Bavaria, Germany",
  "241050, Bryansk, Bryansk, Russia",
  "Perry County, Arkansas, United States",
  "Qurmanğazı, Atyrau Region, Kazakhstan",
  "Talğar, Almaty Region, Kazakhstan",
  "Lanester, Brittany, France",
  "Dushanovë, Prizren District, Kosovo",
  "Calhoun County, Arkansas, United States",
  "383 34, Vólos, Thessalia, Greece",
  "Ritchie, Edmonton, Alberta, Canada",
  "Radford, Windward Islands, French Polynesia",
  "Douglas County, Nebraska, United States",
  "Fundulea, Călăraşi, Romania",
  "Smiths Station, Alabama, United States",
  "Tishomingo, Mississippi, United States",
  "93057, Regensburg, Bavaria, Germany",
  "Gölbaşı, Bitlis, Türkiye",
  "46580, Warsaw, Indiana, United States",
  "Bath, Indiana, United States",
  "Webber, Kansas, United States",
  "Blue, Missouri, United States",
  "61821, Champaign, Illinois, United States",
  "Golden Valley, Minnesota, United States",
  "54981, Waupaca, Wisconsin, United States",
  "89233, Neu Ulm, Bavaria, Germany",
  "Nyenlund, Region of Southern Denmark, Denmark",
  "28202, Charlotte, North Carolina, United States",
  "Pokrovskoye, Tyumen’, Russia",
  "320061, Reşiţa, Caraş-Severin, Romania",
  "Kurskiy, Stavropol, Russia",
  "94597, Walnut Creek, California, United States",
  "Wickford, England, United Kingdom",
  "Newport, New South Wales, Australia",
  "11000, Bilecik, Bilecik, Türkiye",
  "Zalaegerszegi Járás, Zala, Hungary",
  "23207, Sveti Filip i Jakov, Zadar, Croatia",
  "ejido canada de ricos la alfalfa, Jalisco, Mexico",
  "450090, Zalău, Sălaj, Romania",
  "392558, Sovkhoza Komsomolets poselok, Tambov, Russia",
  "368599, Derbent, Dagestan, Russia",
  "Saint-André-Lez-Lille, Hauts-de-France, France",
  "Bari, Apulia, Italy",
  "Rio Claro, São Paulo, Brazil",
  "74436, Haskell, Oklahoma, United States",
  "Daniels, Colorado, United States",
  "Port Monmouth, New Jersey, United States",
  "173018, Velikiy Novgorod, Novgorod, Russia",
  "Klin, Moscow, Russia",
  "31300, Beli Manastir, Osijek-Baranja, Croatia",
  "Pendleton, Cincinnati, Ohio, United States",
  "Stará Turá, Trenciansky, Slovakia",
  "677021, Yakutsk, Sakha, Russia",
  "Jones County, North Carolina, United States",
  "624999, Serov, Sverdlovsk, Russia",
  "Prentiss County, Mississippi, United States",
  "346910, Novoshakhtinsk, Rostov, Russia",
  "CF24 1RU, Cardiff, Wales, United Kingdom",
  "Madison County, Ohio, United States",
  "Liberty, North Carolina, United States",
  "49872, Perkins, Michigan, United States",
  "309188, Gubkin, Belgorod, Russia",
  "68600-000, Bragança, Pará, Brazil",
  "68005-000, Santarém, Pará, Brazil",
  "66253, Tulungagung, East Java, Indonesia",
  "Robeson County, North Carolina, United States",
  "Sweet Briar, Virginia, United States",
  "20-002, Lublin, Lubelskie, Poland",
  "Oktyabr'skiy, Hrodna, Belarus",
  "Fairfax, Oklahoma, United States",
  "713 06, Iráklion, Crete, Greece",
  "LS1 3JR, Leeds, England, United Kingdom",
  "Woodbridge, Virginia, United States",
  "61111, Loves Park, Illinois, United States",
  "Kosice Metropolitan Area",
  "27101, Winston-Salem, North Carolina, United States",
  "Berkeley, Illinois, United States",
  "Lovell, Wyoming, United States",
  "693007, Yuzhno-Sakhalinsk, Sakhalin, Russia",
  "50667, Cologne, North Rhine-Westphalia, Germany",
  "Yefremovsky District, Tula, Russia",
  "Chesapeake City County, Virginia, United States",
  "Montana, Montana, Bulgaria",
  "21003, Huelva, Andalusia, Spain",
  "152905, Rybinsk, Yaroslavl’, Russia",
  "Cook County, Georgia, United States",
  "Alcochete, Setúbal, Portugal",
  "M23 9SA, Wythenshawe, England, United Kingdom",
  "Buckingham Township, Pennsylvania, United States",
  "2557, Gregory Hills, New South Wales, Australia",
  "Mineral, Virginia, United States",
  "354071, Sochi, Krasnodar, Russia",
  "Nishi-ku, Aichi, Japan",
  "Uryupinsky District, Volgograd, Russia",
  "53401, Racine, Wisconsin, United States",
  "9309, Fier, Fier, Albania",
  "H34E9D5, Qulan, Jambyl Region, Kazakhstan",
  "Kong Haakon VIIs plass, Trondheim, Trøndelag, Norway",
  "Faulkland, England, United Kingdom",
  "Rybinsky District, Krasnoyarsk Krai, Russia",
  "692059, Lesozavodsk, Primorye, Russia",
  "23061, Gloucester, Virginia, United States",
  "TS9 5PT, Stokesley, England, United Kingdom",
  "628002, Khanty-Mansiysk, Khanty-Mansi, Russia",
  "504 33, Borås, Västra Götaland County, Sweden",
  "Belogorsk, Crimea, Ukraine",
  "Calhoun County, Texas, United States",
  "4556, Mons, Queensland, Australia",
  "Greater Fond du Lac Area",
  "Stanton, Kentucky, United States",
  "Iredell Park, Rock Hill, South Carolina, United States",
  "357100, Nevinnomyssk, Stavropol, Russia",
  "Ashaway, Rhode Island, United States",
  "29056, Greeleyville, South Carolina, United States",
  "169501, Sosnogorsk, Komi, Russia",
  "29651, Greer, South Carolina, United States",
  "692417, Kavalerovo, Primorye, Russia",
  "225405, Baranavichy, Brest, Belarus",
  "07007, Palma, Balearic Islands, Spain",
  "Le Valnotte, Barneville-Carteret, Normandy, France",
  "Osage, Wyoming, United States",
  "Storey County, Nevada, United States",
  "2825, Ag. Nikolaos Soleas, Nicosia, Cyprus",
  "L10F5D9, Chapaev, West Kazakhstan Region, Kazakhstan",
  "50412, Tartu, Tartumaa, Estonia",
  "188560, Slantsy, Leningrad, Russia",
  "Majorda, Salcette, Goa, India",
  "Kungur-Tuk, Tuva, Russia",
  "25165, Racine, West Virginia, United States",
  "5130, Jászapáti, Jász-Nagykun-Szolnok, Hungary",
  "45-056, Opole, Opolskie, Poland",
  "K0K 1A0, Ameliasburg, Ontario, Canada",
  "142103, Podolsk, Moscow, Russia",
  "Salto, Uruguay",
  "Bayou Terrace, Hitchcock, Texas, United States",
  "2415-574, Leiria, Leiria, Portugal",
  "60122, Ancona, Marches, Italy",
  "Kurancılı, Kırşehir, Türkiye",
  "S9H 3H4, Swift Current, Saskatchewan, Canada",
  "Tekman, Erzurum, Türkiye",
  "74446, Wagoner, Oklahoma, United States",
  "309182, Gubkin, Belgorod, Russia",
  "Portimão, Faro, Portugal",
  "Safonovo, Smolensk, Russia",
  "428017, Cheboksary, Chuvashia, Russia",
  "96450, Coburg, Bavaria, Germany",
  "15212, Pittsburgh, Pennsylvania, United States",
  "Morrisonville, Wisconsin, United States",
  "BA1 0SA, Bath, England, United Kingdom",
  "182110, Velikiye Luki, Pskov, Russia",
  "4505, Lezhë, Lezhë, Albania",
  "Starkholmes, England, United Kingdom",
  "P7J 1K3, Fort William 52, Ontario, Canada",
  "Craven Arms, England, United Kingdom",
  "41008, Seville, Andalusia, Spain",
  "Sabugal, Guarda, Portugal",
  "Cottonwood, California, United States",
  "320026, Reşiţa, Caraş-Severin, Romania",
  "Charleroi, Pennsylvania, United States",
  "34025, Istanbul, Istanbul, Türkiye",
  "Lee County, Georgia, United States",
  "Municipio Linares, Aragua State, Venezuela",
  "Huntley, Illinois, United States",
  "Big Bear City, California, United States",
  "31008, Pamplona, Chartered Community of Navarre, Spain",
  "653047, Prokopyevsk, Kemerovo, Russia",
  "G0M 0B7, Saint-Évariste-de-Forsyth, Quebec, Canada",
  "Palma, Minas Gerais, Brazil",
  "9912, Kirkenes, Troms og Finnmark, Norway",
  "358005, Elista, Kalmykia, Russia",
  "Sokołów Małopolski, Podkarpackie, Poland",
  "Novokakhovska miska hromada, Kherson, Ukraine",
  "Sibley, Louisiana, United States",
  "75473, Powderly, Texas, United States",
  "Muro Leccese, Apulia, Italy",
  "Princeton, West Virginia, United States",
  "399058, Gryazi, Lipetsk, Russia",
  "T0E 1A0, Yellowstone, Alberta, Canada",
  "27929, Currituck, North Carolina, United States",
  "Benton, Arkansas, United States",
  "R0M 1T0, Pipestone, Manitoba, Canada",
  "Pecan Wood Estates, Hitchcock, Texas, United States",
  "José María Blanco, Lara State, Venezuela",
  "3000-481, Coimbra, Coimbra, Portugal",
  "72400, Batman, Batman, Türkiye",
  "Granville, Pennsylvania, United States",
  "430124, Baia Mare, Maramureş, Romania",
  "302 58, Halmstad, Halland County, Sweden",
  "48892, Webberville, Michigan, United States",
  "Sainte-Marthe-sur-le-Lac, Quebec, Canada",
  "Makarovskiy, Bashkortostan, Russia",
  "B61C1T4, Sarqan, Jetisu Region, Kazakhstan",
  "422587, Kanash, Tatarstan, Russia",
  "Patra, India",
  "683003, Petropavlovsk-Kamchatskiy, Kamchatka, Russia",
  "Perkins, St Petersburg, Florida, United States",
  "Váci járás, Pest, Hungary",
  "Alatyr’, Chuvashia, Russia",
  "Hutchinson Island South, Florida, United States",
  "Dufferin, New Brunswick, Canada",
  "Jimenez, Northern Mindanao, Philippines",
  "Saranpaul’, Khanty-Mansi, Russia",
  "100244, Ploieşti, Prahova, Romania",
  "Billings, Ontario, Canada",
  "Delaware County, Pennsylvania, United States",
  "507 40, Borås, Västra Götaland County, Sweden",
  "Okharpauwa, Nepal",
  "Mitchell, Nebraska, United States",
  "Fond du Sac, Pamplemousses, Mauritius",
  "E9C 1T6, Storeytown, New Brunswick, Canada",
  "Carterton, Wellington, New Zealand",
  "Michurinskiy, Altai Krai, Russia",
  "446628, Arzamastsevka, Samara, Russia",
  "Oxford, Massachusetts, United States",
  "142212, Serpukhov, Moscow, Russia",
  "Langlade, Brive-la-Gaillarde, Nouvelle-Aquitaine, France",
  "45131, Essen, North Rhine-Westphalia, Germany",
  "27610, Raleigh, North Carolina, United States",
  "94025, Menlo Park, California, United States",
  "Morgan City, Louisiana, United States",
  "Patnos, Ağrı, Türkiye",
  "Podravska Slatina, Virovitica-Podravina, Croatia",
  "07446, Ramsey, New Jersey, United States",
  "New Bern-Morehead City Area",
  "Covington, Louisiana, United States",
  "Chelannur, Kozhikode, Kerala, India",
  "Kansas, United States",
  "McHenry, Kentucky, United States",
  "627321, Golyshmanovo, Tyumen’, Russia",
  "Cape Winelands, Western Cape, South Africa",
  "Cockerham, England, United Kingdom",
  "Glazovskoye, Tatarstan, Russia",
  "33312, Fort Lauderdale, Florida, United States",
  "Coal City, Illinois, United States",
  "Luxemburg, Walloon Region, Belgium",
  "Roosevelt County, New Mexico, United States",
  "692924, Nakhodka, Primorye, Russia",
  "8281 AT, Hasselt, Overijssel, Netherlands",
  "Allendale, St Petersburg, Florida, United States",
  "53583, Sauk City, Wisconsin, United States",
  "Midsomer Norton, England, United Kingdom",
  "Joué-lès-Tours, Centre-Val de Loire, France",
  "665462, Usol'ye-Sibirskoye, Irkutsk, Russia",
  "70210, Kuopio, Northern Savonia, Finland",
  "Shostka, Sumy, Ukraine",
  "Shannon, Kansas, United States",
  "Craven, England, United Kingdom",
  "McKenzie, Tennessee, United States",
  "Transylvania, Louisiana, United States",
  "15500, Barros Blancos, Canelones, Uruguay",
  "Greater Ottawa Metropolitan Area",
  "LV-4650, Viļāni, Rezekne, Latvia",
  "Blue Ridge, Georgia, United States",
  "Tillamook, Oregon, United States",
  "Kanekal, Andhra Pradesh, India",
  "Covington, Texas, United States",
  "Adair County, Kentucky, United States",
  "461431, Tatarskaya Kargala, Orenburg, Russia",
  "NR1 1AA, Norwich, England, United Kingdom",
  "Baltimore, Maryland, United States",
  "38061, Pocahontas, Tennessee, United States",
  "Columbus, Georgia, United States",
  "State Side, Sheboygan, Wisconsin, United States",
  "Nottoway County, Virginia, United States",
  "654018, Novokuznetsk, Kemerovo, Russia",
  "De Ridder, Louisiana, United States",
  "8500-823, Portimão, Faro, Portugal",
  "602254, Murom, Vladimir, Russia",
  "41-935, Bytom, Śląskie, Poland",
  "Odessa, Texas, United States",
  "Moroni-Bambao, Grande Comore, Comoros",
  "Klyuchi, Kirov, Russia",
  "97370, Maripasoula, Saint-Laurent-du-Maroni, French Guiana",
  "8600-524, Lagos, Faro, Portugal",
  "71460, Kırıkkale, Kırıkkale, Türkiye",
  "SO17 1AB, Southampton, England, United Kingdom",
  "302 32, Halmstad, Halland County, Sweden",
  "Michigan, United States",
  "60123, Ancona, Marches, Italy",
  "Skopje, Skopje Statistical Region, North Macedonia",
  "301244, Shchëkino, Tula, Russia",
  "Rockingham, Georgia, United States",
  "Poundbury, Dorchester, England, United Kingdom",
  "33619, Bielefeld, North Rhine-Westphalia, Germany",
  "61-223, Poznan, Wielkopolskie, Poland",
  "Kampung Klarion, Beraur, West Papua, Indonesia",
  "Vichy, Auvergne-Rhône-Alpes, France",
  "Youngwood, Pennsylvania, United States",
  "Hitchcock County, Nebraska, United States",
  "050011, Bucharest, Bucharest, Romania",
  "403113, Uryupinsk, Volgograd, Russia",
  "Hall in Tirol, Tyrol, Austria",
  "602 34, Norrköping, Östergötland County, Sweden",
  "Le Bar-sur-Loup, Provence-Alpes-Côte d'Azur, France",
  "Montegrosso d’Asti, Piedmont, Italy",
  "Kateríni, Central Macedonia, Greece",
  "Tatarsky District, Novosibirsk, Russia",
  "346405, Novocherkassk, Rostov, Russia",
  "24124, Bergamo, Lombardy, Italy",
  "Shulan, Jilin, China",
  "24939, Flensburg, Schleswig-Holstein, Germany",
  "08028, Barcelona, Catalonia, Spain",
  "347811, Kamensk-Shakhtinskiy, Rostov, Russia",
  "Richland, Pennsylvania, United States",
  "Randall Square, Illinois, United States",
  "8323, Svolvær, Nordland, Norway",
  "621 47, Visby, Gotland County, Sweden",
  "03053, Cottbus, Brandenburg, Germany",
  "Pryvillia, Luhansk, Ukraine",
  "Floyd, New York, United States",
  "Bertier, Saint-Jean-Chambre, Auvergne-Rhône-Alpes, France",
  "44000, Nantes, Pays de la Loire, France",
  "9700-222, Angra do Heroísmo, Portugal",
  "Lugau, Saxony, Germany",
  "08030, Barcelona, Catalonia, Spain",
  "Seward, Alaska, United States",
  "Ivanovo, Brest, Belarus",
  "78215, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "Beaver Falls, Pennsylvania, United States",
  "38485, Waynesboro, Tennessee, United States",
  "Castilla and Leon, Spain",
  "MK45 4NF, Barton-Le-Clay, England, United Kingdom",
  "TS7 0EE, Middlesbrough, England, United Kingdom",
  "F63C3A0, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "Blackpool, England, United Kingdom",
  "47138, Duisburg, North Rhine-Westphalia, Germany",
  "Copenhagen Metropolitan Area",
  "Platteville, Colorado, United States",
  "Lamarque, Río Negro Province, Argentina",
  "Brooker, South Australia, Australia",
  "Harding, Roseville, California, United States",
  "Ravenna, Kentucky, United States",
  "Mount Vernon, Washington, United States",
  "Colfax, Wisconsin, United States",
  "Keya Paha County, Nebraska, United States",
  "81-578, Gdynia, Pomorskie, Poland",
  "Willows at Lake Rhea, Windermere, Florida, United States",
  "49451, Ravenna, Michigan, United States",
  "Sweetwater, Tennessee, United States",
  "93210, St.-Denis, Île-de-France, France",
  "371 52, Karlskrona, Blekinge County, Sweden",
  "5223 VG, ’s-Hertogenbosch, North Brabant, Netherlands",
  "Thurston, Nebraska, United States",
  "R25F8H7, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "55939, Harmony, Minnesota, United States",
  "673402, Nerchinsk, Zabaykalsky Krai, Russia",
  "Chickasaw Pines, Florida, United States",
  "Jackson, New Jersey, United States",
  "Teton Valley, Idaho, United States",
  "Tartumaa, Estonia",
  "582 47, Linköping, Östergötland County, Sweden",
  "Görele, Giresun, Türkiye",
  "900118, Constanţa, Constanţa, Romania",
  "164751, Mezen, Arkhangel’sk, Russia",
  "Coffee County, Georgia, United States",
  "71017, Viljandi, Viljandimaa, Estonia",
  "Kamenka, Cherkasy, Ukraine",
  "457357, Kartaly, Chelyabinsk, Russia",
  "14019, Villanova d’Asti, Piedmont, Italy",
  "Belgrade, Nebraska, United States",
  "S25C0K4, Ertis, Pavlodar Region, Kazakhstan",
  "Y35 T0H3, Wexford, County Wexford, Ireland",
  "Juneautown, Milwaukee, Wisconsin, United States",
  "Wallers, Hauts-de-France, France",
  "Pontotoc, Texas, United States",
  "73034, Kherson, Kherson, Ukraine",
  "Omutninsk, Kirov, Russia",
  "Esbjerg Municipality, Region of Southern Denmark, Denmark",
  "OL1 1AL, Oldham, England, United Kingdom",
  "Fosters, Alabama, United States",
  "90620, Buena Park, California, United States",
  "T0M 0M0, Caroline, Alberta, Canada",
  "Camp de personnes déplacées, Sahel, Burkina Faso",
  "75703, Tyler, Texas, United States",
  "03824, Durham, New Hampshire, United States",
  "BT78 1AA, Omagh, Northern Ireland, United Kingdom",
  "Howard, Pennsylvania, United States",
  "19604, Reading, Pennsylvania, United States",
  "Lea County, New Mexico, United States",
  "Loteamento Angelina, Jaraguá do Sul, Santa Catarina, Brazil",
  "Mason City, Iowa, United States",
  "Tyrrellstown, County Westmeath, Ireland",
  "420132, Bistriţa, Bistriţa-Năsăud, Romania",
  "665805, Angarsk, Irkutsk, Russia",
  "77550, Limoges-Fourches, Île-de-France, France",
  "S1 1AA, Sheffield, England, United Kingdom",
  "79842, Marathon, Texas, United States",
  "Zimarevskiy, Altai Krai, Russia",
  "Çorum, Çorum, Türkiye",
  "Missouri, United States",
  "Strafford, Vermont, United States",
  "6305, Tepelenë, Gjirokastër, Albania",
  "Shengavit, Yerevan, Armenia",
  "Brighton, Michigan, United States",
  "Milas, Muğla, Türkiye",
  "63005, Chesterfield, Missouri, United States",
  "La Joséphine, La Chapelle-Achard, Pays de la Loire, France",
  "29069, Lamar, South Carolina, United States",
  "Lamoureaux, Pickering, Ontario, Canada",
  "Richlands, North Carolina, United States",
  "7405, Kaposvár, Somogy, Hungary",
  "24943, Flensburg, Schleswig-Holstein, Germany",
  "Labinot-Fushë, Elbasan, Albania",
  "HU1 1EL, Kingston Upon Hull, England, United Kingdom",
  "Colonial, Quintana Roo, Mexico",
  "64640, Gallatin, Missouri, United States",
  "86640, Comalcalco, Tabasco, Mexico",
  "Pokrovskyi raion, Donetsk, Ukraine",
  "Serik, Antalya, Türkiye",
  "65193, Wiesbaden, Hesse, Germany",
  "Papua New Guinea",
  "Spotsylvania Courthouse, Virginia, United States",
  "Tolbert, Gilmer, North Carolina, United States",
  "07044, Verona, New Jersey, United States",
  "Lyman, Utah, United States",
  "HU7 0EL, Kingston Upon Hull, England, United Kingdom",
  "Greater Rijeka Area",
  "Hancock County, Maine, United States",
  "Africa",
  "Gümüşhacıköy, Amasya, Türkiye",
  "17201, Chambersburg, Pennsylvania, United States",
  "670004, Ulan-Ude, Buryatia, Russia",
  "607223, Arzamas, Nizhniy Novgorod, Russia",
  "Sarny Raion, Rivne, Ukraine",
  "48905, Autlán de Navarro, Jalisco, Mexico",
  "Iași metropolitan area, Iaşi, Romania",
  "Krasnoarmeyskoye, Rostov, Russia",
  "546 39, Thessaloniki, Central Macedonia, Greece",
  "Comox Valley, British Columbia, Canada",
  "65029, Odesa, Odessa, Ukraine",
  "Furnas, Sombrio, Santa Catarina, Brazil",
  "Smithfield, North Carolina, United States",
  "Tylertown, Mississippi, United States",
  "7000-862, Évora, Évora, Portugal",
  "33392, Gijón, Principality of Asturias, Spain",
  "Hvar, Split-Dalmatia, Croatia",
  "Flaxweiler, Grevenmacher, Luxembourg",
  "V92 X3F4, Tralee, County Kerry, Ireland",
  "236011, Kaliningrad, Kaliningrad, Russia",
  "Pittston, Pennsylvania, United States",
  "Winchester, Ohio, United States",
  "Middlesex, Ontario, Canada",
  "27614, Raleigh, North Carolina, United States",
  "Alexander, Iowa, United States",
  "9900-361, Horta, Portugal",
  "Chernyshevskiy, Amur, Russia",
  "Greater Mardin",
  "Tucker County, West Virginia, United States",
  "Kyzyl-Dyikan, Chuy Region, Kyrgyzstan",
  "Lääneranna vald, Pärnu maakond, Estonia",
  "Chesterfield, England, United Kingdom",
  "Offmore Farm, Kidderminster, England, United Kingdom",
  "79110, Freiburg, Baden-Württemberg, Germany",
  "Brooks, Georgia, United States",
  "Bratislava 1, Bratislava, Slovakia",
  "Allendale, Oakland, California, United States",
  "98168, Messina, Sicily, Italy",
  "Bratislava-Rača, Bratislava, Slovakia",
  "174411, Borovichi, Novgorod, Russia",
  "96091, Trinity Center, California, United States",
  "12548, Modena, New York, United States",
  "Mercer County, Kentucky, United States",
  "Keith, South Australia, Australia",
  "Ellis County, Texas, United States",
  "Tioga, Pennsylvania, United States",
  "Bedfordview, Gauteng, South Africa",
  "DL2 2UB, Walworth Gate, England, United Kingdom",
  "9033, Klagenfurt, Carinthia, Austria",
  "Watauga, Texas, United States",
  "Port Hood, Nova Scotia, Canada",
  "N1M 1N6, Fergus, Ontario, Canada",
  "040 11, Košice, Kosice, Slovakia",
  "Albemarle Country Estate, Brakpan, Gauteng, South Africa",
  "91108, San Marino, California, United States",
  "Cortland, Nebraska, United States",
  "SP11 0AG, Hurstbourne Tarrant, England, United Kingdom",
  "53641, Volosskoye, Dnipropetrovsk, Ukraine",
  "Williamsport, Pennsylvania, United States",
  "Chélan, Occitanie, France",
  "Greenbrier, Arkansas, United States",
  "36208, Vigo, Galicia, Spain",
  "Dmytrushkivska silska hromada, Cherkasy, Ukraine",
  "BD2 1AA, Bradford, England, United Kingdom",
  "8220, Chloraka, Paphos, Cyprus",
  "Kovdorskiy Rayon, Murmansk, Russia",
  "84095, Furth, Bavaria, Germany",
  "Borovsky District, Kaluga, Russia",
  "T1R 0A1, Brooks, Alberta, Canada",
  "Scottsbluff, Nebraska, United States",
  "362003, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "Targovište, Turgovishte, Bulgaria",
  "West Bengal, India",
  "Bledsoe, Kentucky, United States",
  "Awala-Yalimapo, Saint-Laurent-du-Maroni, French Guiana",
  "Ayanot, Center District, Israel",
  "98338, Graham, Washington, United States",
  "78521, Brownsville, Texas, United States",
  "Oklahoma, United States",
  "78000, Karabuk, Karabuk, Türkiye",
  "80814, Divide, Colorado, United States",
  "652196, Mariinskiy, Kemerovo, Russia",
  "Turan, Tuva, Russia",
  "Steele, Missouri, United States",
  "Mills, Wyoming, United States",
  "Cross Plains, Wisconsin, United States",
  "Sovetskoye, Saratov, Russia",
  "Columbus, Kansas, United States",
  "Viseu, Viseu, Portugal",
  "3532, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Shostka Raion, Sumy, Ukraine",
  "412909, Vol'sk, Saratov, Russia",
  "Sarapulka, Novosibirsk, Russia",
  "Steuben, Indiana, United States",
  "Oceanside, California, United States",
  "173001, Velikiy Novgorod, Novgorod, Russia",
  "Pikeville, North Carolina, United States",
  "Reading, Massachusetts, United States",
  "618353, Kizel, Perm, Russia",
  "Mason County, West Virginia, United States",
  "37200, Tours, Centre-Val de Loire, France",
  "McNairy County, Tennessee, United States",
  "R13C9X1, Beyneu, Mangystau Region, Kazakhstan",
  "Terry Lake, Colorado, United States",
  "99354, Richland, Washington, United States",
  "72209, Zenica, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "618201, Chusovoy, Perm, Russia",
  "31055, Quinto di Treviso, Veneto, Italy",
  "Bashkia Lushnje, Fier, Albania",
  "33699, Bielefeld, North Rhine-Westphalia, Germany",
  "67755, Linares, Nuevo León, Mexico",
  "Newbury, England, United Kingdom",
  "Bonnybridge, Scotland, United Kingdom",
  "427970, Sarapul, Udmurtia, Russia",
  "Coleman, Georgia, United States",
  "E25B7F2, Maqat, Atyrau Region, Kazakhstan",
  "Kirovsky City District, Perm, Russia",
  "15205, Pittsburgh, Pennsylvania, United States",
  "Eastern Avenue, Schenectady, New York, United States",
  "2600, Vác, Pest, Hungary",
  "N1M 0A1, Fergus, Ontario, Canada",
  "41100, Shostka, Sumy, Ukraine",
  "62970, Olmsted, Illinois, United States",
  "1190, Vienna, Vienna, Austria",
  "Balchik, Dobrich, Bulgaria",
  "Converse, Indiana, United States",
  "Midland, Pennsylvania, United States",
  "K1V 0A1, Ottawa, Ontario, Canada",
  "Grafton, Illinois, United States",
  "R6W 0A1, Winkler, Manitoba, Canada",
  "Allen Park, Michigan, United States",
  "Royal Sutton Coldfield, England, United Kingdom",
  "851 01, Bratislava, Bratislava, Slovakia",
  "Coffeeville, Alabama, United States",
  "Altıeylül, Balıkesir, Türkiye",
  "Ciudad Guayana, Bolivar State, Venezuela",
  "Cumberland, Nova Scotia, Canada",
  "36203, Vigo, Galicia, Spain",
  "Parker–Hickman Farm Historic District, Arkansas, United States",
  "627013, Yalutorovsk, Tyumen’, Russia",
  "Kirovskiy gorodskoy sovet, Mahilyow, Belarus",
  "670034, Ulan-Ude, Buryatia, Russia",
  "64000, Uşak, Uşak, Türkiye",
  "BD5 0AA, Bradford, England, United Kingdom",
  "WR10 2JW, Throckmorton, England, United Kingdom",
  "370, Göttingen, Lower Saxony, Germany",
  "05454, Fairfax, Vermont, United States",
  "01003, Amherst, Massachusetts, United States",
  "Versailles, Île-de-France, France",
  "462356, Novotroitsk, Orenburg, Russia",
  "Elliston, Montana, United States",
  "Paloh, Johore, Malaysia",
  "300006, Timişoara, Timiş, Romania",
  "761, Karlsruhe, Baden-Württemberg, Germany",
  "Čento, Skopje Statistical Region, North Macedonia",
  "Phillip's Town, New Tredegar, Wales, United Kingdom",
  "452452, Birsk, Bashkortostan, Russia",
  "57104, Sioux Falls, South Dakota, United States",
  "831 34, Östersund, Jämtland County, Sweden",
  "Laurens, Occitanie, France",
  "48844, Howell, Michigan, United States",
  "Călăraşi, Romania",
  "Austin, Minnesota, United States",
  "Greater Marbella Metropolitan Area",
  "320001, Reşiţa, Caraş-Severin, Romania",
  "Shenandoah, Fort Collins, Colorado, United States",
  "Lahti sub-region, Päijät-Häme, Finland",
  "Portsmouth City County, Virginia, United States",
  "Wallace, Idaho, United States",
  "Zimbabwe",
  "New York, United States",
  "E7L 1L5, Divide, New Brunswick, Canada",
  "Walla Walla, Washington, United States",
  "32792, Winter Park, Florida, United States",
  "Tepebasi, Eskişehir, Türkiye",
  "Walshaw, Bury, England, United Kingdom",
  "Cosola, Cabella Ligure, Piedmont, Italy",
  "Kolpinsky District, St Petersburg, St Petersburg City, Russia",
  "F18M9H2, Semey, Abay Region, Kazakhstan",
  "694431, Aleksandrovsk-Sakhalinskiy, Sakhalin, Russia",
  "Sunflower Park, Setsoto, Free State, South Africa",
  "37692, Unicoi, Tennessee, United States",
  "Polk City, Florida, United States",
  "173025, Velikiy Novgorod, Novgorod, Russia",
  "53089, Sussex, Wisconsin, United States",
  "623420, Kamensk Uralskiy, Sverdlovsk, Russia",
  "Country Estates, Gentry, Arkansas, United States",
  "Ruskin, Florida, United States",
  "Chesapeake Beach, Maryland, United States",
  "V94 H2Y7, Limerick, County Limerick, Ireland",
  "10962, Orangeburg, New York, United States",
  "Huntingdon Valley, Pennsylvania, United States",
  "MD-2075, Chişinău, Chișinău, Moldova",
  "L01B2A7, Oral, West Kazakhstan Region, Kazakhstan",
  "Sampsonievskiy, St Petersburg, St Petersburg City, Russia",
  "Kingsbury, Bentonville, Arkansas, United States",
  "HP17 9TR, Great Kimble, England, United Kingdom",
  "Kotel'nichskoye, Kirov, Russia",
  "29659, Lowndesville, South Carolina, United States",
  "La Boquilla del Conchos, Chihuahua, Mexico",
  "CF3 0LU, Cardiff, Wales, United Kingdom",
  "1674, Pepingen, Flemish Region, Belgium",
  "171506, Kimry, Tver’, Russia",
  "29010, Málaga, Andalusia, Spain",
  "352383, Kropotkin, Krasnodar, Russia",
  "Riau Islands, Indonesia",
  "Calhoun Falls, South Carolina, United States",
  "24106, Subotica, Vojvodina, Serbia",
  "39564, Ocean Springs, Mississippi, United States",
  "427965, Sarapul, Udmurtia, Russia",
  "Luna, Cordillera Admin Region, Philippines",
  "8001, Zurich, Zurich, Switzerland",
  "Hazrat-e Turkestan, Yassy, Turkistan Region, Kazakhstan",
  "21300, Makarska, Split-Dalmatia, Croatia",
  "491 00, Kérkira, Ionian Islands, Greece",
  "665402, Cheremkhovo, Irkutsk, Russia",
  "37007, Salamanca, Castilla and Leon, Spain",
  "Custer County, Oklahoma, United States",
  "Wheeler, Oregon, United States",
  "Kendall, Illinois, United States",
  "Big Horn County, Wyoming, United States",
  "Derventa, Serb Republic, Bosnia and Herzegovina",
  "Clinton, South Carolina, United States",
  "141323, Sergiyev Posad, Moscow, Russia",
  "Sussex County, Delaware, United States",
  "96237, Ebersdorf bei Coburg, Bavaria, Germany",
  "442530, Kuznetsk, Penza, Russia",
  "30103, Adairsville, Georgia, United States",
  "Redding, California, United States",
  "Colombia",
  "Cooper City, Florida, United States",
  "Bain Park, Kenosha, Wisconsin, United States",
  "6013, Innsbruck, Tyrol, Austria",
  "18002, Granada, Andalusia, Spain",
  "Marquette Charter Township, Michigan, United States",
  "641883, Shadrinsk, Kurgan, Russia",
  "IP13 0JB, Pettistree, England, United Kingdom",
  "2513 AA, The Hague, South Holland, Netherlands",
  "Guinea-Bissau",
  "Ashland City, Tennessee, United States",
  "666782, Ust-Kut, Irkutsk, Russia",
  "E9C 1V6, Storeytown, New Brunswick, Canada",
  "Greater Cairo",
  "Portsmouth, Virginia, United States",
  "La Nolandière, Landivy, Pays de la Loire, France",
  "Poronayskiy Rayon, Sakhalin, Russia",
  "NR14 8PL, Swainsthorpe, England, United Kingdom",
  "20154, Milan, Lombardy, Italy",
  "Salem, Tamil Nadu, India",
  "620144, Yekaterinburg, Sverdlovsk, Russia",
  "West Flanders, Flemish Region, Belgium",
  "00144, Rome, Latium, Italy",
  "Antalya Subregion, Türkiye",
  "Novotroitskoye, Tyumen’, Russia",
  "Traverse, Cuq, Nouvelle-Aquitaine, France",
  "City Of Bristol, England, United Kingdom",
  "6450, Molde, Møre og Romsdal, Norway",
  "Kamenka-Bugskaya, Lviv, Ukraine",
  "Naples, Campania, Italy",
  "360005, Nalchik, Kabardino-Balkaria, Russia",
  "666534, Ulkan, Irkutsk, Russia",
  "Dimmitt, Texas, United States",
  "Wickersley, England, United Kingdom",
  "152935, Rybinsk, Yaroslavl’, Russia",
  "Pecan Park, Bastrop, Texas, United States",
  "Marinha Grande, Leiria, Portugal",
  "Robertson Quay, Singapore River, Central Singapore Community Development Council, Singapore",
  "8702, Astë, Kukës, Albania",
  "Russell, Australian Capital Territory, Australia",
  "3526, Miskolc, Borsod-Abaúj-Zemplén, Hungary",
  "Y21 D510, Enniscorthy, County Wexford, Ireland",
  "51710, Bor, Niğde, Türkiye",
  "Umantsevo, Kalmykia, Russia",
  "Marshall, Texas, United States",
  "75-001, Koszalin, Zachodniopomorskie, Poland",
  "48159, Münster, North Rhine-Westphalia, Germany",
  "Otero de Herreros, Castilla and Leon, Spain",
  "B61B3E0, Sarqan, Jetisu Region, Kazakhstan",
  "53081, Sheboygan, Wisconsin, United States",
  "2611, Lillehammer, Innlandet, Norway",
  "659304, Biysk, Altai Krai, Russia",
  "35004, Moody, Alabama, United States",
  "Rostov, Yaroslavl’, Russia",
  "75006, Carrollton, Texas, United States",
  "39770, Kirklareli, Kırklareli, Türkiye",
  "Harrison, Ohio, United States",
  "Ontario County, New York, United States",
  "92841, Garden Grove, California, United States",
  "Isaccea, Tulcea, Romania",
  "Mille-Isles, Quebec, Canada",
  "10360, Zagreb, Zagreb, Croatia",
  "Zapolyarnyy, Murmansk, Russia",
  "BT82 9PD, Sion Mills, Northern Ireland, United Kingdom",
  "Red Hill, Queensland, Australia",
  "70123, Bari, Apulia, Italy",
  "9020, Klagenfurt, Carinthia, Austria",
  "18280, Cankiri, Çankırı, Türkiye",
  "20810, Chevy Chase, Maryland, United States",
  "250 02, Helsingborg, Skåne County, Sweden",
  "800001, Galaţi, Galaţi, Romania",
  "Inowrocław Gmina, Kujawsko-pomorskie, Poland",
  "Abbeville, Alabama, United States",
  "Calhoun County, Mississippi, United States",
  "68874, Sargent, Nebraska, United States",
  "Racine, Wisconsin, United States",
  "06437, Guilford, Connecticut, United States",
  "Ugarchin Municipality, Lovech, Bulgaria",
  "TS8 9ZD, Middlesbrough, England, United Kingdom",
  "88-105, Inowrocław, Kujawsko-pomorskie, Poland",
  "Agdam District, Karabakh, Azerbaijan",
  "47179, Duisburg, North Rhine-Westphalia, Germany",
  "225760, Asnyezhytsy, Brest, Belarus",
  "Hanover, Indiana, United States",
  "Béziers, Occitanie, France",
  "Mas de Labarthe, Laramière, Occitanie, France",
  "3145, Tønsberg, Vestfold og Telemark, Norway",
  "76600, Le Havre, Normandy, France",
  "652479, Anzhero-Sudzhensk, Kemerovo, Russia",
  "Missoula, Montana, United States",
  "Pierce, Indiana, United States",
  "79932-000, Aral Moreira, Mato Grosso do Sul, Brazil",
  "39208, Pearl, Mississippi, United States",
  "Oxford, Connecticut, United States",
  "Gentil, Rio Grande do Sul, Brazil",
  "Cashwell Minnehaha Shores, Florida, United States",
  "M1L 0A1, Scarborough, Ontario, Canada",
  "0667, Oslo, Oslo, Norway",
  "47259, Duisburg, North Rhine-Westphalia, Germany",
  "8305, Svolvær, Nordland, Norway",
  "652990, Tashtagol, Kemerovo, Russia",
  "Iron, Missouri, United States",
  "Dinwiddie, Virginia, United States",
  "2023 KR, Haarlem, North Holland, Netherlands",
  "Beek en Donk, North Brabant, Netherlands",
  "24504, Lynchburg, Virginia, United States",
  "666705, Kirensk, Irkutsk, Russia",
  "San Lorenzo Canton, Esmeraldas, Ecuador",
  "676282, Tynda, Amur, Russia",
  "Szekesfehervar Metropolitan Area",
  "16143, Genoa, Liguria, Italy",
  "NN15 5LU, Burton Latimer, England, United Kingdom",
  "B55E0B9, Zharkent, Jetisu Region, Kazakhstan",
  "Pacific County, Washington, United States",
  "San Andrés de Giles, Buenos Aires Province, Argentina",
  "Alcorn, Laguiole, Occitanie, France",
  "Kilkenny, County Kilkenny, Ireland",
  "Smiths Falls, Ontario, Canada",
  "Wells, Texas, United States",
  "Greater Perth Area",
  "Wrocław, Dolnośląskie, Poland",
  "141370, Sergiyev Posad, Moscow, Russia",
  "Hudson Falls, New York, United States",
  "88215, Mostar, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "17056, Mifflintown, Pennsylvania, United States",
  "Nelas, Viseu, Portugal",
  "Starritstown, County Donegal, Ireland",
  "1200, Geneva, Geneva, Switzerland",
  "Buena, New Jersey, United States",
  "NR3 1AA, Norwich, England, United Kingdom",
  "City Of Southampton, England, United Kingdom",
  "31206, Macon, Georgia, United States",
  "Sumner, Washington, United States",
  "420061, Kazan, Tatarstan, Russia",
  "Étival-lès-le-Mans, Pays de la Loire, France",
  "606425, Balakhna, Nizhniy Novgorod, Russia",
  "V0N 2M1, Pender Island, British Columbia, Canada",
  "Alexandria, Ontario, Canada",
  "Todd Creek, Colorado, United States",
  "Rutherfordton, North Carolina, United States",
  "Greater Dobrich Area",
  "110036, Piteşti, Argeş, Romania",
  "28635, Hays, North Carolina, United States",
  "DE5 9QP, Ripley, England, United Kingdom",
  "Carter County, Oklahoma, United States",
  "Mizoram, India",
  "Syzransky District, Samara, Russia",
  "47198, Duisburg, North Rhine-Westphalia, Germany",
  "Ruse, Ruse, Bulgaria",
  "Shiraz County, Fars Province, Iran",
  "Henry County, Ohio, United States",
  "Nassau, Rhineland-Palatinate, Germany",
  "Yalta, Donetsk, Ukraine",
  "Galliane, Larée, Occitanie, France",
  "Sargent, Missouri, United States",
  "Burtonsville, Maryland, United States",
  "Koba Tatéma, Boké Region, Guinea",
  "12630, Agen-d’Aveyron, Occitanie, France",
  "Naples, New York, United States",
  "Ramsey County, Minnesota, United States",
  "Geneva, Indiana, United States",
  "12770, Pond Eddy, New York, United States",
  "Little Billing, Northampton, England, United Kingdom",
  "427432, Votkinsk, Udmurtia, Russia",
  "431750, Bol’shiye Berezniki, Mordovia, Russia",
  "57280, Maizières-lès-Metz, Grand Est, France",
  "Hude, Lower Saxony, Germany",
  "79532, Loraine, Texas, United States",
  "McCurtain County, Oklahoma, United States",
  "Săcele, Braşov, Romania",
  "86370, Comalcalco, Tabasco, Mexico",
  "Cortland Township, Illinois, United States",
  "186509, Belomorsk, Karelia, Russia",
  "C19C3A4, Zhaltyr, Akmola Region, Kazakhstan",
  "Kiowa, Kansas, United States",
  "658820, Slavgorod, Altai Krai, Russia",
  "452607, Oktyabrsky, Bashkortostan, Russia",
  "Smolensky District, Altai Krai, Russia",
  "Calumet, Indiana, United States",
  "120036, Buzău, Buzău, Romania",
  "Khmelnytsky, Ukraine",
  "Moniteau County, Missouri, United States",
  "44230-000, Amélia Rodrigues, Bahia, Brazil",
  "Athens, Wisconsin, United States",
  "403882, Kamyshin, Volgograd, Russia",
  "CV37 8HW, Clifford Chambers, England, United Kingdom",
  "Marmaris, Muğla, Türkiye",
  "Warwick, Rhode Island, United States",
  "Colesberg, Northern Cape, South Africa",
  "Ertis district, Pavlodar Region, Kazakhstan",
  "Fort Washakie, Wyoming, United States",
  "San Damiano d’Asti, Piedmont, Italy",
  "Palo Negro, Aragua State, Venezuela",
  "Aldermaston Soke, England, United Kingdom",
  "Montoursy, Junhac, Auvergne-Rhône-Alpes, France",
  "Licking, West Virginia, United States",
  "Danese, West Virginia, United States",
  "Buzuluk, Orenburg, Russia",
  "11205, Algeciras, Andalusia, Spain",
  "Greater Syracuse, Italy Area",
  "78628, Georgetown, Texas, United States",
  "HP17 0XH, Little Kimble, England, United Kingdom",
  "Greenville, North Carolina, United States",
  "Dixon Springs, Tennessee, United States",
  "713 07, Iráklion, Crete, Greece",
  "Randall, Iowa, United States",
  "94600, Kemi, Lapland, Finland",
  "Daniels, Wisconsin, United States",
  "Columbia-Shuswap, British Columbia, Canada",
  "J1T 0A2, Asbestos, Quebec, Canada",
  "166001, Naryan Mar, Nenets, Russia",
  "Greater Leipzig Area",
  "59211, Antelope, Montana, United States",
  "17100, Çanakkale, Çanakkale, Türkiye",
  "Volgodonskoy District, Rostov, Russia",
  "5535, Haugesund, Rogaland, Norway",
  "Winklern, Carinthia, Austria",
  "Wayne County, Georgia, United States",
  "220155, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "08-300, Sokołów Podlaski, Mazowieckie, Poland",
  "Santos Lugares, Buenos Aires Province, Argentina",
  "9720, Delvinë, Vlorë, Albania",
  "Hubbard Lake, Michigan, United States",
  "Banatski Karlovac, Vojvodina, Serbia",
  "Jamaica",
  "Łódzki wschodni, Łódzkie, Poland",
  "Bobruyskiy gorodskoy sovet, Mahilyow, Belarus",
  "C40F3G4, Esil, Akmola Region, Kazakhstan",
  "Galaxi, Veracruz, Mexico",
  "Northampton County, Virginia, United States",
  "Dover Plains, New York, United States",
  "B6L 5E5, Lower Onslow, Nova Scotia, Canada",
  "15-001, Białystok, Podlaskie, Poland",
  "0363, Oslo, Oslo, Norway",
  "Swansea, South Carolina, United States",
  "Callahan, California, United States",
  "97141, Tillamook, Oregon, United States",
  "69017, Dolinskoye, Zaporizhzhya, Ukraine",
  "Pabianicki, Łódzkie, Poland",
  "33010, Oviedo, Principality of Asturias, Spain",
  "Alexandria, Indiana, United States",
  "Greater Larisa Area",
  "TR18 2SA, Penzance, England, United Kingdom",
  "Orange County, New York, United States",
  "9445, Evenskjær, Troms og Finnmark, Norway",
  "76209, Denton, Texas, United States",
  "S13H2H1, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Webertal, Hornberg, Baden-Württemberg, Germany",
  "238311, Gur'yevsk, Kaliningrad, Russia",
  "Engelsbrand, Baden-Württemberg, Germany",
  "Gercüş, Batman, Türkiye",
  "89828, Lamoille, Nevada, United States",
  "665727, Bratsk, Irkutsk, Russia",
  "363754, Mozdok, Severnaya Osetiya-Alaniya, Russia",
  "M22 5QZ, Wythenshawe, England, United Kingdom",
  "33326, Fort Lauderdale, Florida, United States",
  "HU9 1AA, Kingston Upon Hull, England, United Kingdom",
  "La Ravallière, Vaunoise, Normandy, France",
  "Greater St-Catharines-Niagara Metropolitan Area",
  "Alicante, Valencian Community, Spain",
  "Pineville, Louisiana, United States",
  "Kumertau Urban Okrug, Bashkortostan, Russia",
  "’s-Hertogenbosch, North Brabant, Netherlands",
  "Ovar, Aveiro, Portugal",
  "Glacière de Pivaut, Mazaugues, Provence-Alpes-Côte d'Azur, France",
  "94100, Kemi, Lapland, Finland",
  "West Lothian, Scotland, United Kingdom",
  "Hamarøy, Nordland, Norway",
  "Grayson, Saskatchewan, Canada",
  "Limestone, New Brunswick, Canada",
  "Greater Uzhgorod Area",
  "6040-108, Gavião, Portalegre, Portugal",
  "La Bertière, Malemort-du-Comtat, Provence-Alpes-Côte d'Azur, France",
  "394006, Voronezh, Voronezh, Russia",
  "Mellette Township, South Dakota, United States",
  "Princeton, New Jersey, United States",
  "7005-258, Évora, Évora, Portugal",
  "Brownsville, Texas, United States",
  "Cazasu, Brăila, Romania",
  "Jackson, Missouri, United States",
  "1384, Drammen, Viken, Norway",
  "Flora Duval Bueno, Hidalgo, Mexico",
  "71300, Kırıkkale, Kırıkkale, Türkiye",
  "Coopersburg, Pennsylvania, United States",
  "LV-5415, Daugavpils, Daugavpils, Latvia",
  "Bacău, Bacău, Romania",
  "Kayapinar, Diyarbakır, Türkiye",
  "New Plymouth District, Taranaki, New Zealand",
  "453215, Ishimbay, Bashkortostan, Russia",
  "Dover, North Carolina, United States",
  "Yedisu, Bingöl, Türkiye",
  "Sainte-Luce-sur-Loire, Pays de la Loire, France",
  "659301, Biysk, Altai Krai, Russia",
  "414032, Astrakhan, Astrakhan’, Russia",
  "Ukraine",
  "4230, Asenovgrad, Plovdiv, Bulgaria",
  "Yusufeli, Artvin, Türkiye",
  "800283, Galaţi, Galaţi, Romania",
  "184209, Apatity, Murmansk, Russia",
  "Bristol County, Rhode Island, United States",
  "02290, Adıyaman, Adıyaman, Türkiye",
  "35215, Birmingham, Alabama, United States",
  "Moody County, South Dakota, United States",
  "60010-000, Fortaleza, Ceará, Brazil",
  "Berlin, Maryland, United States",
  "Georgetown, Indiana, United States",
  "Bodoquena, Mato Grosso do Sul, Brazil",
  "67585, Hutchinson, Kansas, United States",
  "Windsor, North Carolina, United States",
  "371 40, Karlskrona, Blekinge County, Sweden",
  "Arras, Hauts-de-France, France",
  "Santa Marta, Magdalena, Colombia",
  "Randall, New Tecumseth, Ontario, Canada",
  "Refahiye, Erzincan, Türkiye",
  "71420, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "Westchester County, New York, United States",
  "Tierps kommun, Uppsala County, Sweden",
  "Slatina, Moravia-Silesia, Czechia",
  "89129, Reggio di Calabria, Calabria, Italy",
  "Hudson County, New Jersey, United States",
  "97753, Powell Butte, Oregon, United States",
  "37362, Panevėžys, Panevėžio, Lithuania",
  "Greater Dijon Area",
  "G5R 6C1, Rivière-du-Loup, Quebec, Canada",
  "Woerden, Utrecht, Netherlands",
  "92843, Garden Grove, California, United States",
  "Khanty-Mansiysky District, Khanty-Mansi, Russia",
  "Hudson, Michigan, United States",
  "12306, Schenectady, New York, United States",
  "90412, Haapsalu, Laane, Estonia",
  "29117, Orangeburg, South Carolina, United States",
  "11060, Belgrade, Serbia",
  "Ward 7, Ba Ria-Vung Tau, Vietnam",
  "47010, Valladolid, Castilla and Leon, Spain",
  "DD5 3TB, Broughty Ferry, Scotland, United Kingdom",
  "Dickey Plan, Selwyn, Ontario, Canada",
  "60326, Frankfurt, Hesse, Germany",
  "320201, Reşiţa, Caraş-Severin, Romania",
  "2150, Harris Park, New South Wales, Australia",
  "97129, Lamentin, Basse-Terre, Guadeloupe",
  "Greater Trieste Metropolitan Area",
  "Hickman, Kentucky, United States",
  "347387, Volgodonsk, Rostov, Russia",
  "Y25 A3E9, Gorey, County Wexford, Ireland",
  "01307, Dresden, Saxony, Germany",
  "Pays de la Loire, France",
  "831 92, Östersund, Jämtland County, Sweden",
  "Mikhaylovka, Luhansk, Ukraine",
  "Indiana, United States",
  "Venango, Nebraska, United States",
  "Ružomberok, Zilina, Slovakia",
  "45102, Amelia, Ohio, United States",
  "27705, Durham, North Carolina, United States",
  "34604, Brooksville, Florida, United States",
  "Alekseyevka, Ryazan’, Russia",
  "Churchill, Manitoba, Canada",
  "10010, Velika Gorica, Zagreb, Croatia",
  "Utah, United States",
  "78216, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "678733, Oymyakon, Sakha, Russia",
  "Upton, Broadstairs, England, United Kingdom",
  "Inverness, Subdivision C, Nova Scotia, Canada",
  "Alabama, United States",
  "75212, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Guadalupe, Huila, Colombia",
  "6371, Stans, Nidwalden, Switzerland",
  "77343, Kalush, Ivano-Frankivsk, Ukraine",
  "663230, Turukhansk, Krasnoyarsk Krai, Russia",
  "Tatlı, Qazakh-Tovuz, Azerbaijan",
  "Pine City, Minnesota, United States",
  "423580, Nizhnekamsk, Tatarstan, Russia",
  "Cherbourg-en-Cotentin, Normandy, France",
  "36000, Poltava, Poltava, Ukraine",
  "Upton, Kentucky, United States",
  "Eisenstadt-Umgebung, Burgenland, Austria",
  "156013, Kostroma, Kostroma, Russia",
  "REFUGIO DEL SOL, Cauca, Colombia",
  "61040, Trabzon, Trabzon, Türkiye",
  "Cheyenne, Texas, United States",
  "32925, Patrick AFB, Florida, United States",
  "4371, Egersund, Rogaland, Norway",
  "99206, Spokane, Washington, United States",
  "Winston, Georgia, United States",
  "Fondettes, Centre-Val de Loire, France",
  "Carver City-Lincoln Gardens, Tampa, Florida, United States",
  "9000-260, Funchal, Madeira Island, Portugal",
  "420008, Bistriţa, Bistriţa-Năsăud, Romania",
  "15851, Reynoldsville, Pennsylvania, United States",
  "Lunenburg County, Virginia, United States",
  "Philadelphia, Mississippi, United States",
  "Victoria, Minnesota, United States",
  "Boxborough, Massachusetts, United States",
  "Velsky District, Arkhangel’sk, Russia",
  "22980, Waynesboro, Virginia, United States",
  "3128, Box Hill South, Victoria, Australia",
  "22031, Fairfax, Virginia, United States",
  "Limestone, Arkansas, United States",
  "Enniscorthy, County Wexford, Ireland",
  "9112, Herisau, Appenzell Outer-Rhoden, Switzerland",
  "Cartagena Metropolitan Area",
  "Saluda County, South Carolina, United States",
  "Jardim Sobradinho e Furnas, Araras, São Paulo, Brazil",
  "Gaziantep, Gaziantep, Türkiye",
  "414041, Astrakhan, Astrakhan’, Russia",
  "2940, Newcastle, KwaZulu-Natal, South Africa",
  "Brno, South Moravia, Czechia",
  "3373 AA, Hardinxveld-Giessendam, South Holland, Netherlands",
  "Dublin 4, County Dublin, Ireland",
  "Perth Airport, Western Australia, Australia",
  "62770, Tlaltizapán de Zapata, Morelos, Mexico",
  "81739, Munich, Bavaria, Germany",
  "Llano Grande, Coclé, Panama",
  "33609, Bielefeld, North Rhine-Westphalia, Germany",
  "4605, Rrëshen, Lezhë, Albania",
  "26725, Emden, Lower Saxony, Germany",
  "Schœlcher, Fort-de-France, Martinique",
  "503 30, Borås, Västra Götaland County, Sweden",
  "Santa Rosa, California, United States",
  "69118, Heidelberg, Baden-Württemberg, Germany",
  "50054, Colfax, Iowa, United States",
  "London, Canada Metropolitan Area",
  "Pawnee County, Nebraska, United States",
  "24143, Kiel, Schleswig-Holstein, Germany",
  "3912, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Brookeland, Texas, United States",
  "8064 PT, Hasselt, Overijssel, Netherlands",
  "24179, Vinton, Virginia, United States",
  "29600, Gumushane, Gümüşhane, Türkiye",
  "169848, Inta, Komi, Russia",
  "95200-000, Vacaria, Rio Grande do Sul, Brazil",
  "62553, Oconee, Illinois, United States",
  "623400, Kamensk Uralskiy, Sverdlovsk, Russia",
  "Cabarrus Farms South, North Carolina, United States",
  "10400, Balıkesir, Balıkesir, Türkiye",
  "72756, Rogers, Arkansas, United States",
  "Muskego, Wisconsin, United States",
  "Treasure Town, Indore, Madhya Pradesh, India",
  "56367, Rice, Minnesota, United States",
  "37221, Nashville, Tennessee, United States",
  "77488, Wharton, Texas, United States",
  "368304, Kaspiysk, Dagestan, Russia",
  "Carolinas, North Carolina, United States",
  "Clallam County, Washington, United States",
  "81200, Joensuu, North Karelia, Finland",
  "Inverness, Quebec, Canada",
  "Carbon County, Utah, United States",
  "Wales, United Kingdom",
  "Khmelnytskyi Raion, Khmelnytsky, Ukraine",
  "15057, McDonald, Pennsylvania, United States",
  "47891, Serravalle, San Marino",
  "5954, Nikopol, Pleven, Bulgaria",
  "Kalynivska miska hromada, Vinnytsya, Ukraine",
  "Söke, Aydın, Türkiye",
  "La Petite Ville Audrain, Loudéac, Brittany, France",
  "Hudson, New York, United States",
  "55020, Elko New Market, Minnesota, United States",
  "80636, Munich, Bavaria, Germany",
  "Southwest Sequoyah, Fayetteville, Arkansas, United States",
  "20910, Silver Spring, Maryland, United States",
  "Hughes, Santa Fe, Argentina",
  "Wilson, Oklahoma, United States",
  "850, Ingolstadt, Bavaria, Germany",
  "Vancouver, Washington, United States",
  "Simferopol, Crimea, Ukraine",
  "10-900, Olsztyn, Warmińsko-mazurskie, Poland",
  "94506, Danville, California, United States",
  "Daggett, California, United States",
  "40217, Düsseldorf, North Rhine-Westphalia, Germany",
  "West Chester, Pennsylvania, United States",
  "620007, Yekaterinburg, Sverdlovsk, Russia",
  "3046, Drammen, Viken, Norway",
  "Le Bourg, Laramière, Occitanie, France",
  "Cascade, Iowa, United States",
  "303854, Livny, Orel, Russia",
  "676018, Skovorodino, Amur, Russia",
  "10302, Kyiv, Kyiv City, Ukraine",
  "Barnesville, Pennsylvania, United States",
  "Millerovo, Rostov, Russia",
  "Monplaisir, Chélan, Occitanie, France",
  "24017, Roanoke, Virginia, United States",
  "Aroneanu, Iaşi, Romania",
  "Lamoure, Villebazy, Occitanie, France",
  "Krasnokamensk, Zabaykalsky Krai, Russia",
  "Adams County, Indiana, United States",
  "Greater La Rochelle Area",
  "2034 LV, Haarlem, North Holland, Netherlands",
  "McLeod Settlement, Inverness, Subdivision C, Nova Scotia, Canada",
  "Palau",
  "10020, Pecetto, Piedmont, Italy",
  "69124, Heidelberg, Baden-Württemberg, Germany",
  "Papillion, Nebraska, United States",
  "Garden City, New York, United States",
  "Cameron Parish County, Louisiana, United States",
  "Seminole Heights, Tampa, Florida, United States",
  "Davie Towne Centre, Davie, Florida, United States",
  "Travis Park, Goodyear, Arizona, United States",
  "30820, Alcantarilla, Región de Murcia, Spain",
  "358007, Elista, Kalmykia, Russia",
  "116, Reykjavík, Capital Region, Iceland",
  "507 50, Borås, Västra Götaland County, Sweden",
  "Vilassar de Dalt, Catalonia, Spain",
  "8010, Paphos Municipality, Paphos, Cyprus",
  "433502, Dimitrovgrad, Ul’yanovsk, Russia",
  "Calarasi, Moldova",
  "618507, Solikamsk, Perm, Russia",
  "446627, Arzamastsevka, Samara, Russia",
  "26129, Oldenburg, Lower Saxony, Germany",
  "346916, Novoshakhtinsk, Rostov, Russia",
  "412420, Atkarsk, Saratov, Russia",
  "Mediaş, Sibiu, Romania",
  "01060, Northampton, Massachusetts, United States",
  "188961, Svetogorsk, Leningrad, Russia",
  "3113, Tønsberg, Vestfold og Telemark, Norway",
  "Albany Creek, Queensland, Australia",
  "9700-111, Angra do Heroísmo, Portugal",
  "32000, Čačak, Centralna Srbija, Serbia",
  "20018, San Sebastián, Basque Country, Spain",
  "8281 BK, Hasselt, Overijssel, Netherlands",
  "300 11, Agrínio, Western Greece, Greece",
  "Chippewa, Stone Mills, Ontario, Canada",
  "413124, Engels, Saratov, Russia",
  "Forrest Hill, Auckland, Auckland, New Zealand",
  "9602, Hammerfest, Troms og Finnmark, Norway",
  "33604, Bielefeld, North Rhine-Westphalia, Germany",
  "346505, Shakhty, Rostov, Russia",
  "Konotop Raion, Sumy, Ukraine",
  "37075, Hendersonville, Tennessee, United States",
  "South Dakota, United States",
  "Lehigh County, Pennsylvania, United States",
  "652162, Mariinskiy, Kemerovo, Russia",
  "Denton, North Carolina, United States",
  "4337 KX, Middelburg, Zeeland, Netherlands",
  "Walloway, South Australia, Australia",
  "Asino, Tomsk, Russia",
  "187557, Tikhvin, Leningrad, Russia",
  "Carbondale, Illinois, United States",
  "24224, Subotica, Vojvodina, Serbia",
  "Carleys Corner, Merrickville-Wolford, Ontario, Canada",
  "Timiryazevskoye, Ivanovo, Russia",
  "33027, Rivne, Rivne, Ukraine",
  "Gračac, Zadar, Croatia",
  "Newberry Historic District, Newberry, Florida, United States",
  "368601, Derbent, Dagestan, Russia",
  "27055, Yadkinville, North Carolina, United States",
  "Greater Kuopio Area",
  "9850, Preslav, Shumen, Bulgaria",
  "Cherry Hill, Virginia, United States",
  "410004, Oradea, Bihor, Romania",
  "Umbarger, Texas, United States",
  "617060, Krasnokamsk, Perm, Russia",
  "Douglas, Wyoming, United States",
  "Swiss Bellvue, Umatilla, Florida, United States",
  "54100, Sakarya, Sakarya, Türkiye",
  "Osage Beach, Missouri, United States",
  "Yellow Pine, Idaho, United States",
  "Asenovgrad, Plovdiv, Bulgaria",
  "6200-109, Covilhã, Castelo Branco, Portugal",
  "94730, Tenango de Río Blanco, Veracruz, Mexico",
  "Ville Platte, Louisiana, United States",
  "60457, Hickory Hills, Illinois, United States",
  "28605-742, New Fribourg, Rio de Janeiro, Brazil",
  "60620, Tokat, Tokat, Türkiye",
  "Campello sul Clitunno, Umbria, Italy",
  "04100, Agri, Ağrı, Türkiye",
  "Cassano Magnago, Lombardy, Italy",
  "676244, Zeya, Amur, Russia",
  "Hamburg, Minnesota, United States",
  "Cabell County, West Virginia, United States",
  "OL8 2LL, Oldham, England, United Kingdom",
  "59424, Coffee Creek, Montana, United States",
  "Ordu, Türkiye",
  "9702, Szombathely, Vas, Hungary",
  "Hampshire County, West Virginia, United States",
  "354068, Sochi, Krasnodar, Russia",
  "412308, Balashov, Saratov, Russia",
  "7350-085, Elvas, Portalegre, Portugal",
  "Odessa, New York, United States",
  "Sunflower, Wichita, Kansas, United States",
  "Kadiivska miska hromada, Luhansk, Ukraine",
  "6020, Ålesund, Møre og Romsdal, Norway",
  "Esil District, Akmola Region, Kazakhstan",
  "38820, Pontotoc, Mississippi, United States",
  "26009, Logroño, Autonomía de La Rioja, Spain",
  "PE8 5PW, Barnwell, England, United Kingdom",
  "Chase, Kansas, United States",
  "Morrisville, Pennsylvania, United States",
  "Navarro, Buenos Aires Province, Argentina",
  "182649, Borovichi, Pskov, Russia",
  "Killarney, County Kerry, Ireland",
  "658135, Aleysk, Altai Krai, Russia",
  "Sovetskiy, Altai Krai, Russia",
  "05401, Burlington, Vermont, United States",
  "Pittsboro, North Carolina, United States",
  "ST18 0SE, Great Haywood, England, United Kingdom",
  "Bouches-du-Rhône, Provence-Alpes-Côte d'Azur, France",
  "84536, Artëmovsk, Donetsk, Ukraine",
  "1001, Lausanne, Vaud, Switzerland",
  "462731, Dombarovskiy, Orenburg, Russia",
  "4634, Kristiansand, Agder, Norway",
  "Apsheronsk, Krasnodar, Russia",
  "614051, Perm, Perm, Russia",
  "593, Fallon, Montana, United States",
  "44500, Andorra, Aragon, Spain",
  "Burke, Virginia, United States",
  "Clifden, County Galway, Ireland",
  "37500, Lubny, Poltava, Ukraine",
  "08304, Mataró, Catalonia, Spain",
  "Sliven, Sliven, Bulgaria",
  "HP8 4AS, Chalfont St Giles, England, United Kingdom",
  "225702, Pinsk, Brest, Belarus",
  "Asotin County, Washington, United States",
  "Dumfries, New Brunswick, Canada",
  "Tegucigalpa, Francisco Morazán, Honduras",
  "56466, Motley, Minnesota, United States",
  "03820, Dover, New Hampshire, United States",
  "Uyar, Krasnoyarsk Krai, Russia",
  "Gorodskoy Okrug Kaspiysk, Dagestan, Russia",
  "10137, Turin, Piedmont, Italy",
  "K0C 1R0, Lunenburg, Ontario, Canada",
  "Locust Grove, Virginia, United States",
  "Butte, Montana, United States",
  "Grand Est, France",
  "644119, Omsk, Omsk, Russia",
  "St Andrew, Guernsey, Guernsey",
  "2817, Gjøvik, Innlandet, Norway",
  "Tucker, Georgia, United States",
  "Illinois, United States",
  "55374, Rogers, Minnesota, United States",
  "55344, Eden Prairie, Minnesota, United States",
  "352128, Tikhoretsk, Krasnodar, Russia",
  "652057, Yurga Vtoraya, Kemerovo, Russia",
  "Humboldt, Portland, Oregon, United States",
  "Buena Vista County, Iowa, United States",
  "Reşadiye, Tokat, Türkiye",
  "357747, Kislovodsk, Stavropol, Russia",
  "Făgăraş, Braşov, Romania",
  "10-444, Olsztyn, Warmińsko-mazurskie, Poland",
  "70840, Kuopio, Northern Savonia, Finland",
  "Phillips, Illinois, United States",
  "Permsky District, Perm, Russia",
  "9056, Gais, Appenzell Outer-Rhoden, Switzerland",
  "400212, Cluj-Napoca, Cluj, Romania",
  "IP1 9XG, Ipswich, England, United Kingdom",
  "Ballymote, County Sligo, Ireland",
  "140477, Kolomna, Moscow, Russia",
  "362008, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "23840, DeWitt, Virginia, United States",
  "694902, Uglegorsk, Sakhalin, Russia",
  "200813, Craiova, Dolj, Romania",
  "Freeborn County, Minnesota, United States",
  "Broadwater, St Petersburg, Florida, United States",
  "13421, Oneida, New York, United States",
  "04917, Belgrade, Maine, United States",
  "426006, Izhevsk, Udmurtia, Russia",
  "111 44, Stockholm, Stockholm County, Sweden",
  "Orangeburg Terrace, Modesto, California, United States",
  "9028, Klagenfurt, Carinthia, Austria",
  "Williamsburg, Florida, United States",
  "Vyskatskoye, Leningrad, Russia",
  "Pendergrass, Georgia, United States",
  "Thomasville, Alabama, United States",
  "03030, Afyonkarahisar, Afyon, Türkiye",
  "78643, Llano, Texas, United States",
  "Altdorf, Bavaria, Germany",
  "663319, Noril'sk, Taymyr, Russia",
  "71020, Viljandi, Viljandimaa, Estonia",
  "04958, North Anson, Maine, United States",
  "Kapullu, Karabuk, Türkiye",
  "671824, Ust’-Kyakhta, Buryatia, Russia",
  "652500, Leninsk-Kuznetskiy, Kemerovo, Russia",
  "Krumë, Kukës, Albania",
  "Gujarat, India",
  "Avery, Idaho, United States",
  "46128, Fillmore, Indiana, United States",
  "Hamburg, New York, United States",
  "VLT 1432, Valletta, Valletta, Malta",
  "4032, Liège, Walloon Region, Belgium",
  "Ternopilska miska hromada, Ternopil, Ukraine",
  "13794, Marathon, New York, United States",
  "Sauka, Itki, Jharkhand, India",
  "E1A 1V1, Dieppe, New Brunswick, Canada",
  "110003, Piteşti, Argeş, Romania",
  "Garden City, Michigan, United States",
  "CV31 1TQ, Radford Semele, England, United Kingdom",
  "79516, Snyder, Texas, United States",
  "105, Reykjavík, Capital Region, Iceland",
  "54448, Marathon City, Wisconsin, United States",
  "462734, Dombarovskiy, Orenburg, Russia",
  "Frederick County, Virginia, United States",
  "54120, Lappeenranta, South Karelia, Finland",
  "Lüleburgaz Bucağı, Kırklareli, Türkiye",
  "Lake Ozark, Missouri, United States",
  "Noble, Ohio, United States",
  "412313, Balashov, Saratov, Russia",
  "309993, Valuyki, Belgorod, Russia",
  "RM16 2AA, Grays, England, United Kingdom",
  "Gisy-les-Nobles, Bourgogne-Franche-Comté, France",
  "North York, Ontario, Canada",
  "357535, Pyatigorsk, Stavropol, Russia",
  "Härnösand, Västernorrland County, Sweden",
  "25271, Ripley, West Virginia, United States",
  "852, Sundsvall, Västernorrland County, Sweden",
  "Goat, Cockermouth, England, United Kingdom",
  "Westchester, Ocoee, Florida, United States",
  "Cheyenne County, Kansas, United States",
  "Ramsey, England, United Kingdom",
  "9019, Tromsø, Troms og Finnmark, Norway",
  "Ashland, Nebraska, United States",
  "Phillipsburg, Kansas, United States",
  "2021 RK, Haarlem, North Holland, Netherlands",
  "9405, Harstad, Troms og Finnmark, Norway",
  "Richland County, Wisconsin, United States",
  "356401, Blagodarnyy, Stavropol, Russia",
  "682860, Vanino, Khabarovsk, Russia",
  "37079, Göttingen, Lower Saxony, Germany",
  "13080, Aix-en-Provence, Provence-Alpes-Côte d'Azur, France",
  "C25B9X4, Makinsk, Akmola Region, Kazakhstan",
  "Somerset, New Jersey, United States",
  "Cockermouth, England, United Kingdom",
  "Brives-Charensac, Auvergne-Rhône-Alpes, France",
  "667000, Kyzyl, Tuva, Russia",
  "Fort Payne, Alabama, United States",
  "Seminole County, Florida, United States",
  "Craven County, North Carolina, United States",
  "446302, Otradnyy, Samara, Russia",
  "59106, Billings, Montana, United States",
  "Dewitt, South Valley, New Mexico, United States",
  "Hiram Bell Farmstead, Columbiana, Ohio, United States",
  "Münster, Hesse, Germany",
  "Highland Park, Michigan, United States",
  "446303, Otradnyy, Samara, Russia",
  "Juaben, Ashanti Region, Ghana",
  "Nottinghamshire, England, United Kingdom",
  "Zelenodolska miska hromada, Dnipropetrovsk, Ukraine",
  "OX49 5ST, Aston Rowant, England, United Kingdom",
  "Oxford, Mississippi, United States",
  "Charleston, Illinois, United States",
  "Edinburgh, Indiana, United States",
  "Saint-Joseph, Fort-de-France, Martinique",
  "456227, Zlatoust, Chelyabinsk, Russia",
  "426011, Izhevsk, Udmurtia, Russia",
  "4639, Kristiansand, Agder, Norway",
  "Trumbull, Ohio, United States",
  "Vernon Hills, Illinois, United States",
  "C40A1T5, Esil, Akmola Region, Kazakhstan",
  "Reims, Grand Est, France",
  "Columbus, North Carolina, United States",
  "28661, Lenoir, North Carolina, United States",
  "Winchester, New Hampshire, United States",
  "Hubbard, Texas, United States",
  "Marlborough, England, United Kingdom",
  "626150, Tobolsk, Tyumen’, Russia",
  "28586, Vanceboro, North Carolina, United States",
  "Sochiapa, Veracruz, Mexico",
  "607233, Arzamas, Nizhniy Novgorod, Russia",
  "Dadeville, Alabama, United States",
  "Orta, Kırşehir, Türkiye",
  "Padre Burgos, Calabarzon, Philippines",
  "211392, Orsha, Vitsyebsk, Belarus",
  "413861, Balakovo, Saratov, Russia",
  "230037, Slatina, Olt, Romania",
  "33709, St Petersburg, Florida, United States",
  "658090, Novoaltaysk, Altai Krai, Russia",
  "Rijeka, Primorje-Gorski, Croatia",
  "Loudon, Ruy-Montceau, Auvergne-Rhône-Alpes, France",
  "646902, Kalachinsk, Omsk, Russia",
  "30000, Nîmes, Occitanie, France",
  "Bodh Gaya, Bihar, India",
  "Kercha, Oromia Region, Ethiopia",
  "606414, Balakhna, Nizhniy Novgorod, Russia",
  "04315, Leipzig, Saxony, Germany",
  "Clinton Township, Michigan, United States",
  "Loveland, Colorado, United States",
  "Christian County, Illinois, United States",
  "Mifflin, Pennsylvania, United States",
  "Weston, Missouri, United States",
  "51014, Tartu, Tartumaa, Estonia",
  "76308, Wichita Falls, Texas, United States",
  "67060, Haysville, Kansas, United States",
  "Havsa, Edirne, Türkiye",
  "44040, Gates Mills, Ohio, United States",
  "81220, Cimarron, Colorado, United States",
  "453118, Sterlitamak, Bashkortostan, Russia",
  "Tunica County, Mississippi, United States",
  "53100, Siena, Tuscany, Italy",
  "Novomoskovsk, Tula, Russia",
  "831 30, Östersund, Jämtland County, Sweden",
  "Woods Hole, Massachusetts, United States",
  "4180, Sorø, Region Zealand, Denmark",
  "7109, Bitola, Pelagonia, North Macedonia",
  "Donetsk, Donetsk, Ukraine",
  "Hudson, Colorado, United States",
  "Mineral City, Ohio, United States",
  "Greater Norrköping Metropolitan Area",
  "Askersund, Örebro County, Sweden",
  "Butte County, Idaho, United States",
  "Bennett, Jackson, Michigan, United States",
  "7402, Ersekë, Korçë, Albania",
  "73790, Tours-en-Savoie, Auvergne-Rhône-Alpes, France",
  "Assenede, Flemish Region, Belgium",
  "Waterford, Wisconsin, United States",
  "Walworth County, South Dakota, United States",
  "40125, Bologna, Emilia-Romagna, Italy",
  "San Gil, Santander, Colombia",
  "360021, Nalchik, Kabardino-Balkaria, Russia",
  "Zaragoza, La Libertad, El Salvador",
  "38100, Grenoble, Auvergne-Rhône-Alpes, France",
  "ST17 0AA, Stafford, England, United Kingdom",
  "12006, Castellón de la Plana, Valencian Community, Spain",
  "33800, Tampere, Pirkanmaa, Finland",
  "Saint-Hubert-de-Rivière-du-Loup, Quebec, Canada",
  "Ramsey, New Jersey, United States",
  "53550, Lappeenranta, South Karelia, Finland",
  "East London, South Africa Metropolitan Area",
  "11566, Merrick, New York, United States",
  "Yefremovo-Stepanovka, Rostov, Russia",
  "90121, Palermo, Sicily, Italy",
  "TS3 0AA, Middlesbrough, England, United Kingdom",
  "48157, Münster, North Rhine-Westphalia, Germany",
  "678915, Chagda, Sakha, Russia",
  "Weston-On-Trent, England, United Kingdom",
  "1340087, Urayasu, Chiba, Japan",
  "LV-1050, Riga, Riga, Latvia",
  "Dawson, Georgia, United States",
  "Rotterdam–The Hague metropolitan area, South Holland, Netherlands",
  "Bonne Terre, Missouri, United States",
  "21236, Nottingham, Maryland, United States",
  "Cottonwood County, Minnesota, United States",
  "Pafos, Paphos, Cyprus",
  "Ashland, Virginia, United States",
  "Podolsk, Moscow, Russia",
  "S21 3YQ, Spinkhill, England, United Kingdom",
  "C25B5X6, Makinsk, Akmola Region, Kazakhstan",
  "Greeneville, Tennessee, United States",
  "Edinburgh, South Australia, Australia",
  "Bonner Springs, Kansas, United States",
  "47166, Ramsey, Indiana, United States",
  "410605, Oradea, Bihor, Romania",
  "Robertsonpet, Karnataka, India",
  "352062, Severnyy, Krasnodar, Russia",
  "Kemalpaşa, Artvin, Türkiye",
  "Stadt Kassel, Hesse, Germany",
  "Ruzayevka, Mordovia, Russia",
  "141320, Sergiyev Posad, Moscow, Russia",
  "Tarrant Hinton, England, United Kingdom",
  "Uvarovo, Tambov, Russia",
  "Saline, Ohio, United States",
  "0470013, Otaru, Hokkaido, Japan",
  "Gaydon, England, United Kingdom",
  "Hardin County, Tennessee, United States",
  "Ventspils, Ventspils, Latvia",
  "4023, Plovdiv, Plovdiv, Bulgaria",
  "715100, Darabani, Botoşani, Romania",
  "90508, Haapsalu, Laane, Estonia",
  "30605, Athens, Georgia, United States",
  "308036, Belgorod, Belgorod, Russia",
  "Webster County, West Virginia, United States",
  "Maliq, Korçë, Albania",
  "Buena Vista, Georgia, United States",
  "New Orleans, Louisiana, United States",
  "Cookesfield, County Laois, Ireland",
  "Cherlak, Omsk, Russia",
  "Polevskoy, Sverdlovsk, Russia",
  "9810, Vadsø, Troms og Finnmark, Norway",
  "Kyzylorda Region, Kazakhstan",
  "Selfoss, Southern Region, Iceland",
  "Omskiy, Omsk, Russia",
  "Deerlijk, Flemish Region, Belgium",
  "Forest Hill, Maryland, United States",
  "LV-5421, Daugavpils, Daugavpils, Latvia",
  "Pervomaysk, Mykolayiv, Ukraine",
  "Williamstown, Victoria, Australia",
  "Santander, Colombia",
  "04062, Windham, Maine, United States",
  "680002, Bucaramanga, Santander, Colombia",
  "Montgomery County, Virginia, United States",
  "352125, Tikhoretsk, Krasnodar, Russia",
  "Dickeyville, Baltimore, Maryland, United States",
  "364040, Groznyy, Chechnya, Russia",
  "06100, Porvoo, Uusimaa, Finland",
  "Craig, Nebraska, United States",
  "Manternach, Grevenmacher, Luxembourg",
  "694452, Nogliki, Sakhalin, Russia",
  "Kimumu ward, Uasin Gishu County, Kenya",
  "Frederikshavn Municipality, North Denmark Region, Denmark",
  "1090, Brussels, Brussels Region, Belgium",
  "Afyonkarahisar, Afyon, Türkiye",
  "E9C 1T8, Storeytown, New Brunswick, Canada",
  "East Calais, Vermont, United States",
  "88862-959, Treviso, Santa Catarina, Brazil",
  "Saunders, Richmond, British Columbia, Canada",
  "Blagoveshchensky District, Amur, Russia",
  "Grundy County, Illinois, United States",
  "77655, Sabine Pass, Texas, United States",
  "Greater Diyarbakir",
  "Perm, Perm, Russia",
  "Lenskiy ulus, Sakha, Russia",
  "1095, Middelburg, Mpumalanga, South Africa",
  "Ostrov, Pskov, Russia",
  "32600, Isparta, Isparta, Türkiye",
  "Galliana, Cartignano, Piedmont, Italy",
  "6423, Molde, Møre og Romsdal, Norway",
  "679006, Birobidzhan, Birobijan, Russia",
  "Knivsta, Uppsala County, Sweden",
  "Shabla Municipality, Dobrich, Bulgaria",
  "12010, Amsterdam, New York, United States",
  "Hamilton Hill & Vale, Schenectady, New York, United States",
  "Jimsar County, Xinjiang Uygur, China",
  "Arrasate / Mondragón, Basque Country, Spain",
  "Bangladesh",
  "Catherine-De-Barnes, England, United Kingdom",
  "7819, Namsos, Trøndelag, Norway",
  "C22D8Y0, Atbasar, Akmola Region, Kazakhstan",
  "19339, Embid, Castile-La Mancha, Spain",
  "Novyy Port, Yamalo-Nenets, Russia",
  "066 01, Humenné, Presov, Slovakia",
  "620014, Yekaterinburg, Sverdlovsk, Russia",
  "80011, Aurora, Colorado, United States",
  "453306, Kumertau, Bashkortostan, Russia",
  "27560, Morrisville, North Carolina, United States",
  "V94 P21W, Limerick, County Limerick, Ireland",
  "831 33, Östersund, Jämtland County, Sweden",
  "Picketts Cove, Florida, United States",
  "La Luzerne, Bricquebec-en-Cotentin, Normandy, France",
  "Les Hauts-d'Anjou, Pays de la Loire, France",
  "R6W 4C1, Winkler, Manitoba, Canada",
  "Norrköpings kommun, Östergötland County, Sweden",
  "Koszalin Metropolitan Area",
  "37075, Göttingen, Lower Saxony, Germany",
  "47150, New Albany, Indiana, United States",
  "Kozelets, Chernihiv, Ukraine",
  "Tuscarawas County, Ohio, United States",
  "Le Revest-les-Eaux, Provence-Alpes-Côte d'Azur, France",
  "43941, Dillonvale, Ohio, United States",
  "23185, Williamsburg, Virginia, United States",
  "45700, Kouvola, Kymenlaakso, Finland",
  "Rankin, Illinois, United States",
  "57070, Viborg, South Dakota, United States",
  "Weston, Colorado, United States",
  "624591, Ivdel’, Sverdlovsk, Russia",
  "Burgos, Tamaulipas, Mexico",
  "MD-3907, Cahul, Cahul District, Moldova",
  "Moore, England, United Kingdom",
  "Capesterre-Belle-Eau, Basse-Terre, Guadeloupe",
  "Orleans County, Vermont, United States",
  "10-685, Olsztyn, Warmińsko-mazurskie, Poland",
  "Toronto, Ontario, Canada",
  "Krasnokamsky District, Bashkortostan, Russia",
  "Waterford, Michigan, United States",
  "74115, Tulsa, Oklahoma, United States",
  "Munster, Indiana, United States",
  "45419, Tateposco, Jalisco, Mexico",
  "KW15 1PT, Kirkwall, Scotland, United Kingdom",
  "New Martinsville, West Virginia, United States",
  "Meade Meadows West, Wisconsin, United States",
  "94200, Kemi, Lapland, Finland",
  "Carroll County, Indiana, United States",
  "B6605AYB, Navarro, Buenos Aires Province, Argentina",
  "Baarle-Hertog, North Brabant, Netherlands",
  "Arthurville, New South Wales, Australia",
  "33008, Oviedo, Principality of Asturias, Spain",
  "Sierra County, California, United States",
  "725700, Vatra Dornei, Suceava, Romania",
  "MK18 1EG, Buckingham, England, United Kingdom",
  "682460, Nikolayevsk-na-Amure, Khabarovsk, Russia",
  "Toolern Vale, Victoria, Australia",
  "Sutton Hill, Telford, England, United Kingdom",
  "Satu Mare Metropolitan Area",
  "38658, Pope, Mississippi, United States",
  "Sokółka, Podlaskie, Poland",
  "50000, Kryvyi Rih, Dnipropetrovsk, Ukraine",
  "669451, Kutulik, Ust-Ordyn-Buryat, Russia",
  "920012, Slobozia, Ialomiţa, Romania",
  "Əzizabad, Lankaran-Astara, Azerbaijan",
  "347040, Belaya Kalitva, Rostov, Russia",
  "Merrick, New York, United States",
  "05487, Starksboro, Vermont, United States",
  "Qazakh District, Qazakh-Tovuz, Azerbaijan",
  "C01C3D0, Kokshetau, Akmola Region, Kazakhstan",
  "11580, Miguel Hidalgo, Mexico City, Mexico",
  "59034, Hardin, Montana, United States",
  "Webberville, Michigan, United States",
  "20090, Ajaccio, Corsica, France",
  "Sanbornton, New Hampshire, United States",
  "57332, Emery, South Dakota, United States",
  "Bagdarin, Buryatia, Russia",
  "Como, Mississippi, United States",
  "3920, Porsgrunn/Skien, Vestfold og Telemark, Norway",
  "Nevada, Ohio, United States",
  "Pacific Grove, California, United States",
  "453115, Sterlitamak, Bashkortostan, Russia",
  "Avanos Bucağı, Nevşehir, Türkiye",
  "Golden, British Columbia, Canada",
  "89067, Reggio di Calabria, Calabria, Italy",
  "Slatina, South Moravia, Czechia",
  "Chevy Chase, Maryland, United States",
  "Madison County, Illinois, United States",
  "Moody, Texas, United States",
  "632331, Barabinsk, Novosibirsk, Russia",
  "Door County, Wisconsin, United States",
  "Iron County, Missouri, United States",
  "Clackamas County, Oregon, United States",
  "140411, Kolomna, Moscow, Russia",
  "Nancegollan, England, United Kingdom",
  "Tularosa, New Mexico, United States",
  "Mérida, Merida State, Venezuela",
  "BT28 2RE, Lisburn, Northern Ireland, United Kingdom",
  "142455, Noginsk, Moscow, Russia",
  "Pori sub-region, Satakunta, Finland",
  "Henrico County, Virginia, United States",
  "627300, Golyshmanovo, Tyumen’, Russia",
  "PA15 4EW, Greenock, Scotland, United Kingdom",
  "Sullivan County, Indiana, United States",
  "Taşova, Amasya, Türkiye",
  "Idanha-A-Nova, Castelo Branco, Portugal",
  "140441, Kolomna, Moscow, Russia",
  "Otar-Dubrovskoye, Tatarstan, Russia",
  "Güce, Giresun, Türkiye",
  "Holtgast, Lower Saxony, Germany",
  "Stonewall, Virginia, United States",
  "34000, Kragujevac, Centralna Srbija, Serbia",
  "Solonka, Lviv, Ukraine",
  "Logan County, Kentucky, United States",
  "125 81, Stockholm, Stockholm County, Sweden",
  "10117, Berlin, Berlin, Germany",
  "Ijevan, Tavush Province, Armenia",
  "16504, Erie, Pennsylvania, United States",
  "DL1 2PB, Darlington, England, United Kingdom",
  "89900, Vibo Valentia, Calabria, Italy",
  "66783, Yates Center, Kansas, United States",
  "453214, Ishimbay, Bashkortostan, Russia",
  "Modelu, Călăraşi, Romania",
  "12525-000, Potim, São Paulo, Brazil",
  "79202, Prijedor, Serb Republic, Bosnia and Herzegovina",
  "211414, Polatsk, Vitsyebsk, Belarus",
  "831 43, Östersund, Jämtland County, Sweden",
  "78400, Nadvornaya, Ivano-Frankivsk, Ukraine",
  "6010, Kecskemét, Bács-Kiskun, Hungary",
  "New Bandon, New Brunswick, Canada",
  "Altagracia, Guarico State, Venezuela",
  "Ortahisar, Trabzon, Türkiye",
  "Tripoli, Peloponnese, Greece",
  "Lees Summit, Missouri, United States",
  "86156, Augsburg, Bavaria, Germany",
  "90123, Palermo, Sicily, Italy",
  "Wellsboro, Pennsylvania, United States",
  "Brooke”s Point, Mimaropa, Philippines",
  "Kentau, Turkistan Region, Kazakhstan",
  "Hamlin, Grand Falls, New Brunswick, Canada",
  "Beaver County, Pennsylvania, United States",
  "Buynaksk, Dagestan, Russia",
  "Turaiyur, Tamil Nadu, India",
  "Lea, Preston, England, United Kingdom",
  "Kozarë, Berat, Albania",
  "Brighton, Tasmania, Australia",
  "Greenock, Scotland, United Kingdom",
  "Chattanooga, Tennessee, United States",
  "17500, Pryluky, Chernihiv, Ukraine",
  "Coleman, Alberta, Canada",
  "Vallon-en-Sully, Auvergne-Rhône-Alpes, France",
  "Capital Region, Iceland",
  "Bukit Intan, Bangka-Belitung, Indonesia",
  "Lille, Flemish Region, Belgium",
  "Leipzig, Saxony, Germany",
  "462280, Mednogorsk, Orenburg, Russia",
  "Union Grove, Wisconsin, United States",
  "309163, Gubkin, Belgorod, Russia",
  "E9C 1T2, Storeytown, New Brunswick, Canada",
  "352386, Kropotkin, Krasnodar, Russia",
  "Castelo Branco, Castelo Branco, Portugal",
  "Daviess County, Indiana, United States",
  "Denton County, Texas, United States",
  "Martin Area, Gilmer, North Carolina, United States",
  "Batesville, Texas, United States",
  "38138, Les Côtes-d'Arey, Auvergne-Rhône-Alpes, France",
  "Archer, Ohio, United States",
  "Berdsk, Novosibirsk, Russia",
  "96050, Bamberg, Bavaria, Germany",
  "Stillwater County, Montana, United States",
  "20255, Vatutine, Cherkasy, Ukraine",
  "Greater Brunswick Area",
  "Lewisville, North Carolina, United States",
  "462278, Mednogorsk, Orenburg, Russia",
  "43124, Parma, Emilia-Romagna, Italy",
  "Dolni Dabnik, Pleven, Bulgaria",
  "443004, Samara, Samara, Russia",
  "618362, Kizel, Perm, Russia",
  "E7L 1K8, Divide, New Brunswick, Canada",
  "173008, Velikiy Novgorod, Novgorod, Russia",
  "Antelope Park, Lincoln, Nebraska, United States",
  "20866, Burtonsville, Maryland, United States",
  "Tioga, New York, United States",
  "587 50, Linköping, Östergötland County, Sweden",
  "Raleigh-Durham-Chapel Hill Area",
  "618230, Chusovoy, Perm, Russia",
  "39571, Pass Christian, Mississippi, United States",
  "Burgos, Caraga, Philippines",
  "Sainte-Catherine, Quebec, Canada",
  "R6W 4B1, Winkler, Manitoba, Canada",
  "98100, Kemijärvi, Lapland, Finland",
  "16037, Harmony, Pennsylvania, United States",
  "618455, Berezniki, Perm, Russia",
  "37002, Salamanca, Castilla and Leon, Spain",
  "371 62, Karlskrona, Blekinge County, Sweden",
  "75-900, Koszalin, Zachodniopomorskie, Poland",
  "Saint-Étienne-la-Geneste, Nouvelle-Aquitaine, France",
  "Mezenka, Tyumen’, Russia",
  "Lamar County, Mississippi, United States",
  "Kerrisdale, Victoria, Australia",
  "Utrecht, Netherlands",
  "Belmont, Hereford, England, United Kingdom",
  "Tulun, Irkutsk, Russia",
  "Cartagena De Indias, Bolívar, Colombia",
  "Bestöbe k.ä., Akmola Region, Kazakhstan",
  "Wasatch County, Utah, United States",
  "Alekseyevka, Kaluga, Russia",
  "Thurston, Ohio, United States",
  "Salt Lake City Metropolitan Area",
  "Minnehaha Oaks, Hopkins, Minnesota, United States",
  "Lugagnano Val d’Arda, Emilia-Romagna, Italy",
  "75102, Barry, Texas, United States",
  "13400, Bitlis, Bitlis, Türkiye",
  "Zvoleněves, Central Bohemia, Czechia",
  "Tursunzoda, Districts of Republican Subordination, Tajikistan",
  "210002, Vitsyebsk, Vitsyebsk, Belarus",
  "Kemper County, Mississippi, United States",
  "Leeds and Grenville, Ontario, Canada",
  "18966, Southampton, Pennsylvania, United States",
  "Hanover, Minnesota, United States",
  "85924, Concho, Arizona, United States",
  "Douglas, Isle of Man",
  "678942, Aldan, Sakha, Russia",
  "CM24 8SP, Stansted Mountfitchet, England, United Kingdom",
  "Harper, Texas, United States",
  "429136, Shumerlya, Chuvashia, Russia",
  "184620, Severomorsk, Murmansk, Russia",
  "Gemeente Houten, Utrecht, Netherlands",
  "36730, Salamanca, Guanajuato, Mexico",
  "61830, Trabzon, Trabzon, Türkiye",
  "83611, Cascade, Idaho, United States",
  "Sandoval, Campeche, Mexico",
  "94555, Fremont, California, United States",
  "678720, Khandyga, Sakha, Russia",
  "Les Petits Millards, Saint-Firmin-des-Bois, Centre-Val de Loire, France",
  "Madison County, Alabama, United States",
  "49405, Custer, Michigan, United States",
  "Chickasaw County, Mississippi, United States",
  "Memphis metropolitan area, Mississippi, United States",
  "51200, Nigde Centre, Niğde, Türkiye",
  "Pleumeur-Bodou, Brittany, France",
  "Holmes, New York, United States",
  "Wiset Chai Chan district, Ang Thong, Thailand",
  "M22 9AN, Wythenshawe, England, United Kingdom",
  "Chesterfield, South Carolina, United States",
  "53140, Kenosha, Wisconsin, United States",
  "Windsor, California, United States",
  "Arys, Turkistan Region, Kazakhstan",
  "Setúbal, Setúbal, Portugal",
  "La Rochelle, Nouvelle-Aquitaine, France",
  "PH33 6JF, Fort William, Scotland, United Kingdom",
  "East Providence, Rhode Island, United States",
  "Knox County, Illinois, United States",
  "Powderhorn Park, Minneapolis, Minnesota, United States",
  "Palm Gardens, Galveston, Texas, United States",
  "Crockett, Callington, England, United Kingdom",
  "686430, Evensk, Magadan, Russia",
  "382 22, Vólos, Thessalia, Greece",
  "Cayuga, Ontario, Canada",
  "9428, Walzenhausen, Appenzell Outer-Rhoden, Switzerland",
  "187510, Tikhvin, Leningrad, Russia",
  "164514, Severodvinsk, Arkhangel’sk, Russia",
  "51000, Çiftlik, Niğde, Türkiye",
  "Karnobat Municipality, Burgas, Bulgaria",
  "18119, Rostock, Mecklenburg-West Pomerania, Germany",
  "Caledonia County, Vermont, United States",
  "Bakerganj Upazila, Barisāl, Bangladesh",
  "Woodward Park, Columbus, Ohio, United States",
  "646783, Bologoye, Omsk, Russia",
  "Chickasaw Woods, Florida, United States",
  "07869, Randolph, New Jersey, United States",
  "St Charles, Illinois, United States",
  "Grudziadz Metropolitan Area",
  "658081, Novoaltaysk, Altai Krai, Russia",
  "6753, Szeged, Csongrád, Hungary",
  "Middelburg, Zeeland, Netherlands",
  "Divide County, North Dakota, United States",
  "38001, Santa Cruz de Tenerife, Canary Islands, Spain",
  "3110, Salgótarján, Nógrád, Hungary",
  "Otradnyy, Samara, Russia",
  "Knox County, Nebraska, United States",
  "F63C8F0, Shemonaikha, East Kazakhstan Region, Kazakhstan",
  "08033, Makarov, Kyiv, Ukraine",
  "Libya",
  "L’govo, Ryazan’, Russia",
  "663308, Noril'sk, Taymyr, Russia",
  "Arlesheim, Basel-Country, Switzerland",
  "Presidio de los Reyes, Nayarit, Mexico",
  "Kos, Southern Aegean, Greece",
  "45150, Boumalne Dades, Drâa-Tafilalet, Morocco",
  "Odessa, Ukraine",
  "Ottertail, Minnesota, United States",
  "06007, Badajoz, Extremadura, Spain",
  "BT7 1AB, Belfast, Northern Ireland, United Kingdom",
  "ZE1 0HJ, Lerwick, Scotland, United Kingdom",
  "Stralsund, Mecklenburg-West Pomerania, Germany",
  "60435, Frankfurt, Hesse, Germany",
  "Goyave, Basse-Terre, Guadeloupe",
  "Agri, Ağrı, Türkiye",
  "623375, Polevskoy, Sverdlovsk, Russia",
  "Pacific Junction, Iowa, United States",
  "Garfield County, Nebraska, United States",
  "Esmeralda, Rio Grande do Sul, Brazil",
  "St Fergus, Scotland, United Kingdom",
  "7104, Bitola, Pelagonia, North Macedonia",
  "08816, East Brunswick, New Jersey, United States",
  "McCulloch, College Station, Texas, United States",
  "99740, Sodankylä, Lapland, Finland",
  "Buena Vista City County, Virginia, United States",
  "McLean County, Kentucky, United States",
  "Preston, Idaho, United States",
  "70354, Galliano, Louisiana, United States",
  "EH32 0AA, Cockenzie And Port Seton, Scotland, United Kingdom",
  "48313, Sterling Heights, Michigan, United States",
  "173000, Velikiy Novgorod, Novgorod, Russia",
  "164527, Severodvinsk, Arkhangel’sk, Russia",
  "143433, Krasnogorsk, Moscow, Russia",
  "97885, Wallowa, Oregon, United States",
  "Cartersville, Virginia, United States",
  "Coryell County, Texas, United States",
  "Pendleton, North Carolina, United States",
  "Taylor Station, Jefferson, Ohio, United States",
  "Maloyaroslavets, Kaluga, Russia",
  "403261, Ust’-Buzulukskaya, Tambov, Russia",
  "3050, Drammen, Viken, Norway",
  "Pskovsky District, Pskov, Russia",
  "L02M8T3, Oral, West Kazakhstan Region, Kazakhstan",
  "Hancock County, West Virginia, United States",
  "Tazewell Historic District, Tazewell, Virginia, United States",
  "65084, Versailles, Missouri, United States",
  "Voznesenska miska hromada, Mykolayiv, Ukraine",
  "Chernyshëvo, Ivanovo, Russia",
  "West Yankton, South Dakota, United States",
  "St Nicholas, Wales, United Kingdom",
  "40131, Bologna, Emilia-Romagna, Italy",
  "Limoges, Ontario, Canada",
  "Hof bei Salzburg, Salzburg, Austria",
  "10025, Zhytomyr, Zhytomyr, Ukraine",
  "694061, Dolinsk, Sakhalin, Russia",
  "Akhtyrka, Sumy, Ukraine",
  "Yenice, Karabuk, Türkiye",
  "624933, Karpinsk, Sverdlovsk, Russia",
  "28684, Todd, North Carolina, United States",
  "Geneva, Geneva, Switzerland",
  "222518, Barysaw, Minsk, Belarus",
  "16830, Clearfield, Pennsylvania, United States",
  "27379, Yanceyville, North Carolina, United States",
  "827195, Sfântu Gheorghe, Tulcea, Romania",
  "623971, Tavda, Sverdlovsk, Russia",
  "Volkhovsky District, Leningrad, Russia",
  "R13P5T3, Beyneu, Mangystau Region, Kazakhstan",
  "Lago Agrio Canton, Sucumbíos, Ecuador",
  "44515, Youngstown, Ohio, United States",
  "Zile, Tokat, Türkiye",
  "320168, Reşiţa, Caraş-Severin, Romania",
  "Hookerton, North Carolina, United States",
  "163001, Archangel, Arkhangel’sk, Russia",
  "Lucerne, Lucerne, Switzerland",
  "422545, Zelënodol'sk, Mari El, Russia",
  "15090, Wexford, Pennsylvania, United States",
  "40021, Sumy, Sumy, Ukraine",
  "Saukhet, Kanda, Uttarakhand, India",
  "67140, Guadalupe, Nuevo León, Mexico",
  "Mid Ulster, Northern Ireland, United Kingdom",
  "NR35 2NZ, Broome, England, United Kingdom",
  "51106, Rijeka, Primorje-Gorski, Croatia",
  "Turar Risqulov, Turkistan Region, Kazakhstan",
  "76121, Barletta, Apulia, Italy",
  "70651, Reeves, Louisiana, United States",
  "78500, Karabuk, Karabuk, Türkiye",
  "Humphreys County, Mississippi, United States",
  "EH1 1QB, Edinburgh, Scotland, United Kingdom",
  "Tikhvinskiy, Lipetsk, Russia",
  "90621, Buena Park, California, United States",
  "Wells, New York, United States",
  "Slobodskoye, Voronezh, Russia",
  "26020, Eskişehir, Eskişehir, Türkiye",
  "Kielce, Świętokrzyskie, Poland",
  "97520, Ashland, Oregon, United States",
  "Masqaţ, Oman",
  "BT79 0AA, Omagh, Northern Ireland, United Kingdom",
  "78222, Siauliai, Siauliu, Lithuania",
  "393950, Morshansk, Tambov, Russia",
  "140410, Kolomna, Moscow, Russia",
  "050556, Bucharest, Bucharest, Romania",
  "917180, Modelu, Călăraşi, Romania",
  "17857, Northumberland, Pennsylvania, United States",
  "456390, Miass, Chelyabinsk, Russia",
  "Archangelos, Southern Aegean, Greece",
  "627314, Golyshmanovo, Tyumen’, Russia",
  "Jimbolia, Timiş, Romania",
  "Taoshan District, Heilongjiang, China",
  "871 33, Härnösand, Västernorrland County, Sweden",
  "Stoddard, New Hampshire, United States",
  "NN15 5QB, Burton Latimer, England, United Kingdom",
  "McLennan, Richmond, British Columbia, Canada",
  "Monmouth, Oregon, United States",
  "412906, Vol'sk, Saratov, Russia",
  "R25G8X2, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "2900-099, Setúbal, Setúbal, Portugal",
  "Alexandria, Tennessee, United States",
  "Louisa Creek, New South Wales, Australia",
  "Sully, Penarth, Wales, United Kingdom",
  "53186, Waukesha, Wisconsin, United States",
  "28905, Cabo Frio, Rio de Janeiro, Brazil",
  "79845, Presidio, Texas, United States",
  "Kingfisher Place Estate, Brakpan, Gauteng, South Africa",
  "Cameron, Oklahoma, United States",
  "35014, Las Palmas de Gran Canaria, Canary Islands, Spain",
  "Park Forest, Illinois, United States",
  "Klintsovsky District, Bryansk, Russia",
  "Găişeni, Giurgiu, Romania",
  "Wrightsboro, Texas, United States",
  "Lenoir County, North Carolina, United States",
  "Dade County, Georgia, United States",
  "Città di Castello, Umbria, Italy",
  "E7L 1K9, Divide, New Brunswick, Canada",
  "New Bedford, Massachusetts, United States",
  "Dänew, Lebap Region, Turkmenistan",
  "48005, Kaunas, Kauno, Lithuania",
  "Ranchos de Taos Plaza, Ranchos De Taos, New Mexico, United States",
  "Aurora, Indiana, United States",
  "75702, Tyler, Texas, United States",
  "Crown Point, Indiana, United States",
  "Klyuchi, Orenburg, Russia",
  "IP13 0NQ, Pettistree, England, United Kingdom",
  "15100, Lahti, Päijät-Häme, Finland",
  "McLeod, Quebec, Canada",
  "20160, Denizli, Denizli, Türkiye",
  "Karnobat, Burgas, Bulgaria",
  "353913, Novorossiysk, Krasnodar, Russia",
  "Muromtsevskoye, Vladimir, Russia",
  "Hardin County, Kentucky, United States",
  "Burlington, Massachusetts, United States",
  "33016, Rivne, Rivne, Ukraine",
  "37121, Verona, Veneto, Italy",
  "Doña Mencía, Andalusia, Spain",
  "42283, Wuppertal, North Rhine-Westphalia, Germany",
  "26002, Logroño, Autonomía de La Rioja, Spain",
  "Manassas, Georgia, United States",
  "30205, Cartagena, Región de Murcia, Spain",
  "St Louis, Missouri, United States",
  "65280, Vaasa, Ostrobothnia, Finland",
  "Suceava, Romania",
  "76251, Montague, Texas, United States",
  "94200, Sochiapa, Veracruz, Mexico",
  "3340, Okhahlamba, KwaZulu-Natal, South Africa",
  "18210, Aleksinac, Centralna Srbija, Serbia",
  "Judith Caballero de Nuñoz, Guerrero, Mexico",
  "Gallatin, New York, United States",
  "431440, Ruzayevka, Mordovia, Russia",
  "456574, Yemanzhelinka, Chelyabinsk, Russia",
  "Callahan Heights, Rogers, Arkansas, United States",
  "Horodyshchenska miska hromada, Cherkasy, Ukraine",
  "Sequoyah Woods, Arkansas, United States",
  "02777, Swansea, Massachusetts, United States",
  "346500, Shakhty, Rostov, Russia",
  "22027, Dunn Loring, Virginia, United States",
  "Petrozavodsk, Karelia, Russia",
  "44-105, Gliwice, Śląskie, Poland",
  "E25B9C2, Maqat, Atyrau Region, Kazakhstan",
  "Greenbrier Hills, Maryland, United States",
  "41011, Seville, Andalusia, Spain",
  "Richland, Mississippi, United States",
  "Noumea, South Province, New Caledonia",
  "Kecskeméti Járás, Bács-Kiskun, Hungary",
  "28002, Madrid, Community of Madrid, Spain",
  "Rowangchhari Upazila, Chattogram, Bangladesh",
  "Otoe, Nebraska, United States",
  "22211, Tribunj, Sibenik-Knin, Croatia",
  "DL1 1AA, Darlington, England, United Kingdom",
  "Orange, Provence-Alpes-Côte d'Azur, France",
  "Hameau Toulorge, Barneville-Carteret, Normandy, France",
  "Mons Metropolitan Area",
  "222526, Barysaw, Minsk, Belarus",
  "Coalville, Utah, United States",
  "Emerywood West, High Point, North Carolina, United States",
  "Vinnytsia Raion, Vinnytsya, Ukraine",
  "Augusta, Michigan, United States",
  "Campbell, Texas, United States",
  "Gibsons, British Columbia, Canada",
  "E9C 2B5, New Bandon Northumberland County, New Brunswick, Canada",
  "80-041, Gdańsk, Pomorskie, Poland",
  "6020, Innsbruck, Tyrol, Austria",
  "Walsh County, North Dakota, United States",
  "Centre, Burkina Faso",
  "81541, Munich, Bavaria, Germany",
  "Permatang Pauh, Penang, Malaysia",
  "Kem, Karelia, Russia",
  "Clinton, Indiana, United States",
  "Sevier County, Arkansas, United States",
  "Shelby County, Kentucky, United States",
  "353901, Novorossiysk, Krasnodar, Russia",
  "Decatur, Mississippi, United States",
  "20380, Turku, Southwest Finland, Finland",
  "Engelsholm, Region of Southern Denmark, Denmark",
  "Platte, Missouri, United States",
  "347913, Taganrog, Rostov, Russia",
  "McLeod, North Dakota, United States",
  "Putnam, Oklahoma, United States",
  "SA7 9SA, Swansea, Wales, United Kingdom",
  "Leeuwarden, Friesland, Netherlands",
  "PH33 6RW, Fort William, Scotland, United Kingdom",
  "Saint Michel de l’Attalaye, Artibonite Department, Haiti",
  "71425, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "F39B0K8, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Vel’sk, Arkhangel’sk, Russia",
  "Pazar, Rize, Türkiye",
  "62275, Pocahontas, Illinois, United States",
  "53142, Kenosha, Wisconsin, United States",
  "Ballsh, Fier, Albania",
  "Brookshire, Texas, United States",
  "34127, Kassel, Hesse, Germany",
  "184603, Severomorsk, Murmansk, Russia",
  "Mazyr, Homel, Belarus",
  "Hillsborough County, Florida, United States",
  "Mason, Ohio, United States",
  "Amelia County, Virginia, United States",
  "Megino-Aldan, Sakha, Russia",
  "Armstrong, British Columbia, Canada",
  "Clay County, Florida, United States",
  "62085, Sawyerville, Illinois, United States",
  "Rice, Texas, United States",
  "871 62, Härnösand, Västernorrland County, Sweden",
  "V0N 2P0, Port Hardy, British Columbia, Canada",
  "Swisher, Iowa, United States",
  "27203, Asheboro, North Carolina, United States",
  "628483, Kogalym, Khanty-Mansi, Russia",
  "352508, Labinsk, Krasnodar, Russia",
  "Tioga, West Virginia, United States",
  "Jasper County, Georgia, United States",
  "Degerfors, Örebro County, Sweden",
  "Zlatoustovsk, Amur, Russia",
  "Shariatpur District, Dhaka, Bangladesh",
  "Billingstad, Oslo, Oslo, Norway",
  "4307, Radford, Queensland, Australia",
  "Caroline County, Virginia, United States",
  "09247, Chemnitz, Saxony, Germany",
  "Lesosibirsk, Krasnoyarsk Krai, Russia",
  "182636, Borovichi, Pskov, Russia",
  "Trippstadt, Rhineland-Palatinate, Germany",
  "9009, Tromsø, Troms og Finnmark, Norway",
  "230058, Slatina, Olt, Romania",
  "Canadian, Oklahoma, United States",
  "185 46, Piraeus, Attiki, Greece",
  "Defiance, Iowa, United States",
  "462291, Mednogorsk, Orenburg, Russia",
  "Chickasaw Corners, Florida, United States",
  "St Giles, Lincoln, England, United Kingdom",
  "Chelyuskintsev, Tyumen’, Russia",
  "Ulkan, Irkutsk, Russia",
  "169906, Vorkuta, Komi, Russia",
  "607340, Voznesenskoye, Nizhniy Novgorod, Russia",
  "Bedford, New Hampshire, United States",
  "DY10 1AA, Kidderminster, England, United Kingdom",
  "5835, Knezha, Pleven, Bulgaria",
  "95043, Militello in Val di Catania, Sicily, Italy",
  "Albemarle, York, Pennsylvania, United States",
  "Norton, New Brunswick, Canada",
  "360051, Nalchik, Kabardino-Balkaria, Russia",
  "192210, Dooru, Jammu & Kashmir, India",
  "76010, Arlington, Texas, United States",
  "Roosevelt, Oklahoma, United States",
  "811 01, Bratislava, Bratislava, Slovakia",
  "6582, Bellinzona, Ticino, Switzerland",
  "Arenac County, Michigan, United States",
  "79231, Floydada, Texas, United States",
  "981 41, Kiruna, Norrbotten County, Sweden",
  "Clinton, Tennessee, United States",
  "666401, Ordynskiy, Ust-Ordyn-Buryat, Russia",
  "Tarapoto, San Martín, Peru",
  "81280, Joensuu, North Karelia, Finland",
  "La Giles, Nueva Esparta State, Venezuela",
  "47893, Borgo Maggiore, San Marino",
  "456304, Miass, Chelyabinsk, Russia",
  "La Cour Duchesne, Le Mesnil-Simon, Normandy, France",
  "Beaver County, Oklahoma, United States",
  "Transylvania Beach, Louisville, Kentucky, United States",
  "6721, Szeged, Csongrád, Hungary",
  "7402, Kaposvár, Somogy, Hungary",
  "24210, Subotica, Vojvodina, Serbia",
  "Washington County, Wisconsin, United States",
  "Essendon Fields, Victoria, Australia",
  "238325, Gur'yevsk, Kaliningrad, Russia",
  "Orange County, North Carolina, United States",
  "North Rhine-Westphalia, Germany",
  "6419, Molde, Møre og Romsdal, Norway",
  "Ashaka, Gombe State, Nigeria",
  "Rice, Pennsylvania, United States",
  "542 33, Mariestad, Västra Götaland County, Sweden",
  "Blaine, Tennessee, United States",
  "Millich Phelps, San Jose, California, United States",
  "Buchanan County, Virginia, United States",
  "31201, Macon, Georgia, United States",
  "81101, Podgorica, Montenegro",
  "641878, Shadrinsk, Kurgan, Russia",
  "Seneca Falls, New York, United States",
  "413113, Engels, Saratov, Russia",
  "Coahoma, Texas, United States",
  "353477, Gelendzhik, Krasnodar, Russia",
  "90011, Los Angeles, California, United States",
  "810111, Brăila, Brăila, Romania",
  "Kimball, West Virginia, United States",
  "Chautauqua County, New York, United States",
  "Păuleşti, Satu Mare, Romania",
  "Brooks, California, United States",
  "Pecos Manor, Gilbert, Arizona, United States",
  "Gabon",
  "Ozalj, Karlovac, Croatia",
  "664047, Irkutsk, Irkutsk, Russia",
  "Laurens County, Georgia, United States",
  "Sunset McMinn, South Santa Rosa, California, United States",
  "7106, Bitola, Pelagonia, North Macedonia",
  "Pittsford, New York, United States",
  "66460, Maury, Occitanie, France",
  "78705, Austin, Texas, United States",
  "Mohyliv-Podil’s’kyy, Vinnytsya, Ukraine",
  "Khaskovo, Bulgaria",
  "453845, Sibay, Bashkortostan, Russia",
  "Cheyenne County, Colorado, United States",
  "Nicosia, Sicily, Italy",
  "15820, Santiago de Compostela, Galicia, Spain",
  "Davenport, Iowa, United States",
  "37800, Kastamonu, Kastamonu, Türkiye",
  "06850, Porvoo, Uusimaa, Finland",
  "43960, Emiliano Zapata, Hidalgo, Mexico",
  "19720, New Castle, Delaware, United States",
  "Timiryazev District, North Kazakhstan Region, Kazakhstan",
  "Morrowville, Kansas, United States",
  "Carter County, Kentucky, United States",
  "Howell, Utah, United States",
  "Lamar, Indiana, United States",
  "700028, Iaşi, Iaşi, Romania",
  "347388, Volgodonsk, Rostov, Russia",
  "64068, Liberty, Missouri, United States",
  "60050, McHenry, Illinois, United States",
  "Keyanna, Limerick, County Limerick, Ireland",
  "5230, Odense, Region of Southern Denmark, Denmark",
  "50630, Fredericksburg, Iowa, United States",
  "Forest Lake, Minnesota, United States",
  "Roosevelt, Minnesota, United States",
  "Ordino, Andorra",
  "96279, Weidhausen bei Coburg, Bavaria, Germany",
  "38002, Santa Cruz de Tenerife, Canary Islands, Spain",
  "33972, Lehigh Acres, Florida, United States",
  "Hudiksvall, Gävleborg County, Sweden",
  "Hickmans River, East London, Eastern Cape, South Africa",
  "0774, Oslo, Oslo, Norway",
  "653 49, Karlstad, Värmland County, Sweden",
  "9305, Finnsnes, Troms og Finnmark, Norway",
  "50254, Thayer, Iowa, United States",
  "52721, Bennett, Iowa, United States",
  "627322, Golyshmanovo, Tyumen’, Russia",
  "Meade County, Kansas, United States",
  "Lebanon, Virginia, United States",
  "PA16 0EN, Greenock, Scotland, United Kingdom",
  "Comarca Metropolitana de Almería, Andalusia, Spain",
  "Lancaster County, Pennsylvania, United States",
  "3021 AA, Rotterdam, South Holland, Netherlands",
  "Stafford, New York, United States",
  "Limestone, Pennsylvania, United States",
  "54410, Lappeenranta, South Karelia, Finland",
  "Olmsted-Berea West, Ohio, United States",
  "Craig, Arkansas, United States",
  "Turin, Piedmont, Italy",
  "624237, Nizhnyaya Tura, Sverdlovsk, Russia",
  "Chegdomyn, Khabarovsk, Russia",
  "Râşnov, Braşov, Romania",
  "Hugheston, West Virginia, United States",
  "90518, Altdorf, Bavaria, Germany",
  "Minnesota, United States",
  "Stewart, Ohio, United States",
  "Shkodër, Shkodër, Albania",
  "81800, Grazac, Occitanie, France",
  "689417, Pevek, Chukot, Russia",
  "Chenango County, New York, United States",
  "27870, Roanoke Rapids, North Carolina, United States",
  "Saline, Arkansas, United States",
  "682813, Sovetskaya Gavan’, Khabarovsk, Russia",
  "DT11 9JG, Tarrant Keyneston, England, United Kingdom",
  "Stonehouse, England, United Kingdom",
  "42277, Wuppertal, North Rhine-Westphalia, Germany",
  "65400, Arras-en-Lavedan, Occitanie, France",
  "Cimarron, Rochester, Minnesota, United States",
  "Lake City, Florida, United States",
  "790 23, Falun, Dalarna County, Sweden",
  "Baripada, Odisha, India",
  "Qabalan, West Bank",
  "82514, Fort Washakie, Wyoming, United States",
  "57369, Platte, South Dakota, United States",
  "11700, Bilecik, Bilecik, Türkiye",
  "655603, Sayanogorsk, Khakassia, Russia",
  "5305, Kuçovë, Berat, Albania",
  "713 03, Iráklion, Crete, Greece",
  "Waukesha Town, Wisconsin, United States",
  "347360, Volgodonsk, Rostov, Russia",
  "56870, Siirt, Siirt, Türkiye",
  "47022, Guilford, Indiana, United States",
  "47011, Bennington, Indiana, United States",
  "24139, Erzincan, Erzincan, Türkiye",
  "Taylor County, Kentucky, United States",
  "71007, Port Dickson, Negri Sembilan, Malaysia",
  "Gölpazarı, Bilecik, Türkiye",
  "30512, Blairsville, Georgia, United States",
  "Moineşti, Bacău, Romania",
  "45090, Manisa, Manisa, Türkiye",
  "184676, Polyarnyy, Murmansk, Russia",
  "831 48, Östersund, Jämtland County, Sweden",
  "Chaves, Vila Real, Portugal",
  "E28D2E3, Makhambet, Atyrau Region, Kazakhstan",
  "666904, Bodaybo, Irkutsk, Russia",
  "Beernem, Flemish Region, Belgium",
  "50100, Nevsehir, Nevşehir, Türkiye",
  "97110, Cannon Beach, Oregon, United States",
  "Elkins Park, Pennsylvania, United States",
  "3372 AA, Hardinxveld-Giessendam, South Holland, Netherlands",
  "Aleksandrovsk-Sakhalinskiy Rayon, Sakhalin, Russia",
  "29800, Landerneau, Brittany, France",
  "Gosper County, Nebraska, United States",
  "Mower County, Minnesota, United States",
  "56093, Waseca, Minnesota, United States",
  "976 34, Luleå, Norrbotten County, Sweden",
  "602253, Murom, Vladimir, Russia",
  "Niğde, Türkiye",
  "Otero, Colima, Mexico",
  "Antelope, Webb 138, Saskatchewan, Canada",
  "Benton County, Minnesota, United States",
  "Krasino, Mordovia, Russia",
  "Erathu, Adoor, Kerala, India",
  "Butler, Missouri, United States",
  "New Albany, Indiana, United States",
  "Serrai, Central Macedonia, Greece",
  "Johnson County, Georgia, United States",
  "Douglas County, Georgia, United States",
  "Lucerne Valley, California, United States",
  "Norwich, Ohio, United States",
  "Attalagere, Belur, Karnataka, India",
  "40-040, Katowice, Śląskie, Poland",
  "Wright, Pennsylvania, United States",
  "7461, Kaposvár, Somogy, Hungary",
  "Ust’-Omchug, Magadan, Russia",
  "Valgunde, Jelgava Municipality, Latvia",
  "080086, Giurgiu, Giurgiu, Romania",
  "69003, Lyon, Auvergne-Rhône-Alpes, France",
  "Thomaston, Connecticut, United States",
  "Amsterdam-Zuidoost, North Holland, Netherlands",
  "301666, Novomoskovsk, Tula, Russia",
  "T23 KX09, Cork, County Cork, Ireland",
  "Sovetsk, Tula, Russia",
  "Horodyshche, Cherkasy, Ukraine",
  "Logroño, Autonomía de La Rioja, Spain",
  "Monterrey, Nuevo León, Mexico",
  "21004, Huelva, Andalusia, Spain",
  "M28C0E5, Aqtau, Karaganda Region, Kazakhstan",
  "981 36, Kiruna, Norrbotten County, Sweden",
  "Wichita Falls, Texas, United States",
  "Valpaços, Vila Real, Portugal",
  "Benson, England, United Kingdom",
  "NG8 3GF, Nottingham, England, United Kingdom",
  "162611, Cherepovets, Vologda, Russia",
  "74127, Tulsa, Oklahoma, United States",
  "81-363, Gdynia, Pomorskie, Poland",
  "Roseau, Saint George Parish, Dominica",
  "Winston County, Mississippi, United States",
  "Elko, Georgia, United States",
  "Dallas, Texas, United States",
  "Long Eddy, New York, United States",
  "Modena, Emilia-Romagna, Italy",
  "641871, Shadrinsk, Kurgan, Russia",
  "Neryungrinskiy ulus, Sakha, Russia",
  "347910, Taganrog, Rostov, Russia",
  "Jenangan, East Java, Indonesia",
  "75219, Dallas, Texas, United States",
  "Bukachacha, Zabaykalsky Krai, Russia",
  "Carson, Illinois, United States",
  "Bath County, Virginia, United States",
  "Ayvalık, Balıkesir, Türkiye",
  "Pabianice, Łódzkie, Poland",
  "10129, Turin, Piedmont, Italy",
  "Concho, Junín, Peru",
  "Cooper County, Missouri, United States",
  "Antarctica",
  "Grimesnil, Normandy, France",
  "Ontario, Canada",
  "50501, Fort Dodge, Iowa, United States",
  "S0A 0C1, Wadena, Saskatchewan, Canada",
  "Dinwiddie, Gauteng, South Africa",
  "626020, Nizhnyaya Tavda, Tyumen’, Russia",
  "43068, Reynoldsburg, Ohio, United States",
  "Laramière, Occitanie, France",
  "Fort Collins, Colorado, United States",
  "665382, Zima, Irkutsk, Russia",
  "Vladimir, Gorj, Romania",
  "Rye, England, United Kingdom",
  "Bremen, Germany",
  "Palos Park, Illinois, United States",
  "Oxford, Ohio, United States",
  "Balipur Person, Lalganj, Uttar Pradesh, India",
  "Logroño Canton, Morona Santiago, Ecuador",
  "Montgomery County, Kentucky, United States",
  "634041, Tomsk, Tomsk, Russia",
  "S13K7Y9, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Reynolds, Missouri, United States",
  "Greater Murcia Metropolitan Area",
  "127653, Vadu Pașii, Buzău, Romania",
  "680003, Bucaramanga, Santander, Colombia",
  "Crown Heights, New York, New York, United States",
  "Dobrich, Bulgaria",
  "Dzhanaly, Qazakh-Tovuz, Azerbaijan",
  "Kendall West, Florida, United States",
  "Madison County, Idaho, United States",
  "Juneau, Montfort-le-Gesnois, Pays de la Loire, France",
  "456306, Miass, Chelyabinsk, Russia",
  "70511, Abbeville, Louisiana, United States",
  "414018, Astrakhan, Astrakhan’, Russia",
  "Kâhta, Adıyaman, Türkiye",
  "Cuminget, Saint-Mary-le-Plain, Auvergne-Rhône-Alpes, France",
  "Bristol, Pennsylvania, United States",
  "Champaign County, Ohio, United States",
  "429111, Shumerlya, Chuvashia, Russia",
  "424031, Yoshkar-Ola, Mari El, Russia",
  "456804, Verkhniy Ufaley, Chelyabinsk, Russia",
  "453838, Sibay, Bashkortostan, Russia",
  "Shiawassee County, Michigan, United States",
  "94450, Keminmaa, Lapland, Finland",
  "48888, Stanton, Michigan, United States",
  "Mercer, Pennsylvania, United States",
  "Oryakhovo, Vratsa, Bulgaria",
  "Fairfield, Iowa, United States",
  "Bragadiru, Ilfov, Romania",
  "75962, Nacogdoches, Texas, United States",
  "73061, Morrison, Oklahoma, United States",
  "Lunardelli, Paraná, Brazil",
  "Babruysk District, Mahilyow, Belarus",
  "La Traillonnière, Mérigny, Centre-Val de Loire, France",
  "Cooke Plains, South Australia, Australia",
  "4818, Arendal, Agder, Norway",
  "Krasnyy Bay, Donetsk, Ukraine",
  "39000, Kirklareli, Kırklareli, Türkiye",
  "60004, Arlington Heights, Illinois, United States",
  "27568, Bremerhaven, Bremen, Germany",
  "Franklin County, Kentucky, United States",
  "S0G 0W0, Craven, Saskatchewan, Canada",
  "422981, Chistopol’, Tatarstan, Russia",
  "Pershing Square, Manhattan, New York, United States",
  "Lanet/Umoja ward, Nakuru, Kenya",
  "67572, Ransom, Kansas, United States",
  "Tatarsko-Pishlinskoye, Mordovia, Russia",
  "45-221, Opole, Opolskie, Poland",
  "Ferry County, Washington, United States",
  "Persön, Norrbotten County, Sweden",
  "79011, Lviv, Lviv, Ukraine",
  "98840, Okanogan, Washington, United States",
  "WF3 3AX, Robin Hood, England, United Kingdom",
  "Pendik, Istanbul, Türkiye",
  "Blandburg, Pennsylvania, United States",
  "423584, Nizhnekamsk, Tatarstan, Russia",
  "0470021, Otaru, Hokkaido, Japan",
  "Esch-Sur-Alzette, Luxembourg, Luxembourg",
  "North Las Vegas, Nevada, United States",
  "Grady County, Georgia, United States",
  "Minsk, Belarus",
  "Bashkia Gramsh, Elbasan, Albania",
  "Burton, Michigan, United States",
  "Cedar Springs, Michigan, United States",
  "9407 HM, Assen, Drenthe, Netherlands",
  "Hertfordshire, England, United Kingdom",
  "38054, Fiera di Primiero, Trentino-Alto Adige, Italy",
  "McLennan County, Texas, United States",
  "Camden, Ohio, United States",
  "Ozolnieki, Jelgava Municipality, Latvia",
  "Curry County, Oregon, United States",
  "Kempston, England, United Kingdom",
  "Fremont County, Wyoming, United States",
  "04230, Huércal de Almería, Andalusia, Spain",
  "Taranaki, New Zealand",
  "Richland County, Ohio, United States",
  "Stonewall, West Virginia, United States",
  "665825, Angarsk, Irkutsk, Russia",
  "Velikaya Kamenka, Ivano-Frankivsk, Ukraine",
  "Balıkesir Subregion, Türkiye",
  "83843, Moscow, Idaho, United States",
  "Jackson County, Indiana, United States",
  "33301, Fort Lauderdale, Florida, United States",
  "Cheshire Woods, Ocoee, Florida, United States",
  "Dixon, New Mexico, United States",
  "28335, Dunn, North Carolina, United States",
  "Sargent, Nebraska, United States",
  "Metsamor, Armavir Province, Armenia",
  "Sur, Diyarbakır, Türkiye",
  "Timiryazevskiy, Ul’yanovsk, Russia",
  "King County, Washington, United States",
  "628307, Nefteyugansk, Khanty-Mansi, Russia",
  "36252, Panevėžys, Panevėžio, Lithuania",
  "430032, Saransk, Mordovia, Russia",
  "Harding, Richmond Hill, Ontario, Canada",
  "66963, Randall, Kansas, United States",
  "16121, Genoa, Liguria, Italy",
  "IP1 3RB, Ipswich, England, United Kingdom",
  "Arifiye, Sakarya, Türkiye",
  "9402 XL, Assen, Drenthe, Netherlands",
  "24018, Roanoke, Virginia, United States",
  "Huddinge kommun, Stockholm County, Sweden",
  "Lunamatrona, Sardinia, Italy",
  "Otterfing, Bavaria, Germany",
  "Isle, Nouvelle-Aquitaine, France",
  "88-100, Inowrocław, Kujawsko-pomorskie, Poland",
  "Barito, Central Kalimantan, Indonesia",
  "41042, Florence, Kentucky, United States",
  "McPherson-Pioneer Ranch, California, United States",
  "Fort Pierce, Florida, United States",
  "Bastia, Corsica, France",
  "462360, Novotroitsk, Orenburg, Russia",
  "94063, Redwood City, California, United States",
  "Alğa district, Aqtöbe region, Kazakhstan",
  "9014, Tromsø, Troms og Finnmark, Norway",
  "56073, New Ulm, Minnesota, United States",
  "Claiborne, Maryland, United States",
  "L-8552, Beckerich, Diekirch, Luxembourg",
  "412918, Vol'sk, Saratov, Russia",
  "Cheb, Karlovy Vary, Czechia",
  "39740, Caledonia, Mississippi, United States",
  "95125, Catania, Sicily, Italy",
  "Groß Plasten, Mecklenburg-West Pomerania, Germany",
  "428031, Cheboksary, Chuvashia, Russia",
  "Partizansk, Primorye, Russia",
  "Belišce, Osijek-Baranja, Croatia",
  "Soledad, Atlántico, Colombia",
  "422546, Zelënodol'sk, Mari El, Russia",
  "Simferopol Raion, Crimea, Ukraine",
  "76055, Itasca, Texas, United States",
  "Ordu, Ordu, Türkiye",
  "Vranov nad Topľou, Presov, Slovakia",
  "32087, Sanderson, Florida, United States",
  "Greater Ravenna Metropolitan Area",
  "97314, Saül, Saint-Laurent-du-Maroni, French Guiana",
  "Henryville, Indiana, United States",
  "Starr King, Long Beach, California, United States",
  "Greater Bilbao Metropolitan Area",
  "Haiti",
  "38505, Cookeville, Tennessee, United States",
  "Gaston, North Carolina, United States",
  "Nelson, New Zealand",
  "Vejlen, North Denmark Region, Denmark",
  "City Of Plymouth, England, United Kingdom",
  "Carbon, Indiana, United States",
  "Vireux-Wallerand, Grand Est, France",
  "Traversetolo, Emilia-Romagna, Italy",
  "Khara-Aldan, Sakha, Russia",
  "Hasankeyf, Batman, Türkiye",
  "Renfrewshire, Scotland, United Kingdom",
  "46151, Martinsville, Indiana, United States",
  "Makarovo, Bashkortostan, Russia",
  "Uglegorskiy, Rostov, Russia",
  "360003, Nalchik, Kabardino-Balkaria, Russia",
  "641430, Kurtamysh, Kurgan, Russia",
  "30203, Cartagena, Región de Murcia, Spain",
  "670047, Ulan-Ude, Buryatia, Russia",
  "3303, Gramsh, Elbasan, Albania",
  "Newberry Estates, Appleton, Wisconsin, United States",
  "Nancy-sur-Cluses, Auvergne-Rhône-Alpes, France",
  "Spain",
  "142105, Podolsk, Moscow, Russia",
  "110195, Piteşti, Argeş, Romania",
  "Treviso, Santa Catarina, Brazil",
  "Sweetwater, South Carolina, United States",
  "Chimney Oaks at Lake Travis, Texas, United States",
  "Logansport, Indiana, United States",
  "Danielsville, Georgia, United States",
  "347369, Volgodonsk, Rostov, Russia",
  "Baqubah, Diyala Governorate, Iraq",
  "Lake Zurich, Illinois, United States",
  "8420, Zirc, Veszprém, Hungary",
  "Meadow Brook, Hitchcock, Texas, United States",
  "B55G2F3, Zharkent, Jetisu Region, Kazakhstan",
  "243007, Novozybkov, Bryansk, Russia",
  "Satu Mare, Romania",
  "Kingston, Jamaica",
  "B61B2X6, Sarqan, Jetisu Region, Kazakhstan",
  "E4M 1M7, Coburg, New Brunswick, Canada",
  "45010, Manisa, Manisa, Türkiye",
  "422584, Kanash, Tatarstan, Russia",
  "Nadymskiy okrug, Yamalo-Nenets, Russia",
  "184671, Polyarnyy, Murmansk, Russia",
  "55967, Racine, Minnesota, United States",
  "06006, Badajoz, Extremadura, Spain",
  "57310, Savonlinna, South Savo, Finland",
  "Sibley County, Minnesota, United States",
  "02860, Pawtucket, Rhode Island, United States",
  "E1A 1G8, Dieppe, New Brunswick, Canada",
  "NR3 3XP, Norwich, England, United Kingdom",
  "40004, Sumy, Sumy, Ukraine",
  "Dumfries, Scotland, United Kingdom",
  "Rybinsky District, Yaroslavl’, Russia",
  "RG1 1AF, Reading, England, United Kingdom",
  "72500, Batman, Batman, Türkiye",
  "Stanley, Idaho, United States",
  "220020, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "E9C 1T3, Storeytown, New Brunswick, Canada",
  "Refugio del Sol, Cauca, Colombia",
  "393773, Michurinsk, Tambov, Russia",
  "9621, Hammerfest, Troms og Finnmark, Norway",
  "Luzerne, Michigan, United States",
  "Bashkia Tropojë, Kukës, Albania",
  "4410, Liestal, Basel-Country, Switzerland",
  "8212, Neuhausen, Schaffhausen, Switzerland",
  "660028, Krasnoyarsk, Krasnoyarsk Krai, Russia",
  "3058, Coburg, Victoria, Australia",
  "Summersville, Kentucky, United States",
  "225404, Baranavichy, Brest, Belarus",
  "Anderson Ridge, Albemarle, North Carolina, United States",
  "71355, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "238317, Gur'yevsk, Kaliningrad, Russia",
  "Küçük Tarsus, İçel, Türkiye",
  "04911, Anson, Maine, United States",
  "FY3 0AA, Blackpool, England, United Kingdom",
  "Arthur City, Texas, United States",
  "Greater Yakima Area",
  "Hamar Jabjab District, Banaadir, Somalia",
  "1386, Drammen, Viken, Norway",
  "Galax, Virginia, United States",
  "625000, Tyumen, Tyumen’, Russia",
  "Sherburne, New York, United States",
  "43935, Martins Ferry, Ohio, United States",
  "Saint Helena, Ascension and Tristan da Cunha",
  "61951, Sullivan, Illinois, United States",
  "Craven, New South Wales, Australia",
  "61825, Champaign, Illinois, United States",
  "3117, Tønsberg, Vestfold og Telemark, Norway",
  "Preble Park, Green Bay, Wisconsin, United States",
  "167022, Syktyvkar, Komi, Russia",
  "Berne, Switzerland",
  "Durham, Maine, United States",
  "Sanpete County, Utah, United States",
  "5200, Odense, Region of Southern Denmark, Denmark",
  "Portage, Indiana, United States",
  "220133, Drobeta-Turnu Severin, Mehedinţi, Romania",
  "Cabelle, Vignola, Emilia-Romagna, Italy",
  "Richland Center, Wisconsin, United States",
  "53901, Portage, Wisconsin, United States",
  "Guilford Tech. Community College, High Point, North Carolina, United States",
  "Swift, Sheboygan, Wisconsin, United States",
  "Svobodnyy, Krasnodar, Russia",
  "4335 AA, Middelburg, Zeeland, Netherlands",
  "791 31, Falun, Dalarna County, Sweden",
  "78602, Bastrop, Texas, United States",
  "Saint-Amand-les-Eaux, Hauts-de-France, France",
  "Cork Metropolitan Area",
  "Galaxie Commons, Minnesota, United States",
  "Kyzyl-Jyldyz, Naryn Region, Kyrgyzstan",
  "Clarkefield, Victoria, Australia",
  "Alexander, Illinois, United States",
  "71144, East Sarajevo, Serb Republic, Bosnia and Herzegovina",
  "Spotsylvania, Virginia, United States",
  "1219, Tetovo, Polog, North Macedonia",
  "70374, Stuttgart, Baden-Württemberg, Germany",
  "Liverpool, Nova Scotia, Canada",
  "Belebeyevsky District, Bashkortostan, Russia",
  "Tambovka, Amur, Russia",
  "Polyarnyy, Sakha, Russia",
  "Chambersburg, Illinois, United States",
  "427437, Votkinsk, Udmurtia, Russia",
  "674673, Krasnokamensk, Zabaykalsky Krai, Russia",
  "Kimball, Seaside, California, United States",
  "Dunnington, England, United Kingdom",
  "Kemiri, Banten, Indonesia",
  "South Korea",
  "357502, Pyatigorsk, Stavropol, Russia",
  "Craig-Cefn-Parc, Wales, United Kingdom",
  "31006, Pamplona, Chartered Community of Navarre, Spain",
  "38030, Rovere della Luna, Trentino-Alto Adige, Italy",
  "16900, Hämeenlinna, Kanta-Häme, Finland",
  "82944, Robertson, Wyoming, United States",
  "Sumy Raion, Sumy, Ukraine",
  "Upton, Chester, England, United Kingdom",
  "13002, Marseille, Provence-Alpes-Côte d'Azur, France",
  "Amherst, Virginia, United States",
  "23921, Buckingham, Virginia, United States",
  "31097, Yatesville, Georgia, United States",
  "Lille, Hauts-de-France, France",
  "130086, Târgovişte, Dîmboviţa, Romania",
  "59777, Lille, Hauts-de-France, France",
  "Novoselë, Vlorë, Albania",
  "24290, Weber City, Virginia, United States",
  "6705, Esbjerg, Region of Southern Denmark, Denmark",
  "43613, Toledo, Ohio, United States",
  "Sainte-Julie, Auvergne-Rhône-Alpes, France",
  "368575, Derbent, Dagestan, Russia",
  "15235, Pittsburgh, Pennsylvania, United States",
  "19605, Reading, Pennsylvania, United States",
  "Ust-Kansky District, Altay, Russia",
  "Delvinë, Vlorë, Albania",
  "Bregenz-Bludenz Area",
  "Timashevsk, Krasnodar, Russia",
  "16250, Bursa, Bursa, Türkiye",
  "692051, Lesozavodsk, Primorye, Russia",
  "La Ville Audrain, Cruguel, Brittany, France",
  "61550, Morton, Illinois, United States",
  "Toboliu, Bihor, Romania",
  "Harmony, New York, United States",
  "Olbia, Sardinia, Italy",
  "Nova Zagora Municipality, Sliven, Bulgaria",
  "23100, Elâzığ, Elazığ, Türkiye",
  "North Athens, Attiki, Greece",
  "70500, Kuopio, Northern Savonia, Finland",
  "Williamson County, Texas, United States",
  "111, Reykjavík, Capital Region, Iceland",
  "Zaragoza, Aragon, Spain",
  "636000, Seversk, Tomsk, Russia",
  "Alexander County, North Carolina, United States",
  "Knox County, Indiana, United States",
  "3100, Mezdra, Vratsa, Bulgaria",
  "Bistriţa-Năsăud, Romania",
  "Spokane-Coeur d'Alene Area",
  "Soroca, Moldova",
  "65320, Vaasa, Ostrobothnia, Finland",
  "Terrell Hills, Texas, United States",
  "Greater Graz",
  "Sergiyev Posad, Moscow, Russia",
  "Yalutorovsk, Tyumen’, Russia",
  "6729, Szeged, Csongrád, Hungary",
  "Meigs County, Ohio, United States",
  "658481, Zmeinogorsk, Altai Krai, Russia",
  "Wright, Wyoming, United States",
  "Laurens, Iowa, United States",
  "4623, Lille Skensved, Region Zealand, Denmark",
  "155804, Kineshma, Ivanovo, Russia",
  "Churchill Meadows, Ontario, Canada",
  "841 04, Bratislava, Bratislava, Slovakia",
  "1204, Geneva, Geneva, Switzerland",
  "6724, Szeged, Csongrád, Hungary",
  "Clay County, South Dakota, United States",
  "Vila Real, Vila Real, Portugal",
  "Queens, New Brunswick, Canada",
  "301663, Novomoskovsk, Tula, Russia",
  "11011, Cadiz, Andalusia, Spain",
  "53500, Lappeenranta, South Karelia, Finland",
  "Vinton, Lafayette, Indiana, United States",
  "Nikopol, Pleven, Bulgaria",
  "278, Currituck, North Carolina, United States",
  "72150, Sheridan, Arkansas, United States",
  "Shackelford County, Texas, United States",
  "Phillipsburg, Ohio, United States",
  "Troitskoye, Ingushetia, Russia",
  "Trenton, Tennessee, United States",
  "22407, Fredericksburg, Virginia, United States",
  "5306, Kuçovë, Berat, Albania",
  "Greene County, New York, United States",
  "V42 E292, Limerick, County Limerick, Ireland",
  "Garfield, Washington, United States",
  "Coal City, West Virginia, United States",
  "Brest City Council, Brest, Belarus",
  "Jamestown, Ohio, United States",
  "45790-000, Potiraguá, Bahia, Brazil",
  "C19B8Y0, Zhaltyr, Akmola Region, Kazakhstan",
  "EH32 0HZ, Cockenzie And Port Seton, Scotland, United Kingdom",
  "Wilkinstown, County Wexford, Ireland",
  "Lowndesboro Historic District, Lowndesboro, Alabama, United States",
  "216501, Roslavl’, Smolensk, Russia",
  "Hardin, Montana, United States",
  "346906, Novoshakhtinsk, Rostov, Russia",
  "Lillemer, Brittany, France",
  "Växjö, Kronoberg County, Sweden",
  "15213, Pittsburgh, Pennsylvania, United States",
  "Ereğli, Konya, Türkiye",
  "1011, Lausanne, Vaud, Switzerland",
  "Lake County, Ohio, United States",
  "Oktyabrsky District, Rostov, Russia",
  "Harborwalk, Hitchcock, Texas, United States",
  "94323, Klaipėda, Klaipėdos, Lithuania",
  "34225, Kragujevac, Centralna Srbija, Serbia",
  "E9C 1T7, Storeytown, New Brunswick, Canada",
  "Pisarovina, Zagreb, Croatia",
  "430004, Saransk, Mordovia, Russia",
  "22560, Pleumeur-Bodou, Brittany, France",
  "663317, Noril'sk, Taymyr, Russia",
  "Sliven, Bulgaria",
  "Trento, Caraga, Philippines",
  "169731, Usinsk, Komi, Russia",
  "Stonewall, Mississippi, United States",
  "94303, Palo Alto, California, United States",
  "682971, Bikin, Khabarovsk, Russia",
  "Svolvær, Nordland, Norway",
  "Chaves County, New Mexico, United States",
  "Hutchinson County, Texas, United States",
  "462721, Dombarovskiy, Orenburg, Russia",
  "6875, De Doorns, Western Cape, South Africa",
  "Glarus Süd, Glarus, Switzerland",
  "Blount's Green, Uttoxeter, England, United Kingdom",
  "31500, Toulouse, Occitanie, France",
  "Somerset, Texas, United States",
  "Nizhynska miska hromada, Chernihiv, Ukraine",
  "Aitkin Township, Minnesota, United States",
  "Plumsted, New Jersey, United States",
  "602266, Murom, Vladimir, Russia",
  "Cheyenne, Wyoming, United States",
  "Olyokminsky District, Sakha, Russia",
  "673771, Mogocha, Zabaykalsky Krai, Russia",
  "06087, Perugia, Umbria, Italy",
  "Plymouth, Minnesota, United States",
  "Lewisville, Texas, United States",
  "Ceuta, Spain",
  "Lamar County, Alabama, United States",
  "Abbeville, Mississippi, United States",
  "Eureka, Nevada, United States",
  "700359, Iaşi, Iaşi, Romania",
  "162614, Cherepovets, Vologda, Russia",
  "Fléville-devant-Nancy, Grand Est, France",
  "81-589, Gdynia, Pomorskie, Poland",
  "Niceto Pérez, Guantánamo, Cuba",
  "Rogoznica, Sibenik-Knin, Croatia",
  "54112, Coleman, Wisconsin, United States",
  "Livorno Ferraris, Piedmont, Italy",
  "602256, Murom, Vladimir, Russia",
  "Buy, Kostroma, Russia",
  "107, Reykjavík, Capital Region, Iceland",
  "141540, Solnechnogorsk, Moscow, Russia",
  "302 57, Halmstad, Halland County, Sweden",
  "184683, Polyarnyy, Murmansk, Russia",
  "167021, Syktyvkar, Komi, Russia",
  "Noblejas, Castile-La Mancha, Spain",
  "04022, Fondi, Latium, Italy",
  "Los Tres Ojos, Santo Domingo, Dominican Republic",
  "Paris, Ontario, Canada",
  "550035, Sibiu, Sibiu, Romania",
  "Greater Burgos Metropolitan Area",
  "Didim, Aydın, Türkiye",
  "624604, Alapayevsk, Sverdlovsk, Russia",
  "682818, Sovetskaya Gavan’, Khabarovsk, Russia",
  "74600, Annecy, Auvergne-Rhône-Alpes, France",
  "Hardin County, Iowa, United States",
  "03008, Alicante, Valencian Community, Spain",
  "3401, Librazhd, Elbasan, Albania",
  "Forest Lodge, New South Wales, Australia",
  "Greater Logan Area",
  "95008, Campbell, California, United States",
  "Sibsagar, Assam, India",
  "363753, Mozdok, Severnaya Osetiya-Alaniya, Russia",
  "BT5 4AA, Belfast, Northern Ireland, United Kingdom",
  "165302, Kotlas, Arkhangel’sk, Russia",
  "S13E0T0, Ekibastuz, Pavlodar Region, Kazakhstan",
  "Galaxia, Veracruz, Mexico",
  "646901, Kalachinsk, Omsk, Russia",
  "86157, Augsburg, Bavaria, Germany",
  "Greater Banska Bystrica Area",
  "456228, Zlatoust, Chelyabinsk, Russia",
  "791 45, Falun, Dalarna County, Sweden",
  "624343, Krasnoural’sk, Sverdlovsk, Russia",
  "Lecce ne’ Marsi, Abruzzi, Italy",
  "Kaposvár, Hungary",
  "Salzburg, Salzburg, Austria",
  "35205, Birmingham, Alabama, United States",
  "Ozark, Arkansas, United States",
  "394086, Voronezh, Voronezh, Russia",
  "Otterville, Ontario, Canada",
  "Barton County, Kansas, United States",
  "5965, Nikopol, Pleven, Bulgaria",
  "Tromsø Region",
  "Georgetown, Ontario, Canada",
  "652484, Anzhero-Sudzhensk, Kemerovo, Russia",
  "Dixon, California, United States",
  "Liverpool, New South Wales, Australia",
  "72215, Zenica, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "Stone Ridge, New York, United States",
  "R25H5D1, Fort-Shevchenko, Mangystau Region, Kazakhstan",
  "Rock County, Wisconsin, United States",
  "La Massana, Andorra",
  "Lamouret, Bérenx, Nouvelle-Aquitaine, France",
  "Mihalıççık, Eskişehir, Türkiye",
  "Cascade, Minnesota, United States",
  "Avis, Portalegre, Portugal",
  "618352, Kizel, Perm, Russia",
  "63303, St Charles, Missouri, United States",
  "185 10, Piraeus, Attiki, Greece",
  "403115, Uryupinsk, Volgograd, Russia",
  "663961, Zaozërnyy, Krasnoyarsk Krai, Russia",
  "2066, Lane Cove West, New South Wales, Australia",
  "Payne County, Oklahoma, United States",
  "192221, Dooru, Jammu & Kashmir, India",
  "Cocker Hill, Stalybridge, England, United Kingdom",
  "62081, Rockbridge, Illinois, United States",
  "Yalutorovsky District, Tyumen’, Russia",
  "Miami Gardens, Florida, United States",
  "422520, Zelënodol'sk, Mari El, Russia",
  "Los Gatos, California, United States",
  "8312, Peshkopi, Dibrë, Albania",
  "44900, Guadalajara, Jalisco, Mexico",
  "Kirovskiy, Primorye, Russia",
  "Rockingham South, Halifax, Nova Scotia, Canada",
  "32222, Čačak, Centralna Srbija, Serbia",
  "10147, Turin, Piedmont, Italy",
  "F39A1G3, Zaysan, East Kazakhstan Region, Kazakhstan",
  "Lavry, Pskov, Russia",
  "Gibson Island, Maryland, United States",
  "31350, Saint-Loup-en-Comminges, Occitanie, France",
  "Karachay-Cherkessia, Russia",
  "Navarro, San Martín, Peru",
  "Svilengrad, Khaskovo, Bulgaria",
  "623119, Pervoural'sk, Sverdlovsk, Russia",
  "Dunnville, Ontario, Canada",
  "184653, Polyarnyy, Murmansk, Russia",
  "671561, Taksimo, Buryatia, Russia",
  "Titusville Heights, Titusville, Florida, United States",
  "Pinski Rayon, Brest, Belarus",
  "Kastrat, Shkodër, Albania",
  "Greater Jönköping Metropolitan Area",
  "Davis, South Dakota, United States",
  "PA15 3JS, Greenock, Scotland, United Kingdom",
  "12222, Albany, New York, United States",
  "99690, Sodankylä, Lapland, Finland",
  "658423, Gornyak, Altai Krai, Russia",
  "West Hamlin, West Virginia, United States",
  "Taraz Q.Ä., Jambyl Region, Kazakhstan",
  "624990, Serov, Sverdlovsk, Russia",
  "Marion, Iowa, United States",
  "South Lake, Sheboygan, Wisconsin, United States",
  "7800-503, Beja, Beja, Portugal",
  "X0E 0P0, Fort Smith, Northwest Territories, Canada",
  "Roman, Vratsa, Bulgaria",
  "42281, Wuppertal, North Rhine-Westphalia, Germany",
  "Trenton, Ontario, Canada",
  "662252, Uzhur, Krasnoyarsk Krai, Russia",
  "Barnes County, North Dakota, United States",
  "Dewey, Arizona, United States",
  "Batesville, Indiana, United States",
  "Carrefour Boudet, Barneville-Carteret, Normandy, France",
  "Shubra El Kheima, Al Qalyubiyah, Egypt",
  "Novosibirsk Metropolitan Area",
  "Kamianske, Dnipropetrovsk, Ukraine",
  "656906, Barnaul, Altai Krai, Russia",
  "75207, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "F42G2D3, Serebryansk, East Kazakhstan Region, Kazakhstan",
  "Rochester-Austin, Minnesota Area",
  "23243, Zrenjanin, Vojvodina, Serbia",
  "Comala, Colima, Mexico",
  "PA5 8ST, Johnstone, Scotland, United Kingdom",
  "368577, Derbent, Dagestan, Russia",
  "Lexington–Fayette metropolitan area, Kentucky, United States",
  "Cedar Park, Texas, United States",
  "Amelia, Ohio, United States",
  "Western Province, Sri Lanka",
  "DeWitt, Michigan, United States",
  "70620, Kuopio, Northern Savonia, Finland",
  "Alexander, North Carolina, United States",
  "Serpa, Beja, Portugal",
  "04750, Limestone, Maine, United States",
  "Grahamsville, New York, United States",
  "4844, Arendal, Agder, Norway",
  "Greater Aveiro Area",
  "Lexington, Nebraska, United States",
  "Klingerstown, Pennsylvania, United States",
  "Lehigh, Kansas, United States",
  "9815, Vadsø, Troms og Finnmark, Norway",
  "3430, Clarkefield, Victoria, Australia",
  "Yamhill, Oregon, United States",
  "603 75, Norrköping, Östergötland County, Sweden",
  "Barnesville, Georgia, United States",
  "14048, Montegrosso d’Asti, Piedmont, Italy",
  "34949, Fort Pierce, Florida, United States",
  "Khotin, Chernivtsi, Ukraine",
  "Kulunda, Altai Krai, Russia",
  "Kapanewon Girisubo, Yogyakarta, Indonesia",
  "Kampung Yakikma, Elelim, Papua, Indonesia",
  "K0A 2E0, Kars, Ontario, Canada",
  "PA15 4RT, Greenock, Scotland, United Kingdom",
  "Trinity, Texas, United States",
  "Maizières-lès-Metz, Grand Est, France",
  "9900, Horta, Portugal",
  "28050, Madrid, Community of Madrid, Spain",
  "28164, Stanley, North Carolina, United States",
  "Egvekinot, Chukot, Russia",
  "Delaware, United States",
  "08301, Mataró, Catalonia, Spain",
  "37077, Göttingen, Lower Saxony, Germany",
  "Alekseyevka, Omsk, Russia",
  "1039, Budapest, Budapest, Hungary",
  "Eastland, Columbus, Ohio, United States",
  "Paulding, Ohio, United States",
  "Ukhta, Komi, Russia",
  "16743, Port Allegany, Pennsylvania, United States",
  "3372 BM, Hardinxveld-Giessendam, South Holland, Netherlands",
  "Boulder, Colorado, United States",
  "Kent, Ohio, United States",
  "North Perth, Western Australia, Australia",
  "McDowell, Kentucky, United States",
  "21339, Lunenburg, Lower Saxony, Germany",
  "Klamath County, Oregon, United States",
  "Gagarin, Smolensk, Russia",
  "Traillonne, Sauveterre, Occitanie, France",
  "Aberdeen Proving Ground, Maryland, United States",
  "48902, Autlán de Navarro, Jalisco, Mexico",
  "Simpson, Milton Keynes, England, United Kingdom",
  "60300, Tokat, Tokat, Türkiye",
  "Lacaussade, Nouvelle-Aquitaine, France",
  "DT11 8JN, Tarrant Gunville, England, United Kingdom",
  "Glazovskiy Rayon, Udmurtia, Russia",
  "309191, Gubkin, Belgorod, Russia",
  "BH10 5AU, Bournemouth, England, United Kingdom",
  "Coosawhatchie, South Carolina, United States",
  "1222, Tetovo, Polog, North Macedonia",
  "Nelidovsky District, Tver’, Russia",
  "Swift Current 137, Saskatchewan, Canada",
  "35-010, Rzeszów, Podkarpackie, Poland",
  "58103, Fargo, North Dakota, United States",
  "Hughes County, South Dakota, United States",
  "Donegal Township, Pennsylvania, United States",
  "La Massana, La Massana, Andorra",
  "364021, Groznyy, Chechnya, Russia",
  "1134, Budapest, Budapest, Hungary",
  "624936, Karpinsk, Sverdlovsk, Russia",
  "B6L 5J5, Lower Onslow, Nova Scotia, Canada",
  "Orleans, Santa Catarina, Brazil",
  "Bradley, Bilston, England, United Kingdom",
  "N0E 2A0, Windham Centre, Ontario, Canada",
  "69002, Lyon, Auvergne-Rhône-Alpes, France",
  "43270, Velika Pisanica, Bjelovar-Bilogora, Croatia",
  "Williamsburg, New Mexico, United States",
  "Feldkirchen bei Graz, Styria, Austria",
  "Karlskoga, Örebro County, Sweden",
  "Tyumen Metropolitan Area",
  "Sussex Inlet, New South Wales, Australia",
  "720001, Suceava, Suceava, Romania",
  "78604, Gonzales, Texas, United States",
  "871 30, Härnösand, Västernorrland County, Sweden",
  "Novomoskovsk, Dnipropetrovsk, Ukraine",
  "4031, Liège, Walloon Region, Belgium",
  "Northern Mariana Islands",
  "Randolph, New Jersey, United States",
  "78212, Banja Luka, Serb Republic, Bosnia and Herzegovina",
  "Patrasayer, West Bengal, India",
  "Martinsville, New Jersey, United States",
  "Essex County, Massachusetts, United States",
  "6964, Lugano, Ticino, Switzerland",
  "Montalegre, Vila Real, Portugal",
  "Medina, Cundinamarca, Colombia",
  "Albemarle County, Virginia, United States",
  "M28C5K0, Aqtau, Karaganda Region, Kazakhstan",
  "Sverdlovsk, Luhansk, Ukraine",
  "Antelope, Oregon, United States",
  "5012, Bergen, Vestland, Norway",
  "Clarkesville, Georgia, United States",
  "Fort Thomas, Kentucky, United States",
  "60416, Coal City, Illinois, United States",
  "Philippines",
  "455025, Magnitogorsk, Chelyabinsk, Russia",
  "Stanley, Scotland, United Kingdom",
  "Devant les Rains, Gerbépal, Grand Est, France",
  "Whitman Park, Camden, New Jersey, United States",
  "Moore, Idaho, United States",
  "50857, Nodaway, Iowa, United States",
  "Sin-le-Noble, Hauts-de-France, France",
  "Forsyth County, Georgia, United States",
  "Kapani, Brest, Belarus",
  "PE8 5PX, Barnwell, England, United Kingdom",
  "40221, Düsseldorf, North Rhine-Westphalia, Germany",
  "Meshchovsky District, Kaluga, Russia",
  "Varna, Chelyabinsk, Russia",
  "Andrews, South Carolina, United States",
  "16300, Bursa, Bursa, Türkiye",
  "Brazos Heights, Colón, Panama",
  "77453, Wharton, Texas, United States",
  "Caroline, Wisconsin, United States",
  "Pettisville, Ohio, United States",
  "Saratoga, Indiana, United States",
  "12132, Nassau, New York, United States",
  "160024, Ogarkovo, Kostroma, Russia",
  "Tillman, Palm Bay, Florida, United States",
  "Garfield, Arkansas, United States",
  "Alta, California, United States",
  "Walsh, Illinois, United States",
  "Dresden, Saxony, Germany",
  "Nigde, Niğde, Türkiye",
  "Bejaad, Béni Mellal-Khénifra, Morocco",
  "Ortaköy, Rize, Türkiye",
  "Phillips County, Montana, United States",
  "Greater Guadalajara, Spain Area",
  "12487, Ulster Park, New York, United States",
  "Haskovo, Khaskovo, Bulgaria",
  "67864, Meade, Kansas, United States",
  "Greater Balikesir",
  "Bahşılı, Kırıkkale, Türkiye",
  "Giessenburg, South Holland, Netherlands",
  "02068, Kyiv, Kyiv City, Ukraine",
  "Muskogee County, Oklahoma, United States",
  "9700, Angra do Heroísmo, Portugal",
  "27832, Gaston, North Carolina, United States",
  "Goldens Bridge, New York, United States",
  "Greater Ordu",
  "670045, Ulan-Ude, Buryatia, Russia",
  "50239, Embid de Ariza, Aragon, Spain",
  "629832, Gubkinskiy, Yamalo-Nenets, Russia",
  "Overton, Nova Scotia, Canada",
  "02355, Carver, Massachusetts, United States",
  "357503, Pyatigorsk, Stavropol, Russia",
  "Columbia County, Florida, United States",
  "SG14 2HY, Hertford, England, United Kingdom",
  "3100 Kenosha, Tulsa, Oklahoma, United States",
  "23704, Portsmouth, Virginia, United States",
  "Crowntown, England, United Kingdom",
  "Bristol City County, Virginia, United States",
  "48400, Muğla, Muğla, Türkiye",
  "66129, Saarbrücken, Saarland, Germany",
  "354031, Sochi, Krasnodar, Russia",
  "399877, Topki, Lipetsk, Russia",
  "97305, Mérida, Yucatán, Mexico",
  "618355, Kizel, Perm, Russia",
  "21004, Narva, Ida-Virumaa, Estonia",
  "Nuevo León, Mexico",
  "Alanya Bucağı, Antalya, Türkiye",
  "Acre, Brazil",
  "665726, Bratsk, Irkutsk, Russia",
  "Amelia, Nebraska, United States",
  "162602, Cherepovets, Vologda, Russia",
  "55130, Mainz, Rhineland-Palatinate, Germany",
  "95124, Catania, Sicily, Italy",
  "J0X 0C3, Davidson, Quebec, Canada",
  "Amherstburg, Ontario, Canada",
  "90800, Oulu, North Ostrobothnia, Finland",
  "51103, Sioux City, Iowa, United States",
  "Lamarche-sur-Saône, Bourgogne-Franche-Comté, France",
  "73639, Custer City, Oklahoma, United States",
  "Verkh-Kargatskiy, Novosibirsk, Russia",
  "Sunny Slope, Edina, Minnesota, United States",
  "80-283, Gdańsk, Pomorskie, Poland",
  "347930, Taganrog, Rostov, Russia",
  "Pecos Park, North Las Vegas, Nevada, United States",
  "Rockbridge, Illinois, United States",
  "220015, Minsk, Minsk, Belarus",
  "C25C4K3, Makinsk, Akmola Region, Kazakhstan",
  "352747, Timashevsk, Krasnodar, Russia",
  "87714, Cimarron, New Mexico, United States",
  "Partido de Arrecifes, Buenos Aires Province, Argentina",
  "Pechora rayon, Komi, Russia",
  "Colonial Square, Gallatin, Missouri, United States",
  "Giles, Arkansas, United States",
  "Bolívar, Miranda State, Venezuela",
  "42202, Adairville, Kentucky, United States",
  "666686, Ust'-Ilimsk, Irkutsk, Russia",
  "403344, Mikhaylovka Urban Okrug, Volgograd, Russia",
  "05151, Perkinsville, Vermont, United States",
  "356411, Blagodarnyy, Stavropol, Russia",
  "Pershing-King, York, Pennsylvania, United States",
  "624603, Alapayevsk, Sverdlovsk, Russia",
  "Alleghany, Blacksburg, Virginia, United States",
  "Estonia",
  "Western Cape, South Africa",
  "358014, Elista, Kalmykia, Russia",
  "320084, Reşiţa, Caraş-Severin, Romania",
  "Severodvinsk, Arkhangel’sk, Russia",
  "4503, Lezhë, Lezhë, Albania",
  "Zell Am See, Salzburg, Austria",
  "49076, Osnabrück, Lower Saxony, Germany",
  "301371, Aleksin, Kaluga, Russia",
  "Lu'nan District, Hebei, China",
  "Medina, Tennessee, United States",
  "B78 2PE, Kingsbury, England, United Kingdom",
  "04177, Leipzig, Saxony, Germany",
  "75-430, Koszalin, Zachodniopomorskie, Poland",
  "Stewart, Minnesota, United States",
  "79226, Clarendon, Texas, United States",
  "TA3 6HX, Stoke St Gregory, England, United Kingdom",
  "Gilmerton, Scotland, United Kingdom",
  "7103, Bitola, Pelagonia, North Macedonia",
  "88320, Pärnu, Parnu, Estonia",
  "64457, Guilford, Missouri, United States",
  "Taylor, Arkansas, United States",
  "79762, Odessa, Texas, United States",
  "Haskell County, Texas, United States",
  "362045, Vladikavkaz, Severnaya Osetiya-Alaniya, Russia",
  "185505, Shuya, Karelia, Russia",
  "AB55 6QX, Keith, Scotland, United Kingdom",
  "Sabrier, Laramière, Occitanie, France",
  "404120, Volzhskiy, Volgograd, Russia",
  "663920, Uyar, Krasnoyarsk Krai, Russia",
  "Dubăsari District, Moldova",
  "Hamilton, Ontario, Canada",
  "Chortkiv Raion, Ternopil, Ukraine",
  "Orshanka, Mari El, Russia",
  "Upper Black Eddy, Pennsylvania, United States",
  "29501, Florence, South Carolina, United States",
  "Live Oak, Florida, United States",
  "TS7 8HG, Middlesbrough, England, United Kingdom",
  "Ashton Place, Gentry, Arkansas, United States",
  "Jimei District, Fujian, China",
  "Cheremkhovskoye, Irkutsk, Russia",
  "Hughes, Alaska, United States",
  "243009, Novozybkov, Bryansk, Russia",
  "Gusinoozërsk, Buryatia, Russia",
  "Sankt Gallen, Styria, Austria",
  "Eskişehir, Türkiye",
  "Brissac Loire Aubance, Pays de la Loire, France",
  "Cleveland County, North Carolina, United States",
  "51001, Ceuta, Ceuta, Spain",
  "Sonbhadra, Robertsganj, Uttar Pradesh, India",
  "50008, Zaragoza, Aragon, Spain",
  "Guarda-Mor, Minas Gerais, Brazil",
  "Huron, South Dakota, United States",
  "Juiz de Fora, Minas Gerais, Brazil",
  "Prairie, Mississippi, United States",
  "Adams, Nebraska, United States",
  "37771, Lenoir City, Tennessee, United States",
  "77808, Bryan, Texas, United States",
  "Buzău, Romania",
  "9200, Aalborg, North Denmark Region, Denmark",
  "Goychay District, Central Aran, Azerbaijan",
  "Viseu, Pará, Brazil",
  "Orlynivska silska rada, Sevastopol, Ukraine",
  "60-682, Poznan, Wielkopolskie, Poland",
  "30083, Stone Mountain, Georgia, United States",
  "665458, Usol'ye-Sibirskoye, Irkutsk, Russia",
  "Trippelsdorf, Bornheim, North Rhine-Westphalia, Germany",
  "Peterborough, Ontario, Canada",
  "629806, Noyabrsk, Yamalo-Nenets, Russia",
  "82646, Zavala, Sinaloa, Mexico",
  "611 45, Nyköping, Södermanland County, Sweden",
  "61-005, Poznan, Wielkopolskie, Poland",
  "461048, Buzuluk, Orenburg, Russia",
  "Lincoln, Buenos Aires Province, Argentina",
  "681 31, Alexandroúpolis, Eastern Macedonia and Thrace, Greece",
  "677000, Yakutsk, Sakha, Russia",
  "Kochubeyevskoye, Stavropol, Russia",
  "152914, Rybinsk, Yaroslavl’, Russia",
  "677007, Yakutsk, Sakha, Russia",
  "241 33, Kalamata, Peloponnese, Greece",
  "302 61, Halmstad, Halland County, Sweden",
  "McKinley, Albuquerque, New Mexico, United States",
  "Constanta Metropolitan Area",
  "70728, Gonzales, Louisiana, United States",
  "Messina, Sicily, Italy",
  "Korsakovskoye, Khabarovsk, Russia",
  "400257, Cluj-Napoca, Cluj, Romania",
  "Minsk Province, Belarus",
  "461635, Buguruslan, Orenburg, Russia",
  "Livermore, California, United States",
  "2525 AA, The Hague, South Holland, Netherlands",
  "Neuhausen, Schaffhausen, Switzerland",
  "Faulkland Woods, Delaware, United States",
  "66133, Saarbrücken, Saarland, Germany",
  "Oroqen Autonomous Banner, Inner Mongolia, China",
  "ZE1 0LL, Lerwick, Scotland, United Kingdom",
  "Robertson, Winnipeg, Manitoba, Canada",
  "St Petersburg, St Petersburg City, Russia",
  "Çelikhan, Adıyaman, Türkiye",
  "Dallas, Pennsylvania, United States",
  "Orellana, Ecuador",
  "53184, Walworth, Wisconsin, United States",
  "Bathgate, Scotland, United Kingdom",
  "730036, Vaslui, Vaslui, Romania",
  "36020, Coosada, Alabama, United States",
  "Somerset, Wisconsin, United States",
  "43300, Seri Kembangan, Malaysia",
  "75000, Tuzla, Federation of Bosnia and Herzegovina, Bosnia and Herzegovina",
  "416503, Akhtubinsk, Astrakhan’, Russia",
  "Carbon County, Wyoming, United States",
  "15013, Zeyarthiri, Nay Pyi Taw Union Territory, Myanmar",
  "28924-190, Cabo Frio, Rio de Janeiro, Brazil",
  "Starkenburg, Rhineland-Palatinate, Germany",
  "Knox County, Ohio, United States",
  "Hopewell Cape, New Brunswick, Canada",
  "Allegheny Center, Pittsburgh, Pennsylvania, United States",
  "70737, Gonzales, Louisiana, United States",
  "4027, Debrecen, Hajdú-Bihar, Hungary",
  "ZE1 0LQ, Lerwick, Scotland, United Kingdom",
  "13355, Hubbardsville, New York, United States",
  "Lincoln County, Missouri, United States",
  "Mineral Point, Missouri, United States",
  "CF32 7SR, Price Town, Wales, United Kingdom",
  "678315, Chagda, Sakha, Russia",
  "7822, Namsos, Trøndelag, Norway",
  "450105, Ufa, Bashkortostan, Russia",
  "34141, Trieste, Friuli-Venezia Giulia, Italy",
  "Victoria, Entre Ríos Province, Argentina",
  "341 31, Chalkida, Central Greece, Greece",
  "V92 CX78, Tralee, County Kerry, Ireland",
  "Beaufort, North Carolina, United States",
  "931 01, Šamorín, Trnava Region, Slovakia",
  "Kavalerovo, Primorye, Russia",
  "06280, Windham, Connecticut, United States",
  "5043, Veliko Tŭrnovo, Veliko Turnovo, Bulgaria",
  "690021, Vladivostok, Primorye, Russia",
  "Wilson, New York, United States",
  "2670, Bobovdol, Kyustendil, Bulgaria",
  "Saunders Island Settlement, Falkland Islands",
  "Porto, Porto, Portugal",
  "Burke County, North Carolina, United States",
  "White House, Tennessee, United States",
  "Debrecen, Hajdú-Bihar, Hungary",
  "McLennan Crossing, Texas, United States",
  "Vienna, Georgia, United States",
  "Glacier, Ollomont, Valle d'Aosta, Italy",
  "District of Pristina, Kosovo",
  "68414, Nemaha, Nebraska, United States",
  "Tours, Centre-Val de Loire, France",
  "Tura, Evenki, Russia",
  "Elko New Market, Minnesota, United States",
  "Forest Hills, New York, New York, United States",
  "Westchester, Springfield, Illinois, United States",
  "N0A 1E0, Cayuga, Ontario, Canada",
  "627018, Yalutorovsk, Tyumen’, Russia",
  "Voznesenskoye, Chelyabinsk, Russia",
  "627310, Golyshmanovo, Tyumen’, Russia",
  "75153, Powell, Texas, United States",
  "Newmarket, England, United Kingdom",
  "Trinity Center, California, United States",
  "303851, Livny, Orel, Russia",
  "Toolenburg-Oost, Hoofddorp, North Holland, Netherlands",
  "Encamp, Encamp, Andorra",
  "352902, Armavir, Krasnodar, Russia",
  "56100, Lorient, Brittany, France",
  "East Berkshire, Vermont, United States",
  "Hardinsburg, Indiana, United States",
  "682817, Sovetskaya Gavan’, Khabarovsk, Russia",
  "Baylor-Meadows, Dallas, Texas, United States",
  "Salgótarjáni járás, Nógrád, Hungary",
  "Avery Island, County Galway, Ireland",
  "172511, Nelidovo, Tver’, Russia",
  "6217 XE, Maastricht, Limburg, Netherlands",
  "Utrecht, KwaZulu-Natal, South Africa",
  "Wayne County, West Virginia, United States",
  "Sivasagar, Assam, India",
  "426067, Izhevsk, Udmurtia, Russia",
  "3072, Preston, Victoria, Australia",
  "Angelina Mauro, Spezzano Albanese, Calabria, Italy",
  "88-113, Inowrocław, Kujawsko-pomorskie, Poland",
  "Gage, Saint-Mont, Occitanie, France",
  "Sheboygan County, Wisconsin, United States",
  "Yeniseyskoye, Altai Krai, Russia",
  "Burleigh Heads, Queensland, Australia",
  "Alekšince, Nitra Region, Slovakia",
  "Bursa Subregion, Türkiye",
  "Furnas, Cambui, Minas Gerais, Brazil",
  "Paynesville, Minnesota, United States",
  "Kisela Voda, Skopje Statistical Region, North Macedonia",
  "75202, Dallas, Texas, United States",
  "Bath, North Carolina, United States",
  "31025, Kohtla-Järve, Ida-Virumaa, Estonia",
  "6824 MP, Arnhem, Gelderland, Netherlands",
  "27500, Svetlovodsk, Kirovohrad, Ukraine",
  "Sanborn, Iowa, United States",
  "5760, Saalfelden, Salzburg, Austria",
  "Vidzy, Vitsyebsk, Belarus",
  "Neryungrinsky District, Sakha, Russia",
  "Bonny, Rivers State, Nigeria",
  "Hotolisht, Elbasan, Albania",
  "9430, Harstad, Troms og Finnmark, Norway",
  "43121, Parma, Emilia-Romagna, Italy",
  "A92 K6CY, Drogheda, Ireland",
  "Dallas–Fort Worth metroplex, Texas, United States",
  "Gediz Bucağı, Kütahya, Türkiye",
  "Noxubee County, Mississippi, United States",
  "27102, Winston-Salem, North Carolina, United States",
  "302 47, Halmstad, Halland County, Sweden",
  "Churriana de la Vega, Andalusia, Spain",
  "68434, Seward, Nebraska, United States",
  "Kavarna, Dobrich, Bulgaria",
  "676471, Svobodnyy, Amur, Russia",
  "384 45, Vólos, Thessalia, Greece",
  "Sincik, Adıyaman, Türkiye",
  "02-676, Warsaw, Mazowieckie, Poland",
  "Berezniki, Vladimir, Russia",
  "Chickasawba, Arkansas, United States",
  "Zaozërnyy, Krasnoyarsk Krai, Russia",
  "07006, Caldwell, New Jersey, United States",
  "54162, Pulaski, Wisconsin, United States",
  "DL2 2LZ, Walworth, England, United Kingdom",
  "6904, Bregenz, Vorarlberg, Austria",
  "Greater Poitiers Area",
  "X12X8F8, Shymkent, Shymkent, Kazakhstan",
  "Gillespie County, Texas, United States",
  "Campbellton, New Brunswick, Canada",
  "9017, Lushnjë, Fier, Albania",
  "647235, Dudinka, Krasnoyarsk Krai, Russia",
  "Guilford, Maine, United States",
  "McKinley, Kenosha, Wisconsin, United States",
  "Carteret, Quintenic, Brittany, France",
  "12068, Fonda, New York, United States",
  "Butterworth, Butterworth, Eastern Cape, South Africa",
  "346413, Novocherkassk, Rostov, Russia",
  "9024, Klagenfurt, Carinthia, Austria",
  "Kursk, Russia",
  "Lake Norman of Iredell, North Carolina, United States",
  "Walsh, Colorado, United States",
  "Hoke County, North Carolina, United States",
  "Pecos, New Mexico, United States",
  "Hamburg, Pennsylvania, United States",
  "Dewey, Indiana, United States",
  "East Palatka, Florida, United States",
  "Karsiyaka, İzmir, Türkiye",
  "37601, Johnson City, Tennessee, United States",
  "05701, Rutland, Vermont, United States",
  "Steele, West Virginia, United States",
  "08001, Barcelona, Catalonia, Spain",
  "S5 6GD, Sheffield, England, United Kingdom",
  "E28D7E1, Makhambet, Atyrau Region, Kazakhstan",
  "38901, Grenada, Mississippi, United States",
  "Coleman, Prince Edward Island, Canada",
  "Kaunata, Rezekne, Latvia",
  "Mason, Illinois, United States",
  "East Arnhem, Northern Territory, Australia",
  "Campeche, Mexico",
  "Gasconade, Missouri, United States",
  "Waterford, Ontario, Canada",
  "352332, Ust’-Labinsk, Krasnodar, Russia",
  "357106, Nevinnomyssk, Stavropol, Russia",
  "PE7 0LA, Peterborough, England, United Kingdom",
  "Lamoureux, Izon, Nouvelle-Aquitaine, France",
  "6843 XG, Arnhem, Gelderland, Netherlands",
  "Windham, Connecticut, United States",
  "236016, Kaliningrad, Kaliningrad, Russia",
  "Gentry Woods, Newport, South Carolina, United States",
  "Mingorría, Castilla and Leon, Spain",
  "422983, Chistopol’, Tatarstan, Russia",
  "North Wilkesboro, North Carolina, United States",
];
