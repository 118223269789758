import { Modal } from "antd";
import LoaderButtonBlack from "@/Components/LoaderButtonBlack";
import DeleteIcon from "../../assets/SVGs/DeleteIcon.svg";
import { deleteWorkflowFolder } from "@/utils/apis";

type Props = {
  modal: boolean;
  close: () => void;
  folder: any;
};

const DeleteWorkflowFolder = ({ modal, close, folder }: Props) => {
  const handleDelete = async () => {
    await deleteWorkflowFolder(folder.id);
    window.location.reload();
  };

  return (
    <Modal centered open={modal} onCancel={close} footer={null} width="50%">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label htmlFor="workflow-name" className="text-md font-semibold text-gray-800">
            <div>
              Are you sure you want to delete this folder (<span className="text-red-600">{folder.name}</span>
              )?
              <br />
              <br />
              <span className="text-red-600">
                NOTE: All the workflows inside this folder will also be erased. You cannot undo this action.
              </span>
            </div>
          </label>
        </div>
        <div className="flex gap-x-3 items-center mt-6">
          <div className="flex border border-red-600 px-4 gap-x-3 rounded-md group hover:bg-red-300 ">
            <img src={DeleteIcon} alt="deleteWorkflow" width={20} />
            <LoaderButtonBlack
              disabled={false}
              error=""
              loaderText="Deleting Folder..."
              text="Delete Folder"
              onClickCallback={handleDelete}
              btnClasses={"w-fit bg-white text-red-800 px-0 group-hover:bg-red-300 "}
            />
          </div>
          <button onClick={close} className="w-fit text-md font-semibold py-3 px-6 text-black rounded-md">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteWorkflowFolder;
