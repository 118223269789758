import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import type { GetProp, GetRef, TableProps } from "antd";
import { ConfigProvider, Dropdown, Form, Input, message, Popconfirm, Table } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import { ActionResponses } from "../../../utils/interfaces";
import Loader from "../../Loader";
import { Tooltip } from "antd";
import { FaRegCopy } from "react-icons/fa";
import {
  ACTION_TYPES,
  APOLLO_EMAIL_RESPONSE_STRUCTURE_NAME,
  APOLLO_EMAIL_STATUS_RESPONSE_STRUCTURE_ID,
  CELL_STATUS_DISPLAY,
  CELL_STATUS_SYMBOL,
  CELL_STATUS_TYPE,
  DISPLAY_DATE_TIME_FORMAT,
  LOADER_TYPES,
  TABLE_ERROR_MESSAGES,
  TabEnum,
  USER_ACTION_STATUS,
  WATERFALL_ACTIONS,
  setStateType,
} from "../../../utils/constants";
import { IoClose, IoPlay } from "react-icons/io5";
import { FaArrowDownUpAcrossLine, FaPlus } from "react-icons/fa6";
import JsonViewer from "../Modals/JsonViewer";
import ReviewModal from "../Modals/ReviewModal/index";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import {
  fetchDocContent,
  getDataIds,
  getDataIdsComleted,
  getDataIdsNotCompleted,
  isApplicableURL,
} from "@/utils/functions";
import TenKDocViewer from "../Modals/TenKDocViewer";
import ChatNameModal from "./TableChat/ChatNameModal";
import useStateRef from "react-usestateref";
import TableViewer from "../Modals/TableViewer";
import WaterfallViewer from "../Modals/WaterfallViewer";
import MultiReviewModal from "../Modals/MultiReviewModal";
import styled from "styled-components";
import { FiDownload, FiSave } from "react-icons/fi";
import CSVInput from "../CSVInput";
import RegenMenu from "../Modals/RegenMenu";
import DownArrowNew from "../../../assets/SVGs/DownArrowNew.svg";
import LinkedinProfileViewer from "../Modals/LinkedinProfileViewer";
import DedupeModal from "../Modals/DedupeModal";
import ErrorModal from "../Modals/ErrorModal";
import LinkedinPostViewer from "../Modals/LinkedinPostViewer";
import ApolloEmployeeViewer from "../Modals/ApolloEmployeeViewer";
import PasteRowsModal from "../Modals/PasteRowsModal";
import {
  deleteRecords,
  getTableInfoForWorkflow,
  triggerEngagebayPullContacts,
  triggerSalesforcePullData,
} from "@/utils/apis";
import WellsViewer from "../Modals/WellsViewer";
import moment from "moment";
import SalesNavEmployeeViewer from "../Modals/SalesNavEmployeeViewer";
import CrunchBaseFundraisingViewer from "../Modals/CrunchBaseFundraisingViewer";

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

type EditableRowProps = {
  index: number;
};

type EditableCellProps = {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: any;
  handleSave: (record: any) => void;
  handlePasteData: (record: any, data: any, columnId: string) => Promise<void>;
};

type EditableTableProps = Parameters<typeof Table>[0];
type TablePaginationConfig = Exclude<GetProp<TableProps, "pagination">, boolean>;

type TableParams = {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, "onChange">>[1];
};

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface ViewerProps {
  open: boolean;
  data: any;
  title: () => React.ReactNode;
  type: string;
  // style?:any;
  credits: number | Record<string, number>;
  errorMessage?: string;
}
const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handlePasteData,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const pasteRef = useRef<boolean>(false);
  const inputRef = useRef<any>(null);
  const form: any = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: typeof record[dataIndex] === "object" ? JSON.stringify(record[dataIndex]) : record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      // values
      if (typeof record[dataIndex] === "object") {
        values[dataIndex] = JSON.parse(values[dataIndex]);
      }
      toggleEdit();
      if (!pasteRef.current) {
        handleSave({ ...record, ...values });
      }
      pasteRef.current = false;
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const handlePaste = async (event: any) => {
    const clipboardData = event.clipboardData.getData("Text");
    pasteRef.current = true;
    const inputCell = document.getElementById("inputCell");

    const pastedData = clipboardData
      .split("\n")
      .map((line: string) => line.split("\t").map((cell) => cell.trim()))
      .filter((row: any) => row.some((cell: string) => cell !== ""));

    inputCell?.blur();
    handlePasteData(pastedData, dataIndex, record.key).then(() => {
      pasteRef.current = false;
    });
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input id="inputCell" ref={inputRef} onPressEnter={save} onBlur={save} onPaste={handlePaste} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap cursor-pointer hover:bg-gray-100 transition"
        onClick={toggleEdit} // Enable editing on click
        // onMouseEnter={toggleEdit} // Enable editing on hover
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

interface TableTabProps {
  setTab: setStateType<TabEnum>;
}

const ColTitle = ({ action, children }: { action: ActionResponses; children?: React.ReactNode }) => (
  <div className="relative flex items-center justify-between gap-2">
    <section className="flex items-center justify-between gap-2 overflow-hidden text-ellipsis">
      <img src={action.logo} alt={action.name} className="w-6 h-6" />
      <span>{action.displayName || action.name}</span>
    </section>
    {children}
  </div>
);

const CustomTable = styled(Table)`
  .ant-spin-nested-loading {
    height: 100%;
  }

  .ant-spin-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-table {
    flex: 1 0 auto;
  }

  .ant-table-thead > tr > th {
    height: 36px;
    min-height: 36px;
    padding: 4px 8px;
    text-overflow: ellipsis;
    white-space: nowrap; /* Keep the header text on a single line */
    overflow: hidden; /* Hide overflow content */
    /* Ensure there's a width or max-width set to trigger overflow */
    // width: 150px; /* Adjust based on your needs */
  }
  .ant-table-tbody > tr > td {
    // height: 36px;
    // min-height: 36px;
    padding: 4px 8px;
    line-height: 0.5;
  }
  .ant-pagination-simple-pager > input {
    width: 40px !important;
    pointer-events: none !important;
    border: none !important;
    padding: 0px !important;
    font-size: 14px !important;
  }
  .ant-table-pagination-right {
    margin: 0 !important;
    align-items: center;
  }
`;

const TableTab = ({ setTab }: TableTabProps) => {
  const tableRef = useRef<any>(null);
  const [chatNameModal, setChatNameModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selectedForChat, setSelectedForChat] = useState<string[] | "all">("all");
  const [selected, setSelected, selectedRef] = useStateRef<string[]>([]);
  const [crmSyncAction, setCrmSyncAction] = useState<string>("");
  const [loadingEngagebayContacts, setLoadingEngagebayContacts] = useState(false);
  const [loadingSfData, setLoadingSfData] = useState<boolean>(false);
  const [columns, setColumns, colsRef] = useStateRef<any[]>([]);
  const [numOfRows, setNumOfRows] = useState<number>(0);
  const [sections, setSections] = useState<number>(0);
  const [currentSection, setCurrentSection, currentSectionRef] = useStateRef<number>(0);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const addedRef = useRef(false);
  const paginationChangeRef = useRef(false);
  const [modalStyle, setModalStyle] = useState({});
  const [customViewer, setCustomViewer] = useState<ViewerProps>({
    open: false,
    data: {},
    title: () => <></>,
    type: "",
    credits: 0,
    errorMessage: "",
  });
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const [paginationMove, setPaginationMove] = useState<any>({
    next: true,
    dataId: "",
  });
  const [workflowActions, setWorkflowActions] = useState<ActionResponses[]>([]);
  const [numOfActions, setNumOfActions] = useState<number>(0);
  const [exporting, setExporting] = useState<boolean>(false);
  const [downloadLink, setDownloadLink] = useState<string>("");
  const [dedupeData, setDedupeData] = useState<any>([]);
  const [dedupeModal, setDedupeModal] = useState<boolean>(false);
  const [pasteModal, setPasteModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>({});
  const [dedupeDataLoading, setDedupeDataLoading] = useState<boolean>(false);
  const { id } = useParams();
  const {
    data,
    dataRef,
    setData,
    refresh,
    setUserActions,
    retryWorkflowForData,
    retryAction,
    updateWorkflowRunDataUtil,
    exportTableData,
    dedupeRowsCheck,
    saveWorkflowRecord,
  } = useWorkflow();
  const [resizingColumnIndex, _setResizingColumnIndex] = useState<string>();
  const resizingColumnIndexRef = useRef(resizingColumnIndex);

  const setResizingColumnIndex = (index: string | undefined) => {
    resizingColumnIndexRef.current = index;
    _setResizingColumnIndex(index);
  };

  const getCurrentWidth = (dataIndex: string) => {
    const currentWidths = JSON.parse(localStorage.getItem(id || "") || "{}");
    return currentWidths[dataIndex] || 250;
  };

  useEffect(() => {
    if (colsRef.current.length === 0) return;
    const currentWidths: any = {};
    colsRef.current.forEach((col) => {
      currentWidths[col.colId] = col.width || 250;
    });
    localStorage.setItem(id || "", JSON.stringify(currentWidths));
  }, [colsRef.current]);

  const handleResize = (index: string, delta: number) => {
    if (!resizingColumnIndexRef.current) return;
    const MIN_COLUMN_WIDTH = 75;
    setColumns((prevColumns) => {
      const idx = prevColumns.findIndex((col) => col.colId === index);
      const indexNewWidth = Number(prevColumns[idx].width) + delta;
      const nextWidth = indexNewWidth >= MIN_COLUMN_WIDTH ? indexNewWidth : MIN_COLUMN_WIDTH;
      return prevColumns.map((col) => (col.colId === index ? { ...col, width: nextWidth } : col));
    });
  };

  const resizeListener = (e: MouseEvent) => {
    window.onmouseup = () => {
      window.removeEventListener("mousemove", resizeListener);
      setResizingColumnIndex(undefined);
    };
    window.onmouseleave = () => {
      window.removeEventListener("mousemove", resizeListener);
      setResizingColumnIndex(undefined);
    };
    if (resizingColumnIndexRef.current) {
      handleResize(resizingColumnIndexRef.current, e.movementX);
    }
  };

  const scrollToLastRow = useCallback(() => {
    const ele = document.querySelector(".ant-table-tbody");
    const lastChild = ele?.lastElementChild;
    if (!lastChild) return;
    lastChild.scrollIntoView({ behavior: "smooth" });
  }, [dataRef.current]);

  useEffect(() => {
    if (!paginationChangeRef.current) return;
    scrollToLastRow();
    paginationChangeRef.current = false;
  }, [tableParams.pagination?.current]);

  const RunCol = {
    colId: "run",
    title: "Run",
    dataIndex: "run",
    editable: false,
    type: "run",
    fixed: "left",
    width: 50,
    render: (text: string, record: any) => {
      return (
        <div className="flex justify-center">
          {text === "run" ? (
            // <button
            //   type="button"
            //   title="Run"
            //   className={`bg-primary text-white p-2 rounded hover:bg-dark`}
            //   onClick={() => run(record)}
            // >
            //   <IoPlay size={"12px"} />
            // </button>
            <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
              <Tooltip
                placement="right"
                title="Run"
                arrow={false}
                overlayClassName="border border-gray-200 rounded-md"
                overlayInnerStyle={{
                  fontWeight: 600,
                  backgroundColor: "#f3f4f6",
                  color: "#6c727f",
                  fontSize: "0.75rem",
                }}
              >
                <button
                  type="button"
                  onClick={() => retry(record)}
                  // title="Retry"
                  className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-300 p-2"
                >
                  <IoPlay size={"12px"} />
                </button>
              </Tooltip>
            </div>
          ) : text === "retry" ? (
            // <button
            //   type="button"
            //   title="Retry"
            //   className={`bg-primary text-white p-2 rounded hover:bg-dark`}
            //   onClick={() => retry(record)}
            // >
            //     <IoReload size={"12px"} />
            // </button>
            <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
              <Tooltip
                placement="right"
                title="Run"
                arrow={false}
                overlayClassName="border border-gray-200 rounded-md"
                overlayInnerStyle={{
                  fontWeight: 600,
                  backgroundColor: "#f3f4f6",
                  color: "#6c727f",
                  fontSize: "0.75rem",
                }}
              >
                <button
                  // type="button"
                  onClick={() => retry(record)}
                  // title="Retry"
                  className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-300 p-2"
                >
                  <IoPlay size={"12px"} />
                </button>
              </Tooltip>
            </div>
          ) : text === "loading" ? (
            <div className="flex justify-center items-center p-2">
              <SpinnerStatus />
            </div>
          ) : text === "review" ? (
            <div className="flex items-center justify-center p-2">{CELL_STATUS_SYMBOL.readyToBeReviewed}</div>
          ) : text === "complete" ? (
            <div className="flex items-center justify-center p-2">{CELL_STATUS_SYMBOL.completed}</div>
          ) : (
            <></>
          )}
        </div>
      );
    },
  };

  const retry = async (record: any) => {
    const inputColIds = colsRef.current.filter((col) => col.editable).map((col) => col.dataIndex);

    const inputs = Object.entries(record).reduce((acc, [key, value]) => {
      if (key === "key" || key === "run") return acc;
      if (inputColIds.includes(key)) {
        return { ...acc, [key]: value };
      }
      return acc;
    }, {});

    await retryWorkflowForData(id || "", [record.key], [inputs], currentSectionRef.current);
  };

  const handleDedupeInput = async (columnId: string) => {
    if (dataRef.current.length <= 1) {
      return;
    }

    setDedupeDataLoading(true);
    setDedupeModal(true);
    const dataaa: any = await dedupeRowsCheck(id || "", currentSectionRef.current, columnId);

    setDedupeData(dataaa.duplicates);
    setDedupeDataLoading(false);
  };

  const showModalNearCell = (event: any) => {
    const cellRect = event.target.getBoundingClientRect();
    const modalWidth = 300;
    const modalHeight = 100;

    let modalPosition = {
      top: cellRect.top + window.scrollY,
      left: cellRect.left + window.scrollX,
    };

    // Adjust if modal goes beyond the right edge of the window
    if (modalPosition.left + modalWidth > window.innerWidth) {
      modalPosition.left = window.innerWidth - modalWidth;
    }

    // Adjust if modal goes beyond the bottom edge of the window
    if (modalPosition.top + modalHeight > window.innerHeight) {
      modalPosition.top = window.innerHeight - modalHeight;
    }

    setModalStyle({
      position: "absolute",
      top: `${modalPosition.top}px`,
      left: `${modalPosition.left}px`,
    });
  };

  const jsonParser = (json: string) => {
    if (typeof json !== "string") return json;
    try {
      return JSON.parse(json);
    } catch (e) {
      return json;
    }
  };

  const findCredits = (colResponse: any) => {
    let credits = 0;
    const data = Object?.values(colResponse)
      ?.map((res: any) => ({
        [res.name]: res.value,
      }))
      ?.reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {});
    Object?.values(data["List of Employees"])?.map((emp: any) => {
      const email_status = emp.find(
        (res: any) => res.responseStructureId === APOLLO_EMAIL_STATUS_RESPONSE_STRUCTURE_ID
      )?.value;
      const email = emp.find((res: any) => res.responseStructureId === APOLLO_EMAIL_RESPONSE_STRUCTURE_NAME)?.value;
      if (email_status === "verified" && email && email !== "email_not_unlocked@domain.com") {
        credits += 1;
      }
    });
    return credits;
  };

  const mapColumns = (actions: ActionResponses[]) => {
    const cols: any[] = [RunCol];
    actions.map((action: any) => {
      if (action.type === "input" || action.type === "add_section") {
        const col = {
          colId: "createdAtt",
          width: getCurrentWidth("createdAtt"),
          title: () => (
            <div>
              <div
                className="resizeHandle"
                onMouseDown={() => {
                  setResizingColumnIndex("createdAtt");
                  window.addEventListener("mousemove", resizeListener, {
                    passive: true,
                  });
                }}
              />
              <section className="flex items-center justify-between gap-2 w-full">
                <span className="overflow-hidden text-ellipsis">Created At</span>
                <Tooltip
                  placement="bottomLeft"
                  title={"DeDupe Column"}
                  arrow={false}
                  overlayClassName={`border border-gray-200 rounded-md`}
                  overlayInnerStyle={{
                    fontWeight: 600,
                    backgroundColor: `#f3f4f6`,
                    color: `#6c727f`,
                    fontSize: "0.75rem",
                  }}
                >
                  {/* <button
                      type="button"
                      onClick={() => handleDedupeInput("createdAtt")}
                      className={`flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2 `}
                    >
                      <FaArrowDownUpAcrossLine size={"12px"} className="text-gray-600" />
                    </button> */}
                </Tooltip>
              </section>
            </div>
          ),
          dataIndex: "createdAtt",
          key: Math.random(),
          editable: false,
          type: action.type,
          ellipsis: true,
          onHeaderCell: () => ({
            className: "!bg-[#FAFAFA]",
          }),
          onCell: (record: any) => ({
            record,
            editable: false,
            dataIndex: "createdAtt",
            title: <ColTitle action={action} />,
            handleSave,
            handlePasteData,
          }),
          render: (text: string) => {
            return {
              props: {
                className: "!p-0",
              },
              children: (
                <div className="overflow-hidden p-4 whitespace-nowrap text-ellipsis w-auto">
                  {typeof text === "object" ? JSON.stringify(text) : text}
                </div>
              ),
            };
          },
        };
        cols.push(col);
      }

      if (action.type === "input" || action.type === "add_section") {
        setCrmSyncAction(action.actionName);
        action.response.map((res: any) => {
          const col = {
            colId: res.responseId,
            width: getCurrentWidth(res.responseId),
            title: () => (
              <div className="relative flex items-center justify-between gap-2">
                <div
                  className="resizeHandle"
                  onMouseDown={() => {
                    setResizingColumnIndex(res.responseId);
                    window.addEventListener("mousemove", resizeListener, {
                      passive: true,
                    });
                  }}
                />
                <section className="flex items-center justify-between gap-2 w-full">
                  <span className="overflow-hidden text-ellipsis">{res.name}</span>
                  <Tooltip
                    placement="bottomLeft"
                    title={"DeDupe Column"}
                    arrow={false}
                    overlayClassName={`border border-gray-200 rounded-md`}
                    overlayInnerStyle={{
                      fontWeight: 600,
                      backgroundColor: `#f3f4f6`,
                      color: `#6c727f`,
                      fontSize: "0.75rem",
                    }}
                  >
                    <button
                      type="button"
                      onClick={() => handleDedupeInput(res.responseId)}
                      className={`flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2 `}
                    >
                      <FaArrowDownUpAcrossLine size={"12px"} className="text-gray-600" />
                    </button>
                  </Tooltip>
                </section>
              </div>
            ),
            dataIndex: res.responseId,
            key: res.responseId,
            editable: true,
            type: action.type,
            ellipsis: true,
            onHeaderCell: () => ({
              className: "!bg-[#fffcf0]",
            }),
            onCell: (record: any) => ({
              record,
              editable: col.editable,
              dataIndex: col.dataIndex,
              title: <ColTitle action={action} />,
              handleSave,
              handlePasteData,
            }),
            render: (text: string) => {
              if (!text) {
                return {
                  props: {
                    className: "!p-0",
                  },
                  children: <div className="p-5 w-auto">{text}</div>,
                };
              } else {
                return {
                  props: {
                    className: "!p-0",
                  },
                  children: (
                    <div className="overflow-hidden p-4 whitespace-nowrap text-ellipsis w-auto">
                      {typeof text === "object" ? JSON.stringify(text) : text}
                    </div>
                  ),
                };
              }
            },
          };
          cols.push(col);
        });
      } else {
        const col = {
          colId: action.actionId,
          width: getCurrentWidth(action.actionId),
          title: (disp: boolean = false) => (
            <ColTitle action={action}>
              <div
                className="resizeHandle"
                onMouseDown={() => {
                  setResizingColumnIndex(action.actionId);
                  window.addEventListener("mousemove", resizeListener, {
                    passive: true,
                  });
                }}
              />
              <div className="flex flex-row gap-2">
                {action.type === ACTION_TYPES.AI && disp && (
                  <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                    <MultiReviewModal
                      actionId={action.actionId}
                      userWorkflowId={id || ""}
                      section={currentSectionRef.current}
                      // showModalNearCell={showModalNearCell}
                      style={modalStyle}
                      selectedRows={selectedRef.current}
                      setSelectedRows={() => setSelected([])}
                    />
                  </div>
                )}
                <div className="opacity-1 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                  <RegenMenu
                    actionId={action.actionId}
                    userWorkflowId={id || ""}
                    section={currentSectionRef.current}
                    showModalNearCell={showModalNearCell}
                    style={modalStyle}
                    selectedRows={selectedRef.current}
                    setSelectedRows={() => setSelected([])}
                  />
                </div>
              </div>
            </ColTitle>
          ),
          dataIndex: action.actionId,
          key: action.actionId,
          type: action.type,
          editable: false,
          ellipsis: true,
          onHeaderCell: () => ({
            className: "!bg-white",
          }),
          onCell: (record: any) => ({
            record,
            editable: false,
            dataIndex: col.dataIndex,
            title: <ColTitle action={action} />,
            handleSave,
            handlePasteData,
          }),

          render: (text: any, _record: any) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [copied, setCopied] = useState(false);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [retried, setRetried] = useState(false);

            const handleRunCell = async () => {
              await retryAction(
                action.actionId,
                id || "",
                [_record.key],
                [_record.key],
                currentSectionRef.current,
                "singleCell"
              );
            };

            if (!text)
              return {
                props: {
                  className: "!p-0",
                },
                children: (
                  <div className="flex flex-row gap-2 h-10 items-center justify-between px-2 hover:bg-gray-100 transition group">
                    <div className="flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis w-auto">
                      <span className="flex py-2 pr-2 gap-1 sm:gap-2">
                        {CELL_STATUS_SYMBOL[text?.status || "pending"]}
                        <span>{CELL_STATUS_DISPLAY[text?.status || "pending"]}</span>
                      </span>
                    </div>
                    {(text?.status || "pending") === "pending" && (
                      <Tooltip
                        placement="bottomLeft"
                        title="Run this cell"
                        arrow={false}
                        overlayClassName="border border-gray-200 rounded-md"
                        overlayInnerStyle={{
                          fontWeight: 600,
                          backgroundColor: "#f3f4f6",
                          color: "#6c727f",
                          fontSize: "0.75rem",
                        }}
                      >
                        <button
                          type="button"
                          onClick={handleRunCell}
                          // title="Run"
                          className="group-hover:flex justify-center items-center rounded-full bg-gray-200 hover:bg-gray-300 p-2 hidden"
                        >
                          <IoPlay size={"12px"} className="text-gray-600" />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                ),
              };

            const { extra, status, stepDownResponse, ...colResponse } = text;
            const actionResponseSize: string = action.responseSize;
            const isActionResponseSizeSmall: boolean = actionResponseSize === "small";
            const isWaterfallAction = WATERFALL_ACTIONS.includes(action.actionName);
            const actionResponse: any = isActionResponseSizeSmall && Object.values(colResponse)[0];

            const handleCopyEmail = () => {
              if (isActionResponseSizeSmall) {
                navigator.clipboard
                  .writeText(actionResponse.value)
                  .then(() => {
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000); // Hide after 2 seconds
                  })
                  .catch((_err) => {
                    alert("Failed to copy. Please try again.");
                  });
              }
            };

            const handleRetryCell = async () => {
              setRetried(true);
              await retryAction(
                text.extra.actionId,
                id || "",
                [text.extra.dataId],
                [text.extra.dataId],
                currentSectionRef.current,
                "singleCell"
              );
              setRetried(false);
            };

            const showModalNearCell = (event: any) => {
              const cellRect = event.target.getBoundingClientRect();
              // Assuming a fixed size for the modal for demonstration purposes
              const modalWidth = 500; // Adjust as needed
              const modalHeight = 200; // Adjust as needed

              // Initial modal position calculation
              let modalPosition = {
                top: cellRect.top + window.scrollY,
                left: cellRect.left + window.scrollX,
              };

              // Adjust if modal goes beyond the right edge of the window
              if (modalPosition.left + modalWidth > window.innerWidth)
                modalPosition.left = window.innerWidth - modalWidth;

              // Adjust if modal goes beyond the bottom edge of the window
              if (modalPosition.top + modalHeight > window.innerHeight)
                modalPosition.top = window.innerHeight - modalHeight;

              // Apply the adjusted positions, converting back to strings with 'px'
              setModalStyle({
                position: "absolute",
                top: `${modalPosition.top}px`,
                left: `${modalPosition.left}px`,
              });
            };

            const customDataFormat = (value: any) => {
              if (["string", "number"].includes(typeof value)) {
                return value;
              }
              return JSON.stringify(value);
            };

            return {
              props: {
                className: "!p-0",
              },
              children: (
                //<div className="relative flex space-x-4 hover:bg-gray-100 transition group">
                <div className="flex flex-row gap-2 px-2 justify-between hover:bg-gray-100 transition group ">
                  {action["ui-style"] === "Formatting" && action.actionName.includes("FORMATTER") ? (
                    // display the formatted value as is in the cell
                    <div className="flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis w-auto">
                      <span className="flex items-center justify-center py-2 pr-2 gap-1 sm:gap-2">
                        {customDataFormat((Object.values(colResponse)[0] as any)?.value || {})}
                      </span>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className={`flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis w-auto`}
                      disabled={
                        !(
                          text &&
                          [
                            CELL_STATUS_TYPE.COMPLETED,
                            CELL_STATUS_TYPE.READY_TO_BE_REVIEWED,
                            CELL_STATUS_TYPE.FAILED,
                          ].includes(text?.status || "")
                        )
                      }
                      onClick={() => {
                        if (!text) return alert("Error processing request. Please try again later.");
                        if (text.status === "failed") {
                          if (isWaterfallAction) {
                            setCustomViewer({
                              open: true,
                              data: {
                                stepDownResponse,
                                action,
                                email: actionResponse.value,
                              },
                              title: () => <ColTitle action={action} />,
                              type: "email",
                              credits: action.credits,
                              errorMessage: text.error,
                            });
                          } else {
                            setErrorData({
                              status: text.status,
                              message: text.error,
                            });
                            setErrorModal(true);
                          }
                          return;
                        }

                        if (action.actionName === "PUBLIC_LINKEDIN_POSTS_ENRICH") {
                          setCustomViewer({
                            open: true,
                            data: Object.values(colResponse)
                              .map((res: any) => ({
                                [res.name]: res.value,
                              }))
                              .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                            title: () => <ColTitle action={action} />,
                            type: "linkedinPosts",
                            credits: action.credits,
                          });
                        } else if (action.actionName === "SCRAPE_LINKEDIN_PUBLIC_DATA_2") {
                          setCustomViewer({
                            open: true,
                            data: Object.values(colResponse)
                              .map((res: any) => ({
                                [res.name]: res.value,
                              }))
                              .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                            title: () => <ColTitle action={action} />,
                            type: "linkedinProfile",
                            credits: action.credits,
                          });
                        } else if (action.actionName === "FIND_EMPLOYEES_USING_SALES_NAV_URL") {
                          setCustomViewer({
                            open: true,
                            data: Object.values(colResponse)
                              .map((res: any) => ({
                                [res.name]: res.value,
                              }))
                              .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                            title: () => <ColTitle action={action} />,
                            type: "salesNavEmployees",
                            credits: Number(action.credits + findCredits(colResponse)),
                          });
                        } else if (action.actionName === "FIND_EMPLOYEES_FOR_COMPANY_USING_APOLLO") {
                          setCustomViewer({
                            open: true,
                            data: Object.values(colResponse)
                              .map((res: any) => ({
                                [res.name]: res.value,
                              }))
                              .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                            title: () => <ColTitle action={action} />,
                            type: "apolloEmployees",
                            credits: Number(action.credits + findCredits(colResponse)),
                          });
                        } else if (action.actionName === "WELL_DB_OPERATOR_LOOKUP_AND_WELLS_SEARCH") {
                          setCustomViewer({
                            open: true,
                            data: Object.values(colResponse)
                              .map((res: any) => ({
                                [res.name]: res.value,
                              }))
                              .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                            title: () => <ColTitle action={action} />,
                            type: "wells",
                            credits: 0,
                          });
                        } else if (action.actionName === "CRUNCHBASE_RECENT_FUNDRAISING_DATA_FROM_COMPANY") {
                          setCustomViewer({
                            open: true,
                            data: Object.values(colResponse)
                              .map((res: any) => ({
                                [res.name]: res.value,
                              }))
                              .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                            title: () => <ColTitle action={action} />,
                            type: "crunchBaseFundraising",
                            credits: action.credits,
                          });
                        } else if (
                          typeof colResponse === "object" &&
                          Object.keys(colResponse).length > 0 &&
                          Object.values(colResponse).some((res: any) => res.type && res.type === "textFileURL")
                        ) {
                          setCustomViewer({
                            open: true,
                            data: text,
                            title: () => <ColTitle action={action} />,
                            type: "textFileURL",
                            credits: action.credits,
                          });
                        } else if (
                          typeof colResponse === "object" &&
                          Object.keys(colResponse).length > 0 &&
                          Object.values(colResponse).some((res: any) => res.type && res.type === "table")
                        ) {
                          setCustomViewer({
                            open: true,
                            data: Object.values(colResponse)
                              .map((res: any) => ({
                                [res.name]: res.value,
                              }))
                              .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                            title: () => <ColTitle action={action} />,
                            type: "table",
                            credits: action.credits,
                          });
                        } else if (typeof text === "object" && text.extra.reviewAllowed) {
                          showModalNearCell(event);
                          setCustomViewer({
                            open: true,
                            data: text,
                            title: () => <ColTitle action={action} />,
                            type: "review",
                            credits: action.credits,
                          });
                        } else if (isWaterfallAction) {
                          setCustomViewer({
                            open: true,
                            data: {
                              stepDownResponse,
                              action,
                              email: actionResponse.value,
                            },
                            title: () => <ColTitle action={action} />,
                            type: "email",
                            credits: action.credits,
                          });
                        } else {
                          setCustomViewer({
                            open: true,
                            data: Object.values(colResponse)
                              .map((res: any) => ({
                                [res.name]:
                                  res.stringified || action.actionName === "HTTP_API_CALL"
                                    ? jsonParser(res.value)
                                    : res.value,
                              }))
                              .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
                            title: () => <ColTitle action={action} />,
                            type: "default",
                            credits: action.credits,
                          });
                        }
                      }}
                    >
                      <span className="flex items-center justify-center py-2 pr-2 gap-1 sm:gap-2">
                        {CELL_STATUS_SYMBOL[text?.status || "pending"]}
                        <span>
                          {text?.status === USER_ACTION_STATUS.FAILED &&
                          text.error &&
                          TABLE_ERROR_MESSAGES.includes(text.error)
                            ? text.error
                            : (text?.status === "completed" || text?.status === "readyToBeReviewed") && actionResponse
                              ? actionResponse.value
                              : CELL_STATUS_DISPLAY[text?.status || "pending"]}
                        </span>
                      </span>
                    </button>
                  )}
                  <div className="flex items-center justify-center gap-2">
                    {actionResponse && (
                      <div className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                        <Tooltip
                          placement="bottomLeft"
                          title={copied ? "Copied!" : "Copy to clipboard"}
                          arrow={false}
                          overlayClassName="border border-gray-200 rounded-md"
                          overlayInnerStyle={{
                            fontWeight: 600,
                            backgroundColor: `${copied ? "#6c727f" : "#f3f4f6"}`,
                            color: `${copied ? "#f3f4f6" : "#6c727f"}`,
                            fontSize: "0.75rem",
                          }}
                        >
                          <button
                            type="button"
                            onClick={handleCopyEmail}
                            // title="Copy"
                            className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                          >
                            <FaRegCopy size={"12px"} className="text-gray-600" />
                          </button>
                        </Tooltip>
                      </div>
                    )}
                    <div className="opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out">
                      <Tooltip
                        placement="bottomLeft"
                        title={retried ? "Running!" : "Run this cell"}
                        arrow={false}
                        overlayClassName="border border-gray-200 rounded-md"
                        overlayInnerStyle={{
                          fontWeight: 600,
                          backgroundColor: `${retried ? "#6c727f" : "#f3f4f6"}`,
                          color: `${retried ? "#f3f4f6" : "#6c727f"}`,
                          fontSize: "0.75rem",
                        }}
                      >
                        <button
                          type="button"
                          onClick={handleRetryCell}
                          // title="Retry"
                          className="flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2"
                        >
                          <IoPlay size={"12px"} className="text-gray-600" />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ),
            };
          },
        };
        cols.push(col);

        const subCols = action.response
          .map((res: any) => {
            // check if res.display_column is true
            if (res.display_column) {
              return {
                colId: res.responseId,
                width: getCurrentWidth(res.responseId),
                title: () => (
                  <div className="relative flex items-center justify-between gap-2">
                    <div
                      className="resizeHandle"
                      onMouseDown={() => {
                        setResizingColumnIndex(res.responseId);
                        window.addEventListener("mousemove", resizeListener, {
                          passive: true,
                        });
                      }}
                    />
                    <section className="flex items-center justify-between gap-2 w-full">
                      <span className="flex flex-row items-center justify-center gap-2 overflow-hidden text-ellipsis">
                        <img src={action.logo} alt={res.name} className="w-6 h-6" />
                        {res.name}
                      </span>
                      <Tooltip
                        placement="bottomLeft"
                        title={"DeDupe Column"}
                        arrow={false}
                        overlayClassName={`border border-gray-200 rounded-md`}
                        overlayInnerStyle={{
                          fontWeight: 600,
                          backgroundColor: `#f3f4f6`,
                          color: `#6c727f`,
                          fontSize: "0.75rem",
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => handleDedupeInput(res.responseId)}
                          className={`flex justify-center items-center rounded-full bg-gray-100 hover:bg-gray-200 p-2`}
                        >
                          <FaArrowDownUpAcrossLine size={"12px"} className="text-gray-600" />
                        </button>
                      </Tooltip>
                    </section>
                  </div>
                ),
                dataIndex: action.actionId,
                key: action.actionId,
                editable: false,
                type: action.type,
                ellipsis: true,
                onHeaderCell: () => ({
                  className: "!bg-[#fffcf0]",
                }),
                onCell: (record: any) => ({
                  record,
                  editable: false,
                  dataIndex: col.dataIndex,
                  title: <ColTitle action={action} />,
                  handleSave,
                  handlePasteData,
                }),
                render: (text: any) => {
                  if (!text)
                    return {
                      props: {
                        className: "!p-0",
                      },
                      children: (
                        <div className="flex gap-2 overflow-hidden whitespace-nowrap text-ellipsis w-auto">
                          <span className="flex items-center justify-center py-2 pr-2 gap-1 sm:gap-2">
                            {CELL_STATUS_SYMBOL[text?.status || "pending"]}
                            <span>{CELL_STATUS_DISPLAY[text?.status || "pending"]}</span>
                          </span>
                        </div>
                      ),
                    };
                  const { extra, status, stepDownResponse, ...colResponse } = text;
                  const data = colResponse[res.responseId];
                  return {
                    props: {
                      className: "!p-0",
                    },
                    children: (
                      <Tooltip
                        placement="bottom"
                        title={
                          ["string", "number"].includes(typeof data?.value)
                            ? data?.value
                            : JSON.stringify(data?.value, null, 2)
                        }
                        arrow={false}
                        overlayClassName="border border-gray-200 rounded-md text-sm whitespace-pre-wrap overflow-auto max-h-96 max-w-md scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
                        overlayInnerStyle={{
                          fontWeight: 600,
                          backgroundColor: "#f3f4f6",
                          color: "#6c727f",
                          fontSize: "0.75rem",
                        }}
                      >
                        <div className="overflow-hidden p-4 whitespace-nowrap text-ellipsis w-auto cursor-pointer">
                          {status !== "completed" && status !== "readyToBeReviewed" ? (
                            <div className="flex gap-2 items-center">
                              {CELL_STATUS_SYMBOL[text?.status || "pending"]}
                              <span>{CELL_STATUS_DISPLAY[text?.status || "pending"]}</span>
                            </div>
                          ) : ["string", "number"].includes(typeof data?.value) ? (
                            data?.value
                          ) : (
                            JSON.stringify(data?.value)
                          )}
                        </div>
                      </Tooltip>
                    ),
                  };
                },
              };
            }
          })
          .filter((col: any) => col);
        cols.push(...subCols);
      }
    });
    setColumns(cols);
    setNumOfActions(cols.length);
  };

  const mapRows = async (actions: ActionResponses[], data: any) => {
    const rows: any[] = [];
    await Promise.all(
      Object.entries(data).map(async ([dataId, item]: [string, any], idx: number) => {
        if (item.sectionInfo && currentSection === 0) return;
        const row: any = { key: dataId };
        const status: string[] = [];
        await Promise.all(
          Object.entries(item).map(async ([actionId, data]: [string, any]) => {
            const action = actions.find((a) => a.actionId === actionId || a.fsWfActionId === actionId);
            if (action && (action.type === "input" || action.type === "add_section")) {
              (data.response ?? []).map((res: any) => {
                row[res.responseId] = res.value;
              });
            } else if (["createdAt"].includes(actionId)) {
              row["createdAtt"] = moment.utc(data).local().format(DISPLAY_DATE_TIME_FORMAT);
            } else if (["sectionInfo"].includes(actionId)) return;
            else {
              status.push(data.status);
              let temp: any = {};
              let respArray = [];
              if (typeof data.response === "string" && isApplicableURL(data.response))
                respArray = await fetchDocContent(data.response);
              else respArray = data.response || [];
              respArray.map((r: any) => {
                temp[r.responseId] = {
                  ...(action?.response.find((res) => res.responseId === r.responseId) || {}),
                  value: r.value,
                  stringified: r.stringified,
                };
              });
              if (data.stepDownResponse) temp.stepDownResponse = data.stepDownResponse;
              if (data.error) temp.error = data.error;
              temp = {
                ...temp,
                status: data.status,
                extra: {
                  reviewNeeded: action?.reviewNeeded || false,
                  reviewed: data.reviewed || false,
                  index: idx,
                  workflowId: id,
                  actionId,
                  dataId,
                  reviewAllowed: action?.reviewAllowed || false,
                },
              };
              row[actionId] = temp;
              row.key = dataId;
            }
          })
        );
        if (status.includes(CELL_STATUS_TYPE.FAILED)) row.run = "retry";
        else if (status.includes(CELL_STATUS_TYPE.READY_TO_BE_REVIEWED)) row.run = "review";
        else if (
          status.includes(CELL_STATUS_TYPE.PENDING) ||
          status.includes(CELL_STATUS_TYPE.MISSING) ||
          status.includes(CELL_STATUS_TYPE.CHECKING_NEXT_SOURCE) ||
          status.includes(CELL_STATUS_TYPE.PAYLOAD_FILLED) ||
          status.includes(CELL_STATUS_TYPE.RETRYING)
        )
          row.run = "loading";
        else row.run = "retry";
        rows.push(row);
      })
    );
    setData(rows);
  };

  useEffect(() => {
    if (!id || currentSection === undefined || !tableParams.pagination?.current || !tableParams.pagination?.pageSize)
      return;
    setLoading(true);
    getTableInfoForWorkflow(
      id,
      currentSection,
      tableParams.pagination?.pageSize,
      paginationMove.next,
      paginationMove.dataId
    ).then((datas) => {
      let sects = 0;
      const queue = ["id1"];
      const resp: any[] = datas.responses;
      const actions: ActionResponses[][] = [];

      while (queue.length > 0) {
        const actionId = queue.shift();
        if (!actionId) continue;
        const action = resp.find((a: any) => a.actionId === actionId || a.fsWfActionId === actionId);
        if (!action) continue;
        if (action.type === "add_section") sects += 1;
        if (actions[sects]) actions[sects].push(action);
        else actions.push([action]);
        const childIds = action.nextAction.map((a: any) => (a && typeof a === "object" ? a.actionId : a));
        queue.push(...childIds);
      }

      setSections(sects);
      setWorkflowActions(actions.flat());
      setUserActions(actions);
      mapColumns(actions[currentSectionRef.current]);
      mapRows(actions[currentSectionRef.current], datas.data);
      setNumOfRows(datas.numOfRows);
      setFirstTime(false);
      setLoading(false);
    });
  }, [currentSection, tableParams.pagination?.current, tableParams.pagination?.pageSize, refreshing]);

  useEffect(() => {
    if (firstTime) return;
    const tot = setInterval(() => {
      if (!dataRef.current.length) return;
      const dataIds = getDataIdsNotCompleted(dataRef.current);
      if (!dataIds.length) return;
      updateWorkflowRunDataUtil(id || "", dataIds, currentSectionRef.current);
    }, 7000);
    return () => clearInterval(tot);
  }, [firstTime]);

  const handleAdd2 = useCallback(async () => {
    const newData: any = {
      key: data.length + Math.random(),
      run: "run",
      createdAtt: moment().format(DISPLAY_DATE_TIME_FORMAT),
    };
    colsRef.current.map((col) => {
      if (col.dataIndex && col.dataIndex !== "run" && col.editable) {
        newData[col.dataIndex] = "";
      }
    });
    addedRef.current = true;
    const addData = Object.entries(newData).reduce((acc, [key, value]) => {
      if (key === "key" || key === "run") return acc;
      return { ...acc, [key]: value };
    }, {});

    try {
      const { dataIds } = await saveWorkflowRecord(id || "", addData);
      newData.key = dataIds[0];
      setData([...dataRef.current, newData]);
    } catch (error) {
      console.error("Error adding new row", error);
      setData([...dataRef.current, newData]);
    }
  }, [dataRef.current]);

  const handleRunRetry = async () => {
    const filteredDataRetry = data.filter((record: any) => selectedRef.current.includes(record.key));

    const filteredDataIdRetry = data
      .filter((record: any) => selectedRef.current.includes(record.key))
      .map((record: any) => record.key);

    if (filteredDataIdRetry.length > 0) {
      await retryWorkflowForData(id || "", filteredDataIdRetry, filteredDataRetry, currentSectionRef.current);
    }
    setSelected([]);
    setRefreshing((p) => !p);
  };

  /**
   * @todo: Add error handling, Add toast to show success and error msg.
   */
  const handleDelete = async () => {
    await deleteRecords(id || "", selectedRef.current?.length === 0 ? getDataIds(data) : selectedRef.current);
    setSelectedForChat("all");
    setSelected([]);
    setRefreshing((p) => !p);
  };

  const handleSfDataPull = async () => {
    setLoadingSfData(true);
    await triggerSalesforcePullData(id || "");
    setLoadingSfData(false);
    setRefreshing((p) => !p);
  };

  const handleEngagebayContactsPull = async () => {
    setLoadingEngagebayContacts(true);
    await triggerEngagebayPullContacts(id || "");
    setLoadingEngagebayContacts(false);
    setRefreshing((p) => !p);
  };

  const handleSave = useCallback(
    async (row: any) => {
      const newData = [...dataRef.current];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setData(newData);
      const dataId = row.key;
      const data = Object.entries(row).reduce((acc, [key, value]) => {
        if (key === "key" || key === "run") return acc;
        return { ...acc, [key]: value };
      });
      try {
        await saveWorkflowRecord(id || "", data, dataId);
      } catch (error) {
        console.error("Error saving record", error);
      }
    },
    [dataRef.current]
  );

  const handlePasteData = useCallback(
    async (pasteArray: string[][], columnId: string, rowId: string) => {
      setPasteModal(true);
      const keys = Object.values(colsRef.current).map((col) => col.dataIndex);
      const colIndex = colsRef.current.findIndex((col) => col.dataIndex === columnId);
      const tempCols = colsRef.current.slice(colIndex);
      const inputColsLength = tempCols.filter((col) => col.editable).length;

      const pastedColsLength = Object.keys(pasteArray[0]).length;
      if (pastedColsLength > inputColsLength) {
        message.error(`Mismatching number of columns: ${inputColsLength} selected vs ${pastedColsLength} in clipboard`);
        setPasteModal(false);
        return;
      }

      const pastedData = pasteArray.map((row: string[]) => {
        let rowData: any = {};
        row.forEach((cell: string, idx: number) => {
          if (keys[colIndex + idx]) rowData[keys[colIndex + idx]] = cell;
          else rowData[columnId] = cell;
        });
        return rowData;
      });

      // Add new rows if needed
      const rowCount = pastedData.length;
      const updatedRowIdx = [...dataRef.current].findIndex((item) => rowId === item.key);
      const currentDataLength = dataRef.current.length;

      // Get the keys for the rows after updatedRowIdx
      const updatedDataKeys = dataRef.current
        .slice(
          updatedRowIdx,
          rowCount + updatedRowIdx > currentDataLength ? currentDataLength : rowCount + updatedRowIdx
        )
        .map((row) => row.key);

      const updatedData = pastedData.splice(
        0,
        rowCount + updatedRowIdx > currentDataLength ? currentDataLength - updatedRowIdx : rowCount + updatedRowIdx
      );
      const [_, response] = await Promise.all([
        saveWorkflowRecord(id || "", updatedData, updatedDataKeys),
        pastedData.length && saveWorkflowRecord(id || "", pastedData),
      ]);

      const newIds = response.dataIds;

      const newRows = [...dataRef.current];
      updatedData.forEach((row: any, idx: number) => {
        newRows[updatedRowIdx + idx] = {
          ...newRows[updatedRowIdx + idx],
          ...row,
        };
      });
      pastedData.forEach((row: any, idx: number) => {
        newRows.splice(updatedRowIdx + rowCount + idx, 0, {
          key: newIds[idx],
          run: "retry",
          createdAtt: moment().format(DISPLAY_DATE_TIME_FORMAT),
          ...row,
        });
      });

      setData(newRows);
      setPasteModal(false);
    },
    [dataRef.current]
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleTableChange: TableProps["onChange"] = (pagination, filters, sorter) => {
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      pagination.current = 1;
      setPaginationMove({ next: true, dataId: "" });
    } else if (
      pagination.current &&
      tableParams.pagination?.current &&
      pagination.current > tableParams.pagination?.current
    )
      setPaginationMove({
        next: true,
        dataId: dataRef.current[dataRef.current.length - 1].key,
      });
    else setPaginationMove({ next: false, dataId: dataRef.current[0].key });
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const viewerComponent = () => {
    switch (customViewer.type) {
      case "textFileURL":
        return (
          <TenKDocViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "table":
        return (
          <TableViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "review":
        return (
          <ReviewModal
            close={() => setCustomViewer({ ...customViewer, open: false })}
            data={customViewer.data}
            open={customViewer.open}
            title={customViewer.title}
            section={currentSectionRef.current}
            style={modalStyle}
            currentSectionRef={currentSectionRef}
          />
        );
      case "email":
        return (
          <WaterfallViewer
            close={() => setCustomViewer({ ...customViewer, open: false })}
            data={customViewer.data}
            open={customViewer.open}
            title={customViewer.title}
            credits={customViewer.credits}
            error={customViewer.errorMessage}
          />
        );
      case "linkedinProfile":
        return (
          <LinkedinProfileViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "linkedinPosts":
        return (
          <LinkedinPostViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "apolloEmployees":
        return (
          <ApolloEmployeeViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "salesNavEmployees":
        return (
          <SalesNavEmployeeViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "wells":
        return (
          <WellsViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
          />
        );
      case "crunchBaseFundraising":
        return (
          <CrunchBaseFundraisingViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      default:
        return (
          <JsonViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
    }
  };

  if (loading) {
    return (
      <div className="w-full h-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Table" />
      </div>
    );
  }

  const handleExport = async () => {
    setExporting(true);
    setDownloadLink("");
    const workflowId = id || "";
    try {
      const fileUrl = (await exportTableData(workflowId, selectedRef.current, currentSectionRef.current)) as string;
      setDownloadLink(fileUrl);
    } catch (err) {
      console.error("Error in exporting table data --> ", err);
      setExporting(false);
    }
  };

  const rowActionItems = [
    {
      label: (
        <div
          className={`${
            selectedRef.current.length > 0 ? "block" : "hidden"
          } flex items-center gap-x-2.5 rounded-sm px-1 py-1 font-semibold text-[#1c1c1c] w-fit justify-center z-10 text-[18px]`}
          onClick={() => handleRunRetry()}
        >
          <RunRowsIcon />
          {selectedRef.current.length === 0 ? (
            <span>Run Rows</span>
          ) : (
            <span>Run {selectedRef.current.length} row(s)</span>
          )}
        </div>
      ),
      key: "run selected rows",
    },
    {
      label: (
        <Popconfirm
          title="Delete the selected row(s)?"
          description="Are you sure?"
          icon={<QuestionCircleOutlined style={{ color: "#AA0000" }} />}
          onConfirm={handleDelete}
          okText="Yes"
          cancelText="Cancel"
          disabled={selectedRef.current.length === 0}
          okButtonProps={{ className: "bg-red-500 hover:bg-red-600" }}
          className={`${selectedRef.current.length > 0 ? "block" : "hidden"} gap-x-3 px-0`}
        >
          <button
            type="button"
            className="flex py-1 text-[#AA0000] hover:text-white items-center justify-center rounded-sm font-semibold text-[18px]"
          >
            <TrashIcon />
            {selectedRef.current.length === 0 ? (
              <span>Delete Rows</span>
            ) : (
              <span>Delete {selectedRef.current.length} row(s)</span>
            )}
          </button>
        </Popconfirm>
      ),
      danger: true,
      key: "delete selected rows",
    }, // remember to pass the key prop
  ];
  // const tableActionItems = [
  //   {
  //     label: (
  //       <button
  //         type="button"
  //         className="py-1 flex items-center justify-center rounded-sm disabled:cursor-not-allowed
  //           text-[#1c1c1c] gap-2 font-semibold w-full text-[18px]"
  //         onClick={() => {
  //           setChatNameModal(true);
  //         }}
  //         disabled={selectedForChat !== "all" && selectedForChat.length === 0}
  //       >
  //         <ChatIcon />
  //         {selectedForChat === "all" ? (
  //           <span>Chat with table</span>
  //         ) : (
  //           <span>Chat with {selectedForChat.length} completed row(s)</span>
  //         )}
  //       </button>
  //     ),
  //     key: "chat with selected rows",
  //   },
  //   {
  //     label:
  //       salesforceAction === "SALESFORCE_PULL_DATA" ? (
  //         <button
  //           type="button"
  //           className="flex py-1 items-center gap-2 rounded-sm disabled:cursor-not-allowed font-semibold text-[#1c1c1c] w-full justify-start text-[18px]"
  //           onClick={() => {
  //             handleSfDataPull();
  //           }}
  //         >
  //           {loadingSfData ? (
  //             <SpinnerStatus />
  //           ) : (
  //             <img
  //               src={
  //                 "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/salesforce.svg"
  //               }
  //               alt="Salesforce"
  //               className="w-6 h-6"
  //             />
  //           )}
  //           Pull Data
  //         </button>
  //       ) : (
  //         <CSVInput
  //           template={
  //             workflowActions.length > 0
  //               ? workflowActions[0].responseConfiguration
  //               : []
  //           }
  //           refresh={(): void => setRefreshing((p) => !p)}
  //           workflowId={id || ""}
  //         >
  //           <button
  //             type="button"
  //             className="disabled:cursor-not-allowed py-1 bg flex items-center gap-2 rounded-sm font-semibold text-[#1c1c1c] justify-start z-10 text-[18px] w-full"
  //             disabled={currentSectionRef.current !== 0}
  //           >
  //             <ImportIcon className="text-[#1c1c1c]" />
  //             Import CSV
  //           </button>
  //         </CSVInput>
  //       ),
  //     key: "import csv",
  //   },
  //   {
  //     label: (
  //       <button
  //         type="button"
  //         className="flex py-1 items-center gap-2 rounded-sm disabled:cursor-not-allowed font-semibold text-[#1c1c1c] w-full justify-start text-[18px]"
  //         onClick={handleExport}
  //         disabled={exporting}
  //       >
  //         {exporting ? <SpinnerStatus /> : <ExportCSVIcon />}
  //         Export to CSV
  //       </button>
  //     ),
  //     key: "export to csv",
  //   }, // remember to pass the key prop
  // ];

  return (
    <div className="relative w-full flex flex-col !m-0 bg-white flex-1">
      {exporting && (
        <dialog className="fixed inset-0 z-[200] bg-black bg-opacity-50 flex items-center justify-center backdrop-filter w-full h-full">
          <div className="flex flex-col min-w-[440px] min-h-[100px] bg-white px-4 py-2 rounded-md">
            <div className="flex items-center justify-between w-full mb-2 pb-2 border-b gap-2">
              <div className="flex items-center gap-2">
                <FiSave className="w-6 h-6" />
                <h3 className="text-lg font-semibold">Exporting Table Data</h3>
              </div>
              <button
                type="button"
                className="p-1 rounded-full hover:bg-gray-100"
                onClick={() => {
                  setDownloadLink("");
                  setExporting(false);
                }}
              >
                <IoClose size="1.5rem" />
              </button>
            </div>
            {downloadLink.length === 0 ? (
              <div className="flex flex-col items-center justify-center w-full my-2 gap-2">
                <div className="h-1.5 w-full bg-blue-100 overflow-hidden rounded-lg">
                  <div className="animate-progress w-full h-full bg-blue-500 origin-left-right"></div>
                </div>
                <span className="text-sm text-[#69717d]">Please wait while we export the table data</span>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center w-full my-2 gap-2">
                <span className="text-sm text-[#69717d]">Your file is ready for download</span>
                <button
                  type="button"
                  className="flex items-center justify-center px-4 py-2 font-semibold text-white bg-primary rounded-md w-full gap-2"
                  onClick={() => {
                    window.open(downloadLink, "_blank");
                    setExporting(false);
                    setDownloadLink("");
                  }}
                >
                  <FiDownload className="w-4 h-4" />
                  Download
                </button>
              </div>
            )}
          </div>
        </dialog>
      )}

      <section className="w-full flex items-center overflow-auto justify-between gap-4 p-4 bg-white border-b border-gray-200 relative">
        <div className="flex flex-row w-fit gap-4">
          <button
            type="button"
            className="disabled:cursor-not-allowed bg flex items-center gap-2 rounded-sm px-3 py-1.5 font-semibold text-[#1c1c1c] border border-gray-500 bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out w-fit justify-center z-10"
            onClick={handleAdd2}
            disabled={currentSectionRef.current !== 0}
          >
            <FaPlus className="text-[#1c1c1c]" />
            Add a row
          </button>
          {/* </div> */}
          {/* <div className="flex flex-row w-fit gap-4"> */}
          {(() => {
            switch (crmSyncAction) {
              case "SALESFORCE_PULL_DATA":
                return (
                  <button
                    type="button"
                    className="flex items-center gap-2 rounded-sm border border-gray-500 disabled:cursor-not-allowed disabled:bg-gray-100 px-3 py-1.5 font-semibold text-[#1c1c1c] bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out w-fit justify-center"
                    onClick={handleSfDataPull}
                    disabled={loadingSfData}
                  >
                    {loadingSfData ? (
                      <SpinnerStatus />
                    ) : (
                      <img
                        src={"https://storage.googleapis.com/public_image_assets/internal-web-app-logos/salesforce.svg"}
                        alt="Salesforce"
                        className="w-6 h-6"
                      />
                    )}
                    Import Records
                  </button>
                );
              case "ENGAGEBAY_CONTACT_ADDED":
                return (
                  <button
                    type="button"
                    className="flex items-center gap-2 rounded-sm border border-gray-500 disabled:cursor-not-allowed disabled:bg-gray-100 px-3 py-1.5 font-semibold text-[#1c1c1c] bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out w-fit justify-center"
                    onClick={handleEngagebayContactsPull}
                    disabled={loadingEngagebayContacts}
                  >
                    {loadingEngagebayContacts ? (
                      <SpinnerStatus />
                    ) : (
                      <img
                        src={"https://storage.googleapis.com/public_image_assets/internal-web-app-logos/engagebay.png"}
                        alt="Engagebay"
                        className="w-6 h-6"
                      />
                    )}
                    Import Contacts
                  </button>
                );
              default:
                return (
                  <CSVInput
                    template={workflowActions.length > 0 ? workflowActions[0].responseConfiguration : []}
                    refresh={(): void => setRefreshing((p) => !p)}
                    workflowId={id || ""}
                  >
                    <button
                      type="button"
                      className="disabled:cursor-not-allowed bg flex items-center gap-2 rounded-sm px-3 py-1.5 font-semibold text-[#1c1c1c] border border-gray-500 bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out w-fit justify-center z-10"
                      disabled={currentSectionRef.current !== 0}
                    >
                      <ImportIcon className="text-[#1c1c1c] w-4 h-4" />
                      Import CSV
                    </button>
                  </CSVInput>
                );
            }
          })()}
        </div>
        {/* <section className="w-fit absolute inset-[40vw] top-auto bottom-auto">
        
        </section> */}
        <section className="flex items-center justify-center gap-4">
          {sections > 0 && (
            <div className="flex gap-2 w-fit mx-auto">
              {Array.from({ length: sections + 1 }, (_, i) => i).map((section) => (
                <button
                  type="button"
                  key={section}
                  className={`px-4 py-2 flex items-center gap-2 rounded-lg border-2 border-gray-200
                  text-primary font-semibold ${
                    section === currentSectionRef.current
                      ? "bg-primary text-white"
                      : "bg-white text-primary hover:bg-primary/10"
                  }`}
                  onClick={() => {
                    setCurrentSection(section);
                  }}
                >
                  Section {section + 1}
                </button>
              ))}
            </div>
          )}
        </section>
        <section className="flex items-center justify-center gap-4">
          {refresh && (
            <span className="flex items-center justify-center gap-1">
              <SpinnerStatus />
              <span className="text-[#69717d] text-sm">Refreshing</span>
            </span>
          )}
          {selectedRef.current.length > 0 && (
            <Dropdown
              menu={{ items: rowActionItems }}
              trigger={["click"]}
              overlayClassName="rounded-lg px-0 divide-y-2 border"
              overlayStyle={{ width: "fit-content", padding: "0" }}
            >
              <button
                onClick={(e) => e.preventDefault()}
                className="disabled:cursor-not-allowed bg flex items-center gap-3 rounded-sm px-5 py-1.5 font-semibold text-[#0800C1] border border-[#9A96FF] bg-[#F7F7FF] hover:bg-[#eaeaff] transition-all duration-200 ease-in-out w-fit justify-center z-10 text-[18px]"
              >
                Row Actions
                <img src={DownArrowNew} alt="arrow down" width={14} />
              </button>
            </Dropdown>
          )}
          {/* a button to pull data from salesforce */}
          {/* <Dropdown
            menu={{ items: tableActionItems }}
            trigger={["click"]}
            overlayClassName="rounded-lg px-0 divide-y-2 border"
            className=""
          >
            <button
              onClick={(e) => e.preventDefault()}
              className="disabled:cursor-not-allowed bg flex items-center gap-3 rounded-sm px-5 py-1.5 font-semibold text-[#0800C1] border border-[#9A96FF] bg-[#F7F7FF] hover:bg-[#eaeaff] transition-all duration-200 ease-in-out w-fit justify-center z-10 text-[18px]"
            >
              Table Actions
              <img src={DownArrowNew} alt="arrow down" width={14} />
            </button>
          </Dropdown> */}
          <button
            type="button"
            className="px-3 py-1.5 flex items-center justify-center rounded-sm border border-gray-500 disabled:cursor-not-allowed disabled:bg-gray-100
            text-[#1c1c1c] bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out gap-2 font-semibold w-fit"
            onClick={() => {
              setChatNameModal(true);
            }}
            disabled={selectedForChat !== "all" && selectedForChat.length === 0}
          >
            <ChatIcon />
            {selectedForChat === "all" ? (
              <span>Chat with table</span>
            ) : (
              <span>Chat with {selectedForChat.length} completed row(s)</span>
            )}
          </button>
          <button
            type="button"
            className="flex items-center gap-2 rounded-sm disabled:cursor-not-allowed disabled:bg-gray-100
             px-3 py-1.5 font-semibold text-[#1c1c1c] border border-gray-500 bg-gray-100 hover:bg-gray-200 transition-all duration-200 ease-in-out w-fit justify-center"
            onClick={handleExport}
            disabled={exporting}
          >
            {exporting ? <SpinnerStatus /> : <ExportCSVIcon className="text-[#1c1c1c] font-bold" />}
            Export to CSV
          </button>
        </section>
      </section>

      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Lausanne !important",
          },
          components: {
            Table: {
              fontFamily: "Lausanne !important",
            },
          },
        }}
      >
        <CustomTable
          ref={tableRef}
          className="bg-white flex-1"
          sortDirections={["descend", "ascend"]}
          components={components}
          scroll={{ y: "calc(100vh - 16.7rem)", x: numOfActions * 200 + 100 }}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selected,
            onChange: (_selectedRowKeys, selectedRows) => {
              if (selectedRows.length === 0) {
                setSelectedForChat("all");
                setSelected([]);
              } else {
                setSelectedForChat(getDataIdsComleted(selectedRows));
                setSelected(getDataIds(selectedRows));
              }
            },
          }}
          rowClassName="editable-row !bg-white !font-Lausanne"
          bordered
          dataSource={data}
          columns={columns as ColumnTypes}
          pagination={{
            simple: true,
            ...tableParams.pagination,
            total: numOfRows,
            className: "sticky bottom-0 bg-white z-10 p-4 border-t border-gray-200",
            showSizeChanger: true,
          }}
          // @ts-ignore
          onChange={handleTableChange}
        />
      </ConfigProvider>
      {viewerComponent()}
      {chatNameModal && (
        <ChatNameModal
          selected={selectedForChat === "all" ? getDataIdsComleted(data) : selectedForChat}
          setChatNameModal={setChatNameModal}
          userWorkflowId={id || ""}
          setTab={setTab}
        />
      )}
      <PasteRowsModal modal={pasteModal} />
      <DedupeModal
        modal={dedupeModal}
        dataLoading={dedupeDataLoading}
        close={() => setDedupeModal(false)}
        duplicateData={dedupeData}
        workflowId={id || ""}
        refresh={() => setRefreshing((p) => !p)}
        numOfRows={numOfRows}
      />
      <ErrorModal open={errorModal} close={() => setErrorModal(false)} errorData={errorData} />
    </div>
  );
};

export const ImportIcon = ({ className }: { className?: string }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M14.6429 8.85825V6.31039L9.76191 10.4104V0.785156H7.80952V10.4104L2.92857 6.31039V8.85825L8.78571 13.7392L14.6429 8.85825ZM0 15.428V17.3804H17.5714V15.428H0Z"
      fill="#202020"
    />
  </svg>
);

export const ChatIcon = ({ className }: { className?: string }) => (
  <svg width="18" height="18" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M4.75 0H0V4.75H4.75V0ZM19 9.5H0V14.25H19V9.5ZM19 9.5H4.75V4.75H9.5V0H19V9.5ZM4.75 19V14.25H9.5L4.75 19Z"
      fill="#2D2E2E"
    />
  </svg>
);

export const TrashIcon = ({ className }: { className?: string }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M17.5933 18.9403H9.67525L8.84962 7.61174H6.86015L7.82504 21H19.4435L20.4084 7.61174H18.419L17.5933 18.9403ZM22 3.02884L21.6618 1L16.5688 1.91658L15.574 1.05149L10.7396 1.93718L10.093 3.10093L5 4.01751L5.34816 6.05664L22 3.02884Z"
      fill="#AA0000"
    />
  </svg>
);
export const RunRowsIcon = ({ className }: { className?: string }) => (
  <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M18.7292 10.8113L0 0V21.6225L18.7292 10.8113ZM2.58333 4.46917L13.5625 10.8113L2.58333 17.1533V4.46917Z"
      fill="#202020"
    />
  </svg>
);
export const ExportCSVIcon = ({ className }: { className?: string }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M14.6429 9.30776V11.8556L9.76191 7.75562V17.3809H7.80952V7.75562L2.92857 11.8556V9.30776L8.78571 4.42681L14.6429 9.30776ZM0 2.738V0.78562H17.5714V2.738H0Z"
      fill="#202020"
    />
  </svg>
);

export default TableTab;
