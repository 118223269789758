import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IFiltersProps } from "@/Components/UserWorkflows/create/Components/filter.schema";
import { filters } from "@/Components/UserWorkflows/create/Components/filters";
import { filtersToParams } from "@/Components/UserWorkflows/create/Components/utils";
import SearchFilterBase from "@/Components/UserWorkflows/create/Components/AppliedFilters";
import ButtonAddFilter from "@/Components/UserWorkflows/create/Components/AddFilterButton";
import { createSignal } from "../../apis";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";
import LoaderButton from "@/Components/LoaderButton";

interface SalesNavigatorProps {
  name: string;
  signalId: string;
  preview?: boolean;
  generatePreview: (payload: any) => Promise<void>;
}

const order_by = [
  {
    desc: true,
    field: "confidence",
  },
  {
    desc: true,
    field: "jobs",
  },
  {
    desc: true,
    field: "num_jobs",
  },
];

const CompaniesListComponent = ({ name, signalId, preview, generatePreview }: SalesNavigatorProps): React.JSX.Element => {
  const navigate = useNavigate();
  const [appliedFilters, setAppliedFilters] = useState<IFiltersProps[]>([]);
  const [maxCount, setMaxCount] = useState<number>();

  useEffect(() => {
    const missingPermanentFilters = filters
      .filter((filter) => filter.is_permanent)
      .map((filter) => ({ ...filter, operator: filter.availableOperators[0] }));
    setAppliedFilters(missingPermanentFilters);
  }, []);
  
  const addFilters = (filters: IFiltersProps[]) => {
    const newFilters = [...appliedFilters, ...filters];
    setAppliedFilters(newFilters);
  };

  const onRemoveFilter = (index: number) => {
    const newFilters = [...appliedFilters];
    newFilters.splice(index, 1);
    setAppliedFilters(newFilters);
  };

  const onChangeFilter = (index: number, filter: IFiltersProps) => {
    const newFilters = [...appliedFilters];
    newFilters[index] = filter;
    newFilters[index].hasChanged = true;
    setAppliedFilters(newFilters);
  };

  const isFilterRemovable = (filter: IFiltersProps) => {
    const exist_job_filter = appliedFilters.some(
      (f) => f.id.startsWith("job_filters.") && f.id !== "job_filters.posted_at"
    );
    if (filter.id === "job_filters.posted_at" && exist_job_filter) {
      return false;
    }
    return true;
  };

  const isFilterPermanent = (filter: IFiltersProps, index: number, appliedFilters: IFiltersProps[]) => {
    if (filter.is_permanent) {
      const firstOcurrenceSameId = appliedFilters.findIndex((appliedFilter) => appliedFilter.id === filter.id);
      if (firstOcurrenceSameId == index) return true;
    }
    return false;
  };

  const handleGeneratePreview = async () => {

    try {
      const params = filtersToParams(appliedFilters, order_by);
      generatePreview(params);
      setAppliedFilters(appliedFilters.map((filter) => ({ ...filter, hasChanged: false })));
    } catch (e) {
      console.error(e);
    }
  };

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="flex flex-col overflow-hidden h-[90vh]">
        <div className="flex flex-col space-y-6 overflow-y-auto h-full">
          <div className="flex flex-col gap-6 px-10">
            <p className="text-xl font-semibold">Add the filters to refine your companies search</p>
            <div className="flex flex-wrap items-center gap-2">
              <input
                type="number"
                className="broder border-gray-200 rounded-md h-9 px-4 w-full shadow"
                placeholder="Number of companies to import"
                value={maxCount}
                onChange={(e) => setMaxCount(Number(e.target.value) || undefined)}
              />
              <div className="flex flex-col gap-2 w-full">
                {appliedFilters.map((filter, index) => (
                  <SearchFilterBase
                    key={index}
                    index={index}
                    filter={filter}
                    changeFilter={onChangeFilter}
                    removeFilter={onRemoveFilter}
                    appliedFilters={appliedFilters}
                    isRemovable={isFilterRemovable(filter)}
                    isPermanent={isFilterPermanent(filter, index, appliedFilters)}
                    hasChanged={filter.hasChanged || false}
                  />
                ))}
              </div>
              <ButtonAddFilter availableFilters={filters} appliedFilters={appliedFilters} addFilters={addFilters} />
            </div>

            {preview && (
              <LoaderButton
                text="Generate Preview"
                loaderText="Generating Preview..."
                btnClasses="mt-auto "
                onClickCallback={async () => {
                  // check if all required payloads are present
                  await handleGeneratePreview();
                  return;
                }}
                error=""
                disabled={false}
              />
            )}
            <LoaderButton
              text="Create Signal"
              loaderText="Creating Signal..."
              btnClasses="mt-auto"
              onClickCallback={async () => {
                const params = filtersToParams(appliedFilters, order_by);

                const paramsWithCount = { params, maxCount: maxCount || 0 };
                const newId = await createSignal(
                  SIGNAL_ID_TO_URL[signalId as keyof typeof SIGNAL_ID_TO_URL],
                  paramsWithCount,
                  name
                );
                navigate(`/list/${newId}`);
                return
              }}
              error=""
              disabled={false}
            />
          </div>
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default CompaniesListComponent;
