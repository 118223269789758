import moment from "moment";
import {
  CELL_STATUS_TYPE,
  DISPLAY_DATE_TIME_FORMAT,
  FOLDERS_APPLICABLE_FOR_PROMPTS,
  LOADER_TYPES,
  firebaseAccessToken,
  firebaseRefreshToken,
} from "./constants.tsx";
import { ActionResponses, ApiPrice, ResponseStructure, Workflow } from "./interfaces.ts";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const validateEmail = (str: string): boolean => {
  const arr: string[] = str.split("@");
  return arr.length === 2;
};

const defaultLoaderMsg = (payload: string) => `Loading ${payload}...`;

const generatingLoaderMsg = (payload: string) => `Generating ${payload}...`;

const fetchingLoaderMsg = (payload: string) => `Fetching ${payload}...`;

const pastingLoaderMsg = (payload: string) => `Saving ${payload}...`;

export const getLoaderMsg = (payload: string, type: LOADER_TYPES) => {
  switch (type) {
    case LOADER_TYPES.generating:
      return generatingLoaderMsg(payload);
    case LOADER_TYPES.fetching:
      return fetchingLoaderMsg(payload);
    case LOADER_TYPES.loading:
      return defaultLoaderMsg(payload);
    case LOADER_TYPES.pasting:
      return pastingLoaderMsg(payload);
    default:
      return payload;
  }
};

export const updateAccessToken = (newAccessToken: string, oldAccessToken: string) => {
  try {
    if (newAccessToken === oldAccessToken) return;
    document.cookie = `${firebaseAccessToken}=${newAccessToken};max-age=157680000;`;
  } catch (err) {
    console.log("Error in updating access token --> ", err);
    throw err;
  }
};

export const updateRefreshToken = (newRefreshToken: string, oldRefreshToken: string) => {
  try {
    if (newRefreshToken === oldRefreshToken) return;
    document.cookie = `${firebaseRefreshToken}=${newRefreshToken};`;
  } catch (err) {
    console.log("Error in updating refresh token --> ", err);
    throw err;
  }
};

/**
 * @description Returns the auth tokens as stored in browser cookies
 * @returns [accessToken, refreshToken]
 */

export const getBrowserTokens = () => {
  const cookies = document.cookie.split(";");
  const tokens: { [key: string]: string } = {};
  cookies.forEach((cookie) => {
    const [key, value] = cookie.split("=");
    tokens[key.trim()] = value;
  });
  return [tokens[firebaseAccessToken], tokens[firebaseRefreshToken]];
};

export const handleUpdateTokens = (data: any, accessToken: string, refreshToken: string) => {
  try {
    const newAccessToken = data.accessToken;
    const newRefreshToken = data.refreshToken;
    newAccessToken && updateAccessToken(newAccessToken, accessToken);
    newRefreshToken && updateRefreshToken(newRefreshToken, refreshToken);
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getIndex = (idx: number): string => {
  try {
    if (idx < 10) return `0${idx}`;
    return `${idx}`;
  } catch (err) {
    alert(err);
    return "";
  }
};

export const getDataIdsNotCompleted = (data: any[]): string[] => {
  try {
    const res: string[] = data
      .filter((row) => {
        delete row.createdAt;
        return (
          Object.values(row).some(
            (rowData: any) =>
              rowData &&
              rowData.status &&
              (rowData.status === CELL_STATUS_TYPE.PAYLOAD_FILLED ||
                rowData.status === CELL_STATUS_TYPE.CHECKING_NEXT_SOURCE)
          ) || row.run === "loading"
        );
      })
      .map((row) => row.key);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDataIdsComleted = (data: any[]): string[] => {
  try {
    const res: string[] = data
      .filter((row) => {
        delete row.createdAt;
        return Object.values(row).every((rowData: any) => {
          typeof rowData === "object" ? rowData.status === CELL_STATUS_TYPE.COMPLETED : true;
        });
      })
      .map((row) => row.key);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getDataIds = (data: any[]): string[] => {
  try {
    const res: string[] = data.map((row) => row.key);
    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getCurrIdxForDataEntry = (data: any[], dataId: string) => {
  try {
    const idx = data.findIndex((row) => row.key == dataId);
    return idx;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const getUpdatedRows = async (
  currTableRows: any[],
  workflowData: any,
  workflowId: string,
  userActions: ActionResponses[]
) => {
  try {
    const rows: any[] = [];
    await Promise.all(
      Object.entries(workflowData).map(async ([dataId, item]: [string, any]) => {
        const row: any = {};
        item.id = null;
        const status: string[] = [];
        const updateIdx = getCurrIdxForDataEntry(currTableRows, dataId);
        await Promise.all(
          Object.entries(item).map(async ([actionId, data]: [string, any]) => {
            if (actionId === "id") return;
            const action = userActions.find((action) => action.actionId === actionId);
            if (action && (action.type === "input" || action.type === "add_section")) {
              const actionResponseMap: Record<string, string> = {};
              action.response.forEach((res: ResponseStructure) => {
                actionResponseMap[res.responseId] = res?.name;
              });

              data.response.map((res: any) => {
                row[res.responseId] = res.value;
              });
              if (data?.createdAt) {
                row["createdAtt"] = moment.utc(data?.createdAt).local().format(DISPLAY_DATE_TIME_FORMAT);
              }
              row.responseMap = Object.fromEntries(
                Object.entries(actionResponseMap).map(([key, value]) => [value, key])
              );
            } else if (["createdAt"].includes(actionId)) {
              row["createdAtt"] = moment.utc(data).local().format(DISPLAY_DATE_TIME_FORMAT);
            } else if (["sectionInfo"].includes(actionId)) return;
            else {
              status.push(data.status);
              let temp: any = {};
              let respArray = [];
              if (typeof data.response === "string" && isApplicableURL(data.response))
                respArray = await fetchDocContent(data.response);
              else respArray = data.response || [];
              respArray.map((r: any) => {
                temp[r.responseId] = {
                  ...(action?.response.find((res) => res.responseId === r.responseId) || {}),
                  value: r.value,
                };
              });
              if (data.stepDownResponse) temp.stepDownResponse = data.stepDownResponse;

              if (data.error) temp.error = data.error;
              temp = {
                ...temp,
                status: data.status,
                extra: {
                  reviewNeeded: action?.reviewNeeded || false,
                  reviewed: data.reviewed || false,
                  index: updateIdx,
                  workflowId: workflowId,
                  actionId,
                  dataId,
                  reviewAllowed: action?.reviewAllowed || false,
                },
              };
              row[actionId] = temp;
            }
          })
        );
        if (status.includes(CELL_STATUS_TYPE.FAILED)) row.run = "retry";
        else if (status.includes(CELL_STATUS_TYPE.READY_TO_BE_REVIEWED)) row.run = "review";
        else if (
          // status.includes(CELL_STATUS_TYPE.PENDING) ||
          status.includes(CELL_STATUS_TYPE.MISSING) ||
          status.includes(CELL_STATUS_TYPE.CHECKING_NEXT_SOURCE) ||
          status.includes(CELL_STATUS_TYPE.PAYLOAD_FILLED) ||
          status.includes(CELL_STATUS_TYPE.RETRYING)
        )
          row.run = "loading";
        else row.run = "retry";

        row.key = dataId;

        rows.push(row);
      })
    );
    return rows;
  } catch (err) {
    console.error(err);
  }
};

export const sameTimeStamp = (str1: string, str2: string): boolean => {
  try {
    return moment(str1).isAfter(str2) || moment(str1).isSame(str2);
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const isApplicableURL = (url: string) => {
  return FOLDERS_APPLICABLE_FOR_PROMPTS.some((folder) => url.includes(folder));
};

export const fetchDocContent = async (url: any) => {
  try {
    const data = await axios.get(url, {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    // const data = await fetch(url, {
    //   method: "GET",
    //   headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Content-Type": "application/json",
    //   },
    // });

    const res = await data.data;

    return res;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const ancestors = (graph: dagre.graphlib.Graph, node: any): any[] => {
  const up = graph.predecessors(node);
  if (!up) return [];
  return up.concat(
    up.reduce((sum, u): any => {
      return sum.concat(ancestors(graph, u) as any);
    }, [])
  );
};

export const getStartAndEndDate = (monthString: string) => {
  // Split the string to get year and month
  const [year, month] = monthString.split("-").map(Number);

  // Create a Date object for the first day of the month
  const startDate = new Date(Date.UTC(year, month - 1, 1));

  // Create a Date object for the last day of the month
  const endDate = new Date(Date.UTC(year, month, 0));

  // Format the dates
  const formatDate = (date: Date) => {
    const isoString = date.toISOString();
    return isoString.replace("T", " ").replace("Z", "+00");
  };

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  };
};

export const getWorkflokDropdownListFromWorkflows = (workflows: Workflow[]) => {
  const list = workflows?.map((Workflow) => {
    return {
      value: Workflow.id,
      label: Workflow.publishedWorkflowConfig.name,
    };
  });

  return list;
};

export const getActionDropdownListFromApiPricing = (apiPricing: ApiPrice[]) => {
  const list = apiPricing.map((pricing) => {
    return {
      value: `${pricing?.id}`,
      label: `${pricing?.apiName}`,
    };
  });

  return list;
};

export const getEmailFromToken = () => {
  const [accessToken] = getBrowserTokens();
  const decoded: any = jwtDecode(accessToken);

  return decoded?.email || "";
};
