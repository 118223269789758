import { message, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoaderButton from "@/Components/LoaderButton";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IFiltersProps } from "@/Components/UserWorkflows/create/Components/filter.schema";
import { getTotalItems } from "./SalesNavList";
import SearchFilterBase from "@/Components/UserWorkflows/create/Components/AppliedFilters";
import ButtonAddFilter from "@/Components/UserWorkflows/create/Components/AddFilterButton";
import { crustDataLinkedinFilters } from "@/Components/UserWorkflows/create/Components/filters";
import { createSignal } from "../../apis";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";

interface Props {
  name: string;
  signalId: string;
  preview?: boolean;
  generatePreview: (payload: any) => Promise<void>;
  data: any;
}

const CrustDataListViaFilters: React.FC<Props> = ({ name, signalId, generatePreview, preview, data }) => {
  const navigate = useNavigate();
  const [appliedFilters, setAppliedFilters] = useState<IFiltersProps[]>([]);
  const [totalItems, setTotalItems] = useState<number>();
  const [pageCount, setPageCount] = useState<number>(1);

  useEffect(() => {
    const total = data?.metadata?.total_results || 0;
    setTotalItems(total);
  }, [totalItems]);

  const isFilterRemovable = (filter: IFiltersProps) => {
    const exist_job_filter = appliedFilters.some(
      (f) => f.id.startsWith("job_filters.") && f.id !== "job_filters.posted_at"
    );
    if (filter.id === "job_filters.posted_at" && exist_job_filter) {
      return false;
    }
    return true;
  };

  const isFilterPermanent = (filter: IFiltersProps, index: number, appliedFilters: IFiltersProps[]) => {
    if (filter.is_permanent) {
      const firstOcurrenceSameId = appliedFilters.findIndex((appliedFilter) => appliedFilter.id === filter.id);
      if (firstOcurrenceSameId == index) return true;
    }
    return false;
  };

  const onChangeFilter = (index: number, filter: IFiltersProps) => {
    const newFilters = [...appliedFilters];
    newFilters[index] = filter;
    newFilters[index].hasChanged = true;
    setAppliedFilters(newFilters);
  };

  const onRemoveFilter = (index: number) => {
    const newFilters = [...appliedFilters];
    newFilters.splice(index, 1);
    setAppliedFilters(newFilters);
  };

  const addFilters = (filters: IFiltersProps[]) => {
    const newFilters = [...appliedFilters, ...filters];
    setAppliedFilters(newFilters);
  };

  const convertFilters = (filters: any[]) => {
    return filters.map((filter) => {
      const newFilter: any = {
        filter_type: filter.id,
        type: filter.operator === "not_in" ? "not in" : filter.operator,
        value: Array.isArray(filter.value) ? filter.value : [filter.value],
      };

      if (filter.data_type === "options-subfilter") {
        newFilter.sub_filter = filter.value;
        newFilter.value =
          filter.subValue.length === 1
            ? { min: 0, max: filter.subValue[0] }
            : { min: filter.subValue[0], max: filter.subValue[1] };
      }

      return newFilter;
    });
  };

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="py-5 px-10 overflow-hidden flex flex-col h-screen">
        <div className="flex flex-col">
          <div className="flex min-w-fit items-start">
            <div className="flex flex-col gap-3 items-center min-w-fit">
              <div className="flex items-center gap-3">
                <p>Import</p>
                <Tooltip title="Each page includes upto 25 companies">
                  <input
                    type="number"
                    className="broder border-gray-200 rounded-md h-9 px-4 w-50 shadow"
                    placeholder="Number of pages to import"
                    value={pageCount}
                    onChange={(e) => setPageCount(Number(e.target.value))}
                    max={99}
                    min={1}
                  />
                </Tooltip>
                <p>page(s)</p>
              </div>
              <p>using the following filters</p>
            </div>
          </div>
          {/* <p className="text-xl font-semibold">Add the filters to refine your companies search</p> */}
          <div className="flex flex-col gap-2 mt-6">
            {appliedFilters.map((filter, index) => (
              <SearchFilterBase
                key={index}
                index={index}
                filter={filter}
                changeFilter={onChangeFilter}
                removeFilter={onRemoveFilter}
                appliedFilters={appliedFilters}
                isRemovable={isFilterRemovable(filter)}
                isPermanent={isFilterPermanent(filter, index, appliedFilters)}
                hasChanged={filter.hasChanged || false}
              />
            ))}
            <ButtonAddFilter
              availableFilters={crustDataLinkedinFilters}
              appliedFilters={appliedFilters}
              addFilters={addFilters}
            />
          </div>
        </div>
        <div className="flex flex-col gap-3 mt-4">
          {preview && (
            <LoaderButton
              text="Generate Preview"
              loaderText="Generating Preview..."
              btnClasses="mt-auto"
              onClickCallback={async () => {
                // check if all required payloads are present
                // if (requiredPayloads.some((payload: any) => !payloads[payload.payloadStructureId])) {
                //   message.error("Please fill all required fields");
                //   return;
                // }
                const filters = convertFilters(appliedFilters);
                if (filters.length === 0) {
                  message.error("Please add filters");
                  return;
                }
                await generatePreview({ filters: filters, type: "filters" });
                return;
              }}
              error=""
              disabled={false}
            />
          )}
          <LoaderButton
            text="Create Signal"
            loaderText="Creating Signal..."
            btnClasses="mt-auto"
            onClickCallback={async () => {
              const filters = convertFilters(appliedFilters);
              if (filters.length === 0) {
                message.error("Please add filters");
                return;
              }

              const newId = await createSignal(
                SIGNAL_ID_TO_URL[signalId as keyof typeof SIGNAL_ID_TO_URL],
                { filters: filters, page: getTotalItems(totalItems || 0, pageCount), type: "filters" },
                name
              );
              navigate(`/list/${newId}`);
            }}
            error=""
            disabled={false}
          />
        </div>
      </div>
    </QueryClientProvider>
  );
};

export default CrustDataListViaFilters;
