import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useWorkflow } from "../../../../contexts/WorkflowContext";
import {
  getTableInfoForWorkflowV2,
  savePagination,
  stopAllWorkflowActions,
  triggerEngagebayPullContacts,
  triggerHubspotPullContacts,
  triggerSalesforcePullData,
} from "@/utils/apis";
import { ActionResponses } from "@/utils/interfaces";
import useStateRef from "react-usestateref";
import { mapColumns, mapRows, normalizeRowData, ViewerProps, Sorter, getSelectedRows } from "./TableHandlers";
import { isProd, LOADER_TYPES, TabEnum } from "@/utils/constants";
import ChatNameModal from "../TableChat/ChatNameModal";
import { renderToString } from "react-dom/server";
import Loader from "@/Components/Loader";
import { Pagination, message } from "antd";
import { deleteRecords } from "@/utils/apis";
import { getDataIds, getDataIdsNotCompleted } from "@/utils/functions";
import { CellEditingStoppedEvent, ColumnVisibleEvent } from "ag-grid-community";
import ErrorModal from "../../Modals/ErrorModal";
import DedupeModal from "../../Modals/NewDedupeModal";
import { useDebounce } from "use-debounce";
import TableActions from "./TableActions";
import "ag-grid-enterprise";

interface Props {
  setTab: React.Dispatch<React.SetStateAction<TabEnum>>;
}

const TableAG: React.FC<Props> = ({ setTab }) => {
  const [sections, setSections] = useState<number>(0);
  const [currentAction, _setCurrentAction] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sorters, setSorters] = useState<Sorter[] | null>(null);
  const [workflowData, setWorkflowData] = useState<any[]>([]);
  const [responses, setResponses] = useState<ActionResponses[]>([]);
  const [actions, setActions] = useState<ActionResponses[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [exporting, setExporting] = useState<boolean>(false);
  const [downloadLink, setDownloadLink] = useState<string>("");
  const [chatNameModal, setChatNameModal] = useState<boolean>(false);
  const [colunmsData, setColumnsData] = useState([]);
  const [columnWidths, setColumnWidths] = useState({});
  const [filters, setFilters] = useState<any | null>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [loadTrigger, setLoadTrigger] = useState(false);
  const [pagination, setPagination] = useState<{ currentPage: number; pageSize: number | null; total: number }>({
    currentPage: 1,
    pageSize: null,
    total: 0,
  });
  const [searchedText, setSearchedText] = useState<string>("");
  const [debouncedSearched] = useDebounce(searchedText, 1000);
  const [initDebounce, setInitDebounce] = useState<boolean>(true);
  // manual dedupe
  const [dedupeModal, setDedupeModal] = useState<boolean>(false);
  const [dedupeDataLoading, setDedupeDataLoading] = useState<boolean>(false);
  const [dedupeData, setDedupeData] = useState<any>([]);
  const [dedupeOrder, setDedupeOrder] = useState<"asc" | "desc">("asc");

  const [customViewer, setCustomViewer] = useState<ViewerProps>({
    open: false,
    data: {},
    title: () => <></>,
    type: "",
    credits: 0,
    errorMessage: "",
  });

  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>({});
  const [modalStyle, setModalStyle] = useState({});
  const [currentSection, setCurrentSection, currentSectionRef] = useStateRef<number>(0);
  const [createRows, setCreateRows] = useState<number>(1);
  const [firstTime, setFirstTime] = useState<boolean>(true);

  const [crmSyncAction, setCrmSyncAction] = useState<string>("");
  const [loadingEngagebayContacts, setLoadingEngagebayContacts] = useState(false);
  const [loadingHubspotContacts, setLoadingHubspotContacts] = useState<boolean>(false);
  const [loadingSfData, setLoadingSfData] = useState<boolean>(false);

  const [tempClipboard, setTempClipboard] = useState<any[]>([]);

  const grid = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const [copied, _setCopied] = useState(false);

  const { id } = useParams();

  const {
    data,
    dataRef,
    setData,
    setUserActions,
    retryWorkflowForData,
    retryAction,
    refreshSection,
    updateWorkflowRunDataUtil,
    exportTableData,
    dedupeRowsCheck,
    saveWorkflowRecord,
    errorEncountered,
  } = useWorkflow();

  useEffect(() => {
    if (firstTime) {
      if (!id) return;
      const temp = localStorage.getItem(id);
      const savedProps = temp ? JSON.parse(temp) : {};
      setHiddenColumns(savedProps?.hiddenColumns || []);
      return;
    }
    const tot = setInterval(() => {
      if (!dataRef.current.length) return;
      const dataIds = getDataIdsNotCompleted(dataRef.current);
      if (!dataIds.length) {
        return;
      }
      updateWorkflowRunDataUtil(id || "", dataIds, currentSectionRef.current);
    }, 7000);
    return () => clearInterval(tot);
  }, [firstTime]);

  useEffect(() => {
    if (!errorEncountered) {
      return;
    }

    messageApi.open({
      type: "error",
      content: "Experienced an un expected error, reloading data",
      duration: 5,
    });
    setLoading(true);
    setTimeout(() => {
      setLoadTrigger((prev) => !prev);
    }, 7000);
  }, [errorEncountered]);

  useEffect(() => {
    if (initDebounce) {
      setInitDebounce(false);
      return;
    }
    setPagination({ ...pagination, currentPage: 1 });
    setLoadTrigger((prev) => !prev);
    setLoading(true);
  }, [debouncedSearched]);

  useEffect(() => {
    resizeTable();
    window.addEventListener(
      "resize",
      () => {
        resizeTable();
      },
      true
    );
  }, []);

  const resizeTable = () => {
    const tableWrapper = document.getElementById("table_wrapper") as HTMLDivElement | null;
    const tableActionsWrapper = document.getElementById("table_actions_wrapper") as HTMLDivElement | null;
    const paginationWrapper = document.getElementById("pagination_wrapper") as HTMLDivElement | null;
    const table = document.getElementById("table") as HTMLDivElement | null;

    if (!tableWrapper || !tableActionsWrapper || !table || !paginationWrapper) {
      return;
    }
    const wrapperHeight = tableWrapper.offsetHeight;
    const actionsHeight = tableActionsWrapper.offsetHeight;
    const paginationHeight = paginationWrapper.offsetHeight;
    table.style.height = `${wrapperHeight - actionsHeight - paginationHeight}px`;
  };

  const handleHubspotPullContacts = async () => {
    setLoadingHubspotContacts(true);
    setLoading(true);
    await triggerHubspotPullContacts(id || "");
    setLoadingHubspotContacts(false);
    setLoadTrigger((prev) => !prev);
  };

  const handleSfDataPull = async () => {
    setLoadingSfData(true);
    setLoading(true);
    await triggerSalesforcePullData(id || "");
    setLoadingSfData(false);
    setLoadTrigger((prev) => !prev);
  };

  const handleEngagebayContactsPull = async () => {
    setLoadingEngagebayContacts(true);
    setLoading(true);
    await triggerEngagebayPullContacts(id || "");
    setLoadingEngagebayContacts(false);
    setLoadTrigger((prev) => !prev);
  };

  const loadTableData = async (
    id: string,
    currentSection: number,
    pageSize: number | null,
    currentPage: number,
    filters: any,
    sorters: Sorter[] | null,
    searchedText: string
  ) => {
    getTableInfoForWorkflowV2(
      id,
      currentSection,
      pageSize,
      currentPage || 1,
      filters,
      sorters,
      searchedText || null
    ).then((datas) => {
      let sects = 0;
      const queue = ["id1"];
      const resp: any[] = datas?.responses;
      const actions: ActionResponses[][] = [];

      while (queue.length > 0) {
        const actionId = queue.shift();
        if (!actionId) continue;
        const action = resp.find((a: any) => a.actionId === actionId || a.fsWfActionId === actionId);
        if (!action) continue;
        if (action.type === "add_section") sects += 1;
        if (actions[sects]) actions[sects].push(action);
        else actions.push([action]);
        const childIds = action.nextAction.map((a: any) => (a && typeof a === "object" ? a.actionId : a));
        queue.push(...childIds);
      }

      setSections(sects);
      setUserActions(actions);
      setActions(actions[currentSectionRef.current]);
      setWorkflowData(datas.data);
      setResponses(datas.responses);
      setSorters(datas.sorter);
      setPagination((prev) => ({ ...prev, pageSize: datas.pagination, total: datas.numOfRows }));
      setFilters(datas.filters);
      setFirstTime(false);

      if (loading) {
        setLoading(false);
      }
    });
  };

  const updateRow = (row: any) => {
    const prev = dataRef.current;

    const tempRows = prev.map((r: any) => {
      if (r.key === row.key) {
        return row;
      }
      return r;
    });

    setData(tempRows);
  };

  const updateRows = (rows: any[] | "all") => {
    return;
    const prev = dataRef.current;

    if (rows === "all") {
      const tempRows = prev.map((r: any) => {
        r.run = "loading";
        return r;
      });

      setData(tempRows);

      return;
    }

    const rowKeys: string[] = selectedRows?.map((row) => row.key);

    const tempRows = prev.map((r: any) => {
      if (rowKeys.includes(r.key)) {
        r.run = "loading";
      }
      return r;
    });

    setData(tempRows);
  };

  const handleDelete = async () => {
    const selectedRowsIds = selectedRows?.map((row) => row.key);
    setLoading(true);
    deleteRecords(id || "", selectedRows?.length === 0 ? getDataIds(data) : selectedRowsIds)
      .then((_) => {
        setLoadTrigger((prev) => !prev);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleRun = async (recordKey: any[], inputs: any[]) => {
    const chunkSize = isProd ? 30 : 10;
    const dataChunks = [];
    const dataIdsChunks = [];

    if (recordKey?.length < chunkSize) {
      await retryWorkflowForData(id || "", recordKey, inputs, currentSectionRef.current);
      setLoading(false);
      return;
    }

    for (let i = 0; i < data.length; i += chunkSize) {
      const dataChunk = inputs.slice(i, i + chunkSize);
      const dataIdsChunk = recordKey.slice(i, i + chunkSize);
      dataChunks.push(dataChunk);
      dataIdsChunks.push(dataIdsChunk);
    }

    for (let i = 0; i < dataChunks.length; i++) {
      dataChunks[i] &&
        (await retryWorkflowForData(id || "", dataIdsChunks[i], dataChunks[i], currentSectionRef.current));
      if (i === 0) {
        setLoading(false);
      }
    }
  };

  const handleRunCell = async (actionId: string, dataId: string) => {
    // TODO: add filters here
    await retryAction(actionId, id || "", [dataId], [dataId], currentSectionRef.current, "singleCell", {});
  };

  const runMultipleRows = async () => {
    const data = selectedRows.map((row) => {
      const temp: any = {};
      const { responseMap } = row;
      for (let key in responseMap) {
        temp[responseMap[key]] = row[key] || "";
      }
      return temp;
    });
    const dataIds = selectedRows?.map((row) => {
      return row.key;
    });

    setLoading(true);
    handleRun(dataIds, data);

    const temp = dataRef.current.map((row) => {
      if (dataIds.includes(row.key)) {
        // row.run = "loading";
      }
      return row;
    });

    setData(temp);
    grid.current.api.deselectAll();
  };

  const [dedupeColumnId, setDedupeColumnId] = useState<string>("");

  const handleDedupeInput = async (columnId: string) => {
    if (dataRef.current.length <= 1) {
      return;
    }

    setDedupeDataLoading(true);
    setDedupeModal(true);
    setDedupeColumnId(columnId);
    const dataa: any = await dedupeRowsCheck(id || "", currentSectionRef.current, columnId, dedupeOrder);

    setDedupeData(dataa.duplicates);
    setDedupeDataLoading(false);
  };

  const handleEdit = async (
    event: CellEditingStoppedEvent<
      {
        field: string;
        id: string;
      },
      any
    >
  ) => {
    // const { data } = event as any;
    // @ts-ignore
    const { id: columnId } = event.colDef;
    let responseKey = columnId;
    // @ts-ignore
    const dataID = event.data?.key || "";
    const newValue = { [responseKey]: event.newValue };
    // const prev = dataRef.current;
    // setData(
    //   prev.map((row) => {
    //     if (row.key === data.key) {
    //       data.run = "loading";
    //       return data;
    //     }
    //     return row;
    //   })
    // );

    saveWorkflowRecord(id || "", newValue, dataID, currentSectionRef.current);
  };

  const handleExport = async () => {
    setExporting(true);
    setDownloadLink("");
    const workflowId = id || "";
    try {
      const fileUrl = (await exportTableData(workflowId, [], currentSectionRef.current)) as string;
      setDownloadLink(fileUrl);
    } catch (err) {
      console.error("Error in exporting table data --> ", err);
      setExporting(false);
    }
  };

  const handleRefresh = async () => {
    message.info("Refreshing data. Please wait while we process your request...");
    const workflowId = id || "";
    const addSectionAction = actions.find((action) => action.type === "add_section");
    const actionId = addSectionAction?.actionId || "";
    try {
      await refreshSection(actionId, workflowId, [], 0, "all", {});
    } catch (err) {
      console.error("Error in refreshing table data --> ", err);
      message.error("An internal server error occurred while starting the data refresh process.");
    }
  };

  const handleAdd = async (rowsCount?: number) => {
    if (loading) return;

    setLoading(true);
    rowsCount = rowsCount || 1;
    const emptyDataArr: any[] = [];
    for (let i = 0; i < rowsCount; i++) {
      const emptyData = colunmsData.reduce((acc, obj) => {
        const { editable, id } = obj;
        if (!editable) return acc;
        return { ...acc, [id]: "" };
      }, {});
      emptyDataArr.push(emptyData);
    }

    try {
      saveWorkflowRecord(id || "", emptyDataArr).then((res) => {
        insertRowsInTable(res?.dataIds);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error adding new row", error);
      // setRowsData([...rowsData, newData]);
    }
  };

  const insertRowsInTable = (dataIds: string[], addBottom?: boolean, existingData?: any) => {
    const temp: any = [];
    // const dataIds = res?.dataIds;
    for (let i = 0; i < dataIds.length; i++) {
      const emptyData: any = colunmsData.reduce((acc, obj) => {
        let tempData = existingData?.[i];
        const { id } = obj;
        if (!id) return acc;
        let val = tempData?.[id];
        return { ...acc, [id]: val ? val : "" };
      }, {});
      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      emptyData.createdAtt = formattedTime;
      emptyData.key = dataIds[i];
      emptyData.run = "retry";
      temp.push(normalizeRowData(emptyData, actions));
    }

    if (addBottom) {
      setData([...dataRef.current, ...temp]);
    } else {
      setData([...temp, ...dataRef.current]);
    }
  };

  const onColumnVisible = (params: ColumnVisibleEvent<any, any>) => {
    if (!id) return;

    const hiddenColumns = params.api
      .getAllGridColumns()
      .filter((col) => !col.isVisible())
      .map((col) => col.getColId());
    const temp = localStorage.getItem(id);
    const savedProps = temp ? JSON.parse(temp) : {};
    savedProps.hiddenColumns = hiddenColumns;
    localStorage.setItem(id, JSON.stringify(savedProps));
    // setHiddenColumns(hiddenColumns);
  };

  const updateExistingRows = async (tempClipboard: any[]) => {
    const temp = tempClipboard?.map((item: any) => {
      const { id: columnId } = item.colDef;

      // @ts-ignore
      const dataID = item.data?.key || "";
      const newValue = { [columnId]: item.newValue };
      return [newValue, dataID];
    });

    const dataIds: string[] = [];
    temp?.forEach((item: any) => {
      if (dataIds.includes(item[1])) return;
      dataIds.push(item[1]);
    });

    const data = dataIds.map((id: string) => {
      const datas = temp?.filter((item: any) => item[1] === id);
      let allData = {};
      datas?.forEach((data) => {
        allData = { ...allData, ...data[0] };
      });
      return allData;
    });

    const headerIds: string[] = [];
    temp.forEach((item: any) => {
      const key = Object.keys(item[0])[0];

      if (!headerIds.includes(key)) {
        headerIds.push(key);
      }
    });

    if (dataIds.length < 5) {
      await saveWorkflowRecord(id || "", data, dataIds, currentSectionRef.current);
    } else {
      await optimizeSaveWorkflowRecord(data, dataIds);
    }

    return { dataIds, headerIds };
  };

  const optimizeSaveWorkflowRecord = async (data: any[], dataIds: any[]) => {
    // data and dataIds are of the same length, split them in chunks of 5 members per chunk
    const chunkSize = 5;
    const dataChunks = [];
    const dataIdsChunks = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      const dataChunk = data.slice(i, i + chunkSize);
      const dataIdsChunk = dataIds.slice(i, i + chunkSize);
      dataChunks.push(dataChunk);
      dataIdsChunks.push(dataIdsChunk);
    }

    for (let i = 0; i < dataChunks.length; i += 3) {
      await Promise.all([
        dataChunks[i] && saveWorkflowRecord(id || "", dataChunks[i], dataIdsChunks[i], currentSectionRef.current),
        dataChunks[i + 1] &&
          saveWorkflowRecord(id || "", dataChunks[i + 1], dataIdsChunks[i + 1], currentSectionRef.current),
        dataIdsChunks[i + 2] &&
          saveWorkflowRecord(id || "", dataChunks[i + 2], dataIdsChunks[i + 2], currentSectionRef.current),
      ]);
    }

    // await saveWorkflowRecord(id || "", data, dataIds, currentSectionRef.current);
  };

  const createRemainingRows = async (clipboard: string[][], existingDataNum: number, headerIds: string[]) => {
    if (!existingDataNum) {
      setLoading(false);
      return;
    }
    const data = clipboard.slice(existingDataNum);

    if (!data?.length) {
      setLoading(false);
      return;
    }

    const newDataArr = data.map((item: string[]) => {
      const data: any = {};
      item.forEach((value: string, idx: number) => {
        data[headerIds[idx]] = value;
      });

      return data;
    });

    saveWorkflowRecord(id || "", newDataArr).then((res) => {
      insertRowsInTable(res?.dataIds, true, newDataArr);
      setLoading(false);
    });
  };

  const sortCreatedAt = () => {
    const createdAtSort = sorters?.find((sort) => sort.feild === "createdAt");

    if (!createdAtSort) {
      const tempSort = { feild: "createdAt", order: "ascend" };
      setSorters((prev) => (prev ? [...prev, tempSort] : [tempSort]));
      setLoadTrigger((prev) => !prev);
      setLoading(true);
      return;
    }
    const tempSort = { feild: "createdAt", order: createdAtSort?.order === "ascend" ? "descend" : "ascend" };

    setSorters((prev) => {
      if (!prev) {
        return null;
      }

      return prev.map((sort) => {
        if (sort.feild === "createdAt") {
          return tempSort;
        }
        return sort;
      });
    });

    setLoadTrigger((prev) => !prev);
    setLoading(true);
  };
  const stopAllRowsRunning = () => {
    if (!id || loading) return;

    setLoading(true);
    stopAllWorkflowActions(id, currentSectionRef.current)
      .then((res) => {
        if (res?.status === "not found") {
          messageApi.open({
            type: "info",
            content: "Found no running actions",
            duration: 3,
          });
          setLoading(false);
          return;
        }
        setTimeout(() => setLoadTrigger((prev) => !prev), 7000);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        messageApi.open({
          type: "error",
          content: "Experienced an error while stopping all running actions",
          duration: 5,
        });
      });
  };

  const onColumnResized = (params: any) => {
    if (params.finished) {
      const columnState = params.api?.getColumnState();
      const newColumnWidths = columnState.reduce((acc: any, col: any) => {
        acc[col.colId] = col.width;
        return acc;
      }, {});
      setColumnWidths(newColumnWidths);
    }
  };

  useMemo(() => {
    if (id) {
      // setLoading(true);

      loadTableData(
        id,
        currentSectionRef.current,
        pagination.pageSize,
        pagination.currentPage,
        filters,
        sorters,
        searchedText
      );
    }
  }, [id, currentSectionRef.current, loadTrigger]);

  useMemo(async () => {
    if (!responses?.length) return;

    const columnsData = mapColumns(actions, {
      runAction: handleRun,
      runCellAction: handleRunCell,
      copied,
      updateRow,
      updateRows,
      setCustomViewer: (data) => {
        setCustomViewer(data);
      },
      setErrorModal: (data) => setErrorModal(data),
      setErrorData: (data) => setErrorData(data),
      setModalStyle: (data) => setModalStyle(data),
      workflowId: id || "",
      currentSection: currentSectionRef.current,
      modalStyle,
      filters,
      triggerReload: () => {
        setLoadTrigger((prev) => !prev);
      },
      triggerLoadingOverlay: () => {
        setLoadTrigger(true);
      },
      handleDedupeInput: handleDedupeInput,
      selectedRows: selectedRows,
      sorters,
      sortCreatedAt,
      columnWidths,
      hiddenColumns,
      setCrmSyncAction,
    });

    setColumnsData(columnsData);
  }, [sections, responses, currentAction, currentSection, selectedRows, sorters, columnWidths]);

  useMemo(async () => {
    if (!responses?.length) {
      return;
    }
    const rowsData = await mapRows(actions, workflowData, { id: id || "", currentSection, updateRow });

    setData(rowsData);
  }, [sections, responses, currentAction, currentSection]);

  useMemo(async () => {
    if (!tempClipboard.length) {
      return;
    }
    if (tempClipboard.length === 1) {
      handleEdit(tempClipboard[0]);
      setTempClipboard([]);
      return;
    }

    setLoading(true);

    const clipboardData = await navigator.clipboard.readText();
    const { dataIds, headerIds } = await updateExistingRows(tempClipboard);

    const pastedData = clipboardData
      .split("\n")
      .map((line: string) => line.split("\t").map((cell) => cell.trim()))
      .filter((row: any) => row.some((cell: string) => cell !== ""));

    await createRemainingRows(pastedData, dataIds?.length, headerIds);

    setTempClipboard([]);
  }, [tempClipboard]);

  const getLocaleNumber = (number: string | number) => {
    const formattedNumber = number.toLocaleString("en-US");
    return formattedNumber;
  };

  return (
    <div className="w-full h-full" id="table_wrapper">
      {contextHolder}
      {chatNameModal && (
        <ChatNameModal
          selected={getSelectedRows(selectedRows, colunmsData)}
          setChatNameModal={setChatNameModal}
          userWorkflowId={id || ""}
          setTab={setTab}
        />
      )}
      <ErrorModal open={errorModal} close={() => setErrorModal(false)} errorData={errorData} />
      <DedupeModal
        modal={dedupeModal}
        dataLoading={dedupeDataLoading}
        setdedupeDataLoading={setDedupeDataLoading}
        close={() => setDedupeModal(false)}
        duplicateData={dedupeData}
        setDedupeData={setDedupeData}
        workflowId={id || ""}
        dedupeColumnId={dedupeColumnId}
        refresh={() => {
          setLoadTrigger((prev) => !prev);
          setLoading(true);
        }}
        currentSectionRef={currentSectionRef.current}
        dedupeOrder={dedupeOrder}
        setDedupeOrder={setDedupeOrder}
        numOfRows={dataRef.current?.length}
      />
      <div id="table_actions_wrapper">
        <TableActions
          actions={actions}
          currentSection={currentSectionRef.current}
          setCreateRows={setCreateRows}
          createRows={createRows}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          setChatNameModal={setChatNameModal}
          handleExport={handleExport}
          handleRefresh={handleRefresh}
          exporting={exporting}
          filters={filters}
          setFilters={setFilters}
          setLoadTrigger={setLoadTrigger}
          id={id || ""}
          loading={loading}
          responses={responses}
          setSearchedText={setSearchedText}
          sections={sections}
          runMultipleRows={runMultipleRows}
          setCurrentSection={setCurrentSection}
          selectedRows={selectedRows}
          downloadLink={downloadLink}
          setDownloadLink={setDownloadLink}
          setExporting={setExporting}
          currentSectionRef={currentSection}
          customViewer={customViewer}
          modalStyle={modalStyle}
          setCustomViewer={setCustomViewer}
          setLoading={setLoading}
          stopAllRowsRunning={stopAllRowsRunning}
          handleHubspotPullContacts={handleHubspotPullContacts}
          loadingHubspotContacts={loadingHubspotContacts}
          handleSfDataPull={handleSfDataPull}
          loadingSfData={loadingSfData}
          // engagebay pull contacts
          handleEngagebayContactsPull={handleEngagebayContactsPull}
          loadingEngagebayContacts={loadingEngagebayContacts}
          crmSyncAction={crmSyncAction}
        />
      </div>

      <div className="w-full block">
        <div className={"ag-theme-alpine h-full w-full block"}>
          <div className="h-full block" id="table">
            <AgGridReact
              ref={grid}
              className="ag-theme-alpine h-full block"
              suppressColumnVirtualisation={true}
              rowData={dataRef.current?.map((row) => normalizeRowData(row, actions)) || []}
              columnDefs={colunmsData}
              onColumnResized={onColumnResized}
              onSelectionChanged={(e) => {
                setSelectedRows(e.api.getSelectedRows());
              }}
              loading={loading}
              overlayLoadingTemplate={`${renderToString(
                <Loader loaderType={LOADER_TYPES.fetching} payload="Table" />
              )}`}
              getRowId={(params) => {
                // @ts-ignore
                return params.data.key;
              }}
              animateRows={true}
              defaultColDef={{ enableCellChangeFlash: true, lockPosition: true, sortable: false }}
              pagination={false}
              gridOptions={{
                // autoSizeStrategy: {
                //   type: "fitCellContents",
                // },
                rowSelection: {
                  mode: "multiRow",
                },
                selectionColumnDef: {
                  pinned: "left",
                },
                getRowId: (row) => row.data.key,
              }}
              cellSelection={true}
              onCellValueChanged={(e) => {
                setTempClipboard((prev) => [...prev, e]);
              }}
              getContextMenuItems={() => {
                return ["copy", "copyWithHeaders", "cut"];
              }}
              onColumnVisible={onColumnVisible}
            />
          </div>
          <div id="pagination_wrapper" className="flex ">
            <div className="min-w-fit bg-white flex items-center p-3">
              <div className="font-semibold text-gray-600">Total rows:</div>
              <div className=" ml-1 text-gray-900 text-[1.15rem]">{getLocaleNumber(pagination.total)}</div>
            </div>
            <Pagination
              defaultCurrent={20}
              total={pagination.total}
              pageSize={pagination.pageSize || 0}
              current={pagination.currentPage}
              onChange={(pageNumber, pageSize) => {
                if (pageSize && pagination.pageSize !== pageSize) {
                  savePagination(id || "", pageSize || 20);
                }
                setPagination((prev) => ({ ...prev, pageSize, currentPage: pageNumber }));
                setLoadTrigger((prev) => !prev);
                setLoading(true);
              }}
              showQuickJumper={true}
              pageSizeOptions={[20, 50, 100, 250]}
              showSizeChanger
              className="bg-white rounded-lg shadow-sm w-full px-5 py-4 ml-auto flex justify-end"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableAG;
