type Props = {
  member: any;
  handleRemove: (email: string, role: string) => void;
};

const MemberItem = ({ member, handleRemove }: Props) => {
  return (
    <div className="sm:w-full lg:w-4/5 xl:w-3/5 flex flex-row items-center justify-between px-4 py-2 border-2 border-[#C8C8C8] rounded-lg">
      <div className="flex flex-col gap-1">
        {/* <div className='text-black font-semibold' >Member1</div> */}
        <div className="text-black font-semibold">{member?.email || "email not available"}</div>
        {/* <div className='text-[#414141]' >Created on {} </div> */}
      </div>
      <button
        className="bg-transparent p-2 rounded-lg hover:bg-gray-200 transition cursor-pointer text-[#D60000] font-semibold "
        onClick={() => handleRemove(member?.email, member?.role)}
      >
        Remove member
      </button>
      {/* {dispModal && <EditOffering disp = {dispModal} setDisp={setDispModal} product={product} />} */}
    </div>
  );
};

export default MemberItem;
