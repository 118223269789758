import LoaderSVG from "@/assets/SVGs/LoaderSVG";
import { Table } from "antd";
import { useEffect, useState } from "react";

type Props = {
  data: any[];
  responseStructure: any[];
  dynamic?: boolean;
  generating: boolean;
  signalId?: string;
};

interface DataObject {
  data: any[];
  metadata: any;
}

const PreviewData = ({ data, responseStructure, dynamic, generating, signalId }: Props) => {
  const [previewData, setPreviewData] = useState<any[]>();
  const [totalItems, setTotalItems] = useState<number>();

  useEffect(() => {
    Array.isArray(data) ? setPreviewData(data) : setPreviewData((data as DataObject).data);
    Array.isArray(data) ? setTotalItems(0) : setTotalItems((data as DataObject)?.metadata?.total_results || 0);
  }, [data]);

  const generateDynamicColumns = () => {
    const columns = [];

    for (const key in data[0]) {
      columns.push({
        title: key,
        dataIndex: key,
        key,
        render: (text: any) => (
          <div className="text-sm line-clamp-3" title={typeof text === "object" ? JSON.stringify(text) : text}>
            {text}
          </div>
        ),
      });
    }

    return columns;
  };
  if (generating)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <LoaderSVG />
        <p className="text-lg font-medium text-gray-400">Generating Leads...</p>
        <p className="text-sm text-gray-400">Might take a few minutes depending on the website</p>
      </div>
    );

  if (!data || data.length === 0) {
    switch (signalId) {
      case "FLOQER_COMPANY_LIST_BUILDING_TS":
        return (
          <div className="text-black px-10">
            <p className="font-bold">When using this source, please keep in mind:</p>
            <ul className="list-disc list-inside mt-3 text-sm">
              <li>
                You can add filters by clicking on the{" "}
                <span className="w-fit inline-flex items-center justify-center whitespace-nowrap text-sm font-semibold">
                  + Add Filters
                </span>{" "}
                button to find better companies according to your needs.
              </li>
              <li>
                After you are satisfied with the filters, click on{" "}
                <span className="font-semibold">Generate Preview</span> to see the companies that match your criteria.
              </li>
              <li>The preview will show you the first 25 companies that match your criteria.</li>
              <li>
                The preview hides some of the data such as the company's name, website, and other sensitive information.
                The imported companies will not have this data hidden.
              </li>
              <li>
                If you are satisfied with the results, click on{" "}
                <span className="font-semibold">Import x Companies</span> to import the companies to your workflow.
              </li>
              <li>
                You can also specify the number of companies you want to import by entering the number in the input
                field. If you don't specify the number, all the companies that match your criteria will be imported.
              </li>
              <li>
                If you want to change the filters, you can click on the{" "}
                <span className="font-semibold">Regenerate Preview</span> button to see the updated list of companies.
              </li>
              <li>
                The cost of importing the companies will be displayed at the bottom of the page. The cost is <b>2</b>{" "}
                credits per company.
              </li>
            </ul>
          </div>
        );
      default:
        return (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <p className="text-lg font-medium text-gray-400">No Data Available</p>
            <p className="text-sm text-gray-400">Click on Generate Preview to get data</p>
          </div>
        );
    }
  }

  return (
    <div className="w-full h-full flex flex-col gap-4 p-4 border border-gray-300 rounded-md">
      <div className="flex gap-2">
        <p className="text-2xl font-medium">Preview Data</p>
        {totalItems && (
          <div className="text-sm text-gray-400">
            <p>
              Showing {previewData?.length || 0} of {totalItems} results
            </p>
            {/* <p className="text-red-900">Cost: 2 * No. of rows imported</p> */}
          </div>
        )}
      </div>
      {/* <div className="grid grid-cols-4 gap-4"> */}
      <div className="flex flex-col gap-2 overflow-y-scroll h-[70vh]">
        <Table
          dataSource={previewData}
          columns={
            dynamic
              ? generateDynamicColumns()
              : responseStructure.map((response) => ({
                  title: response.name,
                  dataIndex: response.responseStructureId,
                  key: response.responseStructureId,
                  render: (text: any) => {
                    // Check if `text` is an object or an array
                    if (typeof text === "object" && text !== null) {
                      // Handle arrays or objects by converting them to a readable string
                      return (
                        <div
                          className="text-sm line-clamp-3"
                          title={JSON.stringify(text, null, 2)} // Add a tooltip with a prettified JSON view
                        >
                          {Array.isArray(text)
                            ? text.join(", ") // Join array elements with commas
                            : JSON.stringify(text)}{" "}
                        </div>
                      );
                    }
                    return (
                      <div
                        className="text-sm line-clamp-3"
                        title={String(text)} // Add a tooltip for other types
                      >
                        {text}
                      </div>
                    );
                  },
                }))
          }
          pagination={false}
        />
      </div>
    </div>
  );
};

export default PreviewData;
