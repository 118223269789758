import { Navigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { MainSidebar } from "@/Components/NavbarNew";

type Props = {
  profile: boolean;
  paymentPending: boolean;
};

const PrivateRoutes = ({ profile, paymentPending }: Props) => {
  const { currentUser } = useUser();

  return currentUser ? (
    profile ? (
      !paymentPending ? (
        <MainSidebar />
      ) : (
        <Navigate to="/pricing" />
      )
    ) : (
      <Navigate to="setup" />
    )
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoutes;
