import { setStateType } from "../../../utils/constants";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { Action, PayloadConfiguration } from "../../../utils/interfaces";
import { Alert, ConfigProvider, Drawer, Select, Skeleton, Switch } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import RunCondition from "./ModalComponents/RunCondition";
import { useConnections } from "@/contexts/ConnectionContext";
import MondayFields from "./ModalComponents/MondayFields";
import { getCampaigns } from "@/utils/apis";

type Props = {
  action: Action;
  modal: boolean;
  setModal: setStateType<boolean>;
  payloadConfiguration: PayloadConfiguration[];
  responseConfiguration: any[];
  variables: any[];
};

const BOARD_PAYLOAD_STRUCTURE_ID = "824acfc3-84b2-4d30-b8c3-e0dbaaba86ca";
const FIELDS_PAYLOAD_STRUCTURE_ID = "b8a37fe5-c4ac-4750-a187-baa416b6ee49";
const EDITABLE_FIELDS = [
  "checkbox",
  "country",
  "date",
  "dropdown",
  "email", // tiptap
  "hour",
  "link", // tiptap
  "long_text", // tiptap
  "numbers", // tiptap
  "phone",
  "rating",
  "status",
  "text", // tiptap
  "timeline",
];

const MondayModal = ({ action, modal, setModal, payloadConfiguration, responseConfiguration, variables }: Props) => {
  const [loading, setLoading] = useState(false);
  const [campaignsLoading, setCampaignsLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [changed, setChanged] = useState(false);
  const [updateField, setUpdateField] = useState(0);
  const [payloads, setPayloads] = useState<PayloadConfiguration[]>(payloadConfiguration);
  const [responses, setResponses] = useState(responseConfiguration);
  const [boardId, setBoardId] = useState<string>(
    payloads.find((input) => input.payloadStructureId === BOARD_PAYLOAD_STRUCTURE_ID)?.inputString || ""
  );
  const [columns, setColumns] = useState<any[]>([]);
  const [runCondition, setRunCondition] = useState(action.runCondition);
  const { updatePayloadConfig, updateResponseConfig, saveWorkflowActions, updateActionName, setPublishWarning } =
    useWorkflow();
  const { getConnectionFromType } = useConnections();
  const [isConnectionExists, setIsConnectionExists] = useState(true);
  const [changeName, setChangeName] = useState(false);
  const { id } = useParams();
  const types = useMemo<string[]>(
    () =>
      action.payloadStructure.filter((field) => field.type === "dynamicDropdown").map((field) => field.valuesId ?? ""),
    [action.payloadStructure]
  );

  useEffect(() => {
    if (types.length === 0) return;
    types.forEach((type) => {
      setCampaignsLoading(true);
      getCampaigns(type)
        .then((data: any[]) => {
          setCampaigns(data);
        })
        .catch((error) => {
          console.log(error);
          setCampaigns([]);
        })
        .finally(() => {
          setCampaignsLoading(false);
        });
    });
  }, [types]);

  useEffect(() => {
    if (!action?.connectionId) return;
    getConnectionFromType(action?.connectionId).then((connection: any) => {
      setIsConnectionExists(connection?.userConnection ? true : false);
    });
  }, [action?.connectionId]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  useEffect(() => {
    if (payloads !== payloadConfiguration) setChanged(true);
    else setChanged(false);
  }, [payloads]);

  useEffect(() => {
    if (!boardId) setColumns([]);
    else
      setColumns(
        campaigns
          .find((category: any) => category.id === boardId)
          ?.columns?.filter((col: any) => EDITABLE_FIELDS.includes(col.type) && !col.archive && !col.hidden) || []
      );
  }, [boardId, campaigns]);

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false, action.continueOnFail, runCondition);
    setPayloads(updated);
    const updatedResponses = updateResponseConfig(responses, action.id);
    setResponses(updatedResponses);
    await saveWorkflowActions(id || "");
    setChanged(false);
    setLoading(false);
    setPublishWarning(true);
    setModal(false);
  };

  const handleBoardSelect = useCallback(
    (value: string) => {
      const temp = [...payloads];
      const index = payloads.findIndex((input) => input.payloadStructureId === BOARD_PAYLOAD_STRUCTURE_ID);
      if (index === -1)
        temp.push({
          payloadStructureId: BOARD_PAYLOAD_STRUCTURE_ID,
          inputString: value,
        });
      else temp[index].inputString = value;

      // Clear the fields payload structure since the board has changed and the columns will change
      const objectIndex = temp.findIndex((input) => input.payloadStructureId === FIELDS_PAYLOAD_STRUCTURE_ID);
      if (objectIndex !== -1) temp[objectIndex].inputString = [];

      setPayloads(temp);
      setBoardId(value);
    },
    [payloads]
  );

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "dynamicDropdown":
        return (
          <Skeleton loading={campaignsLoading ?? true}>
            <Select
              showSearch
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={handleBoardSelect}
              optionFilterProp="label"
              options={(campaigns ?? []).map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "jsonArray":
        return (
          <div className="flex flex-col gap-6">
            <Skeleton loading={campaignsLoading ?? true}>
              {payloads
                .find((input) => input.payloadStructureId === field.payloadStructureId)
                ?.inputString.map((input: any, idx: number) => {
                  return (
                    <div className="flex flex-col gap-1" key={idx}>
                      <div className="flex flex-row gap-2 items-end justify-between">
                        <Select
                          showSearch
                          optionFilterProp="label"
                          style={{ width: "100%", marginBottom: "0.25rem" }}
                          value={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].label || ""
                          }
                          onChange={(value, options: any) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].name = value;
                            temp[index].inputString[idx].label = options.label;
                            temp[index].inputString[idx].type = options.ctype;
                            setPayloads(temp);
                          }}
                          options={
                            columns.length > 0
                              ? columns.map((column: any) => ({
                                  label: column.title,
                                  value: column.id,
                                  ctype: column.type,
                                }))
                              : []
                          }
                        />
                        <button
                          className="hover:bg-primary/20 h-fit w-fit p-2 rounded-md text-primary border-2 bg-primary/10 transition-colors duration-300 ease-in-out"
                          onClick={() => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString.splice(idx, 1);
                            setUpdateField((prev) => prev + 1);
                            setPayloads(temp);
                          }}
                        >
                          <MdDeleteOutline size={25} />
                        </button>
                      </div>
                      <MondayFields
                        col={columns.find((col: any) => col.id === input.name) || {}}
                        value={JSON.parse(input.value || "{}")}
                        tiptapJson={input.tiptapJson || null}
                        setPayload={(value: any, tiptapJson?: any) => {
                          const temp = [...payloads];
                          const index = payloads.findIndex(
                            (input) => input.payloadStructureId === field.payloadStructureId
                          );
                          temp[index].inputString[idx].value = JSON.stringify(value);
                          if (tiptapJson) temp[index].inputString[idx].tiptapJson = tiptapJson;

                          setPayloads(temp);
                        }}
                        variables={variables}
                      />
                    </div>
                  );
                })}
              <div className="flex flex-row gap-2">
                <button
                  className="text-gray-900 font-semibold flex flex-row gap-2 items-center border-2 shadow-sm rounded-sm border-gray-400 w-fit px-3 py-2 text-md"
                  // disabled={!boardId}
                  title={!boardId ? "Please select a board" : undefined}
                  onClick={() => {
                    const temp = [...payloads];
                    const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                    temp[index].inputString.push({
                      name: "",
                      value: "",
                      type: "json_array",
                    });
                    setPayloads(temp);
                  }}
                >
                  <FaPlus />
                  Add key-value pair
                </button>
              </div>
            </Skeleton>
          </div>
        );

      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width="30%"
        className="!font-Lausanne !p-0"
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            {!isConnectionExists && (
              <Alert
                message={
                  <>
                    Please add the required connection to proceed.{" "}
                    <Link to="/connections" target="_blank" rel="noopener noreferrer">
                      <i>Click here</i>
                    </Link>
                  </>
                }
                type="warning"
                showIcon
              />
            )}
            <button
              className={` text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                loading || !changed || !isConnectionExists
                  ? "cursor-not-allowed bg-gray-500"
                  : "bg-primary cursor-pointer"
              }`}
              onClick={saveOutputStructure}
              disabled={loading || !changed || !isConnectionExists}
            >
              {loading ? "Saving..." : !changed ? "Saved" : "Save"}
            </button>
          </div>
        }
        title={
          <div className="flex items-center gap-2">
            <img src={action.logo} alt={action.name} className="w-6 h-6" />
            <span
              contentEditable={changeName}
              suppressContentEditableWarning
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.target.blur();
                }
              }}
              onBlur={async (e) => {
                if (!e.target.textContent) return;
                setLoading(true);
                updateActionName(action.id, e.target.textContent || action.name);
                setChangeName(false);
                await saveWorkflowActions(id || "");
                setChanged(false);
                setLoading(false);
                setPublishWarning(true);
              }}
            >
              {action.name}
            </span>
            <button
              onClick={() => {
                setChangeName(!changeName);
                const p = document.getElementById("actionName");
                if (!p) return;
                setTimeout(function () {
                  const range = document.createRange();
                  const selection = window.getSelection();
                  range.selectNodeContents(p);
                  range.collapse(false); // <-- Set the cursor at the end of the selection
                  selection?.removeAllRanges();
                  selection?.addRange(range);
                  p.focus();
                }, 0);
              }}
              className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                changeName ? "opacity-50" : "opacity-100"
              }`}
            >
              <FaRegEdit />
            </button>
          </div>
        }
      >
        <div className="bg-white gap-10 flex flex-col">
          {action.payloadStructure.map((field, idx) => {
            return (
              <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                <div>
                  <p className="tracking-primary font-semibold">
                    {field.name.toUpperCase()}
                    {!field.required && (
                      <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                    )}
                  </p>
                  <p className="text-sm font-light">{field.description}</p>
                </div>
                {field.needVars ? (
                  <TiptapField
                    content={
                      payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson || ""
                    }
                    setContent={(content: any, text: string) => {
                      const temp = [...payloads];
                      const index = payloads.findIndex(
                        (input) => input.payloadStructureId === field.payloadStructureId
                      );
                      if (index === -1) {
                        temp.push({
                          payloadStructureId: field.payloadStructureId,
                          tiptapJson: content,
                          inputString: text,
                          type: field.type,
                        });
                      } else {
                        temp[index].tiptapJson = content;
                        temp[index].inputString = text;
                      }
                      setPayloads(temp);
                    }}
                    refresh={updateField}
                    placeholder={field.name}
                    variables={variables}
                  />
                ) : (
                  fieldSwitchCase(field)
                )}
              </div>
            );
          })}
          <div>
            Continue workflow even if this fails:{" "}
            <Switch
              defaultChecked={action.continueOnFail}
              onChange={(value) => {
                setChanged(true);
                action.continueOnFail = value;
              }}
            />
          </div>
          {action.name.includes("Lookup") && (
            <RunCondition
              variables={variables}
              responses={action.responseStructure}
              conditionsArr={
                runCondition || {
                  conditions: [],
                  otherwise: [],
                }
              }
              setConditionsArr={setRunCondition}
            />
          )}
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default MondayModal;
