import { message, Modal } from "antd";
import LoaderButtonBlack from "@/Components/LoaderButtonBlack";
import DeleteIcon from "../../assets/SVGs/DeleteIcon.svg";
import { deleteWorkflow } from "@/utils/apis";

type Props = {
  modal: boolean;
  close: () => void;
  workflow: any;
  isV2: boolean;
  userEmail: string;
};

const DeleteWorkflow = ({ modal, close, workflow, isV2, userEmail }: Props) => {
  const handleDelete = async () => {
    if (isV2 && workflow.userEmail !== userEmail) {
      message.error("You cannot delete a workflow shared with you");
      return;
    }
    try {
      await deleteWorkflow(workflow.id, workflow.shared_with, isV2);
      window.location.reload();
    } catch (error) {
      message.error("Failed to delete workflow");
    }
  };

  return (
    <Modal centered open={modal} onCancel={close} footer={null} width="45%">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <label htmlFor="workflow-name" className="text-md font-semibold text-gray-800">
            <div>
              Are you sure you want to delete this workflow (
              <span className="text-red-600">{workflow.name || workflow.draftWorkflowConfig.name}</span>
              )?
            </div>
          </label>
        </div>
        <div className="flex gap-x-3 items-center">
          <div className="flex border border-red-600 px-4 gap-x-1 rounded-md group hover:bg-red-300 ">
            <img src={DeleteIcon} alt="deleteWorkflow" width={18} />
            <LoaderButtonBlack
              disabled={false}
              error=""
              loaderText="Deleting Workflow..."
              text="Delete Workflow"
              onClickCallback={handleDelete}
              btnClasses={"w-fit bg-white text-red-800 px-0 group-hover:bg-red-300 "}
            />
          </div>
          <button onClick={close} className="w-fit text-md font-semibold py-3 px-6 text-black rounded-md">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteWorkflow;
