import { setStateType } from "@/utils/constants";
import React, { useState } from "react";
import CrustDataListViaFilters from "./SalesNavListFilters";
import CrustDataListViaURL from "./SalesNavListURL";

interface SalesNavigatorProps {
  name: string;
  signalId: string;
  preview?: boolean;
  data: any;
  generatePreview: (payload: any) => Promise<void>;
}

interface TabButtonProps {
  toSelect: string;
  selected: string;
  setSelected: setStateType<string>;
  beta?: boolean;
}

export const tableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Company Type",
    dataIndex: "company_type",
    key: "company_type",
  },
  {
    title: "Decision Makers Count",
    dataIndex: "decision_makers_count",
    key: "decision_makers_count",
  },
  // {
  //   title: "Description",
  //   dataIndex: "description",
  //   key: "description",
  // },
  // {
  //   title: "Employee Count",
  //   dataIndex: "employee_count",
  //   key: "employee_count",
  // },
  {
    title: "Employee Count Range",
    dataIndex: "employee_count_range",
    key: "employee_count_range",
  },
  {
    title: "Founded Year",
    dataIndex: "founded_year",
    key: "founded_year",
  },
  {
    title: "Industry",
    dataIndex: "industry",
    key: "industry",
  },
  {
    title: "LinkedIn Company URL",
    dataIndex: "linkedin_company_url",
    key: "linkedin_company_url",
    render: (text: string) => (
      <a href={text} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    ),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Website",
    dataIndex: "website",
    key: "website",
    render: (text: string) => (
      <a href={text} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    ),
  },
];

export const convertDataToTableFormat = (dataArray: any[]) => {
  return dataArray.map((data, index) => ({
    key: index,
    company_type: data.company_type,
    decision_makers_count: data.decision_makers_count,
    description: data.description,
    employee_count: data.employee_count,
    employee_count_range: data.employee_count_range,
    founded_year: data.founded_year,
    industry: data.industry,
    linkedin_company_url: data.linkedin_company_url,
    location: data.location,
    name: data.name,
    website: data.website,
  }));
};

export const getTotalItems = (totalResults: number, pageCount: number) => {
  const totalResultInPage = pageCount * 25;
  const leastAmount = totalResultInPage <= totalResults ? totalResultInPage : totalResults;

  return leastAmount;
};

export const getCostString = (totalResults: number, pageCount: number) => {
  return `Cost: ${getTotalItems(totalResults, pageCount)} credits`;
};

const TabButton: React.FC<TabButtonProps> = ({ toSelect, selected, setSelected, beta }) => {
  return (
    <button
      className={` h-full flex justify-center items-center border-b-2 gap-1 ${
        selected === toSelect ? " border-primary " : "border-transparent"
      }`}
      onClick={() => setSelected(toSelect)}
    >
      <span
        className={`h-fit w-fit min-w-max rounded-md p-2 flex items-center hover:bg-primary/10 justify-center transition ${
          selected === toSelect ? "bg-primary/10" : "bg-transparent"
        } `}
      >
        {toSelect}
      </span>
      {beta && (
        <span className="bg-gradient-to-r from-[#D4D0FF] from-0 to-[#FAF2FF] to-100% font-semibold text-[10px] tracking-widest px-1 py-0.5 rounded-md text-gray-600">
          BETA
        </span>
      )}
    </button>
  );
};

const SalesNavList: React.FC<SalesNavigatorProps> = ({ name, signalId, preview, generatePreview, data }) => {
  const [selected, setSelected] = useState<string>("Custom Filter");

  const tabs = ["Url Search", "Custom Filter"];

  const getListBuilder = () => {
    switch (selected) {
      case "Url Search":
        return (
          <CrustDataListViaURL
            name={name}
            generatePreview={generatePreview}
            signalId={signalId}
            preview={preview}
            data={data}
          />
        );
      case "Custom Filter":
        return (
          <CrustDataListViaFilters
            name={name}
            generatePreview={generatePreview}
            signalId={signalId}
            preview={preview}
            data={data}
          />
        );
      default:
        return;
    }
  };

  return (
    <div className="flex flex-col overflow-hidden h-screen">
      <div className="flex justify-center mt-5">
        <div className="h-14 rounded-2xl font-bold color-black z-10 px-6 bg-white shadow flex items-center justify-evenly gap-6 self-center w-fit">
          {tabs.map((tab, idx) => (
            <TabButton selected={selected} setSelected={setSelected} toSelect={tab} key={idx} />
          ))}
        </div>
      </div>
      {getListBuilder()}
    </div>
  );
};

export default SalesNavList;
