import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { FunnelPlotOutlined } from "@ant-design/icons";
import FileInput from "../../FileInput";
import { ActionResponses } from "@/utils/interfaces";
import Filters from "../../Actions/Filters";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { FiDownload, FiSave } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { ViewerProps } from "./TableHandlers";
import TenKDocViewer from "../../Modals/TenKDocViewer";
import TableViewer from "../../Modals/TableViewer";
import ReviewModal from "../../Modals/ReviewModal";
import WaterfallViewer from "../../Modals/WaterfallViewer";
import LinkedinProfileViewer from "../../Modals/LinkedinProfileViewer";
import LinkedinPostViewer from "../../Modals/LinkedinPostViewer";
import ApolloEmployeeViewer from "../../Modals/ApolloEmployeeViewer";
import SalesNavEmployeeViewer from "../../Modals/SalesNavEmployeeViewer";
import WellsViewer from "../../Modals/WellsViewer";
import CrunchBaseFundraisingViewer from "../../Modals/CrunchBaseFundraisingViewer";
import JsonViewer from "../../Modals/JsonViewer";
import { AlertDialog, Button, DropdownMenu, Flex, TextField } from "@radix-ui/themes";
import { TfiImport } from "react-icons/tfi";
import { CiPlay1 } from "react-icons/ci";
import { FaRegTrashAlt } from "react-icons/fa";
import { BsChatLeftText } from "react-icons/bs";
import { TfiExport } from "react-icons/tfi";
import AcquisitionsViewer from "../../Modals/AcquisitionsViewer";
import LinkedinCompanyProfileViewer from "../../Modals/LinkedinCompanyProfileViewer";
import CrustdataCompanyViewer from "../../Modals/CrustdataCompanyViewer";
// import { deleteAllWorkflowData } from "@/utils/urls";
import { deleteAllRecords } from "@/utils/apis";
import { GrRefresh } from "react-icons/gr";

interface Props {
  handleAdd: (rowsCount?: number) => Promise<void>;
  currentSection: number;
  setCreateRows: React.Dispatch<React.SetStateAction<number>>;
  createRows: number;
  loading: boolean;
  id: string;
  responses: ActionResponses[];
  filters: {};
  actions: ActionResponses[];
  setFilters: React.Dispatch<React.SetStateAction<{}>>;
  setLoadTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchedText: React.Dispatch<React.SetStateAction<string>>;
  sections: number;
  setCurrentSection: React.Dispatch<React.SetStateAction<number>>;
  selectedRows: any[];
  runMultipleRows: () => Promise<void>;
  handleDelete: () => Promise<void>;
  setChatNameModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleExport: () => Promise<void>;
  handleRefresh: () => Promise<void>;
  exporting: boolean;
  setDownloadLink: React.Dispatch<React.SetStateAction<string>>;
  setExporting: React.Dispatch<React.SetStateAction<boolean>>;
  downloadLink: string;
  customViewer: ViewerProps;
  setCustomViewer: React.Dispatch<React.SetStateAction<ViewerProps>>;
  modalStyle: {};
  currentSectionRef: any;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  stopAllRowsRunning: () => void;
  handleHubspotPullContacts: () => void;
  loadingHubspotContacts: boolean;
  handleSfDataPull: () => void;
  loadingSfData: boolean;
  handleEngagebayContactsPull: () => void;
  loadingEngagebayContacts: boolean;
  crmSyncAction: any;
}

const TableActions: React.FC<Props> = ({
  handleAdd,
  currentSection,
  id,
  responses,
  filters,
  actions,
  setFilters,
  setLoadTrigger,
  setSearchedText,
  sections,
  setCurrentSection,
  selectedRows,
  runMultipleRows,
  handleDelete,
  setChatNameModal,
  handleExport,
  handleRefresh,
  exporting,
  setDownloadLink,
  setExporting,
  downloadLink,
  customViewer,
  setCustomViewer,
  modalStyle,
  currentSectionRef,
  loading,
  setLoading,
  // stopAllRowsRunning
  handleHubspotPullContacts,
  loadingHubspotContacts,
  handleSfDataPull,
  loadingSfData,
  handleEngagebayContactsPull,
  loadingEngagebayContacts,
  crmSyncAction,
}) => {
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openDeleteAllAlert, setOpenDeleteAllAlert] = useState(false);

  const getFilterButtonText = () => {
    const appliedFiltersNum = Object?.entries(filters || {})?.length;
    return (
      <>
        <span className="font-thin">
          <FunnelPlotOutlined />
        </span>{" "}
        {appliedFiltersNum ? `${appliedFiltersNum} ${appliedFiltersNum > 1 ? "Filters" : "Filter"}` : `Filter`}
      </>
    );
  };

  const viewerComponent = () => {
    switch (customViewer.type) {
      case "textFileURL":
        return (
          <TenKDocViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "table":
        return (
          <TableViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "review":
        return (
          <ReviewModal
            close={() => setCustomViewer({ ...customViewer, open: false })}
            data={customViewer.data}
            open={customViewer.open}
            title={customViewer.title}
            section={currentSection}
            style={modalStyle}
            currentSectionRef={currentSectionRef}
          />
        );
      case "email":
        return (
          <WaterfallViewer
            close={() => setCustomViewer({ ...customViewer, open: false })}
            data={customViewer.data}
            open={customViewer.open}
            title={customViewer.title}
            credits={customViewer.credits}
            error={customViewer.errorMessage}
          />
        );
      case "linkedinProfile":
        return (
          <LinkedinProfileViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "linkedinCompany":
        return (
          <LinkedinCompanyProfileViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "linkedinPosts":
        return (
          <LinkedinPostViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "apolloEmployees":
        return (
          <ApolloEmployeeViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "salesNavEmployees":
        return (
          <SalesNavEmployeeViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "wells":
        return (
          <WellsViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
          />
        );
      case "acquisitions":
        return (
          <AcquisitionsViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
          />
        );
      case "crunchBaseFundraising":
        return (
          <CrunchBaseFundraisingViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      case "crustdataCompany":
        return (
          <CrustdataCompanyViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
      default:
        return (
          <JsonViewer
            open={customViewer.open}
            close={() => {
              setCustomViewer({ ...customViewer, open: false });
            }}
            data={customViewer.data}
            title={customViewer.title}
            credits={customViewer.credits}
          />
        );
    }
  };

  return (
    <>
      {viewerComponent()}
      {exporting && (
        <dialog className="fixed inset-0 z-[200] bg-black bg-opacity-50 flex items-center justify-center backdrop-filter w-full h-full">
          <div className="flex flex-col min-w-[440px] min-h-[100px] bg-white px-4 py-2 rounded-md">
            <div className="flex items-center justify-between w-full mb-2 pb-2 border-b gap-2">
              <div className="flex items-center gap-2">
                <FiSave className="w-6 h-6" />
                <h3 className="text-lg font-semibold">Exporting Table Data</h3>
              </div>
              <button
                type="button"
                className="p-1 rounded-full hover:bg-gray-100"
                onClick={() => {
                  setDownloadLink("");
                  setExporting(false);
                }}
              >
                <IoClose size="1.5rem" />
              </button>
            </div>
            {downloadLink.length === 0 ? (
              <div className="flex flex-col items-center justify-center w-full my-2 gap-2">
                <div className="h-1.5 w-full bg-blue-100 overflow-hidden rounded-lg">
                  <div className="animate-progress w-full h-full bg-blue-500 origin-left-right"></div>
                </div>
                <span className="text-sm text-[#69717d]">Please wait while we export the table data</span>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center w-full my-2 gap-2">
                <span className="text-sm text-[#69717d]">Your file is ready for download</span>
                <button
                  type="button"
                  className="flex items-center justify-center px-4 py-2 font-semibold text-white bg-primary rounded-md w-full gap-2"
                  onClick={() => {
                    window.open(downloadLink, "_blank");
                    setExporting(false);
                    setDownloadLink("");
                  }}
                >
                  <FiDownload className="w-4 h-4" />
                  Download
                </button>
              </div>
            )}
          </div>
        </dialog>
      )}

      <section className="w-full flex justify-between items-center overflow-auto gap-4 p-4 bg-white border-b border-gray-200 relative">
        <AlertDialog.Root open={openDeleteAlert}>
          <AlertDialog.Content maxWidth="450px">
            <AlertDialog.Title>Delete the selected row(s)?</AlertDialog.Title>
            <AlertDialog.Description size="2">
              Are you sure you want to delete the selected rows from the table?
            </AlertDialog.Description>

            <Flex gap="3" mt="4" justify="end">
              <AlertDialog.Cancel>
                <Button variant="soft" color="gray" onClick={() => setOpenDeleteAlert(false)}>
                  Cancel
                </Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action>
                <Button
                  variant="solid"
                  color="red"
                  onClick={() => {
                    handleDelete();
                    setOpenDeleteAlert(false);
                  }}
                >
                  Yes
                </Button>
              </AlertDialog.Action>
            </Flex>
          </AlertDialog.Content>
        </AlertDialog.Root>

        <AlertDialog.Root open={openDeleteAllAlert}>
          <AlertDialog.Content maxWidth="450px">
            <AlertDialog.Title>Delete all workflow data?</AlertDialog.Title>
            <AlertDialog.Description size="2">
              Are you sure you want to delete all data from this workflow? This action cannot be undone.
            </AlertDialog.Description>

            <Flex gap="3" mt="4" justify="end">
              <AlertDialog.Cancel>
                <Button variant="soft" color="gray" onClick={() => setOpenDeleteAllAlert(false)}>
                  Cancel
                </Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action>
                <Button
                  variant="solid"
                  color="red"
                  onClick={async () => {
                    setOpenDeleteAllAlert(false);
                    setLoading(true);
                    await deleteAllRecords(id);
                    setLoadTrigger((prev) => !prev);
                  }}
                >
                  Delete All
                </Button>
              </AlertDialog.Action>
            </Flex>
          </AlertDialog.Content>
        </AlertDialog.Root>

        <section className="flex flex-row gap-4">
          <Button color="gray" variant="soft" highContrast onClick={() => handleAdd()} disabled={currentSection !== 0}>
            <FaPlus className="text-[#1c1c1c]" />
            Add a row
          </Button>

          <div>
            <Filters
              filters={filters}
              actions={actions?.filter((action: any) => action?.actionName !== "UPLOAD_PROSPECT_CSV_MANUAL")}
              onChange={(filters) => {
                // (filters);
                setFilters({ ...filters });
                setLoadTrigger((prev) => !prev);
                setLoading(true);
              }}
              triggerButton={
                <Button color="gray" variant="soft" highContrast>
                  {getFilterButtonText()}
                </Button>
              }
            />
          </div>
          <div>
            {(() => {
              switch (crmSyncAction) {
                case "SALESFORCE_PULL_DATA":
                  return (
                    <Button
                      color="gray"
                      variant="soft"
                      highContrast
                      onClick={handleSfDataPull}
                      disabled={loadingSfData}
                    >
                      {loadingSfData ? (
                        <SpinnerStatus />
                      ) : (
                        <img
                          src={
                            "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/salesforce.svg"
                          }
                          alt="Salesforce"
                          className="w-4 h-4"
                        />
                      )}
                      {loadingSfData ? "Importing..." : "Import Records"}
                    </Button>
                  );
                case "ENGAGEBAY_CONTACT_ADDED":
                  return (
                    <Button
                      color="gray"
                      variant="soft"
                      highContrast
                      onClick={handleEngagebayContactsPull}
                      disabled={loadingEngagebayContacts}
                    >
                      {loadingEngagebayContacts ? (
                        <SpinnerStatus />
                      ) : (
                        <img
                          src={
                            "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/engagebay.png"
                          }
                          alt="Engagebay"
                          className="w-4 h-4"
                        />
                      )}
                      {loadingEngagebayContacts ? "Importing..." : "Import Records"}
                    </Button>
                  );
                case "HUBSPOT_CONTACT_ADDED":
                  return (
                    <Button
                      color="gray"
                      variant="soft"
                      highContrast
                      onClick={handleHubspotPullContacts}
                      disabled={loadingHubspotContacts}
                    >
                      {loadingHubspotContacts ? (
                        <SpinnerStatus />
                      ) : (
                        <img
                          src={"https://storage.googleapis.com/public_image_assets/internal-web-app-logos/HubSpot.svg"}
                          alt="Hubspot"
                          className="w-4 h-4"
                        />
                      )}
                      {loadingHubspotContacts ? "Importing..." : "Import Records"}
                    </Button>
                  );
                default:
                  return (
                    <FileInput
                      template={responses.length > 0 ? responses[0].responseConfiguration : []}
                      refresh={(): void => {
                        setLoadTrigger((prev) => !prev);
                        setLoading(true);
                      }}
                      workflowId={id || ""}
                    >
                      <Button
                        color="gray"
                        variant="soft"
                        highContrast
                        disabled={currentSection !== 0}
                        // loading={loading} // removed the loading as it was not intuitive enough for users
                      >
                        <TfiImport className="text-[1rem] translate-y-[-3px]" />
                        Import CSV/Excel
                      </Button>
                    </FileInput>
                  );
              }
            })()}
          </div>

          <div>
            <TextField.Root
              placeholder="Search the table"
              onChange={async (e) => {
                setSearchedText(e.target.value);
              }}
            >
              <TextField.Slot>
                <IoIosSearch height="16" width="16" />
              </TextField.Slot>
            </TextField.Root>
          </div>
        </section>

        <section className="flex items-center justify-center gap-4">
          {sections > 0 && (
            <div className="flex gap-2 w-fit mx-auto">
              {Array.from({ length: sections + 1 }, (_, i) => i).map((section, index) => (
                <div key={index}>
                  <Button
                    variant={section === currentSection ? "solid" : "surface"}
                    color="indigo"
                    onClick={() => {
                      if (!id) {
                        return;
                      }
                      setCurrentSection(section);
                      setLoading(true);
                    }}
                  >
                    Section {section + 1}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </section>

        <section className="flex flex-row w-fit gap-4">
          {/* Refresh button */}
          {currentSection > 0 && (
            <Button
              variant="solid"
              color="indigo"
              disabled={loading}
              onClick={() => handleRefresh()}
              className={loading ? "cursor-not-allowed pointer-events-none" : "cursor-pointer"}
            >
              {loading ? <SpinnerStatus /> : <GrRefresh />}
            </Button>
          )}
          {selectedRows.length > 0 && (
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button variant="surface" color="indigo">
                  Row Actions
                  <DropdownMenu.TriggerIcon />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                <DropdownMenu.Item onClick={runMultipleRows}>
                  <CiPlay1 />
                  {selectedRows.length === 0 ? <span>Run Rows</span> : <span>Run {selectedRows.length} row(s)</span>}
                </DropdownMenu.Item>

                <DropdownMenu.Item color="red" onClick={() => setOpenDeleteAlert(true)}>
                  <div className="flex justify-between items-center h-full gap-2">
                    <FaRegTrashAlt />
                    {selectedRows.length === 0 ? (
                      <span>Delete Rows</span>
                    ) : (
                      <span>Delete {selectedRows.length} row(s)</span>
                    )}
                  </div>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          )}
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button variant="solid" color="indigo">
                Actions
                <DropdownMenu.TriggerIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {/* <DropdownMenu.Item
                onClick={stopAllRowsRunning}
                color="red"
              >
                <FaStop />
                Stop all actions
              </DropdownMenu.Item> */}

              <DropdownMenu.Item
                onClick={() => {
                  setChatNameModal(true);
                }}
              >
                <BsChatLeftText />
                {!selectedRows?.length ? (
                  <span>Chat with table</span>
                ) : (
                  <span>Chat with {selectedRows.length} completed row(s)</span>
                )}
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onClick={() => {
                  if (exporting) return;
                  handleExport();
                }}
                disabled={exporting}
              >
                {exporting ? <SpinnerStatus /> : <TfiExport />}
                Export to CSV
              </DropdownMenu.Item>
              {currentSection > 0 && (
                <DropdownMenu.Item
                  onClick={() => handleRefresh()}
                  disabled={loading}
                  className={loading ? "cursor-not-allowed pointer-events-none" : ""}
                >
                  {loading ? <SpinnerStatus /> : <GrRefresh />}
                  Refresh
                </DropdownMenu.Item>
              )}
              <DropdownMenu.Separator />
              <DropdownMenu.Item color="red" onClick={() => setOpenDeleteAllAlert(true)}>
                <FaRegTrashAlt />
                Delete All Data
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </section>
      </section>
    </>
  );
};

export default TableActions;
