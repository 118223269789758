import { App, Drawer, message } from "antd";
import React, { useEffect, useState } from "react";
import { FaCoins, FaRegCopy } from "react-icons/fa";
import LinkedinProfileKeyValue from "./LinkedinProfileKeyValue";
import { calculateTotalCreditsConsumed } from "../utils";

type Props = {
  open: boolean;
  close: () => void;
  data: any;
  title: () => React.ReactNode;
  credits: number | { [key: string]: number };
};

/**
 * @Test_Cases for calculateTotalCreditsConsumed() function
 * 1. ✅old response with empty data
 * 2. ✅old response with data but no creditsConsumed field
 * 3. ✅new response with empty data
 * 4. ✅new response with data and with creditsConsumed field
 */

const LinkedinProfileViewer = ({ open, close, data, title, credits }: Props) => {
  const response = data?.largeActionResponse;
  const stepDownResponse = data?.stepDownResponse;
  const [newData, setNewData] = useState<any>(response);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(response);
  }, [open, data]);

  const toggleAccordion = (index: number) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index) ? prevIndices.filter((i) => i !== index) : [...prevIndices, index]
    );
  };
  const handleCopyJSON = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const jsonString = JSON.stringify(content, null, 2); // Convert JSON to string with indentation
      await navigator.clipboard.writeText(jsonString);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };
  const handleCopyText = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };

  return (
    <Drawer title={title()} placement="right" onClose={close} open={open} width="30%">
      <App>
        <div className="flex flex-col gap-2 !text-[14px]">
          <div className="flex">
            {newData?.["Profile Pic"] && (
              <div className="w-[126px] h-[126px] mr-4">
                <img
                  className="rounded-full object-cover"
                  width={126}
                  height={126}
                  src={
                    newData?.["Profile Pic"] !== "No data found"
                      ? newData?.["Profile Pic"]
                      : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=100"
                  }
                  alt=""
                />
              </div>
            )}
            <div className={`${newData?.["Profile Pic"] ? "flex flex-col" : "flex"} flex-grow gap-x-4`}>
              <div className="w-auto flex-1 group flex flex-col items-start justify-between">
                <p className="text-[#575757]">First Name</p>
                <div className="bg-[#F2FBFF] w-full text-left px-2 py-1 flex items-center justify-between">
                  {newData["First Name"] || "\u00A0"}
                  <p
                    className="cursor-pointer hidden group-hover:block"
                    onClick={(e) => handleCopyText(newData["First Name"], e)}
                  >
                    <FaRegCopy className="text-gray-600" />
                  </p>
                </div>
              </div>
              <div className="w-auto flex-1 group flex flex-col items-start justify-between">
                <p className="text-[#575757]">Last Name</p>
                <div className="bg-[#F2FBFF] w-full text-left px-2 py-1 flex items-center justify-between">
                  {newData["Last Name"] || "\u00A0"}
                  <p
                    className="cursor-pointer hidden group-hover:block"
                    onClick={(e) => handleCopyText(newData["Last Name"], e)}
                  >
                    <FaRegCopy className="text-gray-600" />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-auto group flex flex-col items-start justify-between">
            <p className="text-[#575757]">Title</p>
            <div className="bg-[#F2FBFF] w-full text-left px-2 py-1 flex items-center justify-between">
              <p className="w-[96%]">{newData["Title"] || "\u00A0"}</p>
              <p
                className="cursor-pointer hidden group-hover:block"
                onClick={(e) => handleCopyText(newData["Title"], e)}
              >
                <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
          <div className="w-auto group flex flex-col items-start justify-between relative">
            <p className="text-[#575757]">Summary</p>
            <div className="bg-[#F2FBFF] w-full text-left px-2 py-1 flex items-center justify-between">
              <p className="w-[95%]">{newData["Summary"] || "\u00A0"}</p>
              <p
                className="cursor-pointer hidden group-hover:block "
                onClick={(e) => handleCopyText(newData["Summary"], e)}
              >
                <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
          <div className="w-auto group flex flex-col items-start justify-between relative">
            <p className="text-[#575757]">LinkedIn URL</p>
            <div className="bg-[#F2FBFF] w-full text-left px-2 py-1 flex items-center justify-between">
              <p className="w-[95%]">{newData["LinkedIn URL"] || "\u00A0"}</p>
              <p
                className="cursor-pointer hidden group-hover:block "
                onClick={(e) => handleCopyText(newData["LinkedIn URL"], e)}
              >
                <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
          <div className="w-auto group flex flex-col items-start justify-between">
            <p className="text-[#575757]">Connections</p>
            <div className="bg-[#F2FBFF] w-full text-left px-2 py-1 flex items-center justify-between">
              {newData["Connections"] || "\u00A0"}
              <p
                className="cursor-pointer hidden group-hover:block"
                onClick={(e) => handleCopyText(newData["Connections"], e)}
              >
                <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-2 gap-x-4">
            <div className="col-span-1 flex flex-col w-full group">
              <span className="text-[#575757]">Country</span>
              <div className="bg-[#F2FBFF] px-2 py-1 flex items-center justify-between">
                {newData["Country"] || "\u00A0"}
                <p
                  className="cursor-pointer hidden group-hover:block"
                  onClick={(e) => handleCopyText(newData["Country"], e)}
                >
                  <FaRegCopy className="text-gray-600" />
                </p>
              </div>
            </div>
            <div className="col-span-1 flex flex-col w-full group">
              <span className="text-[#575757]">City</span>
              <div className="bg-[#F2FBFF] px-2 py-1 flex items-center justify-between">
                {newData["City"] || "\u00A0"}
                <p
                  className="cursor-pointer hidden group-hover:block"
                  onClick={(e) => handleCopyText(newData["City"], e)}
                >
                  <FaRegCopy className="text-gray-600" />
                </p>
              </div>
            </div>
            <div className="col-span-1 flex flex-col w-full group">
              <span className="text-[#575757]">State</span>
              <div className="bg-[#F2FBFF] px-2 py-1 flex items-center justify-between">
                {newData["State"] || "\u00A0"}
                <p
                  className="cursor-pointer hidden group-hover:block"
                  onClick={(e) => handleCopyText(newData["State"], e)}
                >
                  <FaRegCopy className="text-gray-600" />
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="w-auto group flex flex-col items-start justify-between">
            <p className="text-[#575757]">Current Title</p>
            <div className="bg-[#F2FBFF] w-full text-left px-2 py-1 flex items-center justify-between">
              <p className="w-[96%]">{newData["Current Title"] || "\u00A0"}</p>
              <p
                className="cursor-pointer hidden group-hover:block"
                onClick={(e) => handleCopyText(newData["Current Title"], e)}
              >
                <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
          <div className="w-auto group flex flex-col items-start justify-between relative">
            <p className="text-[#575757]">Current Company Name</p>
            <div className="bg-[#F2FBFF] w-full text-left px-2 py-1 flex items-center justify-between">
              <p className="w-[95%]">{newData["Current Company Name"] || "\u00A0"}</p>
              <p
                className="cursor-pointer hidden group-hover:block "
                onClick={(e) => handleCopyText(newData["Current Company Name"], e)}
              >
                <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
          <div className="w-auto group flex flex-col items-start justify-between relative">
            <p className="text-[#575757]">Current Company LinkedIn URL</p>
            <div className="bg-[#F2FBFF] w-full text-left px-2 py-1 flex items-center justify-between">
              <p className="w-[95%]">{newData["Current Company LinkedIn URL"] || "\u00A0"}</p>
              <p
                className="cursor-pointer hidden group-hover:block "
                onClick={(e) => handleCopyText(newData["Current Company LinkedIn URL"], e)}
              >
                <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
          <div className="w-auto group flex flex-col items-start justify-between">
            <p className="text-[#575757]">Current Company Domain</p>
            <div className="bg-[#F2FBFF] w-full text-left px-2 py-1 flex items-center justify-between">
              {newData["Current Company Domain"] || "\u00A0"}
              <p
                className="cursor-pointer hidden group-hover:block"
                onClick={(e) => handleCopyText(newData["Current Company Domain"], e)}
              >
                <FaRegCopy className="text-gray-600" />
              </p>
            </div>
          </div>
          <hr />
          <div
            className={`${
              activeIndices.includes(1001) ? "" : "border border-gray-200 rounded-sm bg-[#FAFAFA]"
            } p-2 cursor-pointer space-y-2`}
          >
            <div className="flex items-center justify-between" onClick={() => toggleAccordion(1001)}>
              <p className="!text-[16px] font-semibold flex items-center gap-x-2">
                Experiences
                {activeIndices.includes(1001) ? <UpArrowNew color="black" /> : <DownArrowNew color="black" />}
              </p>

              <p
                className="cursor-pointer flex items-center gap-x-2"
                onClick={(e) => handleCopyJSON(newData["Experiences"], e)}
              >
                Copy All <FaRegCopy className="text-gray-600" />
              </p>
            </div>
            {activeIndices.includes(1001) && (
              <div className="space-y-2">
                {newData["Experiences"] &&
                  newData["Experiences"] !== "No data found" &&
                  newData["Experiences"]?.map((exp: any, idx: number) => (
                    <div
                      key={idx}
                      className={`${
                        activeIndices.includes(1001 + idx + 1) ? "" : " bg-[#FAFAFA]"
                      } border border-gray-200 p-2 rounded-sm cursor-pointer `}
                    >
                      <div
                        className="flex items-center justify-between"
                        onClick={() => toggleAccordion(1001 + idx + 1)}
                      >
                        <p className="text-[16px] font-semibold flex items-center gap-x-2">
                          {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                          {activeIndices.includes(1001 + idx + 1) ? (
                            <UpArrowNew color="black" />
                          ) : (
                            <DownArrowNew color="black" />
                          )}
                        </p>
                        <p className="cursor-pointer flex items-center gap-x-2" onClick={(e) => handleCopyJSON(exp, e)}>
                          Copy All <FaRegCopy className="text-gray-600" />
                        </p>
                      </div>
                      {activeIndices.includes(1001 + idx + 1) && (
                        <div className="space-y-2">
                          <div className="flex flex-col w-full group">
                            <span className="w-full text-[#575757]">Title</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              <p className="w-[96%]">
                                {exp?.title ||
                                  exp?.profile_positions?.[0]?.title ||
                                  exp?.profilePositions?.[0]?.title ||
                                  "\u00A0"}
                              </p>
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) =>
                                  handleCopyText(
                                    exp?.title ||
                                      exp?.profile_positions?.[0]?.title ||
                                      exp?.profilePositions?.[0]?.title,
                                    e
                                  )
                                }
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group">
                            <span className="w-full text-[#575757]">Company</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              {exp.company?.name ||
                                (typeof exp?.company === "string" ? exp?.company : null) ||
                                "\u00A0"}
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) =>
                                  handleCopyText(
                                    exp.company?.name || (typeof exp?.company === "string" ? exp?.company : null),
                                    e
                                  )
                                }
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group">
                            <span className="w-full text-[#575757]">Start Date</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              {exp?.starts_at?.year ||
                                exp?.date?.start?.year ||
                                (typeof exp?.date?.start === "string" ? exp?.date?.start : null) ||
                                "\u00A0"}
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) =>
                                  handleCopyText(
                                    exp?.starts_at?.year ||
                                      exp?.date?.start?.year ||
                                      (typeof exp?.date?.start === "string" ? exp?.date?.start : null),
                                    e
                                  )
                                }
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group">
                            <span className="w-full text-[#575757]">End Date</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              {exp?.ends_at?.year ||
                                exp?.date?.end?.year ||
                                (typeof exp?.date?.end === "string" ? exp?.date?.end : null) ||
                                "\u00A0"}
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) =>
                                  handleCopyText(
                                    exp?.ends_at?.year ||
                                      exp?.date?.end?.year ||
                                      (typeof exp?.date?.end === "string" ? exp?.date?.end : null),
                                    e
                                  )
                                }
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group relative">
                            <span className="w-full text-[#575757]">Summary</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              <p className="w-[96%]">
                                {exp?.description ||
                                  exp?.profile_positions?.[0]?.description ||
                                  exp?.profilePositions?.[0]?.description ||
                                  "\u00A0"}
                              </p>

                              <p
                                className="cursor-pointer hidden group-hover:block "
                                onClick={(e) =>
                                  handleCopyText(
                                    exp?.description ||
                                      exp?.profile_positions?.[0]?.description ||
                                      exp?.profilePositions?.[0]?.description,
                                    e
                                  )
                                }
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group">
                            <span className="w-full text-[#575757]">URL</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              <p className="w-[96%]">
                                {exp?.company_linkedin_profile_url || exp?.company?.url || "\u00A0"}
                              </p>

                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) =>
                                  handleCopyText(exp?.company_linkedin_profile_url || exp?.company?.url, e)
                                }
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group">
                            <span className="w-full">Company Domain</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 gap-x-4 break-words flex items-center justify-between">
                              <p className="w-[96%]">
                                {exp?.company_linkedin_profile_url || exp?.company?.url || "\u00A0"}
                              </p>
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) =>
                                  handleCopyText(exp?.company_linkedin_profile_url || exp?.company?.url, e)
                                }
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group">
                            <span className="w-full">Company ID</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              {exp?.company?.id || "\u00A0"}
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) => handleCopyText(exp?.company?.id, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          {/* <div className="flex flex-col w-full group">
                        <span className="w-full">Currently Employed</span> 
                        <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                           
                          {exp?.company?.id || "\u00A0"}
                          <p className="cursor-pointer hidden group-hover:block" onClick={(e)=>handleCopyText(exp?.company?.id,e)}><FaRegCopy className="text-gray-600" /></p>
                        </div>
                      </div> */}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* <hr />

        <div>
            <p>Experiences</p>
            {newData["Experiences"]?.map((edu: any, idx: number) => (
                Object.entries(edu).map(([key, val]: [string, any]) => (
                    <LinkedinProfileKeyValue key={key} keyName={key} value={val} open={open} />
                ))
            ))}
        </div> */}

          <hr />
          <div
            className={`${
              activeIndices.includes(2001) ? "" : "border border-gray-200 rounded-sm bg-[#FAFAFA]"
            } p-2 cursor-pointer space-y-2`}
          >
            <div className="flex items-center justify-between" onClick={() => toggleAccordion(2001)}>
              <p className="!text-[16px] font-semibold flex items-center gap-x-2">
                Education
                {activeIndices.includes(2001) ? <UpArrowNew color="black" /> : <DownArrowNew color="black" />}
              </p>
              <p
                className="cursor-pointer flex items-center gap-x-2"
                onClick={(e) => handleCopyJSON(newData["Education"], e)}
              >
                Copy All <FaRegCopy className="text-gray-600" />
              </p>
            </div>
            {activeIndices.includes(2001) && (
              <div className="space-y-2">
                {newData["Education"] &&
                  newData["Education"] !== "No data found" &&
                  newData["Education"]?.map((edu: any, idx: number) => (
                    <div
                      key={idx}
                      className={`${
                        activeIndices.includes(2001 + idx + 1) ? "" : " bg-[#FAFAFA]"
                      } border border-gray-200 p-2 rounded-sm cursor-pointer `}
                    >
                      <div>
                        <div
                          className="flex items-center justify-between"
                          onClick={() => toggleAccordion(2001 + idx + 1)}
                        >
                          <p className="text-[16px] font-semibold flex items-center gap-x-2">
                            {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                            {activeIndices.includes(2001 + idx + 1) ? (
                              <UpArrowNew color="black" />
                            ) : (
                              <DownArrowNew color="black" />
                            )}
                          </p>
                          <p
                            className="cursor-pointer flex items-center gap-x-2"
                            onClick={(e) => handleCopyJSON(edu, e)}
                          >
                            Copy All <FaRegCopy className="text-gray-600" />
                          </p>
                        </div>
                      </div>
                      {activeIndices.includes(2001 + idx + 1) && (
                        <div className="space-y-2">
                          <div className="flex flex-col w-full group">
                            <span className="w-full">School Name</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              <p className="w-[96%]">{edu?.school?.name || edu?.school || "\u00A0"}</p>
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) => handleCopyText(edu?.school?.name || edu?.school, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group">
                            <span className="w-full">Degree Name</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              <p className="w-[96%]">{edu?.degree_name || edu?.degreeName || "\u00A0"}</p>
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) => handleCopyText(edu?.degree_name || edu?.degreeName, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group">
                            <span className="w-full">Field Of Study</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              {edu.field_of_study || edu?.fieldOfStudy || "\u00A0"}
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) => handleCopyText(edu?.field_of_study || edu?.fieldOfStudy, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group">
                            <span className="w-full">Start Date</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              {edu?.starts_at?.year ||
                                edu?.date?.start.year ||
                                (typeof edu?.date?.start === "string" ? edu?.date?.start : null) ||
                                "\u00A0"}
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) =>
                                  handleCopyText(edu?.starts_at?.year || edu?.date?.start.year || edu?.date?.start, e)
                                }
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          <div className="flex flex-col w-full group">
                            <span className="w-full">End Date</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              {edu?.ends_at?.year ||
                                edu?.date?.end.year ||
                                (typeof edu?.date?.end === "string" ? edu?.date?.end : null) ||
                                "\u00A0"}
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) =>
                                  handleCopyText(edu?.ends_at?.year || edu?.date?.end.year || edu?.date?.end, e)
                                }
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>

                          {/* <div className="flex flex-col w-full">
                        <span className="w-full">Activities</span> 
                        <span className="w-full bg-[#F2FBFF] px-2 py-1 break-words">
                           
                        </span>
                      </div> */}

                          <div className="flex flex-col w-full group">
                            <span className="w-full">Grade</span>
                            <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                              {edu.grade || "\u00A0"}
                              <p
                                className="cursor-pointer hidden group-hover:block"
                                onClick={(e) => handleCopyText(edu.grade, e)}
                              >
                                <FaRegCopy className="text-gray-600" />
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* <hr />
        <div>
            <p>Education</p>
            {newData["Education"]?.map((edu: any, idx: number) => (
                Object.entries(edu).map(([key, val]: [string, any]) => (
                    <LinkedinProfileKeyValue key={key} keyName={key} value={val} open={open} />
                ))
            ))}
        </div> */}

          <hr />
          <div
            className={`${
              activeIndices.includes(3001) ? "" : "border border-gray-200 rounded-sm bg-[#FAFAFA]"
            } p-2 cursor-pointer space-y-2`}
          >
            <div className="flex items-center justify-between" onClick={() => toggleAccordion(3001)}>
              <p className=" flex items-center font-semibold !text-[16px] gap-x-2">
                Skills
                {activeIndices.includes(3001) ? <UpArrowNew color="black" /> : <DownArrowNew color="black" />}
              </p>
              <p
                className="cursor-pointer flex items-center gap-x-2"
                onClick={(e) => handleCopyJSON(newData["Skills"], e)}
              >
                Copy All <FaRegCopy className="text-gray-600" />
              </p>
            </div>
            {activeIndices.includes(3001) && (
              <div className="grid grid-cols-2 gap-x-4 gap-y-4">
                {newData["Skills"] !== "No data found" &&
                  newData["Skills"]?.map((skl: any, idx: number) => (
                    <div
                      key={idx}
                      className="bg-[#F2FBFF] col-span-1 text-left px-2 py-1 group flex items-center justify-between"
                    >
                      <p>{skl}</p>
                      <p className="cursor-pointer hidden group-hover:block" onClick={(e) => handleCopyText(skl, e)}>
                        <FaRegCopy className="text-gray-600" />
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </div>

          <hr />
          <div
            className={`${
              activeIndices.includes(4001) ? "" : "border border-gray-200 rounded-sm bg-[#FAFAFA]"
            } p-2 cursor-pointer space-y-2`}
          >
            <div className="flex items-center justify-between" onClick={() => toggleAccordion(4001)}>
              <p className="flex items-center gap-x-2 text-[16px] font-semibold">
                Accomplishments
                {activeIndices.includes(4001) ? <UpArrowNew color="black" /> : <DownArrowNew color="black" />}
              </p>
              <p
                className="cursor-pointer flex items-center gap-x-2"
                onClick={(e) => handleCopyJSON(newData["Accomplishments"], e)}
              >
                Copy All <FaRegCopy className="text-gray-600" />
              </p>
            </div>
            {activeIndices.includes(4001) &&
              newData &&
              newData["Accomplishments"] &&
              Object.entries(newData["Accomplishments"])?.map(([key, val]: [string, any]) => (
                <LinkedinProfileKeyValue key={key} keyName={key} value={val} open={open} />
              ))}
          </div>
        </div>
      </App>
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">{calculateTotalCreditsConsumed(stepDownResponse, credits)}</span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </Drawer>
  );
};

export const DownArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.296875V5.16511L9 12.999L18 5.16511V0.296875L9 8.11216L0 0.296875Z" fill={color} />
  </svg>
);
export const UpArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.999 12.6953V7.82708L8.99902 -0.00685804L-0.000980854 7.82708V12.6953L8.99902 4.88003L17.999 12.6953Z"
      fill={color}
    />
  </svg>
);

export default LinkedinProfileViewer;
