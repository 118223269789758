import { ResponseStructure } from "../models";

type Props = {
  responseStructure: ResponseStructure[];
  exampleResponse: any;
};

const getNestedValue = (obj: any, path: string): any => {
  if (path.includes(".")) {
    return path.split(".").reduce((acc, key) => {
      return acc && (acc as any)[key] !== undefined ? (acc as any)[key] : undefined;
    }, obj);
  }
  return obj[path];
};

const ExampleResponse = ({ exampleResponse, responseStructure }: Props) => {
  return (
    <div className="w-full h-full flex flex-col gap-4 p-4 border border-gray-300 rounded-md">
      <div className="w-full flex items-start gap-1 rounded-md">
        <p className="text-2xl font-medium">Example Data</p>
      </div>
      {/* <div className="grid grid-cols-4 gap-4"> */}
      <div className="flex flex-col gap-2">
        {responseStructure.map((response) => {
          const responseValue = getNestedValue(exampleResponse, response.responseStructureId);
          return (
            <div key={response.responseStructureId} className="flex flex-col gap-2 bg-primary/5 p-2 rounded-md">
              <div className="font-semibold text-[#1E0068]">{response.name}</div>
              <div
                className="text-sm line-clamp-3"
                title={
                  typeof exampleResponse[response.responseStructureId] === "object"
                    ? JSON.stringify(exampleResponse[response.responseStructureId])
                    : exampleResponse[response.responseStructureId]
                }
              >
                {/* {exampleResponse[response.responseStructureId]} */}
                {typeof responseValue === "object" && responseValue !== null
                  ? JSON.stringify(responseValue)
                  : responseValue}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExampleResponse;
