import { useEffect, useState } from "react";
import { useUser } from "../../../contexts/UserContext";
import Loader from "../../Loader";
import { LOADER_TYPES } from "../../../utils/constants";
import LoaderButton from "../../LoaderButton";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    job_title: "",
  });
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [passwordOk, setPasswordOk] = useState(false);
  const [profileOk, setProfileOk] = useState(false); // [1
  const { currentUser, updateProfile, getProfile, changePassword } = useUser();

  useEffect(() => {
    setLoading(true);
    getProfile().then((res: any) => {
      setUserData({
        first_name: res.first_name,
        last_name: res.last_name,
        job_title: res.job_title,
      });
      setLoading(false);
    });
  }, []);

  const profileSubmit = async () => {
    await updateProfile(userData);
    setProfileOk(true);
  };

  const passwordUpdate = async () => {
    try {
      setError("");
      if (!currentUser) throw new Error("User not found");
      if (password.newPassword !== password.confirmPassword) {
        setError("Passwords do not match");
        return;
      }
      await changePassword(password.oldPassword, password.newPassword);
      setPasswordOk(true);
      setPassword({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setError("");
    } catch (error: any) {
      if (error.code === "auth/wrong-password") {
        setError("Password incorrect");
      }
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (profileOk) {
      setTimeout(() => {
        setProfileOk(false);
      }, 5000);
    }
  }, [profileOk]);

  if (loading) return <Loader payload={"profile info"} loaderType={LOADER_TYPES.loading} />;
  return (
    <div className="flex flex-col gap-8 items-start m-0 bg-white rounded-lg p-8 h-[75vh] w-full overflow-y-auto">
      {profileOk && <div className="bg-[#5fef73] p-2 rounded text-sm">Profile information updated</div>}
      <div className="w-1/2">
        <form className="flex flex-col justify-start items-start gap-3" onSubmit={profileSubmit}>
          <div className="text-lg font-semibold text-black">Profile Information</div>
          <div className="flex justify-start gap-4 w-full">
            <div className="flex flex-col gap-1 w-full">
              <label>First name</label>
              <input
                className="p-2 rounded-md border border-black w-full"
                type="text"
                value={userData.first_name}
                autoComplete="off"
                onChange={(e) => setUserData({ ...userData, first_name: e.target.value })}
                required
                placeholder="First name"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label>Last name</label>
              <input
                className="p-2 rounded-md border border-black w-full"
                type="text"
                value={userData.last_name}
                onChange={(e) => setUserData({ ...userData, last_name: e.target.value })}
                required
                placeholder="Last name"
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-1">
            <label>Job title</label>
            <input
              className="p-2 rounded-md border border-black w-full"
              type="text"
              value={userData.job_title}
              onChange={(e) => setUserData({ ...userData, job_title: e.target.value })}
              required
              placeholder="Job title"
            />
          </div>
          <LoaderButton
            text="Save Information"
            onClickCallback={profileSubmit}
            loaderText="Saving..."
            error=""
            disabled={false}
            btnClasses="text-md"
          />
        </form>
      </div>
      {error.length > 0 && <div className="bg-[#feed94] rounded p-2 text-sm">{error}</div>}
      {passwordOk && <div className="bg-[#5fef73] p-2 rounded mb-2 text-sm">Password updated successfully</div>}
      <div className="w-1/2">
        <form className="flex flex-col justify-start items-start gap-3" onSubmit={passwordUpdate}>
          <div className="text-lg font-semibold text-black">Change your password</div>
          <div className="w-full flex flex-col gap-1">
            <label>Old password</label>
            <input
              className="p-2 rounded-md border border-black w-full"
              type="password"
              value={password.oldPassword}
              autoComplete="off"
              onChange={(e) =>
                setPassword({
                  ...password,
                  oldPassword: e.target.value,
                })
              }
              required
              placeholder="Enter your old password"
            />
          </div>
          <div className="flex justify-start gap-4 w-full">
            <div className="flex flex-col gap-1 w-full">
              <label>New password</label>
              <input
                className="p-2 rounded-md border border-black w-full"
                type="password"
                value={password.newPassword}
                autoComplete="off"
                onChange={(e) =>
                  setPassword({
                    ...password,
                    newPassword: e.target.value,
                  })
                }
                required
                placeholder="Enter a new password"
              />
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label>Confirm password</label>
              <input
                className="p-2 rounded-md border border-black w-full"
                type="password"
                autoComplete="off"
                value={password.confirmPassword}
                onChange={(e) =>
                  setPassword({
                    ...password,
                    confirmPassword: e.target.value,
                  })
                }
                required
                placeholder="Confirm your new password"
              />
            </div>
          </div>
          <LoaderButton
            text={"Change Password"}
            onClickCallback={passwordUpdate}
            loaderText="Changing..."
            error=""
            disabled={false}
          />
        </form>
      </div>
    </div>
  );
};

export default Profile;
