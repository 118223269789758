import React, { useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { Spin } from "antd";

// interface ConfirmDeleteButtonProps {
//   onConfirm: () => Promise<void>; // Make onConfirm an async function
// }

// const ConfirmDeleteButton: React.FC<ConfirmDeleteButtonProps> = ({ onConfirm }) => {
//   const [buttonState, setButtonState] = useState<"delete" | "confirm" | "loading">("delete");

//   useEffect(() => {
//     setTimeout(() => {
//       if (buttonState === "confirm") {
//         setButtonState("delete");
//       }
//     }, 3000);
//   }, [buttonState]);

//   const handleClick = async () => {
//     switch (buttonState) {
//       case "delete":
//         setButtonState("confirm");
//         // Automatically reset to delete state after 3 seconds
//         break;

//       case "confirm":
//         setButtonState("loading");
//         try {
//           await onConfirm(); // Wait for the confirmation logic to complete
//         } catch (error) {
//           console.error("Delete operation failed", error);
//           // Optionally handle error (return to delete state)
//         } finally {
//           setButtonState("delete");
//         }
//         break;
//     }
//   };

//   const renderButtonContent = () => {
//     switch (buttonState) {
//       case "delete":
//         return <MdDeleteOutline size={25} />;
//       case "confirm":
//         return <span className="text-sm font-semibold text-primary">Sure?</span>;
//       case "loading":
//         return <Spin size="small" />;
//     }
//   };

//   return (
//     <button
//       onClick={handleClick}
//       className="absolute top-2 right-2 px-2 py-1 rounded-2xl text-primary hover:bg-primary/10 transition-colors duration-300 ease-in-out flex items-center justify-center"
//       disabled={buttonState === "loading"}
//     >
//       {renderButtonContent()}
//     </button>
//   );
// };

type ConfirmDeleteButtonProps = {
  onConfirm: () => Promise<void>;
  loadingAll: boolean;
  setLoadingAll: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfirmDeleteButton: React.FC<ConfirmDeleteButtonProps> = ({ onConfirm, loadingAll, setLoadingAll }) => {
  const [buttonState, setButtonState] = useState<"delete" | "confirm" | "loading">("delete");

  useEffect(() => {
    if (buttonState === "confirm") {
      const timer = setTimeout(() => setButtonState("delete"), 3000);
      return () => clearTimeout(timer);
    }
  }, [buttonState]);

  const handleClick = async () => {
    switch (buttonState) {
      case "delete":
        setButtonState("confirm");
        break;

      case "confirm":
        setButtonState("loading");
        setLoadingAll(true); // Disable all delete buttons
        try {
          await onConfirm();
        } catch (error) {
          console.error("Delete operation failed", error);
        } finally {
          setTimeout(() => setLoadingAll(false), 1000); // Add 2 seconds delay
          setButtonState("delete");
        }
        break;
    }
  };

  const renderButtonContent = () => {
    switch (buttonState) {
      case "delete":
        return <MdDeleteOutline size={25} />;
      case "confirm":
        return <span className="text-sm font-semibold text-primary">Sure?</span>;
      case "loading":
        return <Spin size="small" />;
    }
  };

  return (
    <button
      onClick={handleClick}
      className="absolute top-2 right-2 px-2 py-1 rounded-2xl text-primary hover:bg-primary/10 transition-colors duration-300 ease-in-out flex items-center justify-center"
      disabled={loadingAll || buttonState === "loading"} // Disable if another delete is in progress
    >
      {renderButtonContent()}
    </button>
  );
};

export default ConfirmDeleteButton;
