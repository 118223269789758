import React, { useCallback, useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { Button, Dropdown, Menu, message, Tree } from "antd";
import type { TreeDataNode, TreeProps } from "antd";
import { MdDragIndicator } from "react-icons/md";
import { cn } from "@/utils/cn";
import {
  // isV2 as isV2ENV,
  LOADER_TYPES,
} from "../../utils/constants";
import WorkflowFolderIcon from "../../assets/SVGs/WorkflowFolderIcon.svg";
import RenameIcon from "../../assets/SVGs/RenameIcon.svg";
import DeleteIcon from "../../assets/SVGs/DeleteIcon.svg";
import CreateIcon from "../../assets/SVGs/CreateIcon.svg";
import CreateIconBlack from "../../assets/SVGs/CreateIconBlack.svg";
import SearchIcon from "../../assets/SVGs/SearchIcon.svg";
import Loader from "../Loader";
import CreateWorkflowUI from "./create/CreateWorkflow";
import DuplicateWorkflow from "./DuplicateWorkflow";
import CreateWorkflowFolder from "./CreateWorkflowFolder";
import DeleteWorkflow from "./DeleteWorkflow";
import DeleteWorkflowFolder from "./DeleteWorkflowFolder";
import {
  getUserWorkflows,
  renameWorkflow,
  renameWorkflowFolder,
  getWorkflowStructure,
  reorderWorkflowStructure,
  shareWorkflowAccess,
} from "@/utils/apis";
import _debounce from "lodash/debounce";
import _ from "lodash";
import Workflow from "./TitleRender/Workflow";
import Folder from "./TitleRender/Folder";
import { useUser } from "@/contexts/UserContext";
import { getEmailFromToken } from "@/utils/functions";
import clsx from "clsx";
import { IoMdShare } from "react-icons/io";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { CollapsibleIcon } from "../NavbarNew/CollapsibleIcon";

type glodalData = {
  searchParam: string;
  viewData: any[];
  realData: any[];
};

const generateSearchedTree = (data: any[], search: string, allWorkflows: any, workflowFolders: any): any[] => {
  const results: any[] = [];

  for (const item of data) {
    const workflow = allWorkflows.get(item.key.split("=")[1]);
    const folder = workflowFolders.get(item.key.split("=")[1]);
    if (
      item.isLeaf &&
      (workflow?.name || workflow?.draftWorkflowConfig?.name)?.toLowerCase().includes(search.toLowerCase())
    ) {
      results.push(item);
    } else if (!item.isLeaf && folder.name.toLowerCase().includes(search.toLowerCase())) {
      results.push(_.cloneDeep(item));
    } else if (!item.isLeaf) {
      const children = generateSearchedTree(item.children, search, allWorkflows, workflowFolders);
      if (children.length > 0) {
        results.push({
          ...item,
          children,
        });
      }
    }
  }

  return results;
};

const Workflows = () => {
  const [create, setCreate] = useState("");
  const [createFolder, setCreateFolder] = useState(false);
  const [workflowFolders, setWorkflowFolders] = useState<Map<string, any>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteWorkflow, setDeleteWorkflow] = useState<any>(null);
  const [isV2, setIsV2] = useState(false);
  const [duplicateWorkflow, setDuplicateWorkflow] = useState<any>(null);
  const [toRenameWorkflow, setToRenameWorkflow] = useState<any>(null);
  const [toRenameFolder, setToRenameFolder] = useState<any>(null);
  const [deleteWorkflowFolder, setDeleteWorkflowFolder] = useState<any>(null);
  const [search, setSearch] = useState<string>("");
  const [gData, setGData] = useState<glodalData>({
    searchParam: "",
    viewData: [],
    realData: [],
  });
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [generatingTree, setGeneratingTree] = useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [orgDetails, setOrgDetails] = useState({ org_name: "", org_members: [] });
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
  const [allWorkflows, setAllWorkflows] = useState<Map<string, any>>();
  const { fetchOrg } = useUser();

  const userEmail = getEmailFromToken();

  useEffect(() => {
    setGeneratingTree(true);
    if (gData.searchParam === search) {
    } else if (search === "") {
      setGData({
        searchParam: search,
        viewData: _.cloneDeep(gData.realData),
        realData: gData.realData,
      });
      setExpandedKeys([]);
    } else {
      const vData = generateSearchedTree(gData.realData, search, allWorkflows, workflowFolders);
      setGData({
        searchParam: search,
        viewData: vData,
        realData: gData.realData,
      });
      vData.map((item) => setExpandedKeys((prev) => [...prev, item.key]));
    }
    setGeneratingTree(false);
  }, [search]);

  const debounceFn = useCallback(
    _debounce((value: string) => setSearch(value), 500),
    []
  );

  // const generateFolders = (data: any[]): void => {
  //   for (const item of data) {
  //     if (!item.isLeaf) {
  //       setWorkflowFolders((prev) => [
  //         workflowFolders.find((folder) => folder.id === item.key.split('=')[1]) || item,
  //         ...prev,
  //       ]);
  //       generateFolders(item.children);
  //     }
  //   }
  // };

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        setLoading(true);
        const [resp, resp2] = await Promise.all([getWorkflowStructure(), getUserWorkflows()]);
        const firebase = resp2.workflows || [];
        const supabase = resp2.workflowsV2 || [];

        // if there is some issue fetching, then don't proceed as it will change the layout and the user will loose the workflow from the view.
        if (firebase.error || supabase.error || resp2.error)
          return message.error(
            "There was some issue while fetching your workflows. Please refresh the page or try again later.",
            5
          );

        // Step 1: Combine Firebase and Supabase workflows and folders into one array
        const workflowFolders = firebase.workflowFolders || [];
        const workflowFoldersV2 = supabase.workflowFolders || [];
        const combinedWorkflows = (firebase?.workflows || []).concat(supabase?.workflows || []);

        // Step 2: Create a Map for instant lookup
        const workflowMap: Map<string, any> = new Map(
          combinedWorkflows.map((workflow: any) => {
            const existsInFirebase = (firebase?.workflows || []).some((w: any) => w.id === workflow.id);

            // Add a flag for whether it belongs to Supabase
            const enrichedWorkflow = {
              ...workflow,
              supabase: !existsInFirebase,
            };

            return [workflow.id, enrichedWorkflow]; // Map key: `id`, value: `enrichedWorkflow`
          })
        );

        // Step 3: Convert the Map back to an array for setting all workflows
        const workflowsArray = Array.from(workflowMap.values());

        const inFolderWorkflows: string[] = [];
        const inFolderWorkflowFolders: string[] = [];
        const workflowAllFolders = [...workflowFolders, ...workflowFoldersV2];
        // Map key: folder id, value: enriched folder object
        const workflowFoldersMap = new Map(workflowAllFolders.map((folder: any) => [folder.id, folder]));

        setAllWorkflows(workflowMap);
        setWorkflowFolders(workflowFoldersMap);

        const resultData = filterValidWorkflows(resp.data || [], workflowMap);

        if (resp.success) {
          setGData({
            searchParam: "",
            viewData: _.cloneDeep(resultData),
            realData: resultData,
          });

          try {
            await reorderWorkflowStructure(resultData);
          } catch (error) {
            message.error("Error while fetching workflows, reloading...");
            window.location.reload();
          }

          setLoading(false);
          return;
        }

        setLoading(false);

        setGeneratingTree(true);

        workflowAllFolders.map((folder) => {
          folder?.workflows?.map((workflow: string) => inFolderWorkflows.push(workflow));
          folder?.folders?.map((workflowFolder: string) => inFolderWorkflowFolders.push(workflowFolder));
        });

        const appendChildren = (folder: any) => {
          const childFolders: any[] = workflowAllFolders
            .filter((workflowFolder: any) => folder?.folders?.includes(workflowFolder.id))
            .map((workflowFolder: any) => ({
              key: `folder=${workflowFolder.id}`,
              children: appendChildren(workflowFolder),
            }));

          const childWorkflows: any[] = workflowsArray
            .filter((workflow: any) => folder?.workflows?.includes(workflow.id))
            .map((workflow: any) => ({
              v2: workflow.supabase || false,
              key: `workflow=${workflow.id}`,
              isLeaf: true,
            }));

          return [...childFolders, ...childWorkflows];
        };

        const _gData = [];

        for (const folder of workflowAllFolders)
          if (!inFolderWorkflowFolders.includes(folder.id))
            _gData.push({
              key: `folder=${folder.id}`,
              children: appendChildren(folder),
            });

        for (const workflow of workflowsArray)
          if (!inFolderWorkflows.includes(workflow.id))
            _gData.push({
              v2: workflow.supabase || false,
              key: `workflow=${workflow.id}`,
              isLeaf: true,
            });

        setGData({
          searchParam: "",
          viewData: _.cloneDeep(_gData),
          realData: _gData,
        });

        setGeneratingTree(false);
        try {
          await reorderWorkflowStructure(_gData);
        } catch (error) {
          message.error("Failed to reorder workflow structure.");
          window.location.reload();
        }
      } catch (error) {
        window.location.reload();
      }
    };
    fetchWorkflows();
    fetchOrg().then((res: any) => setOrgDetails({ org_name: res.name, org_members: res.members }));
  }, []);

  const filterValidWorkflows = (dataArray: any[], workflowsMap: Map<string, any>): any[] => {
    return dataArray
      .map((item) => {
        // If the item is a folder
        if (item.key.startsWith("folder="))
          return {
            ...item,
            children: filterValidWorkflows(item.children, workflowsMap), // Recursively filter children
          };
        // If the item is a workflow
        if (item.key.startsWith("workflow=")) {
          const workflowId = item.key.split("=")[1]; // Extract the workflow ID
          return workflowsMap.has(workflowId) ? item : null; // Keep if it exists in the map
        }
        // Default: Exclude invalid items
        return null;
      })
      .filter(Boolean); // Remove `null` items
  };

  const handleDeleteClick = (workflow: any) => {
    setDeleteWorkflow(workflow);
    setIsV2(workflow.supabase || false);
  };
  const handleDeleteFolder = (folder: any) => setDeleteWorkflowFolder(folder);

  const handleDuplicateClick = (workflow: any) => {
    setDuplicateWorkflow(workflow);
    setIsV2(workflow.supabase || false);
  };

  const renameWorkflowFunc = async (workflow: any, newWorkflowName: string) => {
    setToRenameWorkflow(null);

    if ((workflow.name || workflow.draftWorkflowConfig.name) === newWorkflowName || !newWorkflowName.trim()) return;

    setAllWorkflows((prev: any) => {
      const w = prev.get(workflow.id);
      if (w.name) w.name = newWorkflowName;
      else w.draftWorkflowConfig.name = newWorkflowName;

      return prev;
    });

    try {
      await renameWorkflow(workflow.id, newWorkflowName, workflow.supabase || false);
    } catch (error) {
      message.error("Failed to rename workflow.");
      window.location.reload();
    }
  };

  const renameFolderFunc = async (folder: any, newFolderName: string) => {
    setToRenameFolder(null);

    if (folder.name === newFolderName || !newFolderName.trim()) return;

    setWorkflowFolders((prev: any) => {
      const f = prev.get(folder.id);
      f.name = newFolderName;
      return prev;
    });

    try {
      await renameWorkflowFolder(folder.id, newFolderName);
    } catch (error) {
      message.error("Failed to rename folder.");
      window.location.reload();
    }
  };

  const handleRenamed = (workflow: any) => setToRenameWorkflow(workflow);

  const handleRenamedFolder = (folder: any) => setToRenameFolder(folder);

  const onDragEnter: TreeProps["onDragEnter"] = (info) => {
    if (info.node.dragOver && !info.node.isLeaf && !info.node.expanded) setExpandedKeys([info.node.key as string]);
  };

  const onDrop: TreeProps["onDrop"] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    // if(info.node.isLeaf){
    //   return;
    // }

    const loop = (
      data: TreeDataNode[],
      key: React.Key,
      parentKey: React.Key,
      callback: (node: TreeDataNode, i: number, data: TreeDataNode[], key: React.Key) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) return callback(data[i], i, data, parentKey);
        if (data[i].children) loop(data[i].children!, key, data[i].key, callback);
      }
    };

    const view = [...gData.viewData];
    const data = [...gData.realData];

    // Find dragObject
    let dragObj: TreeDataNode;
    let viewDragObj: TreeDataNode;
    loop(data, dragKey, "root", (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    loop(view, dragKey, "root", (item, index, arr) => {
      arr.splice(index, 1);
      viewDragObj = item;
    });

    if (!info.dropToGap && !info.node.isLeaf) {
      // Drop on the content
      loop(data, dropKey, "root", (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
      loop(view, dropKey, "root", (item) => {
        item.children = item.children || [];
        item.children.unshift(viewDragObj);
      });
    } else {
      let ar: TreeDataNode[] = [];
      let i: number;
      loop(data, dropKey, "root", (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      let arView: TreeDataNode[] = [];
      let iView: number;
      loop(view, dropKey, "root", (_item, index, arr) => {
        arView = arr;
        iView = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i!, 0, dragObj!);
        arView.splice(iView!, 0, viewDragObj!);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i! + 1, 0, dragObj!);
        arView.splice(iView! + 1, 0, viewDragObj!);
      }
    }

    setGData({
      searchParam: gData.searchParam,
      viewData: view,
      realData: data,
    });
    // setExpandedKeys([]);

    try {
      reorderWorkflowStructure(data);
    } catch (error) {
      message.error("Failed to reorder workflow structure.");
      window.location.reload();
    }
  };

  const availableEmails = (sharedEmails: string[], ownerEmail: string) => {
    return orgDetails?.org_members.filter(
      (email: any) => !sharedEmails.includes(email.email) && email?.email !== userEmail && email?.email !== ownerEmail
    );
  };

  const handleToggleEmail = (e: any, email: string) => {
    e.stopPropagation();
    setSelectedEmails((prev) => (prev.includes(email) ? prev.filter((e) => e !== email) : [...prev, email]));
  };

  const handleShareWorkflow = async (selectedEmails: string[], workflow: any) => {
    if (selectedEmails.length === 0) return;
    const workflowId = workflow?.id;

    const sharingData = {
      v2: workflow.supabase,
      key: `workflow=${workflow.id}`,
      isLeaf: true,
    };

    try {
      await shareWorkflowAccess(workflowId, selectedEmails, "add", sharingData);
    } catch (error) {
      message.error("Failed to share workflow and save the structure.");
      window.location.reload();
    }
    setSelectedEmails([]);
  };

  const getWorkflowActionMenu = (workflow: any) => (
    <Menu
      className="rounded-lg"
      items={[
        {
          key: "rename",
          label: (
            <div className="flex items-center text-base gap-2 py-1">
              <img src={RenameIcon} alt="rename" width={16} className="opacity-60" />
              <span>Rename</span>
            </div>
          ),
          onClick: () => handleRenamed(workflow),
        },
        {
          key: "duplicate",
          label: (
            <div className="flex items-center text-base gap-2 py-1">
              <FaRegCopy size={16} className="text-gray-500" />
              <span>Duplicate</span>
            </div>
          ),
          onClick: () => handleDuplicateClick(workflow),
        },
        workflow.supabase && {
          key: "share",
          label: (
            <div className="flex items-center text-base gap-2 py-1">
              <IoMdShare size={16} className="text-gray-500" />
              <span>Share</span>
            </div>
          ),
          children: [
            {
              key: "share-checkboxes",
              label: (
                <div className="space-y-2 h-40 overflow-y-auto">
                  {availableEmails(workflow.shared_with || [], workflow.userEmail).length > 0 ? (
                    availableEmails(workflow.shared_with || [], workflow.userEmail).map((email: any) => (
                      <div
                        key={email.email}
                        className="flex items-center"
                        onClick={(e) => e.stopPropagation()} // prevent closing
                      >
                        <input
                          type="checkbox"
                          checked={selectedEmails.includes(email.email)}
                          onChange={(e: any) => handleToggleEmail(e, email.email)}
                          className="mr-2"
                        />
                        <label className="text-gray-800">{email.email}</label>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm text-gray-500">No additional users available</p>
                  )}
                </div>
              ),
            },
            {
              key: "share-button",
              label: (
                <Button
                  onClick={() => handleShareWorkflow(selectedEmails, workflow)}
                  className={clsx(
                    "mt-4 w-full py-2 rounded border border-gray-800 text-gray-800 hover:bg-gray-800 hover:text-white",
                    selectedEmails.length === 0 && "opacity-50 cursor-not-allowed"
                  )}
                  disabled={selectedEmails.length === 0}
                >
                  Share Permission
                </Button>
              ),
            },
          ],
        },
        {
          type: "divider",
        },
        {
          key: "delete",
          label: (
            <div className="flex items-center text-base gap-2 py-1 text-red-600">
              <img src={DeleteIcon} alt="delete" width={16} className="opacity-60" />
              <span>Delete</span>
            </div>
          ),
          onClick: () => handleDeleteClick(workflow),
        },
      ]}
    />
  );

  const RenameWorkflowInput = () => {
    const { register } = useFormContext();

    return (
      <input
        {...register("workflowName")}
        className={cn(
          "focus:!outline-none",
          "w-[85%] rounded-sm focus:!border-[#efe5ffc7] focus:!ring-0 focus:!ring-offset-0 !text-md !text-black px-0 py-1"
        )}
        autoFocus
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.currentTarget.blur();
          }
        }}
      />
    );
  };

  const RenameWorkflow = () => {
    const methods = useForm({
      defaultValues: {
        workflowName: toRenameWorkflow?.name || toRenameWorkflow?.draftWorkflowConfig?.name || "",
      },
    });

    const onBlur = async (data: { workflowName: string }) => {
      await renameWorkflowFunc(toRenameWorkflow, data.workflowName);
    };

    return (
      <FormProvider {...methods}>
        <form className="inputStyleCSS flex items-center gap-x-4 w-full" onBlur={methods.handleSubmit(onBlur)}>
          <RenameWorkflowInput />
        </form>
      </FormProvider>
    );
  };

  const getFolderActionMenu = (folder: any) => (
    <Menu
      onClick={(e) => e.domEvent.stopPropagation()}
      className="rounded-lg"
      items={[
        {
          key: "rename",
          label: (
            <div className="flex text-base items-center gap-2 py-1">
              <img src={RenameIcon} alt="rename" width={16} className="opacity-60" />
              <span>Rename</span>
            </div>
          ),
          onClick: () => handleRenamedFolder(folder),
        },
        {
          key: "create",
          label: (
            <div className="flex text-base items-center gap-2 py-1">
              <img src={CreateIconBlack} alt="create" width={16} className="opacity-60" />
              <span>Create Workflow</span>
            </div>
          ),
          onClick: () => {
            setSelectedFolder(folder.id);
            setCreate("v2");
          },
        },
        {
          type: "divider",
        },
        {
          key: "delete",
          label: (
            <div className="flex text-base items-center gap-2 py-1 text-red-600">
              <img src={DeleteIcon} alt="delete" width={16} className="opacity-60" />
              <span>Delete</span>
            </div>
          ),
          onClick: () => handleDeleteFolder(folder),
        },
      ]}
    />
  );

  const RenameWorkflowFolderInput = () => {
    const { register } = useFormContext();

    return (
      <input
        {...register("folderName")}
        className={cn(
          "focus:!outline-none",
          "w-[85%] rounded-sm focus:!border-[#efe5ffc7] focus:!ring-0 focus:!ring-offset-0 !text-md !text-black px-0 py-1"
        )}
        autoFocus
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.currentTarget.blur();
          e.stopPropagation();
        }}
      />
    );
  };

  const RenameWorkflowFolder = () => {
    const methods = useForm({
      defaultValues: {
        folderName: toRenameFolder?.name || "",
      },
    });

    const onBlur = async (data: { folderName: string }) => {
      await renameFolderFunc(toRenameFolder, data.folderName);
    };

    return (
      <FormProvider {...methods}>
        <form
          className="inputStyleCSS flex items-center gap-x-4 w-full"
          onBlur={methods.handleSubmit(onBlur)}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <RenameWorkflowFolderInput />
        </form>
      </FormProvider>
    );
  };

  if (loading)
    return (
      <div className="h-full w-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Workflows" />
      </div>
    );
  const items = [
    {
      label: (
        <div className="flex gap-2 w-full items-center font-medium text-md rounded-md" onClick={() => setCreate("v2")}>
          <img src={CreateIconBlack} alt="create-workflow-v2" width={16} />
          <p> Create Workflow</p>
        </div>
      ),
      key: "create workflow v2",
    }, // remember to pass the key prop
    {
      label: (
        <div
          className="flex gap-2 w-full items-center font-medium text-md rounded-md"
          onClick={() => setCreateFolder(true)}
        >
          <img src={WorkflowFolderIcon} alt="workflow-folder-icon" width={16} />
          <p> Create Folder</p>
        </div>
      ),
      key: "create folder",
    },
  ];

  if (create !== "") {
    return (
      <CreateWorkflowUI
        folders={Array.from((workflowFolders ?? new Map()).values())}
        close={() => {
          setCreate("");
          setSelectedFolder("root");
        }}
        selectedFolderId={selectedFolder}
        v2={true}
      />
    );
  }

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-5">
      <div className="w-full flex flex-row gap-2 items-center relative px-5 pt-5">
        {/* CollapsibleIcon in top left */}
        <CollapsibleIcon isHeader />
        <div className="text-2xl font-bold bg-gradient-to-r from-[#5e35b1] via-[#d192e2] to-[#ab47bc] bg-clip-text text-black cursor-default">
          Your Workflows
        </div>
      </div>
      <div className="flex flex-row justify-between items-center w-full px-5">
        <div className="inputStyleCSS h-fit w-2/3 flex justify-between items-center px-4 border border-black rounded-md">
          <img src={SearchIcon} alt="search icon" width={20} />
          <input
            type="text"
            placeholder="Search Your Workflows"
            onChange={(e) => debounceFn(e?.target?.value)}
            className={cn(
              "focus:!outline-none ",
              "w-full rounded-md focus:!border-none !border-none focus:!ring-0 focus:!ring-offset-0 !text-black"
            )}
          />
        </div>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <button
            onClick={(e) => e.preventDefault()}
            className="flex gap-x-2 items-center bg-black hover:bg-gray-800 py-2 px-4 rounded-md font-medium text-white"
          >
            <img src={CreateIcon} alt="create-workflow" width={16} color="black" /> Create
          </button>
        </Dropdown>
      </div>

      <div>
        <div className="px-8 py-2 text-md grid grid-cols-8 font-semibold text-gray-700 border-b-[0.5px] border-gray-300">
          <div className="text-lg col-span-6">Title</div>
          <div className="text-lg col-span-2">Created On</div>
        </div>
        {!generatingTree ? (
          <Tree
            className="draggable-tree pr-4 text-gray-500"
            expandedKeys={expandedKeys}
            defaultCheckedKeys={[]}
            draggable={{
              icon: <MdDragIndicator size={20} color="black" />,
            }}
            blockNode
            titleRender={(node: any) =>
              node.isLeaf ? (
                <Workflow
                  workflow={allWorkflows?.get(node.key.split("=")[1])}
                  getWorkflowActionMenu={getWorkflowActionMenu}
                  RenameWorkflow={RenameWorkflow}
                  toRenameWorkflow={toRenameWorkflow}
                />
              ) : (
                <Folder
                  folder={workflowFolders?.get(node.key.split("=")[1])}
                  getFolderActionMenu={getFolderActionMenu}
                  RenameWorkflowFolder={RenameWorkflowFolder}
                  toRenameFolder={toRenameFolder}
                  setExpandedKeys={setExpandedKeys}
                />
              )
            }
            onDragEnter={onDragEnter}
            onDrop={(e) => onDrop(e)}
            treeData={gData.viewData}
            motion={false}
            virtual={false}
          />
        ) : (
          <div className="h-1/4 w-full">
            <Loader loaderType={LOADER_TYPES.generating} payload="Workflow Structure" />
          </div>
        )}
      </div>

      <CreateWorkflowFolder modal={createFolder} close={() => setCreateFolder(false)} />

      {deleteWorkflow && (
        <DeleteWorkflow
          modal={!!deleteWorkflow}
          close={() => setDeleteWorkflow(null)}
          workflow={deleteWorkflow}
          isV2={isV2}
          userEmail={userEmail}
        />
      )}

      {deleteWorkflowFolder && (
        <DeleteWorkflowFolder
          modal={!!deleteWorkflowFolder}
          close={() => setDeleteWorkflowFolder(null)}
          folder={deleteWorkflowFolder}
        />
      )}

      {duplicateWorkflow && (
        <DuplicateWorkflow
          modal={!!duplicateWorkflow}
          close={() => setDuplicateWorkflow(null)}
          workflow={duplicateWorkflow}
          workflowFolders={Array.from((workflowFolders ?? new Map()).values())}
        />
      )}
    </div>
  );
};

export default Workflows;
