import { useEffect, useState } from "react";
import { useUser } from "../../../contexts/UserContext";
import { validateEmail } from "../../../utils/functions";
import Loader from "../../Loader";
import { LOADER_TYPES } from "../../../utils/constants";
import MemberItem from "./MemberItem";
import LoaderButton from "../../LoaderButton";

type Props = {
  orgId: string;
};

const Members = ({ orgId }: Props) => {
  const { fetchMembers, addMember, removeMember } = useUser();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<
    {
      email: string;
      role: string;
    }[]
  >([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    setLoading(true);
    fetchMembers().then((res) => {
      setMembers(res);
      setLoading(false);
    });
  }, [refresh, orgId]);

  const handleInvite = async () => {
    if (!validateEmail(email) || !role.length) {
      console.error("Invalid credentials");
      return;
    }
    await addMember(email, role);
    setRefresh(refresh + 1);
    setEmail("");
  };

  const handleRemove = (email: string, role: string) => {
    if (!email.length || !role.length) {
      console.error("Invalid credentials");
      return;
    }
    removeMember(email, role).then(() => {
      setRefresh(refresh + 1);
    });
  };

  if (loading) return <Loader payload={"members"} loaderType={LOADER_TYPES.fetching} />;
  return (
    <div className="flex flex-col gap-8 p-8 h-[75vh] w-full overflow-y-auto">
      <div className="w-full flex flex-col gap-2">
        <div className="font-semibold text-lg text-black">Add new member</div>
        <div className="w-full flex flex-row gap-4">
          <input
            className="outline-none border-2 border-gray-400 rounded-md w-2/5"
            placeholder="Enter the email of who you'd like to invite"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <select
            className="outline-none border-2 border-gray-400 rounded-md w-1/5"
            onChange={(e) => {
              setRole(e.target.value);
            }}
          >
            <option value="" className="text-gray-400">
              Select role
            </option>
            <option value="org_lead">Admin</option>
            <option value="org_member">Member</option>
          </select>
          <LoaderButton
            text="Send invite"
            onClickCallback={handleInvite}
            loaderText="Sending..."
            error=""
            disabled={false}
          />
          {/* <button
            className="bg-primary font-semibold text-white py-4 px-6 rounded-md ml-auto"
            onClick={handleInvite}
          >
            Send invite
          </button> */}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="font-semibold text-lg text-black">Your team members</div>
        <div className="flex flex-col gap-2">
          {members.map((member, idx) => (
            <MemberItem key={idx} member={member} handleRemove={handleRemove} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Members;
