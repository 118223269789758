export function calculateTotalCreditsConsumed(stepDownResponse: Record<string, any>, credits: any): number {
  console.log(stepDownResponse, credits);
  // if stepDownResponse is empty(or undefined) or credits is empty, return the first value of credits
  if (!stepDownResponse || !credits || Object.keys(stepDownResponse).length === 0) {
    return (credits && Object.values(credits)[0]) || 0;
  }
  // Calculate the total creditsConsumed
  return Object.values(stepDownResponse).reduce((total, user, index) => {
    const userKey = Object.keys(stepDownResponse)[index];
    // Use user.creditsConsumed if defined; otherwise, use the corresponding value from credits
    const userCredits = user?.creditsConsumed !== undefined ? user.creditsConsumed : credits[userKey];
    return total + (userCredits || 0);
  }, 0);
}
