import { message, Modal } from "antd";
import { useState } from "react";

import LoaderButtonBlack from "@/Components/LoaderButtonBlack";
import { useWorkflow } from "@/contexts/WorkflowContext";
import { cn } from "@/utils/cn";

type Props = {
  modal: boolean;
  close: () => void;
};

const CreateWorkflowFolder = ({ modal, close }: Props) => {
  const [workflowFolderName, setWorkflowFolderName] = useState("");
  const { createNewWorkflowFolder } = useWorkflow();

  const handleCreateFolder = async () => {
    if (!workflowFolderName.trim()) {
      message.error("Folder Name cannot be empty");
      return;
    }
    const id = await createNewWorkflowFolder(workflowFolderName);
    if (id === "") {
      message.error("Error creating workflow Folder");
      return;
    }
    close(); // Close the modal after successful creation
    window.location.reload(); // Reload the page
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleCreateFolder();
    }
  };

  return (
    <Modal centered open={modal} onCancel={close} footer={null} width="40%">
      <div className="flex flex-col gap-4">
        <h1 className="text-lg font-bold">Create Workflow Folder</h1>
        <div className="inputStyleCSS2 flex gap-2 w-full items-center">
          <label htmlFor="workflow-name" className="text-md font-semibold w-fit text-nowrap">
            Folder Name:
          </label>
          <input
            type="text"
            name="workflow-name"
            id="workflow-name"
            placeholder="Enter workflow folder name"
            value={workflowFolderName}
            onChange={(e) => setWorkflowFolderName(e.target.value)}
            onKeyDown={handleKeyDown} // Add keydown event listener
            className={cn(
              "focus:!outline-none !text-md",
              "w-full rounded-md focus:!border-gray-500 focus:!ring-0 focus:!ring-offset-0 !text-md !text-black"
            )}
          />
        </div>

        <LoaderButtonBlack
          disabled={false}
          error=""
          loaderText="Creating Folder..."
          text="Create Folder"
          onClickCallback={handleCreateFolder}
          btnClasses="w-fit text-white bg-black hover:bg-gray-800"
        />
      </div>
    </Modal>
  );
};

export default CreateWorkflowFolder;
