import { useEffect } from "react";
import Loader from "../../Components/Loader";
import { LOADER_TYPES } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useConnections } from "@/contexts/ConnectionContext";

const AirtableRedirect = () => {
  const { saveOAuthConnection } = useConnections();
  const navigate = useNavigate();

  useEffect(() => {
    const func = async () => {
      try {
        const url = new URL(window.location.href);
        const code = url.searchParams.get("code");
        const error = url.searchParams.get("error");
        const state = url.searchParams.get("state");

        if (error) {
          throw new Error(decodeURIComponent(error));
        }

        if (!code) {
          throw new Error("No authorization code received");
        }
        console.log("code", code);
        await saveOAuthConnection("airtable", { code, state });
        navigate("/connections?success=true");
      } catch (error: any) {
        console.error("Airtable OAuth Error:", error);
        navigate(`/connections?error=${encodeURIComponent(error.message)}`);
      }
    };
    func();
  }, []);

  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Loader payload={"Connecting to Airtable"} loaderType={LOADER_TYPES.loading} />
    </div>
  );
};

export default AirtableRedirect;
