import { ConfigProvider, Divider, Drawer, Select, Switch, Table } from "antd";
import { useEffect, useState } from "react";
import { ResponseStructure } from "../models";
import { createListConnection, updateListConnection } from "../apis";
import { useParams } from "react-router-dom";

type Props = {
  update: string | undefined;
  loading: boolean;
  close: () => void;
  existingConnections: any[];
  workflows: any[];
  responseStructure: ResponseStructure[];
  addConnection: (connection: any) => void;
  updateExistingConnection: (connection: any) => void;
};

const Connection = ({
  update,
  close,
  existingConnections,
  workflows,
  loading,
  responseStructure,
  addConnection,
  updateExistingConnection,
}: Props) => {
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>(
    update ? workflows.find((workflow) => workflow.id === update) : undefined
  );
  const selectedConnection = update
    ? existingConnections.find((connection) => connection.workflow_id === update)
    : undefined;
  const { id } = useParams();
  const [inputs, setInputs] = useState<any[]>([]);
  const [mapping, setMapping] = useState<any>(selectedConnection ? selectedConnection.payload_map : {});
  const [existingLeads, setExistingLeads] = useState(false);

  useEffect(() => {
    setSelectedWorkflow(workflows.find((workflow) => workflow.id === update) || undefined);
    const connection = existingConnections.find((connection) => connection.workflow_id === update);
    setMapping(connection?.payload_map || {});
  }, [update]);

  useEffect(() => {
    const actions = selectedWorkflow?.actions || [];
    const inputs = actions.find((action: any) => action.id === "id1")?.responseConfiguration || [];
    setInputs(inputs);
  }, [selectedWorkflow]);

  const updateConnection = async () => {
    if (!selectedWorkflow || !id) return;
    if (update === "") {
      const newConn = await createListConnection(id, selectedWorkflow.id, mapping, existingLeads);
      addConnection(newConn);
    } else {
      const updated = await updateListConnection(id, selectedWorkflow.id, mapping);
      updateExistingConnection(updated);
    }
    close();
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={update !== undefined}
        onClose={close}
        width="30%"
        className="!font-Lausanne !p-0 rounded-lg"
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            <button
              className={`text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                loading || !selectedWorkflow ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
              }`}
              onClick={updateConnection}
              disabled={loading || !selectedWorkflow}
            >
              {loading ? "Saving..." : update === "" ? "Create" : "Update"}
            </button>
          </div>
        }
        title={
          <p className="text-xl font-semibold">{update === "" ? "Create a new Connection" : "Update Connection"}</p>
        }
      >
        <div className="bg-white overflow-x-hidden">
          <div className="flex flex-col w-full gap-3 border-b">
            <p className="font-semibold text-lg">Choose a workflow to connect</p>
            <Select
              disabled={update !== ""}
              showSearch
              className="w-full rounded-md"
              placeholder="Select a workflow"
              optionFilterProp="label"
              value={selectedWorkflow?.id || ""}
              options={workflows
                .filter((workflow) => !existingConnections.find((connection) => connection.workflow_id === workflow.id))
                .map((workflow) => ({
                  label: workflow.name || workflow.publishedWorkflowConfig.name,
                  value: workflow.id,
                }))}
              onChange={(value) => {
                const workflow = workflows.find((workflow) => workflow.id === value);
                setSelectedWorkflow(workflow);
              }}
            />
          </div>
          <Divider className="border-gray-300" />
          <div className="flex flex-col w-full gap-3">
            <div className="flex flex-col w-full gap-1">
              <p className="font-medium text-lg">Map your data</p>
              <p className="text-sm font-light">
                The input fields from the workflow chosen above will appear here. Match your list's inputs to the fields
                you want to send to the selected workflow.
              </p>
            </div>
            <Table
              dataSource={inputs.map((input) => {
                return {
                  key: input.responseId,
                  name: input.name,
                  field: (
                    <Select
                      className="w-full rounded-md"
                      showSearch
                      placeholder="Select a field"
                      optionFilterProp="label"
                      defaultValue={mapping[input.responseId]}
                      options={responseStructure.map((structure) => ({
                        label: structure.name,
                        value: structure.responseStructureId,
                      }))}
                      onChange={(value) => {
                        setMapping((prev: any) => ({ ...prev, [input.responseId]: value }));
                      }}
                    />
                  ),
                };
              })}
              columns={[
                {
                  title: "Input Name",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "List Field",
                  dataIndex: "field",
                  key: "field",
                },
              ]}
              pagination={false}
            />
          </div>
          {update === "" && (
            <>
              <Divider className="border-gray-300" />
              <div className="flex flex-col w-full gap-3">
                <div className="flex flex-col w-full gap-1">
                  <p className="font-medium text-lg">Push existing leads</p>
                  <p className="text-sm font-light">
                    This will add and trigger the workflow for all the leads currently in the list.
                  </p>
                </div>
                <Switch
                  className="w-fit ml-1 mb-1"
                  defaultChecked={existingLeads}
                  onChange={(checked) => setExistingLeads(checked)}
                />
              </div>
            </>
          )}
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default Connection;
