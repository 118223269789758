import { useEffect, useState } from "react";
import { PayloadStructure } from "../models";
import { Collapse, ConfigProvider, DatePicker, Input, InputNumber, Switch, message, Select } from "antd";
import { RiExpandUpDownLine } from "react-icons/ri";
import { crustDataIndustries } from "@/utils/industries";
import LoaderButton from "@/Components/LoaderButton";
import { createSignal } from "../apis";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { regions } from "@/utils/crustdataRegions";
import TextArea from "antd/es/input/TextArea";

type Props = {
  payloadStructure: PayloadStructure[];
  signalId: string;
  name: string;
  preview?: boolean;
  generatePreview: (payload: any) => Promise<void>;
};

const Filters = ({ payloadStructure, signalId, name, preview, generatePreview }: Props) => {
  const [payloads, setPayloads] = useState<any>({});
  const [requiredPayloads, setRequiredPayloads] = useState<any>([]);
  const [crustdataRegions, setCrustdataRegions] = useState<string[]>(regions);
  const navigate = useNavigate();

  // Utility function to validate `minMax` payloads
const validateMinMaxPayloads = (requiredPayloads: any[], payloads: any): boolean => {
  // Filter for `minMax` payloads
  const minMaxPayloads = requiredPayloads.filter(
    (payload: any) => payload.type === "minMax"
  );

  // Validate each `minMax` payload
  for (const payload of minMaxPayloads) {
    const { payloadStructureId, name } = payload;
    const min = payloads[payloadStructureId]?.min;
    const max = payloads[payloadStructureId]?.max;

    if (!min || !max) {
      message.error(`Both 'min' and 'max' values are required for "${name}"`);
      return false;
    }

    if (min > max) {
      message.error(`The 'min' value cannot be greater than the 'max' value for "${name}"`);
      return false;
    }
  }

  return true; 
};

  useEffect(() => {
    const requiredPayloads = payloadStructure.filter((payload) => payload.required);
    setRequiredPayloads(requiredPayloads);
  }, [payloadStructure]);

  const payloadSwitch = (payload: PayloadStructure) => {
    switch (payload.type) {
      case "minMax":
        return (
          <div className="flex gap-4 items-center">
            {/* Input for Minimum Value */}
            <InputNumber
              min={0}
              placeholder="Min Value"
              className="w-full"
              value={payloads[payload.payloadStructureId]?.min || ""}
              onChange={(value) => {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: {
                    ...prev[payload.payloadStructureId],
                    min: value || 0, 
                  },
                }));
              }}
            />
            {/* Input for Maximum Value */}
            <InputNumber
              min={0}
              placeholder="Max Value"
              className="w-full"
              value={payloads[payload.payloadStructureId]?.max || ""}
              onChange={(value) => {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: {
                    ...prev[payload.payloadStructureId],
                    max: value || 0, 
                  },
                }));
              }}
            />
          </div>
        );
      case "dropdown":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads[payload.payloadStructureId] || ""}
            showSearch
            className="w-full"
            optionFilterProp="name"
            onChange={(value) => {
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: value,
              }));
            }}
            options={(payload.values ?? []).map((value: any) => {
              if (typeof value === "string")
                return {
                  label: value,
                  value,
                };
              return {
                label: (
                  <div className="flex justify-between items-center gap-2 p-1 w-full">
                    <div className="flex items-center gap-2 justify-end text-ellipsis">
                      {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                      <p>{value?.name}</p>
                    </div>
                  </div>
                ),
                value: value?.id || value.name,
                name: value?.name,
              };
            })}
          />
        );
      case "industry":
          return (
            <Select
              className="w-full"
              suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
              value={payloads[payload.payloadStructureId] || ""}
              showSearch
              onChange={(value) => {
                setPayloads((prev: any) => ({
                  ...prev,
                  [payload.payloadStructureId]: value,
                }));
              }}
              options={crustDataIndustries.map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          );
      case "industries":
        return (
          <Select
            className="w-full"
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads[payload.payloadStructureId] || []}
            showSearch
            mode="multiple"
            onChange={(value) => {
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: value,
              }));
            }}
            options={crustDataIndustries.map((value: any) => ({
              label: value.name,
              value: value.id,
            }))}
          />
        );
      case "crustdataRegion":
        return (
          <Select
            className="w-full"
            virtual={true}
            onSearch={(value) => {
              if (!value) return setCrustdataRegions(regions);
              // display perfect match first
              const perfectMatch = regions.filter((region) => region.toLowerCase() === value.toLowerCase());
              // display partial match next by sorting with matching length
              const partialMatch = regions
                .filter((region) => region.toLowerCase().includes(value.toLowerCase()))
                .sort((a, b) => a.length - b.length);

              const newSet = new Set([...perfectMatch, ...partialMatch]);
              setCrustdataRegions(newSet.size ? Array.from(newSet) : []);
            }}
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads[payload.payloadStructureId] || []}
            showSearch
            onChange={(value) => {
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: value,
              }));
            }}
            options={crustdataRegions.map((value: string) => ({
              label: value,
              value: value,
            }))}
          />
        );
      case "multiDropdown":
        return (
          <Select
            suffixIcon={<RiExpandUpDownLine className="w-5 h-5 text-[#999]" />}
            value={payloads[payload.payloadStructureId] || []}
            className="w-full"
            showSearch
            optionFilterProp="name"
            mode="multiple"
            onChange={(value) => {
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: value,
              }));
            }}
            options={(payload.values ?? []).map((value: any) => {
              if (typeof value === "string")
                return {
                  label: value,
                  value,
                };
              return {
                label: (
                  <div className="flex justify-between items-center gap-2 p-1 w-full">
                    <div className="flex items-center gap-2 justify-end text-ellipsis">
                      {value?.logo && <img src={value?.logo} alt={value?.name} className="w-6 h-6" />}
                      <p>{value?.name}</p>
                    </div>
                  </div>
                ),
                value: value?.id || value.name,
                name: value?.name,
              };
            })}
          />
        );
      case "number":
        return (
          <Input
            type="number"
            min={1}
            className="w-full p-1 rounded"
            value={payloads[payload.payloadStructureId] || ""}
            onChange={(e) => setPayloads((prev: any) => ({ ...prev, [payload.payloadStructureId]: e.target.value }))}
          />
        );
      case "date":
        return (
          <DatePicker
            format="YYYY-MM-DD"
            className="w-full p-1 rounded"
            allowClear={false}
            value={dayjs(
              payloads[payload.payloadStructureId] || moment.utc().local().format("YYYY-MM-DD"),
              "YYYY-MM-DD"
            )}
            onChange={(value) =>
              setPayloads((prev: any) => ({ ...prev, [payload.payloadStructureId]: value?.format("YYYY-MM-DD") }))
            }
          />
        );
      case "textarea":
        return (
          <TextArea
            className="w-full p-1 rounded"
            value={payloads[payload.payloadStructureId] || ""}
            onChange={(e) => setPayloads((prev: any) => ({ ...prev, [payload.payloadStructureId]: e.target.value }))}
          />
        );
      case "boolean":
        return (
          <Switch
            checked={payloads[payload.payloadStructureId] || false}
            onChange={(checked) => {
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: checked,
              }));
            }}
          />
        );
      case "multiInput":
        return (
          <Select
            mode="tags"
            className="w-full"
            value={payloads[payload.payloadStructureId] || []}
            onChange={(value) => {
              setPayloads((prev: any) => ({
                ...prev,
                [payload.payloadStructureId]: value,
              }));
            }}
            tokenSeparators={[","]}
            open={false}
            suffixIcon={null}
            placeholder="Type and press Enter to add values"
          />
        );
      case "string":
      default:
        return (
          <Input
            type="text"
            className="w-full p-1 rounded"
            value={payloads[payload.payloadStructureId] || ""}
            onChange={(e) => setPayloads((prev: any) => ({ ...prev, [payload.payloadStructureId]: e.target.value }))}
          />
        );
    }
  };

  const items = payloadStructure.map((payload) => ({
    key: payload.payloadStructureId,
    label: (
      <div className="block items-start gap-2 w-[calc(100%-1px)]">
        <p className="text-md font-normal text-dark">
          {payload.name}
          {payload.required && <span className="text-red-500">*</span>}
        </p>
        {payloads[payload.payloadStructureId] &&
          (Array.isArray(payloads[payload.payloadStructureId]) ? (
            <div className="flex flex-wrap gap-1">
              {payloads[payload.payloadStructureId].map((item: any) => (
                <p className="bg-primary/20 py-0.5 px-1 text-xs text-gray-500">
                  {typeof item === "object" ? JSON.stringify(item) : item}
                </p>
              ))}
            </div>
          ) : (
            <p className=" w-fit bg-primary/20 py-0.5 px-1 text-xs text-gray-500 break-words">
              {typeof payloads[payload.payloadStructureId] === "object"
                ? JSON.stringify(payloads[payload.payloadStructureId])
                : payloads[payload.payloadStructureId].toString()  }
            </p>
          ))}
      </div>
    ),
    children: (
      <div className="flex flex-wrap gap-2 w-full">
        <p className="text-sm font-light">{payload.description}</p>
        {payloadSwitch(payload)}
      </div>
    ),
  }));

  return (
    <div className="w-full h-fit flex flex-col gap-4 p-4 pl-0">
      <div className="w-full flex items-start gap-1 rounded-md">
        <p className="text-2xl font-medium">Filters</p>
      </div>
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              colorBorder: "rgb(209 213 219)",
              borderRadius: 10,
              headerBg: "white",
            },
          },
        }}
      >
        <Collapse rootClassName="!font-Lausanne" destroyInactivePanel items={items} expandIconPosition="end"></Collapse>
      </ConfigProvider>
      {preview && (
        <LoaderButton
          text="Generate Preview"
          loaderText="Generating Preview..."
          btnClasses="mt-auto"
          onClickCallback={async () => {
            // check if all required payloads are present
            if (requiredPayloads.some((payload: any) => !payloads[payload.payloadStructureId])) {
              message.error("Please fill all required fields");
              return;
            }
            await generatePreview(payloads);
            return;
          }}
          error=""
          disabled={false}
        />
      )}
      <LoaderButton
        text="Create Signal"
        loaderText="Creating Signal..."
        btnClasses="mt-auto"
        onClickCallback={async () => {
          // Validate `minMax` payloads
          if (!validateMinMaxPayloads(requiredPayloads, payloads)) {
            return; 
          }

          // check if all required payloads are present
          if (requiredPayloads.some((payload: any) => !payloads[payload.payloadStructureId])) {
            message.error("Please fill all required fields");
            return;
          }
          const newId = await createSignal(SIGNAL_ID_TO_URL[signalId as keyof typeof SIGNAL_ID_TO_URL], payloads, name);
          navigate(`/list/${newId}`);
        }}
        error=""
        disabled={false}
      />
    </div>
  );
};

export default Filters;
