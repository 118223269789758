import { App, Drawer, message } from "antd";
import { useEffect, useState } from "react";
import { FaCoins, FaRegCopy } from "react-icons/fa";
import { calculateTotalCreditsConsumed } from "../utils";

type Props = {
  open: boolean;
  close: () => void;
  data: any;
  title: () => React.ReactNode;
  credits: number | { [key: string]: number };
};

/**
 * @Test_Cases for calculateTotalCreditsConsumed() function
 * 1. ✅old response with empty data
 * 2. ✅old response with data but no creditsConsumed field
 * 3. ✅new response with empty data
 * 4. ✅new response with data and with creditsConsumed field
 */

const LinkedinPostViewer = ({ open, close, data, title, credits }: Props) => {
  const response = data?.largeActionResponse || {};
  const stepDownResponse = data?.stepDownResponse;
  const [newData, setNewData] = useState<any>(response);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  useEffect(() => {
    if (!open) setNewData({});
    else setNewData(response);
  }, [open, data]);

  const toggleAccordion = (index: number) => {
    setActiveIndices((prevIndices) =>
      prevIndices.includes(index) ? prevIndices.filter((i) => i !== index) : [...prevIndices, index]
    );
  };
  const handleCopyJSON = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const jsonString = JSON.stringify(content, null, 2); // Convert JSON to string with indentation
      await navigator.clipboard.writeText(jsonString);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };
  const handleCopyText = async (content: any, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      await navigator.clipboard.writeText(content);
      message.success("Content copied to clipboard!");
      // Check the data that got copied
      await navigator.clipboard.readText();
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };

  return (
    <Drawer title={title()} placement="right" onClose={close} open={open} width="30%">
      <App>
        <div className="flex flex-col gap-2 !text-[14px]">
          <div className="text-[20px] font-bold">
            <span className="text-[#007BB0]">{newData["Number of Posts"]} LinkedIn Post(s)</span> Found
          </div>
          <hr />

          <div className="space-y-2">
            {newData["Posts"]?.map((post: any, idx: number) => (
              <div
                key={idx}
                className={`${
                  activeIndices.includes(1001 + idx + 1) ? "" : " bg-[#FAFAFA]"
                } border border-gray-200 p-2 rounded-sm cursor-pointer mt-2 `}
              >
                <div className="flex items-center justify-between" onClick={() => toggleAccordion(1001 + idx + 1)}>
                  <p className="text-[16px] font-semibold flex items-center gap-x-2">
                    Post {idx + 1 < 10 ? "0" + (idx + 1) : idx + 1}
                    {activeIndices.includes(1001 + idx + 1) ? (
                      <UpArrowNew color="black" />
                    ) : (
                      <DownArrowNew color="black" />
                    )}
                  </p>
                  <p className="cursor-pointer flex items-center gap-x-2" onClick={(e) => handleCopyJSON(post, e)}>
                    Copy All <FaRegCopy className="text-gray-600" />
                  </p>
                </div>
                {activeIndices.includes(1001 + idx + 1) && (
                  <div className="space-y-2">
                    <div className="flex flex-col w-full group relative">
                      <span className="w-full">URL</span>
                      <div className="w-full bg-[#F2FBFF] px-2 py-1 flex justify-between items-center break-words">
                        <p className="w-[95%]">{post.url || "\u00A0"}</p>
                        <p
                          className="cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(post.url, e)}
                        >
                          <FaRegCopy className="text-gray-600" />
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col w-full group ">
                      <span className="w-full">Post</span>
                      <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                        <p className="w-[95%]">{post?.post || post?.commentary || "\u00A0"}</p>
                        <p
                          className="cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(post?.post || post?.commentary || "\u00A0", e)}
                        >
                          <FaRegCopy className="text-gray-600" />
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4">
                      <div className="col-span-1 flex flex-col w-full group">
                        <span className="w-full">Date</span>
                        <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                          {post.date || "\u00A0"}
                          <p
                            className="cursor-pointer hidden group-hover:block"
                            onClick={(e) => handleCopyText(post.date || "\u00A0", e)}
                          >
                            <FaRegCopy className="text-gray-600" />
                          </p>
                        </div>
                      </div>
                      <div className="col-span-1 flex flex-col w-full group">
                        <span className="w-full">Time</span>
                        <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                          {post.time || "\u00A0"}
                          <p
                            className="cursor-pointer hidden group-hover:block"
                            onClick={(e) => handleCopyText(post.time, e)}
                          >
                            <FaRegCopy className="text-gray-600" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-full group">
                      <span className="w-full">Datetime</span>
                      <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                        {post?.datetime || "\u00A0"}
                        <p
                          className="cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(post?.datetime || "\u00A0", e)}
                        >
                          <FaRegCopy className="text-gray-600" />
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4">
                      <div className="col-span-1 flex flex-col w-full group">
                        <span className="w-full">Comment Count</span>
                        <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                          {post?.commentCount || post?.totalComments || "\u00A0"}
                          <p
                            className="cursor-pointer hidden group-hover:block"
                            onClick={(e) => handleCopyText(post?.commentCount || post?.totalComments || "\u00A0", e)}
                          >
                            <FaRegCopy className="text-gray-600" />
                          </p>
                        </div>
                      </div>
                      <div className="col-span-1 flex flex-col w-full group">
                        <span className="w-full">Reaction Count</span>
                        <div className="w-full bg-[#F2FBFF] px-2 py-1 break-words flex items-center justify-between">
                          {post?.reaction_count || post?.totalReactions || "\u00A0"}
                          <p
                            className="cursor-pointer hidden group-hover:block"
                            onClick={(e) => handleCopyText(post?.reaction_count || post?.totalReactions || "\u00A0", e)}
                          >
                            <FaRegCopy className="text-gray-600" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </App>
      <div className="mt-4 flex items-center">
        <h1 className="font-semibold text-md">Total cost:</h1>
        <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
          <span className="font-bold text-md">{calculateTotalCreditsConsumed(stepDownResponse, credits)}</span>
          <FaCoins className="ml-1 text-purple-500 w-5" />
        </div>
      </div>
    </Drawer>
  );
};

export const DownArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.296875V5.16511L9 12.999L18 5.16511V0.296875L9 8.11216L0 0.296875Z" fill={color} />
  </svg>
);
export const UpArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.999 12.6953V7.82708L8.99902 -0.00685804L-0.000980854 7.82708V12.6953L8.99902 4.88003L17.999 12.6953Z"
      fill={color}
    />
  </svg>
);

export default LinkedinPostViewer;
