import { useState } from "react";
import { motion } from "framer-motion";
import LoaderButton from "../LoaderButton";
import CheckCircle from "../../assets/SVGs/CheckCircle.svg";
import { setStateType } from "../../utils/constants";
import { useUser } from "../../contexts/UserContext";

type Props = {
  setReset: setStateType<boolean>;
  email: string;
};

const ResetPassword = ({ setReset, email }: Props) => {
  const [sent, setSent] = useState(false);
  const { resetPassword } = useUser();

  const handleReset = async () => {
    await resetPassword(email);
    setSent(true);
  };

  return (
    <motion.div
      className="h-screen w-screen bg-gray bg-opacity-50 backdrop-blur-md flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex flex-col justify-center items-center bg-white rounded-3xl p-8 gap-6 shadow-lg border border-gray-200 w-full max-w-lg font-TWK-Lausanne-500">
        {sent ? (
          <>
            <div className="flex items-center gap-3">
              <img src={CheckCircle} alt="Check" className="h-10 w-10" />
              <h2 className="text-2xl text-gray-800 font-TWK-Lausanne-300">Password reset link sent!</h2>
            </div>
            <button
              className="mt-4 bg-primary text-white px-6 py-2 rounded-full hover:bg-dark transition"
              onClick={() => setReset(false)}
            >
              Go Back
            </button>
          </>
        ) : (
          <>
            <h2 className="text-2xl text-gray-800 text-center font-TWK-Lausanne-500">
              Are you sure you want to reset your password?
            </h2>
            <div className="flex gap-4 w-full justify-center font-TWK-Lausanne-300">
              <button
                className="bg-gray-200 text-gray-700 px-6 py-2 rounded-full hover:bg-gray-300 transition"
                onClick={() => setReset(false)}
              >
                Cancel
              </button>
              <LoaderButton
                text="Yes"
                loaderText="Sending..."
                onClickCallback={handleReset}
                disabled={false}
                error=""
                btnClasses="bg-primary text-white px-6 py-2 rounded-full hover:bg-dark transition"
              />
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default ResetPassword;
