import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { exportListData, fetchPaginatedListData, fetchWatcherData, updateWatcherName } from "../apis";
import { LOADER_TYPES } from "@/utils/constants";
import Loader from "@/Components/Loader";
import { ResponseStructure } from "../models";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { BiExport } from "react-icons/bi";
import { HiLink } from "react-icons/hi2";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { renderToString } from "react-dom/server";
import { FiDownload, FiSave } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import ListConnections from "./ListConnections";
import { message, Pagination, Typography } from "antd";
import { CollapsibleIcon } from "@/Components/NavbarNew/CollapsibleIcon";
import PushFloq from "../Modals/PushFloq";
import { FaRegEdit } from "react-icons/fa";

const ListPage = () => {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [signal, setSignal] = useState<any>({});
  const [watcher, setWatcher] = useState<any>({});
  const [listData, setListData] = useState<any[]>([]);
  const [exporting, setExporting] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string>("");
  const [connectionsPage, setConnectionsPage] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20, total: 0 });
  const [pushToFloqModal, setPushToFloqModal] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const action = searchParams.get("connections");
    if (action && action === "true") setConnectionsPage(true);
    else setConnectionsPage(false);
  }, []);

  useEffect(() => {
    if (connectionsPage) window.history.pushState({}, "", `${window.location.pathname}?connections=true`);
    else window.history.pushState({}, "", `${window.location.pathname}`);
  }, [connectionsPage]);

  useEffect(() => {
    if (!id) return;
    setTableLoading(true);
    fetchPaginatedListData(id, pagination.current, pagination.pageSize)
      .then((data) => {
        setListData(data.listData);
        setPagination((prev) => ({ ...prev, total: data.count }));
      })
      .catch((err) => message.error(err))
      .finally(() => setTableLoading(false));
  }, [pagination.current, pagination.pageSize, id]);

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    fetchWatcherData(id)
      .then((data) => {
        setWatcher(data.watcher);
        setSignal(data.signal);
      })
      .catch((err) => message.error(err))
      .finally(() => setLoading(false));
  }, [id]);

  const handleExport = async () => {
    const signalId = watcher.signal_id;
    if (!signalId || !id) return;
    setExporting(true);
    setDownloadLink("");
    try {
      const fileUrl = (await exportListData(id, signalId)) as string;
      setDownloadLink(fileUrl);
    } catch (err: any) {
      message.error(err.message);
      console.error("Error in exporting table data --> ", err);
      setExporting(false);
    }
  };

  const changeWatcherNameHandler = async (newName: string): Promise<void> => {
    const previousName = watcher.name; 
    setWatcher((prevWatcher: any) => ({
      ...prevWatcher,
      name: newName,
    }));

    try {
      const responseData = await updateWatcherName(watcher.id, newName);
      message.success(responseData.message || "Watcher name updated successfully.");
    } catch (error) {
      console.error("Error updating watcher name:", error);

      // Revert to the previous name if an error occurs
      setWatcher((prevWatcher: any) => ({
        ...prevWatcher,
        name: previousName,
      }));

      message.error(
        error instanceof Error ? error.message : "An unexpected error occurred while updating the watcher name."
      );
    }
  };

  if (loading)
    return (
      <div className="w-full h-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Watcher" />
      </div>
    );

  if (connectionsPage)
    return (
      <ListConnections
        close={() => setConnectionsPage(false)}
        responseStructure={signal.responseStructure as ResponseStructure[]}
      />
    );

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-5">
      {exporting && (
        <dialog className="fixed inset-0 z-[200] bg-black bg-opacity-50 flex items-center justify-center backdrop-filter w-full h-full">
          <div className="flex flex-col min-w-[440px] min-h-[100px] bg-white px-4 py-2 rounded-md">
            <div className="flex items-center justify-between w-full mb-2 pb-2 border-b gap-2">
              <div className="flex items-center gap-2">
                <FiSave className="w-6 h-6" />
                <h3 className="text-lg font-semibold">Exporting Leads</h3>
              </div>
              <button
                type="button"
                className="p-1 rounded-full hover:bg-gray-100"
                onClick={() => {
                  setDownloadLink("");
                  setExporting(false);
                }}
              >
                <IoClose size="1.5rem" />
              </button>
            </div>
            {!downloadLink ? (
              <div className="flex flex-col items-center justify-center w-full my-2 gap-2">
                <div className="h-1.5 w-full bg-blue-100 overflow-hidden rounded-lg">
                  <div className="animate-progress w-full h-full bg-blue-500 origin-left-right"></div>
                </div>
                <span className="text-sm text-[#69717d]">Please wait while we export the the Leads</span>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center w-full my-2 gap-2">
                <span className="text-sm text-[#69717d]">Your file is ready for download</span>
                <button
                  type="button"
                  className="flex items-center justify-center px-4 py-2 font-semibold text-white bg-primary rounded-md w-full gap-2"
                  onClick={() => {
                    window.open(downloadLink, "_blank");
                    setExporting(false);
                    setDownloadLink("");
                  }}
                >
                  <FiDownload className="w-4 h-4" />
                  Download
                </button>
              </div>
            )}
          </div>
        </dialog>
      )}
      <div className="text-2xl font-semibold p-5 pb-0 bg-gradient-to-r from-[#5e35b1] via-[#d192e2] to-[#ab47bc] bg-clip-text text-black cursor-default">
        {/* CollapsibleIcon in top left */}
        <div className="flex gap-2 items-center text-2xl font-semibold bg-gradient-to-r from-[#5e35b1] via-[#d192e2] to-[#ab47bc] bg-clip-text text-black cursor-default">
          <CollapsibleIcon isHeader />
          <Link to="/lists">
            <MdOutlineKeyboardBackspace size={35} />
          </Link>
          <Typography.Title
            editable={{
              icon: <FaRegEdit className="text-[#160F38]" />,
              enterIcon: null,
              tooltip: "Click to change the name of the signal",
              onChange: (newName) => {
                if (newName.trim() !== "") {
                  changeWatcherNameHandler(newName);
                }
              },
            }}
            level={3}
            style={{
              width: "fit-content",
              margin: 0,
              marginLeft: 6,
              display: "inline-flex",
              alignItems: "center",
              gap: 6,
            }}
          >
            {watcher.name}
          </Typography.Title>
        </div>
      </div>
      <div className="flex gap-4 items-center px-5">
        <div className="min-w-fit bg-white flex items-center p-3">
          <div className="font-semibold text-gray-600">Total rows:</div>
          <div className=" ml-1 text-gray-900 text-[1.15rem]">{pagination.total}</div>
        </div>
        <button
          type="button"
          className="flex items-center gap-2 ml-auto text-md rounded-md disabled:cursor-not-allowed disabled:bg-gray-100
             px-3 py-1.5 font-medium text-dark hover:bg-primary/10 transition-all duration-200 ease-in-out w-fit justify-center"
          onClick={handleExport}
          disabled={exporting}
        >
          {exporting ? <SpinnerStatus /> : <BiExport color="rgb(30 0 104)" size={25} />}
          Export to CSV
        </button>
        {signal.type === "list" ? (
          <button
            onClick={() => setPushToFloqModal(true)}
            type="button"
            className="flex items-center gap-2 rounded-md border border-gray-200 disabled:cursor-not-allowed disabled:bg-gray-100
            px-3 py-1.5 font-medium text-dark hover:bg-primary/10 transition-all duration-200 ease-in-out w-fit justify-center"
          >
            <HiLink color="rgb(30 0 104)" size={25} />
            Push Leads to Floq
          </button>
        ) : (
          <button
            onClick={() => setConnectionsPage(true)}
            type="button"
            className="flex items-center gap-2 rounded-md border border-gray-200 disabled:cursor-not-allowed disabled:bg-gray-100
            px-3 py-1.5 font-medium text-dark hover:bg-primary/10 transition-all duration-200 ease-in-out w-fit justify-center"
          >
            <HiLink color="rgb(30 0 104)" size={25} />
            Connections
          </button>
        )}
      </div>
      <div className="flex flex-col h-full">
        <div className="ag-theme-alpine h-full w-full block">
          <AgGridReact
            className="ag-theme-alpine"
            rowData={listData}
            loading={tableLoading}
            tooltipShowDelay={1000}
            overlayLoadingTemplate={`${renderToString(<Loader loaderType={LOADER_TYPES.fetching} payload="Table" />)}`}
            columnDefs={
              signal.outputStructureType === "dataDefined"
                ? listData.length > 0
                  ? Object.keys(listData[0])
                      .map((field: string) => {
                        const skip = ["_id", "created_at", "list_id", "type"];
                        if (skip.includes(field)) return null;
                        return {
                          headerName: field,
                          field: field,
                          mainMenuItems: [],
                          headerComponent: (props: any) => {
                            return <div className="px-3">{props.displayName}</div>;
                          },
                        };
                      })
                      .filter((field) => field)
                  : []
                : signal.responseStructure.map((field: ResponseStructure) => ({
                    headerName: field.name,
                    field: field.responseStructureId,
                    tooltipValueGetter: (params: any) => JSON.stringify(params.value),
                    cellRenderer: (params: any) => {
                      if (field.type === "json")
                        return <p className="font-normal">{params.value && JSON.stringify(params.value)}</p>;
                      else if (field.type === "url")
                        return (
                          <a href={params.value} className="text-blue-500 underline" target="_blank" rel="noreferrer">
                            {params.value}
                          </a>
                        );
                      else if (field.type === "image")
                        return <img src={params.value} alt={field.name} className="w-8 h-8" />;
                      else if (field.type === "date")
                        return moment(params.value).utc().local().format("MMM DD, YYYY HH:mm:ss");
                      else return <p className="font-normal line-clamp-3">{params.value && params.value.toString()}</p>;
                    },
                    mainMenuItems: [],
                    headerComponent: (props: any) => {
                      return <div className="px-3">{props.displayName}</div>;
                    },
                  }))
            }
          />
        </div>
        <Pagination
          defaultCurrent={20}
          total={pagination.total}
          pageSize={pagination.pageSize || 20}
          current={pagination.current}
          onChange={(pageNumber: number, pageSize: number) =>
            setPagination((prev) => ({ ...prev, pageSize, current: pageNumber }))
          }
          showQuickJumper={true}
          pageSizeOptions={[20, 50, 100, 250]}
          showSizeChanger
          className="bg-white rounded-lg shadow-sm w-full px-5 py-4 ml-auto flex justify-end"
        />
      </div>
      <PushFloq
        open={pushToFloqModal}
        close={() => setPushToFloqModal(false)}
        fields={
          signal.outputStructureType === "dataDefined"
            ? listData.length > 0
              ? Object.keys(listData[0])
                  .map((field: string) => {
                    const skip = ["_id", "created_at", "list_id", "type"];
                    if (skip.includes(field)) return null;
                    return {
                      name: field,
                    };
                  })
                  .filter((field) => field)
              : []
            : signal.responseStructure
        }
      />
    </div>
  );
};

export default ListPage;
