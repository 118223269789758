import { useEffect, useState } from "react";
import TableComponent from "./Table";
import MapCSV from "./MapCSV";
import { RxCross2 } from "react-icons/rx";
import { ResponseConfiguration } from "../../../utils/interfaces";
import FinalMap from "./FinalMap";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { Dropdown, MenuProps, Space } from "antd";
import { cn } from "@/utils/cn";
import { publishDraft } from "@/utils/apis";
import { FaPlus } from "react-icons/fa";
import LoaderButton from "@/Components/LoaderButtonBlack";
import { DownArrowNew } from "./LinkedinProfileViewer";
import { useUser } from "@/contexts/UserContext";

type Props = {
  name: string;
  getRemoveFileProps: () => any;
  resArr: any[];
  template: ResponseConfiguration[];
  workflowId: string;
  refresh: () => void;
};

type Map = {
  [key: string]: string;
};

const CSVModal = ({
  // name,
  getRemoveFileProps,
  resArr,
  template,
  workflowId,
  refresh,
}: Props) => {
  const [selected, setSelected] = useState<number>(0);
  const [columns, setColumns] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any>(undefined);
  const [map, setMap] = useState<Map>({});
  const [duplicateMap, setDuplicateMap] = useState<Record<string, boolean>>({});
  const [importState, setImportState] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [importOption, setImportOption] = useState<number>(0);
  const { triggerWorkflowForInput, saveWorkflowRecord } = useWorkflow();
  const { collapsed } = useUser();

  const {
    updateResponseConfig,
    // updatePayloadConfig, TODO::what is this function for?
    saveWorkflowActions,
  } = useWorkflow();

  useEffect(() => {
    if (resArr.length > 0) {
      setColumns(
        resArr[0].map((item: any, idx: number) => {
          return {
            title: item,
            dataIndex: idx,
            ellipsis: true,
            width: 200,
          };
        })
      );
      setRows(
        resArr
          .filter((item: any[]) => item.length !== 0 && !(item.length === 1 && item[0] === ""))
          .map((item: any[], idx: number) => {
            return { key: idx + 1, ...item };
          })
      );
    }
  }, [resArr]);

  useEffect(() => {
    if (rows.length > 0) setHeaders(rows[0]);
  }, [rows]);

  useEffect(() => {
    if (headers && headers.key !== undefined) setSelectedRows(rows.filter((row) => row.key > headers.key));
  }, [headers, rows]);

  const importCSV = async (rows: any[], option: number) => {
    if (rows.length === 0 || loading) return;
    setLoading(true);
    const data = rows.map((row) => {
      const obj: any = {};
      template.find((res) => {
        Object.entries(row).forEach((item) => {
          if (res.name === item[0]) {
            obj[res.responseId || ""] = item[1];
          }
        });
      });
      return obj;
    });
    const firstArray = data.slice(0, 10); // First 10 items
    const secondArray = data.slice(10); // Remaining items

    switch (option) {
      case 0:
        console.log("saving the data.....");
        await saveWorkflowRecord(workflowId, data);
        break;
      case 1:
        console.log("Running 10, saving the rest.....");
        await triggerWorkflowForInput(workflowId, firstArray, true, -1);
        await saveWorkflowRecord(workflowId, secondArray);
        break;
      case 2:
        console.log("Running all.....");
        await triggerWorkflowForInput(workflowId, data, false, -1);
        break;
      default:
        console.log("DEFAULT: saving the data.....");
        await saveWorkflowRecord(workflowId, data);
        break;
    }

    // if (trigger) await triggerWorkflowForInput(workflowId, data, true, -1);
    // else await saveWorkflowRecord(workflowId, data);
    refresh();
    setLoading(false);
    const mouseEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    getRemoveFileProps().onClick(mouseEvent);
  };

  const next = () => {
    if (selected === 2) {
      setImportOption(0);
      setImportState(importState + 1);
    } else setSelected(selected + 1);
  };

  const addColumn = async () => {
    if (name === "") return;
    const nameArr = name.split(",");
    const temp = [...template];

    nameArr.forEach((eachName) => {
      temp.push({
        name: eachName.trim(),
        description: "",
      });
    });

    const updated = updateResponseConfig(temp, "id1");
    template.splice(0, template.length, ...updated);
    await saveWorkflowActions(workflowId || "");
    await publishDraft(workflowId || "");
    setName("");
  };

  const items: MenuProps["items"] = [
    {
      label: "Import and Run 10",
      key: "1",
    },
    {
      label: "Import and Run All",
      key: "2",
    },
  ];
  const itemClick: MenuProps["onClick"] = ({ key }) => {
    setImportOption(parseInt(key));
    setImportState(importState + 1);
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className="fixed inset-0 w-screen h-screen bg-black bg-opacity-20 flex flex-col items-center justify-center overflow-auto z-[100] pb-12"
    >
      <dialog
        className={`bg-white ${collapsed ? "w-[90%]" : "w-[70%] inset-x-32"} max-h-[90%] rounded-lg shadow-lg overflow-auto py-5 px-8 flex flex-1 flex-col justify-between gap-2 border-2 border-gray-300 relative z-[999]`}
      >
        <div className="flex items-center justify-between w-full border-b pb-5">
          <h2 className="text-xl font-bold">{["Select Header Row", "Map Columns", "Import Data"][selected]}</h2>
          <button
            className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 transition-colors duration-300 ease-in-out"
            onClick={getRemoveFileProps().onClick}
          >
            <RxCross2 />
          </button>
        </div>
        {/* <span>{name || "test file"}</span> */}
        {selected === 0 && <TableComponent columns={columns} rows={rows} setHeaders={setHeaders} />}
        {selected === 1 && (
          <MapCSV
            headers={headers}
            data={selectedRows}
            responses={template}
            map={map}
            setMap={setMap}
            setDuplicateMap={setDuplicateMap}
          />
        )}
        {selected === 2 && (
          <FinalMap
            map={map}
            data={selectedRows}
            duplicateMap={duplicateMap}
            columnIds={template}
            importCSV={importCSV}
            importState={importState}
            importOption={importOption}
          />
        )}
        {loading ? (
          <div className="flex gap-2 items-center text-lg">
            <SpinnerStatus />
            Importing...
          </div>
        ) : (
          <div
            className={cn(
              "flex flex-row-reverse items-center justify-between gap-4 border-t pt-5",
              selected === 2 && "flex-row"
            )}
          >
            {selected === 2 && <div className="flex items-center gap-4 text-muted"></div>}
            <div className="flex gap-4 items-center">
              {selected === 1 && (
                <div className="inputStyleCSS3 flex border rounded-md border-primary h-fit items-center">
                  <input
                    type="text"
                    placeholder="Add Column"
                    // className="outline-none border-none rounded-md focus:outline-none focus:border-none h-fit px-2 py-1"
                    className={cn(
                      "focus:!outline-none !text-[16px]",
                      "w-full rounded-md border-none outline-none focus:!border-none focus:!ring-0 focus:!ring-offset-0 !text-[16px] !text-black px-2 py-1"
                    )}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <LoaderButton
                    btnClasses={
                      " px-3 py-1 flex gap-x-2 items-center justify-center rounded-md bg-primary text-white w-fit hover:bg-primary/95 transition-colors duration-300 ease-in-out "
                    }
                    txtClasses="text-[1.0rem]"
                    onClickCallback={addColumn}
                    disabled={false}
                    error=""
                    loaderText={"\u00A0"}
                    text=""
                    IconComponent={FaPlus}
                    iconSize={24}
                  />
                </div>
              )}

              {selected !== 2 && (
                <button
                  className="px-3 py-1 flex items-center justify-center rounded-md bg-primary text-white w-24 hover:bg-primary/95 transition-colors duration-300 ease-in-out"
                  onClick={next}
                >
                  Next
                </button>
              )}
              {selected === 2 && (
                <Space direction="vertical">
                  <Dropdown.Button
                    icon={<DownArrowNew color="white" />}
                    type="primary"
                    // loading={loadings[1]}
                    menu={{ items, onClick: itemClick }}
                    onClick={next}
                    // className="p-3 flex items-center justify-center rounded-md bg-primary text-white w-fit hover:bg-primary/80 transition-colors duration-300 ease-in-out"
                  >
                    Import
                  </Dropdown.Button>
                </Space>
              )}
              <button
                className="px-3 py-1 flex items-center justify-center rounded-md border w-24 hover:bg-gray-200 transition-colors duration-300 ease-in-out"
                onClick={(e) => {
                  if (selected === 0) getRemoveFileProps().onClick(e);
                  else setSelected(selected - 1);
                }}
              >
                {selected === 0 ? "Cancel" : "Back"}
              </button>
            </div>
          </div>
        )}
      </dialog>
    </div>
  );
};

export default CSVModal;
