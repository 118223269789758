import React, { useState, useMemo } from "react";
import { Dropdown, Menu } from "antd";
import { IoHomeOutline } from "react-icons/io5";
import WorkflowFolderIcon from "../../../assets/SVGs/WorkflowFolderIcon.svg";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useWorkflow } from "@/contexts/WorkflowContext";
import LoaderButtonBlack from "@/Components/LoaderButtonBlack";
import SalesNavigatorComponent from "./SalesNavigator";
import { FaX } from "react-icons/fa6";
import CompaniesListComponent from "./CompaniesList";
import OperatorsListComponent from "./WelldbOperatorsList";
import CompaniesListViaLinkedin from "./CompaniesListViaLinkedin";
import PipedriveComponent from "./Pipedrive";

interface WorkflowFolder {
  id: string;
  createdAt: string;
  name: string;
  workflows: string[];
}

interface FolderDropdownProps {
  workflowFolders: WorkflowFolder[];
  selectedFolder: string;
  setSelectedFolder: React.Dispatch<React.SetStateAction<string>>;
}

interface WorkflowUIProps {
  folders: WorkflowFolder[];
  close: () => void;
  selectedFolderId: string;
  v2?: boolean;
}

const FolderDropdown = ({ workflowFolders, selectedFolder, setSelectedFolder }: FolderDropdownProps) => {
  const menu = useMemo(() => {
    return (
      <Menu className="">
        <Menu.Item key="root" onClick={() => setSelectedFolder("root")}>
          <span className="flex items-center gap-2">
            <IoHomeOutline size={16} />
            <span>Root</span>
          </span>
        </Menu.Item>
        {workflowFolders.map((folder) => (
          <Menu.Item key={folder.id} onClick={() => setSelectedFolder(folder.id)}>
            <span className="flex items-center gap-2">
              <img src={WorkflowFolderIcon} alt={folder.name} className="w-4 h-4" />
              <span>{folder.name}</span>
            </span>
          </Menu.Item>
        ))}
      </Menu>
    );
  }, [workflowFolders, setSelectedFolder]);

  return (
    <Dropdown overlay={menu} trigger={["click"]} className="w-full rounded border border-black px-4">
      <div className="flex items-center gap-2 cursor-pointer">
        {workflowFolders.find((folder) => folder.id === selectedFolder) ? (
          <img src={WorkflowFolderIcon} alt="folder" className="w-5 h-5 shrink-0" />
        ) : (
          <IoHomeOutline size={24} className="shrink-0" />
        )}
        <span className="text-black font-normal text-md truncate py-2">
          {workflowFolders.find((folder) => folder.id === selectedFolder)?.name || "Root"}
        </span>
      </div>
    </Dropdown>
  );
};

const WorkflowUI = ({ close, folders, selectedFolderId, v2 = true }: WorkflowUIProps) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [next, setNext] = useState(false);
  const [workflowName, setWorkflowName] = useState("");
  const [selectedFolder, setSelectedFolder] = useState(selectedFolderId || "root");
  const [selectedSource, setSelectedSource] = useState("csv");

  const { createNewWorkflow } = useWorkflow();

  const handleCreateWorkflow = async () => {
    if (!workflowName.trim()) {
      setError("Workflow Name cannot be empty");
      return;
    }
    const id = await createNewWorkflow(workflowName, selectedSource, selectedFolder, {}, v2);
    if (id === "") setError("Something went wrong. Please try again. If the problem persists, contact support.");
    else if (id.includes("Hubspot connection not found"))
      setError("Please connect your Hubspot account on the connections page.");
    else if (id.includes("Salesforce connection found"))
      setError("Please connect your Salesforce account on the connections page.");
    else if (id.includes("Monday connection not found"))
      setError("Please visit the connections page and insert your monday.com API key");
    else if (id.includes("Airtable connection not found"))
      setError("Please connect your Airtable account on the connections page.");
    else navigate(`/workflow/${id}?v2=${v2}`);
  };

  const inputSources: {
    name: string;
    action: string;
    logo: string;
    description: string;
    v2Only?: boolean;
    disabled?: boolean;
  }[] = [
    {
      name: "Manual/CSV",
      action: "csv",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/upload-csv-logo.svg",
      description: "Import Email Addresses, LinkedIn URLs, or Names",
    },
    {
      name: "Webhook",
      action: "webhook",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/Group%2038162%20(1).png",
      description: "Pull in data from a webhook URL",
    },
    {
      name: "Hubspot",
      action: "hubspot",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/HubSpot2.svg",
      description: "Import data from your HubSpot CRM and enrich it.",
    },
    {
      name: "Salesforce",
      action: "salesforce",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/salesforce.svg",
      description: "Import data from your SalesForce CRM and enrich.",
    },
    {
      name: "PhantomBuster",
      action: "phantombuster",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/phantombuster.svg",
      description: "Import data from PhantomBuster and enrich.",
    },
    {
      name: "EngageBay",
      action: "engagebay",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/engagebay.png",
      description: "Import data from your EngageBay CRM and enrich.",
    },
    {
      name: "Sales Navigator",
      action: "sales_navigator",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/navigator.svg",
      description: "Import people from a sales navigator URL and enrich their data.",
    },
    {
      name: "Operators List from Well Database",
      action: "operators_list",
      v2Only: true,
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/welldb.svg",
      description:
        "Import a list of operators from the Well Database filtering by number of wells, country, state, etc.",
    },
    {
      name: "Monday",
      action: "monday",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/monday.svg",
      description: "Import data from Monday.com and enrich it.",
    },
    {
      name: "Pipedrive",
      action: "pipedrive",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/pipedrive.png",
      description: "Import Organizations/Persons from Pipedrive and enrich them.",
    },
    {
      name: "Airtable",
      action: "airtable",
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/Airtable.svg",
      description: "Import data from Airtable and enrich it.",
    },
  ];

  const liestBuildingInputSources: {
    name: string;
    action: string;
    logo: string;
    description: string;
    v2Only?: boolean;
    disabled?: boolean;
  }[] = [
    {
      name: "List of Companies",
      action: "comapnies_list",
      v2Only: true,
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/floqer.svg",
      description:
        "Import a list of companies filtering by technologies they use, job openings, industries, size, revenue, etc.",
    },
    {
      name: "List of Companies via linkedin",
      action: "comapnies_crustdata_realtime_linkedin_list",
      v2Only: true,
      logo: "https://storage.googleapis.com/public_image_assets/internal-web-app-logos/floqer.svg",
      description:
        "Import a list of companies from linkedin filtering by keyword, comapny activities, department headcount, department growth, followers, etc.",
    },
  ];

  const NEXT_STEP_ACTIONS = [
    "sales_navigator",
    "comapnies_list",
    "operators_list",
    "comapnies_crustdata_realtime_linkedin_list",
    "pipedrive",
  ];

  if (next && selectedSource === "sales_navigator")
    return (
      <SalesNavigatorComponent
        stepsClose={close}
        workflowName={workflowName}
        selectedSource={selectedSource}
        selectedFolder={selectedFolder}
        close={setNext}
        v2={v2}
      />
    );

  if (next && selectedSource === "comapnies_list")
    return (
      <CompaniesListComponent
        stepsClose={close}
        workflowName={workflowName}
        selectedSource={selectedSource}
        selectedFolder={selectedFolder}
        close={setNext}
        v2={v2}
      />
    );

  if (next && selectedSource === "operators_list")
    return (
      <OperatorsListComponent
        stepsClose={close}
        workflowName={workflowName}
        selectedSource={selectedSource}
        selectedFolder={selectedFolder}
        close={setNext}
        v2={v2}
      />
    );

  if (next && selectedSource === "comapnies_crustdata_realtime_linkedin_list")
    return (
      <CompaniesListViaLinkedin
        stepsClose={close}
        workflowName={workflowName}
        selectedSource={selectedSource}
        selectedFolder={selectedFolder}
        close={setNext}
        v2={v2}
      />
    );

  if (next && selectedSource === "pipedrive")
    return (
      <PipedriveComponent
        stepsClose={close}
        workflowName={workflowName}
        selectedSource={selectedSource}
        selectedFolder={selectedFolder}
        close={setNext}
        v2={v2}
      />
    );

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex flex-row w-full items-center justify-between border-b py-2 px-5">
        <button
          onClick={() => close()}
          className="h-14 rounded-2xl self-start font-bold color-black z-10 px-6 py-2 bg-white flex items-center justify-center gap-4 hover:bg-[#eeedfe] transition cursor-pointer border-2 border-[#eeedfe]"
        >
          <MdOutlineKeyboardBackspace size={30} />
          <span>Back</span>
        </button>
        <FaX
          className="w-5 h-5 text-red-500 hover:text-red-700 cursor-pointer transition-all duration-300 ease-in-out"
          onClick={() => close()}
        />
      </div>
      <div className="p-5 flex flex-col gap-6 overflow-y-auto">
        <div className="flex gap-8 items-start">
          <div className="flex-1">
            <label className="block text-[#222] font-semibold text-lg">Name your workflow</label>
            <input
              value={workflowName}
              onChange={(e) => setWorkflowName(e.target.value)}
              placeholder="Workflow name"
              className="w-full border-r border-black rounded"
            />
          </div>

          <div className="flex-1">
            <label className="block text-[#222] font-semibold text-lg">Save to folder</label>
            <FolderDropdown
              workflowFolders={folders}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
          </div>
        </div>

        <label className="block text-[#222] font-semibold text-lg">Select input source</label>

        <div className="flex items-center">
          <span className="mx-4 text-gray-500 text-left">Data Sources & Integrations</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>

        <div className="grid grid-cols-4 gap-4">
          {inputSources.map((source) => {
            if (source.v2Only && !v2) return null;
            return (
              <button
                disabled={source.disabled}
                key={source.name}
                className={`p-4 border-2 shadow-sm flex flex-col gap-1 justify-center items-start min-h-fit ${
                  selectedSource === source.action ? "border-blue-500" : "border-[#BFBFBF]"
                } rounded-md hover:border-blue-500 hover:shadow-md cursor-pointer transition-all duration-300 ease-in-out disabled:cursor-not-allowed`}
                onClick={() => setSelectedSource(source.action)}
              >
                <div className="flex items-center gap-2">
                  <img src={source.logo} alt={source.name} className="w-8 h-8 shrink-0" />
                  <p className="text-md font-medium">{source.name}</p>
                </div>
                <p className="text-sm font-light text-gray-500 text-start">{source.description}</p>
              </button>
            );
          })}
        </div>

        <div className="flex items-center">
          <span className="mx-4 text-gray-500 text-left">List building with floqer</span>
          <div className="flex-grow border-t border-gray-300"></div>
        </div>

        <div className="grid grid-cols-4 gap-8">
          {liestBuildingInputSources.map((source) => {
            if (source.v2Only && !v2) return null;
            return (
              <button
                disabled={source.disabled}
                key={source.name}
                className={`p-4 border-2 shadow-sm flex flex-col gap-1 justify-center items-start min-h-fit ${
                  selectedSource === source.action ? "border-blue-500" : "border-[#BFBFBF]"
                } rounded-md hover:border-blue-500 hover:shadow-md cursor-pointer transition-all duration-300 ease-in-out disabled:cursor-not-allowed`}
                onClick={() => setSelectedSource(source.action)}
              >
                <div className="flex items-center gap-2">
                  <img src={source.logo} alt={source.name} className="w-8 h-8 shrink-0" />
                  <p className="text-md font-medium">{source.name}</p>
                </div>
                <p className="text-sm font-light text-gray-500 text-start">{source.description}</p>
              </button>
            );
          })}
        </div>
      </div>

      <div className="flex items-center justify-between w-full bg-white p-4 border-t border-gray-200">
        <div className="text-red-500 font-semibold">
          {error && "Error creating Workflow: "}
          {error}
        </div>
        {NEXT_STEP_ACTIONS.includes(selectedSource) ? (
          <LoaderButtonBlack
            disabled={false}
            error=""
            loaderText="Loading..."
            text="Go To Next Step"
            onClickCallback={async () => {
              if (!workflowName.trim()) {
                setError("Workflow Name cannot be empty");
                return;
              }
              setNext(true);
            }}
            btnClasses="w-fit text-white bg-black hover:bg-gray-800"
          />
        ) : (
          <LoaderButtonBlack
            disabled={false}
            error=""
            loaderText="Creating Workflow..."
            text="Create Workflow"
            onClickCallback={handleCreateWorkflow}
            btnClasses="w-fit text-white bg-black hover:bg-gray-800"
          />
        )}
      </div>
    </div>
  );
};

export default WorkflowUI;
