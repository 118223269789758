import { useEffect, useState } from "react";
import { getSignals } from "./apis";
import Signals from "./Pages/Signals";
import { message } from "antd";
import Loader from "../Loader";
import { LOADER_TYPES } from "@/utils/constants";
import { useSearchParams } from "react-router-dom";
import Create from "./Pages/Create";
import Dashboard from "./Pages/Dashboard";
import { Tabs } from "./Components/Tabs";
import { CollapsibleIcon } from "../NavbarNew/CollapsibleIcon";

const index = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState<string>("dashboard");
  const [availableSignals, setAvailableSignals] = useState<any[]>([]);
  const [userSignals, setUserSignals] = useState<any[]>([]);
  const [create, setCreate] = useState<string>("");
  useEffect(() => {
    const action = searchParams.get("action");
    const create = searchParams.get("create");

    if (create && create !== "") setCreate(create);
    if (action && action !== "") setSelected(action);
    else setSelected("dashboard");
  }, []);

  useEffect(() => {
    if (selected !== "") setSearchParams((params) => ({ ...params, action: selected }));
  }, [selected]);

  useEffect(() => {
    if (!create) setSearchParams({ action: selected });
    if (create !== "") setSearchParams({ action: selected, create });
  }, [create]);

  useEffect(() => {
    setLoading(true);
    getSignals()
      .then((data) => {
        setUserSignals(data.userWatchers);
        setAvailableSignals(data.availableWatchers);
      })
      .catch((error) => message.error(error.message))
      .finally(() => setLoading(false));
  }, []);

  const tabs = [
    {
      title: "Dashboard",
      value: "dashboard",
      content: <Dashboard availableSignals={availableSignals} userSignals={userSignals} />,
    },
    {
      title: "Lists & Signals",
      value: "signals",
      content: <Signals availableSignals={availableSignals} setCreate={setCreate} />,
    },
    // {
    //   title: "CRM",
    //   value: "crm",
    //   content: <CRM />,
    // },
  ];

  if (loading)
    return (
      <div className="w-full h-full">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Signals" />
      </div>
    );

  if (create !== "")
    return (
      <div className="w-full h-full">
        <Create signal={availableSignals.find((signal) => signal.id === create)} setCreate={setCreate} setSelected={setSelected} />
      </div>
    );
  return (
    <div className="h-full w-full flex flex-col !cursor-default">
      <div className="w-full flex flex-row gap-2 items-center relative p-5">
        <CollapsibleIcon isHeader />
        <div className="text-2xl font-bold bg-gradient-to-r from-[#5e35b1] via-[#d192e2] to-[#ab47bc] bg-clip-text text-black cursor-default">
          Get Leads
        </div>
      </div>
      {/* <Sidebar selected={selected} setSelected={setSelected} /> */}
      <Tabs setSelected={setSelected} tabs={tabs} selected={selected} />
      {/* <div className="col-span-4">{switchComponent()}</div> */}
    </div>
  );
};

export default index;
