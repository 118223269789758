import { Drawer } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Filter from "./Filter";
import TextSection from "./TextSection/index";
import { CellDataWrapperType } from "@/utils/interfaces";
import { useWorkflow } from "@/contexts/WorkflowContext";
import Contract from "@/assets/SVGs/buildTab/Contract";
import Expand from "@/assets/SVGs/buildTab/Expand";

type Props = {
  open: boolean;
  close: () => void;
  data: CellDataWrapperType;
  contentKey: string;
  title: () => React.ReactNode;
  section: number;
};

const Index = ({ open, close, data, contentKey, title, section }: Props) => {
  const { extra } = data;
  const { dataRef } = useWorkflow();
  const idx = dataRef.current.findIndex((d) => d.key === extra.dataId);
  const [curridx, setCurridx] = useState<number>(idx !== -1 ? idx : 0);
  const [expand, setExpand] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState("30%");

  useEffect(() => {
    setWidthPercent();
    window.addEventListener("resize", setWidthPercent);
    return () => window.removeEventListener("resize", setWidthPercent);
  }, []);

  const setWidthPercent = useCallback(() => {
    try {
      // Adjust width for different screen sizes
      if (window.matchMedia("(min-width: 1024px) and (min-height: 1366px)").matches) {
        // iPad Pro and similar devices
        setDrawerWidth("50%");
      } else if (window.matchMedia("(min-width: 1024px)").matches) {
        setDrawerWidth("30%");
      } else if (window.matchMedia("(min-width: 640px)").matches) {
        setDrawerWidth("50%");
      } else {
        setDrawerWidth("100%");
      }
    } catch (err) {
      console.error("Error calculating width percent based on media size");
    }
  }, []);

  useEffect(() => {
    const newId = dataRef.current.findIndex((d) => d.key === extra.dataId);
    setCurridx(newId !== -1 ? newId : 0);
  }, [open]);

  return (
    <Drawer
      title={title()}
      placement="right"
      onClose={close}
      open={open}
      // classNames={{ wrapper: "w-screen shadow-none" }}
      styles={{
        wrapper: { width: "100vw", boxShadow: "none" },
        content: {
          borderTopLeftRadius: "0.5rem",
          borderBottomLeftRadius: "0.5rem",
          width: `${expand ? `calc(100% - 28px)` : drawerWidth}`,
          transition: "width 0.3s",
          position: "relative",
          overflow: "visible",
        },
        header: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        },
        body: { padding: "1rem", overflow: "hidden" },
      }}
      className="ml-auto !font-Lausanne !p-0 "
    >
      <button
        className="font-semibold bg-white px-2 py-8 text-sm rounded-tl-lg rounded-bl-lg transition absolute left-[-28px] top-[30%] bottom-auto"
        title={expand ? "Contract to original size" : "Expand to full screen"}
        onClick={() => setExpand(!expand)}
      >
        {expand ? <Contract /> : <Expand />}
      </button>
      <section className="flex flex-col gap-2 items-start justify-center h-full">
        {/* <div className="flex gap-1 items-center">
          <Ring />
          <span className="font-semibold">21/2400</span>
          {" Reviewed"}
        </div> */}
        <section className="w-full flex flex-1 justify-between items-start overflow-hidden">
          <Filter
            // colData={data.colData}
            currIdx={curridx}
            setcurrIdx={setCurridx}
            maxIdx={dataRef.current.length - 1}
          />
          <TextSection
            data={
              dataRef.current?.[curridx]?.[extra.actionId] || {
                extra: {
                  dataId: dataRef.current?.[curridx]?.key,
                  actionId: extra.actionId,
                  workflowId: extra.workflowId,
                  reviewNeeded: true,
                },
                status: "pending",
              }
            }
            contentKey={contentKey}
            currIdx={curridx}
            setCurridx={setCurridx}
            maxIdx={dataRef.current.length - 1}
            section={section}
          />
        </section>
      </section>
    </Drawer>
  );
};

export default Index;
