import React from "react";
// import ProgressBar from "../Generics/PregressBar";
import { OrgInfo } from "@/utils/interfaces";

interface Props {
  orgInfo: OrgInfo | undefined;
  creditsLeft: number | null;
}

const PlanOverview: React.FC<Props> = ({ orgInfo, creditsLeft }) => {
  return (
    <div className="w-[50rem] py-3 border-solid border-2 border-[#AAAAAA]">
      <div className="p-3 py-0">
        <div className="flex justify-between pb-5">
          <div>
            <span className="text-xl font-semibold pr-2 border-r-2 border-[#B7B7B7]">{orgInfo?.name}</span>
            <span className="ml-2 bg-[#C7DAFF] uppercase text-sm px-2 py-1 tracking-[0.1rem] font-medium">starter</span>
          </div>
          <div>
            <span className="font-bold text-md">Credits: </span>
            <span>{creditsLeft}</span>
          </div>
        </div>
        {/* <div className="pb-6 pt-3">
          <div className="flex justify-between">
            <span className="font-semibold">Credits</span>
            <span>673/1000</span>
          </div>
          <div className="pt-1">
            <ProgressBar />
          </div>
        </div> */}
      </div>

      <div className="border-t-2 border-[#AAAAAA] p-3 py-0 pt-3 text-md">
        Have questions about your plan? 
        <span className="text-[#5750FF] cursor-pointer font-bold ml-1">Contact us</span>
      </div>
    </div>
  );
};

export default PlanOverview;
