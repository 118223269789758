import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const reviews = [
  {
    id: 1,
    name: "Vishal Makwana",
    title: "Director",
    role: ", Business Development",
    company: "",
    text: "Anyone who isn't using tech like Floqer's are going to fall behind.",
    rating: 5,
  },
  {
    id: 2,
    name: "Arjun R. Krishna",
    title: "Co-founder",
    role: "",
    company: "at WeMakeScholars",
    text: "THE premium sales automation tool around.",
    rating: 5,
  },
  {
    id: 3,
    name: "Nathan Conway",
    title: "Sales Leader",
    role: "",
    company: "",
    text: "What would take me weeks, Floqer did in 3 mins.",
    rating: 5,
  },
];

const ReviewSlider = () => {
  const [currentReview, setCurrentReview] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((prev) => (prev + 1) % reviews.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      className="w-full max-w-2xl mx-auto px-4 py-12 overflow-hidden"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
    >
      <div className="relative h-32">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentReview}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{
              type: "tween",
              duration: 0.5,
              ease: "easeInOut",
            }}
            className="absolute inset-0 text-center"
          >
            <div className="relative px-8">
              <FaQuoteLeft className="absolute left-0 top-0 text-gray-300 text-3xl opacity-50" />
              <p className="text-lg text-gray-800 mx-6 font-TWK-Lausanne-200">{reviews[currentReview].text}</p>
              <FaQuoteRight className="absolute right-0 bottom-0 text-gray-300 text-3xl opacity-50" />
            </div>

            <div className="mt-6">
              <h3 className="text-lg font-semibold text-gray-900 font-TWK-Lausanne-200">
                {reviews[currentReview].name}
              </h3>
              <p className="text-gray-600 text-sm font-TWK-Lausanne-300">
                {reviews[currentReview].title} {reviews[currentReview].role} {reviews[currentReview].company}
              </p>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      <div className="flex justify-center mt-4 space-x-2">
        {reviews.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentReview(index)}
            className={`w-2 h-2 rounded-full transition-colors duration-300 ${
              index === currentReview ? "bg-indigo-500" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default ReviewSlider;
