import CreditsUsedAreaChart from "@/Components/Charts/CreditsUsedAreaChart";
import CreditsUsedBarChart from "@/Components/Charts/CreditsUsedBarChart";
import PlanOverview from "@/Components/Payments/PlanOverview";
import WorkflowCostCard from "@/Components/Payments/WorkflowCostCard";
import { useUser } from "@/contexts/UserContext";
import { DatePicker, Segmented, Select, Spin, Tag } from "antd";

import { useEffect, useReducer, useState } from "react";
import { useWorkflow } from "@/contexts/WorkflowContext";
import {
  getActionDropdownListFromApiPricing,
  getStartAndEndDate,
  getWorkflokDropdownListFromWorkflows,
} from "@/utils/functions";
import { ApiPrice, OrgInfo, transactionStatusDropdownList } from "@/utils/interfaces";
import moment from "moment";
import Loader from "@/Components/Loader";
import { LOADER_TYPES } from "@/utils/constants";
import { getUserWorkflows } from "@/utils/apis";
import { CollapsibleIcon } from "@/Components/NavbarNew/CollapsibleIcon";

interface FilterTag {
  label: string;
  id: string;
  stateKey: string;
  removeIndex?: number;
}

interface DropdownItem {
  value: string;
  label: string;
}

interface ICredits {
  start_date: null | Date;
  start_date_error: null | string;
  end_date: null | Date;
  end_date_error: null | string;
  actions: DropdownItem[];
  actions_error: null | string;
  workflows: DropdownItem[];
  workflows_error: null | string;
  status: null | DropdownItem;
  status_error: null | string;
}

type reducerActions = "update" | "map" | "mapError";

const initState: ICredits = {
  start_date: null,
  start_date_error: null,
  end_date: null,
  end_date_error: null,
  actions: [],
  actions_error: null,
  workflows: [],
  workflows_error: null,
  status: null,
  status_error: null,
};

const creditsReducer = (state: ICredits, actions: { type: reducerActions; payload: any }) => {
  const { key, value }: { key: keyof ICredits; value: any } = actions.payload;

  switch (actions.type) {
    case "update":
      state[key] = value;
      // @ts-ignore
      state[`${key}_error` as keyof Istate] = "";
      return { ...state };
    case "map":
      return { ...state, ...value };
    case "mapError":
      const temp: any = {};
      const errorKeys = Object.keys(value);
      errorKeys.forEach((key) => {
        temp[`${key}_error`] = value[key];
      });
      return { ...state, ...temp };

    default:
      return { ...state };
  }
};

const CreditsNew = () => {
  const [selectedChat, setSelectedChat] = useState<"bars" | "area">("bars");

  const [workflows, setWorkflows] = useState<any[]>([]);
  const [fetchedWorkflowTransactions, setFetchedWorkflowTransactions] = useState<any>({});
  const [fetchedChartData, setFetchedChartData] = useState<any>({});
  const [fetchedAllAPIPricing, setFetchedAllAPIPricing] = useState<ApiPrice[]>([]);

  const [filterTags, setFilterTags] = useState<FilterTag[]>([]);
  const [barChartDataset, setBarChartDataSet] = useState<any[]>([]);
  const [areaChartDataset, setAreaChartDataSet] = useState<any>({});
  const [loadingData, setLoadingData] = useState(false);
  const [orgInfo, setOrgInfo] = useState<OrgInfo>();
  const [initLoading, setInitLoading] = useState(true);
  const [totalCreditsLeft, setTotalCreditsLeft] = useState(0);

  const { fetchCreditsForOrgId, fetchTransactionsForOrgIdWithFilters, fetchAllAPIPricing } = useWorkflow();
  const { fetchOrg } = useUser();

  const [state, dispatch] = useReducer(creditsReducer, initState);

  const initialState = () => {
    const date = new Date();
    const yearMonth = date.toISOString().slice(0, 7);
    const { startDate, endDate } = getStartAndEndDate(yearMonth);

    const initVals = {
      start_date: startDate,
      end_date: endDate,
      actions: [],
      workflows: [],
      status: null,
    };

    dispatch({
      type: "map",
      payload: {
        value: initVals,
      },
    });
  };

  const transformStateIntoTags = () => {
    const { start_date, end_date, workflows, actions, status } = state;

    const tags: FilterTag[] = [];

    if (start_date && end_date) {
      const datefilterTag = {
        label: `Date: ${moment(start_date).format("DD/MM/YY")} - ${moment(end_date).format("DD/MM/YY")}`,
        id: `date-${start_date}-${end_date}`,
        stateKey: "start_date+end_date",
      };
      tags.push(datefilterTag);
    }

    if (status) {
      const statusFilterTag = {
        label: `Status: ${status?.label}`,
        id: `${status?.value}`,
        stateKey: "status",
      };
      tags.push(statusFilterTag);
    }

    workflows?.forEach((workflow: any, index: number) => {
      const temp = {
        label: workflow.label,
        id: workflow.value,
        stateKey: "workflows",
        removeIndex: index,
      };
      tags.push(temp);
    });

    actions?.forEach((action: any, index: number) => {
      const temp = {
        label: action.label,
        id: action.value,
        stateKey: "actions",
        removeIndex: index,
      };
      tags.push(temp);
    });

    setFilterTags(tags);
  };

  const transformFetchedTransactionsIntoBarChartDataset = () => {
    const datasetObj: any = fetchedChartData;

    const dateObject = new Date();
    const month = dateObject.getMonth();
    const year = dateObject.getFullYear();
    const daysInMonth = new Date(year, month, 0).getDate();

    const areaChartDataset: any = {
      days: [],
      data: [],
    };

    for (let day = 1; day <= daysInMonth; day++) {
      areaChartDataset.days.push(day);

      if (!datasetObj[day]) {
        datasetObj[day] = {
          credits: 0,
          date: day,
        };
        areaChartDataset.data[day - 1] = 0;

        continue;
      }

      areaChartDataset.data[day - 1] = datasetObj[day]?.credits;
    }

    const dataset = Object.keys(datasetObj).map((key) => {
      return datasetObj[key];
    });

    setAreaChartDataSet(areaChartDataset);
    setBarChartDataSet(dataset);
  };

  const getWorkflowTransactionCards = () => {
    const keys = Object.keys(fetchedWorkflowTransactions);
    const cards = keys?.map((key, index) => {
      const workflow = workflows.find((w) => w.id === key);
      const workflowName = workflow?.publishedWorkflowConfig?.name;
      const filters = {
        start_date: state?.start_date,
        end_date: state?.end_date,
        actions: state?.actions?.map((action: any) => action.value),
        status: state?.status?.value,
      };
      return (
        <WorkflowCostCard
          name={workflowName ?? "Deleted Workflow"}
          credits={fetchedWorkflowTransactions[key]?.credits}
          // actionTransactionData={[]}
          workflowId={key}
          key={index}
          filters={filters}
        />
      );
    });

    return cards;
  };

  const onChangeDateFilter = (_: any, dateString: string | string[]) => {
    if (typeof dateString !== "string") {
      return;
    }
    const { startDate, endDate } = getStartAndEndDate(dateString);

    dispatch({
      type: "update",
      payload: {
        key: "start_date",
        value: startDate,
      },
    });

    dispatch({
      type: "update",
      payload: {
        key: "end_date",
        value: endDate,
      },
    });
  };

  const onChangeWorkflowFilter = (_: DropdownItem, option: any) => {
    const addedworkflowFlowFIlters = state.workflows;
    addedworkflowFlowFIlters.push(option);

    dispatch({
      type: "update",
      payload: {
        key: "workflows",
        value: addedworkflowFlowFIlters,
      },
    });
  };

  const onChangeActionsFilter = (_: DropdownItem, option: any) => {
    const addedActionFIlters = state.actions;
    addedActionFIlters.push(option);

    dispatch({
      type: "update",
      payload: {
        key: "actions",
        value: addedActionFIlters,
      },
    });
  };

  const onChangeStatus = (_: DropdownItem, option: any) => {
    dispatch({
      type: "update",
      payload: {
        key: "status",
        value: option,
      },
    });
  };

  const onFilterRemove = (tag: FilterTag) => {
    const { stateKey, removeIndex = null } = tag;
    const newState = state;

    if (removeIndex !== null) {
      newState[stateKey].splice(removeIndex, 1);
    } else {
      newState[stateKey] = null;
    }

    dispatch({
      type: "map",
      payload: {
        value: newState,
      },
    });
  };

  // set initial state
  useEffect(() => {
    initialState();
    fetchCreditsForOrgId().then((creditsInfo) => {
      setTotalCreditsLeft(creditsInfo.credits);
    });
    fetchOrg().then((orgInfo: any) => {
      setOrgInfo(orgInfo);
    });
    fetchAllAPIPricing().then((apiPricing: any) => {
      setFetchedAllAPIPricing(apiPricing?.api_pricing_pans);
    });
    getUserWorkflows(false, true).then((workflows) => {
      const v1 = workflows.workflows;
      const v2 = workflows.workflowsV2;
      setWorkflows(v2.workflows.concat(v1.workflows));
    });
    // fetchAllAPIPricing
    setLoadingData(true);
  }, []);

  // load transactions data and create filters based on state filters
  useEffect(() => {
    if (!state?.start_date || !state?.end_date) {
      return;
    }
    if (workflows.length === 0) {
      if (initLoading) setInitLoading(false);
      setLoadingData(false);
      return;
    }

    const filters = {
      start_date: state?.start_date,
      end_date: state?.end_date,
      workflows: state?.workflows?.map((workflow: any) => workflow.value),
      actions: state?.actions?.map((action: any) => action.value),
      status: state?.status?.value,
    };

    setLoadingData(true);
    fetchTransactionsForOrgIdWithFilters(filters).then((transactionsData) => {
      // setFetchedTransaction(transactionsData?.transactions);
      setFetchedWorkflowTransactions(transactionsData?.workflowStructuredTransactions);
      setFetchedChartData(transactionsData?.chartData);

      if (initLoading) setInitLoading(false);
      setLoadingData(false);
    });
    transformStateIntoTags();
  }, [workflows, state]);

  // structure fetched transactions for UI
  useEffect(() => {
    transformFetchedTransactionsIntoBarChartDataset();
  }, [fetchedChartData]);

  if (initLoading)
    return (
      <div className="h-full w-full relative">
        <Loader loaderType={LOADER_TYPES.fetching} payload="Workflow Libraries" />
      </div>
    );

  return (
    <div className="h-full w-full flex flex-col p-5 gap-5">
      <div className="w-full flex flex-row gap-2 items-center relative">
        {/* CollapsibleIcon in top left */}
        <CollapsibleIcon isHeader />
        <div className="text-2xl font-bold bg-gradient-to-r from-[#5e35b1] via-[#8e24aa] to-[#ab47bc] bg-clip-text text-black cursor-default">
          Floq history
        </div>
      </div>
      <PlanOverview orgInfo={orgInfo} creditsLeft={totalCreditsLeft} />

      <div className="max-w-[60rem]">
        {/* <div className="mb-3 max-w-[30rem]">
            <Input
              size="large"
              placeholder="Search"
              prefix={<LiaSearchSolid />}
              onChange={(e) => {
                onSearch(e.target.value);
              }}
            />
          </div> */}
        <div className="flex gap-3">
          <DatePicker onChange={onChangeDateFilter} picker="month" size="large" value={null} />
          <Select
            showSearch
            placeholder="Workflows"
            optionFilterProp="label"
            onChange={onChangeWorkflowFilter}
            options={getWorkflokDropdownListFromWorkflows(workflows)}
            size="large"
            style={{ width: "12rem" }}
            value={null}
          />
          <Select
            showSearch
            placeholder="Actions"
            optionFilterProp="label"
            onChange={onChangeActionsFilter}
            options={getActionDropdownListFromApiPricing(fetchedAllAPIPricing)}
            // options={[]}
            style={{ width: "12rem" }}
            size="large"
            value={null}
          />
          <Select
            style={{
              width: 160,
            }}
            // onChange={handleChange}
            placeholder="Status"
            options={transactionStatusDropdownList}
            size="large"
            value={null}
            onChange={onChangeStatus}
          />
        </div>
      </div>

      <div className="mt-3">
        {filterTags?.map((tag, index) => {
          return (
            <Tag
              key={`${index}-${tag.id}`}
              id={tag.id}
              color="purple"
              closable={tag?.id?.includes("date") ? false : true}
              onClose={() => onFilterRemove(tag)}
            >
              {tag.label}
            </Tag>
          );
        })}
        <span
          className={`text-[0.75rem] border-b-2 ${
            filterTags?.length > 1
              ? "cursor-pointer border-[#5750FF] text-[#5750FF]"
              : "cursor-not-allowed border-gray-300 text-gray-300"
          }`}
          onClick={() => {
            if (filterTags?.length <= 1) {
              return;
            }
            initialState();
          }}
        >
          Clear
        </span>
      </div>

      <div className="w-[75rem] relative">
        {loadingData && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-300/50 w-full h-full z-10 flex items-center justify-center">
            <Spin />
            <div className="ml-3">Loading...</div>
          </div>
        )}
        <Segmented
          options={["Bars", "Area"]}
          onChange={(value) => {
            setSelectedChat(value.toLocaleLowerCase() as "bars" | "area");
          }}
        />
        {selectedChat === "bars" ? (
          <CreditsUsedBarChart dataset={barChartDataset} />
        ) : (
          // @ts-ignore
          <CreditsUsedAreaChart dataset={areaChartDataset} barDataSet={barChartDataset} />
        )}
      </div>

      <div className="w-[75rem]">{getWorkflowTransactionCards()}</div>
    </div>
  );
};

export default CreditsNew;
