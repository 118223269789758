import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { setStateType } from "@/utils/constants";

type Tab = {
  title: string;
  value: string;
  content: string | React.ReactNode | any;
};

export const Tabs = ({
  setSelected,
  tabs: propTabs,
  selected,
}: {
  setSelected: setStateType<string>;
  tabs: Tab[];
  selected: string;
}) => {
  const [active, setActive] = useState<Tab>(propTabs[0]);
  const [tabs, setTabs] = useState<Tab[]>(propTabs);

  const moveSelectedTabToTop = (idx: number) => {
    const newTabs = [...propTabs];
    const selectedTab = newTabs.splice(idx, 1);
    newTabs.unshift(selectedTab[0]);
    setTabs(newTabs);
    setSelected(newTabs[0].value);
    setActive(newTabs[0]);
  };

  useEffect(() => {
    const selectedIndex = tabs.findIndex((tab) => tab.value === selected);
    if (selectedIndex !== -1 && tabs[selectedIndex] !== active) moveSelectedTabToTop(selectedIndex);
  }, [selected, tabs]);

  return (
    <>
      <div className="flex flex-row items-center m-5 mt-0 justify-start [perspective:1000px] relative overflow-auto sm:overflow-visible no-visible-scrollbar border-b border-gray-300">
        {propTabs.map((tab, idx) => (
          <button
            key={tab.title}
            onClick={() => {
              moveSelectedTabToTop(idx);
            }}
            className="relative px-4 py-2 rounded-md"
            style={{
              transformStyle: "preserve-3d",
            }}
          >
            {active.value === tab.value && (
              <motion.div
                layoutId="clickedbutton"
                // transition={{ type: "spring", bounce: 0.3, duration: 0.6 }}
                className="absolute inset-0 bg-primary/10 rounded-md "
              />
            )}

            <span className="relative block text-black text-sm font-normal">{tab.title}</span>
          </button>
        ))}
      </div>
      <FadeInDiv tabs={tabs} active={active} key={active.value} />
    </>
  );
};

export const FadeInDiv = ({
  tabs,
}: {
  className?: string;
  key?: string;
  tabs: Tab[];
  active: Tab;
  hovering?: boolean;
}) => {
  return (
    <div className="relative w-full h-full">
      {tabs.map((tab, idx) => (
        <motion.div
          key={tab.value}
          layoutId={tab.value}
          style={{
            zIndex: -idx,
            opacity: idx < 3 ? 1 - idx * 0.1 : 0,
          }}
          className="w-full h-full absolute top-0 left-0"
        >
          {tab.content}
        </motion.div>
      ))}
    </div>
  );
};
