import { useState, useEffect } from "react";
import { Modal, Tooltip } from "antd";
import { CellDataWrapperType } from "@/utils/interfaces";
import { useWorkflow } from "@/contexts/WorkflowContext";
import expandIcon from "../../../../assets/SVGs/TableTab/ReviewModal/expand.svg";
import reviewIcon from "../../../../assets/SVGs/TableTab/ReviewModal/review.svg";
import regenerateIcon from "../../../../assets/SVGs/TableTab/ReviewModal/regenerate.svg";
import { CELL_STATUS_DISPLAY, CELL_STATUS_TYPE, CELL_STATUS_SYMBOL } from "@/utils/constants";
import ReviewDrawer from "../ReviewDrawer/index";
import React from "react";
import { useParams } from "react-router-dom";
import Formatter from "./TextSection/Formatter";

type Props = {
  open: boolean;
  close: () => void;
  data: CellDataWrapperType;
  section: number;
  title: () => React.ReactNode;
  style: any;
  currentSectionRef: any;
};

/**
 * @todo when popup is open, and we do regenerate, the content should be updated in the textarea itself
 */
const Index = ({ open, close, data, section, title, style, currentSectionRef }: Props) => {
  let { extra, status, ...payload } = data;
  const contentKey = Object.keys(payload)[0] || "";
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [regenerating, setRegenerating] = useState<boolean>(false);
  const [content, setContent] = React.useState<string>(contentKey ? payload[contentKey].value || "" : "");
  const { markActionAsReviewedAndMoveOn, updateGeneratedEmail, retryAction, dataRef } = useWorkflow();
  const { id } = useParams();
  // const payload = data[contentKey]

  const saveContent = () => {
    if (
      extra.reviewNeeded &&
      status === CELL_STATUS_TYPE.READY_TO_BE_REVIEWED &&
      content !== (payload[contentKey]?.value || "") &&
      !saving &&
      !regenerating
    ) {
      setSaving(true);
      updateGeneratedEmail(extra.workflowId, extra.dataId, extra.actionId, contentKey, content, section).finally(() =>
        setSaving(false)
      );
    }
  };

  useEffect(() => {
    if (!open) {
      saveContent();
    }
  }, [open]);

  useEffect(() => {
    const newData = dataRef.current.find((datas) => datas.key === extra?.dataId)?.[extra?.actionId];
    if (!newData) return;
    extra = newData.extra;
    status = newData.status;
    payload = newData[contentKey];
    setContent(payload?.value || "");
  }, [dataRef.current.find((datas) => datas.key === extra?.dataId), data]);

  const handleExpand = () => {
    setIsDrawerOpen(true);
    close();
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={close}
        footer={null}
        width="30%"
        mask={false}
        closable={false}
        className="!font-Lausanne"
        styles={{
          body: {
            height: "200px",
            padding: 0,
            margin: -18,
            marginBottom: -30,
            marginLeft: -23,
            marginRight: -23,
            borderRadius: 0,
          },
        }}
        style={style}
      >
        <section className="grid grid-cols-10 h-[200px]">
          <div className="col-span-9">
            {payload && [CELL_STATUS_TYPE.READY_TO_BE_REVIEWED, CELL_STATUS_TYPE.COMPLETED].includes(status) ? (
              <Formatter
                content={content}
                setContent={setContent}
                disabled={status === CELL_STATUS_TYPE.COMPLETED || saving || regenerating}
              />
            ) : (
              <span className="flex flex-wrap gap-2 items-center justify-center text-3xl text-gray-400 font-semibold">
                <span>{CELL_STATUS_SYMBOL[status || "pending"]}</span>
                <span>{CELL_STATUS_DISPLAY[status || "pending"]}</span>
              </span>
            )}
          </div>
          <div className="col-span-1 grid grid-rows-4 items-center gap-2">
            <div className="flex flex-col items-center">
              <Tooltip
                title="Expand"
                placement="right"
                arrow={false}
                overlayClassName="border border-gray-200 rounded-md"
                overlayInnerStyle={{
                  fontWeight: 600,
                  backgroundColor: "#f3f4f6",
                  color: "#6c727f",
                  fontSize: "0.75rem",
                }}
              >
                <button onClick={handleExpand}>
                  <img src={expandIcon} alt="Expand" />
                </button>
              </Tooltip>
            </div>
            <div className="flex flex-col items-center">
              <Tooltip
                title={regenerating ? "Regenerating..." : "Regenerate"}
                placement="right"
                arrow={false}
                overlayClassName="border border-gray-200 rounded-md"
                overlayInnerStyle={{
                  fontWeight: 600,
                  backgroundColor: `${regenerating ? "#6c727f" : "#f3f4f6"}`,
                  color: `${regenerating ? "#f3f4f6" : "#6c727f"}`,
                  fontSize: "0.75rem",
                }}
              >
                <button
                  disabled={regenerating}
                  onClick={(e) => {
                    e.preventDefault();
                    setRegenerating(true);
                    retryAction(extra?.actionId, id || "", [extra?.dataId], [extra?.dataId], section, "single")
                      .then((generatedEmail) => {
                        setContent(generatedEmail);
                      })
                      .catch((err) => console.error(err))
                      .finally(() => {
                        setRegenerating(false);
                      });
                  }}
                >
                  <img src={regenerateIcon} alt="Regenerate" />
                </button>
              </Tooltip>
            </div>
            <div className="flex flex-col items-center">
              <Tooltip
                title={fetching ? "Reviewed" : "Review"}
                placement="right"
                arrow={false}
                overlayClassName="border border-gray-200 rounded-md"
                overlayInnerStyle={{
                  fontWeight: 600,
                  animationDelay: "1s",
                  backgroundColor: `${fetching ? "#6c727f" : "#f3f4f6"}`,
                  color: `${fetching ? "#f3f4f6" : "#6c727f"}`,
                  fontSize: "0.75rem",
                }}
              >
                <button
                  disabled={fetching || regenerating}
                  onClick={async () => {
                    if (status !== CELL_STATUS_TYPE.COMPLETED) {
                      setFetching(true);
                      close();
                      await markActionAsReviewedAndMoveOn(
                        extra.actionId,
                        extra.workflowId,
                        [extra.dataId],
                        [extra.dataId],
                        section,
                        "singleCell"
                      );
                      setFetching(false);
                    }
                  }}
                >
                  <img src={reviewIcon} alt="Review" />
                </button>
              </Tooltip>
            </div>

            <div
              className={`row-start-4 flex flex-col items-center overflow-hidden font-semibold px-2 py-2
                ${status === CELL_STATUS_TYPE.COMPLETED ? "text-green-500" : "text-black"}
              `}
            >
              <span className="text-xs">
                {status === CELL_STATUS_TYPE.COMPLETED
                  ? "Done"
                  : status === CELL_STATUS_TYPE.PAYLOAD_FILLED
                    ? ""
                    : CELL_STATUS_DISPLAY[status || "pending"]}
              </span>
            </div>
          </div>
        </section>
      </Modal>
      <ReviewDrawer
        close={() => setIsDrawerOpen(false)}
        data={data}
        contentKey={contentKey}
        open={isDrawerOpen}
        title={title}
        section={currentSectionRef.current}
      />
    </>
  );
};

export default Index;
