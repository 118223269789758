import { ConfigProvider, Divider, Drawer, message, Select, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserWorkflows } from "@/utils/apis";
import moment from "moment";
import { pushListToFloq } from "../apis";

type Props = {
  open: boolean;
  close: () => void;
  fields: any[];
};

const PushFloq = ({ open, close, fields }: Props) => {
  const [loading, setLoading] = useState(false);
  const [workflows, setWorkflows] = useState<any[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>(null);
  const { id } = useParams();
  const [inputs, setInputs] = useState<any[]>([]);
  const [mapping, setMapping] = useState<any>({});

  useEffect(() => {
    const getConnectionsAndWorkflows = async () => {
      setLoading(true);
      try {
        const flows = await getUserWorkflows(true);
        const firebase = flows.workflows;
        const supabase = flows.workflowsV2;
        const allWorkflows: any[] = (firebase?.workflows || [])
          .concat(supabase?.workflows || [])
          .map((workflow: any) => {
            if ((firebase?.workflows || []).find((w: any) => w.id === workflow.id)) workflow.supabase = false;
            else workflow.supabase = true;
            return workflow;
          });
        allWorkflows.sort((a: any, b: any) => {
          const aDate = moment.utc(a.createdAt);
          const bDate = moment.utc(b.createdAt);

          if (aDate.isAfter(bDate)) return -1;
          if (aDate.isBefore(bDate)) return 1;
          return 0;
        });
        setWorkflows(allWorkflows);
      } catch (error) {
        message.error("Failed to fetch connections");
      } finally {
        setLoading(false);
      }
    };
    getConnectionsAndWorkflows();
  }, [open]);

  const pushToFloq = async () => {
    if (!id || !selectedWorkflow) return;
    setLoading(true);
    pushListToFloq(id, selectedWorkflow.id, mapping)
      .then(() => {
        message.success("Pushed to Floq successfully");
        close();
      })
      .catch(() => message.error("Failed to push to Floq"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const actions = selectedWorkflow?.actions || [];
    const inputs = actions.find((action: any) => action.id === "id1")?.responseConfiguration || [];
    setInputs(inputs);
  }, [selectedWorkflow]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={open}
        onClose={close}
        width="30%"
        className="!font-Lausanne !p-0 rounded-lg"
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            <button
              className={`text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                loading || !selectedWorkflow ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
              }`}
              onClick={pushToFloq}
              disabled={loading || !selectedWorkflow}
            >
              {loading ? "Pushing..." : "Push"}
            </button>
          </div>
        }
        title={<p className="text-xl font-semibold">Push to a Floq</p>}
      >
        <div className="bg-white overflow-x-hidden">
          <div className="flex flex-col w-full gap-3 border-b">
            <p className="font-semibold text-lg">Choose a workflow to connect</p>
            <Skeleton loading={loading} active>
              <Select
                showSearch
                className="w-full rounded-md"
                placeholder="Select a workflow"
                optionFilterProp="label"
                value={selectedWorkflow?.id || ""}
                options={workflows.map((workflow) => ({
                  label: workflow.name || workflow.publishedWorkflowConfig.name,
                  value: workflow.id,
                }))}
                onChange={(value) => {
                  const workflow = workflows.find((workflow) => workflow.id === value);
                  setSelectedWorkflow(workflow);
                }}
              />
            </Skeleton>
          </div>
          <Divider className="border-gray-300" />
          <div className="flex flex-col w-full gap-3">
            <div className="flex flex-col w-full gap-1">
              <p className="font-medium text-lg">Map your data</p>
              <p className="text-sm font-light">
                The input fields from the workflow chosen above will appear here. Match your list's inputs to the fields
                you want to send to the selected workflow.
              </p>
            </div>
            <Table
              dataSource={inputs.map((input) => {
                return {
                  key: input.responseId,
                  name: input.name,
                  field: (
                    <Select
                      className="w-full rounded-md"
                      showSearch
                      placeholder="Select a field"
                      optionFilterProp="label"
                      defaultValue={mapping[input.responseId]}
                      options={fields.map((structure) => ({
                        label: structure.name,
                        value: structure.responseStructureId || structure.name,
                      }))}
                      onChange={(value) => {
                        setMapping((prev: any) => ({ ...prev, [input.responseId]: value }));
                      }}
                    />
                  ),
                };
              })}
              columns={[
                {
                  title: "Input Name",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "List Field",
                  dataIndex: "field",
                  key: "field",
                },
              ]}
              pagination={false}
            />
          </div>
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default PushFloq;
