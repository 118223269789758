import { useState } from "react";
import FloqerLogo from "@Assets/SVGs/floqerLogo.svg";
import WorkflowsIcon from "@Assets/SVGs/workflows-icon.svg";
import ConnectionsIcon from "@Assets/SVGs/connections.svg";
import LeadsIcon from "@Assets/SVGs/leads.svg";
import CreditsIcon from "@Assets/SVGs/credits.svg";
import SettingsIcon from "@Assets/SVGs/Settings.svg";
import LogoutIcon from "@Assets/SVGs/Logout.svg";
import { motion } from "framer-motion";
import { cn } from "@/utils/cn";
import { Sidebar, SidebarBody, SidebarLink } from "./Sidebar";
import { Link, Outlet } from "react-router-dom";
import { useUser } from "@/contexts/UserContext";
import { CollapsibleIcon } from "./CollapsibleIcon";

export function MainSidebar() {
  const [open, setOpen] = useState(false);
  const { collapsed } = useUser();
  const topLinks = [
    {
      label: "Get Leads",
      href: "/lists",
      selected: "/list",
      icon: <img src={LeadsIcon} className="h-5 w-5 flex-shrink-0" />,
    },
    {
      label: "Your Workflows",
      href: "/workflows",
      selected: "/workflow",
      icon: <img src={WorkflowsIcon} className="h-5 w-5 flex-shrink-0" />,
    },
    {
      label: "Connections",
      href: "/connections",
      selected: "/connections",
      icon: <img src={ConnectionsIcon} className="h-5 w-5 flex-shrink-0" />,
    },
  ];

  const bottomLinks = [
    {
      label: "Credits",
      href: "/credits",
      selected: "/credits",
      icon: <img src={CreditsIcon} className="h-5 w-5 flex-shrink-0" />,
    },
    {
      label: "Settings",
      href: "/settings",
      selected: "/settings",
      icon: <img src={SettingsIcon} className="h-5 w-5 flex-shrink-0" />,
    },
    // {
    //   label: "Logout",
    //   href: "#",
    //   selected: "/logout",
    //   icon: <img src={LogoutIcon} className="h-5 w-5 flex-shrink-0" />,
    // },
  ];

  return (
    <div
      className={cn(
        "flex flex-col md:flex-row bg-white w-full border border-black overflow-hidden",
        "h-screen" // for your use case, use `h-screen` instead of `h-[60vh]`
      )}
    >
      <Sidebar open={open} setOpen={setOpen} animate={collapsed}>
        <SidebarBody className="justify-between gap-10">
          <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <div className="flex items-center justify-between gap-2 group/sidebar py-3 border-b border-gray-200">
              <FloqLogo isOpen={open} animate={collapsed} />
              <CollapsibleIcon />
            </div>
            <div className="flex flex-col gap-1.5 mt-3">
              {topLinks.map((link, idx) => (
                <SidebarLink key={idx} link={link} />
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-1.5 mb-3">
            {bottomLinks.map((link, idx) => (
              <SidebarLink key={idx} link={link} />
            ))}
          </div>
          <div className="flex items-center gap-2 group/sidebar py-3 border-t border-gray-200">
            <Logout isOpen={open} animate={collapsed} />
          </div>
        </SidebarBody>
      </Sidebar>
      <div className="w-full bg-[#ffffff] overflow-auto">
        <Outlet />
      </div>
    </div>
  );
}

export const FloqLogo = ({ isOpen, animate }: any) => {
  return (
    <Link to="#" className="flex items-center justify-start gap-2 group/sidebar py-2">
      <img src={FloqerLogo} className="h-7 w-7" />

      <motion.span
        animate={{
          display: animate ? (isOpen ? "inline-block" : "none") : "inline-block",
          opacity: animate ? (isOpen ? 1 : 0) : 1,
        }}
        transition={{
          duration: 0.15,
          ease: "easeInOut",
        }}
        className="text-neutral-700 font-bold text-lg whitespace-pre inline-block !p-0 !m-0"
      >
        Floqer
      </motion.span>
    </Link>
  );
};

export const Logout = ({ isOpen, animate }: any) => {
  const { logout } = useUser();
  return (
    <motion.button
      animate={{
        display: animate ? (isOpen ? "flex" : "none") : "flex",
        opacity: animate ? (isOpen ? 1 : 0) : 1,
      }}
      transition={{
        duration: 0.15,
        ease: "easeInOut",
      }}
      onClick={logout}
      className="flex items-center justify-start gap-2 group/sidebar p-2 hover:bg-red-200 w-full rounded-md"
    >
      <img src={LogoutIcon} className="h-5 w-5" />
      <span className="text-[#C50000] text-sm font-medium whitespace-pre inline-block !p-0 !m-0">Sign Out</span>
    </motion.button>
  );
};

// Dummy dashboard component with content
// const Dashboard = () => {
//   return (
//     <div className="flex flex-1">
//       <div className="p-2 md:p-10 rounded-tl-2xl border border-neutral-200 bg-white flex flex-col gap-2 flex-1 w-full h-full">
//         <div className="flex gap-2">
//           {[...new Array(4)].map((_, i) => (
//             <div key={"first-array" + i} className="h-20 w-full rounded-lg  bg-gray-100 animate-pulse"></div>
//           ))}
//         </div>
//         <div className="flex gap-2 flex-1">
//           {[...new Array(2)].map((_, i) => (
//             <div key={"second-array" + i} className="h-full w-full rounded-lg  bg-gray-100 animate-pulse"></div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };
