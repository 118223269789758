import { useState, useMemo } from "react";
import { CloseOutlined, PlusOutlined, DownOutlined, DeleteOutlined } from "@ant-design/icons";
import { Select } from "@radix-ui/themes";
import { TABLE_COLUMN_STATUS_FILTERS } from "@/utils/constants";
import * as Popover from "@radix-ui/react-popover";
import * as Checkbox from "@radix-ui/react-checkbox";
import { ImCheckboxChecked } from "react-icons/im";

interface Props {
  filters: any;
  actions: any;
  onChange: (filters: any) => void;
  triggerButton: React.ReactNode;
}

const Filters: React.FC<Props> = ({ filters, actions, onChange, triggerButton }) => {
  const [appliedFilters, setAppliedFilters] = useState<any>(filters || {});
  const [tempFilters, setTempFilters] = useState<any>(filters || {});
  const [appliedFilterKeys, setAppliedFilterKeys] = useState<string[]>(Object.keys(filters || {}));
  const [remainingActions, setRemainingActions] = useState<any>([]);
  const [reachedLimit, setReachedLimit] = useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const updateFilterStates = (currentFilters: any, currentActions: any[]) => {
    const currentFilterKeys = Object.keys(currentFilters || {});
    setAppliedFilters(currentFilters || {});
    setTempFilters(currentFilters || {});
    setAppliedFilterKeys(currentFilterKeys);

    const remaining = currentActions?.filter((action: any) => !currentFilterKeys.includes(action?.id));
    setRemainingActions(remaining);

    setReachedLimit(currentFilterKeys.length >= (currentActions?.length || 0));
  };

  const handleAddingFilter = () => {
    if (!remainingActions?.length) return;

    const newFilter = remainingActions.find((action: any) => action.type !== "input") || remainingActions[0];
    const newAppliedFilterKeys = [...appliedFilterKeys, newFilter.id];
    const newRemainingActions = remainingActions.filter(
      (action: { id: string; type: string }) => action.id !== newFilter.id
    );

    setAppliedFilterKeys(newAppliedFilterKeys);
    setRemainingActions(newRemainingActions);
    setReachedLimit(newAppliedFilterKeys.length >= actions.length);

    setTempFilters((prev: any) => ({ ...prev, [newFilter.id]: [] }));
  };

  const handleRemoveFilter = (filterKey: string) => {
    const newTempFilters = { ...tempFilters };
    delete newTempFilters[filterKey];
    const newAppliedFilterKeys = appliedFilterKeys.filter((key) => key !== filterKey);
    const removedFilter = actions.find((action: any) => action.id === filterKey);
    const newRemainingActions = [...remainingActions, removedFilter].sort((a, b) => a.id.localeCompare(b.id));

    // Only update appliedFilters if they match tempFilters (meaning filters have been applied)
    const filtersHaveBeenApplied = JSON.stringify(appliedFilters) === JSON.stringify(tempFilters);

    setTempFilters(newTempFilters);
    setAppliedFilterKeys(newAppliedFilterKeys);
    setRemainingActions(newRemainingActions);
    setReachedLimit(newAppliedFilterKeys.length >= actions.length);

    if (filtersHaveBeenApplied) {
      setAppliedFilters(newTempFilters);
      onChange(newTempFilters);
    }
  };

  const handleFilterOptionChange = (option: { filterId: string; filterVal: string }, checked: boolean | string) => {
    const newTempFilters = { ...tempFilters };

    if (!newTempFilters[option.filterId]) newTempFilters[option.filterId] = [];

    if (checked) newTempFilters[option.filterId] = [...newTempFilters[option.filterId], option.filterVal];
    else
      newTempFilters[option.filterId] = newTempFilters[option.filterId].filter(
        (filterOption: string) => filterOption !== option.filterVal
      );

    // Remove the filter if no options are selected
    if (newTempFilters[option.filterId].length === 0) delete newTempFilters[option.filterId];

    setTempFilters(newTempFilters);
  };

  const handleApplyFilters = () => {
    setAppliedFilters(tempFilters);
    onChange(tempFilters);
  };

  const hasFilterChanges = () => {
    return JSON.stringify(tempFilters) !== JSON.stringify(appliedFilters);
  };

  useMemo(() => {
    if (isPopoverOpen) {
      return;
    }
    updateFilterStates(
      filters,
      [...actions].filter((action) => action.type !== "input" || action.type === "add_section")
    );
  }, [filters, actions]);

  useMemo(() => {
    if (appliedFilterKeys.length === 0) {
      // Add a default filter when opening the popover if no filters exist
      handleAddingFilter();
    }
  }, [appliedFilterKeys]);

  const appliedFiltersUI = (appliedFilterKeys: string[]) => {
    return (
      <div className="flex flex-col justify-start items-start gap-2">
        {appliedFilterKeys.map((key, index) => {
          const filter = actions.find((action: any) => action.id === key);
          if (!filter) return null;

          const appliedOptionsCount = tempFilters[key]?.length || 0;

          return (
            <div className="flex gap-2 justify-center items-center" key={key}>
              <div>
                {index === 0 ? (
                  <div className="text-gray-500 text-sm w-10">Where</div>
                ) : (
                  <div className="text-sm w-10">And</div>
                )}
              </div>
              <Select.Root
                defaultValue={filter?.id}
                key={filter?.id}
                onValueChange={(val) => {
                  const newKey = val;
                  const prevKey = filter?.id;

                  const temp = { ...tempFilters };
                  temp[newKey] = temp[prevKey];
                  delete temp[prevKey];

                  const newKeys = [...appliedFilterKeys]?.filter((key) => key !== prevKey);
                  newKeys.push(newKey);

                  const newRemainingActions = [...remainingActions]?.filter((action: any) => {
                    return !newKeys?.includes(action?.id);
                  });
                  newRemainingActions.push(filter);

                  setTempFilters(temp);
                  setAppliedFilterKeys(newKeys);
                  setRemainingActions(newRemainingActions);
                }}
              >
                <Select.Trigger className="px-3 py-1 gap-2 w-52 border border-gray-300 rounded" />
                <Select.Content>
                  {actions
                    ?.filter((action: any) => {
                      // Exclude input type actions and include only current filter or unused filters
                      return (
                        action.type !== "input" && (action.id === filter.id || !appliedFilterKeys.includes(action.id))
                      );
                    })
                    .map((action: any) => {
                      return (
                        <Select.Item value={action?.id} key={action?.id}>
                          {action?.displayName || action?.name}
                        </Select.Item>
                      );
                    })}
                </Select.Content>
              </Select.Root>
              {/* <div>
                    <div className="text-sm">Is</div>
                  </div> */}
              <Popover.Root>
                <Popover.Trigger asChild>
                  <button className="flex items-center justify-between w-44 px-3 py-1 text-sm bg-white border border-gray-300 rounded hover:bg-gray-50">
                    <span>
                      {appliedOptionsCount} filter
                      {appliedOptionsCount !== 1 ? "s" : ""} applied
                    </span>
                    <DownOutlined className="text-gray-400" />
                  </button>
                </Popover.Trigger>
                <Popover.Content className="bg-white py-1 px-1 rounded shadow-sm w-48">
                  {TABLE_COLUMN_STATUS_FILTERS?.map((status) => {
                    const checked = tempFilters[filter?.id]?.includes(status?.value);
                    const checkboxID = `${filter?.id}_${status?.value}`;
                    return (
                      <label
                        key={checkboxID}
                        htmlFor={checkboxID}
                        className="flex items-center gap-2 py-1 px-2 cursor-pointer hover:bg-gray-100"
                      >
                        <Checkbox.Root
                          className="w-4 h-4 border border-gray-300 rounded"
                          checked={checked}
                          id={checkboxID}
                          onCheckedChange={(val) => {
                            handleFilterOptionChange(
                              {
                                filterId: filter?.id,
                                filterVal: status?.value,
                              },
                              val
                            );
                          }}
                        >
                          <Checkbox.Indicator>
                            {checked && <ImCheckboxChecked className="text-blue-500" />}
                          </Checkbox.Indicator>
                        </Checkbox.Root>
                        <span className="text-sm">{status?.text}</span>
                      </label>
                    );
                  })}
                </Popover.Content>
              </Popover.Root>
              <button
                onClick={() => handleRemoveFilter(filter.id)}
                className="p-2 rounded hover:bg-gray-100 text-red-600"
                aria-label="Remove filter"
              >
                <DeleteOutlined />
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Popover.Root
        onOpenChange={(state) => {
          setIsPopoverOpen(state);
          if (!state) setTempFilters(appliedFilters);
        }}
      >
        <Popover.Trigger>{triggerButton}</Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className="PopoverContent">
            <div className="bg-white shadow-lg py-5 px-5">
              <div>
                {appliedFiltersUI(appliedFilterKeys)}
                <div className="flex gap-2 text-sm mt-5">
                  {!reachedLimit && (
                    <button
                      onClick={handleAddingFilter}
                      className="flex items-center gap-1 px-2 py-1 text-sm bg-transparent rounded transition duration-300 hover:bg-gray-100"
                    >
                      <PlusOutlined />
                      Add filter
                    </button>
                  )}
                  {appliedFilterKeys.length > 0 && (
                    <button
                      onClick={() => {
                        setTempFilters({});
                        setAppliedFilters({});
                        onChange({});
                      }}
                      className="flex items-center gap-1 px-2 py-1 text-sm rounded transition duration-300 text-red-600 hover:bg-gray-100 cursor-pointer"
                    >
                      <CloseOutlined />
                      Clear filters
                    </button>
                  )}
                  {Object.keys(tempFilters).length > 0 &&
                    hasFilterChanges() &&
                    Object.values(tempFilters).some((arr) => (arr as any[]).length > 0) && (
                      <button
                        onClick={handleApplyFilters}
                        className="border border-gray-350 bg-white text-black py-2 px-4 text-sm rounded-md disabled:bg-gray-300 hover:bg-gray-100"
                      >
                        Apply Filters
                      </button>
                    )}
                </div>
              </div>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default Filters;
