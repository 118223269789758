import { Handle, NodeProps, Position, useHandleConnections } from "@xyflow/react";
import DefaultAction from "@/assets/SVGs/DefaultAction";
import { CELL_STATUS_SYMBOL } from "@/utils/constants";

export function ActionNode({ data }: NodeProps<any>) {
  const connections = useHandleConnections({
    type: "target",
  });

  return (
    <div className="nodrag w-[76vw] max-w-[80vw] h-[10vh] flex items-center justify-center">
      {/* Handle for incoming connections */}
      {data.type !== "input" && <Handle type="target" position={Position.Top} isConnectable={connections.length < 1} />}

      {/* Node Content */}
      <div className="relative group p-2">
        <div className="flex flex-col bg-white transition-opacity duration-300 w-full py-3 px-4 border border-grey-400 rounded-2xl cursor-pointer relative z-10 hover:border-primary hover:bg-gray-50">
          {/* Top Section */}
          <div className="flex items-center justify-between gap-4">
            {/* Icon Section */}
            <div className="h-8 w-8 flex justify-center items-center rounded-full flex-shrink-0">
              {data.logo !== "img:link" ? (
                <img src={data.logo as string} alt="icon" className="h-full w-full object-cover" />
              ) : (
                <DefaultAction />
              )}
            </div>
            {/* Name Section */}
            <div className="flex-1 overflow-hidden">
              <label className="text-grey-900 text-lg font-semibold select-none pointer-events-none line-clamp-2">
                {data.name as string}
              </label>
            </div>
          </div>

          {/* Action Counts Section */}
          <div className="flex flex-wrap gap-2 mt-3 text-gray-600 font-light text-lg">
            {/* Individual Status Items */}
            <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
              {
                // (data?.actionCounts?.payloadFilled > 0 || data?.actionCounts?.checkingNextSource > 0) &&
                CELL_STATUS_SYMBOL.payloadFilled
              }
              &nbsp;
              {data?.actionCounts?.payloadFilled || data?.actionCounts?.checkingNextSource || 0}
              &nbsp;In Progress
            </div>
            <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
              {CELL_STATUS_SYMBOL.pending}&nbsp;
              {data?.actionCounts?.pending || 0}
              &nbsp;Pending
            </div>
            <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
              {CELL_STATUS_SYMBOL.completed}&nbsp;
              {data?.actionCounts?.completed || 0}
              &nbsp;Completed
            </div>
            <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
              {CELL_STATUS_SYMBOL.failed}&nbsp;
              {data?.actionCounts?.failed || 0}
              &nbsp;Failed
            </div>
            <div className="bg-gray-100 flex items-center justify-center py-1 px-2 rounded">
              {CELL_STATUS_SYMBOL.conditionNotMet}&nbsp;
              {data?.actionCounts?.conditionNotMet || 0}
              &nbsp;Condition Not Met
            </div>
          </div>
        </div>
      </div>

      {/* Handle for outgoing connections */}
      <Handle type="source" position={Position.Bottom} id="a" />
    </div>
  );
}
