import { setStateType } from "@/utils/constants";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import Filters from "../Components/Filters";
import ExampleResponse from "../Components/ExampleResponse";
import { useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { CollapsibleIcon } from "@/Components/NavbarNew/CollapsibleIcon";
import PreviewData from "../Components/PreviewData";
import { signalPreview } from "../apis";
import { SIGNAL_ID_TO_LIST_PREVIEW_URL } from "@/utils/urls";
import CompaniesListComponent from "../Components/ListBuilding/CompaniesList";
import SalesNavList from "../Components/ListBuilding/SalesNavList";
import { message } from "antd";

type Props = {
  signal: any;
  setCreate: setStateType<string>;
  setSelected: setStateType<string>;
};

const Create = ({ signal, setCreate, setSelected }: Props) => {
  const [changeName, setChangeName] = useState(false);
  const [name, setName] = useState("New Signal-" + signal.shortName);
  const [loading, setLoading] = useState(false);
  const [previewData, setPreviewData] = useState<any>([]);

  const generatePreview = async (payload: any) => {
    setLoading(true);
    try {
      const url = SIGNAL_ID_TO_LIST_PREVIEW_URL[signal.id as keyof typeof SIGNAL_ID_TO_LIST_PREVIEW_URL];
      const responses = await signalPreview(url, payload);
      setPreviewData(responses);
      setLoading(false);
    } catch (error: any) {
      message.error(error.message || error);
      setLoading(false);
    }
  };

  // console.log("signalllll",signal);

  const getSignalLayout = (signal: any) => {
    const signal_id = signal.id;

    switch (signal_id) {
      case "FLOQER_COMPANY_LIST_BUILDING_TS":
        return (
          <CompaniesListComponent
            name={name}
            signalId={signal.id}
            preview={signal.isPreviewAvailable}
            generatePreview={generatePreview}
          />
        );

      case "FLOQER_LIST_BUILDING_SN":
        return (
          <SalesNavList
            name={name}
            signalId={signal.id}
            preview={signal.isPreviewAvailable}
            generatePreview={generatePreview}
            data={previewData}
          />
        );

      default:
        return (
          <Filters
            payloadStructure={signal.payloadStructure}
            signalId={signal.id}
            name={name}
            preview={signal.isPreviewAvailable}
            generatePreview={generatePreview}
          />
        );
    }
  };

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-5 p-5">
      <div className="w-full flex flex-row gap-2 items-center relative">
        {/* CollapsibleIcon in top left */}
        <CollapsibleIcon isHeader />
        <div className="flex gap-2 items-center text-2xl font-semibold bg-gradient-to-r from-[#5e35b1] via-[#d192e2] to-[#ab47bc] bg-clip-text text-black cursor-default">
          <button
            onClick={() => {
              setCreate("");
              setSelected("signals");
            }}
          >
            <MdOutlineKeyboardBackspace size={35} />
          </button>
          {/* Get Leads from <span className="text-primary">{signal.shortName}</span> */}
          <span
            id="signalName"
            contentEditable={changeName}
            className={changeName ? "border border-black px-2" : "border-none"}
            suppressContentEditableWarning
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.target.blur();
              }
            }}
            onBlur={async (e) => {
              if (!e.target.textContent) return;
              setName(e.target.textContent);
              setChangeName(false);
            }}
          >
            {name}
          </span>
          <button
            onClick={() => {
              setChangeName(!changeName);
              const p = document.getElementById("signalName");
              if (!p) return;
              setTimeout(function () {
                const range = document.createRange();
                const selection = window.getSelection();
                range.selectNodeContents(p);
                range.collapse(false); // <-- Set the cursor at the end of the selection
                selection?.removeAllRanges();
                selection?.addRange(range);
                p.focus();
              }, 0);
            }}
            className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
              changeName ? "opacity-50" : "opacity-100"
            }`}
          >
            <FaRegEdit />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4 h-full">
        {getSignalLayout(signal)}
        <div className="col-span-3">
          {signal.isPreviewAvailable ? (
            <PreviewData
              responseStructure={signal.responseStructure}
              dynamic={signal.outputStructureType === "dataDefined"}
              data={previewData}
              generating={loading}
              signalId={signal.id}
            />
          ) : (
            <ExampleResponse exampleResponse={signal.exampleResponse} responseStructure={signal.responseStructure} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Create;
