// import { ENV } from "./constants.tsx";
// const gen = "Gen2";
// const env = ENV.Dev;
// const v = "V3";

export const BASE_URL = import.meta.env.VITE_BASE_SERVER_URL;
export const TURBO_URL = import.meta.env.VITE_TURBO_SERVER_URL;
// const BASE_URL = "https://fastify-app-nzkkjvbi7q-pd.a.run.app";
// const BASE_URL = "https://tight-boa-neatly.ngrok-free.app";

// Auth
export const checkLoginMethodAndMemberURL = BASE_URL + "/auth/check_method";
export const loginWithGoogleURL = BASE_URL + "/loginWithGoogle";
export const loginWithEmailAndPassURL = BASE_URL + "/auth/password";
export const changePasswordURL = BASE_URL + "/auth/change_password";
export const resetPasswordURL = BASE_URL + "/auth/reset_password";

// User
export const getProfileURL = BASE_URL + "/user";
export const updateProfileURL = BASE_URL + "/user";
export const checkProfileAndPaymentURL = BASE_URL + "/user/check_profile_payment";
export const getOrgNameURL = BASE_URL + "/user/org_name";

// Org
export const createOrgURL = BASE_URL + "/org";
export const fetchOrgURL = BASE_URL + "/org";
export const updateOrgURL = BASE_URL + "/org";
export const addMemberURL = BASE_URL + "/org/members";
export const fetchMembersURL = BASE_URL + "/org/members";
export const removeMemberURL = BASE_URL + "/org/members";

// Workflows
export const getUserWorkflowsURL = BASE_URL + "/workflows"; //GET
export const deleteWorkflowURL = BASE_URL + "/workflows"; //DELETE
export const createNewWorkflowURL = BASE_URL + "/workflows"; //POST
export const renameWorkflowURL = BASE_URL + "/workflows"; //PATCH
export const createNewWorkflowFolderURL = BASE_URL + "/workflows/folder"; //POST
export const renameWorkflowFolderURL = BASE_URL + "/workflows/folder"; //PATCH //TODO IN FASTIFY
export const deleteWorkflowFolderURL = BASE_URL + "/workflows/folder"; //DELETE
export const moveWorkflowURL = BASE_URL + "/workflows/move"; //PATCH
export const moveWorkflowFolderURL = BASE_URL + "/workflows/move/folder"; //PATCH
export const duplicateWorkflowForUserURL = BASE_URL + "/workflows/duplicate"; //POST
export const exportWorkflowURL = TURBO_URL + "/workflows/export"; //POST
export const shareWorkflowAccessURL = BASE_URL + "/workflows/share"; // PATCH

// Workflows V2
export const getUserWorkflowsURLV2 = BASE_URL + "/v2/workflows"; //GET
export const deleteWorkflowURLV2 = BASE_URL + "/v2/workflows"; //DELETE
export const createNewWorkflowURLV2 = BASE_URL + "/v2/workflows"; //POST
export const renameWorkflowURLV2 = BASE_URL + "/v2/workflows"; //PATCH
export const createNewWorkflowFolderURLV2 = BASE_URL + "/v2/workflows/folder"; //POST
export const renameWorkflowFolderURLV2 = BASE_URL + "/v2/workflows/folder"; //PATCH //TODO IN FASTIFY
export const deleteWorkflowFolderURLV2 = BASE_URL + "/v2/workflows/folder"; //DELETE
export const moveWorkflowURLV2 = BASE_URL + "/v2/workflows/move"; //PATCH
export const duplicateWorkflowForUserURLV2 = BASE_URL + "/v2/workflows/duplicate"; //POST
export const getWorkflowStructureURLV2 = BASE_URL + "/v2/workflows/reorder"; //GET
export const changeWorkflowStructureURLV2 = BASE_URL + "/v2/workflows/reorder"; //POST
export const exportWorkflowURLV2 = TURBO_URL + "/v2/workflows/export"; //POST
export const shareWorkflowAccessURLV2 = BASE_URL + "/v2/workflows/share"; // PATCH

// Workflow Libs
export const getAllPredefinedWorkflowsURL = BASE_URL + "/workflow_libs";
export const copyWorkflowToUserURL = BASE_URL + "/workflow_libs";

// Workflow Libs V2
export const getAllPredefinedWorkflowsURLV2 = BASE_URL + "/v2/workflow_libs";
export const copyWorkflowToUserURLV2 = BASE_URL + "/v2/workflow_libs";

// Build
export const getAllWorkflowActionsDetailsURL = BASE_URL + "/build";
export const saveWorkflowActionsURL = BASE_URL + "/build";
export const publishDraftURL = BASE_URL + "/build";
export const revertDraftURL = BASE_URL + "/build/revert";
export const getCampaignsURL = BASE_URL + "/build/campaigns";
export const getSalesforceObjectsURL = BASE_URL + "/build/salesforce_objects";
export const getSfObjectsFieldsURL = BASE_URL + "/build/salesforce_object_fields";
export const getSfObjectItemsURL = BASE_URL + "/build/salesforce_object_items";
export const getSfListViewsURL = BASE_URL + "/build/salesforce_list_views";
export const getSfListViewColumnsURL = BASE_URL + "/build/salesforce_list_view_columns";
export const getSpreadsheetsURL = BASE_URL + "/build/spreadsheets";
// Build V2
export const getAllWorkflowActionsDetailsURLV2 = BASE_URL + "/v2/build";
export const saveWorkflowActionsURLV2 = BASE_URL + "/v2/build";
export const publishDraftURLV2 = BASE_URL + "/v2/build";
export const revertDraftURLV2 = BASE_URL + "/v2/build/revert";
export const getCampaignsURLV2 = BASE_URL + "/v2/build/campaigns";
export const getSalesforceObjectsURLV2 = BASE_URL + "/v2/build/salesforce_objects";
export const getSfObjectsFieldsURLV2 = BASE_URL + "/v2/build/salesforce_object_fields";
export const getSfObjectItemsURLV2 = BASE_URL + "/v2/build/salesforce_object_items";
export const getSfListViewsURLV2 = BASE_URL + "/v2/build/salesforce_list_views";
export const getSfListViewColumnsURLV2 = BASE_URL + "/v2/build/salesforce_list_view_columns";
export const getSpreadsheetsURLV2 = BASE_URL + "/v2/build/spreadsheets";

// Run
export const getDataInfoForWorkflowURL = TURBO_URL + "/run";
export const updateDataForWorkflowRunURL = TURBO_URL + "/run";
export const getTableInfoForWorkflowURL = TURBO_URL + "/run/table";
export const deleteTableRecordsURL = BASE_URL + "/run/table";
export const retryWorkflowForDataURL = TURBO_URL + "/run/retry";
export const updateGeneratedEmailURL = BASE_URL + "/run/email";
export const getDataStoreValueURL = BASE_URL + "/run/datastore";
export const markAsReviewedAndMoveOnURL = BASE_URL + "/run/review";
export const retryActionURL = TURBO_URL + "/run/retry_action";
export const dedupeRowsCheckURL = TURBO_URL + "/run/dedupe_rows_check";
export const saveWorkflowRecordURL = BASE_URL + "/run/save_workflow_record";
export const savePaginationURL = BASE_URL + "/run/table"; // PATCH

// Run V2
export const getDataInfoForWorkflowURLV2 = TURBO_URL + "/v2/run";
export const updateDataForWorkflowRunURLV2 = TURBO_URL + "/v2/run";
export const getTableInfoForWorkflowURLV2 = TURBO_URL + "/v2/run/table";
export const deleteTableRecordsURLV2 = BASE_URL + "/v2/run/table";
export const retryWorkflowForDataURLV2 = TURBO_URL + "/v2/run/retry";
export const updateGeneratedEmailURLV2 = BASE_URL + "/v2/run/email";
export const getDataStoreValueURLV2 = BASE_URL + "/v2/run/datastore";
export const markAsReviewedAndMoveOnURLV2 = BASE_URL + "/v2/run/review";
export const retryActionURLV2 = TURBO_URL + "/v2/run/retry_action";
export const refreshURLV2 = BASE_URL + "/v2/run/refresh";
export const dedupeRowsCheckURLV2 = TURBO_URL + "/v2/run/dedupe_rows_check";
export const saveWorkflowRecordURLV2 = BASE_URL + "/v2/run/save_workflow_record";
export const savePaginationURLV2 = BASE_URL + "/v2/run/table"; // PATCH
export const stopWorkflowActions = TURBO_URL + "/v2/run/stop_action";
export const deleteAllWorkflowData = BASE_URL + "/v2/run/table/all"; // DELETE

// Trigger
export const triggerWorkflowForInputURL = TURBO_URL + "/trigger/csv";
export const triggerSalesforcePullDataURL = TURBO_URL + "/trigger/salesforce_pull_data";
export const triggerEngagebayPullContactsURL = TURBO_URL + "/trigger/engagebay_pull_contacts";
export const triggerHubspotPullContactsURL = TURBO_URL + "/trigger/hubspot_pull_contacts";

// Trigger V2
export const triggerWorkflowForInputURLV2 = TURBO_URL + "/v2/trigger/csv";
export const triggerSalesforcePullDataURLV2 = TURBO_URL + "/v2/trigger/salesforce_pull_data";
export const triggerEngagebayPullContactsURLV2 = TURBO_URL + "/v2/trigger/engagebay_pull_contacts";
export const triggerHubspotPullContactsURLV2 = TURBO_URL + "/v2/trigger/hubspot_pull_contacts";

// Connections
export const getAllConnectionsURL = BASE_URL + "/connections";
export const saveConnectionURL = BASE_URL + "/connections/save/api";
export const saveOAuthConnectionURL = BASE_URL + "/connections/save/oauth";

// Connections
export const getAllConnectionsURLV2 = BASE_URL + "/v2/connections";
export const saveConnectionURLV2 = BASE_URL + "/v2/connections/save/api";
export const saveOAuthConnectionURLV2 = BASE_URL + "/v2/connections/save/oauth";

// Billing
export const fetchTransactionsForOrgIdUrl = BASE_URL + "/billing/transactions";
export const fetchTransactionsForOrgIdWithFiltersUrl = BASE_URL + "/billing/v2/transactions";
export const fetchTransactionsForOrgIdWithFilterUnformattedUrl = BASE_URL + "/billing/v2/transactions_unformatted";
export const fetchCreditsForOrgIdUrl = BASE_URL + "/billing/credits";
export const fetchPricingPlansForUserUrl = BASE_URL + "/billing/plans";
export const fetchAllAPIPricingUrl = BASE_URL + "/v2/billing/api_pricing";

// Workflow Builder
export const createDraftWorkflowURL = BASE_URL + "/draft_workflows";
export const getDraftWorkflowURL = BASE_URL + "/draft_workflows";
export const saveDraftWorkflowURL = BASE_URL + "/draft_workflows";
export const publishDraftWorkflowURL = BASE_URL + "/draft_workflows";
export const getAllDraftWorkflowsURL = BASE_URL + "/draft_workflows";

// MISSING APIs

//Chat URLs
export const CREATE_CHAT_URL = BASE_URL + "/createChatV2";
export const GET_CHATIDS_URL = BASE_URL + "/getChatIDs";
export const GET_CHAT_FOR_ID_URL = BASE_URL + "/getChatFromID";
export const RAG_BASED_TEXT_GENERATION_URL = BASE_URL + "/rag_chat_endpoint";

// Others
export const HTTP_API_CALL_PING_URL = BASE_URL + "/actions/http_api_call_ping_with_body";
export const HTTP_API_CALL_PING_URLV2 = BASE_URL + "/v2/actions/http_api_call_ping_with_body";
export const SALES_NAVIGATOR_PREVIEW_URL = BASE_URL + "/trigger/salesnavigator-preview";
export const SALES_NAVIGATOR_PREVIEW_URLV2 = BASE_URL + "/v2/trigger/salesnavigator-preview";
export const SALES_NAVIGATOR_IMPORT_URL = BASE_URL + "/trigger/salesnavigator-import";
export const SALES_NAVIGATOR_IMPORT_URLV2 = BASE_URL + "/v2/trigger/salesnavigator-import";
export const COMPANY_SEARCH_LIST_FETCH_URL = BASE_URL + "/trigger/ts-lists";
export const COMPANY_SEARCH_LIST_FETCH_URL_V2 = BASE_URL + "/v2/trigger/ts-lists";
export const REGION_SEARCH_LIST_FETCH_CRUSTDATA_URL_V2 = BASE_URL + "/v2/trigger/crustData-regions-list";
export const COMPANY_SEARCH_PREVIEW_URL = BASE_URL + "/trigger/ts-lists-company-preview";
export const COMPANY_SEARCH_PREVIEW_URL_V2 = BASE_URL + "/v2/trigger/ts-lists-company-preview";
export const COMPANY_SEARCH_IMPORT_URL = BASE_URL + "/trigger/ts-lists-company-import";
export const COMPANY_SEARCH_IMPORT_URL_V2 = BASE_URL + "/v2/trigger/ts-lists-company-import";
export const WELL_DB_OPERATORS_IMPORT_URL = BASE_URL + "/trigger/well-db-operators-import";
export const WELL_DB_OPERATORS_IMPORT_URL_V2 = BASE_URL + "/v2/trigger/well-db-operators-import";

export const getAirtableBasesURL = BASE_URL + "/v2/build/airtable/airtable_bases";
export const getAirtableTablesURL = BASE_URL + "/v2/build/airtable/airtable_tables";
export const getAirtableColumnsURL = BASE_URL + "/v2/build/airtable/airtable_columns";
export const CRUSTDATA_SALES_NAVIGATOR_PREVIEW_URL_V2 = BASE_URL + "/v2/trigger/crustData-linkedin-company-preview";
export const CRUSTDATA_SALES_NAV_IMPORT_URLV2 = BASE_URL + "/v2/trigger/crustData-salesnav-url-company-import";
export const CRUSTDATA_FILTERS_IMPORT_URLV2 = BASE_URL + "/v2/trigger/crustData-filters-company-import";
export const PIPEDRIVE_IMPORT_URL_V2 = BASE_URL + "/v2/trigger/pipedrive-import";

// Airtable URLs
export const createAirtableWebhookURL = BASE_URL + "/v2/build/airtable/webhook";
export const airtableWebhookTriggerURL = BASE_URL + "/v2/trigger/airtable";

// SIGNALS
export const GET_SIGNALS_URL = BASE_URL + "/signals";
export const GET_WATCHER_DATA_URL = BASE_URL + "/signals/watcher";
export const GET_LIST_DATA_URL = BASE_URL + "/signals/list";
export const EXPORT_LIST_DATA_URL = BASE_URL + "/signals/export";
export const GET_LIST_CONNECTIONS_URL = BASE_URL + "/signals/connections";
export const PUSH_LIST_TO_FLOQ_URL = BASE_URL + "/signals/push_list_to_floq";
export const UPDATE_WATCHER_NAME_URL = BASE_URL + "/signals/update-watcher-name";
export const SIGNAL_ID_TO_URL = {
  CRUSTDATA_MONITOR_JOBS: BASE_URL + "/signals/monitor-jobs-watcher",
  CRUSTDATA_MONITOR_FUNDING_ANNOUNCEMENTS: BASE_URL + "/signals/monitor-funding-announcements-watcher",
  CRUSTDATA_MONITOR_LINKEDIN_POSTS_WITH_KEYWORDS: BASE_URL + "/signals/monitor-linkedin-posts-with-keyword-watcher",
  CRUSTDATA_MONITOR_PERSON_STARTING_NEW_JOB: BASE_URL + "/signals/monitor-person-starting-new-job-watcher",
  CRUSTDATA_MONITOR_COMPANY_HEADCOUNT_INCREASED: BASE_URL + "/signals/monitor-headcount-increase-watcher",
  FIRECRAWL_SCRAPE_URL: BASE_URL + "/signals/firecrawl-scrape",
  FLOQER_COMPANY_LIST_BUILDING_TS: BASE_URL + "/signals/floqer-company-list-ts",
  FLOQER_LIST_BUILDING_SN: BASE_URL + "/signals/floqer-list-building-sn",
};
export const SIGNAL_ID_TO_LIST_PREVIEW_URL = {
  FIRECRAWL_SCRAPE_URL: BASE_URL + "/signals/firecrawl-scrape-preview",
  FLOQER_COMPANY_LIST_BUILDING_TS: BASE_URL + "/signals/floqer-company-list-ts-preview",
  FLOQER_LIST_BUILDING_SN: BASE_URL + "/signals/floqer-list-building-sn-preview",
};
