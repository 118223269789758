import { useUser } from "../../contexts/UserContext";
import Logout from "../../assets/SVGs/Logout";
import Wrapper from "./Components/Wrapper";
import { CollapsibleIcon } from "../NavbarNew/CollapsibleIcon";

const SettingIndex = () => {
  const { logout } = useUser();

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="h-full w-full flex flex-col !cursor-default gap-5 p-5">
      <div className="w-full flex flex-row justify-between items-center">
        <div className="w-full flex flex-row gap-2 items-center relative">
          <CollapsibleIcon isHeader />
          <div className="text-2xl font-bold bg-gradient-to-r from-[#5e35b1] via-[#8e24aa] to-[#ab47bc] bg-clip-text text-black cursor-default">
            Settings
          </div>
        </div>
        <div onClick={handleLogout}>
          <Logout />
        </div>
      </div>
      <Wrapper />
    </div>
  );
};

export default SettingIndex;
