import { useEffect, useState, useCallback } from "react";
import { Select, Skeleton, Switch, message, ConfigProvider, Drawer } from "antd";
import { FaPlus, FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { useWorkflow } from "../../../contexts/WorkflowContext";
import TiptapField from "../Tiptap/TiptapField";
import { useParams } from "react-router-dom";
import { getWorkflowDetailsWithActions } from "@/utils/apis";

type Props = {
  action: any;
  modal: boolean;
  setModal: (value: boolean) => void;
  payloadConfiguration: any[];
  variables: any[];
};

// First, let's add interfaces for our state types
interface TableData {
  loading: boolean;
  tables: Array<{
    id: string;
    name: string;
    fields: Array<{
      name: string;
      type: string;
    }>;
  }>;
}

interface ColumnData {
  loading: boolean;
  columns: Array<{
    name: string;
    type: string;
  }>;
}

// Add these interfaces near the top with other interfaces
interface AirtableField {
  name: string;
  type: string;
  options?: {
    choices?: Array<{
      id?: string;
      color?: string;
      name: string;
    }>;
  };
}

interface AirtableTable {
  id: string;
  name: string;
  fields: AirtableField[];
}

interface AirtableTablesResponse {
  tables: AirtableTable[];
}

const AirtableAddDataModal = ({ action, modal, setModal, payloadConfiguration, variables }: Props) => {
  const [bases, setBases] = useState<{ loading: boolean; bases: any[] }>({
    loading: false,
    bases: [],
  });
  const [tables, setTables] = useState<TableData>({
    loading: false,
    tables: [],
  });
  const [columns, setColumns] = useState<ColumnData>({
    loading: false,
    columns: [],
  });
  const [payloads, setPayloads] = useState<any[]>(payloadConfiguration);
  const { getAirtableData, updatePayloadConfig, saveWorkflowActions, updateActionName, setPublishWarning } =
    useWorkflow();
  const [loading, setLoading] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const [updateField, setUpdateField] = useState(0);
  const { id } = useParams();

  useEffect(() => {
    setBases({ loading: true, bases: [] });
    getAirtableData("airtable_bases").then(async (data: any) => {
      const workflowActions = await getWorkflowDetailsWithActions(id || "", true);
      const firstAction = workflowActions?.response?.actions?.find((a: any) => a.id === "id1");

      if (firstAction?.actionName === "AIRTABLE_IMPORT") {
        const baseConfig = firstAction.payloadConfiguration.find((p: any) => p.payloadStructureId === "baseId");
        const baseToFilter = baseConfig?.inputString;
        const filteredBases = data.bases.filter((base: any) => base.id !== baseToFilter);
        setBases({ loading: false, bases: filteredBases });
      } else {
        setBases({ loading: false, bases: data.bases });
      }
    });
  }, []);

  /**
   * @todo: instead of directly using index numbers, find the index by the payloadStructureId and then get the payload
   */
  useEffect(() => {
    if (payloads[0]?.inputString) {
      setTables({
        loading: true,
        tables: [],
      });
      const selectedBase = payloads[0]?.inputString;
      getAirtableData("airtable_tables", selectedBase).then((response: unknown) => {
        const data = response as AirtableTablesResponse;
        setTables({
          loading: false,
          tables: data.tables,
        });
        if (payloads[1]?.inputString) {
          const selectedTable = data.tables.find((table) => table.id === payloads[1].inputString);
          if (selectedTable) {
            const supportedFields = selectedTable.fields.filter(
              (field) =>
                field.type === "singleLineText" ||
                field.type === "multilineText" ||
                field.type === "phoneNumber" ||
                field.type === "url" ||
                field.type === "email" ||
                field.type === "number" ||
                field.type === "currency" ||
                field.type === "percent" ||
                field.type === "duration" ||
                field.type === "date" ||
                field.type === "dateTime" ||
                field.type === "singleSelect" ||
                field.type === "multipleSelects" ||
                field.type === "singleCollaborator" ||
                field.type === "checkbox" ||
                field.type === "rating"
            );

            setColumns({
              loading: false,
              columns: supportedFields,
            });
          }
        }
      });
    }
  }, [setBases]);

  useEffect(() => {
    setPayloads(payloadConfiguration);
    setUpdateField((prev) => prev + 1);
  }, [payloadConfiguration]);

  const handleBaseSelect = useCallback(
    async (selectedBaseId: string) => {
      setTables({
        loading: true,
        tables: [],
      });
      getAirtableData("airtable_tables", selectedBaseId).then((response: unknown) => {
        const data = response as AirtableTablesResponse;
        setTables({
          loading: false,
          tables: data.tables,
        });
      });
    },
    [getAirtableData]
  );

  const handleTableSelect = useCallback(() => {
    setColumns({
      loading: true,
      columns: [],
    });
    const selectedTable = tables.tables.find((table: any) => table.id === payloads[1]?.inputString);
    if (selectedTable) {
      if (selectedTable.fields.length === 0) {
        message.error("No columns found in the selected table");
      }
      setColumns({
        loading: false,
        columns: selectedTable.fields,
      });
    }
  }, [tables.tables, payloads]);

  const fieldSwitchCase = (field: any) => {
    switch (field.type) {
      case "airtableBasesDropdown":
        return (
          <Skeleton loading={bases.loading} paragraph={false} active={bases.loading}>
            <Select
              showSearch
              optionFilterProp="label"
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                handleBaseSelect(value);
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
              }}
              options={bases.bases?.map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "inTableDropdown":
        return (
          <Skeleton loading={tables.loading} paragraph={false} active={tables.loading}>
            <Select
              showSearch
              optionFilterProp="label"
              defaultValue={
                payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""
              }
              onChange={(value) => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString = value;
                setPayloads(temp);
                handleTableSelect();
              }}
              options={tables.tables?.map((value: any) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Skeleton>
        );
      case "jsonArray":
        return (
          <div className="w-full flex flex-col gap-4">
            {payloads
              .find((input) => input.payloadStructureId === field.payloadStructureId)
              ?.inputString.map((input: any, idx: number) => {
                return (
                  <div className="flex gap-2 justify-between" key={idx}>
                    <div className="w-full" key={idx}>
                      <Skeleton
                        loading={columns.loading}
                        paragraph={false}
                        active={columns.loading}
                        style={{ marginBottom: "0.25rem" }}
                      >
                        <Select
                          showSearch
                          style={{ width: "100%", marginBottom: "0.25rem" }}
                          value={input.label || ""}
                          onChange={(_value, options: any) => {
                            const selectedLabel = options.label;
                            const selectedField = columns.columns.find((col) => col.name === selectedLabel);
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx] = {
                              ...temp[index].inputString[idx],
                              name: selectedLabel,
                              label: selectedLabel,
                              uniqueKey: `${selectedLabel}_${idx}`,
                              fieldType: selectedField?.type || "string",
                            };
                            setColumns({
                              loading: false,
                              columns: columns.columns,
                            });
                            setPayloads(temp);
                          }}
                          options={columns.columns.map((field: any, i: number) => ({
                            label: field.name,
                            value: `${field.name}_${i}`,
                          }))}
                        />
                      </Skeleton>
                      {input.type !== "boolean" ? (
                        <TiptapField
                          content={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].tiptapJson || ""
                          }
                          setContent={(content: any, text: string) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].value = text;
                            temp[index].inputString[idx].tiptapJson = content;
                            setPayloads(temp);
                          }}
                          refresh={updateField}
                          placeholder="Enter the value"
                          variables={variables}
                        />
                      ) : (
                        <Switch
                          style={{
                            width: "fit-content",
                            marginTop: "0.25rem",
                            marginLeft: "0.20rem",
                          }}
                          defaultChecked={
                            payloads.find((input) => input.payloadStructureId === field.payloadStructureId)
                              ?.inputString[idx].value === true
                          }
                          checkedChildren="True"
                          unCheckedChildren="False"
                          onChange={(value) => {
                            const temp = [...payloads];
                            const index = payloads.findIndex(
                              (input) => input.payloadStructureId === field.payloadStructureId
                            );
                            temp[index].inputString[idx].value = value;
                            setPayloads(temp);
                          }}
                        />
                      )}
                    </div>
                    <button
                      className="hover:bg-primary/10 h-fit w-fit p-2 rounded-full text-primary"
                      onClick={() => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        temp[index].inputString.splice(idx, 1);
                        setUpdateField((prev) => prev + 1);
                        setPayloads(temp);
                      }}
                    >
                      <MdDeleteOutline size={25} />
                    </button>
                  </div>
                );
              })}
            <button
              className="text-primary flex gap-2 items-center"
              onClick={() => {
                const temp = [...payloads];
                const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
                temp[index].inputString.push({
                  name: "",
                  value: "",
                });
                setPayloads(temp);
              }}
            >
              <FaPlus />
              Add a new Variable Name and Variable Value pair
            </button>
          </div>
        );
      default:
        return (
          <textarea
            className="w-full h-32 rounded-lg"
            placeholder="Enter the value"
            value={payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.inputString || ""}
            onChange={(e) => {
              const temp = [...payloads];
              const index = payloads.findIndex((input) => input.payloadStructureId === field.payloadStructureId);
              if (index === -1) {
                temp.push({
                  payloadStructureId: field.payloadStructureId,
                  inputString: e.target.value,
                  type: field.type,
                });
              } else {
                temp[index].inputString = e.target.value;
              }
              setPayloads(temp);
            }}
          />
        );
    }
  };

  const saveOutputStructure = async () => {
    setLoading(true);
    const updated = updatePayloadConfig(payloads, action.id, false);
    setPayloads(updated);
    await saveWorkflowActions(id || "");
    setLoading(false);
    setPublishWarning(true);
    setModal(false);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Drawer: {
            footerPaddingBlock: 16,
            footerPaddingInline: 24,
          },
        },
      }}
    >
      <Drawer
        open={modal}
        onClose={() => setModal(false)}
        width="30%"
        className='!font-["Inter"] !p-0'
        footer={
          <div className="w-full flex flex-col gap-4 bg-white">
            <button
              className={`text-white w-full font-semibold rounded-lg p-2 mt-auto ${
                loading ? "cursor-not-allowed bg-gray-500" : "bg-primary cursor-pointer"
              }`}
              onClick={saveOutputStructure}
              disabled={loading}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        }
        title={
          <div className="flex items-center gap-2">
            <img src={action.logo} alt={action.name} className="w-6 h-6" />
            <span
              contentEditable={changeName}
              suppressContentEditableWarning
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.target.blur();
                }
              }}
              onBlur={async (e) => {
                if (!e.target.textContent) return;
                setLoading(true);
                updateActionName(action.id, e.target.textContent || action.name);
                setChangeName(false);
                await saveWorkflowActions(id || "");
                setLoading(false);
                setPublishWarning(true);
              }}
            >
              {action.name}
            </span>
            <button
              onClick={() => {
                setChangeName(!changeName);
              }}
              className={`p-2 rounded-md transition-opacity duration-600 ease-in-out hover:bg-gray-200 ${
                changeName ? "opacity-50" : "opacity-100"
              }`}
            >
              <FaRegEdit />
            </button>
          </div>
        }
      >
        <div className="bg-white gap-10 flex flex-col">
          {action.payloadStructure.map(
            (
              field: {
                name: string;
                description: string;
                required?: boolean;
                needVars?: boolean;
                payloadStructureId: string;
                type: string;
              },
              idx: number
            ) => {
              return (
                <div key={idx} className="flex flex-col w-full gap-4 p-4 border border-gray-300 rounded-lg">
                  <div>
                    <p className="tracking-primary font-semibold">
                      {field.name.toUpperCase()}
                      {!field.required && (
                        <span className="text-sm font-normal tracking-normal text-gray-400"> (Optional)</span>
                      )}
                    </p>
                    <p className="text-sm font-light">{field.description}</p>
                  </div>
                  {field.needVars ? (
                    <TiptapField
                      content={
                        payloads.find((input) => input.payloadStructureId === field.payloadStructureId)?.tiptapJson ||
                        ""
                      }
                      setContent={(content: any, text: string) => {
                        const temp = [...payloads];
                        const index = payloads.findIndex(
                          (input) => input.payloadStructureId === field.payloadStructureId
                        );
                        if (index === -1) {
                          temp.push({
                            payloadStructureId: field.payloadStructureId,
                            tiptapJson: content,
                            inputString: text,
                            type: field.type,
                          });
                        } else {
                          temp[index].tiptapJson = content;
                          temp[index].inputString = text;
                        }
                        setPayloads(temp);
                      }}
                      refresh={0}
                      placeholder={field.name}
                      variables={variables}
                    />
                  ) : (
                    fieldSwitchCase(field)
                  )}
                </div>
              );
            }
          )}
        </div>
      </Drawer>
    </ConfigProvider>
  );
};

export default AirtableAddDataModal;
