import { App, Drawer, message } from "antd";import { FaRegCopy, FaCoins } from "react-icons/fa";
import { useState } from "react";

interface Props {
  open: boolean;
  close: () => void;
  data: any;
  title: () => React.ReactNode;
  credits: number | Record<string, number>;
}

// Add this interface for job openings
interface JobOpening {
  url: string;
  title: string;
  category: string;
  date_added: string;
  location_text?: string;
}

// Add interface for milestone data
interface FundingMilestone {
  date: string;
  funding_round: string;
  funding_milestone_amount_usd?: number;
  funding_milestone_investors: string;
}

// First, add interfaces for the headcount data structure
interface HeadcountTimeseriesEntry {
  date: string;
  employee_count: number;
}

interface RoleMetrics {
  all_roles: string;
  "0_to_10_percent": string;
  "11_to_30_percent": string | null;
  "31_to_50_percent": string | null;
  "51_to_70_percent": string | null;
  "71_to_100_percent": string | null;
}

interface RoleGrowth {
  yoy: Record<string, number>;
  six_months: Record<string, number>;
}

interface SkillMetrics {
  all_skills: string;
  "0_to_10_percent": string | null;
  "11_to_30_percent": string | null;
  "31_to_50_percent": string | null;
  "51_to_70_percent": string | null;
  "71_to_100_percent": string | null;
}

interface HeadcountData {
  linkedin_headcount: number;
  role_metrics: RoleMetrics;
  role_percentages: Record<string, number>;
  role_absolute: Record<string, number>;
  role_growth: RoleGrowth;
  region_metrics: {
    all_regions: string;
    [key: string]: string | null;
  };
  region_percentages: Record<string, number>;
  region_absolute: Record<string, number>;
  total_growth_percent: {
    mom: number;
    qoq: number;
    yoy: number;
    six_months: number;
    two_years: number;
  };
  total_growth_absolute: {
    mom: number;
    qoq: number;
    yoy: number;
    six_months: number;
    two_years: number;
  };
  headcount_timeseries: HeadcountTimeseriesEntry[];
  headcount_by_function_timeseries: {
    CURRENT_FUNCTION: Record<string, HeadcountTimeseriesEntry[]>;
    GEO_REGION: Record<string, HeadcountTimeseriesEntry[]>;
  };
  skill_metrics: SkillMetrics;
  skill_percentages: Record<string, number>;
  skill_absolute: Record<string, number>;
}

// Add interfaces for timeseries data
interface TimeseriesEntry {
  date: string;
  monthly_visitors: number;
}

interface TrafficSourceEntry {
  date: string;
  traffic_source_direct_pct: number;
  traffic_source_search_pct: number;
  traffic_source_referral_pct: number;
  traffic_source_social_pct: number;
}

// Add interface for traffic source data
interface TrafficSource {
  name: string;
  value: number | null;
}

// Add this interface with the other interfaces at the top of the file
interface JobOpeningTimeseriesEntry {
  date: string;
  open_jobs: number;
}

// Then update the jobOpeningsData interface to include the proper type
interface JobOpeningsData {
  job_openings_count: number | null;
  growth_percent: {
    mom: number | null;
    qoq: number | null;
    yoy: number | null;
  };
  function_growth: {
    qoq: Record<string, number | null>;
    six_months: Record<string, number | null>;
  };
  recent_openings: JobOpening[];
  openings_timeseries: JobOpeningTimeseriesEntry[];
}

// First, add this interface near the top with other interfaces
interface NewsArticle {
  article_publish_date: string;
  article_publisher_name: string;
  article_title: string;
  article_url: string;
  company_id: number;
}

const CrustDataCompanyViewer = ({ open, close, data, title, credits }: Props) => {
  const [activeIndices, setActiveIndices] = useState<number[]>([]);

  // Access the nested data
  const companyData = data.largeActionResponse;
  // Basic company info section
  const basicInfo = {
    company_name: companyData["Company Name"],
    linkedin_profile_url: companyData["LinkedIn Profile URL"],
    linkedin_id: companyData["LinkedIn ID"],
    linkedin_logo_url: companyData["LinkedIn Logo URL"],
    company_website: companyData["Company Website"],
    description: companyData["LinkedIn Company Description"],
    headquarters: companyData["Headquarters"],
    year_founded: companyData["Year Founded"],
    company_type: companyData["Company Type"],
    employee_count_range: companyData["Employee Count Range"],
    estimated_revenue: `USD ${companyData["Estimated Revenue Lower Bound USD"]} - USD ${companyData["Estimated Revenue Higher Bound USD"]}`,
    hq_country: companyData["HQ Country"],
    hq_street_address: companyData["HQ Street Address"],
    largest_headcount_country: companyData["Largest Headcount Country"],
    company_twitter_url: companyData["Company Twitter URL"],
    ceo_location: companyData["CEO Location"],
    decision_makers: companyData["Decision Makers"],
    acquisition_status: companyData["Acquisition Status"] || "No data found",
    all_office_addresses: companyData["All Office Addresses"] || []
  };

  // Add this after the basicInfo constant
  const foundersData = {
    profiles: companyData.Founders?.profiles || [],
    founders_locations: companyData.Founders?.founders_locations || [],
    founders_degree_name: companyData.Founders?.founders_degree_name || "",
    founders_previous_titles: companyData.Founders?.founders_previous_titles || [],
    founders_previous_companies: companyData.Founders?.founders_previous_companies || [],
    founders_education_institute: companyData.Founders?.founders_education_institute || ""
  };

  // SEO data
  const seoData = {
    // Traffic metrics
    monthly_visitors: companyData.SEO?.monthly_visitors,
    monthly_visitor_mom_pct: companyData.SEO?.monthly_visitor_mom_pct,
    monthly_visitor_qoq_pct: companyData.SEO?.monthly_visitor_qoq_pct,
    
    // Traffic sources
    traffic_source_social_pct: companyData.SEO?.traffic_source_social_pct,
    traffic_source_search_pct: companyData.SEO?.traffic_source_search_pct,
    traffic_source_direct_pct: companyData.SEO?.traffic_source_direct_pct,
    traffic_source_referral_pct: companyData.SEO?.traffic_source_referral_pct,
    
    // SEO metrics
    average_ad_rank: companyData.SEO?.average_ad_rank,
    monthly_paid_clicks: companyData.SEO?.monthly_paid_clicks,
    total_ads_purchased: companyData.SEO?.total_ads_purchased,
    monthly_organic_value: companyData.SEO?.monthly_organic_value,
    total_organic_results: companyData.SEO?.total_organic_results,
    monthly_organic_clicks: companyData.SEO?.monthly_organic_clicks,
    average_seo_organic_rank: companyData.SEO?.average_seo_organic_rank,
    lost_ranked_seo_keywords: companyData.SEO?.lost_ranked_seo_keywords,
    monthly_google_ads_budget: companyData.SEO?.monthly_google_ads_budget,
    newly_ranked_seo_keywords: companyData.SEO?.newly_ranked_seo_keywords,
    gained_ranked_seo_keywords: companyData.SEO?.gained_ranked_seo_keywords
  };

  // Job openings data
  const jobOpeningsData: JobOpeningsData = {
    // Basic metrics
    job_openings_count: companyData["Job Openings"]?.job_openings_count,
    
    // Growth metrics
    growth_percent: {
      mom: companyData["Job Openings"]?.job_openings_count_growth_percent?.mom ?? null,
      qoq: companyData["Job Openings"]?.job_openings_count_growth_percent?.qoq ?? null,
      yoy: companyData["Job Openings"]?.job_openings_count_growth_percent?.yoy ?? null
    },
    
    // Function growth metrics
    function_growth: {
      qoq: companyData["Job Openings"]?.job_openings_by_function_qoq_pct || {},
      six_months: companyData["Job Openings"]?.job_openings_by_function_six_months_growth_pct || {}
    },
    
    // Recent openings
    recent_openings: companyData["Job Openings"]?.recent_job_openings || [],
    
    // Timeseries data
    openings_timeseries: companyData["Job Openings"]?.open_jobs_timeseries || []
  };

  // Add this after other data constants
  const fundingData = {
    crunchbase_investors: companyData["Funding and Investment"]?.crunchbase_investors || [],
    last_funding_round_type: companyData["Funding and Investment"]?.last_funding_round_type || "",
    days_since_last_fundraise: companyData["Funding and Investment"]?.days_since_last_fundraise,
    funding_milestones_timeseries: companyData["Funding and Investment"]?.funding_milestones_timeseries || [],
    crunchbase_total_investment_usd: companyData["Funding and Investment"]?.crunchbase_total_investment_usd,
    last_funding_round_investment_usd: companyData["Funding and Investment"]?.last_funding_round_investment_usd
  };

  // Add this after other data constants
  const competitorsData = {
    competitor_website_domains: companyData.Competitors?.competitor_website_domains || [],
    paid_seo_competitors_website_domains: companyData.Competitors?.paid_seo_competitors_website_domains || [],
    organic_seo_competitors_website_domains: companyData.Competitors?.organic_seo_competitors_website_domains || []
  };

  // Add this after other data constants
  const g2Data = {
    g2_review_count: companyData.G2?.g2_review_count,
    g2_average_rating: companyData.G2?.g2_average_rating,
    g2_review_count_mom_pct: companyData.G2?.g2_review_count_mom_pct,
    g2_review_count_qoq_pct: companyData.G2?.g2_review_count_qoq_pct,
    g2_review_count_yoy_pct: companyData.G2?.g2_review_count_yoy_pct
  };

  // Add this after other data constants
  const glassdoorData = {
    glassdoor_review_count: companyData.Glassdoor?.glassdoor_review_count,
    glassdoor_culture_rating: companyData.Glassdoor?.glassdoor_culture_rating,
    glassdoor_overall_rating: companyData.Glassdoor?.glassdoor_overall_rating,
    glassdoor_ceo_approval_pct: companyData.Glassdoor?.glassdoor_ceo_approval_pct,
    glassdoor_diversity_rating: companyData.Glassdoor?.glassdoor_diversity_rating,
    glassdoor_compensation_rating: companyData.Glassdoor?.glassdoor_compensation_rating,
    glassdoor_business_outlook_pct: companyData.Glassdoor?.glassdoor_business_outlook_pct,
    glassdoor_recommend_to_friend_pct: companyData.Glassdoor?.glassdoor_recommend_to_friend_pct,
    glassdoor_senior_management_rating: companyData.Glassdoor?.glassdoor_senior_management_rating,
    glassdoor_work_life_balance_rating: companyData.Glassdoor?.glassdoor_work_life_balance_rating,
    glassdoor_career_opportunities_rating: companyData.Glassdoor?.glassdoor_career_opportunities_rating,
    glassdoor_ceo_approval_growth_percent: companyData.Glassdoor?.glassdoor_ceo_approval_growth_percent,
    glassdoor_review_count_growth_percent: companyData.Glassdoor?.glassdoor_review_count_growth_percent
  };

  // Then update the headcountDetailsData declaration
  const headcountDetailsData: HeadcountData = {
    linkedin_headcount: companyData.Headcount?.linkedin_headcount,
    role_metrics: companyData.Headcount?.linkedin_role_metrics,
    role_percentages: companyData.Headcount?.linkedin_headcount_by_role_percent,
    role_absolute: companyData.Headcount?.linkedin_headcount_by_role_absolute,
    role_growth: {
      yoy: companyData.Headcount?.linkedin_headcount_by_role_yoy_growth_percent,
      six_months: companyData.Headcount?.linkedin_headcount_by_role_six_months_growth_percent
    },
    region_metrics: companyData.Headcount?.linkedin_region_metrics,
    region_percentages: companyData.Headcount?.linkedin_headcount_by_region_percent,
    region_absolute: companyData.Headcount?.linkedin_headcount_by_region_absolute,
    total_growth_percent: companyData.Headcount?.linkedin_headcount_total_growth_percent,
    total_growth_absolute: companyData.Headcount?.linkedin_headcount_total_growth_absolute,
    headcount_timeseries: companyData.Headcount?.linkedin_headcount_timeseries,
    headcount_by_function_timeseries: companyData.Headcount?.linkedin_headcount_by_function_timeseries,
    skill_metrics: companyData.Headcount?.linkedin_skill_metrics,
    skill_percentages: companyData.Headcount?.linkedin_headcount_by_skill_percent,
    skill_absolute: companyData.Headcount?.linkedin_headcount_by_skill_absolute
  };
  // Add this after other data constants
  const marketsData = {
    markets: companyData.Markets || []
  };

  // Add this after other data constants
  const stockData = {
    symbols: companyData["Stock Symbols"] || []
  };

  // Add this after other data constants
  const taxonomyData = {
    linkedin_industries: companyData.Taxonomy?.linkedin_industries || [],
    crunchbase_categories: companyData.Taxonomy?.crunchbase_categories || [],
    linkedin_specialities: companyData.Taxonomy?.linkedin_specialities || []
  };

  // Update the webTrafficData constant
  const webTrafficData = {
    // Basic metrics
    monthly_visitors: companyData["Web Traffic"]?.monthly_visitors,
    monthly_visitor_mom_pct: companyData["Web Traffic"]?.monthly_visitor_mom_pct,
    monthly_visitor_qoq_pct: companyData["Web Traffic"]?.monthly_visitor_qoq_pct,
    
    // Traffic sources
    traffic_sources: {
      direct: companyData["Web Traffic"]?.traffic_source_direct_pct,
      search: companyData["Web Traffic"]?.traffic_source_search_pct,
      social: companyData["Web Traffic"]?.traffic_source_social_pct,
      referral: companyData["Web Traffic"]?.traffic_source_referral_pct,
      paid_referral: companyData["Web Traffic"]?.traffic_source_paid_referral_pct
    },
    
    // Timeseries data
    monthly_visitors_timeseries: companyData["Web Traffic"]?.monthly_visitors_timeseries,
    traffic_sources_timeseries: {
      direct: companyData["Web Traffic"]?.traffic_source_direct_pct_timeseries,
      search: companyData["Web Traffic"]?.traffic_source_search_pct_timeseries,
      social: companyData["Web Traffic"]?.traffic_source_social_pct_timeseries,
      referral: companyData["Web Traffic"]?.traffic_source_referral_pct_timeseries,
      paid_referral: companyData["Web Traffic"]?.traffic_source_paid_referral_pct_timeseries
    }
  };

  // First, add a new constant for LinkedIn data
  const linkedinData = {
    description: companyData["LinkedIn Company Description"],
    followers: data.linkedin_followers?.linkedin_followers,
    followers_mom_growth: data.linkedin_followers?.linkedin_followers_mom_percent,
    followers_yoy_growth: data.linkedin_followers?.linkedin_followers_yoy_percent,
    id: companyData["LinkedIn ID"],
    logo_url: companyData["LinkedIn Logo URL"],
    profile_url: companyData["LinkedIn Profile URL"],
    linkedin_follower_count_timeseries: companyData["LinkedIn Follower Count Timeseries"]
  };

  const handleCopyText = async (text: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (!text) return;
    try {
      await navigator.clipboard.writeText(text);
      message.success("Content copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy content.");
      console.error("Copy error:", err);
    }
  };

  const renderMetricSection = (title: string, data: any, index: number) => {
    if (!data) return null;

    const handleClick = () => {
      // If the section is already open, close it
    if (activeIndices.includes(index)) {
        setActiveIndices(activeIndices.filter(i => i !== index));
    } else {
        // Close any other open sections and open this one
        setActiveIndices([index]);
      }
    };
    
    return (
      <div 
        className="flex items-center justify-between p-2 bg-gray-50 cursor-pointer hover:bg-gray-100"
        onClick={handleClick}
      >
        <span className="font-medium">{title}</span>
        <span className={`transform transition-transform ${activeIndices.includes(index) ? 'rotate-180' : ''}`}>
          ▼
        </span>
      </div>
    );
  };

  const renderField = (label: string, value: any) => {
    if (value === undefined || value === null) return null;
    
    // Handle array values
    if (Array.isArray(value)) {
      return (
        <div className="w-auto flex-1 group flex flex-col items-start justify-between">
          <p className="text-[#575757]">{label}</p>
          <div className="bg-[#F2FBFF] w-full text-left px-2 py-1">
            <div className="max-h-40 overflow-y-auto">
              {value.map((item, index) => (
                <div key={index} className="flex items-center justify-between py-1 border-b border-gray-100 last:border-0">
                  <p className="w-[96%]">{item}</p>
                  <span
                    className="cursor-pointer hidden group-hover:block"
                    onClick={(e) => handleCopyText(String(item), e)}
                  >
                    <FaRegCopy className="text-gray-600" />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    
    // Handle string/number values
    return (
      <div className="w-auto flex-1 group flex flex-col items-start justify-between">
        <p className="text-[#575757]">{label}</p>
        <div className="bg-[#F2FBFF] w-full text-left px-2 py-1">
          <div className="max-h-40 overflow-y-auto">
            <div className="flex items-center justify-between">
              <p className="w-[96%]">{value || "\u00A0"}</p>
              <span
                className="cursor-pointer hidden group-hover:block"
                onClick={(e) => handleCopyText(String(value), e)}
              >
                <FaRegCopy className="text-gray-600" />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // First, add a helper function to format the date
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <App>
      <Drawer
        title={title()}
        placement="right"
        width={720}
        onClose={close}
        open={open}
        footer={
          <div className="flex items-center">
            <h1 className="font-semibold text-md">Total cost:</h1>
            <div className="flex items-center ml-2 bg-purple-100 rounded p-1">
              <span className="font-bold text-md">
                {typeof credits === "number" ? credits : Object.values(credits)[0]}
              </span>
              <FaCoins className="ml-1 text-purple-500 w-5" />
            </div>
          </div>
        }
      >
        <div className="space-y-4">
          {/* Basic Info Section - Always Visible */}
          <div className="space-y-4 mb-6">
            {/* Company Details */}
            <div>
              <p className="text-[#575757] font-semibold mb-2">Company Information</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                {/* First Column */}
                <div className="space-y-2">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Company Name</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.company_name}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Company Type</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.company_type}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Year Founded</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.year_founded}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Employee Count Range</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.employee_count_range}</p>
                    </div>
                  </div>
                </div>
                {/* Second Column */}
                <div className="space-y-2 self-start">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Estimated Revenue Range (USD)</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.estimated_revenue}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Company Website</p>
                    <div className="max-h-40 overflow-y-auto">
                      <a 
                        href={basicInfo.company_website} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-blue-600 hover:underline break-words"
                      >
                        {basicInfo.company_website}
                      </a>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Acquisition Status</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.acquisition_status}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Location Details */}
            <div>
              <p className="text-[#575757] font-semibold mb-2">Location Information</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                {/* First Column */}
                <div className="space-y-2">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Headquarters</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.headquarters}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">HQ Country</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.hq_country}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">HQ Address</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.hq_street_address}</p>
                    </div>
                  </div>
                </div>
                {/* Second Column */}
                <div className="space-y-2 self-start">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">CEO Location</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.ceo_location}</p>
                    </div>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Largest Headcount Country</p>
                    <div className="max-h-40 overflow-y-auto">
                      <p className="break-words">{basicInfo.largest_headcount_country}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* All Office Addresses */}
            {basicInfo.all_office_addresses.length > 0 && (
              <div>
                <p className="text-[#575757] font-semibold mb-2">All Office Addresses</p>
                <div className="bg-[#F2FBFF] p-2">
                  <div className="max-h-60 overflow-y-auto">
                    {basicInfo.all_office_addresses.map((address: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between py-1 border-b border-gray-100 last:border-0">
                        <span className="break-words">{address}</span>
                        <FaRegCopy 
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(address, e)}
                        />
                      </div>
                    ))}
                  </div>
              </div>
            </div>
          )}
          </div>

          {/* LinkedIn Followers Section - after basic info */}
          <div className="space-y-4 mb-6">
            <p className="text-[#575757] font-semibold mb-2">LinkedIn Followers</p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {/* Current Followers and Growth */}
              <div className="space-y-2">
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-sm text-gray-600">Current Followers</p>
                  <p className="break-words">{companyData["LinkedIn Followers"]?.linkedin_followers?.toLocaleString()}</p>
                </div>
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-sm text-gray-600">Growth Metrics</p>
                  <div className="space-y-1">
                    <div className="flex justify-between">
                      <span>MoM:</span>
                      <span>{companyData["LinkedIn Followers"]?.linkedin_followers_mom_percent?.toFixed(2)}%</span>
                    </div>
                    <div className="flex justify-between">
                      <span>QoQ:</span>
                      <span>{companyData["LinkedIn Followers"]?.linkedin_followers_qoq_percent?.toFixed(2)}%</span>
                    </div>
                    <div className="flex justify-between">
                      <span>YoY:</span>
                      <span>{companyData["LinkedIn Followers"]?.linkedin_followers_yoy_percent?.toFixed(2)}%</span>
                    </div>
                    <div className="flex justify-between">
                      <span>6 Month:</span>
                      <span>{companyData["LinkedIn Followers"]?.linkedin_followers_six_months_growth_percent?.toFixed(2)}%</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Followers Timeline */}
              <div className="space-y-2">
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-sm text-gray-600 mb-2">Followers Timeline</p>
                  <div className="h-[200px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                    {companyData["LinkedIn Followers"]?.linkedin_follower_count_timeseries
                      ?.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())
                      .map((entry: any, index: number) => (
                        <div key={index} className="flex justify-between items-center py-1.5 border-b border-gray-100 last:border-0 hover:bg-gray-50">
                          <span className="text-sm">{formatDate(entry.date)}</span>
                          <span className="text-sm font-medium">{entry.follower_count.toLocaleString()} followers</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Other sections with dropdowns continue below */}
          {/* LinkedIn Details */}
          <div className="space-y-4 mt-2">
            <div>
              <p className="text-[#575757] font-semibold mb-2">LinkedIn Details</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="space-y-2">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">LinkedIn ID</p>
                    <p className="break-words">{linkedinData.id}</p>
                  </div>
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Profile URL</p>
                    <a 
                      href={linkedinData.profile_url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-blue-600 hover:underline break-words"
                    >
                      {linkedinData.profile_url}
                    </a>
                  </div>
                </div>
                <div className="space-y-2 self-start">
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">Logo URL</p>
                    <a 
                      href={linkedinData.profile_url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="text-blue-600 hover:underline break-words"
                    >
                      {linkedinData.logo_url}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Company Description */}
            <div>
              <p className="text-[#575757] font-semibold mb-2">Company Description</p>
              <div className="bg-[#F2FBFF] p-2">
                <p className="whitespace-pre-wrap">{linkedinData.description}</p>
              </div>
            </div>
          </div>

          {/* Founders Information */}
          {renderMetricSection("Founders Information", foundersData, 8)}
          {activeIndices.includes(8) && (
            <div className="space-y-2 mt-2">
              {foundersData.founders_locations.length === 0 && 
               foundersData.founders_previous_companies.length === 0 && 
               (!foundersData.founders_degree_name?.length || foundersData.founders_degree_name.length === 0) && 
               (!foundersData.founders_education_institute?.length || foundersData.founders_education_institute.length === 0) && (
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-gray-600">No data found</p>
                </div>
              )}

              {foundersData.founders_locations.length > 0 && (
                <div className="w-full">
                  <p className="text-[#575757]">Founder Locations</p>
                  <div className="bg-[#F2FBFF] p-2">
                    <div className="max-h-40 overflow-y-auto">
                      {foundersData.founders_locations.map((location: string, index: number) => (
                        <div key={index} className="group flex items-center justify-between py-1 border-b border-gray-100 last:border-0">
                          <p>{location}</p>
                          <FaRegCopy 
                            className="text-gray-600 cursor-pointer hidden group-hover:block"
                            onClick={(e) => handleCopyText(location, e)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {foundersData.founders_previous_companies.length > 0 && (
                <div className="w-full mt-4">
                  <p className="text-[#575757]">Previous Companies</p>
                  <div className="bg-[#F2FBFF] p-2">
                    <div className="max-h-40 overflow-y-auto">
                      {foundersData.founders_previous_companies.map((company: string, index: number) => (
                        <div key={index} className="group flex items-center justify-between py-1 border-b border-gray-100 last:border-0">
                          <p>{company}</p>
                          <FaRegCopy 
                            className="text-gray-600 cursor-pointer hidden group-hover:block"
                            onClick={(e) => handleCopyText(company, e)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {/* Education Information */}
              {(foundersData.founders_degree_name?.length > 0 || foundersData.founders_education_institute?.length > 0) && (
                <div className="grid grid-cols-1 gap-4 mt-4">
                  {foundersData.founders_degree_name?.length > 0 && renderField("Education Degrees", foundersData.founders_degree_name)}
                  {foundersData.founders_education_institute?.length > 0 && renderField("Education Institutes", foundersData.founders_education_institute)}
                </div>
              )}
            </div>
          )}

          {/* Headcount Section */}
          {renderMetricSection("LinkedIn Headcount Metrics", headcountDetailsData, 1)}
          {activeIndices.includes(1) && (
            <div className="space-y-4 mt-2">
              {/* Basic Metrics */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Basic Metrics</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {/* First Column */}
                  <div className="space-y-2">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Current Headcount</p>
                      <p className="break-words">{headcountDetailsData.linkedin_headcount?.toLocaleString()}</p>
              </div>
                  </div>
                  {/* Second Column */}
                  <div className="space-y-2 self-start">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Growth Metrics</p>
                      <div className="space-y-1">
                        <div className="flex justify-between">
                          <span>MoM:</span>
                          <div>
                            <span>{headcountDetailsData.total_growth_percent?.mom}%</span>
                            <span className="ml-2 text-gray-500">({headcountDetailsData.total_growth_absolute?.mom} employees)</span>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span>QoQ:</span>
                          <div>
                            <span>{headcountDetailsData.total_growth_percent?.qoq}%</span>
                            <span className="ml-2 text-gray-500">({headcountDetailsData.total_growth_absolute?.qoq} employees)</span>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span>YoY:</span>
                          <div>
                            <span>{headcountDetailsData.total_growth_percent?.yoy}%</span>
                            <span className="ml-2 text-gray-500">({headcountDetailsData.total_growth_absolute?.yoy} employees)</span>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span>6 Month:</span>
                          <div>
                            <span>{headcountDetailsData.total_growth_percent?.six_months}%</span>
                            <span className="ml-2 text-gray-500">({headcountDetailsData.total_growth_absolute?.six_months} employees)</span>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span>2 Year:</span>
                          <div>
                            <span>{headcountDetailsData.total_growth_percent?.two_years}%</span>
                            <span className="ml-2 text-gray-500">({headcountDetailsData.total_growth_absolute?.two_years} employees)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Role Information */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Role Distribution</p>
                
                {/* All Roles Overview */}
                <div className="bg-[#F2FBFF] p-2 mb-4">
                  <p className="text-sm text-gray-600 mb-2">All Roles</p>
                  <p className="break-words">
                    {headcountDetailsData.role_metrics.all_roles
                      .split(', ')
                      .sort()
                      .join(', ')}
                  </p>
                </div>

                {/* Role Distribution by Percentage Range */}
                <div className="mb-4">
                  <p className="text-sm text-gray-600 mb-2">Distribution by Percentage Range</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {Object.entries(headcountDetailsData.role_metrics)
                      .filter(([key]) => key !== 'all_roles')
                      .map(([range, roles]) => roles && (
                        <div key={range} className="bg-[#F2FBFF] p-2">
                          <p className="text-sm text-gray-600 font-medium">
                            {range === '0_to_10_percent' ? '0-10%' :
                             range === '11_to_30_percent' ? '11-30%' :
                             range === '31_to_50_percent' ? '31-50%' :
                             range === '51_to_70_percent' ? '51-70%' :
                             range === '71_to_100_percent' ? '71-100%' : range}
                          </p>
                          <p className="break-words text-sm mt-1">
                            {String(roles).replace(/['"]/g, '').split(', ').join(', ')}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>

                {/* Role Counts and Percentages */}
                <div className="mb-4">
                  <p className="text-sm text-gray-600 mb-2">Role Distribution Details</p>
                  <div className="bg-[#F2FBFF] p-2">
                    {Object.entries(headcountDetailsData.role_absolute)
                      .sort(([, a], [, b]) => (b as number) - (a as number))
                      .map(([role, count], index) => (
                        <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                          <span className="text-sm">{role}</span>
                          <div className="flex items-center gap-4">
                            <span className="text-sm">{count.toLocaleString()} employees</span>
                            <span className="text-sm">({headcountDetailsData.role_percentages[role]?.toFixed(2)}%)</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                {/* Role Growth */}
                <div>
                  <p className="text-sm text-gray-600 mb-2">Role Growth</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {/* YoY Growth */}
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600 font-medium mb-2">Year over Year Growth</p>
                      {Object.entries(headcountDetailsData.role_growth.yoy || {})
                        .sort(([, a], [, b]) => (b as number) - (a as number))
                        .map(([role, growth], index) => (
                          <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                            <span className="text-sm capitalize">{role.replace(/_/g, ' ')}</span>
                            <span className="text-sm">{(growth as number).toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                    
                    {/* 6-Month Growth */}
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600 font-medium mb-2">6-Month Growth</p>
                      {Object.entries(headcountDetailsData.role_growth.six_months || {})
                        .sort(([, a], [, b]) => (b as number) - (a as number))
                        .map(([role, growth], index) => (
                          <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                            <span className="text-sm capitalize">{role.replace(/_/g, ' ')}</span>
                            <span className="text-sm">{(growth as number).toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Skills Information */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Skills Distribution</p>
                
                {/* All Skills Overview */}
                <div className="bg-[#F2FBFF] p-2 mb-4">
                  <p className="text-sm text-gray-600 mb-2">All Skills</p>
                  <p className="break-words">
                    {headcountDetailsData.skill_metrics?.all_skills
                      ? headcountDetailsData.skill_metrics.all_skills
                          .split(', ')
                          .sort()
                          .join(', ')
                      : 'No skills data available'}
                  </p>
                </div>

                {/* Skills Distribution by Percentage Range */}
                <div className="mb-4">
                  <p className="text-sm text-gray-600 mb-2">Distribution by Percentage Range</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {Object.entries(headcountDetailsData.skill_metrics || {})
                      .filter(([key]) => key !== 'all_skills' && key !== '__typename')
                      .map(([range, skills]) => skills && (
                        <div key={range} className="bg-[#F2FBFF] p-2">
                          <p className="text-sm text-gray-600 font-medium">
                            {range === '0_to_10_percent' ? '0-10%' :
                             range === '11_to_30_percent' ? '11-30%' :
                             range === '31_to_50_percent' ? '31-50%' :
                             range === '51_to_70_percent' ? '51-70%' :
                             range === '71_to_100_percent' ? '71-100%' : range}
                          </p>
                          <p className="break-words text-sm mt-1">
                            {typeof skills === 'string' 
                              ? skills.replace(/['"]/g, '').split(', ').join(', ')
                              : 'No data available'}
                          </p>
              </div>
                      ))}
            </div>
                </div>

                {/* Skills Counts and Percentages */}
                <div className="mb-4">
                  <p className="text-sm text-gray-600 mb-2">Skills Distribution Details</p>
                  <div className="bg-[#F2FBFF] p-2">
                    {Object.entries(headcountDetailsData.skill_absolute || {})
                      .sort(([, a], [, b]) => b - a)
                      .map(([skill, count], index) => (
                        <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                          <span className="text-sm">{skill}</span>
                          <div className="flex items-center gap-4">
                            <span className="text-sm">{count.toLocaleString()} employees</span>
                            <span className="text-sm">
                              ({headcountDetailsData.skill_percentages[skill]?.toFixed(2)}%)
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              
              {/* Regional Distribution */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Region Distribution by Percentage</p>
                <div className="space-y-4">
                  {/* All Regions */}
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600">All Regions</p>
                    <p className="break-words">
                      {headcountDetailsData.region_metrics.all_regions
                        ? String(headcountDetailsData.region_metrics.all_regions)
                            .replace(/['"]/g, '')  // Remove all quotes
                            .split(', ')           // Split into array
                            .join(', ')           // Join back with commas
                        : 'None'}
                    </p>
                  </div>
                  
                  {/* Distribution by Percentage Ranges */}
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {Object.entries(headcountDetailsData.region_metrics)
                      .filter(([key]) => key !== 'all_regions')
                      .map(([range, regions]) => (
                        <div key={range} className="bg-[#F2FBFF] p-2">
                          <p className="text-sm text-gray-600">
                            {range === '0_to_10_percent' ? '0-10%' :
                             range === '11_to_30_percent' ? '11-30%' :
                             range === '31_to_50_percent' ? '31-50%' :
                             range === '51_to_70_percent' ? '51-70%' :
                             range === '71_to_100_percent' ? '71-100%' : range}
                          </p>
                          <p className="break-words">
                            {regions ? String(regions)
                              .replace(/['"]/g, '')  // Remove quotes
                              .split(', ')           // Split into array
                              .join(', ')           // Join back with commas
                              : 'None'}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Region Absolute Counts */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Regional Headcount Distribution</p>
                <div className="bg-[#F2FBFF] p-2">
                  {Object.entries(headcountDetailsData.region_absolute || {})
                    .sort(([, a], [, b]) => (b as number) - (a as number))
                    .map(([region, count], index) => (
                      <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                        <span className="text-sm">{region}</span>
                        <span className="text-sm font-medium">{count.toLocaleString()} employees</span>
                </div>
                    ))}
              </div>
            </div>

              {/* Region Percentages */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Regional Distribution Percentages</p>
                <div className="bg-[#F2FBFF] p-2">
                  {Object.entries(headcountDetailsData.region_percentages || {})
                    .sort(([, a], [, b]) => (b as number) - (a as number))
                    .map(([region, percentage], index) => (
                      <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                        <span className="text-sm">{region}</span>
                        <span className="text-sm font-medium">{(percentage as number).toFixed(2)}%</span>
                      </div>
                    ))}
                </div>
              </div>
              
              {/* Headcount Time Series */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Headcount Time Series</p>
                
                {/* Function-wise Headcount */}
                <div className="space-y-4">
                  <p className="text-[#575757] font-medium">By Function</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {Object.entries(headcountDetailsData.headcount_by_function_timeseries?.CURRENT_FUNCTION || {}).map(([function_name, data]) => (
                      <div key={function_name} className="bg-[#F2FBFF] p-2">
                        <p className="text-sm text-gray-600 font-medium mb-2">
                          {function_name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                        </p>
                        <div className="max-h-40 overflow-y-auto">
                          {(data as Array<{date: string; employee_count: number}>)
                            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                            .map((entry, index) => (
                              <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                                <span className="text-sm">{formatDate(entry.date)}</span>
                                <span className="text-sm font-medium">{entry.employee_count.toLocaleString()} employees</span>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ))}
                  </div>
              </div>
              
                {/* Region-wise Headcount */}
                <div className="space-y-4 mt-4">
                  <p className="text-[#575757] font-medium">By Region</p>
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {Object.entries(headcountDetailsData.headcount_by_function_timeseries?.GEO_REGION || {}).map(([region, data]) => (
                      <div key={region} className="bg-[#F2FBFF] p-2">
                        <p className="text-sm text-gray-600 font-medium mb-2">{region}</p>
                        <div className="max-h-40 overflow-y-auto">
                          {(data as Array<{date: string; employee_count: number}>)
                            .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                            .map((entry, index) => (
                              <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                                <span className="text-sm">{formatDate(entry.date)}</span>
                                <span className="text-sm font-medium">{entry.employee_count.toLocaleString()} employees</span>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Overall Headcount Time Series */}
              <div className="mt-4">
                  <p className="text-[#575757] font-semibold mb-2">Overall Headcount Timeline</p>
                  <div className="bg-[#F2FBFF] p-2">
                    <div className="max-h-60 overflow-y-auto">
                      {headcountDetailsData.headcount_timeseries
                        ?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                        .map((entry, index) => (
                          <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                            <span className="text-sm">{formatDate(entry.date)}</span>
                            <span className="text-sm font-medium">{entry.employee_count.toLocaleString()} employees</span>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Glassdoor */}
          {renderMetricSection("Glassdoor Metrics", glassdoorData, 3)}
          {activeIndices.includes(3) && (
            <div className="space-y-4 mt-2">
              {/* Basic Metrics */}
              <div className="grid grid-cols-2 gap-4">
                {renderField("Review Count", glassdoorData.glassdoor_review_count?.toLocaleString())}
                {renderField("Overall Rating", glassdoorData.glassdoor_overall_rating?.toFixed(2))}
                {renderField("CEO Approval %", glassdoorData.glassdoor_ceo_approval_pct)}
                {renderField("Business Outlook %", glassdoorData.glassdoor_business_outlook_pct)}
                {renderField("Recommend to Friend %", glassdoorData.glassdoor_recommend_to_friend_pct)}
              </div>

              {/* Detailed Ratings */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Detailed Ratings</p>
                <div className="grid grid-cols-2 gap-4">
                  {renderField("Culture Rating", glassdoorData.glassdoor_culture_rating)}
                  {renderField("Diversity Rating", glassdoorData.glassdoor_diversity_rating)}
                  {renderField("Compensation Rating", glassdoorData.glassdoor_compensation_rating?.toFixed(2))}
                  {renderField("Senior Management Rating", glassdoorData.glassdoor_senior_management_rating?.toFixed(2))}
                  {renderField("Work Life Balance", glassdoorData.glassdoor_work_life_balance_rating)}
                  {renderField("Career Opportunities", glassdoorData.glassdoor_career_opportunities_rating?.toFixed(2))}
                </div>
              </div>

              {/* Growth Metrics */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Growth Metrics</p>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-[#575757]">Review Count Growth %</p>
                    <div className="space-y-1">
                      {renderField("MoM", glassdoorData.glassdoor_review_count_growth_percent?.mom)}
                      {renderField("QoQ", glassdoorData.glassdoor_review_count_growth_percent?.qoq)}
                      {renderField("YoY", glassdoorData.glassdoor_review_count_growth_percent?.yoy)}
                    </div>
                  </div>
                  <div>
                    <p className="text-[#575757]">CEO Approval Growth %</p>
                    <div className="space-y-1">
                      {renderField("MoM", glassdoorData.glassdoor_ceo_approval_growth_percent?.mom)}
                      {renderField("QoQ", glassdoorData.glassdoor_ceo_approval_growth_percent?.qoq)}
                      {renderField("YoY", glassdoorData.glassdoor_ceo_approval_growth_percent?.yoy)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* G2 Reviews */}
          {renderMetricSection("G2 Reviews", g2Data, 4)}
          {activeIndices.includes(4) && (
            <div className="space-y-2 mt-2">
              <div className="grid grid-cols-2 gap-4">
                {renderField("Review Count", g2Data.g2_review_count?.toLocaleString())}
                {renderField("Average Rating", g2Data.g2_average_rating)}
                {renderField("MoM Growth %", g2Data.g2_review_count_mom_pct)}
                {renderField("QoQ Growth %", g2Data.g2_review_count_qoq_pct)}
                {renderField("YoY Growth %", g2Data.g2_review_count_yoy_pct)}
              </div>
            </div>
          )}

          {/* LinkedIn Followers */}
          {renderMetricSection("LinkedIn Followers", data.linkedin_followers, 5)}
          {activeIndices.includes(5) && (
            <div className="space-y-2 mt-2">
              <div className="grid grid-cols-2 gap-4">
                {renderField("Follower Count", data.linkedin_followers?.linkedin_followers)}
                {renderField("MoM Growth %", data.linkedin_followers?.linkedin_followers_mom_percent)}
                {renderField("YoY Growth %", data.linkedin_followers?.linkedin_followers_yoy_percent)}
              </div>
            </div>
          )}

          {/* Funding Information */}
          {renderMetricSection("Funding & Investment", fundingData, 6)}
          {activeIndices.includes(6) && (
            <div className="space-y-4 mt-2">
              <div className="grid grid-cols-2 gap-4">
                {renderField("Total Investment (USD)", `$${fundingData.crunchbase_total_investment_usd?.toLocaleString()}`)}
                {renderField("Last Round Type", fundingData.last_funding_round_type)}
                {renderField("Last Round Amount (USD)", `$${fundingData.last_funding_round_investment_usd?.toLocaleString()}`)}
                {renderField("Days Since Last Fundraise", fundingData.days_since_last_fundraise)}
              </div>

              {/* Investors List */}
              {fundingData.crunchbase_investors?.length > 0 && (
                <div className="w-full">
                  <p className="text-[#575757] font-semibold">Investors</p>
                  <div className="bg-[#F2FBFF] p-2 flex flex-col gap-2">
                    {fundingData.crunchbase_investors.map((investor: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between">
                        <p>{investor}</p>
                        <FaRegCopy 
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(investor, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Funding Timeline */}
              {fundingData.funding_milestones_timeseries?.length > 0 && (
                <div className="w-full">
                  <p className="text-[#575757] font-semibold">Funding Timeline</p>
                  <div className="bg-[#F2FBFF] p-2 flex flex-col gap-4">
                    {fundingData.funding_milestones_timeseries
                      ?.sort((a: FundingMilestone, b: FundingMilestone) => 
                        new Date(b.date).getTime() - new Date(a.date).getTime()
                      )
                      .map((milestone: FundingMilestone, index: number) => (
                        <div key={index} className="space-y-2 border-b border-gray-200 pb-2 last:border-0">
                          <div className="flex justify-between items-center">
                            <p className="font-medium">{milestone.funding_round}</p>
                            <p className="text-sm text-gray-600">
                              {new Date(milestone.date).toLocaleDateString()}
                            </p>
                          </div>
                          <p className="text-sm">
                            Amount: ${milestone.funding_milestone_amount_usd?.toLocaleString()}
                          </p>
                          <div className="text-sm">
                            <p className="text-gray-600">Investors:</p>
                            <p>{milestone.funding_milestone_investors.split(',').filter(Boolean).join(', ')}</p>
                          </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Job Openings */}
          {renderMetricSection("Job Openings", jobOpeningsData, 7)}
          {activeIndices.includes(7) && (
            <div className="space-y-4 mt-2">
              {/* Basic Metrics & Growth in a grid */}
              <div className="grid grid-cols-2 gap-4">
                {/* Current Job Openings */}
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-sm text-gray-600 font-medium">Current Job Openings</p>
                  <div className="max-h-40 overflow-y-auto">
                    <p className="text-sm font-medium mt-1">
                      {jobOpeningsData.job_openings_count?.toLocaleString() || '0'}
                    </p>
                  </div>
                </div>

                {/* Growth Percentages */}
                <div className="bg-[#F2FBFF] p-2">
                  <p className="text-sm text-gray-600 font-medium">Growth Percentages</p>
                  <div className="space-y-2 mt-1">
                    {jobOpeningsData.growth_percent?.mom !== null && (
                      <div className="flex justify-between items-center">
                        <span>MoM Growth</span>
                        <span className="font-medium">{jobOpeningsData.growth_percent.mom?.toFixed(2)}%</span>
                      </div>
                    )}
                    {jobOpeningsData.growth_percent?.qoq !== null && (
                      <div className="flex justify-between items-center">
                        <span>QoQ Growth</span>
                        <span className="font-medium">{jobOpeningsData.growth_percent.qoq?.toFixed(2)}%</span>
                      </div>
                    )}
                    {jobOpeningsData.growth_percent?.yoy !== null && (
                      <div className="flex justify-between items-center">
                        <span>YoY Growth</span>
                        <span className="font-medium">{jobOpeningsData.growth_percent.yoy?.toFixed(2)}%</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Job Openings Timeline */}
              <div className="mt-4">
                <p className="text-[#575757] font-semibold mb-2">Job Openings Timeline</p>
                <div className="bg-[#F2FBFF] p-2">
                  <div className="max-h-60 overflow-y-auto">
                    {jobOpeningsData.openings_timeseries
                      ?.sort((a: JobOpeningTimeseriesEntry, b: JobOpeningTimeseriesEntry) => 
                        new Date(b.date).getTime() - new Date(a.date).getTime()
                      )
                      .map((entry: JobOpeningTimeseriesEntry, index: number) => (
                        <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                          <span className="text-sm">{formatDate(entry.date)}</span>
                          <span className="text-sm font-medium">
                            {entry.open_jobs.toLocaleString()} open {entry.open_jobs === 1 ? 'position' : 'positions'}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Recent Job Openings - Updated with scrollable container */}
              {jobOpeningsData.recent_openings?.length > 0 && (
                <div className="mt-4">
                  <p className="text-[#575757] font-semibold mb-2">Recent Job Openings</p>
                  <div className="bg-[#F2FBFF] p-2">
                    <div className="max-h-[400px] overflow-y-auto">
                      {jobOpeningsData.recent_openings.map((job: JobOpening, index: number) => (
                        <div key={index} className="border-b border-gray-200 pb-2 last:border-0">
                          <div className="flex justify-between">
                            <a href={job.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                              {job.title}
                            </a>
                            <span className="text-gray-600">{new Date(job.date_added).toLocaleDateString()}</span>
                          </div>
                          <div className="text-sm text-gray-600 mt-1">
                            <span>{job.category}</span>
                            {job.location_text && <span> • {job.location_text}</span>}
                          </div>
                        </div>
                      ))}
                    </div>
              </div>
            </div>
          )}

              {/* Function Growth */}
              {(Object.keys(jobOpeningsData.function_growth.qoq || {}).length > 0 || 
                Object.keys(jobOpeningsData.function_growth.six_months || {}).length > 0) && (
                <div className="mt-4">
                  <p className="text-[#575757] font-semibold mb-2">Function Growth</p>
                  <div className="grid grid-cols-2 gap-4">
                    {Object.keys(jobOpeningsData.function_growth.qoq || {}).length > 0 && (
                      <div>
                        <p className="font-medium mb-2">QoQ Growth %</p>
                        <div className="bg-[#F2FBFF] p-2">
                          {Object.entries(jobOpeningsData.function_growth.qoq || {})
                            .filter(([, value]) => value !== null)
                            .map(([func, growth], index) => (
                              <div key={index} className="flex justify-between items-center py-1">
                                <span className="capitalize">{func.replace(/_/g, ' ')}</span>
                                <span>{(growth as number).toFixed(1)}%</span>
                              </div>
                            ))}
              </div>
            </div>
          )}
                    {Object.keys(jobOpeningsData.function_growth.six_months || {}).length > 0 && (
                      <div>
                        <p className="font-medium mb-2">6 Month Growth %</p>
                        <div className="bg-[#F2FBFF] p-2">
                          {Object.entries(jobOpeningsData.function_growth.six_months || {})
                            .filter(([, value]) => value !== null)
                            .map(([func, growth], index) => (
                              <div key={index} className="flex justify-between items-center py-1">
                                <span className="capitalize">{func.replace(/_/g, ' ')}</span>
                                <span>{(growth as number).toFixed(1)}%</span>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Competitors Section */}
          {renderMetricSection("Competitors", competitorsData, 9)}
          {activeIndices.includes(9) && (
            <div className="space-y-4 mt-2">
              {/* Direct Competitors */}
              {competitorsData.competitor_website_domains?.length > 0 && (
                <div className="w-full">
                  <p className="text-[#575757] font-semibold">Direct Competitors</p>
                  <div className="bg-[#F2FBFF] p-2 flex flex-col gap-2">
                    {competitorsData.competitor_website_domains.map((domain: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between">
                        <p>{domain}</p>
                        <FaRegCopy 
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(domain, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Paid SEO Competitors */}
              {competitorsData.paid_seo_competitors_website_domains?.length > 0 && (
                <div className="w-full mt-4">
                  <p className="text-[#575757] font-semibold">Paid SEO Competitors</p>
                  <div className="bg-[#F2FBFF] p-2 flex flex-col gap-2">
                    {competitorsData.paid_seo_competitors_website_domains.map((domain: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between">
                        <p>{domain}</p>
                        <FaRegCopy 
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(domain, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Organic SEO Competitors */}
              {competitorsData.organic_seo_competitors_website_domains?.length > 0 && (
                <div className="w-full mt-4">
                  <p className="text-[#575757] font-semibold">Organic SEO Competitors</p>
                  <div className="bg-[#F2FBFF] p-2 flex flex-col gap-2">
                    {competitorsData.organic_seo_competitors_website_domains.map((domain: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between">
                        <p>{domain}</p>
                        <FaRegCopy 
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(domain, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Markets Section */}
          {renderMetricSection("Markets", marketsData, 10)}
          {activeIndices.includes(10) && marketsData.markets.length > 0 && (
            <div className="space-y-2 mt-2">
              <div className="bg-[#F2FBFF] p-2">
                {marketsData.markets.map((market: string, index: number) => (
                  <div key={index} className="group flex items-center justify-between py-1">
                    <span>{market}</span>
                    <FaRegCopy 
                      className="text-gray-600 cursor-pointer hidden group-hover:block"
                      onClick={(e) => handleCopyText(market, e)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Stock Symbols Section */}
          {renderMetricSection("Stock Symbols", stockData, 11)}
          {activeIndices.includes(11) && stockData.symbols.length > 0 && (
            <div className="space-y-2 mt-2">
              <div className="bg-[#F2FBFF] p-2">
                {stockData.symbols.map((symbol: string, index: number) => (
                  <div key={index} className="group flex items-center justify-between py-1">
                    <span>{symbol}</span>
                    <FaRegCopy 
                      className="text-gray-600 cursor-pointer hidden group-hover:block"
                      onClick={(e) => handleCopyText(symbol, e)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Taxonomy Section */}
          {renderMetricSection("Taxonomy", taxonomyData, 12)}
          {activeIndices.includes(12) && (
            <div className="space-y-4 mt-2">
              {/* LinkedIn Industries */}
              {taxonomyData.linkedin_industries.length > 0 && (
                <div>
                  <p className="text-[#575757] font-semibold mb-2">LinkedIn Industries</p>
                  <div className="bg-[#F2FBFF] p-2">
                    {taxonomyData.linkedin_industries.map((industry: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between py-1">
                        <span>{industry}</span>
                        <FaRegCopy 
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(industry, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Crunchbase Categories */}
              {taxonomyData.crunchbase_categories.length > 0 && (
                <div>
                  <p className="text-[#575757] font-semibold mb-2">Crunchbase Categories</p>
                  <div className="bg-[#F2FBFF] p-2">
                    {taxonomyData.crunchbase_categories.map((category: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between py-1">
                        <span>{category}</span>
                        <FaRegCopy 
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(category, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* LinkedIn Specialties */}
              {taxonomyData.linkedin_specialities.length > 0 && (
                <div>
                  <p className="text-[#575757] font-semibold mb-2">LinkedIn Specialties</p>
                  <div className="bg-[#F2FBFF] p-2">
                    {taxonomyData.linkedin_specialities.map((specialty: string, index: number) => (
                      <div key={index} className="group flex items-center justify-between py-1">
                        <span className="capitalize">{specialty}</span>
                        <FaRegCopy 
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(specialty, e)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* SEO Section */}
          {renderMetricSection("SEO Metrics", seoData, 13)}
          {activeIndices.includes(13) && (
            <div className="space-y-4 mt-2">
              {/* Paid Search Metrics */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Paid Search</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Monthly Ad Budget</p>
                      <p className="break-words">${seoData.monthly_google_ads_budget?.toLocaleString()}</p>
                    </div>
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Monthly Paid Clicks</p>
                      <p className="break-words">{seoData.monthly_paid_clicks?.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="space-y-2 self-start">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Total Ads Purchased</p>
                      <p className="break-words">{seoData.total_ads_purchased?.toLocaleString()}</p>
                    </div>
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Average Ad Rank</p>
                      <p className="break-words">{seoData.average_ad_rank}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Organic Search Metrics */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Organic Search</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Monthly Organic Value</p>
                      <p className="break-words">${seoData.monthly_organic_value?.toLocaleString()}</p>
                    </div>
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Monthly Organic Clicks</p>
                      <p className="break-words">{seoData.monthly_organic_clicks?.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="space-y-2 self-start">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Total Organic Results</p>
                      <p className="break-words">{seoData.total_organic_results?.toLocaleString()}</p>
                    </div>
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Average Organic Rank</p>
                      <p className="break-words">{seoData.average_seo_organic_rank}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Keyword Changes */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Keyword Changes</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Newly Ranked Keywords</p>
                      <p className="break-words">{seoData.newly_ranked_seo_keywords?.toLocaleString()}</p>
                    </div>
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Gained Ranked Keywords</p>
                      <p className="break-words">{seoData.gained_ranked_seo_keywords?.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="space-y-2 self-start">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Lost Ranked Keywords</p>
                      <p className="break-words">{seoData.lost_ranked_seo_keywords?.toLocaleString()}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Web Traffic Section */}
          {renderMetricSection("Web Traffic", webTrafficData, 14)}
          {activeIndices.includes(14) && (
            <div className="space-y-4 mt-2">
              {/* Basic Metrics */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Traffic Overview</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Monthly Visitors</p>
                      <p className="break-words">{webTrafficData.monthly_visitors?.toLocaleString()}</p>
                    </div>
                  </div>
                  <div className="space-y-2 self-start">
                    <div className="bg-[#F2FBFF] p-2">
                      <p className="text-sm text-gray-600">Growth Percentages</p>
                      <div className="space-y-1">
                        <div>MoM: {webTrafficData.monthly_visitor_mom_pct?.toFixed(2)}%</div>
                        <div>QoQ: {webTrafficData.monthly_visitor_qoq_pct?.toFixed(2)}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Monthly Visitors Timeline */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Monthly Visitors Timeline</p>
                <div className="bg-[#F2FBFF] p-2">
                  <div className="max-h-60 overflow-y-auto">
                    {webTrafficData.monthly_visitors_timeseries
                      ?.sort((a: TimeseriesEntry, b: TimeseriesEntry) => 
                        new Date(b.date).getTime() - new Date(a.date).getTime()
                      )
                      .map((entry: TimeseriesEntry, index: number) => (
                        <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                          <span className="text-sm">{formatDate(entry.date)}</span>
                          <span className="text-sm font-medium">{entry.monthly_visitors.toLocaleString()} visitors</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              {/* Current Traffic Sources */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Current Traffic Sources</p>
                <div className="bg-[#F2FBFF] p-2">
                  {[
                    { name: 'Direct', value: webTrafficData.traffic_sources.direct },
                    { name: 'Search', value: webTrafficData.traffic_sources.search },
                    { name: 'Referral', value: webTrafficData.traffic_sources.referral },
                    { name: 'Paid Referral', value: webTrafficData.traffic_sources.paid_referral },
                    { name: 'Social', value: webTrafficData.traffic_sources.social }
                  ]
                    .sort((a: TrafficSource, b: TrafficSource) => ((b.value || 0) - (a.value || 0)))
                    .map((source: TrafficSource, index: number) => (
                      <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                        <span className="text-sm">{source.name}</span>
                        <span className="text-sm">{source.value?.toFixed(2)}%</span>
                      </div>
                    ))}
                </div>
              </div>

              {/* Traffic Sources Timeline */}
              <div>
                <p className="text-[#575757] font-semibold mb-2">Traffic Sources Timeline</p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {/* Direct Traffic */}
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600 font-medium mb-2">Direct Traffic</p>
                    <div className="max-h-40 overflow-y-auto">
                      {webTrafficData.traffic_sources_timeseries.direct
                        ?.sort((a: TrafficSourceEntry, b: TrafficSourceEntry) => 
                          new Date(b.date).getTime() - new Date(a.date).getTime()
                        )
                        .map((entry: TrafficSourceEntry, index: number) => (
                          <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                            <span className="text-sm">{formatDate(entry.date)}</span>
                            <span className="text-sm">{entry.traffic_source_direct_pct.toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* Search Traffic */}
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600 font-medium mb-2">Search Traffic</p>
                    <div className="max-h-40 overflow-y-auto">
                      {webTrafficData.traffic_sources_timeseries.search
                        ?.sort((a: TrafficSourceEntry, b: TrafficSourceEntry) => 
                          new Date(b.date).getTime() - new Date(a.date).getTime()
                        )
                        .map((entry: TrafficSourceEntry, index: number) => (
                          <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                            <span className="text-sm">{formatDate(entry.date)}</span>
                            <span className="text-sm">{entry.traffic_source_search_pct.toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* Referral Traffic */}
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600 font-medium mb-2">Referral Traffic</p>
                    <div className="max-h-40 overflow-y-auto">
                      {webTrafficData.traffic_sources_timeseries.referral
                        ?.sort((a: TrafficSourceEntry, b: TrafficSourceEntry) => 
                          new Date(b.date).getTime() - new Date(a.date).getTime()
                        )
                        .map((entry: TrafficSourceEntry, index: number) => (
                          <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                            <span className="text-sm">{formatDate(entry.date)}</span>
                            <span className="text-sm">{entry.traffic_source_referral_pct.toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>

                  {/* Social Traffic */}
                  <div className="bg-[#F2FBFF] p-2">
                    <p className="text-sm text-gray-600 font-medium mb-2">Social Traffic</p>
                    <div className="max-h-40 overflow-y-auto">
                      {webTrafficData.traffic_sources_timeseries.social
                        ?.sort((a: TrafficSourceEntry, b: TrafficSourceEntry) => 
                          new Date(b.date).getTime() - new Date(a.date).getTime()
                        )
                        .map((entry: TrafficSourceEntry, index: number) => (
                          <div key={index} className="flex justify-between items-center py-1 border-b border-gray-100 last:border-0">
                            <span className="text-sm">{formatDate(entry.date)}</span>
                            <span className="text-sm">{entry.traffic_source_social_pct.toFixed(2)}%</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* News Articles Section */}
          {renderMetricSection("News Articles", companyData["News Articles"], 15)}
          {activeIndices.includes(15) && companyData["News Articles"]?.length > 0 && (
            <div className="space-y-4 mt-2">
              <div className="bg-[#F2FBFF] p-2">
                <div className="h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                  {companyData["News Articles"]
                    ?.sort((a: NewsArticle, b: NewsArticle) => 
                      new Date(b.article_publish_date).getTime() - new Date(a.article_publish_date).getTime()
                    )
                    .map((article: NewsArticle, index: number) => (
                      <div key={index} className="py-3 border-b border-gray-200 last:border-0 hover:bg-gray-50">
                        <div className="flex justify-between items-start mb-2">
                          <a 
                            href={article.article_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline font-medium flex-1 mr-4"
                          >
                            {article.article_title}
                          </a>
                          <FaRegCopy 
                            className="text-gray-600 cursor-pointer hover:text-gray-800 mt-1"
                            onClick={(e) => handleCopyText(article.article_url, e)}
                          />
                        </div>
                        <div className="flex justify-between items-center text-sm text-gray-600">
                          <span>{article.article_publisher_name}</span>
                          <span>{formatDate(article.article_publish_date)}</span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}

          {/* Decision Makers Section */}
          {basicInfo.decision_makers && 
           basicInfo.decision_makers !== "No data found" && 
           Array.isArray(basicInfo.decision_makers) && 
           basicInfo.decision_makers.length > 0 && (
            <div className="space-y-4 mb-6">
              <p className="text-[#575757] font-semibold mb-2">Decision Makers</p>
              <div className="bg-[#F2FBFF] p-2">
                <div className="max-h-[400px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                  {basicInfo.decision_makers.map((person: string, index: number) => (
                    <div key={index} className="py-2 border-b border-gray-200 last:border-0 hover:bg-gray-50 group">
                      <div className="flex justify-between items-center">
                        <span>{person}</span>
                        <FaRegCopy 
                          className="text-gray-600 cursor-pointer hidden group-hover:block"
                          onClick={(e) => handleCopyText(person, e)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </App>
  );
};

export const DownArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.296875V5.16511L9 12.999L18 5.16511V0.296875L9 8.11216L0 0.296875Z" fill={color} />
  </svg>
);

export const UpArrowNew = ({ color }: { color?: string }) => (
  <svg width="14" height="9" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.999 12.6953V7.82708L8.99902 -0.00685804L-0.000980854 7.82708V12.6953L8.99902 4.88003L17.999 12.6953Z"
      fill={color}
    />
  </svg>
);

export default CrustDataCompanyViewer; 
