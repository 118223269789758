import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { deleteRecords } from "@/utils/apis";
import { Button, Checkbox, Input, Modal, Row } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { memo, useCallback, useEffect, useMemo, useState } from "react";

type Props = {
  modal: boolean;
  dataLoading: boolean;
  close: () => void;
  duplicateData: any[];
  workflowId: string;
  refresh: () => void;
  numOfRows: number;
};

const CheckboxRow = memo(
  ({
    data,
    onCheckChange,
    isChecked,
  }: {
    data: any;
    onCheckChange: (e: CheckboxChangeEvent, data: any) => void;
    isChecked: boolean;
  }) => {
    return (
      <Row className="flex w-full justify-between items-center px-6 border-t py-2">
        <Checkbox onChange={(e) => onCheckChange(e, data)} checked={isChecked}>
          {data.value}
        </Checkbox>
        <span className="bg-gray-100 px-2 py-1 rounded-md">{data.count} rows</span>
      </Row>
    );
  }
);

const DedupeModal = ({ modal, dataLoading, close, duplicateData, workflowId, refresh, numOfRows }: Props) => {
  const [checkedValuesFinal, setCheckedValuesFinal] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("");

  const filteredData = useMemo(() => {
    return duplicateData.filter((data) => data.value.toLowerCase().includes(searchText.toLowerCase()));
  }, [searchText, duplicateData]);

  useEffect(() => {
    const flattenData = duplicateData.map((data) => data.keysToRemove);
    setCheckedValuesFinal(flattenData.flat());
  }, [duplicateData]);

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        const allKeys = filteredData.flatMap((data) => data.keysToRemove);
        const existingChecked = checkedValuesFinal.filter(
          (value) => !filteredData.some((data) => data.keysToRemove.includes(value))
        );
        setCheckedValuesFinal([...existingChecked, ...allKeys]);
      } else {
        const keysToRemove = new Set(filteredData.flatMap((data) => data.keysToRemove));
        setCheckedValuesFinal((prev) => prev.filter((value) => !keysToRemove.has(value)));
      }
    },
    [filteredData, checkedValuesFinal]
  );

  const { isAllChecked, isIndeterminate } = useMemo(() => {
    const filteredKeys = new Set(filteredData.flatMap((data) => data.keysToRemove));
    const allChecked = filteredData.length > 0 && [...filteredKeys].every((key) => checkedValuesFinal.includes(key));
    const someChecked = [...filteredKeys].some((key) => checkedValuesFinal.includes(key));

    return {
      isAllChecked: allChecked,
      isIndeterminate: someChecked && !allChecked,
    };
  }, [filteredData, checkedValuesFinal]);

  const handleDelete = useCallback(async () => {
    if (checkedValuesFinal.length === 0) return;
    await deleteRecords(workflowId || "", checkedValuesFinal);
    setCheckedValuesFinal([]);
    close();
    refresh();
  }, [checkedValuesFinal, workflowId, close, refresh]);

  const onCheckChange = useCallback((e: CheckboxChangeEvent, data: any) => {
    const { checked } = e.target;
    const checkedValues = data?.keysToRemove.flat();

    setCheckedValuesFinal((prev) => {
      if (checked) {
        const newCheckedValues = [...prev];
        checkedValues.forEach((value: string) => {
          if (!newCheckedValues.includes(value)) {
            newCheckedValues.push(value);
          }
        });
        return newCheckedValues;
      } else {
        return prev.filter((value) => !checkedValues.includes(value));
      }
    });
  }, []);

  const checkIfValueIsChecked = useCallback(
    (data: any) => {
      const checkedValues: string[] = data?.keysToRemove;
      return checkedValues.every((value: string) => checkedValuesFinal.includes(value));
    },
    [checkedValuesFinal]
  );

  if (numOfRows === 0) {
    return (
      <Modal
        title="Dedupe Based on Column"
        centered
        open={modal}
        onCancel={close}
        styles={{ footer: { display: "none" } }}
        width="30%"
        className="text-center"
      >
        <p>No rows to dedupe!</p>
      </Modal>
    );
  }

  return (
    <Modal
      title="Dedupe Based on Column"
      centered
      open={modal}
      onCancel={close}
      width="30%"
      maskClosable={false}
      className="text-center"
      footer={[
        <Button key="back" type="text" onClick={close}>
          Cancel
        </Button>,
        <Button key="submit" danger disabled={checkedValuesFinal.length === 0} onClick={handleDelete}>
          Delete
        </Button>,
      ]}
    >
      {!dataLoading ? (
        duplicateData.length > 0 ? (
          <div>
            <div className="py-4 space-y-2">
              <p>Review the duplicates found</p>
              <p>
                Duplicates rows are found with respect to the current view only. Deleting duplicates keeps only the
                first appearance of each item in the view.
              </p>
            </div>
            <Input
              placeholder="Search duplicates..."
              className="mb-3"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div className="mb-1 font-bold flex justify-between px-5">
              <p>Items with duplicates({checkedValuesFinal.length})</p>
              <p>Total Rows</p>
            </div>
            <div className="border rounded-md">
              <Row className="px-6 py-2 border-b">
                <Checkbox
                  onChange={(e) => handleSelectAll(e.target.checked)}
                  checked={isAllChecked}
                  indeterminate={isIndeterminate}
                >
                  Select All
                </Checkbox>
              </Row>
              <div className="max-h-56 overflow-y-scroll">
                {filteredData.map((data: any, _: number) => (
                  <CheckboxRow
                    key={data.value}
                    data={data}
                    onCheckChange={onCheckChange}
                    isChecked={checkIfValueIsChecked(data)}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <p>No Duplicates Found!</p>
        )
      ) : (
        <span>
          <SpinnerStatus /> Loading...
        </span>
      )}
    </Modal>
  );
};

export default memo(DedupeModal);
