import { message, Table } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import FloqerLogo from "@Assets/SVGs/floqerLogo.svg";
import { capitalize } from "lodash";
import { IoMdPause, IoMdPlay } from "react-icons/io";
import { useEffect, useState } from "react";
import SpinnerStatus from "@/Components/Generics/SpinnerStatus/SpinnerStatus";
import { updateSignal } from "../apis";
import { SIGNAL_ID_TO_URL } from "@/utils/urls";

type Props = {
  availableSignals: any[];
  userSignals: any[];
};

const STATUS_COLORS = {
  active: "bg-green-500",
  terminated: "bg-red-500",
  paused: "bg-yellow-500",
};

const Dashboard = ({ availableSignals, userSignals }: Props) => {
  const [updatedSignals, setUpdatedSignals] = useState<any[]>(userSignals);
  const [staticSignals, setStaticSignals] = useState<any[]>([]);
  const [activeSignals, setActiveSignals] = useState<any[]>([]);
  const [loading, setLoading] = useState<any>({});

  useEffect(() => {
    if (!updatedSignals.length) return;
    setStaticSignals(updatedSignals.filter((signal) => signal.status === "static"));
    setActiveSignals(updatedSignals.filter((signal) => signal.status !== "static"));
  }, [updatedSignals]);

  const handleUpdateSignal = async (id: string, signal_id: string, status: string) => {
    try {
      setLoading((prev: any) => ({ ...prev, [id]: true }));
      await updateSignal(id, SIGNAL_ID_TO_URL[signal_id as keyof typeof SIGNAL_ID_TO_URL], status);
      setUpdatedSignals((prev) => prev.map((signal) => (signal.id === id ? { ...signal, status } : signal)));
    } catch (error) {
      message.error("Failed to update signal");
    } finally {
      setLoading((prev: any) => ({ ...prev, [id]: false }));
    }
  };

  return (
    <div className="w-full h-full flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-medium text-[#1E0068] px-5">Signals</h2>
        <Table
          dataSource={activeSignals.map((signal) => {
            const { signal_id, created_at, expiration_date, payload, paused_at, name, status, id } = signal;
            const { logo } = availableSignals.find((availableSignal) => availableSignal.id === signal_id);
            return {
              key: signal.id,
              name: (
                <Link to={`/list/${signal.id}`} className="flex items-center gap-3">
                  <img src={logo || FloqerLogo} alt={name} className="w-8 h-8" />
                  <h3 className="font-medium">{name}</h3>
                </Link>
              ),
              status: status,
              payload: JSON.stringify(payload),
              created_at: moment(created_at).utc().local().format("MMM DD, YYYY HH:mm:ss"),
              paused_at: paused_at ? moment(paused_at).utc().local().format("MMM DD, YYYY HH:mm:ss") : "Not Paused",
              expiration_date: moment(expiration_date).utc().local().format("MMM DD, YYYY HH:mm:ss"),
              actions: (
                <div className="flex items-center justify-center gap-2">
                  {loading[id] ? (
                    <SpinnerStatus />
                  ) : status === "active" ? (
                    <button
                      className="text-sm font-medium text-primary"
                      title="Pause Signal"
                      onClick={() => handleUpdateSignal(id, signal_id, "paused")}
                    >
                      <IoMdPause />
                    </button>
                  ) : (
                    <button
                      className="text-sm font-medium text-primary"
                      title="Resume Signal"
                      onClick={() => handleUpdateSignal(id, signal_id, "active")}
                    >
                      <IoMdPlay />
                    </button>
                  )}
                </div>
              ),
            };
          })}
          columns={[
            {
              title: "Signal",
              dataIndex: "name",
              key: "name",
              width: "30%",
            },
            {
              title: "Applied Filters",
              dataIndex: "payload",
              key: "payload",
              width: "20%",
              ellipsis: true,
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              width: "15%",
              align: "center",
              render: (params: any) => (
                <div className="flex items-center justify-center gap-2">
                  <div className={`w-2 h-2 rounded-full ${STATUS_COLORS[params as keyof typeof STATUS_COLORS]}`}></div>
                  <p className="text-sm font-normal">{capitalize(params)}</p>
                </div>
              ),
            },
            {
              title: "Created At",
              dataIndex: "created_at",
              key: "created_at",
              width: "18%",
              align: "center",
            },
            {
              title: "Paused At",
              dataIndex: "paused_at",
              key: "paused_at",
              width: "18%",
              align: "center",
            },
            {
              title: "Expiration Date",
              dataIndex: "expiration_date",
              key: "expiration_date",
              width: "18%",
              align: "center",
            },
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              width: "9%",
              align: "center",
            },
          ]}
          pagination={{
            pageSize: 10,
            // showSizeChanger: true,
          }}
        />
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-lg font-medium text-[#1E0068] px-5">Lists</h2>
        <Table
          dataSource={staticSignals.map((signal) => {
            const { signal_id, created_at, name } = signal;
            const { logo } = availableSignals.find((availableSignal) => availableSignal.id === signal_id);
            return {
              key: signal.id,
              name: (
                <Link to={`/list/${signal.id}`} className="flex items-center gap-3">
                  <img src={logo || FloqerLogo} alt={name} className="w-8 h-8" />
                  <h3 className="font-medium">{name}</h3>
                </Link>
              ),
              created_at: moment(created_at).utc().local().format("MMM DD, YYYY HH:mm:ss"),
            };
          })}
          columns={[
            {
              title: "Signal",
              dataIndex: "name",
              key: "name",
              width: "70%",
            },
            {
              title: "Created At",
              dataIndex: "created_at",
              key: "created_at",
              width: "30%",
              align: "center",
            },
          ]}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Dashboard;
